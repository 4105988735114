import {
  APPEND_INSPECTION_LEAD,
  CLEAR_CRM_LEAD_DETAIL,
  CRM_LEAD_ADD_TASK,
  CRM_LEAD_CHANGE_STATUS,
  CRM_LEAD_CONNECTS_UPDATE,
  CRM_LEAD_TASK_STATUS_UPDATE,
  CRM_LEAD_UPDATE,
  CRM_LEAD_UPDATE_TASK,
  FETCH_CRM_LEAD_DETAIL,
  FETCH_CRM_LEAD_TASKS,
  SET_CRM_LEAD_DATA,
  UPDATE_CRM_LEAD_COMMENTS,
  UPDATE_CRM_LEAD_WATCHERS,
  UPDATE_CRM_LEAD_DETAIL,
  UPDATE_VAS_LEAD,
} from "../../constant/actionTypes";

export const INITIAL_STATE = {
  lead: {},
  statuses: [],
  task_statuses: [],
  lead_dispositions: [],
  dispositions: {},
  classifications: [],
  assignees: {
    users: [],
    loading: false,
    error: "",
  },
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CRM_LEAD_DETAIL:
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_CRM_LEAD_DETAIL:
      return {
        ...state,
        lead: { ...action.payload.crmLead },
      };

    case SET_CRM_LEAD_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case CLEAR_CRM_LEAD_DETAIL:
      return {
        INITIAL_STATE,
      };
    case CRM_LEAD_UPDATE:
      const { tasks, status, disposition, connects, classification } = action.payload;
      state.lead = { ...state.lead, tasks, status, disposition, connects, classification };
      state.dispositions = { ...action.payload.dispositions };
      return {
        ...state,
      };

    case CRM_LEAD_TASK_STATUS_UPDATE: {
      const lead = { ...state.lead };
      let index = lead.tasks.findIndex(e => e.id === action.payload.id);
      lead.tasks[index] = { ...lead.tasks[index], ...action.payload };
      lead.tasks = [...lead.tasks];
      return {
        ...state,
        lead,
      };
    }

    case CRM_LEAD_UPDATE_TASK: {
      const lead = state.lead;
      const tasks = lead.tasks;
      let index = tasks.findIndex(e => e.id === action.payload.task.id);
      tasks.splice(index, 1, { ...action.payload.task });
      lead.tasks = [...tasks];
      return {
        ...state,
        ...lead,
      };
    }
    case CRM_LEAD_ADD_TASK:
      const lead = state.lead;
      lead.tasks = [action.payload.task].concat([...state.lead.tasks]);
      return {
        ...state,
        ...lead,
      };

    case UPDATE_CRM_LEAD_COMMENTS: {
      const lead = state.lead;
      lead.comments = [...lead.comments, action.payload.comment];
      return {
        ...state,
        ...lead,
      };
    }

    case UPDATE_CRM_LEAD_WATCHERS: {
      const lead = state.lead;
      lead.watchers = [...action.payload.watchers];
      return {
        ...state,
        ...lead,
      };
    }
    case FETCH_CRM_LEAD_TASKS: {
      return {
        ...state,
        ...{
          lead: {
            ...state.lead,
            tasks: action.payload.tasks,
          },
        },
      };
    }

    case CRM_LEAD_CHANGE_STATUS: {
      return {
        ...state,
        ...{
          lead: {
            ...state.lead,
            status: action.payload.status,
          },
        },
      };
    }

    case CRM_LEAD_CONNECTS_UPDATE: {
      return {
        ...state,
        ...{
          lead: {
            ...state.lead,
            connects: action.payload.connects,
          },
        },
      };
    }
    case APPEND_INSPECTION_LEAD:
      return {
        ...state,
        lead: {
          ...state.lead,
          leads: [...state.lead.leads, action.payload.newLead],
        },
      };

    case UPDATE_VAS_LEAD:
      return {
        ...state,
        lead: {
          ...state?.lead,
          crm_leads: [action?.payload, ...state?.lead?.crm_leads],
        },
      };

    default:
      return state;
  }
};
