import * as yup from "yup";

import { ButtonLoading, CustomAlert, FormInput } from "../../components/common";
import React, { Fragment } from "react";
import { getErrorString, removeAppCookies } from "../../helpers/Util";

import { Formik } from "formik";
import { LOGIN_PATH } from "../../constant/appPaths";
import { changePasswordApi } from "../../helpers/ApiHelper";
import { strings } from "../../constant/strings";

const ResetPwd = ({ handleLogout, history }) => {
  const onSubmit = async ({ current_password, password, password_confirmation }, { setSubmitting, setStatus }) => {
    setStatus({ message: "" });
    const response = await changePasswordApi({
      current_password,
      password,
      password_confirmation,
    });
    if (response) {
      setSubmitting(false);
      if (response.success) {
        removeAppCookies();
        handleLogout();
        history.push(LOGIN_PATH);
      } else {
        setStatus({ message: getErrorString(response?.errors) });
      }
    }
  };

  const validationSchema = () =>
    yup.object().shape({
      current_password: yup.string().required(strings.type_current_password),
      password: yup.string().required(strings.type_password),
      // password: passwordValidation,
      password_confirmation: yup.string().required(strings.re_type_password),
      // .oneOf([yup.ref("password"), null], "Passwords must match"),
    });

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12 p-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="card mt-4 p-4">
                      <Formik
                        initialValues={{
                          current_password: "",
                          password: "",
                          password_confirmation: "",
                        }}
                        validationSchema={validationSchema()}
                        onSubmit={onSubmit}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          status,
                        }) => (
                          <form className="theme-form">
                            <div className="text-center">
                              <h4 className="f-16 mb-3 f-w-600">{strings.chanage_your_password}</h4>
                            </div>
                            <FormInput
                              label={strings.current_password}
                              placeholder="********"
                              type="password"
                              name="current_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.current_password}
                              errorMsg={errors.current_password && touched.current_password && errors.current_password}
                            />
                            <FormInput
                              label={strings.new_password}
                              placeholder="********"
                              type="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              errorMsg={errors.password && touched.password && errors.password}
                            />
                            <FormInput
                              label={strings.retype_password}
                              placeholder="********"
                              type="password"
                              name="password_confirmation"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password_confirmation}
                              errorMsg={
                                errors.password_confirmation &&
                                touched.password_confirmation &&
                                errors.password_confirmation
                              }
                            />
                            {status && status.message && <CustomAlert message={status.message} />}
                            <div className="form-group form-row mt-3 mb-0">
                              <ButtonLoading
                                onClick={handleSubmit}
                                block
                                type="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                              >
                                {strings.change_password}
                              </ButtonLoading>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPwd;
