import { FETCH_CRM_PAYMENTS, UPDATE_CRM_PAYMENT, SET_CRM_PAYMENTS_LOADING } from "constant/actionTypes";
import { fetchCRMPaymentsApi } from "helpers/ApiHelper";
import { getErrorString } from "helpers/Util";

export const fetchCrmPayments =
  (queryParams = "") =>
  async dispatch => {
    dispatch({ type: SET_CRM_PAYMENTS_LOADING, payload: { loading: true } });
    const res = await fetchCRMPaymentsApi(queryParams);
    if (res?.success) {
      dispatch({
        type: FETCH_CRM_PAYMENTS,
        payload: {
          list: [...res.payments],
          loading: false,
          error: "",
          pagination: { ...res.pagination },
          statuses: [...res.statuses],
          paymentMethods: [...res.crm_payment_methods],
          paymentTypes: [...res.crm_payment_types],
          paymentLinkStatuses: [...res.payment_link_statuses],
          permittedMeta: res.permitted_meta,
        },
      });
    } else {
      dispatch({
        type: FETCH_CRM_PAYMENTS,
        payload: {
          list: [],
          loading: false,
          error: getErrorString(res.message),
        },
      });
    }
  };

export const updateCrmPayment = item => dispatch => {
  dispatch({
    type: UPDATE_CRM_PAYMENT,
    payload: {
      item: { ...item },
    },
  });
};
