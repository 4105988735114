import {
  CLEAR_CRM_TASK_DETAIL,
  FETCH_TASK_DETAIL,
  REVERT_TASK_STATUS,
  SET_TASK_DATA,
  UPDATE_FOLLOW_UPS,
  UPDATE_TASK_REMINDERS,
  UPDATE_TASK_STATUS,
} from "../../constant/actionTypes";
import { clone } from "../../helpers/Util";

export const INITIAL_STATE = {
  task: null,
  statuses: [],
  assignees: {
    users: [],
    loading: false,
    error: "",
  },
  follow_ups: [],
  loading: false,
  error: "",
  type: "new",
  newAppointments: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TASK_DETAIL:
      return {
        ...state,
        ...action.payload,
      };

    case SET_TASK_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_CRM_TASK_DETAIL:
      return {
        ...state,
        task: null,
      };

    case REVERT_TASK_STATUS: {
      let task = state.task;
      task = { ...task, ...action.payload };

      return {
        ...state,
        task,
      };
    }

    case UPDATE_TASK_STATUS: {
      const task = state.task;
      task.status = action.payload.status;
      return {
        ...state,
        task,
      };
    }

    case UPDATE_TASK_REMINDERS: {
      const task = state.task;
      if (task) {
        task.reminders = action.payload.reminders;
        return {
          ...state,
          task,
        };
      }
    }

    case UPDATE_FOLLOW_UPS: {
      return {
        ...state,
        follow_ups: [...clone(action.payload.followUps)],
      };
    }
    // case SET_APPOINTMENT_DATA:
    //   return {
    //     ...state,
    //     newAppointments: [...action.payload.appointments],
    //   };

    // case CLEAR_APPOINTMENT:
    //   return {
    //     ...state,
    //     newAppointments: [],
    //   };

    default:
      return state;
  }
};
