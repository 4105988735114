import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { Modal, Status, Toast } from "../common";
import { strings } from "../../constant/strings";
import { changeFollowUpStatus } from "../../helpers/ApiHelper";
import { getErrorString, isFieldRestricted } from "../../helpers/Util";
import { updateTaskFollowUps, updateTaskFollowUpStatus } from "../../actions/crm";
import { useDispatch } from "react-redux";

const FollowUpStatusInput = ({
  followUps,
  followUpId,
  statuses,
  status,
  canUpdate = true,
  setFollowUps,
  fromTaskForm,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedStatus, setStatus] = useState(status);
  const [showModal, setShowModal] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setStatus(status);
  }, [status]);

  const postData = async () => {
    const response = await changeFollowUpStatus(followUpId, { status_id: modalStatus.id });
    if (response) {
      if (response.success) {
        fromTaskForm && dispatch(updateTaskFollowUpStatus(response?.follow_up));
        setStatus(modalStatus);
        const newFollowUps = !isFieldRestricted(followUps)
          ? followUps.map(followUp => {
              if (followUp?.reference_number === response?.follow_up?.reference_number) {
                return { ...response?.follow_up };
              }
              return { ...followUp };
            })
          : [];
        if (setFollowUps) {
          setFollowUps([...newFollowUps]);
        } else {
          dispatch(updateTaskFollowUps([...newFollowUps]));
        }
      } else {
        Toast.error(getErrorString(response));
        setModalStatus(false);
      }
      setShowModal(false);
    }
  };

  return (
    <>
      <Modal
        toggle={() => setShowModal(!showModal)}
        title={"Change Follow Up Status"}
        open={showModal}
        body={() => <>{"Are you sure you want to change follow up status?"}</>}
        actions={[
          {
            label: strings.no,
            onClick: () => {
              setShowModal(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
          {
            label: strings.yes,
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: () => {
              postData();
            },
          },
        ]}
      />
      <Dropdown
        className="dropdown-status"
        isOpen={open}
        toggle={() => {
          canUpdate && setOpen(prevState => !prevState);
        }}
        direction="down"
      >
        <DropdownToggle
          className={`btn btn-pill badge-status`}
          size="sm"
          tag="div"
          caret={!!statuses?.length && canUpdate && selectedStatus?.slug === "due"}
          style={{
            "--badge-bg": selectedStatus?.bg_color,
            "--badge-color": selectedStatus?.color,
          }}
        >
          {selectedStatus ? (
            <div className={"text-truncate"} style={{ maxWidth: "15ch" }}>
              {selectedStatus.name}
            </div>
          ) : (
            strings.to_do
          )}
        </DropdownToggle>
        <DropdownMenu container="body" className="mt-2">
          {statuses
            .filter(e => Number(e.id) !== Number(selectedStatus?.id))
            .map((e, i) => (
              <Fragment key={e.id}>
                {i === 0 && <span className="pl-4 pt-2 pb-2">{strings.select_status}</span>}
                <DropdownItem
                  key={e.id}
                  onClick={() => {
                    setModalStatus(e);
                    setShowModal(true);
                  }}
                >
                  <Status {...e} />
                  {selectedStatus?.id === e.id && (
                    <span className="badge-status-check pull-right mt-2">
                      <i className="fa fa-check"></i>
                    </span>
                  )}
                </DropdownItem>
              </Fragment>
            ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default FollowUpStatusInput;
