import React, { Fragment } from "react";
import { Avatar } from "./Avatar";
import { Skeleton } from "./index";

export const RowSkeletons = ({ number, avatarSkeletonColumnIndex, columnSkeletons, rowSkeletons }) => {
  return (
    <>
      {Array.from(Array(number)).map((value, index) => (
        <Fragment key={index}>
          <TableLoader
            columnSkeletons={columnSkeletons}
            rowSkeletons={rowSkeletons}
            avatarSkeletonColumnIndex={avatarSkeletonColumnIndex}
            showCheckboxSkeleton
          />
        </Fragment>
      ))}
    </>
  );
};

export const TableLoader = props => {
  const {
    rowSkeletons = [1, 2, 3, 4, 5, 6, 7, 8],
    columnSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    showCheckboxSkeleton = false,
  } = props;
  return (
    <div className="d-flex flex-column w-100">
      {rowSkeletons.map(e => (
        <div className="d-flex flex-row justify-content-between border-bottom" key={e}>
          {columnSkeletons.map(i => (
            <div className={`ml-2 mr-2 ${e === 1 ? "mt-2" : "mt-1"}`} key={i}>
              <div className="d-flex flex-row mb-3 mt-1" key={i}>
                {props.avatarSkeletonColumnIndex?.find(val => val === i) ? <Avatar loading /> : null}
                {i === 0 && showCheckboxSkeleton ? (
                  <Skeleton height={17} width={17} style={{ marginLeft: "30px" }} className="mt-3" />
                ) : (
                  <Skeleton height={21} width={90} className="ml-1 mt-3" />
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
