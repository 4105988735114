import React, { Children, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { inline } from "substyle";
import { defaultStyle } from "./utils";

import { getSuggestionHtmlId } from "./utils";
import Suggestion from "./Suggestion";
import LoadingIndicator from "./LoadingIndicator";

const SuggestionsOverlay = React.forwardRef((props, ref) => {
  const {
    id,
    suggestions = {},
    a11ySuggestionsListLabel,
    focusIndex,
    position,
    left,
    right,
    top,
    scrollFocusedIntoView,
    isLoading,
    isOpened,
    onSelect = () => null,
    ignoreAccents,
    containerRef,
    children,
    style,
    customSuggestionsContainer,
    onMouseDown,
    onMouseEnter,
    onReachBottom,
    setListRef,
    loading,
  } = props;
  const [ulElement, setUlElement] = useState();
  let overlayRef = useRef(null);
  let ulRef = useRef(null);

  useEffect(() => {
    if (!ulElement || ulElement.offsetHeight >= ulElement.scrollHeight || !scrollFocusedIntoView) {
      return;
    }
    const scrollTop = ulElement.scrollTop;

    let { top, bottom } = ulElement.children[focusIndex].getBoundingClientRect();
    const { top: topContainer } = ulElement.getBoundingClientRect();
    top = top - topContainer + scrollTop;
    bottom = bottom - topContainer + scrollTop;

    if (top < scrollTop) {
      ulElement.scrollTop = top;
    } else if (bottom > ulElement.offsetHeight) {
      ulElement.scrollTop = bottom - ulElement.offsetHeight;
    }
  }, []);

  const renderSuggestions = () => {
    const suggestionsToRender = Object.values(suggestions).reduce(
      (accResults, { results, queryInfo }) => [
        ...accResults,
        ...results.map((result, index) => renderSuggestion(result, queryInfo, accResults.length + index)),
      ],
      []
    );

    if (customSuggestionsContainer) return customSuggestionsContainer(suggestionsToRender);
    else return suggestionsToRender;
  };

  const renderSuggestion = (result, queryInfo, index) => {
    const isFocused = index === focusIndex;
    const { childIndex, query } = queryInfo;
    const { renderSuggestion } = Children.toArray(children)[childIndex].props;
    let suggestionStyle = { ...style("item"), padding: "5px" };
    if (isFocused) {
      suggestionStyle = {
        ...suggestionStyle,
        backgroundColor: "#E3E3E3",
      };
    }

    return (
      <Suggestion
        style={suggestionStyle}
        key={`${childIndex}-${getID(result)}`}
        id={getSuggestionHtmlId(id, index)}
        query={query}
        index={index}
        ignoreAccents={ignoreAccents}
        renderSuggestion={renderSuggestion}
        suggestion={result}
        focused={isFocused}
        onClick={() => select(result, queryInfo)}
        onMouseEnter={() => handleMouseEnter(index)}
      />
    );
  };

  const renderLoadingIndicator = () => {
    return null;
    if (!loading) {
      return;
    }
    return <LoadingIndicator style={style("loadingIndicator")} />;
  };

  const handleMouseEnter = (index, ev) => {
    if (onMouseEnter) {
      onMouseEnter(index);
    }
  };

  const select = (suggestion, queryInfo) => {
    onSelect(suggestion, queryInfo);
  };

  const getID = suggestion => {
    if (typeof suggestion === "string") {
      return suggestion;
    }
    return suggestion.id;
  };

  if (!isOpened) {
    return null;
  }

  const logRef = ref => {
    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    console.log({
      scrollTop,
      scrollHeight,
      clientHeight,
    });
  };

  const onScroll = () => {
    if (ulRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = ulRef.current;
      if (scrollTop + clientHeight + 20 >= scrollHeight) {
        onReachBottom();
      }
    }
  };

  return (
    <div
      {...inline(
        { position: position || "absolute", left, right, top },
        {
          zIndex: 11,
          backgroundColor: "white",
          marginTop: 14,
          minWidth: 100,
          listStyleType: "none",
        }
      )}
      onMouseDown={onMouseDown}
      ref={el => {
        containerRef(el);
        overlayRef.current = el;
      }}
      onScroll={onScroll}
    >
      <div
        ref={el => {
          setUlElement(el);
          ulRef.current = el;
          setListRef(el);
        }}
        id={id}
        role="listbox"
        aria-label={a11ySuggestionsListLabel}
        {...style("list")}
        style={{
          boxShadow: "0px 0px 10px #00000024",
          borderRadius: "4px",
          scrollbarWidth: "thin",
          overflow: "scroll",
          width: "260px",
          maxHeight: "280px",
        }}
      >
        {renderSuggestions()}
        {renderLoadingIndicator()}
      </div>
    </div>
  );
});

SuggestionsOverlay.propTypes = {
  id: PropTypes.string.isRequired,
  suggestions: PropTypes.object.isRequired,
  a11ySuggestionsListLabel: PropTypes.string,
  focusIndex: PropTypes.number,
  position: PropTypes.string,
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
  scrollFocusedIntoView: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpened: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  ignoreAccents: PropTypes.bool,
  customSuggestionsContainer: PropTypes.func,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: typeof Element === "undefined" ? PropTypes.any : PropTypes.instanceOf(Element),
    }),
  ]),
};

const styled = defaultStyle({
  zIndex: 11,
  backgroundColor: "white",
  marginTop: 14,
  minWidth: 100,

  list: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0.5px solid slategrey",
    },
  },
});

//export default styled(SuggestionsOverlay);
export default SuggestionsOverlay;
