import { getCurrency } from "../../helpers/Util";
import React from "react";

const WarningMessage = props => {
  const { changedValues, country } = props;
  const formatKey = key => {
    let formattedKey = key
      .replace(/_/g, " ")
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    formattedKey = formattedKey.replace(/^Car Dynamic /i, "");
    formattedKey = formattedKey.replace(/\[0\]/g, "");
    return formattedKey;
  };
  const formatValue = (key, value, country) => {
    if (key === "demandPrice") {
      return getCurrency(country, value);
    }
    return value;
  };
  return (
    <>
      The following fields have been updated on the inventory which will also change the respective fields in the Ad
      Post. Are you sure you want to save the changes?
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px", marginBottom: "20px" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "30px", fontWeight: "bold" }}>
          <div style={{ flex: 1.5 }}>Field</div>
          <div style={{ flex: 1 }}>New Value</div>
          <div style={{ flex: 1 }}>Previous Value</div>
        </div>
        {Object.entries(changedValues).map(([key, { currentValue, prevValue }]) => (
          <div key={key} style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
            <div style={{ flex: 1.5 }}>{formatKey(key)}</div>
            <div style={{ flex: 1 }}>{formatValue(key, currentValue, country) ?? "N/A"}</div>
            <div style={{ flex: 1 }}>{formatValue(key, prevValue, country) ?? "N/A"}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default WarningMessage;
