import React, { Fragment, useEffect, useRef, useState } from "react";
import { Breadcrumb, ButtonLoading, Toast } from "../../components/common";
import { strings } from "../../constant/strings";
import { fetchBookingExpenses, updateBookingExpenses } from "../../helpers/ApiHelper";
import { Formik } from "formik";
import { getErrorString, clone, isEmptyArray } from "../../helpers/Util";
import { useSelector } from "react-redux";
import { selectTenant } from "../../reducers/general/selectors";
import * as yup from "yup";
import {
  ImageAdditionalCharges,
  ImageDeliveryCharges,
  ImageFixedCharges,
} from "../../components/common/EmptyState/EmptyStateSvgs";
import ServiceTypeCharges from "../../components/cars/ServiceTypeCharges";

const serviceTypesMetaData = [
  {
    serviceType: "fixed",
    hasTitle: true,
    hasCityFrom: false,
    hasCityTo: false,
    header: "Fixed Charges",
    Icon: ImageFixedCharges,
  },
  {
    serviceType: "additional",
    hasTitle: true,
    hasCityFrom: false,
    hasCityTo: false,
    header: "Additional Charges",
    Icon: ImageAdditionalCharges,
  },
  {
    serviceType: "pick_up_delivery",
    hasTitle: false,
    hasCityFrom: true,
    hasCityTo: false,
    header: "Pick Up Delivery Charges",
    Icon: ImageDeliveryCharges,
  },
  {
    serviceType: "door_step_delivery",
    hasTitle: false,
    hasCityFrom: true,
    hasCityTo: true,
    header: "Door Step Delivery Charges",
    Icon: ImageDeliveryCharges,
  },
];

const getServiceSchema = serviceType => {
  let metaData = serviceTypesMetaData.find(serviceTypeMetaData => serviceTypeMetaData.serviceType === serviceType);
  let schemaObj = {
    ...(metaData?.hasTitle && {
      name: yup.string().required(strings.enter_name_validation),
    }),
    price: yup
      .number()
      .typeError(strings.invalid_input)
      .nullable()
      .required(strings.enter_price_message)
      .min(0, "Price cannot be less than 0"),
    ...(metaData?.hasCityFrom && {
      city_from: yup.object().nullable().required(strings.pickup_location_msg),
    }),
    ...(metaData?.hasCityTo && {
      city_to: yup.object().nullable().required(strings.dropoff_location_msg),
    }),
  };
  return yup.object().shape({ ...schemaObj });
};

const filterBookingServices = (bookingServices, type) =>
  bookingServices.filter(bookingService => bookingService.service_type === type);

const getServiceChargePrices = response => {
  let fixedChargePrices = filterBookingServices(response?.booking_services, "fixed");
  let additionalChargePrices = filterBookingServices(response?.booking_services, "additional");
  let pickUpChargePrices = filterBookingServices(response?.booking_services, "pick_up_delivery").map(service => ({
    ...service,
    city_to_id: service?.city_to?.id ?? null,
  }));
  let doorStepChargePrices = filterBookingServices(response?.booking_services, "door_step_delivery").map(service => ({
    ...service,
    city_from_id: service?.city_from?.id ?? null,
    city_to_id: service?.city_to?.id ?? null,
  }));

  return [...fixedChargePrices, ...additionalChargePrices, ...pickUpChargePrices, ...doorStepChargePrices];
};

const formatServicesIntoFormikValues = previousServicesSnapshot => {
  return previousServicesSnapshot.reduce((acc, curr) => {
    let formikKey = `${curr.service_type}-${curr.id}`;
    return {
      ...acc,
      [formikKey]: { ...curr },
    };
  }, {});
};

const formatServicesIntoFormikValidations = previousServicesSnapshot => {
  return previousServicesSnapshot.reduce((acc, curr) => {
    let formikKey = `${curr?.service_type}-${curr.id}`;
    return {
      ...acc,
      [formikKey]: getServiceSchema(curr?.service_type),
    };
  }, {});
};

const INITIAL_VALUES = {};

const AuctionBookingExpenses = () => {
  const [schema, setSchema] = useState({});
  let formikRef = useRef();
  const tenant = useSelector(selectTenant);
  const tenantId = tenant?.id;
  const [submitLoading, setSubmitLoading] = useState(false);
  const appendSchema = (formikKey, serviceType) => {
    setSchema(prev => ({ ...prev, [formikKey]: getServiceSchema(serviceType) }));
  };

  const removeSchema = formikKey => {
    setSchema(prev => {
      let newSchema = clone({ ...prev });
      delete newSchema[formikKey];
      return { ...newSchema };
    });
  };

  const getBookingExpenses = async () => {
    const response = await fetchBookingExpenses(tenantId);
    if (response.success) {
      if (formikRef.current) {
        let previousServicesSnapshot = clone(getServiceChargePrices(response));
        let savedServicesValues = formatServicesIntoFormikValues(previousServicesSnapshot);
        let savedServicesValidations = formatServicesIntoFormikValidations(previousServicesSnapshot);
        // Used for diffing later when submitting
        formikRef.current.setFieldValue("previousServicesSnapshot", [...previousServicesSnapshot]);
        formikRef.current.setValues(value => ({ ...value, ...savedServicesValues }));
        setSchema({ ...savedServicesValidations });
      }
    }
  };

  useEffect(() => {
    getBookingExpenses();
  }, []);

  const onSubmit = async (values, { setStatus }) => {
    setStatus({ message: "" });
    setSubmitLoading(true);

    let { previousServicesSnapshot, ...rest } = values;
    let serviceEntries = Object.entries(rest);
    let bookingServicesAttributes = serviceEntries.map(([_, payload]) => {
      const { isNewService, formikKey, ...rest } = payload;
      let name = rest.name;
      if (typeof name !== "string") {
        name = `${name.cityFrom} -> ${name.cityTo}`;
      }
      return { ...rest, name };
    });

    let newBookingServicesAttributes = bookingServicesAttributes.filter(bookingService => !bookingService.id);
    let savedBookingServicesAttributes = bookingServicesAttributes
      .filter(bookingService => !!bookingService.id)
      .sort((a, b) => a.id - b.id);
    let previousBookingServicesAttributes = [...previousServicesSnapshot].sort((a, b) => a.id - b.id);
    let updatedBookingServicesAttributes = [];
    for (let i = 0; i < savedBookingServicesAttributes.length; i++) {
      let [savedBookingService, previousBookingService] = [
        savedBookingServicesAttributes[i],
        previousBookingServicesAttributes[i],
      ];

      let serviceProperties = Object.keys(savedBookingService);
      if (savedBookingService._destroy === true) {
        let { city_from = null, city_to = null, ...trimmedBookingServiceAttributes } = savedBookingService;
        updatedBookingServicesAttributes.push(trimmedBookingServiceAttributes);
      } else if (
        !serviceProperties.every(property => previousBookingService[property] === savedBookingService[property])
      ) {
        let { city_from = null, city_to = null, ...trimmedBookingServiceAttributes } = savedBookingService;
        updatedBookingServicesAttributes.push(trimmedBookingServiceAttributes);
      }
    }

    let differentiatedAttributes = [...newBookingServicesAttributes, ...updatedBookingServicesAttributes];

    const bookingData = {
      tenant: {
        booking_services_attributes: differentiatedAttributes,
      },
    };

    if (isEmptyArray(differentiatedAttributes)) {
      Toast.error("Please Add/Update Booking Service");
    } else {
      const response = await updateBookingExpenses(tenantId, bookingData);
      if (response.success) {
        setSubmitLoading(false);
        Toast.success("Booking Expenses Updated Successfully");
        if (formikRef.current) {
          let previousServicesSnapshot = clone(getServiceChargePrices(response));
          let savedServicesValues = formatServicesIntoFormikValues(previousServicesSnapshot);
          let savedServicesValidations = formatServicesIntoFormikValidations(previousServicesSnapshot);
          // formikRef.current.setFieldValue("previousSnapshot", [...previousSnapshot]);
          formikRef.current.setValues(value => ({
            previousServicesSnapshot: [...previousServicesSnapshot],
            ...savedServicesValues,
          }));
          setSchema({ ...savedServicesValidations });
        }
      } else {
        Toast.error(getErrorString(response));
      }
    }
    setSubmitLoading(false);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div>
          <Formik
            innerRef={formikRef}
            initialValues={INITIAL_VALUES}
            validationSchema={yup.object().shape({ ...schema })}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {formikProps => {
              const { handleSubmit } = formikProps;
              return (
                <form className="form theme-form">
                  <Breadcrumb
                    title={strings.booking_expenses}
                    rightView={() => (
                      <ButtonLoading
                        type="submit"
                        onClick={handleSubmit}
                        disabled={submitLoading}
                        loading={submitLoading}
                      >
                        {strings.save}
                      </ButtonLoading>
                    )}
                  />
                  {serviceTypesMetaData.map(metaData => {
                    return (
                      <ServiceTypeCharges
                        key={metaData.serviceType}
                        metaData={metaData}
                        formikProps={formikProps}
                        appendSchema={appendSchema}
                        removeSchema={removeSchema}
                      />
                    );
                  })}
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Fragment>
  );
};

export default AuctionBookingExpenses;
