import React, { useEffect, useState } from "react";
import QCSummary from "./QCSummary";
import { useParams, useHistory } from "react-router-dom";
import QCForm from "./QCForm";
import { QC_STATUS } from "constant/appConstants";
import useMountEffect from "../../../hooks/useMountEffect";
import { fetchCallRecordings, fetchQCReportAnswers } from "../../../helpers/ApiHelper";
import { getQCSummaryData, viewQCDetailPermission } from "./QCHelpers";
import { IF } from "../../../components/common";
import { QualityAssuranceSkeleton } from "./QCSkeletons";

export const CallQC = () => {
  const params = useParams();
  const history = useHistory();
  const isViewOrEditPage = history?.location?.state?.isViewOrEditPage;

  const [qcCallData, setQcCallData] = useState({
    loading: false,
    qcCallDetail: null,
  });

  const [qcSummaryData, setQCSummaryData] = useState({
    loading: false,
    qcSectionWiseRating: [],
    qcOverallRatingScore: "",
  });

  const { id: callId = "", templateId = "" } = params;

  const { loading, qcCallDetail } = qcCallData;
  const callRecordingDetails = qcCallDetail ?? null;
  const report = callRecordingDetails?.report ?? null;
  const viewOrEditQC = callRecordingDetails?.recording_qc_status === QC_STATUS.EVALUATION_DONE ?? false;

  // Permission
  const hasQCDetailViewingPermission = viewQCDetailPermission();

  const handleQCSummaryData = async () => {
    try {
      setQCSummaryData(prev => ({
        ...prev,
        loading: true,
      }));

      const response = await fetchQCReportAnswers(report.id);
      const { evaluation_report } = response;
      const { score, sectionWiseRating } = getQCSummaryData(evaluation_report);

      setQCSummaryData(prev => ({
        ...prev,
        qcOverallRatingScore: score,
        qcSectionWiseRating: sectionWiseRating,
      }));
    } catch (error) {
    } finally {
      setQCSummaryData(prev => ({
        ...prev,
        loading: false,
      }));
    }
  };

  useMountEffect(() => {
    const getRecordingDetailById = async id => {
      setQcCallData(prev => ({
        ...prev,
        loading: true,
      }));
      const { success, recordings } = await fetchCallRecordings(`?f[id][]=${id}`);
      if (success) {
        setQcCallData(prev => ({
          ...prev,
          loading: false,
          qcCallDetail: recordings[0],
        }));
      } else {
        setQcCallData(prev => ({
          ...prev,
          loading: false,
        }));
      }
    };
    getRecordingDetailById(callId);
  });

  useEffect(() => {
    viewOrEditQC && handleQCSummaryData();
  }, [viewOrEditQC]);

  if (loading) return <QualityAssuranceSkeleton showSummaryDataSkeleton={isViewOrEditPage} />;

  return (
    !!callRecordingDetails && (
      <IF condition={hasQCDetailViewingPermission}>
        <div className="container-fluid" style={{ marginTop: 100 }}>
          <QCSummary
            callRecordingDetails={callRecordingDetails}
            qcSummaryData={qcSummaryData}
            showCallInfoStats={viewOrEditQC}
          />
          <QCForm
            setQCSummaryData={setQCSummaryData}
            viewOrEditQC={viewOrEditQC}
            callId={callId}
            templateId={templateId}
            report={report}
            setQcCallData={setQcCallData}
          />
        </div>
      </IF>
    )
  );
};
