import { MODELS_FOR_VIEW_PHONE_NUMBER_API, PHONE_NUMBER_CATEGORIZATION_PER_PAGE } from "constant/appConstants";
import { Formik } from "formik";
import { fetchUserOptions } from "helpers/userHelper";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { strings } from "../../constant/strings";
import { fetchGroupsApi, updateAuctionUserApi } from "../../helpers/ApiHelper";
import { checkUndefinedApiParams, getErrorString, makeListToSelectData, selectAllGroupField } from "../../helpers/Util";
import { AsyncSelect, CustomAlert, FormInput, FormSelect } from "../common";
import FormInputPhoneNumbers from "../common/FormInputPhoneNumbers/FormInputPhoneNumbers";
import FormRow from "../common/FormRow";
import { PaginatedSearch } from "../common/PaginatedSearch";

const AddUserForm = forwardRef(
  (
    {
      cities,
      // addUser,
      closeDialog,
      asUpdate,
      updateData,
      updateAuctionUser,
      toggleAddAuctionUserLoading,
      groups,
      groupsFetchLoading,
      groupsFetchError,
      fromAuctionUserDetail,
    },
    ref
  ) => {
    let formikRef = useRef();
    const [groupOptions, setGroupOptions] = useState([]);
    const [addPhoneNumbersInAPIPayload, setAddPhoneNumbersInAPIPayload] = useState(false);
    const phoneRegex = useSelector(content => content.Configs.tenant.configuration.phone_regex);
    useImperativeHandle(ref, () => ({
      submitForm() {
        formikRef.current.submitForm();
      },
    }));

    const validationSchema = () =>
      yup.object().shape(
        {
          city: yup.string().required(strings.select_city),
          groups: yup.array().of(
            yup
              .object()
              .required(strings.select_group_message)
              .test("group", strings.select_valid_group_message, value => value && value.name)
          ),
          // activeInOffers: yup.boolean(),
          // is_verified: yup.boolean(),
          firstName: yup.string().required(strings.enter_name).nullable(),
          phone: yup
            .string()
            .test("phone-validation", strings.valid_phone, function (value) {
              if (value) {
                if (value?.includes("*")) return true;
                const regex = new RegExp(phoneRegex);
                return regex.test(value);
              }
              return true;
            })
            .nullable(),
        },
        [["phone", "phone"]]
      );
    const getInitialValues = () =>
      asUpdate
        ? {
            city: updateData?.auction_user_profile?.city?.id,
            groups: updateData?.groups,
            // activeInOffers: updateData?.is_active,
            // is_verified: updateData?.auction_user_profile?.is_verified,
            firstName: updateData?.name,
            phone: updateData?.phone,
            dre_assignee: updateData?.assignee,
          }
        : {
            city: "",
            groups: [],
            // activeInOffers: false,
            // is_verified: false,
            firstName: "",
            phone: "",
            dre_assignee: null,
          };

    const onSubmit = async (values, { setStatus }) => {
      if (!checkUndefinedApiParams(updateData?.id, "update data")) return;
      setStatus({ message: "" });
      let auctionUserData = {
        user: {
          ...selectAllGroupField(values.groups),
          is_active: values?.activeInOffers,
          first_name: values?.firstName,
          auction_user_profile_attributes: {
            id: updateData?.auction_user_profile?.id,
            city_id: values.city,
            // is_verified: values.is_verified,
          },
          assignee_id: values?.dre_assignee?.id,
        },
      };

      if (!values?.phone.includes("*"))
        auctionUserData = {
          ...auctionUserData,
          user: { ...auctionUserData.user, phone: values?.phone ? values?.phone : "" },
        };

      toggleAddAuctionUserLoading(true);
      if (asUpdate) {
        const response = await updateAuctionUserApi(updateData.id, auctionUserData);
        if (response) {
          toggleAddAuctionUserLoading(false);
          if (response?.user?.id) {
            updateAuctionUser(response.user);
            closeDialog();
          } else {
            setStatus({
              message: getErrorString(response),
            });
          }
        }
      } else {
        // const response = await addUserApi(userData);
        // if (response) {
        //   toggleAddUserLoading(false);
        //   if (response?.user?.id) {
        //     addUser(response.user);
        //     fetchUsers();
        //     closeDialog();
        //   } else {
        //     setStatus({
        //       message: getErrorString(response),
        //     });
        //   }
        // }
      }
    };

    return (
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, status, setFieldValue }) => (
          <form className="form theme-form">
            {cities && cities.length > 0 && (
              <FormSelect
                label={strings.city}
                placeholder={strings.city_ph}
                options={makeListToSelectData(cities)}
                name="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMsg={!!errors.city && !!touched.city && errors.city}
                setSlugAsValue
                required
              />
            )}

            <FormInput
              label={strings.name}
              placeholder={strings.name_ph}
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              errorMsg={errors.firstName && touched.firstName && errors.firstName}
              required
            />
            {!!updateData?.phone ? (
              <FormInputPhoneNumbers
                phoneData={{
                  uuid: updateData?.uuid,
                  associatedId: updateData?.id,
                  associatedType: MODELS_FOR_VIEW_PHONE_NUMBER_API.AUCTION_MODEL,
                  component: PHONE_NUMBER_CATEGORIZATION_PER_PAGE.UPDATE_BIDDER_DETAIL,
                  isUserTypeNotClient: true,
                  successCallback: data => {
                    setAddPhoneNumbersInAPIPayload(true);
                    // eslint-disable-next-line
                    formikRef?.current?.setFieldValue && formikRef?.current?.setFieldValue("phone", data?.phone);
                  },
                }}
                // hasSuccessfullyFetchedTheData={addPhoneNumbersInAPIPayload}
                isEditLeadPage={true}
                showPhoneNumberCTA={!addPhoneNumbersInAPIPayload} //show CTA until data is not fetched, in some other cases showPhoneNumberCTA depends on other cases as well
                ctaText={"Edit Phone Number"}
                formInputProps={{
                  label: strings.phone,
                  placeholder: "Enter Phone",
                  onChange: handleChange("phone"),
                  onBlur: handleBlur("phone"),
                  value: values?.phone || "",
                  required: true,
                  errorMsg: errors.phone && touched.phone && errors.phone,
                  isEditable: true,
                  horizontal: false,
                  textRight: false,
                }}
              />
            ) : (
              <FormInput
                label={strings.phone}
                placeholder={strings.phone}
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                errorMsg={errors.phone && touched.phone && errors.phone}
                required={false}
              />
            )}

            <FormRow textRight label={strings.groups} columnLeftSection={3} columnRightSection={9}>
              <AsyncSelect
                isClearable={true}
                listApi={fetchGroupsApi}
                listProperty={"groups"}
                isFitler
                isMulti={true}
                placeholder={strings.groups}
                getDataInParent={data => {
                  setGroupOptions(data);
                }}
                prependOptions={[
                  {
                    name: "Select all Groups",
                    id: "select_all",
                    slug: "select_all",
                  },
                ]}
                errorMsg={!!errors.groups && !!touched.groups && errors.groups}
                selected={values.groups ? makeListToSelectData([...values.groups]) : []}
                onChange={selected => {
                  setFieldValue("groups", selected);
                }}
              />
            </FormRow>

            <FormRow textRight label={strings.assign_dre} columnLeftSection={3} columnRightSection={9}>
              <PaginatedSearch
                infoView
                textRight
                showLabel={false}
                toggleClassName="form-control text-truncate"
                onChange={selectedUser => setFieldValue("dre_assignee", selectedUser)}
                isSingleSelect
                defaultSelected={values.dre_assignee}
                showSearchIcon={false}
                horizontal
                fetchOptions={(input, page) =>
                  fetchUserOptions({ "s[name]": input, page, "f[roles.slug]": "auction-dre" })
                }
                onClear={() => setFieldValue("dre_assignee", null)}
                direction={"up"}
              />
            </FormRow>

            {/*<div className="row mx-1 justify-content-between">*/}
            {/*  <CheckboxComponent*/}
            {/*    label={strings.verified}*/}
            {/*    checked={values.is_verified}*/}
            {/*    onChange={e => setFieldValue("is_verified", e.target.checked)}*/}
            {/*  />*/}
            {/*  <CheckboxComponent*/}
            {/*    label={strings.active}*/}
            {/*    checked={values.activeInOffers}*/}
            {/*    onChange={e => setFieldValue("activeInOffers", e.target.checked)}*/}
            {/*    errorMsg={!!errors.activeInOffers && !!touched.activeInOffers && errors.activeInOffers}*/}
            {/*  />*/}
            {/*</div>*/}
            {status && status.message && <CustomAlert message={status.message} />}
          </form>
        )}
      </Formik>
    );
  }
);

export default AddUserForm;
