import React from "react";
import QCHeaderSkeleton from "./QCHeaderSkeleton";
import QCFormSkeleton from "./QCFormSkeleton";
import QCQualityRatingSkeleton from "./QCQualityRatingSkeleton";
import QCQualityAnalysisSkeleton from "./QCQualityAnalysisSkeleton";
import { IF } from "../../../../components/common";

const QualityAssuranceSkeleton = ({ showSummaryDataSkeleton = false }) => {
  return (
    <>
      <div className="container-fluid" style={{ marginTop: 100 }}>
        <div>
          <section>
            <QCHeaderSkeleton />
          </section>
          <div>
            <IF condition={showSummaryDataSkeleton}>
              <div className="grid p-relative" style={{ "--template": "minmax(0, 1fr) 40%", marginTop: 25 }}>
                <QCQualityRatingSkeleton />
                <QCQualityAnalysisSkeleton />
              </div>
            </IF>
          </div>
        </div>
        <div>
          <QCFormSkeleton />
        </div>
      </div>
    </>
  );
};

export default QualityAssuranceSkeleton;
