import React from "react";
import { ImageNoRecordFound } from "./EmptyStateSvgs";

const EmptyState = props => {
  const { image, title, message, button, minHeight, maxWidth, style, titleStyle = {}, ...rest } = props;
  return (
    <div
      className="emptyState"
      style={{
        "--min-height": minHeight ? `${minHeight}px` : "auto",
        "--max-width": maxWidth ? `${maxWidth}px` : "auto",
        ...style,
      }}
      {...rest}
    >
      <div className="emptyStateImg">{image ? image : <ImageNoRecordFound />}</div>
      <h5 className="m-t-20 m-b-16" style={titleStyle}>
        {title ? title : "No records found!"}
      </h5>
      {message && <p>{message}</p>}
      {button && <div>{button}</div>}
    </div>
  );
};

export default EmptyState;
