import React from "react";
import { IconNoUploadsAvailable } from "../../components/svg-icon/svg-icons";
import { strings } from "../../constant/strings";
import { Avatar, Skeleton } from "../../components/common";
import FileUploadItemWithLabel from "../../components/crm-leads/UploadAttachmentsWithLabels/FileUploadItemWithLabel";
import { getPermission, isFieldRestricted } from "../../helpers/Util";
import { AUC_AUCTION_USER_DETAIL_PATH } from "../../constant/appPaths";
import { useHistory } from "react-router-dom";
import RenderPrivateField from "../../components/common/RenderPrivateField/RenderPrivateField";
import { MODELS_FOR_VIEW_PHONE_NUMBER_API, PHONE_NUMBER_CATEGORIZATION_PER_PAGE } from "constant/appConstants";

const RenderBidderInfo = props => {
  const history = useHistory();
  const { bidderInfo, loading, bookingID = null } = props;

  const handleClick = () => {
    if (getPermission("AuctionUser", "read")) {
      history.push(AUC_AUCTION_USER_DETAIL_PATH.replace(":id", bidderInfo.id));
    }
  };
  return (
    <>
      {loading ? (
        <InfoSkeleton />
      ) : (
        <>
          <h5 className="card-subheading">{strings.bidder_info}</h5>
          <div onClick={handleClick} className="d-flex spbwx16 hover-pointer align-items-center">
            {!isFieldRestricted(bidderInfo?.name) && <Avatar size={54} name={bidderInfo?.name} avatarOnly round />}
            <div style={{ flex: 1, minWidth: 0 }}>
              {bidderInfo?.name && !isFieldRestricted(bidderInfo?.name) && (
                <div className="f-w-500">{bidderInfo?.name}</div>
              )}
              {bidderInfo?.phone && !isFieldRestricted(bidderInfo?.phone) && (
                <div
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <RenderPrivateField
                    associatedId={bookingID}
                    uuid={bidderInfo?.uuid}
                    associatedType={MODELS_FOR_VIEW_PHONE_NUMBER_API.AUCTION_MODEL}
                    component={PHONE_NUMBER_CATEGORIZATION_PER_PAGE.AUCTION.BOOKING_EXPENSES}
                    isUserTypeNotClient={true}
                  />
                </div>
              )}
              {bidderInfo?.email && !isFieldRestricted(bidderInfo?.email) && (
                <div className="text-truncate" title={bidderInfo?.email}>
                  {bidderInfo?.email}
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <hr />
    </>
  );
};

const BookingRelatedDetails = ({ auctionBookingDetail, loading }) => {
  const { payment_proof, documents } = auctionBookingDetail;
  return (
    <>
      <div className={"p-b-10 m-b-10 related-details-heading"}>
        <h5>{strings.related_details}</h5>
      </div>
      <RenderBidderInfo
        bidderInfo={auctionBookingDetail?.bidder}
        loading={loading}
        bookingID={auctionBookingDetail?.id}
      />
      <div>
        <h5 className="card-subheading">{strings.proof_of_payment}</h5>
        {payment_proof && !isFieldRestricted(payment_proof) ? (
          <FileUploadItemWithLabel item={payment_proof} canRemoveFile={false} canEditLabel={false} />
        ) : (
          <div className={"d-flex flex-column justify-content-center align-items-center mt-5"}>
            <IconNoUploadsAvailable />
            <span className={"mt-3 no-upload-text"}>No Uploads Available</span>
          </div>
        )}
      </div>
      <div>
        <h5 className="card-subheading mt-2">{strings.attachments}</h5>
        {documents &&
          !isFieldRestricted(documents) &&
          documents.map(item => {
            return (
              <FileUploadItemWithLabel
                item={item}
                key={item.id}
                canRemoveFile={false}
                canEditLabel={false}
                label={item.label}
              />
            );
          })}
      </div>
    </>
  );
};

const InfoSkeleton = () => (
  <div className="card flex-grow-1">
    <div className="mx-4 mt-4">
      <Skeleton height={15} width={90} />
      <div className="d-flex spbwx16 mt-4 mb-4">
        <Avatar loading size={54} />
        <div className="d-flex flex-column">
          <Skeleton width={110} />
          <Skeleton width={180} />
          <Skeleton width={160} />
        </div>
      </div>
    </div>
  </div>
);

export default BookingRelatedDetails;
