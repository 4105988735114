import React, { useEffect, useState } from "react";
import cx from "clsx";
import Sidebar from "react-sidebar";

const SidebarModal = ({
  setSidebarOpen,
  sidebarOpen,
  content,
  className,
  sidebarClassName,
  rootElementClassName,
  sidebarStyles,
  overallStyles,
  rootClassName,
  ...rest
}) => {
  const onSetSidebarOpen = open => setSidebarOpen(open);

  useEffect(() => {
    if (sidebarOpen) {
      document.documentElement.classList.add(rootElementClassName ? rootElementClassName : "overlay-open");
    }
    return () => {
      document.documentElement.classList.remove(rootElementClassName ? rootElementClassName : "overlay-open");
    };
  }, [sidebarOpen]);

  return (
    <Sidebar
      rootClassName={cx("sidebar-overlay", rootClassName)}
      sidebarClassName={sidebarClassName}
      sidebar={content}
      open={sidebarOpen}
      children={<></>}
      onSetOpen={onSetSidebarOpen}
      shadow={false}
      styles={{
        root: {},
        sidebar: {
          position: "fixed",
          zIndex: 1020,
          backgroundColor: "white",
          width: 650,
          ...sidebarStyles,
        },
        content: {},
        overlay: {
          zIndex: 1010,
        },
        dragHandle: {
          zIndex: 1020,
        },
      }}
      pullRight
      {...rest}
    />
  );
};

export default SidebarModal;
