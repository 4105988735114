export const selectActiveColumns = state => state.PermissionsData.activeColumns;
export const selectAssignees = state => state.AssigneesData.assignees;
export const selectAssigneesError = state => state.AssigneesData.error;
export const selectTimeZone = state => state.Configs.tenant.country.timezone;
export const selectPhoneRegex = state => state.Configs.tenant.configuration.phone_regex;
export const selectTenant = state => state.Configs.tenant;
export const selectConfig = state => state.Configs;
export const selectRestrictedModules = state => state.Configs.tenant.restricted_modules;
export const selectCustomState = state => state.CustomReducer.payload;
export const selectAssigneesInitialRequestCalled = state => state.AssigneesData.initialRequestCalled;
export const selectCRMLeadsData = state => state.CRMLeadsData;
export const selectCicosData = state => state.CrmCicoData;
export const selectQuickLinksData = state => state.QuickLinksData;
