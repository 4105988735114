import React from "react";
import Skeleton from "../../../Skeleton";

const ChevronLeadCategoriesLoader = () => {
  return (
    <div className="mb-4 d-flex flex-direction-row">
      {[1, 2, 3, 4].map(item => (
        <div key={item} className="mr-3">
          <Skeleton width={150} height={40} style={{ borderRadius: "25px" }} />
        </div>
      ))}
    </div>
  );
};

export default ChevronLeadCategoriesLoader;
