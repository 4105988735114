import cx from "clsx";
import React, { useEffect, useState } from "react";
import { Collapse, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { columnsMap } from "../../constant/columnsMapStrings";
import { APPROVAL_STATUSES, AUCTION_USERS } from "constant/appConstants";
import { strings } from "../../constant/strings";
import {
  capitalize,
  clone,
  formatAmountToAED,
  getPermission,
  getTableColumns,
  getTimeDateString,
  isFieldRestricted,
} from "../../helpers/Util";
import { Actions, Button, ButtonLoading, DataTableComponent, IF, Permissions, Status, Tooltip } from "../common";
import { FaCaretDown, IconTrophy, MdInfoOutline } from "../svg-icon/svg-icons";
import AcceptOfferModal from "./AcceptOfferModal";
import CounterOfferModal from "./CounterOfferModal.tsx";
import MarkAsWinnerModal from "./MarkAsWinnerModal";
import styles from "./OfferTrials/OfferTrails.module.scss";
import RejectNegotiationModal from "./RejectNegotiationModal";
import ViewNegotiationModal from "./ViewNegotiationModal";
import {
  doesAuctionUserHasHODRole,
  getClientInfo,
  isAuctionUserRevisingOffer,
  isNegotiationStatusBuying,
  isNegotiationStatusSelling,
  sortNegotiationsByUpdatedTime,
} from "./OfferTrials/OfferTrialsHelpers";
import { AdjustMarginModal } from "./AdjustMarginModal";
import { useSelector } from "react-redux";
import ExtendTimeNegotiationModal from "./ExtendTimeNegotiationModal";
import { Clock } from "react-feather";
import MarkWinnerAsLostModal from "./MarkWinnerAsLostModal/MarkWinnerAsLostModal";

const AuctionNegotiationTable = ({
  index,
  statuses,
  title,
  data = {},
  containsRealTime,
  offerId,
  offersObject,
  markAsWinnerRef,
  fetchOffers,
  latestAuctionId,
  setLoadingWinner,
  loadingWinner,
  user = null,
  negotiationsPermittedColumns = [],
}) => {
  const [open, setOpen] = useState(!!data?.isCurrentNegotiation);
  const [showAcceptModal, setShowAcceptModal] = useState(null);
  const [showCounterModal, setShowCounterModal] = useState(null);
  const [showExtendTimeModal, setShowExtendTimeModal] = useState(null);
  const [showViewNegotiation, setShowViewNegotiation] = useState(null);
  const [rejectNegotiationModal, setRejectNegotiationModal] = useState(null);
  const [showMarkWinnerDialog, setShowMarkWinnerDialog] = useState(false);
  const [showMarkWinnerLostModal, setShowMarkWinnerLostModal] = useState(false);
  const [showAdjustMarginModal, setShowAdjustMarginModal] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popover2Open, setPopover2Open] = useState(false);

  const inventorySellerExpectation = useSelector(getInventorySellerExpectations);
  const timezone = useSelector(content => content.Configs.tenant.country.timezone);
  const auctionUser = getAuctionUsers();

  const { auction_negotiations } = columnsMap;
  const {
    negotiation_offers = [],
    seller_expectation: sellerExpectations,
    isCurrentNegotiation = false,
    status: negotiationStatus = {},
  } = clone(data);

  const sortedNegotiations = sortNegotiationsByUpdatedTime(negotiation_offers);

  useEffect(() => {
    if (!!data?.isCurrentNegotiation) setOpen(true);
  }, []);

  const MarkAsWinner = () => {
    return (
      <Button
        size="sm"
        color={"monochrome"}
        btnText={strings.mark_as_winner}
        onClick={e => {
          setShowMarkWinnerDialog(true);
          e.stopPropagation();
        }}
        icon={<IconTrophy />}
        variant="default"
      />
    );
  };

  const MarkWinnerAsLost = () => {
    return (
      <ButtonLoading
        loading={false}
        icon={<Clock size={16} />}
        color="monochrome"
        size="sm"
        variant=""
        className="border-0"
        onClick={e => {
          setShowMarkWinnerLostModal(true);
          e.stopPropagation();
        }}
      >
        {strings.remove_winner}
      </ButtonLoading>
    );
  };

  const ClientInfo = ({ negotiationOfferStatus, dealer = {}, purchaser = {} }) => {
    const { name, email } = getClientInfo({
      negotiationOfferStatus,
      dealer,
      purchaser,
      negotiationsPermittedColumns,
    });
    return !name && !email ? (
      "-"
    ) : (
      <div style={{ display: "grid", gap: 4 }}>
        <span style={{ fontSize: 14, fontWeight: 500 }}>{name ?? "-"}</span>
        <span style={{ fontSize: 10, color: "#707070" }}>{email ?? "-"}</span>
      </div>
    );
  };
  const getAuctionNegotiationsList = () => {
    let auctionNegotiationsList = [];
    if (negotiation_offers && !!negotiation_offers?.length) {
      auctionNegotiationsList = sortedNegotiations?.map((item, index) => ({
        ID: item.id,
        [strings.user_info]: (
          <>
            <ClientInfo
              negotiationOfferStatus={item?.negotiation_offer_type}
              dealer={data?.started_by}
              purchaser={data?.purchaser}
            />
          </>
        ),
        [strings.type]: capitalize(item?.negotiation_offer_type) || "-",
        [strings.negotiated_offer]: (
          <div onMouseLeave={() => setPopoverOpen(false)}>
            <IF
              condition={item?.adjusted_negotiated_offer}
              ELSE={() => <div>{formatAmountToAED(item?.negotiated_offer)}</div>}
            >
              <div className="d-flex align-items-center">
                <div className="mr-1">{formatAmountToAED(item?.adjusted_negotiated_offer)}</div>
                <div className={cx(styles.hoverContainer)} id={"Popover1"} onMouseEnter={() => setPopoverOpen(true)}>
                  <MdInfoOutline size={16} />
                </div>
                <Popover placement="bottom" isOpen={popoverOpen} target="Popover1">
                  <PopoverHeader>Negotiated Offer</PopoverHeader>
                  <PopoverBody>
                    <div className="d-flex align-items-center justify-content-between">
                      <span style={{ color: "#707070" }}>Previous Offer : </span>
                      <span style={{ fontWeight: 600 }}>{formatAmountToAED(item?.negotiated_offer)}</span>
                    </div>
                  </PopoverBody>
                </Popover>
              </div>
            </IF>
          </div>
        ),
        [strings.negotiated_bid]: formatAmountToAED(item?.negotiated_bid),
        [strings.status]: <Status {...data?.status} />,
        [strings.margin]: (
          <div onMouseLeave={() => setPopover2Open(false)}>
            <IF condition={item?.adjusted_margin} ELSE={() => <div>{formatAmountToAED(item?.margin)}</div>}>
              <div className="d-flex align-items-center">
                <div className="mr-1">{formatAmountToAED(item?.adjusted_margin)}</div>
                <div className={cx(styles.hoverContainer)} id={"Popover2"} onMouseEnter={() => setPopover2Open(true)}>
                  <MdInfoOutline size={16} />
                </div>
                <Popover placement="bottom" isOpen={popover2Open} target="Popover2">
                  <PopoverHeader>Negotiated Margin</PopoverHeader>
                  <PopoverBody>
                    <div className="d-flex align-items-center justify-content-between">
                      <span style={{ color: "#707070" }}> Previous Margin : </span>
                      <span style={{ fontWeight: 600 }}>{` ${formatAmountToAED(item?.margin)}`}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span style={{ color: "#707070" }}> Margin reduced By : </span>
                      <span style={{ fontWeight: 600 }}>
                        {` -${formatAmountToAED(item?.margin - item?.adjusted_margin)}`}
                      </span>
                    </div>
                  </PopoverBody>
                </Popover>
              </div>
            </IF>
          </div>
        ),

        [strings.updated_at]: data?.updated_at ? getTimeDateString(data.updated_at, timezone) : "-",
        [strings.created_at]: data?.created_at ? getTimeDateString(data.created_at, timezone) : "-",

        [strings.actions]:
          index === 0 &&
          data?.isCurrentNegotiation &&
          data?.status?.slug === APPROVAL_STATUSES.PENDING &&
          !data?.winner ? (
            <Actions
              id={item.id}
              actionsList={[
                showAcceptOfferAction(item) && {
                  //   getPermission("Negotiation", "update")
                  //   !isPurchaserOrDre &&
                  //  &&
                  type: "button",
                  iconType: "check",
                  tooltipLabel: strings.accept,
                  onClick: () => {
                    setShowAcceptModal(item?.id);
                  },
                  id: item.id,
                  color: "green",
                },

                showCounterOrReviseOfferAction(item) && {
                  //   getPermission("NegotiationOffer", "create") //  &&
                  type: "button",
                  iconType: "counter-auction",
                  tooltipLabel: counterBtnToolTip(item),
                  onClick: () => {
                    setShowCounterModal(item?.id);
                  },
                  id: item.id,
                },

                showRejectOfferAction(item) && {
                  type: "button",
                  iconType: "cross",
                  tooltipLabel: strings.reject_negotiation,
                  onClick: () => {
                    setRejectNegotiationModal(item?.id);
                  },
                  id: item.id,
                  color: "red",
                },

                showAdjustMarginAction(item) && {
                  type: "button",
                  iconType: "file",
                  tooltipLabel: strings.adjust_margin,
                  onClick: () => {
                    setShowAdjustMarginModal(prev => !prev);
                  },
                  id: item.id,
                },
              ].filter(Boolean)}
            />
          ) : (
            "-"
          ),
      }));
      return auctionNegotiationsList;
    }
  };

  let updatedList = getAuctionNegotiationsList();

  let columnsToRender = getTableColumns(updatedList, negotiationsPermittedColumns, auction_negotiations)?.map(
    column => {
      return {
        ...column,
        name: (
          <>
            <div data-for={`tooltip-${column.id}`} data-tip className={"text-truncate cursor-pointer"}>
              {column.name}
            </div>
            <Tooltip
              className="pointer-events-unset"
              backgroundColor="black"
              place="bottom"
              textColor="white"
              isCapture
              id={`tooltip-${column.id}`}
            >
              {column.name}
            </Tooltip>
          </>
        ),
      };
    }
  );

  function getAuctionUsers() {
    const auctionUser = {
      DRE: null,
      Purchaser: null,
      HOD: null,
      AuctionController: null,
    };

    if (!!data[AUCTION_USERS.DRE] && data[AUCTION_USERS.DRE].id === user.id) {
      auctionUser.DRE = data[AUCTION_USERS.DRE];
    } else if (!!data[AUCTION_USERS.PURCHASER] && data[AUCTION_USERS.PURCHASER].id === user.id) {
      auctionUser.Purchaser = data[AUCTION_USERS.PURCHASER];
    } else if (doesAuctionUserHasHODRole({ roles: user?.roles })) {
      auctionUser.HOD = user;
    }

    // TODO: need to add a condition for handling auction manager

    return auctionUser;
  }

  function getInventorySellerExpectations(state) {
    return state?.CarDetailData?.car?.dynamic_attributes?.car?.seller_expectations;
  }
  function showAcceptOfferAction(negotiationItem) {
    const { negotiation_offer_type, adjusted_negotiated_offer } = negotiationItem;

    if (auctionUser.Purchaser && isNegotiationStatusBuying(negotiation_offer_type)) return true;

    // Offer's Margin has been changed by the HOD, then an adjusted negotiation offer is created
    if (auctionUser.Purchaser && isNegotiationStatusSelling(negotiation_offer_type) && adjusted_negotiated_offer)
      return true;

    if (auctionUser.HOD && isNegotiationStatusBuying(negotiation_offer_type)) return true;

    // commented out actions for DRE Role
    // if (auctionUser.DRE && isNegotiationStatusSelling(negotiation_offer_type)) return true;

    return false;
  }

  function showRejectOfferAction(negotiationItem) {
    const { negotiation_offer_type } = negotiationItem;
    if (auctionUser.HOD) return true;

    return false;
  }

  function showCounterOrReviseOfferAction(negotiationItem) {
    const { negotiation_offer_type } = negotiationItem;
    // Purchaser can revise his previous offer too
    if (
      auctionUser.Purchaser &&
      (isNegotiationStatusBuying(negotiation_offer_type) || isNegotiationStatusSelling(negotiation_offer_type))
    )
      return true;

    if (
      auctionUser.HOD &&
      (isNegotiationStatusBuying(negotiation_offer_type) || isNegotiationStatusSelling(negotiation_offer_type))
    )
      return true;

    // commented out actions for DRE Role
    // if (auctionUser.DRE && isNegotiationStatusSelling(negotiation_offer_type)) return true;

    return false;
  }

  function showAdjustMarginAction(negotiationItem) {
    const { negotiation_offer_type } = negotiationItem;

    if (auctionUser.HOD && isNegotiationStatusSelling(negotiation_offer_type)) return true;

    return false;
  }

  function counterBtnToolTip(negotiationItem) {
    const { negotiation_offer_type } = negotiationItem;

    if (
      isAuctionUserRevisingOffer({
        AuctionUser: auctionUser,
        negotiationStatus: negotiation_offer_type,
      })
    ) {
      return strings.revise_offer;
    }

    return strings.counter_offer;
  }

  const canShowExtendButton =
    data?.auction?.can_extend_negotiation &&
    ![APPROVAL_STATUSES.REJECTED, APPROVAL_STATUSES.REMOVED].includes(data?.status?.slug);

  return (
    <>
      <div>
        <div
          className={cx(
            open && styles.collapseHeader,
            "d-flex align-items-center justify-content-between cursor-pointer"
          )}
          onClick={() => setOpen(!open)}
        >
          <div className={cx("d-flex align-items-center")} style={{ gap: "12px" }}>
            <div className="d-flex align-items-center" style={{ gap: "8px" }}>
              <FaCaretDown style={{ transform: !open ? "rotate(-90deg)" : "rotate(0)" }} />
              <div className={styles.auctionTitle}>Negotiation with {title}</div>
            </div>
            {/* {!auctionDetail?.winner && isCurrentNegotiation && (
              <Status color={"#dc6803"} bg_color={"#fef0c7"} name={"Current"} />
            )} */}
            {!isFieldRestricted(data?.winner) ? (
              <>
                {(!data?.winner || data?.lost) && <Status {...negotiationStatus} />}

                {data?.winner && !data?.lost && <Status color={"#dc6803"} bg_color={"#fef0c7"} name={"Winner"} />}
              </>
            ) : null}
          </div>
          {data?.status?.slug === APPROVAL_STATUSES.ACCEPTED &&
            !data?.winner &&
            getPermission("Auction", "mark_winner") && <MarkAsWinner />}

          <IF
            condition={
              !isFieldRestricted(data?.winner) && !isFieldRestricted(data?.lost) && data?.winner && !data?.lost
            }
          >
            <MarkWinnerAsLost />
          </IF>

          {canShowExtendButton && (
            <ButtonLoading
              loading={false}
              icon={<Clock size={16} />}
              color="monochrome"
              size="sm"
              variant=""
              className="border-0"
              onClick={() => {
                setShowExtendTimeModal(true);
              }}
            >
              {strings.extend_negotiation}
            </ButtonLoading>
          )}
        </div>
        <Collapse className={styles.collapseContent} isOpen={open}>
          <div className="auction__noborder--table">
            <DataTableComponent
              className="sticky-action-cells auction-negotiation"
              tableData={updatedList}
              columns={columnsToRender}
              noHeader
              handlePagination
              // limitPerPage={tenant?.configuration?.records_per_page}
              noUrlPush
              // queryParam={`?q[auction_id_eq]=${auctionCycle || latestAuctionId}&`}
            />
          </div>
        </Collapse>
      </div>

      {showAcceptModal && (
        <AcceptOfferModal
          data={data}
          statuses={statuses}
          showModal={showAcceptModal}
          setShowModal={setShowAcceptModal}
          setShowCounterModal={setShowCounterModal}
          auctionUser={auctionUser}
        />
      )}

      {showCounterModal && (
        <CounterOfferModal
          data={data}
          sellerExpectations={sellerExpectations}
          showModal={showCounterModal}
          setShowModal={setShowCounterModal}
          auctionUser={auctionUser}
          setShowAcceptModal={setShowAcceptModal}
          inventorySellerExpectation={inventorySellerExpectation}
        />
      )}
      {showViewNegotiation && (
        <ViewNegotiationModal
          data={data}
          auctionUser={auctionUser}
          showModal={showViewNegotiation}
          setShowModal={setShowViewNegotiation}
        />
      )}
      {showExtendTimeModal && (
        <ExtendTimeNegotiationModal
          negotiationId={data?.id}
          statuses={statuses}
          showModal={showExtendTimeModal}
          setShowModal={setShowExtendTimeModal}
        />
      )}
      {rejectNegotiationModal && (
        <RejectNegotiationModal
          negotiationId={data?.id}
          statuses={statuses}
          showModal={rejectNegotiationModal}
          setShowModal={setRejectNegotiationModal}
        />
      )}
      {showMarkWinnerDialog && (
        <MarkAsWinnerModal
          data={data}
          offerId={data?.offer?.id}
          setShowMarkWinnerDialog={setShowMarkWinnerDialog}
          showMarkWinnerDialog={showMarkWinnerDialog}
          offersObject={offersObject}
          markAsWinnerRef={markAsWinnerRef}
          fetchOffers={fetchOffers}
          latestAuctionId={latestAuctionId}
          setLoadingWinner={setLoadingWinner}
          loadingWinner={loadingWinner}
          liveNegotiationOffer={sortedNegotiations[0] ?? []}
        />
      )}

      {data?.winner && (
        <MarkWinnerAsLostModal
          offerId={data?.offer?.id}
          offersObject={offersObject}
          onSuccess={() => fetchOffers(`?q[auction_id_eq]=${latestAuctionId}`)}
          showMarkWinnerLostModal={showMarkWinnerLostModal}
          setShowMarkWinnerLostModal={setShowMarkWinnerLostModal}
        />
      )}

      {showAdjustMarginModal && (
        <AdjustMarginModal
          showModal={showAdjustMarginModal}
          setShowModal={setShowAdjustMarginModal}
          auctionUser={auctionUser}
          liveNegotiationOffer={sortedNegotiations[0] ?? []}
        />
      )}
    </>
  );
};

export default AuctionNegotiationTable;
