import {
  FETCH_CHEVRON_DISPOSITIONS_DATA,
  SET_INITIAL_CHEVRON_DISPOSITIONS_DATA,
  FETCH_SINGLE_DISPOSITION_DATA,
  SET_DISPOSITION_LOADING,
  FETCH_SINGLE_DISPOSITION_PAGINATED_DATA,
} from "../../constant/actionTypes";

const INITIAL_STATE = {
  chevronDispositions: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CHEVRON_DISPOSITIONS_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DISPOSITION_LOADING:
      return {
        ...state,
        chevronDispositions: {
          ...state.chevronDispositions,
          [action.payload.dispositionSlug]: {
            ...state.chevronDispositions[action.payload.dispositionSlug],
            loading: action.payload.dispositionLoading,
          },
        },
      };
    case SET_INITIAL_CHEVRON_DISPOSITIONS_DATA:
      return {
        ...state,
        chevronDispositions: action.payload,
      };
    case FETCH_SINGLE_DISPOSITION_DATA:
      const { dispositionSlug, dataPayload } = action.payload;
      return {
        ...state,
        chevronDispositions: {
          ...state.chevronDispositions,
          [dispositionSlug]: {
            ...state.chevronDispositions[dispositionSlug],
            ...dataPayload,
          },
        },
      };
    case FETCH_SINGLE_DISPOSITION_PAGINATED_DATA:
      return {
        ...state,
        chevronDispositions: {
          ...state.chevronDispositions,
          [action.payload.dispositionSlug]: {
            ...state.chevronDispositions[action.payload.dispositionSlug],
            ...action.payload.dataPayload,
            list: [
              ...state.chevronDispositions[action.payload.dispositionSlug].list,
              ...action.payload.dataPayload.list,
            ],
          },
        },
      };
    default:
      return state;
  }
};
