import { DynamicList, Modal } from "../../common";
import React, { useState } from "react";
import { IoIosArrowForward } from "../../svg-icon/svg-icons";
import { getYears } from "../../../helpers/Util";
import { strings } from "../../../constant/strings";

const CarInfoSelectModal = ({ visible, setVisible, onSelect }) => {
  const [selected, setSelected] = useState({});
  const years = getYears();

  const onDone = obj => {
    onSelect(obj);
    setVisible(false);
    setSelected({});
  };

  return (
    <Modal
      toggle={() => {
        setVisible(!visible);
      }}
      open={visible}
      className="modal-lg deposit_model crm-modal"
      body={() => {
        return (
          <div className="modal-wrapper d-flex flex-row">
            <div className="modal-inner-wrapper">
              <h6>{strings.model_year}</h6>
              <div className="modal-list-wrapper d-flex flex-column">
                {years?.map(e => (
                  <div
                    className={`modal-list d-flex flex-row align-items-center justify-content-between ${
                      selected.model_year == e ? "selected" : ""
                    }`}
                    onClick={() => {
                      selected.model_year = e;
                      setSelected({ ...selected });
                    }}
                    key={e}
                  >
                    <span className="d-flex">{e}</span>
                    <IoIosArrowForward className="d-flex" size="15" />
                  </div>
                ))}
              </div>
            </div>

            <DynamicList
              title={strings.make}
              params={selected.model_year ? `?q[select_key_in][]=make` : ""}
              onSelect={e => {
                selected.make = e;
                setSelected({ ...selected });
              }}
            />
            <DynamicList
              title={strings.model}
              params={selected.make ? `?q[select_key_in][]=model&q[ancestry_eq]=${selected.make.id}` : ""}
              onSelect={e => {
                selected.model = e;
                setSelected({ ...selected });
              }}
            />
            <DynamicList
              title={strings.variant}
              params={selected.model ? `?q[select_key_in][]=variant&q[ancestry_eq]=${selected.model.id}` : ""}
              onSelect={e => {
                selected.variant = e;
                setSelected({ ...selected });
                onDone(selected);
              }}
            />
          </div>
        );
      }}
      actions={[
        {
          label: strings.cancel,
          onClick: () => {
            setVisible(false);
          },
          color: "secondary",
          className: "modal__cancel-btn btn-pill",
        },
        {
          label: strings.done,
          color: "primary",
          className: "modal__cr-btn btn-pill",
          onClick: () => onDone(selected),
          disabled: !(selected?.model_year && selected?.make && selected?.model),
        },
      ]}
    />
  );
};

export default CarInfoSelectModal;
