import { Toast } from "../../components/common";
import {
  FETCH_CHEVRON_OPTIONS,
  FETCH_CHEVRON_USERS_DATA,
  FETCH_SINGLE_DISPOSITION_DATA,
  FETCH_SINGLE_DISPOSITION_PAGINATED_DATA,
  SET_CHEVRON_OPTIONS_LOADING,
  SET_CHEVRON_USERS_LOADING,
  SET_DISPOSITION_LOADING,
  SET_INITIAL_CHEVRON_DISPOSITIONS_DATA,
} from "../../constant/actionTypes";
import { fetchChevronUsers, fetchCRMLeadsApi, fetchLeadsChevronOptions } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

const setInitialChevronDispositionsData = (chevronLeadDisposition, dispatch) => {
  const chevronDispositionsPayload = {};
  if (chevronLeadDisposition?.length) {
    chevronLeadDisposition.map(
      dispositionType =>
        (chevronDispositionsPayload[dispositionType?.slug] = {
          loading: true,
          list: [],
          error: "",
          limit: 20,
          pagination: {
            current_page: null,
            next_page: null,
            prev_page: null,
            total_pages: null,
            total_count: null,
          },
        })
    );
  }
  dispatch({ type: SET_INITIAL_CHEVRON_DISPOSITIONS_DATA, payload: chevronDispositionsPayload });
};

export const fetchLeadsChevronOptionsData = () => async dispatch => {
  try {
    dispatch({ type: SET_CHEVRON_OPTIONS_LOADING, payload: { optionsLoading: true } });
    const res = await fetchLeadsChevronOptions();
    if (res && res.success) {
      dispatch({
        type: FETCH_CHEVRON_OPTIONS,
        payload: {
          chevronLeadCategoryTypes: res?.category_types ?? [],
          chevronLeadDispositions: res?.dispositions ?? [],
          optionsLoading: false,
        },
      });
      setInitialChevronDispositionsData(res?.dispositions, dispatch);
    } else {
      dispatch({
        type: FETCH_CHEVRON_OPTIONS,
        payload: {
          chevronLeadCategoryTypes: [],
          chevronLeadDispositions: [],
          optionsLoading: false,
        },
      });
    }
  } catch (error) {
    Toast.error(getErrorString(error));
  }
};

export const fetchChevronDispositionData =
  (dispositionSlug, queryParams, isPaginatedFetch = false, initialDispositionLoading = true, limit = 20) =>
  async dispatch => {
    const fetchDispatchType = isPaginatedFetch
      ? FETCH_SINGLE_DISPOSITION_PAGINATED_DATA
      : FETCH_SINGLE_DISPOSITION_DATA;
    try {
      dispatch({
        type: SET_DISPOSITION_LOADING,
        payload: { dispositionSlug, dispositionLoading: initialDispositionLoading },
      });
      const res = await fetchCRMLeadsApi(queryParams);
      if (res && res.success) {
        const dispositionPayload = {
          loading: false,
          list: res?.crm_leads ?? [],
          error: "",
          limit: limit,
          pagination: res?.pagination,
        };
        dispatch({
          type: fetchDispatchType,
          payload: {
            dispositionSlug: dispositionSlug,
            dataPayload: dispositionPayload,
          },
        });
      } else {
        const dispositionPayload = {
          loading: false,
          list: [],
          error: res?.error ?? res?.message ?? "Error Fetching Data",
          limit: 20,
          pagination: {
            current_page: null,
            next_page: null,
            prev_page: null,
            total_pages: null,
            total_count: null,
          },
        };
        dispatch({
          type: fetchDispatchType,
          payload: {
            dispositionSlug: dispositionSlug,
            dataPayload: dispositionPayload,
          },
        });
      }
    } catch (error) {
      const dispositionPayload = {
        loading: false,
        list: [],
        error: getErrorString(error),
        limit: 20,
        pagination: {
          current_page: null,
          next_page: null,
          prev_page: null,
          total_pages: null,
          total_count: null,
        },
      };
      dispatch({
        type: fetchDispatchType,
        payload: {
          dispositionSlug: dispositionSlug,
          dataPayload: dispositionPayload,
        },
      });
      Toast.error(getErrorString(error));
    }
  };

export const fetchChevronUsersData =
  (queryParams, initialDispositionLoading = true) =>
  async dispatch => {
    try {
      dispatch({ type: SET_CHEVRON_USERS_LOADING, payload: { usersLoading: initialDispositionLoading } });
      const res = await fetchChevronUsers(queryParams);
      if (res && res.success) {
        const usersPayload = {
          chevronUsers: res?.grouped_users ?? {},
          usersLoading: false,
        };
        dispatch({
          type: FETCH_CHEVRON_USERS_DATA,
          payload: usersPayload,
        });
      } else {
        const usersPayload = {
          chevronUsers: {},
          usersLoading: false,
        };
        dispatch({
          type: FETCH_CHEVRON_USERS_DATA,
          payload: usersPayload,
        });
        Toast.error(getErrorString(res));
      }
    } catch (error) {
      const usersPayload = {
        chevronUsers: {},
        usersLoading: false,
      };
      dispatch({
        type: FETCH_CHEVRON_USERS_DATA,
        payload: usersPayload,
      });
      Toast.error(getErrorString(error));
    }
  };
