export const PRIVATE_FIELDS_LABELS = {
  PHONE: "Phone",
  EMAIL: "Email",
};

export const PRIVATE_FIELDS_KEYS = {
  PHONE: "phone",
};

export const MODELS_FOR_VIEW_PHONE_NUMBER_API = Object.freeze({
  CONTRACT_DETAIL_PHONE_KEY: "Contract",
  QUOTATION_PHONE_KEY: "Quotation",
  BIDDER_PHONE_KEY: "Booking",
  INVENTORY_PHONE_KEY: "Car",
  CONNECT_PHONE_KEY: "Connect",
  CLIENT_LISTING_PHONE_KEY: "Client",
  LEAD_PHONE_KEY: "CrmLead",
  TASK_PHONE_KEY: "Task",
  AUCTION_MODEL: "Auction",
  INSPECTION_MODEL: "Inspection",
  PAYMENTS_MODEL: "Payment",
  RECORDINGS: "Recording",
});

export const PHONE_NUMBER_CATEGORIZATION_PER_PAGE = Object.freeze({
  CRM_LEADS: {
    DETAIL: {
      LEAD_DETAIL: "crm_lead/detail",
      CLIENT_DETAIL: "crm_leads/detail/client_detail",
      DETAILS_TAB_SELLER_INFO: "crm_leads/details_tab_seller_info",
      CALL_BUTTON: "crm_leads/detail/click_to_call_button",
    },
    LISTING: "crm_leads/listing",
    FOLLOWUP_LISTING: "crm_leads/followup_listing",
    EDIT: "crm_leads/edit",
    NEW: "crm_leads/new",
    VAS: {},
    SELLER: {},
    BUYER: {},
  },
  INVENTORY: {
    LISTING: {
      CLIENT: "car/listing/client_phone",
      BUYER: "car/listing/buyer_phone",
    },
    DETAIL: {
      INVENTORY_DETAIL: "car/detail",
      BUYER_CONNECT: "car/detail/buyer_connects",
      CLIENT_INFO: "car/detail/car_details_client_info",
      SOLD_TO: "car/detail/car_details_sold_to",
      PAYMENTS: "car/detail/car-payments",
      QUOTATIONS_BUYER: "car/detail/quotations_buyer",
      QUOTATIONS_SELLER: "car/detail/quotations_seller",
      BUYER_RECOMMENDATION: "car/detail/buyer_recommendation",
      OFFER_TRAIL: "car/detail/offer_trail",
    },
  },
  CONTRACTS: {
    DETAIL: "contract/detail/client_info",
    NEW: "contract/new",
  },
  CLIENTS: {
    LISTING: "client/listing",
    DETAIL: "client/detail/client_info",
  },
  AUCTION: {
    BIDDERS: "auction/bidders",
    BIDDER_DETAIL: "auction/bidder_detail/bidder_info",
    UPDATE_BIDDER_DETAIL: "auction/update_bidder_detail",
    DETAIL: "auction/detail/user-info",
    BOOKING_EXPENSES: "auction/booking_expenses/bidder_info",
  },
  INSPECTION: {
    LISTING: "inspection/leads",
    DETAIL: "inspection/leads/detail",
    DETAIL_CALL_BUTTON: "inspection/leads/detail/click_to_call_button",
    EDIT: "inspection/leads/edit",
    CALENDAR: "inspection/calendar/schedules",
  },
  PAYMENTS: {
    LISTING: "payments/listing",
  },
  TASK: {
    CALENDAR: {
      BUYER_DETAIL: "crm/task/calendar/buyer_detail",
      SELLER_DETAIL: "crm/task/calendar/seller_detail",
    },
    INVENTORY_MODAL: "crm/tasks/select_inventory_modal",
    LEAD_MODAL: "crm/tasks/select_lead_modal",
    FORM: "crm/tasks/task_form",
  },
  QC: {
    DETAIL: "qc/detail",
  },
});

export const VIEW_PHONE_CTA_TEXTS = {
  VIEW_NUMBER: "View Number",
  SHOW_NUMBER: "Show Number",
  SHOW_PHONE_NUMBER: "Show Phone Number",
};

export const VIEW_PHONE_CONSTANTS = Object.freeze({
  API: {
    ASSOCIATED_ID: "associated_id",
    ASSOCIATED_TYPE: "associated_type",
    COMPONENT: "component",
    PLATFORM: "platform",
  },
});
