import React, { useEffect } from "react";

import { AuctionActions } from ".";
import Countdown from "react-countdown";
import { strings } from "../../constant/strings";

const AuctionDetailCardHeader = ({ auction }) => {
  const getHeadingStyleClass = () => {
    switch (auction?.status?.name) {
      case "Upcoming":
        return "inventory_aution-status upcoming_status";
      case "Live":
        return "inventory_aution-status live_status";
      case "Completed":
        return "inventory_aution-status completed_status";
      case "Cancelled":
        return "inventory_aution-status cancelled_status";
      default:
        return;
    }
  };

  const timeElement = (label, value, end) => (
    <li>
      <span>{value < 10 ? "0" + value : value}</span>
      <p>{label}</p>
    </li>
  );

  const timer = (days, hours, minutes, seconds) => (
    <div className="pull-right row align-items-center m-r-30">
      <h6 className="mr-2">{strings.ending_in}</h6>
      <ul className={Number(minutes) <= 15 ? "timer_list ending_time" : "timer_list"}>
        {timeElement("Days", days)}
        {timeElement("Hours", hours)}
        {timeElement("Mins", minutes)}
        {timeElement("Secs", seconds, true)}
      </ul>
    </div>
  );

  return (
    <div className="row">
      <div className="col-md-6">
        {auction?.status?.name && (
          <div className={getHeadingStyleClass()}>
            <figure className="blob"></figure>
            <div className="status__invent-cont">
              <h5>{auction?.status?.name}</h5>
              {auction?.status?.name === "Completed" && !!auction.winner && (
                <p>
                  {strings.auction_won_by} {[auction.winner.first_name, auction.winner.last_name].join(" ")}!
                </p>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="col-md-6">
        <AuctionActions auction={auction} />

        {auction?.status?.name === "Live" && (
          <Countdown
            date={new Date(auction?.end_time)}
            renderer={({ total, days, hours, minutes, seconds }) => {
              if (total) {
                return timer(days, hours, minutes, seconds);
              } else {
                return timer(0, 0, 0, 0);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AuctionDetailCardHeader;
