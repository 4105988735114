import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Button,
  Flex,
  Text,
  NumberInput,
  NumberInputField,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
} from "@chakra-ui/react";
import { Search } from "react-feather";
import { strings } from "../../../constant/strings";
import ConfigDB from "../../../data/config";
import { RANGE_FILTER_KEYS } from "constant/appConstants";
import OutsideAlerter from "../OutsideAlerter";
import { useHistory } from "react-router-dom";

const getRangeInitialState = ({ type, MAX_LIMIT, MIN_LIMIT }) => {
  const { MAX_LIMIT: max, MIN_LIMIT: min } = RANGE_FILTER_KEYS({ MAX_LIMIT, MIN_LIMIT })[type];
  return {
    min,
    max,
  };
};
const getRangeFilterKeys = type => {
  const { GREATER_THAN, LESS_THAN, STEP } = RANGE_FILTER_KEYS()[type];
  return {
    min: GREATER_THAN,
    max: LESS_THAN,
    step: STEP,
  };
};

const getInputValue = (min, max) => {
  if (!min || !max) return "";
  return `${min} To ${max}`;
};

const RangeFilter = props => {
  const {
    label,
    type,
    onClearFilter,
    filtersObject,
    filterHandler,
    MAX_LIMIT,
    MIN_LIMIT,
    className = "",
    inputClassName = "",
  } = props;
  const history = useHistory();
  const [showRange, setShowRange] = useState(false);
  const range = getRangeInitialState({ type, MAX_LIMIT, MIN_LIMIT });

  const [rangeValues, setRangeValues] = useState(range);
  const { max: MAX_VALUE, min: MIN_VALUE } = range;

  const FILTER_KEYS = getRangeFilterKeys(type);
  const resetRangeValues = () => {
    setRangeValues(range);
  };
  const onRangeSubmit = () => {
    filterHandler(rangeValues, FILTER_KEYS);
    setShowRange(false);
  };
  const onClear = () => {
    onClearFilter(FILTER_KEYS);
    resetRangeValues();
  };
  const handleOutsideAlerter = () => {
    setShowRange(false);
    const ifFilterIsApplied = history.location.search?.includes(RANGE_FILTER_KEYS()[type].GREATER_THAN);

    !ifFilterIsApplied && setRangeValues(range);
  };

  const filtersObjKeys = {
    min: filtersObject[FILTER_KEYS.min] ?? null,
    max: filtersObject[FILTER_KEYS.max] ?? null,
  };

  useEffect(() => {
    if (filtersObjKeys.min && filtersObjKeys.max) {
      setRangeValues({ min: Number(filtersObjKeys.min), max: Number(filtersObjKeys.max) });
    }
  }, [filtersObjKeys.min, filtersObjKeys.max]);

  const inputValue = getInputValue(filtersObjKeys.min, filtersObjKeys.max);

  return (
    <OutsideAlerter cb={handleOutsideAlerter}>
      <div>
        <div className={`${className} faq-form`}>
          <Search size={18} className={`search-icon ${props.filterSelected ? "selected-filter-svg" : ""}`} />
          <Box
            cursor={"pointer"}
            onClick={() => {
              setShowRange(prev => !prev);
            }}
          >
            <input
              className={`form-control ${inputClassName}`}
              type={"text"}
              value={inputValue}
              readOnly
              style={{ pointerEvents: "none", background: "#ffffff" }}
              placeholder={label}
            />
          </Box>
        </div>
        {showRange && (
          <Box
            zIndex={"999"}
            width={"270px"}
            height={"220px"}
            background={"#ffffff"}
            marginTop={"5px"}
            borderRadius={"5px"}
            position={"absolute"}
            boxShadow={"2px 6px 15px -4px rgba(0,0,0,0.5)"}
          >
            <Box paddingLeft={"10px"} paddingRight={"10px"}>
              <Box fontWeight={"semibold"} marginTop={"5px"} paddingTop={"0.5rem"} marginBottom={"10px"}>
                <Text fontSize="sm">{label}</Text>
              </Box>
              <Divider marginBottom={"20px"} marginLeft={"-10px"} />
              <Flex flexDirection={"row"} justifyContent={"center"}>
                <NumberInput
                  min={MIN_VALUE}
                  max={Number(rangeValues.max)}
                  value={Number(rangeValues.min)}
                  maxW="150px"
                  mr={"1rem"}
                  onChange={value => {
                    setRangeValues({
                      min: value,
                      max: Number(rangeValues.max),
                    });
                  }}
                >
                  <NumberInputField />
                </NumberInput>
                <Text paddingTop={"0.5rem"}>{"To"}</Text>
                <NumberInput
                  min={Number(rangeValues.min)}
                  max={Number(MAX_VALUE)}
                  value={Number(rangeValues.max)}
                  maxW="150px"
                  ml={"1rem"}
                  onChange={value => {
                    setRangeValues({
                      min: Number(rangeValues.min),
                      max: Number(value),
                    });
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </Flex>
              <Box paddingLeft={"10px"} paddingRight={"10px"}>
                <RangeSlider
                  aria-label={["min", "max"]}
                  color={ConfigDB.data.color.primary_color}
                  value={[rangeValues.min, rangeValues.max]}
                  marginTop={"20px"}
                  max={MAX_VALUE}
                  min={MIN_VALUE}
                  step={FILTER_KEYS.step}
                  onChange={value => {
                    let [min, max] = [value[0], value[1]];
                    setRangeValues({
                      min: min,
                      max: max,
                    });
                  }}
                >
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack bg={ConfigDB.data.color.primary_color} />
                  </RangeSliderTrack>
                  <RangeSliderThumb
                    index={0}
                    border={`3px solid`}
                    borderColor={`${ConfigDB.data.color.primary_color}`}
                    //dataActive={"none"}
                    focus={"none"}
                  ></RangeSliderThumb>
                  <RangeSliderThumb
                    index={1}
                    border={`3px solid`}
                    borderColor={`${ConfigDB.data.color.primary_color}`}
                  ></RangeSliderThumb>
                </RangeSlider>
              </Box>
              <Divider marginTop={"5px"} marginBottom={"10px"} marginLeft={"-10px"} />
              <Flex flexDirection={"row"} justifyContent={"space-between"}>
                <Box paddingTop={"0.5rem"} cursor={"pointer"} onClick={onClear}>
                  <Text fontSize={"sm"}>{strings.clear}</Text>
                </Box>
                <Box>
                  <Button
                    onClick={onRangeSubmit}
                    backgroundColor={ConfigDB.data.color.primary_color}
                    color={"#ffffff"}
                    disabled={Number(rangeValues.max) < Number(rangeValues.min)}
                    _hover={{ bg: ConfigDB.data.color.primary_color }}
                    cursor={"pointer"}
                  >
                    {strings.submit}
                  </Button>
                </Box>
              </Flex>
            </Box>
          </Box>
        )}
      </div>
    </OutsideAlerter>
  );
};
export default RangeFilter;
