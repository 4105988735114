import { combineReducers } from "redux";
import {
  AuctionTypeReducer,
  DynamicFieldsReducer,
  DynamicModelsReducer,
  EmailTemplateReducer,
  GroupReducer,
  InspectionsProgramReducer,
  LeadSourceReducer,
  LeadTypeReducer,
  RolesReducer,
  SmsTemplateReducer,
  TeamReducer,
  TokensReducer,
  UserLeavesReducer,
  UserReducer,
} from "./admin";
import {
  AuctionBookingsReducer,
  AuctionDashboardReducer,
  AuctionNegotiations,
  AuctionReducer,
  AuctionUserReducer,
  CarDetailReducer,
  CarFeaturesLists,
  CarReducer,
  OfferReducer,
} from "./auction";
import {
  CRMApprovalReducer,
  CRMCicoReducer,
  CRMClientsReducer,
  CRMContractsReducer,
  CRMDashboardReducer,
  CRMKittReducer,
  CRMLeadDetailReducer,
  CRMLeadFollowUpsReducer,
  CRMLeadsChevronOptionsReducer,
  CRMLeadsChevronDispositionsReducer,
  CRMLeadsChevronUsersReducer,
  CRMLeadsReducer,
  CRMPaymentsReducer,
  CRMReminderReducer,
  CRMStockCountReducer,
  CRMTaskDetailReducer,
  CRMTaskFollowUpsReducer,
  CRMTasksReducer,
  CRMWatcherUsersReducer,
  EventCalendarReducer,
  ItemListsReducer,
  LocationsReducer,
  TaskTypesReducer,
} from "./crm";
import CrmCallsReducer from "./crm/crmCalls.reducer";
import {
  AssigneesReducer,
  CitiesReducer,
  ConfigsReducer,
  CustomReducer,
  Customizer,
  DynamicFilterReducer,
  PermissionsReducer,
  QuickLinksReducer,
  StockCarsReducer,
  GeneralReportsReducer,
} from "./general";
import {
  DepositsReducer,
  InspectionDashboardReducer,
  LeadDetailsReducer,
  LeadReducer,
  PaymentsReducer,
  ReportsReducer,
  UnscheduledLeadsReducer,
} from "./inspection";
import { LoggedInUserReducer } from "./login";

const reducers = combineReducers({
  Customizer,
  UserData: UserReducer,
  TeamData: TeamReducer,
  LeadData: LeadReducer,
  LeadDetailsData: LeadDetailsReducer,
  UnscheduledLeads: UnscheduledLeadsReducer,
  ReportsData: ReportsReducer,
  PaymentsData: PaymentsReducer,
  Configs: ConfigsReducer,
  QuickLinksData: QuickLinksReducer,
  dashboard: InspectionDashboardReducer,
  RolesData: RolesReducer,
  PermissionsData: PermissionsReducer,
  LeadTypeData: LeadTypeReducer,
  CitiesData: CitiesReducer,
  CarFeaturesLists: CarFeaturesLists,
  CarsData: CarReducer,
  carsStockListData: StockCarsReducer,
  GroupData: GroupReducer,
  AuctionTypeData: AuctionTypeReducer,
  AuctionUserData: AuctionUserReducer,
  AuctionsData: AuctionReducer,
  OffersData: OfferReducer,
  AuctionDashboardData: AuctionDashboardReducer,
  DepositsData: DepositsReducer,
  TokensData: TokensReducer,
  InspectionProgramData: InspectionsProgramReducer,
  CRMLeadsData: CRMLeadsReducer,
  CRMLeadDetail: CRMLeadDetailReducer,
  TasksData: CRMTasksReducer,
  TaskDetail: CRMTaskDetailReducer,
  CrmClientsData: CRMClientsReducer,
  CrmWatcherUsers: CRMWatcherUsersReducer,
  CrmDashboardData: CRMDashboardReducer,
  LeadSourceData: LeadSourceReducer,
  SmsTemplateData: SmsTemplateReducer,
  EmailTemplateData: EmailTemplateReducer,
  DynamicModelsData: DynamicModelsReducer,
  DynamicFieldsData: DynamicFieldsReducer,
  CarDetailData: CarDetailReducer,
  EventCalendarData: EventCalendarReducer,
  TaskTypesData: TaskTypesReducer,
  LocationsData: LocationsReducer,
  LoggedInUserData: LoggedInUserReducer,
  CRMPaymentsData: CRMPaymentsReducer,
  AssigneesData: AssigneesReducer,
  CRMApprovalData: CRMApprovalReducer,
  CustomReducer: CustomReducer,
  StockCountData: CRMStockCountReducer,
  CrmCicoData: CRMCicoReducer,
  ItemListsData: ItemListsReducer,
  CrmReminderData: CRMReminderReducer,
  DynamicFilterData: DynamicFilterReducer,
  AuctionBookingsData: AuctionBookingsReducer,
  UserLeavesData: UserLeavesReducer,
  CRMTaskFollowUpsData: CRMTaskFollowUpsReducer,
  CRMContractsData: CRMContractsReducer,
  CRMKittReducer: CRMKittReducer,
  CRMLeadFollowUpsData: CRMLeadFollowUpsReducer,
  CRMLeadsChevronOptionsData: CRMLeadsChevronOptionsReducer,
  CRMLeadsChevronDispositionsData: CRMLeadsChevronDispositionsReducer,
  CRMLeadsChevronUsersData: CRMLeadsChevronUsersReducer,
  AuctionNegotiationsData: AuctionNegotiations,
  CallRecordingsData: CrmCallsReducer,
  GeneralReportsReducerData: GeneralReportsReducer,
});

export default (state, action) => reducers(action.type === "USER_LOGGED_OUT" ? undefined : state, action);
