import React, { useEffect, useState } from "react";
import { strings } from "../../constant/strings";
import InfiniteScroll from "react-infinite-scroll-component";

import { fetchNotificationApi, updateNotificationaAsAllReadApi } from "../../helpers/ApiHelper";
import { isObjRestricted, getDateTimeDiff, getPermission, getErrorString } from "../../helpers/Util";
import { ClipboardImage, DoubleCheck } from "../svg-icon/svg-icons";
import Skeleton from "./Skeleton";
import NotificationCard from "./NotificationCard";
import EmptyState from "./EmptyState/EmptyState";
import Toast from "./Toast";
import ConfigDB from "../../data/config";
const NotificationCenter = ({
  platform,
  setnotificationCount,
  history,
  setSidebarOpen,
  sidebarOpen,
  setFetchOnOpen,
  fetchOnOpen,
}) => {
  const [data, setData] = useState([]);
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [olderNotifications, setOlderNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  //Function
  const fetchData = async isPlatformChanged => {
    let searchStringWithPage = `${page?.next_page ? `&page=${page?.next_page}` : ""}`;
    let searchStringWithPlateForm = `?platform=${platform}`;

    let searchString = fetchOnOpen ? searchStringWithPlateForm : searchStringWithPlateForm + searchStringWithPage;
    const { success, notifications, pagination, unread_count } = await fetchNotificationApi(searchString);
    if (success) {
      setLoading(false);
      if (pagination?.next_page === null) {
        setHasMore(false);
      }
      if (!isPlatformChanged && !fetchOnOpen) {
        setData([...data, ...notifications]);
        setPage(pagination);
        setnotificationCount(unread_count);
      } else {
        setFetchOnOpen(false);
        setData([...notifications]);
        setPage(pagination);
        setnotificationCount(unread_count);
      }
    }
  };
  useEffect(() => {
    page && page.next_page && setHasMore(true);
  }, [page]);
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (fetchOnOpen) {
      fetchData();
    }
  }, [fetchOnOpen]);

  useEffect(() => {
    if (!sidebarOpen) {
      setData([]);
      setPage(null);
      setHasMore(false);
    }
  }, [sidebarOpen]);

  useEffect(() => {
    const today = new Date();
    let todayNotificationsObj = data.filter(item => {
      if (getDateTimeDiff(item.created_at, today).split(" ")[0] < 24 && !isObjRestricted(item)) {
        return item;
      }
    });
    let olderNotificationsObj = data.filter(item => {
      if (getDateTimeDiff(item.created_at, today).split(" ")[0] >= 24 && !isObjRestricted(item)) {
        return item;
      }
    });
    setOlderNotifications(olderNotificationsObj);
    setTodayNotifications(todayNotificationsObj);
  }, [data]);
  const markAllAsRead = async () => {
    const response = await updateNotificationaAsAllReadApi({ platform });
    if (response.success) {
      setnotificationCount(0);
      let updatedNotifications = data.map(item => {
        item.is_read = true;
        return item;
      });
      setData(updatedNotifications);
    } else {
      Toast.error(getErrorString(response));
    }
  };
  return (
    <div>
      <div className="card flex-grow-1 p-0">
        <div className="card-header d-flex justify-content-between align-items-center p-3 pr-7">
          <h3 className="">{strings.notifications}</h3>
          {getPermission("Notification", "mark_all_as_read") && (
            <div className={`d-flex align-items-center ${data.length ? "" : "disabled"}`}>
              <DoubleCheck fill={ConfigDB.data.color.primary_color} />
              <div onClick={markAllAsRead} className="ml-1 pr-3 text-primary" style={{ cursor: "pointer" }}>
                {strings.mark_all_read}
              </div>
            </div>
          )}
        </div>
        <div className="card-body h-100 p-0">
          <InfiniteScroll
            dataLength={data.length}
            next={fetchData}
            hasMore={hasMore}
            scrollThreshold={0.9}
            className={
              "infinite-scroll-table timeline-small timeline-activity activity-scroll timeline-activity-height mt-0"
            }
            height={"100%"}
            style={{ maxHeight: "85vh", background: "#F5F5F5" }}
            loader={<NotificationLoading count={2} />}
          >
            {loading ? (
              <NotificationLoading count={8} />
            ) : (
              <>
                {data.length ? (
                  <>
                    <div>
                      {!!todayNotifications.length && <h5 className="text-muted m-3">Today</h5>}
                      {todayNotifications.length
                        ? todayNotifications.map(item => (
                            <NotificationCard
                              key={item.id}
                              item={item}
                              data={data}
                              setData={setData}
                              history={history}
                              setSidebarOpen={setSidebarOpen}
                              platform={platform}
                              setnotificationCount={setnotificationCount}
                            />
                          ))
                        : null}
                    </div>
                    <div>
                      {!!olderNotifications.length && <h5 className="text-muted m-3">Older</h5>}
                      {olderNotifications.length
                        ? olderNotifications.map(item => (
                            <NotificationCard
                              key={item.id}
                              item={item}
                              data={data}
                              setData={setData}
                              history={history}
                              setSidebarOpen={setSidebarOpen}
                              platform={platform}
                              setnotificationCount={setnotificationCount}
                            />
                          ))
                        : null}
                    </div>
                  </>
                ) : (
                  <div style={{ minHeight: "85vh", background: "#F5F5F5" }}>
                    <EmptyState title="No available Notifications!" image={<ClipboardImage />} />
                  </div>
                )}
              </>
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default NotificationCenter;

const NotificationLoading = ({ count = 5 }) => {
  return (
    <>
      {[...Array(count).keys()].map(e => (
        <div className="d-flex align-item-center  m-3" key={e}>
          <div className="p-2">
            <Skeleton height={40} width={40} />
          </div>
          <div className="d-flex flex-column p-2">
            <Skeleton height={20} width={280} />
            <Skeleton height={20} width={280} />
          </div>
          <div className="ml-auto p-2">
            <Skeleton height={20} width={20} circle={true} />
          </div>
        </div>
      ))}
    </>
  );
};
