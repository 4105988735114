import React, { Fragment } from "react";

import { CustomAlert } from "./CustomAlert";
import Skeleton from "./Skeleton";
import { StatusCard } from "./StatusCard";
import { formatNumberString, openUrlLinkInNewTab } from "../../helpers/Util";
import Anchor from "./Anchor";

const StatusCardContainer = props => {
  const { title, history, statistics, loading, error, setStatusFilters, timeFilters, path, type, search } = props;

  const onFilterChange = (e, status_id) => {
    setStatusFilters({
      timeFilters: {
        ...timeFilters,
        [status_id]: { days_before: e.target.value },
      },
    });
  };

  return (
    <Fragment>
      {loading ? (
        <>
          {[1, 2, 3, 4].map(i => (
            <div className="mb-3 col-xl-3 col-sm-6 col-6" key={i}>
              <div className="card status-card-height">
                <div className="card-body"></div>{" "}
              </div>
            </div>
          ))}
        </>
      ) : (
        statistics.map((item, i) => (
          <div className={`col-xl-3 col-sm-6 col-6 d-flex`} key={item.status_id || item.slug || item.top_label + i}>
            <StatusCard
              link={`${path}${search}${item.status_id}`}
              path={path}
              onClick={event => {
                event.preventDefault();
                if (path) {
                  if (event.ctrlKey || event.control || event.metaKey) {
                    openUrlLinkInNewTab(`/admin${path}${search}${item.status_id}`);
                  } else {
                    history.push({
                      pathname: path,
                      search: `${search}${item.status_id}`,
                    });
                  }
                }
              }}
              timeFilters={timeFilters}
              onFilterChange={onFilterChange}
              type={type}
              {...item}
            />
          </div>
        ))
      )}
    </Fragment>
  );
};

export default StatusCardContainer;
