import React, { useState, useRef } from "react";
import cx from "clsx";
import { CustomAlert } from "../common/CustomAlert";
import { Bell } from "react-feather";
import { Modal } from "reactstrap";
import { strings } from "../../constant/strings";
import ReminderCard from "../forms/task-form/ReminderCard";
import { useSelector } from "react-redux";
import { getPermission } from "../../helpers/Util";
function AddReminder({
  label,
  labelClassName,
  horizontal,
  columnsLeftSection = 3,
  containerClassName,
  errorMsg,
  errorType,
  errorClassName,
  textRight,
  columnsRightSection = 9,
  setReminderModal,
  reminderId,
  reminder,
  setTaskFieldValue,
  isEditTaskForm,
}) {
  const task = useSelector(content => content.TaskDetail.task);
  const RenderLabel = () =>
    label ? (
      <label
        className={cx(
          labelClassName ? labelClassName : horizontal && `col-form-label d-flex col-sm-${columnsLeftSection}`,
          textRight && "justify-content-end"
        )}
      >
        {label}
      </label>
    ) : null;

  const getReminder = () => {
    const data = {
      [!!reminder]: reminder,
      [!!task]: task?.reminders?.at(0),
    };
    return data[true];
  };
  return (
    <>
      <div
        className={
          containerClassName != undefined
            ? containerClassName
            : `${horizontal ? "form-group row form-group-y" : ""} hover`.trim()
        }
      >
        {!!errorMsg && errorType === "icon" && (
          <CustomAlert message={errorMsg} type="icon" containerClassName={errorClassName} />
        )}
        {!!errorMsg && errorType !== "icon" && <p className={"invalid-error " + errorClassName}>{errorMsg}</p>}
        {getReminder()?.status?.slug === "pending" ? (
          <>
            <RenderLabel />
            <div className={cx("d-flex align-items-center", horizontal && `col-sm-${columnsRightSection}`)}>
              <ReminderCard
                reminder={getReminder()}
                taskId={task?.id}
                setReminderModal={setReminderModal}
                setTaskFieldValue={setTaskFieldValue}
                isEditTaskForm={isEditTaskForm}
              />
            </div>
          </>
        ) : (
          <>
            {getPermission("Task", "add_reminder") && (
              <>
                <RenderLabel />
                <div
                  onClick={() => {
                    setReminderModal(true);
                  }}
                  className={cx("d-flex align-items-center", horizontal && `col-sm-4 hover-pointer `)}
                >
                  <div className="media-icon simple bordered">
                    <Bell />
                  </div>
                  <div className="text-muted">{strings.save_reminder}</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default AddReminder;
