import { Formik } from "formik";
import { fetchUserOptions } from "helpers/userHelper";
import React, { useEffect, useRef, useState } from "react";
import { strings } from "../../constant/strings";
import {
  addPermittedProperty,
  appendTimeZone,
  getErrorString,
  getPermission,
  isEmptyArray,
  isFieldEditable,
  isFieldRestricted,
  isFieldViewable,
  replaceKeyWithId,
  setTimeZone,
} from "../../helpers/Util";
import { Avatar, CustomAlert, FormInput, Modal, SelectField, Toast } from "../common";
import { PaginatedSearch } from "../common/PaginatedSearch";
import { IoMdAdd } from "../svg-icon/svg-icons";
import { AssignDateInput } from "./index";

import cx from "clsx";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as yup from "yup";
import { fetchCrmTaskFollowUps, updateTaskFollowUps } from "../../actions/crm";
import { CRM_LEAD_DETAIL_PATH } from "../../constant/appPaths";
import { followUpTypeList } from "../../constant/lists";
import { createLeadFollowUp, createTaskFollowUp } from "../../helpers/ApiHelper";
import { selectActiveColumns, selectTimeZone } from "../../reducers/general/selectors";
import { selectLoggedInUser } from "../../reducers/login/selectors";
import FormRow from "../common/FormRow";
import styles from "./FollowUp.module.scss";
import FollowUpTypeStatusInput from "./FollowUpTypeStatusInput";

const getIsFirstFollowUpDisabled = ({ hasFollowUps, sortedFollowUps, followUpStatuses }) => {
  if (!hasFollowUps || sortedFollowUps?.length <= 0) return false;
  return !!followUpStatuses.find((status, index) => {
    return index !== 0 && status.slug === "due";
  });
};

const FollowUp = props => {
  const followUps = useSelector(state => state.TaskDetail.follow_ups);
  const followUpStatuses = useSelector(state => state.TaskDetail.follow_up_statuses);
  const [visible, setVisible] = useState(false);
  const [followUpsVisible, setFollowUpsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const hasFollowUps = !isFieldRestricted(followUps) && !isEmptyArray(followUps);
  let sortedFollowUps = hasFollowUps ? followUps?.sort((a, b) => b?.reference_number - a?.reference_number) : [];
  const isFirstStatusDisabled = getIsFirstFollowUpDisabled({ hasFollowUps, sortedFollowUps, followUpStatuses });
  const followUp = sortedFollowUps?.[0];
  const { fromTaskForm } = props;

  return (
    <>
      <div className=" form-group row d-flex flex-row align-items-center">
        <label className={`col-sm-3 col-form-label`}>{strings.follow_up}</label>
        {followUp?.status?.slug !== "due" && getPermission("Task", "add_follow_up") && (
          <div className={"col-sm-9 d-flex flex-column align-items-start"}>
            <button
              className="btn btn-pill btn-pill-icon-left btn-outline-primary d-flex"
              onClick={() => {
                setVisible(true);
              }}
              disabled={followUp?.status?.slug === "due"}
              type="button"
            >
              <IoMdAdd size="1.4em" />
              <span>{strings.add_follow_up}</span>
            </button>
          </div>
        )}
      </div>

      <div className=" form-group row d-flex flex-row align-items-center">
        <label className={`col-sm-3 col-form-label`}></label>
        <div className="col-sm-9 d-flex flex-column align-items-start" style={{ gap: "16px" }}>
          {!isFieldRestricted(followUp) && !!followUp && (
            <FollowUpCard
              followUp={followUp}
              followUpStatuses={followUpStatuses}
              isStatusDisabled={followUp?.status?.slug !== "due"}
              fromTaskForm={fromTaskForm}
            />
          )}
          {hasFollowUps && sortedFollowUps?.length > 1 && (
            <span
              className="txt-info hover-pointer text-primary text-right ml-auto"
              onClick={() => {
                setFollowUpsVisible(true);
              }}
            >
              View All
            </span>
          )}
        </div>
      </div>

      <AddFollowUpModal
        visible={visible}
        setVisible={setVisible}
        loading={loading}
        taskId={props.taskId}
        setLoading={setLoading}
        error={error}
        setError={setError}
        fromTaskForm={fromTaskForm}
      />

      <FollowUpsListModal
        visible={followUpsVisible}
        setVisible={setFollowUpsVisible}
        list={!isFieldRestricted(followUps) ? followUps : []}
        followUpStatuses={followUpStatuses}
        isFirstStatusDisabled={isFirstStatusDisabled}
      />
    </>
  );
};

export const AddFollowUpModal = props => {
  const {
    visible,
    setVisible,
    loading,
    setLoading,
    error,
    setError,
    followUpPrefilled,
    fromTaskForm,
    fromCrmLeadFollowUpListing = false,
  } = props;

  const dispatch = useDispatch();
  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const followUpPermittedAttributes = activeColumnPermittedAttributes.FollowUp;
  const formikRef = useRef(null);
  const timezone = useSelector(selectTimeZone);
  const currentUser = useSelector(selectLoggedInUser);
  const formattedFollowUpTypeList = followUpTypeList.filter(item => item?.slug !== "In Person");
  const leadRefNumber =
    fromCrmLeadFollowUpListing &&
    !!followUpPrefilled?.crm_lead &&
    !isFieldRestricted(followUpPrefilled?.crm_lead) &&
    followUpPrefilled?.crm_lead?.reference_number
      ? followUpPrefilled?.crm_lead?.reference_number
      : null;
  const hasLink =
    fromCrmLeadFollowUpListing && !isFieldRestricted(followUpPrefilled?.crm_lead) && !!followUpPrefilled?.crm_lead?.id;
  const history = useHistory();

  const getInitialDueTime = () => {
    const dateLocal = new Date();
    const minutes = dateLocal.getMinutes();
    let roundedMinutes;
    if (minutes < 30) {
      roundedMinutes = 30;
    } else {
      roundedMinutes = 0;
      dateLocal.setHours(dateLocal.getHours() + 1);
    }
    dateLocal.setMinutes(roundedMinutes);
    return dateLocal;
  };

  const handleOnSubmit = async values => {
    const payload = {
      follow_up: {
        ...addPermittedProperty(
          followUpPermittedAttributes?.["due_date"],
          {
            due_date: appendTimeZone(values.due_date, timezone),
          },
          true
        ),
        ...addPermittedProperty(
          followUpPermittedAttributes?.["comments"],
          {
            comments: values.comment,
          },
          true
        ),
        ...(!!values.type?.slug && {
          ...addPermittedProperty(
            followUpPermittedAttributes?.["follow_up_type"],
            {
              follow_up_type: values.type.slug,
            },
            true
          ),
        }),
        ...addPermittedProperty(
          followUpPermittedAttributes?.["assignee.id"],
          {
            assignee_id: values?.assignee?.id,
          },
          true
        ),
      },
    };

    setLoading(true);
    const modelId = props.leadId ?? props.taskId;
    const post = props.leadId ? createLeadFollowUp : createTaskFollowUp;
    const response = await post(modelId, { ...payload });
    if (response && response.success) {
      dispatch(fetchCrmTaskFollowUps());
      Toast.success(response.message);
      setVisible(false);
      if (props.taskId) {
        dispatch(updateTaskFollowUps(response.follow_ups));
      }
      if (props.leadId) {
        props.setFollowUpsResponse([...response.follow_ups]);
      }
    } else {
      Toast.error(getErrorString(response));
    }
    setLoading(false);
  };

  const getInitialValues = () => {
    return {
      ...addPermittedProperty(followUpPermittedAttributes?.["due_date"], {
        due_date: setTimeZone(timezone, getInitialDueTime()),
      }),
      ...addPermittedProperty(followUpPermittedAttributes?.["assignee.name"], {
        assignee: currentUser,
      }),
      ...addPermittedProperty(followUpPermittedAttributes?.["comments"], {
        comments: "",
      }),
      ...addPermittedProperty(followUpPermittedAttributes?.["follow_up_type"], {
        type: null,
      }),
    };
  };

  const validationSchema = () => {
    return yup.object().shape({
      ...addPermittedProperty(followUpPermittedAttributes?.["follow_up_type"], {
        type: yup.object().nullable().required(strings.option_ph),
      }),
    });
  };

  useEffect(() => {
    formikRef.current && formikRef.current.setFieldValue("assignee", currentUser);
  }, [visible]);

  return (
    <Modal
      toggle={() => {
        setVisible(!visible);
        if (visible) setError("");
      }}
      title={!!followUpPrefilled ? strings.view_follow_up : strings.create_follow_up}
      open={visible}
      body={() => (
        <>
          <Formik
            innerRef={formikRef}
            initialValues={getInitialValues()}
            validationSchema={validationSchema()}
            onSubmit={handleOnSubmit}
          >
            {formikProps => {
              const { values, errors, touched, handleBlur, handleChange, setFieldValue } = formikProps;
              return (
                <form className="form theme-form db__form-main">
                  {fromCrmLeadFollowUpListing && !!followUpPrefilled?.crm_lead && (
                    <FormRow label={strings.lead_ref} horizontal>
                      <div
                        className="info-block-desc text-break infoItemHover"
                        onClick={e => {
                          e.preventDefault();
                          if (!hasLink) return;
                          history.push(replaceKeyWithId(CRM_LEAD_DETAIL_PATH, ":id", followUpPrefilled?.crm_lead?.id));
                        }}
                        style={{ marginTop: "11px", marginLeft: "5px" }}
                      >
                        {leadRefNumber}
                      </div>
                    </FormRow>
                  )}
                  {isFieldViewable(followUpPermittedAttributes?.["due_date"]) && (
                    <AssignDateInput
                      placeholder={strings.select_due_date}
                      label={strings.due_date}
                      selected={
                        followUpPrefilled?.due_date
                          ? setTimeZone(timezone, followUpPrefilled?.due_date)
                          : values.due_date
                          ? values.due_date
                          : null
                      }
                      onChange={value => {
                        setFieldValue("due_date", value);
                      }}
                      errorMsg={errors.due_date}
                      canUpdate={!followUpPrefilled && isFieldEditable(followUpPermittedAttributes?.["due_date"])}
                      canClear
                    />
                  )}
                  {isFieldViewable(followUpPermittedAttributes?.["assignee.name"]) && (
                    <PaginatedSearch
                      infoView
                      horizontal
                      label={strings.assignee}
                      toggleClassName="form-control text-truncate"
                      isSingleSelect
                      defaultSelected={followUpPrefilled?.assignee ?? values.assignee}
                      onChange={value => {
                        setFieldValue("assignee", value);
                      }}
                      selectHeading={strings.assignee}
                      showSearchIcon={false}
                      fetchOptions={(input, page) =>
                        fetchUserOptions({
                          "s[name]": input,
                          criteria: "task",
                          task_type_id: values?.task_type?.id,
                          page,
                        })
                      }
                      disabled={!!followUpPrefilled || !isFieldEditable(followUpPermittedAttributes?.["assignee.name"])}
                    />
                  )}
                  {isFieldViewable(followUpPermittedAttributes?.["follow_up_type"]) && (
                    <FormRow label={strings.type} horizontal>
                      <SelectField
                        placeholder={strings.none}
                        options={fromTaskForm ? followUpTypeList : formattedFollowUpTypeList}
                        onChange={value => {
                          setFieldValue("type", value);
                        }}
                        value={
                          followUpPrefilled?.follow_up_type
                            ? followUpTypeList.find(item => item.name === followUpPrefilled?.follow_up_type)
                            : values.type
                        }
                        isSearchable
                        onBlur={handleBlur("type")}
                        errorMsg={!!errors?.type && !!touched?.type && errors.type}
                        errorClassName={"ml-2"}
                        isDisabled={
                          !!followUpPrefilled || !isFieldEditable(followUpPermittedAttributes?.["follow_up_type"])
                        }
                      />
                    </FormRow>
                  )}
                  {isFieldViewable(followUpPermittedAttributes?.["comments"]) && (
                    <FormRow label={strings.comments} horizontal>
                      <FormInput
                        placeholder={strings.enter_your_comments}
                        onChange={handleChange("comment")}
                        onBlur={handleBlur("comment")}
                        value={followUpPrefilled?.comments ?? values.comment}
                        errorMsg={errors.comment && touched.comment && errors.comment}
                        linesCount="3"
                        horizontal
                        columnRightSection="12"
                        isEditable={isFieldEditable(followUpPermittedAttributes?.["comments"])}
                        disabled={!!followUpPrefilled || !isFieldEditable(followUpPermittedAttributes?.["comments"])}
                      />
                    </FormRow>
                  )}
                </form>
              );
            }}
          </Formik>
          {error && <CustomAlert message={error} />}
        </>
      )}
      actions={
        !followUpPrefilled
          ? [
              {
                label: strings.cancel,
                onClick: () => {
                  setVisible(false);
                  if (visible) setError("");
                },
                color: "secondary",
                className: "modal__cancel-btn btn-pill",
              },
              {
                label: strings.create,
                color: "primary",
                className: "modal__cr-btn btn-pill",
                onClick: () => {
                  formikRef.current && formikRef.current.handleSubmit();
                },
                disabled: !!followUpPrefilled,
                loading: loading,
              },
            ]
          : []
      }
    />
  );
};

const FollowUpsListModal = props => {
  const { visible, setVisible, list, followUpStatuses } = props;
  return (
    <Modal
      toggle={() => {
        setVisible(!visible);
      }}
      title={strings.follow_up}
      open={visible}
      body={() => (
        <>
          <div className={cx("flex flex-column")} style={{ height: "500px", overflowY: "scroll" }}>
            {!!list?.length &&
              list?.map(followUp => {
                return (
                  <React.Fragment key={followUp.reference_number}>
                    <FollowUpCard
                      followUp={followUp}
                      followUpStatuses={followUpStatuses}
                      isStatusDisabled={followUp?.status?.slug !== "due"}
                    />
                  </React.Fragment>
                );
              })}
          </div>
        </>
      )}
      actions={[
        {
          label: strings.close,
          onClick: () => {
            setVisible(false);
          },
          color: "secondary",
          className: "modal__cancel-btn btn-pill",
        },
      ]}
    />
  );
};

const FollowUpCard = props => {
  const { followUp, followUpStatuses, isStatusDisabled, fromTaskForm = false } = props;
  const { assignee, comments, due_date, follow_up_type, id, reference_number, status } = followUp;
  const timezone = useSelector(selectTimeZone);
  const followUps = useSelector(state => state.TaskDetail.follow_ups);
  const formatDateString = date => moment(date).format("MMMM DD, YYYY");
  let dueDate = !isFieldRestricted(due_date) ? formatDateString(setTimeZone(timezone, due_date)) : null;

  return (
    <div className={styles.followUpCard}>
      <div className={cx("d-flex align-items-center justify-content-between", styles.header)}>
        {!isFieldRestricted(reference_number) && <span className={styles.refNo}> {`Ref # ${reference_number}`}</span>}
        {!isFieldRestricted(assignee) && !isFieldRestricted(assignee?.first_name) && (
          <Avatar avatarOnly name={assignee?.first_name?.split(" ")[0]} size={30} />
        )}
      </div>

      <div className={styles.cardBody}>
        {!isFieldRestricted(dueDate) && (
          <div className="d-flex align-items-center justify-content-between">
            <span className={styles.label}>Due Date</span>
            <div>{dueDate}</div>
          </div>
        )}

        {!isFieldRestricted(follow_up_type) && (
          <div className="d-flex align-items-center justify-content-between">
            <span className={styles.label}>Type</span>
            <div>{follow_up_type}</div>
          </div>
        )}
        {!isFieldRestricted(status) && !isFieldRestricted(status?.name) && (
          <div className="d-flex align-items-center justify-content-between">
            <span className={styles.label}>Status</span>
            <FollowUpTypeStatusInput
              followUpId={id}
              status={status}
              statuses={followUpStatuses}
              canUpdate={!isStatusDisabled}
              followUps={followUps}
              fromTaskForm={fromTaskForm}
            />
          </div>
        )}

        {!isFieldRestricted(comments) && (
          <FormRow label={strings.comments} labelClassName={styles.label} containerClassName={"mb-0"}>
            <FormInput
              isEditable={false}
              value={comments}
              errorMsg={false}
              linesCount="3"
              columnRightSection="9"
              containerClassName={"mb-0"}
            />
          </FormRow>
        )}
      </div>
    </div>
  );
};

export default FollowUp;
