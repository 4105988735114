import moment from "moment";
import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { IoIosCalendar } from "../svg-icon/svg-icons";
import { useSelector } from "react-redux";
import { updateCRMTaskAttributesApi } from "../../helpers/ApiHelper";
import { appendTimeZone, checkUndefinedApiParams, getErrorString, setTimeZone } from "../../helpers/Util";
import { Skeleton, Toast } from "../common";

const AssignDateInput = ({
  onChange,
  placeholder,
  initialValue,
  taskId,
  errorMsg,
  loading,
  canUpdate,
  label,
  canClear,
  onSuccess,
  labelClass,
  noTimeFilter,
  inputClassName = "border-0",
  fetchCrmLeadActivities,
  crmLeadId,
  ...rest
}) => {
  const timeZone = useSelector(content => content.Configs.tenant.country.timezone);
  const [date, setDate] = useState(initialValue ? setTimeZone(timeZone, initialValue) : null);
  const [changeDate, handleDateChange] = useState(setTimeZone(timeZone));
  const postData = async date => {
    if (!checkUndefinedApiParams(taskId, "task")) return;
    let obj = {
      task: {
        due_date: appendTimeZone(date, timeZone),
      },
    };
    const response = await updateCRMTaskAttributesApi(taskId, obj, "change_due_date");
    if (response) {
      if (response.task) {
        onSuccess(date, response.task);
        fetchCrmLeadActivities && fetchCrmLeadActivities(crmLeadId);
      } else {
        Toast.error(getErrorString(response));
      }
    }
  };

  const calculateMinTime = date => {
    let isToday = moment(setTimeZone(timeZone, date)).isSame(moment(), "day")
      ? moment(setTimeZone(timeZone)).toDate()
      : moment(setTimeZone(timeZone)).startOf("day").toDate();
    return isToday;
  };

  const CustomInput = forwardRef((props, ref) => {
    return (
      <input
        {...props}
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        ref={ref}
        className={inputClassName}
        style={{ cursor: canUpdate ? "pointer" : "default" }}
      />
    );
  });
  const renderComponent = () =>
    loading ? (
      <Skeleton height={30} />
    ) : (
      <>
        <div className="media flex-column d-flex">
          <div className={`${"d-flex align-items-center"} ${canUpdate && canClear ? "custom-field-state b-r-6" : ""}`}>
            <div className="media-icon simple bordered">
              <IoIosCalendar />
            </div>
            <DatePicker
              portalId={onSuccess || onChange ? undefined : "root"}
              selected={initialValue ? initialValue : null}
              showTimeSelect
              onChange={date => {
                if (onChange) {
                  onChange(date);
                  handleDateChange(date);
                } else {
                  setDate(date);
                  postData(date);
                  handleDateChange(date);
                }
              }}
              disabled={!canUpdate}
              placeholderText={placeholder}
              dateFormat="h:mm a, MMM dd, yyyy"
              {...rest}
              minDate={setTimeZone(timeZone)}
              minTime={!noTimeFilter && calculateMinTime(changeDate)}
              maxTime={!noTimeFilter && moment().endOf("day").toDate()}
              customInput={<CustomInput />}
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ["bottom"], // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false, // turn off since needs preventOverflow to be enabled
                },
              }}
            />
          </div>
          {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
        </div>
      </>
    );

  return label ? (
    <div className="form-group row">
      <label className={`col-sm-3 col-form-label ${labelClass}`}>{loading ? <Skeleton height={20} /> : label}</label>
      <div className="col-sm-9">{renderComponent()}</div>
    </div>
  ) : (
    renderComponent()
  );
};

export default AssignDateInput;
