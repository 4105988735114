import {
  ADD_FILTERS,
  ADD_LEAD,
  CLEAR_LEADS,
  FETCH_LEADS,
  SET_LEADS_LOADING,
  UPDATE_LEAD,
} from "../../constant/actionTypes";

export const INITIAL_STATE = {
  leads: [],
  // filters: {
  //   status_id: "",
  //   id: "",
  //   client_phone: "",
  //   client_name: "",
  // },
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  leadSources: [],
  linkStatuses: [],
  statuses: [],
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_LEAD:
      return {
        ...state,
        leads: [action.payload.item].concat([...state.leads]),
      };
    case UPDATE_LEAD:
      const leadList = state.leads;
      let index = leadList.findIndex(e => e.id === action.payload.item.id);
      leadList.splice(index, 1, { ...action.payload.item });
      return {
        ...state,
        leads: [...leadList],
      };
    case FETCH_LEADS:
      return {
        ...state,
        ...action.payload,
      };

    case ADD_FILTERS:
      return {
        ...state,
        leads: [],
        pagination: {
          current_page: 1,
          next_page: null,
          prev_page: null,
        },
        loading: true,
        error: "",
        filters: { ...action.payload.item },
      };
    case CLEAR_LEADS:
      return {
        ...state,
        INITIAL_STATE,
      };
    case SET_LEADS_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    default:
      return state;
  }
};
