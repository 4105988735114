import React, { useState, useEffect, useRef } from "react";

export function useInterval(props) {
  const { callback, delay, conditionals = true, deps } = props;
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback?.current && savedCallback.current();
    }
    if (conditionals) {
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => {
          clearInterval(id);
        };
      }
    }
  }, [delay, conditionals, deps]);
}
export default useInterval;
