import React from "react";

import { strings } from "../../constant/strings";
import { Avatar, FormInput, IF, Modal, Status } from "../common";

const ViewNegotiationModal = ({ data, showModal, setShowModal, auctionUser }) => {
  const negotiationOffer = data?.negotiation_offers?.find(offer => offer?.id === showModal);

  const dreClient = data?.started_by;
  const purchaserClient = data?.seller_lead;

  const isAuctionDre = !!auctionUser?.DRE;
  const isPurchaser = !!auctionUser?.Purchaser;

  const client = (isAuctionDre ? dreClient : isPurchaser ? purchaserClient : "") ?? "";

  console.log({ negotiationOffer });

  return (
    <div>
      <Modal
        toggle={() => setShowModal(null)}
        title={
          <div>
            {strings.negotiated_offer}
            {/* <Status {...data?.status} /> */}
          </div>
        }
        open={!!showModal}
        // scrollable={true}
        body={() => (
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <div
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar name={client?.name} avatarOnly />
                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <span style={{ fontWeight: "bolder", fontSize: 16 }}>{client?.name}</span>
                  <span>{client?.email}</span>
                </div>
              </div>
              <div>
                <Status name={negotiationOffer?.negotiation_offer_type} />
              </div>
            </div>
            <div>
              {/* TODO: decide what offers do we want to show information ?*/}
              <div style={{ width: "100%" }}>
                <FormInput
                  label={strings.negotiated_offer}
                  placeholder={strings.negotiated_offer}
                  value={"24,000"}
                  isEditable={false}
                />
              </div>
              <div style={{ width: "100%" }}>
                <FormInput
                  label={strings.counter_offer}
                  placeholder={strings.enter_counter_offer}
                  value={"25,000"}
                  isEditable={false}
                />
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default ViewNegotiationModal;
