import {
  ADD_DYNAMIC_FIELD,
  DELETE_DYNAMIC_FIELD,
  FETCH_DYNAMIC_FIELDS,
  UPDATE_DYNAMIC_FIELD,
  SET_DYNAMIC_FIELDS_LOADING,
} from "../../constant/actionTypes";

export const INITIAL_STATE = {
  dynamicFields: [],
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  loading: true,
  error: "",
  permittedMeta: {},
  supportedFieldTypes: [],
  supportedSectionsList: [],
  dynamicModel: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DYNAMIC_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_DYNAMIC_FIELD:
      const dynamicFields = state.dynamicFields;
      dynamicFields.unshift(action.payload.dynamicField);

      return {
        ...state,
        dynamicFields,
      };
    case UPDATE_DYNAMIC_FIELD: {
      const dynamicFields = state.dynamicFields;
      let index = dynamicFields.findIndex(e => e.id === action.payload.dynamicField.id);
      dynamicFields[index] = action.payload.dynamicField;
      return {
        ...state,
        dynamicFields,
      };
    }

    case DELETE_DYNAMIC_FIELD: {
      const dynamicFields = state.dynamicFields;
      let index = dynamicFields.findIndex(e => e.id === action.payload.dynamicField.id);
      dynamicFields.splice(index, 1);
      return {
        ...state,
        dynamicFields,
      };
    }

    case SET_DYNAMIC_FIELDS_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }

    default:
      return state;
  }
};
