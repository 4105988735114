import React, { FC, useState } from "react";
import cx from "clsx";
import { IF, Permissions, Toast } from "../../common";
import ButtonLoading from "../ButtonLoading";
import { CallIcon } from "../../svg-icon/svg-icons";
import { strings } from "../../../constant/strings";
import { selectLoggedInUser } from "../../../reducers/login/selectors";
import { selectTenant } from "../../../reducers/general/selectors";
import { callDialerApi, initiatePropGoCallApi, viewPhoneNumber } from "../../../helpers/ApiHelper";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { getErrorString } from "helpers/Util";
import CallPhoneRow from "./CallPhoneRow";
import useOnScroll from "hooks/useOnScroll/useOnScroll";
import styles from "./CallButton.module.scss";
import { RESTRICTED_MODULES } from "constant/Common";

export interface CallButtonProps {
  buttonIcon?: React.ComponentType;
  buttonTitle?: string;
  showButtonTitle?: boolean;
  size: "sm" | "lg" | "xs";
  buttonColor?: string;
  buttonVariant?: string;
  buttonStyle?: React.CSSProperties;
  buttonClassName?: string;
  uuid: string;
  associatedId: string;
  associatedType: string;
  associatedComponent: string;
  insLeadId?: string;
  crmLeadId?: string;
  inventoryId?: string;
  taskId?: string;
  onScrollElement?: HTMLElement;
  isMenuDirectionRight?: boolean;
}

const CallButton: FC<CallButtonProps> = ({
  buttonIcon,
  buttonTitle = strings.call,
  showButtonTitle = true,
  size = "sm",
  buttonColor = "primary",
  buttonVariant = "",
  buttonStyle,
  buttonClassName = "",
  uuid,
  associatedId,
  associatedType,
  associatedComponent,
  insLeadId = null,
  crmLeadId = null,
  inventoryId = null,
  taskId = null,
  onScrollElement = null,
  isMenuDirectionRight = false,
}) => {
  const [callLoader, setCallLoader] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [phoneData, setPhoneData] = useState<{ primary_number?: string; secondary_number?: string }>({});
  const user: any = useSelector(selectLoggedInUser);
  const tenant: any = useSelector(selectTenant);
  const restrictedModules = tenant?.configuration?.restricted_modules;
  const isRecordingRestricted = restrictedModules?.includes(RESTRICTED_MODULES.RECORDING);
  const toggle = event => {
    setDropdownOpen(prevState => !prevState);
    event?.preventDefault();
  };
  useOnScroll({ handleScroll: () => setDropdownOpen(false), onScrollElement: onScrollElement });

  const loadPhoneNumbers = async () => {
    if (dropdownOpen) return;

    if (!associatedId || !uuid) {
      Toast.error("Incomplete Arguments to view phone number! ");
      toggle(null);
      setPhoneData({});
      return;
    }
    setPhoneLoading(true);
    try {
      let data = await viewPhoneNumber({
        uuid: uuid,
        associatedId: associatedId,
        associatedType: associatedType,
        component: associatedComponent,
      });
      if (data && data?.success) {
        setPhoneData({
          primary_number: data?.phone,
          secondary_number: data?.secondary_phone,
        });
      } else {
        toggle(null);
        setPhoneData({});
        Toast.error("API Error while fetching phone number data");
      }
    } catch (error) {
      toggle(null);
      setPhoneData({});
      Toast.error(getErrorString(error));
    } finally {
      setPhoneLoading(false);
    }
  };

  const toggleCallButton = async event => {
    toggle(event);
    await loadPhoneNumbers();
  };

  const submitCzCall = async (phoneNumber = "") => {
    if (!user?.user_setting?.extension_number) {
      return Toast.error("Agent extension number does not exist");
    }
    if (!insLeadId && !crmLeadId && !taskId) {
      return Toast.error("Atleast one refrence ID required");
    }
    setCallLoader(true);
    toggle(null);
    const linkToOpen = callDialerApi({
      agentId: user?.["user_setting"]?.extension_number,
      phoneNumber: phoneNumber,
      crmLeadId,
      inventoryId,
      taskId,
      insLeadId,
    });
    try {
      const response = await axios.get(linkToOpen);
      if (response.data.response.reason === "Call In Progress") {
        setCallLoader(false);
        Toast.success(response.data.response.reason);
      } else {
        setCallLoader(false);
        Toast.error(response.data.response.reason);
      }
    } catch (e) {
      console.log("-> error", e);
      setCallLoader(false);
      Toast.error(e.message);
    }
  };

  const submitGoDialerNotification = async () => {
    setCallLoader(true);
    toggle(null);
    try {
      const payload = {
        inventory_id: inventoryId,
        task_id: taskId,
        inspection_lead_id: insLeadId,
        crm_lead_id: crmLeadId,
      };
      const res = await initiatePropGoCallApi(payload);
      if (res && res?.success) {
        Toast.success(strings.prop_go_initiate_success);
      } else {
        Toast.error(getErrorString(res));
      }
    } catch (error) {
      Toast.error(getErrorString(error));
    } finally {
      setCallLoader(false);
    }
  };

  return (
    <IF condition={!isRecordingRestricted}>
      <Permissions catagories="Recording" actions="create">
        <Dropdown isOpen={dropdownOpen} toggle={toggleCallButton} key={associatedId}>
          <DropdownToggle tag="span" aria-haspopup>
            <ButtonLoading
              color={buttonColor}
              icon={buttonIcon ?? <CallIcon />}
              size={size}
              variant={buttonVariant}
              style={buttonStyle ?? {}}
              disabled={callLoader}
              className={cx(styles.callBtnContainer, buttonClassName)}
              loading={callLoader}
            >
              {showButtonTitle && buttonTitle}
            </ButtonLoading>
          </DropdownToggle>
          <DropdownMenu container="body" className={cx("m-t-20", styles.menuContainer)} right={isMenuDirectionRight}>
            <DropdownItem text>
              <CallPhoneRow
                phoneNumber={phoneData?.primary_number}
                phoneLoading={phoneLoading}
                submitCzCall={submitCzCall}
                submitGoCall={submitGoDialerNotification}
              />
            </DropdownItem>
            <IF condition={phoneData?.secondary_number}>
              <DropdownItem text>
                <CallPhoneRow
                  phoneNumber={phoneData?.secondary_number}
                  phoneLoading={phoneLoading}
                  submitCzCall={submitCzCall}
                  submitGoCall={submitGoDialerNotification}
                />
              </DropdownItem>
            </IF>
          </DropdownMenu>
        </Dropdown>
      </Permissions>
    </IF>
  );
};

export default CallButton;
