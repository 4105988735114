import { PaginatedSearch } from "components/common/PaginatedSearch";
import { TASK_MEETING_TYPES } from "constant/appConstants";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { strings } from "../../constant/strings";
import { fetchLocationAssigneesApi } from "../../helpers/ApiHelper";
import {
  appendObjectToQuery,
  capitalize,
  formatArrayWithStringsForReactSelect,
  isFieldEditable,
  isFieldRestricted,
  isFieldViewable,
  parseLocationType,
  setTimeZone,
} from "../../helpers/Util";
import {
  ButtonLoading,
  CheckboxComponent,
  DropdownSelect,
  DynamicListSelect,
  FormInput,
  IF,
  SelectField,
} from "../common";
import AreaSelectModal from "../common/AreaSelectModal";
import { GrTextAlignFull, ImLocation, LanguagePreferrenceIcon } from "../svg-icon/svg-icons";
import MeetingTime from "./task-form/MeetingTime";

const fetchResponseKey = "available_users";

const AddMeetingForm = props => {
  const {
    rescheduleMeeting = null,
    onDone,
    newMeeting,
    setFieldValue,
    saveMeetingLoading,
    taskId,
    showMeetingCalender,
    inventory,
    errors,
    assignee,
    crmTaskPermittedAttributes,
    values,
  } = props;

  const [inventoryAddresses, setInventoryAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [meetingArea, setMeetingArea] = useState({});
  const showAutoAssignmentCheckBox = values?.task_type?.auto_assignment === true;
  const tenant = useSelector(content => content.Configs.tenant);
  const [isBranchAndRepresentation, setIsBranchAndRepresentation] = useState(false);
  const isTenantDubizzle = tenant.slug ? tenant.slug.includes("dubizzle") : false;

  const autoFillRescheduledMeetingFields = () => {
    if (rescheduleMeeting) {
      newMeeting.id = rescheduleMeeting.id;
      if (rescheduleMeeting.time_slot) {
        let sellerAddress =
          inventory?.time_slots &&
          !isFieldRestricted(inventory?.time_slots) &&
          inventory?.time_slots.find(timeSlot => timeSlot.id === rescheduleMeeting.time_slot.id);
        if (sellerAddress) {
          newMeeting.seller_address = sellerAddress;
        }
      }
      if (rescheduleMeeting.area) {
        setMeetingArea({
          ...rescheduleMeeting.meeting_area,
          area: rescheduleMeeting.area,
        });
      }
      newMeeting.comments = rescheduleMeeting.comments;
      newMeeting.start_time = rescheduleMeeting.start_time;
      newMeeting.end_time = rescheduleMeeting.end_time;
      setFieldValue("newMeeting", newMeeting);
      setFieldValue("assignee", rescheduleMeeting.user);
    }
  };

  useEffect(() => {
    autoFillRescheduledMeetingFields();
  }, [rescheduleMeeting]);

  useEffect(() => {
    inventory && setInventoryAddresses(inventory.time_slots);
  }, [inventory]);

  //reset everything when tab changes
  useEffect(() => {
    if (!rescheduleMeeting) {
      setFieldValue("language", null);
      setFieldValue("assignee", null);
    }
  }, [props.type]);

  useEffect(() => {
    const locationType = parseLocationType(rescheduleMeeting);
    if (rescheduleMeeting && locationType === props.type) {
      autoFillRescheduledMeetingFields();
    } else {
      newMeeting.comments = "";
      setFieldValue("newMeeting", newMeeting);
      setFieldValue("language", null);
      setFieldValue("assignee", null);
    }
  }, [props.type]);

  useEffect(() => {
    if (meetingArea) {
      newMeeting.meeting_area = meetingArea.area;
      if (meetingArea?.area?.location_id && values?.task_type?.slug === "representation") {
        setIsBranchAndRepresentation(true);
        const meetingObjCopy = { ...newMeeting, autoAssign: true, start_time: "", end_time: "" };
        setFieldValue("newMeeting", meetingObjCopy);
      } else {
        setIsBranchAndRepresentation(false);
        const meetingObjCopy = { ...newMeeting, autoAssign: false, start_time: "", end_time: "" };
        setFieldValue("newMeeting", meetingObjCopy);
      }
    }
  }, [meetingArea]);

  const handleAssigneeChange = value => {
    resetMeetingTime();
    setFieldValue("assignee", value);
    if (newMeeting.autoAssign === true) {
      const meetingObjCopy = { ...newMeeting, autoAssign: false };
      setFieldValue("newMeeting", meetingObjCopy);
    }
    // showTimeSlots();
  };

  const resetMeetingTime = () => {
    [newMeeting.start_time, newMeeting.end_time] = [null, null];
    setFieldValue("newMeeting", newMeeting);
  };

  const onChangeDropdownSellerAddress = e => {
    const item = {
      inventory_id: inventory?.id,
    };
    [newMeeting.start_time, newMeeting.end_time] = [null, null];
    newMeeting.seller_address = { ...e, ...item };
    setFieldValue("newMeeting", newMeeting);
  };

  const timezone = useSelector(content => content.Configs.tenant.country.timezone);
  const preferredLanguagesList = useSelector(content => content.Configs.tenant?.configuration?.supported_languages);

  const Address = ({ city, area, address, start_time, end_time }) => (
    <div className="w-100 seller-address-data">
      <div className="d-flex flex-row align-items-center">
        <div className="d-inline-flex location">
          {area?.name}
          {area?.name ? ", " : ""}
          {city?.name}
        </div>
      </div>
      <div className="date">
        {moment(setTimeZone(timezone, start_time)).format("hh:mmA")} -{" "}
        {moment(setTimeZone(timezone, end_time)).format("hh:mmA")}
      </div>
      <div className="address">{address}</div>
    </div>
  );

  const getAssigneePropsOnType = () => {
    switch (props.type) {
      case TASK_MEETING_TYPES.AREA:
        return {
          assigneeQueryParams: `?area_id=${newMeeting?.meeting_area?.id || ""}&task_type_id=${values?.task_type?.id}${
            values?.language ? `&language_preference=${values?.language}` : ""
          }`,
          assigneeDisabled: !newMeeting?.meeting_area,
        };
      case TASK_MEETING_TYPES.SELLER_ADDRESS:
        return {
          assigneeQueryParams: `?seller_address_id=${newMeeting?.seller_address?.id}&task_type_id=${
            values?.task_type?.id
          }${values?.language ? `&language_preference=${values?.language}` : ""}`,
          assigneeDisabled: !newMeeting?.seller_address,
        };
      default:
        return {};
    }
  };

  const { assigneeQueryParams, assigneeDisabled } = getAssigneePropsOnType();

  const onSelectCityArea = item => {
    setMeetingArea(item);
    setFieldValue("assignee", null);
    setFieldValue("language", null);
    resetMeetingTime();
  };

  const renderNoAddressAvailable = () => (
    <div className="d-flex align-items-center justify-content-center m-4">
      <small>
        {strings.no_seller_address_msg} {inventory?.inventoriable?.reference_number}!
      </small>
    </div>
  );

  const DynamicListSelectOnMeetingType = () => (
    <>
      <DynamicListSelect
        values={newMeeting?.meeting_area ? meetingArea : {}}
        label={<ImLocation />}
        answer_key="id"
        levels={["city", "area"]}
        type="generic_list"
        formFieldKey="meeting_area"
        // saveObjAsValue
        onSelectItem={onSelectCityArea}
        containerClass="d-flex flex-row align-items-center meeting-field area-select area-select-tab"
        labelContainerClass="d-flex align-items-center justify-content-center m-b-0 media-icon"
        inputContainerClass="d-flex flex-grow-1 area-select-input"
        onClose={item => {
          newMeeting.meeting_area = null;
          setFieldValue("newMeeting", newMeeting);
          setFieldValue("assignee", null);
          setMeetingArea({});
          resetMeetingTime();
        }}
        noSelectIcon
        selectInnerClassName="flex-grow-1 meeting-field-inner"
        placeholder={strings.select_area}
        allSelected
        setValueAsSelected
        errorMsg={errors?.meetingArea || ""}
        disabled={!isFieldEditable(crmTaskPermittedAttributes?.["appointments.area.name"])}
        SelectModal={AreaSelectModal}
      />
    </>
  );

  const getDropDownData = () => {
    switch (props.type) {
      case TASK_MEETING_TYPES.SELLER_ADDRESS:
        return {
          label: strings.select_seller_address,
          options: inventoryAddresses,
          changeHandler: onChangeDropdownSellerAddress,
          RenderItem: Address,
          selectedValue: newMeeting.seller_address,
        };
      default:
        return {};
    }
  };

  const DropdownSelectOnMeetingType = () => {
    let shouldRenderDropdown = [TASK_MEETING_TYPES.SELLER_ADDRESS].includes(props.type);
    if (!shouldRenderDropdown) return null;

    const { label, options, RenderItem, changeHandler, selectedValue } = getDropDownData();
    if (
      TASK_MEETING_TYPES.SELLER_ADDRESS &&
      !isFieldViewable(crmTaskPermittedAttributes?.["appointments.time_slot.address"])
    )
      return null;
    let dropdownDisabledKey = "appointments.time_slot.address";
    return (
      <DropdownSelect
        label={label}
        labelAsValue={false}
        options={options}
        onChange={e => changeHandler(e)}
        renderToggleItem={option => <RenderItem {...option} />}
        renderMenuItem={option => <RenderItem {...option} />}
        className="w-100 seller-address-outer m-b-15"
        toggleClassName="w-100 seller-address-toggle-button"
        menuStyles="w-100"
        listLoading={loading}
        listError={error}
        selected={selectedValue}
        errorMsg={errors?.sellerAddress || errors?.location || ""}
        disabled={!isFieldEditable(crmTaskPermittedAttributes?.[dropdownDisabledKey])}
      />
    );
  };

  return props.type === TASK_MEETING_TYPES.SELLER_ADDRESS && !inventoryAddresses?.length ? (
    renderNoAddressAvailable()
  ) : (
    <>
      {isFieldViewable(crmTaskPermittedAttributes?.["appointments.area.name"]) && (
        <IF condition={props.type === TASK_MEETING_TYPES.AREA}>
          <DynamicListSelectOnMeetingType />
        </IF>
      )}
      <DropdownSelectOnMeetingType />
      {isTenantDubizzle ? (
        <SelectField
          label={<LanguagePreferrenceIcon />}
          placeholder={strings.preferred_language_ph}
          options={formatArrayWithStringsForReactSelect(preferredLanguagesList)}
          onChange={selected => {
            if (selected) {
              setFieldValue("language", selected.value);
            } else {
              setFieldValue("language", "");
            }
          }}
          value={values.language ? [{ value: values?.language }] : []}
          formatOptionLabel={option => capitalize(option.value)}
          isSearchable
          isEditable={true}
          hideDropDownIndicator={true}
          transparentBorder={true}
          labelClassName="d-flex align-items-center justify-content-center m-b-0 media-icon bordered add-meeting-select-label"
          containerClassName="d-flex align-items-center py-0 px-3"
          removeFormGroup={true}
        />
      ) : (
        ""
      )}
      {isFieldViewable(crmTaskPermittedAttributes?.["task_type.is_out_of_zone"]) &&
        values?.task_type?.is_out_of_zone &&
        !values?.newMeeting?.autoAssign && (
          <CheckboxComponent
            label={strings.out_of_zone}
            checked={values?.newMeeting?.outOfZone || ""}
            onChange={event => {
              const meetingObjCopy = { ...newMeeting, outOfZone: event.target.checked };
              setFieldValue("newMeeting", meetingObjCopy);
              setFieldValue("assignee", null);
            }}
            horizontal={true}
            disabled={!isFieldEditable(crmTaskPermittedAttributes?.["task_type.is_out_of_zone"])}
            mainStyles={{ marginLeft: "1.2rem", marginTop: "0.5rem", marginBottom: "0.5rem" }}
            labelStyles={{ marginLeft: "0.9rem", color: "#9da7b0", fontWeight: "400" }}
          />
        )}
      {showAutoAssignmentCheckBox &&
        !isBranchAndRepresentation &&
        !values?.newMeeting?.outOfZone &&
        isFieldViewable(crmTaskPermittedAttributes?.["appointments.user.name"]) && (
          <CheckboxComponent
            label={strings.auto_assign}
            checked={values?.newMeeting?.autoAssign || ""}
            onChange={event => {
              const meetingObjCopy = {
                ...newMeeting,
                autoAssign: event.target.checked,
                outOfZone: false,
                start_time: "",
                end_time: "",
              };
              setFieldValue("newMeeting", meetingObjCopy);
              setFieldValue("assignee", null);
            }}
            horizontal={true}
            disabled={assigneeDisabled || !isFieldEditable(crmTaskPermittedAttributes?.["appointments.user.name"])}
            mainStyles={{ marginLeft: "1.2rem", marginTop: "0.5rem", marginBottom: "0.5rem" }}
            labelStyles={{ marginLeft: "0.9rem", color: "#9da7b0", fontWeight: "400" }}
          />
        )}
      {isFieldViewable(crmTaskPermittedAttributes?.["appointments.user.name"]) && !isBranchAndRepresentation && (
        <div className="d-flex align-items-center" style={{ padding: "0.893rem 0.857rem" }}>
          <PaginatedSearch
            rowClass="mb-0 d-inline-flex"
            infoView
            showLabel={false}
            toggleClassName="form-control text-truncate"
            isSingleSelect
            defaultSelected={assignee}
            selectHeading={strings.assignee}
            id={taskId}
            showSearchIcon={false}
            loading={false}
            disabled={
              !isFieldEditable(crmTaskPermittedAttributes?.["appointments.user.name"]) ||
              assigneeDisabled ||
              newMeeting.autoAssign === true
            }
            onChange={handleAssigneeChange}
            fetchOptions={(input, page) => {
              const query =
                appendObjectToQuery({ "s[name]": input, page }) +
                (values?.newMeeting?.outOfZone ? "&out_of_zone=true" : "");
              return fetchLocationAssigneesApi(assigneeQueryParams + `&${query}`);
            }}
            payloadKey={"task"}
          />
          {newMeeting?.autoAssign === true && values.assignee?.is_over_booked === true && (
            <span className="meetingAssigneeStatus">{"(Overbooking this user)"}</span>
          )}
        </div>
      )}
      {isFieldViewable(crmTaskPermittedAttributes?.["appointments.start_time"]) &&
        isFieldViewable(crmTaskPermittedAttributes?.["appointments.end_time"]) && (
          <MeetingTime
            type={props.type}
            timezone={timezone}
            newMeeting={newMeeting}
            showMeetingCalender={showMeetingCalender}
            inventory={inventory}
            errors={errors?.meetingTime || ""}
            disabled={!newMeeting.autoAssign && !props?.assignee}
            values={props.values}
            isMeetingRescheduled={!!rescheduleMeeting}
          />
        )}
      {isFieldViewable(crmTaskPermittedAttributes?.["appointments.description"]) && (
        <FormInput
          label={<GrTextAlignFull />}
          placeholder={strings.description_ph}
          value={newMeeting.comments}
          onChange={e => {
            newMeeting.comments = e.target.value;
            setFieldValue("newMeeting", newMeeting);
          }}
          containerClassName="d-flex flex-row align-items-center meeting-field py-1 px-3"
          labelClassName="d-flex align-items-center justify-content-center m-b-0 media-icon bordered"
          inputContainerClass="d-flex flex-grow-1 seller-description-field"
          inputClassName="form-control meeting-field-inner"
          onBlur={e => {
            newMeeting.comments = e.target.value;
            setFieldValue("newMeeting", newMeeting);
          }}
          isEditable={isFieldEditable(crmTaskPermittedAttributes?.["appointments.description"])}
        />
      )}
      <div className="w-100 d-flex justify-content-end">
        <IF condition={taskId}>
          <ButtonLoading
            color="link"
            className="p-0 m-r-10 f-16"
            loading={saveMeetingLoading}
            type="button"
            disabled={saveMeetingLoading}
            onClick={() => {
              onDone({
                ...(rescheduleMeeting && { id: rescheduleMeeting?.id }),
                ...newMeeting,
              });
            }}
          >
            {strings.save}
          </ButtonLoading>
        </IF>
      </div>
    </>
  );
};

export default AddMeetingForm;
