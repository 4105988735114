import cx from "clsx";
import Button from "components/common/Button";
import { MdContentCopy } from "components/svg-icon/svg-icons";
import { copyContent } from "helpers/Util";
import React, { useState } from "react";
import { Check } from "react-feather";

const Copy = props => {
  const { text, className, ...rest } = props;
  const [isCopied, setIsCopied] = useState(false);

  const copyField = val => {
    setTimeout(() => {
      copyContent(val);
      setIsCopied(true);
    }, 200);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const renderCopyIcon = value => (
    <Button
      onClick={() => {
        if (!isCopied) copyField(value);
      }}
      disabled={isCopied}
      icon={isCopied ? <Check size={15} /> : <MdContentCopy size={15} />}
      btnText=""
      color="link"
      className={cx("p-0", className)}
    />
  );

  return text ? renderCopyIcon(text) : null;
};

export default Copy;
