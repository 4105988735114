import {
  CLEAR_CRM_TASK_DETAIL,
  FETCH_TASK_DETAIL,
  REVERT_TASK_STATUS,
  SET_TASK_DATA,
  UPDATE_TASK_REMINDERS,
  UPDATE_TASK_STATUS,
  UPDATE_FOLLOW_UPS,
} from "../../constant/actionTypes";
import { fetchTaskDetailApi } from "../../helpers/ApiHelper";
import { getErrorString, isEmptyObject } from "../../helpers/Util";

export const fetchTaskDetail = id => async dispatch => {
  dispatch({
    type: FETCH_TASK_DETAIL,
    payload: { loading: true },
  });
  const res = await fetchTaskDetailApi(id);
  if (res) {
    const { task, ...rest } = res;
    if (task) {
      dispatch({
        type: FETCH_TASK_DETAIL,
        payload: {
          task: task,
          follow_ups: task.follow_ups,
          ...rest,
          loading: false,
          error: "",
          type: "edit",
        },
      });
    } else {
      dispatch({
        type: FETCH_TASK_DETAIL,
        payload: {
          statuses: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  }
};

export const revertTaskStatus = item => async dispatch => {
  dispatch({
    type: REVERT_TASK_STATUS,
    payload: {
      ...item,
    },
  });
};

export const updateTaskStatus = params => async dispatch => {
  dispatch({
    type: UPDATE_TASK_STATUS,
    payload: {
      status: params.status,
    },
  });
};

export const updateTaskReminders = params => async dispatch => {
  dispatch({
    type: UPDATE_TASK_REMINDERS,
    payload: {
      reminders: [params.reminders],
    },
  });
};

export const updateTaskDetail = updatedTask => dispatch => {
  if (!isEmptyObject(updatedTask)) {
    dispatch({
      type: SET_TASK_DATA,
      payload: {
        task: updatedTask,
      },
    });
  }
};

export const clearCRMTaskDetail = () => dispatch => {
  dispatch({
    type: CLEAR_CRM_TASK_DETAIL,
  });
};

export const updateTaskFollowUps = followUps => dispatch => {
  dispatch({
    type: UPDATE_FOLLOW_UPS,
    payload: {
      followUps,
    },
  });
};
