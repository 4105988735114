import { IF, Skeleton, Status, Tooltip } from "../common";
import {
  FaCircle,
  AiTwotoneCalendar,
  MdFormatAlignLeft,
  MdPlace,
  MdSubdirectoryArrowRight,
} from "../svg-icon/svg-icons";
import { Avatar } from "../common/Avatar";
import React from "react";
import { getTimeDateString } from "../../helpers/Util";
import moment from "moment";
import { strings } from "../../constant/strings";
import { useSelector } from "react-redux";

const Event = ({ event, view, onClick = () => {}, showPopup, loading, height }) => {
  const {
    id,
    reference_number,
    seller_client = {},
    buyer_client = {},
    status = {},
    inventory = {},
    area = {},
    description,
    task = {},
    meeting_area,
    title,
  } = event;
  const { bg_color, color } = status;
  const timezone = useSelector(content => content.Configs.tenant.country.timezone);

  const isHalf = !(moment(event?.end_time).diff(moment(event?.start_time), "minutes") > 30);

  const monthView = view === "month";

  const renderMonthView = () => (
    <div
      className="d-flex flex-row align-items-center"
      style={{ backgroundColor: bg_color, borderColor: color }}
      onClick={() => {
        onClick(id, task.id);
      }}
      data-tip
      data-for={showPopup && `pop-${id}-${reference_number}`}
    >
      <Avatar name={event?.user?.name} size={18} round="9px" className="d-flex" avatarOnly />
      <div className="d-flex  flex-column m-l-10">
        <small className="calender-text-color">{inventory?.title || title}</small>
      </div>
    </div>
  );

  const renderClientInfo = (nameString, phoneString, name, phone) => {
    return (
      <>
        <div className="f-12 pt-2">
          {nameString}: {name}
        </div>
        <div className="f-12">
          {phoneString}: {phone}
        </div>
      </>
    );
  };

  return loading ? (
    <div key={event.start.toString()} style={{ backgroundColor: "#E0E0E0", zIndex: -1 }}>
      <Skeleton themeProps={{ color: "#E0E0E0", highlightColor: "#FAFAFA" }} height={height} duration={2} />
    </div>
  ) : (
    <>
      {monthView ? (
        renderMonthView()
      ) : (
        <div
          key={`${id}-${reference_number}`}
          style={{ backgroundColor: bg_color, borderColor: color }}
          className="d-flex flex-column align-items-start"
          id={`pop-${id}-${reference_number}`}
          onClick={() => {
            onClick(id, task.id);
          }}
          data-tip
          data-for={`pop-${id}-${reference_number}`}
        >
          <div className="d-flex flex-row">
            {event?.user?.name && (
              <Avatar name={event?.user?.name} size={24} round="12px" className="d-flex" avatarOnly />
            )}{" "}
            {isHalf ? (
              <div className="d-flex  flex-column m-l-10">
                <div className="calender-text-color inventory-title d-flex m-b-5">{inventory?.title || title}</div>
                {event?.user?.name && <div className="calender-text-color">{event?.user?.name}</div>}
              </div>
            ) : (
              <div className="d-flex  flex-column m-l-10">
                <div className="calender-text-color inventory-title d-flex m-b-5">{inventory?.title || title}</div>
                {event?.user?.name && <div className="calender-text-color">{event?.user?.name}</div>}
                <IF condition={area?.name || meeting_area?.name}>
                  <span className="calender-text-color locationIcon d-inline-flex">
                    <MdPlace /> {area?.name || meeting_area?.name}{" "}
                  </span>
                </IF>
              </div>
            )}
          </div>
        </div>
      )}
      <Tooltip id={`pop-${id}-${reference_number}`} backgroundColor="white" textColor="black">
        <div className="py-3" style={{ zIndex: 2000 }}>
          <div className="popoverfield d-flex flex-row align-items-start m-b-10">
            <div className="d-flex m-r-10 flex-shrink-0">
              <Avatar className="calender-avatar" name={event?.user?.name} size={24} round="12px" avatarOnly />
            </div>
            <div className="d-flex flex-column">
              <h6 className="f-15 m-b-5" style={{ color: "#000000" }}>
                {`${inventory?.title} (${inventory.reference_number})`}
              </h6>
              {event?.user?.name && (
                <div className="f-12">
                  {strings.assigned_to}: {event?.user?.name}
                </div>
              )}
              {seller_client?.name &&
                renderClientInfo(strings.seller_name, strings.seller_phone, seller_client?.name, seller_client?.phone)}
              {buyer_client?.name &&
                renderClientInfo(strings.buyer_name, strings.buyer_phone, buyer_client?.name, buyer_client?.phone)}
            </div>
          </div>
          {area?.name && (
            <div className="popoverfield d-flex flex-row align-items-start m-b-10">
              <div className="d-flex m-r-15 flex-shrink-0">
                <MdPlace size="1.3rem" />
              </div>
              <div className="d-flex flex-column">
                <h6 className="f-12 m-b-5  f-w-400">{strings.area}</h6>
                <div className="f-14">{area?.name}</div>
              </div>
            </div>
          )}
          {description && (
            <div className="popoverfield d-flex flex-row align-items-start m-b-10">
              <div className="d-flex m-r-15 flex-shrink-0">
                <MdFormatAlignLeft size="1.3rem" />
              </div>
              <div className="d-flex flex-column">
                <h6 className="f-12 m-b-5 f-w-400">{strings.description}</h6>
                <div className="f-14 text-break">{description}</div>
              </div>
            </div>
          )}
          <div className="popoverfield d-flex flex-row align-items-start m-b-10">
            <div className="d-flex m-r-15 flex-shrink-0">
              <MdSubdirectoryArrowRight size="1.3rem" />
            </div>
            <div className="d-flex flex-column">
              <h6 className="f-12 m-b-5 f-w-400">{strings.task}</h6>
              <div className="f-14">{task?.name}</div>
            </div>
          </div>
          <div className="popoverfield d-flex flex-row align-items-start m-b-10">
            <div className="d-flex m-r-15 flex-shrink-0">
              <AiTwotoneCalendar size="1.3rem" />
            </div>
            <div className="d-flex flex-column">
              <h6 className="f-12 m-b-5  f-w-400">{strings.start_time}</h6>
              <div className="f-14">{getTimeDateString(event.start_time, timezone, true, false, false)}</div>
            </div>
          </div>

          <div className="popoverfield d-flex flex-row align-items-start m-b-10">
            <div className="d-flex m-r-15 flex-shrink-0">
              <AiTwotoneCalendar size="1.3rem" />
            </div>

            <div className="d-flex flex-column">
              <h6 className="f-12 m-b-5  f-w-400">{strings.end_time}</h6>
              <div className="f-14">{getTimeDateString(event.end_time, timezone, true, false, false)}</div>
            </div>
          </div>

          {/*{buyer_client?.phone && (*/}
          {/*  <div className="popoverfield d-flex flex-row align-items-start m-b-10">*/}
          {/*    <div className="d-flex m-r-15 flex-shrink-0">*/}
          {/*      <AiFillPhone size="1.3rem" />*/}
          {/*    </div>*/}

          {/*    <div className="d-flex flex-column">*/}
          {/*      <h6 className="f-12 m-b-5  f-w-400">{strings.buyer_phone_number}</h6>*/}
          {/*      <div className="f-14">{buyer_client.phone}</div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className="popoverfield d-flex flex-row align-items-start">
            <div className="d-flex m-r-15 flex-shrink-0">
              <FaCircle />
            </div>
            <div className="d-flex flex-column">
              <h6 className="f-12 m-b-10 f-w-400">{strings.status}</h6>
              {status && <Status {...status} />}
            </div>
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default Event;
