import React from "react";
import cx from "clsx";
import styles from "../ChevronUsersSidebar.module.scss";
import { Avatar } from "../../../Avatar";
import { FaCircle } from "../../../../svg-icon/svg-icons";
import IF from "../../../IF";
import { strings } from "../../../../../constant/strings";
import Status from "components/common/Status";

const ChevronUserCard = ({ userData }) => {
  const { name = "", picture = {}, tasks = {} } = userData;
  return (
    <div className={cx("d-flex flex-row align-items-center", styles.userCard)}>
      <Avatar avatarOnly imgSrc={picture?.url} name={name} containerClass="mr-3" />
      <div className={cx(styles.sidebarItemDetail, "d-flex flex-column")}>
        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
          <span className="f-w-600">{name}</span>
          <FaCircle size={8} color="#12B76A" />
        </div>
        <div className={cx(styles.sidebarItemTasks, "d-flex flex-row justify-content-between")}>
          <div className=" flex-shrink-0">
            <span className="text-muted">{strings.due}:</span> {tasks?.due ?? 0}
          </div>
          <div className=" flex-shrink-0">
            <span className="text-muted">{strings.done}:</span> {tasks?.completed ?? 0}
          </div>
          <div className="flex-shrink-0">
            <span className="text-muted">{strings.total_tasks}:</span> {tasks?.total ?? 0}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChevronUserSection = ({ chevronUsersData }) => {
  const sectionHeader = (usersSection, sectionCount) => (
    <div className="d-flex align-items-center">
      <div className={"text-truncate f-w-600"}>{usersSection}</div>
      <Status className={cx(styles.totalCount)} color={"#dc6803"} bg_color={"#fef0c7"} name={`${sectionCount}`} />
    </div>
  );

  return (
    <div className={cx(styles.sidebarItem)}>
      <IF condition={chevronUsersData}>
        {Object.keys(chevronUsersData).map(usersSection => (
          <div key={usersSection} className="m-t-20">
            {sectionHeader(usersSection, chevronUsersData?.[usersSection]?.length || 0)}
            <IF condition={chevronUsersData?.[usersSection]?.length}>
              {chevronUsersData[usersSection].map(sectionUser => (
                <ChevronUserCard key={sectionUser?.id} userData={sectionUser} />
              ))}
            </IF>
          </div>
        ))}
      </IF>
    </div>
  );
};

export default ChevronUserSection;
