import { Redirect, Route, withRouter } from "react-router-dom";

import { LOGIN_PATH } from "../constant/appPaths";
import { Permissions } from "../components/common";
import React from "react";

class ProtectedRoute extends React.PureComponent {
  render() {
    const { component: Component, user, type, catagories, actions, history, ...rest } = this.props;
    return catagories ? (
      <Permissions catagories={catagories} actions={actions}>
        <Route
          {...rest}
          render={props => {
            if (user === true) {
              return <Component {...props} type={type} />;
            }
            return (
              <Redirect
                to={{
                  pathname: LOGIN_PATH,
                  search: history?.location?.pathname ? `?redirect_to=${history.location.pathname}` : "",
                }}
              />
            );
          }}
        />
      </Permissions>
    ) : (
      <Route
        {...rest}
        render={props => {
          if (user === true) {
            return <Component {...props} type={type} />;
          }
          return (
            <Redirect
              to={{
                pathname: LOGIN_PATH,
                search: history?.location?.pathname ? `?redirect_to=${history.location.pathname}` : "",
              }}
            />
          );
        }}
      />
    );
  }
}

export default withRouter(ProtectedRoute);
