import React from "react";
import { Skeleton } from ".";
import cx from "clsx";

export default ({
  className = "",
  children = null,
  loading = false,
  color = "primary",
  variant = "pill",
  size = null,
  block = null,
  loadingSkeleton = false,
  icon = null,
  ...rest
}) => {
  return loadingSkeleton ? (
    rest?.className?.includes("pull-right") ? (
      <div className="pull-right">
        <Skeleton height={40} />
      </div>
    ) : (
      <Skeleton height={40} />
    )
  ) : (
    <button
      className={cx("btn", `btn-${color}`, `btn-${variant}`, size && `btn-${size}`, block && "btn-block", className)}
      {...rest}
    >
      {icon && icon}
      <span>{children}</span>
      {loading && <i className="fa fa-refresh fa-spin" style={{ marginLeft: "8px" }} />}
    </button>
  );
};
