import { ADD_ROLE, DELETE_ROLE, FETCH_ROLES, UPDATE_ROLE, SET_ROLES_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  roles: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_ROLE:
      return {
        ...state,
        roles: [action.payload.item].concat([...state.roles]),
      };

    case UPDATE_ROLE:
      const rolesList = state.roles;
      let index = rolesList.findIndex(e => e.id === action.payload.item.id);
      rolesList.splice(index, 1, { ...action.payload.item });
      return {
        ...state,
        roles: [...rolesList],
      };

    // case DELETE_ROLE:
    //   const rolesList = state.roles;
    //   let index = rolesList.findIndex((e) => e.id === action.payload.item.id);
    //   rolesList.splice(index, 1);
    //   return {
    //     ...state,
    //     roles: [...rolesList],
    //   };

    case FETCH_ROLES:
      return {
        ...state,
        ...action.payload,
      };

    case SET_ROLES_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    default:
      return state;
  }
};
