import { clone } from "../../helpers/Util";

export const INITIAL_STATE = {
  lists: {
    area: null,
    city: null,
    zone: null,
  },
  loading: {
    area: false,
    city: false,
    zone: false,
  },
  pagination: {
    area: null,
    city: null,
    zone: null,
  },
  search: {
    area: "",
    city: "",
    zone: "",
  },
  selected: { city: [], area: [], zone: [] },
};
export function reducer(state, action) {
  switch (action.type) {
    case "set-loading":
      return clone({
        ...state,
        loading: {
          ...state.loading,
          [action.payload.property]: action.payload.loading,
        },
      });
    case "set-search": {
      return clone({
        ...state,
        search: {
          ...action.payload,
        },
      });
    }

    case "set-single-search": {
      return clone({
        ...state,
        search: {
          ...state.search,
          [action.payload.property]: action.payload.value,
        },
      });
    }
    case "set-list":
      return clone({ ...state, lists: { ...action.payload.list } });
    case "set-single-list":
      return clone({
        ...state,
        lists: { ...state.lists, [action.payload.property]: [...action.payload.list] },
        ...(action.payload.pagination && {
          pagination: {
            ...state.pagination,
            [action.payload.property]: action.payload.pagination,
          },
        }),
      });
    case "set-selected":
      return clone({ ...state, selected: { ...action.payload.selected } });
    case "set-area-type":
      return clone({ ...state, areaType: action.payload.type });
    case "reset":
      return { ...INITIAL_STATE };
    default:
      throw new Error();
  }
}

export const actionFactory = dispatch => {
  const setSelected = selected => dispatch({ type: "set-selected", payload: { selected } });
  const setLoading = (property, loading) => dispatch({ type: "set-loading", payload: { property, loading } });
  const setLists = lists => dispatch({ type: "set-list", payload: { lists } });
  const setSingleList = (property, list, pagination = null) =>
    dispatch({ type: "set-single-list", payload: { list, property, pagination } });
  const setAreaType = type => dispatch({ type: "set-area-type", payload: { type } });
  const setSingleSearch = ({ property, value }) =>
    dispatch({ type: "set-single-search", payload: { property, value } });
  const setSearch = searchProps => dispatch({ type: "set-search", payload: { ...searchProps } });
  const reset = () => dispatch({ type: "reset" });
  return { setSelected, setLoading, setLists, setSingleList, setAreaType, setSingleSearch, setSearch, reset };
};
