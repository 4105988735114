import OneSignal from "react-onesignal";
import ConfigDB from "../../data/config";
import { Toast } from "../../components/common";

const useOneSignal = () => {
  const initializeOneSignal = async () => {
    const oneSignalConfigs = {
      appId: ConfigDB.data.oneSignalId,
      allowLocalhostAsSecureOrigin: true,
    };
    try {
      await OneSignal.init(oneSignalConfigs);
    } catch (error) {
      Toast.error(error);
    }
  };

  const promptOneSignalNotification = async () => {
    try {
      // Check if notifications are already granted or denied
      let oneSignalNotificationPermission = OneSignal?.Notifications?.permission;
      if (!oneSignalNotificationPermission) {
        await OneSignal?.Slidedown?.promptPush();

        /* eslint-disable no-unused-expressions */
        await new Promise(resolve => {
          OneSignal?.Notifications?.addEventListener("permissionChange", newPermission => {
            oneSignalNotificationPermission = newPermission;
            resolve();
          });
        });
      }

      await new Promise(resolve => {
        OneSignal?.Notifications?.addEventListener("change", () => {
          if (OneSignal?.User?.PushSubscription?.id) {
            resolve();
          }
        });
      });

      return OneSignal?.User?.PushSubscription?.id;
    } catch (error) {
      Toast.error(error);
    }
  };

  //later we can add other fuctionality such as oneSignal.login(uuid) which will restrict notifications to specific user session
  //We would need to handle other cases such as if OneSignal.Notifications.permission status is permission granted or not
  //If granted we woould also need to check if it was for the same user or different

  return {
    initializeOneSignal,
    promptOneSignalNotification,
  };
};

export default useOneSignal;
