import { strings } from "constant/strings";
import React from "react";
import { Modal, Copy, QrCode, ShareOnWhatsapp } from "components/common";
import cx from "clsx";
import styles from "components/forms/CRMAddPaymentForm.module.scss";
import img from "assets/images/dubizzle-qr-logo.png";
import { Flex } from "antd";

const AdyenPaymentLinkModal = props => {
  const {
    open,
    toggle,
    actions,
    adyenData,
    style,
    headerClass,
    headerRightSection,
    bodyClass,
    footerClass,
    onPaymentSaveSuccess,
    ...rest
  } = props;

  const handleContinue = () => {
    toggle();
    if (onPaymentSaveSuccess) {
      onPaymentSaveSuccess(adyenData?.response);
    }
  };

  return (
    <Modal
      open={open}
      toggle={handleContinue}
      title={strings.adyen_payment_link}
      body={() => (
        <>
          <div>
            <div className="text-center text-secondary font-weight-bold" style={{ fontSize: "20px" }}>
              {strings.scan_to_pay}
            </div>
            <QrCode
              url={adyenData?.url}
              className={"d-flex justify-content-center my-2"}
              img={img}
              height={250}
              width={250}
            />
          </div>
          <div className="text-center font-weight-bold">{strings.link_generated_message}</div>
          <div className="text-center text-secondary my-1">{strings.copy_link_message}</div>
          <Flex
            className={cx(styles.container, "mt-3")}
            align="center"
            justify="space-between"
            style={{ width: "70%", marginInline: "auto" }}
          >
            <span>{adyenData?.url}</span>
            <Flex align="center" gap={5}>
              <Copy text={adyenData?.url} />
              <ShareOnWhatsapp text={adyenData?.url} />
            </Flex>
          </Flex>
        </>
      )}
      size="lg"
      style={{ maxWidth: "650px", width: "100%" }}
      actions={[
        {
          label: strings.continue,
          onClick: handleContinue,
          color: "primary",
          key: "continue",
          className: "modal__cr-btn mx-auto my-3 ",
        },
      ]}
      {...rest}
    />
  );
};

export default AdyenPaymentLinkModal;
