import { Chip, IF } from "../../common";
import React, { useEffect, useState } from "react";

import { BtnDefaultState } from "../../common/BtnDefaultState";
import { CarInfoSelectModal } from ".";
import { strings } from "../../../constant/strings";
import { getActiveColumnPermissions } from "../../../helpers/Util";
const CarInfoSelect = ({ values, setFieldValue, isEditable = null }) => {
  const [list, setList] = useState(values?.car_preferences_attributes);
  const [modalShow, setModalShow] = useState(false);

  const getValue = obj => {
    const value = `${obj.make?.select_value} ${obj.model?.select_value} ${
      obj.variant ? obj.variant?.select_value + " " : ""
    } (${obj.model_year}) `;
    return value;
  };

  const onSelect = item => {
    const modelsList = list ? [...list, item] : [item];
    setList(modelsList);
    setFieldValue("car_preferences_attributes", modelsList);
  };

  return (
    <div className="form-group row d-flex flex-row align-items-center">
      <label className="col-sm-3 col-form-label text-right">{strings.car_information}</label>
      <CarInfoSelectModal visible={modalShow} setVisible={setModalShow} onSelect={onSelect} />
      <div
        className="col-sm-9 d-flex flex-row align-items-center"
        style={isEditable === false ? { pointerEvents: "none" } : {}}
      >
        {list?.map(
          (e, i) =>
            !e._destroy && (
              <Chip
                key={getValue(e)}
                title={getValue(e)}
                onClose={() => {
                  const modelsList = [...list];
                  modelsList.splice(i, 1, { ...modelsList[i], _destroy: true });
                  setList(modelsList);
                  setFieldValue("car_preferences_attributes", modelsList);
                }}
              />
            )
        )}
        <BtnDefaultState btnText="Select Car" onClick={() => setModalShow(true)} />
      </div>
    </div>
  );
};

export default CarInfoSelect;
