import React, { useEffect, useState } from "react";
import DetailCard from "../crm/DetailCard";
import { strings } from "../../constant/strings";
import {
  IconCalendar,
  IconCar,
  IconDiscount,
  IconDollar,
  IconId,
  IconMoney,
  IconPerson,
} from "../../components/svg-icon/svg-icons";
import moment from "moment";
import {
  checkUndefinedApiParams,
  getCurrency,
  getErrorString,
  isFieldRestricted,
  setTimeZone,
} from "../../helpers/Util";
import { fetchAuctionBookingDetailApi } from "../../helpers/ApiHelper";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BookingDetailInfo from "./BookingDetailInfo";
import BookingRelatedDetails from "./BookingRelatedDetails";
import { AUC_CARS_DETAIL_PATH, CRM_PAYMENTS_PATH } from "../../constant/appPaths";

/* /auction/booking_expenses Main Component Start */
const AuctionBookingExpensesDetail = () => {
  const [auctionBookingDetail, setAuctionBookingDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const bookingId = params.id;
  const timezone = useSelector(content => content.Configs.tenant.country.timezone);
  const country = useSelector(content => content.Configs.tenant.country);

  useEffect(() => {
    getAuctionBookingDetail();
  }, []);

  async function getAuctionBookingDetail() {
    if (!checkUndefinedApiParams(bookingId, "auction booking id")) return;
    setLoading(true);
    const response = await fetchAuctionBookingDetailApi(bookingId);
    if (response) setLoading(false);
    if (response.success) {
      setAuctionBookingDetail(response?.booking);
    } else {
      getErrorString(response);
    }
  }

  return (
    <div className={"container-fluid"}>
      <div className={"page-header"}></div>
      <DetailCard
        title={"Booking Expenses"}
        rowActionDropdownList={[]}
        actionsList={[]}
        IconsList={auctionDetailWithIcons(auctionBookingDetail, country, timezone)}
        cardData={auctionBookingDetail}
        loading={loading}
        auctionUserId={bookingId}
      />
      <div className={"row form-row"} style={{ minHeight: "65vh" }}>
        <div className={` col-sm-8 d-flex flex-column`}>
          <div className={"card flex-grow-1"}>
            <div className="card-body">
              <BookingDetailInfo
                bookingInfo={auctionBookingDetail}
                carDetailItems={carDetailItems}
                servicesItems={servicesItems}
                loading={loading}
              />
            </div>
          </div>
        </div>
        <div className={`  d-flex col-sm-4 flex-column related-item-col`}>
          <div className={"card flex-grow-1"}>
            <div className="card-body">
              <BookingRelatedDetails auctionBookingDetail={auctionBookingDetail} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const auctionDetailWithIcons = (auctionBookingDetail = {}, country = "", timezone = "") => {
  if (!auctionBookingDetail) return;
  const doesCrmPaymentsExist = auctionBookingDetail.crm_payments && auctionBookingDetail.crm_payments?.length;
  let auctionDetailItems = [
    {
      title: strings.booking_reference_number,
      value: auctionBookingDetail.reference_number,
      Icon: IconId,
    },
    {
      title: strings.car_reference_number,
      value: auctionBookingDetail.inventory?.car?.reference_number,
      Icon: IconCar,
      route: AUC_CARS_DETAIL_PATH,
      id: auctionBookingDetail.inventory?.car?.id,
      permissionCategory: "Car",
      permissionAction: "read",
    },
    {
      title: strings.crm_payment_reference_number,
      value: doesCrmPaymentsExist && auctionBookingDetail.crm_payments[0]?.reference_number,
      Icon: IconDollar,
      route: `${CRM_PAYMENTS_PATH}?f[reference_number]=${
        doesCrmPaymentsExist && auctionBookingDetail.crm_payments[0]?.reference_number
      }`,
      id: doesCrmPaymentsExist && auctionBookingDetail.crm_payments[0]?.id,
      permissionCategory: "CrmPayment",
      permissionAction: "change_status",
    },
    {
      title: strings.buyer_lead_reference_number,
      value: auctionBookingDetail.crm_lead?.reference_number,
      Icon: IconPerson,
    },
    {
      title: strings.offer_reference_number,
      value: auctionBookingDetail.offer?.reference_number,
      Icon: IconDiscount,
    },
    {
      title: strings.total_booking_amount,
      value: !isFieldRestricted(auctionBookingDetail?.amount) && getCurrency(country, auctionBookingDetail?.amount),
      Icon: IconMoney,
    },
    {
      title: strings.created_at,
      value:
        !isFieldRestricted(auctionBookingDetail?.created_at) &&
        moment(setTimeZone(timezone, auctionBookingDetail?.created_at)).format("DD MMM, yyyy").toString(),
      Icon: IconCalendar,
    },
  ];
  return auctionDetailItems;
};

const carDetailItems = (bookingInfo = {}) => {
  if (!bookingInfo) return;
  let CarDetailList = [
    {
      title: strings.make,
      text: bookingInfo.inventory?.car?.make?.name,
    },
    {
      title: strings.model,
      text: bookingInfo.inventory?.car?.model?.name,
    },
    {
      title: strings.model_year,
      text: bookingInfo.inventory?.car?.model_year,
    },
    {
      title: strings.variant,
      text: bookingInfo.inventory?.car?.variant?.name,
    },
    {
      title: strings?.receiver_address,
      text: bookingInfo?.receiver_address,
    },
  ];
  return CarDetailList;
};

const servicesItems = (bookingInfo = {}, country = "") => {
  if (!bookingInfo) return;
  let ServicesList = [
    {
      title: strings.total_booking_amount,
      text: !isFieldRestricted(bookingInfo.amount) && bookingInfo.amount && getCurrency(country, bookingInfo.amount),
    },
    {
      title: strings.final_amount,
      text:
        !isFieldRestricted(bookingInfo.offer?.final_amount) &&
        bookingInfo.offer?.final_amount &&
        getCurrency(country, bookingInfo.offer?.final_amount),
    },
    {
      title: strings.highest_offer,
      text:
        !isFieldRestricted(bookingInfo.offer) &&
        bookingInfo.offer?.bid_amount &&
        getCurrency(country, bookingInfo.offer?.bid_amount),
    },
  ];
  return ServicesList;
};

export default AuctionBookingExpensesDetail;
