import { AsyncSelect, CheckboxComponent, FormInput, SelectField, SelectionListTypeHead } from "../common";
import React, { useEffect, useRef, useState } from "react";
import { carEngineType, carTransmission } from "../../constant/lists";
import { connect } from "react-redux";
import { smoothScrollError } from "../../helpers/functions";
import { strings } from "../../constant/strings";
import {
  groupByOnKeys,
  isFieldRestricted,
  isFieldEditable,
  isFieldViewable,
  isObjRestricted,
  capitalize,
  formatValuesForAsyncSelect,
  formatArrayWithStringsForReactSelect,
} from "../../helpers/Util";
import { renderDynamicFields } from "../common/DyamicFields/DynamicFieldComponents";
import { fetchItemListsApi } from "../../helpers/ApiHelper";

const AdditionalInfoInput = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
    carFeatures,
    carFeaturesLoading,
    carFeaturesError,
    features,
    inventoryPermittedAttributes = null,
    car,
  } = props;

  const refObject = {
    bodyType: useRef(),
    engineType: useRef(),
    engineCapacity: useRef(),
    transmission: useRef(),
    assembly: useRef(),
    inventory_type: useRef(),
    dynamicFieldsRef: useRef(),
  };

  const [carFeaturesIds, setCarFeaturesIds] = useState([]);
  const [carFeaturesObject, setCarFeaturesObject] = useState(features);
  const groupedDynamicFields = groupByOnKeys(props?.dynamicFieldsData?.dynamic_fields, "dynamic_field_section") || {};

  useEffect(() => {
    smoothScrollError(errors, refObject, isSubmitting);
  });

  const checkboxStyles = { display: "flex", flexDirection: "column-reverse" };
  const checkboxLabelStyles = { marginBlockEnd: "0.5rem", fontWeight: "400" };
  const horizontal = false;

  // useEffect(() => {
  //   console.log('Object.keys(errors)?.[0]?.includes("_dynamic_")',Object.keys(errors)?.[0]?.includes("_dynamic_"))
  //   if (Object.keys(errors)?.[0]?.includes("_dynamic_")) {
  //     refObject.dynamicFieldsRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //     });
  //   }
  //
  // },[JSON.stringify(errors)]);

  useEffect(() => {
    setCarFeaturesObject(features);
    features &&
      !isFieldRestricted(features) &&
      features.forEach(e => {
        if (e.selected) carFeaturesIds.push(e.id);
      });
    setCarFeaturesIds(carFeaturesIds);
  }, [features]);

  const onChangeCheckbox = id => event => {
    carFeaturesObject.find(e => e.id === id).selected = event.target.checked;
    setCarFeaturesObject(carFeaturesObject);
    if (event.target.checked) {
      carFeaturesIds.push(id);
      const ids = [...new Set(carFeaturesIds)];
      setCarFeaturesIds(ids);
      setFieldValue("carFeatures", ids);
    } else {
      const ids = carFeaturesIds.filter(el => el != id);
      setCarFeaturesIds(ids);
      setFieldValue("carFeatures", ids);
    }
  };

  const getCheckBoxFeatures = () => {
    return (
      <div className="form-group ">
        <div className="grid" style={{ "--template": "repeat(2, 1fr)", "--gap": "12px 16px" }}>
          {!isFieldRestricted(carFeaturesObject) &&
            isFieldViewable(inventoryPermittedAttributes?.["car_features.name"]) &&
            carFeaturesObject?.map((item, i) =>
              i % 2 === 0 ? (
                <div className="row my-2" key={i}>
                  <div className="col-sm-6">
                    <CheckboxComponent
                      label={item?.name}
                      key={item.id}
                      value={item.id}
                      checked={item.selected}
                      onChange={onChangeCheckbox(item.id)}
                      isEditable={isFieldEditable(inventoryPermittedAttributes?.["car_features.name"])}
                      mainStyles={{ gap: "16px" }}
                      labelStyles={{ fontWeight: "500" }}
                    />
                  </div>
                  {carFeaturesObject?.[i + 1] && (
                    <div className="col-sm-6">
                      <CheckboxComponent
                        label={carFeaturesObject[i + 1].name}
                        key={carFeaturesObject[i + 1].id}
                        value={carFeaturesObject[i + 1].id}
                        checked={carFeaturesObject[i + 1].selected}
                        onChange={onChangeCheckbox(carFeaturesObject[i + 1].id)}
                        isEditable={isFieldEditable(inventoryPermittedAttributes?.["car_features.name"])}
                        mainStyles={{ gap: "16px" }}
                        labelStyles={{ fontWeight: "500" }}
                      />
                    </div>
                  )}
                </div>
              ) : null
            )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div id={strings.additional_information} className="card">
        <div className="card-header">
          <h5>{strings.additional_information}</h5>
        </div>
        <div className="card-body grid" style={{ "--template": "repeat(2, 1fr)", "--gap": "12px 16px" }}>
          {!isObjRestricted(values?.bodyType, ["select_value"]) &&
            isFieldViewable(inventoryPermittedAttributes?.["body_type.name"]) && (
              <AsyncSelect
                isDisabled={false}
                extraParams={{ "q[select_key_in][]": "body_type" }}
                clearButton
                isClearable={true}
                label={strings.body_type}
                listProperty={"item_lists"}
                placeholder={strings.body_type_ph}
                selected={formatValuesForAsyncSelect(values, "bodyType")}
                listApi={fetchItemListsApi}
                isMulti={false}
                onChange={selected => {
                  setFieldValue("bodyType", selected);
                }}
                errorMsg={errors.bodyType && touched.bodyType && errors.bodyType}
                isEditable={isFieldEditable(inventoryPermittedAttributes?.["body_type.name"])}
                horizontal={false}
                id={"bodyType"}
              />
            )}
          {!isObjRestricted(values?.engineType, ["name"]) &&
            isFieldViewable(inventoryPermittedAttributes?.["engine"]) && (
              <SelectField
                label={strings.engine_type}
                placeholder={strings.engine_type_ph}
                textRight
                horizontal={false}
                options={formatArrayWithStringsForReactSelect(carEngineType)}
                onBlur={handleBlur("engineType")}
                errorMsg={errors.engineType && touched.engineType && errors.engineType}
                onChange={selected => {
                  if (selected) {
                    setFieldValue("engineType", selected.value);
                  } else {
                    setFieldValue("engineType", "");
                  }
                }}
                value={values.engineType ? [{ value: values.engineType }] : []}
                formatOptionLabel={option => capitalize(option.value)}
                isSearchable
                isEditable={isFieldEditable(inventoryPermittedAttributes?.["engine"])}
                id={"engineType"}
              />
            )}

          {!isObjRestricted(values?.transmission, ["name"]) &&
            isFieldViewable(inventoryPermittedAttributes?.["engine_capacity"]) && (
              <FormInput
                label={strings.engine_capacity}
                placeholder={strings.engine_capacity_ph}
                horizontal={false}
                value={values.engineCapacity}
                onBlur={handleBlur("engineCapacity")}
                errorMsg={errors.engineCapacity && touched.engineCapacity && errors.engineCapacity}
                onChange={handleChange("engineCapacity")}
                formRef={refObject["engineCapacity"]}
                isEditable={isFieldEditable(inventoryPermittedAttributes?.["engine_capacity"])}
                id={"engineCapacity"}
              />
            )}
          {isFieldViewable(inventoryPermittedAttributes?.["transmission"]) && (
            <SelectField
              label={strings.transmission}
              placeholder={strings.transmission_ph}
              textRight
              horizontal={false}
              options={formatArrayWithStringsForReactSelect(carTransmission)}
              onBlur={handleBlur("transmission")}
              errorMsg={errors.transmission && touched.transmission && errors.transmission}
              onChange={selected => {
                if (selected) {
                  setFieldValue("transmission", selected.value);
                } else {
                  setFieldValue("transmission", "");
                }
              }}
              value={values.transmission ? [{ value: values.transmission }] : []}
              formatOptionLabel={option => capitalize(option.value)}
              isSearchable
              isEditable={isFieldEditable(inventoryPermittedAttributes?.["transmission"])}
              id={"transmission"}
            />
          )}
          {!isObjRestricted(values?.assembly, ["name"]) &&
            isFieldViewable(inventoryPermittedAttributes?.["assembly_id"]) && (
              <SelectField
                label={strings.assembly}
                placeholder={strings.assembly_ph}
                textRight
                horizontal={false}
                options={props.assemblies}
                onBlur={handleBlur("assembly")}
                errorMsg={errors.assembly && touched.assembly && errors.assembly}
                onChange={selected => {
                  setFieldValue("assembly", selected);
                }}
                value={values.assembly}
                formatOptionLabel={option => option.name}
                isSearchable
                isEditable={isFieldEditable(inventoryPermittedAttributes?.["assembly_id"])}
                id={"assembly"}
              />
            )}
        </div>
      </div>
      <div ref={refObject["dynamicFieldsRef"]}>
        {Object.keys(groupedDynamicFields).map((item, i) => {
          return (
            <div key={i} className="card" id={item}>
              <div className="card-header">
                <h5>{item}</h5>
              </div>
              <div className="card-body">
                <div className="grid" style={{ "--template": "repeat(2, 1fr)", "--gap": "12px 16px" }}>
                  {renderDynamicFields(
                    groupedDynamicFields[item],
                    props,
                    "car",
                    true,
                    inventoryPermittedAttributes,
                    horizontal,
                    checkboxStyles,
                    checkboxLabelStyles,
                    props?.dynamicFieldsData?.dynamic_fields,
                    props?.oldDependentField
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div id={strings.car_features} className="card">
        <div className="card-header">
          <h5>{strings.car_features}</h5>
        </div>
        <div className="card-body">{getCheckBoxFeatures()}</div>
      </div>
    </>
  );
};

export default connect(({ CarFeaturesLists }) => {
  return {
    carFeatures: CarFeaturesLists.lists,
    carFeaturesLoading: CarFeaturesLists.loading,
    carFeaturesError: CarFeaturesLists.error,
  };
}, {})(AdditionalInfoInput);
