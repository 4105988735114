import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";
import React from "react";
import moment from "moment";
import { setTimeZone } from "../../helpers/Util";

const DateSelect = props => {
  const todayDate = new Date();
  const {
    defaultText,
    label,
    errorMsg,
    inspections = [],
    inspection_id,
    timeZone,
    horizontal,
    timeIntervals = 15,
    ...rest
  } = props;

  const getTime = type => {
    const inspection = inspections.find(e => e.id === inspection_id);

    const inspectionTime = inspection && inspection?.[type === "min" ? "service_start_time" : "service_end_time"];
    if (inspectionTime) {
      const time = setTimeZone(timeZone, inspectionTime);
      return type === "min" ? time : time.setMinutes(time.getMinutes() - inspection?.duration);
    } else {
      const time = setTimeZone(timeZone, todayDate);

      type === "min" ? time.setHours(0, 0, 0, 0) : time.setHours(23, 59, 59, 99);
      return time;
    }
  };

  return (
    <div className={`form-group ${horizontal ? "row" : ""}`}>
      <label
        className={`${horizontal ? "col-sm-3 text-right" : ""} col-form-label`}
        htmlFor="exampleFormControlSelect3"
      >
        {label}
      </label>
      <div className={horizontal ? "col-sm-9" : ""}>
        <DatePicker
          disabled={props.isEditable === false || props.disabled === true}
          showTimeSelect
          timeIntervals={timeIntervals}
          className="form-control digits"
          id="exampleFormControlSelect3"
          autoComplete="off"
          minTime={getTime("min")}
          maxTime={getTime("max")}
          minDate={moment().toDate()}
          shouldCloseOnSelect
          onKeyDown={e => e.preventDefault()}
          {...rest}
        />
        {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
      </div>
    </div>
  );
};

export default DateSelect;
