import React from "react";
import { Switch as AntdSwitch, Row } from "antd";

import PropTypes from "prop-types";

function Switch(props) {
  const { label, className, onChange, standalone, ...rest } = props;

  const renderSwitch = () => {
    return <AntdSwitch onChange={onChange} className={className} checked={props.value} {...rest} />;
  };

  return standalone ? (
    renderSwitch()
  ) : (
    <Row justify="space-between" style={{ gap: "4px 16px" }} wrap={false}>
      {label && <label style={{ display: "block" }}>{label}</label>}
      {renderSwitch()}
    </Row>
  );
}

Switch.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
};

Switch.defaultProps = {};

export default Switch;
