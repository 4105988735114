import { CLEAR_CRM_KITT_REPORTS, FETCH_CRM_KITT_REPORTS, UPDATE_CRM_KITT_REPORTS } from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CRM_KITT_REPORTS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CRM_KITT_REPORTS:
      return {
        ...state,
        list: [action.payload, ...state.list],
      };
    case CLEAR_CRM_KITT_REPORTS:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
};
