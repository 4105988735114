import { Avatar, CustomAlert, SelectionListTypeHead } from "../common";
import React, { useEffect, useRef } from "react";

import { getPermission } from "../../helpers/Util";
import { strings } from "../../constant/strings";

const WatcherInput = ({
  horizontal,
  users,
  loading,
  error,
  getItemChanged,
  addWatcherError,
  watchers,
  onHideInput,
  type,
}) => {
  let ref = useRef();

  useEffect(() => {
    const handleClickOutside = event => ref.current && !ref.current.contains(event.target) && onHideInput();
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);

  return (
    <div className={`${"d-flex spbwx8"} ${horizontal ? "align-items-start flex-grow-1" : ""}`}>
      <SelectionListTypeHead
        className="flex-grow-1 m-0"
        placeholder={strings.add_user}
        options={users?.filter(item => !watchers?.find(e => e.id === item.id)) || []}
        isLoading={loading}
        isError={error}
        selected={watchers}
        onChange={selected => {
          getItemChanged(selected);
        }}
        labelKey="name"
        multiple
        // onBlur={onHideInput}
        renderMenuItemChildren={option => <Avatar name={option.name} />}
        clearButton={false}
        formSelectRef={ref}
        // renderToken
      />
      <CustomAlert type="icon" message={addWatcherError} />
    </div>
  );
};

export default WatcherInput;
