import { DateFilter, Select } from ".";
import React, { useEffect, useState } from "react";
import { appendTimeZone, getUTCOffset } from "../../helpers/Util";

import ChartistGraph from "react-chartist";
import Skeleton from "./Skeleton";
import { fetchLeadsByStatusesApi } from "../../helpers/ApiHelper";
import moment from "moment";
import { strings } from "../../constant/strings";
import { useSelector } from "react-redux";

const LeadStatusesChart = ({ setFilters, currentFilter }) => {
  const timezone = useSelector(content => content.Configs.tenant.country.timezone);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ labels: [], series: [], statuses: [] });

  const { byTime, timeType } = currentFilter;
  useEffect(() => {
    if (!currentFilter["created_at_gteq"] && !currentFilter["created_at_lt"]) {
      currentFilter["created_at_gteq"] = moment(appendTimeZone(moment().subtract(1, "month"), timezone)).format(
        "MMM dd, yyyy"
      );
      currentFilter["created_at_lt"] = moment(appendTimeZone(moment(), timezone)).format("MMM dd, yyyy");
    }
  }, []);

  useEffect(() => {
    currentFilter && fetchData();
  }, [currentFilter, byTime]);

  const fetchData = async () => {
    setLoading(true);
    const obj = { ...currentFilter };

    if (byTime && timeType) {
      obj[`q[${byTime}]`] = timeType;
    }
    const response = await fetchLeadsByStatusesApi("", { filters: obj });
    if (response && response.success) {
      setLoading(false);
      const series = [];
      const labels = Object.keys(response.leads_statistics).map(date => moment(date).format("MMM DD"));
      getChartData(response.leads_statistics, response.statuses, series);
      setData({ labels, series, statuses: response.statuses });
    }
    // else{

    // }
  };

  const getChartData = (obj, statuses, series) => {
    statuses.forEach(status => {
      let data = [];
      let seriesObject = {};
      Object.keys(obj).forEach(item => {
        data.push(obj[item][status.name] || 0);
      });
      seriesObject.name = status.name;
      seriesObject.data = data;
      seriesObject.color = status.color;
      series.push(seriesObject);
    });
  };

  const options = {
    height: 270,
    fullWidth: true,
    chartPadding: {
      right: 8,
      left: -10,
      top: 14,
      bottom: -14,
    },
    axisY: {
      onlyInteger: true,
    },
  };
  const key = "created_at";
  return !data?.statuses?.length && loading ? (
    <div className="col-xl-8 mb-3">
      <div className="card lead-status-chart-height"></div>
    </div>
  ) : data.statuses.length ? (
    <div className="col-xl-8 d-flex">
      <div className="card flex-1">
        <div className="card-header">
          <div className="row">
            <div className="col-sm-6">
              <h5>{strings.lead_statistics}</h5>
            </div>
            {/* <div className="col-sm-2">
              <Select
                options={[
                  { slug: "today_on", label: strings.today },
                  { slug: "yesterday_on", label: strings.yesterday },
                  { slug: "weekly_on", label: strings.weekly },
                  { slug: "monthly_on", label: strings.monthly },
                ]}
                value={byTime}
                onChange={e => {
                  setFilters({ byTime: e.target.value });
                }}
              />
            </div> */}
            <div className="col-sm-6">
              <DateFilter
                label={strings.select_date_range}
                queryStartDate={currentFilter[`${key}_gteq`]}
                queryEndDate={currentFilter[`${key}_lt`]}
                onSelect={(startDate, endDate) => {
                  const utcOffset = getUTCOffset(timezone);

                  const obj = {
                    ...currentFilter,
                    [`${key}_gteq`]: `${startDate}${utcOffset}`,
                    [`${key}_lt`]: `${endDate}${utcOffset}`,
                  };
                  obj.page && delete obj.page;
                  setFilters(obj);
                }}
                onClear={() => {
                  delete currentFilter[`${key}_gteq`];
                  delete currentFilter[`${key}_lt`];
                  setFilters(currentFilter);
                }}
                timezone={timezone}
                dateFormat={"MMM Do, YYYY"}
                className="lead-statistics-calender d-flex justify-content-end"
              />
            </div>
          </div>
        </div>
        <div className="card-body server-canvas">
          <>
            <div className="d-flex flex-row justify-content-center w-100">
              {data.statuses.map(item => (
                <div className="d-flex flex-row align-items-center mx-3" key={item.id}>
                  <div
                    style={{ backgroundColor: item.color, height: 16, width: 16, borderRadius: 4, marginRight: 4 }}
                  ></div>
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
            <ChartistGraph
              key="1"
              className="ct-chart-line"
              data={data}
              type={"Line"}
              listener={{
                draw: function (data) {
                  if (data.type === "point") {
                    data.element._node.setAttribute("style", `stroke-width: 6px; stroke: ${data.series.color}`);
                  }
                  if (data.type === "line" || data.type === "area") {
                    data.element._node.setAttribute("style", `stroke-width: 2px;  stroke: ${data.series.color}`);
                    data.element.animate({
                      d: {
                        begin: 500 * data.index,
                        dur: 500,
                        from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                        to: data.path.clone().stringify(),
                      },
                    });
                  }
                },
              }}
              options={options}
            />
          </>
        </div>
      </div>
    </div>
  ) : null;
};

export default LeadStatusesChart;
