import { ADD_QUICK_LINK, CLEAR_QUICK_LINKS, DELETE_QUICK_LINK, FETCH_QUICK_LINKS } from "../../constant/actionTypes";

import { fetchQuikLinks } from "../../helpers/ApiHelper";
import { getErrorString, mapLinksPayloadToTypes } from "../../helpers/Util";

export const addQuickLink = (item, type) => dispatch => {
  dispatch({
    type: ADD_QUICK_LINK,
    payload: {
      item: { ...item },
      type: type,
    },
  });
};

export const deleteQuickLink = (item, type) => dispatch => {
  dispatch({
    type: DELETE_QUICK_LINK,
    payload: {
      item: { ...item },
      type: type,
    },
  });
};

export const fetchQuickLinks =
  (queryString, cb = () => {}, type = null) =>
  async dispatch => {
    const res = await fetchQuikLinks(queryString);
    const parsedLinks = mapLinksPayloadToTypes(res.quick_links);

    if (res && res.success) {
      dispatch({
        type: FETCH_QUICK_LINKS,
        payload: {
          links: parsedLinks,
          loading: false,
          linksType: type,
          error: "",
        },
      });
      cb(res.quick_links);
    } else {
      dispatch({
        type: FETCH_QUICK_LINKS,
        payload: {
          links: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  };

export const clearQuickLinks = () => dispatch => {
  dispatch({
    type: CLEAR_QUICK_LINKS,
  });
};
