import React from "react";
import Skeleton from "./Skeleton";

export const FilterSelect = props => {
  const { options, loading, ...rest } = props;
  return loading ? (
    <div className={"mr-3"}>
      <Skeleton height={35} width={120} />
    </div>
  ) : (
    <div className="select2-drpdwn-project select-options px-1">
      <select className="form-control form-control-primary btn-square" name="select" {...rest}>
        {options.map((it, index) => {
          return (
            <option
              // selected={it.slug === Number(value)}
              value={it.slug}
              key={index}
            >
              {it.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
