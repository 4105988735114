export const MILEAGE = {
  MIN: 0,
  MAX: 2000000,
};

export const INVENTORY_ACTIONS = Object.freeze({
  UPDATE: "update",
  MARK_AS_REMOVE: "mark_as_remove",
  MARK_AS_SOLD: "mark_as_sold",
  MARK_AS_ON_HOLD: "mark_as_on_hold",
  MARK_AS_AVAILABLE: "mark_as_available",
  MARK_AS_READY_FOR_AUCTION: "mark_as_ready_for_auction",
});

export const MAKE_MODEL_VARIANT = Object.freeze({
  make: "make",
  model: "model",
  variant: "variant",
});
