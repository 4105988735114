import { DropdownSelect, Skeleton, Toast } from "../common";
import React, { useEffect, useState } from "react";
import { fetchLeadAssigneesApi, updateCRMLeadAttributesApi } from "../../helpers/ApiHelper";

import { Avatar } from "../common/Avatar";
import { MdPerson } from "../svg-icon/svg-icons";
import { checkUndefinedApiParams, getErrorString, isFieldRestricted } from "../../helpers/Util";
import { strings } from "../../constant/strings";

const AssignUserInputLead = ({
  user,
  style,
  leadId,
  onSelect,
  loadingSkeleton,
  disabled,
  type,
  show = true,
  label,
  avatarSize = 28,
}) => {
  const [selectedUser, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    user && setUser(user);
  }, [user]);

  const fetchData = async params => {
    const response = await fetchLeadAssigneesApi(params);
    setLoading(true);
    if (response) {
      setLoading(false);
      if (response.success && response.users) {
        setUsers(response.users);
      } else {
        setError(getErrorString(response));
      }
    }
  };

  const postData = async e => {
    if (!checkUndefinedApiParams(leadId, "lead")) return;
    let obj = {
      crm_lead: {
        assignee_id: e.id,
      },
    };

    const response = await updateCRMLeadAttributesApi(leadId, obj, "change_assignee");
    if (response) {
      if (response.crm_lead) {
        setUser(e);
      } else {
        Toast.error(getErrorString(response));
      }
    }
  };

  const renderComponent = () =>
    loadingSkeleton ? (
      <Skeleton height={30} />
    ) : (
      <DropdownSelect
        className={`d-flex dropdown-status dropdown-font ${type === "detail" ? "info-block" : ""}`.trim()}
        options={users}
        labelAsValue={false}
        toggleClick={() => !disabled && fetchData(`?crm_lead_id=${leadId}`)}
        selected={selectedUser}
        disabled={disabled}
        toggleClassName="d-flex align-items-end spbwx8"
        style={{ lineHeight: "inherit" }}
        caret={false}
        color="skeleton"
        onChange={e => {
          if (onSelect) {
            setUser(e);
            onSelect(e);
          } else {
            postData(e);
          }
        }}
        renderMenuItem={e => <Avatar name={e.name} size={avatarSize} round />}
        renderToggleItem={() =>
          type === "detail" ? (
            <div className="d-flex align-items-end spbwx8">
              {selectedUser ? (
                <Avatar name={selectedUser.name} avatarOnly size={avatarSize} round />
              ) : (
                <div className="media-icon simple bordered">
                  <MdPerson />
                </div>
              )}
              <div className="d-flex flex-column align-items-start">
                <div className="info-block-title">{strings.assigned_to}</div>
                <div className={`info-block-desc ${disabled ? "" : "caret-dropdown-toggle"}`}>
                  {selectedUser ? selectedUser.name : strings.unassigned}
                </div>
              </div>
            </div>
          ) : selectedUser ? (
            <Avatar name={selectedUser.name} size={avatarSize} round />
          ) : (
            <div className="media align-items-center">
              <div className="media-icon simple bordered">
                <MdPerson />
              </div>
              <div>{strings.unassigned}</div>
            </div>
          )
        }
      />
    );

  return label ? (
    <div className="form-group row">
      <label className="col-sm-3 col-form-label">{loadingSkeleton ? <Skeleton height={20} /> : label}</label>
      <div className="col-sm-9">{renderComponent()}</div>
    </div>
  ) : (
    renderComponent()
  );
};

export default AssignUserInputLead;
