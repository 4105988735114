import {
  ADD_LOCATION,
  FETCH_LOCATIONS,
  EDIT_LOCATION,
  DELETE_LOCATION,
  SET_LOCATIONS_LOADING,
} from "../../constant/actionTypes";
import { clone } from "../../helpers/Util";
export const INITIAL_STATE = {
  list: [],
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  statuses: [],
  loading: true,
  error: "",
  initialRequestCalled: false,
  locationTypes: [],
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_LOCATION:
      return {
        ...state,
      };
    case EDIT_LOCATION:
      return {
        ...state,
      };
    case DELETE_LOCATION:
      return {
        ...state,
      };
    case FETCH_LOCATIONS:
      return clone({
        ...state,
        ...action.payload,
      });
    case SET_LOCATIONS_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};
