import React from "react";
import styles from "./Timeline.module.scss";
import cx from "clsx";

const Timeline = ({
  className,
  icon,
  bgColor,
  containerSize,
  borderColor,
  borderRadius,
  style,
  TimeLineBodyComponent = null,
  timeLineData = [],
}) => {
  const timelineEntry = (entry, index) => (
    <div key={index} className="d-flex">
      <div
        className={cx(
          "rounded-circle d-inline-flex justify-content-center align-items-center mt-1",
          styles.timelineRound
        )}
      >
        {icon}
      </div>
      <div className={cx(styles.TimeLineBody, "w-100")}>
        {TimeLineBodyComponent ? (
          <TimeLineBodyComponent data={{ ...entry }} />
        ) : (
          <div className={cx("d-flex justify-content-between", styles.scheduleTime)}>
            <div>{entry?.description ?? ""}</div>
            <div>{entry?.text ?? ""}</div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className={cx(styles.TimelineContainer)} style={{ ...style }}>
      {timeLineData?.length && timeLineData?.map((entry, index) => timelineEntry(entry, index))}
    </div>
  );
};

export default Timeline;
