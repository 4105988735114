import { UPDATE_MODEL_PERMISSIONS } from "../../constant/actionTypes";
import { checkUndefinedApiParams, getErrorString, isFieldRestricted } from "../../helpers/Util";
import { fetchPermittedAttributesApi } from "../../helpers/ApiHelper";
import { Toast } from "../../components/common";
import { strings } from "../../constant/strings";

export const updateModelPermissions = (model, attributesHash) => async dispatch => {
  dispatch({
    type: UPDATE_MODEL_PERMISSIONS,
    payload: {
      attributesHash: attributesHash,
      model: model,
    },
  });
};

export const fetchPermittedAttributes = (userId, model) => async dispatch => {
  if (!checkUndefinedApiParams(userId, "user")) return;
  const response = await fetchPermittedAttributesApi(userId, model);
  if (response.success) {
    dispatch({
      type: UPDATE_MODEL_PERMISSIONS,
      payload: {
        permitttedAttributes: [],
        model: model,
        loading: false,
      },
    });
  } else {
    dispatch({
      type: UPDATE_MODEL_PERMISSIONS,
      payload: {
        permittedAttributes: [],
        loading: false,
        error: getErrorString(response.error),
      },
    });
  }
};
