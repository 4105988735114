import { QUERIES } from "constant/appConstants";
import React, { useEffect, useReducer } from "react";
import { strings } from "../../constant/strings";
import { fetchAreaListApi, fetchCityListApi, fetchCityZones } from "../../helpers/ApiHelper";
import { defaultFn, getQueryStringFromArray, isEmptyArray } from "../../helpers/Util";
import useIsMounted from "../../hooks/useIsMounted";
import { actionFactory, INITIAL_STATE, reducer } from "../../reducers/local/userLocationReducer";
import { FormInput, IF, Modal, Skeleton } from "../common";
import FlatList from "../common/FlatList";
import { IoIosArrowForward } from "../svg-icon/svg-icons";

const AssignUserLocationModal = ({ visible, setVisible, onSelect, levels, values }) => {
  const isMounted = useIsMounted();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const actions = actionFactory(dispatch);

  const fetchInitialCityData = async ({ search = null, cities = null }) => {
    actions.setSingleList("city", []);
    actions.setLoading("city", true);
    const params = `?page=1${search ? `&${QUERIES.RANSACK.SEARCH_NAME}=${search}` : ""}`;
    const response = await fetchCityListApi(params);
    if (!isMounted) return;
    if (response.success) {
      actions.setSingleList("city", [...response.cities], response.pagination);
    }
    actions.setLoading("city", false);
  };

  const fetchInitialZoneData = async ({ city, search = null }) => {
    actions.setSingleList("zone", []);
    actions.setLoading("zone", true);
    const cityParam = getQueryStringFromArray(
      QUERIES.RANSACK.CITY_MULTISELECT,
      city?.map(city => city.id)
    );
    const searchParam = search ? `&${QUERIES.RANSACK.SEARCH_NAME}=${search}` : "";
    const params = `?${cityParam}${searchParam}`;
    const response = await fetchCityZones(params);
    if (response.success) {
      actions.setSingleList("zone", [...response.zones], response.pagination);
    }
    actions.setLoading("zone", false);
  };

  const fetchMoreZoneData = async () => {
    const nextPage = state?.pagination?.area?.next_page;
    if (!nextPage) return;
    const paginationParam = `page=${state?.pagination?.area.next_page}`;
    const cityParam = getQueryStringFromArray(
      QUERIES.RANSACK.CITY_MULTISELECT,
      state.selected.city.map(city => city.id)
    );
    const searchParam = state?.search?.zone ? `&${QUERIES.RANSACK.SEARCH_NAME}=${state.search.zone}` : ``;
    const params = `?${paginationParam}&${cityParam}${searchParam}`;
    const response = await fetchCityZones(params);
    if (response.success) {
      actions.setSingleList("area", [...state.lists.zones, ...response.zones], response.pagination);
    }
  };

  const fetchInitialAreaData = async ({ zone, search = null }) => {
    actions.setSingleList("area", []);
    actions.setLoading("area", true);
    const zoneParam = getQueryStringFromArray(
      QUERIES.RANSACK.ZONE_MULTISELECT,
      zone?.map(zone => zone.id)
    );
    const searchParam = search ? `&${QUERIES.RANSACK.SEARCH_NAME}=${search}` : "";
    const params = `?${zoneParam}${searchParam}`;
    const response = await fetchAreaListApi(params);
    if (response.success) {
      actions.setSingleList("area", [...response.areas], response.pagination);
    }
    actions.setLoading("area", false);
  };

  const fetchMoreAreaData = async () => {
    const nextPage = state?.pagination?.area?.next_page;
    if (!nextPage) return;
    const paginationParam = `page=${state?.pagination?.area.next_page}`;
    const zoneParam = getQueryStringFromArray(
      QUERIES.RANSACK.ZONE_MULTISELECT,
      state.selected.zone.map(zone => zone.id)
    );
    const searchParam = state?.search?.area ? `&${QUERIES.RANSACK.SEARCH_NAME}=${state.search.area}` : ``;
    const params = `?${paginationParam}&${zoneParam}${searchParam}`;
    const response = await fetchAreaListApi(params);
    if (response.success) {
      actions.setSingleList("area", [...state.lists.area, ...response.areas], response.pagination);
    }
  };

  const fetchMoreCityData = async () => {
    const nextPage = state?.pagination?.city?.next_page;
    if (!nextPage) return;
    const paginationParam = `?page=${state?.pagination?.city.next_page}`;
    const searchParam = state?.search?.area ? `&${QUERIES.RANSACK.SEARCH_NAME}=${state.search.city}` : ``;
    const params = `${paginationParam}${searchParam}`;
    const response = await fetchAreaListApi(params);
    if (response.success) {
      actions.setSingleList("city", [...state.lists.city, ...response.cities], response.pagination);
    }
  };

  const attachCityToZone = list => {
    if (!list || !list.length) return [];
    let arr = list.map((item, index) => {
      if (!item?.city?.name) return item;

      return { ...item, name: `${item.name}, ${item?.city?.name}` };
    });

    return arr;
  };

  const onDone = obj => {
    onSelect(obj);
    setVisible(false);
    actions.setSelected({});
  };
  useEffect(() => {
    if (visible) {
      fetchInitialCityData({});
      if (!isEmptyArray(values.cities)) {
        fetchInitialZoneData({ city: values.cities });
      }
      if (!isEmptyArray(values.zones)) {
        fetchInitialAreaData({ zone: values.zones });
      }
      actions.setSelected({
        ...(levels.length > 2 && {
          area: values?.areas ?? [],
        }),
        ...(levels.length > 1 && {
          zone: values?.zones ?? [],
        }),
        city: values?.cities ?? [],
      });
      //actions.setSelected({});
    }
  }, [visible]);

  function filterZoneByCity(cityArray, zoneArray) {
    const validCities = new Set(cityArray.map(city => city.id));
    return zoneArray.filter(zone => validCities.has(zone.city.id));
  }

  function filterAreaByZone(zoneArray, areaArray) {
    const validZones = new Set(zoneArray.map(zone => zone.id));
    return areaArray.filter(area => validZones.has(area.zone.id));
  }

  return (
    <Modal
      toggle={() => {
        setVisible(!visible);
      }}
      open={visible}
      className="modal-lg deposit_model crm-modal"
      body={() => {
        return (
          <div className="modal-wrapper d-flex flex-row">
            <div className="modal-inner-wrapper">
              <h6>{strings.cities}</h6>
              <div className="modal-list-wrapper d-flex flex-column">
                <div className="modal-list-wrapper d-flex flex-column">
                  <div className="form theme-form db__form-main">
                    <FormInput
                      className="save_model_row"
                      placeholder={strings.city}
                      onChange={e => {
                        actions.setSingleSearch({ property: "city", value: e.target.value });
                        fetchInitialCityData({ search: e.target.value });
                      }}
                      value={state.search.city}
                    />
                  </div>

                  <FlatList
                    ListComponent={SelectItem}
                    className={""}
                    styleProps={{ height: "480px" }}
                    // fetchItems={fetchCityData}

                    parentList={{
                      fetchInitialData: defaultFn,
                      fetchMoreData: fetchMoreCityData,
                      list: state?.lists?.city ?? [],
                      hasMore: !!state?.pagination?.city,
                    }}
                    onSelect={city => e => {
                      let newCities = [...state.selected.city];
                      if (newCities.find(selectedCity => selectedCity.id === city.id)) {
                        newCities = newCities.filter(selectedCity => selectedCity.id !== city.id);
                      } else {
                        newCities.push({ ...city });
                      }
                      actions.setSelected({
                        city: newCities,
                        zone:
                          state?.selected?.zone?.length > 0 ? filterZoneByCity(newCities, state?.selected?.zone) : [],
                        area:
                          state?.selected?.area?.length > 0
                            ? filterAreaByZone(
                                filterZoneByCity(newCities, state?.selected?.zone),
                                state?.selected?.area
                              )
                            : [],
                      });
                      fetchInitialZoneData({ city: newCities });
                    }}
                    ScrollLoader={ListSkeleton}
                    disableLoader={!state.lists?.city?.length > 0}
                    optionProperty={"name"}
                    selected={state.selected.city}
                  />
                </div>
              </div>
            </div>

            {/*<IF condition={levels.length > 1 && !!state.selected?.city}>*/}
            {/*  <DynamicListMultiSelect*/}
            {/*    title={strings.zones}*/}
            {/*    params={state.selected?.city?.length ? getString("q[city_id_in][]=", "cities") : null}*/}
            {/*    selected={selected}*/}
            {/*    setSelected={setSelected}*/}
            {/*    apiCall={fetchCityZones}*/}
            {/*    keystring="zones"*/}
            {/*  />*/}
            {/*</IF>*/}

            <IF condition={levels.length > 1 && !!state.selected?.city?.length}>
              <div className="modal-inner-wrapper">
                <h6>{strings.zone}</h6>
                {!!state.selected?.city && (
                  <div className="modal-list-wrapper d-flex flex-column">
                    <div className="form theme-form db__form-main">
                      <FormInput
                        className="save_model_row"
                        placeholder={strings.zone}
                        onChange={e => {
                          actions.setSingleSearch({ property: "zone", value: e.target.value });
                          fetchInitialZoneData({ city: state.selected.city, search: e.target.value });
                        }}
                        value={state.search.zone}
                      />
                    </div>
                    <FlatList
                      ListComponent={SelectItem}
                      className={""}
                      parentList={{
                        fetchInitialData: defaultFn,
                        fetchMoreData: fetchMoreZoneData,
                        list: attachCityToZone(state?.lists?.zone ?? []),
                        hasMore: !!state?.pagination?.zone,
                      }}
                      onSelect={zone => e => {
                        let newZones = [...state.selected.zone];
                        if (newZones.find(selectedZone => selectedZone.id === zone.id)) {
                          newZones = newZones.filter(selectedZone => selectedZone.id !== zone.id);
                        } else {
                          newZones.push({ ...zone });
                        }
                        actions.setSelected({
                          zone: newZones,
                          city: state.selected.city,
                          area:
                            state?.selected?.area?.length > 0
                              ? filterAreaByZone(newZones, [...state?.selected?.area])
                              : [],
                        });
                        fetchInitialAreaData({ zone: newZones, search: state.search.area });
                      }}
                      styleProps={{ height: "470px" }}
                      selected={state.selected.zone}
                      ScrollLoader={ListSkeleton}
                      disableLoader={!state.loading.zone}
                      optionProperty={"name"}
                    />
                  </div>
                )}
              </div>
            </IF>

            <IF condition={levels.length > 2 && !!state?.selected?.zone?.length}>
              <div className="modal-inner-wrapper">
                <h6>{strings.area}</h6>
                {!!state.selected?.zone && (
                  <div className="modal-list-wrapper d-flex flex-column">
                    <div className="form theme-form db__form-main">
                      <FormInput
                        className="save_model_row"
                        placeholder={strings.area}
                        onChange={e => {
                          actions.setSingleSearch({ property: "area", value: e.target.value });
                          fetchInitialAreaData({
                            zone: state.selected.zone,
                            search: e.target.value,
                          });
                        }}
                        value={state.search.area}
                      />
                    </div>
                    <FlatList
                      ListComponent={SelectItem}
                      className={""}
                      parentList={{
                        fetchInitialData: defaultFn,
                        fetchMoreData: fetchMoreAreaData,
                        list: state?.lists?.area ?? [],
                        hasMore: !!state?.pagination?.area,
                      }}
                      onSelect={area => e => {
                        let newAreas = [...state.selected.area];
                        if (newAreas.find(selectedArea => selectedArea.id === area.id)) {
                          newAreas = newAreas.filter(selectedArea => selectedArea.id !== area.id);
                        } else {
                          newAreas.push({ ...area });
                        }
                        actions.setSelected({ zone: state.selected.zone, area: newAreas, city: state.selected.city });
                      }}
                      styleProps={{ height: "470px" }}
                      selected={state.selected.area}
                      ScrollLoader={ListSkeleton}
                      disableLoader={!state.loading.area}
                      optionProperty={"name"}
                    />
                  </div>
                )}
              </div>
            </IF>
          </div>
        );
      }}
      actions={[
        {
          label: strings.cancel,
          onClick: () => {
            setVisible(false);
            actions.reset();
          },
          color: "secondary",
          className: "modal__cancel-btn btn-pill",
        },
        {
          label: strings.done,
          color: "primary",
          className: "modal__cr-btn btn-pill",
          onClick: () =>
            onDone({
              ...(levels.length === 3 && {
                areas: state.selected.area,
              }),
              ...(levels.length === 2 && {
                zones: state.selected.zone,
              }),
              ...(levels.length === 1 && {
                cities: state.selected.city,
              }),
            }),
          disabled: !(state.selected?.city || state.selected?.zone || state.selected?.area),
        },
      ]}
      onClosed={e => {
        actions.reset();
      }}
    />
  );
};

const SelectItem = props => {
  const { onSelect, selected, optionProperty = "name", loading } = props;
  const entry = props.item;
  return (
    <div
      key={entry.id}
      className={`modal-list d-flex align-items-center justify-content-between ${
        Array.isArray(selected) && selected?.map(selected => selected.id).includes(entry.id) ? "selected" : ""
      }`}
      onClick={onSelect(entry)}
    >
      <span>{entry[optionProperty]}</span>
      <IoIosArrowForward style={{ minWidth: "1em" }} />
    </div>
  );
};
const ListSkeleton = props => {
  const { loading } = props;
  if (!loading) return null;
  return [1, 2, 3, 4, 5, 6, 7].map(e => (
    <Skeleton key={e} height={45} themeProps={{ color: "#DCDCDC", highlightColor: "#F0F0F0" }} className="my-2" />
  ));
};

export default AssignUserLocationModal;
