import React from "react";
import moment from "moment";
import cx from "clsx";
import ImagePlaceholder from "../../../assets/images/ic_car_placeholder.png";
import { Status, Tooltip } from "components/common";
import { BlockIcon, IoIosWarning, ViewInspectionReportIcon } from "components/svg-icon/svg-icons";
import { isFieldRestricted, truncateText } from "../../../helpers/Util";
import styles from "./ExistingDetailCard.module.scss";
import { strings } from "constant/strings";
import Flex from "components/common/Flex";
import Group from "components/common/Group";
import Card from "components/common/Card";
import ExistingCarDetailSkeleton from "components/crm/duplicationSearch/ExistingCarDetailSkeleton";

const ExistingCarDetailCard = ({ car, style, carPermittedAttributes, loading, ...props }) => {
  const getScoreTagColor = score => {
    if (score < 3) {
      return { color: "#FF793E", bg_color: "#FFE4D8" };
    } else if (score < 5) {
      return { color: "#FFDF31", bg_color: "#FFF8D5" };
    } else if (score < 8) {
      return { color: "#FFB231", bg_color: "#FFEFD5" };
    } else if (score <= 10) {
      return { color: "#5AC35A", bg_color: "#DEF3DE" };
    }
  };

  const colors = getScoreTagColor(car?.inspection_details?.score);
  return loading ? (
    <ExistingCarDetailSkeleton />
  ) : (
    <Card cardBg="#f9fafb" cardBorderRadius="6px" cardPadding="12px" style={{ ...style }}>
      <Group className="align-items-center" template={"82px 1fr"} gap="10px">
        <img src={car.main_picture?.url ?? ImagePlaceholder} alt="Car" className={styles.carImg} />

        <div>
          <div>
            <Flex align="center" justify="space-between">
              {!isFieldRestricted(car?.reference_number) && (
                <div
                  className={cx("item-separator-dot d-flex align-items-center text-dark-gray ")}
                  style={{ fontSize: "10px" }}
                >
                  Ref# <span className="font-weight-bold">{car?.reference_number}</span>
                </div>
              )}
              <Flex align="center" gap={"4px"}>
                {!isFieldRestricted(car?.inspection_details?.score) && !!car?.inspection_details && (
                  <Status
                    className="f-w-600"
                    bg_color={colors?.bg_color}
                    color={colors?.color}
                    name={`${car?.inspection_details?.score.toFixed(1)}/10`}
                    // style={{ "--badge-radius": "4px" }}
                  />
                )}
                <Status
                  className="f-w-600"
                  name={`${car?.status?.name} • ${car?.disposition?.name}`}
                  color={car?.status?.color}
                  bg_color={car?.status?.bg_color}
                />
                {!isFieldRestricted(car?.inventory?.risks) && car?.inventory?.risks?.length > 0 && (
                  <>
                    <Status
                      data-tip
                      data-for={`risks-${car?.id}`}
                      bg_color="#F8E9E9"
                      color="#BB2025"
                      name={strings.risks}
                      icon={<IoIosWarning color="currentColor" size={12} />}
                      className="f-w-600"
                    />

                    <Tooltip
                      id={`risks-${car?.id}`}
                      place={props?.tooltipPlacement ?? "up"}
                      type="dark"
                      effect="solid"
                      padding="10px"
                    >
                      {car?.inventory?.risks?.map((risk, index) => (
                        <Group template={"repeat(2, 1fr)"} key={index} gap="48px">
                          <div>{risk.title}</div>
                          <ul className="f-w-500 text-align-left" style={{ listStyle: "disc" }}>
                            {risk.issues.map((issue, i) => (
                              <>
                                <li key={i}>{issue}</li>
                              </>
                            ))}
                          </ul>
                        </Group>
                      ))}
                    </Tooltip>
                  </>
                )}
              </Flex>
            </Flex>
            <div className="d-flex flex-row align-items-center">
              {!isFieldRestricted(car?.client?.name) && (
                <div style={{ color: "#707070" }}>
                  <span className="f-w-400" style={{ fontSize: "12px", gap: 2 }}>
                    Client Name &nbsp;
                  </span>
                  <span style={{ fontWeight: 600 }}>{car?.client?.name}</span>
                </div>
              )}
              {!isFieldRestricted(car?.client?.is_blacklisted) && car?.client?.is_blacklisted && (
                <>
                  <span className="ml-2" data-tip data-for={`blacklist-reason-${car?.id}`}>
                    <BlockIcon size={14} color={"#BB2025"} />
                  </span>
                  {!isFieldRestricted(car?.client?.reason) && (
                    <Tooltip id={`blacklist-reason-${car?.id}`} place="down" type="dark" effect="solid" padding="10px">
                      {car?.client?.reason}
                    </Tooltip>
                  )}
                </>
              )}
            </div>

            {!isFieldRestricted(car?.full_title) && (
              <div className="f-w-600 f-16 mb-0" style={{ color: "#000" }}>
                {truncateText(car?.full_title, 50)}
              </div>
            )}
          </div>

          <Flex align="center" justify="space-between">
            <Flex align="center" className={cx("item-separator-dot text-dark-gray f-12", styles.carProperties)}>
              {!isFieldRestricted(car?.inspection_details) && !!car?.inspection_details && (
                <>
                  <div>
                    Inspected By
                    <span className="font-weight-bold ml-1 text-truncate" style={{ maxWidth: "20ch" }}>
                      {car?.inspection_details?.inspected_by}
                    </span>
                  </div>
                  <div>
                    Inspected At
                    <span className="font-weight-bold ml-1">
                      {moment(car?.inspection_details?.inspected_at).format("DD MMM, YYYY")}
                    </span>
                  </div>
                </>
              )}
            </Flex>
          </Flex>
          <Flex vertical align="end" justify="space-between" style={{ height: "94%" }}>
            {car?.inspection_details?.inspection_report_link && (
              <a
                className="f-12"
                href={car?.inspection_details?.inspection_report_link}
                target="_blank"
                rel="noopoener noreferrer"
                style={{ color: "#707070", textDecoration: "underline" }}
                onClick={e => e.stopPropagation()}
              >
                View Inspection Report <ViewInspectionReportIcon />
              </a>
            )}
          </Flex>
        </div>
      </Group>
    </Card>
  );
};

export default ExistingCarDetailCard;
