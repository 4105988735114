import "react-alice-carousel/lib/alice-carousel.css";

import AliceCarousel from "react-alice-carousel";
import React, { useRef } from "react";
import { FiChevronLeft, FiChevronRight } from "../svg-icon/svg-icons";

const Carousel = ({
  elements,
  controlButtonContainerStyles = "",
  children,
  showButtons,
  leftIcon,
  rightIcon,
  ...rest
}) => {
  const carouselRef = useRef();

  const carouselOptions = {
    mouseTracking: true,
    autoHeight: true,
  };
  return (
    <>
      <AliceCarousel ref={carouselRef} activeIndex={0} items={elements || children} {...carouselOptions} {...rest} />
      {showButtons && (
        <div className={controlButtonContainerStyles}>
          <button
            className="btn btn-light slide-btn btn-prev"
            type="button"
            onClick={() => carouselRef.current.slidePrev()}
          >
            {leftIcon ? leftIcon : <FiChevronLeft />}
          </button>
          <button
            className="btn btn-light slide-btn btn-next"
            type="button"
            onClick={() => carouselRef.current.slideNext()}
          >
            {rightIcon ? rightIcon : <FiChevronRight />}
          </button>
        </div>
      )}
    </>
  );
};
export default Carousel;
