import {
  ADD_APPOINTMENT,
  SET_APPOINTMENTS_DATA,
  SET_SCHEDULES_DATA,
  SET_TASK_APPOINTMENTS,
  UPDATE_APPOINTMENT_DATA,
  UPDATE_APPOINTMENTS_DATA,
} from "../../constant/actionTypes";

export const INITIAL_STATE = {
  appointments: [],
  users: [],
  schedules: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_APPOINTMENTS_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SCHEDULES_DATA:
      return {
        ...state,
        schedules: [...action.payload],
      };

    case UPDATE_APPOINTMENT_DATA:
      const appointments = [...state.appointments];
      const index = appointments.findIndex(item => item.id === action.payload.appointmentId);
      appointments.splice(index, 1, { ...action.payload.appointment });
      return {
        ...state,
        appointments,
      };

    case UPDATE_APPOINTMENTS_DATA: {
      const appointments = [...state.appointments];
      const appointmentsList = state.appointments.reduce((acc, currentValue, index) => {
        if (currentValue.task.id === action.payload.task.id) {
          acc.push({ currentValue, index });
        }
        return acc;
      }, []);
      appointmentsList.forEach(({ currentValue, index }) => {
        currentValue.task.name = action.payload.task.name;
        appointments.splice(index, 1, { ...currentValue });
      });
      return {
        ...state,
        appointments,
      };
    }

    case ADD_APPOINTMENT: {
      const appointments = [...state.appointments];
      appointments.push(action.payload.item);
      return {
        ...state,
        appointments,
      };
    }

    case SET_TASK_APPOINTMENTS: {
      let appointments = [...state.appointments];
      appointments = [...appointments, ...action.payload.item];
      return {
        ...state,
        appointments,
      };
    }
    default:
      return state;
  }
};
