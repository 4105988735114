import React, { useEffect, useState } from "react";
import { Chip, DynamicListSelectModal } from ".";

import { strings } from "../../constant/strings";
import { BtnDefaultState } from "./BtnDefaultState";

const DynamicListSelect = props => {
  const {
    type,
    levels,
    answer_key,
    values,
    label,
    setFieldValue,
    formFieldKey,
    errorMsg,
    saveObjAsValue,
    textRight = true,
    containerClass,
    labelContainerClass,
    inputContainerClass,
    noSelectIcon,
    onSelectItem,
    selectInnerClassName = "",
    onClose,
    allSelected,
    placeholder = "",
    setValueAsSelected,
    SelectModal = DynamicListSelectModal,
  } = props;
  const [selected, setSelected] = useState({});
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setValueAsSelected && setSelected(values);
  }, [values]);
  const onSelect = item => {
    setSelected({ ...item });
    setFieldValue &&
      setFieldValue(
        formFieldKey,
        saveObjAsValue ? item[levels[levels.length - 1]] : item[levels[levels.length - 1]][answer_key]
      );
    onSelectItem && allSelected ? onSelectItem(item) : onSelectItem(item[levels[levels.length - 1]]);
  };

  const getChipValue = () => {
    return allSelected
      ? levels.map(e => selected[e]?.name).join(", ")
      : type === "generic_list"
      ? selected[levels[levels.length - 1]]?.name + " "
      : selected[levels[levels.length - 1]]?.select_value + " ";
  };

  const hasValue = () => !!values[formFieldKey];

  const hasSelected = () => !!Object.keys(selected).length;

  const getDefaultChipValue = () => {
    return allSelected ? levels.map(e => values[e].name).join(", ") : values[formFieldKey]?.name;
  };

  return (
    <>
      <div className={containerClass ? containerClass : "form-group row"}>
        <label
          className={
            labelContainerClass ? labelContainerClass : `col-sm-3 col-form-label ${textRight ? "text-right" : ""}`
          }
        >
          {label}
        </label>

        <SelectModal
          visible={modalShow}
          setVisible={setModalShow}
          onSelect={onSelect}
          levels={levels}
          type={type}
          variant="crm-area-modal"
        />

        <div className={inputContainerClass ? inputContainerClass : "col-sm-9"}>
          {hasSelected() || values[formFieldKey] ? (
            <Chip
              disabled={props.disabled}
              title={hasSelected() ? getChipValue() : getDefaultChipValue()}
              onClick={() => setModalShow(true)}
              onClose={() => {
                setSelected({});
                setFieldValue && setFieldValue(formFieldKey, "");
                onClose && onClose();
              }}
              key={getChipValue()}
            />
          ) : null}
          {hasSelected() || hasValue() ? null : noSelectIcon ? (
            <span
              style={props.disabled ? { pointerEvents: "none" } : {}}
              className={`${selectInnerClassName ? selectInnerClassName : "btn btn-skeleton media align-items-center"}`}
              onClick={() => setModalShow(true)}
            >
              {placeholder ? placeholder : strings.select}
            </span>
          ) : (
            <BtnDefaultState disabled={props.disabled} btnText="Select" onClick={() => setModalShow(true)} />
          )}
        </div>
      </div>
      {!!errorMsg && <p className="ml-3 invalid-error">{errorMsg}</p>}
    </>
  );
};

export default DynamicListSelect;
