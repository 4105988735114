import {
  ADD_CAR,
  ADD_CARS_FILTERS,
  CLEAR_CARS,
  FETCH_CARS,
  UPDATE_CAR,
  SET_CARS_LOADING,
} from "../../constant/actionTypes";

export const INITIAL_STATE = {
  list: [],
  filters: {
    status_id: "",
    id: "",
    client_phone: "",
    client_name: "",
  },
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  statuses: [],
  loading: true,
  error: "",
  restrictions: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CAR:
      return {
        ...state,
        list: [action.payload.item].concat([...state.list]),
      };
    case UPDATE_CAR:
      const carsList = state.list;
      let index = carsList.findIndex(e => e.id === action.payload.item.id);
      carsList.splice(index, 1, { ...action.payload.item });
      return {
        ...state,
        list: [...carsList],
      };
    case FETCH_CARS:
      return {
        ...state,
        ...action.payload,
      };

    case ADD_CARS_FILTERS:
      return {
        ...state,
        list: [],
        pagination: {
          current_page: 1,
          next_page: null,
          prev_page: null,
        },
        loading: true,
        error: "",
        filters: { ...action.payload.item },
      };
    case CLEAR_CARS:
      return {
        ...state,
        INITIAL_STATE,
      };
    case SET_CARS_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };
    default:
      return state;
  }
};
