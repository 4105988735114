export const serviceContractList = ["MP - Light Service", "Yes", "MP - Advance Service"];

export const ADVANCED_REPORT_PRICE = 369;

export const carFeaturesList = [
  "Bluetooth System",
  "Cruise Control",
  "Fog Lights",
  "Tiptronic Gears",
  "Climate Control",
  "Front Wheel Drive",
  "Keyless Entry",
  "Parking Sensors",
  "Rear View Camera",
];

export const EMIList = {
  11: 12,
  10: 24,
  9: 36,
  8: 48,
  7: 60,
  6: 60,
  5: 60,
  4: 60,
  3: 60,
  2: 60,
  1: 60,
  0: 60,
};

export const EMI_INTEREST_RATE = 0.0279;
export const MINIMUM_DOWN_PAYMENT = 0.2;

export const AD_STATUSES = {
  LIVE: "live",
  PAUSED: "pause",
  RESERVED: "reserve",
};
