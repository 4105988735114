import React, { useEffect, useRef } from "react";

import FormInput from "../common/FormInput";
import { smoothScrollError } from "../../helpers/functions";
import { strings } from "../../constant/strings";
import { numberValidation } from "../../helpers/validations";
import { isFieldEditable, isFieldViewable } from "../../helpers/Util";

const DemandPriceInput = ({
  values,
  errors,
  handleBlur,
  isSubmitting,
  touched,
  handleChange,
  inventoryPermittedAttributes = null,
  horizontal = true,
}) => {
  const refObject = {
    demandPrice: useRef(),
  };
  useEffect(() => {
    smoothScrollError(errors, refObject, isSubmitting);
  });
  return (
    <div id={strings.listing_price} className="card">
      <div className="card-header">
        <h5>{strings.listing_price}</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            {isFieldViewable(inventoryPermittedAttributes?.["inventory.ask_price"]) && (
              <FormInput
                label={strings.listing_price}
                placeholder={strings.price_ph}
                horizontal={horizontal}
                value={values.demandPrice}
                onBlur={handleBlur("demandPrice")}
                errorMsg={errors.demandPrice && touched.demandPrice && errors.demandPrice}
                onChange={handleChange("demandPrice")}
                formRef={refObject["demandPrice"]}
                type={"number"}
                isEditable={isFieldEditable(inventoryPermittedAttributes?.["inventory.ask_price"])}
                id={"demandPrice"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandPriceInput;
