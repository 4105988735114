import { CzCallIcon, GoDialerIcon } from "components/svg-icon/svg-icons";
import React, { FC, Fragment, useState } from "react";
import { Eye } from "react-feather";
import Button from "../Button/Button";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";
import { Flex } from "antd";

interface CallPhoneRowProps {
  phoneNumber: string;
  phoneLoading?: boolean;
  submitCzCall: (number: string) => void;
  submitGoCall: () => void;
}

const CallPhoneRow: FC<CallPhoneRowProps> = ({
  phoneNumber = "",
  phoneLoading = false,
  submitCzCall = () => {},
  submitGoCall = () => {},
}) => {
  const [showNumber, setShowNumber] = useState(false);

  return (
    <Flex align="center" justify="space-between">
      <Flex align="center" gap={4}>
        {showNumber ? (
          <>
            <span style={{ color: "#707070" }}>{phoneNumber}</span>
            <CopyToClipboard value={phoneNumber} />
          </>
        ) : (
          <>
            <span>{"****************"}</span>
            <Button
              onClick={() => setShowNumber(true)}
              icon={<Eye size={14} />}
              btnText=""
              color="link"
              className={"p-0"}
            />
          </>
        )}
      </Flex>
      <Flex align="center" gap={10}>
        <Button onClick={() => submitGoCall()} icon={<GoDialerIcon />} btnText="" color="link" className={"p-0"} />
        <Button
          onClick={() => submitCzCall(phoneNumber)}
          icon={<CzCallIcon />}
          btnText=""
          color="link"
          className={"p-0"}
        />
      </Flex>
    </Flex>
  );
};

export default CallPhoneRow;
