import React, { Fragment, useEffect, useRef, useState } from "react";
import { CheckboxComponent, FormInput } from "./index";
import { FaCaretDown } from "../svg-icon/svg-icons";
import { strings } from "../../constant/strings";

const CheckboxDropdown = props => {
  const [optionsData, setOptionsData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const ref = useRef(null);
  const { label, filterKey, filtersObject, setFiltersObject, isFilter = true, ...rest } = props;
  const setValues = () => {
    if (inputValue) {
      const filteredData = props.options.filter(e => e?.name?.toLowerCase().includes(inputValue?.toLowerCase()));
      setOptionsData(filteredData);
    } else {
      setOptionsData(props.options);
    }
  };
  useEffect(() => {
    setValues();
  }, [props.options, inputValue]);

  useEffect(() => {
    !dropdownOpen && setInputValue("");
  }, [dropdownOpen]);

  useEffect(() => {
    const handleClickOutside = event => ref.current && !ref.current.contains(event.target) && setDropdownOpen(false);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getValue = () => {
    const filtersObjValues = props.filtersObject[filterKey];
    if (!isFilter) return;
    const filterObjValuesLength = filtersObjValues?.split(",")?.length;
    if (filterObjValuesLength > 1) {
      const firstSelectedValue = filtersObjValues?.split(",")[0];
      const othersLength = filterObjValuesLength - 1;
      return `${props.options?.find(item => item.id === parseInt(firstSelectedValue))?.name} ${
        othersLength > 0 ? `(+${othersLength})` : ""
      }`;
    }
    return filterObjValuesLength
      ? optionsData
          ?.filter(option => filtersObjValues.split(",").find(selected => option.id === parseInt(selected)))
          .map(e => e.name)
          .join(", ")
      : "";
  };

  const renderIconCaret = () => {
    return (
      <span>
        <FaCaretDown />
      </span>
    );
  };

  return (
    <div className="dropdown-caret p-relative" ref={ref} onClick={e => setDropdownOpen(true)}>
      <FormInput
        placeholder={label ? label : strings.select_category}
        onChange={e => setInputValue(e.target.value)}
        value={dropdownOpen ? inputValue : getValue()}
        className="mb-0"
        right={renderIconCaret}
      />
      {dropdownOpen && (
        <CheckboxDropdownOptions
          options={optionsData}
          label={label}
          clearAll={props.clearAll}
          onChange={props.onChange}
          filtersObject={props.filtersObject}
          filterKey={props.filterKey}
        />
      )}
    </div>
  );
};

const Clear = ({ clearAll = null, label, onChange, options, isFilter }) => {
  return (
    <span className="clear-checkbox-dropdowns">
      <span>{label.toUpperCase()} </span>
      <span
        className="clear-button"
        onClick={() => {
          clearAll && clearAll();
          isFilter && onChange(options);
        }}
      >
        Clear All
      </span>
    </span>
  );
};

const CheckboxDropdownOptions = ({ options, label, clearAll, onChange, filtersObject, filterKey, isFilter }) => {
  return (
    <div className="checkbox-dropdown-options">
      <Clear clearAll={clearAll} label={label} onChange={onChange} options={options} isFilter={isFilter} />
      <span>
        {options?.map(option => {
          return (
            <Fragment key={option.id}>
              {<ChildItem data={option} onChange={onChange} filtersObject={filtersObject} filterKey={filterKey} />}
            </Fragment>
          );
        })}
      </span>
    </div>
  );
};

const ChildItem = ({ data, onChange, filtersObject, filterKey }) => {
  return (
    <div key={data.id}>
      <CheckboxComponent
        label={data.name}
        onChange={e => {
          onChange(data);
        }}
        id={`${data.id}-checkbox`}
        checked={!!filtersObject[filterKey]?.includes(data.id)}
      />
    </div>
  );
};

export default CheckboxDropdown;
