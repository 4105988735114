import { FETCH_CITIES } from "../../constant/actionTypes";
import { fetchCitiesApi } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

export const fetchCities =
  (queryParams = "") =>
  async dispatch => {
    dispatch({
      type: FETCH_CITIES,
      payload: {
        list: [],
        loading: true,
        error: "",
      },
    });
    const res = await fetchCitiesApi(queryParams);
    if (res && res.success) {
      dispatch({
        type: FETCH_CITIES,
        payload: {
          list: [...res.cities],
          loading: false,
          error: "",
        },
      });
    } else {
      dispatch({
        type: FETCH_CITIES,
        payload: {
          list: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  };
