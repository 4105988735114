import {
  ADD_AUTHENTICATION_TOKEN,
  EXPIRE_AUTHENTICATION_TOKEN,
  FETCH_AUTHENTICATION_TOKENS,
  SET_AUTHENTICATION_TOKENS_LOADING,
  UPDATE_AUTHENTICATION_TOKEN,
} from "../../constant/actionTypes";

const INITIAL_STATE = {
  tokens: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_AUTHENTICATION_TOKEN:
      return {
        ...state,
        tokens: [action.payload.item].concat([...state.tokens]),
      };

    case UPDATE_AUTHENTICATION_TOKEN:
      const tokensList = state.tokens;
      let index = tokensList.findIndex(e => e.id === action.payload.item.expiryTimeId);
      tokensList[index].expiry = action.payload.item.time;

      return {
        ...state,
      };

    case EXPIRE_AUTHENTICATION_TOKEN:
      const tokenList = state.tokens;
      let deleteIndex = tokenList.findIndex(e => e.id === action.payload.id);
      tokenList[deleteIndex].is_expired = true;
      return {
        ...state,
        tokens: [...tokenList],
      };

    case FETCH_AUTHENTICATION_TOKENS:
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
        tokens: [...action.payload.tokens],
        pagination: action.payload?.pagination ? { ...action.payload.pagination } : { ...state.pagination },
        permittedMeta: action.payload?.permittedMeta,
      };

    case SET_AUTHENTICATION_TOKENS_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    default:
      return state;
  }
};
