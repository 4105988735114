import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { checkifTenantIsDubizzle, getErrorString, openBulkImportUrl } from "../../helpers/Util";
import { strings } from "../../constant/strings";
import CheckboxComponent from "./CheckboxComponent";
import { Button, Close, CustomAlert, Modal, RadioButton } from "../../components/common";
import { Formik } from "formik";
import { CRM_LEAD_EXCEL_FILES, CRM_LEAD_TYPES, LEAD_TYPES } from "constant/appConstants";
import { GoPlus, IconExcel, IoMdDownloadIcon, MdFolderOpen, MdInfoOutline } from "../svg-icon/svg-icons";
import { uploadPaymentSheetApi } from "../../helpers/ApiHelper";
import * as yup from "yup";
import imageUploadIllustration from "../../assets/illustrations/image-upload-illustration.svg";
import { useSelector } from "react-redux";
import { selectCRMLeadsData, selectTenant } from "../../reducers/general/selectors";
import DropZone from "./DropZone";

const ImportLeadsModal = props => {
  const { categories: leadCategories = [] } = useSelector(selectCRMLeadsData);
  const [confirmed, setConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { title } = props;
  const formRef = useRef();

  const getModalActions = () => {
    const list = [
      {
        label: strings.close,
        onClick: () => {
          props.setShowModal(false);
          setSuccess(false);
        },
        color: "outline-dark",
        className: "modal__cancel-btn btn-pill",
      },
      {
        label: strings.import,
        color: "primary",
        className: "modal__cr-btn btn-pill",
        onClick: () => formRef.current && formRef.current.submitForm(),
        disabled: !confirmed,
        loading,
      },
    ];
    if (success) {
      return [list[0]];
    }
    return list;
  };

  return (
    <Modal
      toggle={() => {
        props.onToggle();
        setSuccess(false);
      }}
      title={title}
      open={!!props.showModal}
      body={() => (
        <RenderModalBody
          leadCategories={leadCategories}
          setLoading={setLoading}
          setConfirmation={setConfirmation}
          ref={formRef}
          setSuccess={setSuccess}
          success={success}
          {...props}
        />
      )}
      className="import-bulk-leads-popup"
      actions={getModalActions()}
    />
  );
};

const RenderModalBody = forwardRef((props, ref) => {
  const formikRef = useRef();
  const { setConfirmation, setSuccess, success, leadCategories } = props;

  const tenant = useSelector(selectTenant);
  const isTenantDubizzle = checkifTenantIsDubizzle({ tenant });

  useImperativeHandle(ref, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  const getImportLeadModalValidationSchema = yup.object().shape({
    file: yup.mixed().required(strings.upload_file),
    selectedLead: yup.string().nullable().required(strings.select_lead_type),
  });

  const getImportLeadModalInitialValues = () => ({
    file: null,
    selectedLead: CRM_LEAD_TYPES.find(leadType => leadType.slug === "seller")?.slug,
    confirmationCheck: false,
  });

  const onSubmit = async (values, { setStatus }) => {
    const data = new FormData();
    data.append("file", values.file);
    data.append("category_slug", values.selectedLead);
    props.setLoading(true);
    const response = await uploadPaymentSheetApi(data);
    if (response && response.success) {
      //props.setShowModal(false);
      props.setLoading(false);
      setSuccess(response.message);
      setStatus({ message: "" });
      //Toast.success(response.message);
      props.fetchLeads();
    } else {
      props.setLoading(false);
      setStatus({ message: getErrorString(response) });
    }
  };

  const RenderFileUploader = props => {
    const file = props.values.file;

    return (
      <div>
        <DropZone
          onDropCallback={file => {
            props.setFieldValue("file", file[0]);
            props.setStatus({ message: "" });
          }}
        >
          {file ? (
            <div className="img-upload-wrapper d-flex hover-pointer flex-row align-items-center justify-content-between spbwx8">
              <div className="d-flex flex-row align-items-center">
                <MdFolderOpen size={30} className="download-icon" />
                {file?.name}
              </div>
              {!success && (
                <Close
                  disabled={success}
                  className="btn-close-right"
                  onClick={e => {
                    props.setFieldValue("file", null);
                    props.setStatus({ message: "" });

                    props.setFieldValue("confirmationCheck", false);
                  }}
                  type={"button"}
                />
              )}
            </div>
          ) : (
            <>
              <div className="mx-0 uploadHorizontalView">
                <figure className="img-upload-illustration-wrapper">
                  {<img src={imageUploadIllustration} style={{ minHeight: "100%" }} />}
                </figure>
                <div>
                  <h6>{strings.upload_file}</h6>
                  <p>{strings.upload_xls_file}</p>

                  <input
                    type="file"
                    multiple
                    accept={".xlsx,.xls"}
                    style={{ display: "none" }}
                    id={props.id}
                    onChange={event => {
                      props.setFieldValue("file", event.target.files[0]);
                      props.setStatus({ message: "" });
                    }}
                  />

                  <Button
                    btnText={strings.upload}
                    color="link"
                    icon={<GoPlus />}
                    className="p-0 f-w-500"
                    onClick={() => {
                      document.getElementById(props.id).click();
                    }}
                    errorMsg={props.touched["file"] && props.errors["file"]}
                  />
                </div>
              </div>
            </>
          )}
        </DropZone>
      </div>
    );
  };

  const RenderSheetFormat = props => {
    const leadType = {
      [LEAD_TYPES.BUYER]: {
        type: LEAD_TYPES.BUYER,
        excelSheetHeading: CRM_LEAD_EXCEL_FILES.BUYER,
      },
      [LEAD_TYPES.SELLER]: {
        type: LEAD_TYPES.SELLER,
        excelSheetHeading: CRM_LEAD_EXCEL_FILES.SELLER,
      },
      [LEAD_TYPES.VAS]: {
        type: LEAD_TYPES.VAS,
        excelSheetHeading: CRM_LEAD_EXCEL_FILES.VAS,
      },
    };
    const { type: selectedLead = "", excelSheetHeading = "" } = leadType[props.values?.selectedLead] ?? "";

    const getLeadSample = (tenant, type) =>
      `${window.location.origin}/${tenant}/carforce_import_${type}_lead_sample.xlsx`;

    const tenantType = isTenantDubizzle ? "dubizzle" : "generic";

    const leadSample = getLeadSample(tenantType, selectedLead);
    const handleClick = () => openBulkImportUrl(leadSample);

    return (
      <>
        <p className={"text-muted"}> {props.heading}</p>
        <div className="d-flex justify-content-between download-box mb-4">
          <div className="d-flex align-items-center">
            <IconExcel className="mr-3" size="2.125em" />
            {excelSheetHeading}
          </div>
          <div className="d-flex align-items-center" onClick={handleClick}>
            <IoMdDownloadIcon className="mr-1" size="1.25em" color="#0bb233" />
          </div>
        </div>
      </>
    );
  };

  const RenderRadioButtons = props => {
    return (
      <div className="form-group d-flex">
        <label className=" col-form-label text-right">{strings.file_type}</label>
        <div className=" d-flex flex-row align-items-center mb-0 p-20 pt-0 pb-0">
          {leadCategories.map(leadType => {
            return (
              <React.Fragment key={leadType.slug}>
                <RadioButton
                  label={leadType.name}
                  onChange={e => {
                    props.setFieldValue("selectedLead", leadType.slug);
                    props.setFieldValue("file", null);
                    props.setFieldValue("confirmationCheck", false);
                    props.setStatus({ message: "" });
                    setConfirmation(false);
                  }}
                  disabled={success}
                  id={leadType.slug}
                  checked={props.values?.selectedLead === leadType.slug}
                />
              </React.Fragment>
            );
          })}
        </div>
        <span className="invalid-error">{props.touched["selectedLead"] && props.errors["selectedLead"]}</span>
      </div>
    );
  };
  const RenderConfirmationMessage = props => {
    return (
      <div className="d-flex align-items-start mb-2">
        {success ? (
          <>
            <div className="leadsError-title mb-5">
              <MdInfoOutline height={"1.5em"} width={"1.5em"} className="leadsError-icon" />
              <span>{success}</span>
            </div>
          </>
        ) : (
          <>
            <CheckboxComponent
              disabled={props.values.file === null}
              onChange={e => {
                props.setFieldValue("confirmationCheck", e.target.checked);
                setConfirmation(e.target.checked);
              }}
              id={"finalize-contract-checkbox"}
              checked={props.values.confirmationCheck}
            />
            <span>{strings.payment_sheet_upload_confirmation}</span>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={getImportLeadModalInitialValues()}
        validationSchema={getImportLeadModalValidationSchema}
        onSubmit={onSubmit}
      >
        {formikProps => {
          const { status } = formikProps;
          return (
            <form>
              <RenderRadioButtons {...formikProps} />
              <RenderSheetFormat {...formikProps} />
              <RenderFileUploader id={"excel-file-upload"} success={success} {...formikProps} />
              <RenderConfirmationMessage success={success} {...formikProps} />

              {status?.message && <CustomAlert message={status.message} />}
            </form>
          );
        }}
      </Formik>
    </>
  );
});
export default ImportLeadsModal;
