import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { strings } from "../../constant/strings";
import { markAsNegotiator } from "../../helpers/ApiHelper";
import { Modal } from "../common";
import Toast from "../common/Toast";
import { getErrorString } from "../../helpers/Util";
import { fetchAuctionNegotiations } from "../../actions/auction/auctionNegotiations.actions";

const MarkAsNegotiatorModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);

  const { bidder } = showModal || {};
  const onConfirm = async () => {
    let payload = {
      negotiation: {
        offer_id: showModal?.id,
        auction_id: showModal?.auction?.id,
        started_by_id: showModal?.bidder?.id,
        amount: showModal?.bid_amount,
        negotiation_type: "Manual",
        negotiation_jump: showModal?.auction?.offer_jump,
      },
    };

    setLoading(true);
    try {
      const response = await markAsNegotiator(payload);
      if (response.success) {
        dispatch(fetchAuctionNegotiations(`?f[auction_id]=${showModal?.auction?.id}`));
        setLoading(false);
        setShowModal(false);
      }
    } catch (error) {
      setLoading(false);

      Toast.error(getErrorString(error));
    }
  };

  const promptTag = `${strings.mark_as_negotiator_prompt} ${bidder.first_name ? bidder.first_name : "this bidder"}?`;
  return (
    <div>
      <Modal
        toggle={() => setShowModal(null)}
        title={<div>{strings.mark_as_negotiator}</div>}
        open={!!showModal}
        // scrollable={true}
        body={() => <div>{promptTag}</div>}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setShowModal(false);
            },
            color: "secondary",
            className: "modal__cancel-btn",
          },
          {
            label: strings.confirm,
            onClick: () => {
              onConfirm();
            },
            color: "primary",
            className: "modal__cancel-btn",
            loading: loading,
          },
        ]}
      />
    </div>
  );
};

export default MarkAsNegotiatorModal;
