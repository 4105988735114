import { Avatar, CustomAlert, Toast } from "../../components/common";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { checkUndefinedApiParams, getErrorString, makeListToSelectData } from "../../helpers/Util";
import { FiSearch, MdPerson } from "../svg-icon/svg-icons";

import Select from "react-select";
import cx from "clsx";
import { strings } from "../../constant/strings";
import UserOption from "./UserOption";

const SearchAssignee = props => {
  const [value, setValue] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    props.dataFromProps && setUsers(props.dataFromProps);
  }, [props.dataFromProps]);

  useEffect(() => {
    setValue(props.user);
  }, [props.user]);

  const {
    containerClassName,
    label,
    labelClassName,
    textRight,
    horizontal,
    errorMsg,
    errorType,
    errorClassName,
    columnsLeftSection = "3",
    columnsRightSection = "9",
    infoView,
    avatarSize = "28",
    filterCurrentUser,
    direction = "down",
    labelUser,
    selectHeading,
    showLabel = true,
    ...rest
  } = props;

  const fetchData = async params => {
    setUsers([]);
    const response = await props.apiCall(params);
    setLoading(true);
    if (response) {
      setLoading(false);
      const usersKey = props.fetchResponseKey || "users";
      if (response.success && response[usersKey]) {
        let usersList = response[usersKey];
        if (filterCurrentUser) usersList = usersList.filter(user => user.id !== filterCurrentUser);
        setUsers(usersList);
      } else {
        setError(getErrorString(response));
      }
    }
  };

  const postData = async e => {
    if (!checkUndefinedApiParams(props.id)) return;
    toggle();
    let obj = {
      [props.payloadKey]: {
        assignee_id: e.id,
      },
    };
    const response = await props.updateAssignee(props.id, obj, "change_assignee");
    if (response) {
      if (response[props.payloadKey]) {
        props.onSuccess && props.onSuccess(e, response[props.payloadKey]);
        props.fetchCrmLeadActivities && props.fetchCrmLeadActivities(props.crmLeadId ? props.crmLeadId : props.id);
        setValue(e);
      } else {
        Toast.error(getErrorString(response));
      }
    }
  };

  const onSelectChange = value => {
    if (props.onChange) {
      props.onChange(value);
      toggle();
    } else {
      postData(value);
    }
  };

  // const styles = { bottom: 0, left: 0, top: 0, right: 0, position: "fixed", zIndex: 1 };

  // const DropDown = ({ children, isOpen, target, onClose }) => {
  //   return (
  //     <div className={"searchAssignee"}>
  //       {target}
  //       {isOpen ? <div className={"dropdown"}>{children}</div> : null}
  //       {isOpen ? <Blanket onClick={onClose}> </Blanket> : null}
  //     </div>
  //   );
  // };

  // const Blanket = props => <div style={styles} onClick={props.onClick} />;

  const customStyles = {
    container: provided => ({
      ...provided,
      minWidth: "220px",
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: "none",
    }),
    control: provided => ({
      ...provided,
      boxShadow: "none",
      borderColor: "#efefef",
      margin: "0.5rem",
    }),
    indicatorsContainer: provided => ({
      ...provided,
      width: "2rem",
      justifyContent: "center",
      fontSize: "1.125rem",
      color: "#919191",
    }),
    menu: provided => ({
      ...provided,
      width: "100%",
      position: "static",
      marginBottom: "0",
      borderRadius: "0 0 4px 4px",
      border: "1px solid #efefef",
      boxShadow: "none",
      overflow: "hidden",
    }),
  };

  const DropdownIndicator = () => {
    return <FiSearch />;
  };

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const renderLabel = () =>
    label ? (
      <label
        className={cx(
          labelClassName ? labelClassName : horizontal && `col-form-label d-flex col-sm-${columnsLeftSection}`,
          textRight && "justify-content-end"
        )}
      >
        {label}
      </label>
    ) : null;

  const renderInput = () => {
    return (
      <div className={cx("d-flex align-items-center", horizontal && `col-sm-${columnsRightSection}`)}>
        <Dropdown
          className={`search-assignee dropdown-status ${
            props.disabled ? "" : "custom-field-state"
          } d-flex align-items-center b-r-6`}
          isOpen={dropdownOpen}
          toggle={toggle}
          direction={direction}
          disabled={props.disabled}
        >
          <DropdownToggle
            style={{ cursor: props.disabled ? "default" : "pointer" }}
            className="d-flex align-items-center py-0 pl-0"
            caret={!props.disabled ? true : false}
            color="skeleton"
            onClick={() => {
              const shouldFetchUsers = !props.disabled && props.apiCall;
              shouldFetchUsers && fetchData(props.fetchParams ? props.fetchParams : "");
            }}
          >
            <div
              className="d-flex align-items-center"
              // onClick={() => !props.disabled && fetchData(props.fetchParams ? props.fetchParams : "")}
            >
              {!value && !infoView ? (
                <>
                  <div className="media-icon simple bordered">
                    <MdPerson />
                  </div>
                  {selectHeading || "Select Assignee"}
                </>
              ) : !value && infoView ? (
                <div className={cx("d-flex spbwx8 info-block", showLabel ? "align-items-end" : " align-items-center")}>
                  <div className="media-icon simple bordered">
                    <MdPerson />
                  </div>
                  <div className="d-flex flex-column align-items-start">
                    <div className="info-block-title">{showLabel && (labelUser || strings.assigned_to)}</div>
                    <div className="info-block-desc"> {selectHeading || "Select Assignee"}</div>
                  </div>
                </div>
              ) : value && infoView ? (
                <div className={cx("d-flex spbwx8 info-block", showLabel ? "align-items-end" : " align-items-center")}>
                  <Avatar
                    avatarOnly={true}
                    name={value?.name || value?.first_name || value?.full_name}
                    size={avatarSize}
                    imgSrc={value?.picture?.url}
                  />
                  <div className="d-flex flex-column align-items-start">
                    <div className="info-block-title">{showLabel && (labelUser || strings.assigned_to)}</div>
                    <div className="info-block-desc"> {value?.name || value?.first_name || value?.full_name}</div>
                  </div>
                </div>
              ) : (
                <Avatar name={value?.name || value?.first_name} size={avatarSize} imgSrc={value?.picture?.url} />
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu container="body" className="selectDropdown">
            <Select
              backspaceRemovesValue={false}
              components={!props.disabled ? { DropdownIndicator } : {}}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={!props.disabled}
              // isDisabled={props.disabled}
              menuIsOpen
              onChange={onSelectChange}
              options={makeListToSelectData(users || [])}
              placeholder="Search..."
              classNamePrefix="select"
              styles={customStyles}
              tabSelectsValue={false}
              formatOptionLabel={option => (
                <Fragment key={option.id}>
                  <UserOption value={option} />
                </Fragment>
              )}
            />
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  };

  return (
    <div
      className={
        containerClassName != undefined
          ? containerClassName
          : `${horizontal ? "form-group row form-group-y" : ""}`.trim()
      }
    >
      {!!errorMsg && errorType === "icon" && (
        <CustomAlert message={errorMsg} type="icon" containerClassName={errorClassName} />
      )}
      {!!errorMsg && errorType !== "icon" && <p className={"invalid-error " + errorClassName}>{errorMsg}</p>}
      {renderLabel()}
      {renderInput()}
    </div>
  );
};

export default SearchAssignee;
