import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { strings } from "../constant/strings";
import { fetchCrmItemListsApi } from "../helpers/ApiHelper";
import { stateUpdateCallback } from "../helpers/Util";

//Query keys for listing filters
const MAKE_QUERY_ID = "f[make.id][]";
const MODEL_QUERY_ID = "f[model.id][]";
const VARIANT_QUERY_ID = "f[variant.id][]";

//Make Model Variant keys for Ids
let MAKE_ID = "make.select_value";
let MODEL_ID = "model.select_value";
let VARIANT_ID = "variant.select_value";

const ANCESTRY_KEY = "q[ancestry_in][]";

const makeModelVariant = (data = {}) => {
  const { make = {}, model = {}, variant = {} } = data;
  const makeQueryId = make.queryId || MAKE_QUERY_ID;
  const modelQueryId = model.queryId || MODEL_QUERY_ID;
  const variantQueryId = variant.queryId || VARIANT_QUERY_ID;

  return [
    {
      id: make.filterId || MAKE_ID,
      queryId: makeQueryId,
      itemListQueryKey: { "q[select_key_eq]": "make" },
      filterStateKey: "makeList",
      label: strings.make,
      parent: null,
      child: ["f[model.id][]", "f[variant.id][]"],
    },
    {
      id: model.filterId || MODEL_ID,
      queryId: modelQueryId,
      itemListQueryKey: { "q[select_key_eq]": "model" },
      filterStateKey: "modelList",
      label: strings.model,
      parent: makeQueryId,
      child: ["f[variant.id][]"],
    },
    {
      id: variant.filterId || VARIANT_ID,
      queryId: variantQueryId,
      itemListQueryKey: { "q[select_key_eq]": "variant" },
      filterStateKey: "variantList",
      label: strings.variant,
      parent: modelQueryId,
      child: [],
    },
  ];
};

const makeQueryData = (data = {}) =>
  makeModelVariant(data).reduce((acc, item) => ({ ...acc, [item.queryId]: item.itemListQueryKey }), {});
const makeInitialData = (data = {}) =>
  makeModelVariant(data).reduce((acc, item) => ({ ...acc, [item.filterStateKey]: [] }), {});

export const useMakeModel = (setFilters, dependencyArray = [], makeModelVariantMetaData = {}) => {
  const [filterStates, setFilterStates] = useState(makeInitialData(makeModelVariantMetaData));
  const [queryData, setQueryData] = useState(makeQueryData(makeModelVariantMetaData));
  const history = useHistory();
  const filterStateHelper = {
    set: setFilterStates,
    get: filterStates,
    reset: (type = null) => {
      if (!type) {
        setFilterStates(makeInitialData(makeModelVariantMetaData));
        setQueryData(makeQueryData(makeModelVariantMetaData));
        return;
      }
    },
  };

  const getMakeModelVariantFilters = () => {
    return makeModelVariant(makeModelVariantMetaData)
      .map(item => {
        const { id, label, queryId, filterStateKey, parent, child } = item;
        const ancestryData = queryData[queryId]?.[ANCESTRY_KEY];
        const asyncQueryKey = parent ? ancestryData?.join(",") || queryId : queryId;
        const showFilter = parent ? ancestryData?.length > 0 : true;
        return {
          id,
          sizeratio: 1,
          label: label,
          key: queryId,
          type: "scrollable-dropdown",
          listApi: fetchCrmItemListsApi,
          extraParams: queryData[queryId],
          listProperty: "item_lists",
          getDataInParent: data =>
            setFilterStates(prev => ({ ...prev, [filterStateKey]: stateUpdateCallback(prev[filterStateKey], data) })),
          queryKey: "q[select_value_cont]",
          formatOptions: option => option.title,
          isSingleSelect: false,
          customClass: "col-sm-6 col-md-4 col-xl-2 m-b-10 async-select-filter z-index-12",
          asyncQueryKey,
          child: child,
          showFilter,
        };
      })
      .filter(item => item.showFilter);
  };

  useEffect(() => {
    if (!history.location.search) {
      setFilterStates({ ...filterStates, modelList: [], variantList: [] });
      setQueryData(makeQueryData(makeModelVariantMetaData));
    } else {
      const { queryObj } = setFilters();
      const data = formatQueryData(queryObj, makeModelVariantMetaData);
      setQueryData({ ...queryData, ...data });
    }
  }, [history.location.search, ...dependencyArray]);

  return [
    filterStates?.makeList,
    filterStates?.modelList,
    filterStates?.variantList,
    getMakeModelVariantFilters,
    filterStateHelper,
  ];
};

const formatQueryData = (queryObj = {}, data = {}) => {
  let queryStateData = {};
  for (const value of makeModelVariant(data)) {
    if (!value.parent) continue;
    const parent = queryObj[value.parent];
    const ancestryData = parent?.split(",");
    const data = {
      [value.queryId]: {
        ...value.itemListQueryKey,
        [ANCESTRY_KEY]: ancestryData,
      },
    };
    queryStateData = { ...queryStateData, ...data };
  }
  return queryStateData;
};
