import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";

const ShareOnWhatsapp = ({ text }) => {
  const URL = "https://web.whatsapp.com/send?text=:text";
  const handleClick = () => {
    window.open(`${URL.replace(":text", encodeURIComponent(text))}&app_absent=0`, "_blank");
  };
  return (
    <>
      <IoLogoWhatsapp size={16} color="#25D366" onClick={handleClick} className="hover-pointer" />
    </>
  );
};

export default ShareOnWhatsapp;
