import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { strings } from "../../constant/strings";
import { DatePicker, DropdownSelect, FormInput, Modal, Toast, Copy } from "components/common";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as yup from "yup";
import { updateCrmPayment } from "actions/crm";
import { CRM_CARS_DETAIL_PATH, CRM_LEAD_DETAIL_PATH } from "constant/appPaths";
import { ADYEN_PAYMENT_LINK, LINK_STATUS, PAYMENT_DISPOSITIONS, PAYMENT_STATUSES } from "constant/appConstants";
import { changePaymentStatusApi, fetchPaymentApi } from "helpers/ApiHelper";
import {
  appendTimeZone,
  canEditPayment,
  checkUndefinedApiParams,
  getCurrency,
  getErrorString,
  getTimeDateString,
  groupByOnKeys,
  isFieldRestricted,
  isValidDate,
  makeDispositionSelectData,
  replaceKeyWithId,
  setTimeZone,
} from "helpers/Util";
import { selectTimeZone } from "reducers/general/selectors";
import FileTilePreview from "components/crm/FileTilePreview";
import { futureDate, getLastMinRangeOfDays, getValueDatePlaceholder } from "components/crm/Payments/Helpers";
import { Circle } from "components/svg-icon/svg-icons";

const ChangePaymentStatus = props => {
  const [paymentsData, setPaymentData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const country = useSelector(state => state.Configs.tenant.country);
  const valueDateLimit = useSelector(state => state.Configs?.tenant.configuration.value_date_limit);
  const futureValueDateLimit = useSelector(state => state.Configs?.tenant.configuration.future_value_date_limit);
  const dispatch = useDispatch();

  const formikRef = useRef();
  const history = useHistory();
  const timeZone = useSelector(selectTimeZone);

  useEffect(() => {
    if (props.openModal) {
      getPaymentData(props.paymentId);
    }
  }, [props.openModal]);

  useEffect(() => {
    if (paymentsData) {
      const data = {
        transaction_id: paymentsData?.payment?.transaction_id,
        transaction_date: paymentsData?.payment?.transaction_date
          ? setTimeZone(country?.timezone, paymentsData?.payment?.transaction_date)
          : null,
        possibleDispositions: getPossibleDispositions(paymentsData?.possibleDispositions),
        paymentProofs: paymentsData?.payment?.payment_proofs,
        selectedDisposition: paymentsData?.payment?.disposition,
        value_date: paymentsData?.payment?.value_date
          ? setTimeZone(country?.timezone, paymentsData?.payment?.value_date)
          : null,
      };
      formikRef.current && formikRef.current.setValues(data);
    }
  }, [paymentsData]);

  const getPossibleDispositions = dispositions => {
    const paymentMethod = paymentsData?.payment?.crm_payment_method;
    const filteredDispositions = dispositions?.filter(disposition => {
      if (paymentMethod?.slug === ADYEN_PAYMENT_LINK.slug) {
        return disposition?.status_slug !== PAYMENT_DISPOSITIONS.DEPOSITED;
      } else {
        return disposition?.status_slug !== PAYMENT_DISPOSITIONS.PENDING;
      }
    });

    return filteredDispositions;
  };

  const getPaymentData = async paymentId => {
    if (!checkUndefinedApiParams(paymentId, "payment")) return;
    try {
      const res = await fetchPaymentApi(paymentId);
      if (res.crm_payment) {
        setPaymentData({ payment: res.crm_payment, possibleDispositions: res?.possible_dispositions });
      }
    } catch (e) {
      Toast.error(getErrorString(e));
    }
  };

  const renderTotalAmount = () => {
    return (
      <div className="info-block">
        <div className="info-block-title">{strings.amount}</div>
        <div className="info-block-desc">{getCurrency(country, paymentsData?.payment?.amount) ?? "-"}</div>
      </div>
    );
  };

  const renderCollectorName = () => {
    return (
      paymentsData?.payment?.deposited_by?.name && (
        <div className="info-block">
          <div className="info-block-title">{strings.collected_by}</div>
          <div className="info-block-desc">{paymentsData?.payment?.deposited_by?.name ?? "-"}</div>
        </div>
      )
    );
  };

  const renderCollectedDate = () => {
    return paymentsData?.transaction_date ? (
      <div className="info-block">
        <div className="info-block-title">{strings.transaction_date}</div>
        <div className="info-block-desc">
          {getTimeDateString(country?.timezone, paymentsData?.payment?.collected_at) ?? "-"}
        </div>
      </div>
    ) : null;
  };

  const renderPaymentMethod = () => {
    return (
      <div className="info-block">
        <div className="info-block-title">{strings.payment_method}</div>
        <div className="info-block-desc">{paymentsData?.payment?.crm_payment_method?.name ?? "-"}</div>
      </div>
    );
  };

  const renderPaymentType = () => {
    return (
      <div className="info-block">
        <div className="info-block-title">{strings.payment_type}</div>
        <div className="info-block-desc">{paymentsData?.payment?.crm_payment_type?.name ?? "-"}</div>
      </div>
    );
  };

  const renderInventoryName = () => {
    const hasLink = !!paymentsData?.payment?.inventory?.inventoriable_id;
    return (
      <div className="info-block">
        <div className="info-block-title">{strings.car_ref}</div>
        <div
          className="info-block-desc text-break infoItemHover"
          onClick={e => {
            e.preventDefault();
            if (!hasLink) return;
            history.push(
              replaceKeyWithId(CRM_CARS_DETAIL_PATH, ":id", paymentsData?.payment?.inventory?.inventoriable_id)
            );
          }}
        >
          {paymentsData?.payment?.inventory?.inventoriable?.reference_number ?? "-"}
        </div>
      </div>
    );
  };

  const renderCrmLead = () => {
    const hasLink = !!paymentsData?.payment?.crm_lead?.id;
    return (
      <div className="info-block">
        <div className="info-block-title">{strings.lead_ref}</div>
        <div
          className="info-block-desc text-break infoItemHover"
          onClick={e => {
            e.preventDefault();
            if (!hasLink) return;
            history.push(replaceKeyWithId(CRM_LEAD_DETAIL_PATH, ":id", paymentsData?.payment?.crm_lead?.id));
          }}
        >
          {paymentsData?.payment?.crm_lead?.reference_number}
        </div>
      </div>
    );
  };

  const renderPaymentStatus = () => {
    return (
      <div className="info-block">
        <div className="info-block-title">{strings.payment_status}</div>
        <div className="info-block-desc">{paymentsData?.payment?.status?.name}</div>
      </div>
    );
  };

  const formInitialValues = () => {
    return {
      transaction_id: "",
      transaction_date: null,
      possibleDispositions: [],
      selectedDisposition: null,
      value_date: null,
    };
  };
  const onSubmit = async values => {
    if (!checkUndefinedApiParams(props.paymentId, "payment")) return;
    const data = {
      disposition_id: values.selectedDisposition?.id,
      transaction_date: appendTimeZone(values.transaction_date, timeZone),
      transaction_id: values.transaction_id,
      value_date: appendTimeZone(values?.value_date, timeZone),
    };
    setIsSubmitting(true);
    const res = await changePaymentStatusApi(props.paymentId, JSON.parse(JSON.stringify(data)));
    if (res.crm_payment) {
      props.setOpenModal(null);
      // dispatch action
      dispatch(updateCrmPayment(res.crm_payment));
    } else {
      Toast.error(getErrorString(res));
    }
    setIsSubmitting(false);
  };

  const disabled = !canEditPayment(paymentsData?.payment);
  const validationSchema = () => {
    return yup.object().shape({
      transaction_id: yup.string().nullable().required(strings.enter_transaction_id),
      transaction_date: yup.date(strings.invalid_date).nullable().required(strings.enter_transaction_date),
      value_date: yup
        .date()
        .nullable()
        .when("selectedDisposition", {
          is: value => {
            return value.status_slug === PAYMENT_STATUSES.VERIFIED;
          },
          then: yup.date().required(strings.enter_value_date),
        }),
    });
  };

  const renderAdyenRelatedDetails = () => {
    if (paymentsData?.payment?.crm_payment_method?.slug === ADYEN_PAYMENT_LINK.slug) {
      return (
        <>
          {!isFieldRestricted(paymentsData?.payment?.transaction_id) && (
            <FormInput
              label={strings.adyen_id}
              labelClassName="font-weight-bold"
              value={paymentsData?.payment?.payment_link?.psp_reference}
              textRight={true}
              disabled={true}
            />
          )}
          {!isFieldRestricted(paymentsData?.payment?.payment_link?.url) && (
            <>
              <label className="text-right font-weight-bold">{strings.payment_link}</label>
              <div className="text-secondary mb-2 font-weight-bold">
                {paymentsData?.payment?.payment_link?.url}
                <Copy text={paymentsData?.payment?.payment_link?.url} className="ml-2" />
              </div>
            </>
          )}
          {!isFieldRestricted(paymentsData?.payment?.payment_link?.status?.id) && (
            <>
              <label className="text-right font-weight-bold">{strings.adyen_link_status}</label>
              <div className="font-weight-bold" style={{ color: paymentsData?.payment?.payment_link?.status?.color }}>
                <i className="mr-2">
                  <Circle size={15} color={paymentsData?.payment?.payment_link?.status?.color} />
                </i>
                {paymentsData?.payment?.payment_link?.status?.name}
              </div>
            </>
          )}
        </>
      );
    } else return null;
  };

  const renderModalBody = () => {
    return (
      <Formik
        innerRef={formikRef}
        initialValues={formInitialValues()}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {formikProps => {
          const { values, setFieldValue, handleChange, handleBlur, errors, touched } = formikProps;
          return (
            <div className="theme-form mb-4">
              <div className="d-flex popupPaymentInfo">
                {renderTotalAmount()}
                {renderCollectorName()}
                {renderCollectedDate()}
                {renderPaymentMethod()}
                {renderPaymentType()}
                {renderInventoryName()}
                {renderCrmLead()}
                {renderPaymentStatus()}
              </div>
              <div className="popupPaymentSections">
                <div className="popupPaymentSection">
                  <FormInput
                    label={strings.transaction_id}
                    placeholder={strings.transaction_id}
                    value={values.transaction_id}
                    onBlur={handleBlur("transaction_id")}
                    errorMsg={errors.transaction_id && touched.transaction_id && errors.transaction_id}
                    onChange={handleChange("transaction_id")}
                    textRight={false}
                    disabled={disabled || paymentsData?.payment?.crm_payment_method?.slug === ADYEN_PAYMENT_LINK.slug}
                  />
                  {!isFieldRestricted(values.transaction_date) && !isNaN(values.transaction_date) && (
                    <DatePicker
                      label={strings.transaction_date}
                      placeholderText={strings.select_date}
                      onChange={date => {
                        setFieldValue("transaction_date", date);
                      }}
                      onBlur={handleBlur("transaction_date")}
                      errorMsg={touched.transaction_date && errors.transaction_date}
                      dateFormat="MMM dd, yyyy"
                      inputContainerClass="dateInput form-group icon-right"
                      textRight={true}
                      selected={values.transaction_date ? values.transaction_date : null}
                      shouldCloseOnSelect={true}
                      showTimeSelect={false}
                      horizontal={false}
                      disabled={disabled || paymentsData?.payment?.crm_payment_method?.slug === ADYEN_PAYMENT_LINK.slug}
                    />
                  )}

                  <div className="form-group lead_card_group">
                    <label>{strings.select_disposition}</label>
                    <DropdownSelect
                      label={strings.select_disposition}
                      selected={values.selectedDisposition?.name}
                      onChange={value => {
                        setFieldValue("selectedDisposition", value);
                      }}
                      setValue={true}
                      options={makeDispositionSelectData(groupByOnKeys(values?.possibleDispositions, "status_name"))}
                      menuStyles="action_dropDown"
                      toggleClassName="form-control text-truncate"
                      disabled={disabled}
                      labelAsValue={false}
                    />
                  </div>

                  {!isFieldRestricted(values.value_date) &&
                    (values?.selectedDisposition?.slug === PAYMENT_DISPOSITIONS.VERIFIED ||
                      values?.selectedDisposition?.status_slug === PAYMENT_STATUSES.VERIFIED) && (
                      <DatePicker
                        label={strings.value_date}
                        placeholderText={getValueDatePlaceholder({ valueDate: values?.value_date, disabled: disabled })}
                        onChange={date => {
                          setFieldValue("value_date", date);
                        }}
                        onBlur={handleBlur("value_date")}
                        errorMsg={touched.value_date && errors.value_date}
                        dateFormat="MMM dd, yyyy"
                        inputContainerClass="dateInput form-group icon-right"
                        textRight={true}
                        selected={isValidDate(values?.value_date) ? values?.value_date : null}
                        shouldCloseOnSelect={true}
                        showTimeSelect={false}
                        horizontal={false}
                        disabled={disabled}
                        minDate={getLastMinRangeOfDays(valueDateLimit)}
                        maxDate={futureDate(futureValueDateLimit)}
                      />
                    )}
                </div>
                <div className="popupPaymentSection">
                  {values?.paymentProofs?.length > 0 && (
                    <>
                      <h5 className="mb-3">{strings.attachments}</h5>
                      <div className="scroll-y" style={{ "--box-height": "230px" }}>
                        {!isFieldRestricted(values?.paymentProofs) &&
                          values?.paymentProofs?.map(doc => {
                            return <FileTilePreview item={doc} key={doc.id} disabled={true} />;
                          })}
                      </div>
                    </>
                  )}
                  {renderAdyenRelatedDetails()}
                </div>
              </div>
              {!!paymentsData?.payment?.notes && (
                <>
                  <h5 className="mb-3">{strings.notes}</h5>
                  <div style={{ maxHeight: "90px", overflowY: "auto" }}>
                    <p>{paymentsData?.payment?.notes}</p>
                  </div>
                </>
              )}
            </div>
          );
        }}
      </Formik>
    );
  };

  return (
    <Modal
      open={props.openModal}
      trapFocus={true}
      title={!canEditPayment(paymentsData?.payment) ? strings.view_payment_status : strings.change_payment_status}
      className="verifyPaymentPopup"
      body={renderModalBody}
      onClosed={() => {
        props.setOpenModal(null);
      }}
      toggle={() => {
        props.setOpenModal(!props.setOpenModal);
      }}
      actions={[
        {
          label: "Cancel",
          onClick: () => {
            props.setOpenModal(null);
          },
          color: "outline-dark",
          className: "modal__cancel-btn btn-pill",
        },
        {
          label: strings.save,
          color: "primary",
          className: "modal__cr-btn btn-pill",
          onClick: () => {
            formikRef.current && formikRef.current.handleSubmit();
          },
          disabled:
            !paymentsData?.possibleDispositions?.length || !canEditPayment(paymentsData?.payment) || isSubmitting,
          loading: isSubmitting,
        },
      ]}
    />
  );
};
export default ChangePaymentStatus;
