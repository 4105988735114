import toast from "react-hot-toast";

const getBackgroundColor = type => {
  switch (type) {
    case "error":
      return "#f54545";
    case "success":
      return "#62c25b";
    default:
      break;
  }
};

const renderToast =
  type =>
  (
    msg,
    options = {
      position: "top-right",
      duration: 3000,
      hideProgressBar: true,
      icon: null,
      style: {
        minWidth: "280px",
        minHeight: "70px",
        fontSize: "1.1em",
        background: getBackgroundColor(type),
        color: "#FFFFFF",
      },
    }
  ) =>
    msg && toast?.[type](msg, options);

const Toast = {
  error: renderToast("error"),
  success: renderToast("success"),
  info: renderToast("info"),
};

export default Toast;
