export const regex = {
  numbersOnly: /^\d+$/,
  nonZeroFirstNumber: /^$|[1-9][0-9]*$/,
  password: /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  name: /^[a-zA-Z][a-zA-Z ]*[a-zA-Z]$/,
  text: /^(?!\s+$).*/,
  fieldName: /^[a-zA-Z0-9_]*$/,
  decimalNumbers: /^(?!0\d)\d*(\.\d+)?$/,
  vinNumber: /^[A-Za-z0-9]{17}$/,
  emiratesIdNumber: /^\d{15}$/,
  noSpaces: /^(\S+$)/,
  noSpecialCharacters: /^[A-Za-z0-9 ]+$/,
  numbersOrZero: /^\d*$/,
  urlPattern: /(https?:\/\/[^\s,]+)/g,
  priceWithDecimal:/^[\d.]*$/
};
