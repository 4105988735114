import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { useEffect, useState } from "react";

import { Check } from "react-feather";
import cx from "clsx";

const FormCategorySelect = ({
  label,
  options,
  onChange,
  menuStyles,
  formSelectRef,
  horizontal,
  className,
  errorMsg,
  selected,
  disabled,
  placeholder,
  dropdownToggleClass,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState(placeholder);

  const toggle = () => !disabled && setDropdownOpen(prevState => !prevState);
  const horizontalClass = horizontal ? "col-sm-9" : "";
  const disabledClass = disabled ? "lead-type-disabled" : "";

  useEffect(() => {
    if (selected && selected?.label) {
      setDropdownLabel(selected.label);
    }
  }, [selected]);

  return (
    <div className={`form-group ${horizontal ? "row" : ""}${className ? ` ${className}` : ""}`} ref={formSelectRef}>
      {label && <label className={horizontal ? "col-sm-3 col-form-label text-right" : ""}>{label}</label>}
      <div className={`${horizontalClass} ${disabledClass}`}>
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
          <DropdownToggle
            aria-haspopup
            size="lg"
            tag="span"
            className={cx("form-control text-truncate", dropdownToggleClass)}
            caret
            style={{ background: "white" }}
          >
            {dropdownLabel}
          </DropdownToggle>

          <DropdownMenu className={menuStyles}>
            {options &&
              options.map((e, i) => (
                <DropdownItem
                  key={i}
                  onClick={() => {
                    setDropdownLabel(e.label);
                    onChange(e);
                  }}
                  header={e.header}
                >
                  {e.label}
                  {e.selected && <Check className="check__icon" />}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </Dropdown>
        {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
      </div>
    </div>
  );
};

export default FormCategorySelect;
