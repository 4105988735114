import React, { useEffect } from "react";
import { convertQueryObjToString, mapQueryStringToFilterObject, removeChildFilter } from "../../helpers/Util";
import { X } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./FilterChip.module.scss";

export const FilterChip = props => {
  const {
    title,
    chipFilters,
    searchKey,
    searchValue,
    history,
    groupedChips,
    isGroupedFilter,
    asyncFiltersSelected,
    filterChips,
    setFilterChips,
  } = props;

  const location = useLocation();
  const historyHook = useHistory();

  const groupChipsByFilterTitle = data => {
    const groupedChip = [];
    data.forEach(item => {
      groupedChip.push(item.title.value);
    });

    const searchKey = data?.[0]?.searchKey;

    if (searchKey.includes("[gte]") || searchKey.includes("[lte]")) {
      return groupedChip.join(" to ");
    }

    return groupedChip.join(", ");
  };

  const onGroupedChipCross = groupedFilters => {
    const obj = { ...chipFilters };
    const filterChild = groupedFilters?.[0]?.title?.filterChild;
    let searchKey = groupedFilters?.[0]?.searchKey;

    const keysToRemove = new Set(groupedFilters.map(gf => gf.searchKey));

    if (searchKey.includes("[gte]") || searchKey.includes("[lte]")) {
      const secondKey = groupedFilters?.[1]?.searchKey;
      if (secondKey) {
        keysToRemove.add(secondKey);
      }
    }

    if (filterChild) {
      filterChild.forEach(element => keysToRemove.add(element));
    }

    const filteredChips = filterChips.filter(filter => !keysToRemove.has(filter.searchKey));

    keysToRemove.forEach(key => {
      delete obj[key];
      delete asyncFiltersSelected[key];
    });
    setFilterChips(filteredChips);

    historyHook.push({
      pathname: location.pathname,
      search: convertQueryObjToString({ ...obj }),
    });
  };

  if (isGroupedFilter && groupedChips[0]?.title?.value) {
    return (
      <div key={props.index} className={styles.chip}>
        <div>
          <span>{groupedChips[0]?.title?.filterTitle}:</span>{" "}
          <span style={{ fontWeight: 600 }}>{groupChipsByFilterTitle(groupedChips)}</span>
        </div>
        <X size={14} role="button" onClick={() => onGroupedChipCross(groupedChips)} />
      </div>
    );
  } else {
    return null;
  }
};
