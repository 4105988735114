import { Avatar, Chip, Close, CustomAlert, FormInput, IF, Loader, Modal, Skeleton, Status } from "../common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { fetchAvailableCarsApi, fetchCRMLeadNewApi, fetchCRMLeadsApi, fetchCarsApi } from "../../helpers/ApiHelper";
import { getCarTitle, getErrorString, getQueryStringFromArray } from "../../helpers/Util";
import { BtnDefaultState } from "../common/BtnDefaultState";
import { CRMLeadForm } from "../../components/forms/crm-lead-form";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import addLeadIllustration from "../../assets/illustrations/add-lead-illustration.svg";
import { strings } from "../../constant/strings";
import { availableInventoryStatuses, availableLeadStatuses } from "../../constant/lists";
import RenderPrivateField from "../common/RenderPrivateField/RenderPrivateField";
import {
  MODELS_FOR_VIEW_PHONE_NUMBER_API,
  PHONE_NUMBER_CATEGORIZATION_PER_PAGE,
  SCROLLABLE_ELEMENTS_KEYS,
} from "constant/appConstants";
import { extractPayloadFromClientObj } from "../../helpers/ViewPhoneNumber";

const SelectListModal = props => {
  const {
    onSelect,
    taskId,
    onDeselect,
    title,
    limit = 25,
    currentValue,
    errorMsg,
    editable = true,
    type,
    textRight,
    inventory,
    canAddLead,
    loading: loadingSkeleton,
    detailsRoute,
    history,
    isEditable = null,
    horizontal = true,
    id = "",
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [requestId, setRequestId] = useState("");

  const [selected, setSelected] = useState(null);
  const [addLeadLoading, setAddLeadLoading] = useState(false);

  const [visible, setVisible] = useState(false);

  const [list, setList] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [leadForm, setLeadForm] = useState(false);
  const [leadData, setLeadData] = useState(null);
  const [leadsLoading, setLeadsLoading] = useState(false);
  const [leadsError, setLeadsError] = useState("");

  const crmLeadFormRef = useRef();

  const getOnScrollElement = useCallback(() => {
    return document.getElementById(SCROLLABLE_ELEMENTS_KEYS.MODAL_TABLE_KEY);
  }, [list]);

  const fetchLead = async () => {
    setLeadsLoading(true);
    const response = await fetchCRMLeadNewApi();
    if (response) {
      setLeadsLoading(false);
      if (response.success) {
        setLeadData({ ...response });
      } else {
        setLeadsError(getErrorString(response));
      }
    }
  };

  const fetchData = async (apiCall, params, controller) => {
    setLoading(true);
    if (props.filterRemovedAndSold) {
      params = `${params}&${getQueryStringFromArray("f[status.slug][]", availableInventoryStatuses)}`;
    }
    if (props.removeLostLead) {
      params = `${params}&${getQueryStringFromArray("f[status.slug][]", availableLeadStatuses)}`;
    }
    const response = await apiCall(params);
    if (response) {
      setLoading(false);
      if (response.success) {
        response[controller].length <= limit
          ? setList(response[controller])
          : setList(response[controller].slice(0, limit));
      } else {
        setError(getErrorString(response));
      }
    }
  };

  const fetchCall = useCallback(
    value => {
      if (!(type === "lead")) {
        fetchData(fetchCarsApi, !!value ? `?f[reference_number]=${value}&limit=${10}` : `?limit=${10}`, "cars");
      } else {
        fetchData(
          fetchCRMLeadsApi,
          !!value ? `?f[reference_number]=${value}&limit=${10}` : `?limit=${10}`,
          "crm_leads"
        );
      }
    },

    [fetchData, type]
  );

  useEffect(() => {
    if (visible) fetchCall("");
  }, [visible]);

  const onChange = event => {
    let value = event.target.value;
    setInputValue(value);
    requestId && clearTimeout(requestId);
    setRequestId(
      setTimeout(() => {
        fetchCall(value);
      }, 300)
    );
  };

  useEffect(() => {
    if (!currentValue) {
      setSelected(null);
    }
  }, [currentValue]);

  const onAddLead = () => {
    fetchLead();
    setLeadForm(true);
  };

  const renderHeader = () =>
    !(type === "lead") ? (
      <tr>
        <th></th>
        <th>{strings.ref}</th>
        <th>{strings.make_model}</th>
        <th>{strings.registration_number}</th>
        <th>{strings.city}</th>
        <th>{strings.status}</th>
      </tr>
    ) : (
      <tr>
        <th></th>
        <th>{strings.ref}</th>
        <th>{strings.name}</th>
        <th>{strings.phone}</th>
        <th>{strings.status}</th>
        <th>{strings.lead_category}</th>
      </tr>
    );

  const renderBody = item =>
    !(type === "lead") ? (
      <tr
        onClick={() => {
          setSelected(item);
          onSelect && onSelect(item);
          setVisible(false);
        }}
        key={item?.id}
      >
        <td></td>
        <td scope="row">{item?.reference_number}</td>
        <td>{item?.make?.select_value + " " + item?.model?.select_value}</td>
        <td>{item?.registration_no}</td>
        <td>{item?.inventory?.city?.name}</td>
        <td>
          <Status {...item?.inventory?.status} />
        </td>
      </tr>
    ) : (
      <tr
        onClick={() => {
          setSelected(item);
          onSelect && onSelect(item);
          setVisible(false);
        }}
        key={item?.id}
      >
        <td></td>
        <td scope="row">{item.reference_number}</td>
        <td>
          <Avatar name={item?.client?.name} />
        </td>
        <td>
          <div
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <RenderPrivateField
              {...extractPayloadFromClientObj({ item: item?.client })}
              associatedType={MODELS_FOR_VIEW_PHONE_NUMBER_API.LEAD_PHONE_KEY}
              component={PHONE_NUMBER_CATEGORIZATION_PER_PAGE.TASK.LEAD_MODAL}
              associatedId={item?.id}
              dropDownContainer="tbody"
              onScrollElement={getOnScrollElement()}
            />
          </div>
        </td>
        <td>
          <Status {...item.status} />
        </td>
        <td scope="row">{item.category?.name}</td>
      </tr>
    );

  const renderSkeleton = item =>
    !(type === "lead") ? (
      <tr key={item}>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Status loading={loading} />
        </td>
      </tr>
    ) : (
      <tr key={item}>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Avatar name={item?.client?.name} loading={loading} />
        </td>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Status loading={loading} />
        </td>
      </tr>
    );

  const getTitle = () => {
    const name = !(type === "lead")
      ? getCarTitle(!!selected ? selected : currentValue, true)
      : selected
      ? `${selected?.reference_number} - ${selected?.client?.name}`
      : `${currentValue?.reference_number} - ${currentValue?.client?.name}`;
    return name;
  };

  const addLead = () => (
    <div className="d-flex justify-content-center flex-column align-items-center p-24">
      <figure>{<img src={addLeadIllustration} />}</figure>
      <strong> {strings.no_result_found}</strong>
      <div>{strings.create_new_lead}</div>
      <button className="btn btn-outline-primary btn-pill btn-sm" style={{ marginTop: 10 }} onClick={onAddLead}>
        {strings.add_lead}
      </button>
    </div>
  );

  return (
    <div className={`form-group ${horizontal ? "row d-flex flex-row align-items-center" : ""}`} id={id}>
      <label className={`${horizontal ? "col-sm-3 col-form-label" : ""} ${textRight ? "text-right" : ""}`}>
        {loadingSkeleton ? <Skeleton height={20} /> : title}
      </label>
      <div className={`${horizontal ? "col-sm-9" : ""} d-flex flex-column align-items-start`}>
        {loadingSkeleton ? (
          <Skeleton height={30} />
        ) : (
          <>
            <IF condition={selected || currentValue}>
              <div
                style={isEditable === false ? { pointerEvents: "none" } : {}}
                className={`d-flex align-items-center ${!taskId ? "hover-pointer" : ""}`}
              >
                <Chip
                  containerClassName="pr-0"
                  onClick={() => editable && setVisible(true)}
                  title={getTitle()}
                  onClose={() => {
                    onDeselect();
                    setSelected(null);
                  }}
                  canClose={!taskId}
                  // key={selected?.id}
                />
                {taskId && currentValue && (
                  <Link
                    className="m-t-10 txt-info hover-pointer"
                    onClick={() => {
                      // window.open(`/crm/${detailsRoute}`, "_blank");
                      history.push({
                        pathname: `/crm/${detailsRoute}`,
                      });
                    }}
                    to={`/crm/${detailsRoute}`}
                  >
                    {strings.view_details}
                  </Link>
                )}
              </div>
            </IF>
            <IF condition={!selected && !currentValue}>
              <BtnDefaultState
                disabled={isEditable === false}
                onClick={() => setVisible(true)}
                className="custom-field-state b-r-6"
              />
            </IF>
          </>
        )}

        {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
      </div>
      <Modal
        toggle={() => {
          setVisible(!visible);
        }}
        open={visible}
        className="modal-lg deposit_model"
        body={() => {
          return (
            <>
              <div className="d-flex w-100 align-items-start justify-content-between">
                <h4>{leadForm ? strings.add_lead : title}</h4>
                {/*{(inventory || canAddLead) && !leadForm && !inputValue?.length && (*/}
                {/*  <button*/}
                {/*    className="btn btn-outline-primary btn-pill btn-sm"*/}
                {/*    style={{ marginRight: 44 }}*/}
                {/*    onClick={onAddLead}*/}
                {/*  >*/}
                {/*    {strings.add_lead}*/}
                {/*  </button>*/}
                {/*)}*/}
                <Close
                  style={{ right: "30px" }}
                  isTransparent
                  onClick={() => {
                    setVisible(false);
                  }}
                />
              </div>
              {leadForm ? (
                leadsLoading ? (
                  <div className="d-flex justify-content-center w-100">
                    <Loader />
                  </div>
                ) : leadsError ? (
                  <div className="d-flex justify-content-center w-100">
                    <CustomAlert message={leadsError} />
                  </div>
                ) : (
                  <CRMLeadForm
                    inventory={inventory}
                    leadData={leadData || {}}
                    onSuccess={e => {
                      setSelected(e);
                      onSelect && onSelect(e);
                      setVisible(false);
                    }}
                    ref={crmLeadFormRef}
                    toggleAddLeadLoading={setAddLeadLoading}
                  />
                )
              ) : (
                <>
                  <div className="filter__row--section mb-2 mt-3" style={{ width: 250 }}>
                    <FormInput
                      isEditable={isEditable}
                      placeholder={strings.ref}
                      onChange={onChange}
                      value={inputValue}
                      modalInput
                      type={"number"}
                    />
                  </div>

                  <div className="modal-table inventory-modal-table">
                    {error ? (
                      <CustomAlert message={error} className="mx-3" />
                    ) : (
                      <Table>
                        <thead>{renderHeader()}</thead>
                        <tbody>
                          {loading ? [1, 2, 3, 4, 5].map(renderSkeleton) : list?.map(item => renderBody(item))}
                        </tbody>
                      </Table>
                    )}
                    {(inventory || canAddLead) && !list?.length && addLead()}
                  </div>
                </>
              )}
            </>
          );
        }}
        actions={
          leadForm
            ? [
                {
                  label: strings.cancel,
                  onClick: () => {
                    setVisible(false);
                  },
                  color: "secondary",
                  className: "modal__cancel-btn btn-pill",
                },
                {
                  label: strings.create,
                  onClick: () => {
                    crmLeadFormRef && crmLeadFormRef.current && crmLeadFormRef.current.submitForm();
                  },
                  color: "primary",
                  className: "modal__cr-btn btn-pill",
                  loading: addLeadLoading,
                },
              ]
            : [
                {
                  label: strings.cancel,
                  onClick: () => {
                    setVisible(false);
                  },
                  color: "secondary",
                  className: "modal__cancel-btn btn-pill",
                },
              ]
        }
        onClosed={e => {
          setInputValue("");
          setLeadForm(false);
        }}
      />
    </div>
  );
};

export default SelectListModal;
