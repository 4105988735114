import React from "react";
import FileUploadHelper from "../../../../services/FileUploadHelper";
import Image from "./Image";

const ImagesUpload = ({ pictures, setPictures, title, url, isEditable }) => {
  const updateImage = (image, updated, pictures) => {
    if (!pictures) return;
    const newPictures = pictures.map(picture => (picture.id === image.id ? updated : picture));
    setPictures(newPictures);
  };

  const uploadImage = async item => {
    const { file } = item;

    const fileUploader = new FileUploadHelper();
    const presignedResponse = await fileUploader.getUUIDForLocalFile("documents", "dynamic_attachments", file.name);
    let isImageFile = file?.type?.includes("image");
    let fileData = file;
    if (isImageFile) {
      fileData = await fileUploader.resize(file);
    }
    if (presignedResponse?.success === true) {
      let response;
      try {
        response = await fileUploader.uploadFileOnS3(fileData, presignedResponse, Boolean(isImageFile));
      } catch (err) {
        console.log(err);
      }
      let newFile = Object.assign({}, { id: presignedResponse.id, file: fileData, uploaded: false, name: file.name });
      if (response) {
        newFile = Object.assign({}, { id: presignedResponse.id, file: fileData, uploaded: true, name: file.name });
        updateImage(item, newFile, pictures);
      }
    }
  };
  const removeImage = item => {
    let newPictures = pictures.filter(pic => pic.id !== item.id);
    setPictures(newPictures);
  };

  return (
    <div className="images-grid mb-0">
      {pictures &&
        pictures.map(picture => {
          if (!picture) return null;
          return (
            <Image
              title={title}
              key={picture.id || JSON.stringify(picture)}
              item={picture}
              removeImage={removeImage}
              uploadImage={uploadImage}
              url={url}
              isEditable={isEditable}
            />
          );
        })}
    </div>
  );
};

export default ImagesUpload;
