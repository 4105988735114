import React from "react";
import { AsyncSelect } from "../common";
import { fetchCrmItemListsApi } from "../../helpers/ApiHelper";
import {
  isObjRestricted,
  isFieldEditable,
  isFieldViewable,
  formatValuesForAsyncSelect,
  isEmptyArray,
} from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { MAKE_MODEL_VARIANT } from "constant/appConstants";

const MakeModelVariant = props => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    permittedAttributes,
    refObject,
    horizontal = true,
    hasStaticField = () => true,
    editable = true,
    ...rest
  } = props;

  const makeFieldsData = () => {
    return [
      {
        label: strings.make,
        placeholder: strings.make_ph,
        formKey: MAKE_MODEL_VARIANT.make,
        permission: "make.name",
        children: {
          formKeys: [MAKE_MODEL_VARIANT.model, MAKE_MODEL_VARIANT.variant],
        },
      },
      {
        label: strings.model,
        placeholder: strings.model_ph,
        formKey: MAKE_MODEL_VARIANT.model,
        parent: MAKE_MODEL_VARIANT.make,
        permission: "model.name",
        children: {
          formKeys: [MAKE_MODEL_VARIANT.variant],
        },
      },
      {
        label: strings.variant,
        placeholder: strings.variant_ph,
        formKey: MAKE_MODEL_VARIANT.variant,
        parent: MAKE_MODEL_VARIANT.model,
        permission: "variant.name",
        children: null,
      },
    ];
  };
  return (
    <>
      {makeFieldsData()?.map((item, index) => {
        const extraParams = {
          "q[select_key_eq]": item?.formKey,
        };
        if (item?.parent && values[item.parent]?.id) {
          extraParams["q[ancestry_eq]"] = values[item.parent].id;
        }
        const queryKey = "q[select_value_cont]";
        const disabled = item?.parent ? !values[item?.parent] : false;
        return (
          <React.Fragment key={index}>
            {!isObjRestricted(values?.make, ["select_value"]) &&
              isFieldViewable(permittedAttributes?.[item?.permission]) &&
              hasStaticField(item?.formKey) && (
                <AsyncSelect
                  cacheUniqs={[values?.[item?.parent]]}
                  isDisabled={disabled || !editable || !isFieldEditable(permittedAttributes?.[item?.permission])}
                  extraParams={extraParams}
                  cache={false}
                  clearButton
                  isClearable={true}
                  label={item?.label}
                  listProperty={"item_lists"}
                  placeholder={item?.placeholder}
                  selected={formatValuesForAsyncSelect(values, item?.formKey)}
                  listApi={fetchCrmItemListsApi}
                  isMulti={false}
                  queryKey={queryKey}
                  onChange={value => {
                    setFieldValue(item?.formKey, value);
                    if (!!item?.children?.formKeys && !isEmptyArray(item?.children?.formKeys)) {
                      item.children.formKeys.forEach(formKey => {
                        setFieldValue(formKey, null);
                      });
                    }
                  }}
                  errorMsg={touched?.[item?.formKey] && errors?.[item?.formKey]}
                  formatOptionLabel={props.formatOptionLabel}
                  formSelectRef={refObject[item?.formKey]}
                  horizontal={horizontal}
                  {...rest}
                  id={item?.formKey}
                />
              )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default MakeModelVariant;
