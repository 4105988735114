import { DropdownSelect, Toast } from "../common";
import React, { useEffect, useState } from "react";

import { checkUndefinedApiParams, getErrorString } from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { updateCRMLeadApi } from "../../helpers/ApiHelper";
import { useSelector, useDispatch } from "react-redux";
import { CRM_LEAD_UPDATE } from "../../constant/actionTypes";

const IconSuperHot = props => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28.213 28.213" {...props}>
      <g transform="translate(-17 -455)">
        <circle cx="14.106" cy="14.106" r="14.106" transform="translate(17 455)" fill="#ffe2e3" />
        <path
          fill="#e83a3a"
          d="M242.533,408.484a5.648,5.648,0,0,1-5.641-5.641,5.747,5.747,0,0,1,2.817-4.929l.878-.518-.131,1.011a1.785,1.785,0,0,0,.293,1.235c.079-1.593.546-4.489,2.822-5.8l.746-.43v.861c0,1.516.786,2.471,1.695,3.579a7.379,7.379,0,0,1,2.163,4.993A5.647,5.647,0,0,1,242.533,408.484Z"
          transform="translate(-210.892 68.59)"
        />
        <path
          fill="#f88"
          d="M241.9,394.275c-2.048,1.18-1.8,4.5-1.8,4.5a1.6,1.6,0,0,1-.9-1.651,3.661,3.661,0,0,0-1.8,3.153,3.6,3.6,0,1,0,7.208,0c0-2.931-2.7-3.528-2.7-6.006Z"
          transform="translate(-209.392 70.725)"
        />
      </g>
    </svg>
  );
};
const IconHot = props => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28.213 28.213" {...props}>
      <circle fill="#ffd6c1" opacity={0.597} cx="14.106" cy="14.106" r="14.106" />
      <path
        fill="#e8763a"
        d="M242.785,409.158a5.9,5.9,0,0,1-5.893-5.893,6,6,0,0,1,2.943-5.15l.918-.541-.137,1.056a1.865,1.865,0,0,0,.306,1.29c.082-1.664.57-4.69,2.948-6.061l.779-.449v.9c0,1.583.821,2.582,1.771,3.739a7.709,7.709,0,0,1,2.26,5.216A5.9,5.9,0,0,1,242.785,409.158Z"
        transform="translate(-228.189 -387.678)"
      />
      <path
        fill="#ed9f77"
        d="M240.468,394.275c-1.4.806-1.23,3.075-1.23,3.075a1.092,1.092,0,0,1-.615-1.128,2.5,2.5,0,0,0-1.23,2.153,2.461,2.461,0,0,0,4.921,0c0-2-1.845-2.409-1.845-4.1Z"
        transform="translate(-225.256 -382.465)"
      />
    </svg>
  );
};
const Moderate = props => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28.213 28.213" {...props}>
      <circle fill="#cea51c" opacity={0.258} cx="14.106" cy="14.106" r="14.106" />
      <path
        fill="#cea51c"
        d="M48.133,7.75a.4.4,0,0,0-.346-.2H42.578L43.451.875a.4.4,0,0,0-.733-.26l-6.335,11.1a.4.4,0,0,0,.339.606h5.131L41.161,19a.4.4,0,0,0,.737.245l6.23-11.1a.4.4,0,0,0,0-.4Z"
        transform="translate(-28.031 4.08)"
      />
    </svg>
  );
};
const Cold = props => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28.213 28.213" {...props}>
      <circle fill="#1aadef" opacity={0.12} cx="14.106" cy="14.106" r="14.106" />
      <path
        fill="#1aadef"
        d="M47.361,7.272a.372.372,0,0,0-.323-.188H42.169L42.985.847A.374.374,0,0,0,42.3.6L36.38,10.977a.372.372,0,0,0,.317.566h4.8l-.647,6.246a.374.374,0,0,0,.689.229L47.357,7.646a.372.372,0,0,0,0-.374Z"
        transform="translate(-27.76 5.915)"
      />
    </svg>
  );
};

const AssignClassificationInput = props => {
  const { classification, style, onChange, leadId, disabled } = props;
  const options = useSelector(content => content.CRMLeadDetail.classifications);
  const [open, setOpen] = useState(false);
  const [selectedClassification, setClassification] = useState(classification);
  const dispatch = useDispatch();
  useEffect(() => {
    if (classification) {
      setClassification(options.find(e => e.id === classification));
    }
  }, [classification, options]);

  const renderIcon = slug => {
    switch (slug) {
      case "cold":
        return <Cold className="icon-svg" />;
      case "moderate":
        return <Moderate className="icon-svg" />;
      case "hot":
        return <IconHot className="icon-svg" />;
      case "very_hot":
        return <IconSuperHot className="icon-svg" />;
      default:
        return;
    }
  };

  const postData = async e => {
    if (!checkUndefinedApiParams(leadId, "lead")) return;
    let obj = {
      crm_lead: {
        classification: e.id,
      },
    };

    const response = await updateCRMLeadApi(leadId, obj);
    if (response) {
      if (response.crm_lead) {
        dispatch({
          type: CRM_LEAD_UPDATE,
          payload: response.crm_lead,
        });
        setClassification(e);
        props.fetchCrmLeadActivities && props.fetchCrmLeadActivities(leadId);
      } else {
        Toast.error(getErrorString(response));
      }
    }
  };

  return (
    <DropdownSelect
      className="info-block d-flex dropdown-status"
      options={options}
      labelAsValue={false}
      selected={selectedClassification}
      disabled={disabled}
      toggleClassName="d-flex align-items-end spbwx8"
      toggleStyles={{
        "--bg": selectedClassification?.bg_color,
        "--color": selectedClassification?.color,
        lineHeight: "inherit",
      }}
      caret={false}
      color="skeleton"
      onChange={e => {
        if (onChange) {
          setClassification(e);
          onChange(e);
        } else {
          postData(e);
        }
      }}
      renderMenuItem={e => (
        <div className="d-flex flex-row spbwx8">
          {renderIcon(e.slug)}
          <div>
            <div className="info-block-desc">{e.name}</div>
          </div>
        </div>
      )}
      renderToggleItem={() =>
        selectedClassification ? (
          <div className="d-flex flex-row spbwx8 align-items-end bg-white">
            {renderIcon(selectedClassification.slug || classification.slug)}
            <div className="d-flex flex-column align-items-start">
              <div className="info-block-title">{strings.classification}</div>
              <div className={`info-block-desc ${disabled ? "" : "caret-dropdown-toggle"}`}>
                {selectedClassification.name || classification.name}
              </div>
            </div>
          </div>
        ) : (
          strings.unassigned
        )
      }
    />
  );
};

export default AssignClassificationInput;
