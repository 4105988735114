import * as yup from "yup";

import { CheckboxComponent, CustomAlert, FormInput, FormSelect, Toast } from "../common";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { addAuctionTypeApi, updateAuctionTypeApi } from "../../helpers/ApiHelper";
import { extensionCountList, offerExtensionPeriod, offerExtensionThreshold } from "../../constant/lists";

import { Formik } from "formik";
import { checkUndefinedApiParams, getErrorString, isFieldRestricted } from "../../helpers/Util";
import { nameValidation } from "../../helpers/validations";
import { strings } from "../../constant/strings";

const AddAuctionTypeForm = forwardRef((props, ref) => {
  const {
    addAuctionType,
    updateAuctionType,
    closeDialog,
    asUpdate,
    updateData,
    fetchAuctionTypes,
    toggleAddAuctionTypeLoading,
  } = props;

  let formikRef = useRef();

  useImperativeHandle(ref, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  useEffect(() => {
    if (asUpdate) {
      setFromInitialValues();
    }
  }, [asUpdate, updateData]);

  const validationSchema = () =>
    yup.object().shape({
      name: nameValidation(strings.auction_type_name, 30),
      is_extendable: yup.boolean(),
      extension_threshold: yup.number().required(strings.select_extension_threshold),
      extension_period: yup.number().required(strings.select_extension_period),
      extensions_count: yup.number().required(strings.select_extension_count),
    });

  const setFromInitialValues = () => {
    if (updateData && formikRef && formikRef.current) {
      const { name, is_extendable, extension_threshold, extension_period, extensions_count } = updateData;
      formikRef.current.setValues({
        name: formikRef.current.values.name || name,
        is_extendable: formikRef.current.values.is_extendable || is_extendable,
        extension_threshold: formikRef.current.values.extension_threshold || extension_threshold,
        extension_period: formikRef.current.values.extension_period || extension_period,
        extensions_count: formikRef.current.values.extensions_count || extensions_count,
      });
    }
  };
  const getInitialValues = () => ({
    name: "",
    is_extendable: "",
    extension_threshold: "",
    extension_period: "",
    extensions_count: "",
  });

  const onSubmit = async (values, { setStatus }) => {
    setStatus({ message: "" });
    const auctionTypeData = {
      auction_type: values,
    };
    toggleAddAuctionTypeLoading(true);
    if (asUpdate) {
      const response = await updateAuctionTypeApi(updateData.id, auctionTypeData);
      if (response) {
        toggleAddAuctionTypeLoading(false);
        if (response.auction_type) {
          updateAuctionType(response.auction_type);
          // fetchAuctionTypes();
          closeDialog();
        } else {
          setStatus({
            message: getErrorString(response),
          });
        }
      }
    } else {
      const response = await addAuctionTypeApi(auctionTypeData);
      if (response) {
        toggleAddAuctionTypeLoading(false);
        if (response.auction_type) {
          addAuctionType(response.auction_type);
          // fetchAuctionTypes();
          closeDialog();
        } else {
          setStatus({ message: getErrorString(response) });
        }
      }
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, status, setFieldValue }) => {
        return (
          <form className="form theme-form">
            <FormInput
              label={strings.name}
              placeholder={strings.name_ph}
              onChange={handleChange("name")}
              onBlur={handleBlur("name")}
              value={values.name}
              errorMsg={errors.name && touched.name && errors.name}
            />

            <FormSelect
              placeholder={strings.offer_extension_threshold_ph}
              label={strings.offer_extension_threshold}
              onBlur={handleBlur("extension_threshold")}
              value={values.extension_threshold}
              errorMsg={errors.extension_threshold && touched.extension_threshold && errors.extension_threshold}
              options={offerExtensionThreshold}
              onChange={e => {
                handleChange("extension_threshold")(e);
              }}
              setSlugAsValue
            />

            <FormSelect
              placeholder={strings.offer_extension_period_ph}
              label={strings.offer_extension_period}
              onBlur={handleBlur("extension_period")}
              value={values.extension_period}
              errorMsg={errors.extension_period && touched.extension_period && errors.extension_period}
              options={offerExtensionPeriod}
              onChange={e => {
                handleChange("extension_period")(e);
              }}
              setSlugAsValue
            />

            <FormSelect
              placeholder={strings.extension_count_ph}
              label={strings.extension_count}
              onBlur={handleBlur("extensions_count")}
              value={values.extensions_count}
              errorMsg={errors.extensions_count && touched.extensions_count && errors.extensions_count}
              options={extensionCountList}
              onChange={e => {
                handleChange("extensions_count")(e);
              }}
              setSlugAsValue
            />

            <CheckboxComponent
              label={strings.auction_extendable}
              checked={values.is_extendable}
              onChange={e => setFieldValue("is_extendable", e.target.checked)}
            />
            {status && status.message && <CustomAlert message={status.message} />}
          </form>
        );
      }}
    </Formik>
  );
});

export default AddAuctionTypeForm;
