import React, { Fragment, useState } from "react";
import { Skeleton } from "../../components/common";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import styles from "./UserAction.module.scss";

function UserAction(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  return props.loading
    ? Array(props.actionList?.length)
        .fill(0)
        .map((e, index) => (
          <Fragment key={index}>
            <Skeleton height={35} width={95} />
          </Fragment>
        ))
    : props.actionList.map((item, index) => {
        const { Icon, title, onClick, show, isDropdown, dropdownItems } = item;

        if (isDropdown) {
          return (
            <Fragment key={`${item?.title}`}>
              {show && (
                <Dropdown className={styles.dropdownItem} isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle className="btn" color="transparent">
                    {Icon && <Icon size={16} />}
                  </DropdownToggle>
                  <DropdownMenu>
                    {dropdownItems.map(
                      (dropdownItem, i) =>
                        dropdownItem.show && (
                          <DropdownItem key={i} onClick={dropdownItem.onClick}>
                            {dropdownItem.Icon && <dropdownItem.Icon size={16} />}
                            {dropdownItem.title}
                          </DropdownItem>
                        )
                    )}
                  </DropdownMenu>
                </Dropdown>
              )}
            </Fragment>
          );
        } else {
          return (
            <Fragment key={`${item?.title}`}>
              {show && (
                <button
                  style={item?.customClass && item?.customClass}
                  className={"btn btn-outline-sky d-flex align-items-center spbwx4"}
                  onClick={onClick}
                >
                  {Icon && <Icon size={16} />}
                  <span>{title}</span>
                </button>
              )}
            </Fragment>
          );
        }
      });
}

export default UserAction;
