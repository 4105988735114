import React, { forwardRef } from "react";

import DateSelect from "react-datepicker";
import { IoIosCalendar } from "../svg-icon/svg-icons";
import { Skeleton } from "../common";
import moment from "moment";
import Calendar from "react-calendar";

const DatePicker = ({
  containerClass = "",
  placeholder = "",
  selected,
  onChange,
  errorMsg,
  loading,
  label,
  canClear = false,
  labelClass,
  timezone = false,
  showIcon = true,
  inputClassName = "border-0",
  horizontal = true,
  isEditable = null,
  isViewable = true,
  ...rest
}) => {
  if (!isViewable) return null;
  const CustomInput = forwardRef((props, ref) => {
    return (
      <input
        {...props}
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        ref={ref}
        className={inputClassName}
      />
    );
  });

  const setLocalZone = (date, timezone) => {
    const dateWithoutZone = moment.tz(date, timezone).format("YYYY-MM-DDTHH:mm:ss.SSS");
    const localZone = moment(dateWithoutZone).format("Z");
    const dateWithLocalZone = [dateWithoutZone, localZone].join("");

    return new Date(dateWithLocalZone);
  };

  const setOtherZone = (date, timezone) => {
    const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
    const otherZone = moment.tz(date, timezone).format("Z");
    const dateWithOtherZone = [dateWithoutZone, otherZone].join("");

    return new Date(dateWithOtherZone);
  };

  const renderComponent = () =>
    loading ? (
      <Skeleton height={30} />
    ) : (
      <>
        <div
          className={`${"media flex-column d-flex"} ${containerClass ? containerClass : ""} ${horizontal && "mt-2"}`}
        >
          <div
            className={`${"d-flex align-items-center justify-content-center"} ${
              canClear ? "custom-field-state b-r-6" : ""
            }`}
          >
            {showIcon && (
              <div className="media-icon simple bordered">
                <IoIosCalendar />
              </div>
            )}
            <DateSelect
              portalId={"root"}
              selected={selected ? (timezone ? setLocalZone(selected, timezone) : selected) : null}
              showTimeSelect
              placeholderText={placeholder}
              dateFormat="HH:mm, d MMMM, yy"
              onChange={date => {
                onChange(date ? (timezone ? setOtherZone(date, timezone) : date) : null);
              }}
              customInput={<CustomInput />}
              {...rest}
            />
          </div>
          {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
        </div>
      </>
    );

  return label ? (
    <div className={`${"form-group"} ${horizontal ? "row " : ""}`}>
      <label className={`${horizontal ? "col-sm-3 col-form-label" : ""} ${labelClass}`}>
        {loading ? <Skeleton height={20} /> : label}
      </label>
      <div
        className={`${horizontal ? "col-sm-9" : ""}`}
        style={isEditable === false ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
      >
        {renderComponent()}
      </div>
    </div>
  ) : (
    renderComponent()
  );
};

export default DatePicker;
