import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { Modal, Skeleton, Status, Toast } from "../common";

import { checkUndefinedApiParams, getErrorString, isFieldRestricted } from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { updateCRMTaskAttributesApi } from "../../helpers/ApiHelper";

const TaskStatusInput = ({
  status,
  selectedTask,
  style,
  onChange,
  taskId,
  canUpdate,
  onSuccess,
  loading,
  label,
  className = "",
  updateTask,
  isRenderedByLeadDetail,
  fetchCrmLeadActivities,
  crmLeadId,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedStatus, setStatus] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    if (selectedTask) {
      setStatus(status?.find(e => Number(e.id) === Number(selectedTask.id)) || selectedTask);
    } else {
      setStatus(null);
    }
  }, [selectedTask, status]);

  const getBadgeStatus = name => {
    switch (name) {
      case "To Do":
        return "status-todo";
      case "Done":
      case "Accepted":
        return "status-success";
      default:
        return "status-default";
    }
  };

  const postData = async e => {
    if (!checkUndefinedApiParams(taskId, "task")) return;
    let obj = {
      task: {
        task_option_id: e.id,
      },
    };

    const response = await updateCRMTaskAttributesApi(taskId, obj, "change_status");
    if (response) {
      if (response.task) {
        onSuccess && onSuccess(e, response);
        updateTask && updateTask(response);
        fetchCrmLeadActivities && fetchCrmLeadActivities(crmLeadId);

        setStatus(e);
      } else {
        Toast.error(getErrorString(response));
      }
    }
  };

  const renderComponent = () =>
    loading ? (
      <Skeleton height={33} width={80} style={{ borderRadius: 25 }} />
    ) : (
      <>
        <Modal
          toggle={() => setShowModal(!showModal)}
          title={strings.change_task_status}
          open={showModal}
          body={() => (
            <>
              {isRenderedByLeadDetail
                ? "Are you sure you want to change task status?"
                : `Are you sure you want change meeting status?`}
            </>
          )}
          actions={[
            {
              label: strings.no,
              onClick: () => {
                setShowModal(false);
              },
              color: "secondary",
              className: "modal__cancel-btn btn-pill",
            },
            {
              label: strings.yes,
              color: "primary",
              className: "modal__cr-btn btn-pill",
              onClick: () => {
                if (onChange) {
                  setStatus(modalStatus);
                  onChange(modalStatus);
                } else {
                  postData(modalStatus);
                }
                setShowModal(false);
              },
            },
          ]}
        />
        <Dropdown
          className="dropdown-status"
          isOpen={open}
          toggle={() => canUpdate && setOpen(prevState => !prevState)}
          direction="down"
        >
          <DropdownToggle
            className={`btn btn-pill badge-status ${getBadgeStatus(
              selectedStatus ? selectedStatus.name : strings.to_do
            )} ${className}`.trim()}
            size="sm"
            tag="div"
            caret={!!status?.length && canUpdate}
            style={{
              "--badge-bg": selectedStatus?.bg_color,
              "--badge-color": selectedStatus?.color,
              cursor: canUpdate ? "pointer" : "default",
            }}
          >
            {selectedStatus ? selectedStatus.name : strings.to_do}
          </DropdownToggle>
          {status && !isFieldRestricted(status) && !!status?.length && (
            <DropdownMenu container="body" className="mt-2">
              {status
                ? status
                    .filter(e => Number(e.id) !== Number(selectedStatus?.id))
                    .map((e, i) => (
                      <Fragment key={e.id}>
                        {i === 0 && <span className="pl-4 pt-2 pb-2">{strings.select_status}</span>}
                        <DropdownItem
                          key={e.id}
                          onClick={() => {
                            setModalStatus(e);
                            setShowModal(true);
                          }}
                        >
                          <Status {...e} />
                          {selectedStatus?.id === e.id && (
                            <span className="badge-status-check pull-right mt-2">
                              <i className="fa fa-check"></i>
                            </span>
                          )}
                        </DropdownItem>
                      </Fragment>
                    ))
                : null}
            </DropdownMenu>
          )}
        </Dropdown>
      </>
    );

  return label ? (
    <div className="form-group row">
      <label className="col-sm-3 col-form-label">{loading ? <Skeleton height={20} /> : label}</label>
      <div className="col-sm-9">{renderComponent()}</div>
    </div>
  ) : (
    renderComponent()
  );
};

export default TaskStatusInput;
