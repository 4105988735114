import { Formik } from "formik";
import React from "react";
import { strings } from "../../constant/strings";
import * as yup from "yup";
import { Chip, DatePicker, FormInput, SelectionListTypeHead, Toast } from "../common";
import {
  addPermittedProperty,
  appendTimeZone,
  getErrorString,
  getPermission,
  isEmptyObject,
  isFieldEditable,
  isFieldViewable,
  makeListToSelectData,
  setTimeZone,
} from "../../helpers/Util";
import { forwardRef } from "react";
import { addTaskReminderApi, updateTaskReminderApi } from "../../helpers/ApiHelper";
import { selectActiveColumns, selectTimeZone } from "../../reducers/general/selectors";
import { useDispatch, useSelector } from "react-redux";
import { updateCrmTaskReminders, updateTaskReminders, updateTasksReminders } from "../../actions/crm";
import { Link } from "react-router-dom";
const AddTaskReminderForm = (
  {
    reminderId,
    reminder,
    task,
    setReminderModal,
    loading,
    setLoading,
    isEditTaskForm = true,
    setTaskFieldValue,
    toggleTaskModal = null,
  },
  ref
) => {
  const dispatch = useDispatch();
  const timeZone = useSelector(selectTimeZone);
  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const taskReminderPermittedAttributes = activeColumnPermittedAttributes.Task;
  const getChipTitle = () => {
    return `${task?.reference_number} - ${task?.name}`;
  };

  const getFormInitialValues = () => {
    return {
      ...addPermittedProperty(taskReminderPermittedAttributes?.["reminders.title"], {
        title: reminder ? reminder?.title : "",
      }),
      ...addPermittedProperty(taskReminderPermittedAttributes?.["reminders.notes"], {
        notes: reminder ? reminder?.notes : "",
      }),
      ...addPermittedProperty(taskReminderPermittedAttributes?.["reminders.date"], {
        reminder_date: reminder ? setTimeZone(timeZone, reminder?.date) : "",
      }),
      ...addPermittedProperty(taskReminderPermittedAttributes?.["reminders.priority"], {
        priority: reminder ? { name: reminder?.priority, id: reminder?.priority, slug: reminder?.priority } : null,
      }),
    };
  };

  const validationSchema = () => {
    return yup.object().shape({
      ...addPermittedProperty(taskReminderPermittedAttributes?.["reminders.title"], {
        title: yup.string().trim().required("Reminder title is Required"),
      }),
      ...addPermittedProperty(taskReminderPermittedAttributes?.["reminders.notes"], {
        notes: yup.string(),
      }),
      ...addPermittedProperty(taskReminderPermittedAttributes?.["reminders.date"], {
        reminder_date: yup.string().required("Reminder date & time is Required"),
      }),
      ...addPermittedProperty(taskReminderPermittedAttributes?.["reminders.priority"], {
        priority: yup.object().nullable().required("Reminder priority is Required"),
      }),
    });
  };
  const onSubmit = async values => {
    setLoading(true);
    const payload = {
      ...addPermittedProperty(
        taskReminderPermittedAttributes?.["reminders.title"],
        {
          title: values.title,
        },
        true
      ),
      ...addPermittedProperty(
        taskReminderPermittedAttributes?.["reminders.notes"],
        {
          notes: values.notes,
        },
        true
      ),
      ...addPermittedProperty(
        taskReminderPermittedAttributes?.["reminders.date"],
        {
          date: appendTimeZone(values.reminder_date, timeZone),
        },
        true
      ),
      ...addPermittedProperty(
        taskReminderPermittedAttributes?.["reminders.priority"],
        {
          priority: values?.priority?.name,
        },
        true
      ),
    };
    if (isEditTaskForm || reminderId) {
      if (!isEmptyObject(payload)) {
        let res = reminder
          ? await updateTaskReminderApi(task.id, { reminder_id: reminder.id, reminder: { ...payload } })
          : await addTaskReminderApi(task.id, { reminder: { ...payload } });
        if (res.success) {
          dispatch(updateTaskReminders({ reminders: res?.reminder }));
          dispatch(updateCrmTaskReminders(res?.reminder));
          Toast.success(res.message);
          setLoading(false);
          setReminderModal(false);
        } else {
          setLoading(false);
          Toast.error(getErrorString(res));
        }
      } else {
        setLoading(false);
      }
    } else {
      setTaskFieldValue("reminder", { ...payload, status: { slug: "pending" } });
      setLoading(false);
      setReminderModal(false);
    }
  };

  const REMINDER_PRIORITY_OPTIONS = makeListToSelectData(
    ["None", "Low", "Medium", "High"].map(item => ({ name: item, id: item, slug: item }))
  );

  return (
    <Formik
      innerRef={ref}
      initialValues={getFormInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {formikProps => {
        const { values, setFieldValue, handleChange, handleBlur, errors, touched } = formikProps;
        return (
          <form className="form theme-form db__form-main">
            {isEditTaskForm && (
              <div className="row form-group form-group-y align-items-center">
                <label className="col-sm-3 text-right col-form-label">{`Task ${` `}`} </label>
                <div className="col-sm-9">
                  <Chip title={getChipTitle()} canClose={false} chipOnly />
                  {toggleTaskModal && (
                    <Link
                      className="m-t-10 ml-1 txt-info hover-pointer"
                      onClick={() => {
                        if (task && getPermission("Task", "index")) {
                          toggleTaskModal(true, task.id);
                        }
                      }}
                    >
                      {strings.view_details}
                    </Link>
                  )}
                </div>
              </div>
            )}
            {isFieldViewable(taskReminderPermittedAttributes?.["reminders.title"]) && (
              <FormInput
                label={strings.title}
                placeholder={strings.reminder_title}
                onChange={handleChange("title")}
                onBlur={handleBlur("title")}
                value={values.title}
                errorMsg={errors.title && touched.title && errors.title}
                horizontal
                textRight
                isEditable={isFieldEditable(taskReminderPermittedAttributes?.["reminders.title"])}
                disabled={
                  !isFieldEditable(taskReminderPermittedAttributes?.["reminders.title"]) ||
                  (reminder && reminder?.status?.slug !== "pending")
                }
              />
            )}
            {isFieldViewable(taskReminderPermittedAttributes?.["reminders.notes"]) && (
              <FormInput
                label={strings.notes}
                placeholder={strings.reminder_notes}
                onChange={handleChange("notes")}
                onBlur={handleBlur("notes")}
                value={values.notes}
                errorMsg={errors.notes && touched.notes && errors.notes}
                linesCount="4"
                horizontal
                textRight
                isEditable={isFieldEditable(taskReminderPermittedAttributes?.["reminders.notes"])}
                disabled={
                  !isFieldEditable(taskReminderPermittedAttributes?.["reminders.notes"]) ||
                  (reminder && reminder?.status?.slug !== "pending")
                }
              />
            )}
            {isFieldViewable(taskReminderPermittedAttributes?.["reminders.date"]) && (
              <DatePicker
                label={"Due Date"}
                timeIntervals={1}
                timeFormat="hh:mm a"
                placeholderText={strings.due_date}
                fieldName={strings.due_date}
                onChange={date => {
                  setFieldValue("reminder_date", date);
                }}
                onBlur={handleBlur("reminder_date")}
                errorMsg={touched.reminder_date && errors.reminder_date}
                dateFormat="hh:mm a, MMM do, yyyy"
                textRight={true}
                selected={values.reminder_date ? values.reminder_date : null}
                shouldCloseOnSelect={true}
                showTimeSelect
                horizontal
                labelClass={"text-right"}
                isEditable={isFieldEditable(taskReminderPermittedAttributes?.["reminders.date"])}
                disabled={
                  !isFieldEditable(taskReminderPermittedAttributes?.["reminders.date"]) ||
                  (reminder && reminder?.status?.slug !== "pending")
                }
              />
            )}
            {isFieldViewable(taskReminderPermittedAttributes?.["reminders.priority"]) && (
              <SelectionListTypeHead
                placeholder={"Select..."}
                label={strings.priority}
                onBlur={handleBlur("priority")}
                errorMsg={touched.priority && errors.priority}
                options={REMINDER_PRIORITY_OPTIONS || []}
                horizontal
                selected={values.priority ? [values.priority] : []}
                onChange={selected => {
                  setFieldValue("priority", selected[0]);
                }}
                labelKey="name"
                caret
                textRight
                disabled={
                  !isFieldEditable(taskReminderPermittedAttributes?.["reminders.priority"]) ||
                  (reminder && reminder?.status?.slug !== "pending")
                }
                isEditable={isFieldEditable(taskReminderPermittedAttributes?.["reminders.priority"])}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default forwardRef(AddTaskReminderForm);
