import * as yup from "yup";

import { ButtonLoading, CustomAlert, FormInput, Toast } from "../common";
import React, { useEffect, useRef, useState } from "react";
import { addLeadSourceApi, updateLeadSourceApi } from "../../helpers/ApiHelper";
import { getErrorString, getPath, isFieldRestricted } from "../../helpers/Util";

import { Formik } from "formik";
import { strings } from "../../constant/strings";

const AddLeadSourceForm = props => {
  const { leadSourceId, leadSourceData, history } = props;
  const [saveLoading, setSaveLoading] = useState(false);

  const formikRef = useRef();

  useEffect(() => {
    formikRef.current &&
      formikRef.current.setValues({
        ...formikRef.current.values,
        name: leadSourceData?.name,
        source: leadSourceData?.source,
        medium: leadSourceData?.medium,
        campaign: leadSourceData?.campaign,
      });
  }, [leadSourceData]);

  // Initial Values
  const getInitialValues = () => ({
    name: "",
    source: "",
    medium: "",
    campaign: "",
  });

  const validationSchema = () =>
    yup.object().shape({
      source: yup.string().required(strings.lead_source_message),
      medium: yup.string().required(strings.lead_medium_message),
      campaign: yup.string().required(strings.lead_campaign_message),
    });

  const onSubmit = async (values, { setStatus }) => {
    const leadSourceObj = {
      lead_source: {
        name: values.name,
        source: values.source,
        medium: values.medium,
        campaign: values.campaign,
      },
    };

    setSaveLoading(true);
    const response = leadSourceId
      ? await updateLeadSourceApi(leadSourceId, leadSourceObj)
      : await addLeadSourceApi(leadSourceObj);
    if (response) {
      setSaveLoading(false);
      if (response.success && response.lead_source) {
        history.push({
          pathname: getPath("lead_source", undefined, true),
        });
      } else {
        setStatus({ message: getErrorString(response) });
        Toast.error(getErrorString(response));
      }
    }
  };

  return (
    <div className="card">
      <div className="card-body w-70 mt-4">
        <Formik
          innerRef={formikRef}
          initialValues={getInitialValues()}
          validationSchema={validationSchema()}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, status, setFieldValue }) => {
            return (
              <form className="form theme-form" onSubmit={handleSubmit}>
                <FormInput
                  label={strings.name}
                  placeholder={strings.lead_source_name}
                  onChange={handleChange("name")}
                  onBlur={handleBlur("name")}
                  value={values.name || ""}
                  horizontal
                  errorMsg={errors.name && touched.name && errors.name}
                  required
                  loading={props.loading}
                />
                <FormInput
                  label={strings.source}
                  placeholder={strings.source_ph}
                  onChange={handleChange("source")}
                  onBlur={handleBlur("source")}
                  value={values.source || ""}
                  horizontal
                  errorMsg={errors.source && touched.source && errors.source}
                  required
                  loading={props.loading}
                />

                <FormInput
                  label={strings.medium}
                  placeholder={strings.medium_ph}
                  onChange={handleChange("medium")}
                  onBlur={handleBlur("medium")}
                  value={values.medium || ""}
                  horizontal
                  errorMsg={errors.medium && touched.medium && errors.medium}
                  required
                  loading={props.loading}
                />
                <FormInput
                  label={strings.campaign}
                  placeholder={strings.campaign_ph}
                  onChange={handleChange("campaign")}
                  onBlur={handleBlur("campaign")}
                  value={values.campaign || ""}
                  horizontal
                  errorMsg={errors.campaign && touched.campaign && errors.campaign}
                  required
                  loading={props.loading}
                />

                {status && status.message && <CustomAlert message={status.message} />}
                <ButtonLoading
                  className="pull-right"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={saveLoading}
                  loading={saveLoading}
                >
                  {strings.save}
                </ButtonLoading>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddLeadSourceForm;
