import { FETCH_CALL_RECORDINGS, SET_CALL_RECORDINGS_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  statuses: [],
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CALL_RECORDINGS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CALL_RECORDINGS_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };
    default:
      return state;
  }
};
