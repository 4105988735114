import { Toast } from "../../components/common";
import {
  performDeleteRequestWithHeaders,
  performGetRequest,
  performPatchRequest,
  performPostRequest,
  performPutRequest,
} from "../../services/NetworkServices";
export const endpoint = process.env.REACT_APP_API_URL;
export const endpointApi = `${endpoint}/api`;
export const ROLES_CONTROLLER = "/roles";
export const TOKENS_CONTROLLER = "/authentication_tokens";
export const LEAD_TYPES_CONTROLLER = "/lead_types";

export const getError = e =>
  e.errors
    ? e
    : {
        error: e ? (e.message ? e.message : e.error) : "Unknown Error",
      };

export const getApiMethod = async (path, object) => {
  try {
    const res = await performGetRequest(path, object);
    return res.data;
  } catch (e) {
    const formattedError = getError(e);

    Toast.error(formattedError?.error ?? "API Call Error");
    throw formattedError;
  }
};

export const putApiMethod = async (path, object, errorKey = null) => {
  try {
    const res = await performPutRequest(path, object);
    return res.data;
  } catch (e) {
    if (errorKey && e[errorKey]) throw { multi_errors: e[errorKey] };
    const formattedError = getError(e);
    throw formattedError;
  }
};

export const getCallback = async (path, object, cancelToken = null, extraHeaders = null) => {
  try {
    const res = await performGetRequest(path, object, cancelToken, extraHeaders);
    return res.data;
  } catch (e) {
    console.log("e", e);
    return getError(e);
  }
};

export const patchCallback = async (path, object) => {
  try {
    const res = await performPatchRequest(path, object);
    return res.data;
  } catch (e) {
    return getError(e);
  }
};

export const postApiMethod = async (path, object, responseOnly) => {
  try {
    const res = await performPostRequest(path, object);
    return responseOnly ? res : res.data;
  } catch (e) {
    const formattedError = getError(e);
    throw formattedError;
  }
};

export const postCallback = async (path, object, responseOnly) => {
  try {
    const res = await performPostRequest(path, object);
    return responseOnly ? res : res.data;
  } catch (e) {
    const formattedError = getError(e);
    throw formattedError;
  }
};

export const putCallback = async (path, object, errorKey = null) => {
  try {
    const res = await performPutRequest(path, object);
    return res.data;
  } catch (e) {
    if (errorKey && e[errorKey]) return { multi_errors: e[errorKey] };
    return getError(e);
  }
};

export const deleteCallback = async (path, object, responseOnly) => {
  try {
    const res = await performDeleteRequestWithHeaders(path, object);
    return responseOnly ? res : res.data;
  } catch (e) {
    return getError(e);
  }
};
