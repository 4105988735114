import React from "react";
import { Typography } from "antd";
import cx from "clsx";
import styles from "./index.module.less";

const Text = props => {
  const { children, block = false, className, ...rest } = props;
  const { Text } = Typography;
  const renderComponent = () => {
    return (
      <Text
        className={cx(styles.typography, rest.type && styles[`ant-typography-${rest.type}`], className)}
        style={{ "--typography-color": rest.color, ...rest.style }}
        {...rest}
      >
        {children}
      </Text>
    );
  };

  return block ? <div>{renderComponent()}</div> : renderComponent();
};

export default Text;
