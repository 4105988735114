import React, { FC, Fragment } from "react";
import cx from "clsx";
import Countdown from "react-countdown";
import styles from "./Counter.module.scss";
import { TimeElementProps, CounterProps, RenderOptions } from "./Counter.type";

const TimeElement: FC<TimeElementProps> = ({ value }) => (
  <li>
    <span>{value < 10 ? "0" + value : value}</span>
  </li>
);

const timer = (days: number, hours: number, minutes: number, seconds: number, renderOptions: RenderOptions) => {
  const {
    showDays,
    showHours,
    showMinutes = true,
    showSeconds = true,
    checkDaysCount = true,
    checkHoursCount = false,
    isPrimaryColor = false,
    customCounterStyles = "",
  } = renderOptions;
  return (
    <ul
      className={cx({
        [styles.timerList]: true,
        [styles.timerListPrimary]: isPrimaryColor,
        [customCounterStyles]: !!customCounterStyles,
      })}
    >
      {showDays && (!checkDaysCount || days > 0) && <TimeElement label={"Days"} value={days} />}
      {showHours && (!checkHoursCount || hours > 0) && <TimeElement label={"Hrs"} value={hours} />}
      {showMinutes && <TimeElement label={"Mins"} value={minutes} />}
      {showSeconds && <TimeElement label={"Sec"} value={seconds} />}
    </ul>
  );
};

const Counter: FC<CounterProps> = ({ startTime = "", endTime = "", fixedTimer = false, renderOptions }) => {
  const getNowTime = () => {
    if (fixedTimer) return new Date(endTime).valueOf();
    return Date.now();
  };
  return (
    <Fragment>
      <Countdown
        date={new Date(startTime)}
        now={getNowTime}
        autoStart={fixedTimer ? false : true}
        overtime
        renderer={({ total, days, hours, minutes, seconds }) => {
          if (total) {
            return timer(days, hours, minutes, seconds, renderOptions);
          } else {
            return timer(0, 0, 0, 0, renderOptions);
          }
        }}
      />
    </Fragment>
  );
};

export default Counter;
