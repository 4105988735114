import * as yup from "yup";

import { CustomAlert, FormInput } from "../common";
import React, { forwardRef, useImperativeHandle, useRef } from "react";

import AmountCalculation from "../inspection-payment/AmountCalculation";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import {
  addPermittedProperty,
  checkUndefinedApiParams,
  getErrorString,
  isFieldEditable,
  isFieldViewable,
} from "../../helpers/Util";
import { leadDiscountApi } from "../../helpers/ApiHelper";
import { strings } from "../../constant/strings";
import { updateLeadDetails } from "../../actions/inspection/leadDetail.action";
import { selectActiveColumns } from "../../reducers/general/selectors";

const AddDiscountForm = props => {
  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const addDiscountPermittedAttributes = activeColumnPermittedAttributes.Payment;

  const { fRef, updateLeadDetails, toggleAddDiscountLoading, setShowDiscountModal, lead } = props;

  let formikRef = useRef();

  useImperativeHandle(fRef, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  const getInitialValues = () => {
    return {
      ...addPermittedProperty(addDiscountPermittedAttributes?.["discount"], {
        discount: lead?.discount || null,
      }),
      ...addPermittedProperty(addDiscountPermittedAttributes?.["discount_notes"], {
        discountNotes: lead?.discount_notes || "",
      }),
    };
  };

  const onSubmit = async ({ discount, discountNotes }, { setStatus, setFieldError }) => {
    if (!checkUndefinedApiParams(lead.id, "lead")) return;
    setStatus({ message: "" });
    let obj = {
      ...addPermittedProperty(
        addDiscountPermittedAttributes?.["discount"],
        {
          discount,
        },
        true
      ),

      ...addPermittedProperty(
        addDiscountPermittedAttributes?.["discount_notes"],
        {
          discount_notes: discountNotes,
        },
        true
      ),
    };

    toggleAddDiscountLoading(true);
    const response = await leadDiscountApi(lead?.id, obj);
    if (response) {
      toggleAddDiscountLoading(false);

      if (response.lead) {
        updateLeadDetails(response.lead);
        setShowDiscountModal(false);
      } else {
        setStatus({
          message: getErrorString(response),
        });
      }
    }
  };

  const validationSchema = () =>
    yup.object().shape({
      ...addPermittedProperty(addDiscountPermittedAttributes?.["discount"], {
        discount: yup
          .number()
          .typeError(strings.enter_number_message)
          .min(0, strings.min_value_message)
          .max(lead?.lead_type?.price, strings.less_discount_message),
      }),
    });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, status, setFieldValue, setFieldError }) => (
        <form className="form theme-form db__form-main">
          {isFieldViewable(addDiscountPermittedAttributes?.["discount"]) && (
            <FormInput
              label={strings.discount_amount}
              placeholder={strings.enter_discount_amount}
              onChange={e => {
                setFieldValue("discount", e.target.value);
              }}
              onBlur={handleBlur("discount")}
              value={values.discount || ""}
              errorMsg={errors.discount}
              labelClassName="p-t-0"
              type={"number"}
              isEditable={isFieldEditable(addDiscountPermittedAttributes?.["discount"])}
            />
          )}

          {isFieldViewable(addDiscountPermittedAttributes?.["discount_notes"]) && (
            <FormInput
              label={strings.discount_notes}
              placeholder={strings.enter_discount_notes}
              onChange={handleChange("discountNotes")}
              onBlur={handleBlur("discountNotes")}
              value={values.discountNotes || ""}
              errorMsg={errors.discountNotes && touched.discountNotes && errors.discountNotes}
              linesCount="4"
              required
              labelClassName="p-t-0"
              isEditable={isFieldEditable(addDiscountPermittedAttributes?.["discount_notes"])}
            />
          )}

          <AmountCalculation
            leadTypePrice={lead?.lead_type?.price}
            price={lead?.payment?.amount || 0}
            discount={values.discount}
          />

          {status && status.message && <CustomAlert message={status.message} />}
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ Configs }) => ({
  tenant: Configs.tenant,
});

const ConnectedComponent = connect(mapStateToProps, { updateLeadDetails })(AddDiscountForm);

export default forwardRef((props, ref) => <ConnectedComponent {...props} fRef={ref} />);
