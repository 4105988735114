import React from "react";
import { strings } from "../../constant/strings";
import { Avatar, Skeleton } from "../common";
import { getTimeDateString, isFieldRestricted, getDateTimeDiff } from "../../helpers/Util";
import { PinLocation, CreatedAt, clock, Initials, IconId, count } from "../svg-icon/svg-icons";
const StockCountDetailCard = props => {
  const { data, loading } = props;

  const item = (
    Icon,
    title,
    desc,
    iconOptions = { color: "", iconClass: "", size: "" },
    truncated = false,
    text = ""
  ) => {
    return desc && !isFieldRestricted(desc) && !isFieldRestricted(text) ? (
      <div className={`info-block d-flex spbwx8 ${truncated ? "info-block-truncated" : ""}`.trim()}>
        <Icon
          letter={desc && desc?.toString()?.charAt(0)}
          desc={text}
          className={`icon-svg ${iconOptions.iconClass ? iconOptions.iconClass : ""}`.trim()}
          style={{
            "--icon-size": iconOptions.size ? iconOptions.size : "",
            "--icon-color": iconOptions.color ? iconOptions.color : "",
          }}
        />
        <div>
          <div className="info-block-title">{title}</div>
          <div className="info-block-desc" title={truncated === true ? desc : ""}>
            {desc}
          </div>
        </div>
      </div>
    ) : null;
  };

  const loadingItem = key => (
    <div className={`info-block d-flex spbwx8`.trim()} key={key}>
      <Skeleton height={40} width={40} />
      <div className="d-flex flex-column">
        <Skeleton width={100} />
        <Skeleton width={150} />
      </div>
    </div>
  );

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex flex-column m-b-25 justify-content-between align-items-left">
          <div className={`d-flex flex-wrap align-items-center ${props.className}`.trim()}>
            {item(
              PinLocation,
              "",
              " ",
              {
                color: "#ffa564",
              },
              false,
              data?.location?.address
            )}
          </div>
          <div className={`d-flex flex-wrap align-items-center ${props.className}`.trim()}>
            {loading ? (
              [1, 2, 3, 4, 5, 6].map(e => loadingItem(e))
            ) : (
              <>
                {item(IconId, strings.ref, data.reference_number, {
                  color: "#ffa564",
                })}
                {item(count, strings.total_cars, data?.stock_counter_inventories?.length, {
                  color: "#ffa564",
                })}
                {item(CreatedAt, strings.start_time, getTimeDateString(data?.start_time), {
                  color: "#ffa564",
                })}
                {item(CreatedAt, strings.end_time, getTimeDateString(data?.end_time), {
                  color: "#ffa564",
                })}

                {item(clock, strings.time, getDateTimeDiff(data?.start_time, data?.end_time), {
                  color: "#ffa564",
                })}

                <div className={`info-block d-flex spbwx8 ${"info-block-truncated"}`.trim()}>
                  <Avatar name={data?.user?.name} imgSrc={data?.user?.picture?.url}></Avatar>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockCountDetailCard;
