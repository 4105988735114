import React from "react";
import { Skeleton, Status, Tooltip } from "../../components/common";
import RenderPrivateField from "../../components/common/RenderPrivateField/RenderPrivateField";
import { BlockIcon, PreferenceStar, UserInfo } from "../../components/svg-icon/svg-icons";
import { extractPayloadFromClientObj } from "../../helpers/ViewPhoneNumber";
import styles from "./DetailCard.module.scss";
import cx from "clsx";
import { ifTypeObject, isFieldRestricted } from "../../helpers/Util";

export const ContactInfo = props => {
  const {
    loading,
    client = {},
    hidePhoneNumberProps = null,
    // cardData,
    isBlacklisted,
    blacklistReason,
    status,
    disposition,
    onPressPreference,
    className,
  } = props;

  return loading ? (
    <div className={cx("d-flex clientInfoWrap")}>
      <div>
        <div className={"d-flex clientInfoThumb"}>
          <Skeleton height={40} width={60} />
        </div>
      </div>
      <div className={"clientInfo mt-2"}>
        <Skeleton height={25} width={60} />
      </div>
    </div>
  ) : (
    <div className={cx("d-flex clientInfoWrap", className)}>
      <div>
        <div className={"d-flex clientInfoThumb"}>
          <UserInfo />
        </div>
      </div>
      <div className={cx("clientInfo", !!client?.preferences?.length && styles.preferenceCard)}>
        <h5 className={"clientInfoName d-flex align-items-center"}>
          {client?.name}
          {!isFieldRestricted(isBlacklisted) && isBlacklisted && (
            <span className={cx(styles.hoverableSvg, "ml-2")} data-tip data-for={"block-tooltip"}>
              <BlockIcon size={"1em"} color={"#BB2025"} />
              <Tooltip id={"block-tooltip"} place="top" type="dark" effect="solid" padding="10px">
                {blacklistReason || client?.reason}
              </Tooltip>
            </span>
          )}
          {status && <Status className="ml-2 mb-4" {...status} disposition={disposition} />}
          {ifTypeObject(client) &&
            !!Object.keys(client).length &&
            client?.preferences &&
            client?.preferences?.length && (
              <div className="cursor-pointer" onClick={() => onPressPreference()}>
                <PreferenceStar size={20} />
              </div>
            )}
        </h5>
        <ul className={"mt-2 d-flex clientInfoOption"}>
          <li>{client?.email}</li>

          {!!hidePhoneNumberProps && client?.phone ? (
            <li>
              <RenderPrivateField
                {...hidePhoneNumberProps}
                {...extractPayloadFromClientObj({ item: client })}
                optionalClassName="d-inline-block"
              />
            </li>
          ) : (
            <li style={{ color: "green" }}>{client?.phone}</li>
          )}
        </ul>
      </div>
    </div>
  );
};
