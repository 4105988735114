import React, { useEffect, useMemo, useState } from "react";
import { DatePicker } from "antd";
import { DATE_FORMAT } from "pf-constants";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const DateRangePicker = props => {
  const { value, onChange, noDefault, noOfDaysBefore = 0, limitRange = false, restrictRange, ...rest } = props;
  const { t } = useTranslation();

  const [date, setDate] = useState(
    !noDefault ? [dayjs().subtract(7 + noOfDaysBefore, "day"), dayjs().subtract(noOfDaysBefore, "day")] : undefined
  );

  useEffect(() => {
    setDate(value?.[0] && value?.[1] ? [dayjs(value[0]), dayjs(value[1])] : undefined);
  }, [value]);

  const onChangeDate = dates => {
    setDate(dates);
    if (!!restrictRange && !!date) {
      if (dates?.[0] && dates?.[1]) {
        const diffInDays = dates[1].diff(dates[0], "day");
        if (diffInDays > restrictRange) {
          dates[1] = dayjs(dates[0]).add(restrictRange, "day");
        }
      }
      onChange(dates);
    } else {
      onChange(dates);
    }
  };

  const ranges = useMemo(() => {
    return !!restrictRange
      ? []
      : [
          { label: t("Yesterday"), value: [dayjs().subtract(1, "days"), dayjs().subtract(1, "days")] },
          { label: t("Last 7 Days"), value: [dayjs().subtract(7 + noOfDaysBefore, "days"), dayjs()] },
          { label: t("Last 30 Days"), value: [dayjs().subtract(30 + noOfDaysBefore, "days"), dayjs()] },
          { label: t("Last 90 Days"), value: [dayjs().subtract(90 + noOfDaysBefore, "days"), dayjs()] },
          { label: t("Last 6 Months"), value: [dayjs().subtract(6, "months"), dayjs()] },
          { label: t("Last Year"), value: [dayjs().subtract(12, "months"), dayjs()] },
        ];
  }, [restrictRange]);

  return (
    <div>
      <RangePicker
        allowClear={false}
        presets={ranges}
        value={date}
        onChange={onChangeDate}
        format={DATE_FORMAT}
        // size="large"
        style={{ minHeight: "var(--ant-min-height)" }}
        {...rest}
        // locale={appLanguage === "en" ? undefined : locale}
      />
    </div>
  );
};
export default DateRangePicker;
