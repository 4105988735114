import { fetchUsers } from "helpers/userHelper";
import React from "react";
import { strings } from "../../../constant/strings";
import FormRow from "../FormRow";
import { PaginatedSearch } from "../PaginatedSearch";

const ReporteeSelect = ({
  setFieldValue,
  value,
  designation,
  currentUserId,
  errorMsg,
  isDesignationRootLevel,
  horizontal,
  columnLeftSection,
  columnRightSection,
  textRight,
}) => {
  const designationId = designation?.id;
  const filterDesignationId = id => id !== designationId;
  const filteredPathIds = designation?.path_ids?.filter(filterDesignationId);
  const appendQueryParam = (queryString, parentId) => {
    return queryString + `&f[designation.id][]=${parentId}`;
  };
  const fetchReporteeParams = filteredPathIds => {
    if (!Array.isArray(filteredPathIds)) return "";
    const queryString = filteredPathIds.reduce(appendQueryParam, "");
    return queryString;
  };
  const shouldHideReporteeSelect = designation => {
    if (!designation) return true; // when designation is not selected in the first place
    if (isDesignationRootLevel(designation)) return true; //Case when designation is root level
    return false;
  };
  if (shouldHideReporteeSelect(designation)) return null;

  const handleChange = event => {
    setFieldValue("reportee", event);
  };

  const reportee = {
    label: strings.reportee,
    user: value,
    disabled: !designation,
    onChange: handleChange,
    apiCall:
      filteredPathIds?.length < 1
        ? () => ({})
        : (input, page) => fetchUsers({ "s[name]": input, page }, fetchReporteeParams(filteredPathIds)),
    fetchResponseKey: "",
    fetchParams: filteredPathIds?.length < 1 ? "" : fetchReporteeParams(filteredPathIds),
  };

  return (
    <FormRow
      horizontal={horizontal}
      label={reportee.label}
      columnLeftSection={columnLeftSection}
      columnRightSection={columnRightSection}
      textRight={textRight}
      rightSectionClass="m-t-10"
    >
      <PaginatedSearch
        infoView
        textRight
        showLabel={false}
        filterCurrentUser={currentUserId}
        toggleClassName="form-control text-truncate"
        onChange={reportee.onChange}
        isSingleSelect
        defaultSelected={reportee.user}
        showSearchIcon={false}
        horizontal
        fetchOptions={reportee.apiCall}
        direction={"up"}
        disabled={reportee.disabled}
      />
      {!shouldHideReporteeSelect(designation) && <span className="invalid-error ml-1">{errorMsg}</span>}
    </FormRow>
  );
};

export default ReporteeSelect;
