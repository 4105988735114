import * as yup from "yup";
import { FormInput } from "../common";
import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { Formik } from "formik";
import { strings } from "../../constant/strings";

const AddLinkTypeDynamicField = props => {
  let formikRef = useRef();

  useImperativeHandle(props.fRef, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  const getInitialValues = () => ({
    label: "",
    url: "",
  });

  const validateLink = yup.object().shape({
    label: yup.string().required(),
    url: yup.string().url().required(),
  });

  const handleSubmit = values => {
    props.saveCallback(values);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={validateLink}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => {
        return (
          <>
            <FormInput
              label={strings.url}
              placeholder={strings.url_ph}
              onChange={handleChange("url")}
              onBlur={handleBlur("url")}
              value={values.url}
              errorMsg={errors.url && touched.url && errors.url}
              required
            />
            <FormInput
              label={strings.add_label}
              placeholder={strings.field_label_ph}
              onChange={handleChange("label")}
              onBlur={handleBlur("label")}
              value={values.label}
              errorMsg={errors.label && touched.label && errors.label}
              required
            />
          </>
        );
      }}
    </Formik>
  );
};

export default forwardRef((props, ref) => <AddLinkTypeDynamicField {...props} fRef={ref} />);
