import React, { useState } from "react";
import {
  LEAD_TYPES,
  MODELS_FOR_VIEW_PHONE_NUMBER_API,
  PHONE_NUMBER_CATEGORIZATION_PER_PAGE,
} from "constant/appConstants";
import { strings } from "../../../constant/strings";
import { isFieldEditable, isFieldViewable } from "../../../helpers/Util";
import { FormInput, Toast } from "../../common";
import FormInputPhoneNumbers from "../../common/FormInputPhoneNumbers/FormInputPhoneNumbers";

const Info = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleBlur,
  title,
  tenant,
  leadId = null,
  clientUUID = null,
  crmLeadPermittedAttributes = null,
  isEditLeadPage,
  showPhoneNumberProps = {
    addPhoneNumbersInAPIPayload: false,
    setAddPhoneNumbersInAPIPayload: null,
    hasViewPhonePermission: false,
    showPhoneNumberCTA: false,
    setShowPhoneNumberCTA: () => {},
  },
  disablePhoneField = false,
}) => {
  const { phone_placeholder } = tenant.configuration;
  const [viewPhoneLoading, setViewPhoneLoading] = useState(false);
  const [warning, setWarning] = useState(null);

  const phoneIsEditable = key => {
    const ctaPermissionsForEditField = !!(
      showPhoneNumberProps?.hasViewPhonePermission && isFieldEditable(crmLeadPermittedAttributes?.[key])
    );
    const defaultPermissionsForEditField = isFieldEditable(crmLeadPermittedAttributes?.[key]);

    const isBuyerLeadAttached = !!values.preferred_buyer_lead?.client.uuid;

    if (values?.lead_category?.slug === LEAD_TYPES.VAS && isBuyerLeadAttached) {
      return false;
    }

    return isEditLeadPage ? ctaPermissionsForEditField : defaultPermissionsForEditField;
  };

  const checkIsEditLeadPage = () => {
    const isBuyerLeadAttached = !!values?.preferred_buyer_lead?.client?.uuid;

    if (values?.lead_category?.slug === LEAD_TYPES.VAS) {
      return isBuyerLeadAttached ? true : false;
    } else {
      return isEditLeadPage;
    }
  };

  const getCTAText = () => {
    if (values?.lead_category?.slug === LEAD_TYPES.VAS) {
      return "Show Phone Number";
    } else {
      return "Edit Phone Number";
    }
  };

  const successCallback = data => {
    if (showPhoneNumberProps && showPhoneNumberProps?.setAddPhoneNumbersInAPIPayload)
      showPhoneNumberProps.setAddPhoneNumbersInAPIPayload(true);

    setFieldValue("phone", data?.phone || "");
    setFieldValue("secondary_phone", data?.secondary_phone || "");
  };

  const warningText = fieldName => `Updating ${fieldName} will also reflect in client.`;

  return (
    <>
      <div className="border-bottom my-4">
        <h6>{title}</h6>
      </div>

      {isFieldViewable(crmLeadPermittedAttributes?.["client.name"]) && (
        <>
          <FormInput
            label={strings.name}
            placeholder={strings.name_ph}
            onChange={handleChange("name")}
            onBlur={e => {
              handleBlur("name")(e);
              setWarning({ name: "" });
            }}
            onFocus={() => {
              setWarning({ name: warningText("name") });
            }}
            value={values.name || ""}
            errorMsg={errors.name && touched.name ? errors.name : warning?.name}
            required
            horizontal
            textRight
            isEditable={!values.preferred_buyer_lead && isFieldEditable(crmLeadPermittedAttributes?.["client.name"])}
          />
        </>
      )}

      {isFieldViewable(crmLeadPermittedAttributes?.["client.phone"]) && (
        <>
          <FormInputPhoneNumbers
            phoneData={{
              uuid: clientUUID,
              associatedId: leadId,
              associatedType: MODELS_FOR_VIEW_PHONE_NUMBER_API.CLIENT_LISTING_PHONE_KEY,
              component: leadId
                ? PHONE_NUMBER_CATEGORIZATION_PER_PAGE.CRM_LEADS.EDIT
                : PHONE_NUMBER_CATEGORIZATION_PER_PAGE.CRM_LEADS.NEW,
              successCallback,
            }}
            // hasSuccessfullyFetchedTheData={showPhoneNumberProps?.addPhoneNumbersInAPIPayload}
            isEditLeadPage={checkIsEditLeadPage()}
            showPhoneNumberCTA={
              !showPhoneNumberProps?.addPhoneNumbersInAPIPayload && showPhoneNumberProps?.showPhoneNumberCTA
            }
            ctaText={getCTAText()}
            formInputProps={{
              label: strings.phone,
              placeholder: phone_placeholder,
              onChange: handleChange("phone"),
              onBlur: handleBlur("phone"),
              value: values?.phone || "",
              horizontal: true,
              errorMsg: errors.phone && touched.phone && errors.phone,
              textRight: true,
              isEditable: phoneIsEditable("client.phone"),
              disabled: disablePhoneField,
            }}
          />
        </>
      )}

      {isFieldViewable(crmLeadPermittedAttributes?.["client.secondary_phone"]) && (
        <>
          <FormInputPhoneNumbers
            phoneData={{
              uuid: clientUUID,
              associatedId: leadId,
              associatedType: MODELS_FOR_VIEW_PHONE_NUMBER_API.CLIENT_LISTING_PHONE_KEY,
              component: leadId
                ? PHONE_NUMBER_CATEGORIZATION_PER_PAGE.CRM_LEADS.EDIT
                : PHONE_NUMBER_CATEGORIZATION_PER_PAGE.CRM_LEADS.NEW,
              successCallback,
            }}
            // hasSuccessfullyFetchedTheData={showPhoneNumberProps?.addPhoneNumbersInAPIPayload}
            isEditLeadPage={checkIsEditLeadPage()}
            showPhoneNumberCTA={
              !showPhoneNumberProps?.addPhoneNumbersInAPIPayload && showPhoneNumberProps?.showPhoneNumberCTA
            }
            ctaText={getCTAText()}
            formInputProps={{
              label: strings.secondary_phone_optional,
              placeholder: phone_placeholder,
              onChange: handleChange("secondary_phone"),
              onBlur: () => {
                handleBlur("secondary_phone");
                setWarning({ secondary_phone: "" });
              },
              onFocus: () => setWarning({ secondary_phone: warningText("secondary phone") }),
              value: values?.secondary_phone || "",
              horizontal: true,
              errorMsg:
                errors.secondary_phone && touched.secondary_phone ? errors.secondary_phone : warning?.secondary_phone,
              textRight: true,
              isEditable: phoneIsEditable("client.secondary_phone"),
            }}
          />
        </>
      )}

      {isFieldViewable(crmLeadPermittedAttributes?.["client.email"]) && (
        <>
          <FormInput
            label={strings.email_optional}
            placeholder={strings.email_ph}
            onChange={handleChange("email")}
            onBlur={() => {
              handleBlur("email");
              setWarning({ email: "" });
            }}
            onFocus={() => setWarning({ email: warningText("email") })}
            value={values.email || ""}
            horizontal
            errorMsg={errors.email && touched.email ? errors.email : warning?.email}
            textRight
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["client.email"])}
          />
        </>
      )}

      {isFieldViewable(crmLeadPermittedAttributes?.["client.address"]) && (
        <>
          <FormInput
            label={strings.address}
            placeholder={strings.address_ph}
            onChange={handleChange("address")}
            onBlur={() => {
              handleBlur("address");
              setWarning({ address: "" });
            }}
            onFocus={() => setWarning({ address: warningText("address") })}
            value={values.address || ""}
            horizontal
            errorMsg={errors.address && touched.address ? errors.address : warning?.address}
            linesCount={4}
            textRight
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["client.address"])}
          />
        </>
      )}
    </>
  );
};

export default Info;
