import { FiChevronDown, FiChevronUp } from "../../svg-icon/svg-icons";
import React, { useState } from "react";
import {
  addQueryFilterArray,
  addQueryFilterArrayPreSelected,
  getUTCOffset,
  makeListToSelectData,
} from "../../../helpers/Util";

import DateFilter from "./DateFilter";
import DropdownSelect from "../DropdownSelect";
import { SearchInput } from "../SearchInput";
import SelectionListTypeHead from "../SelectionListTypeHead";
import { connect } from "react-redux";
import { strings } from "../../../constant/strings";
import { FILTER_TYPES } from "constant/appConstants";
import AsyncSelect from "../AsyncSelect";

// import moment from "moment";

const FiltersWithoutQueryParams = props => {
  const { list, containerClass, country, loading } = props;
  const baseList = list.filter(e => (e.list !== undefined ? !!e.list.length : true));

  const [showFilters, setShowFilters] = useState(false);
  const { timezone } = country;

  const onSearch = (object = props.filtersObject) => {
    const obj = { ...object };
    props.setFiltersObject(obj);
  };

  const onValueSelect = ({ slug: value, selected }, filterList, searchKey) => {
    if (!selected) {
      addQueryFilterArray(filterList, searchKey, value, "", obj => {
        props.setFiltersObject(obj);
      });
    } else {
      addQueryFilterArrayPreSelected(filterList, searchKey, value, "", [], obj => {
        props.setFiltersObject(obj);
      });
    }
  };

  const getFilterType = ({
    filtersObject,
    selected,
    formatOptions,
    asyncQueryKey,
    isSingleSelect,
    extraParams,
    queryKey,
    listProperty,
    listApi,
    type,
    isRefNumber,
    label,
    key,
    list,
    setSlugAsKey,
    verifySlugOnly = false,
    ...rest
  }) => {
    switch (type) {
      case FILTER_TYPES.SCROLLABLE_DROPDOWN:
        return (
          <AsyncSelect
            isClearable={true}
            selected={selected}
            listApi={listApi}
            listProperty={listProperty}
            queryKey={queryKey}
            extraParams={extraParams}
            onChange={selected => {
              if (selected) {
                props.setFiltersObject({
                  ...props.filtersObject,
                  [key]: String(selected.id),
                });
              } else {
                delete props.filtersObject[key];
                props.setFiltersObject({
                  ...props.filtersObject,
                });
              }
            }}
            filterKey={key}
            filtersObject={filtersObject}
            setSlugAsKey={setSlugAsKey}
            verifySlugOnly={verifySlugOnly}
            placeholder={label}
            loading={loading}
            isFitler={true}
            key={asyncQueryKey}
            formatOptionLabel={option => {
              return formatOptions(option);
            }}
            {...rest}
          />
        );
      case "dropdown":
        return (
          <DropdownSelect
            label={label}
            options={makeListToSelectData(list || [], props.filtersObject[key], setSlugAsKey, verifySlugOnly)}
            toggleClassName="form-control text-truncate"
            onChange={item => onValueSelect(item, props.filtersObject, key)}
            showSearchIcon
            noSelectValue
            loading={loading}
            {...rest}
          />
        );
      case "date":
        return (
          <DateFilter
            label={label}
            timezone={timezone}
            queryStartDate={props.filtersObject[`q[${key}_gteq]`]}
            queryEndDate={props.filtersObject[`q[${key}_lt]`]}
            onSelect={(startDate, endDate) => {
              const utcOffset = getUTCOffset(timezone);
              const obj = {
                ...props.filtersObject,
                [`q[${key}_gteq]`]: `${startDate}${utcOffset}`,
                [`q[${key}_lt]`]: `${endDate}${utcOffset}`,
              };
              obj.page && delete obj.page;
              props.setFiltersObject(obj);
              onSearch(obj);
            }}
            onClear={() => {
              delete props.filtersObject[`q[${key}_gteq]`];
              delete props.filtersObject[`q[${key}_lt]`];
              onSearch(props.filtersObject);
            }}
            loading={loading}
            {...rest}
          />
        );

      case "typeahead": {
        const selectedValue = props.filtersObject?.[key] ? list?.find(e => e.id === props.filtersObject?.[key]) : null;
        return (
          <SelectionListTypeHead
            className="filter-typehead"
            placeholder={label}
            selected={selectedValue ? [selectedValue] : []}
            options={makeListToSelectData(list || [])}
            onChange={selected => {
              if (selected.length) {
                props.setFiltersObject({
                  ...props.filtersObject,
                  [key]: selected[0].id,
                });
              } else {
                delete props.filtersObject[key];
                props.setFiltersObject({
                  ...props.filtersObject,
                });
              }
            }}
            showSearchIcon
            labelKey="name"
            loading={loading}
          />
        );
      }

      default:
        return (
          <SearchInput
            value={props.filtersObject?.[key] || ""}
            placeholder={label}
            onChange={e => {
              if (!e.target.value) delete props.filtersObject[key];
              props.filtersObject.page && delete props.filtersObject.page;
              props.setFiltersObject({
                ...props.filtersObject,
                [key]: e.target.value,
              });
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
            loading={loading}
            {...(isRefNumber === true ? { type: "number" } : null)}
            {...rest}
          />
        );
    }
  };
  return (
    <div
      className={`filter__row--section ${containerClass ? containerClass : ""}`}
      style={{ position: "relative", zIndex: 999 }}
    >
      <div className="form-row filter-animation">
        {baseList.map((item, index) =>
          index < 4 || showFilters ? (
            <div className="col-sm-6 col-md-4 col-xl-3 m-b-10" key={item.key}>
              {getFilterType(item)}
            </div>
          ) : null
        )}
        {baseList.length > 4 && !loading && (
          <span
            className="d-flex align-items-center more-filters"
            onClick={() => setShowFilters(showFilters => !showFilters)}
          >
            {showFilters ? strings.hide_filters : strings.more_filters}
            {showFilters ? <FiChevronUp /> : <FiChevronDown />}
          </span>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ Configs }) => {
  return {
    country: Configs.tenant.country,
  };
};
export default connect(mapStateToProps, {})(FiltersWithoutQueryParams);
