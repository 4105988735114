import dubizzleFavicon from "../assets/images/dubizzle-favicon.ico";
import dubizzleLogo from "../assets/images/dubizzle-logo.png";
import { getCookie } from "../helpers/CookieHelper";
import kaideeFavicon from "../assets/images/kaidee-favicon.ico";
import kaideeLogo from "../assets/images/kaidee-logo.png";
import olxCareemFavIcon from "../assets/images/olx-careem-favicon.png";
import olxCareemLogo from "../assets/images/olx-careem-logo.png";
import olxFavicon from "../assets/images/olx-favicon.ico";
import olxLebanonFavicon from "../assets/images/olx-lebanon-favicon.png";
import olxLebanonLogo from "../assets/images/olx-lebanon-logo.png";
import olxLogo from "../assets/images/olx-logo.png";
import carFirstLogo from "../assets/images/carfirst-logo.png";
import carFirstFavicon from "../assets/images/carfirst-favicon.png";

const olxTheme = () => {
  setThemeStorage("color-1", "#4466f2", "light", "#1ea6ec");
  return {
    color: {
      layout_version: "light",
      color: "color-1",
      primary_color: "#4466f2",
      secondary_color: "#1ea6ec",
      mix_layout: "light-only",
    },
    logo: olxLogo,
    favicon: olxFavicon,
    oneSignalId: "1bc6c772-b4ed-4834-84b8-e7ad4495fe3a",
  };
};

const dubizzleTheme = () => {
  setThemeStorage("color-2", "#BB2025", "light", "#2B2D2E");
  return {
    color: {
      layout_version: "light",
      color: "color-2",
      primary_color: "#BB2025",
      secondary_color: "#2B2D2E",
      mix_layout: "light-only",
    },
    logo: dubizzleLogo,
    favicon: dubizzleFavicon,
    oneSignalId: process.env.REACT_APP_ONESIGNAL_APP_ID,
  };
};

const kaideeTheme = () => {
  setThemeStorage("color-3", "#000E49", "light", "#1ea6ec");
  return {
    color: {
      layout_version: "light",
      color: "color-3",
      primary_color: "#000E49",
      secondary_color: "#1ea6ec",
      mix_layout: "light-only",
    },
    logo: kaideeLogo,
    favicon: kaideeFavicon,
  };
};

const olxLebanonTheme = () => {
  setThemeStorage("color-4", "#002f34", "light", "#002f34");
  return {
    color: {
      layout_version: "light",
      color: "color-4",
      primary_color: "#002f34",
      secondary_color: "#002f34",
      mix_layout: "light-only",
    },
    logo: olxLebanonLogo,
    favicon: olxLebanonFavicon,
  };
};

const olxCareemTheme = () => {
  setThemeStorage("color-5", "#28BB4E", "light", "#1ea6ec");
  return {
    color: {
      layout_version: "light",
      color: "color-5",
      primary_color: "#28BB4E",
      secondary_color: "#5f6a74",
      mix_layout: "light-only",
    },
    logo: olxCareemLogo,
    favicon: olxCareemFavIcon,
  };
};

const carFirstTheme = () => {
  setThemeStorage("color-6", "#178971", "light", "#FBD703");
  return {
    color: {
      layout_version: "light",
      color: "color-6",
      primary_color: "#178971",
      secondary_color: "#FBD703",
      mix_layout: "light-only",
    },
    logo: carFirstLogo,
    favicon: carFirstFavicon,
  };
};

const setThemeStorage = (colorVersion, primaryColor, layoutVersion, secondaryColor) => {
  localStorage.setItem("color", colorVersion);
  localStorage.setItem("primary_color", primaryColor);
  localStorage.setItem("layout_version", layoutVersion);
  localStorage.setItem("secondary_color", secondaryColor);
};

const getTenantTheme = () => {
  const url = new URL(window.location.href);
  if (url.hostname.includes("olxlb")) {
    return olxLebanonTheme();
  } else if (url.hostname.includes("dubizzle")) {
    return dubizzleTheme();
  } else if (url.hostname.includes("kaidee")) {
    return kaideeTheme();
  } else if (url.hostname.includes("olx")) {
    return olxTheme();
  } else if (url.hostname.includes("careem")) {
    return olxCareemTheme();
  } else if (url.hostname.includes("carfirst")) {
    return carFirstTheme();
  } else {
    return dubizzleTheme();
  }
};

export class ConfigDB {
  static data = {
    settings: {
      layout_type: getCookie("language") === "ur" ? "rtl" : "ltr",
      sidebar: {
        wrapper: "default",
        bodyWrapper: "default",
      },
      sidebar_setting: "default-sidebar",
      sidebar_backround: "light-sidebar",
    },
    ...getTenantTheme(),
    router_animation: "fadeIn",
  };
}

export default ConfigDB;
