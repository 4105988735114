import {
  appendTimeZone,
  generateUUID,
  isFieldRestricted,
  setTimeZone,
  validateURL,
  isFieldViewable,
  isFieldEditable,
  makeListToSelectData,
} from "../../../helpers/Util";
import { strings } from "../../../constant/strings";
import { DYNAMIC_FIELD_TYPES, DYNAMIC_MODELS } from "constant/appConstants";
import { maxValidation } from "./DynamicFieldSchemaHelpler";

export const makeOptionsData = (e, values, modelName) =>
  e.dynamic_field_options
    .filter(
      it =>
        !values[`${modelName}_dynamic_${e.field_name}`]?.find(el =>
          el.label ? it.field_label === el.label : el === it.field_label
        )
    )
    .map(item => ({
      id: generateUUID(),
      label: item.field_label || item,
      multiSelect: e.field_data_type === DYNAMIC_FIELD_TYPES.MULTI_SELECT,
    }));

export const makeDynamicSelectFieldOptionsData = (e, values, modelName) => {
  const selectedLabels = values[`${modelName}_dynamic_${e.field_name}`]?.map(el => el.label || el?.name) || [];
  const requiredDynamicData = e.dynamic_field_options
    .filter(it => !selectedLabels.includes(it.field_label || it))
    .map(item => {
      return {
        id: item.id,
        name: item.field_label || item || item.name,
        multiSelect: e.field_data_type === DYNAMIC_FIELD_TYPES.MULTI_SELECT,
        dependentFields: item.dependent_fields ?? [],
      };
    });
  return requiredDynamicData;
};

export const makeSelectData = (data = [], isMultiSelect, dynamicFieldObject = {}) => {
  if (!data?.length) return;
  let formattedData = [];
  if (!Array.isArray(data) && typeof data === "string") {
    formattedData = [data];
  } else {
    formattedData = [...data];
  }
  return formattedData.map(i => {
    const selectedOptionDependentFields =
      dynamicFieldObject?.dynamic_field_options.find(item => item.field_label === i)?.dependent_fields ?? [];
    return {
      name: i,
      multiSelect: isMultiSelect,
      dependentFields: selectedOptionDependentFields ?? [],
    };
  });
};
export const getDynamicFieldName = (modelName, fieldKey) => fieldKey.replace(`${modelName}_dynamic_`, "");

// export const getDynamicFieldName = fieldKey => fieldKey.split("_dynamic_")[1];
export const isFieldDynamic = (fieldKey, modelName) => fieldKey.includes(`${modelName}_dynamic_`);

export const makeImageData = pictures => {
  const createAttachmentsObject = (attachmentIds = []) => ({
    type: DYNAMIC_FIELD_TYPES.ATTACHMENT,
    attachment_ids: attachmentIds,
  });
  const attachmentIds = pictures.map(picture => (picture?.id ? picture?.id : picture));
  const attachments = createAttachmentsObject(attachmentIds);
  return attachments;
};

export const ifValueForInput = type =>
  type === DYNAMIC_FIELD_TYPES.STRING ||
  type === DYNAMIC_FIELD_TYPES.INTEGER ||
  type === DYNAMIC_FIELD_TYPES.TEXT ||
  type === DYNAMIC_FIELD_TYPES.PRICE;

export const ifValueForTypeahead = type =>
  type === DYNAMIC_FIELD_TYPES.SINGLE_SELECT || type === DYNAMIC_FIELD_TYPES.MULTI_SELECT;

export const ifValueTypeNumber = type => type === DYNAMIC_FIELD_TYPES.INTEGER || type === DYNAMIC_FIELD_TYPES.PRICE;

const getUserSelectValue = (selected, dynamicAttributes) => {
  if (selected) {
    return makeListToSelectData(selected?.users, "", true)?.at(0);
  } else {
    return null;
  }
};

const getUserSelectValueForItemList = (selected, dynamicAttributes) => {
  if (selected) {
    return selected;
  } else {
    return "";
  }
};

export const setInitialValuesForDynamicFields = (
  currentValue,
  current,
  modelName = "",
  setFieldValue,
  fieldKey = "",
  timezone
) => {
  const dynamicAttributes = {};
  currentValue &&
    currentValue.dynamic_fields &&
    !isFieldRestricted(currentValue.dynamic_fields) &&
    currentValue.dynamic_fields.forEach(e => {
      const isPresent = !!Object.keys(current?.dynamic_attributes?.[modelName] || {}).find(i => {
        return e.field_name === i;
      });
      const dynamicAttribute = current?.dynamic_attributes?.[modelName]?.[e.field_name];
      if (isPresent && dynamicAttribute !== "Restricted") {
        const fieldKey = `${modelName}_dynamic_${e.field_name}`;
        if (e.field_data_type === DYNAMIC_FIELD_TYPES.MULTI_SELECT) {
          const dynamicFieldObject = currentValue.dynamic_fields.find(item => item.field_label === e.field_label);
          dynamicAttributes[fieldKey] = dynamicAttribute
            ? makeSelectData(dynamicAttribute, true, dynamicFieldObject)
            : [];
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.SINGLE_SELECT) {
          const dynamicFieldObject = currentValue.dynamic_fields.find(item => {
            return item.field_label === e.field_label;
          });
          dynamicAttributes[fieldKey] = dynamicAttribute
            ? makeSelectData(dynamicAttribute, false, dynamicFieldObject)
            : [];
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.ATTACHMENT) {
          if (dynamicAttribute?.attachment_ids?.length) {
            const url = current?.dynamic_attachments?.find(i => i.id === dynamicAttribute?.attachment_ids?.[0])?.url;
            const data = { ...dynamicAttribute, url };
            dynamicAttributes[fieldKey] = data;
          }
        } else if (ifValueForInput(e.field_data_type)) {
          dynamicAttributes[fieldKey] = {
            type: e.field_data_type,
            value: dynamicAttribute,
          };
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.DATE_TIME) {
          dynamicAttributes[fieldKey] = {
            type: e.field_data_type,
            value: dynamicAttribute ? setTimeZone(timezone, dynamicAttribute) : null,
          };
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.DATE) {
          const dynamicValue = dynamicAttribute?.value || dynamicAttribute;
          dynamicAttributes[fieldKey] = {
            type: e.field_data_type,
            value: dynamicValue ? setTimeZone(timezone, dynamicValue) : null,
          };
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.USER_LIST) {
          dynamicAttributes[fieldKey] = {
            type: e.field_data_type,
            value: getUserSelectValue(dynamicAttribute, e),
          };
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.ITEM_LIST) {
          dynamicAttributes[fieldKey] = getUserSelectValueForItemList(dynamicAttribute, e);
        } else {
          if (dynamicAttribute?.type === "link" && !dynamicAttribute?.link) {
          } else {
            dynamicAttributes[fieldKey] = dynamicAttribute;
          }
        }
      }
    });

  currentValue && fieldKey && setFieldValue(fieldKey, currentValue);
  Object.keys(dynamicAttributes).forEach(item => {
    setFieldValue(item, dynamicAttributes[item]);
  });
};

export const makeValuesObject = (
  values,
  modelName,
  timezone,
  activeColumnsPermittedAttributes,
  checkViewable = false
) => {
  const valuesObj = {};

  Object.keys(values).map(e => {
    if (isFieldDynamic(e, modelName)) {
      let activeColumnKey = `dynamic_attributes.${modelName}.${getDynamicFieldName(modelName, e)}`;
      const activeColumnsPermittedAttributePayload = activeColumnsPermittedAttributes?.[activeColumnKey];
      if (!isFieldEditable(activeColumnsPermittedAttributePayload)) {
        if (!checkViewable) return null;
        if (!isFieldViewable(activeColumnsPermittedAttributePayload)) {
          return null;
        }
      }
      let fieldName = getDynamicFieldName(modelName, e);
      let currentValue = values[e];

      if (typeof currentValue === "object" && !Object.keys(currentValue || {})?.length) {
        valuesObj[fieldName] = null;
      } else if (currentValue?.type === DYNAMIC_FIELD_TYPES.INTEGER) {
        valuesObj[fieldName] = parseFloat(currentValue?.value);
      } else if (currentValue?.type === DYNAMIC_FIELD_TYPES.ATTACHMENT) {
        let imageData = makeImageData(values[e].attachment_ids);
        valuesObj[fieldName] = imageData;
      } else if (Array.isArray(currentValue)) {
        currentValue.forEach(item => {
          valuesObj[fieldName] =
            item["multiSelect"] === true ? [...(valuesObj[fieldName] || []), item.name] : [item.name];
        });
      } else if (
        currentValue?.type === DYNAMIC_FIELD_TYPES.STRING ||
        currentValue?.type === DYNAMIC_FIELD_TYPES.TEXT ||
        currentValue?.type === DYNAMIC_FIELD_TYPES.PRICE
      ) {
        valuesObj[fieldName] = values[e]?.value;
      } else if (currentValue?.type === DYNAMIC_FIELD_TYPES.DATE_TIME) {
        if (modelName === DYNAMIC_MODELS.CICO) {
          valuesObj[fieldName] = values[e]?.value;
        } else {
          valuesObj[fieldName] = values[e]?.value ? appendTimeZone(values[e]?.value, timezone) : null;
        }
      } else if (currentValue?.type === DYNAMIC_FIELD_TYPES.USER_LIST) {
        let data = {
          type: DYNAMIC_FIELD_TYPES.USER_LIST,
          users: [{ id: values[e]?.value?.id, email: values[e]?.value?.email, name: values[e]?.value?.name }],
        };
        valuesObj[fieldName] = data;
      } else {
        valuesObj[fieldName] = values[e];
      }
    }
  });
  return valuesObj;
};

export const validateFieldsData = (
  values,
  currentValue,
  modelName,
  setFieldError,
  activeColumnsPermittedAttributes = null
) => {
  let hasError = false;
  let errorData = {};
  !isFieldRestricted(currentValue?.dynamic_fields) &&
    currentValue.dynamic_fields.forEach(e => {
      let activeColumnKey = `dynamic_attributes.${modelName}.${e.field_name}`;
      const dynamicAttributeKey = `${modelName}_dynamic_${e.field_name}`;
      const activeColumnsPermittedAttributePayload = activeColumnsPermittedAttributes?.[activeColumnKey];
      if (!isFieldEditable(activeColumnsPermittedAttributePayload)) {
      } else if (
        e.field_data_type === DYNAMIC_FIELD_TYPES.INTEGER &&
        values[dynamicAttributeKey]?.value &&
        !maxValidation(values[dynamicAttributeKey]?.value)
      ) {
        hasError = true;
        errorData[e.field_name] = true;
        setFieldError(dynamicAttributeKey, strings.integer_exceeds_maximum_value);
      } else if (e.is_required === true && e.field_data_type !== DYNAMIC_FIELD_TYPES.BOOLEAN) {
        if (ifValueForTypeahead(e.field_data_type) && !values[dynamicAttributeKey]?.length) {
          hasError = true;
          errorData[e.field_name] = true;
          setFieldError(dynamicAttributeKey, strings.field_required_message);
        } else if (ifValueForInput(e.field_data_type) && !values[dynamicAttributeKey]?.value) {
          hasError = true;
          errorData[e.field_name] = true;
          setFieldError(dynamicAttributeKey, strings.field_required_message);
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.LINK) {
          if (!validateURL(values[dynamicAttributeKey]?.["link"])) {
            hasError = true;
            errorData[e.field_name] = true;
            setFieldError(dynamicAttributeKey, strings.invalid_link_message);
          }
        } else if (
          e.field_data_type === DYNAMIC_FIELD_TYPES.ATTACHMENT &&
          !values[dynamicAttributeKey]?.attachment_ids?.length
        ) {
          hasError = true;
          errorData[e.field_name] = true;
          setFieldError(dynamicAttributeKey, strings.field_required_message);
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.DATE_TIME && !values[dynamicAttributeKey]?.value) {
          hasError = true;
          errorData[e.field_name] = true;
          setFieldError(dynamicAttributeKey, strings.field_required_message);
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.DATE && !values[dynamicAttributeKey]?.value) {
          hasError = true;
          errorData[e.field_name] = true;
          setFieldError(dynamicAttributeKey, strings.field_required_message);
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.USER_LIST && !values[dynamicAttributeKey]?.value) {
          hasError = true;
          errorData[e.field_name] = true;
          setFieldError(dynamicAttributeKey, strings.field_required_message);
        } else if (e.field_data_type === DYNAMIC_FIELD_TYPES.ITEM_LIST) {
          hasError = true;
          errorData[e.field_name] = true;
          setFieldError(dynamicAttributeKey, strings.field_required_message);
        }
      } else if (e.field_regex) {
        if (!new RegExp(e.field_regex).test(values[dynamicAttributeKey]?.value)) {
          hasError = true;
          setFieldError(dynamicAttributeKey, strings.field_not_valid_message);
        }
      }
    });
  return { hasError, errorData };
};

export const getDynamicAttachmentIds = (dynamicAttributes = {}) => {
  const dynamicAttachmentIds = Object.keys(dynamicAttributes).reduce((acc, element) => {
    const field = dynamicAttributes[element];
    if (field?.type === DYNAMIC_FIELD_TYPES.ATTACHMENT) {
      acc.push(field.attachment_ids[0]);
    }
    return acc;
  }, []);
  return dynamicAttachmentIds;
};

export const getDynamicFieldInitialValues = (dynamicFieldsData = [], permittedAttributes, model) => {
  const setInitialValues = type => {
    switch (type) {
      case "single-select":
        return [];
      case "multi-select":
        return [];
      // case "link":
      //   return {}
      // case "attachment":
      //   return {};
      default:
        return null;
    }
  };
  let dynamicFieldsInitialValues = [];
  Array.isArray(dynamicFieldsData) &&
    dynamicFieldsData.forEach(e => {
      const activeColumnKey = `dynamic_attributes.${model}.${e.field_name}`;
      const activeColumnsPermittedAttributePayload = permittedAttributes?.[activeColumnKey];
      isFieldViewable(activeColumnsPermittedAttributePayload) &&
        dynamicFieldsInitialValues.push({
          [`${model}_dynamic_${e.field_name}`]: setInitialValues(e.field_data_type),
        });
    });
  return dynamicFieldsInitialValues;
};
