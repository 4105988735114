import { FormInput, Toast } from "../../components/common";
import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";
import { strings } from "../../constant/strings";
import { Formik } from "formik";
import { getErrorString, isFieldRestricted } from "../../helpers/Util";
import { selectTenant } from "../../reducers/general/selectors";
import * as yup from "yup";
import {
  fetchAuctionOfferMarkAsOfferDetail,
  fetchTenantBookingServices,
  markAuctionOfferAsWinner,
} from "../../helpers/ApiHelper";
import { useSelector } from "react-redux";
import { Switch } from "@chakra-ui/react";

const defaultState = {
  bookingServices: [],
  serviceTypes: [],
};

const MarkAsWinnerForm = forwardRef((props, ref) => {
  const { offerId, setOpenModal, openModal, fetchOffers, auctionCycle, setLoading, offerAmount } = props;
  const [offer, setOffer] = useState(null);
  const [bookingServicesDetail, setBookingServicesDetail] = useState({ ...defaultState });
  let formikRef = useRef();
  let bidder = offer?.bidder;
  let inventory = offer?.inventory;
  let inventoriable = offer?.inventory?.inventoriable;

  const tenant = useSelector(selectTenant);

  useEffect(() => {
    const fetchDetail = async () => {
      const response = await fetchAuctionOfferMarkAsOfferDetail(offerId);
      if (response.success) {
        setOffer(response.offer);
      }
    };
    fetchDetail();
  }, [openModal]);

  useEffect(() => {
    const fetchTenantDetail = async () => {
      const response = await fetchTenantBookingServices(tenant?.id);
      if (response.success) {
        setBookingServicesDetail({
          bookingServices: [...response.booking_services],
          serviceTypes: [...response.service_types],
        });
      }
    };
    fetchTenantDetail();
  }, [openModal]);

  let formattedServices = getFormattedServices(bookingServicesDetail);

  const inventoryData = [
    {
      value: offerAmount?.toLocaleString(),
      label: "Dealer Offer Amount",
    },
    {
      value: inventoriable?.make?.title,
      label: "Vehicle Make",
    },
    {
      value: inventory?.city?.name,
      label: "Representation Location",
    },
    {
      value: bidder?.name,
      label: "Dealer Name",
    },
    {
      value: inventoriable?.model?.title,
      label: "Vehicle Model",
    },
    {
      value: inventoriable?.registration_city?.name,
      label: "Registration City",
    },
    {
      value: bidder?.phone,
      label: "Dealer Ph#",
    },
    {
      value: inventoriable?.variant?.name,
      label: "Vehicle Variant",
    },
    {
      value: inventoriable?.mileage,
      label: "Mileage",
    },
    // {
    //   value: offer?.final_amount,
    //   label: "Final Amount",
    // },
  ];

  useImperativeHandle(ref, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  const formInitialValues = services => {
    if (!services) return {};
    return services.reduce(
      (prev, curr) => {
        return {
          ...prev,
          [`${curr.service_type}-${curr.id}`]: {
            ...curr,
            bookingServiceAmount: Number(curr.price),
          },
        };
      },
      {
        finalAmount: offerAmount,
      }
    );
  };

  const onSubmit = async (values, actions) => {
    setLoading(true);
    const payload = {
      offer: {
        won: true,
        booking_expenses_attributes: getBookingExpensesAttributes(values, inventory?.id),
        final_amount: Number(values?.finalAmount),
      },
    };
    const response = await markAuctionOfferAsWinner(offerId, payload);
    if (response.success) {
      fetchOffers(`?q[auction_id_eq]=${auctionCycle}`);
      Toast.success("Offer Successfully Marked As Winner");
    } else {
      Toast.error(getErrorString(response));
    }
    setLoading(false);
    setOffer({ ...defaultState });
    setOpenModal(false);
  };

  const validationSchema = (services = []) => {
    return yup.object().shape(
      services.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [`${curr.service_type}-${curr.id}`]: yup.object().shape({
              price: yup.number().required(strings.enter_price_message).min(0, "Price cannot be less than 0"),
            }),
          };
        },
        {
          finalAmount: yup.number().required(strings.enter_price_message).min(0, "Price cannot be less than 0"),
        }
      )
    );
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={formInitialValues(bookingServicesDetail?.bookingServices)}
      validationSchema={validationSchema(bookingServicesDetail.bookingServices)}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {formikProps => {
        return (
          <>
            <div className="inventory-list">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="mb-0 text-uppercase f-w-700">Inventory Info</h6>
              </div>
              <div className="inventory-grid">
                {inventoryData.map(item => {
                  return <InventoryDisplay key={item.label} value={item.value} label={item.label} />;
                })}
              </div>
            </div>
            <form className="form theme-form">
              <ServiceType services={formattedServices} formikProps={formikProps} />
              <div>
                <div className="mb-3">
                  <strong>Final Amount</strong>
                </div>
                <div className="row">
                  <div className="col-6">
                    <FormInput
                      placeholder={strings.enter_amount}
                      value={formikProps?.values?.finalAmount}
                      onBlur={formikProps?.handleBlur("finalAmount")}
                      errorMsg={
                        formikProps?.errors?.["finalAmount"] &&
                        formikProps?.touched?.["finalAmount"] &&
                        formikProps?.errors?.["finalAmount"]
                      }
                      onChange={e => {
                        formikProps.setFieldValue("finalAmount", e.target.value);
                      }}
                      isEditable={true}
                      type={"number"}
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        );
      }}
    </Formik>
  );
});

const getBookingExpensesAttributes = (values, inventoryId) => {
  let bookingExpensesAttributes = Object.values(values)
    .filter(service => service.is_active)
    .map(service => {
      return {
        inventory_id: inventoryId,
        booking_service_id: service.id,
        price: Number(service.price),
        booking_service_amount: Number(service.bookingServiceAmount),
        service_type: service.service_type,
        name: service.name,
      };
    });
  return bookingExpensesAttributes;
};

const getFormattedServices = bookingServicesDetail => {
  let { bookingServices, serviceTypes } = bookingServicesDetail;
  let servicesMap = new Map();
  serviceTypes &&
    serviceTypes.forEach(serviceType => {
      let serviceTypeSlug = serviceType.slug;
      let metaData = { ...serviceType };
      metaData.toggle = serviceTypeSlug !== "fixed";
      servicesMap.set(serviceTypeSlug, {
        metaData,
        services: [
          ...bookingServices
            .filter(bookingService => bookingService.service_type === serviceTypeSlug)
            .map(bookingService => ({
              ...bookingService,
              bookingServiceAmount: Number(bookingService.price),
              formikKey: `${bookingService.service_type}-${bookingService.id}`,
            })),
        ],
      });
    });
  return servicesMap;
};

const ServiceType = props => {
  const { services, formikProps } = props;
  return (
    <>
      {[...services].map(entry => {
        let [key, serviceType] = entry;
        return !!serviceType?.services?.length ? (
          <div key={key}>
            <div className="mb-3">
              <strong>{serviceType?.metaData?.name}</strong>
            </div>
            <div className="row">
              {serviceType?.services.map(service => {
                return (
                  <Service
                    key={service.id}
                    formikProps={formikProps}
                    service={service}
                    metaData={serviceType?.metaData}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          ""
        );
      })}
    </>
  );
};

const Service = props => {
  const { service, formikProps, metaData } = props;
  const formikKey = service.formikKey;
  const value = formikProps?.values?.[formikKey];
  const label = service.name;
  const { values, errors, touched, setFieldValue, handleBlur } = formikProps;
  const isActive = value?.is_active;
  return (
    <div className="col-6">
      <div className={"d-flex align-items-center justify-content-between mb-2"}>
        <div>{label}</div>
        {metaData?.toggle && (
          <div>
            <Switch
              colorScheme={"primary"}
              outline={0}
              isChecked={isActive}
              onChange={() => {
                setFieldValue(formikKey, { ...value, is_active: !isActive });
              }}
              className="mb-0"
            />
          </div>
        )}
      </div>
      <FormInput
        placeholder={strings.enter_amount}
        value={values?.[formikKey]?.price}
        onBlur={handleBlur(formikKey)}
        errorMsg={errors?.[formikKey] && touched?.[formikKey] && errors?.[formikKey]?.price}
        onChange={e => {
          setFieldValue(formikKey, { ...value, price: e.target.value });
        }}
        isEditable={true}
        type={"number"}
      />
    </div>
  );
};

const InventoryDisplay = props => {
  const { value, label } = props;

  return (
    <>
      {!isFieldRestricted(value) && (
        <div className="inventory-grid-item">
          <small>{label}</small>
          {value ? value : "-"}
        </div>
      )}
    </>
  );
};

export default MarkAsWinnerForm;
