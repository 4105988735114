import { CRM_CHEVRON_FILTER_KEYS } from "constant/appConstants";
import { mapQueryStringToFilterObject, appendParamsToQueryString, getQueryString } from "../Util";

export const checkAllFiltersApplied = filtersObject => {
  const mandatoryKeys = [
    CRM_CHEVRON_FILTER_KEYS.CATEGORY_TYPE_KEY,
    CRM_CHEVRON_FILTER_KEYS.APPOINTMENT_TIME_START_KEY.CHEVRON_DISPOSITION_KEY,
  ];
  const optionalKeys = [
    CRM_CHEVRON_FILTER_KEYS.APPOINTMENT_CITY_ALL_KEY.CHEVRON_DISPOSITION_KEY,
    CRM_CHEVRON_FILTER_KEYS.APPOINTMENT_AREA_KEY.CHEVRON_DISPOSITION_KEY,
  ];

  const mandatoryKeysPresent = mandatoryKeys.every(key => filtersObject?.[key]);
  const optionalKeysPresent = optionalKeys.some(key => filtersObject?.[key]);

  return mandatoryKeysPresent && optionalKeysPresent;
};

export const getChevronUsersQueryString = history => {
  const { queryObj } = mapQueryStringToFilterObject(history.location.search);
  let updatedQueryObj = {};
  Object.keys(CRM_CHEVRON_FILTER_KEYS).forEach(filterKey => {
    const dispositionKey = CRM_CHEVRON_FILTER_KEYS[filterKey]?.CHEVRON_DISPOSITION_KEY;
    const usersKey = CRM_CHEVRON_FILTER_KEYS[filterKey]?.CHEVRON_USERS_KEY;
    if (usersKey && queryObj?.[dispositionKey]) {
      updatedQueryObj[usersKey] = queryObj?.[dispositionKey];
    }
  });
  return getQueryString(updatedQueryObj);
};

export const getChevronDispositionQueryString = (history, extraFiltersConfig = []) => {
  let dispositionKey = history.location.search;
  extraFiltersConfig.forEach(extraFilter => {
    if (extraFilter) {
      dispositionKey = appendParamsToQueryString(extraFilter.keySlug, extraFilter.value, dispositionKey);
    }
  });
  return dispositionKey;
};
