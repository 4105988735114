import { AsyncSelect, DynamicFormField, SelectField, SelectionListTypeHead } from "../common";
import React, { useEffect, useRef, useState } from "react";

import { SelectListModal } from "../crm";
import { fetchLocationsApi } from "../../helpers/ApiHelper";
import {
  getErrorString,
  isFieldEditable,
  isFieldViewable,
  isObjRestricted,
  isEmptyArray,
  capitalize,
  formatValuesForAsyncSelect,
} from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { smoothScrollError } from "../../helpers/functions";

const LocationCard = ({
  values,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  crmLead,
  carId,
  inventoryTypes,
  isSubmitting,
  inventoryPermittedAttributes = null,
  horizontal = true,
}) => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const refObject = {
    locationRef: useRef(),
    inventoryType: useRef(),
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    smoothScrollError(errors, refObject, isSubmitting);
  });

  const fetchData = async () => {
    const response = await fetchLocationsApi("?limit=1000");
    setLoading(true);
    if (response) {
      setLoading(false);
      if (response.success && response.locations) {
        let locations = response.locations.map(location => ({
          ...location,
          address: `${location.address} (${capitalize(location.location_type)})`,
        }));
        setLocations(locations);
        // formRef && formRef.current.setValues({ roles: response.user.roles });
      } else {
        setError(getErrorString(response));
      }
    }
  };
  return (
    <div id={strings.inventory_info} className="card">
      <div className="card-header">
        <h5>{strings.inventory_info}</h5>
      </div>
      <div className="card-body grid" style={{ "--template": "repeat(2, 1fr)", "--gap": "12px 16px" }}>
        {!isObjRestricted(values.inventoryType, ["name"]) &&
          isFieldViewable(inventoryPermittedAttributes?.["inventory.inventory_type.name"]) && (
            <SelectField
              label={strings.inventory_type}
              placeholder={strings.inventory_type_ph}
              textRight
              horizontal={horizontal}
              options={inventoryTypes || []}
              onBlur={handleBlur("inventoryType")}
              errorMsg={errors.inventoryType && touched.inventoryType && errors.inventoryType}
              onChange={selected => {
                if (isEmptyArray(selected)) {
                  setFieldValue("inventoryType", null);
                } else {
                  setFieldValue("inventoryType", selected);
                }
              }}
              value={values.inventoryType ? [values.inventoryType] : []}
              isSearchable
              isEditable={isFieldEditable(inventoryPermittedAttributes?.["inventory.inventory_type.name"])}
              clearButton
              isClearable={true}
              formSelectRef={refObject["inventoryType"]}
              id={"inventoryType"}
            />
          )}
        {isFieldViewable(inventoryPermittedAttributes?.["inventory.location.name"]) && (
          <AsyncSelect
            key={""}
            isDisabled={false}
            extraParams={""}
            clearButton
            isClearable={true}
            label={strings.location}
            listProperty={"locations"}
            placeholder={strings.select_location}
            selected={formatValuesForAsyncSelect(values, "location")}
            listApi={fetchLocationsApi}
            isMulti={false}
            queryKey={"s[address]"}
            onChange={selected => {
              setFieldValue("location", selected);
            }}
            errorMsg={errors.location && touched.location && errors.location}
            isEditable={isFieldEditable(inventoryPermittedAttributes?.["inventory.location.name"])}
            horizontal={horizontal}
            id={"location"}
          />
        )}

        {/* {!carId && ( */}
        {isFieldViewable(inventoryPermittedAttributes?.["inventory.crm_lead.id"]) && (
          <SelectListModal
            title={strings.associated_lead}
            currentValue={values.crmLead}
            onSelect={item => setFieldValue("crmLead", item)}
            onDeselect={() => setFieldValue("crmLead", null)}
            type="lead"
            errorMsg={errors.crmLead}
            textRight
            editable={!crmLead}
            canAddLead
            isEditable={isFieldEditable(inventoryPermittedAttributes?.["inventory.crm_lead.id"])}
            horizontal={false}
            removeLostLead
            id={"crmLead"}
          />
        )}
        {/* )} */}
      </div>
    </div>
  );
};
export default LocationCard;
