import {
  CAR_CONNECTS_UPADTE,
  CLEAR_CAR_DETAILS,
  REMOVE_CAR_DOCUMENT,
  SET_CAR_DETAIL,
  SET_CAR_DETAIL_COMMENTS,
  UPDATE_CAR_DOCUMENTS,
  UPDATE_CAR_DOCUMENT_LABEL,
  UPDATE_RECOMMENDED_PRICE,
  UPDATE_INVENTORY_STATUS,
  UPDATE_CAR_TAGS,
  UPDATE_CAR_DETAIL,
  SET_CAR_DETAIL_DYNAMIC_ATTRIBUTES,
} from "../../constant/actionTypes";
import { store } from "../../store";
import { clone } from "../../helpers/Util";

export const updateInventoryStatus = payload => dispatch => {
  let state = store.getState().CarDetailData;
  let newState = clone({
    ...state,
    car: {
      ...state.car,
      inventory: {
        ...state.car.inventory,
        status: { ...payload?.status },
        disposition: { ...payload?.disposition },
        can_add_in_auction: payload.canAddInAuction,
        can_be_edited: payload.canBeEdited,
        can_be_removed: payload.canBeRemoved,
      },
      possibleStatuses: [...payload.possibleStatuses],
    },
  });
  dispatch({
    type: UPDATE_INVENTORY_STATUS,
    payload: {
      ...newState,
    },
  });
};

export const setCarDetailComments = comments => dispatch => {
  dispatch({
    type: SET_CAR_DETAIL_COMMENTS,
    payload: {
      comments,
    },
  });
};

export const setCarDetail = item => dispatch => {
  dispatch({
    type: SET_CAR_DETAIL,
    payload: {
      car: { ...item.car, possibleStatuses: item?.possible_statuses || [], connects: item?.car?.connects || [] },
      documentLabels: item.document_labels,
      // dynamicFields: item.dynamic_fields,
    },
  });
};

export const setCarDetailDynamicFields = item => dispatch => {
  dispatch({
    type: SET_CAR_DETAIL_DYNAMIC_ATTRIBUTES,
    payload: {
      dynamicFields: item,
    },
  });
};

export const updateCarDetail = item => dispatch => {
  dispatch({
    type: UPDATE_CAR_DETAIL,
    payload: {
      car: item,
    },
  });
};

export const updateRecommendedPrice = item => dispatch => {
  dispatch({
    type: UPDATE_RECOMMENDED_PRICE,
    payload: {
      price: { ...item },
    },
  });
};

export const clearCarDetail = () => dispatch => {
  dispatch({
    type: CLEAR_CAR_DETAILS,
  });
};

export const carConnectsUpdate = item => async dispatch => {
  dispatch({
    type: CAR_CONNECTS_UPADTE,
    payload: {
      connects: item,
    },
  });
};

export const updateCarDocuments = data => async dispatch => {
  dispatch({
    type: UPDATE_CAR_DOCUMENTS,
    payload: {
      documents: data,
    },
  });
};

export const removeCarDocuments = data => async dispatch => {
  dispatch({
    type: REMOVE_CAR_DOCUMENT,
    payload: {
      document: data,
    },
  });
};

export const updateLabel = data => async dispatch => {
  dispatch({
    type: UPDATE_CAR_DOCUMENT_LABEL,
    payload: {
      ...data,
    },
  });
};

export const updateTags = data => dispatch => {
  dispatch({
    type: UPDATE_CAR_TAGS,
    payload: [...data],
  });
};
