import React from "react";
import Button from "./Button";
import { Eye } from "react-feather";
import cx from "clsx";
import styles from "./ShowPhoneButton.module.scss";

function ShowPhoneButton({ ctaText = "Show Phone Number", textStyle = {}, onBtnClick = null, ...rest }) {
  return (
    <Button
      iconPlacement="right"
      icon={<Eye size={14} />}
      btnText={ctaText}
      color="link"
      className={cx(styles.txtColor, "p-0")}
      style={{ fontSize: "12px", ...textStyle }}
      onClick={() => {
        onBtnClick && onBtnClick();
      }}
      {...rest}
    />
  );
}

export default ShowPhoneButton;
