import React from "react";
import { IF } from ".";
import { MdCancel, MdClose } from "../svg-icon/svg-icons";

import cx from "clsx";

const Chip = ({
  title,
  onClose,
  canClose = true,
  onClick,
  chipBgColor = "#EFEFEF",
  chipIconColor = "#6e7782",
  chipIconBgColor = "transparent",
  titleContainerClassName = "",
  containerClassName = "",
  simpleView,
  chipClassName,
  chipBorderRadius,
  icon = <MdCancel size={22} />,
  chipOnly,
}) =>
  chipOnly ? (
    <span className="chip__filter-row">
      <span className={cx("chip", "m-0", chipClassName)} style={{ "--chip-radius": chipBorderRadius }}>
        <span
          className={cx("chip-value font-weight-normal", titleContainerClassName)}
          onClick={onClick}
          style={{ paddingRight: 10 }}
        >
          {title}
        </span>
      </span>
    </span>
  ) : (
    <span
      className={cx(simpleView ? "chip-simple" : "chip__filter-row m-t-0 ", containerClassName)}
      style={{
        "--chip-bg-color": chipBgColor,
        "--chip-icon-color": chipIconColor,
        "--chip-icon-bg-color": chipIconBgColor,
      }}
    >
      <span className={cx("chip p-r-5", chipClassName)} style={{ "--chip-radius": chipBorderRadius }}>
        <span
          className={cx("chip-value font-weight-normal", titleContainerClassName)}
          onClick={onClick}
          style={{ paddingRight: !canClose ? 10 : 30 }}
        >
          {title}
        </span>
        <IF condition={canClose}>
          {simpleView ? (
            <button type="button" className="btn btn-link p-0 btn-text-danger" onClick={onClose}>
              <MdClose />
            </button>
          ) : (
            <button type="button" className="chip-delete-button" onClick={onClose}>
              {icon}
            </button>
          )}
        </IF>
      </span>
    </span>
  );

export default Chip;
