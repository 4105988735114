import { FETCH_CRM_REMINDER, UPDATE_CRM_REMINDER, SET_CRM_REMINDERS_LOADING } from "../../constant/actionTypes";
import { fetchCrmTaskRemindersList } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

export const fetchCRMTaskReminders = queryString => async dispatch => {
  dispatch({ type: SET_CRM_REMINDERS_LOADING, payload: { loading: true } });
  const res = await fetchCrmTaskRemindersList(queryString);
  if (res.success) {
    const { reminders, statuses, pagination, permitted_meta, ...rest } = res;
    if (reminders) {
      dispatch({
        type: FETCH_CRM_REMINDER,
        payload: {
          remindersList: reminders,
          statuses,
          pagination,
          permittedMeta: permitted_meta,

          loading: false,
          error: "",
        },
      });
    } else {
      dispatch({
        type: FETCH_CRM_REMINDER,
        payload: {
          remindersList: [],
          pagination: {},
          permittedMeta: {},
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  }
};

export const updateCrmTaskReminders = reminder => dispatch => {
  dispatch({
    type: UPDATE_CRM_REMINDER,
    payload: {
      reminder,
    },
  });
};
