import { lazy } from "react";

export { default as SignIn } from "./onBoarding/SignIn";
export { default as ImpersonatedSignin } from "./onBoarding/ImpersonatedSignin";
export { default as ResetPassword } from "./onBoarding/ResetPassword";
export { default as BulkQRCode } from "./crm/BulkQRCode";
export { default as PrivacyPolicy } from "./public-pages/PrivacyPolicy";

export { default as Terms } from "./public-pages/Terms";

// Crm
export const CrmDashboard = lazy(() => import(/* webpackChunkName: "CrmDashboard" */ "./crm/CrmDashboard"));

export const CrmDashboardReports = lazy(() =>
  import(/* webpackChunkName: "CrmDashboardReports" */ "./crm/CrmDashboardReports")
);
export const CrmDashboardReportWidgets = lazy(() =>
  import(/* webpackChunkName: "CrmDashboardReportWidgets" */ "./crm/CrmDashboardReportWidgets")
);

{
  /* /crm/crm_leads Component */
}
export const CrmLeads = lazy(() => import(/* webpackChunkName: "CrmLeads" */ "./crm/CrmLeads"));
export const CrmSaveLead = lazy(() => import(/* webpackChunkName: "CrmSaveLead" */ "./crm/CrmSaveLead.js"));
export const CrmSaveQuotation = lazy(() => import(/* webpackChunkName: "CrmSaveLead" */ "./crm/CrmSaveQuotation.tsx"));
export const CrmLeadDetail = lazy(() => import(/* webpackChunkName: "CrmLeadDetail" */ "./crm/CrmLeadDetail"));
export const CrmTasks = lazy(() => import(/* webpackChunkName: "CrmTasks" */ "./crm/CrmTasks"));
// /crm/clients Component
export const CrmClients = lazy(() => import(/* webpackChunkName: "CrmClients" */ "./crm/CrmClients"));
{
  /* /crm/payments Component */
}
export const CrmPayments = lazy(() => import(/* webpackChunkName: "CrmPayments" */ "./crm/CrmPayments"));
export const CrmSavePayment = lazy(() => import(/* webpackChunkName: "CrmSavePayment" */ "./crm/CrmSavePayment"));
{
  /* /crm/calendar Component */
}
export const CrmCalendar = lazy(() => import(/* webpackChunkName: "EventCalendar" */ "./crm/CrmCalendar"));
export const CrmLocations = lazy(() => import(/* webpackChunkName: "CrmLocations" */ "./crm/CrmLocations"));
export const CrmSaveLocation = lazy(() => import(/* webpackChunkName: "CrmSaveLocation" */ "./crm/CrmSaveLocation"));
export const CrmTaskTypes = lazy(() => import(/* webpackChunkName: "CrmTaskTypes" */ "./crm/CrmTaskTypes"));
export const CrmApprovals = lazy(() => import(/* webpackChunkName: "CrmApprovals" */ "./crm/CrmApprovals"));
export const CrmAddApproval = lazy(() => import(/* webpackChunkName: "CrmAddApproval" */ "./crm/CrmAddApproval"));
export const CrmClientDetail = lazy(() => import(/* webpackChunkName: "CrmClientDetail" */ "./crm/CrmClientDetail"));
export const CrmClientEditDetail = lazy(() =>
  import(/* webpackChunkName: "CrmClientEditDetail" */ "./crm/CrmClientEditDetail")
);
export const CrmStockCount = lazy(() => import(/* webpackChunkName: "CrmStockCount" */ "./crm/CrmStockCount"));
export const CrmStockCountDetail = lazy(() =>
  import(/* webpackChunkName: "CrmStockCountDetail" */ "./crm/CrmStockCountDetail")
);
export const CrmReminders = lazy(() => import(/* webpackChunkName: "CrmReminders" */ "./crm/CrmReminders"));
export const CrmRotaPlan = lazy(() => import(/* webpackChunkName: "CrmReminders" */ "./crm/CrmRotaPlan"));

export const CrmCiCo = lazy(() => import(/* webpackChunkName: "CrmCiCo" */ "./crm/CrmCiCo"));
export const CrmSaveCico = lazy(() => import(/* webpackChunkName: "CrmSaveCico" */ "./crm/CrmSaveCico"));
export const CrmCicoDetail = lazy(() => import(/* webpackChunkName: "CrmCiCoDetail" */ "./crm/CrmCiCoDetail"));

export const CrmCalls = lazy(() => import(/* webpackChunkName: "CrmCiCo" */ "./crm/CrmCalls"));
export const CrmFieldMapper = lazy(() => import(/* webpackChunkName: "CrmFieldMapper" */ "./crm/CrmFieldMapper"));
export const CrmApprovalAdmin = lazy(() => import(/* webpackChunkName: "CrmFieldMapper" */ "./crm/CrmApprovalAdmin"));
export const CrmApprovalDetailPage = lazy(() =>
  import(/* webpackChunkName: "CrmFieldMapper" */ "./crm/CrmApprovalDetailPage")
);
export const CrmItemList = lazy(() => import(/* webpackChunkName: "CrmItemList" */ "./crm/CrmItemLists"));

export const CrmSaveItemList = lazy(() => import(/* webpackChunkName: "CrmSaveItemList" */ "./crm/CrmSaveItemList"));

export const CrmApplyLeave = lazy(() => import(/* webpackChunkName: "CrmApplyLeave" */ "./crm/CrmApplyLeave"));

// Contracts
export const CrmContracts = lazy(() => import(/* webpackChunkName: "CrmContracts" */ "./crm/CrmContracts"));
export const CrmSaveContract = lazy(() => import(/* webpackChunkName: "CrmSaveContract" */ "./crm/CrmSaveContract"));
export const CrmContractDetail = lazy(() =>
  import(/* webpackChunkName: "CrmSaveContract" */ "./crm/CrmContractDetail")
);
export const CRMRetailPriceReduction = lazy(() =>
  import(/* webpackChunkName: "CrmAuctionMarginSlabs" */ "./crm/CrmRetailPriceReduction.js")
);
export const CRMWhatsappTriggerController = lazy(() =>
  import(/* webpackChunkName: "CrmAuctionMarginSlabs" */ "./admin/WhatsappTriggerController.js")
);

// Inspection
export const InspectionDashboard = lazy(() =>
  import(/* webpackChunkName: "InspectionDashboard" */ "./inspection/InspectionDashboard")
);
export const InspectionLeads = lazy(() =>
  import(/* webpackChunkName: "InspectionLeads" */ "./inspection/InspectionLeads")
);
export const InspectionSaveLead = lazy(() =>
  import(/* webpackChunkName: "InspectionSaveLead" */ "./inspection/InspectionSaveLead")
);
export const InspectionLeadDetail = lazy(() =>
  import(/* webpackChunkName: "InspectionLeadDetail" */ "./inspection/InspectionLeadDetail")
);
export const InspectionPayments = lazy(() =>
  import(/* webpackChunkName: "InspectionPayments" */ "./inspection/InspectionPayments")
);
export const InspectionDeposits = lazy(() =>
  import(/* webpackChunkName: "InspectionDeposits" */ "./inspection/InspectionDeposits")
);
export const InspectionReports = lazy(() =>
  import(/* webpackChunkName: "InspectionReports" */ "./inspection/InspectionReports")
);
export const InspectionSchedules = lazy(() =>
  import(/* webpackChunkName: "InspectionSchedules" */ "./inspection/InspectionSchedules")
);
export const InspectionEmailTemplates = lazy(() =>
  import(/* webpackChunkName: "InspectionEmailTemplates" */ "./inspection/InspectionEmailTemplates")
);
export const InspectionSaveEmailTemplate = lazy(() =>
  import(/* webpackChunkName: "InspectionSaveEmailTemplate" */ "./inspection/InspectionSaveEmailTemplate")
);
export const InspectionLeadTypes = lazy(() =>
  import(/* webpackChunkName: "InspectionLeadTypes" */ "./inspection/InspectionLeadTypes")
);
export const InspectionSaveLeadType = lazy(() =>
  import(/* webpackChunkName: "InspectionSaveLeadType" */ "./inspection/InspectionSaveLeadType")
);
export const InspectionSmsTemplates = lazy(() =>
  import(/* webpackChunkName: "InspectionSmsTemplates" */ "./inspection/InspectionSmsTemplates")
);
export const InspectionSaveSmsTemplate = lazy(() =>
  import(/* webpackChunkName: "InspectionSaveSmsTemplate" */ "./inspection/InspectionSaveSmsTemplate")
);
export const InspectionTeams = lazy(() =>
  import(/* webpackChunkName: "InspectionTeams" */ "./inspection/InspectionTeams")
);

// Common
export const Inventories = lazy(() => import(/* webpackChunkName: "Inventories" */ "./common/Inventories"));
export const SaleStockInventories = lazy(() =>
  import(/* webpackChunkName: "SaleStockInventories" */ "./common/SaleStockInventories")
);
export const SaveInventory = lazy(() => import(/* webpackChunkName: "SaveInventory" */ "./common/SaveInventory"));
export const InventoryDetail = lazy(() => import(/* webpackChunkName: "InventoryDetail" */ "./common/InventoryDetail"));
export const CrmInventoryAddPost = lazy(() =>
  import(/* webpackChunkName: "CrmInventoryAddPost"*/ "./common/CrmInventoryAddPost")
);
export const CrmInventoryAdPostDetail = lazy(() =>
  import(/* webpackChunkName: "CrmInventoryAdPostDetail"*/ "./common/CrmInventoryAdPostDetail")
);

export const Users = lazy(() => import(/* webpackChunkName: "Users" */ "./common/Users"));
export const SaveUserConfiguration = lazy(() =>
  import(/* webpackChunkName: "SaveUserConfiguration" */ "./common/SaveUserConfiguration")
);
export const CRMAutomateBranchCapacity = lazy(() =>
  import(/* webpackChunkName: "CrmInventoryAdPostDetail"*/ "./crm/CrmAutomateBranchCapacity")
);
export const Roles = lazy(() => import(/* webpackChunkName: "Roles" */ "./common/Roles"));
export const SaveRole = lazy(() => import(/* webpackChunkName: "SaveRole" */ "./common/SaveRole/index"));
export const AuthenticationTokens = lazy(() =>
  import(/* webpackChunkName: "AuthenticationTokens" */ "./common/AuthenticationTokens")
);
export const UserSettingsPage = lazy(() =>
  import(/* webpackChunkName: "UserSettingsPage" */ "./onBoarding/UserSettings")
);

export const SaveAuthenticationToken = lazy(() =>
  import(/* webpackChunkName: "SaveAuthenticationToken" */ "./common/SaveAuthenticationToken")
);
export const LeadSources = lazy(() => import(/* webpackChunkName: "LeadSources" */ "./common/LeadSources"));
export const SaveLeadSource = lazy(() => import(/* webpackChunkName: "SaveLeadSource" */ "./common/SaveLeadSource"));
export const SaveTenant = lazy(() => import(/* webpackChunkName: "SaveTenant" */ "./common/SaveTenant"));
export const DynamicModels = lazy(() => import(/* webpackChunkName: "DynamicModels" */ "./common/DynamicModels"));
export const DynamicFields = lazy(() => import(/* webpackChunkName: "DynamicFields" */ "./common/DynamicFields"));

// Admin
export const Groups = lazy(() => import(/*webpackChunkName: "Groups"*/ "./admin/Groups"));

// Auction
export const AuctionDashboard = lazy(() =>
  import(/* webpackChunkName: "AuctionDashboard"*/ "./auction/AuctionDashboard")
);
export const AuctionTypes = lazy(() => import(/*webpackChunkName: "AuctionTypes" */ "./admin/AuctionTypes"));
export const AuctionUsers = lazy(() => import(/*webpackChunkName: "AuctionUsers" */ "./auction/AuctionUsers"));
export const Auctions = lazy(() => import(/*webpackChunkName: "Auctions" */ "./auction/Auctions"));
export const Offers = lazy(() => import(/*webpackChunkName: "Offers" */ "./auction/Offers"));
export const AuctionUsersDetail = lazy(() =>
  import(/* webpackChunkName: "AuctionUsersDetail" */ "./auction/AuctionUsersDetail")
);
export const AuctionBookings = lazy(() =>
  import(/* webpackChunkName: "AuctionUsersBookings" */ "./auction/AuctionBookings")
);

export const AuctionMarginSlabs = lazy(() =>
  import(/* webpackChunkName: "CrmAuctionMarginSlabs" */ "./auction/AuctionMarginSlabs.js")
);

export const CallCenterSellerReport = lazy(() =>
  import(/* webpackChunkName: "CallCenterSeller" */ "../features/reports/CallCenterSeller")
);

export const CallCenterBuyerReport = lazy(() =>
  import(/* webpackChunkName: "CallCenterSeller" */ "../features/reports/CallCenterBuyer")
);

export const CrmDuplicationSearch = lazy(() =>
  import(/* webpackChunkName: "CrmDuplicationSearch" */ "./crm/CrmDuplicationSearch/CrmDuplicationSearch")
);

// export const BulkQRCode = lazy(() => import(/*webpackChunkName: "Offers" */ "./crm/BulkQRCode"));
