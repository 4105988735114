import React, { useEffect } from "react";
import { uploadFile } from "../crm-leads/UploadAttachmentsHelpers";
import Close from "../common/Close";
import { Button } from "../common";

const ImageItem = props => {
  const { uploadImage, item, removeImage } = props;
  const { uploaded, uploading, inError } = item;
  useEffect(() => {
    uploadFile(
      props.item,
      props.attachmentType,
      props.associationKey,
      props.files,
      props.setFiles,
      props.makeBlob,
      props.isSvg,
      undefined
    );
  }, []);

  return (
    <div className="hover-pointer img-upload-wrapper d-flex flex-row align-items-center justify-content-between spbwx8 hover-bg">
      {uploading === true && <span className="fa fa-refresh fa-spin text-primary"></span>}

      {inError && <Button color="link" className="p-0" onClick={props.onRetry} btnText="Retry" />}

      {!uploading && (
        <Close
          className="p-absolute"
          type="button"
          onClick={e => {
            e.preventDefault();
            removeImage(props.item);
          }}
        />
      )}

      {!!uploaded && <div className="m-b-0 path-name"> {item?.file?.name || item?.file_name}</div>}
    </div>
  );
};

export default ImageItem;
