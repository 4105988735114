import * as yup from "yup";

import { CheckboxComponent, CustomAlert, FormInput } from "../common";
import React, { forwardRef, useImperativeHandle, useRef } from "react";

import { Formik } from "formik";
import { connect } from "react-redux";
import { getErrorString } from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { updatePriceRecommendationApi } from "../../helpers/ApiHelper";
import { updateRecommendedPrice } from "../../actions/general/carDetail.action";

const RecommendedPriceForm = props => {
  const { fRef, toggleLoading, inventoryId, setRecommendedPriceModal, updateRecommendedPrice, priceInfo } = props;

  let formikRef = useRef();

  useImperativeHandle(fRef, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  const getInitialValues = () => {
    return {
      listingPrice: priceInfo ? priceInfo.listing_price : "",
      finalPrice: priceInfo ? priceInfo.final_price : "",
      reason: priceInfo ? priceInfo.reason : "",
      sendEmail: priceInfo ? priceInfo.email_sent : false,
    };
  };

  const onSubmit = async ({ listingPrice, finalPrice, reason, sendEmail }, { setStatus, setFieldError }) => {
    setStatus({ message: "" });

    let obj = {
      price_recommendation: {
        listing_price: listingPrice,
        final_price: finalPrice,
        reason,
        email_sent: sendEmail,
        inventory_id: inventoryId,
      },
    };

    toggleLoading(true);

    const response = inventoryId ? await updatePriceRecommendationApi(inventoryId, obj) : null;
    if (response) {
      toggleLoading(false);

      if (response.price_recommendation) {
        updateRecommendedPrice(response.price_recommendation);
        setRecommendedPriceModal(false);
      } else {
        setStatus({
          message: getErrorString(response),
        });
      }
    } else {
      toggleLoading(false);
      setRecommendedPriceModal(false);
    }
  };

  const validationSchema = () =>
    yup.object().shape({
      finalPrice: yup.number().typeError("you must specify a number").min(0, "Min value 0."),
      listingPrice: yup.number().typeError("you must specify a number").min(0, "Min value 0."),
    });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, status, setFieldValue }) => (
        <form className="form theme-form db__form-main">
          <FormInput
            label={strings.final_price}
            placeholder={strings.enter_final_price}
            onChange={handleChange("finalPrice")}
            onBlur={handleBlur("finalPrice")}
            value={values.finalPrice || ""}
            errorMsg={errors.finalPrice && touched.finalPrice && errors.finalPrice}
            labelClassName="p-t-0"
          />
          <FormInput
            label={strings.listing_price}
            placeholder={strings.enter_listing_price}
            onChange={handleChange("listingPrice")}
            onBlur={handleBlur("listingPrice")}
            value={values.listingPrice || ""}
            errorMsg={errors.listingPrice && touched.listingPrice && errors.listingPrice}
            required
            labelClassName="p-t-0"
          />
          <FormInput
            label={strings.reason}
            placeholder={strings.reason_ph}
            onChange={handleChange("reason")}
            onBlur={handleBlur("reason")}
            value={values.reason || ""}
            errorMsg={errors.reason && touched.reason && errors.reason}
            linesCount="6"
            required
            labelClassName="p-t-0"
          />

          <div className="col-sm-9">
            <CheckboxComponent
              label={strings.send_email}
              checked={values.sendEmail}
              onChange={event => {
                setFieldValue("sendEmail", event.target.checked);
              }}
              horizontal
            />
          </div>

          {status && status.message && <CustomAlert message={status.message} />}
        </form>
      )}
    </Formik>
  );
};

const ConnectedComponent = connect(() => ({}), { updateRecommendedPrice })(RecommendedPriceForm);

export default forwardRef((props, ref) => <ConnectedComponent {...props} fRef={ref} />);
