import React from "react";
import CustomTabs from "../../../components/common/CustomTabs";
import Questionnaire from "./Questionnaire";
import { AlertCircle } from "react-feather";
import { IF, Tooltip } from "../../../components/common";

const QCTabs = props => {
  const { formikProps, disableFields } = props;
  const { values, errors } = formikProps;

  const TabNameSection = props => {
    const { sectionName, questions = [], sectionId } = props;

    const sectionErrorsCount = questions.reduce((acc, curr) => {
      if (curr?.formikKey in errors) {
        acc++;
      }
      return acc;
    }, 0);

    return (
      <>
        <Tooltip id={`section-name-${sectionId}`}>{sectionName}</Tooltip>
        <div className="text-truncate" data-tip data-for={`section-name-${sectionId}`} style={{ maxWidth: "11ch" }}>
          {sectionName}
        </div>

        <IF condition={sectionErrorsCount}>
          <Tooltip id={`section-${sectionId}`}>There are ({sectionErrorsCount}) fields that you havent filled</Tooltip>
          <div data-tip data-for={`section-${sectionId}`}>
            <AlertCircle className="text-primary" />
          </div>
        </IF>
      </>
    );
  };

  const sections =
    Object.entries(values)
      .reduce((acc, [key, value]) => {
        const parts = key.split("_");
        const sectionId = parts[1];

        const sectionIndex = acc.findIndex(item => {
          return item?.sectionId === sectionId;
        });

        if (sectionIndex === -1) {
          acc.push({
            sectionId: sectionId,
            sectionName: value.section.name,
            questions: [value],
          });
        } else {
          acc[sectionIndex].questions.push(value);
        }

        return acc;
      }, [])
      .filter(Boolean) ?? [];

  const sectionsWithQuestionnaire = sections.map(section => {
    return {
      id: section.sectionId,
      name: <TabNameSection {...section} />,
      renderComponent: () => (
        <Questionnaire
          disableFields={disableFields}
          key={section.sectionId}
          questions={section.questions}
          formikProps={formikProps}
        />
      ),
    };
  });

  return <CustomTabs tabTitleContainerClassName="w-100" loading={false} tabsData={sectionsWithQuestionnaire} />;
};

export default QCTabs;
