import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Spin, Select } from "antd";
import { objectToQuery } from "../../helpers/Util";
import { BiCaretDown } from "react-icons/bi";
import FormRow from "./FormRow";
import { debounce } from "lodash";
import { CloseCircleOutlined } from "@ant-design/icons";
import styles from "./NestedDropdown.module.scss";

const NestedDropdown = ({
  listApi,
  extraParams,
  listProperty,
  editCase,
  fieldKey,
  label,
  errorMsg,
  horizontal,
  isMultiSelect = false,
  setFieldValue = () => {},
  placeholder,
  initialValue,
  queryKey = "s[name]",
  className = "",
  formatData,
  onChange = () => {},
}) => {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    onLoadData("");
  }, []);

  useEffect(() => {
    if (editCase && initialValue?.source) {
      setValue({ label: initialValue?.name, value: initialValue?.id });
    }
  }, [initialValue]);

  const handleScroll = e => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      onLoadData();
    }
  };

  async function onLoadData(search = null) {
    if (loading || !hasMore) return;
    setLoading(true);
    let queryObj = {};
    if (search !== null) {
      if (Array.isArray(queryKey)) {
        queryKey.forEach(key => {
          queryObj[key] = search;
        });
      } else {
        queryObj[queryKey] = search;
      }
    }
    try {
      const response = await listApi(objectToQuery({ page, ...queryObj, ...extraParams }));
      const newOptions = formatData(response[listProperty]);
      setRawData(prev => ({ ...prev, ...response[listProperty] }));
      setOptions(prev => (search || search === "" ? newOptions : [...prev, ...newOptions]));
      setPage(response?.pagination.next_page);
      setHasMore(response?.pagination.next_page !== null);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  }
  const debouncedSearch = useCallback(
    debounce(search => {
      onLoadData(search);
    }, 300),
    [listApi, extraParams]
  );

  const findChildById = id => {
    for (const group of options) {
      const child = group.options?.find(option => option.value === id);
      if (child) {
        return child;
      }
    }
    return null;
  };
  const handleChange = value => {
    const selectedChild = findChildById(value);
    setValue({ label: selectedChild?.name, value });
    if (selectedChild && setFieldValue) {
      setFieldValue(fieldKey, selectedChild);
    }
    if (onChange) {
      const selectedChildNew = rawData[selectedChild.source]?.find(it => (it.id = value));
      selectedChildNew && onChange(selectedChildNew);
    }
  };
  const onSearch = searchText => {
    setPage(1);
    setHasMore(true);
    debouncedSearch(searchText);
  };
  const clearSelection = () => {
    setValue(null);
    fieldKey && setFieldValue(fieldKey, null);
    setOptions([]);
    debouncedSearch("");
  };
  const renderSuffixIcon = (loading, value, clearSelection) => {
    if (!value) {
      return !loading ? <BiCaretDown size={14} /> : <Spin size="small" />;
    }
    return (
      <CloseCircleOutlined
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          clearSelection();
        }}
      />
    );
  };
  return (
    <>
      <Fragment>
        <FormRow horizontal={horizontal} label={label} textRight={true} shouldWrap={!!label}>
          <Select
            showSearch
            mode="single"
            loading={loading}
            onChange={handleChange}
            dropdownRender={menu => <div>{menu}</div>}
            options={options}
            style={{ width: "100%" }}
            suffixIcon={renderSuffixIcon(loading, value, clearSelection)}
            value={value ? value.label : placeholder}
            filterOption={false}
            onSearch={onSearch}
            className={className}
            onPopupScroll={handleScroll}
          />
          {!!errorMsg && <span className="invalid-error">{errorMsg}</span>}
        </FormRow>
      </Fragment>
    </>
  );
};
export default NestedDropdown;
