import React from "react";
import { Text, Tooltip } from "components/ant-common";
import { formatCompactedNumber, internationalFormatCompactedNumber, numberFormat } from "./../../../utils/index";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Numbers = props => {
  const { t } = useTranslation();
  const {
    value,
    compact = "intl",
    isCurrency: isC,
    tooltip,
    customToolTip,
    unit,
    defaultValue,
    digitAfterFraction,
    isStringOnly,
    wrapValue,
    tooltipPlacement,
    currencyUnit,
    showWholeNumber = false,
    showNumberInWordsInTooltip = false,
    ...rest
  } = props;
  const isCurrency = currencyUnit ? true : isC;
  const numberOptions = { minimumFractionDigits: 0, maximumFractionDigits: digitAfterFraction };

  const currency = "AED";

  let compactedNumber, wholeNumber;
  const number = !isNaN(Number(value)) ? Number(value) : false;

  const placeNumberUnit = numberString => {
    if (isCurrency) {
      return currency + " " + numberString;
    } else if (unit) {
      return numberString + " " + unit;
    } else return t(numberString);
  };

  if (number !== false) {
    wholeNumber = placeNumberUnit(numberFormat(number, "en-AE", { style: "currency", currency: "AED" }));
    if (compact === "local") {
      compactedNumber = placeNumberUnit(formatCompactedNumber(number, numberOptions.maximumFractionDigits));
    } else if (compact === "intl") {
      compactedNumber = placeNumberUnit(
        internationalFormatCompactedNumber(number, numberOptions.maximumFractionDigits)
      );
    }
  } else {
    return defaultValue;
  }
  const getNumber = () => wrapValue(compactedNumber ? compactedNumber : wholeNumber);
  const getText = () => (isStringOnly ? getNumber() : <Text {...rest}>{getNumber()}</Text>);

  return customToolTip ? (
    <Tooltip {...customToolTip}>
      <span>{showWholeNumber ? wholeNumber : getText()}</span>{" "}
    </Tooltip>
  ) : tooltip && (number >= 1000 || number <= -1000) ? (
    <Tooltip placement={tooltipPlacement} title={showWholeNumber ? getText() : wholeNumber}>
      <span>{showWholeNumber ? wholeNumber : getText()}</span>
    </Tooltip>
  ) : (
    getText()
  );
};

Numbers.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compact: PropTypes.oneOf(["local", "intl"]),
  isCurrency: PropTypes.bool,
  tooltip: PropTypes.bool,
  unit: PropTypes.string,
  defaultValue: PropTypes.string,
  isStringOnly: PropTypes.bool,
  digitAfterFraction: PropTypes.number,
  wrapValue: PropTypes.func,
};

Numbers.defaultProps = {
  isCurrency: false,
  tooltip: false,
  isStringOnly: false,
  defaultValue: "–",
  digitAfterFraction: 2,
  wrapValue: val => val,
  tooltipPlacement: "top",
};

export default Numbers;
