import { Formik } from "formik";
import React, { useRef } from "react";
import { Button, ButtonLoading } from "../../../components/common";
import QCTabs from "./QCTabs";
import useQCForm from "./useQCForm";
import { strings } from "../../../constant/strings";
import cx from "clsx";
import styles from "./index.module.scss";
import { QCFormSkeleton } from "./QCSkeletons";

const QCForm = props => {
  const { viewOrEditQC, callId, templateId, report, setQCSummaryData = () => {}, setQcCallData = () => {} } = props;

  const formikRef = useRef();

  const {
    hasEditQCPermission,
    hasCreateQCReportPermission,
    disableFields,
    questionnaire,
    getInitialValues,
    getValidationSchema,
    handleSubmit,
    isFormEditable,
    toggleFormEdit,
  } = useQCForm({
    viewOrEditQC,
    callId,
    formikRef,
    templateId,
    report,
    setQCSummaryData,
    setQcCallData,
  });

  const { loading, questionnaireWithSections } = questionnaire;

  if (loading) return <QCFormSkeleton />;

  return (
    <div>
      <Formik
        initialValues={getInitialValues(questionnaireWithSections ?? []) ?? {}}
        innerRef={formikRef}
        validationSchema={getValidationSchema(questionnaireWithSections ?? []) ?? {}}
        onSubmit={handleSubmit}
      >
        {formikProps => {
          const { handleSubmit, isSubmitting } = formikProps;
          return (
            <form className="form theme-form db__form-main g-span-all">
              <div className="card" style={{ minHeight: "480px" }}>
                <div className="d-flex justify-content-between align-items-center pt-4 px-5">
                  <h4 className="f-w-600 mb-0">{strings.qc_questionnaire}</h4>
                  {viewOrEditQC && hasEditQCPermission && !isFormEditable && (
                    <Button style={{ maxWidth: "120px" }} btnText={strings.edit} onClick={toggleFormEdit} />
                  )}
                </div>

                <div className={cx("card-body pt-2", styles.QATabs)}>
                  <QCTabs disableFields={disableFields} formikProps={formikProps} />
                </div>
              </div>
              {viewOrEditQC
                ? hasEditQCPermission &&
                  isFormEditable && (
                    <div className="m-b-20" style={{ textAlign: "end" }}>
                      <ButtonLoading
                        loading={isSubmitting}
                        style={{ maxWidth: "120px" }}
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        {strings.update}
                      </ButtonLoading>
                    </div>
                  )
                : hasCreateQCReportPermission && (
                    <div className="m-b-20" style={{ textAlign: "end" }}>
                      <ButtonLoading loading={isSubmitting} onClick={handleSubmit} disabled={isSubmitting}>
                        {strings.submit}
                      </ButtonLoading>
                    </div>
                  )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default QCForm;
