import cx from "clsx";
import { TreeSelectInput } from "components/ant-common";
import DateRangePicker from "components/ant-common/dateRangePicker/dateRangePicker";
import { FILTER_TYPES, RANGE_FILTER_KEYS } from "constant/appConstants";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Check } from "react-feather";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { dateTimeFunction } from "utils/date";
import { strings } from "../../../constant/strings";
import {
  addQueryFilterArray,
  addQueryFilterArrayPreSelected,
  convertQueryObjToString,
  convertQueryStringToArray,
  formatOptionsForReactSelect,
  formatPhoneNumber,
  generateChips,
  generateUUID,
  getUTCOffset,
  makeListToSelectData,
  mapQueryStringToFilterObject,
  shortenString,
} from "../../../helpers/Util";
import { selectTenant } from "../../../reducers/general/selectors";
import { FiChevronDown, FiChevronUp } from "../../svg-icon/svg-icons";
import AsyncSelect from "../AsyncSelect";
import CheckboxDropdown from "../CheckboxDropdown";
import DropdownSelect from "../DropdownSelect";
import { FilterChip } from "../FilterChip";
import NestedCheckboxDropdown from "../NestedCheckboxDropdown";
import { SearchInput } from "../SearchInput";
import SelectionListTypeHead from "../SelectionListTypeHead";
import Skeleton from "../Skeleton";
import DateFilter from "./DateFilter";
import styles from "./Filters.module.scss";
import filterStyle from "./index.module.less";
import ModalFilter from "./ModalFilter/ModalCityFilter";
import RangeFilter from "./RangeFilter";

const Filters = React.memo(props => {
  const {
    list,
    rootPath,
    fetch,
    containerClass,
    initialRender,
    country,
    loading,
    filterChipsRequired = true,
    showResetFiltersButton = true,
    canSelectCurrentDate = false,
    rootFiltersObj = null,
    dependencyArray = [],
    defaultSearchQuery = "",
    customPagePerLimit = 0,
  } = props;

  const { timezone } = country;
  const tenant = useSelector(selectTenant);
  const history = useHistory();

  const filteredList = list?.filter(e => props.filtersToDisplay?.find(i => i === e.id));
  const baseList = filteredList?.filter(e => (e.list !== undefined ? !!e.list.length : true));
  const [filtersObject, setFiltersObject] = useState({});
  const [filterChips, setFilterChips] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const [asyncFiltersSelected, setAsyncFiltersSelected] = useState({});
  const limitPerPage =
    customPagePerLimit > 0
      ? customPagePerLimit
      : (tenant?.listingRecordsPerPage && tenant?.listingRecordsPerPage[history.location.pathname]) ??
        tenant?.configuration?.records_per_page;

  const showResetFilters = () =>
    history.location.search.length > 0 &&
    history.location.search !== defaultSearchQuery &&
    showResetFiltersButton &&
    !loading;

  const createChipIdentifier = (key, value) => `${key}-${value}`;

  const renderFilterChips = async refresh => {
    const newFilterChips = [];
    if (!history.location.search) {
      setFilterChips(newFilterChips);
    }
    const { queryObj } = mapQueryStringToFilterObject(history.location.search);
    const newArray = convertQueryStringToArray(history.location.search);
    const queryArray = newArray.filter(item => !item.key.includes("sort"));

    const promises = [];

    if (queryArray.length) {
      const existingChipsMap = filterChips.length
        ? new Map(filterChips.map(chip => [createChipIdentifier(chip.searchKey, chip.searchValue), chip]))
        : null;

      for (const item of queryArray) {
        const { key, value } = item;
        const chipId = createChipIdentifier(key, value);
        if (existingChipsMap && existingChipsMap.has(chipId)) {
          const chip = existingChipsMap.get(chipId);
          if (chip?.title?.filterTitle && chip?.title?.value) {
            newFilterChips.push(chip);
            existingChipsMap.delete(chipId);
          } else {
            const chipPromise = generateChips(list, key, decodeURIComponent(value), setAsyncFiltersSelected).then(
              chip => {
                if (chip) {
                  newFilterChips.push({
                    title: chip,
                    searchKey: key,
                    searchValue: value,
                    history,
                  });
                }
              }
            );
            promises.push(chipPromise);
          }
        } else {
          const chipPromise = generateChips(list, key, decodeURIComponent(value), setAsyncFiltersSelected).then(
            chip => {
              if (chip) {
                newFilterChips.push({
                  title: chip,
                  searchKey: key,
                  searchValue: value,
                  history,
                });
              }
            }
          );
          promises.push(chipPromise);
        }
      }

      await Promise.all(promises);

      if (newFilterChips.length && JSON.stringify(newFilterChips) !== JSON.stringify(filterChips)) {
        setFilterChips(newFilterChips);
      }
    }

    if (refresh) {
      const searchQuery = convertQueryObjToString({ ...queryObj, limit: limitPerPage });
      setFiltersObject({ ...queryObj });
      searchQuery === "?" ? fetch() : fetch(`?${searchQuery}`);
      if (rootFiltersObj) {
        props.setRootFiltersObj({ ...queryObj, page: 1 });
      }
    }
  };

  useEffect(() => {
    renderFilterChips(true);
  }, [history.location.search]);

  useEffect(() => {
    if (list.length > 0 && !loading) {
      renderFilterChips(false);
    }
  }, [list.length]);

  const onSearch = (object = filtersObject) => {
    const obj = { ...object };
    if (obj.hasOwnProperty("f[client.phone]")) {
      try {
        const phoneValue = obj["f[client.phone]"];
        obj["f[client.phone]"] = formatPhoneNumber(phoneValue);
      } catch (error) {
        console.error("Error formatting phone number:", error.message);
      }
    }
    history.push({
      pathname: history.location.pathname,
      search: convertQueryObjToString(obj),
    });
  };
  const onValueSelect = ({ slug: value, id, selected, name, flag }, filterList, searchKey) => {
    setFiltersObject({ ...filterList });
    if (!selected) {
      addQueryFilterArray(filterList, searchKey, value || id, name, obj => {
        history.push({
          pathname: history.location.pathname,
          search: convertQueryObjToString({ ...obj }),
        });
      });
    } else {
      const filteredChips = filterChips.filter(filter => filter.searchValue !== `${value || id}`);
      setFilterChips(filteredChips);
      addQueryFilterArrayPreSelected(filterList, searchKey, value || id, name, baseList, obj => {
        history.push({
          pathname: history.location.pathname,
          search: convertQueryObjToString({ ...obj }),
        });
      });
    }
  };

  const getOptionLabel = (option, key) => {
    const selectedOptionLabel = asyncFiltersSelected?.[key]?.find(item => item.id === option.id);
    if (option.name === "selected-options") {
      return (
        <div>
          <div className={cx("d-flex justify-content-between align-items-center", styles.label, styles.totalUsers)}>
            <div className={cx("text-uppercase")}>selected options</div>
            <div style={{ marginLeft: 10 }}>{`${asyncFiltersSelected?.[key]?.length || 0} / 10`}</div>
          </div>
        </div>
      );
    }
    if (option.name === "unselected-options") {
      return <div className={cx(styles.divider, "text-center text-uppercase")}>unselected users</div>;
    }
    return (
      <div className={cx(!!selectedOptionLabel ? styles.selectedLabel : styles.label)}>
        <div className={"d-flex align-items-center justify-content-between"}>
          <div className="d-flex" style={{ gap: "12px" }}>
            {/* <Avatar size={30} name={option?.name} avatarOnly round /> */}

            <div className="user-profile">
              <div>{option.name}</div>
              <div className="text-truncate" style={{ maxWidth: "18ch" }}>
                {option.email}
              </div>
            </div>
          </div>
          {!!selectedOptionLabel && <Check size={14} />}
        </div>
      </div>
    );
  };

  const formatListCallback = (list, page, search, userList = [], key) => {
    let finalOptions = asyncFiltersSelected?.[key] || [];
    const selectedOptionIds = finalOptions?.map(option => option.id);
    let prependData;
    if (page === 1 && !search) {
      prependData = [
        ...[
          {
            id: generateUUID(),
            name: "selected-options",
          },
        ],
        ...finalOptions,
      ];
    } else {
      prependData = [];
    }
    const filteredOptions = list?.filter(option => !selectedOptionIds.includes(option.id));
    return [...prependData, ...filteredOptions];
  };

  const disabledDate = current => {
    const minDate = new Date();
    const maxDate = new Date();
    minDate.setDate(minDate.getDate() - 731);
    maxDate.setDate(canSelectCurrentDate ? maxDate.getDate() : maxDate.getDate() - 1);
    return current && (current < minDate || current > maxDate);
  };

  const disabledDateMonthPicker = date => {
    const currentMonth = dayjs().startOf("month");
    const dateMonth = dayjs(date).startOf("month");
    return dateMonth.isAfter(currentMonth, "month");
  };

  const nestedCheckboxOnChange = props => {
    const { selectedChildren, filterKey, filtersObject = {}, setFiltersObject = () => {}, label } = props;
    if (selectedChildren && selectedChildren.length > 0) {
      const childIds = selectedChildren.map(child => child.value || child.key).join(",");
      const updatedFilterChips = filterChips.filter(chip => childIds.includes(chip.searchValue));
      selectedChildren.forEach(child => {
        if (!filterChips.some(chip => chip.searchValue === child.value)) {
          updatedFilterChips.push({
            title: { value: child.title, filterTitle: label, filterChild: null },
            searchKey: filterKey,
            searchValue: child.value,
          });
        }
      });

      const updatedFilterList = { ...filtersObject, [filterKey]: childIds };
      setFilterChips(updatedFilterChips);
      setFiltersObject(updatedFilterList);
      history.push({
        pathname: history.location.pathname,
        search: convertQueryObjToString(updatedFilterList),
      });
    } else {
      const updatedFilterChips = filterChips.filter(chip => !chip.searchKey.includes(filterKey));
      setFilterChips(updatedFilterChips);
      const { [filterKey]: removed, ...restFilters } = filtersObject;
      setFiltersObject(restFilters);
      history.push({
        pathname: history.location.pathname,
        search: convertQueryObjToString(restFilters),
      });
    }
  };

  const getFilterType = props => {
    const {
      type,
      label,
      key,
      isRefNumber,
      list,
      setSlugAsKey,
      verifySlugOnly = false,
      renderMenuItem = null,
      isSingleSelect = false,
      listApi,
      formatOptions,
      listProperty = "",
      queryKey = "",
      extraParams = "",
      asyncQueryKey,
      startDateKey = "",
      endDateKey = "",
      showFullName = false,
      isSelected,
      isMultiOption,
      areaKey = "",
      cityKey = {},
      isMonthPicker = false,
      ...rest
    } = props;

    const onChangeModalFilter = selectedItem => {
      if (isMultiOption) {
        const selectedCityKey =
          selectedItem.selectAllAreas || !selectedItem?.area?.length ? cityKey.allCityKey : cityKey.defaultCityKey;
        const nonSelectedCityKey =
          selectedItem.selectAllAreas || !selectedItem?.area?.length ? cityKey.defaultCityKey : cityKey.allCityKey;
        delete filtersObject[areaKey];
        delete filtersObject[nonSelectedCityKey];
        let obj = {
          ...filtersObject,
        };
        selectedItem?.area?.length &&
          selectedItem.area.forEach(area => {
            addQueryFilterArray(obj, areaKey, area?.id, area?.name, updatedObj => {
              obj = { ...updatedObj };
            });
          });
        obj = {
          ...obj,
          [selectedCityKey]: `${selectedItem?.city?.id}`,
        };
        setFiltersObject(obj);
        props?.getDataInParent && props.getDataInParent(selectedItem);
        onSearch(obj);
      } else {
        const obj = {
          ...filtersObject,
          [areaKey]: selectedItem?.area?.id,
        };
        const chipValue = props.levels?.map(level => selectedItem[level]?.name).join(", ") ?? "";
        setFiltersObject(obj);
        props?.getDataInParent && props.getDataInParent({ value: chipValue });
        onSearch(obj);
      }
    };

    switch (type) {
      case FILTER_TYPES.NESTED_CHECKBOX_DROPDOWN:
        return (
          <NestedCheckboxDropdown
            listApi={listApi}
            extraParams={extraParams}
            listProperty={listProperty}
            onChecked={nestedCheckboxOnChange}
            filterKey={key}
            filtersObject={filtersObject}
            label={label}
          />
        );
      case FILTER_TYPES.DROPDOWN:
        const truncatedLabel = !showFullName ? shortenString(label, 22, 14, "...") : label;
        return (
          <DropdownSelect
            label={truncatedLabel}
            options={makeListToSelectData(list || [], filtersObject[key], setSlugAsKey, verifySlugOnly, key)}
            toggleClassName={`form-control text-truncate ${!!filtersObject?.[key] ? "selected-filter" : ""}`}
            filterSelected={!!filtersObject?.[key]}
            onChange={item => {
              if (isSingleSelect) {
                delete filtersObject[key];
              }
              onValueSelect(item, filtersObject, key);
            }}
            showSearchIcon
            noSelectValue
            loading={initialRender}
            isFilter
            isSelected={!!filtersObject?.[key]}
            renderMenuItem={renderMenuItem}
            isSingleSelect={isSingleSelect}
            {...rest}
          />
        );

      case FILTER_TYPES.TREE_DROPDOWN:
        return (
          <TreeSelectInput
            zIndex={10}
            className={cx("selectSearch", filterStyle.customTreeDropDownSelect)}
            // label={label}
            value={
              filtersObject[key] && typeof filtersObject[key] === "string"
                ? filtersObject[key].split(",").map(Number)
                : []
            }
            options={list}
            handleOnChange={it => {
              const id = it.pop();
              const obj = {
                ...filtersObject,
                [key]: it.length ? it.join(",") : "",
              };

              delete obj.page;
              onValueSelect({ id }, obj, key);
            }}
            placeholder={label}
            defaultToggleValue={!(filtersObject[rest?.toggle?.key] == "true")}
            {...rest}
          />
        );

      case FILTER_TYPES.DROPDOWN_V2:
        return (
          <DropdownSelect
            label={shortenString(label, 17, 14, "...")}
            options={formatOptionsForReactSelect(list || [], filtersObject[key])}
            toggleClassName={`form-control text-truncate ${!!filtersObject?.[key] ? "selected-filter" : ""}`}
            onChange={item => {
              if (isSingleSelect) {
                delete filtersObject[key];
              }
              onValueSelect(item, filtersObject, key);
            }}
            showSearchIcon
            noSelectValue
            loading={initialRender}
            renderMenuItem={renderMenuItem}
            isFilter
            isSelected={!!filtersObject?.[key]}
            isSingleSelect={isSingleSelect}
            filterSelected={!!filtersObject?.[key]}
            {...rest}
          />
        );

      case FILTER_TYPES.MONTH_DATE_RANGE_FILTER:
        const formattedStartDateKey = startDateKey === "" ? `f[${key}][gte]` : startDateKey;
        const formattedEndDateKey = endDateKey === "" ? `f[${key}][lte]` : endDateKey;
        return (
          <DateRangePicker
            disabledDate={disabledDate}
            onChange={dates => {
              let obj = { ...filtersObject };
              obj[formattedStartDateKey] = encodeURIComponent(
                dateTimeFunction({ date: dates[0], apiRequest: true, timeZone: true })
              );
              obj[formattedEndDateKey] = encodeURIComponent(
                dateTimeFunction({ date: dates[1], apiRequest: true, timeZone: true })
              );
              setFiltersObject(obj);
              onSearch(obj);
            }}
            value={[filtersObject?.[formattedStartDateKey], filtersObject?.[formattedEndDateKey]]}
          />
        );

      case FILTER_TYPES.DATE: {
        const utcOffset = getUTCOffset(timezone);
        const formattedStartDateKey = startDateKey === "" ? `f[${key}][gte]` : startDateKey;
        const formattedEndDateKey = endDateKey === "" ? `f[${key}][lte]` : endDateKey;
        const queryStartDate = `${filtersObject[formattedStartDateKey]}${utcOffset}`;
        const queryEndDate = `${filtersObject[formattedEndDateKey]}${utcOffset}`;

        return (
          <DateFilter
            label={label}
            timezone={timezone}
            queryStartDate={queryStartDate}
            queryEndDate={queryEndDate}
            onSelect={(startDate, endDate) => {
              let obj = {};
              obj = {
                ...filtersObject,
                [formattedStartDateKey]: startDate,
                [formattedEndDateKey]: endDate,
              };
              obj.page && delete obj.page;
              setFiltersObject(obj);
              onSearch(obj);
            }}
            onClear={() => {
              let filteredChips = filterChips.filter(filter => filter.searchKey !== `${formattedStartDateKey}`);
              filteredChips = filteredChips.filter(filter => filter.searchKey !== `${formattedEndDateKey}`);
              setFilterChips(filteredChips);
              delete filtersObject[formattedStartDateKey];
              delete filtersObject[formattedEndDateKey];
              onSearch(filtersObject);
            }}
            filterSelected={!!filtersObject?.[formattedStartDateKey] && !!filtersObject?.[formattedEndDateKey]}
            loading={initialRender}
            inputClassName={
              !!filtersObject?.[formattedStartDateKey] && !!filtersObject?.[formattedEndDateKey]
                ? "selected-filter"
                : ""
            }
            isSingleSelect={isSingleSelect}
            {...rest}
          />
        );
      }

      case FILTER_TYPES.CHECKBOX_DROPDOWN:
        return (
          <CheckboxDropdown
            options={makeListToSelectData(list || [], filtersObject[key], setSlugAsKey)}
            label={label}
            filterKey={key}
            filtersObject={filtersObject}
            onChange={selected => {
              onValueSelect(selected, filtersObject, key);
            }}
            clearAll={() => {
              delete filtersObject[key];
              onSearch(filtersObject);
            }}
            className={!!filtersObject?.[key] ? "primary-border" : ""}
            filterSelected={!!filtersObject?.[key]}
          />
        );

      case FILTER_TYPES.TYPEAHEAD:
        return (
          <SelectionListTypeHead
            className={!!filtersObject?.[key] ? "filter-typehead selected-filter" : "filter-typehead"}
            placeholder={label}
            options={makeListToSelectData(list || [], filtersObject[key], setSlugAsKey)}
            selected={[]}
            onChange={selected => {
              onValueSelect(selected[0], filtersObject, key);
            }}
            showSearchIcon
            labelKey="name"
            clearButton={undefined}
            loading={initialRender}
          />
        );
      case FILTER_TYPES.RANGE:
        return (
          <RangeFilter
            filterHandler={(rangeValues, filterKeys) => {
              const obj = {
                ...filtersObject,
                [filterKeys.min]: String(rangeValues.min),
                [filterKeys.max]: String(rangeValues.max),
              };
              obj.page && delete obj.page;
              setFiltersObject(obj);
              onSearch(obj);
            }}
            getRangeValue={filterKeys => {
              if (filtersObject?.[filterKeys.min] && filtersObject?.[filterKeys.max]) {
                return `${filtersObject[filterKeys.min]} To ${filtersObject[filterKeys.max]}`;
              }
              return "";
            }}
            onClearFilter={filterKeys => {
              delete filtersObject[filterKeys.min];
              delete filtersObject[filterKeys.max];
              onSearch(filtersObject);
            }}
            filterSelected={isSelected}
            filtersObject={filtersObject}
            type={key}
            label={label}
            MIN_LIMIT={props.MIN_LIMIT}
            MAX_LIMIT={props.MAX_LIMIT}
            inputClassName={isSelected ? "selected-filter" : ""}
          />
        );

      case FILTER_TYPES.SCROLLABLE_DROPDOWN:
        return (
          <AsyncSelect
            isClearable={true}
            formatListCallback={formatListCallback}
            selected={[]}
            cacheUniqs={[asyncFiltersSelected?.[key]]}
            listApi={listApi}
            listProperty={listProperty}
            queryKey={queryKey || "s[name]"}
            extraParams={extraParams}
            isMulti={isSingleSelect}
            onChange={onAsyncSelectFilterChange(
              isSingleSelect,
              filtersObject,
              key,
              onValueSelect,
              asyncFiltersSelected,
              setAsyncFiltersSelected
            )}
            filterKey={key}
            filtersObject={filtersObject}
            setSlugAsKey={setSlugAsKey}
            verifySlugOnly={verifySlugOnly}
            placeholder={label}
            loading={initialRender}
            filterSelected={!!filtersObject?.[key]}
            isFitler={true}
            key={asyncQueryKey}
            isMultiOption={isMultiOption}
            {...rest}
            formatOptionLabel={option => {
              return getOptionLabel(option, key);
            }}
          />
        );
      case FILTER_TYPES.MODAL:
        return (
          <ModalFilter
            filtersObject={filtersObject}
            label={label}
            filterSelected={!!filtersObject?.[areaKey] || !!filtersObject?.[cityKey.allCityKey]}
            areaKey={areaKey}
            cityKey={cityKey}
            onChange={selectedItem => onChangeModalFilter(selectedItem)}
            loading={loading}
            isMultiOption={isMultiOption}
            {...rest}
          />
        );

      default:
        return (
          <SearchInput
            value={filtersObject[key] || ""}
            placeholder={label}
            onChange={e => {
              filtersObject.page && delete filtersObject.page;
              setFiltersObject({
                ...filtersObject,
                [key]: e.target.value,
              });
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
            loading={initialRender}
            className={!!filtersObject?.[key] ? "selected-filter" : ""}
            filterSelected={!!filtersObject?.[key]}
            {...(isRefNumber === true ? { type: "number" } : null)}
            {...rest}
          />
        );
    }
  };

  const organizedData = [];

  filterChips.forEach(item => {
    const filterTitle = item.title.filterTitle;

    if (!filterTitle) return;

    let found = false;

    // Check if the group with the same filterTitle exists
    for (let group of organizedData) {
      if (group.length > 0 && group[0].title.filterTitle === filterTitle) {
        group.push({ ...item });
        found = true;
        break;
      }
    }

    // If no group is found, create a new one
    if (!found) {
      organizedData.push([{ ...item }]);
    }
  });
  if (initialRender)
    return (
      <>
        <div className="form-row flex-end filter-animation ">
          {[1, 2, 3, 4, 5, 6].map((key, index) => (
            <div key={index} className="col-sm-6 col-md-4 col-xl-2 m-b-10">
              <Skeleton height={42} className="d-block" />
            </div>
          ))}
        </div>
      </>
    );

  return (
    <div className={cx("filter__row--section", { containerClass: containerClass }, { "pe-none": loading })}>
      <div className="form-row flex-end filter-animation ">
        {baseList?.map((item, index) => {
          const key = item?.key;
          let isSelected = !!filtersObject?.[key];
          if (item.type === "range") {
            const { GREATER_THAN, LESS_THAN } = RANGE_FILTER_KEYS()[item.key];
            isSelected = !!filtersObject[GREATER_THAN] && !!filtersObject[LESS_THAN];
          }
          let filterClass = item.customClass ?? "col-sm-6 col-md-4 col-xl-2 m-b-10";
          // if (isSelected) {
          //   filterClass = filterClass + " primary-border";
          // }
          return index < 6 || showFilters ? (
            <div className={filterClass} key={item.id}>
              {getFilterType({ ...item, isSelected })}
              {/* <FilterType item={{ ...item, isSelected }} /> */}
            </div>
          ) : null;
        })}
      </div>

      {!!baseList?.length && filterChips && (
        <>
          <div className="clearfix"></div>
          <div className="chip__filter-row row form-row">
            <div
              className="col-auto d-flex flex-wrap align-items-center"
              style={{ maxWidth: "calc(100% - 14ch)", gap: 6 }}
            >
              {filterChipsRequired &&
                organizedData.flatMap((group, index) => {
                  return (
                    <FilterChip
                      key={index}
                      index={index}
                      chipFilters={filtersObject}
                      filterChips={filterChips}
                      setFilterChips={setFilterChips}
                      baseList={baseList}
                      groupedChips={group}
                      isGroupedFilter={true}
                      asyncFiltersSelected={asyncFiltersSelected}
                    />
                  );
                })}

              {Object.keys(filtersObject).length > 0 &&
              !(Object.keys(filtersObject).length === 1 && Object.keys(filtersObject)[0] === "page")
                ? showResetFilters() && (
                    <span
                      className="clear_all_filters text-nowrap d-inline-block"
                      onClick={() => {
                        setAsyncFiltersSelected({});
                        setFilterChips([]);
                        history.push(`${!!rootPath ? rootPath : history?.location?.pathname}`);
                      }}
                    >
                      {strings.reset_filters}
                    </span>
                  )
                : ""}
            </div>
            <div
              className="col-auto ml-auto d-flex align-items-center"
              style={{ pointerEvents: loading ? "none" : "auto" }}
            >
              {baseList.length > 6 && !initialRender && (
                <span
                  className="d-flex align-items-center align-self-start mt-3 more-filters"
                  onClick={() => setShowFilters(showFilters => !showFilters)}
                >
                  {showFilters ? strings.hide_filters : strings.more_filters}
                  {showFilters ? <FiChevronUp /> : <FiChevronDown />}
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const removeItemById = (obj, arrayKey, id) => {
  if (!obj[arrayKey]) {
    return obj; // If the key does not exist, return the original object
  }

  obj[arrayKey] = obj[arrayKey].filter(item => item.id !== id);

  return obj;
};

const onAsyncSelectFilterChange =
  (isSingleSelect, filtersObject, key, onValueSelect, asyncFiltersSelected, setAsyncFiltersSelected) => item => {
    if (isSingleSelect) {
      const currentlySelectedOptionId = Number(filtersObject?.[key]);
      delete filtersObject[key];
      const currentOption = Number(item.at(0).id);
      const newItem = {
        ...item.at(0),
        selected: currentlySelectedOptionId === currentOption,
      };
      const firstSelectedName = newItem?.name;

      if (firstSelectedName === "selected-options" || firstSelectedName === "unselected-options") {
        return;
      }
      setAsyncFiltersSelected({ [key]: [newItem] });

      onValueSelect(newItem, filtersObject, key);
    } else {
      const currentOption = Number(item.id);
      const selectedOptionsIds = filtersObject?.[key]?.split(",")?.map(value => Number(value)) ?? [];
      const currentOptionSelected = !!selectedOptionsIds.find(optionId => currentOption === optionId);
      const newItem = {
        ...item,
        selected: currentOptionSelected,
      };

      if (currentOptionSelected) {
        setAsyncFiltersSelected(removeItemById(asyncFiltersSelected, key, newItem.id));
      }
      const firstSelectedName = newItem?.name;

      if (firstSelectedName === "selected-options" || firstSelectedName === "unselected-options") {
        return;
      }
      onValueSelect(newItem, filtersObject, key);
    }
  };

const mapStateToProps = ({ Configs }) => {
  return {
    country: Configs.tenant.country,
  };
};
export default connect(mapStateToProps, {})(Filters);
