import Skel, { SkeletonTheme } from "react-loading-skeleton";
import React from "react";
import { isEmptyObject } from "helpers/Util";

const Skeleton = ({ themeProps = {}, ...rest }) =>
  !isEmptyObject(themeProps) ? (
    <SkeletonTheme {...themeProps}>
      <Skel {...rest} />
    </SkeletonTheme>
  ) : (
    <Skel {...rest} />
  );

export default Skeleton;
