export const QUERY_KEYS = {
  EXACT: {
    reference_number: "f[reference_number]",
    status_id: "f[status.id][]",
    assignee_id: "f[assignee.id][]",
    follow_up_type_array: "f[follow_up_type][]",
    followed_type: "f[followed_type]",
    due_date: "due_date",
    lead_reference_number: "f[crm_lead.reference_number]",
    lead_phone: "f[crm_lead.client.phone]",
    client_email_address: "f[client.email]",
    negotiation_status: "f[negotiation.status.id][]",
  },
  RANSACK: {
    assignee_id: "q[assignee_id_in][]",
  },
  SIMILAR: {
    name: "s[name]",
    lead_name: "s[crm_lead.client.name]",
  },
};
