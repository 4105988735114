import React from "react";
import { generateUUID } from "../../../../helpers/Util";
import UploadFileDynamicInput from "./UploadFileDynamicInput";

export const AttachmentUpload = props => {
  const {
    label = "attachment-dynamic",
    setFieldValue,
    pictures,
    field,
    textRight = true,
    errorMsg,
    title,
    url,
    isEditable,
    horizontal = true,
  } = props;
  const setPictures = pics => {
    setFieldValue(field, pics || pictures);
  };

  const onFilesAdded = async fileList => {
    let files = Array.from(fileList);
    if (!Array.isArray(files)) return;
    let newFile = { id: generateUUID(), file: files[0], name: files[0].name, uploaded: false };
    setPictures([newFile]);
  };

  return (
    <UploadFileDynamicInput
      pictures={pictures}
      setPictures={setPictures}
      onFilesAdded={onFilesAdded}
      textRight={textRight}
      label={label}
      title={title}
      id={field}
      errorMsg={errorMsg}
      url={url}
      isEditable={isEditable}
      horizontal={horizontal}
    />
  );
};

export default AttachmentUpload;
