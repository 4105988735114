import { SET_CUSTOM_STATE, RESET_CUSTOM_STATE } from "../../constant/actionTypes";

export const setCustomState = payload => async dispatch => {
  dispatch({
    type: SET_CUSTOM_STATE,
    payload,
  });
};

export const resetCustomState = () => async dispatch => {
  dispatch({
    type: RESET_CUSTOM_STATE,
  });
};
