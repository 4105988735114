import { endpointApi, getCallback, putApiMethod } from "../coreApiHelper";

export const updateWhatsappTrigger = ({ triggerId, payload }) => {
  const path = `${endpointApi}/whatsapp_templates/${triggerId}`;
  return putApiMethod(path, payload);
};

export const fetchWhatsappTriggers = () => {
  const path = `${endpointApi}/whatsapp_templates`;
  return getCallback(path);
};
