import React from "react";
import { shortenString } from "../../helpers/Util";
//import { Tooltip } from "./index";
import { Tooltip, Box } from "@chakra-ui/react";
const Text = ({ text, dataTipId }) => {
  let truncatedText = text.length < 12 ? text : shortenString(text, 16, 13, "...");
  if (text < 17) return <span>{truncatedText}</span>;
  return (
    <div className="d-flex align-items-center">
      {/*<div data-tip data-for={dataTipId}>*/}
      {/*  {truncatedText}*/}
      {/*</div>*/}
      {/*<Tooltip*/}
      {/*  className="pointer-events-unset"*/}
      {/*  id={dataTipId}*/}
      {/*  backgroundColor="white"*/}
      {/*  place="bottom"*/}
      {/*  textColor="black"*/}
      {/*  isCapture*/}
      {/*  // eventOff="scroll blur"*/}
      {/*>*/}
      {/*  <div className="taskTooltipWrap">*/}
      {/*    <div className="taskTooltipItem">*/}
      {/*      <p className="fs-6 mb-0 fw-semibold " style={{ maxWidth: "150px", marginInline: "auto" }}>*/}
      {/*        {text}*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Tooltip>*/}
      <Tooltip
        padding={"10px"}
        borderRadius={"4px"}
        fontSize={"12px"}
        fontWeigth={"400"}
        color={"#393535DE"}
        maxWidth={"150px"}
        marginInline={"auto"}
        backgroundColor={"white"}
        label={text}
      >
        {truncatedText}
      </Tooltip>
    </div>
  );
};
export default Text;
