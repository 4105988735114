import React from "react";
import { Skeleton } from "../../../../components/common";

const QCHeaderSkeleton = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className={"d-flex clientInfoWrap justify-content-between align-items-center m-b-25 p-b-15 border-bottom"}>
          <div className="d-flex flex-row">
            <div className={"d-flex clientInfoThumb mt-1"}>
              <Skeleton height={40} width={70} />
            </div>
            <div className={"clientInfo mt-2"}>
              <Skeleton height={30} width={150} />
            </div>
          </div>
          <div>
            <Skeleton height={30} width={250} />
          </div>
        </div>
        <div className="d-flex gap-3">
          {[1, 2, 3].map(item => (
            <Skeleton key={item} width={80} height={40} className="mr-3" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default QCHeaderSkeleton;
