export const PRICE_CATEGORIES = {
  GREAT: "great",
  FAIR: "fair",
  RISKY: "risky",
};

export const KITT_HARDCODED_VALUES = {
  panels_painted_initial_value: {
    kitt_retool_dropdown_parameter_sk: "empg|mea|ae|parameters|panelsrepainted|0",
    kitt_retool_dropdown_parameter_nk: "panelsrepainted|0",
    type: "panels_repainted",
    key: "0",
    value: "0",
    value2: "",
  },
  odometer_rollback_initial_value: {
    kitt_retool_dropdown_parameter_sk: "empg|mea|ae|parameters|odometerrollback|no",
    kitt_retool_dropdown_parameter_nk: "odometerrollback|no",
    type: "odometer_rollback",
    key: "No",
    value: "0.00",
    value2: "",
  },
  seller_loan_initial_value: {
    kitt_retool_dropdown_parameter_sk: "empg|mea|ae|parameters|sellerloan|no",
    kitt_retool_dropdown_parameter_nk: "sellerloan|no",
    type: "seller_loan",
    key: "No",
    value: "0",
    value2: "",
  },
  vat_invoice_available_initial_value: {
    id: 2,
    name: "No",
    slug: "no",
  },
  insured_city_initial_value: {
    id: 1,
    name: "Yes",
    slug: "yes",
  },
  additional_reconditioning_expenses_initial_value: "0",
  accident_type_initial_value: {
    kitt_retool_dropdown_parameter_sk: "empg|mea|ae|parameters|accidenttype|no",
    kitt_retool_dropdown_parameter_nk: "accidenttype|no",
    type: "accident_type",
    key: "No",
    value: "0.00",
    value2: "",
  },
  car_fleet_initial_value: {
    kitt_retool_dropdown_parameter_sk: "empg|mea|ae|parameters|carfleet|no",
    kitt_retool_dropdown_parameter_nk: "carfleet|no",
    type: "car_fleet",
    key: "no",
    value: "0.00",
    value2: "",
  },
  warranty_initial_value: {
    kitt_retool_dropdown_parameter_sk: "empg|mea|ae|parameters|warranty|yes",
    kitt_retool_dropdown_parameter_nk: "warranty|yes",
    type: "warranty",
    key: "Yes",
    value: "yes",
    value2: "",
  },
};

export const kittMetaDataStaticFields = {
  "make.name": true,
  "model.name": true,
  "variant.name": true,
  model_year: true,
  "assembly.name": true,
  engine: true,
  mileage: true,
  "exterior_color.name": true,
  "inventory.id": true,
  "dynamic_attributes.car.car_cylinders": true,
  "dynamic_attributes.car.reconditioning_expenses": true,
  "dynamic_attributes.car.mortgage": true,
  "dynamic_attributes.car.vat_applicable": true,
};
