import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { crmLeadUpdateStatus } from "../../actions/crm/crm-detail.action";
import crmLeadDispositionCoverted from "../../assets/illustrations/crm-lead-disposition-converted.svg";
import { strings } from "../../constant/strings";
import { fetchColaborators, updateCRMLeadAttributesApi } from "../../helpers/ApiHelper";
import { checkUndefinedApiParams, getErrorString, isFieldRestricted, isObjRestricted } from "../../helpers/Util";
import { Avatar, CustomAlert, FormInput, IF, Modal, SelectionListTypeHead, Toast } from "../common";

const ChangeStatusModal = ({ visible, setVisible, crmLeadId, crmLeadUpdateStatus }) => {
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [contributors, setContributors] = useState(null);
  const [updateLeadLoading, setUpdateLeadLoading] = useState(false);
  const contributorsExist =
    !isFieldRestricted(contributors) && contributors?.length && !isObjRestricted(contributors[0], ["name"]);
  useEffect(() => {
    crmLeadId && !!visible && fetchData();
  }, [visible]);

  const fetchData = async () => {
    if (!checkUndefinedApiParams(crmLeadId, "CRM lead")) return;
    setLoading(true);
    const response = await fetchColaborators(crmLeadId);
    if (response) {
      setLoading(false);
      if (response.success && response.users) {
        setContributors(response.users);
      } else {
        setError(getErrorString(response));
      }
    }
  };

  const makeData = () => {
    const cont = contributors?.filter(item => !selected.find(e => e.reference_number === item?.reference_number));
    const data = cont?.map(item => ({
      ...item,
      label: item.name,
      collaboratorId: item.id,
    }));
    return data || [];
  };

  const updatePercentage = async () => {
    setError("");
    setUpdateLeadLoading(true);
    const obj = {
      crm_lead: {
        disposition_id: visible,
        contributors_attributes: [
          ...selected.map(item => ({ user_id: item.collaboratorId, percentage: item.contribution })),
        ],
      },
    };
    const res =
      crmLeadId && !isFieldRestricted(crmLeadId)
        ? await updateCRMLeadAttributesApi(crmLeadId, obj, "change_status")
        : Toast.error(strings.something_went_wrong);
    if (res) {
      setUpdateLeadLoading(false);
      if (res.success && res.crm_lead) {
        crmLeadUpdateStatus(res.crm_lead);
        setVisible(false);
      } else {
        setError(getErrorString(res));
      }
    }
  };

  const updatePercentageValue = (item, error, contribution) => {
    const values = selected;
    const index = values?.findIndex(ele => ele.collaboratorId === item?.collaboratorId);
    values[index] = { ...values[index], contribution, error };
    setSelected([...values]);
  };

  const onPercentageChange = item => e => {
    setError("");
    updatePercentageValue(item, false, e.target.value);
    // numberValidation(
    //   e.target.value,
    //   value => updatePercentageValue(item, false, value),
    //   () => updatePercentageValue(item, true, item.contribution || "")
    // );
  };

  const onRemoveItem = item => () => {
    const values = selected;
    const index = values?.findIndex(e => e.collaboratorId === item?.collaboratorId);
    values.splice(index, 1);
    setSelected([...values]);
  };

  const onChangeSearchInput = value => {
    setError("");
    setSelected([...selected, value[0]]);
  };

  const MenuItem = ({ item }) => (
    <div className="d-flex flex-row align-items-center change-status-list">
      <Avatar name={item.label} size="28" round containerClass="change-status-name p-r-10" />
      <div className="change-status-email text-turncate p-r-10">{item.email}</div>
      <div className="change-status-phone p-r-5">{item.phone} </div>
      <div className="change-status-role text-turncate">
        {item.roles.map((e, i) => (
          <div style={{ display: "inline-block" }} key={e.id}>
            {`${e.name}${item.roles.length > i ? ", " : ""}`}
          </div>
        ))}
      </div>
    </div>
  );

  const renderHeader = () => (
    <tr>
      <th className="text-nowrap">{strings.ref}</th>
      <th>{strings.name}</th>
      <th>{strings.roles}</th>
      <th>{strings.percentage}</th>
      <th>{strings.actions}</th>
    </tr>
  );

  const renderBody = item => (
    <tr key={item?.reference_number}>
      <td scope="row">{item.reference_number}</td>
      <td scope="row">
        <Avatar name={item?.label} />
      </td>
      <td scope="row">{item?.roles.map(e => e.name).join(", ")}</td>
      <td scope="row">
        <div className="d-flex flex-row align-items-center">
          <FormInput
            onChange={onPercentageChange(item)}
            loading={loading}
            value={item.contribution || ""}
            inputContainerClass="percentage-input"
            containerClassName="percentage-input-outer"
            right={() => <span>%</span>}
            type={"number"}
          />
          <CustomAlert message={item.error && strings.specify_a_number_message} type="icon" containerClassName="ml-1" />
        </div>
      </td>
      <td scope="row">
        <button onClick={onRemoveItem(item)} className="btn btn-link">
          {strings.remove}
        </button>
      </td>
    </tr>
  );

  return (
    <Modal
      toggle={() => setVisible(!visible)}
      open={!!visible}
      className="modal-lg deposit_model"
      body={() => (
        <div className="form theme-form">
          <div className="d-flex w-100 align-items-start justify-content-between">
            <h4 className="m-b-15">{strings.change_status}</h4>
          </div>
          {!!contributorsExist && (
            <SelectionListTypeHead
              placeholder={strings.search_by_name}
              onBlur={() => {}}
              isLoading={loading}
              options={makeData()}
              onChange={onChangeSearchInput}
              selected={[]}
              className="change-status-dropdown"
              renderMenuItemChildren={option => <MenuItem item={option} />}
            />
          )}

          <Table className="change-status-table">
            <thead>{renderHeader()}</thead>
            <IF condition={!!selected?.length}>
              <tbody>{selected?.map(item => renderBody(item))}</tbody>
            </IF>
          </Table>

          <IF condition={!selected?.length}>
            <div className="d-flex justify-content-center flex-column align-items-center my-4 change-status-img">
              <img className="m-b-30 m-t-15" src={crmLeadDispositionCoverted} />
              <p className="text-muted">{strings.search_users_to_add_here}</p>
            </div>
          </IF>

          <IF condition={error}>
            <div className="d-flex justify-content-center w-100">
              <CustomAlert message={error} />
            </div>
          </IF>
        </div>
      )}
      actions={[
        {
          label: strings.cancel,
          onClick: () => {
            setVisible(false);
          },
          color: "secondary",
          className: "modal__cancel-btn btn-pill",
        },
        {
          label: strings.submit,
          onClick: () => {
            if (selected?.reduce((acc, item) => Number(acc) + Number(item.contribution), 0) === 100) {
              updatePercentage(selected);
            } else {
              setError("Contribution percentage should be equal to 100");
            }
          },
          color: "primary",
          className: "modal__cr-btn btn-pill",
          loading: updateLeadLoading,
        },
      ]}
      onClosed={e => {
        setSelected([]);
      }}
    />
  );
};
export default connect(() => ({}), { crmLeadUpdateStatus })(ChangeStatusModal);
