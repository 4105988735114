import { Alert } from "reactstrap";
import { AlertCircle } from "react-feather";
import IF from "./IF";
import React from "react";
import { Tooltip } from ".";

export const CustomAlert = props => {
  const { message, type, tooltipKey, error = true, success, warning, info, containerClassName, ...rest } = props;

  const getColor = () => {
    if (success) return { bg_color: "#07bc0c", color: "white" };
    if (warning) return { bg_color: "#f1c40f", color: "black" };
    if (info) return { bg_color: "#3498db", color: "white" };
    return { bg_color: "#e74c3c", color: "white" };
  };

  switch (type) {
    case "icon":
      return (
        <IF condition={!!message}>
          <div className={containerClassName}>
            <Tooltip backgroundColor="white" textColor={getColor()?.bg_color} id={tooltipKey}>
              {message}
            </Tooltip>
            <span data-tip data-for={tooltipKey}>
              <AlertCircle color={getColor()?.bg_color} />
            </span>
          </div>
        </IF>
      );
    default:
      return (
        <IF condition={!!message}>
          <Alert
            style={{
              backgroundColor: getColor()?.bg_color,
              color: getColor()?.color,
              borderWidth: 0,
            }}
            {...rest}
          >
            {message}
          </Alert>
        </IF>
      );
  }
};
