import React, { Fragment, useMemo } from "react";
import cx from "clsx";
import moment from "moment";
import { useSelector } from "react-redux";
import styles from "./ChevronCardDateTime.module.scss";
import { GridIcon, MdInfoOutline } from "../../../../../svg-icon/svg-icons";
import Counter from "../../../../counter/Counter";
import { checkDaysPresent, formatStringToAbbrevation, setTimeZone } from "../../../../../../helpers/Util";
import Tooltip from "../../../../Tooltip";
import { strings } from "../../../../../../constant/strings";
import IconWithBg from "components/common/IconWithBackground/icon-with-bg";
import Timeline from "components/common/Timeline/Timeline";
import IF from "components/common/IF";

const TimeLineRow = ({ data: timelineObject = null }) => {
  const renderOptions = {
    showDays: true,
    showHours: true,
    showMinutes: true,
    showSeconds: true,
    checkDaysCount: timelineObject?.checkDaysCount,
  };
  return (
    <div key={timelineObject?.id} className="d-flex flex-row justify-content-between">
      <div className={cx({ [styles.primaryText]: timelineObject?.isPrimaryColor })}>{timelineObject?.title}</div>
      <Counter
        startTime={timelineObject?.startTime}
        endTime={timelineObject?.endTime}
        fixedTimer={timelineObject?.fixedTimer}
        renderOptions={{ ...renderOptions, isPrimaryColor: timelineObject?.isPrimaryColor }}
      />
    </div>
  );
};

const ChevronCardDateTime = ({
  chevronAppointment,
  chevronTraversedDispositions,
  dispositionSlug,
  leadRefNumber,
  className,
}) => {
  const currentTraversedDisposition = chevronTraversedDispositions?.find(
    disposition => disposition?.slug === dispositionSlug
  );
  const { start_time, end_time } = chevronAppointment;
  const timeZone = useSelector(content => content.Configs.tenant.country.timezone);
  const tasksTooltipId = `tooltip-popup-chevron-timeline-${leadRefNumber}`;
  const renderOptions = {
    showDays: true,
    showHours: true,
    showMinutes: true,
    showSeconds: true,
  };

  const totalTimeTimelineObject = {
    id: chevronTraversedDispositions?.[0]?.id ?? "",
    startTime: chevronTraversedDispositions?.[0]?.timestamp ?? "",
    endTime: "",
    fixedTimer: false,
    isPrimaryColor: true,
  };

  const getDispositionsTimeline = useMemo(() => {
    const dispositionsTimeline = [];
    const daysPresent = checkDaysPresent(totalTimeTimelineObject.startTime);
    if (chevronTraversedDispositions?.length > 1) {
      for (let index = 0; index < chevronTraversedDispositions?.length - 1; index++) {
        const timelineObject = {
          title: `${formatStringToAbbrevation(chevronTraversedDispositions[index]?.name)} - ${formatStringToAbbrevation(
            chevronTraversedDispositions[index + 1]?.name
          )}`,
          id: `${chevronTraversedDispositions[index]?.id}-${chevronTraversedDispositions[index + 1]?.id}`,
          startTime: chevronTraversedDispositions[index]?.timestamp ?? "",
          endTime: chevronTraversedDispositions[index + 1]?.timestamp ?? "",
          fixedTimer: true,
          checkDaysCount: !daysPresent,
        };
        dispositionsTimeline.push(timelineObject);
      }
    }
    return dispositionsTimeline;
  }, [chevronTraversedDispositions]);

  return (
    <div className={cx("d-flex flex-row align-items-center justify-content-between mt-2", className)}>
      <div className="d-flex align-items-center">
        <IconWithBg icon={<GridIcon size={12} />} borderRadius="50%" />
        <div className={cx(styles.dateText, "ml-2")}>
          {moment(setTimeZone(timeZone, start_time)).format("h:mm A").toString()}
        </div>
      </div>
      <div className="d-flex align-items-end">
        <Counter startTime={currentTraversedDisposition?.timestamp} renderOptions={renderOptions} />
        <Fragment>
          <div id={tasksTooltipId} data-tip data-for={tasksTooltipId} className={cx("cursor-pointer")}>
            <MdInfoOutline size="1.325em" color="#667085" />
          </div>
          <Tooltip
            open={true}
            backgroundColor="white"
            textColor="black"
            className="cheveron-tooltip"
            id={tasksTooltipId}
          >
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="f-w-600" style={{ fontSize: "14px" }}>
                {strings.timeline}
              </div>
              <Counter
                {...totalTimeTimelineObject}
                renderOptions={{ ...renderOptions, isPrimaryColor: totalTimeTimelineObject?.isPrimaryColor }}
              />
            </div>
            <IF condition={getDispositionsTimeline?.length}>
              <Timeline
                style={{ marginBlockStart: "20px" }}
                timeLineData={getDispositionsTimeline ?? []}
                TimeLineBodyComponent={TimeLineRow}
              />
            </IF>
          </Tooltip>
        </Fragment>
      </div>
    </div>
  );
};

export default ChevronCardDateTime;
