import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import React from "react";
import cx from "clsx";

const ModalComponent = props => {
  const {
    open,
    toggle,
    title,
    body,
    headerClass,
    headerRightSection,
    bodyClass,
    footerClass,
    actions = [],
    className,
    onClosed,
    style = {},
    contentClassName,
    modalSpacingNone,
    size,
  } = props;
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className={`modal-dialog-centered${className ? ` ${className}` : ""}`}
      onClosed={onClosed}
      style={style}
      contentClassName={contentClassName}
      size={size}
    >
      {!!title && (
        <ModalHeader
          className={cx(headerClass ? headerClass : "", headerRightSection && "modalRigthSection")}
          toggle={toggle}
        >
          {title}
          {headerRightSection && <div className="ml-auto">{headerRightSection}</div>}
        </ModalHeader>
      )}
      {body && <ModalBody className={bodyClass ? bodyClass : ""}>{body()}</ModalBody>}
      {modalSpacingNone && <div>{modalSpacingNone()}</div>}
      {actions && actions.length > 0 && (
        <ModalFooter className={footerClass ? footerClass : ""}>
          {actions.map((item, index) => {
            const { label, loading, ...rest } = item;
            return (
              <Button
                className="btn-pill"
                color="primary"
                style={item?.disabled ? { cursor: "not-allowed", pointerEvents: "none" } : {}}
                {...rest}
                key={index}
              >
                {label}
                {loading && <i className="fa fa-refresh fa-spin" style={{ marginLeft: "8px" }} />}
              </Button>
            );
          })}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ModalComponent;
