import {
  APPOINTMENT_STATUSES_SLUGS,
  MODELS_FOR_VIEW_PHONE_NUMBER_API,
  PERMITTED_ATTRIBUTE_MODELS,
  PHONE_NUMBER_CATEGORIZATION_PER_PAGE,
  RESTRICTED_MODULES,
  SCROLLABLE_ELEMENTS_KEYS,
} from "constant/appConstants";
import { Formik, useFormikContext } from "formik";
import { fetchUserOptions } from "helpers/userHelper";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { crmLeadConnectsUpdate, crmLeadTaskStatusUpdate, crmLeadUpdate } from "../../../actions/crm/crm-detail.action";
import {
  clearCRMTaskDetail,
  revertTaskStatus,
  updateTaskDetail,
  updateTaskStatus,
} from "../../../actions/crm/task-detail.action";
import { updateTask, updateTaskMeetings } from "../../../actions/crm/tasks.action";
import { setCustomState } from "../../../actions/general/custom.action";
import { updateModelPermissions } from "../../../actions/general/permissions.action";
import { CRM_PAYMENTS_EDIT_PATH, CRM_PAYMENTS_NEW_PATH, CRM_TASKS_PATH } from "../../../constant/appPaths";
import { strings } from "../../../constant/strings";
import {
  addTaskApi,
  fetchDynamicFieldsApi,
  fetchTaskTypesListApi,
  revertTaskStatusApi,
  updateCRMTaskAttributesApi,
  updateTaskApi,
  updateTaskInventory,
} from "../../../helpers/ApiHelper";
import {
  addPermittedProperty,
  appendTimeZone,
  canEditPayment,
  checkUndefinedApiParams,
  clone,
  ConditionalLink,
  defaultFn,
  getErrorString,
  getPermission,
  handleEmptyFormPayload,
  isEmptyArray,
  isEmptyObject,
  isFieldEditable,
  isFieldRestricted,
  isFieldViewable,
  setTimeZone,
  subtractSeconds,
  validateFormikFields,
} from "../../../helpers/Util";
import { useFetch } from "../../../hooks";
import { selectTenant } from "../../../reducers/general/selectors";
import { selectLoggedInUser } from "../../../reducers/login/selectors";
import { CallButton, Close, CustomAlert, DynamicFields, FormInput, IF, Modal, Permissions, Toast } from "../../common";
import { getDynamicAttachmentIds } from "../../common/DyamicFields/DynamicFieldHelpers";
import DynamicFormFieldTask from "../../common/DyamicFields/DynamicFormFieldTask";
import AddTaskReminderModal from "../../common/modals/AddTaskReminderModal";
import { PaginatedSearch } from "../../common/PaginatedSearch";
import { AssignDateInput, TaskStatusInput } from "../../crm";
import AddReminder from "../../crm/AddReminder";
import FollowUp from "../../crm/FollowUp";
import SelectInventoryModal from "../../crm/SelectInventoryModal";
import SelectLeadModal from "../../crm/SelectLeadModal";
import { BiArrowToRight, IoMdAdd, IoMdRefresh, OnlineDot } from "../../svg-icon/svg-icons";
import TaskRecordingsCard from "../../tasks/TaskRecordingsCard";
import MeetingFormFields from "./MeetingFormFields";
import TaskTimer from "./TaskTimer/TaskTimer";

const FormikContext = props => {
  const { prefillTaskLayout } = props;
  const inventory = prefillTaskLayout?.payload?.inventory;
  const open = props.open;
  const { values, setFieldValue, setValues } = useFormikContext();
  const history = useHistory();
  const location = history?.location;

  useEffect(() => {
    if (isFieldEditable(props.crmTaskPermittedAttributes?.["inventory.title"])) {
      if (inventory && setFieldValue && open && !values.inventory) {
        setFieldValue("inventory", inventory);
      }
    }
  }, [inventory, setFieldValue, open, values, prefillTaskLayout]);

  const locationStateType = location?.state?.type ?? null;

  useEffect(() => {
    if (locationStateType) {
      if (locationStateType === "new-lead-from-task" && open) {
        const values = clone({ ...location?.state?.values, crm_lead: location?.state?.crm_lead });
        setValues(value => ({ ...value, ...values }));
        history.replace({
          pathname: `${CRM_TASKS_PATH}`,
          state: null,
        });
      }
    }
  }, [locationStateType, setValues, open]);

  return null;
};

const TaskForm = props => {
  const {
    fRef,
    toggleAddTaskLoading,
    open,
    onSuccess,
    taskId,
    taskFrom,
    closeSidebar,
    clearCRMTaskDetail,
    timeZone,
    inventory,
    loading,
    showMeetingCalender,
    crmLeadUpdate,
    onClose,
    disabled,
    onAppointmentUpdate,
    addAppointment,
    crmLeadConnectsUpdate,
    addTaskAppointments,
    history,
    revertTaskStatus,
    updateTaskStatus,
    crmLeadTaskStatusUpdate,
    updateTask,
    permittedMeta,
    updateModelPermissions,
    updateTaskMeetings,
    resetPrefill = defaultFn,
    reminderId,
    updateTaskDetail,
    disableRevertTaskButton = false,
  } = props;
  const dispatch = useDispatch();
  const crmTaskPermittedAttributes = props.permissions?.activeColumns.Task;
  const crmLeadPermittedAttributes = props.permissions?.activeColumns.CrmLead;
  const tenant = useSelector(selectTenant);
  const restrictedModules = tenant?.configuration?.restricted_modules;
  const isFollowUpRestricted = restrictedModules?.includes(RESTRICTED_MODULES.FOLLOW_UP);
  const isTenantDubizzle = tenant.slug ? tenant.slug.includes("dubizzle") : false;
  const isRecordingRestricted = restrictedModules?.includes(RESTRICTED_MODULES.RECORDING);

  const {
    data,
    loading: dynamicFieldLoading,
    error: dynamicFieldError,
  } = useFetch(() => open && fetchDynamicFieldsApi("task", "?limit=1000"), [], [open]);

  const lead = useSelector(content => content.CRMLeadDetail.lead);
  const task = useSelector(content => content.TaskDetail.task);
  const [tabIndex, setTabIndex] = useState(0);
  const [taskTypeDynamicAttributes, setTaskTypeDynamicAttributes] = useState(null);
  const [taskDynamicAttributes, setTaskDynamicAttributes] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showOnSubmitConfirmationModal, setShowOnSubmitConfirmationModal] = useState(false);
  const [editDynamicFields, setEditDynamicFields] = useState(false);
  const [paymentsCount, setPaymentsCount] = useState(3);
  const [addressesTab, setAddressesTab] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [isAdditionalFieldsUpdationLoading, setAdditionalFieldsUpdationLoading] = useState(false);

  const isEditTaskForm = !!taskId;
  const user = useSelector(selectLoggedInUser);

  useEffect(() => {
    if (!open && tabIndex !== 0) {
      setTabIndex(0);
    }
    if (!open) {
      resetPrefill();
    }
  }, [tabIndex, open]);

  useEffect(() => {
    if (permittedMeta && permittedMeta.bypass !== true && !isEmptyObject(permittedMeta.attributes_hash)) {
      updateModelPermissions(PERMITTED_ATTRIBUTE_MODELS.TASK, permittedMeta.attributes_hash);
    }
  }, [permittedMeta, updateModelPermissions]);

  const formInitialValues = {
    taskReOpened: false,
    reminder: null,
    ...addPermittedProperty(crmTaskPermittedAttributes?.["task_type.name"], {
      task_type: null,
    }),
    ...addPermittedProperty(crmTaskPermittedAttributes?.["name"], { name: "" }),

    ...addPermittedProperty(crmTaskPermittedAttributes?.["assignee.name"], { assignee: null }),

    ...addPermittedProperty(crmTaskPermittedAttributes?.["due_date"], { due_date: "" }),

    ...addPermittedProperty(crmTaskPermittedAttributes?.["selected_task_option.name"], {
      selected_task_option: null,
    }),
    ...addPermittedProperty(crmTaskPermittedAttributes?.["description"], { description: "" }),

    ...addPermittedProperty(crmTaskPermittedAttributes?.["inventory.title"], {
      inventory: null,
    }),
    ...addPermittedProperty(crmTaskPermittedAttributes?.["crm_lead.client.name"], {
      crm_lead: null,
    }),
    ...addPermittedProperty(crmTaskPermittedAttributes?.["appointments.id"], {
      meetings: null,
    }),
    dynamicAttributes: null,
    ...(isTenantDubizzle ? { language: null } : {}),
  };
  let formikRef = useRef();
  let taskTypeRef = useRef();
  let dynamicFieldsRef = useRef();

  useEffect(() => {
    formikRef.current && isFieldViewable(crmTaskPermittedAttributes?.["assignee.name"]);
    formikRef.current.setFieldValue("assignee", user);
  }, [open]);

  useEffect(() => {
    formikRef.current && formikRef.current.setFieldValue("dynamicAttributes", data?.dynamic_fields);
  }, [data]);

  useEffect(() => {
    taskTypeDynamicAttributes &&
      taskDynamicAttributes &&
      updateDynamicAttValues(taskTypeDynamicAttributes, taskDynamicAttributes);
  }, [taskDynamicAttributes, taskTypeDynamicAttributes]);

  useEffect(() => {
    !open && clearFields();
  }, [open]);

  useEffect(() => {
    inventory && setInventory(inventory);
  }, [inventory]);

  useEffect(() => {
    const ifSellerCategoryHasInventory =
      props?.lead && props?.lead?.category?.slug === "seller" && props?.lead?.inventory !== null;
    if (ifSellerCategoryHasInventory) {
      setInventory(props?.lead?.inventory);
    }
  }, [props.lead]);

  const setInventory = inventory => formikRef.current.setFieldValue("inventory", inventory);

  useEffect(() => {
    if (task && taskId) {
      const appoint =
        !isFieldRestricted(task?.appointments) &&
        isFieldViewable(crmTaskPermittedAttributes?.["appointments.id"]) &&
        task?.appointments?.length
          ? task?.appointments.map(e => ({
              ...e,
              meeting_area: e.area,
              comments: e.description,
            }))
          : null;

      const values = {
        ...formikRef.current.values,
        ...addPermittedProperty(crmTaskPermittedAttributes?.["task_type.name"], { task_type: task.task_type }),
        ...addPermittedProperty(crmTaskPermittedAttributes?.["name"], { name: task.name }),
        ...addPermittedProperty(crmTaskPermittedAttributes?.["assignee.name"], {
          assignee: task.assignee,
        }),

        ...addPermittedProperty(crmTaskPermittedAttributes?.["due_date"], {
          due_date: !isFieldRestricted(task.due_date) ? setTimeZone(timeZone, task.due_date, true) : task.due_date,
        }),
        ...addPermittedProperty(crmTaskPermittedAttributes?.["selected_task_option.name"], {
          selected_task_option: task.selected_task_option,
        }),
        ...addPermittedProperty(crmTaskPermittedAttributes?.["description"], { description: task.description }),
        ...addPermittedProperty(crmTaskPermittedAttributes?.["inventory.title"], {
          inventory: task.inventory,
        }),
        ...(!(taskFrom === "lead") &&
          !!taskId &&
          isFieldViewable(crmTaskPermittedAttributes?.["crm_lead.client.name"]) && { crm_lead: task.crm_lead }),
        ...(isFieldViewable(crmTaskPermittedAttributes?.["appointments.id"]) && { meetings: appoint && [...appoint] }),
      };
      formikRef.current.setValues(values);
      isFieldViewable(crmTaskPermittedAttributes?.["task_type.name"]) &&
        taskTypeRef.current &&
        taskTypeRef.current.setInitialValues();
    }
  }, [task, taskId]);

  useImperativeHandle(fRef, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
    setValues(values) {
      formikRef.current.setValues({ ...formikRef.current.values, ...values });
    },
    setFieldValue(key, value) {
      formikRef.current.setFieldValue(key, value);
    },
    getValues() {
      return formikRef.current.values;
    },
  }));

  const getInitialValues = () => ({ ...formInitialValues });

  const clearFields = () => {
    const values = { ...formInitialValues };
    formikRef.current.setValues(values);
    taskTypeRef.current && taskTypeRef.current.clearField();
    clearCRMTaskDetail();
  };

  const postTask = async (apiCall, ...rest) => {
    toggleAddTaskLoading(true);
    try {
      const response = await apiCall(...rest);
      if (response) {
        toggleAddTaskLoading(false);
        if (response.task) {
          if (taskFrom === "lead") {
            if (taskId) {
              crmLeadUpdate(response);
            } else {
              lead.tasks.push(response.task);
              crmLeadConnectsUpdate(response.task?.crm_lead?.connects);
            }
            closeSidebar();
          } else {
            onSuccess(response.task);
            addTaskAppointments && addTaskAppointments(response.task);
            closeSidebar();
          }
          formikRef.current && formikRef.current.setValues(formInitialValues);
        } else {
          Toast.error(getErrorString(response));
        }
      }
    } catch (error) {
      Toast.error(getErrorString(error));
    } finally {
      toggleAddTaskLoading(false);
    }
  };

  const validateOnNewMeeting = (isNewMeeting, meeting, setFieldError, key) => {
    if (!isNewMeeting) return false;
    const errorObj = {
      ...(tabIndex === 0 && !meeting?.seller_address && { sellerAddress: strings.field_required_message }),
      ...((tabIndex === 1) & !(meeting?.area || meeting?.meeting_area) && {
        meetingArea: strings.field_required_message,
      }),
      ...(!meeting?.start_time && { meetingTime: strings.field_required_message }),
      // ...([3, 2].includes(tabIndex) && !meeting?.location?.id && { location: strings.field_required_message }),
    };

    if (Object.keys(errorObj).length) {
      setFieldError(key, (() => JSON.stringify(errorObj))());
      return true;
    }
    return false;
  };

  const makeAppointmentData = (newMeeting, statusUpdate, values) => {
    if (statusUpdate)
      return [
        {
          id: newMeeting.id,
          status_id: newMeeting.status.id,
        },
      ];
    return [
      {
        ...(newMeeting.status?.slug === APPOINTMENT_STATUSES_SLUGS.RESCHEDULED && {
          status_id: newMeeting.status.id,
          id: newMeeting.id,
        }),

        ...addPermittedProperty(
          crmTaskPermittedAttributes?.["appointments.start_time"],
          {
            start_time: appendTimeZone(newMeeting.start_time, timeZone),
          },
          true
        ),
        ...addPermittedProperty(
          crmTaskPermittedAttributes?.["appointments.end_time"],
          {
            end_time: appendTimeZone(subtractSeconds(newMeeting.end_time), timeZone),
          },
          true
        ),
        ...(isFieldEditable(crmTaskPermittedAttributes?.["appointments.description"]) &&
          newMeeting.comments && { description: newMeeting.comments }),
        ...(isFieldEditable(crmTaskPermittedAttributes?.["appointments.area.name"]) && {
          area_id: newMeeting?.meeting_area?.id || null,
        }),

        ...(isFieldEditable(crmTaskPermittedAttributes?.["appointments.time_slot.id"]) && {
          time_slot_id: newMeeting?.seller_address?.id || null,
        }),
        ...addPermittedProperty(
          crmTaskPermittedAttributes?.["appointments.user.name"],
          {
            user_id: values?.assignee?.id,
          },
          true
        ),
        is_auto_assigned: newMeeting.autoAssign,
        ...addPermittedProperty(
          crmTaskPermittedAttributes?.["appointments.inventory.id"],
          {
            inventory_id: values.inventory.id,
          },
          true
        ),
        // ...(isFieldEditable(crmTaskPermittedAttributes?.["inventory.location.name"]) && {
        //   location_id: newMeeting?.meeting_area?.location_id || null,
        // }),
      },
    ];
  };

  const handleOnSubmitConfirmationModal = (values, { setFieldError }) => {
    let taskTypeDynamicFieldsError = false;
    taskTypeRef.current &&
      taskTypeRef.current.validateFields((taskTypeValues, e) => {
        taskTypeDynamicFieldsError = e;
      });

    let taskDynamicFieldsError = false;
    dynamicFieldsRef.current &&
      dynamicFieldsRef.current.validateFields((taskValues, e) => {
        taskDynamicFieldsError = e;
      });

    const validateFormikFieldsParams = [];
    isFieldViewable(crmTaskPermittedAttributes?.["name"]) && validateFormikFieldsParams.push(["name", values.name]);
    isFieldViewable(crmTaskPermittedAttributes?.["due_date"]) &&
      validateFormikFieldsParams.push(["due_date", values.due_date]);
    isFieldViewable(crmTaskPermittedAttributes?.["inventory.title"]) &&
      validateFormikFieldsParams.push([
        "inventory",
        values.inventory,
        inventory?.id ? false : values.task_type?.inventory_required,
      ]);
    isFieldViewable(crmTaskPermittedAttributes?.["crm_lead.client.name"]) &&
      validateFormikFieldsParams.push(["crm_lead", values.crm_lead, !(taskFrom === "lead")]);
    isFieldViewable(crmTaskPermittedAttributes?.["assignee.name"]) &&
      validateFormikFieldsParams.push([
        "assignee",
        values.assignee,
        !!values.newMeeting && values.newMeeting.autoAssign === false,
      ]);
    const notValid = validateFormikFields(validateFormikFieldsParams, setFieldError);
    const meetingError = validateOnNewMeeting(!!values.newMeeting, values.newMeeting, setFieldError, "meetings");
    if (taskTypeDynamicFieldsError || taskDynamicFieldsError || notValid || meetingError) {
      return;
    }
    setShowOnSubmitConfirmationModal(true);
  };

  const onSubmit = (values, { setFieldError }) => {
    const { task_type, name, assignee, selected_task_option, description, due_date, crm_lead, reminder } = values;

    const { status, ...rest } = reminder ?? {};

    let dynamic_attributes = {};
    let taskTypeDynamicFields = {};
    let taskDynamicFields = {};

    taskTypeRef.current &&
      taskTypeRef.current.validateFields((taskTypeValues, e) => {
        taskTypeDynamicFields = { ...taskTypeDynamicFields, ...taskTypeValues };
      });

    dynamicFieldsRef.current &&
      dynamicFieldsRef.current.validateFields((taskValues, e) => {
        taskDynamicFields = { ...taskDynamicFields, ...taskValues };
      });

    dynamic_attributes = {
      ...(!isEmptyObject(taskTypeDynamicFields?.task_type)
        ? { task_type: { ...taskTypeDynamicFields?.task_type } }
        : {}),
      ...(!isEmptyObject(taskDynamicFields?.task) && { task: { ...taskDynamicFields?.task } }),
    };

    let dynamicAttachmentIds = [
      ...getDynamicAttachmentIds(taskDynamicFields?.task),
      ...getDynamicAttachmentIds(taskTypeDynamicFields?.task_type),
    ];

    let obj = {
      task: {
        ...addPermittedProperty(
          crmTaskPermittedAttributes?.["name"],
          {
            name,
          },
          true
        ),

        ...addPermittedProperty(
          crmTaskPermittedAttributes?.["task_type.name"],
          {
            task_type_id: task_type?.id,
          },
          true
        ),
        ...addPermittedProperty(
          crmTaskPermittedAttributes?.["due_date"],
          {
            due_date: appendTimeZone(due_date, timeZone),
          },
          true
        ),
        ...addPermittedProperty(
          crmTaskPermittedAttributes?.["description"],
          {
            description,
          },
          true
        ),
        ...(reminder &&
          addPermittedProperty(
            crmTaskPermittedAttributes?.["reminder.name"],
            {
              reminders_attributes: [{ ...rest, user_id: user.id }],
            },
            true
          )),
        ...(!isEmptyObject({ ...taskTypeDynamicFields?.task_type, ...taskDynamicFields?.task }) && {
          dynamic_attributes,
        }),
        ...(!isEmptyArray(dynamicAttachmentIds) && {
          dynamic_attachment_ids: dynamicAttachmentIds,
        }),
        ...(isFieldEditable(crmTaskPermittedAttributes?.["assignee.name"]) &&
          assignee?.id && {
            assignee_id: assignee?.id,
          }),
        ...(isFieldEditable(crmTaskPermittedAttributes?.["selection_task_option.name"]) &&
          !!selected_task_option && {
            task_option_id: selected_task_option?.id,
          }),
        ...(isFieldEditable(crmTaskPermittedAttributes?.["inventory.title"]) &&
          !!task_type.inventory_required && {
            inventory_id: values?.inventory?.id,
          }),

        ...(taskFrom === "lead" &&
          isFieldEditable(crmLeadPermittedAttributes?.["client.name"]) && {
            crm_lead_id: lead?.id,
            crm_lead_disposition_id: lead?.disposition?.id,
          }),

        ...(taskFrom !== "lead" &&
          isFieldEditable(crmTaskPermittedAttributes?.["crm_lead.client.name"]) && {
            crm_lead_id: crm_lead?.id,
            crm_lead_disposition_id: crm_lead?.disposition?.id,
          }),

        ...(values.newMeeting &&
          isFieldEditable(crmTaskPermittedAttributes?.["appointments.id"]) && {
            appointments_attributes: makeAppointmentData(values?.newMeeting, false, values),
          }),
      },
    };
    if (!handleEmptyFormPayload(obj.task, "task")) {
      toggleAddTaskLoading(false);
      setShowOnSubmitConfirmationModal(false);
      return;
    }
    taskId ? postTask(updateTaskApi, taskId, obj) : postTask(addTaskApi, obj);
    setShowOnSubmitConfirmationModal(false);
  };
  const updateField = async (
    key,
    payloadKey,
    value,
    setFieldError,
    validate = false,
    validationKeys = [],
    onDone = () => {}
  ) => {
    if (!checkUndefinedApiParams(taskId, "task")) return;
    const notValid = validate && validateFormikFields([[key, value, ...validationKeys]], setFieldError);
    if (!notValid) {
      const response = await updateTaskApi(taskId, { task: { [payloadKey]: value } });
      if (response) {
        if (response.task) {
          onSuccess(response.task);
          onDone(response.task);
          crmLeadConnectsUpdate(response.task?.crm_lead?.connects);
        } else {
          Toast.error(getErrorString(response), { autoClose: 3000 });
        }
      }
    }
  };

  const dynamicAttributesValidationModal = () => {
    return (
      <Modal
        toggle={() => setShowModal(null)}
        title={strings.warning}
        open={!!showModal}
        body={() => {
          return <>{strings.save_your_changes}</>;
        }}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setShowModal(null);
            },
            color: "secondary",
            className: "modal__cancel-btn",
          },
        ]}
      />
    );
  };

  const updateDynamicAttValues = async (taskType, task) => {
    if (!checkUndefinedApiParams(taskId, "task")) return;
    if (taskType.error === true || task.error === true) return;
    let dynamicAttributes = {
      ...taskType.valuesObj.task_type,
      ...task.valuesObj.task,
    };
    let dynamicAttachmentIds = [
      ...getDynamicAttachmentIds(taskType?.valuesObj?.task_type),
      ...getDynamicAttachmentIds(task?.valuesObj?.task),
    ];
    setAdditionalFieldsUpdationLoading(true);
    const response = await updateTaskApi(taskId, {
      task: {
        ...(!isEmptyObject(dynamicAttributes) && {
          dynamic_attributes: {
            ...(!isEmptyObject(taskType.valuesObj.task_type) && { task_type: { ...taskType.valuesObj.task_type } }),
            ...(!isEmptyObject(task.valuesObj.task) && { task: { ...task.valuesObj.task } }),
          },
        }),
        ...(!isEmptyArray(dynamicAttachmentIds) && { dynamic_attachment_ids: dynamicAttachmentIds }),
      },
    });
    if (response) {
      setEditDynamicFields(false);
      if (response.task) {
        onSuccess(response.task);
        closeSidebar();
      } else {
        Toast.error(getErrorString(response));
      }
    }
    setAdditionalFieldsUpdationLoading(false);
  };

  const onChangeHandle = (key, setFieldValue) =>
    taskId
      ? {
          onSuccess: (value, payload) => {
            setFieldValue(key, value);
            onSuccess(payload.task || payload);
          },
        }
      : { onChange: value => setFieldValue(key, value) };

  const revertTaskStatusCall = async (taskId, setFieldValue) => {
    if (!checkUndefinedApiParams(taskId, "task")) return;
    const res = await revertTaskStatusApi(taskId);
    if (res && res.success) {
      revertTaskStatus(res.task);
      setFieldValue("taskReOpened", true);
      taskFrom === "lead"
        ? crmLeadTaskStatusUpdate({
            status: res.task.status,
            task_options: res.task.task_options,
            selected_task_option: res.task.selected_task_option,

            id: res.task.id,
          })
        : updateTask(res.task);
    } else {
      Toast.error(getErrorString(res));
    }
  };

  const RevertTaskStatusButton = ({ setFieldValue }) => (
    <button
      className="btn btn-pill btn-outline-primary py-2 px-4 ml-3 d-flex align-items-center"
      type="button"
      onClick={() => {
        revertTaskStatusCall(taskId, setFieldValue);
      }}
      disabled={disableRevertTaskButton}
    >
      <IoMdRefresh className="mr-2" />
      <span>{strings.reopen_task}</span>
    </button>
  );

  const handleEditInventory = async (item, setFieldValue) => {
    if (!checkUndefinedApiParams(taskId, "task")) return;
    if (!isFieldEditable(crmTaskPermittedAttributes?.["inventory.title"])) return;
    const payload = {
      task: {
        inventory_id: item.inventory.id,
      },
    };
    const response = await updateTaskInventory(taskId, { ...payload });
    if (response.success) {
      setFieldValue("inventory", response.task?.inventory);
      setFieldValue("meetings", response.task?.appointments);
      setFieldValue("newMeeting", null);
      setAddressesTab(false);
      setTabIndex(0);
      updateTaskMeetings(taskId, response.task?.appointments);
    } else {
      Toast.error(getErrorString(response));
    }
  };

  const getOnScrollTaskElement = useCallback(() => {
    return document.getElementById(SCROLLABLE_ELEMENTS_KEYS.TASK_FORM_KEY);
  }, [taskId]);

  return (
    <>
      <Formik innerRef={formikRef} initialValues={getInitialValues()} onSubmit={handleOnSubmitConfirmationModal}>
        {formikProps => {
          const { values, errors, touched, handleChange, status, setFieldValue, setFieldError } = formikProps;
          return (
            <form className="form theme-form db__form-main">
              <FormikContext
                prefillTaskLayout={props.prefillTaskLayout}
                open={open}
                crmTaskPermittedAttributes={crmTaskPermittedAttributes}
              />
              <AddTaskReminderModal
                task={task}
                reminderModal={reminderModal}
                setReminderModal={setReminderModal}
                isEditTaskForm={isEditTaskForm}
                setFieldValue={setFieldValue}
                reminder={values.reminder}
              />

              <Modal
                toggle={() => setShowOnSubmitConfirmationModal(!showModal)}
                title={"Create new task confirmation"}
                open={showOnSubmitConfirmationModal}
                body={() => <>{`Are you sure you want to create new task?`}</>}
                actions={[
                  {
                    label: strings.cancel,
                    onClick: () => {
                      setShowOnSubmitConfirmationModal(false);
                    },
                    color: "secondary",
                  },
                  {
                    label: strings.confirm,
                    color: "primary",
                    onClick: () => onSubmit(values, props),
                  },
                ]}
              />
              {}
              <div className="sidebar-overlay-header m-b-25 d-flex flex-row">
                {dynamicAttributesValidationModal()}
                {isFieldViewable(crmTaskPermittedAttributes?.["name"]) && (
                  <FormInput
                    inputClassName="form-control form-control-lg"
                    containerClassName="form-control-custom flex-grow-1 mr-2"
                    type="text"
                    placeholder={strings.name_ph}
                    onChange={handleChange("name")}
                    onBlur={event => {
                      taskId && updateField("name", "name", event.target.value, setFieldError, true);
                    }}
                    value={values.name}
                    errorMsg={errors.name && touched.name && errors.name}
                    loading={loading}
                    disabled={disabled}
                    isEditable={isFieldEditable(crmTaskPermittedAttributes?.["name"])}
                  />
                )}
                <IF condition={!task?.completed_at && !!task?.started_at}>
                  <TaskTimer startTime={task?.started_at ?? ""} />
                </IF>

                <Close onClick={onClose} isTransparent icon={<BiArrowToRight size="24px" />} type="button" />
              </div>
              {task?.status?.slug === "completed" && !values.taskReOpened && (
                <Permissions catagories="Task" actions="reopen">
                  <RevertTaskStatusButton setFieldValue={setFieldValue} />
                </Permissions>
              )}
              <div className="px-32">
                {isFieldViewable(crmTaskPermittedAttributes?.["task_type.name"]) && (
                  <DynamicFormFieldTask
                    {...formikProps}
                    activeColumnsPermittedAttributes={crmTaskPermittedAttributes}
                    fieldKey="task_type"
                    server
                    serverProps={{
                      controller: "task_types",
                      fetchCall: fetchTaskTypesListApi,
                      params: inventory ? `q[inventory_required_eq]=` : undefined,
                    }}
                    current={task}
                    open={open}
                    label={strings.task_type}
                    placeholder={strings.task_type_ph}
                    fieldErrorMsg="Please select value"
                    className="col-form-label form-group-x"
                    horizontal
                    ref={taskTypeRef}
                    onChange={() => {
                      if (isFieldEditable(crmTaskPermittedAttributes?.["task_type.name"])) {
                        setFieldValue("newMeeting", null);
                        setFieldValue("inventory", null);
                        setAddressesTab(false);
                      }
                    }}
                    loading={loading}
                    disabled={!!taskId || disabled}
                    textRight={false}
                    selectedSlug={""}
                    taskForm
                    FieldsParent
                    dynamicFieldsRef={dynamicFieldsRef}
                    setTaskDynamicAttributes={setTaskDynamicAttributes}
                    setTaskTypeDynamicAttributes={setTaskTypeDynamicAttributes}
                    editDynamicFields={editDynamicFields}
                    setEditDynamicFields={setEditDynamicFields}
                    isEditable={isFieldEditable(crmTaskPermittedAttributes?.["task_type.name"])}
                    taskReOpened={values.taskReOpened}
                    showEditButton={disabled}
                    prefillTaskLayout={props.prefillTaskLayout}
                    isAdditionalFieldsUpdationLoading={isAdditionalFieldsUpdationLoading}
                  >
                    {!isFieldRestricted(values.due_date) &&
                      isFieldViewable(crmTaskPermittedAttributes?.["due_date"]) && (
                        <AssignDateInput
                          initialValue={values.due_date}
                          label={strings.due_date}
                          selected={values.due_date ? values.due_date : null}
                          placeholder={strings.select_due_date}
                          {...onChangeHandle("due_date", setFieldValue)}
                          taskId={taskId}
                          errorMsg={errors.due_date}
                          loading={loading}
                          canUpdate={
                            isFieldEditable(crmTaskPermittedAttributes?.["due_date"]) &&
                            getPermission("Task", "change_due_date") &&
                            !disabled
                          }
                          canClear
                        />
                      )}
                    <AddReminder
                      label={strings.reminder}
                      horizontal
                      task={task}
                      reminder={values.reminder}
                      setReminderModal={setReminderModal}
                      reminderId={reminderId}
                      setTaskFieldValue={setFieldValue}
                      isEditTaskForm={isEditTaskForm}
                    />

                    <IF condition={!values?.task_type?.appointment_required}>
                      {isFieldViewable(crmTaskPermittedAttributes?.["assignee.name"]) && (
                        <PaginatedSearch
                          infoView
                          horizontal
                          label={strings.assignee}
                          toggleClassName="form-control text-truncate"
                          isSingleSelect
                          defaultSelected={values.assignee}
                          {...onChangeHandle("assignee", setFieldValue)}
                          selectHeading={strings.assignee}
                          id={taskId}
                          showSearchIcon={false}
                          // loading={leadsLoading}
                          fetchOptions={(input, page) =>
                            fetchUserOptions({
                              "s[name]": input,
                              criteria: "task",
                              task_type_id: values?.task_type?.id,
                              page,
                            })
                          }
                          updateDataApi={({ id, data }) => updateCRMTaskAttributesApi(id, data, "change_assignee")}
                          modelKey={"task"}
                          payloadKey={"assignee_id"}
                          disabled={
                            !isFieldEditable(crmTaskPermittedAttributes?.["assignee.name"]) ||
                            disabled ||
                            !values?.task_type?.id
                          }
                        />
                      )}
                    </IF>

                    <IF condition={!!taskId}>
                      {!isFieldRestricted(task?.task_options) &&
                        isFieldViewable(crmTaskPermittedAttributes?.["status.name"]) && (
                          <TaskStatusInput
                            label={strings.task_outcome}
                            status={task?.task_options}
                            selectedTask={values?.selected_task_option}
                            taskId={taskId}
                            canUpdate={isFieldEditable(crmTaskPermittedAttributes?.["status.name"]) && !disabled}
                            canClear={!disabled}
                            updateTask={response => {
                              response?.task && updateTaskDetail(response.task);
                              updateTaskStatus(response.task);
                              crmLeadUpdate(response);
                            }}
                            {...onChangeHandle("selected_task_option", setFieldValue)}
                          />
                        )}
                    </IF>
                    <IF condition={values?.task_type?.inventory_required === true}>
                      {isFieldViewable(crmTaskPermittedAttributes?.["inventory.title"]) && (
                        <SelectInventoryModal
                          values={values}
                          title={strings.select_inventory}
                          fromEdit={!!taskId}
                          showPrompt
                          isEditable={isFieldEditable(crmTaskPermittedAttributes?.["inventory.title"]) && !disabled}
                          editable={
                            isFieldEditable(crmTaskPermittedAttributes?.["inventory.title"]) &&
                            !disabled &&
                            !values.taskReOpened
                          }
                          currentValue={values.inventory}
                          onSelect={item => {
                            const isNewTask = !taskId;
                            if (isNewTask) return setFieldValue("inventory", item.inventory);
                            handleEditInventory(item, setFieldValue);
                          }}
                          onDeselect={() => {
                            if (props?.prefillTaskLayout?.onRemoveInventoryHandler) {
                              props.prefillTaskLayout.onRemoveInventoryHandler();
                            }
                            setFieldValue("inventory", null);
                          }}
                          detailsRoute={`cars/${values?.inventory?.inventoriable_id}/detail`}
                          history={history}
                          errorMsg={errors.inventory}
                          validateInventoryStatus
                          associatedId={taskId}
                          filterRemovedAndSold
                        />
                      )}
                    </IF>
                    <div className="position-relative">
                      <IF condition={!(taskFrom === "lead")}>
                        {isFieldViewable(crmTaskPermittedAttributes?.["crm_lead.client.name"]) && (
                          <SelectLeadModal
                            title={strings.select_lead}
                            fromEdit={!!taskId}
                            currentValue={values.crm_lead}
                            values={values}
                            isEditable={isFieldEditable(crmTaskPermittedAttributes?.["crm_lead.client.name"])}
                            editable={!taskId && !values.taskReOpened}
                            onSelect={item =>
                              taskId
                                ? updateField(
                                    "crm_lead",
                                    "crm_lead_id",
                                    item.id,
                                    setFieldError,
                                    true,
                                    [!(taskFrom === "lead")],
                                    () => setFieldValue("crm_lead", item)
                                  )
                                : setFieldValue("crm_lead", item)
                            }
                            onDeselect={() => setFieldValue("crm_lead", null)}
                            detailsRoute={`crm_leads/${values?.crm_lead?.id}/detail`}
                            history={history}
                            errorMsg={errors.crm_lead}
                            inventory={props.inventory}
                            removeLostLead
                          />
                        )}
                      </IF>
                      <div className={"form-group row"}>
                        {values.crm_lead && isEditTaskForm && !isRecordingRestricted && (
                          <Permissions catagories="Recording" actions="create">
                            <label className="col-sm-3">Call</label>
                            <div className="col-sm-9">
                              <CallButton
                                buttonColor="outline-primary"
                                buttonVariant="block"
                                size="sm"
                                buttonStyle={{ maxWidth: "100px" }}
                                taskId={task.reference_number}
                                associatedComponent={PHONE_NUMBER_CATEGORIZATION_PER_PAGE.TASK.FORM}
                                associatedType={MODELS_FOR_VIEW_PHONE_NUMBER_API.TASK_PHONE_KEY}
                                associatedId={values.crm_lead?.id}
                                uuid={values.crm_lead?.client?.uuid}
                                onScrollElement={getOnScrollTaskElement()}
                              />
                            </div>
                          </Permissions>
                        )}
                      </div>
                    </div>
                    <IF condition={isEditTaskForm && open}>
                      <TaskRecordingsCard taskId={taskId} />
                    </IF>
                    {isFieldViewable(crmTaskPermittedAttributes?.["appointments.id"]) && (
                      <IF condition={values?.task_type?.appointment_required === true}>
                        <MeetingFormFields
                          {...formikProps}
                          crmTaskPermittedAttributes={crmTaskPermittedAttributes}
                          showMeetingCalender={showMeetingCalender}
                          taskId={taskId}
                          disabled={disabled}
                          tabIndex={tabIndex}
                          setTabIndex={setTabIndex}
                          validateOnNewMeeting={validateOnNewMeeting}
                          onAppointmentUpdate={onAppointmentUpdate}
                          addAppointment={addAppointment}
                          makeAppointmentData={makeAppointmentData}
                          taskFrom={taskFrom}
                          addressesTab={addressesTab}
                          setAddressesTab={setAddressesTab}
                        />
                      </IF>
                    )}

                    {isEditTaskForm && values?.task_type?.payment_required === true && values?.inventory?.id && (
                      <>
                        <>
                          {getPermission("CrmPayment", "create") && (
                            <div className=" form-group row d-flex flex-row align-items-center">
                              <label className={`col-sm-3 col-form-label ${props.textRight ? "text-right" : ""}`}>
                                {strings.payment}
                              </label>
                              <div className="col-sm-9 d-flex flex-column align-items-start">
                                <button
                                  className="btn btn-pill btn-pill-icon-left btn-outline-primary d-flex
                          "
                                  disabled={!values.inventory}
                                  onClick={() => {
                                    dispatch(
                                      setCustomState({
                                        taskId,
                                        lead: taskFrom === "lead" ? lead : values?.crm_lead,
                                        inventory: formikRef.current?.values?.inventory || null,
                                      })
                                    );
                                    history.push({
                                      pathname: CRM_PAYMENTS_NEW_PATH,
                                      state: {
                                        taskId,
                                        from: "task",
                                        lead: taskFrom === "lead" ? lead : values?.crm_lead,
                                        inventory:
                                          {
                                            ...formikRef.current?.values?.inventory,
                                            carId: formikRef.current?.values?.inventory?.inventoriable_id,
                                            crm_lead: taskFrom === "lead" ? lead : values?.crm_lead,
                                          } || null,
                                      },
                                    });
                                  }}
                                  type="button"
                                >
                                  <IoMdAdd size="1.4em" />
                                  <span>{strings.add_payment}</span>
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                        {!isFieldRestricted(props.crmPayments) && !!props.crmPayments.length && (
                          <div className="form-group row d-flex flex-row align-items-center">
                            <label className={`col-sm-3 col-form-label ${props.textRight ? "text-right" : ""}`}></label>
                            <div className="col-sm-9 d-flex flex-column align-items-start">
                              <div className={`d-flex flex-column align-items-center w-100`}>
                                <div className="card flex-grow-1 w-100 border">
                                  <div className="card-header d-flex align-items-center p-b-15 p-t-15">
                                    <h5 className="text-muted " style={{ fontSize: "1.2rem" }}>
                                      {strings.all_payments}
                                    </h5>
                                  </div>
                                  <div
                                    className="card-body p-1"
                                    style={{ maxHeight: "180px", overflowX: "hidden", overflowY: "auto" }}
                                  >
                                    {props?.crmPayments.slice(0, paymentsCount).map((item, index) => (
                                      <ConditionalLink
                                        to={CRM_PAYMENTS_EDIT_PATH.replace(":id", item?.id)}
                                        condition={canEditPayment(item)}
                                        key={index}
                                      >
                                        <div className="d-flex align-items-center gap-3 border-bottom">
                                          <div className="p-2">
                                            <OnlineDot></OnlineDot>
                                          </div>
                                          <div className="p-2 d-flex flex-column">
                                            {!isFieldRestricted(item?.reference_number) && (
                                              <div className="text-muted">Ref {item?.reference_number}</div>
                                            )}
                                            {!isFieldRestricted(item?.crm_payment_type?.name) && (
                                              <div>{item?.crm_payment_type?.name}</div>
                                            )}
                                          </div>

                                          {!isFieldRestricted(item?.status?.name) && (
                                            <div
                                              className="ml-auto  p-2 mr-2 badge-status status-default"
                                              style={{
                                                backgroundColor: item?.status?.bg_color ?? "",
                                                color: item?.status?.color ?? "",
                                              }}
                                            >
                                              {item?.status?.name}
                                            </div>
                                          )}
                                        </div>
                                      </ConditionalLink>
                                    ))}
                                  </div>
                                  {props?.crmPayments.length > 2 && props?.crmPayments.length !== paymentsCount ? (
                                    <div
                                      className="w-100 text-center hover-pointer text-primary p-1 "
                                      onClick={() => {
                                        setPaymentsCount(props?.crmPayments.length);
                                      }}
                                    >
                                      Show More
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {isEditTaskForm && values?.task_type?.follow_up_required === true && !isFollowUpRestricted && (
                      <FollowUp assignee={values.assignee} taskId={taskId} fromTaskForm={true} />
                    )}

                    {isFieldViewable(crmTaskPermittedAttributes?.["description"]) && (
                      <FormInput
                        label={strings.description}
                        placeholder={strings.description_ph}
                        linesCount="4"
                        onChange={handleChange("description")}
                        onBlur={event => {
                          taskId && updateField("description", "description", event.target.value, setFieldError);
                        }}
                        value={values.description || ""}
                        errorMsg={errors.description && touched.description && errors.description}
                        horizontal
                        className="col-form-label form-group-x"
                        loading={loading}
                        textRight={false}
                        disabled={disabled}
                        isEditable={isFieldEditable(crmTaskPermittedAttributes?.["description"])}
                      />
                    )}

                    <div id="dynamicFields">
                      <DynamicFields
                        {...formikProps}
                        activeColumnsPermittedAttributes={crmTaskPermittedAttributes}
                        dataFromProps={{ data, loading: dynamicFieldLoading, error: dynamicFieldError }}
                        data={data}
                        loading={loading}
                        serverProps={{
                          controller: "task",
                          fetchCall: () => fetchDynamicFieldsApi("task", "?limit=1000"),
                        }}
                        model="task"
                        horizontal
                        textRight
                        ref={dynamicFieldsRef}
                        current={task}
                      />
                    </div>
                  </DynamicFormFieldTask>
                )}

                <CustomAlert message={status && status.message} />
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = ({ Configs, PermissionsData }) => ({
  timeZone: Configs.tenant.country.timezone,
  permissions: PermissionsData,
  permittedMeta: PermissionsData.permittedMeta,
});

const ConnectedComponent = connect(mapStateToProps, {
  crmLeadUpdate,
  clearCRMTaskDetail,
  crmLeadConnectsUpdate,
  revertTaskStatus,
  updateTaskStatus,
  crmLeadTaskStatusUpdate,
  updateTask,
  updateModelPermissions,
  updateTaskMeetings,
  updateTaskDetail,
})(TaskForm);

export default forwardRef((props, ref) => <ConnectedComponent {...props} fRef={ref} />);
