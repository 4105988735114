import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { validationSchemaHelper } from "../helpers/FormikHelpers";
import { isEmptyObject } from "../helpers/Util";
import { selectTenant } from "../reducers/general/selectors";

export const useValidation = (permittedAttributes, dynamicFields, staticSchema, dynamicModel) => {
  const [formikValidationSchema, setFormikValidationSchema] = useState({});
  const tenant = useSelector(selectTenant);

  const formikValidationSchemaHelper = validationSchemaHelper(
    formikValidationSchema,
    permittedAttributes,
    tenant,
    dynamicFields,
    setFormikValidationSchema,
    dynamicModel,
    staticSchema
  );
  useEffect(() => {
    isEmptyObject(formikValidationSchema) && !!dynamicFields && formikValidationSchemaHelper.setSchema();
  }, [formikValidationSchemaHelper, formikValidationSchema, dynamicFields, staticSchema]);

  return { formikValidationSchemaHelper };
};

export default useValidation;
