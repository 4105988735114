import React from "react";
import { Skeleton } from "../../../../components/common";

const QCQualityRatingSkeleton = () => {
  return (
    <>
      <div className="card">
        <div className={"card-body"} style={{ height: "100%" }}>
          <div className="grid" style={{ "--template": "repeat(2,1fr)", gap: "10px 64px" }}>
            {[1, 2, 3, 4, 5, 6, 7].map(e => (
              <div key={e} className={"d-flex"} style={{ flexDirection: "column", gap: 5 }}>
                <Skeleton width={100} height={20} />
                <Skeleton width={"100%"} height={10} style={{ borderRadius: 20 }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default QCQualityRatingSkeleton;
