import React from "react";
import cx from "clsx";

const RadioButton = props => {
  const { label, errorMsg, isEditable = null, radioClassName = "", labelClassName = "", ...rest } = props;
  return (
    <>
      <div className="d-flex flex-row align-items-center m-r-15">
        <input
          disabled={isEditable !== null && !isEditable}
          type="radio"
          className={cx("cursorPointer", radioClassName)}
          {...rest}
        />
        <label htmlFor={rest.id} className={cx("col-form-label m-l-5 cursorPointer", labelClassName)}>
          {label}
        </label>
        {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
      </div>
    </>
  );
};

export default RadioButton;
