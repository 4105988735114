import React from "react";
import cx from "clsx";
import styles from "./TaskTimer.module.scss";
import { strings } from "constant/strings";
import { Counter } from "components/common";

const TaskTimer = ({ startTime }) => {
  const taskCounterRenderOptions = {
    showDays: true,
    showHours: true,
    showMinutes: true,
    showSeconds: true,
    customCounterStyles: styles.customCounterStyles,
  };
  return (
    <div className={cx("d-flex flex-direction-row align-items-center text-bold f-w-600", styles.taskTimerContainer)}>
      <div className="mr-2">{strings.task_timer}</div>
      <Counter renderOptions={taskCounterRenderOptions} startTime={startTime} />
    </div>
  );
};

export default TaskTimer;
