import React, { useState, Fragment, useEffect } from "react";
import { strings } from "../../constant/strings";
import { addInventorytagApi, deleteInventoryTagApi } from "../../helpers/ApiHelper";
import { Button, Modal, Tooltip, Toast } from "../common";
import { SearchInput } from "../common/SearchInput";
import { CrossIcon, PenIcon, TagsIcon } from "../svg-icon/svg-icons";
import { updateTags } from "../../actions/general/carDetail.action";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { getErrorString, getPermission, isFieldRestricted } from "../../helpers/Util";
import { Tooltip as ChakraTooltip } from "@chakra-ui/react";

const TAGS_LIMIT = 20;
const CHARACTER_LIMIT = 30;
const NO_OF_TAGS_ON_INVENTORY_DETAIL = 2;

const TagName = ({ nameForToolTip }) => {
  return (
    <>
      <ChakraTooltip
        padding={"10px"}
        borderRadius={"4px"}
        fontSize={"12px"}
        fontWeigth={"400"}
        color={"#393535DE"}
        maxWidth={"150px"}
        marginInline={"auto"}
        backgroundColor={"white"}
        label={nameForToolTip}
      >
        {`${nameForToolTip.slice(0, 20)}...`}
      </ChakraTooltip>
    </>
  );
};

export default function CarTags({ tags, carId, isColumn = false }) {
  const [createModal, setCreateModal] = useState(false);
  const sortedTags = tags ? tags.sort((a, b) => a.id - b.id) : [];
  return (
    <div className="d-flex" style={{ gap: 6 }}>
      <ModalContainer carId={carId} tags={sortedTags} createModal={createModal} setCreateModal={setCreateModal} />

      {tags &&
        tags.slice(0, NO_OF_TAGS_ON_INVENTORY_DETAIL).map(item => {
          return !isFieldRestricted(item.name) ? (
            <div key={item.name} className={"carTag"}>
              {item.name.length > 20 ? <TagName nameForToolTip={item.name} /> : item.name}
            </div>
          ) : null;
        })}
      {getPermission("Inventory", "add_tags") && !isColumn && (
        <Button
          btnText="Edit Tags"
          icon={<PenIcon />}
          color="monochrome"
          size="sm"
          variant="rounded"
          className="border-0"
          onClick={e => {
            setCreateModal(true);
          }}
        />
      )}

      {tags && tags.length ? <TagsOnHover tags={tags} isColumn={isColumn} /> : null}
    </div>
  );
}
const ModalContainer = ({ tags, carId, createModal, setCreateModal }) => {
  const dispatch = useDispatch();
  const [tag, setTag] = useState("");
  const [loading, setLoading] = useState(false);
  const saveTag = async () => {
    setLoading(true);
    let res = await addInventorytagApi(carId, { tag: { name: tag } });
    if (res.success) {
      setLoading(false);
      Toast.success(res.message);
      dispatch(updateTags(res.tags));
      setTag("");
    } else {
      setLoading(false);
      Toast.error(getErrorString(res));
    }
  };
  const removeInventoryTag = async tagId => {
    let res = await deleteInventoryTagApi(carId, { tag: { id: tagId } });
    if (res.success) {
      dispatch(updateTags(res.tags));
    } else {
      Toast.error(getErrorString(res));
    }
  };

  return (
    <Modal
      toggle={() => {
        setCreateModal(!createModal);
        setTag("");
      }}
      title={"Tags"}
      open={createModal}
      className="save_model modal-lg"
      body={() => (
        <ModalBody tags={tags} tag={tag} setTag={setTag} saveTag={saveTag} removeInventoryTag={removeInventoryTag} />
      )}
      actions={[
        {
          label: strings.cancel,
          onClick: () => {
            setCreateModal(false);
          },
          color: "secondary",
          className: "modal__cancel-btn btn-pill",
        },
        {
          label: strings.add_tag,
          color: "primary",
          className: "modal__cr-btn btn-pill",
          onClick: () => {
            saveTag();
          },

          loading: loading,
          disabled: tag === "",
        },
      ]}
    />
  );
};

const ModalBody = ({ tags, tag, setTag, saveTag, removeInventoryTag }) => (
  <div className="form theme-form ">
    <SearchInput
      inputStyle={"pl-5"}
      icon={<TagsIcon className="tag-icon" />}
      className="save_model_row mb-0 tags"
      placeholder={"Add a tag..."}
      onChange={e => {
        setTag(e.target.value);
      }}
      onKeyPress={e => {
        if (e.charCode == 13) {
          saveTag();
        }
      }}
      value={tag}
      maxlength="30"
    />
    <div className="d-flex m-2">
      <div className="d-flex mr-1">
        <div className="text-muted">Max Tags:</div>
        <div className=" ml-1 mr-2 text-primary">{TAGS_LIMIT - tags.length}</div>
      </div>
      <div className="d-flex">
        <div className="text-muted">Character Left:</div>
        <div className=" ml-2 text-primary">{+CHARACTER_LIMIT - tag.length}</div>
      </div>
    </div>
    <div className="m-1 quick-links d-flex flex-wrap">
      {tags &&
        tags.map(item => (
          <Fragment key={item.id}>
            <div className={"carTag d-flex align-items-center p-2  m-1 "}>
              <>{item.name}</>
              <CrossIcon
                className={"ml-1 cursor-pointer"}
                onClick={() => {
                  removeInventoryTag(item.id);
                }}
              ></CrossIcon>
            </div>
          </Fragment>
        ))}
    </div>
  </div>
);
const TagsOnHover = ({ tags, isColumn }) => {
  const dataTipId = `inventory-tags-tooltip`;
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tags]);
  return (
    <div className="">
      {tags?.length > NO_OF_TAGS_ON_INVENTORY_DETAIL && (
        <div
          // size={"1rem"}
          style={{
            height: 30,
            width: 30,
            border: "1px dashed #9CA6AF",
            borderRadius: "50%",
            display: "grid",
            placeContent: "center",
            color: "#5A5A5A",
          }}
          className="cursor-pointer"
          data-tip
          data-for={dataTipId}
        >
          {isColumn ? "i" : `+${tags.length - +NO_OF_TAGS_ON_INVENTORY_DETAIL}`}
        </div>
      )}
      <Tooltip
        className="pointer-events-unset"
        id={dataTipId}
        backgroundColor="white"
        place="bottom"
        textColor="black"
        isCapture
      >
        <div className="taskTooltipWrap p-0 m-0" style={{ width: "400px" }}>
          <div className=" bg-white card-header p-1 m-1 d-flex align-items-center  ">
            <h5 className="mb-2 ">{"Tags"}</h5>
          </div>
          <div className=" d-flex flex-wrap">
            {tags &&
              tags.slice(NO_OF_TAGS_ON_INVENTORY_DETAIL, tags.length).map(
                item =>
                  !isFieldRestricted(item.name) && (
                    <div key={item.name} className={"btn btn-light m-1"} style={{ cursor: "default" }}>
                      {item.name}
                    </div>
                  )
              )}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
