import React from "react";
import { Space as AntdSpace } from "antd";

const Space = props => {
  const { compact, ...rest } = props;

  return compact ? <AntdSpace.Compact {...rest}></AntdSpace.Compact> : <AntdSpace {...rest}></AntdSpace>;
};

export default Space;
