import { ADD_GROUP, DELETE_GROUP, FETCH_GROUPS, UPDATE_GROUP, SET_GROUPS_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_GROUP:
      return {
        ...state,
        list: [action.payload.item].concat([...state.list]),
      };

    case UPDATE_GROUP:
      const groupList = state.list;
      let index = groupList.findIndex(e => e.id === action.payload.item.id);
      index > -1 && groupList.splice(index, 1, { ...action.payload.item });
      return {
        ...state,
        list: [...groupList],
      };

    case DELETE_GROUP:
      const groupListToDelete = state.list;
      let deleteIndex = groupListToDelete.findIndex(e => e.id === action.payload.id);
      deleteIndex > -1 && groupListToDelete.splice(deleteIndex, 1);
      return {
        ...state,
        list: [...groupListToDelete],
      };

    case FETCH_GROUPS:
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
        list: [...action.payload.list],
        pagination: action.payload?.pagination ? { ...action.payload.pagination } : { ...state.pagination },
      };

    case SET_GROUPS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
