import { FETCH_CRM_APPROVALS, UPDATE_CRM_APPROVAL, SET_CRM_APPROVALS_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  dispositions: [],
  permittedMeta: {},
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CRM_APPROVALS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CRM_APPROVAL:
      return {
        ...state,
        list: action.payload,
      };
    case SET_CRM_APPROVALS_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };
    default:
      return state;
  }
};
