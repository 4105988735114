import React from "react";
import ReactAvatar from "react-avatar";
import { Skeleton } from ".";
import { capitalizeFirstLetters } from "../../helpers/Util";
import cx from "clsx";
export const Avatar = ({
  members = [{}],
  name = "",
  single = true,
  avatarOnly = false,
  loading = false,
  containerClass = "",
  onClick = () => {},
  imgSrc = "",
  size = 30,
  userPanel = null,
  style = {},
  customAvatarStyle = {},
  ...rest
}) => {
  const renderSkeleton = () =>
    avatarOnly ? (
      <Skeleton height={size || 40} width={size || 40} circle />
    ) : (
      <div className="c-avatar d-flex flex-row align-items-center justify-content-center">
        <Skeleton height={size || 40} width={size || 40} circle />
        <Skeleton height={20} />
      </div>
    );
  return loading ? (
    renderSkeleton()
  ) : (
    <div
      className={`c-avatar d-flex flex-${single ? "row" : "column"} align-items-center justify-content-center ${
        containerClass ? containerClass : ""
      }`}
      onClick={onClick}
    >
      <div className={`avatar-collection ${avatarOnly ? "avatar-only" : ""}`.trim()}>
        {members.map((e, i) => (
          <div className="d-inline-block" key={i}>
            {imgSrc === "" || imgSrc === null ? (
              <ReactAvatar
                name={name?.trim()[0]}
                size={size}
                textSizeRatio={2}
                round
                style={{ fontFamily: "'Work Sans', sans-serif", ...customAvatarStyle }}
                {...rest}
              />
            ) : (
              <img
                src={imgSrc}
                width={userPanel ? "70px" : "28px"}
                height={userPanel ? "70px" : "28px"}
                alt="profile_picture"
                className="rounded-circle"
              />
            )}
          </div>
        ))}
      </div>
      {!avatarOnly && (
        <div className={cx("flex-grow-1")} style={style}>
          {capitalizeFirstLetters(name)}
        </div>
      )}
    </div>
  );
};
