import { FETCH_CRM_PAYMENTS, UPDATE_CRM_PAYMENT, SET_CRM_PAYMENTS_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  statuses: [],
  PaymentLinkStatuses: [],
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CRM_PAYMENTS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CRM_PAYMENT:
      const index = state.list.findIndex(item => item.id === action.payload.item.id);
      const updatedList = [...state.list];
      updatedList.splice(index, 1, action.payload.item);
      return {
        ...state,
        list: updatedList,
      };
    case SET_CRM_PAYMENTS_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };
    default:
      return state;
  }
};
