import React from "react";
import { strings } from "../../constant/strings";
import MarkAsWinnerForm from "../../pages/auction/MarkAsWinnerForm";
import { Modal } from "../common";

const MarkAsWinnerModal = ({
  data,
  offerId,
  setShowMarkWinnerDialog,
  showMarkWinnerDialog,
  offersObject,
  markAsWinnerRef,
  fetchOffers,
  latestAuctionId,
  setLoadingWinner,
  loadingWinner,
  liveNegotiationOffer,
}) => {
  return (
    <Modal
      toggle={() => {
        setShowMarkWinnerDialog(prev => !prev);
      }}
      title={strings.mark_as_winner}
      open={showMarkWinnerDialog}
      className="offer-trails-modal"
      body={() => (
        <>
          <MarkAsWinnerForm
            offer={offersObject?.offers?.find(offer => offer?.id === offerId) ?? null}
            offerId={offerId}
            ref={markAsWinnerRef}
            setOpenModal={setShowMarkWinnerDialog}
            openModal={showMarkWinnerDialog}
            fetchOffers={fetchOffers}
            auctionCycle={latestAuctionId}
            setLoading={setLoadingWinner}
            offerAmount={liveNegotiationOffer?.negotiated_bid}
          />
        </>
      )}
      actions={[
        {
          label: strings.cancel,
          onClick: () => {
            setShowMarkWinnerDialog(false);
          },
          color: "secondary",
          className: "modal__cancel-btn btn-pill",
        },
        {
          label: strings.done,
          color: "primary",
          className: "modal__cr-btn btn-pill",
          onClick: () => {
            markAsWinnerRef.current && markAsWinnerRef.current.submitForm();
          },
          disabled: loadingWinner,
          loading: loadingWinner,
        },
      ]}
    />
  );
};

export default MarkAsWinnerModal;
