import { Check, Search } from "react-feather";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { IF, Loader, Skeleton } from ".";
import React, { useEffect, useState } from "react";

import { CustomAlert } from "./CustomAlert";
import OutsideAlerter from "./OutsideAlerter";

const DropdownSelect = props => {
  const {
    label,
    options,
    menuStyles,
    color = "default",
    size = "",
    selected,
    renderMenuItem,
    renderToggleItem,
    caret = true,
    noSelectValue,
    toggleClick = () => {},
    labelAsValue = true,
    showSearchIcon,
    onChange = () => {},
    show = true,
    errorMsg,
    className = "",
    toggleClassName = "",
    toggleStyles = "",
    disabled = false,
    loading,
    listLoading,
    listError,
    selectOnConfirmationModal = false,
    alignment = null,
    icon = null,
    optionClassName = "",
    isSingleSelect = true,
    isTextType = false,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(labelAsValue ? label : selected);

  useEffect(() => {
    selected && !selectOnConfirmationModal && setDropdownValue(labelAsValue ? label : selected);
    selectOnConfirmationModal && !selected && setDropdownValue(label);
  }, [selected, selectOnConfirmationModal]);

  const toggle = () => {
    if (props.isFilter) {
      !disabled &&
        show &&
        setDropdownOpen(prev => {
          return isSingleSelect ? !prev : true;
        });
    } else {
      !disabled && show && setDropdownOpen(prevState => !prevState);
    }
  };

  function dropdownDisplay(renderToggleItem) {
    let displayValue;
    if (renderToggleItem) {
      if (dropdownValue || selected) {
        displayValue = renderToggleItem(dropdownValue);
      } else {
        displayValue = label;
      }
    } else {
      displayValue = dropdownValue;
    }

    return (
      <>
        {displayValue}
        {icon && icon}
      </>
    );
  }

  return loading ? (
    <Skeleton height={30} />
  ) : (
    <OutsideAlerter
      cb={() => {
        if (props.isFilter) {
          setDropdownOpen(false);
        }
      }}
    >
      <Dropdown className={className} isOpen={dropdownOpen} toggle={toggle} direction="down">
        {showSearchIcon ? (
          <Search size={18} className={`search-icon ${props.filterSelected ? "selected-filter-svg" : ""}`} />
        ) : null}
        <div className="d-flex flex-row align-items-center">
          <DropdownToggle
            color={color}
            aria-haspopup
            size={size}
            className={`${disabled ? "disabled" : ""} ${toggleClassName}`}
            styles={toggleStyles}
            caret={caret}
            onClick={() => toggleClick()}
          >
            {dropdownDisplay(renderToggleItem)}
          </DropdownToggle>
          <CustomAlert message={listError} type="icon" containerClassName="ml-2" />
        </div>
        {!!errorMsg && <p className="invalid-error ml-1">{errorMsg}</p>}
        <DropdownMenu className={menuStyles} right={alignment} style={{ zIndex: 900 }}>
          <IF condition={listLoading}>
            <Loader />
          </IF>
          {!!options?.length &&
            options
              .filter(e => e.id !== selected?.id)
              .map((e, i) => {
                return (
                  <DropdownItem
                    className={optionClassName}
                    key={JSON.stringify(e)}
                    onClick={event => {
                      const newDropdownValue = labelAsValue ? e.label : e?.name || e;
                      !noSelectValue && !selectOnConfirmationModal && setDropdownValue(newDropdownValue);
                      onChange(e);
                    }}
                    header={e.header}
                    text={isTextType}
                  >
                    {renderMenuItem ? renderMenuItem(e) : e.label || e.name}
                    {e.selected && <Check className="check__icon" />}
                  </DropdownItem>
                );
              })}
        </DropdownMenu>
      </Dropdown>
    </OutsideAlerter>
  );
};

export default DropdownSelect;
