import React from "react";
import ReactTooltip from "react-tooltip";

const Tooltip = ({ children, ...rest }) => (
  <ReactTooltip isOpen={true} {...rest}>
    {children}
  </ReactTooltip>
);

export default Tooltip;
