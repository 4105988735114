import React from "react";

import { CallProgressBar } from "./CallProgressBar";
import CallOverallQualityProgress from "./CallOverallQualityProgress";
import { IF } from "../../../components/common";

import QCHeaderSection from "./QCHeaderSection";

const QCSummary = props => {
  const { showCallInfoStats = false, qcSummaryData, callRecordingDetails = {} } = props;
  const { loading: qcStatsLoading, qcSectionWiseRating, qcOverallRatingScore } = qcSummaryData;

  return (
    <div>
      <QCHeaderSection callRecordingDetails={callRecordingDetails} />
      <div>
        <IF condition={showCallInfoStats}>
          <div className="grid p-relative" style={{ "--template": "minmax(0, 1fr) 40%" }}>
            {/* Inspection Performance Feedback */}
            <CallProgressBar qcStatsLoading={qcStatsLoading} sectionsRating={qcSectionWiseRating} />

            {/* Inspection Overall Rating */}
            <CallOverallQualityProgress qcStatsLoading={qcStatsLoading} callQualityScore={qcOverallRatingScore} />
          </div>
        </IF>
      </div>
    </div>
  );
};

export default QCSummary;
