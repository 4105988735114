import React, { useState, useEffect, useRef, useCallback } from "react";
import { TreeSelect, Spin, Tooltip } from "antd";
import { objectToQuery } from "../../helpers/Util";
import { BiCaretDown } from "react-icons/bi";
import styles from "./NestedCheckboxDropdown.module.scss";
import { debounce } from "lodash";

const NestedCheckboxDropdown = ({
  listApi,
  extraParams,
  listProperty,
  onChecked,
  filterKey,
  filtersObject,
  label,
  queryKey = "s[name]",
}) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const dropdownRef = useRef();

  useEffect(() => {
    onLoadData();
  }, []);
  useEffect(() => {
    if (filtersObject && filterKey in filtersObject && filtersObject[filterKey] != null) {
      onLoadData();
      const initialValue = filtersObject[filterKey].split(",").map(id => id.trim());
      setSelectedKeys(initialValue);
    } else {
      setSelectedKeys([]);
    }
  }, [filtersObject, filterKey]);

  const onLoadData = async (search = null) => {
    if (loading || !hasMore) return;
    setLoading(true);
    let queryObj = { page, ...extraParams };
    if (search !== null) {
      queryObj[queryKey] = search;
    }
    try {
      const response = await listApi(objectToQuery(queryObj));
      const formattedData = formatData(response[listProperty]);
      setTreeData(prev => {
        if (search === "") {
          return formattedData;
        } else {
          const existingValues = new Set(prev.map(item => item.value));
          const newData = formattedData.filter(item => !existingValues.has(item.value));
          return [...prev, ...newData];
        }
      });
      setPage(prev => response?.pagination.next_page);
      setHasMore(response?.pagination.next_page !== null);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce(search => {
      onLoadData(search);
    }, 300),
    [listApi, extraParams]
  );

  const onSearch = searchText => {
    setPage(1);
    setHasMore(true);
    debouncedSearch(searchText);
  };

  const handleScroll = e => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      onLoadData();
    }
  };

  const formatData = data => {
    let formatted = [];
    Object.keys(data).forEach(key => {
      let children = data[key].map(item => ({
        title: item.name,
        value: `${item.id}`,
        key: `${item.id}`,
      }));
      formatted.push({
        title: key,
        value: key,
        childrenIds: data[key].map(item => item.id),
        children,
      });
    });
    return formatted;
  };

  const onChange = newValue => {
    setSelectedKeys(newValue);
    let selectedChildren = [];
    newValue.forEach(value => {
      treeData.forEach(parentNode => {
        if (parentNode.value === value) {
          selectedChildren.push(...parentNode.children);
        } else {
          const foundChild = parentNode.children.find(child => child.value.toString() === value);
          if (foundChild) {
            selectedChildren.push(foundChild);
          }
        }
      });
    });
    onChecked({ selectedChildren, filterKey, label, setValue: setSelectedKeys, filtersObject });
  };

  return (
    <TreeSelect
      ref={dropdownRef}
      value={selectedKeys}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      treeData={treeData}
      treeCheckable
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      placeholder={label}
      loading={loading}
      onChange={onChange}
      suffixIcon={!loading ? <BiCaretDown size={10} color={"#898989"} /> : <Spin size="small" />}
      className={styles.treeSelect}
      popupClassName={styles.treeSelectPopup}
      onPopupScroll={handleScroll}
      filterOption={false}
      maxTagCount={1}
      onSearch={onSearch}
      maxTagTextLength={25}
      maxTagPlaceholder={omittedValues => {
        return (
          <Tooltip overlayStyle={{ pointerEvents: "none" }} title={omittedValues.map(({ label }) => label).join(", ")}>
            <span>+{omittedValues.length}</span>
          </Tooltip>
        );
      }}
    />
  );
};

export default NestedCheckboxDropdown;
