import T from "../components/common/Translate";

export const strings = {
  crm_lead: T("Crm Lead"),
  preferences: T("Preferences"),
  signed_at: T("Signed At"),
  children: T("Dependent fields"),
  select_children: T("Select Dependent Fields"),
  preferred_buyer_lead_required: T("Preferred buyer lead is required"),
  vas_category: T("Vas Category"),
  select_vas_category: T("Select Vas Category"),
  add_vas_lead: T("Add Vas Lead"),
  select_vas_category_type: T("Select Vas Category Type"),
  create_vas_lead: T("Create Vas Lead"),
  vas_lead: T("Vas Lead"),
  vas_preferred_buyer_lead: T("VAS Preferred Buyer Lead"),
  vas: T("VAS"),
  refresh: T("Refresh"),
  delete_leave: T("Delete Leave"),
  edit_schedule: T("Edit Schedule"),
  search_user: T("Search User"),
  search_role: T("Search Role"),
  rota_plan: T("Rota Plan"),
  follow_up_type: T("Follow Up Type"),
  final_amount: T("Final Amount"),
  call_outcome: T("Call Outcome"),
  pdf_shared: T("Pdf Shared"),
  share_report: T("Share Report"),
  share_invoice: T("Share Invoice"),
  invoice_shared: T("Invoice Shared"),
  send_invoice: T("Send Invoice"),
  send_reciept: T("Send Receipt"),
  copy_link: T("Copy Link"),
  services: T("Services"),
  service_package: T("Serv.Pkg."),
  related_details: T("Related Details"),
  total_booking_amount: T("Total Booking Amount"),
  none: T("None"),
  bidder_dre_off_location: T("Bidder Dre-off Location"),
  car_location: T("Car Location"),
  receiver_address: T("Receiver Address"),
  receiver_coordinates: T("Receiver Coordinates"),
  booking_reference_number: T("Booking Reference No."),
  buyer_lead_reference_number: T("Buyer Lead Reference No."),
  seller_lead_reference_number: T("Seller Lead Reference No."),
  crm_payment_reference_number: T("CRM Payment Reference No."),
  offer_reference_number: T("Offer Reference No."),
  offer_amount: T("Offer Amount"),
  lead_reference_number: T("Lead Reference Number"),
  car_reference_number: T("Car Reference No."),
  receiver_phone: T("Receiver Phone"),
  view_follow_up: T("View Follow Up"),
  create_follow_up: T("Create Follow Up"),
  add_follow_up: T("Add Follow Up"),
  follow_up: T("Follow Up"),
  follow_ups: T("Follow Ups"),
  receiver_name: T("Receiver Name"),
  bookings: T("Bookings"),
  security_deposit_paid: T("Security Deposit Paid"),
  task_allocation: T("Task Allocation"),
  days_for_show_up_rate: T("Days for Show Up Rate"),
  enter_days_for_show_up_rate: T("Select Days for Show Up Rate"),
  inactive: T("Inactive"),
  verify: T("Verify"),
  current_offer: T("Current Offer"),
  inventory_id: T("Inventory Id"),
  inspection_rating: T("Inspection Rating"),
  creation_date: T("Creation Date"),
  bidder_info: T("Bidder Info"),
  target_market: T("Target Market"),
  fetch_lead: T("Fetch Lead"),
  bidder_id: T("Bidder Id"),
  neighbourhood: T("Neighbourhood"),
  edit_ad_listing: T("Edit Ad Listing"),
  reserve_ad: T("Reserve"),
  unreserve_ad: T("Unreserve"),
  pause_ad_confirmation: T("Are you sure you want to pause this Ad?"),
  reserve_ad_confirmation: T("Are you sure you want to reserve this Ad?"),
  unreserve_ad_confirmation: T("Are you sure you want to Unreserve this Ad?"),
  live_ad_confirmation: T("Are you sure you want to make this Ad Live?"),
  lead_allocation: T("Lead Allocation"),
  live_ad: T("Live"),
  pause_ad: T("Pause"),

  404: T("404: Not Found"),
  archive_ad_confirmation: T("Are you sure you want to archive this Ad?"),
  edit_post: T("Edit Post"),
  something_went_wrong: T("Something went wrong"),
  clear_all: T("Clear All"),
  lead_allocation_tooltip_message: T(
    "Leads are arranged top-to-bottom in order of their priority. You can drag lead categories to exchange their priority"
  ),
  task_allocation_tooltip_message: T(
    "Tasks are arranged top-to-bottom in order of their priority. You can drag lead categories to exchange their priority"
  ),
  attached_meetings_prompt: T("All open meetings will be cancelled. Do you wish to continue?"),
  interior_color: T("Interior Color"),
  action: T("Action"),
  integer_exceeds_maximum_value: T(`Number is too large`),
  update_quotation: T("Update Quotation"),
  actions: T("Actions"),
  payment_verified: T("Payment Verified"),
  quotation_type: T("Quotation Type"),
  please_enter_valid_format: T("Please enter valid format"),
  bank: T("Bank"),
  inspector_s: T("Inspector(s)"),
  active: T("Active"),
  bidder_status: T("Bidder Status"),
  chatter: T("Chatter"),
  user_verified: T("User Verified"),
  verify_user: T("Verify User"),
  quotations: T("Quotations"),
  show_up_rate: T("Show up Rate"),
  active_in_offers: T("Active In Offers"),
  activity: T("Activity"),
  add_quotation: T("Add Quotation"),
  priority: T("Priority"),
  capacity_updated_at: T("Capacity Updated At"),
  show_up_rate_updated_at: T("Show Up Rate Updated At"),
  auto_calculate_capacity: T("Auto Calculate Capacity"),
  auto_calculate_show_up_rate: T("Auto Calculate Show Up Rate"),
  auto_calculate_capacity_label: T("AC Capacity"),
  auto_calculate_show_up_rate_label: T("AC Show Up Rate"),
  locations_partially_updated: T("Locations partially updated"),
  locations_updated_successfully: T("Locations Updated Successfully"),
  add: T("Add"),
  ad_post: T("Ad Post"),
  add_tag: T("Add Tag"),
  view_ad: T("View Ad"),
  save_reminder: T("Set Reminder"),
  update_reminder: T("Update Reminder"),
  attachment_uploaded_successfully: T("Attachment(s) uploaded Successfully"),
  reminders: T("Reminders"),
  reminder: T("Reminder"),
  is_external: T("Is External"),
  is_created_externally: T("Is Created Externally"),
  is_updated_externally: T("Is Updated Externally"),
  profile_updated_successfully: T("Profile Updated Successfully"),
  ad_post_success: T("Ad Posted Successfully"),
  ad_update_success: T("Ad Updated Successfully"),
  add_addresses: T("Add Addresses"),
  add_attribute: T("Add Attribute"),
  add_auction_type: T("Add Auction Type"),
  add_comment: T("Please add comments"),
  add_discount: T("Add Discount"),
  add_dynamic_field: T("Add Dynamic Field"),
  resend_email: T("Resend Email"),
  generated_at: T("Generated At"),
  email_resent: T("Email Successfully Resent"),
  file_type: T("File Type"),
  quotation_added_successfully: T("Quotation Added Successfully"),
  seller_leads: T("Seller Leads"),
  generated_by: T("Generated By"),
  seller_lead: T("Seller Lead"),
  buyer_leads: T("Buyer Leads"),
  buyer_lead: T("Buyer Lead"),
  view_quotation: T("View Quotation"),
  view_inspection_report: T("View Inspection Report"),
  recommended_cars: T("Recommended Cars"),
  no_attachements_msg: T("No attachments found."),
  add_event_confirmation: T("Please confirm whether you'd like to schedule a meeting on "),
  add_reschedule_event_confirmation: T("Are you sure you want to reschedule this meeting on"),
  appointments: T("appointments"),
  appointments_status: T("Appointments Status"),
  appointments_start_time: T("Appointment Date "),
  meetings: T("Meetings"),
  view_attachment: T("View Attachment"),
  view_inventory: T("View Inventory"),
  front_picture: T("Front"),
  rear_picture: T("Rear"),
  left_picture: T("Left Side"),
  vin_plate_picture: T("VIN Plate"),
  keys_picture: T("Keys"),
  odometer_picture: T("Odometer"),
  clear: T("Clear"),
  right_picture: T("Right Side"),
  tags: T("Tags"),
  add_favicon: T("Please add favicon"),
  update_status: T("Update Status"),
  add_group: T("Add Group"),
  upload_xls_file: T("Upload XLSX document file to continue."),
  upload_file: T("Upload File"),
  bulk_import_crm_leads: T("Bulk Import CRM Leads"),
  bulk_import_leads: T("Bulk Import Leads"),
  payment_sheet_modal_heading: T("Upload file for client reassignment. Click below to download file format."),
  payment_sheet_upload_confirmation: T("I confirm that the uploaded sheet as per the standard format."),
  evaluation_completed_successfully: T("Evaluation completed successfully "),
  add_inspection_lead: T("Are you sure you want to create inspection lead?"),
  add_lead: T("Add Lead"),
  add_lead_source: T("Add Lead Source"),
  add_lead_type: T("Add Lead Type"),
  add_leadtype_in_lead_message: T(
    "You cannot schedule lead until its associated with lead type. Please add lead type in lead"
  ),
  emirate: T("Emirate"),
  counted_vehicle: T("Counted Vehicles"),
  missed_vehicle: T("Missed Vehicles"),
  inventory_ref: T("Inventory Ref"),
  user_not_authorized_err: T("User not authorized to perform this action"),
  edit_client: T("Edit Client"),
  select_lead_type: T("Select lead type"),
  assigned: T("Assigned"),
  assignee_zones: T("Assignee Zones"),
  engine: T("Engine"),
  select_location_type: T("Select location type"),
  select_location_msg: T("Select location"),
  location_type_ph: T("Select location type"),
  select_users: T("Select Users"),
  add_location: T("Add location"),
  bulk_update_capacity: T("Bulk Update Capacity"),
  bulk_branch_capacity: T("Bulk Branch Capacity"),
  bulk_qr_print: T("Bulk QR Print"),
  bulk_update: T("Bulk Update"),
  add_another_option: T("Add another option"),
  add_option: T("Add option"),
  remove_option: T("Remove option"),
  enter_option_ph: T("Enter Option"),
  valid_field_name_message: T("Please enter a valid field name"),
  add_option_message: T("Please add an option"),
  add_options_message: T("Please add options"),
  view_in_calendar: T("View in Calendar"),
  assigned_leads: T("Assigned Leads"),
  assigned_tasks: T("Assigned Tasks"),
  previous: T("Previous"),
  add_logo: T("Please add logo"),
  add_meeting: T("Add Meeting"),
  add_new: T("Add New"),
  add_new_address: T("Add New Address"),
  add_new_auction_type: T("Add New Auction Type"),
  add_new_group: T("Add New Group"),
  add_new_team: T("Add New Team"),
  add_new_user: T("Add New User"),
  add_password: T("Add Password"),
  add_payment: T("Add Payment"),
  add_payment_info: T("Add Payment Info"),
  add_photos: T("Add Photos"),
  add_role: T("Add Role"),
  add_search_name: T("Please add search name!"),
  search_by_name: T("Search by Name"),
  percentage: T("Percentage"),
  search_users_to_add_here: T("+ Search for users to add here"),
  add_task: T("Add Task"),
  task_ref: T("Task Ref"),
  add_cico: T("CICO"),
  create_cico: T("Add CICO"),
  cico_detail: T("CICO Detail"),
  add_team: T("Add Team"),
  add_to_auction: T("Add To Auction"),
  add_to_auction_again: T("Add To Auction Again"),
  quick_links: T("Quick Links"),
  add_to_auction_again_desc: T("Please click the 'Add To Auction Again' for Another Auction"),
  add_to_auction_desc: T("Please click the 'Add To Auction' button to proceed"),
  add_token: T("Add Token"),
  add_user: T("Add User"),
  add_user_password: T("Change User Password"),
  add_user_settings: T("User Settings"),
  delete_reminder: T("Delete Reminder"),
  add_viewing_task: T("Add Viewing Task"),
  add_working_hours: T("Add Working Hours"),
  additional_information: T("Additional Information"),
  address: T("Address"),
  address_info: T("Address Info"),
  address_ph: T("Enter Address"),
  admin: T("Admin"),
  ad_insight_no_data: T("No Available Insights for the current Ad"),
  all: T("All"),
  all_cities: T("All Cities"),
  all_day: T("All Day"),
  all_inspections: T("All Inspections"),
  all_offers: T("All Offers"),
  amount: T("Amount"),
  archive: T("Archive"),
  view_details: T("View Details"),
  reopen_task: T("Reopen Task"),
  archive_offer: T("Archive Offer"),
  select_task_type: T("Select Task Type"),
  no_search_params_msg: T("No search params selected"),
  archived: T("Archived"),
  archive: T("Archive"),
  area: T("Area"),
  area_name: T("Area Name"),
  areas: T("Areas"),
  assembly: T("Assembly"),
  assembly_ph: T("Select Assembly"),
  assign_role: T("Assign Role"),
  assign_to: T("Assign To"),
  assign_to_ph: T("Assign To"),
  assign_user_role: T("Assign User Role"),
  not_authorized_to_fetch_inspectors: T("You are not authorized to fetch inspectors"),
  assigned_to: T("Assigned To"),
  assignee: T("Assignee"),
  assignments: T("Assignments"),
  select_assignment: T("Select Assignment"),
  associated_lead: T("Associated Lead"),
  attributes: T("Attributes"),
  attachment: T("Attachment"),
  enter_ad_title: T("Enter title for this Ad..."),
  attachments: T("Attachments"),
  auction_: T("Auction #"),
  auction_cycle: T("Auction Cycle"),
  report_present: T("Report Present"),
  highest_bidder: T("Highest Bidder"),
  auction_cycles: T("Auction Cycles"),
  auction_extendable: T("Auction Extendable"),
  auction_insights: T("Auction Insights"),
  auction_matrix: T("Auction Matrix"),
  auction_setting: T("Auction Settings"),
  auction_sheet: T("Auction Sheet"),
  auction_status: T("Auction Status"),
  auction_type: T("Auction Type"),
  auction_type_name: T("auction type name"),
  auction_type_ph: T("Select Auction Type"),
  auction_types: T("Auction Types"),
  auction_won_by: T(" Auction won by"),
  auctions: T("Auctions"),
  select_teams: T("Select Teams"),
  authentication_token: T("Authentication Token"),
  authentication_token_message: T("Your authentication token:"),
  authentication_token_permissions: T("Permissions"),
  authentication_tokens: T("Authentication Tokens"),
  availability: T("Availability"),
  availability_hours: T("Availability Hours"),
  view_compliance_report: T("View Compliance Report"),
  view_bank_evaluation_report: T("View Bank Evaluation Report"),
  approval_signature: T("Approval Signature"),
  approve_report: T("Approve Report"),
  approval_approved_msg: T("approved this approval request"),
  approval_rejected_msg: T("rejected this approval request"),
  update_report: T("Update Report"),
  evaluator_name: T("Select Evaluator"),
  inspector_name: T("Select Inspector"),
  evaluator: T("Evaluator"),
  transaction_id: T("Transaction Id"),
  select_evaluator_name: T("Select Evaluator Name..."),
  manager_authorised_name: T("Manager Authorised Name"),
  enter_manager_authorised_name: T("Enter Manager Authorised Name..."),
  evaluation_price: T("Evaluation Price"),
  enter_evaluation_price: T("Enter Evaluation Price..."),
  evaluation_date: T("Evaluation Date"),
  select_evaluation_date: T("Select Evaluation Date"),
  requested_for: T("Requested For"),
  select_seller_lead: T("Select Seller Lead"),
  select_buyer_lead: T("Select Buyer Lead"),
  select_inventory: T("Select Inventory"),
  requested_by: T("Requested By"),
  enter_email: T("Enter Email"),
  select_quotation_type: T("Select Quotation Type"),

  enter_requested_for: T("Enter Requested For..."),
  import_leads: T("Import Leads"),

  //
  back_to_task: T("Back to Task"),
  basic_calender: T("Basic Calendar"),
  bidder_name: T("Bidder Name"),
  bidder_phone: T("Bidder Phone"),
  bidder_ph: T("Bidder Ph#"),
  bidders: T("Bidders"),
  highest_bidders: T("Highest Bidders"),
  body_type: T("Body Type"),
  body_type_ph: T("Select Body Type"),
  buyer: T("Buyer"),
  buyer_seller_detail: T("Buyer/Seller Detail"),
  buyer_connects: T("Buyer Connects"),
  buyer_info: T("Buyer Information"),
  sold_to: T("Sold To"),
  buyer_name: T("Buyer Name"),
  buyer_preference: T("Buyer Preference"),
  buyer_visit: T("Buyer Visit"),
  by_cities: T("By Cities"),
  by_dispositions: T("By Dispositions"),
  by_lead_source: T("By Lead Source"),
  by_types: T("By Types"),
  building_name: T("Building Name"),
  building_name_ph: T("Enter Building Name "),
  branch_sight: T("Branch Sight"),
  //
  change_scheduled_team: T("Change Scheduled Team"),
  car_features: T("Car Features"),
  car_detail: T("Car Detail"),
  car_ref: T("Car Ref #"),
  calender: T("Calendar"),
  calender_error: T("Please choose another slot"),
  call_center_number: T("Call Center Number"),
  campaign: T("Campaign"),
  campaign_ph: T("Enter Campaign"),
  cancel: T("Cancel"),
  cancel_auction: T("Cancel Auction"),
  car_from_inventory: T("Car from Inventory"),
  car_information: T("Car Information"),
  car_inspection: T("Car Inspection"),
  car_name: T("Car Name"),
  category_type: T("Category Type"),
  category_type_ph: T("Select Category Type"),
  chanage_your_password: T("CHANGE YOUR PASSWORD"),
  change_password: T("Change Password"),
  edit_user_settings: T("User Settings"),
  change_status: T("Change Status"),
  character_validation: T("No more than 25 characters allowed"),
  characters_remaining: T("Characters Remaining"),
  chevron_sidebar_title: T("Users"),
  cico: T("CICO"),
  cities: T("Cities"),
  citizen_number: T("Citizen Number"),
  add_inventory: T("Add Inventory"),
  download_stock_list: T("Download Stock List"),
  citizen_number_optional: T("Citizen Number (Optional)"),
  citizen_number_ph: T("Enter citizen number"),
  city: T("City"),
  city_area: T("City Area"),
  city_area_ph: T("Select City Area"),
  city_ph: T("Select City"),
  classification: T("Classification"),
  classification_breakdown_by_type: T("Classification breakdown by type"),
  clear_all_filters: T("Clear All Filters"),
  client_detail: T("Client Detail"),
  client_info: T("Client Information"),
  clients: T("Clients"),
  clone: T("Clone"),
  close: T("Close"),
  collaborators: T("Collaborators"),
  collected_at: T("Collected At"),
  collected_date: T("Collected Date"),
  color: T("Color"),
  color_ph: T("Select Color"),
  coming_soon: T("Coming Soon"),
  comment: T("Comment"),
  comments: T("Comments"),
  comments_ph: T("Enter Comment"),
  completed: T("Completed"),
  completed_at: T("Completed At"),
  completed_tasks: T("Completed Tasks"),
  confirm: T("Confirm"),
  connects: T("Connects"),
  confirm_passowrd: T("Confirm Password"),
  contact_number: T("Contact Number"),
  contact_numbers: T("Contact Numbers"),
  contact_numbers_ph: T("Enter Contact Number"),
  profile_picture: T("Profile Picture"),
  continue: T("Continue"),
  core: T("Core"),
  correct_phone_regex: T("Please enter correct regex"),
  country: T("Country"),
  country_code: T("Country Code"),
  country_ph: T("Select Country"),
  create: T("Create"),
  create_inspection_lead: T("Create Inspection Lead"),
  create_inventory: T("Create Inventory"),
  create_new_lead: T("Click the button below to create new lead"),
  create_quick_link: T("Create Quick Link"),
  create_task: T("Create Task"),
  created_at: T("Created At"),
  currency: T("Currency"),
  current_password: T("Current Password"),
  customer_trn_validation: T("Please enter a valid 15 digit TRN number"),
  //
  below_leads_were_not_assigned: T("Below leads were not assigned"),
  daily: T("Daily"),
  dashboard: T("Dashboard"),
  data_type: T("DataType"),
  date: T("Date"),
  date_and_time: T("Date & Time"),
  day: T("Day"),
  deactivate_sms: T("Deactivate Sms"),
  bulk_actions: T("Bulk Actions"),
  unable_to_assign_leads: T("Unable to Assign Leads"),
  deactivate_sms_message: T("Are you sure you want to Deactivate this SMS?"),
  default_error: T("Error! Something went wrong"),
  delete: T("Delete"),
  delete_confirmation: T("Are you sure you want to delete?"),
  delete_group: T("Delete Group"),
  delete_inspection: T("Delete Inspection"),
  delete_lead_source: T("Delete Lead Source"),
  delete_lead_type: T("Delete Lead Type"),
  demand_price: T("Demand Price"),
  listing_price: T("Listing Price"),
  demand_price_optional: T("Demand Price (Optional)"),
  deposit: T("Deposit #"),
  depositor: T("Depositor"),
  deposit_time: T("Deposit Time"),
  deposit_ref: T("Deposit Ref #"),
  deposit_amount: T("Deposit Amount"),
  deposit_slip: T("Deposit Slip"),
  deposited_at: T("Deposited At"),
  deposited_date: T("Deposited Date"),
  deposits: T("Deposits"),
  description: T("Description"),
  description_ph: T("Enter Description"),
  ad_description: T("Describe this used car for sale..."),
  destination: T("Destination"),
  deselect_all: T("Deselect All"),
  detail: T("Detail"),
  details: T("Details"),
  disabled: T("Disabled"),
  disclaimer: T("Disclaimer"),
  discount: T("Discount"),
  discount_amount: T("Discount Amount"),
  discount_notes: T("Discount Notes"),
  dismiss: T("Dismiss"),
  disposition: T("Disposition"),
  done: T("Done"),
  dragging_event: T("DRAGGING EVENT"),
  drop_photos: T("Drop photos here"),
  due: T("Due"),
  due_date: T("Due Date"),
  due_tasks: T("Due Tasks"),
  duration: T("Duration"),
  dynamic_fields: T("Dynamic Fields"),
  dynamic_models: T("Dynamic Models"),
  //
  edit: T("Edit"),
  edit_auction_type: T("Edit Auction Type"),
  edit_group: T("Edit Group"),
  edit_info: T("Edit Info"),
  edit_inventory: T("Edit Inventory"),
  edit_lead_type: T("Edit Lead Type"),
  edit_role: T("Edit Role"),
  edit_team: T("Edit Team"),
  edit_token: T("Edit Token"),
  edit_user: T("Edit User"),
  edit_phone_number: T("Edit Phone Number"),
  email: T("Email"),
  email_frequency_message: T("Please enter frequency of email via update"),
  days_for_show_up_rate_message: T("Please enter days for show up rate"),
  email_frequency_ph: T("Enter frequency of update via Email"),
  email_optional: T("Email (Optional)"),
  email_ph: T("Enter Email"),
  email_settings: T("Email Settings"),
  email_templates: T("Email Templates"),
  emirates_id_validation: T("Emirates Id should be 15 digits long"),
  end_date_and_time: T("End Date & Time"),
  end_date_message: T("Please select end date"),
  end_time: T("End Time"),
  end_time_message: T("Please enter end time"),
  end_time_ph: "End Time",
  ending_in: T(" Ending In"),
  ending_soon: T("Ending Soon"),
  enter_address_validation: T("Please enter address"),
  search: T("Search"),
  engine_capacity: T("Engine Capacity (cc)"),
  engine_capacity_ph: T("Engine Capacity"),
  engine_type: T("Engine Type"),
  engine_type_ph: T("Select Engine Type"),
  enter_city_ph: T("Enter City"),
  enter_description_message: T("Please enter description"),
  enter_discount_amount: T("Enter Discount Amount"),
  enter_discount_notes: T("Enter Discount Notes"),
  enter_engine_capacity_message: T("Please enter engine capacity"),
  enter_field_label_message: T("Please enter field label"),
  enter_field_name: T("Please enter field name"),
  enter_final_price: T("Enter Final Price"),
  enter_listing_price: T("Enter Listing Price"),
  enter_millage_message: T("Please enter mileage"),
  enter_name_message: T("Please enter name"),
  enter_name_validation: T("Please enter name"),
  enter_number_message: T("Please enter a number"),
  enter_mileage_message: T("Input exceeds mileage limit. Please enter a lower value."),
  enter_payment_received: T("Enter Payment Received"),
  enter_phone: T("Please enter phone"),
  enter_your_phone: T("Enter your phone number..."),
  enter_phone_validation: T("Please enter phone"),
  enter_link_validation: T("Please enter valid url"),
  enter_transaction_id: T("Enter transaction ID"),
  enter_transaction_date: T("Enter transaction Date"),
  enter_amount: T("Enter amount"),
  url: T("URL"),
  url_ph: T("Enter url"),
  enter_max_range_message: T("Please enter max range"),
  enter_max_range_validation: T("Please enter max range"),
  enter_min_range_message: T("Please enter min range"),
  enter_min_range_validation: T("Please enter min range"),
  enter_value_message: T("Please enter value"),
  enter_value_validation: T("Please enter value"),

  add_label: T("Add Label"),
  enter_price_message: T("Please enter price"),
  enter_report_id_message: T("Please enter report id"),
  enter_role_name: T("Please enter role name"),
  enter_rows: T("Please enter rows to show per page"),
  enter_seller_name_message: T("Please enter seller name"),
  enter_user: T("Enter User"),
  enter_username_password: T("Enter your Username and Password"),
  enter_your_comments: T("Enter Your comments"),
  reminder_title: T("Add title of reminder"),
  reminder_notes: T("Add notes of reminder"),
  error: T("Error"),
  errors: T("Errors"),
  tasks_due_today: T("Tasks Due Today"),
  view_all_tasks: T("View all Tasks"),
  errors_found_in_attached_file: T("Error(s) found in attached file"),
  event: T("Event"),
  events: T("Events"),
  expire: T("Expire"),
  expire_confirmation_msg: T("Are you sure you want expire this token?"),
  expire_token: T("Expire Token"),
  expiry: T("Expiry"),
  expiry_time: T("Expiry Time"),
  expiry_time_ph: T("Select expiry time"),
  extend_auction: T("Extend Auction"),
  extend_time: T("Extend Time"),
  extendable: T("Extendable"),
  extension_count: T("Extension Count"),
  extension_count_ph: T("Select Extension Count"),
  extension_period: T("Extension Period"),
  extension_threshold: T("Extension Threshold"),
  exterior_color: T("Exterior Color"),
  vehicle_identification_number: T("Vehicle Identification Number"),
  vehicle_requirements_validation: T("Please enter alphanumeric characters only"),
  signature_validation: T("Customer signature can only be in letters"),
  country_of_origin: T("Country of Origin"),
  //
  features: T("Features"),
  fetching_users_error: T("Error in fetching users"),
  field_datatype: T("Field Datatype"),
  field_datatype_ph: T("Enter Field Datatype"),
  field_label: T("Field Label"),
  field_label_ph: T("label"),
  field_name: T("Field Name"),
  field_name_ph: T("e.g, first_name"),
  field_name_taken_message: T("Field name is already taken"),
  field_not_valid_message: T("This field is not valid"),
  field_placeholder: T("Field Placeholder"),
  field_placeholder_ph: T("Enter Field Placeholder"),
  field_regex: T("Field Regex"),
  field_regex_ph: T("Enter Field Regex"),
  field_required_message: T("This field is required"),
  final_price: T("Final Price"),
  finance: T("Finance"),
  first_name: T("First Name"),
  first_name_ph: T("Enter First Name"),
  followup_time: T("Followup Time"),
  friday: T("Friday"),
  from: T("From"),
  //
  generate_crm_lead: T("Generate Crm Lead"),
  generate_crm_lead_message: T("Are you sure you want to generate crm lead?"),
  gross_total: T("Gross Total"),
  group_name_message: T("group name"),
  groups: T("Groups"),
  groups_ph: T("Select Groups"),
  //
  handcrafted_made: T("Hand crafted & made with"),
  hide_previous_history: T("Hide Previous History"),
  highest_offer: T("Highest Offer"),
  history: T("History"),
  //
  id: T("ID"),
  image_required: T("Image is required"),
  in_active: T("InActive"),
  in_progress: T("In Progress"),
  inspection: T("Inspection"),
  inspection_category: T("Inspection Category"),
  inspection_category_ph: T("Inspection Category"),
  inspection_lead: T("Inspection Lead"),
  inspection_program: T("Inspection Program"),
  inspection_report: T("Inspection Report"),
  inspection_type: T("Inspection Type"),
  inspection_external_id: T("External ID"),
  tour_placeholder: T("Add URL to 360 tour of this inventory"),
  selling_price: T("Selling Price"),
  total_price: T("Total Price"),
  down_payment: T("Down Payment"),
  finance_amount: T("Finance Amount"),
  final_receivable: T("Final Receivable Amount"),
  car_make_model: T("Car make/model"),
  car_variant: T("Car Variant"),
  car_color: T("Color"),
  eng_no: T("Engine No."),
  chassis_no: T("Chassis No."),
  enter_selling_price: T("Enter Selling Price"),
  enter_bank_name: T("Enter Bank Name"),
  inspection_type_ph: T("Select Inspection Type"),
  invalid_input: T("Invalid Input"),
  invalid_reason: T("Invalid reason"),
  invalid_link_message: T("Invalid link"),
  invalid_transaction_id: T("Invalid Transaction ID"),
  invalid_amount: T("Invalid Amount"),
  price_benchmark: T("Price should be either greater or equal to 2000"),
  inventories: T("Inventories"),
  inventory: T("Inventory"),
  listing: T("Listing"),
  inventory_: T("Inventory #"),
  inventory_detail: T("Inventory Detail"),
  inventory_info: T("Inventory Info"),
  inventory_no: T("Inventory #"),
  inventory_insights: T("Inventory Insights"),
  inventory_overview: T("Inventory Overview"),
  inventory_ready_for_auction: T("Inventory is ready for Auction"),
  inventory_ready_for_desc: T("Your inventory item is ready for Auction!"),
  inventory_type: T("Inventory Type"),
  inv_type: T("Inv. Type"),
  disposition_status: T("Status/Disposition"),
  stats: T("Stats"),
  listing_price: T("Listing Price"),
  last_price: T("Last Price"),
  inventory_type_ph: T("Select Inventory Type"),
  inventory_select_msg: T("Please select inventory first"),
  inspector: T("Inspector"),
  invoices: T("Invoices"),
  invoice: T("Invoice"),
  is_assigned: T("Is Assigned"),
  image: T("Image"),
  assignee_select_msg: T("Please select assignee first"),
  //
  label: T("Label"),
  language: T("Language"),
  last: T("Last"),
  last_name: T("Last Name"),
  last_name_ph: T("Enter Last Name"),
  last_recommended_price: T("Last Recommended Price"),
  last_updated_on: T("Last Updated On"),
  lead: T("Lead"),
  lead_added_as_followup_message: T("Lead added as follow up!"),
  lead_added_as_lost_message: T("Lead added as lost!"),
  lead_assignee: T("Lead Assignee"),
  lead_assignee_ph: T("Select Lead Assignee"),
  lead_campaign_message: T("Please enter campaign"),
  lead_category: T("Lead Category"),
  lead_category_ph: T("Select Lead Category"),
  lead_detail: T("Lead Detail"),
  lead_disposition: T("Lead Disposition"),
  lead_id: T("Lead ID"),
  lead_info: T("Lead Info"),
  lead_lifetime: T("Lead Created"),
  lead_medium_message: T("Please enter medium"),
  lead_name_message: T("Lead name required"),
  lead_scheduled: T("Lead Scheduled!"),
  lead_source: T("Lead Source"),
  lead_source_message: T("Please enter source"),
  lead_source_name: T("Lead Source Name"),
  lead_source_ph: T("Select Lead Source"),
  lead_statistics: T("Lead Statistics"),
  lead_status: T("Lead Status"),
  lead_status_ph: T("Select Lead Status"),
  lead_type: T("Lead Type"),
  lead_type_ph: T("Select Lead Type"),
  lead_type_price: T("Lead Type Price"),
  lead_types: T("Lead Types"),
  leads_count: T("Leads Count"),
  leads_overview: T("Leads Overview"),
  less_discount_message: T("Discount should be less than the total price."),
  loading_chart: T("Loading Chart"),
  time_date: T("Time - Date"),
  location: T("Location"),
  locations: T("Locations"),
  lock: T("Lock"),
  lock_report: T("Lock Report"),
  login: T("Login"),
  login_auth: T("Login With Auth0"),
  logout: T("Logout"),
  lost: T("Lost"),
  latitude: T("Latitude"),
  longitude: T("Longitude"),
  location_type: T("Location Type"),
  location_link: T("Location Link"),
  //
  make: T("Make"),
  make_model: T("Make/Model"),
  make_and_model: T("Make & Model"),
  dvdPlayer: T("DVD Player"),
  climate_control: T("Climate Control"),
  keyless_entry: T("Keyless Entry"),
  cooled_seats: "Cooled Seats",
  front_wheel_drive: T("Front Wheel Drive"),
  leather_seats: T("Leather Seats"),
  four_wheel_drive: T("Four Wheel Drive"),
  alarm: T("Alarm"),
  air_conditioning: T("Air Conditioning"),
  all_wheel_drive: T("All Wheel Drive"),
  steering_side: T("Steering Side"),
  fuel: T("Fuel"),
  doors: T("Doors"),
  cylinders: T("Cylinders"),
  hp: T("Hp"),
  body_condition: T("Body Condition"),
  auto_pilot: T("Auto Pilot"),
  battery_capacity: T("Battery Capacity (kWh)"),
  range: T("Range (KM)"),
  charging_time: T("Charging Time"),
  select_charging_time: T("Select Charging Time"),
  select_range: T("Select Range"),
  select_battery_capacity: T("Select Battery Capacity"),
  auto_pilot_type: T("Auto Pilot Type"),
  mechanical_condition: T("Mechanical Condition"),
  warranty: T("Warranty"),
  warranty_package: T("Wnty. Pkg."),
  whatsapp_modal_title: T("Send WhatsApp Message to"),
  whatsapp_variable_label: T("Variable"),
  regional_spec: T("Regional Spec"),
  tour: T("360 Tour"),
  year: T("Year"),
  kilometers: T("Kilometers"),
  vehicle_insured: T("Vehicle Insured"),
  trim: T("Trim"),
  vin: T("VIN"),
  make_model_variant: T("Make Model Variant"),
  make_ph: T("Select Make"),
  manage_quick_link: T("Manage Quick Link"),
  mark_as_complete: T("Mark as complete"),
  mark_all_read: T("Mark all as read"),

  mark_as_deposited: T("Do you want to mark this payment as deposited?"),
  mark_inventory_to_add_desc: T("Are you sure you want to add this inventory?"),
  mark_as_removed_desc: T("Are you sure you want to mark this as removed?"),
  mark_as_on_hold_desc: T("Are you sure you want to mark this as On Hold?"),
  mark_as_sold: T("Mark As Sold"),
  mark_as_on_hold: T("Mark As On Hold"),
  mark_as_sold_desc: T("Are you sure you want to mark this as Sold?"),
  mark_as_winner: T("Mark As Winner"),
  mark_as_winner_message: T("Are you sure you want to mark this as winner?"),
  mark_as_won: T("Mark As Won"),
  max_limit_per_image: T("Max Limit 5 MB per image "),
  medium: T("Medium"),
  medium_ph: T("Enter Medium"),
  meeting: T("Meeting"),
  meeting_confirmation: T("Meeting Confirmation"),
  reschedule_meeting_confirmation: T("Reschedule Meeting Confirmation"),
  meeting_status_confirmation: T("Meeting Status Confirmation"),
  meeting_status_confirmation_modal: T("Are you sure you want to change meeting status to:"),
  message: T("Message"),
  message_translation: T("Message Translation"),
  method_of_payment: T("Method of Payment"),
  mileage: T("Mileage (km)"),
  mileage_ph: T("Enter Mileage in KM"),
  min_value_message: T("Value cannot be less than zero."),
  model: T("Model"),
  model_ph: T("Select Model"),
  model_year: T("Model Year"),
  model_year_ph: T("Select Model Year"),
  monday: T("Monday"),
  month: T("Month"),
  monthly: T("Monthly"),
  more: T("more"),
  my_calender: T("My Calendar"),
  daily_appointment_capacity_reached: T("Limit reached for number of daily appointments"),
  //
  name: T("Name"),
  name_and_description: T("Name & Description"),
  name_ph: T("Enter Name"),
  name_your_search: T("Name Your Search"),
  net_amount: T("Net Amount"),
  new: T("New"),
  new_password: T("New Password"),
  network_error: T("Network Error"),
  next: T("Next"),
  no: T("No"),
  no_data: T("No Data"),
  no_data_found: T("No Data Found"),
  no_lead_action_message: T("No further action is required on this lead."),
  no_link_available: T("No Links available!"),
  no_offers: T("No Offers"),
  no_result_found: T("No Result Found"),
  no_space_validation: T("Value should not have spaces only"),
  no_seller_address_msg: T("No seller address available, Please add address for inventory no."),
  no_unscheduled_lead: T("No unscheduled lead!"),
  notes: T("Notes"),
  not_found: T("Not Found"),
  not_available: T("Not Available"),
  not_assigned: T("Not Assigned"),
  notifications: T("Notifications"),
  number_of_offers: T("Number Of Offers"),
  more_filters: T("More Filters"),
  hide_filters: T("Hide Filters"),
  //
  of_offers: T("# Of Offers"),
  offer: T("Offer"),
  offer_extension_period: T("Offer Extension Period"),
  offer_extension_period_ph: T("Select Offer Extension Period"),
  offer_extension_threshold: T("Offer Extension Threshold"),
  offer_extension_threshold_ph: T("Select Offer Extension Threshold"),
  offer_increment: T("Offer Increment"),
  offer_increment_ph: T("Enter Offer Increment"),
  offer_jump_message: T("Please enter Offer Increment"),
  offers: T("Offers"),
  off_times: T("Off Times"),
  option: T("Option"),
  overall_score: T("Overall Score"),
  overdue_tasks: T("Overdue Tasks"),
  parked_at: T("Parked At"),
  password: T("Password"),
  password_ph: T("Enter Password"),
  otp_ph: T("Enter OTP"),
  payable_amount: T("Payable Amount"),
  payment: T("Payment"),
  payment_amount: T("Payment Amount"),
  payment_info: T("Payment Info"),
  payment_method: T("Payment Method"),
  payment_received: T("Enter Payment Received"),
  payment_status: T("Payments Status"),
  payment_success: T("Payment Info Added Successfully"),
  payment_to_be_received: T("Payment to be received"),
  client_information: T("Client Info"),
  payment_type: T("Payment Type"),
  payments: T("Payments"),
  all_payments: T("All Payments"),
  phone_: T("Phone:"),
  phone_code: T("Phone Code"),
  phone_number: T("Phone Number"),
  phone_ph: T("Enter Phone"),
  phone_placeholder: T("Phone Placeholder"),
  phone_placeholder_ph: T("Enter placeholder for phone"),
  phone_regex: T("Phone Regex"),
  phone_regex_ph: T("Please enter phone regex"),
  placeholder: T("Placeholder"),
  platform: T("Platform"),
  platform_ph: T("Select Platform"),
  please_select_location: T("Please Select Location"),
  post: T("Post"),
  preview_not_available: T("Preview is not available"),
  pricing: T("Pricing"),
  primary_phone: T("Primary Phone"),
  price: T("Price"),
  price_info: T("Price Info"),
  price_ph: T("Enter Price"),
  price_range: T("Price Range"),
  proof_of_payment: T("Proof of Payment"),
  print_qr: T("Print QR"),
  prop_go_initiate_success: T("Check Notification on Mobile"),
  //
  qc_inspection: T("QC Inspection"),
  quick_link_delete_error: T("Error in deleting quick link"),
  quick_link_name_validation: T("No more than 25 characters allowed"),
  quick_link_sort_error: T("Error in sorting quick link"),
  quick_link_space_validation: T("Quick link name should not have spaces only"),
  buyer_phone_number: T("Buyer Phone Number"),
  //
  re_type_password: T("Please re-type your password"),
  ready_for_auction: T("Ready for Auction"),
  ready_for_auction_msg: T("Are you sure you want to make this inventory ready for auction?"),
  reason: T("Reason"),
  reason_ph: T("Enter Reason"),
  recent_activities: T("Recent Activities"),
  recommend_price_change: T("Recommend Price Change"),
  records_per_page: T("Records per page"),
  records_per_page_ph: T("Enter records to display per page"),
  reciept: T("Receipt"),
  ref: T("Ref #"),
  reference: T("Reference #"),
  reference_no: T("Reference No."),
  regex: T("Regex"),
  registration: T("Registration #"),
  client_name: T("Client Name"),
  client: T("Client"),
  client_phone: T("Client Phone"),
  registration_city: T("Registration City"),
  registration_number: T("Registration No"),
  registration_number_ph: T("Enter Registration No"),
  related_items: T("Related Items"),
  remember_me: T("Remember me"),
  remove: T("Remove"),
  remove_address: T("Remove Address"),
  remove_inventory: T("Remove Inventory"),
  remove_winner: T("Remove Winner"),
  remove_winner_confirmation: T("Remove Winner Confirmation"),
  remove_winner_subtitle: T("Are you sure that you want to remove this winner?"),
  remove_winner_error: T("Reason is required"),
  remove_winner_success: T("Winner marked as lost successfully."),
  report: T("Report #"),
  report_id: T("Report Id"),
  report_id_ph: T("Enter ID"),
  report_link: T("Report Link"),
  reports: T("Reports"),
  reports_to: T("Reports to"),
  required: T("Required"),
  type: T("Type"),

  RESTRICTED: T("RESTRICTED"),
  retry: T("Retry"),
  retype_password: T("Retype Password"),
  role: T("Role"),
  role_name_and_description: T("Role Name & Description"),
  role_permissions: T("Role Permissions"),
  role_ph: T("Select Role"),
  role_restrictions: T("Role Restrictions"),
  roles: T("Roles"),

  //
  saturday: T("Saturday"),
  save: T("Save"),
  save_confirmation: T("You are about to save!"),
  save_draft: T("Save Draft"),
  save_template: T("Save Template"),
  days_in_stock: T("Days in Stock"),
  schedule: T("Schedule"),
  schedule_date: T("Schedule Date"),
  schedule_time: T("Schedule Time"),
  scheduled_at: "Scheduled At",
  scheduled_time: T("Scheduled Time"),
  schedules: T("Schedules"),
  schedule_qc_inspection: T("Schedule QC Inspection"),
  score: T("Score"),
  search_here: T("Search here..."),
  secondary_phone: T("Secondary Phone"),
  secondary_phone_optional: T("Secondary Phone Number (Optional)"),
  select: T("Select"),
  select_all: T("Select All"),
  enter_vehicle_price: T("Enter vehicle price"),
  select_emirate: T("Select Emirate"),
  select_neighbourhood: T("Select Neighbourhood"),
  select_make_model: T("Select Make and Model"),
  select_trim: T("Select Motors Trim"),
  select_regional_spec: T("Select Regional Spec"),
  mileage_input: T("Enter Mileage"),
  select_fuel: T("Select Fuel Type"),
  select_body_condition: T("Select Body Condition"),
  select_auto_pilot: T("Select Auto Pilot"),
  select_auto_pilot_type: T("Select Auto Pilot Type"),
  select_mechanical_condition: T("Select Mechanical Condition"),
  select_target_market: T("Select Target Market"),
  select_color: T("Select Color"),
  select_interior_color: T("Select Interior Color"),
  select_warranty: T("Select Warranty"),
  select_doors: T("Select number of Doors"),
  select_cylinders: T("Select number of Cylinders"),
  select_hp: T("Select Horsepower"),
  select_steering_side: T("Select Steering Side"),
  select_action: T("Select Action"),
  select_area: T("Select Area"),
  select_area_message: T("Select Area"),
  select_assembly: T("Please Select Assembly"),
  select_auction_extension: T("Please select auction extension"),
  select_auction_type: T("Please select auction type"),
  select_body_type: T("Select Body Type"),
  select_category_type: T("Please select Category Type"),
  select_city: T("Please select city"),
  select_city_area: T("Select City Area"),
  select_classification: T("Select Classification"),
  select_csv: T("Select CSV"),
  select_data_type: T("Please select data type"),
  select_date: T("Select Date"),
  select_date_range: T("Select Date Range"),
  select_days: T("Select Days"),
  select_disposition: T("Select Disposition"),
  select_disposition_ph: T("Select Disposition"),
  select_due_date: T("Select Due Date"),
  select_duration: T("Select Duration"),
  select_engine_type: T("Please Select Engine Type"),
  select_extension_count: T("Please select extension count"),
  select_extension_period: T("Please select extension period"),
  select_extension_threshold: T("Please select extension threshold"),
  select_exterior_color: T("Select Exterior Color"),
  select_group_first_message: T("Please select group first"),
  select_group_message: T("Please select a group"),
  select_inspection_category: T("Please select inspection category"),
  select_inspection_message: T("Please select an inspection"),
  select_inspection_ph: T("Select Inspection"),
  select_inspector_message: T("Please select inspector"),
  select_inventory_type: T("Please select inventory type"),
  select_key: T("Select Key"),
  select_lead: T("Select Lead"),
  select_lead_category: T("Please select Lead Category"),
  select_lead_source: T("Please select Lead Source"),
  select_location: T("Select Location"),
  select_make: T("Please Select Make"),
  select_model: T("Please Select Model"),
  select_payment_method: T("Select Payment Method"),
  select_payment_type: T("Select Payment Type"),
  select_ph: T("Select"),
  select_registration_city: T("Please Select Registration City"),
  select_role_message: T("Please select role"),
  select_seller_address: T("Select Seller Address"),
  select_role: T("Select Role"),
  select_branch: T("Select Branch"),
  select_warehouse: T("Select Warehouse"),
  select_status: T("Select Status"),
  select_status_ph: T("Select Status"),
  select_team_lead_message: T("Please select team lead"),
  select_time_ph: T("Select Time"),
  select_transmission: T("Please Select Transmission Type"),
  select_valid_group_message: T("Please select a valid group"),
  select_variant: T("Please Select Variant"),
  select_year: T("Select Year"),
  select_your_task: T("Select Your Task"),
  select_zone: T("Select Zone"),
  reassign_leads: T("Reassign Leads"),
  selected_key: T("Selected Key"),
  import: T("Import"),
  seller: T("Seller"),
  seller_address: T("Seller Address"),
  seller_buyer: T("Seller/Buyer"),
  seller_connects: T("Seller Connects"),
  leads_bulk_assigned_successfully: T("Bulk Leads Assigned Successfully"),
  bulk_leads_partially_assigned: T("Bulk Leads Partially Assigned"),
  seller_info: T("Seller Info"),
  seller_name: T("Seller Name"),
  seller_phone: T("Seller Phone"),
  seller_update_frequency: T("Seller Update Frequency"),
  send: T("Send"),
  send_email: T("Send Email"),
  send_message: T("Send Message"),
  set_as_preferred_address: T("Set as Preferred Address"),
  slot_not_available_msg: T("This slot is not available, Please select another slot!"),
  show_more: T("Show More"),
  show_less: T("Show Less"),
  show_previous_history: T("Show Previous History"),
  select_address: T("Select Address"),
  sms_settings: T("Sms Settings"),
  sms_template: T("SMS Template"),
  sms_templates: T("SMS Templates"),
  source: T("Source"),
  source_ph: T("Enter Source"),
  specify_a_number_message: T("Please specify a number"),
  dynamic_attributes: T("Dynamic Attributes"),
  formula_fields: T("Formula Fields"),
  start_date: T("Start Date"),
  start_date_and_time: T("Start Date & Time"),
  start_date_message: T("Please enter start date"),
  start_time: T("Start Time"),
  start_time_required: T("Start Time Required"),
  start_task: T("Start Task"),
  start_task_message: T(
    "The Task is locked until you start it. Time tracking will begin once initiated and cannot be undone."
  ),
  start_time_message: T("Please enter start time"),
  start_time_ph: "Start Time",
  starting_offer: T("Starting Offer"),
  target_price_message: T("Please enter target price"),
  starting_offer_ph: T("Enter Starting Offer"),
  starting_price: T("Starting Price"),
  status: T("Status"),
  statuses: T("Statuses"),
  stock_count: T("Stock Count"),
  stock_count_detail: T("Stock Count Detail"),
  stock_list: T("Stock List"),
  submit: T("Submit"),
  submit_and_continue: T("Submit and Continue"),
  submit_and_mark_available: T("Submit & Mark Available"),
  sunday: T("Sunday"),
  //
  task: T("Task"),
  task_breakdown_by_status: T("Task breakdown by status"),
  task_outcome: T("Task Outcome"),
  task_type: T("Task Type"),
  task_type_ph: T("Select Task Type"),
  task_timer: T("Task Timer"),
  tasks: T("Tasks"),
  team_assigned: T("Team Assigned"),
  team_lead: T("Team Lead"),
  team_lead_ph: T("Select Team Lead"),
  team_members: T("Team Members"),
  team_name: T("team name"),
  teams: T("Teams"),
  tenant_name: T("Tenant Name"),
  tenant_settings: T("Tenant Settings"),
  tenant_success: T("Tenant information saved successfully!"),
  template_type: T("Template Type"),
  template_type_ph: T("Select Template Type"),
  template_text: T("Template Text"),
  template_type_empty_title: T("No template type selected"),
  template_type_empty_subtitle: T("Select template type first to view related variables"),
  text_copy_error: T("Failed to copy the Text"),
  thursday: T("Thursday"),
  time: T("Time"),
  total_time: T("Total Time"),
  total_tasks: T("Total Tasks"),
  time_ended_up: T("Your time has been ended up!"),
  timezone: T("Timezone"),
  timeline: T("Timeline"),
  title: T("Title"),
  enter_title_msg: T("Enter title"),
  select_bank: T("Select Bank"),
  title_translation: T("Title Translation"),
  to: T("To"),
  total_cars: T("Total Cars"),
  capacity: T("Capacity"),
  enter_capacity_msg: T("Enter capacity"),

  to_do: T("To Do"),
  today: T("Today"),
  token: T("Token"),
  token_permissions: T("Token Permissions"),
  tokens: T("Tokens"),
  buyer_contact: T("Buyer Contact"),
  seller_contact: T("Seller Contact"),
  top_cities: T("Top Cities"),
  top_clients: T("Top Clients"),
  total_offers: T("Total Offers"),
  translation: T("Translation"),
  transmission: T("Transmission"),
  payment_transaction_id: T("Payment Transaction ID"),
  dealer_type: T("Dealer Type"),
  transaction_date: T("Transaction Date"),
  transmission_ph: T("Select Transmission"),
  try_again: T("Try Again"),
  tuesday: T("Tuesday"),
  type_current_password: T("Please type your current password"),
  type_email: T("Please enter email"),
  type_email_password: T("Type your Email and Password"),
  type_new_password_ph: T("Enter New Password"),
  type_password: T("Please type your password"),
  type_valid_email: T("Type a valid email address"),

  //
  unassigned: T("Unassigned"),
  unlock: T("Unlock"),
  unlock_report: T("Unlock Report"),
  update: T("Update"),
  update_atribute: T("Update Atribute"),
  update_attribute: T("Update Attribute"),
  update_auction_type: T("Update Auction Type"),
  update_group: T("Update Group"),
  update_info: T("Update Info"),
  update_lead: T("Update Lead"),
  update_task: T("Update Task"),
  update_team: T("Update Team"),
  update_user: T("Update User"),
  update_user_success_message: T("User status updated"),
  update_user_failure_message: T("User status update failed"),
  upload: T("Upload"),
  upload_xls_document: T("Upload XLS Document File To continue"),
  upload_all_documents: T("Upload all your car documents, files, and receipts"),
  upload_csv: T("Upload CSV"),
  upload_csv_file: T("Upload .csv file format"),
  upload_favicon: T("Upload Favicon"),
  upload_files: T("Upload Files"),
  upload_logo: T("Upload Logo"),
  upload_photos: T("Upload Photos"),
  upload_watermark: T("Upload Watermark"),
  user_assign_update_msg: T("User assignments updated successfully!"),
  user_assignments: T("User Assignments"),
  user_name: T("User Name"),
  users: T("Users"),
  assigned_inspection_team: T("Assigned Inspection Team"),
  //
  valid_comment: T("Comments should not have spaces only"),
  valid_description: T("Description should not have spaces only"),
  valid_name: T("Name must have more than 1 character"),
  valid_password: T(
    "Password must contain at least 8 characters, one uppercase, one number and one special case character"
  ),
  inventory_statuses: T("Inventory Statuses "),
  valid_phone: T("Please enter a valid phone number"),
  variant: T("Variant"),
  variant_ph: T("Select Variant"),
  variables_label: T("Variables"),
  variable_required: T("Variable Required"),
  verified: T("Verified"),
  verified_at: T("Verified At"),
  view: T("View"),
  views: T("Views"),
  view_all_leads: T("View All Leads"),
  view_calendar: T("View Calendar"),
  view_detail: T("View Detail"),
  view_gallery: T("View Gallery"),
  view_less: T("View Less"),
  view_more: T("View More"),
  view_report: T("View Report"),
  view_reports: T("View Reports"),
  //
  watchers: T("Watchers"),
  wednesday: T("Wednesday"),
  week: T("Week"),
  weekly: T("Weekly"),
  won: T("Won"),
  working_hours: T("Working Hours"),
  //
  yes: T("Yes"),
  yesterday: T("Yesterday"),
  your_email: T("Your Email"),
  impersonated_user_email: T("Impersonated User Email"),
  your_name: T("Your Name"),
  your_password: T("Your Password"),
  your_otp: T("Your OTP"),
  your_token: T("Your token"),
  //
  zone: T("Zone"),
  zones: T("Zones"),
  source_name: T("Source Name"),
  save_your_changes: T("Save Your Changes, or else they'll be discarded"),
  mark_as_available: T("Mark as Available"),
  mark_as_available_desc: T("Are you sure you wat to mark this Invenory as Available?"),
  appointment_required: T("Appointment Required"),
  inventory_required: T("Inventory Required"),
  task_type_name: T("Task type name"),
  task_name: T("Task Name"),
  enter_task_type_name: T("Enter task type name"),
  edit_task_type: T("Edit Task Type"),
  add_task_type: T("Add Task Type"),
  task_types: T("Task Types"),
  add_lead_name_message: T("Only Numbers and Alphabets are allowed as a valid lead name"),
  select_label_ph: T("Select label"),
  select_label: T("Select label"),
  edit_label: T("Edit label"),
  warehouse_location: T("Warehouse location"),
  select_warehouse_location: T("Select warehouse location"),
  warehouse: T("Warehouse"),
  branches: T("Branches"),
  add_label_msg: T("Please add label"),
  no_location_available: T("No location available"),
  lead_name: T("Lead name"),
  Restricted: T("Restricted"),
  filterable: T("Filterable"),
  payment_required: T("Payment Required"),
  reportee: T("Reportee"),
  provide_designation_msg: T("Please provide user Designation"),
  select_designation_msg: T("select designation"),
  provide_user_reportee_msg: T("Please provide user Reportee."),
  listable: T("Listable"),
  capacity_validation_msg: T("Capacity must be greater than 1"),
  question_mark: T("?"),
  select_section: T("Select section"),
  section: T("Section"),
  designation: T("Designation"),
  price_policy: T("Price policy"),
  select_price_policy: T("Select price policy"),
  start_offer: T("Start offer"),
  start_offer_msg: T("Enter start offer"),
  target_price: T("Target price"),
  target_price_msg: T("Enter target price"),
  mark_as_ready_for_auction: T("Mark as ready for auction"),
  mark_inventory_as_ready_for_auction: T("Mark inventory as ready for auction"),
  change_task_status: T("Change Task Status"),
  enter_name: T("Enter name"),
  reset_filters: T("Reset Filters"),
  buyer_phone: T("Buyer Phone"),
  copy_to_clipboard: T("Copy to clipboard"),
  select_start_time: T("Select start time"),
  select_end_time: T("Select end time"),
  select_availability_hours: T("Please select availability hours"),
  branch: T("Branch"),
  verified_time: T("Verified Time"),
  invalid_date: T("Invalid Date"),
  deposit_by: T("Deposit By"),
  select_depositor: T("Select Depositor"),
  verify_payment: T("Verify payment"),
  view_payment: T("View Payment"),
  collected_by: T("Collected by"),
  car: T("Car"),
  lead_ref: T("Lead Ref #"),
  change_payment_status: T("Change payment status"),
  view_payment_status: T("View payment status"),
  select_connect: T("Select connect"),
  verification_date: T("Verification Date"),
  verified_by: T("Verified by"),
  mark_as_sold_success_msg: T("Successfully marked as sold!"),
  confirmation: T("Confirmation"),
  edit_payment: T("Edit payment"),
  report_reference_number: T("Report Reference Number"),
  report_ref_ph: T("Enter Reference Number"),
  widgets_count: T("Widgets count"),
  view_widgets: T("View widgets"),
  widgets: T("Widgets"),
  records_per_page_min_number: T("Records per page cannot be less than 20"),
  records_per_page_max_number: T("Records per page cannot be greater than 30"),
  add_approval: T("Add Approval"),
  add_approvers: T("Add Approvers"),
  update_approvers: T("Update Approvers"),
  approval_type: T("Approval Type"),
  initiated_by: T("Initiated By"),
  requested_at: T("Requested At"),
  approved_by: T("Approved By"),
  approved_at: T("Approved At"),
  approved_status: T("Status"),
  approvals: T("Approvals"),
  select_approval_type: T("Select Approval Type"),
  approval_status: T("Approval Status"),
  select_approval_status: T("Select Approval Status"),
  created_by: T("Created by"),
  select_user: T("Select User"),
  auto_assign: T("Auto Assign"),
  upload_file_message: T("Please upload file"),
  download: T("Download"),
  reassign: T("Reassign"),
  over_booking: T("Over booking"),
  make_modal_variant: T("Make Model Variant"),
  add_make_modal_variant: T("Add MMV"),
  select_value: T("Select Value"),
  parent_key: T("Parent Key"),
  parent_value: T("Parent Value"),
  type_ref: T("Ref #"),
  parent_ref: T("Parent Ref #"),
  enter_value: T("Enter value"),
  recommended_buyer: T("Recommended Buyer"),
  evaluated_at: T("Approved At"),
  evaluated_date: T("Evaluated At"),
  parent_type: T("Parent type"),
  parent_name: T("Parent name"),
  mmvs: T("MMVs"),
  external_id: T("External Id"),
  external_id_ph: T("Please enter external id"),
  translation_ph: T("Enter translation"),
  update_make_modal_variant: T("Update MMV"),
  bank_name: T("Bank Name"),
  bank_name_ph: T("Select Bank"),
  select_bank_name: T("Select Bank Name"),
  inspectors: T("Inspector(s)"),
  ads_data: T("Ads Data"),
  campaign_form_data: T("Campaign form data"),
  booking_expenses: T("Booking Expenses"),
  pickup_location: T("Select Pick Up Location"),
  dropoff_location: T("Select Drop Off Location"),
  pickup_location_msg: T("Please Select Pick Up Location"),
  dropoff_location_msg: T("Please Select Drop Off Location"),
  expenses: T("Expenses"),
  full_address: T("Full Address"),
  enter_full_address: T("Enter Full Address"),
  full_address_ph: T("Please Enter Full Address "),
  address_link: T("Address Link"),
  address_link_ph: T("Enter Address Link"),
  address_translation: T("Address Translation"),
  report_completed_at: T("Report Completed At"),
  address_link_msg: T("Please Enter Address Link"),
  offer_trails: T("Offer Trails"),
  listing_price_history: T("Listing Price History"),
  external_id_present: T("External Present"),
  select_type: T("Select Type"),
  apply_leave: T("Apply Leave"),
  days: T("Days"),
  add_leave: T("Add Leave"),
  full_day: T("Full Day"),
  hourly: T("Hourly"),
  Task_Ref: T("Task Ref#"),
  follow_up_required: T("Follow Up Required"),
  outcome_status: T("Outcome Status"),
  end_date: T("End Date"),
  add_schedule: T("Add Schedule"),
  overaged_email: T("Overaged Email"),
  overaged_email_title: T("Send Overaged Car Email"),
  resend_overaged_email_title: T("Resend Overaged Car Email"),
  last_email_sent_at: T("Last Email Sent at "),
  email_sent_to: T("Email Sent to "),
  listing_expiry_alert: T("Listing Expiry Alert"),
  user: T("User"),
  update_leave: T("Update Leave"),
  contracts: T("Contracts"),
  seller_lead_ref: T("Seller Lead Ref#"),
  buyer_lead_ref: T("Buyer Lead Ref#"),
  contract_type: T("Contract Type"),
  contract_type_ph: T("Select Contract Type"),
  add_contract: T("Add Contract"),
  edit_contract: T("Edit Contract"),
  select_assignee: T("Select Assignee"),
  select_contract_type: T("Select Contract Type"),
  recipient: T("Recipient"),
  verified_by_team_lead: T("Verified by Team Lead"),
  verified_by_finance: T("Verified by Finance"),
  not_verified_by_team_lead: T("Not Verified by Team Lead"),
  not_verified_by_finance: T("Not verified by Finance"),
  contract_types: T("Contract Types"),
  vehicle_details: T("Vehicle Details"),
  party_details: T("Party Details"),
  vehicle_details_editing: T("Please Save Vehicle Details"),
  party_details_editing: T("Please Save Party Details"),
  select_reg_no: T("Select Registration Number"),
  position: T("Position"),
  position_ph: T("Please enter position number"),
  dre_assignee_ph: T("Select DRE Assignee"),
  dre_assignee: T("DRE Assignee"),
  assign_dre: T("Assign DRE"),
  dre_id: T("DRE"),
  KITT: T("KITT"),
  get_kitt_price: T("Get KITT Price"),
  kitt_car_pricing: T("KITT Car Pricing"),
  fuel_type: T("Fuel Type"),
  Kitt_id: T("KITT ID"),
  automated_insights: T("Automated Insights"),
  car_fleet: T("Car Fleet"),
  car_fleet_ph: T("Select Car Fleet"),
  specs: T("Specs"),
  specs_ph: T("Select Specs"),
  accident_type: T("Accident Type"),
  accident_type_ph: T("Select Accident Type"),
  panels_painted: T("No. of Panels Painted"),
  panels_painted_ph: T("Select No. of Panels Painted"),
  odometer_rollback: T("Odometer Rollback"),
  odometer_rollback_ph: T("Select Odometer Rollback"),
  additional_reconditioning_expenses: T("Additional Reconditioning Expenses"),
  additional_reconditioning_expenses_ph: T("Select Additional Reconditioning Expenses"),
  value_ph: T("Please Select a Value"),
  seller_loan: T("Seller Loan"),
  seller_loan_ph: T("Select Seller Loan"),
  insured_in_uae: T("Insured in UAE"),
  vat_applicable: T("VAT Applicable"),
  vat_invoice_available: T("VAT Invoice Available"),
  vat_applicable_ph: T("Select VAT Applicable"),
  great_offer: T("Great Offer"),
  fair_offer: T("Fair Offer"),
  risky_offer: T("Risky Offer"),
  expected_purchase_price: T("Expected Purchase Price"),
  expected_selling_price_vat: T("Expected Selling Price (Inc.VAT)"),
  expected_highest_bid: T("Expected Highest Bid"),
  bank_evaluation: T("Bank Evaluation"),
  VAT: T("VAT"),
  total_price: T("Total Price"),
  total_reconditioning_expenses: T("Total Reconditioning Expenses"),
  expected_selling_price: T("Expected Selling Price"),
  assigned_dre: T("Assigned DRE"),
  bidder_ref_no: T("Bidder #"),
  updated_at: T("Updated At"),
  no_attachments: T("You do not have any attachments"),
  uploaded_at: T("Uploaded At"),
  upload_more: T("Upload More"),
  folder_ph: T("Select folder"),
  delete_attachments: T("Delete Attachments"),
  sort_by: T("Sort by"),
  sort_order: T("Sort Order"),
  vin_plate: T("VIN number must be valid and 17 digits long"),
  no_spaces_vin: T("VIN number should not contain spaces"),
  view_internal_info: T("View Internal Info"),
  internal_info: T("Internal Info"),
  projected_expense: T("Projected Expenses"),
  seating_capacity: T("No. Of Seats"),
  select_seating_capacity: T("Select No. of Seats"),
  select_engine_capacity: T("Select Engine Capacity (cc)"),
  no_projected_expenses: T("No Expenses Available"),
  no_expenses_message: T("No projected expense result is available to show"),
  total_expense_per_report: T("Projected total expense according to report"),
  total_expense: T("Total Expense"),
  option_ph: T("Select an Option"),
  enter_value_ph: T("Enter a Value"),
  no_special_characters_allowed: T("Special characters are not allowed"),
  internal_view: "Internal View",
  buyer_view: "Buyer View",
  single_report: "Report",
  preferred_language: T("Preferred Language"),
  preferred_language_ph: T("Select preferred language"),
  buyer_view_report: "Buyer Report",
  full_vat_applicable: T("Full VAT Applicable"),
  profit_margin_scheme: T("Profit Margin Scheme"),
  negotiated_offer: T("Negotiated Offer"),
  negotiated_bid: T("Negotiated Bid"),
  margin: T("Margin"),
  counter_offer: T("Counter Offer"),
  accept: T("Accept"),
  enter_counter_offer: T("Enter Counter Offer"),
  proof: T("Proof"),
  mark_as_negotiator: T("Mark as negotiator"),
  mark_as_negotiator_prompt: T("Are you sure, you want to start negotiation with "),
  reject_negotiation: T("Reject Offer"),
  extend_negotiation: T("Extend Negotiation"),
  reject_negotiation_prompt: T("Are you sure you want to reject this offer?"),
  extend_negotiation_prompt: T("Are you sure you want to extend this negotiation?"),
  highest_bid: T("Highest Bid"),
  seller_expectation: T("Seller Expectation"),
  call_recording: T("Call Recordings"),
  call: T("Call"),
  call_src: T("Call Src"),
  call_type: T("Call Type"),
  call_type_inbound: T("Inbound"),
  call_type_outbound: T("Outbound"),
  create_qc_inspection: T("Create QC Report"),
  view_qc_inspection: T("View QC Report"),
  lead_phone: T("Lead Phone"),
  select_template: T("Select Template"),
  serial_hash: T("Sr#"),
  use_template: T("Use Template"),
  inspector_performance_feedback: T("Inspector Performance Feedback"),
  overall_quality_analysis: T("Overall Rating"),
  no_templates_found: T("No Templates Found"),
  overall_quality: T("Overall Quality"),
  call_details: T("Call Details"),
  recording_qc_status: T("Evaluated"),
  evaluation_done: T("Evaluation Done"),
  unevaluated_call: T("Unevaluated Call"),
  report_ph: "Report",
  qc_score: T("QC Score"),
  evaluated_by: T("Evaluated By"),
  association_info: T("Association Info"),
  qc_questionnaire: T("QC Questionnaire"),
  agent_extension: T("Agent Extension"),
  days_ago: T("Days ago"),
  all_photos: T("All Photos"),
  field_mapping: T("Field Mapping"),
  whatsapp_trigger_controller: T("WhatsApp Controller"),
  field_id: T("Field Id"),
  external_present: T("External Present"),
  ID: T("ID"),
  lookup: T("Select an external key to link"),
  select_field: T("Select Field"),
  external_name: T("External Name"),
  revising_offer: T("Revising Offer"),
  my_last_offer: T("My Last Offer"),
  purchaser_last_offer: T("Purchaser's Latest Offer"),
  revise_offer: T("Revise Offer"),
  enter_revise_offer: T("Enter Revised Offer"),
  dre_latest_offer: T("Dre's Latest Offer"),
  seller_latest_offer: T("Seller's Latest Offer"),
  bidder_latest_offer: T("Bidder's Latest Offer"),
  adjust_margin: T("Adjust Margin"),
  price_change_warning: T(
    "The listing price of the inventory is changed, it will also change the price in the Ad Post. Are you sure you want to save the changes?"
  ),
  price_update_confirmation_title: T("Listing Price Update Confirmation"),
  ad_update_confirmation_title: T("Ad Post Update Confirmation"),
  sync_from_inventory: T("Sync From Inventory"),
  no_of_keys: T("No of Keys"),
  seller_expectations: T("Seller Expectations"),
  negotiation_created_at: T("Negotiation Created At"),
  negotiation_statuses: T("Negotiation Statuses"),
  negotiationDre: T("Negotiation DRE"),
  revise: T("Revise"),
  counter: T("Counter"),
  accept_offer: T("Accept Offer"),
  user_info: T("User Info"),
  auction_margin: T("Auction Margin"),
  auction_margin_slabs: T("Auction Margin Slabs"),
  retail_price_reduction: T("Price Reductions"),
  offer_from: T("Offer From (AED)"),
  offer_to: T("Offer To (AED)"),
  margin_value: T("Margin Value"),
  no_of_days: T("No of Days"),
  no_of_car: T("No of Car Viewing appointments marked “Done”"),
  price_reduction: T("Reduction (%) Or Price (AED)"),
  no_negative_values_are_allowed: T("Negative Values are not allowed"),
  auction_creation_criteria: T("Auction Creation Criteria"),
  auction_error_criteria_info: T("Following fields are mandatory to add to auction"),
  negotiation_status: T("Negotiation Status"),
  negotiation_start_time: T("Negotiation Start Time"),
  assisted_by: T("Assisted By"),
  purchaser: T("Purchaser"),
  is_coming_soon: T("Is Coming Soon"),
  amount_greater_than_one: T("amount should be greater than 1"),
  price_breakdown_exceeds_amount: T("Price breakdown exceeds the total amount"),
  price_breakdown_less_than_amount: T("Price breakdown must be equal to the total amount"),
  miscellaneous: T("Miscellaneous"),
  select_source_name: T("Select source to view results"),
  external_key: T("External Key"),
  no_source_selected: T("No Source Selected"),
  value_date: T("Value Date"),
  enter_value_date: T("Enter Value Date"),
  confirm_changes: "Do you want to save the Changes to",
  enable_msg: "Do you want to enable the ",
  disable_msg: "Do you want to disable the ",
  date_too_old: T("Value date cannot be older than 30 days"),
  driver_license: T("Driver's License Number"),
  enter_driving_license: T("Enter Driving License Number"),
  license_expiry: T("License Expiry Date"),
  enter_license_expiry: T("Expiry Date"),
  terms_and_conditions: T("Terms & Conditions"),
  agreement: T("I have read and agreed to"),
  customer_signature: T("Customer Signature"),
  customer_signature_ph: T("Enter Customer Signature"),
  customer_name: T("Customer Name"),
  enter_customer_name: T("Enter Customer Name"),
  fsr: T("FSR"),
  generate_title_description: T("Generate Title & Description"),
  clear_title_description: T("Clear Title & Description"),
  adyen_id: T("Adyen ID"),
  payment_link: T("Payment Link"),
  adyen_link_status: T("Adyen Link Status"),
  adyen_payment_link: T("Adyen Payment Link"),
  regenerate_link_confirmation: T("Regenerate Link Confirmation"),
  adyen_link: T("Adyen Link"),
  scan_to_pay: T("Scan to Pay"),
  specifiy_reason_for_regeneration: T("Please specify the reason for regenerating the link."),
  select_reason: T("Select Reason"),
  approvals_settings: T("Approvals Settings"),
  add_approver: T("Add Approver"),
  ad_status: T("Ad Status"),
  entity_name: T("Entity Name"),
  enter_entity_name: T("Enter Entity Name"),
  cva: T("No. of Car Viewings"),
  reduction: T("Reduction"),
  auto_assign_external_evaluation: T("Auto Assign External Evaluations"),
  daily_avg: T("Avg. Ad Views"),
  views_yesterday: T("Views Yesterday"),
  ad_searches: T("Avg. Search Volume"),
  ad_views: T("Ad Views"),
  ad_disposition_done: T("Done/Completed"),
  ad_disposition_reschedule: T("Rescheduled"),
  ad_disposition_cancel: T("Cancelled/Others"),
  leads: T("Leads"),
  whatsapp: T("Whatsapp"),
  phone: T("Phone"),
  test_drives: T("Test Drives"),
  test_drives_over_time: T("Test Drives Over Time"),
  credits_consumed: T("Credits Consumption And Search Values"),
  kitt_fair_listing_price: T("KITT Fair Listing Price "),
  kitt_fetched: T("Kitt Fetched"),
  is_auto_approved: T("Auto Approved"),
  payment_link_regenerated: T("Payment Link Regenerated"),
  age_months: T("Age (months)"),
  external_appointment_update: T("External Appointment Update"),
  blacklist_client: T("Blacklist Client"),
  remove_from_blacklist: T("Remove from Blacklist"),
  is_blacklisted: T("Is Blacklisted"),
  blacklisted: T("Blacklisted"),
  risks: T("Risks"),
  add_new_lead: T("Add New Lead"),
  vin_duplication_search_placeholder: T("Enter a 17-digit VIN number"),
  phone_duplication_search_placeholder: T("Enter the client phone number"),
  no_leads_found: T("No leads found!"),
  no_inventories_found: T("No inventories found!"),
  existing_inventory_details: T("Existing Inventory Details"),
  enter_search_value: T("Please enter search value"),
  select_criteria: T("Please select a search criteria"),
  leads_count: T("Leads Count"),
  phone_duplication_search_empty_message: T("Enter Client Phone Number to find any duplicates"),
  vin_duplication_search_empty_message: T("Enter Vehicle Identification Number to find any duplicates"),
  car_details: T("Car Details"),
  category: T("Category"),
  status_disp: T("Status • Disp."),
  initiated_at: T("Initiated At"),
  approvers: T("Approvers"),
  out_of_zone: T("Out of Zone"),
  payment_link_status: T("Payment Link Status"),
  copy_link_message: T("You can copy the link and share with client to pay online."),
  link_generated_message: T("Your Payment link is successfully generated!"),
  link_regenerate_confirmation: T("Are you sure you want to regenerate the payment link?"),
  receipt: T("Receipt"),
  shopper_ref_number: T("Shopper Ref."),
  psp_id: T("PSP ID"),
  previous_link_discarded: T("This will automatically discard the previously generated link."),
  admin_fee: T("Admin Fee"),
  total_amount: T("Total Amount"),
  payment_link_not_generated_msg: T("Payment link could not be generated"),
};
