import {
  ADD_INSPECTION_PROGRAM,
  FETCH_INSPECTION_PROGRAMS,
  UPDATE_INSPECTION_PROGRAM,
} from "../../constant/actionTypes";

const INITIAL_STATE = {
  inspections: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_INSPECTION_PROGRAMS:
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
        inspections: [...action.payload.inspections],
        pagination: action.payload?.pagination ? { ...action.payload.pagination } : { ...state.pagination },
      };
    case ADD_INSPECTION_PROGRAM:
      return {
        ...state,
        inspections: [action.payload.item].concat([...state.inspections]),
      };
    case UPDATE_INSPECTION_PROGRAM:
      let inspectionsList = state.inspections;
      let index = inspectionsList.findIndex(e => e.id === action.payload.item.id);
      inspectionsList.splice(index, 1, { ...action.payload.item });
      return {
        ...state,
        inspections: [...inspectionsList],
      };
    // case DELETE_INSPECTION_PROGRAM:
    //   let inspectionsListt = state.inspections;
    //   const ar = inspectionsListt.filter((item) => !(item.id === 3));
    //   console.log("VALUES", ar);
    //   return {
    //     ...state,
    //   };

    default:
      return state;
  }
};
