import { clone } from "../../helpers/Util";

export const INITIAL_STATE = {
  questionnaireWithSections: [],
  loading: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case "set-loading":
      return clone({
        ...state,
        loading: action.payload.loading,
      });

    case "set-questionnaire":
      return clone({
        ...state,
        ...action.payload,
      });

    default:
      break;
  }
}

export const actionFactory = dispatch => {
  const setLoading = loading =>
    dispatch({
      type: "set-loading",
      payload: {
        loading,
      },
    });

  const setQuestionnaire = payload =>
    dispatch({
      type: "set-questionnaire",
      payload,
    });

  return {
    setLoading,
    setQuestionnaire,
  };
};
