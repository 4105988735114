export const getQRDesign = (width = 250, height = 250, url, img) => ({
  width: width,
  height: height,
  data: url,
  margin: 0,
  qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "H" },
  imageOptions: { hideBackgroundDots: true, imageSize: 0.5, margin: 5, crossOrigin: "anonymous" },
  dotsOptions: { type: "dots", color: "#000000" },
  backgroundOptions: { color: "#ffffff" },
  image: img,
  dotsOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: {
      linear: true,
      radial: false,
      color1: "#6a1a4c",
      color2: "#6a1a4c",
      rotation: "0",
    },
  },
  cornersSquareOptions: { type: "extra-rounded", color: "#000000" },
  cornersSquareOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: {
      linear: true,
      radial: false,
      color1: "#000000",
      color2: "#000000",
      rotation: "0",
    },
  },
  cornersDotOptions: { type: "dot", color: "#d40000", gradient: null },
  cornersDotOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: {
      linear: true,
      radial: false,
      color1: "#000000",
      color2: "#000000",
      rotation: "0",
    },
  },
  backgroundOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: {
      linear: true,
      radial: false,
      color1: "#ffffff",
      color2: "#ffffff",
      rotation: "0",
    },
  },
});
