import * as yup from "yup";

import {
  ButtonLoading,
  CheckboxComponent,
  CustomAlert,
  DateSelect,
  FormCategorySelect,
  FormInput,
  SelectionListTypeHead,
  Toast,
} from "../common";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  addPermittedProperty,
  appendTimeZone,
  checkUndefinedApiParams,
  excludeTodayTimes,
  getCanScheduleDate,
  getErrorString,
  getPermission,
  isFieldEditable,
  isFieldViewable,
  makeAresSelectionData,
  makeLeadTypeListToSelectData,
  makeTeamsSelectData,
  setTimeZone,
} from "../../helpers/Util";
import {
  createAndScheduleQC,
  fetchAvailableTeams,
  fetchCityAreas,
  fetchInspectionsApi,
  fetchLeadTypesApi,
  updateLeadApi,
} from "../../helpers/ApiHelper";

import { Formik, useFormikContext } from "formik";
import { commentsValidation } from "../../helpers/validations";
import { connect as reduxConnect, useSelector } from "react-redux";
import { strings } from "../../constant/strings";
import { selectActiveColumns } from "../../reducers/general/selectors";

const FormikHelper = ({ fetchTeamsData, inspection_id, city_id }) => {
  const { values } = useFormikContext();
  const area = values?.cityArea;
  const area_id = area?.id;
  const queryParam = `&area_id=${area_id}`;
  useEffect(() => {
    if (area) {
      fetchTeamsData(values?.date, inspection_id, city_id, queryParam);
    }
  }, [area]);

  return null;
};

const ScheduleForm = props => {
  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const scheduleFormPermittedAttributes = activeColumnPermittedAttributes.Lead;
  let formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [teamsError, setTeamsError] = useState("");
  const [areas, setAreas] = useState([]);
  const [areasLoading, setAreasLoading] = useState(false);
  const [areasError, setAreasError] = useState("");
  const [leadTypes, setLeadTypes] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [loadingLeadTypes, setLoadingLeadTypes] = useState(false);
  const [errorLeadTypes, setErrorLeadTypes] = useState("");
  const { lead, onDone, dispositionId, tenant, fRef, fromQC } = props;
  const { city_id, inspection_id } = lead;

  const { timezone } = tenant.country;
  const hasQcInspection = tenant.configuration.has_qc_inspection === true;
  const today = setTimeZone(timezone);
  const scheduleDate = inspection_id ? getCanScheduleDate(inspections, inspection_id, timezone) : today;

  useEffect(() => {
    async function fetchInspections() {
      const response = await fetchInspectionsApi();
      if (response.success) {
        setInspections(response.inspections);
      }
    }
    fetchInspections();
  }, []);

  useImperativeHandle(fRef, () => ({
    clearValues() {
      formikRef.current.setValues(
        {
          cityArea: null,
          date: scheduleDate,
          assignTo: null,
          address: "",
          comments: "",
        },
        false
      );
    },
  }));

  useEffect(() => {
    lead && !inspection_id && fetchLeadTypes();
    getPermission("Area", "index") && fetchAreas();
    getPermission("Schedule", "available_teams") && inspection_id && fetchTeamsData(scheduleDate, inspection_id);
  }, [lead]);

  const fetchAreas = async () => {
    setAreasLoading(true);
    const res = await fetchCityAreas(`?q[city_id_eq]=${city_id.toString()}`);
    if (res) {
      setAreasLoading(false);
      if (res.success && res.areas) {
        setAreas(makeAresSelectionData(res.areas));
      } else {
        setAreasLoading(false);
        setAreasError(true);
      }
    }
  };

  const fetchLeadTypes = async () => {
    setLoadingLeadTypes(true);
    const response = await fetchLeadTypesApi("?limit=1000");
    if (response) {
      setLoadingLeadTypes(false);
      if (response.success) {
        setLeadTypes([...response.lead_types]);
      } else {
        setErrorLeadTypes(getErrorString(response));
      }
    }
  };

  const getInitialValues = () => ({
    leadType: lead && !inspection_id ? null : undefined,
    ...addPermittedProperty(scheduleFormPermittedAttributes?.["schedule.area_id"], {
      inspectionScheduleType: null,
    }),
    ...addPermittedProperty(scheduleFormPermittedAttributes?.["schedule.area_id"], {
      cityArea: null,
    }),
    ...(isFieldViewable(scheduleFormPermittedAttributes?.["schedule.start_time"]) &&
      isFieldViewable(scheduleFormPermittedAttributes?.["schedule.end_time"]) && {
        date: scheduleDate,
      }),

    ...addPermittedProperty(scheduleFormPermittedAttributes?.["schedule.team_id"], {
      assignTo: null,
    }),
    ...addPermittedProperty(scheduleFormPermittedAttributes?.["schedule.address"], {
      address: "",
    }),
    ...addPermittedProperty(scheduleFormPermittedAttributes?.["timelines.comment"], {
      comments: "",
    }),
  });

  const validationSchema = () => {
    return yup.object().shape({
      leadType:
        lead && !inspection_id
          ? yup
              .object()
              .nullable()
              .required("Please select lead type")
              .shape({ label: yup.string().required("Lead type not exists") })
          : undefined,
      ...(isFieldViewable(scheduleFormPermittedAttributes?.["schedule.area_id"]) && {
        cityArea: yup
          .object()
          .nullable()
          .required("Please select city area")
          .shape({ name: yup.string().required("City area does not exist") }),
      }),

      ...(isFieldViewable(scheduleFormPermittedAttributes?.["schedule.area_id"]) && {
        cityArea: yup
          .object()
          .nullable()
          .notRequired()
          .when(["inspectionScheduleType"], {
            is: inspectionScheduleType => inspectionScheduleType?.id === 1,
            then: yup
              .object()
              .nullable()
              .required("Please select city area")
              .shape({ name: yup.string().required("City area does not exist") }),
          }),
      }),
      ...(isFieldViewable(scheduleFormPermittedAttributes?.["schedule.start_time"]) &&
        isFieldViewable(scheduleFormPermittedAttributes?.["schedule.end_time"]) && {
          date: yup.string().required("Please select a date"),
        }),

      ...addPermittedProperty(scheduleFormPermittedAttributes?.["schedule.team_id"], {
        assignTo: yup
          .object()
          .nullable()
          .required("Please select team")
          .shape({ name: yup.string().required("Team does not exist") }),
      }),

      ...addPermittedProperty(scheduleFormPermittedAttributes?.["schedule.address"], {
        address: yup
          .string()
          .required("Please enter address")
          .matches(/^(?!\s+$).*/, "Address should not have spaces only"),
      }),

      ...addPermittedProperty(scheduleFormPermittedAttributes?.["timelines.comment"], {
        comments: commentsValidation,
      }),
    });
  };

  const fetchTeamsData = async (e, inspection_id, cityId = city_id, queryParams = "") => {
    if (inspection_id) {
      const dateString = appendTimeZone(e, timezone);
      setTeamsLoading(true);
      const res = await fetchAvailableTeams(
        dateString,
        cityId,
        inspection_id,
        hasQcInspection && lead?.qc_lead_id && !fromQC ? lead?.qc_lead_id : null,
        fromQC ? lead?.team?.id : null,
        queryParams
      );
      if (res.success && res.teams) {
        setTeamsLoading(false);
        setTeams(res.teams);
      } else {
        setTeamsLoading(false);
        setTeamsError(getErrorString(res));
      }
    }
  };

  const onSubmit = async (values, { setStatus, setFieldError }) => {
    if (!checkUndefinedApiParams(lead?.id, "lead")) return;
    const { leadType, cityArea, date, assignTo, address, comments, ...dynamic } = values;
    const dynamic_attributes = {};
    if (!inspection_id) {
      let error = false;
      leadType.dynamic_fields.forEach((e, i) => {
        if (e.is_required && !dynamic["dynamic-" + e.field_name] && e.field_data_type !== "boolean") {
          error = true;
          setFieldError("dynamic-" + e.field_name, "This field is required");
        } else if (e.field_regex) {
          if (!new RegExp(e.field_regex).test(dynamic["dynamic-" + e.field_name])) {
            error = true;
            setFieldError("dynamic-" + e.field_name, "This field is not valid");
          }
        }
      });
      if (error) {
        return;
      }
      Object.keys(dynamic).forEach(e => {
        dynamic_attributes[e.replace("dynamic-", "")] = dynamic[e];
      });
    }
    setStatus({ message: "" });
    setLoading(true);

    const dateString = appendTimeZone(date, timezone);

    const appendScheduleCityId = () => {
      if (cityArea?.id) return { city_id: lead?.city?.id };
      return {};
    };

    const appendScheduleAreaId = () => {
      if (cityArea?.id) return { area_id: cityArea?.id };
      return {};
    };

    const postObj = {
      lead: {
        ...addPermittedProperty(
          scheduleFormPermittedAttributes?.["team_id"],
          {
            team_id: assignTo?.id,
          },
          true
        ),

        ...addPermittedProperty(
          scheduleFormPermittedAttributes?.["disposition.id"],
          {
            disposition_id: dispositionId,
          },
          true
        ),

        schedules_attributes: [
          {
            ...addPermittedProperty(
              scheduleFormPermittedAttributes?.["schedule.team_id"],
              {
                ...(assignTo?.schedule?.id ? { id: assignTo.schedule.id } : {}),
              },
              true
            ),

            ...addPermittedProperty(
              scheduleFormPermittedAttributes?.["schedule.start_time"],
              {
                start_time: dateString,
              },
              true
            ),

            ...addPermittedProperty(
              scheduleFormPermittedAttributes?.["schedule.city_id"],
              {
                ...appendScheduleCityId(),
              },
              true
            ),

            ...addPermittedProperty(
              scheduleFormPermittedAttributes?.["schedule.city_id"],
              {
                ...appendScheduleAreaId(),
              },
              true
            ),

            ...addPermittedProperty(
              scheduleFormPermittedAttributes?.["team_id"],
              {
                team_id: assignTo?.id,
              },
              true
            ),

            ...addPermittedProperty(
              scheduleFormPermittedAttributes?.["schedule.inspection_id"],
              {
                inspection_id: !inspection_id ? leadType.inspection.id : lead.inspection_id,
              },
              true
            ),

            ...addPermittedProperty(
              scheduleFormPermittedAttributes?.["schedule.address"],
              {
                address: address,
              },
              true
            ),
          },
        ],
        timelines_attributes: [
          {
            ...addPermittedProperty(
              scheduleFormPermittedAttributes?.["disposition.id"],
              {
                disposition_id: dispositionId,
              },
              true
            ),

            ...addPermittedProperty(
              scheduleFormPermittedAttributes?.["timelines.comment"],
              {
                comment: comments,
              },
              true
            ),
          },
        ],
        ...(!inspection_id
          ? {
              ...addPermittedProperty(
                scheduleFormPermittedAttributes?.["inspection_id"],
                {
                  inspection_id: leadType.inspection.id,
                },
                true
              ),

              ...addPermittedProperty(
                scheduleFormPermittedAttributes?.["lead_type.id"],
                {
                  lead_type_id: leadType.id,
                },
                true
              ),

              dynamic_attributes,
            }
          : {}),
      },
    };

    try {
      const res = fromQC ? await createAndScheduleQC(lead.id, postObj) : await updateLeadApi(lead.id, postObj);
      if (res) {
        setLoading(false);
        if (res.lead?.id) {
          onDone(res.lead, fromQC);
          Toast.success(strings.lead_scheduled);
        } else {
          setStatus({
            message: getErrorString(res),
          });
        }
      }
    } catch (error) {
      setLoading(false);
      setStatus({
        message: getErrorString(error),
      });
    }
  };

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {({
          setFieldValue,
          setFieldError,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
        }) => {
          return (
            <form className="form theme-form page__inner-form">
              <>
                <FormikHelper fetchTeamsData={fetchTeamsData} inspection_id={inspection_id} city_id={city_id} />
                {!inspection_id && (
                  <>
                    <FormCategorySelect
                      menuStyles="action_dropDown_filter"
                      selected={values.leadType?.name ? values.leadType?.name : strings.lead_type_ph}
                      label={strings.lead_type}
                      errorMsg={errors.leadType && touched.leadType && errors.leadType}
                      options={makeLeadTypeListToSelectData(leadTypes, "", tenant.country)}
                      isLoading={loadingLeadTypes}
                      isError={errorLeadTypes}
                      onChange={selected => {
                        if (selected) {
                          setFieldValue("leadType", selected);
                          fetchTeamsData(values.date, selected.inspection.id);
                          leadTypes &&
                            leadTypes
                              .find(it => it.id === selected.id)
                              .dynamic_fields.forEach(item => {
                                if (item.field_data_type === "boolean") {
                                  setFieldValue("dynamic-" + item.field_name, false);
                                } else {
                                  setFieldValue("dynamic-" + item.field_name, "");
                                }
                              });
                        } else {
                          setFieldValue("leadType", null);
                        }
                      }}
                    />

                    {values.leadType &&
                      values.leadType?.dynamic_fields.map((e, i) => {
                        if (
                          e.field_data_type === "string" ||
                          e.field_data_type === "integer" ||
                          e.field_data_type === "text"
                        ) {
                          return (
                            <FormInput
                              label={e.field_label}
                              placeholder={e.field_place_holder}
                              onChange={event => {
                                handleChange("dynamic-" + e.field_name)(event);
                              }}
                              onBlur={handleBlur("dynamic-" + e.field_name)}
                              value={values["dynamic-" + e.field_name]}
                              linesCount={e.field_data_type === "text" ? "3" : undefined}
                              errorMsg={
                                errors["dynamic-" + e.field_name] &&
                                touched["dynamic-" + e.field_name] &&
                                errors["dynamic-" + e.field_name]
                              }
                              required
                              key={i}
                            />
                          );
                        } else if (e.field_data_type === "boolean") {
                          return (
                            <CheckboxComponent
                              label={e.field_label}
                              checked={values["dynamic-" + e.field_name]}
                              onChange={event => {
                                setFieldValue("dynamic-" + e.field_name, event.target.checked);
                              }}
                            />
                          );
                        }
                      })}
                  </>
                )}
                <>
                  {isFieldViewable(scheduleFormPermittedAttributes?.["schedule.area_id"]) && (
                    <SelectionListTypeHead
                      label={strings.city_area}
                      placeholder={strings.city_area_ph}
                      options={areas}
                      isLoading={areasLoading}
                      isError={areasError}
                      selected={values.cityArea ? [{ ...values.cityArea }] : undefined}
                      onChange={selected => {
                        selected[0] ? setFieldValue("cityArea", selected[0]) : setFieldValue("cityArea", null);
                      }}
                      labelKey="name"
                      errorMsg={errors.cityArea && touched.cityArea && errors.cityArea}
                      clearButton={undefined}
                      isEditable={isFieldEditable(scheduleFormPermittedAttributes?.["schedule.area_id"])}
                    />
                  )}
                </>

                {isFieldViewable(scheduleFormPermittedAttributes?.["schedule.start_time"]) &&
                  isFieldViewable(scheduleFormPermittedAttributes?.["schedule.end_time"]) && (
                    <DateSelect
                      onChange={e => {
                        setFieldValue("date", e);
                        if (getPermission("Schedule", "available_teams")) {
                          fetchTeamsData(
                            e,
                            lead.lead_type ? inspection_id : values.leadType?.inspection.id,
                            city_id,
                            `&area_id=${values?.cityArea?.id}`
                          );
                        }
                      }}
                      selected={values.date}
                      label={strings.date_and_time}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      inspection_id={inspection_id}
                      excludeTimes={excludeTodayTimes(15, values.date, timezone)}
                      excludeDates={today.getDay() !== scheduleDate.getDay() ? [today] : []}
                      timeZone={timezone}
                      inspections={inspections || []}
                      isEditable={
                        isFieldEditable(scheduleFormPermittedAttributes?.["schedule.start_time"]) &&
                        isFieldEditable(scheduleFormPermittedAttributes?.["schedule.end_time"])
                      }
                    />
                  )}

                {values.date && isFieldViewable(scheduleFormPermittedAttributes?.["schedule.team_id"]) && (
                  <SelectionListTypeHead
                    className="form-group"
                    label={strings.assign_to}
                    placeholder={strings.assign_to_ph}
                    options={makeTeamsSelectData(teams)}
                    isLoading={teamsLoading}
                    isError={teamsError}
                    selected={values.assignTo ? [{ ...values.assignTo }] : undefined}
                    onChange={selected => {
                      setFieldValue("assignTo", selected[0] || null);
                    }}
                    onFocus={e => {
                      if (!teams.length) {
                        if (!inspection_id && !values.leadType) {
                          setFieldError("leadType", strings.lead_type_ph);
                        }
                      }
                    }}
                    labelKey="name"
                    errorMsg={errors.assignTo && touched.assignTo && errors.assignTo}
                    clearButton={undefined}
                    isEditable={isFieldEditable(scheduleFormPermittedAttributes?.["schedule.team_id"])}
                  />
                )}

                {isFieldViewable(scheduleFormPermittedAttributes?.["schedule.address"]) && (
                  <FormInput
                    label={strings.address}
                    placeholder={strings.address_ph}
                    linesCount="3"
                    onBlur={handleBlur("address")}
                    value={values.address}
                    errorMsg={errors.address && touched.address && errors.address}
                    onChange={e => {
                      handleChange("address")(e);
                    }}
                    required
                    isEditable={isFieldEditable(scheduleFormPermittedAttributes?.["schedule.address"])}
                  />
                )}

                {isFieldViewable(scheduleFormPermittedAttributes?.["timelines.comment"]) && (
                  <FormInput
                    label={strings.comments}
                    placeholder={strings.comments_ph}
                    linesCount="3"
                    onBlur={handleBlur("comments")}
                    value={values.comments}
                    errorMsg={errors.comments && touched.comments && errors.comments}
                    onChange={e => {
                      handleChange("comments")(e);
                    }}
                    isEditable={isFieldEditable(scheduleFormPermittedAttributes?.["timelines.comment"])}
                  />
                )}

                {status && status.message && <CustomAlert message={status.message} />}
                <ButtonLoading
                  className="theme__primary-btn pull-left"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                  loading={loading}
                >
                  {strings.schedule}
                </ButtonLoading>
              </>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ Configs }) => ({
  tenant: Configs.tenant,
});

const ConnectedComponent = reduxConnect(mapStateToProps, {})(ScheduleForm);

export default forwardRef((props, ref) => <ConnectedComponent {...props} fRef={ref} />);
