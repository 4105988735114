import React, { useState } from "react";
import cx from "clsx";
import { MoreHorizontal } from "react-feather";
import { Popover, PopoverBody } from "reactstrap";
import "./HorizontalPopover.module.scss";

const HorizontalPopover = props => {
  const { items, id = "", placement, trigger, popoverClassName = "" } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  return (
    <div>
      <MoreHorizontal
        strokeWidth={2}
        size={18}
        className="table__action-icon"
        color="#707070"
        id={`actions-popover${id}`}
        onClick={togglePopover}
      />
      <Popover
        isOpen={popoverOpen}
        toggle={togglePopover}
        className={cx(popoverClassName, "no-shadow")}
        placement={placement}
        target={`actions-popover${id}`}
        trigger={trigger}
        onBlur={() => setPopoverOpen(false)}
      >
        <PopoverBody>
          <div className="d-flex align-items-center justify-content-left ml-1 mb-1 mr-1 p-2">
            {items?.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default HorizontalPopover;
