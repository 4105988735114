import React from "react";
import { IF, Skeleton } from ".";
import { separateAnchorTag } from "../../helpers/Util";

const CheckboxComponent = props => {
  const {
    label,
    size = "",
    errorMsg,
    horizontal,
    isEditable = null,
    isViewable = true,
    loading,
    labelStyles,
    mainStyles,
    isLabelLeft = false,
    ...rest
  } = props;
  if (!isViewable) return null;
  return (
    <>
      <label
        className={`c-custom-checkbox ${size} ${isLabelLeft ? "d-flex form-group row align-items-center" : ""}`.trim()}
        style={{ ...mainStyles }}
      >
        {loading ? (
          <>
            <Skeleton height={27} width={30} />
            <Skeleton height={27} width={100} className="pl-2" />
          </>
        ) : (
          <>
            <IF condition={isLabelLeft}>
              <span className="col-sm-3 col-form-label text-right" style={labelStyles}>
                {label}
              </span>
            </IF>
            <input id={props.value} type="checkbox" disabled={isEditable === false || props.disabled} {...rest} />
            <svg
              className="cb-element"
              width="32"
              height="32"
              viewBox="-4 -4 39 39"
              aria-hidden="true"
              focusable="false"
            >
              <rect
                className="cb-bg"
                width="35"
                height="35"
                x="-2"
                y="-2"
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                rx="4"
              />
              <polyline className="cb-cm" points="6,14 13,21 26,8" strokeWidth="4" fill="none" />
            </svg>
            <IF condition={!isLabelLeft}>
              <span style={labelStyles}>{label}</span>
            </IF>
          </>
        )}
      </label>

      {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
    </>
  );
};

export default CheckboxComponent;
