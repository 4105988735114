import { connect } from "react-redux";

const Permissions = ({ permissionObject, catagories, actions = "", children }) => {
  const getCategoryActionPermission = (perm, category, action) => {
    if (typeof action === "string") {
      return perm?.[category] && (perm?.[category]?.manage || perm?.[category]?.[action]);
    } else {
      let actionAll = true;
      action.forEach(e => {
        if (!perm?.[category]?.[e]) {
          actionAll = false;
        }
      });
      return perm?.[category] && (perm?.[category]?.manage || actionAll);
    }
  };

  if (permissionObject?.all) return children;

  if (typeof catagories === "string") {
    return getCategoryActionPermission(permissionObject, catagories, actions) ? children : null;
  } else {
    let categoriesAll = false;
    catagories.forEach(e => {
      categoriesAll = getCategoryActionPermission(permissionObject, e, actions);
    });
    return categoriesAll ? children : null;
  }
};

const mapStateToProps = ({ PermissionsData }) => {
  return {
    permissionObject: PermissionsData.permissions,
  };
};

export default connect(mapStateToProps, {})(Permissions);
