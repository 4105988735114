export const BULK_REASSIGN_LEADS_LIMIT = 100;
export const IMPORT_BUYER_LEAD_SAMPLE = `${window.location.origin}/dubizzle/carforce_import_buyer_lead_sample.xlsx`;
export const IMPORT_SELLER_LEAD_SAMPLE = `${window.location.origin}/dubizzle/carforce_import_seller_lead_sample.xlsx`;

export const CRM_LEAD_EXCEL_FILES = Object.freeze({
  SELLER: "Seller Lead Sample.xlsx",
  BUYER: "Buyer Lead Sample.xlsx",
  VAS: "VAS Lead Sample.xlsx",
});

export const LEAD_TYPES = {
  SELLER: "seller",
  BUYER: "buyer",
  VAS: "vas",
};

export const LEAD_CATEGORY_TYPES = {
  [LEAD_TYPES.SELLER]: [],
  [LEAD_TYPES.BUYER]: [],
  [LEAD_TYPES.VAS]: {
    AUTO_FINANCE: {
      slug: "auto-finance",
      name: "Auto Finance",
    },
    INSURANCE: {
      slug: "insurance",
      name: "Insurance",
    },
    EXTENDED_WARRANTY: {
      slug: "ext-warranty",
      name: "Ext Warranty",
    },
    SERVICE_CONTRACT: {
      slug: "service-contract",
      name: "Service Contract",
    },
    INSPECTION: {
      slug: "inspection",
      name: "Inspection",
    },
    EVALUATION: {
      slug: "evaluation",
      name: "Evaluation",
    },
  },
};

export const CRM_LEAD_TYPES = Object.freeze([
  {
    id: 1,
    name: "Seller Lead",
    slug: "seller",
  },
  {
    id: 2,
    name: "Buyer Lead",
    slug: "buyer",
  },
  {
    id: 3,
    name: "VAS Lead",
    slug: "vas",
  },
]);

export const CRM_LEADS_TABS_DATA_SLUGS = {
  LEAD: "Leads",
  CHEVRON_VIEW: "ChevronView",
  FOLLOW_UP: "FollowUp",
};
