import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Loader } from "../../components/common";
import QRCodeStyling from "qr-code-styling";
import { useSelector } from "react-redux";
import img from "../../assets/images/dubizzle-qr-logo.png";

const getQRDesign = (slugCheck, data) => ({
  width: 900,
  height: 900,
  data: data,
  margin: 0,
  qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "H" },
  imageOptions: { hideBackgroundDots: true, imageSize: 0.5, margin: 5, crossOrigin: "anonymous" },
  dotsOptions: { type: "dots", color: "#000000" },
  backgroundOptions: { color: "#ffffff" },
  image: slugCheck ? img : "",

  dotsOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: { linear: true, radial: false, color1: "#6a1a4c", color2: "#6a1a4c", rotation: "0" },
  },
  cornersSquareOptions: { type: "extra-rounded", color: "#000000" },
  cornersSquareOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: { linear: true, radial: false, color1: "#000000", color2: "#000000", rotation: "0" },
  },
  cornersDotOptions: { type: "dot", color: slugCheck ? "#d40000" : "#000000", gradient: null },
  cornersDotOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: { linear: true, radial: false, color1: "#000000", color2: "#000000", rotation: "0" },
  },
  backgroundOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: { linear: true, radial: false, color1: "#ffffff", color2: "#ffffff", rotation: "0" },
  },
});

const initializeQR = (url, tenetSlugCheck) => new QRCodeStyling(getQRDesign(tenetSlugCheck, url));

async function* getQRDataUrl(arr, tenetSlugCheck) {
  let index = 0;
  while (index < arr.length) {
    const qrCode = initializeQR(arr[index].qr_text, tenetSlugCheck);
    let blob = await qrCode.getRawData();
    let dataUrl = await new Promise(resolve => {
      let a = new FileReader();
      a.onload = function (e) {
        resolve(e.target.result);
      };
      a.readAsDataURL(blob);
    });
    arr[index].QrDataUrl = dataUrl;
    yield arr[index];
    index++;
  }
}
function BulkQRCode({ history }) {
  const { state = {} } = history.location;
  const { data = [] } = state;
  const { tenant } = useSelector(state => state.Configs);

  const tenetSlugCheck = tenant.slug.includes("dubizzle");
  const [qrData, setQrData] = useState([]);

  const [loading, setLoading] = useState(true);

  const dataWithQrUrl = async () => {
    for await (const page of getQRDataUrl(data, tenetSlugCheck)) {
      setQrData(prev => [...prev, { ...page }]);
      setLoading(false);
    }
  };
  useEffect(() => {
    data.length && dataWithQrUrl();
  }, [data]);
  let pairs = arr =>
    arr.reduce((list, _, index, source) => {
      if (index % 1 === 0) {
        list.push(source.slice(index, index + 1));
      }
      return list;
    }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            columnGap: "10px",
          }}
        >
          <Loader></Loader>
        </div>
      ) : (
        <div className={`bulkQR text-center`}>
          {pairs(qrData)
            .map((item, index) => {
              return (
                <div key={index} className="qrBoxOuter">
                  {item.map(el => {
                    if (!el.QrDataUrl) return null;
                    return (
                      <div className="qrBox">
                        <h6 className="fs-5 mb-2 fw-bold">{el.make_model}</h6>
                        <img src={el.QrDataUrl} />
                        <p className="fs-6 mb-0 fw-semibold">{el.vin}</p>
                      </div>
                    );
                  })}
                </div>
              );
            })
            .filter(Boolean)}
        </div>
      )}
    </>
  );
}

export default withRouter(BulkQRCode);
