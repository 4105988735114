import { FETCH_TENANT, UPDATE_LIMIT_PER_PAGE } from "../../constant/actionTypes";
import { PURGE } from "redux-persist";

const INITIAL_STATE = {
  tenant: {},
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE;
    case FETCH_TENANT:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_LIMIT_PER_PAGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
