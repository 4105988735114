import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import {
  AUCTION_PATH,
  BULK_QR_CODE,
  CRM_PATH,
  IMPERSONATED_LOGIN_PATH,
  INSPECTION_PATH,
  LOGIN_PATH,
  PRIVACY_POLICY_PATH,
  RESET_PASSWORD_PATH,
  TERMS_PATH,
} from "../constant/appPaths";
import ConfigDB from "../data/config";
import { getCookie } from "../helpers/CookieHelper";
import { getAppPath } from "../helpers/Util";
import useOneSignal from "../hooks/useOneSignal/useOneSignal";
import { BulkQRCode, ImpersonatedSignin, PrivacyPolicy, ResetPassword, SignIn, Terms } from "../pages";
import { selectLoggedInUser } from "../reducers/login/selectors";
import app from "../services/firebase";
import AuctionRoutes from "./AuctionRoutes";
import CrmRoutes from "./CrmRoutes";
import InspectionRoutes from "./InspectionRoutes";
import { updateUserApi } from "helpers/ApiHelper";
const basePath = "/admin";

const Routes = ({ isAdmin, platforms }) => {
  const currentUser = getCookie("authorization");
  const userInfo = useSelector(selectLoggedInUser);
  const dispatch = useDispatch();
  const configDB = useSelector(content => content.Customizer.customizer);
  const { initializeOneSignal, promptOneSignalNotification } = useOneSignal();

  const history = useHistory();

  const [user, setUser] = useState(!!currentUser);

  useEffect(() => {
    if (user) {
      const auth = getAuth(app);
      onAuthStateChanged(auth, user => {
        if (user) {
          // User is signed in
          const uid = user.uid;
          // ...
        } else {
          // User is signed out
          signInAnonymously(auth)
            .then(userCredential => {
              const user = userCredential.user;
            })
            .catch(error => {});
        }
      });
    }
  }, [user]);

  useEffect(() => {}, [platforms]);
  useEffect(() => {
    dispatch({ type: "ADD_CUSTOMIZER" });

    dispatch({
      type: "ADD_COLOR",
      payload: {
        color: localStorage.getItem("color") || ConfigDB.color.color,
        primary_color: localStorage.getItem("primary_color") || ConfigDB.color.color,
        secondary_color: localStorage.getItem("secondary_color") || ConfigDB.color.color,
        layout_version: localStorage.getItem("layout_version") || ConfigDB.color.color,
      },
    });

    dispatch({
      type: "ADD_LAYOUT",
      payload: getCookie("language") === "ur" ? "rtl" : "ltr",
    });
    document.body.className = configDB.color.mix_layout;
    document.documentElement.className = localStorage.getItem("color") || ConfigDB.color.color;
  }, []);

  useEffect(() => {
    ConfigDB.data.oneSignalId && initializeOneSignal();
  }, []);

  const handleLogin = async user => {
    const id = await promptOneSignalNotification();
    await updateUserApi({ ...user, player_id: id });
  };

  const handleLogout = e => {
    // e && e.preventDefault();
    setUser(false);
  };

  return (
    <BrowserRouter basename={`${basePath}`}>
      <ScrollContext>
        <Switch>
          <Route path={PRIVACY_POLICY_PATH} render={() => <PrivacyPolicy />} />
          <Route path={TERMS_PATH} render={() => <Terms />} />

          <Route exact path={`/`}>
            {user ? (
              <Redirect to={`${getAppPath()}`} />
            ) : (
              <Redirect
                to={{
                  pathname: LOGIN_PATH,
                  search: history?.location?.pathname ? `?redirect_to=${history?.location?.pathname}` : "",
                }}
              />
            )}
          </Route>

          <Route
            path={LOGIN_PATH}
            render={props =>
              user ? (
                <Redirect to={`${getAppPath()}`} />
              ) : (
                <SignIn {...props} handleLogin={handleLogin} setUser={setUser} />
              )
            }
          />

          <Route
            path={IMPERSONATED_LOGIN_PATH}
            render={props => (
              <ImpersonatedSignin {...props} isImpersonatedPath={true} handleLogin={handleLogin} setUser={setUser} />
            )}
          />

          <Route
            path={`${RESET_PASSWORD_PATH}`}
            render={props =>
              !user ? (
                <Redirect
                  to={{
                    pathname: LOGIN_PATH,
                    search: history?.location?.pathname ? `?redirect_to=${history.location.pathname}` : "",
                  }}
                />
              ) : (
                <ResetPassword {...props} handleLogout={handleLogout} />
              )
            }
          />
          <Route
            path={`${BULK_QR_CODE}`}
            render={props =>
              !user ? (
                <Redirect to={{ pathname: LOGIN_PATH, search: `?redirect_to=${history.location.pathname}` }} />
              ) : (
                <BulkQRCode {...props} />
              )
            }
          />
          <Route path={INSPECTION_PATH}>
            <InspectionRoutes
              userInfo={userInfo}
              isAdmin={isAdmin}
              handleLogout={handleLogout}
              user={user}
              platformAvailable={platforms?.includes("inspection")}
            />
          </Route>
          <Route path={AUCTION_PATH}>
            <AuctionRoutes
              userInfo={userInfo}
              isAdmin={isAdmin}
              handleLogout={handleLogout}
              user={user}
              platformAvailable={platforms?.includes("auction")}
            />
          </Route>

          <Route path={CRM_PATH}>
            <CrmRoutes
              userInfo={userInfo}
              isAdmin={isAdmin}
              handleLogout={handleLogout}
              user={user}
              platformAvailable={platforms?.includes("crm")}
            />
          </Route>

          <Redirect to="/" />
        </Switch>
      </ScrollContext>
    </BrowserRouter>
  );
};
const mapStateToProps = ({ PermissionsData, Configs }) => {
  return {
    ...PermissionsData,
    platforms: Configs?.tenant.platforms?.map(e => e.slug),
  };
};

export default connect(mapStateToProps, {})(Routes);
