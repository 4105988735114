import React from "react";
import Tooltip from "../tooltip/tooltip";

const HoverText = ({ data, message, tooltipContentStyles = {} }) => {
  const content = (
    <div style={tooltipContentStyles}>
      {data.map((item, index) => {
        return <p key={index}>{item}</p>;
      })}
    </div>
  );

  return (
    <Tooltip title={content} trigger="hover">
      {message}
    </Tooltip>
  );
};

export default HoverText;
