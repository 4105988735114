import cx from "clsx";
import React from "react";
import { Search, X } from "react-feather";
import { Skeleton } from "./";

export const SearchInput = props => {
  const {
    inputStyle = "",
    className = "",
    icon,
    loading,
    dynamicAttributeKey,
    filterSelected = "",
    onIconClick = () => {},
    iconStyle = {},
    iconSize = 18,
    onClear = null,
    clearIconClass = "",
    ...rest
  } = props;

  return loading ? (
    <Skeleton height={30} />
  ) : (
    <div className={`faq-form ${className}`.trim()}>
      {icon ? (
        icon
      ) : (
        <>
          <Search
            size={iconSize}
            className={`search-icon ${props.filterSelected ? "selected-filter-svg" : ""}`}
            onClick={onIconClick}
            style={iconStyle}
          />
          {rest.value && onClear && (
            <X size={iconSize} className={cx(clearIconClass, "clear-icon")} onClick={onClear} style={iconStyle} />
          )}
        </>
      )}
      <input className={`form-control ${inputStyle} ${className}`} type="text" placeholder="Search.." {...rest} />
    </div>
  );
};
