import React, { useMemo, useState, useEffect } from "react";
import { Actions, CustomAlert, DataTableComponent } from "../../components/common";
import { strings } from "../../constant/strings";
import { capitalize, getErrorString, getPermission, getTableColumns } from "../../helpers/Util";
import { columnsMap } from "../../constant/columnsMapStrings";
import { CRM_CARS_PATH } from "../../constant/appPaths";
import { IconPlaceholder } from "../svg-icon/svg-icons";
import { fetchStockCountCountedVehicles } from "../../helpers/ApiHelper";
import { useParams } from "react-router-dom";

const getVehiclesList = (data, history) => {
  let vehiclesList = [];
  vehiclesList = data.map(item => {
    return {
      ID: item?.car?.id,

      [strings.ref]: item?.car?.reference_number,
      [strings.image]: item?.inventory?.main_picture?.gallerythumb ? (
        <img src={item?.inventory?.main_picture?.gallerythumb} height="50" width="50"></img>
      ) : (
        <IconPlaceholder />
      ),
      [strings.make]: item?.car?.make?.select_value,
      [strings.model]: item?.car?.model?.select_value,
      [strings.variant]: capitalize(item?.car?.variant?.select_value),
      [strings.vin]: item?.car?.vehicle_identification_number,
      [strings.model_year]: item?.car?.model_year,
      [strings.actions]: (
        <Actions
          id={item.id}
          actionsList={[
            {
              type: "button",
              iconType: "view",
              tooltipLabel: strings.view_detail,
              onClick: () =>
                history.push({
                  pathname: `${CRM_CARS_PATH}/${item?.car?.id}/detail`,
                }),
              id: item.id,
              show: getPermission("StockCounter", "read"),
            },
          ]}
        />
      ),
    };
  });
  return vehiclesList;
};
function Vehicles({ api, history }) {
  const { crm_StockCount_vehicle } = columnsMap;
  const stockCountId = useParams()?.id;
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [error, setError] = useState(null);

  async function fetchVehicles(params = "") {
    setLoading(true);
    const response = await api(stockCountId, params);
    if (response.success) {
      setVehicles(response.inventories);
      setPagination(response.pagination);
    } else {
      setError(getErrorString(response));
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchVehicles();
  }, []);

  let updatedList = getVehiclesList(vehicles, history);
  let columnsToRender = getTableColumns(updatedList, crm_StockCount_vehicle.queryNames, crm_StockCount_vehicle);

  return (
    <div>
      <div>
        {error ? (
          <div className="d-flex justify-content-center w-100">
            <CustomAlert message={{ error }} />
          </div>
        ) : (
          <DataTableComponent
            avatarSkeletonColumnIndex={[2, 5]}
            showAvatarSkeleton
            className="rdt-leads sticky-action-cells"
            tableData={updatedList}
            columns={columnsToRender}
            noHeader
            loading={loading}
            customStyles={customStyles}
            noUrlPush
            queryParam={"?"}
            paginationObject={pagination}
            handlePagination
            fetch={fetchVehicles}
          />
        )}
      </div>
    </div>
  );
}

const customStyles = {
  headCells: {
    style: {
      flexBasis: "auto",
      maxWidth: 120,
      width: 120,
    },
  },
};

export default Vehicles;
