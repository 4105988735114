import { FETCH_TENANT, RESET_CUSTOM_STATE, SET_CUSTOM_STATE } from "../../constant/actionTypes";

import { PURGE } from "redux-persist";

const INITIAL_STATE = {
  payload: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CUSTOM_STATE:
      return {
        payload: action.payload,
      };
    case RESET_CUSTOM_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
