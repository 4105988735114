import React, { useEffect, useRef, useState } from "react";
import { fetchUsersListApi } from "../../helpers/ApiHelper";
import { capitalize, defaultFn, isEmptyArray, makeListToSelectData } from "../../helpers/Util";
import CustomMentionsInput from "./MentionField/customMentionsInput";
import CustomMention from "./MentionField/customMention";
import { Avatar } from "./Avatar";
import { debounce } from "../../helpers/Util";

function MentionInputField(props) {
  const {
    required = false,
    submitOnEnter = defaultFn,
    onSubmit = defaultFn,
    onFocus = defaultFn,
    onBlur = defaultFn,
    placeholder,
    value,
    setValue,
  } = props.inputProps;
  const { setMentions, textAreaButton, height, className = "" } = props;

  const inputRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(null);
  const [search, setSearch] = useState(null);
  const [mentionInputValue, setMentionInputValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const getQuery = (queryKey = "s[name]", search = "", page = 1) => {
    return search ? `?page=${page}&${queryKey}=${search}` : "";
  };

  useEffect(() => {
    if (value === "") {
      setMentionInputValue("");
    }
  }, [value]);

  async function load(search) {
    setLoading(true);
    const response = await fetchUsersListApi(getQuery("s[name]", search, 1));
    if (response.success) {
      let list = response.users.map(user => ({ ...user, display: user.name }));
      setUsers([...list]);
      setPage(response.pagination);
    }
    setLoading(false);
  }

  async function loadOnReachBottom(search) {
    setLoading(true);
    const response = await fetchUsersListApi(getQuery("s[name]", search, page?.next_page ?? 1));
    if (response.success) {
      let list = [];
      list = response.users.map(user => ({ ...user, display: user.name }));
      setUsers(prev => [...prev, ...list]);
      setPage(response.pagination);
    } else {
      setPage({
        next_page: null,
      });
    }
    setLoading(false);
  }

  const onReachBottom = () => {
    if (loading) return;
    if (page?.next_page === null) return;
    loadOnReachBottom(search);
  };

  const handleMentionsInputChange = (event, newValue, newPlainTextValue, mentions) => {
    setMentions(mentions);
    setValue(getMarkupText(newValue, mentions));
    setMentionInputValue(event.target.value);
    const searchQuery = newValue.split("@").at(-1);
    let mentionLessValue = getMentionLessValue(newPlainTextValue, mentions);
    const isTriggered = mentionLessValue.indexOf("@") !== -1;
    if (!isTriggered) return;
    const isRepeatingQueryCall = search === searchQuery;
    if (isRepeatingQueryCall) return;
    if (searchQuery.length >= 1) {
      debounce(load(searchQuery), 500);
      setSearch(searchQuery);
    } else {
      setUsers([]);
      setSearch(null);
      setPage(null);
    }
  };

  return (
    <div className="form-group" style={{ width: "100%", backgroundColor: "#fff" }}>
      <CustomMentionsInput
        inputRef={inputRef}
        value={mentionInputValue}
        data={users}
        onChange={handleMentionsInputChange}
        onReachBottom={() => {
          debounce(onReachBottom(), 1500);
        }}
        style={{ height: height }}
        height={height}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        className={className}
        textAreaButton={textAreaButton}
        querySearch={search}
        allowSuggestionsAboveCursor={true}
        allowSpaceInQuery={true}
        loading={loading}
      >
        <CustomMention
          trigger="@"
          displayTransform={(id, display) => {
            return `@${display}`;
          }}
          data={users}
          appendSpaceOnAdd={true}
          renderSuggestion={(entry, search, highlightedDisplay, index, focused) => {
            return (
              <div className={"mention-suggestion d-flex p-1"} style={{ overflowX: "hidden", width: "100%" }}>
                <Avatar name={entry.display} imgSrc={entry?.picture?.url} avatarOnly />
                <div className={"ml-2"}>
                  <div style={{ fontSize: "medium", color: "#2F2929" }}>{entry.name}</div>
                  <div
                    style={{
                      fontSize: "small",
                      color: "#ADADAD",
                      textOverflow: "ellipsis",
                      width: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {entry.email}
                  </div>
                </div>
              </div>
            );
          }}
        />
      </CustomMentionsInput>
    </div>
  );
}

const getMentionLessValue = (newPlainTextValue, mentions) => {
  let text = newPlainTextValue;
  mentions.forEach(mention => {
    text = text.replace(mention.display, "");
  });
  return text;
};

const getMarkupText = (value, mentions) => {
  const mentionsMarkup = mentions.map(mention => `@[${mention.display.slice(1)}](${mention.id})`);
  let markupTextValue = value;
  mentionsMarkup.forEach((mentionMarkup, index) => {
    markupTextValue = markupTextValue.replace(mentionMarkup, `@{${mentions[index].id}}`);
  });
  return markupTextValue;
};

export default MentionInputField;
