import {
  SET_CRM_INVENTORY_STATISTICS,
  SET_CRM_LEAD_STATISTICS_FILTERS,
  SET_CRM_REPORT_TYPE_WIDGETS,
  SET_CRM_STATISTICS,
  SET_CRM_STATUS_FILTER,
} from "../../constant/actionTypes";
import { fetchCrmInventoryStatisticsApi, fetchCrmStatisticsApi } from "../../helpers/ApiHelper";

import { getQueryString } from "../../helpers/Util";

export const setCrmReportsTypesData = data => async dispatch => {
  dispatch({
    type: SET_CRM_REPORT_TYPE_WIDGETS,
    payload: {
      crmReport: data,
    },
  });
};

export const fetchCrmStatistics = filters => async dispatch => {
  const query = Object.keys(filters).length ? getQueryString(filters) : "";
  const res = await fetchCrmStatisticsApi(query);

  if (res && res.success) {
    dispatch({
      type: SET_CRM_STATISTICS,
      payload: {
        crmLeadsStatusesData: res.statistics,
        loadingCrmLeadStatistics: false,
        errorCrmLeadStatistics: "",
        crmLeadsStatisticsData: res.categories,
        crmTasksStatistics: res.tasks,
      },
    });
  }
};

export const fetchCrmInventoryCounts = filters => async dispatch => {
  const query = Object.keys(filters).length ? getQueryString(filters) : "";
  const res = await fetchCrmInventoryStatisticsApi(query);

  if (res && res.success) {
    dispatch({
      type: SET_CRM_INVENTORY_STATISTICS,
      payload: {
        inventoryCounts: res.counts,
        loading: false,
        error: "",
      },
    });
  }
};

export const setCrmLeadStatisticsFilters = obj => dispatch => {
  dispatch({
    type: SET_CRM_LEAD_STATISTICS_FILTERS,
    payload: {
      filters: obj,
    },
  });
};
export const setCrmStatusFilter = filters => dispatch => {
  dispatch({
    type: SET_CRM_STATUS_FILTER,
    payload: {
      filters: filters,
    },
  });
};
