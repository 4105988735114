import { FaClock } from "../../svg-icon/svg-icons";
import React from "react";
import moment from "moment";
import { strings } from "../../../constant/strings";
import { TASK_MEETING_TYPES } from "constant/appConstants";
import { getFormattedTimeStringForMeetingCard, setTimeZone } from "../../../helpers/Util";
const MeetingTime = props => {
  const { newMeeting, showMeetingCalender, inventory, errors, isMeetingRescheduled, timezone } = props;

  const getString = () =>
    `${moment(newMeeting.start_time).format("MMM DD").toString()}, ${moment(newMeeting.start_time).format(
      "h:mm A"
    )} - ${moment(newMeeting.end_time).format("h:mm A")}`;

  const onTimeClick = () => {
    switch (props.type) {
      case TASK_MEETING_TYPES.SELLER_ADDRESS:
        !props.disabled &&
          showMeetingCalender(
            newMeeting.start_time || new Date(),
            { seller_address_id: newMeeting.seller_address?.id },
            props.type,
            isMeetingRescheduled
          );
        break;
      case TASK_MEETING_TYPES.AREA:
        !props.disabled &&
          showMeetingCalender(
            newMeeting.start_time || new Date(),
            {
              ...newMeeting.meeting_area,
              inventory_id: inventory.id,
            },
            props.type,
            isMeetingRescheduled
          );
        break;
      case TASK_MEETING_TYPES.WAREHOUSE:
        !props.disabled &&
          showMeetingCalender(
            new Date(),
            { inventory_id: inventory?.id, location_id: newMeeting?.location?.id },
            props.type,
            isMeetingRescheduled
          );
        break;
      case TASK_MEETING_TYPES.BRANCHES:
        !props.disabled &&
          showMeetingCalender(
            new Date(),
            {
              location_id: newMeeting?.location?.id,
              inventory_id: inventory?.id,
              capacity: newMeeting?.location?.capacity,
              isMeetingRescheduled,
            },
            props.type
          );
        break;
      default:
        break;
    }
  };

  const isDisabled = () => !props.values.assignee;

  const displayTime = () =>
    props.type === TASK_MEETING_TYPES.AREA ||
    props.type === TASK_MEETING_TYPES.SELLER_ADDRESS ||
    props.type === TASK_MEETING_TYPES.WAREHOUSE ||
    props.type === TASK_MEETING_TYPES.BRANCHES;

  return displayTime() ? (
    <>
      <div
        className={`d-flex flex-row align-items-center b-r-6 meeting-field ${isDisabled() ? "hover-pointer" : ""}`}
        onClick={onTimeClick}
        disabled={isDisabled()}
      >
        <label className="d-flex align-items-center justify-content-center m-b-0 media-icon bordered">
          <FaClock />
        </label>
        <div className="d-flex flex-grow-1 flex-row align-items-center">
          <span className="meeting-field-inner">{newMeeting.start_time ? getString() : strings.select_time_ph}</span>
        </div>
      </div>
      {!!errors && <p className="invalid-error ml-3">{errors}</p>}
    </>
  ) : null;
};

export default MeetingTime;
