import * as yup from "yup";

import { ButtonLoading, CustomAlert, FormInput, Toast } from "../common";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { Formik } from "formik";
import { commentsValidation } from "../../helpers/validations";
import {
  addPermittedProperty,
  checkUndefinedApiParams,
  getErrorString,
  isFieldEditable,
  isFieldViewable,
} from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { updateLeadApi } from "../../helpers/ApiHelper";
import { selectActiveColumns } from "../../reducers/general/selectors";
import { useSelector } from "react-redux";

const MarkLostForm = forwardRef((props, ref) => {
  let formikRef = useRef();

  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const lostFormPermittedAttributes = activeColumnPermittedAttributes.Lead;

  const { lead, onDone, dispositionId } = props;
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    clearValues() {
      formikRef.current.setValues({ comments: "" }, false);
    },
  }));

  const getInitialValues = () => ({
    ...addPermittedProperty(lostFormPermittedAttributes?.["timelines.comment"], {
      comments: "",
    }),
  });

  const validationSchema = () => {
    return yup.object().shape({
      ...addPermittedProperty(lostFormPermittedAttributes?.["timelines.comment"], {
        comments: commentsValidation,
      }),
    });
  };

  const onSubmit = async (values, { setStatus }) => {
    if (!checkUndefinedApiParams(lead?.id, "lead")) return;
    setStatus({ message: "" });
    setLoading(true);
    const res = await updateLeadApi(lead.id, {
      lead: {
        disposition_id: dispositionId,
        timelines_attributes: [
          {
            disposition_id: dispositionId,
            comment: values.comments,
          },
        ],
      },
    });
    if (res) {
      setLoading(false);
      if (res.lead) {
        onDone(res.lead);
        Toast.success(strings.lead_added_as_lost_message);
      } else {
        setStatus({
          message: getErrorString(res),
        });
      }
    }
  };

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, status }) => {
          return (
            <form className="form theme-form page__inner-form">
              {isFieldViewable(lostFormPermittedAttributes?.["timelines.comment"]) && (
                <FormInput
                  label={strings.comments}
                  placeholder={strings.comments_ph}
                  linesCount="3"
                  onBlur={handleBlur("comments")}
                  value={values.comments}
                  errorMsg={errors.comments && touched.comments && errors.comments}
                  onChange={e => {
                    handleChange("comments")(e);
                  }}
                  isEditable={isFieldEditable(lostFormPermittedAttributes?.["timelines.comment"])}
                />
              )}
              {status && status.message && <CustomAlert message={status.message} />}
              <ButtonLoading
                className="theme__primary-btn pull-left"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
                loading={loading}
              >
                {strings.confirm}
              </ButtonLoading>
            </form>
          );
        }}
      </Formik>
    </div>
  );
});

export default MarkLostForm;
