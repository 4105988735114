import React, { Fragment, useEffect, useState, useRef } from "react";
import { getPermission } from "../../../helpers/Util";
import { Link } from "react-router-dom";
import { MENUITEMS } from "../../../constant/menu";
import configDB from "../../../data/config";
import { getCookie } from "../../../helpers/CookieHelper";
import { useSelector } from "react-redux";
import { IconCrmLogo, LineSeparator, SidebarAuctionIcon, SidebarInspectionIcon } from "../../svg-icon/svg-icons";
import useOutsideAlterter from "../../../hooks/useOutsideAlterter";

const sideMenuItems = platformAvailable => {
  return platformAvailable ? MENUITEMS() : [];
};

const Sidebar = props => {
  const { open, setOpen } = props;
  const Permissions = useSelector(content => content.PermissionsData);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [mainmenu, setMainMenu] = useState(sideMenuItems(props.platformAvailable));
  const wrapper = configDB.data.settings.sidebar.wrapper;
  const logo = configDB.data.logo;
  const layout = useSelector(content => content.Customizer.layout);
  const navRef = useRef();
  useOutsideAlterter(navRef, () => {
    setOpen(false);
  });

  const currentApp = getCookie("currentApp");
  useEffect(() => {
    unCheckElseItems(sideMenuItems(props.platformAvailable));
  }, [currentApp]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    var currentUrl = window.location.pathname;

    // eslint-disable-next-line
    mainmenu.filter(items => {
      if (items.path === currentUrl) setNavActive(items);
      if (!items.children) return false;
      // eslint-disable-next-line
      items.children.filter(subItems => {
        if (subItems.path === currentUrl) setNavActive(subItems);
        if (!subItems.children) return false;
        // eslint-disable-next-line
        subItems.children.filter(subSubItems => {
          if (subSubItems.path === currentUrl) setNavActive(subSubItems);
        });
      });
    });

    setTimeout(() => {
      const elmnt = document.getElementById("myDIV");
      const menuWidth = (elmnt && elmnt.offsetWidth) || 200;

      // setMenuWidth(menuWidth)
      if (menuWidth > window.innerWidth) {
        setHideRightArrow(false);
        setHideLeftArrowRTL(false);
      } else {
        setHideRightArrow(true);
        setHideLeftArrowRTL(true);
      }
    }, 1000);

    return () => {
      // eslint-disable-next-line
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth - 310);
  };

  const setNavActive = item => {
    // eslint-disable-next-line
    sideMenuItems(props.platformAvailable).filter(menuItem => {
      // eslint-disable-next-line
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item)) menuItem.active = true;
      if (menuItem.children) {
        // eslint-disable-next-line
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: sideMenuItems(props.platformAvailable) });
  };

  const unCheckElseItems = (items, item) => {
    items
      .filter(e => e.children)
      .forEach(el => {
        if (!el.children.includes(item)) {
          el.active = false;
          el.children.forEach(it => {
            it.active = false;
          });
        }
      });
  };

  // Click Toggle menu
  const toggletNavActive = item => {
    const items = sideMenuItems(props.platformAvailable);

    if (!item.active) {
      items.forEach(a => {
        if (items.includes(item)) {
          a.active = false;
          unCheckElseItems(items, item);
        }
        if (!a.children) return false;
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
            unCheckElseItems(items, item);
          }
          if (!b.children) return false;
          b.children.forEach(c => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }

    item.active = !item.active;
    setMainMenu({ mainmenu: items });
  };

  const scrollToRight = () => {
    const elmnt = document.getElementById("myDIV");
    const menuWidth = elmnt.offsetWidth;
    const temp = menuWidth + margin;
    if (temp < menuWidth) {
      setMargin(-temp);
      setHideRightArrow(true);
    } else {
      setMargin(margin => (margin += -width));
      setHideLeftArrow(false);
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
      setHideLeftArrow(true);
    } else {
      setMargin(margin => (margin += width));
      setHideRightArrow(false);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin(margin => (margin += -width));
      setHideLeftArrowRTL(true);
    } else {
      setMargin(margin => (margin += -width));
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    } else {
      setMargin(margin => (margin += width));
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };

  const PlatformLogo = () => {
    switch (true) {
      case window.location.href.indexOf("/inspection") !== -1:
        return <SidebarInspectionIcon color={"currentColor"} />;
      case window.location.href.indexOf("/auction") !== -1:
        return <SidebarAuctionIcon color={"currentColor"} />;
      case window.location.href.indexOf("/crm") !== -1:
        return <IconCrmLogo color={"currentColor"} />;
      default:
        return null;
    }
  };
  return (
    <Fragment>
      <div
        className={`page-sidebar ${open ? "" : "open"}`}
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
        }}
        ref={navRef}
      >
        <div className="sidebar custom-scrollbar">
          <div className="crmLogoContainer">
            {PlatformLogo()}
            <LineSeparator className="mt-2" />
          </div>
          <ul
            className="sidebar-menu"
            id="myDIV"
            style={
              wrapper === "horizontal_sidebar"
                ? layout === "rtl"
                  ? { marginRight: margin + "px" }
                  : { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <li
              className={`left-arrow ${
                layout === "rtl" ? (hideLeftArrowRTL ? "d-none" : "") : hideLeftArrow ? "d-none" : ""
              }`}
              onClick={wrapper === "horizontal_sidebar" && layout === "rtl" ? scrollToLeftRTL : scrollToLeft}
            >
              <i className="fa fa-angle-left"></i>
            </li>
            {sideMenuItems(props.platformAvailable).map((menuItem, i) => {
              if (menuItem.key === "Admin" && !Permissions.isAdmin) {
                return;
              }
              return (
                (menuItem.permit ||
                  menuItem.key === "Admin" ||
                  getPermission(menuItem.key, "index", Permissions.permissions)) && (
                  <Fragment key={i}>
                    <MenuItem menuItem={menuItem} i={i} toggletNavActive={toggletNavActive} />
                  </Fragment>
                )
              );
            })}
            <li
              className={`right-arrow ${
                layout === "rtl" ? (hideRightArrowRTL ? "d-none" : "") : hideRightArrow ? "d-none" : ""
              }`}
              onClick={wrapper === "horizontal_sidebar" && layout === "rtl" ? scrollToRightRTL : scrollToRight}
            >
              <i className="fa fa-angle-right"></i>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

const MenuItem = props => {
  const { menuItem, i, toggletNavActive } = props;
  return (
    <li className={`${menuItem.active ? "active" : ""}`} key={`menu-item-${menuItem?.key}-${i}`}>
      {menuItem.sidebartitle ? <div className="sidebar-title">{menuItem.sidebartitle}</div> : ""}
      {menuItem.type === "sub" ? (
        <a
          className="sidebar-header d-flex align-items-center "
          href="#list"
          onClick={() => toggletNavActive(menuItem)}
        >
          <menuItem.icon />
          <span>{menuItem.title}</span>
          <i className="fa fa-angle-right pull-right "></i>
        </a>
      ) : (
        ""
      )}
      {menuItem.type === "link" ? (
        <Link
          to={`${menuItem.path}`}
          className={`sidebar-header d-flex align-items-center ${
            window.location?.pathname?.includes(menuItem.path) ? "active" : ""
          }`}
          onClick={() => toggletNavActive(menuItem)}
        >
          <menuItem.icon />
          <span>{menuItem.title}</span>
          {menuItem.children ? <i className="fa fa-angle-right pull-right m-t-0"></i> : ""}
        </Link>
      ) : (
        ""
      )}
      {menuItem.children ? (
        <ul
          className={`sidebar-submenu ${menuItem.active ? "menu-open" : ""}`}
          style={menuItem.active ? { opacity: 1, transition: "opacity 500ms ease-in" } : {}}
        >
          {menuItem.children.map(
            (childrenItem, index) =>
              getPermission(childrenItem.key, props.action || "index", Permissions.permissions) && (
                <li key={index} className={childrenItem.children ? (childrenItem.active ? "active" : "") : ""}>
                  {childrenItem.type === "sub" ? (
                    <a href="#list" onClick={() => toggletNavActive(childrenItem)}>
                      {/* <i className="fa fa-circle"></i> */}
                      {childrenItem.title}
                      <i className="fa fa-angle-right pull-right"></i>
                    </a>
                  ) : (
                    ""
                  )}

                  {childrenItem.type === "link" ? (
                    <Link
                      to={`${childrenItem.path}`}
                      className={childrenItem.active ? "active" : ""}
                      onClick={() => toggletNavActive(childrenItem)}
                    >
                      {/* <i className="fa fa-circle"></i> */}
                      {childrenItem.title}
                    </Link>
                  ) : (
                    ""
                  )}
                  {childrenItem.children ? (
                    <ul className={`sidebar-submenu ${childrenItem.active ? "menu-open" : "active"}`}>
                      {childrenItem.children.map((childrenSubItem, key) => (
                        <li className={childrenSubItem.active ? "active" : ""} key={key}>
                          {childrenSubItem.type === "link" ? (
                            <Link
                              to={`${childrenSubItem.path}`}
                              className={childrenSubItem.active ? "active" : ""}
                              onClick={() => toggletNavActive(childrenSubItem)}
                            >
                              {/* <i className="fa fa-circle"></i> */}
                              {childrenSubItem.title}
                            </Link>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )
          )}
        </ul>
      ) : (
        ""
      )}
    </li>
  );
};

export default Sidebar;
