import {
  CLEAR_AUCTION_BOOKINGS,
  FETCH_AUCTION_BOOKINGS,
  SET_AUCTION_BOOKINGS_LOADING,
} from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_AUCTION_BOOKINGS:
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
        list: [...action.payload.list],
        pagination: { ...action.payload.pagination },
        permittedMeta: { ...action.payload?.permittedMeta },
      };
    case CLEAR_AUCTION_BOOKINGS:
      return {
        ...INITIAL_STATE,
      };
    case SET_AUCTION_BOOKINGS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
