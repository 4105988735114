import { strings } from "constant/strings";

export const DUPLICATE_SEARCH_CRITERIA_KEYS = {
  VIN: "vin_number",
  PHONE_NUMBER: "client_phone",
};

export const DUPLICATE_SEARCH_INPUT_PLACEHOLDERS = {
  vin_number: strings.vin_duplication_search_placeholder,
  client_phone: strings.phone_duplication_search_placeholder,
};
