import { FETCH_CRM_WATCHER_USERS } from "../../constant/actionTypes";
import { fetchUsersApi } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

export const fetchCRMWatcherUsers = params => async dispatch => {
  dispatch({
    type: FETCH_CRM_WATCHER_USERS,
    payload: { loading: true, users: null, error: "" },
  });
  const response = await fetchUsersApi("?f[is_active]=true&limit=1000");
  if (response) {
    if (response.success) {
      dispatch({
        type: FETCH_CRM_WATCHER_USERS,
        payload: {
          users: response.users,
          loading: false,
          error: "",
        },
      });
    } else {
      dispatch({
        type: FETCH_CRM_WATCHER_USERS,
        payload: {
          users: null,
          loading: false,
          error: getErrorString(response),
        },
      });
    }
  }
};
