import React from "react";
import cx from "clsx";
import styles from "./index.module.scss";

const Progress = ({ className, value, max, showLabel = true, ...rest }) => {
  const classes = cx(className, styles.progress);

  return (
    <>
      {showLabel && <label className={styles.label}>{value}%</label>}
      <progress className={classes} value={value} max={max} {...rest} />
    </>
  );
};

export default Progress;
