import { FETCH_CAR_FEATURES_LISTS } from "../../constant/actionTypes";
import { fetchItemListsWithoutPaginationApi } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

export const fetchCarFeaturesLists =
  (queryParams = "") =>
  async dispatch => {
    dispatch({
      type: FETCH_CAR_FEATURES_LISTS,
      payload: {
        lists: {
          bodyType: [],
          color: [],
        },
        loading: true,
        error: "",
      },
    });
    const res = await fetchItemListsWithoutPaginationApi(
      "?q[select_key_in][]=body_type&q[select_key_in][]=color" + queryParams
    );
    if (res && res.success) {
      const featuresLists = {
        bodyType: [],
        color: [],
      };
      res.item_lists.forEach(e => {
        switch (e.select_key) {
          case "body_type": {
            featuresLists.bodyType.push(e);
            break;
          }
          case "color": {
            featuresLists.color.push(e);
            break;
          }
          default:
            break;
        }
      });
      dispatch({
        type: FETCH_CAR_FEATURES_LISTS,
        payload: {
          lists: { ...featuresLists },
          loading: false,
          error: "",
        },
      });
    } else {
      dispatch({
        type: FETCH_CAR_FEATURES_LISTS,
        payload: {
          lists: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  };
