import { Toast } from "components/common";
import { viewPhoneNumber } from "helpers/ApiHelper";
import React, { useState } from "react";
import ShowPhoneButton from "../Button/ShowPhoneButton";
import FormInput from "../FormInput";
import FormRow from "../FormRow";

const PRIMARY_PHONE_PERMITTED_ATTRIBUTE_KEY = "client.phone";
const SECONDARY_PHONE_PERMITTED_ATTRIBUTE_KEY = "client.phone";

function FormInputPhoneNumbers({
  phoneData,
  ctaText = "Edit Phone Number",
  textStyle = {},
  formInputProps = { label: "Phone Number", horizontal: true },
  isEditLeadPage = false,
  showPhoneNumberCTA = false,
  disableShowPhoneNumberCTA = false,
}) {
  const [viewPhoneLoading, setViewPhoneLoading] = useState(false);
  const { uuid, associatedId, associatedType, component, successCallback, isUserTypeNotClient = false } = phoneData;

  const fetchPhoneNumbers = async () => {
    if (phoneData?.checkAPICallPrerequisites && !phoneData.checkAPICallPrerequisites()) return;

    if (!uuid || !associatedId) {
      Toast.error("Incomplete Arguments to call an API");
      return;
    }
    setViewPhoneLoading(true);
    let data = await viewPhoneNumber({
      uuid,
      associatedId,
      associatedType,
      component,
      isUserTypeNotClient,
    });

    if (data && data.success && data?.phone) {
      successCallback(data);
    } else {
      Toast.error("API Error while fetching phone number data");
    }

    setViewPhoneLoading(false);
  };

  // if (showPhoneNumberCTA || (isEditLeadPage && formInputProps?.isEditable && !hasSuccessfullyFetchedTheData)) {
  if (showPhoneNumberCTA || (isEditLeadPage && formInputProps?.isEditable && showPhoneNumberCTA)) {
    return (
      <>
        <FormRow
          label={formInputProps?.label}
          horizontal={formInputProps?.horizontal}
          textRight
          rightSectionClass="d-flex align-items-center"
        >
          <ShowPhoneButton
            disabled={viewPhoneLoading || disableShowPhoneNumberCTA}
            onBtnClick={fetchPhoneNumbers}
            ctaText={ctaText}
          />
        </FormRow>
      </>
    );
  } else {
    return (
      <>
        <FormInput {...formInputProps} />
      </>
    );
  }
}

export default FormInputPhoneNumbers;
