import { Flex } from "antd";
import { IF } from "components/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { strings } from "../../constant/strings";
import { fetchCallRecordings } from "../../helpers/ApiHelper";
import { setTimeZone } from "../../helpers/Util";
import { selectTimeZone } from "../../reducers/general/selectors";
import AudioPlayer from "../crm/Audio/AudioPlayer";

const TaskRecordingsCard = props => {
  const [recordingsData, setRecordingsData] = useState([]);
  const [Id, setId] = useState({});

  const onClick = (index, item) => {
    setId(ids => ({
      [index]: item,
    }));
  };

  useEffect(() => {
    getTaskRecordings();
  }, []);

  const getTaskRecordings = async () => {
    const response = await fetchCallRecordings(`?f[task.id]=notnull&f[associated_id][]=${props.taskId}`);
    if (response.success) {
      setRecordingsData(response.recordings);
    }
  };

  const timeZone = useSelector(selectTimeZone);

  return (
    <>
      {!!recordingsData.length && (
        <div className="form-group row d-flex flex-row col-form-label">
          <div className="col-sm-3">{strings.call_recording}</div>
          <div
            className="col-sm-9 border p-t-10 p-b-5 b-r-6 scroll-y hideScroll"
            key={"30/10/2023" + "3/11/2023"}
            style={{ gap: "10px", "--box-height": "250px" }}
          >
            <div className="border-bottom p-b-10 f-w-600 f-16 m-b-10">{strings.call_details}</div>
            <IF condition={!!recordingsData.length}>
              <Flex vertical gap={10}>
                {recordingsData.map(item => (
                  <div key={item.id}>
                    <div className="d-flex flex-row media-body media justify-content-center align-items-center ">
                      {!!item.source_url && (
                        <AudioPlayer
                          key={item.id}
                          item={item}
                          mp3url={item.source_url}
                          index={item.id}
                          audioPlayingId={!!Id[item.id]}
                          setAudioPlayingId={(item, i) => onClick(item, i)}
                          canSpeedUp={true}
                        />
                      )}
                      <div
                        className="p-3 d-flex align-items-center"
                        style={{ gap: "5px", color: "#898989", fontSize: 12 }}
                      >
                        {/*<div className="media-icon simple bordered">*/}
                        {/*  <IoIosCalendar />*/}
                        {/*</div>*/}
                        {moment(setTimeZone(timeZone, item.start_time)).format("MMM DD").toString()},{" "}
                        {moment(setTimeZone(timeZone, item.start_time)).format("h:mm A").toString()} -{" "}
                        {moment(setTimeZone(timeZone, item.end_time)).format("h:mm A").toString()}
                      </div>
                    </div>
                  </div>
                ))}
              </Flex>
            </IF>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskRecordingsCard;
