import { DYNAMIC_MODELS, LEAD_TYPES, PERMITTED_ATTRIBUTE_MODELS } from "constant/appConstants";
import { Formik, useFormikContext } from "formik";
import { fetchUserOptions } from "helpers/userHelper";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { BuyerPreferences, Info } from ".";
import { CRM_DUPLICATION_SEARCH, CRM_LEADS_PATH, CRM_TASKS_PATH } from "../../../constant/appPaths";
import { classificationList } from "../../../constant/lists";
import { strings } from "../../../constant/strings";
import {
  addCRMLeadApi,
  fetchCategoryTypesApi,
  fetchDynamicFieldsApi,
  fetchLeadSourcesApi,
  updateCRMLeadApi,
} from "../../../helpers/ApiHelper";
import { formikPropsHelper } from "../../../helpers/FormikHelpers";
import {
  addPermittedProperty,
  getErrorString,
  handleEmptyFormPayload,
  isEmptyArray,
  isEmptyObject,
  isFieldEditable,
  isFieldViewable,
  makeListToSelectData,
} from "../../../helpers/Util";
import { useValidation } from "../../../hooks/useValidation";
import useHasPermission from "../../../hooks/useViewPhonePermission";
import { ButtonLoading, CustomAlert, DynamicFields, DynamicFormField, IF, SelectionListTypeHead } from "../../common";
import {
  getDynamicAttachmentIds,
  makeValuesObject,
  setInitialValuesForDynamicFields,
} from "../../common/DyamicFields/DynamicFieldHelpers";
import { PaginatedSearch } from "../../common/PaginatedSearch";
import VasPreferredBuyerLead from "../../crm-leads/VasPreferredBuyerLead";

import { selectLoggedInUser } from "../../../reducers/login/selectors";
import NestedDropdown from "../../common/NestedDropdown";
import styles from "../../common/NestedDropdown.module.scss";
const FormikHelper = ({
  assignees,
  crmLeadPermittedAttributes,
  crmLeadModal,
  leadData,
  fetchCategoryTypes,
  inventory,
  getCategoryDynamicFields,
  formikValidationSchemaHelper,
  categoryDynamicFieldsData,
  categoryTypeDynamicFieldsData,
  isEditLeadPage,
  oldCategoryAdditionalDynamicFields,
  oldCategoryTypeAdditionalDynamicFields,
}) => {
  const { setFieldValue, values, setValues } = useFormikContext();
  const currentUser = useSelector(selectLoggedInUser);
  const currentUserId = currentUser.id;

  useEffect(() => {
    if (!isEmptyObject(values?.lead_category) && isEditLeadPage) {
      getCategoryDynamicFields(values?.lead_category?.dynamic_model?.id, DYNAMIC_MODELS.CATEGORY);
    }
    if (!isEmptyObject(values?.category_type) && isEditLeadPage) {
      getCategoryDynamicFields(values?.category_type?.dynamic_model?.id, DYNAMIC_MODELS.CATEGORY_TYPE);
    }
  }, [values?.lead_category, values?.category_type]);

  useEffect(() => {
    if (categoryDynamicFieldsData?.dynamic_fields?.length > 0) {
      formikValidationSchemaHelper.appendAdditionalDynamicFieldSchema(
        categoryDynamicFieldsData.dynamic_fields ?? [],
        DYNAMIC_MODELS.CATEGORY,
        oldCategoryAdditionalDynamicFields
      );
    }
  }, [categoryDynamicFieldsData, oldCategoryAdditionalDynamicFields]);

  useEffect(() => {
    if (
      categoryTypeDynamicFieldsData?.dynamic_fields?.length > 0 ||
      oldCategoryTypeAdditionalDynamicFields?.length > 0
    ) {
      formikValidationSchemaHelper.appendAdditionalDynamicFieldSchema(
        categoryTypeDynamicFieldsData?.dynamic_fields ?? [],
        DYNAMIC_MODELS.CATEGORY_TYPE,
        oldCategoryTypeAdditionalDynamicFields
      );
    }
  }, [categoryTypeDynamicFieldsData, oldCategoryTypeAdditionalDynamicFields]);

  useEffect(() => {
    if (categoryDynamicFieldsData?.dynamic_fields?.length > 0) {
      for (let i = 0; i < categoryDynamicFieldsData?.dynamic_fields.length; i++) {
        values[`category_dynamic_${categoryDynamicFieldsData?.dynamic_fields[i].field_name}`] = null;
      }
      setValues(values);
    }
  }, [categoryDynamicFieldsData]);

  useEffect(() => {
    if (categoryTypeDynamicFieldsData?.dynamic_fields?.length > 0) {
      for (let i = 0; i < categoryTypeDynamicFieldsData?.dynamic_fields.length; i++) {
        values[`category_type_dynamic_${categoryTypeDynamicFieldsData?.dynamic_fields[i].field_name}`] = null;
      }
      setValues(values);
    }
  }, [categoryTypeDynamicFieldsData]);

  useEffect(() => {
    if (isFieldViewable(crmLeadPermittedAttributes?.["assignee.full_name"])) {
      setFieldValue("lead_assignee", currentUser);
      setFieldValue("assigneesData", assignees);
    }
  }, [currentUserId, crmLeadPermittedAttributes, assignees, setFieldValue]);

  useEffect(() => {
    const { categories } = leadData;
    const fromCrmLeadModal = crmLeadModal && leadData?.categories;
    fromCrmLeadModal &&
      fetchCategoryTypes(`?q[category_id_eq]=${categories?.find(e => e.slug === LEAD_TYPES.BUYER).id}`);
    if (fromCrmLeadModal) {
      setFieldValue(
        "lead_category",
        categories?.find(e => e.slug === LEAD_TYPES.BUYER)
      );
      if (inventory?.id) {
        setFieldValue("buyer_preference", "inventory");
        setFieldValue("inventory", {
          id: inventory.id,
          title: inventory.title,
          reference_number: inventory.reference_number,
        });
      }
    }
  }, [leadData?.categories]);

  return null;
};

const CRMLeadForm = props => {
  const {
    fRef,
    history,
    lead,
    leadData,
    leadId,
    tenant,
    loading,
    inventory,
    onSuccess,
    permissions,
    assignees,
    crmLeadModal,
  } = props;
  let formikRef = useRef();
  const isEditLeadPage = !!leadId;
  const isClientPreFilled = !!lead?.client?.phone;

  if (!isEditLeadPage && !isClientPreFilled) {
    history.push({ pathname: CRM_DUPLICATION_SEARCH, state: { criterion: "client_phone" } });
  }

  let { categories, lead_sources } = leadData;
  const leadSources = lead_sources?.filter(source => {
    return source.name && source.medium && source.campaign && source.source;
  });
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [loadingCategoryTypes, setLoadingCategoryTypes] = useState(false);
  const [errorCategoryTypes, setErrorCategoryTypes] = useState("");
  const [addLeadLoading, setAddLeadLoading] = useState(false);
  const [addPhoneNumbersInAPIPayload, setAddPhoneNumbersInAPIPayload] = useState(false);

  const timezone = useSelector(content => content.Configs.tenant.country.timezone);
  let dynamicFieldsRef = useRef();
  const crmLeadPermittedAttributes = permissions.activeColumns.CrmLead;
  const isPrimaryPhoneEditable = isFieldEditable(crmLeadPermittedAttributes?.["client.phone"]);
  const isSecondaryPhoneEditable = isFieldEditable(crmLeadPermittedAttributes?.["client.secondary_phone"]);
  const { hasPermission: hasViewPhonePermission } = useHasPermission("Client", "view_phone");
  const [viewPhoneLoading, setViewPhoneLoading] = useState(false);
  const [showPhoneNumberCTA, setShowPhoneNumberCTA] = useState(false);
  const [categoryDynamicFieldsData, setCategoryDynamicFieldsData] = useState(null);
  const [categoryTypeDynamicFieldsData, setCategoryTypeDynamicFieldsData] = useState(null);
  const [oldCategoryAdditionalDynamicFields, setOldCategoryAdditionalDynamicFields] = useState([]);
  const [oldCategoryTypeAdditionalDynamicFields, setOldCategoryTypeAdditionalDynamicFields] = useState([]);
  const [enableReInitializationCheck, setEnableReInitializationCheck] = useState(isEditLeadPage ? false : true);
  const formikPropHelper = formikPropsHelper(
    PERMITTED_ATTRIBUTE_MODELS.CRM_LEAD,
    props?.dynamicFieldsData?.dynamic_fields,
    {
      formikRef,
      lead,
      addPhoneNumbersInAPIPayload: hasViewPhonePermission && addPhoneNumbersInAPIPayload,
      isPrimaryPhoneEditable,
      isSecondaryPhoneEditable,
      showPhoneNumberCTA,
      categoryDynamicFields: { model: DYNAMIC_MODELS.CATEGORY, fields: categoryDynamicFieldsData?.dynamic_fields },
      categoryTypeDynamicFields: {
        model: DYNAMIC_MODELS.CATEGORY_TYPE,
        fields: categoryTypeDynamicFieldsData?.dynamic_fields,
      },
    }
  );
  const { formikValidationSchemaHelper } = useValidation(
    crmLeadPermittedAttributes,
    props.dynamicFieldsData?.dynamic_fields,
    formikPropHelper.getStaticSchema(),
    DYNAMIC_MODELS.CRM_LEAD
  );

  const initialValues = formikPropHelper.getInitialValues();

  useEffect(() => {
    lead?.id && formikRef && setFormValues(initialValues);
    lead?.id &&
      isFieldViewable(crmLeadPermittedAttributes?.["category.name"]) &&
      fetchCategoryTypes(`?q[category_id_eq]=${lead?.category?.id}`);
  }, [lead?.id]);

  useImperativeHandle(fRef, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  const setFormValues = values => {
    Object.keys(values).forEach(e => {
      formikRef.current && formikRef.current.setFieldValue(e, values[e]);
    });
  };

  //check this func if needed
  useEffect(() => {
    setInitialValuesForDynamicFields(
      props.dynamicFieldsData,
      lead,
      DYNAMIC_MODELS.CRM_LEAD,
      formikRef.current && formikRef.current.setFieldValue,
      "",
      timezone
    );
  }, [props.dynamicFieldsData]);

  const getCategoryDynamicFields = async (modelId, fieldType) => {
    if (!!categoryDynamicFieldsData) {
      setOldCategoryAdditionalDynamicFields(categoryDynamicFieldsData?.dynamic_fields);
    }
    if (!!categoryTypeDynamicFieldsData) {
      setOldCategoryTypeAdditionalDynamicFields(categoryTypeDynamicFieldsData?.dynamic_fields);
    }
    if (!modelId) return;
    const response = await fetchDynamicFieldsApi(modelId, "?limit=1000");
    if (response.success) {
      fieldType === "category" ? setCategoryDynamicFieldsData(response) : setCategoryTypeDynamicFieldsData(response);
    } else {
      fieldType === "category" ? setCategoryDynamicFieldsData(null) : setCategoryTypeDynamicFieldsData(null);
      getErrorString(response);
    }
  };

  const fetchCategoryTypes = async queryParam => {
    setLoadingCategoryTypes(true);
    const response = await fetchCategoryTypesApi(queryParam);
    if (response) {
      setLoadingCategoryTypes(false);
      if (response.success) {
        setCategoryTypes([...response.category_types]);
      } else {
        setErrorCategoryTypes(getErrorString(response));
      }
    }
  };

  const onSubmit = async (values, { setStatus }) => {
    props.toggleAddLeadLoading && props.toggleAddLeadLoading(true);
    const {
      lead_category,
      category_type,
      lead_source,
      lead_assignee,
      classification,
      city,
      name,
      phone,
      email,
      secondary_phone,
      address,
      min_price,
      max_price,
      mileage,
      engine_capacity,
      assembly,
      exterior_color,
      body_type,
      registration_city,
      car_preferences_attributes,
      inventory,
    } = values;
    let dynamicAttributes = makeValuesObject(values, DYNAMIC_MODELS.CRM_LEAD, timezone, crmLeadPermittedAttributes);

    const dynamicAttributesOfCategory = makeValuesObject(
      values,
      DYNAMIC_MODELS.CATEGORY,
      timezone,
      crmLeadPermittedAttributes
    );

    const dynamicAttributesOfCategoryType = makeValuesObject(
      values,
      DYNAMIC_MODELS.CATEGORY_TYPE,
      timezone,
      crmLeadPermittedAttributes
    );

    const clubbedDynamicAttributes = {
      ...dynamicAttributes,
      ...dynamicAttributesOfCategory,
      ...dynamicAttributesOfCategoryType,
    };

    let dynamicAttachmentIds = getDynamicAttachmentIds(clubbedDynamicAttributes);

    let obj = {
      crm_lead: {
        ...addPermittedProperty(
          crmLeadPermittedAttributes?.["category.name"],
          {
            category_id: lead_category?.id,
          },
          true
        ),
        ...addPermittedProperty(
          crmLeadPermittedAttributes?.["category_type.name"],
          {
            category_type_id: category_type?.id,
          },
          true
        ),
        ...addPermittedProperty(
          crmLeadPermittedAttributes?.["lead_source.name"],
          {
            lead_source_id: Number(lead_source?.value) || lead_source?.id,
          },
          true
        ),
        ...addPermittedProperty(
          crmLeadPermittedAttributes?.["assignee.full_name"],
          {
            assignee_id: lead_assignee?.id,
          },
          true
        ),
        ...addPermittedProperty(
          crmLeadPermittedAttributes?.["classification"],
          {
            classification: classification?.id ? classification?.id : classificationList[2].id,
          },
          true
        ),
        ...addPermittedProperty(
          crmLeadPermittedAttributes?.["city.name"],
          {
            city_id: city?.id,
          },
          true
        ),
        ...addPermittedProperty(
          crmLeadPermittedAttributes?.["client.name"],
          {
            client_attributes: {
              name,
              ...addPermittedProperty(crmLeadPermittedAttributes?.["client.email"], { email }, true),
              ...((!showPhoneNumberCTA ||
                (showPhoneNumberCTA && isPrimaryPhoneEditable && addPhoneNumbersInAPIPayload)) &&
                addPermittedProperty(crmLeadPermittedAttributes?.["client.phone"], { phone }, true)),
              // {             isPrimaryPhoneEditable && addPhoneNumbersInAPIPayload && ...addPermittedProperty(crmLeadPermittedAttributes?.["client.phone"], { phone }, true)
              ...addPermittedProperty(crmLeadPermittedAttributes?.["client.address"], { address }, true),

              ...((!showPhoneNumberCTA ||
                (showPhoneNumberCTA && isSecondaryPhoneEditable && addPhoneNumbersInAPIPayload)) &&
                addPermittedProperty(
                  crmLeadPermittedAttributes?.["client.secondary_phone"],
                  { secondary_phone },
                  true
                )),

              // ...addPermittedProperty(
              //   crmLeadPermittedAttributes?.["client.secondary_phone"],
              //   {
              //     secondary_phone,
              //   },
              //   true
              // ),
            },
          },
          true
        ),
        ...(!isEmptyArray(dynamicAttachmentIds) && { dynamic_attachment_ids: dynamicAttachmentIds }),
        ...(!isEmptyObject(clubbedDynamicAttributes) && {
          dynamic_attributes: {
            crm_lead: dynamicAttributes,
            ...(!isEmptyObject(dynamicAttributesOfCategory) && { category: dynamicAttributesOfCategory }),
            ...(!isEmptyObject(dynamicAttributesOfCategoryType) && {
              category_type: dynamicAttributesOfCategoryType,
            }),
          },
        }),
      },
    };
    if (values?.lead_category?.slug === LEAD_TYPES.VAS) {
      obj.crm_lead["buyer_lead_id"] = values?.preferred_buyer_lead?.id || null;
      obj.crm_lead["inventory_id"] = values?.inventory?.id || null;
    }
    if (values?.lead_category?.slug === LEAD_TYPES.BUYER) {
      if (
        values?.buyer_preference === "inventory" &&
        isFieldEditable(crmLeadPermittedAttributes?.["preferred_inventory.title"])
      ) {
        obj.crm_lead.preferred_inventory_id = inventory?.id;
        let preference = lead?.preference?.id ? { id: lead?.preference?.id, _destroy: true } : null;
        if (preference) {
          obj.crm_lead.preference_attributes = { ...preference };
        }
      } else if (values?.buyer_preference === "make-modal") {
        obj.crm_lead.preference_attributes = {
          ...(lead?.preference?.id && leadId && { id: lead?.preference?.id }),
          ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.min_price"], { min_price }, true),
          ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.max_price"], { max_price }, true),
          ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.mileage"], { mileage }, true),
          ...addPermittedProperty(
            crmLeadPermittedAttributes?.["preference.engine_capacity"],
            { engine_capacity },
            true
          ),
          ...addPermittedProperty(
            crmLeadPermittedAttributes?.["preference.assembly_id"],
            {
              assembly_id: assembly?.id,
            },
            true
          ),
          ...addPermittedProperty(
            crmLeadPermittedAttributes?.["preference.colors.name"],
            {
              color_ids: exterior_color?.map(e => e.id),
            },
            true
          ),
          ...addPermittedProperty(
            crmLeadPermittedAttributes?.["preference.body_types.name"],
            {
              body_type_ids: body_type?.map(e => e.id),
            },
            true
          ),
          ...addPermittedProperty(
            crmLeadPermittedAttributes?.["preference.registration.cities.name"],
            {
              registration_city_ids: registration_city?.map(e => e.id),
            },
            true
          ),
          ...addPermittedProperty(
            crmLeadPermittedAttributes?.["preference.car_preferences.name"],
            {
              car_preferences_attributes: car_preferences_attributes?.map(e => ({
                ...(e?.id ? { id: e.id } : {}),
                model_year: e?.model_year,
                make_id: e?.make?.id,
                model_id: e?.model?.id,
                ...(e?.variant ? { variant_id: e?.variant?.id } : {}),
                ...(e?._destroy ? { _destroy: e._destroy } : {}),
              })),
            },
            true
          ),
        };
        obj.crm_lead.preferred_inventory_id = null;
      }
    }
    setAddLeadLoading(true);
    if (!handleEmptyFormPayload(obj.crm_lead, "crm_lead")) {
      setAddLeadLoading(true);
      return;
    }
    if (leadId) {
      const response = await updateCRMLeadApi(leadId, obj);
      if (response) {
        setAddLeadLoading(true);
        if (response.crm_lead) {
          history.push({
            pathname: `${CRM_LEADS_PATH}/${leadId}/detail`,
          });
        } else {
          setAddLeadLoading(false);
          setStatus({
            message: getErrorString(response),
          });
        }
      }
    } else {
      try {
        const response = await addCRMLeadApi(obj);
        if (response) {
          props.toggleAddLeadLoading && props.toggleAddLeadLoading(true);
          setAddLeadLoading(true);
          if (response.crm_lead) {
            let fromTaskFrom = history?.location?.state?.type === "new-lead-from-task";
            if (fromTaskFrom) {
              history.push({
                pathname: `${CRM_TASKS_PATH}`,
                state: {
                  ...history.location.state,
                  crm_lead: response?.crm_lead ?? null,
                },
              });
              setAddLeadLoading(false);
              return;
            }
            if (onSuccess) {
              onSuccess(response.crm_lead);
            } else {
              history.push({
                pathname: `${CRM_LEADS_PATH}/${response?.crm_lead?.id}/detail`,
              });
            }
          } else {
            setAddLeadLoading(false);
            setStatus({
              message: getErrorString(response),
            });
          }
        }
      } catch (error) {
        setAddLeadLoading(false);
        setStatus({
          message: getErrorString(error),
        });
      }
    }
  };
  function formatData(data) {
    return Object.keys(data).map(key => ({
      label: key,
      options: data[key].map(item => ({
        label: (
          <div className={styles.dropdownItem}>
            {/*<div>*/}
            {/*  <span className={styles.labelBold}>Name:</span> {item.name}*/}
            {/*</div>*/}
            <div>
              <span className={styles.labelBold}>Medium:</span> {item.medium}
            </div>
            <div>
              <span className={styles.labelBold}>Campaign:</span> {item.campaign}
            </div>
          </div>
        ),
        value: item.id,
        name: item.name,
      })),
    }));
  }

  if (!props.dynamicFieldsData) return null;
  const leadInitialValues = formikPropHelper.getInitialValues();
  const leadValidationSchema = formikValidationSchemaHelper.getSchema();
  const data = props?.dynamicFieldsData;
  return (
    <Formik
      innerRef={formikRef}
      initialValues={leadInitialValues}
      validationSchema={leadValidationSchema}
      enableReinitialize={enableReInitializationCheck}
      onSubmit={onSubmit}
    >
      {props => {
        const { values, errors, touched, handleChange, handleBlur, status, setFieldValue } = props;
        if (values?.lead_category?.slug == LEAD_TYPES.VAS && !!values?.preferred_buyer_lead?.client?.uuid) {
          //check if buyer lead is attached to vas
          setShowPhoneNumberCTA(true);
        } else {
          isEditLeadPage ? setShowPhoneNumberCTA(true) : setShowPhoneNumberCTA(false);
        }

        const getClientUUID = () => {
          if (values?.lead_category?.slug !== LEAD_TYPES.VAS) {
            return leadData?.crm_lead?.client?.uuid;
          } else {
            if (!isEditLeadPage && values?.preferred_buyer_lead) {
              return values?.preferred_buyer_lead?.client?.uuid;
            }
            return leadData?.crm_lead?.client?.uuid;
          }
        };
        // console.log("getClientUUID", getClientUUID(values));
        return (
          <form className={`form theme-form db__form-main${crmLeadModal ? " m-t-20 p-r-50" : ""}`}>
            <FormikHelper
              assignees={assignees}
              crmLeadPermittedAttributes={crmLeadPermittedAttributes}
              crmLeadModal={crmLeadModal}
              leadData={leadData}
              fetchCategoryTypes={fetchCategoryTypes}
              inventory={inventory}
              isEditLeadPage={isEditLeadPage}
              getCategoryDynamicFields={getCategoryDynamicFields}
              formikValidationSchemaHelper={formikValidationSchemaHelper}
              categoryTypeDynamicFieldsData={categoryTypeDynamicFieldsData}
              categoryDynamicFieldsData={categoryDynamicFieldsData}
              oldCategoryAdditionalDynamicFields={oldCategoryAdditionalDynamicFields}
              oldCategoryTypeAdditionalDynamicFields={oldCategoryTypeAdditionalDynamicFields}
            />
            {isFieldViewable(crmLeadPermittedAttributes?.["category.name"]) && (
              <DynamicFormField
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                isEditable={isFieldEditable(crmLeadPermittedAttributes?.["category.name"])}
                fieldKey="lead_category"
                onChange={selected => {
                  if (isFieldEditable(crmLeadPermittedAttributes?.["category_type.name"])) {
                    if (!!categoryTypeDynamicFieldsData) {
                      setOldCategoryTypeAdditionalDynamicFields(categoryTypeDynamicFieldsData?.dynamic_fields);
                    }
                    setFieldValue("category_type", null);
                    setCategoryTypeDynamicFieldsData(null);
                    fetchCategoryTypes(`?q[category_id_eq]=${selected.id}&limit=1000`);
                    getCategoryDynamicFields(selected?.dynamic_model?.id, DYNAMIC_MODELS.CATEGORY);
                  }
                }}
                activeColumnsPermittedAttributes={crmLeadPermittedAttributes}
                placeholder={strings.lead_category_ph}
                label={strings.lead_category}
                options={categories || []}
                labelKey="name"
                horizontal
                textRight
                loading={loading}
              />
            )}
            {isFieldViewable(crmLeadPermittedAttributes?.["category_type.name"]) && (
              <DynamicFormField
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                onChange={selected => {
                  if (!!selected?.dynamic_model?.id) {
                    getCategoryDynamicFields(selected?.dynamic_model?.id, DYNAMIC_MODELS.CATEGORY_TYPE);
                  }
                }}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                fieldKey="category_type"
                placeholder={strings.category_type_ph}
                isEditable={isFieldEditable(crmLeadPermittedAttributes?.["category_type.name"])}
                label={strings.category_type}
                options={categoryTypes || []}
                isLoading={loadingCategoryTypes}
                isError={errorCategoryTypes}
                activeColumnsPermittedAttributes={crmLeadPermittedAttributes}
                labelKey="name"
                textRight
                horizontal
                loading={loading}
              />
            )}
            {isFieldViewable(crmLeadPermittedAttributes?.["lead_source.name"]) && (
              <NestedDropdown
                listApi={fetchLeadSourcesApi}
                extraParams={""}
                listProperty={"lead_sources"}
                getDataInParent={() => {}}
                label={strings.lead_source}
                horizontal
                setFieldValue={setFieldValue}
                placeholder={strings.lead_source_ph}
                onBlur={() => {
                  if (isFieldEditable(crmLeadPermittedAttributes?.["lead_source.name"])) {
                    handleBlur("lead_source");
                  }
                }}
                isEditable={isFieldEditable(crmLeadPermittedAttributes?.["lead_source.name"])}
                errorMsg={errors.lead_source && touched.lead_source && errors.lead_source}
                editCase={!!leadId}
                initialValue={leadId && values?.lead_source}
                fieldKey={"lead_source"}
                queryKey={"s[name]"}
                className={styles.nestedDropdown}
                formatData={formatData}
              />
            )}
            {isFieldViewable(crmLeadPermittedAttributes?.["assignee.full_name"]) && (
              <PaginatedSearch
                label={strings.lead_assignee}
                infoView
                textRight
                showLabel={false}
                toggleClassName="form-control text-truncate"
                onChange={selectedUser => setFieldValue("lead_assignee", selectedUser)}
                isSingleSelect
                defaultSelected={values.lead_assignee}
                selectHeading={strings.assignee}
                showSearchIcon={false}
                horizontal
                loading={loading}
                fetchOptions={(input, page) => fetchUserOptions({ "s[name]": input, page })}
                onClear={() => setFieldValue("lead_assignee", null)}
                disabled={!isFieldEditable(crmLeadPermittedAttributes?.["assignee.full_name"])}
              />
            )}

            {isFieldViewable(crmLeadPermittedAttributes?.["classification"]) && (
              <SelectionListTypeHead
                placeholder={strings.classification}
                label={strings.classification}
                onBlur={handleBlur("classification")}
                errorMsg={errors.classification && touched.classification && errors.classification}
                options={classificationList}
                textRight
                horizontal
                selected={values.classification ? [values.classification] : []}
                onChange={selected => {
                  setFieldValue("classification", selected[0]);
                }}
                isEditable={isFieldEditable(crmLeadPermittedAttributes?.["classification"])}
                labelKey="name"
                clearButton={undefined}
                loading={loading}
              />
            )}
            {isFieldViewable(crmLeadPermittedAttributes?.["city.name"]) && (
              <SelectionListTypeHead
                placeholder={strings.city_ph}
                label={strings.city}
                onBlur={handleBlur("city")}
                errorMsg={errors.city && touched.city && errors.city}
                options={makeListToSelectData(tenant.cities)}
                textRight
                horizontal
                selected={values.city ? [values.city] : []}
                onChange={selected => {
                  setFieldValue("city", selected[0]);
                }}
                isEditable={isFieldEditable(crmLeadPermittedAttributes?.["city.name"])}
                labelKey="name"
                clearButton={undefined}
                loading={loading}
              />
            )}
            <DynamicFields
              activeColumnsPermittedAttributes={crmLeadPermittedAttributes}
              {...props}
              dataFromProps={{ data, loading: false, error: "" }}
              data={data}
              serverProps={{
                fetchCall: () => fetchDynamicFieldsApi("Crm_lead", "?limit=1000"),
              }}
              model={DYNAMIC_MODELS.CRM_LEAD}
              horizontal
              textRight
              ref={dynamicFieldsRef}
              validateIsTouchedError
              current={lead}
              checkboxStyles={{ marginLeft: "5px" }}
              isCheckBoxLabelLeft
            />
            <DynamicFields
              activeColumnsPermittedAttributes={crmLeadPermittedAttributes}
              {...props}
              dataFromProps={{ data: categoryDynamicFieldsData, loading: false, error: "" }}
              data={categoryDynamicFieldsData}
              serverProps={{
                fetchCall: () => fetchDynamicFieldsApi(DYNAMIC_MODELS.CATEGORY, "?limit=1000"),
              }}
              model={DYNAMIC_MODELS.CATEGORY}
              horizontal
              textRight
              ref={dynamicFieldsRef}
              validateIsTouchedError
              current={lead}
            />
            <DynamicFields
              activeColumnsPermittedAttributes={crmLeadPermittedAttributes}
              {...props}
              dataFromProps={{ data: categoryTypeDynamicFieldsData, loading: false, error: "" }}
              data={categoryTypeDynamicFieldsData}
              serverProps={{
                fetchCall: () => fetchDynamicFieldsApi(DYNAMIC_MODELS.CATEGORY_TYPE, "?limit=1000"),
              }}
              model={DYNAMIC_MODELS.CATEGORY_TYPE}
              horizontal
              textRight
              ref={dynamicFieldsRef}
              validateIsTouchedError
              current={lead}
            />
            <IF condition={values?.lead_category?.slug === LEAD_TYPES.BUYER}>
              <BuyerPreferences
                {...props}
                loading={loading}
                inventory={inventory}
                leadData={leadData}
                crmLeadPermittedAttributes={crmLeadPermittedAttributes}
              />
            </IF>
            <IF condition={values?.lead_category?.slug === LEAD_TYPES.VAS}>
              <VasPreferredBuyerLead
                {...props}
                loading={loading}
                inventory={inventory}
                leadData={leadData}
                crmLeadPermittedAttributes={crmLeadPermittedAttributes}
                setShowPhoneNumberCTA={setShowPhoneNumberCTA}
                setAddPhoneNumbersInAPIPayload={setAddPhoneNumbersInAPIPayload}
              />
            </IF>
            <IF condition={values?.lead_category}>
              <Info
                crmLeadPermittedAttributes={crmLeadPermittedAttributes}
                {...props}
                loading={loading}
                title={
                  values?.lead_category?.slug === LEAD_TYPES.BUYER || values?.lead_category?.slug === LEAD_TYPES.VAS
                    ? strings.buyer_info
                    : strings.seller_info
                }
                tenant={tenant}
                leadId={leadData?.crm_lead?.id}
                // clientUUID={leadData?.crm_lead?.client?.uuid}
                clientUUID={getClientUUID()}
                isEditLeadPage={isEditLeadPage}
                disablePhoneField={isClientPreFilled}
                showPhoneNumberProps={{
                  addPhoneNumbersInAPIPayload,
                  setAddPhoneNumbersInAPIPayload,
                  hasViewPhonePermission,
                  viewPhoneLoading,
                  setViewPhoneLoading,
                  showPhoneNumberCTA,
                  setShowPhoneNumberCTA,
                }}
              />
            </IF>
            <CustomAlert message={status && status.message} />
            {!crmLeadModal && (
              <div className="text-right p-b-10">
                <ButtonLoading
                  type="submit"
                  onClick={props.handleSubmit}
                  disabled={addLeadLoading}
                  loading={addLeadLoading}
                >
                  {strings.submit}
                </ButtonLoading>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = ({ Configs, PermissionsData }) => ({
  tenant: Configs.tenant,
  permissions: PermissionsData,
});

const ConnectedComponent = connect(mapStateToProps, {})(CRMLeadForm);

export default forwardRef((props, ref) => <ConnectedComponent {...props} fRef={ref} />);
