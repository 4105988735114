import { isEmptyObject } from "@chakra-ui/utils";
import { FETCH_CRM_REMINDER, UPDATE_CRM_REMINDER, SET_CRM_REMINDERS_LOADING } from "../../constant/actionTypes";
import { clone } from "../../helpers/Util";

export const INITIAL_STATE = {
  remindersList: [],
  statuses: [],
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CRM_REMINDER:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CRM_REMINDER: {
      const reminderId = action.payload.reminder.id;
      const updatedReminder = action.payload.reminder;
      const newState = clone(state);
      const reminder = newState.remindersList.find(reminder => reminder.id === reminderId);
      if (reminder && !isEmptyObject(updatedReminder)) {
        Object.assign(reminder, updatedReminder);
      } else if (reminder) {
        Object.assign(reminder, updatedReminder);
      }
      return { ...newState };
    }
    case SET_CRM_REMINDERS_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };
    default:
      return state;
  }
};
