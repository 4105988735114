import React from "react";
export const Select = props => {
  const { options, ...rest } = props;
  return (
    <div className="select2-drpdwn-project select-options px-1">
      <select className="form-control form-control-primary btn-square" name="select" {...rest}>
        {options.map((it, index) => {
          return (
            <option value={it.slug} key={index}>
              {it.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
