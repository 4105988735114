import { FETCH_ASSIGNEES } from "../../constant/actionTypes";
import { clone } from "../../helpers/Util";
import { ASSIGNEE_KEYS } from "constant/appConstants";

export const INITIAL_STATE = {
  assignees: {
    [ASSIGNEE_KEYS.CRM_LEAD]: [],
    [ASSIGNEE_KEYS.CRM_TASK]: [],
    [ASSIGNEE_KEYS.INSPECTION_REPORTS]: [],
  },
  initialRequestCalled: false,
  loading: false,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ASSIGNEES:
      let fetchedAssignees = getAssignees(state, action.payload);
      const newState = clone({
        loading: action.payload.loading,
        error: action.payload.error,
        assignees: fetchedAssignees,
        initialRequestCalled: true,
      });
      return newState;
    default:
      return state;
  }
};

const getAssignees = (state, payload) => {
  if (payload.error) return state.assignees;
  state.assignees[payload.type] = payload.assignees;
  return state.assignees;
};
