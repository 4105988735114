import React, { Fragment, useState } from "react";
import { Modal } from "..";
import GoDialerQRCode from "./GoDialerModalBody";
import { QRIcon } from "components/svg-icon/svg-icons";

const GoDialerModal = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Fragment>
      <Modal
        toggle={() => {
          setOpenModal(!openModal);
        }}
        title={"GO Dialer QR"}
        open={openModal}
        body={() => <GoDialerQRCode />}
      />

      <a onClick={() => setOpenModal(true)}>
        <QRIcon />
        {"Scan to PropGo"}
      </a>
    </Fragment>
  );
};

export default GoDialerModal;
