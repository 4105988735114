import { Avatar, ButtonLoading, CustomAlert, FormInput, SelectionListTypeHead } from "../common";
import React, { useEffect, useRef, useState, Fragment } from "react";
import { Formik } from "formik";
import {
  extractIds,
  appendTimeZone,
  getErrorString,
  makeListToSelectData,
  setTimeZone,
  checkUndefinedApiParams,
  makeAvailabilityHoursData,
} from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { addLocationApi, editLocationApi, fetchLocationApi, fetchUsersApi } from "../../helpers/ApiHelper";
import LocationSelect from "../common/AssignLocation/LocationSelect";
import { AvailabilityInfoCard } from "../cars";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { locationTypes } from "../../constant/lists";
import moment from "moment";
import { CRM_LOCATIONS_PATH } from "../../constant/appPaths";
import LocationFormSkeleton from "../crm/LocationFormSkeleton";

const LocationForm = props => {
  const tenant = useSelector(content => content.Configs.tenant);
  const timezone = tenant.country.timezone;
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersError, setUsersError] = useState("");
  const { secondary_locale_direction } = tenant.configuration;

  const enabledBranchCapacityInHours = tenant.configuration.enabled_branch_capacity_in_hours === true;

  const formikRef = useRef();

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    props.locationId && fetchLocationData(props.locationId);
  }, [props.locationId]);

  const makeLocationData = location => ({
    ...(location?.city?.id ? { city: location?.city } : {}),
    ...(location?.zone?.id ? { zone: location?.zone } : {}),
  });

  const setLocationData = locationData => {
    if (formikRef && formikRef.current) {
      formikRef.current.setValues({
        name: locationData.address,
        locationType: locationTypes.find(e => e.slug === locationData.location_type),
        location: makeLocationData(locationData),
        addressLink: locationData.address_link,
        addressTranslation: locationData.address_tld,
        fullAddress: locationData.full_address,
        city: locationData.city,
        users: locationData.users,
        availabilityHours: makeAvailabilityHoursData(locationData.time_slots),
        ...(!enabledBranchCapacityInHours && {
          capacity: locationData.capacity,
        }),
      });
    }
  };

  const fetchLocationData = async () => {
    if (!checkUndefinedApiParams(props.locationId, "location")) return;
    setLoading(true);
    const res = await fetchLocationApi(props.locationId);
    if (res.location) {
      setLoading(false);
      setLocationData(res.location);
    } else {
      setLoading(false);
      setError(getErrorString(res));
    }
  };

  const fetchUsers = async () => {
    setUsersLoading(true);
    const res = await fetchUsersApi("?limit=1000");
    if (res) {
      setUsersLoading(false);
      if (res.success) {
        formikRef.current && formikRef.current.setFieldValue("usersList", res.users);
      } else {
        setUsersError(getErrorString(res));
      }
    }
  };

  const validationSchema = () =>
    yup.object().shape({
      name: yup.string().required(strings.enter_name_validation),
      locationType: yup
        .object()
        .nullable()
        .required(strings.select_location_type)
        .test("locationType", strings.select_location_type, value => !!value?.name),
      users: yup.array().test("users", strings.select_users, value => {
        return value.length >= 1;
      }),
      location: yup
        .object()
        .nullable()
        .required(strings.select_location_msg)
        .test("location", strings.select_location_msg, value => !!value?.city?.id),
      ...(!enabledBranchCapacityInHours && {
        capacity: yup
          .number()
          .required()
          .min(1)
          .test("capacity", strings.capacity_validation_msg, function (value, ctx) {
            return parseInt(value) > 1;
          }),
      }),
      addressLink: yup.string().nullable().url(strings.enter_link_validation).required(strings.address_link_msg),
      fullAddress: yup.string().nullable().required(strings.full_address_ph),
      availabilityHours: yup
        .array()
        .of(
          yup.object().shape({
            start_time: yup
              .string()
              .required(strings.select_start_time)
              .test("start_time", strings.select_start_time, value => moment(value).isValid()),
            end_time: yup
              .string()
              .required(strings.select_end_time)
              .test("end_time", strings.select_end_time, value => moment(value).isValid()),
          })
        )
        .min(1, strings.select_availability_hours)
        .test(
          "availabilityHours",
          strings.select_availability_hours,
          value => value?.filter(item => !item?._destroy)?.length >= 1
        ),
    });

  const getLocationData = location => ({
    ...(location?.city?.id ? { city_id: location?.city?.id } : {}),
    ...(location?.zone?.id ? { zone_id: location?.zone?.id } : {}),
  });

  const mapTimeSlotAttributesToUserIds = availabilityHours => {
    if (!availabilityHours?.length) return {};
    return {
      time_slots_attributes: [
        ...availabilityHours.map(({ key, ...item }) => ({
          ...item,
          start_time: appendTimeZone(setTimeZone(timezone, item.start_time), timezone),
          end_time: appendTimeZone(setTimeZone(timezone, item.end_time), timezone),
        })),
      ],
    };
  };

  const onSubmit = async (values, { setFieldError }) => {
    const obj = {
      location: {
        address: values.name,
        location_type: values.locationType?.slug,
        full_address: values.fullAddress,
        address_link: values.addressLink,
        address_tld: values.addressTranslation,
        user_ids: extractIds(values.users),
        ...mapTimeSlotAttributesToUserIds(values.availabilityHours),
        ...getLocationData(values.location),
        ...(!enabledBranchCapacityInHours && { capacity: values.capacity }),
      },
    };

    setSaveLoading(true);
    const res = props.locationId ? await editLocationApi(props.locationId, obj) : await addLocationApi(obj);
    setSaveLoading(false);
    if (res.location) {
      props.history.push(CRM_LOCATIONS_PATH);
    } else {
      setSaveError(getErrorString(res));
    }
  };

  const getInitialValues = () => ({
    name: "",
    locationType: null,
    location: null,
    usersList: [],
    users: [],
    fullAddress: "",
    addressLink: "",
    addressTranslation: "",
    availabilityHours: [],
    ...(!enabledBranchCapacityInHours && { capacity: "" }),
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      validationSchema={validationSchema()}
    >
      {formikProps => {
        const { handleBlur, handleChange, handleSubmit, setFieldValue } = formikProps;
        const { touched, values, errors } = formikProps;
        return (
          <div>
            {loading ? (
              <LocationFormSkeleton />
            ) : (
              <form className="form theme-form">
                <div className="card">
                  <div className="card-header">
                    <h5>Add Location</h5>
                  </div>
                  <div className="card-body w-75">
                    <FormInput
                      label={strings.name}
                      placeholder={strings.enter_name_message}
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      value={values.name}
                      errorMsg={errors.name && touched.name && errors.name}
                      horizontal
                    />
                    <SelectionListTypeHead
                      placeholder={strings.location_type_ph}
                      label={strings.location_type}
                      onBlur={handleBlur("locationType")}
                      errorMsg={errors.locationType && touched.locationType}
                      options={makeListToSelectData(locationTypes, "", true)}
                      textRight
                      horizontal
                      selected={values.locationType ? [values.locationType] : []}
                      onChange={selected => {
                        setFieldValue("locationType", selected[0]);
                      }}
                      labelKey="name"
                    />
                    <LocationSelect
                      value={values.location}
                      onChange={e => {
                        setFieldValue("location", e);
                      }}
                      city={values?.city ?? null}
                      errorMsg={errors.location && touched.location && errors.location}
                      onDeselect={e => {
                        setFieldValue("location", null);
                      }}
                    />

                    {!enabledBranchCapacityInHours && (
                      <FormInput
                        label={strings.capacity}
                        placeholder={strings.enter_capacity_msg}
                        onChange={handleChange("capacity")}
                        onBlur={handleBlur("capacity")}
                        value={values.capacity}
                        horizontal
                        errorMsg={errors.capacity && touched.capacity && errors.capacity}
                        type={"number"}
                      />
                    )}

                    <SelectionListTypeHead
                      label={strings.users}
                      placeholder={strings.select_users}
                      options={values.usersList?.filter(it => !values.users?.find(el => el.id === it.id)) || []}
                      selected={values.users || []}
                      onBlur={handleBlur("users")}
                      onChange={selected => {
                        setFieldValue("users", selected);
                      }}
                      labelKey="name"
                      errorMsg={errors.users && touched.users && errors.users}
                      renderMenuItemChildren={option => <Avatar name={option.name} />}
                      multiple
                      horizontal
                      textRight
                      inputClassName="col-sm-9 select-user-role"
                    />
                    <FormInput
                      label={strings.full_address}
                      placeholder={strings.enter_full_address}
                      onChange={e => setFieldValue("fullAddress", e.target.value)}
                      onBlur={handleBlur("fullAddress")}
                      value={values.fullAddress}
                      horizontal
                      errorMsg={touched.fullAddress && errors.fullAddress}
                    />
                    <FormInput
                      label={strings.address_link}
                      placeholder={strings.address_link_ph}
                      onChange={handleChange("addressLink")}
                      onBlur={handleBlur("addressLink")}
                      value={values.addressLink}
                      errorMsg={errors.addressLink && touched.addressLink && errors.addressLink}
                      required
                      horizontal
                    />
                    <FormInput
                      label={strings.adress_translation}
                      onChange={e => setFieldValue("addressTranslation", e.target.value)}
                      onBlur={handleBlur("addressTranslation")}
                      value={values.addressTranslation}
                      horizontal
                      errorMsg={touched.addressTranslation && errors.addressTranslation}
                      dir={secondary_locale_direction}
                      errorClassName="d-block"
                    />
                  </div>
                </div>

                <AvailabilityInfoCard
                  values={values}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  titleHeading={strings.availability_hours}
                  addText={strings.add}
                  dataKey="availabilityHours"
                  title={strings.availability}
                  removeText={strings.remove}
                  errorMsg={errors.availabilityHours && touched.availabilityHours && errors.availabilityHours}
                  errors={formikProps.errors}
                  touched={touched}
                />

                <div className="text-right p-b-10">
                  <ButtonLoading
                    loading={saveLoading}
                    disabled={saveLoading}
                    onClick={handleSubmit}
                    type={"submit"}
                    error={!!saveError}
                    loadingSkeleton={props.loading}
                  >
                    {props.locationId ? strings.update : strings.create}
                  </ButtonLoading>
                </div>
                {saveError && <CustomAlert message={saveError} />}
              </form>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default LocationForm;
