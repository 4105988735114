import { ADD_QUICK_LINK, CLEAR_QUICK_LINKS, DELETE_QUICK_LINK, FETCH_QUICK_LINKS } from "../../constant/actionTypes";
import { clone } from "../../helpers/Util";

import { PURGE } from "redux-persist";
export const INITIAL_STATE = {
  links: [],
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  const linksList = state.links;
  let newLinksList;
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE;
    case ADD_QUICK_LINK:
      if (!linksList.hasOwnProperty(action.payload.type)) {
        linksList[action.payload.type] = [];
      }
      linksList[action.payload.type].push(action.payload.item);
      newLinksList = clone(linksList);
      return {
        ...state,
        links: newLinksList,
      };
    case DELETE_QUICK_LINK:
      let index = linksList[action.payload.type].findIndex(e => e.id === action.payload.item.id);
      linksList[action.payload.type].splice(index, 1);
      newLinksList = clone(linksList);
      return {
        ...state,
        links: newLinksList,
      };
    case FETCH_QUICK_LINKS:
      return {
        ...state,
        ...action.payload,
      };

    case CLEAR_QUICK_LINKS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
