import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useFetch } from "../../../hooks";
import { makeValuesObject, setInitialValuesForDynamicFields, validateFieldsData } from "./DynamicFieldHelpers";
import { renderDynamicFields } from "./DynamicFieldComponents";
import { useSelector } from "react-redux";

import { groupByOnKeys, isFieldRestricted } from "../../../helpers/Util";

const DynamicFields = forwardRef((props, ref) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleChange,
    handleBlur,
    setValues,
    horizontal,
    fieldKey,
    fieldErrorMsg,
    onChange,
    dataFromProps,
    options,
    isLoading,
    isError,
    server,
    serverProps = {},
    current,
    children,
    textRight,
    selectedSlug,
    noField,
    model,
    containerRef,
    scrollToError,
    activeColumnsPermittedAttributes = null,
    validateIsTouchedError = null,
    columnLeftSection,
    columnRightSection,
    linesCount,
    editable,
    disableAll = false,
    checkboxStyles = null,
    checkboxLabelStyles = null,
    isCheckBoxLabelLeft = false,
    ...rest
  } = props;
  let inputRef = useRef();
  const { fetchCall, params } = serverProps;
  const { data, loading, error } = server ? useFetch(fetchCall, [`?${params ? params : ""}`]) : dataFromProps;
  const timezone = useSelector(content => content.Configs.tenant.country.timezone);

  useEffect(() => {
    if (data && (current || selectedSlug)) {
      setFormInitialValues(data);
    }
  }, [data, current, selectedSlug]);

  useImperativeHandle(ref, () => ({
    validateFields,
    clearField() {
      inputRef.current && inputRef.current.clear();
    },
  }));

  const setFormInitialValues = currentValue => {
    if (!isFieldRestricted(currentValue?.dynamic_model?.source)) {
      const modelName = currentValue?.dynamic_model?.source || model;
      setInitialValuesForDynamicFields(currentValue, current, modelName, setFieldValue, "", timezone);
    }
  };

  const validateFields = cb => {
    const valuesObj = {};
    const currentValue = data;

    const modelName = currentValue?.dynamic_model?.source || model;
    let error;
    let errorObj;
    if (currentValue) {
      const { hasError, errorData } = validateFieldsData(
        values,
        currentValue,
        modelName,
        setFieldError,
        activeColumnsPermittedAttributes
      );
      error = hasError;
      errorObj = errorData;
      if (!error) {
        valuesObj[modelName] = makeValuesObject(values, modelName, timezone, activeColumnsPermittedAttributes);
      }
    }
    if (error && scrollToError) {
      containerRef.current.childNodes.forEach((item, index) => {
        if (Object.keys(errorObj)[0] === item.id) {
          containerRef.current.childNodes[index].scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      });
    }
    cb(valuesObj, error);
  };

  const dynamicFieldsAreAvailable = data && data.dynamic_fields && !isFieldRestricted(data.dynamic_fields);
  const modelName = data?.dynamic_model?.source || model;
  const groupedDynamicFields = groupByOnKeys(data?.dynamic_fields, "dynamic_field_section") || {};

  return (
    <div>
      {props.makeGroupsForDynamicFields
        ? dynamicFieldsAreAvailable &&
          Object.keys(groupedDynamicFields).map(item => {
            return (
              <div key={item.id} className="card">
                <div className="card-header">
                  <h5>{item}</h5>
                </div>
                <div className="card-body">
                  <div className="row w-75">
                    <div className="col">
                      <div ref={containerRef}>
                        {renderDynamicFields(
                          groupedDynamicFields[item],
                          props,
                          modelName,
                          null,
                          activeColumnsPermittedAttributes,
                          horizontal,
                          checkboxStyles,
                          checkboxLabelStyles,
                          isCheckBoxLabelLeft
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : dynamicFieldsAreAvailable &&
          renderDynamicFields(
            data?.dynamic_fields,
            props,
            modelName,
            validateIsTouchedError,
            activeColumnsPermittedAttributes,
            horizontal,
            checkboxStyles,
            checkboxLabelStyles
          )}
    </div>
  );
});

export default DynamicFields;
