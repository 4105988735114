import * as Sentry from "@sentry/react";
import axios from "axios/index";
import { Toast } from "components/common";
import { getCookie } from "../helpers/CookieHelper";
import { getErrorString, removeAppCookies } from "../helpers/Util";

const TIMEOUT = 90 * 1000;

function appendParamsInUrl(url, params) {
  let finalURL = url;
  if (params) {
    finalURL += "?";
    for (const param of params.entries()) {
      finalURL += `${param[0]}=${param[1]}&`;
    }
    finalURL = finalURL.substring(0, finalURL.length - 1);
  }
  return finalURL;
}

function logData(key, response) {
  console.log(key, response);
}

function logApiData(url, params, headers) {
  // console.log("URL " + url);
  // console.log("PARAMS", params);
  // console.log("HEADER", headers);
}

function getHeaders() {
  return {
    // "access-token": `${getCookie("access-token")}`,
    // client: `${getCookie("client")}`,
    // uid: `${getCookie("uid")}`,
    "refresh-token": `${getCookie("refresh-token")}`,
    authorization: `${getCookie("authorization")}`,
  };
}

function getAxiosConfig({ cancelToken = null, extraHeaders = {} } = {}) {
  let configObj = {
    credentials: "same-origin",
    timeout: TIMEOUT,
    headers: getHeaders(),
  };
  if (cancelToken) configObj.cancelToken = cancelToken;
  if (!!extraHeaders) configObj.headers = { ...configObj.headers, ...extraHeaders };

  return configObj;
}

function handleNoInternetError(reject) {
  console.log("no Internet");
}

function handleRejection(reject) {
  setTimeout(() => {
    handleNoInternetError(reject);
  }, 500);
}

function isLoginRequest(url) {
  return url.includes("login") || url.includes("sign_in");
}

export const performGetRequest = (url, params, cancelToken = null, extraHeaders = null) => {
  let finalURL = appendParamsInUrl(url, params);
  logApiData(finalURL, params, null);
  return new Promise((resolve, reject) => {
    axios
      .get(finalURL, {
        ...getAxiosConfig({ cancelToken: cancelToken, extraHeaders: extraHeaders }),
      })
      .then(response => {
        logData("RESPONSE: ", response);
        resolve(response);
      })
      .catch(error => {
        if (error.response?.status === 401) {
          removeAppCookies();
          window.location.reload();
          return;
        }
        const responseError = error.response && error.response.data ? error.response.data : { error: error.message };
        logData("Error: ", responseError);
        Toast.error(getErrorString(responseError));
        // Capture the exception in Sentry with the raw error
        Sentry.captureException(error);

        // Optionally add additional context
        Sentry.setContext("API Error Details", {
          url: finalURL,
          params,
          responseError,
        });

        reject(responseError);
      });
  });
};

export const performPatchRequest = (url, params, silentlyFail = false) => {
  let finalURL = appendParamsInUrl(url, params);
  logApiData(finalURL, params, null);

  return new Promise((resolve, reject) => {
    axios
      .patch(finalURL, { ...getAxiosConfig() })
      .then(response => {
        logData("RESPONSE: ", response);
        resolve(response);
      })
      .catch(error => {
        if (error.response?.status === 401) {
          removeAppCookies();
          window.location.reload();
          return;
        }
        const responseError = error.response && error.response.data ? error.response.data : { error: error.message };
        logData("Error: ", responseError);
        // Capture the exception in Sentry with the raw error
        Sentry.captureException(error);

        // Optionally add additional context
        Sentry.setContext("API Error Details", {
          url: finalURL,
          params,
          responseError,
        });
        reject(responseError);
      });
  });
};

export const performPostRequest = (url, data, params) => {
  let finalURL = appendParamsInUrl(url, params);
  logApiData(finalURL, data, null);

  return new Promise((resolve, reject) => {
    axios
      .post(finalURL, data, {
        ...(isLoginRequest(url) ? { timeout: TIMEOUT } : { ...getAxiosConfig() }),
      })
      .then(response => {
        logData("RESPONSE: ", response);
        resolve(response);
      })
      .catch(error => {
        const responseError = error.response && error.response.data ? error.response.data : { error: error.message };
        logData("error", responseError);
        // Capture the exception in Sentry with the raw error
        Sentry.captureException(error);

        // Optionally add additional context
        Sentry.setContext("API Error Details", {
          url: finalURL,
          params,
          responseError,
        });
        reject(responseError);
      });
  });
};

export const performPutRequest = (url, data, token) => {
  logApiData(url, data);

  return new Promise((resolve, reject) => {
    axios
      .put(url, data, {
        ...getAxiosConfig(),
      })
      .then(response => {
        logData("RESPONSE: ", response);
        resolve(response);
      })
      .catch(error => {
        const responseError = error.response && error.response.data ? error.response.data : { error: error.message };
        logData("error", responseError);
        // Capture the exception in Sentry with the raw error
        Sentry.captureException(error);

        // Optionally add additional context
        Sentry.setContext("API Error Details", {
          url: url,
          data,
          responseError,
        });
        reject(responseError);
      });
  });
};

export const performDeleteRequestWithHeaders = (url, data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "delete",
      url: url,
      data: data,
      ...getAxiosConfig(),
    };
    axios(config)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        const responseError = error.response && error.response.data ? error.response.data : { error: error.message };
        // Capture the exception in Sentry with the raw error
        Sentry.captureException(error);

        // Optionally add additional context
        Sentry.setContext("API Error Details", {
          url: url,
          data,
          responseError,
        });
        reject(responseError);
      });
  });
};
