import React from "react";
import { Skeleton } from ".";

const FormSelect = props => {
  const {
    placeholder,
    label,
    options,
    errorMsg,
    setSlugAsValue,
    horizontal,
    child,
    className,
    formSelectRef,
    value,
    labelClass,
    selectContainerClass,
    loading,
    showRequiredAsterisk,
    isEditable = null,
    textRight,
    ...rest
  } = props;

  return (
    <>
      <div className={`form-group ${horizontal ? "row" : ""}${className ? ` ${className}` : ""}`} ref={formSelectRef}>
        {label && (
          <label
            htmlFor="exampleFormControlSelect3"
            className={horizontal ? (labelClass ? labelClass : "col-sm-3 col-form-label text-right") : ""}
          >
            {loading ? (
              <Skeleton height={20} />
            ) : (
              <>
                {" "}
                {label} {showRequiredAsterisk && <sup className={"invalid-error mb-0"}>*</sup>}
              </>
            )}
          </label>
        )}
        <div className={horizontal ? (selectContainerClass ? selectContainerClass : "col-sm-9") : ""}>
          {loading ? (
            <Skeleton height={30} />
          ) : (
            <select
              style={isEditable === false ? { backgroundColor: "#e9ecef" } : {}}
              disabled={isEditable === false}
              className={`form-control digits${errorMsg ? " is-invalid" : ""}`}
              id={label}
              multiple=""
              defaultValue={value ? undefined : "selectDefaultValue"}
              value={value ? value : undefined}
              {...rest}
            >
              <option disabled value="selectDefaultValue" hidden style={{ display: "none" }}>
                {placeholder ? placeholder : `Select an option`}
              </option>
              {options.map(it => {
                return (
                  <option key={"option-" + it.slug} value={setSlugAsValue ? it.slug : undefined}>
                    {it.label}
                  </option>
                );
              })}
            </select>
          )}

          {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
          {child}
        </div>
      </div>
    </>
  );
};

export default FormSelect;
