import { FETCH_PERMISSIONS, UPDATE_MODEL_PERMISSIONS } from "../../constant/actionTypes";

import { PURGE } from "redux-persist";
import { clone } from "../../helpers/Util";

const INITIAL_STATE = {
  permissions: {},
  widgets: [],
  isAdmin: false,
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE;
    case FETCH_PERMISSIONS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_MODEL_PERMISSIONS:
      const model = action.payload.model;
      const updatedActiveColumns = clone({
        ...state.activeColumns,
        [model]: action.payload.attributesHash,
      });
      const newState = clone({
        ...state,
        activeColumns: updatedActiveColumns,
      });
      return newState;

    default:
      return state;
  }
};
