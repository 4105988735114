import React, { useEffect, useRef, useState } from "react";
import styles from "./AuctionDetailCardItem.module.scss";

const AuctionDetailCardItem = ({ title, subTitle, ...props }) => {
  const [shake, setShake] = useState(false);
  let cardRef = useRef();

  useEffect(() => {}, [title, subTitle]);

  return (
    <div ref={cardRef} className={styles.auctionDetails} {...props}>
      <h5>{title}</h5>
      <span>{subTitle}</span>
    </div>
  );
};

export default AuctionDetailCardItem;
