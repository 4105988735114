import React from "react";

const LegendItem = ({ children, size, color, strokeColor = "" }) => {
  return (
    <div className="d-flex align-items-center spbwx4">
      <svg
        className="icon-svg"
        viewBox="-4.5 -4.5 9 9"
        fill="currentColor"
        style={{ "--icon-size": size, "--icon-color": color, "--icon-stroke": strokeColor }}
      >
        <circle r="4" strokeWidth={0.5} />
      </svg>
      <span>{children}</span>
    </div>
  );
};

export default LegendItem;
