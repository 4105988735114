import { ButtonLoading, FormInput, Modal, SelectionListTypeHead, Toast } from "../../components/common";
import React, { useEffect, useState } from "react";
import {
  addAuthenticationTokenApi,
  fetchAuthenticationTokenDetailApi,
  updateAuthenticationTokenApi,
} from "../../helpers/ApiHelper";
import {
  checkUndefinedApiParams,
  getErrorString,
  getPath,
  makeListToSelectData,
  platformsCheck,
} from "../../helpers/Util";

import { strings } from "../../constant/strings";
import { useSelector } from "react-redux";

const AddTokenForm = props => {
  const { tokenId, setTokenData, onSuccess } = props;
  const [token, setToken] = useState({ name: "", description: "", platforms: [] });
  const [errorName, setErrorName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [warning, setWarning] = useState(false);
  const [authenticationToken, setauthenticationToken] = useState(null);
  const [userPlatforms, setUserPlaforms] = useState([]);
  const platforms = useSelector(content => content.Configs.tenant.platforms);
  useEffect(() => {
    tokenId && fetchTokenDetail();
  }, []);
  const saveTokenDetail = async () => {
    if (!token.name) {
      setErrorName(strings.enter_name_validation);
      return;
    }
    setErrorName("");
    const tokenObject = {
      authentication_token: {
        name: token.name,
        description: token.description,
        platform_ids: token.platforms.map(e => e.id),
      },
    };

    setSaveError("");
    setSaveLoading(true);
    const response = tokenId
      ? await updateAuthenticationTokenApi(tokenId, tokenObject)
      : await addAuthenticationTokenApi(tokenObject);
    if (response) {
      setSaveLoading(false);
      if (response.success && response.authentication_token) {
        setUserPlaforms(response.authentication_token.platforms);
        setTokenData(response.authentication_token);
        const platformValidate = platformsCheck(userPlatforms, token.platforms);
        platformValidate && onSuccess();
        if (!tokenId) {
          setWarning(1);
          setauthenticationToken(response.authentication_token);
        }
      } else {
        Toast.error(getErrorString(response));
      }
    }
  };
  const fetchTokenDetail = async () => {
    if (!checkUndefinedApiParams(tokenId, "token")) return;
    setLoading(true);
    const response = await fetchAuthenticationTokenDetailApi(tokenId);
    if (response) {
      setLoading(false);
      if (response.authentication_token) {
        setUserPlaforms(response.authentication_token.platforms);
        setToken(response.authentication_token);
        setTokenData(response.authentication_token);
      } else {
        setError(getErrorString(response));
      }
    }
  };
  return (
    <div className="card">
      <div className="card-header">
        <h5>{strings.name_and_description}</h5>
      </div>
      <div className="card-body w-70 form theme-form">
        <FormInput
          label={strings.name}
          placeholder={strings.name_ph}
          onChange={e => setToken({ ...token, name: e.target.value })}
          onFocus={e => setErrorName("")}
          onBlur={e => !e.target.value && setErrorName("Please enter name")}
          value={token?.name || ""}
          required
          horizontal
          errorMsg={errorName}
          loading={loading}
        />
        <FormInput
          label={strings.description}
          horizontal
          placeholder={strings.description_ph}
          linesCount="4"
          onChange={e => setToken({ ...token, description: e.target.value })}
          value={token?.description || ""}
          loading={loading}
        />
        <SelectionListTypeHead
          label={strings.platform}
          placeholder={strings.platform_ph}
          options={makeListToSelectData(platforms.filter(it => !token?.platforms?.find(el => el.id == it.id)) || [])}
          selected={token?.platforms ? [...token.platforms] : []}
          onChange={selected => setToken({ ...token, platforms: selected })}
          labelKey="name"
          // errorMsg={!!errors.roles && !!touched.roles && errors.roles}
          renderMenuItemChildren={option => <div>{option.name}</div>}
          horizontal
          multiple
          textRight
          loading={loading}
        />
        <div className="text-right p-b-10">
          <ButtonLoading loading={saveLoading} disabled={saveLoading} onClick={saveTokenDetail} error={!!saveError}>
            {strings.save}
          </ButtonLoading>
        </div>
      </div>
      <Modal
        title={strings.token}
        trapFocus={true}
        open={!!warning}
        body={() => (
          <p>
            <>{strings.your_token}</>
            <p className="font-weight-bold"> {authenticationToken?.access_token}</p>
          </p>
        )}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setWarning(null);
              if (!props.tokenId) {
                props.history.push(getPath("authentication_tokens", authenticationToken?.id));
                return;
              }
            },
            color: "secondary",
            key: "cancel",
            className: "modal__cancel-btn",
          },
        ]}
      />
    </div>
  );
};

export default AddTokenForm;
