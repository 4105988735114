import Button from "components/common/Button";
import RegenerateAdyenLinkModal from "components/common/modals/RegenerateAdyenLinkModal";
import { Regenerate } from "components/svg-icon/svg-icons";
import { PAYMENT_DISPOSITIONS, PAYMENT_STATUSES, ROUTE_TO_PERMISSION_MAP } from "constant/CRM";
import { getPermission, hasExpiryReached } from "helpers/Util";
import React, { useState } from "react";

const RegeneratePaymentLink = ({ paymentId, expiryDate, setAdyenData, route, disposition }) => {
  const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);

  const normalizedRoute = route => {
    const normalizedRoute = route
      .replace(/^crm\//, "") // Remove "crm/" prefix if present
      .replace(/s$/, "") // Remove trailing 's' if present
      .replace(/_/g, "") // Remove underscores
      .toUpperCase(); // Convert to uppercase for matching
    return normalizedRoute;
  };

  const permissionKey = ROUTE_TO_PERMISSION_MAP[normalizedRoute(route)];

  return (
    <div>
      {getPermission(permissionKey, "regenerate_adyen_link") &&
        hasExpiryReached(expiryDate) &&
        (disposition.slug === PAYMENT_DISPOSITIONS.PENDING || disposition.slug === PAYMENT_STATUSES.ISSUE) && (
          <Button
            icon={<Regenerate size={15} />}
            btnText=""
            color="link"
            className={"p-0"}
            onClick={() => setIsRegenerateModalOpen(true)}
          />
        )}
      {isRegenerateModalOpen && (
        <RegenerateAdyenLinkModal
          open={isRegenerateModalOpen}
          toggle={() => setIsRegenerateModalOpen(!isRegenerateModalOpen)}
          id={paymentId}
          setAdyenData={setAdyenData}
          route={route}
        />
      )}
    </div>
  );
};

export default RegeneratePaymentLink;
