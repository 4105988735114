import React from "react";
import { IF, SelectField } from "../../../components/common";
import { strings } from "../../../constant/strings";
import { QC_INPUT_FIELD_TYPES } from "constant/appConstants";
import styles from "./index.module.scss";

const QuestionItem = props => {
  const { question, formikProps, disableField } = props;
  const { statement = "", input_type = "", question_options = [], formikKey } = question;

  const { setFieldValue, values, errors, touched, handleBlur } = formikProps;

  // console.log("errors ===<> ", errors);
  // console.log("touched ===<> ", touched);

  const fieldValue = values[formikKey]?.value ?? null;
  const errorMsg = errors[formikKey]?.value && touched[formikKey] && errors[formikKey]?.value;

  return (
    <div>
      <p className={styles.progressBarLabel}>{statement}</p>
      <IF condition={input_type === QC_INPUT_FIELD_TYPES.DROP_DOWN}>
        <SelectField
          value={fieldValue}
          placeholder={strings.option_ph}
          options={question_options || []}
          onChange={e => {
            setFieldValue(formikKey, {
              ...values[formikKey],
              value: e,
            });
          }}
          onBlur={handleBlur(formikKey)}
          errorMsg={errorMsg}
          isDisabled={disableField}
          textRight
          horizontal
          columnsRightSection={12}
        />
      </IF>
    </div>
  );
};

const Questionnaire = props => {
  const { questions, formikProps, disableFields } = props;

  return (
    <div className="w-70 grid" style={{ "--template": "repeat(2, minmax(0, 1fr))", "--gap": "0 64px" }}>
      {questions.map((question, i) => (
        <QuestionItem
          disableField={!!disableFields}
          key={question?.id ?? i}
          question={question}
          formikProps={formikProps}
        />
      ))}
    </div>
  );
};

export default Questionnaire;
