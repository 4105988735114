import { CustomAlert, IF, SearchInput, Skeleton } from "../";
import React, { useEffect, useState } from "react";

// import { getErrorString } from "../../helpers/Util";

const DynamicList = props => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selected, setSelected] = useState(null);
  const [requestId, setRequestId] = useState("");
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    props.params && fetchData();
  }, [props.params]);

  const fetchData = async (value = "") => {
    setList([]);
    const queryString = `${props.params}${value ? "&s[name]=" + value : ""}`;
    // const queryObj = {
    //   ...(!!value ? { "s[name]": value } : {}),
    //   ...props.params
    // };
    // getQueryString(queryObj)
    setLoading(true);
    const response = await props.apiCall(queryString);
    if (response) {
      setLoading(false);
      if (response.success) {
        setList(response[props.apiKey]);
      } else {
        // setError(getErrorString(response));
      }
    }
  };

  const onValueSelect = (item = null) => {
    setSelected(item?.id);
    props.onSelect({ [props.keyString]: item });
  };

  const renderList = () => {
    return (
      <div>
        {list?.map(e => (
          <div
            className={`modal-list d-flex flex-row align-items-center ${selected == e.id ? "selected" : ""}`}
            onClick={() => {
              onValueSelect(e);
            }}
            key={e.id}
          >
            <span>{e.name}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="modal-inner-wrapper">
      <h6>{list?.length !== 0 ? props.title : null}</h6>
      <IF condition={list}>
        {list?.length !== 0 ? (
          <div className="filter__row--section mb-2 px-3">
            {/*<SearchInput onChange={onChange} placeholder="Search..." className="theme-form" />*/}
          </div>
        ) : null}
      </IF>
      <div className="modal-list-wrapper d-flex flex-column">
        <IF condition={loading}>
          {[1, 2, 3, 4, 5, 6, 7].map(e => (
            <Skeleton
              key={e}
              height={45}
              themeProps={{ color: "#DCDCDC", highlightColor: "#F0F0F0" }}
              className="my-2"
            />
          ))}
        </IF>
        <IF condition={list}>{renderList()}</IF>

        <CustomAlert message={error} />
      </div>
    </div>
  );
};

export default DynamicList;
