import React from "react";
import { strings } from "../../../constant/strings";
import { DropdownSelect } from "..";
import { useSelector } from "react-redux";
import { selectDesignations } from "../../../reducers/login/selectors";
import FormRow from "../FormRow";
const RenderDesignationDropdownItem = ({ label }) => {
  return <>{label} </>;
};
const renderItem = option => <RenderDesignationDropdownItem label={option.name} />;
const DesignationSelect = ({
  handleChange,
  value,
  horizontal,
  columnLeftSection,
  columnRightSection,
  textRight,
  errorMsg,
  disabled = false,
}) => {
  const designationsAvailableToUser = useSelector(selectDesignations);
  const designation = {
    selectedValue: value,
    label: strings.designation,
    placeholder: strings.select_designation_msg,
    renderItem: renderItem,
    onChange: handleChange,
    options: designationsAvailableToUser,
    labelAsValue: false,
  };

  return (
    <FormRow
      horizontal={horizontal}
      label={designation.label}
      columnLeftSection={columnLeftSection}
      columnRightSection={columnRightSection}
      textRight={textRight}
    >
      <DropdownSelect
        selected={designation.selectedValue}
        labelAsValue={designation.labelAsValue}
        options={designation.options}
        onChange={designation.onChange}
        renderToggleItem={designation.renderItem}
        renderMenuItem={designation.renderItem}
        errorMsg={errorMsg}
        toggleClassName="form-control text-truncate"
        menuStyles="w-100"
        disabled={disabled}
        horizontal={horizontal}
      />
    </FormRow>
  );
};
export default DesignationSelect;
