import React from "react";
import { useSelector } from "react-redux";
import { weekdaysList } from "../../constant/lists";
import { strings } from "../../constant/strings";
import { DynamicListSelect, FormInput, IF } from "../common";
import { DatePicker } from "../../components/common";
import moment from "moment";
import { getUTCOffset, isFieldEditable, isFieldViewable } from "../../helpers/Util";

const AvailabilityInfoInput = props => {
  const {
    values,
    setFieldValue,
    handleBlur,
    title,
    data,
    valueIndex,
    dataKey,
    removeText,
    errorMsg,
    infoInputFrom = null,
    activeColumnsPermittedAttributes = null,
    horizontal = true,
  } = props;
  const permissions = useSelector(content => content.PermissionsData);
  const inventoryPermittedAttributes = permissions?.activeColumns?.Car || {};
  const timezone = useSelector(content => content.Configs.tenant.country.timezone);

  const startTime = values[dataKey][valueIndex].start_time;
  const endTime = values[dataKey][valueIndex].end_time;

  const saveValue = (key, value) => {
    if (Array.isArray(key)) {
      const updatedValuesObject = key.reduce((acc, e, i) => {
        const valueObject = { ...acc, [e]: value[i] };
        return valueObject;
      }, values[dataKey][valueIndex]);
      setFieldValue(`${dataKey}.${valueIndex}`, updatedValuesObject);
    } else {
      const valueObject = { ...values[dataKey][valueIndex], [key]: value };
      setFieldValue(`${dataKey}.${valueIndex}`, valueObject, false);
    }
  };
  const renderList = (label, levels, placeholder, key) => (
    <DynamicListSelect
      label={label}
      answer_key="id"
      levels={levels}
      type="generic_list"
      saveObjAsValue
      onSelectItem={({ city, area }) => saveValue(["city", "area"], [city, area])}
      onClose={() => {
        saveValue(["city", "area"], [null, null]);
      }}
      placeholder={placeholder}
      setFieldValue={saveValue}
      errorMsg={errorMsg?.["area"]}
      formFieldKey={key}
      values={data}
      allSelected
    />
  );

  const dateFormatIn12Hour = "hh:mm a";

  const renderDays = () => {
    return (
      <div className="form-group row m-b-10">
        <label className="col-sm-3 col-form-label text-right m-b-20">{strings.availability}</label>
        <div className="col-sm-9">
          <div className="">
            {weekdaysList?.map(item => {
              const selected = values[dataKey][valueIndex][item.slug];
              return (
                <button
                  key={item.slug}
                  className={`availability-list m-b-10 cursor-pointer ${selected ? "selected" : ""}`}
                  type="button"
                  onClick={() => {
                    if (selected) {
                      saveValue(item.slug, false);
                    } else {
                      saveValue(item.slug, true);
                    }
                  }}
                >
                  {item.label} <strong>+</strong>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="m-t-16">
      <div className="form-group row form-group-y">
        <h6 className="f-w-700 col-sm-3 text-right">{title}</h6>
      </div>
      <IF condition={dataKey === "addresses"}>
        <FormInput
          label={strings.address}
          placeholder={strings.address_ph}
          horizontal
          value={values[dataKey][valueIndex].address}
          onChange={e => {
            saveValue("address", e.target.value);
          }}
          inputContainerClass="col-sm-6"
        />

        <FormInput
          label={strings.building_name}
          placeholder={strings.building_name_ph}
          horizontal
          value={values[dataKey][valueIndex]?.building_address}
          onChange={e => {
            saveValue("building_address", e.target.value);
          }}
          inputContainerClass="col-sm-6"
          isViewable={isFieldViewable(inventoryPermittedAttributes?.["inventory.time_slots.building_address"])}
          isEditable={isFieldEditable(inventoryPermittedAttributes?.["inventory.time_slots.building_address"])}
        />

        <FormInput
          label={strings.address_link}
          placeholder={strings.address_link_ph}
          horizontal
          inputContainerClass="col-sm-6"
          value={values[dataKey][valueIndex]?.address_link}
          onChange={e => {
            saveValue("address_link", e.target.value);
          }}
          onBlur={handleBlur(`${dataKey}.address_link`)}
          errorMsg={errorMsg?.["address_link"]}
          isViewable={isFieldViewable(inventoryPermittedAttributes?.["inventory.time_slots.address_link"])}
          isEditable={isFieldEditable(inventoryPermittedAttributes?.["inventory.time_slots.address_link"])}
        />

        {renderList("City Area", ["city", "area"], strings.area, "area")}
      </IF>
      {renderDays()}

      <DatePicker
        placeholder={strings.from}
        onChange={date => {
          saveValue("start_time", date);
        }}
        selected={startTime}
        showTimeSelectOnly
        label={strings.from}
        noTimeFilter
        labelClass="text-right"
        dateFormat={dateFormatIn12Hour}
        inputContainerClass="col-sm-6"
        timezone={timezone}
        errorMsg={errorMsg?.["start_time"]}
      />

      <DatePicker
        placeholder={strings.to}
        onChange={date => {
          saveValue("end_time", date);
        }}
        selected={endTime}
        showTimeSelectOnly
        label={strings.to}
        noTimeFilter
        labelClass="text-right"
        dateFormat={dateFormatIn12Hour}
        inputContainerClass="col-sm-6"
        timezone={timezone}
        errorMsg={errorMsg?.["end_time"]}
      />

      {/* <div className="row">
        <div className="col-sm-3" />
        <button
          onClick={() => {
            saveValue("is_preferred", !data.is_preferred);
          }}
          className={`btn btn-link preferred-address p-relative p-l-0 address-color ${data.is_preferred ? "/" : ""}`}
          type="button"
        >
          {strings.set_as_preferred_address}
        </button>
      </div> */}
      <div className="row">
        <div className="col-sm-3" />
        <button
          onClick={() => {
            values[dataKey][valueIndex].id
              ? (values[dataKey][valueIndex]._destroy = 1)
              : values[dataKey].splice(valueIndex, 1);
            setFieldValue(dataKey, values[dataKey]);
          }}
          className="address-color btn btn-link p-l-0"
          type="button"
        >
          - {removeText}
        </button>
      </div>
    </div>
  );
};

export default AvailabilityInfoInput;
