import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "clsx";
import Card from "../../Card";
import { checkAllFiltersApplied, getChevronDispositionQueryString } from "../../../../helpers/CrmLeadsChevronUtils";
import { mapQueryStringToFilterObject, defaultFn } from "../../../../helpers/Util";
import ChevronLeadCard from "../chevron-leads/chevron-lead-card/ChevronLeadCard";
import { selectLeadsChevronDispositionsData } from "../../../../reducers/crm/selectors";
import { fetchChevronDispositionData } from "../../../../actions/crm";
import styles from "./ChevronLeadColumn.module.scss";
import { ChevronLeadColumnLoader } from "../chevron-loaders";
import IF from "../../IF";
import FlatList from "../../FlatList";
import ChevronLeadColumnHeader from "./ChevronLeadColumnHeader";

const MemoizedChevronFlatList = React.memo(
  ({ dispositionColumnData, dispositionSlug, fetchMoreLeadDispositionData }) => (
    <FlatList
      ListComponent={ChevronLeadCard}
      parentList={{
        fetchInitialData: defaultFn,
        fetchMoreData: fetchMoreLeadDispositionData,
        list: dispositionColumnData?.list ?? [],
        hasMore: !!dispositionColumnData?.pagination?.next_page,
      }}
      dispositionSlug={dispositionSlug}
      styleProps={{ height: `calc(100vh - 90px)` }}
    />
  )
);

const ChevronLeadColumn = ({ dispositionSlug, dispositionTitle = "" }) => {
  const [columnOpen, setColumnOpen] = useState(true);
  const [isLatestTimestamp, setIsLatestTimestamp] = useState(true);
  const history = useHistory();
  const leadDispositionDispatch = useDispatch();
  const data = useSelector(selectLeadsChevronDispositionsData);
  const { chevronDispositions } = data;
  const dispositionColumnData = chevronDispositions?.[dispositionSlug];
  const totalDispositionsCount = dispositionColumnData?.pagination?.total_count ?? 0;

  const fetchMoreLeadDispositionData = () => {
    const { queryObj } = mapQueryStringToFilterObject(history.location.search);
    const nextPage = dispositionColumnData?.pagination?.next_page;
    const limit = dispositionColumnData?.limit ?? 20;
    if (!nextPage) return;
    const extraFiltersConfig = [
      { keySlug: "f[disposition.slug][]", value: dispositionSlug },
      { keySlug: "page", value: nextPage },
      !queryObj["sort[chevron_latest_disposition.timestamp]"]
        ? { keySlug: "sort[chevron_latest_disposition.timestamp]", value: isLatestTimestamp ? "asc" : "desc" }
        : {},
      { keySlug: "limit", value: limit },
    ];
    const paginatedSearchQuery = getChevronDispositionQueryString(history, extraFiltersConfig);
    fetchChevronDispositionData(dispositionSlug, paginatedSearchQuery, true, false)(leadDispositionDispatch);
  };

  useEffect(() => {
    const fetchInitialLeadDispositionData = () => {
      const { queryObj } = mapQueryStringToFilterObject(history.location.search);
      const extraFiltersConfig = [
        { keySlug: "f[disposition.slug][]", value: dispositionSlug },
        !queryObj["sort[chevron_latest_disposition.timestamp]"]
          ? { keySlug: "sort[chevron_latest_disposition.timestamp]", value: isLatestTimestamp ? "asc" : "desc" }
          : {},
      ];
      const searchQuery = getChevronDispositionQueryString(history, extraFiltersConfig);
      if (checkAllFiltersApplied(queryObj)) {
        fetchChevronDispositionData(dispositionSlug, searchQuery)(leadDispositionDispatch);
      }
    };

    fetchInitialLeadDispositionData();
  }, [history.location.search, isLatestTimestamp]);

  if (dispositionColumnData?.loading) {
    return <ChevronLeadColumnLoader index={dispositionSlug} />;
  }

  return (
    <Card
      className={cx({ [styles.cardWrapper]: true, [styles.cardWrapperActive]: !columnOpen })}
      cardBg={"#F9FAFB"}
      cardPadding={"18px 16px"}
      cardBorderRadius={"6px"}
    >
      <ChevronLeadColumnHeader
        totalDispositionsCount={totalDispositionsCount}
        dispositionTitle={dispositionTitle}
        isLatestTimestamp={isLatestTimestamp}
        setColumnOpen={setColumnOpen}
        setIsLatestTimestamp={setIsLatestTimestamp}
      />
      <IF condition={columnOpen}>
        <div>
          <IF condition={dispositionColumnData?.list?.length}>
            <MemoizedChevronFlatList
              dispositionColumnData={dispositionColumnData}
              dispositionSlug={dispositionSlug}
              fetchMoreLeadDispositionData={fetchMoreLeadDispositionData}
            />
          </IF>
        </div>
      </IF>
    </Card>
  );
};

export default ChevronLeadColumn;
