// import PropTypes from "prop-types";
import React from "react";
import { Skeleton } from ".";
import { capitalize } from "../../helpers/Util";
import { IoIosWarning } from "components/svg-icon/svg-icons";
import cx from "clsx";

const Status = ({
  id = null,
  name = "",
  color = "",
  bg_color = "",
  disposition = null,
  className = "",
  style = "",
  truncate = "",
  loading = false,
  icon = false,
  ...rest
}) => {
  const getBadgeStatus = () => {
    switch (name?.replace(" ", "").toLowerCase()) {
      case "completed":
        return "status-info";
      case "active":
      case "available":
      case "done":
      case "sold":
        return "status-success";
      case "inactive":
        return "status-danger";
      case "due":
      case "live":
        return "status-warning";
      case "incomplete":
        return "status-todo";
      case "upcoming":
      case "comingsoon":
        return "status-upcoming";
      case "rescheduled":
        return "rescheduled";

      // TODO: get the updated colors from the product team
      // case "Pending":
      //   return "badge__status--Pending";
      // case "Inprogress":
      //   return "badge__status--Inprogress";
      // case "Scheduled":
      //   return "badge__status--Scheduled";
      // case "Unscheduled":
      //   return "badge__status--Unscheduled";
      // case "Cancelled":
      //   return "badge__status--Cancelled";
      // case "Followup":
      //   return "badge__status--Followup";
      // case "New":
      //   return "badge__status--New";
      // case "Deposited":
      //   return "badge__status--Completed";

      default:
        return "status-default";
    }
  };

  const renderSkeleton = () => <Skeleton height={31} width={90} style={{ borderRadius: 25 }} />;

  return loading ? (
    renderSkeleton()
  ) : name ? (
    <span
      className={cx(`badge-status ${getBadgeStatus()} ${className} ${truncate}`.trim())}
      style={{ "--badge-bg": bg_color, "--badge-color": color, maxWidth: truncate ? "17ch" : "", ...style }}
      {...rest}
    >
      {icon && (
        <span className="mr-1" style={{ verticalAlign: "middle" }}>
          {icon}
        </span>
      )}

      {capitalize(name)}
      {disposition ? (
        <>
          <span className="px-2">•</span>
          {capitalize(disposition)}
        </>
      ) : null}
    </span>
  ) : null;
};

export default Status;
