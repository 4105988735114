import { isEmptyArray, isFieldRestricted } from "../helpers/Util";
import React, { Fragment } from "react";
import ConfigDB from "../data/config";
const regex = new RegExp(`(@\\{\\d+\\})`);

const Comment = ({ taggedUsers, comment }) => {
  if (isFieldRestricted(taggedUsers)) return <></>;
  if (isEmptyArray(taggedUsers)) return <p>{comment}</p>;
  if (isFieldRestricted(taggedUsers?.[0]?.name)) return <></>;

  const taggedUsersMarkup = taggedUsers.map(user => ({
    markup: `@{${user.id}}`,
    name: `@${user.name}`,
  }));
  return (
    <>
      {comment.split(regex).map((text, index) => (
        <Fragment key={index}>
          <HighlightedMarkup text={text} taggedUsersMarkup={taggedUsersMarkup} />
        </Fragment>
      ))}
    </>
  );
};

const HighlightedMarkup = ({ text, taggedUsersMarkup }) => {
  if (!regex.test(text)) return <span>{text}</span>;
  let highlightedText = taggedUsersMarkup.find(item => item.markup === text).name;
  return <span style={{ color: ConfigDB.data.color.primary_color }}>{highlightedText}</span>;
};
export default Comment;
