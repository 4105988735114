import { CustomAlert, PageTitle, Toast } from "components/common";
import { CRMLeadForm } from "components/forms";
import { strings } from "constant/strings";
import { fetchCRMLeadEditApi, fetchCRMLeadNewApi, fetchDynamicFieldsApi } from "helpers/ApiHelper";
import { getErrorString, isFieldRestricted } from "helpers/Util";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

/* /crm/crm_leads/id/edit  Main Component Start*/
/* /crm/crm_leads/new  Main Component Start*/

const CrmSaveLead = props => {
  let crmLeadFormRef = useRef();
  const { history } = props;
  const leadId = props.match?.params?.id || props.leadId;
  const [dynamicFieldsData, setDynamicFieldsData] = useState(null);

  const [leadData, setLeadData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const client = location.state?.client;
  const message = location.state?.message;

  useEffect(() => {
    if (message) Toast.error(getErrorString(message));
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [dynamicFieldsData, response] = await Promise.all([
        fetchDynamicFieldsApi("CrmLead", "?limit=1000"),
        leadId ? fetchCRMLeadEditApi(leadId) : fetchCRMLeadNewApi(),
      ]);
      if (response) {
        if (response.success && dynamicFieldsData.success) {
          setLeadData({
            ...leadData,
            leadData: { ...response },
            crmLeadData: response?.crm_lead,
          });
          !isFieldRestricted(dynamicFieldsData.dynamic_fields) && setDynamicFieldsData(dynamicFieldsData);
        } else {
          setError(getErrorString(response));
        }
        setLoading(false);
      }
    } catch (e) {
      Toast.error(getErrorString(e));
    }
  };

  return (
    <CrmContainer crmLeadModal={props.crmLeadModal} leadId={leadId} error={error}>
      <CRMLeadForm
        lead={leadData?.crmLeadData ? leadData?.crmLeadData : client ? { client } : {}}
        leadData={leadData?.leadData || {}}
        leadId={leadId}
        ref={crmLeadFormRef}
        history={history}
        loading={loading}
        inventory={props.inventory}
        dynamicFieldsData={dynamicFieldsData}
        crmLeadModal={props.crmLeadModal}
        // ref={props.refProp}
        onSuccess={props.onSuccess}
        toggleAddLeadLoading={props.toggleAddLeadLoading}
      />
    </CrmContainer>
  );
};

const CrmContainer = props => {
  if (props.crmLeadModal) return <>{props.children}</>;
  return (
    <div className="container-fluid">
      <PageTitle title={props.leadId ? strings.update_lead : strings.add_lead} />
      <div className="card">
        <div className="card-body w-70">{props.children}</div>
      </div>

      <CustomAlert message={props.error} />
    </div>
  );
};

export default CrmSaveLead;
