import { FETCH_AUCTION_NEGOTIATIONS, UPDATE_AUCTION_NEGOTIATIONS } from "../../constant/actionTypes";
import { getErrorString } from "../../helpers/Util";
import { getAuctionNegotiations } from "../../helpers/ApiHelper";

export const fetchAuctionNegotiations = params => async dispatch => {
  dispatch({
    type: FETCH_AUCTION_NEGOTIATIONS,
    payload: {
      negotiations: [],
      loading: true,
    },
  });
  const res = await getAuctionNegotiations(params);
  if (res && res.success) {
    dispatch({
      type: FETCH_AUCTION_NEGOTIATIONS,
      payload: {
        negotiations: [...res.negotiations],
        loading: false,
        error: "",
        pagination: { ...res.pagination },
        statuses: [...res.statuses],
        permittedMeta: res.permitted_meta,
      },
    });
  } else {
    dispatch({
      type: FETCH_AUCTION_NEGOTIATIONS,
      payload: {
        negotiations: [],
        loading: false,
        error: getErrorString(res.message),
      },
    });
  }
};

export const updateAuctionNegotiations =
  ({ negotiations }) =>
  async dispatch => {
    dispatch({
      type: UPDATE_AUCTION_NEGOTIATIONS,
      payload: {
        negotiations,
        loading: false,
      },
    });
  };
