import { CustomAlert, FormInput, FormSelect, Modal, RowAction } from "../common";
import React, { useState } from "react";
import { cancelAuctionApi, extendAuctionApi } from "../../helpers/ApiHelper";
import {
  checkUndefinedApiParams,
  dropDownListToShow,
  getErrorString,
  getPermission,
  isFieldEditable,
  isFieldViewable,
} from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { useSelector } from "react-redux";
import { selectActiveColumns } from "../../reducers/general/selectors";
import { offerExtensionPeriod } from "../../constant/lists";

const AuctionActions = ({ auction }) => {
  const [showCancelAuctionDialog, setShowCancelAuctionDialog] = useState(false);
  const [comment, setComment] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [showExtendAuctionDialog, setShowExtendAuctionDialog] = useState(false);
  const [time, setTime] = useState("");

  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const auctionPermittedAttributes = activeColumnPermittedAttributes?.Auction;

  const onCancel = async () => {
    if (!checkUndefinedApiParams(auction.id, "auction")) return;
    const response = await cancelAuctionApi(auction.id, {
      auction: { comment },
    });
    setError("");
    setLoading(true);
    if (response) {
      setLoading(false);
      if (response.success) {
        setShowCancelAuctionDialog(false);
        setComment("");
      } else {
        setError(getErrorString(response));
      }
    }
  };

  const onExtend = async () => {
    if (!checkUndefinedApiParams(auction.id, "auction")) return;
    const response = await extendAuctionApi(auction.id, {
      auction: { extension_seconds: time, comment: comment },
    });
    setError("");
    setLoading(true);
    if (response) {
      setLoading(false);
      if (response.success) {
        setShowExtendAuctionDialog(false);
        setTime("");
        setComment("");
      } else {
        setError(getErrorString(response));
      }
    }
  };

  const canAuctionBeCancelled = () => auction?.status?.name !== "Completed";

  const dropDownList = [
    {
      showListItem:
        getPermission("Auction", "extend") && auction?.remaining_extensions > 0 && auction?.status?.name === "Live",
      title: strings.extend_time,
      onPress: () => setShowExtendAuctionDialog(true),
    },
    {
      showListItem: getPermission("Auction", "cancel") && canAuctionBeCancelled(),
      title: strings.cancel_auction,
      onPress: () => setShowCancelAuctionDialog(true),
    },
  ];

  return canAuctionBeCancelled() || (auction?.remaining_extensions > 0 && auction?.status?.name === "Live") ? (
    <div className="pull-right inventory_action">
      <RowAction dropDownList={dropDownListToShow(dropDownList)} />

      <Modal
        toggle={() => {
          setShowCancelAuctionDialog(!showCancelAuctionDialog);
          showCancelAuctionDialog && setComment("");
          showCancelAuctionDialog && setError("");
        }}
        title={strings.cancel_auction}
        open={showCancelAuctionDialog}
        body={() => (
          <>
            {isFieldViewable(auctionPermittedAttributes?.["comment"]) && (
              <FormInput
                className="save_model_row"
                placeholder={strings.comments_ph}
                onChange={e => setComment(e.target.value)}
                value={comment}
                isEditable={isFieldEditable(auctionPermittedAttributes?.["comment"])}
              />
            )}
            {error && <CustomAlert message={error} />}
          </>
        )}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setComment("");
              setError("");
              setShowCancelAuctionDialog(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
          {
            label: strings.done,
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: onCancel,
            disabled: loading,
            loading: loading,
          },
        ]}
      />

      <Modal
        toggle={() => {
          setShowExtendAuctionDialog(!showExtendAuctionDialog);
          if (showExtendAuctionDialog) {
            setTime("");
            setComment("");
          }
        }}
        title={strings.extend_auction}
        open={showExtendAuctionDialog}
        body={() => (
          <>
            {isFieldViewable(auctionPermittedAttributes?.["extensions_count"]) && (
              <FormSelect
                placeholder={strings.select_time_ph}
                label={strings.extend_time}
                value={time}
                options={offerExtensionPeriod}
                onChange={e => {
                  setTime(e.target.value);
                }}
                setSlugAsValue
                isEditable={isFieldEditable(auctionPermittedAttributes?.["extensions_count"])}
              />
            )}
            {isFieldViewable(auctionPermittedAttributes?.["comment"]) && (
              <>
                <FormInput
                  label={strings.comments}
                  placeholder={strings.comments_ph}
                  linesCount="3"
                  value={comment}
                  onChange={e => {
                    setComment(e.target.value);
                  }}
                  isEditable={isFieldEditable(auctionPermittedAttributes?.["comment"])}
                />
              </>
            )}
            {error && <CustomAlert message={error} />}
          </>
        )}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setTime("");
              setComment("");
              setShowExtendAuctionDialog(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
          {
            label: strings.done,
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: onExtend,
            disabled: loading,
            loading: loading,
          },
        ]}
      />
    </div>
  ) : null;
};

export default AuctionActions;
