import { endpointApi, getApiMethod, putApiMethod } from "../coreApiHelper";

export const fetchExternalMappingListing = params => {
  const path = `${endpointApi}/crm/external_ad_mappers${params ? params : ""}`;
  return getApiMethod(path);
};

export const fetchSourceNameApi = id => {
  const path = `${endpointApi}/crm/external_ad_mappers/source_names`;
  return getApiMethod(path);
};

export const fetchAdPayloadApi = id => {
  const path = `${endpointApi}/crm/cars/${id}/ad_payload`;
  return getApiMethod(path);
};

export const mapKeysApi = ({ mapperId, payload }) => {
  const path = `${endpointApi}/crm/external_ad_mappers/${mapperId}`;
  return putApiMethod(path, payload);
};
