import { AsyncSelect, FormInput, SelectField } from "../common";
import React, { useEffect, useRef } from "react";

import MakeModelVariant from "./MakeModelVariant";
import { connect } from "react-redux";
import { fetchCities } from "../../actions/general/cities.action";
import {
  getYears,
  isObjRestricted,
  isFieldEditable,
  isFieldViewable,
  formatValuesForAsyncSelect,
  formatArrayWithStringsForReactSelect,
} from "../../helpers/Util";
import { smoothScrollError } from "../../helpers/functions";
import { strings } from "../../constant/strings";
import { fetchCitiesApi, fetchItemListsApi } from "../../helpers/ApiHelper";

const CarInfoInput = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
    inventoryPermittedAttributes = null,
  } = props;

  const refObject = {
    city: useRef(),
    make: useRef(),
    model: useRef(),
    variant: useRef(),
    year: useRef(),
    registrationCity: useRef(),
    mileage: useRef(),
    exteriorColor: useRef(),
    description: useRef(),
  };
  const years = getYears();

  useEffect(() => {
    smoothScrollError(errors, refObject, isSubmitting);
  });
  return (
    <div id={strings.car_information} className="card">
      <div className="card-header">
        <h5>{strings.car_information}</h5>
      </div>
      <div className="card-body grid" style={{ "--template": "repeat(2, 1fr)", "--gap": "12px 16px" }}>
        {!isObjRestricted(values?.city, ["name"]) && isFieldViewable(inventoryPermittedAttributes?.["city.name"]) && (
          <AsyncSelect
            isDisabled={false}
            extraParams={""}
            clearButton
            isClearable={true}
            label={strings.city}
            listProperty={"cities"}
            placeholder={strings.city_ph}
            selected={formatValuesForAsyncSelect(values, "city")}
            listApi={fetchCitiesApi}
            isMulti={false}
            queryKey={"q[name_cont]"}
            onChange={selected => {
              setFieldValue("city", selected);
            }}
            errorMsg={errors.city && touched.city && errors.city}
            isEditable={isFieldEditable(inventoryPermittedAttributes?.["city.name"])}
            formSelectRef={refObject["city"]}
            horizontal={false}
            id={"city"}
          />
        )}
        <MakeModelVariant
          {...props}
          refObject={refObject}
          permittedAttributes={inventoryPermittedAttributes}
          horizontal={false}
        />
        {isFieldViewable(inventoryPermittedAttributes?.["model_year"]) && (
          <SelectField
            label={strings.model_year}
            placeholder={strings.model_year_ph}
            textRight
            horizontal={false}
            options={formatArrayWithStringsForReactSelect(years)}
            onBlur={handleBlur("year")}
            errorMsg={errors.year && touched.year && errors.year}
            onChange={selected => {
              if (selected) {
                setFieldValue("year", selected.value);
              } else {
                setFieldValue("year", "");
              }
            }}
            value={values.year ? [{ value: values.year }] : []}
            formatOptionLabel={option => option.value}
            isSearchable
            isEditable={isFieldEditable(inventoryPermittedAttributes?.["model_year"])}
            clearButton
            isClearable={true}
            id={"year"}
          />
        )}
        {!isObjRestricted(values?.registrationCity, ["name"]) &&
          isFieldViewable(inventoryPermittedAttributes?.["registration_city.name"]) && (
            <AsyncSelect
              isDisabled={false}
              extraParams={""}
              clearButton
              isClearable={true}
              label={strings.registration_city}
              listProperty={"cities"}
              placeholder={strings.city_ph}
              selected={formatValuesForAsyncSelect(values, "registrationCity")}
              listApi={fetchCitiesApi}
              isMulti={false}
              queryKey={"s[name]"}
              onChange={selected => {
                setFieldValue("registrationCity", selected);
              }}
              errorMsg={errors.registrationCity && touched.registrationCity && errors.registrationCity}
              isEditable={isFieldEditable(inventoryPermittedAttributes?.["registration_city.name"])}
              horizontal={false}
              id={"registrationCity"}
            />
          )}
        {isFieldViewable(inventoryPermittedAttributes?.["mileage"]) && (
          <FormInput
            label={strings.mileage}
            placeholder={strings.mileage_ph}
            horizontal={false}
            value={values.mileage}
            onBlur={handleBlur("mileage")}
            errorMsg={errors.mileage && touched.mileage && errors.mileage}
            onChange={handleChange("mileage")}
            formRef={refObject["mileage"]}
            type={"number"}
            isEditable={isFieldEditable(inventoryPermittedAttributes?.["mileage"])}
            labelClassName=""
            id={"mileage"}
          />
        )}

        {isFieldViewable(inventoryPermittedAttributes?.["registration_no"]) && (
          <FormInput
            label={strings.registration_number}
            placeholder={strings.registration_number_ph}
            value={values.registrationNumber}
            onBlur={handleBlur("registrationNumber")}
            errorMsg={errors.registrationNumber && touched.registrationNumber && errors.registrationNumber}
            onChange={handleChange("registrationNumber")}
            formRef={refObject["registrationNumber"]}
            isEditable={isFieldEditable(inventoryPermittedAttributes?.["registration_no"])}
            id={"registrationNumber"}
          />
        )}
        {!isObjRestricted(values?.exteriorColor, ["select_value"]) &&
          isFieldViewable(inventoryPermittedAttributes?.["exterior_color.name"]) && (
            <AsyncSelect
              isDisabled={false}
              extraParams={{
                "q[select_key_in][]": "color",
              }}
              clearButton
              isClearable={true}
              label={strings.exterior_color}
              listProperty={"item_lists"}
              placeholder={strings.color_ph}
              selected={formatValuesForAsyncSelect(values, "exteriorColor")}
              listApi={fetchItemListsApi}
              isMulti={false}
              queryKey={"s[select_value]"}
              onChange={selected => {
                setFieldValue("exteriorColor", selected);
              }}
              errorMsg={errors.exteriorColor && touched.exteriorColor && errors.exteriorColor}
              isEditable={isFieldEditable(inventoryPermittedAttributes?.["exterior_color.name"])}
              horizontal={false}
              id={"exteriorColor"}
            />
          )}
        {isFieldViewable(inventoryPermittedAttributes?.["description"]) && (
          <FormInput
            label={strings.description}
            placeholder={strings.description_ph}
            linesCount="4"
            horizontal={false}
            value={values.description}
            onBlur={handleBlur("description")}
            errorMsg={errors.description && touched.description && errors.description}
            onChange={handleChange("description")}
            formRef={refObject["description"]}
            isEditable={isFieldEditable(inventoryPermittedAttributes?.["description"])}
            style={{ gridColumn: "span 2" }}
            id={"description"}
          />
        )}
      </div>
    </div>
  );
};

export default connect(
  ({ CitiesData, CarFeaturesLists }) => ({
    cities: CitiesData.list,
    citiesLoading: CitiesData.loading,
    citiesError: CitiesData.error,
    carFeatures: CarFeaturesLists.lists,
    carFeaturesLoading: CarFeaturesLists.loading,
    carFeaturesError: CarFeaturesLists.error,
  }),
  {
    fetchCities,
  }
)(CarInfoInput);
