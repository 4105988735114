import {
  AuthenticationTokens,
  CrmApplyLeave,
  CrmCalls,
  DynamicFields,
  DynamicModels,
  InspectionDashboard,
  InspectionDeposits,
  InspectionEmailTemplates,
  InspectionLeadDetail,
  InspectionLeads,
  InspectionLeadTypes,
  InspectionPayments,
  InspectionReports,
  InspectionSaveEmailTemplate,
  InspectionSaveLead,
  InspectionSaveLeadType,
  InspectionSaveSmsTemplate,
  InspectionSchedules,
  InspectionSmsTemplates,
  InspectionTeams,
  LeadSources,
  Roles,
  SaveAuthenticationToken,
  SaveLeadSource,
  SaveRole,
  SaveTenant,
  SaveUserConfiguration,
  Users,
  UserSettingsPage,
} from "../pages";
import { IF, NotAvailable } from "../components/common";
import {
  INS_AUTHENTICATION_TOKEN_PATH,
  INS_DASHBOARD_PATH,
  INS_DEPOSITS_PATH,
  INS_DYNAMIC_FIELDS_PATH,
  INS_DYNAMIC_MODELS_PATH,
  INS_EDIT_AUTHENTICATION_TOKEN_PATH,
  INS_EDIT_EMAIL_TEMPLATES_PATH,
  INS_EDIT_LEAD_SOURCE_PATH,
  INS_EDIT_LEAD_TYPE_PATH,
  INS_EDIT_ROLE_PATH,
  INS_EDIT_SMS_TEMPLATES_PATH,
  INS_EMAIL_TEMPLATES_PATH,
  INS_INSPECTIONS_PATH,
  INS_LEAD_EDIT_PATH,
  INS_LEAD_NEW_PATH,
  INS_LEAD_SOURCE_PATH,
  INS_LEAD_TYPES_PATH,
  INS_LEADS_DETAIL_PATH,
  INS_LEADS_PATH,
  INS_NEW_AUTHENTICATION_TOKEN_PATH,
  INS_NEW_LEAD_SOURCE_PATH,
  INS_NEW_LEAD_TYPE_PATH,
  INS_NEW_ROLE_PATH,
  INS_PAYMENTS_PATH,
  INS_ROLES_PATH,
  INS_SCHEDULES_PATH,
  INS_SMS_TEMPLATES_PATH,
  INS_TEAMS_PATH,
  INS_TENANT_PATH,
  INS_USER_ASSIGNMENT_PATH,
  INS_USERS_PATH,
  INS_EDIT_USER_SETTINGS_PATH,
  INS_APPLY_LEAVE_NEW_PATH,
  INS_APPLY_LEAVE_EDIT_PATH,
  CRM_CALLS_PATH,
  INS_CALLS_PATH,
  INS_CALL_QC_PATH,
  INS_CALL_VIEW_EDIT_QC_PATH,
} from "../constant/appPaths";
import React, { Suspense, useEffect } from "react";

import App from "../components/app";
import { FETCH_TENANT } from "../constant/actionTypes";
import ProtectedRoute from "../auth/ProtectedRoute";
import { setAppOnRoute } from "../helpers/Util";
import { useDispatch } from "react-redux";
import { CallQC } from "../pages/common/CallQC";
import { PERMISSIONS } from "../constant/permissions";

const InspectionRoutes = props => {
  const { isAdmin, handleLogout, user, userInfo, platformAvailable } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    setAppOnRoute(dispatch, FETCH_TENANT, platformAvailable);
  }, []);

  return (
    <App handleLogout={handleLogout} userInfo={userInfo} user={user} platformAvailable={platformAvailable}>
      {platformAvailable ? (
        <>
          <Suspense fallback={<> </>}>
            <ProtectedRoute path={INS_DASHBOARD_PATH} component={InspectionDashboard} exact user={user} />

            {/* /inspection/leads/id*/}
            <ProtectedRoute
              path={INS_LEADS_DETAIL_PATH}
              exact
              component={InspectionLeadDetail}
              user={user}
              categories="Lead"
              actions="read"
            />

            <ProtectedRoute
              path={INS_EDIT_USER_SETTINGS_PATH}
              exact
              component={UserSettingsPage}
              user={user}
              categories="Lead"
              actions="index"
            />
            {/* /inspection/leads  */}
            <ProtectedRoute
              path={INS_LEADS_PATH}
              exact
              component={InspectionLeads}
              user={user}
              categories="Lead"
              actions="index"
            />
            <ProtectedRoute
              path={INS_SCHEDULES_PATH}
              component={InspectionSchedules}
              user={user}
              categories="Schedule"
              actions="index"
            />
            <ProtectedRoute
              path={INS_INSPECTIONS_PATH}
              component={InspectionReports}
              user={user}
              categories="Report"
              actions="index"
            />
            <ProtectedRoute
              path={INS_CALLS_PATH}
              component={CrmCalls}
              exact
              user={user}
              catagories="Recording"
              actions="index"
            />
            <ProtectedRoute
              path={INS_CALL_QC_PATH}
              exact
              component={CallQC}
              user={user}
              catagories={PERMISSIONS.EvaluationReport.NAME}
              actions={PERMISSIONS.EvaluationReport.ACTIONS.CREATE}
            />
            <ProtectedRoute
              path={INS_CALL_VIEW_EDIT_QC_PATH}
              exact
              component={CallQC}
              user={user}
              catagories={PERMISSIONS.EvaluationReport.NAME}
              actions={PERMISSIONS.EvaluationReport.ACTIONS.READ}
            />

            <ProtectedRoute
              path={INS_PAYMENTS_PATH}
              component={InspectionPayments}
              user={user}
              categories="Payment"
              actions="index"
            />
            <ProtectedRoute
              path={INS_DEPOSITS_PATH}
              component={InspectionDeposits}
              user={user}
              categories="Deposit"
              actions="index"
            />
            <ProtectedRoute
              path={INS_LEAD_NEW_PATH}
              exact
              component={InspectionSaveLead}
              user={user}
              categories="Lead"
              actions="create"
            />
            <ProtectedRoute
              path={INS_LEAD_EDIT_PATH}
              exact
              component={InspectionSaveLead}
              user={user}
              categories="Lead"
              actions="update"
            />

            <ProtectedRoute
              path={INS_APPLY_LEAVE_NEW_PATH}
              component={CrmApplyLeave}
              exact
              user={user}
              catagories="Leave"
              actions="create"
            />

            <ProtectedRoute
              path={INS_APPLY_LEAVE_EDIT_PATH}
              component={CrmApplyLeave}
              exact
              user={user}
              catagories="Leave"
              actions="update"
            />

            <IF condition={isAdmin}>
              <ProtectedRoute path={INS_USERS_PATH} component={Users} user={user} categories="User" actions="index" />
              <ProtectedRoute
                path={INS_USER_ASSIGNMENT_PATH}
                exact
                component={SaveUserConfiguration}
                user={user}
                categories="User"
                actions="update"
              />
              <ProtectedRoute
                path={INS_TEAMS_PATH}
                component={InspectionTeams}
                user={user}
                categories="Team"
                actions="index"
              />
              <ProtectedRoute path={INS_TENANT_PATH} component={SaveTenant} user={user} categories="Tenant" />
              <ProtectedRoute
                exact
                path={`${INS_LEAD_TYPES_PATH}`}
                component={InspectionLeadTypes}
                user={user}
                categories="LeadType"
                actions="index"
              />
              <ProtectedRoute
                exact
                path={INS_NEW_LEAD_TYPE_PATH}
                component={InspectionSaveLeadType}
                user={user}
                categories="LeadType"
                actions="create"
              />
              <ProtectedRoute
                exact
                path={INS_EDIT_LEAD_TYPE_PATH}
                component={InspectionSaveLeadType}
                user={user}
                categories="LeadType"
                actions="update"
              />
              <ProtectedRoute
                exact
                path={INS_ROLES_PATH}
                component={Roles}
                user={user}
                categories="Role"
                actions="index"
              />
              <ProtectedRoute
                exact
                path={INS_NEW_ROLE_PATH}
                component={SaveRole}
                user={user}
                categories="Role"
                actions="create"
              />
              <ProtectedRoute
                path={INS_EDIT_ROLE_PATH}
                exact
                component={SaveRole}
                user={user}
                categories="Role"
                actions="update"
              />

              <ProtectedRoute
                path={INS_AUTHENTICATION_TOKEN_PATH}
                exact
                component={AuthenticationTokens}
                user={user}
                categories="AuthenticationToken"
                actions="index"
              />
              <ProtectedRoute
                path={INS_NEW_AUTHENTICATION_TOKEN_PATH}
                exact
                component={SaveAuthenticationToken}
                user={user}
                categories="AuthenticationToken"
                actions="create"
              />
              <ProtectedRoute
                path={INS_EDIT_AUTHENTICATION_TOKEN_PATH}
                exact
                component={SaveAuthenticationToken}
                user={user}
                categories="AuthenticationToken"
                actions="update"
              />
              {/* <ProtectedRoute
                path={INS_INSPECTION_PROGRAM_PATH}
                exact
                component={InspectionProgram}
                user={user}
                categories="Inspection" // change permission
                actions="index"
              />
              <ProtectedRoute
                path={INS_NEW_INSPECTION_PROGRAM_PATH}
                exact
                component={SaveInspectionProgram}
                user={user}
                categories="Inspection" // change permission
                actions="create"
              />
              <ProtectedRoutes
                path={INS_EDIT_INSPECTION_PROGRAM_PATH}
                exact
                component={SaveInspectionProgram}
                user={user}
                categories="Inspection" // change permission
                actions="update"
              /> */}
              {/*<ProtectedRoute*/}
              {/*  path={INS_SMS_TEMPLATES_PATH}*/}
              {/*  exact*/}
              {/*  component={InspectionSmsTemplates}*/}
              {/*  user={user}*/}
              {/*  categories="SmsTemplate"*/}
              {/*  actions="index"*/}
              {/*/>*/}

              {/*<ProtectedRoute*/}
              {/*  path={INS_EDIT_SMS_TEMPLATES_PATH}*/}
              {/*  exact*/}
              {/*  component={InspectionSaveSmsTemplate}*/}
              {/*  user={user}*/}
              {/*  categories="SmsTemplate"*/}
              {/*  actions="update"*/}
              {/*/>*/}

              <ProtectedRoute
                path={INS_EMAIL_TEMPLATES_PATH}
                exact
                component={InspectionEmailTemplates}
                user={user}
                categories="EmailTemplate"
                actions="index"
              />
              <ProtectedRoute
                path={INS_EDIT_EMAIL_TEMPLATES_PATH}
                exact
                component={InspectionSaveEmailTemplate}
                user={user}
                categories="EmailTemplate"
                actions="update"
              />

              <ProtectedRoute
                path={INS_LEAD_SOURCE_PATH}
                exact
                component={LeadSources}
                user={user}
                catagories="LeadSource"
                actions="index"
              />
              <ProtectedRoute
                path={INS_NEW_LEAD_SOURCE_PATH}
                exact
                component={SaveLeadSource}
                user={user}
                catagories="LeadSource"
                actions="create"
              />
              <ProtectedRoute
                path={INS_EDIT_LEAD_SOURCE_PATH}
                exact
                component={SaveLeadSource}
                user={user}
                catagories="LeadSource"
                actions="update"
              />

              <ProtectedRoute
                path={INS_DYNAMIC_MODELS_PATH}
                exact
                component={DynamicModels}
                user={user}
                categories="DynamicModel"
                actions="index"
              />

              <ProtectedRoute
                path={INS_DYNAMIC_FIELDS_PATH}
                exact
                component={DynamicFields}
                user={user}
                categories="DynamicField"
                actions="index"
              />
            </IF>

            {/* <Redirect to={INS_DASHBOARD_PATH} /> */}
          </Suspense>
        </>
      ) : (
        <NotAvailable />
      )}
    </App>
  );
};

export default InspectionRoutes;
