interface PermissionActions {
  [action: string]: string;
}

interface Permission<T extends PermissionActions> {
  NAME: string;
  ACTIONS: T;
}

type PermissionNames =
  | "REPORT"
  | "ExternalAd"
  | "DynamicField"
  | "EvaluationReport"
  | "CRMLEAD"
  | "TASK"
  | "LEAD"
  | "COMMENT"
  | "CAR"
  | "RECORDING";

export const PERMISSIONS: Record<PermissionNames, Permission<PermissionActions>> = {
  REPORT: {
    NAME: "Report",
    ACTIONS: {
      ADD_EVALUATION: "add_evaluation",
      UPDATE_EVALUATION: "update_evaluation",
      INDEX: "index",
    },
  },
  DynamicField: {
    NAME: "DynamicField",
    ACTIONS: {
      INDEX: "index", // LISTING
    },
  },
  ExternalAd: {
    NAME: "ExternalAd",
    ACTIONS: {
      INDEX: "index", // LISTING
    },
  },
  EvaluationReport: {
    NAME: "EvaluationReport",
    ACTIONS: {
      CREATE: "create",
      EDIT: "edit", //GET call
      READ: "read",
      UPDATE: "update", // PUT CALL
      INDEX: "index", // LISTING
    },
  },
  CRMLEAD: {
    NAME: "CrmLead",
    ACTIONS: {
      READ: "read",
      ADD_COMMENT: "add_comment",
      CHEVRON_VIEW: "chevron_settings",
      CHEVRON_VIEW_USERS: "chevron_users_list",
    },
  },
  TASK: {
    NAME: "Task",
    ACTIONS: {
      INDEX: "index",
      ADD_COMMENT: "add_comment",
    },
  },
  LEAD: {
    NAME: "Lead",
    ACTIONS: {
      READ: "read",
    },
  },
  COMMENT: {
    NAME: "Comment",
    ACTIONS: {
      INDEX: "index",
    },
  },
  CAR: {
    NAME: "Car",
    ACTIONS: {
      READ: "read",
      ADD_COMMENT: "add_comment",
      REPORTS: "reports",
      EXTERNAL_AD: "external_ad",
    },
  },
  RECORDING: {
    NAME: "Recording",
    ACTIONS: {
      CREATE: 'create',
    }
  },
};
