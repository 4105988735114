import { FETCH_CRM_CICOS, FETCH_CRM_CICO_DETAIL, SET_CRM_CICO_LOADING } from "../../constant/actionTypes";

export const INITIAL_STATE = {
  cicoList: [],
  cicoReasons: [],
  cicoTypes: [],
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CRM_CICOS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CRM_CICO_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };
    default:
      return state;
  }
};
