import React, { useState } from "react";
import cx from "clsx";
import { useSelector } from "react-redux";
import styles from "./ChevronLeadCard.module.scss";
import { FiChevronDown, FiChevronUp } from "../../../../svg-icon/svg-icons";
import ChevronLeadTasks from "./chevron-lead-tasks/ChevronLeadTasks";
import ChevronCardDateTime from "./chevron-card-date-time/ChevronCardDateTime";
import Card from "../../../Card";
import Collapse from "components/common/Collapse/Collapse";
import IF from "components/common/IF";
import { isFieldRestricted, isFieldViewable, openUrlLinkInNewTab } from "../../../../../helpers/Util";
import { CRM_LEAD_DETAIL_PATH, CRM_CARS_DETAIL_PATH } from "../../../../../constant/appPaths";
import { selectActiveColumns } from "reducers/general/selectors";
import ChevronCardLocation from "./chevron-card-location/ChevronCardLocation";

const ChevronLeadCard = ({ dispositionSlug, item: cardData }) => {
  const {
    inventory,
    chevron_tasks,
    reference_number,
    id: leadIdNumber,
    client,
    chevron_appointment,
    chevron_traversed_dispositions,
    category_type,
  } = cardData;
  const activeColumns = useSelector(selectActiveColumns);
  const crmLeadPermittedAttributes = activeColumns?.CrmLead;
  const [expandCardVisible, setExpandCardVisible] = useState(false);
  const isChevronDateTimePermitted =
    !isFieldRestricted(chevron_appointment) &&
    !isFieldRestricted(chevron_traversed_dispositions) &&
    isFieldViewable(crmLeadPermittedAttributes?.["chevron_traversed_dispositions"]) &&
    isFieldViewable(crmLeadPermittedAttributes?.["chevron_appointment"]);
  const refNumber =
    !isFieldRestricted(reference_number) && isFieldViewable(crmLeadPermittedAttributes?.["reference_number"])
      ? reference_number
      : "";
  const clientName = client?.name && !isFieldRestricted(client.name) ? client?.name : "";
  const cardTitle = `${refNumber} - ${clientName}`;
  const locationTooltipId = `tooltip-location-${reference_number}-${chevron_appointment?.area?.id}`;
  const navigateToLeadDetailPage = leadId => {
    const path = `/admin${CRM_LEAD_DETAIL_PATH.replace(":id", leadId)}`;
    openUrlLinkInNewTab(path);
  };

  const navigateToCarDetailPage = carId => {
    const path = `/admin${CRM_CARS_DETAIL_PATH.replace(":id", carId)}`;
    openUrlLinkInNewTab(path);
  };

  const leadHeader = () => (
    <div className="d-flex flex-row justify-content-between align-items-center">
      <IF condition={!isFieldRestricted(leadIdNumber)}>
        <div
          className={cx("infoItemHover", styles.cardUserTitle, styles.cardTitle)}
          onClick={() => navigateToLeadDetailPage(leadIdNumber)}
        >
          {cardTitle}
          <div className={styles.detailLink}>
            <i className="fa fa-angle-right"></i>
          </div>
        </div>
      </IF>
    </div>
  );

  const inventoryHeader = () => (
    <div className="mt-2 f-w-600 f-16" onClick={() => navigateToCarDetailPage(inventory?.car?.id)}>
      <IF condition={inventory?.car?.id && !isFieldRestricted(inventory.car.id)}>
        <div className={cx("infoItemHover", styles.cardTitle)}>
          {inventory?.car?.full_title ?? ""}
          <div className={styles.detailLink}>
            <i className="fa fa-angle-right"></i>
          </div>
        </div>
      </IF>
    </div>
  );

  return (
    <Card
      cardBg="#fff"
      className={cx("d-flex flex-column mt-4 p-3 position-relative", styles.cardContainer)}
      cardBorderRadius={"6px"}
      style={{ border: "1px solid #D0D5DD" }}
    >
      {leadHeader()}
      {inventoryHeader()}
      <IF
        condition={
          isFieldViewable(crmLeadPermittedAttributes?.["category_type.name"]) && !isFieldRestricted(category_type?.name)
        }
      >
        <ChevronCardLocation
          categoryType={category_type}
          chevronAppointment={chevron_appointment}
          locationTooltipId={locationTooltipId}
        />
      </IF>
      <IF condition={isChevronDateTimePermitted}>
        <ChevronCardDateTime
          chevronAppointment={chevron_appointment}
          chevronTraversedDispositions={chevron_traversed_dispositions}
          dispositionSlug={dispositionSlug}
          leadRefNumber={refNumber}
        />
      </IF>
      <IF condition={!isFieldRestricted(chevron_tasks) && !!chevron_tasks?.length}>
        <Collapse
          containerClassName={styles.iconContainer}
          isOpen={expandCardVisible}
          closeIcon={<FiChevronDown size={20} />}
          openIcon={<FiChevronUp size={20} />}
        >
          <ChevronLeadTasks leadTasks={chevron_tasks} leadRefNumber={refNumber} />
        </Collapse>
      </IF>
    </Card>
  );
};

export default ChevronLeadCard;
