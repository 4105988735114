import { FETCH_LOGGED_IN_USER_INFO, UPDATE_LOGGED_IN_USER_INFO } from "../../constant/actionTypes";
import { PURGE } from "redux-persist";

const INITIAL_STATE = {
  designations: [],
  user: {},
  loading: false,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE;
    case FETCH_LOGGED_IN_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_LOGGED_IN_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
