import { Avatar, Close, CustomAlert, Modal, Skeleton, Status, Toast } from "../common";
import React, { useEffect, useState, useCallback } from "react";
import { soldInventoryApi } from "../../helpers/ApiHelper";
import { checkUndefinedApiParams, getErrorString } from "../../helpers/Util";
import FiltersWithoutQueryParams from "../common/filters/FiltersWithoutQueryParams";
import { Table } from "reactstrap";
import { strings } from "../../constant/strings";
import RenderPrivateField from "../common/RenderPrivateField/RenderPrivateField";
import { extractPayloadFromClientObj } from "../../helpers/ViewPhoneNumber";
import {
  MODELS_FOR_VIEW_PHONE_NUMBER_API,
  PHONE_NUMBER_CATEGORIZATION_PER_PAGE,
  SCROLLABLE_ELEMENTS_KEYS,
} from "constant/appConstants";

const SelectConnectModal = props => {
  const [filtersObject, setFiltersObject] = useState({});
  const [list, setList] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [crmLeadSelect, setCrmLeadSelect] = useState(null);

  const getOnScrollElement = useCallback(() => {
    return document.getElementById(SCROLLABLE_ELEMENTS_KEYS.MODAL_TABLE_KEY);
  }, [list]);

  useEffect(() => {
    const removeLostLeads = props?.dataFromProps?.filter(item => item?.status?.slug !== "lost");
    props?.visible && props?.dataFromProps && setList(removeLostLeads);
  }, [props?.visible, props?.dataFromProps]);

  const filteredList = list
    ? list.filter(listItem => {
        if (filtersObject && Object.keys(filtersObject).length === 0) return true;
        if (filtersObject?.["f[client.phone]"] && !listItem?.client?.phone.includes(filtersObject?.["f[client.phone]"]))
          return false;
        if (
          filtersObject?.["f[reference_number]"] &&
          !String(listItem?.reference_number).includes(filtersObject?.["f[reference_number]"])
        )
          return false;
        if (
          filtersObject?.["s[client.name]"] &&
          !listItem?.client?.name.toLowerCase().includes(filtersObject?.["s[client.name]"].toLowerCase())
        )
          return false;
        return true;
      })
    : null;

  const selectBuyerLeadHandler = async () => {
    if (!checkUndefinedApiParams(props.inventoryId, "inventory")) return;
    const response = await soldInventoryApi(props.inventoryId, {
      buyer_lead_id: crmLeadSelect?.id,
      inventory: {
        disposition_id: props?.dispositionId,
      },
    });
    if (response) {
      if (response.success) {
        props.updateInventoryCallback(response);
        Toast.success(strings.mark_as_sold_success_msg);
        props.setVisible(false);
      } else {
        Toast.error(getErrorString(response));
      }
    }
    setCrmLeadSelect(null);
  };

  const renderHeader = () => (
    <tr>
      <th></th>
      <th>{strings.ref}</th>
      <th>{strings.name}</th>
      <th>{strings.phone}</th>
      <th>{strings.status}</th>
      <th>{strings.lead_type}</th>
      <th>{strings.lead_category}</th>
    </tr>
  );

  const renderBody = item => (
    <tr
      key={item.id}
      onClick={() => {
        setCrmLeadSelect({ ...item });
      }}
    >
      <td>
        <label style={{ top: "-8px", left: "10px" }}></label>
      </td>
      <td scope="row">{item.reference_number}</td>
      <td>
        <Avatar name={item?.client?.name} />
      </td>
      <td>
        <div
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <RenderPrivateField
            {...extractPayloadFromClientObj({ item: item?.client })}
            associatedType={MODELS_FOR_VIEW_PHONE_NUMBER_API.LEAD_PHONE_KEY}
            component={PHONE_NUMBER_CATEGORIZATION_PER_PAGE.TASK.LEAD_MODAL}
            associatedId={item?.id}
            dropDownContainer="tbody"
            onScrollElement={getOnScrollElement()}
          />
        </div>
      </td>
      <td>
        <Status {...item.status} />
      </td>
      <td>{item?.category?.name}</td>
      <td>{item?.category_type?.name}</td>
    </tr>
  );

  const renderSkeleton = item => (
    <tr key={item}>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Avatar name={item?.client?.name} loading={loading} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Status loading={loading} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
    </tr>
  );

  const renderFilters = () => {
    return (
      <FiltersWithoutQueryParams
        list={[
          {
            sizeratio: 1,
            label: strings.ref,
            type: "input",
            key: "f[reference_number]",
          },
          {
            sizeratio: 1,
            label: strings.name,
            type: "input",
            key: "s[client.name]",
          },
          {
            sizeratio: 1,
            label: strings.phone,
            type: "input",
            key: "f[client.phone]",
          },
        ]}
        filtersObject={filtersObject}
        setFiltersObject={setFiltersObject}
        containerClass="m-t-20"
      />
    );
  };

  const renderModal = () => {
    return (
      <Modal
        toggle={() => {
          props.setVisible(!props.visible);
        }}
        open={!!props.visible}
        className="modal-lg deposit_model"
        body={() => {
          return (
            <>
              <div className="d-flex w-100 align-items-center justify-content-between">
                <h4>{strings.select_connect}</h4>
                <Close
                  style={{ right: "30px" }}
                  isTransparent
                  onClick={() => {
                    props.setVisible(false);
                  }}
                />
              </div>
              {
                <>
                  {renderFilters()}
                  <div className="modal-table inventory-modal-table">
                    {error ? (
                      <CustomAlert message={error} className="mx-3" />
                    ) : (
                      <Table>
                        <thead>{renderHeader()}</thead>
                        <tbody>
                          {loading
                            ? [1, 2, 3, 4, 5, 6].map(renderSkeleton)
                            : filteredList?.length > 0 && filteredList?.map(item => renderBody(item))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </>
              }
            </>
          );
        }}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              props.setVisible(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
        ]}
        onClosed={e => {
          setFiltersObject({});
        }}
      />
    );
  };

  return (
    <div className="form-group row d-flex flex-row align-items-center">
      <div className="col-sm-9 d-flex flex-column align-items-start">
        {!!props.errorMsg && <p className="invalid-error">{props.errorMsg}</p>}
      </div>
      {renderModal()}
      <Modal
        toggle={() => {
          setCrmLeadSelect(null);
        }}
        title={strings.confirmation}
        open={!!crmLeadSelect}
        body={() => (
          <>
            <p>{`Are you sure you want to sell this inventory to ${crmLeadSelect?.client?.name}?`}</p>
            {error && <CustomAlert message={error} />}
          </>
        )}
        actions={[
          {
            label: strings.no,
            onClick: () => {
              setCrmLeadSelect(null);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },

          {
            color: "primary",
            className: "modal__cr-btn btn-pill",
            label: strings.yes,

            onClick: () => {
              selectBuyerLeadHandler();
            },
          },
        ]}
      />
    </div>
  );
};

export default SelectConnectModal;
