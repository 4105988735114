// AUTHENTICATION APP
export const LOGIN = "LOGIN";
export const INITIALIZE_FIREBASE = "INITIALIZE_FIREBASE";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

//CUSTOMIZER
export const ADD_CUSTOMIZER = "ADD_CUSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";

// Users
export const ADD_USER = "ADD_USER";
export const FETCH_USERS = "FETCH_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const UPDATE_LOGGED_IN_USER_INFO = "UPDATE_LOGGED_IN_USER_INFO";
export const FETCH_USER_LEAVES = "FETCH_USER_LEAVES";
export const SET_USERS_LOADING = "SET_USERS_LOADING";

// Login
export const FETCH_LOGGED_IN_USER_INFO = "FETCH_LOGGED_IN_USER_INFO";
export const FETCH_LOGGED_IN_USER_DESIGNATIONS = "FETCH CURRENT_USER_DESIGNATIONS";

// Configs
export const FETCH_TENANT = "FETCH_TENANT";
export const UPDATE_LIMIT_PER_PAGE = "UPDATE_LIMIT_PER_PAGE";

export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const UPDATE_MODEL_PERMISSIONS = "FETCH_MODEL_PERMISSIONS";

// Teams
export const ADD_TEAM = "ADD_TEAM";
export const FETCH_TEAMS = "FETCH_TEAMS";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const DELETE_TEAM = "DELETE_TEAM";
export const SET_TEAMS_LOADING = "SET_TEAMS_LOADING";

// Leads
export const ADD_LEAD = "ADD_LEAD";
export const FETCH_LEADS = "FETCH_LEADS";
export const UPDATE_LEAD = "UPDATE_LEAD";
export const ADD_FILTERS = "ADD_FILTERS";
export const CLEAR_LEADS = "CLEAR_LEADS";
export const UPDATE_LEAD_DETAILS = "UPDATE_LEAD_DETAILS";
export const FETCH_LEAD_DETAILS = "FETCH_LEAD_DETAILS";
export const UPDATE_LEAD_PAYMENT = "UPDATE_LEAD_PAYMENT";
export const SET_LEADS_LOADING = "SET_LEADS_LOADING";

// Unscheduled Leads
export const ADD_UNSCHEDULED_LEADS = "ADD_UNSCHEDULED_LEADS";
export const FETCH_UNSCHEDULED_LEADS = "FETCH_UNSCHEDULED_LEADS";

// Reports
export const FETCH_REPORTS = "FETCH_REPORTS";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const SET_REPORTS_LOADING = "SET_REPORTS_LOADING";

// Payments
export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const SET_PAYMENTS_LOADING = "SET_PAYMENTS_LOADING";

// Quick Links
export const FETCH_QUICK_LINKS = "FETCH_QUICK_LINKS";
export const DELETE_QUICK_LINK = "DELETE_QUICK_LINK";
export const ADD_QUICK_LINK = "ADD_QUICK_LINK";
export const CLEAR_QUICK_LINKS = "CLEAR_QUICK_LINKS";

//DASHBOARD
export const SET_STATUS_FILTERS = "SET_STATUS_FILTERS";
export const FETCH_STATUSES_INFO_DASHBOARD = "FETCH_STATUSES_INFO";
export const FETCH_RUNNING_LATE_DASHBOARD = "FETCH_RUNNING_LATE_DASHBOARD";
export const SET_BY_TYPES_FILTERS = "SET_BY_TYPES_FILTERS";
export const SET_BY_CITIES_FILTERS = "SET_BY_CITIES_FILTERS";
export const SET_BY_DISPOSITIONS_FILTERS = "SET_BY_DISPOSITIONS_FILTERS";
export const SET_BY_LEAD_STATUSES_FILTERS = "SET_BY_LEAD_STATUSES_FILTERS";
export const UPDATE_STATUSES_DATA_FOR_DOGHNUT = "UPDATE_STATUSES_DATA_FOR_DOGHNUT";
export const SET_BY_LEAD_SOURCE_FILTERS = "SET_BY_LEAD_SOURCE_FILTERS";

// Roles
export const FETCH_ROLES = "FETCH_ROLES";
export const ADD_ROLE = "ADD_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const SET_ROLES_LOADING = "SET_ROLES_LOADING";

// Authentication Tokens
export const FETCH_AUTHENTICATION_TOKENS = "FETCH_AUTHENTICATION_TOKENS";
export const ADD_AUTHENTICATION_TOKEN = "ADD_AUTHENTICATION_TOKEN";
export const EXPIRE_AUTHENTICATION_TOKEN = "EXPIRE_AUTHENTICATION_TOKEN";
export const UPDATE_AUTHENTICATION_TOKEN = "UPDATE_AUTHENTICATION_TOKEN";
export const SET_AUTHENTICATION_TOKENS_LOADING = "SET_AUTHENTICATION_TOKENS_LOADING";

// Lead Type
export const FETCH_LEAD_TYPES = "FETCH_LEAD_TYPES";
export const ADD_LEAD_TYPE = "ADD_LEAD_TYPE";
export const DELETE_LEAD_TYPE = "DELETE_LEAD_TYPE";
export const UPDATE_LEAD_TYPE = "UPDATE_LEAD_TYPE";
export const SET_LEAD_SOURCES = "SET_LEAD_SOURCES";
export const SET_LEAD_TYPES_LOADING = "SET_LEAD_TYPES_LOADING";

// Cities
export const FETCH_CITIES = "FETCH_CITIES";

// Car Features
export const FETCH_CAR_FEATURES_LISTS = "FETCH_CAR_FEATURES_LISTS";

// CAR
export const ADD_CAR = "ADD_CAR";
export const FETCH_CARS = "FETCH_CARS";
export const FETCH_STOCK_CARS = "FETCH_STOCK_CARS";
export const SET_STOCK_CARS_LOADING = "SET_STOCK_CARS_LOADING";
export const UPDATE_CAR = "UPDATE_CAR";
export const ADD_CARS_FILTERS = "ADD_CARS_FILTERS";
export const SET_CARS_LOADING = "SET_CARS_LOADING";
export const CLEAR_CARS = "CLEAR_CARS";
export const UPDATE_RECOMMENDED_PRICE = "UPDATE_RECOMMENDED_PRICE";
export const UPDATE_INVENTORY_STATUS = "UPDATE_INVENTORY_STATUS";
export const SET_CAR_DETAIL = "SET_CAR_DETAIL";
export const SET_CAR_DETAIL_DYNAMIC_ATTRIBUTES = "SET_CAR_DETAIL_DYNAMIC_ATTRIBUTES";
export const SET_CAR_DETAIL_COMMENTS = "SET_CAR_DETAIL_COMMENTS";
export const CLEAR_CAR_DETAILS = "CLEAR_CAR_DETAILS";
export const CAR_CONNECTS_UPADTE = "CAR_CONNECTS_UPADTE";
export const UPDATE_CAR_DOCUMENTS = "UPDATE_CAR_DOCUMENTS";
export const REMOVE_CAR_DOCUMENT = "REMOVE_CAR_DOCUMENT";
export const UPDATE_CAR_DOCUMENT_LABEL = "UPDATE_CAR_DOCUMENT_LABEL";
export const FETCH_INVENTORY_ACTIVITIES = "INVENTORY_ACTIVITIES";
export const UPDATE_CAR_TAGS = "UPDATE_CAR_TAGS";
export const UPDATE_CAR_DETAIL = "UPDATE_CAR_DETAIL";

// Groups
export const ADD_GROUP = "ADD_GROUP";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const SET_GROUPS_LOADING = "SET_GROUPS_LOADING";

// Auction Types
export const ADD_AUCTION_TYPE = "ADD_AUCTION_TYPE";
export const FETCH_AUCTION_TYPES = "FETCH_AUCTION_TYPES";
export const UPDATE_AUCTION_TYPE = "UPDATE_AUCTION_TYPE";
export const DELETE_AUCTION_TYPE = "DELETE_AUCTION_TYPE";
export const SET_AUCTION_TYPES_LOADING = "SET_AUCTION_TYPES_LOADING";

// Auction Users
export const FETCH_AUCTION_USERS = "FETCH_AUCTION_USERS";
export const UPDATE_AUCTION_USER = "UPDATE_AUCTION_USER";
export const ARCHIVE_AUCTION_USER = "ARCHIVE_AUCTION_USER";
export const SET_AUCTION_USERS_LOADING = "SET_AUCTION_USERS_LOADING";

// Auctions
export const ADD_AUCTION = "ADD_AUCTION";
export const FETCH_AUCTIONS = "FETCH_AUCTIONS";
export const SET_AUCTIONS_LOADING = "SET_AUCTIONS_LOADING";

// Offers
export const FETCH_OFFERS = "FETCH_OFFERS";

// Auction Dashboard
export const FETCH_AUCTION_STATUSES_INFO_DASHBOARD = "FETCH_AUCTION_STATUSES_INFO_DASHBOARD";
export const FETCH_INVENTORIES_STATUSES_INFO_DASHBOARD = "FETCH_INVENTORIES_STATUSES_INFO_DASHBOARD";
export const SET_TOP_CITIES_AUCTION_FILTERS = "SET_TOP_CITIES_AUCTION_FILTERS";
export const SET_STATUS_AUCTION_FILTERS = "SET_STATUS_AUCTION_FILTERS";
export const SET_STATUS_INVENTORIES_FILTERS = "SET_STATUS_INVENTORIES_FILTERS";
export const UPDATE_STATUSES_DATA_FOR_AUCTION_DOGHNUT = "UPDATE_STATUSES_DATA_FOR_AUCTION_DOGHNUT";
export const FETCH_ENDING_SOON_AUCTIONS = "FETCH_ENDING_SOON_AUCTIONS";

// DEPOSISTS
export const FETCH_DEPOSITS = "FETCH_DEPOSITS";
export const UPDATE_DEPOSIT = "UPDATE_DEPOSIT";
export const SET_DEPOSITS_LOADING = "SET_DEPOSITS_LOADING";

// Inspection Program

export const FETCH_INSPECTION_PROGRAMS = "FETCH_INSPECTION_PROGRAMS";
export const ADD_INSPECTION_PROGRAM = "ADD_INSPECTION_PROGRAM";
export const UPDATE_INSPECTION_PROGRAM = "UPDATE_INSPECTION_PROGRAM";
export const DELETE_INSPECTION_PROGRAM = "DELETE_INSPECTION_PROGRAM";

// CRM Leads
export const ADD_CRM_LEAD = "ADD_CRM_LEAD";
export const FETCH_CRM_LEADS = "FETCH_CRM_LEADS";
export const UPDATE_CRM_LEAD = "UPDATE_CRM_LEAD";
export const ADD_CRM_LEAD_FILTERS = "ADD_CRM_LEAD_FILTERS";
export const CLEAR_CRM_LEADS = "CLEAR_CRM_LEADS";
export const CRM_LEAD_CHANGE_STATUS = "CRM_LEAD_CHANGE_STATUS";
export const SET_CRM_LEADS_LOADING = "SET_CRM_LEADS_LOADING";

// CRM Lead
export const FETCH_CRM_LEAD_DETAIL = "FETCH_CRM_LEAD_DETAIL";
export const SET_CRM_LEAD_DATA = "SET_CRM_LEAD_DATA";
export const CLEAR_CRM_LEAD_DETAIL = "CLEAR_CRM_LEAD_DETAIL";
export const CRM_LEAD_UPDATE = "CRM_LEAD_UPDATE";
export const CRM_LEAD_UPDATE_TASK = "CRM_LEAD_UPDATE_TASK";
export const CRM_LEAD_ADD_TASK = "CRM_LEAD_ADD_TASK";
export const UPDATE_CRM_LEAD_COMMENTS = "UPDATE_CRM_LEAD_COMMENTS";
export const UPDATE_CRM_LEAD_WATCHERS = "UPDATE_CRM_LEAD_WATCHERS";
export const FETCH_CRM_LEAD_TASKS = "FETCH_CRM_LEAD_TASKS";
export const CRM_LEAD_CONNECTS_UPDATE = "CRM_LEAD_CONNECTS_UPDATE";
export const FETCH_CRM_LEAD_ACTIVITIES = "CRM_LEAD_ACTIVITIES";
export const UPDATE_CRM_LEAD_DETAIL = "UPDATE_CRM_LEAD_DETAIL";
export const UPDATE_VAS_LEAD = "UPDATE_VAS_LEAD";

// CRM Tasks
export const ADD_TASK = "ADD_TASK";
export const FETCH_TASKS = "FETCH_TASKS";
export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_TASK_MEETING = "UPDATE_TASK_MEETING";
export const ADD_TASK_FILTERS = "ADD_TASK_FILTERS";
export const SET_TASKS_LOADING = "SET_TASKS_LOADING";
export const CLEAR_TASK = "CLEAR_TASK";
export const REVERT_TASK_STATUS = "REVERT_TASK_STATUS";
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const CRM_LEAD_TASK_STATUS_UPDATE = "CRM_LEAD_TASK_STATUS_UPDATE";
export const UPDATE_TASK_REMINDERS = "UPDATE_TASK_REMINDERS";
export const UPDATE_TASKS_REMINDERS = "UPDATE_TASKS_REMINDERS";
export const UPDATE_FOLLOW_UPS = "UPDATE_FOLLOW_UPS";

// CRM Task
export const FETCH_TASK_DETAIL = "FETCH_TASK_DETAIL";
export const SET_TASK_DATA = "SET_TASK_DATA";
export const CLEAR_CRM_TASK_DETAIL = "CLEAR_CRM_TASK_DETAIL";

// CRM Tasks Locations
export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const ADD_LOCATION = "ADD_LOCATION";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const SET_LOCATIONS_LOADING = "SET_LOCATIONS_LOADING";

// CRM Clients
export const FETCH_CRM_CLIENTS = "FETCH_CRM_CLIENTS";
export const SET_CRM_CLIENTS_LOADING = "SET_CRM_CLIENTS_LOADING";

// CRM Payments
export const FETCH_CRM_PAYMENTS = "FETCH_CRM_PAYMENTS";
export const UPDATE_CRM_PAYMENT = "UPDATE_CRM_PAYMENT";
export const SET_CRM_PAYMENTS_LOADING = "SET_CRM_PAYMENTS_LOADING";

// CRM Contracts
export const FETCH_CRM_CONTRACTS = "FETCH_CRM_CONTRACTS";
export const UPDATE_CRM_CONTRACT = "UPDATE_CRM_CONTRACT";
export const UPDATE_CRM_CONTRACT_EMAIL = "UPDATE_CRM_CONTRACT_EMAIL";
export const CLEAR_CRM_CONTRACT = "CLEAR_CRM_CONTRACT";
export const SET_CRM_CONTRACTS_LOADING = "SET_CRM_CONTRACTS_LOADING";

// Watchers
export const FETCH_CRM_WATCHER_USERS = "FETCH_CRM_WATCHER_USERS";

// CRM Dashboard
// export const CRM_LEADS_OVERVIEW = "CRM_LEADS_OVERVIEW";
export const SET_CRM_LEAD_STATISTICS_FILTERS = "SET_CRM_LEAD_STATISTICS_FILTERS";
export const SET_CRM_LEAD_STATISTICS_DATA = "SET_CRM_LEAD_STATISTICS_DATA";

// Lead Source
export const FETCH_LEAD_SOURCES = "FETCH_LEAD_SOURCES";
export const UPDATE_LEAD_SOURCE = "UPDATE_LEAD_SOURCE";
export const SET_LEAD_SOURCES_LOADING = "SET_LEAD_SOURCES_LOADING";

// export const SET_APPOINTMENT_DATA = "SET_APPOINTMENT_DATA";
// export const CLEAR_APPOINTMENT = "CLEAR_APPOINTMENT";

// SMS
export const FETCH_SMS_TEMPLATES = "FETCH_SMS_TEMPLATES";
export const UPDATE_SMS_TEMPLATE = "UPDATE_SMS_TEMPLATE";

// EMAIL
export const FETCH_EMAIL_TEMPLATES = "FETCH_EMAIL_TEMPLATES";
export const UPDATE_EMAIL_TEMPLATE = "UPDATE_EMAIL_TEMPLATE";
export const SET_EMAILS_TEMPLATES_LOADING = "SET_EMAILS_TEMPLATES_LOADING";

// Dynamic Fields
export const FETCH_DYNAMIC_FIELDS = "FETCH_DYNAMIC_FIELDS";
export const ADD_DYNAMIC_FIELD = "ADD_DYNAMIC_FIELD";
export const SET_DYNAMIC_FIELDS_LOADING = "SET_DYNAMIC_FIELDS_LOADING";
export const UPDATE_DYNAMIC_FIELD = "UPDATE_DYNAMIC_FIELD";
export const DELETE_DYNAMIC_FIELD = "DELETE_DYNAMIC_FIELD";
export const FETCH_DYNAMIC_MODELS = "FETCH_DYNAMIC_MODELS";
export const SET_DYNAMIC_MODELS_LOADING = "SET_DYNAMIC_MODELS_LOADING";

//CRM Dashboard
export const SET_CRM_STATUS_FILTER = "SET_CRM_STATUS_FILTER";
export const SET_CRM_STATISTICS = "SET_CRM_STATISTICS";
export const SET_CRM_INVENTORY_STATISTICS = "SET_CRM_INVENTORY_STATISTICS";
export const SET_CRM_REPORT_TYPE_WIDGETS = "SET_CRM_REPORT_TYPE_WIDGETS";
// Crm Calendar

export const SET_APPOINTMENTS_DATA = "SET_APPOINTMENTS_DATA";
export const UPDATE_APPOINTMENTS_DATA = "UPDATE_APPOINTMENTS_DATA";
export const UPDATE_APPOINTMENT_DATA = "UPDATE_APPOINTMENT_DATA";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const SET_TASK_APPOINTMENTS = "SET_TASK_APPOINTMENTS";
export const SET_SCHEDULES_DATA = "SET_SCHEDULES_DATA";

export const FETCH_TASK_TYPES = "FETCH_TASK_TYPES";
export const ADD_TASK_TYPE = "ADD_TASK_TYPE";
export const UPDATE_TASK_TYPE = "UPDATE_TASK_TYPE";
export const DELETE_TASK_TYPE = "DELETE_TASK_TYPE";
export const SET_TASK_TYPES_LOADING = "SET_TASK_TYPES_LOADING";

export const AUCTION_MATRIX_FILTER = "AUCTION_MATRIX_FILTER";
export const UPDATE_CRM_LEAD_ACTIVITIES = "UPDATE_CRM_LEAD_ACTIVITIES";
export const FETCH_ASSIGNEES = "FETCH_ASSIGNEES";
export const APPEND_INSPECTION_LEAD = "APPEND_INSPECTION_LEAD";
//Crm Approvals
export const FETCH_CRM_APPROVALS = "FETCH_CRM_APPROVALS";
export const UPDATE_CRM_APPROVAL = "UPDATE_CRM_APPROVAL";
export const SET_CRM_APPROVALS_LOADING = "SET_CRM_APPROVALS_LOADING";

//Custom Reducer
export const SET_CUSTOM_STATE = "SET_CUSTOM_STATE";
export const RESET_CUSTOM_STATE = "RESET_CUSTOM_STATE";
//Stock Count
export const FETCH_STOCK_COUNT = "FETCH_STOCK_COUNT";
export const SET_STOCK_COUNT_LOADING = "SET_STOCK_COUNT_LOADING";

//CRM CICO
export const FETCH_CRM_CICOS = "FETCH_CRM_CICOS";
export const FETCH_CRM_CICO_DETAIL = "FETCH_CRM_CICO_DETAIL";
export const SET_CRM_CICO_LOADING = "SET_CRM_CICO_LOADING";

export const FETCH_CRM_ITEM_LISTS = "FETCH_CRM_ITEM_LISTS";
export const UPDATE_ITEM_LIST_DATA = "UPDATE_ITEM_LIST_DATA";
export const ADD_ITEM_LIST_DATA = "ADD_ITEM_LIST_DATA";
export const SET_ITEM_LIST_LOADING = "SET_ITEM_LIST_LOADING";

//crm Reminders
export const FETCH_CRM_REMINDER = "FETCH_CRM_REMINDER";
export const UPDATE_CRM_REMINDER = "UPDATE_CRM_REMINDER";
export const SET_CRM_REMINDERS_LOADING = "SET_CRM_REMINDERS_LOADING";

//Dynamic Filter
export const SET_DYNAMIC_FILTERS_DATA = "SET_DYNAMIC_FILTERS_DATA";
export const FLUSH_DYNAMIC_FILTERS_DATA = "FLUSH_DYNAMIC_FILTERS_DATA";

// Auction Bookings
export const FETCH_AUCTION_BOOKINGS = "FETCH_AUCTION_BOOKINGS";
export const CLEAR_AUCTION_BOOKINGS = "CLEAR_AUCTION_BOOKINGS";
export const SET_AUCTION_BOOKINGS_LOADING = "SET_AUCTION_BOOKINGS_LOADING";

//Crm Follow Ups
export const FETCH_CRM_TASK_FOLLOW_UPS = "FETCH_CRM_FOLLOW_UPS";
export const UPDATE_CRM_TASK_FOLLOW_UPS = "UPDATE_CRM_TASK_FOLLOW_UPS";
export const UPDATE_TASK_FOLLOW_UP_STATUS = "UPDATE_TASK_FOLLOW_UP_STATUS";
export const SET_CRM_TASKS_FOLLOW_UPS_LOADING = "SET_CRM_TASKS_FOLLOW_UPS_LOADING";
export const FETCH_CRM_LEAD_FOLLOW_UPS = "FETCH_CRM_LEAD_FOLLOW_UPS";
export const UPDATE_CRM_LEAD_FOLLOW_UPS = "UPDATE_CRM_LEAD_FOLLOW_UPS";
export const UPDATE_LEAD_FOLLOW_UP_STATUS = "UPDATE_LEAD_FOLLOW_UP_STATUS";
export const SET_CRM_LEADS_FOLLOW_UPS_LOADING = "SET_CRM_LEADS_FOLLOW_UPS_LOADING";

// Crm KITT
export const FETCH_CRM_KITT_REPORTS = "FETCH_CRM_KITT_REPORTS";
export const UPDATE_CRM_KITT_REPORTS = "UPDATE_CRM_KITT_REPORTS";
export const CLEAR_CRM_KITT_REPORTS = "CLEAR_CRM_KITT_REPORTS";
export const FETCH_AUCTION_NEGOTIATIONS = "FETCH_AUCTION_NEGOTIATIONS";
export const UPDATE_AUCTION_NEGOTIATIONS = "UPDATE_AUCTION_NEGOTIATIONS";

// Call recordings
export const FETCH_CALL_RECORDINGS = "FETCH_CALL_RECORDINGS";

// CRM Chevron
export const FETCH_CHEVRON_OPTIONS = "FETCH_CHEVRON_OPTIONS";
export const SET_CHEVRON_OPTIONS_LOADING = "SET_CHEVRON_OPTIONS_LOADING";
export const FETCH_CHEVRON_DISPOSITIONS_DATA = "FETCH_CHEVRON_DISPOSITIONS_DATA";
export const SET_DISPOSITION_LOADING = "SET_DISPOSITION_LOADING";
export const FETCH_SINGLE_DISPOSITION_DATA = "FETCH_SINGLE_DISPOSITION_DATA";
export const FETCH_SINGLE_DISPOSITION_PAGINATED_DATA = "FETCH_SINGLE_DISPOSITION_PAGINATED_DATA";
export const SET_INITIAL_CHEVRON_DISPOSITIONS_DATA = "SET_INITIAL_CHEVRON_DISPOSITIONS_DATA";
export const FETCH_CHEVRON_USERS_DATA = "FETCH_CHEVRON_USERS_DATA";
export const SET_CHEVRON_USERS_LOADING = "SET_CHEVRON_USERS_LOADING";
export const SET_CALL_RECORDINGS_LOADING = "SET_CALL_RECORDINGS_LOADING";

// Reports
export const SET_VISUALIZATION = "SET_VISUALIZATION";
