import { Avatar, Chip, Close, CustomAlert, IF, Loader, Modal, Skeleton, Status } from "../common";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { fetchCrmBuyerLeadConnectsApi, fetchCRMLeadsApi, fetchLeadsOfPaymentTypes } from "../../helpers/ApiHelper";
import { getErrorString, getQueryString, getQueryStringFromArray, isEmptyObject } from "../../helpers/Util";

import { BtnDefaultState } from "../common/BtnDefaultState";
import FiltersWithoutQueryParams from "../common/filters/FiltersWithoutQueryParams";
import { Link, useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import addLeadIllustration from "../../assets/illustrations/add-lead-illustration.svg";
import { strings } from "../../constant/strings";
import CrmSaveLead from "../../pages/crm/CrmSaveLead";
import { CRM_LEAD_NEW_PATH, CRM_LEADS_PATH } from "../../constant/appPaths";
import RenderPrivateField from "../common/RenderPrivateField/RenderPrivateField";
import { extractPayloadFromClientObj } from "../../helpers/ViewPhoneNumber";
import {
  LEAD_TYPES,
  MODELS_FOR_VIEW_PHONE_NUMBER_API,
  PHONE_NUMBER_CATEGORIZATION_PER_PAGE,
  SCROLLABLE_ELEMENTS_KEYS,
} from "constant/appConstants";
import { availableLeadStatuses } from "../../constant/lists";
import EmptyState from "../common/EmptyState/EmptyState";

const SelectLeadModal = props => {
  const { showAddLead = true, isViewable = true, showDetail = true, paymentTypeLeads = {} } = props;
  const [selected, setSelected] = useState(null);
  const [addLeadLoading, setAddLeadLoading] = useState(false);
  const [filtersObject, setFiltersObject] = useState({ limit: 10 });
  const history = useHistory();

  const [visible, setVisible] = useState(false);

  const [list, setList] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [leadForm, setLeadForm] = useState(false);
  // const [leadsLoading, setLeadsLoading] = useState(false);
  // const [leadsError, setLeadsError] = useState("");
  const [leadCategories, setLeadCategories] = useState([]);
  const [categoryTypes, setCategoryTypes] = useState([]);
  const crmLeadFormRef = useRef();

  useEffect(() => {
    if (props?.type && leadCategories?.length) {
      const category = leadCategories.find(category => category?.slug === props.type);
      const categoryId = category?.id;
      if (categoryId && filtersObject?.["f[category.id]"] !== categoryId) {
        setFiltersObject(prev => ({ ...prev, "f[category.id]": categoryId }));
      }
    }
  }, [leadCategories?.length, visible]);

  useEffect(() => {
    let query = getQueryString(filtersObject);
    if (props.removeLostLead && !props?.onlyInProgressLeads) {
      query = `${query}&${getQueryStringFromArray("f[status.slug][]", availableLeadStatuses)}`;
    } else {
      query = `${query}&f[status.slug][]=in-progress`;
    }
    if (!isEmptyObject(paymentTypeLeads)) {
      if (visible) {
        // if (filtersObject?.hasOwnProperty("f[category.id]")) {
        fetchPaymentTypeLeadsData(query);
        // }
        setList(paymentTypeLeads?.crm_leads);
        setLeadCategories(paymentTypeLeads?.crm_lead_category_types);
      }
    } else {
      visible && fetchCrmLeadData(query);
    }
  }, [filtersObject, visible]);

  const fetchPaymentTypeLeadsData = async params => {
    setList([]);
    setLoading(true);
    setError(null);
    const response = await fetchLeadsOfPaymentTypes(props.paymentId, props?.inventoryId, params);
    if (response.success) {
      setLoading(false);
      setList(response.crm_leads);
      setLeadCategories(response.crm_lead_category_types);
    } else {
      setError(getErrorString(response));
    }
  };

  const getOnScrollElement = useCallback(() => {
    return document.getElementById(SCROLLABLE_ELEMENTS_KEYS.MODAL_TABLE_KEY);
  }, [list]);

  const fetchCrmLeadData = async params => {
    setLoading(true);
    setError(null);
    let response, buyerLeadsResponse;
    if (props.fromCrmPayment) {
      [response, buyerLeadsResponse] = await Promise.all([
        fetchCRMLeadsApi(params),
        fetchCrmBuyerLeadConnectsApi(props.inventoryId),
      ]);
      if (
        !filtersObject?.hasOwnProperty("f[category.id]") ||
        filtersObject?.["f[category.id]"] === leadCategories?.find(e => e?.slug === LEAD_TYPES.BUYER)?.id
      ) {
        response.crm_leads = buyerLeadsResponse.buyer_leads;
      }
    } else {
      response = await fetchCRMLeadsApi(params);
    }

    if (response) {
      if (response.success) {
        setList(response.crm_leads);
        setLeadCategories(response.categories);
        response.category_types && setCategoryTypes(response.category_types);
      } else {
        setError(getErrorString(response));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!props.currentValue) {
      setSelected(null);
    }
  }, [props.currentValue]);

  const onAddLead = () => {
    // fetchLead();
    history.push({
      pathname: `${CRM_LEAD_NEW_PATH}`,
      state: {
        type: "new-lead-from-task",
        values: props.values,
      },
    });
    //setLeadForm(true);
  };

  const renderHeader = () => (
    <tr>
      <th></th>
      <th>{strings.ref}</th>
      <th>{strings.name}</th>
      <th>{strings.phone}</th>
      <th>{strings.status}</th>
      <th>{strings.lead_type}</th>
      <th>{strings.lead_category}</th>
    </tr>
  );

  const renderBody = item => (
    <tr
      onClick={() => {
        setSelected(item);
        props.onSelect && props.onSelect(item);
        setVisible(false);
      }}
      key={item?.id}
    >
      <td>
        <label style={{ top: "-8px", left: "10px" }}></label>
      </td>
      <td scope="row">{item.reference_number}</td>
      <td>
        <Avatar name={item?.client?.name} />
      </td>
      {/* <td>{item?.client?.phone}</td> */}
      <td>
        <div
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <RenderPrivateField
            {...extractPayloadFromClientObj({ item: item?.client })}
            associatedType={MODELS_FOR_VIEW_PHONE_NUMBER_API.LEAD_PHONE_KEY}
            component={PHONE_NUMBER_CATEGORIZATION_PER_PAGE.TASK.LEAD_MODAL}
            associatedId={item?.id}
            dropDownContainer="table"
            onScrollElement={getOnScrollElement()}
          />
        </div>
      </td>
      <td>
        <Status {...item.status} />
      </td>
      <td>{item?.category?.name}</td>
      <td>{item?.category_type?.name}</td>
    </tr>
  );

  const renderSkeleton = item => (
    <tr key={item}>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Avatar name={item?.client?.name} loading={loading} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Status loading={loading} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
    </tr>
  );

  const getTitle = () => {
    const name = selected
      ? `${selected?.reference_number} - ${selected?.client?.name ? selected?.client?.name : selected?.name}`
      : `${props.currentValue?.reference_number} - ${
          props.currentValue?.client?.name ? props.currentValue?.client?.name : props.currentValue?.name
        }`;
    return name;
  };

  const addLead = () => (
    <div className="d-flex justify-content-center flex-column align-items-center p-24">
      <figure>{<img src={addLeadIllustration} />}</figure>
      <strong> {strings.no_result_found}</strong>
      <div>{strings.create_new_lead}</div>
      <button className="btn btn-outline-primary btn-pill btn-sm" style={{ marginTop: 10 }} onClick={onAddLead}>
        {strings.add_lead}
      </button>
    </div>
  );

  const renderFilters = () => {
    return (
      <FiltersWithoutQueryParams
        list={[
          {
            sizeratio: 1,
            label: strings.ref,
            type: "input",
            key: "f[reference_number]",
            isRefNumber: true,
          },
          {
            sizeratio: 1,
            label: strings.name,
            type: "input",
            key: "s[client.name]",
          },
          {
            sizeratio: 1,
            label: strings.phone,
            type: "input",
            key: "f[client.phone]",
          },
          {
            sizeratio: 2,
            label: strings.lead_category,
            type: "typeahead",
            key: "f[category.id]",
            list: leadCategories,
          },
          {
            sizeratio: 2,
            label: strings.category_type,
            type: "typeahead",
            key: "f[category_type.id]",
            list: categoryTypes,
          },
        ]}
        filtersObject={filtersObject}
        setFiltersObject={setFiltersObject}
        containerClass="m-t-20"
      />
    );
  };

  const renderChipAndButton = () => {
    return (
      <>
        <IF condition={selected || props.currentValue}>
          <div className={`d-flex align-items-center ${!props.fromEdit ? "hover-pointer" : ""}`}>
            <Chip
              disabled={props.isEditable === false}
              containerClassName="pr-0"
              onClick={() => props.editable && setVisible(true)}
              title={getTitle()}
              onClose={() => {
                props.onDeselect();
                setSelected(null);
              }}
              canClose={!props.fromEdit && props.fromCrmPayment && props.editable}
              // key={selected?.id}
            />
            {props.fromEdit && props.currentValue && showDetail && (
              <Link className="m-t-10 txt-info hover-pointer" to={`/crm/${props.detailsRoute}`}>
                {strings.view_details}
              </Link>
            )}
          </div>
        </IF>
        <IF condition={!selected && !props.currentValue}>
          <BtnDefaultState
            disabled={props.isEditable === false}
            onClick={() => setVisible(true)}
            className="custom-field-state b-r-6"
          />
        </IF>
      </>
    );
  };

  const renderModal = () => {
    const filteredList = list
      ? list.filter(listItem => {
          if (filtersObject && Object.keys(filtersObject).length === 0) return true;
          if (
            filtersObject?.["f[client.phone]"] &&
            !listItem?.client?.phone.includes(filtersObject?.["f[client.phone]"])
          )
            return false;
          if (
            filtersObject?.["f[reference_number]"] &&
            !String(listItem?.reference_number).includes(filtersObject?.["f[reference_number]"])
          )
            return false;
          if (
            filtersObject?.["s[client.name]"] &&
            !listItem?.client?.name.toLowerCase().includes(filtersObject?.["s[client.name]"].toLowerCase())
          )
            return false;
          return true;
        })
      : null;

    return (
      <Modal
        toggle={() => {
          setVisible(!visible);
        }}
        open={visible}
        className="modal-lg deposit_model"
        body={() => {
          return (
            <>
              <div className="d-flex w-100 align-items-center justify-content-between">
                <h4>{leadForm ? strings.add_lead : props.title}</h4>
                {!leadForm && showAddLead && (
                  <button
                    className="btn btn-outline-primary btn-pill btn-sm"
                    style={{ marginRight: 44 }}
                    onClick={onAddLead}
                  >
                    {strings.add_lead}
                  </button>
                )}
                <Close
                  style={{ right: "30px" }}
                  isTransparent
                  onClick={() => {
                    setVisible(false);
                  }}
                />
              </div>
              {leadForm ? (
                <CrmSaveLead
                  crmLeadModal={true}
                  inventory={props.inventory}
                  onSuccess={e => {
                    setSelected(e);
                    props.onSelect && props.onSelect(e);
                    setVisible(false);
                  }}
                  refProp={crmLeadFormRef}
                  toggleAddLeadLoading={setAddLeadLoading}
                />
              ) : (
                // )
                <>
                  {renderFilters()}
                  <div
                    id={SCROLLABLE_ELEMENTS_KEYS.MODAL_TABLE_KEY}
                    className={`${SCROLLABLE_ELEMENTS_KEYS.MODAL_TABLE_KEY} inventory-modal-table`}
                  >
                    {error ? (
                      <CustomAlert message={error} className="mx-3" />
                    ) : (
                      <Fragment>
                        <Table>
                          <thead>{renderHeader()}</thead>
                          <tbody>
                            {loading
                              ? [1, 2, 3, 4, 5, 6].map(renderSkeleton)
                              : filteredList?.length > 0 && filteredList?.map(item => renderBody(item))}
                          </tbody>
                        </Table>
                        {!loading && !filteredList?.length && <EmptyState maxWidth="350" style={{ height: "100%" }} />}
                      </Fragment>
                    )}
                    {!filteredList?.length && showAddLead && addLead()}
                  </div>
                </>
              )}
            </>
          );
        }}
        actions={
          leadForm
            ? [
                {
                  label: strings.cancel,
                  onClick: () => {
                    setVisible(false);
                  },
                  color: "secondary",
                  className: "modal__cancel-btn btn-pill",
                },
                {
                  label: strings.create,
                  onClick: () => {
                    crmLeadFormRef && crmLeadFormRef.current && crmLeadFormRef.current.submitForm();
                  },
                  color: "primary",
                  className: "modal__cr-btn btn-pill",
                  loading: addLeadLoading,
                  disabled: addLeadLoading,
                },
              ]
            : [
                {
                  label: strings.cancel,
                  onClick: () => {
                    setVisible(false);
                  },
                  color: "secondary",
                  className: "modal__cancel-btn btn-pill",
                },
              ]
        }
        onClosed={e => {
          setLeadForm(false);
          setFiltersObject({ limit: 10 });
          setLeadCategories([]);
        }}
      />
    );
  };

  if (!isViewable) return null;

  return (
    <div className="form-group row d-flex flex-row align-items-center">
      <label className={`col-sm-3 col-form-label ${props.textRight ? "text-right" : ""}`}>
        {props.loadingSkeleton ? <Skeleton height={20} width={210} /> : props.title}
      </label>

      <div className="col-sm-9 d-flex flex-column align-items-start">
        {props.loadingSkeleton ? (
          <label className={`col-sm-3 col-form-label ${props.textRight ? "text-right" : ""}`}>
            <Skeleton height={30} margin />
          </label>
        ) : (
          renderChipAndButton()
        )}
        {!!props.errorMsg && <p className="invalid-error">{props.errorMsg}</p>}
      </div>
      {renderModal()}
    </div>
  );
};

export default SelectLeadModal;
