import React, { useEffect } from "react";
import { strings } from "../../constant/strings";
import { FormInputGroup, IF, Close, AsyncSelect } from "../common";
import SelectField from "../common/SelectField";
import { fetchDynamicFieldsApi } from "../../helpers/ApiHelper";
import cx from "clsx";

const DropDownFields = ({
  values,
  setFieldValue,
  errors,
  dynamicAttribute,
  submitCount,
  setFieldError,
  dynamicAttributes,
  supportedSectionsList,
  modelId,
  outerClassName,
}) => {
  useEffect(() => {
    !dynamicAttribute && setFieldValue("options", []);
  }, [values.fieldDataType]);

  const makeDataForDynamicSection = data =>
    data.map((e, i) => ({
      ...e,
      label: e.name,
      slug: e.id,
    })) || [];

  const curriedFunc = modelId => {
    return queryParams => fetchDynamicFieldsApi(modelId, queryParams);
  };

  return (
    <>
      {values.options.map((e, i) => {
        return (
          <>
            <IF condition={!e._destroy}>
              <div className={cx("add-dropdown-wrap", outerClassName)} key={i}>
                <FormInputGroup
                  key={i}
                  label={strings.option}
                  placeholder={strings.enter_option_ph}
                  onChange={e => {
                    const data = values.options;
                    data[i] = { ...data[i], field_label: e.target.value };
                    setFieldValue("options", data, false);
                  }}
                  value={e.field_label}
                  required
                  errorMsg={!e.field_label.length && errors?.options?.[i] && errors.options[i]?.field_label}
                />
                <Close
                  onClick={() => {
                    const data = [...values.options];
                    e.id ? data.splice(i, 1, { ...data[i], _destroy: 1 }) : data.splice(i, 1);
                    setFieldValue("options", [...data], false);
                  }}
                  // className="address-color p-l-0"
                />

                {/* <button
                onClick={() => {
                  const data = [...values.options];
                  e.id ? data.splice(i, 1, { ...data[i], _destroy: 1 }) : data.splice(i, 1);

                  setFieldValue("options", [...data], false);
                }}
                className="address-color btn btn-link p-l-0"
                type="button"
              >
                - {strings.remove_option}
              </button> */}
                <SelectField
                  className="flex-grow-1 m-0"
                  label={strings.section}
                  placeholder={strings.select_section}
                  onChange={selectedSection => {
                    const data = values.optionSection;
                    data[i] = selectedSection;
                    setFieldValue("optionSection", data);
                  }}
                  // errorMsg={errorMsg}
                  formatOptionLabel={option => option.label}
                  required
                  isSearchable
                  // isEditable={props.isEditable}
                  options={makeDataForDynamicSection(supportedSectionsList)}
                  isMulti={false}
                  containerClassName={"w-100"}
                  columnsRightSection={"9"}
                />
                <AsyncSelect
                  cache={false}
                  cacheUniqs={[values?.optionSection[0]]}
                  className="flex-grow-1 m-0"
                  label={strings.children}
                  selected={values?.options[i]?.dependent_fields && [...values?.options[i]?.dependent_fields]}
                  listApi={curriedFunc(modelId)}
                  listProperty={"dynamic_fields"}
                  isMulti={true}
                  queryKey={"q[field_label_start]"}
                  extraParams={
                    !!values?.optionSection[i]?.id && {
                      "q[dynamic_field_section_id_in][]": values?.optionSection[i]?.id,
                    }
                  }
                  // errorMsg={errors.fieldRoles && touched.fieldRoles && errors.fieldRoles}
                  onChange={selectedChildren => {
                    const data = values.options;
                    data[i] = { ...data[i], dependent_fields: selectedChildren };
                    setFieldValue("options", data, false);
                  }}
                  formatOptionLabel={option => option.field_label}
                  // onBlur={handleBlur("fieldRoles")}
                  placeholder={strings.select_children}
                  horizontal={false}
                />
              </div>
            </IF>
          </>
        );
      })}

      <div
        className="btn btn-pill btn-outline-primary btn-sm d-inline-flex align-items-center mb-3 py-2 px-3"
        onClick={e => {
          setFieldValue("options", [...values.options, { field_label: "" }], false);
          setFieldError("options", "");
        }}
      >
        + {strings.add_option}
        {/* {values.options.reduce((acc, item) => {
          if (!item._destroy) {
            acc += 1;
          }
          return acc;
        }, 0)} */}
      </div>
      {typeof errors.options === "string" && !!submitCount && <p className="invalid-error ">{errors.options}</p>}
    </>
  );
};

export default DropDownFields;
