import { Avatar, Loader, Skeleton, Toast } from "../common";
import ChatterInput from "./ChatterInput";
import React, { Fragment, useState, useRef } from "react";
import { Watchers } from ".";
import { useDispatch } from "react-redux";
import { crmLeadUpdateWatchers } from "../../actions/crm/crm-detail.action";
import { clone, getTimeDateString, isFieldRestricted, mergeArrays } from "../../helpers/Util";
import { crmLeadWatchersApi, fetchComments } from "../../helpers/ApiHelper";
import Comment from "../Comment";
import { COMMENTABLE_TYPES } from "constant/appConstants";
import { strings } from "../../constant/strings";
import InfiniteScroll from "react-infinite-scroll-component";
import useMountEffect from "../../hooks/useMountEffect";

const ListSkeleton = () => {
  return [1, 2, 3, 4, 5, 6, 7].map(index => (
    <div className="d-flex align-items-center mt-4" key={index}>
      <Skeleton height={25} width={25} style={{ borderRadius: "50%", marginRight: "10px" }} />

      <div className="d-flex align-items-center justify-content-between">
        <div>
          <Skeleton height={21} width={100} style={{ marginBotton: ".25rem", display: "block" }} />
          <Skeleton height={21} width={Math.random() * (400 - 150) + 150} />
        </div>
      </div>
    </div>
  ));
};

const CommentContainer = props => {
  const { ID, user = [], createdAt, taggedUsers = [], comment } = props;
  return (
    <div className="d-flex flex-row align-items-start m-b-25" key={ID}>
      {!isFieldRestricted(user) && <Avatar name={user?.first_name} imgSrc={user?.picture?.url} avatarOnly />}
      <div className="m-l-10">
        <div className="f-w-600">
          {!isFieldRestricted(user) && user?.name}
          {!isFieldRestricted(createdAt) && (
            <span className="f-w-400 text-muted"> - {getTimeDateString(createdAt)}</span>
          )}
        </div>
        {!isFieldRestricted(comment) && <Comment taggedUsers={taggedUsers} comment={comment} />}
      </div>
    </div>
  );
};

const Chatter = ({ detailId, type, watchers = null, commentableType, addCommentPermission = () => false }) => {
  const scrollContainerRef = useRef();
  const [commentsData, setCommentsData] = useState({
    commentsList: [],
    pagination: {},
    loading: false,
  });

  const { commentsList, loading, pagination } = commentsData;

  const dispatch = useDispatch();
  const setComments = comment => {
    if (isFieldRestricted(commentsList)) return;
    const commentsSnapShot = clone(commentsList);
    commentsSnapShot.unshift(comment);

    setCommentsData(prev => ({
      ...prev,
      commentsList: commentsSnapShot,
    }));

    // scrollToTop();
  };

  const shouldRenderWatchers = !!watchers && !isFieldRestricted(watchers);
  const shouldRenderComments = !isFieldRestricted(commentsList) && !!commentsList?.length;

  const setNewComments = ({ newComments = [], pagination = {} }) => {
    setCommentsData(prev => ({
      ...prev,
      commentsList: mergeArrays(commentsList, newComments),
      pagination,
    }));
  };

  const getComments = async (page = 1) => {
    try {
      const params = `&page=${page}&limit=${10}`;
      const response = await fetchComments({
        commentableId: detailId,
        commentableType,
        params,
      });
      return response;
    } catch (error) {
      Toast.error(strings.something_went_wrong);
    }
  };

  const fetchMoreComments = async () => {
    const nextPage = pagination?.next_page;
    if (!nextPage) return;

    const response = await getComments(nextPage);
    if (response?.success) {
      setNewComments({
        newComments: response?.comments ?? [],
        pagination: response.pagination,
      });
    }
  };
  const getCommentsForInitialLoad = async () => {
    try {
      setCommentsData(prev => ({
        ...prev,
        loading: true,
      }));
      const response = await getComments();

      if (response?.success) {
        setNewComments({
          newComments: response?.comments ?? [],
          pagination: response.pagination,
        });
      }
    } catch (error) {
      console.log("error ===<> ", error);
    } finally {
      setCommentsData(prev => ({
        ...prev,
        loading: false,
      }));
    }
  };

  useMountEffect(getCommentsForInitialLoad);

  const flatListStyle = {
    ...{ maxHeight: "410px", width: "100%", overflowY: "scroll" },
  };

  const scrollToTop = () => {
    // Scroll to the top smoothly
    if (scrollContainerRef.current) {
      // scrollContainerRef.current.scrollIntoView();
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <ChatterInput
        addCommentPermission={addCommentPermission}
        setComments={setComments}
        detailId={detailId}
        type={type}
      />

      {shouldRenderWatchers && (
        <Watchers
          id={detailId}
          watchers={watchers}
          className="m-b-20"
          type="CrmLead"
          saveWatcherApi={crmLeadWatchersApi}
          onSuccess={list => dispatch(crmLeadUpdateWatchers(list))}
        />
      )}
      {loading ? (
        <ListSkeleton />
      ) : (
        shouldRenderComments && (
          <div id="scrollableDiv">
            <InfiniteScroll
              dataLength={commentsList.length}
              hasMore={!!pagination?.next_page}
              next={fetchMoreComments}
              loader={<Loader />}
              scrollableTarget="scrollableDiv"
              scrollThreshold={0.9}
              height={"100%"}
              style={flatListStyle}
            >
              <>
                {commentsList.map((item, index) => (
                  <>
                    <Fragment key={item?.id || `item-${index}`}>
                      <CommentContainer
                        ID={item.id}
                        user={item.user}
                        taggedUsers={item?.tagged_users ?? []}
                        comment={item.comment}
                        createdAt={item.created_at}
                      />
                    </Fragment>
                  </>
                ))}
              </>
            </InfiniteScroll>
          </div>
        )
      )}
    </>
  );
};

export default Chatter;
