import React from "react";
import cx from "clsx";
import styles from "./ChevronView.module.scss";
import IF from "../IF";
import EmptyState from "../EmptyState/EmptyState";
import { NoLeadFoundBanner } from "../../svg-icon/svg-icons";
import ChevronUsersSidebar from "./users-sidebar/ChevronUsersSidebar";
import ChevronLeads from "./chevron-leads/ChevronLeads";
import { checkAllFiltersApplied } from "../../../helpers/CrmLeadsChevronUtils";
import { PERMISSIONS } from "constant/permissions";
import { getPermission } from "helpers/Util";

const ChevronView = ({ rootFiltersObj }) => {
  const isChevronUsersPermitted = getPermission(
    PERMISSIONS.CRMLEAD.NAME,
    PERMISSIONS.CRMLEAD.ACTIONS.CHEVRON_VIEW_USERS
  );
  const leadsContainerStyle = !isChevronUsersPermitted
    ? {
        marginInlineStart: "0px",
      }
    : {};

  if (!checkAllFiltersApplied(rootFiltersObj)) {
    return (
      <EmptyState
        image={<NoLeadFoundBanner />}
        title="No Leads Found"
        message="Select Category Type, Area & Appointment Date to view leads."
      />
    );
  }
  return (
    <div className={cx(styles.chevronMaincontainer, "d-flex flex-row mb-3 p-relative")}>
      <IF condition={isChevronUsersPermitted}>
        <ChevronUsersSidebar />
      </IF>
      <ChevronLeads leadsContainerStyle={leadsContainerStyle} />
    </div>
  );
};

export default ChevronView;
