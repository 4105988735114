import { ButtonLoading, IF, SelectionListTypeHead } from "../index";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useFetch } from "../../../hooks";
import {
  isFieldDynamic,
  makeValuesObject,
  setInitialValuesForDynamicFields,
  validateFieldsData,
} from "./DynamicFieldHelpers";
import { renderDynamicFields } from "./DynamicFieldComponents";
import { Edit2 } from "react-feather";
import { checkUndefinedApiParams, getErrorString, isFieldRestricted } from "../../../helpers/Util";
import { useSelector } from "react-redux";
import { fetchDynamicFieldsApi } from "../../../helpers/ApiHelper";
import Toast from "../Toast";
import { strings } from "../../../constant/strings";

const DynamicFormFieldTask = forwardRef((props, ref) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleChange,
    handleBlur,
    setValues,
    horizontal,
    fieldKey,
    fieldErrorMsg,
    onChange,
    options,
    isLoading,
    isError,
    server,
    serverProps = {},
    current,
    children,
    textRight,
    selectedSlug,
    dynamicFieldsRef,
    setTaskDynamicAttributes,
    setTaskTypeDynamicAttributes,
    disabled = false,
    postValues,
    FieldsParent,
    isEditable = null,
    activeColumnsPermittedAttributes,
    prefillTaskLayout = false,
    open,
    isAdditionalFieldsUpdationLoading = false,
    ...rest
  } = props;

  const { controller, fetchCall, params } = serverProps;
  const { data, loading, error } = useFetch(
    server && (!disabled || selectedSlug) ? fetchCall : undefined,
    [`?limit=1000${params ? "&" + params : ""}`],
    [disabled]
  );

  const dataSuccess = data?.success;
  const taskTypeSlug = prefillTaskLayout?.payload?.task_type_slug;
  useEffect(() => {
    if (prefillTaskLayout.payload && dataSuccess && open) {
      const dataOptions = server ? data?.[controller] || [] : options;
      const selected = dataOptions.find(option => option.slug === prefillTaskLayout.payload.task_type_slug);
      onTaskTypeChange([selected]);
    }
  }, [taskTypeSlug, dataSuccess, open]);

  const timezone = useSelector(content => content.Configs.tenant.country.timezone);

  const isRestricted = () => {
    if (current) {
      return isFieldRestricted(current[fieldKey]);
    } else {
      return values[fieldKey] && isFieldRestricted(values[fieldKey]);
    }
  };

  useEffect(() => {
    if (!isRestricted()) {
      if (!disabled || selectedSlug) {
        data?.[controller]?.length && setFormInitialValues();
      } else {
        current && setFormInitialValues();
      }
    }
  }, [data?.[controller], current, selectedSlug]);

  useEffect(() => {
    values[fieldKey] && fetchData(values[fieldKey]);
  }, [values[fieldKey]?.id, values.taskReOpened]);

  useEffect(() => {
    if (!isRestricted() && values?.[fieldKey]?.dynamic_fields?.length) {
      setFormInitialValues(values?.[fieldKey]);
    }
  }, [values?.[fieldKey]?.dynamic_fields?.length]);

  useEffect(() => {
    if (values[fieldKey] && !current) {
      Object.keys(values).forEach(e => {
        if (isFieldDynamic(e, values?.[fieldKey]?.dynamic_model?.source)) {
          setFieldValue(e, undefined);
        }
      });
    }
  }, [values[fieldKey]?.id]);

  useImperativeHandle(ref, () => ({
    validateFields,
    setInitialValues() {
      if ((disabled || data?.[controller]?.length) && (current || selectedSlug) && !isRestricted()) {
        setFormInitialValues();
      }
    },
    clearField() {
      inputRef.current && inputRef.current.clear();
    },
  }));

  let inputRef = useRef();

  const setFormInitialValues = currentValue => {
    setInitialValuesForDynamicFields(
      currentValue,
      current,
      currentValue?.dynamic_model?.source,
      setFieldValue,
      fieldKey,
      timezone
    );
  };

  const validateFields = cb => {
    const valuesObj = {};
    const currentValue = values[fieldKey];
    let error;
    if (currentValue) {
      const { hasError, errorData } = validateFieldsData(
        values,
        currentValue,
        currentValue.dynamic_model.source,
        setFieldError,
        activeColumnsPermittedAttributes
      );
      error = hasError;
      if (!error) {
        valuesObj[values[fieldKey].dynamic_model.source] = makeValuesObject(
          values,
          values[fieldKey].dynamic_model.source,
          timezone,
          activeColumnsPermittedAttributes
        );
      }
    } else {
      error = true;
      setFieldError(fieldKey, fieldErrorMsg);
    }
    cb(valuesObj, error);
  };

  const fetchData = async value => {
    if (!checkUndefinedApiParams(value?.dynamic_model?.id, "dynamic model")) return;
    const res = await fetchDynamicFieldsApi(value?.dynamic_model?.id, "?limit=1000");
    if (res.success === true) {
      const fieldData = { ...(values[fieldKey] || {}), dynamic_fields: res.dynamic_fields };
      setFieldValue(fieldKey, fieldData);
    } else {
      Toast.error(getErrorString(res));
    }
  };

  const renderFields =
    values?.[fieldKey] &&
    !isFieldRestricted(values?.[fieldKey]?.dynamic_fields) &&
    renderDynamicFields(
      values[fieldKey]?.dynamic_fields,
      props,
      values?.[fieldKey]?.dynamic_model?.source,
      null,
      activeColumnsPermittedAttributes
    );

  const onTaskTypeChange = async selected => {
    const [value] = selected;
    if (value) {
      setFieldValue(fieldKey, value);
      onChange && onChange(value);
    } else {
      setFieldValue(fieldKey, null);
    }
  };

  const onUpdateClick = () => {
    validateFields((valuesObj, error) => setTaskTypeDynamicAttributes({ valuesObj, error }));
    dynamicFieldsRef &&
      dynamicFieldsRef.current &&
      dynamicFieldsRef.current.validateFields((valuesObj, error) => setTaskDynamicAttributes({ valuesObj, error }));
  };
  const taskFormAdditionalFields = () => {
    const isReOpenedTask = current?.status?.slug === "completed" && props?.taskReOpened;
    const showEditIcon = () => {
      if (isReOpenedTask || (!!current && !props?.editDynamicFields && current?.status?.slug !== "completed")) {
        return true;
      } else {
        return false;
      }
    };
    return (
      <div className="additional-field-list b-r-6 p-25 m-b-45 position-relative">
        {showEditIcon() && (
          <button className="circle__site-icon icon-right icon-primary">
            <Edit2
              onClick={() => {
                props.setEditDynamicFields(true);
              }}
            />
          </button>
        )}
        <h5 className="heading m-b-15 ">Additional Fields</h5>

        <div className={`${current && !props?.editDynamicFields ? "pointer-events-none" : ""}`}>
          {renderFields}
          {children?.length && children?.filter(e => e?.props?.id === "dynamicFields")[0]}
        </div>
        <IF condition={(values[fieldKey]?.dynamic_fields?.length || !!values?.dynamicAttributes?.length) && current}>
          <div className="d-flex justify-content-end">
            {props?.editDynamicFields && (
              <ButtonLoading type="button" loading={isAdditionalFieldsUpdationLoading} onClick={onUpdateClick}>
                {strings.update}
              </ButtonLoading>
            )}
          </div>
        </IF>
      </div>
    );
  };

  return (
    <>
      {!isRestricted() && (
        <SelectionListTypeHead
          selected={values[fieldKey] ? [{ ...values[fieldKey] }] : undefined}
          onChange={onTaskTypeChange}
          errorMsg={errors[fieldKey] && touched[fieldKey] && errors[fieldKey]}
          clearButton={undefined}
          horizontal={horizontal}
          isEditable={isEditable}
          options={server ? data?.[controller] || [] : options}
          labelKey="name"
          isLoading={server ? loading : isLoading}
          isError={server ? error : isError}
          inputRef={inputRef}
          textRight={textRight}
          disabled={isEditable === false || disabled}
          caret
          {...rest}
        />
      )}
      {children?.length && children?.filter(e => e?.props?.id !== "dynamicFields")}
      <IF
        condition={
          (values[fieldKey] && values[fieldKey]?.dynamic_fields?.length) || !!values?.dynamicAttributes?.length
        }
      >
        <IF condition={FieldsParent} ELSE={() => renderFields}>
          {props.taskForm ? taskFormAdditionalFields() : <> {renderFields} </>}
        </IF>
      </IF>
    </>
  );
});

export default DynamicFormFieldTask;
