import * as yup from "yup";

import { Formik } from "formik";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useSelector } from "react-redux";
import { strings } from "../../constant/strings";
import { addUserApi, updateUserApi } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";
import { nameValidation, phoneValidation } from "../../helpers/validations";
import { selectDesignations } from "../../reducers/login/selectors";
import { CheckboxComponent, CustomAlert, FormInput, Toast } from "../common";
import DesignationSelect from "../common/Select/DesignationSelect";
import ReporteeSelect from "../common/Select/ReporteeSelect";

const AddUserForm = forwardRef(
  (
    {
      roles,
      addUser,
      closeDialog,
      asUpdate,
      updateData,
      updateUser,
      fetchUsers,
      toggleAddUserLoading,
      phoneRegex,
      phonePlaceholder,
    },
    ref
  ) => {
    let formikRef = useRef();

    useImperativeHandle(ref, () => ({
      submitForm() {
        formikRef.current.submitForm();
      },
    }));

    const designationsAvailableToUser = useSelector(selectDesignations);
    const designationsAreAvailable = () => designationsAvailableToUser?.length > 0;

    const isDesignationRootLevel = designation => {
      return designation && designation?.parent_id === null;
    };
    const validationSchema = () =>
      yup.object().shape({
        firstName: nameValidation("First name"),
        lastName: nameValidation("Last name"),
        email: yup.string().required(strings.type_email).email(strings.type_valid_email),
        phone: phoneValidation(phoneRegex),
        agentExtension: yup.number().typeError(strings.enter_number_message),

        ...(designationsAreAvailable()
          ? {
              designation: yup.object().nullable().required(strings.provide_designation_msg),
              reportee: yup.object().when("designation", {
                is: designation => isDesignationRootLevel(designation),
                then: yup.object().nullable(),
                otherwise: yup.object().nullable().required(strings.provide_user_reportee_msg),
              }),
            }
          : {}),
      });
    const getInitialValues = () =>
      asUpdate
        ? {
            firstName: updateData.first_name,
            lastName: updateData.last_name,
            email: updateData.email,
            phone: updateData.phone,
            designation: updateData?.designation,
            reportee: updateData?.reportee,
            overBooking: updateData?.enable_overbooking,
            agentExtension: updateData?.user_setting?.extension_number,
            agentId: updateData?.user_setting?.id,
            autoApproval: updateData?.auto_approval,
          }
        : {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            designation: null,
            reportee: "",
            overBooking: false,
            agentExtension: "",
            autoApproval: false,
          };

    const createHierarchyAttributesObject = (values, asUpdate, updateData) => {
      const isDesignationRoot = isDesignationRootLevel(values?.designation);
      const designationId = values?.designation?.id;
      const ID = updateData?.hierarchy?.id;
      const reporteeId = values?.reportee?.id;
      const parentId = values?.reportee?.hierarchy?.id;

      const hierarchy_attributes = {
        designation_id: designationId,
        reportee_id: isDesignationRoot ? null : reporteeId,
        parent_id: isDesignationRoot ? null : parentId,
      };
      if (asUpdate) hierarchy_attributes.id = ID;
      return hierarchy_attributes;
    };

    const onSubmit = async (values, { setStatus }) => {
      try {
        setStatus({ message: "" });
        let userData = {
          ...(asUpdate ? { id: updateData.id } : {}),
          phone: values.phone,
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email || "",
          is_verified: true,
          enable_overbooking: values.overBooking,
          user_setting_attributes: {
            extension_number: !!values?.agentExtension ? values?.agentExtension : "",
          },
          auto_approval: values.autoApproval,
          ...(designationsAreAvailable()
            ? { hierarchy_attributes: createHierarchyAttributesObject(values, asUpdate, updateData) }
            : {}),
        };

        if (asUpdate && !!values?.agentId) {
          userData = {
            ...userData,
            user_setting_attributes: {
              ...userData.user_setting_attributes,
              id: values.agentId,
            },
          };
        }

        toggleAddUserLoading(true);
        if (asUpdate) {
          const response = await updateUserApi(userData);
          if (response) {
            toggleAddUserLoading(false);
            if (response?.user?.id) {
              updateUser(response.user);
              closeDialog();
            } else {
              setStatus({
                message: getErrorString(response),
              });
            }
          }
        } else {
          const response = await addUserApi(userData);
          if (response) {
            toggleAddUserLoading(false);
            if (response?.user?.id) {
              addUser(response.user);
              closeDialog();
            } else {
              setStatus({
                message: getErrorString(response),
              });
            }
          }
        }
      } catch (error) {
        toggleAddUserLoading(false);
        Toast.error(getErrorString(error));
      }
    };

    return (
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {formikProps => {
          const { values, errors, touched, handleChange, handleBlur, status, setFieldValue } = formikProps;

          const handleDesignationChange = event => {
            setFieldValue("designation", event);
            setFieldValue("reportee", null);
          };

          return (
            <form className="form theme-form">
              <FormInput
                label={strings.first_name}
                placeholder={strings.first_name_ph}
                name="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                errorMsg={errors.firstName && touched.firstName && errors.firstName}
                required
              />

              <FormInput
                label={strings.last_name}
                placeholder={strings.last_name_ph}
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                errorMsg={errors.lastName && touched.lastName && errors.lastName}
                required
              />

              <FormInput
                label={strings.agent_extension}
                placeholder={strings.agent_extension}
                name="agentExtension"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.agentExtension}
                errorMsg={errors.agentExtension && touched.agentExtension && errors.agentExtension}
                required
                type={"number"}
              />

              <FormInput
                label={strings.email}
                placeholder={strings.email_ph}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                errorMsg={errors.email && touched.email && errors.email}
                required
              />

              <FormInput
                label={strings.phone}
                placeholder={phonePlaceholder}
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                errorMsg={errors.phone && touched.phone && errors.phone}
                required
              />

              {designationsAreAvailable() && (
                <>
                  <DesignationSelect
                    handleChange={handleDesignationChange}
                    value={values.designation}
                    errorMsg={errors.designation && touched.designation && errors.designation}
                  />

                  <ReporteeSelect
                    setFieldValue={formikProps.setFieldValue}
                    value={values?.reportee}
                    designation={values?.designation}
                    currentUserId={updateData?.id}
                    errorMsg={errors.reportee && touched.reportee && errors.reportee}
                    isDesignationRootLevel={isDesignationRootLevel}
                  />
                </>
              )}
              <div className="d-flex" style={{ gap: "10px" }}>
                <CheckboxComponent
                  label={"Over booking"}
                  checked={values.overBooking}
                  onChange={e => {
                    setFieldValue("overBooking", e.target.checked);
                  }}
                />
                <CheckboxComponent
                  label={"Auto Approval"}
                  checked={values.autoApproval}
                  onChange={e => {
                    setFieldValue("autoApproval", e.target.checked);
                  }}
                />
              </div>
              {status && status.message && <CustomAlert message={status.message} />}
            </form>
          );
        }}
      </Formik>
    );
  }
);

export default AddUserForm;
