import React, { useState } from "react";
import { updateCrmTaskReminders, updateTaskReminders, updateTasksReminders } from "../../../actions/crm";
import { deleteTaskReminderApi } from "../../../helpers/ApiHelper";
import {
  capitalize,
  getErrorString,
  getPermission,
  getTimeDateString,
  isFieldRestricted,
  setTimeZone,
} from "../../../helpers/Util";
import { Modal, Status, Toast } from "../../common";
import { BellIconFilled, DeleteIconFilled, EditIconFilled } from "../../svg-icon/svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "../../../constant/strings";
import { selectTimeZone } from "../../../reducers/general/selectors";

const ReminderCard = ({ reminder, taskId, setReminderModal, setTaskFieldValue, isEditTaskForm }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState("0px");
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const timeZone = useSelector(selectTimeZone);

  const { title, priority, date, notes, id, status } = reminder ?? {};

  const deleteTaskReminder = async () => {
    if (isEditTaskForm) {
      setLoading(true);
      const res = await deleteTaskReminderApi(taskId, { reminder_id: id });
      if (res.success) {
        dispatch(updateTaskReminders({ reminders: res.reminders }));
        dispatch(updateTasksReminders(taskId, res.reminders));
        Toast.success("Task Reminder deleted");
        setLoading(false);
      } else {
        setLoading(false);
        Toast.error(getErrorString(res));
      }
    } else {
      setTaskFieldValue("reminder", null);
    }
  };

  return (
    <>
      <Modal
        toggle={() => {
          setConfirmationDialog(!confirmationDialog);
        }}
        title={strings.delete_reminder}
        open={confirmationDialog}
        body={() => <div>Are you sure, you want to delete this reminder?</div>}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setConfirmationDialog(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
          {
            label: strings.done,
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: deleteTaskReminder,
            disabled: loading,
            loading: loading,
          },
        ]}
      />

      {!isFieldRestricted(reminder) && (
        <div
          onMouseEnter={() => {
            setWidth("15px");
          }}
          onMouseLeave={() => {
            setWidth("0px");
          }}
          style={{ border: "1px dashed #E6B341", borderRadius: "5px", backgroundColor: "#E6B3410A", width: "75%" }}
          className="p-2"
        >
          <div className="d-flex justify-content-between align-items-center mt-1 ml-2 mr-2">
            <div className="d-flex">
              {!isFieldRestricted(title) && (
                <>
                  <BellIconFilled fill={"#FDC13A"} />
                  <div
                    className="mr-3 ml-2"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {title}
                  </div>
                </>
              )}
            </div>
            {!isFieldRestricted(status?.name) && <Status {...status} />}
          </div>
          <div className="d-flex justify-content-between align-items-center mr-2 mt-1" style={{ marginLeft: "1.9rem" }}>
            {!isFieldRestricted(date) && (
              <div>
                <div
                  className="text-muted mb-1"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {getTimeDateString(setTimeZone(timeZone, date))}
                </div>
                {!isFieldRestricted(priority) && (
                  <div className="d-flex flex-row">
                    <div className="mr-1">{strings.priority}</div>
                    <span className="text-muted"> • </span>
                    <div className="text-muted ml-1">{priority}</div>
                  </div>
                )}
              </div>
            )}
            <div className="d-flex ">
              {getPermission("Task", "update_reminder") && (
                <div
                  className="mr-2"
                  style={{
                    display: "flex",
                    backgroundColor: "#F4F4F4",
                    height: "15px",
                    width: width,
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "width .2s ease",
                    cursor: "pointer",
                  }}
                >
                  <EditIconFilled
                    onClick={() => {
                      setReminderModal(true);
                    }}
                    fill={"blue"}
                  />
                </div>
              )}
              {getPermission("Task", "remove_reminder") && (
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#F4F4F4",
                    height: "15px",
                    width: width,
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "width .2s ease",
                    cursor: "pointer",
                  }}
                >
                  <DeleteIconFilled
                    onClick={() => {
                      setConfirmationDialog(true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReminderCard;
