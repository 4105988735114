import { Avatar, Skeleton } from "../common";
import React from "react";
import CustomSkeletonFields from "../common/CustomSkeletonFields";

const labelMargin = { marginLeft: "3vw" };
const fieldMargin = { marginLeft: "4vw" };

const LocationFormSkeleton = ({}) => {
  return (
    <div className={"location-form-skeleton"}>
      <div className="sidebar-overlay-header m-b-25"></div>
      <br></br>
      <AssignDateInputSkeleton />
      <br></br>
      <AssignDateInputSkeleton />
      <br></br>
      <SelectInventorySkeleton />
      <br></br>
      <FormInputSkeleton />
    </div>
  );
};

const FormInputSkeleton = () => {
  return (
    <CustomSkeletonFields
      label={{ style: labelMargin, height: 30, width: 70 }}
      field={{ style: fieldMargin, height: 90, width: 500 }}
    />
  );
};

const AddMeetingSkeleton = () => {
  return (
    <CustomSkeletonFields
      label={{ style: labelMargin, height: 30, width: 70 }}
      field={{ style: { ...fieldMargin, borderRadius: "2vw" }, height: 32, width: 120 }}
    />
  );
};

const SelectInventorySkeleton = () => {
  return (
    <div className="d-flex flex-row align-items-center">
      <Skeleton style={{ marginLeft: "3vw" }} height={30} width={70}></Skeleton>
      <span style={{ marginLeft: "4vw" }}>
        <Avatar loading />
      </span>
      <Skeleton height={30} width={100} className="ml-2" />
    </div>
  );
};

const SelectionListTypeHeadSkeleton = () => {
  return (
    <CustomSkeletonFields
      label={{ style: labelMargin, height: 30, width: 70 }}
      field={{ style: fieldMargin, height: 38, width: 380 }}
    />
  );
};
const AssignDateInputSkeleton = () => {
  return (
    <CustomSkeletonFields
      label={{ style: labelMargin, height: 30, width: 70 }}
      field={{ style: fieldMargin, height: 30, width: 500 }}
    />
  );
};
const TaskOutcomeSkeleton = () => {
  return (
    <CustomSkeletonFields
      label={{ style: labelMargin, height: 30, width: 70 }}
      field={{ style: { ...fieldMargin, borderRadius: "2vw" }, height: 30, width: 60 }}
    />
  );
};

export default LocationFormSkeleton;
