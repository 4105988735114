import React, { useState, useEffect, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Status, Close, CheckboxComponent, IF, Button, Skeleton, Toast } from "../../components/common";
import { Table } from "reactstrap";
import { strings } from "../../constant/strings";
import { fetchCarsApi, fetchCitiesApi, fetchLocationApi } from "../../helpers/ApiHelper";
import {
  capitalize,
  CustomMenuItem,
  getQueryString,
  getQueryStringFromArray,
  isEmptyArray,
  stateUpdateCallback,
} from "../../helpers/Util";
import FiltersWithoutQueryParams from "./filters/FiltersWithoutQueryParams";
import { BULK_QR_CODE, CRM_CICO_NEW_PATH } from "../../constant/appPaths";
import { connect } from "react-redux";
import { fetchCities } from "../../actions/general/cities.action";
import { useMakeModel } from "../../hooks/useMakeModel";
import { extractPayloadFromClientObj } from "../../helpers/ViewPhoneNumber";
import {
  MODELS_FOR_VIEW_PHONE_NUMBER_API,
  PHONE_NUMBER_CATEGORIZATION_PER_PAGE,
  SCROLLABLE_ELEMENTS_KEYS,
} from "constant/appConstants";
import RenderPrivateField from "./RenderPrivateField/RenderPrivateField";
import { availableInventoryStatuses } from "../../constant/lists";

function ScrollableTable({ setInventoryModelOpen, history, closeModel, path = "", setSelected, filterRemovedAndSold }) {
  //constants
  const INVENTORY_PATH = path === "inventories";
  const INVENTORY_MODEL = path === "inventoryModel";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [filtersObject, setFiltersObject] = useState({});
  const [carIds, setCarIds] = useState([]);
  const [statues, setStatues] = useState([]);
  const [filterStates, setFilterStates] = useState({ locations: [], cities: [] });

  //Effects
  const setFilters = () => {
    const queryObj = { ...filtersObject };
    return { queryObj };
  };
  const [, , , getMakeModelVariantFilters] = useMakeModel(setFilters, [filtersObject]);

  //Functions
  const fetchData = async isFilterChanged => {
    let filtersObj = {
      ...(filtersObject?.["f[make.id][]"]
        ? filtersObject
        : (() => {
            let objClone = filtersObject;
            delete objClone?.["f[model.id][]"];
            return objClone;
          })()),
      ...(isFilterChanged ? "" : { page: page.next_page }),
    };
    let searchString = getQueryString(filtersObj);
    if (filterRemovedAndSold) {
      searchString === "?"
        ? (searchString = `${searchString}${getQueryStringFromArray("f[status.slug][]", availableInventoryStatuses)}`)
        : (searchString = `${searchString}&${getQueryStringFromArray("f[status.slug][]", availableInventoryStatuses)}`);
    }
    const { success, cars, statuses, pagination } = await fetchCarsApi(searchString);
    if (success) {
      setLoading(false);
      if (pagination?.next_page === null) {
        setHasMore(false);
      }
      if (!isFilterChanged) {
        setData([...data, ...cars]);
        setStatues(statuses);
        setPage(pagination);
      } else {
        setData([...cars]);
        setStatues(statuses);
        setPage(pagination);
        setCarIds([]);
      }
    }
  };
  useEffect(() => {
    page && page.next_page && setHasMore(true);
  }, [page]);

  const getOnScrollElement = useCallback(() => {
    return document.getElementById(SCROLLABLE_ELEMENTS_KEYS.MODAL_TABLE_KEY);
  }, [data]);

  const getQRHeaderString = car => {
    let ref_number = car?.reference_number ?? "";
    let make = car?.make?.select_value ?? "";
    let model = car?.model?.select_value ?? "";
    let year = car?.model_year ?? "";
    let variant = car?.variant?.select_value ?? "";
    return `${ref_number} - ${make} ${model} ${variant} ${year}`;
  };
  const getQRDataString = car => {
    let domain = window.location.origin;
    let uuid = car.uuid;
    return ` ${domain}/cars/${uuid}`;
  };

  const onChangeCheckbox = car => () => {
    const carUUID = car?.uuid;
    if (!carUUID) {
      Toast.error("UUID doesn't exist!");
      return;
    }
    const isInventorySelected = carIds.find(item => item.carRefNo === car.reference_number);
    if (!isInventorySelected) {
      let obj = {
        carRefNo: car?.reference_number,
        id: car.id,
        make_model: getQRHeaderString(car),
        qr_text: getQRDataString(car),
        vin: car?.dynamic_attributes?.car?.vehicle_identification_number ?? "",
      };
      setCarIds(prev => [...prev, obj]);
    } else {
      setCarIds(carIds.filter(item => item.carRefNo !== car.reference_number));
    }
  };
  const onChangeCheckboxHeader = data => event => {
    if (event.target.checked) {
      const uuidExists = car => car?.uuid;
      const carReferenceNumbers = data.filter(uuidExists).map(car => ({
        carRefNo: car?.reference_number,
        id: car.id,
        make_model: getQRHeaderString(car),
        qr_text: getQRDataString(car),
        vin: car?.dynamic_attributes?.car?.vehicle_identification_number ?? "",
      }));
      setCarIds(carReferenceNumbers);
      const isAnyCarWithInvalidUUID = carReferenceNumbers.length < data.length;
      if (isAnyCarWithInvalidUUID) {
        Toast.error("UUID of some cars doesn't exist!");
      }
    } else {
      setCarIds([]);
    }
  };

  const TableLoader = props => {
    const { rowSkeletons = [1, 2, 3, 4, 5, 6, 7, 8], columnSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] } = props;
    return (
      <div className="d-flex flex-column w-100">
        {rowSkeletons.map(e => (
          <div className="d-flex flex-row justify-content-between border-bottom" key={e}>
            {columnSkeletons.map(i => (
              <div className="d-flex flex-row mb-3 mt-1" key={i}>
                <Skeleton height={21} width={90} className=" mt-3" />
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const RenderHeader = ({ data }) => (
    <tr>
      <IF condition={INVENTORY_PATH}>
        <th>
          <CheckboxComponent
            disable={data.toString(data?.length === 0)}
            checked={data.length === carIds.length && data?.length !== 0}
            onChange={onChangeCheckboxHeader(data)}
          />
        </th>
      </IF>
      <th>{strings.ref}</th>
      <th>{strings.make_model}</th>
      <th>{strings.seller_name}</th>
      <th>{strings.seller_phone}</th>
      <th>{strings.city}</th>
      <th>{strings.location}</th>
      <th>{strings.status}</th>
      <th>{strings.disposition}</th>
    </tr>
  );
  const renderBody = (item, history) => (
    <tr
      key={item.id}
      onClick={() => {
        if (INVENTORY_PATH) {
          onChangeCheckbox(item)();
          return;
        } else if (INVENTORY_MODEL) {
          setSelected(item);
          history.push({
            state: { inventory: item, canRemove: true },
          });
          setInventoryModelOpen(false);
        } else {
          history.push({
            pathname: CRM_CICO_NEW_PATH,
            state: { inventory: item, canRemove: true },
          });
        }
      }}
    >
      <IF condition={INVENTORY_PATH}>
        <td>
          <CheckboxComponent
            label={item?.name}
            key={item.id}
            value={item.id}
            checked={carIds.some(e => e?.carRefNo === item?.reference_number)}
          />
        </td>
      </IF>

      <td scope="row">{item?.reference_number}</td>
      <td>{item?.make?.select_value + " " + item?.model?.select_value}</td>
      <td>{`${capitalize(item?.client?.name) || "-"}`}</td>
      <td>
        <div
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <RenderPrivateField
            {...extractPayloadFromClientObj({ item: item?.client })}
            associatedType={MODELS_FOR_VIEW_PHONE_NUMBER_API.INVENTORY_PHONE_KEY}
            component={PHONE_NUMBER_CATEGORIZATION_PER_PAGE.TASK.INVENTORY_MODAL}
            associatedId={item?.id}
            dropDownContainer="tbody"
            onScrollElement={getOnScrollElement()}
          />
        </div>
      </td>
      <td>{item?.city?.name}</td>
      <td>{item?.inventory?.location?.address || "-"}</td>
      <td>
        <Status {...item.status} />
      </td>
      <td>{item?.disposition?.name}</td>
    </tr>
  );

  return (
    <div>
      <Close
        style={{ right: "30px" }}
        onClick={() => {
          closeModel(false);
        }}
      />
      <h4>Select Inventory</h4>
      <RenderFilters
        fetchData={fetchData}
        getMakeModelVariantFilters={getMakeModelVariantFilters}
        statues={statues}
        filterStates={filterStates}
        setFilterStates={setFilterStates}
        filtersObject={filtersObject}
        setFiltersObject={setFiltersObject}
      />

      <InfiniteScroll
        dataLength={data.length}
        next={fetchData}
        hasMore={hasMore}
        scrollThreshold={0.9}
        className={
          "infinite-scroll-table timeline-small timeline-activity activity-scroll timeline-activity-height mt-0"
        }
        height={"100%"}
        style={{ maxHeight: "500px" }}
        loader={
          <TableLoader
            columnSkeletons={[...Array(7).keys()]}
            rowSkeletons={loading ? [...Array(6).keys()] : hasMore ? [...Array(2).keys()] : null}
            showCheckboxSkeleton
          />
        }
      >
        <Table>
          <thead>
            <RenderHeader data={data} />
          </thead>
          <tbody>{loading ? null : data.map(item => renderBody(item, history))}</tbody>
        </Table>
      </InfiniteScroll>
      <div className="modal-footer">
        <IF condition={INVENTORY_PATH}>
          <Button
            className="mt-3"
            disabled={isEmptyArray(carIds)}
            btnText={strings.print_qr}
            onClick={() => {
              history.push({
                pathname: BULK_QR_CODE,
                state: { data: carIds },
              });
            }}
          />
        </IF>
      </div>
    </div>
  );
}

const RenderFilters = props => {
  const { getMakeModelVariantFilters, statues, fetchData, filtersObject, setFiltersObject, setFilterStates } = props;

  useEffect(() => {
    fetchData(true);
  }, [filtersObject]);

  return (
    <FiltersWithoutQueryParams
      list={[
        {
          sizeratio: 1,
          label: strings.ref,
          type: "input",
          key: "f[reference_number]",
          isRefNumber: true,
        },
        {
          sizeratio: 1,
          label: strings.registration_number,
          type: "input",
          key: "f[registration_number]",
        },
        {
          sizeratio: 2,
          label: strings.seller_name,
          type: "input",
          key: "s[client.name]",
        },
        {
          sizeratio: 2,
          label: strings.seller_phone,
          type: "input",
          key: "f[client.phone]",
        },
        ...getMakeModelVariantFilters(),
        {
          id: "inventory.location.address",
          type: "scrollable-dropdown",
          listApi: fetchCitiesApi,
          listProperty: "cities",
          getDataInParent: data =>
            setFilterStates(prev => ({ ...prev, cities: stateUpdateCallback(prev["cities"], data) })),
          queryKey: "s[address]",
          formatOptions: option => <CustomMenuItem option={option} />,
          customClass: "col-sm-6 col-md-4 col-xl-2 m-b-10 async-select-filter",
          sizeratio: 1,
          label: strings.city,
          key: "f[city.id][]",
          isSingleSelect: true,
        },
        {
          sizeratio: 1,
          label: strings.status,
          type: "typeahead",
          key: "f[status.id][]",
          list: statues,
          menuStyles: "action_dropDown_filter",
        },
        {
          id: "inventory.location.address",
          sizeratio: 2,
          label: strings.location,
          type: "scrollable-dropdown",
          key: "f[inventory.location.id][]",
          listApi: fetchLocationApi,
          listProperty: "locations",
          getDataInParent: data =>
            setFilterStates(prev => ({ ...prev, locations: stateUpdateCallback(prev["locations"], data) })),
          queryKey: "s[address]",
          formatOptions: option => <CustomMenuItem option={option} />,
          isSingleSelect: true,
          customClass: "col-sm-6 col-md-4 col-xl-2 m-b-10 async-select-filter",
        },
        {
          id: "disposition.name",
          sizeratio: 2,
          label: strings.disposition,
          type: "dropdown",
          key: "f[disposition.id][]",
          list: [],
          menuStyles: "action_dropDown_filter",
        },
      ]}
      filtersObject={filtersObject}
      setFiltersObject={setFiltersObject}
      containerClass="m-t-20"
    />
  );
};

export default connect(null, { fetchCities })(ScrollableTable);
