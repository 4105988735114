import AvailabilityInfoInput from "./AvailabilityInfoInput";
import React, { useRef, useEffect } from "react";
import { generateUUID } from "../../helpers/Util";

const AvailabilityInfoCard = props => {
  const {
    values,
    setFieldValue,
    handleBlur,
    titleHeading,
    addText,
    dataKey,
    title,
    errors,
    errorMsg,
    touched,
    isRedirectedFromInventoryDetailPageEditAddressButton,
    activeColumnsPermittedAttributes = null,
    cardFrom = null,
    isEditable = null,
    horizontal = true,
    EmptyState,
    fullWidthBody = false,
  } = props;

  const scrollRef = useRef(null);

  const shouldScrollToAddress = scrollRef?.current && isRedirectedFromInventoryDetailPageEditAddressButton;
  const fromInventory = dataKey === "addresses";
  const fromUsers = dataKey === "workingHours";
  const fromLocation = dataKey === "availabilityHours";

  useEffect(() => {
    if (shouldScrollToAddress) scrollRef.current.scrollIntoView();
  }, [scrollRef, shouldScrollToAddress]);

  const initialValue =
    fromUsers || fromLocation
      ? {
          start_time: "",
          end_time: "",
        }
      : {
          address: "",
          address_link: "",
          building_address: "",
          city: null,
          area: null,
          start_time: "",
          end_time: "",
        };

  return (
    <div
      className={`${dataKey === "workingHours" ? "" : "card"}`}
      style={isEditable === false ? { cursor: "not-allowed" } : {}}
      ref={scrollRef}
    >
      <div className="card-header">
        <h5>{titleHeading}</h5>
      </div>
      <div
        className={`${dataKey === "workingHours" ? "" : "card-body"} ${
          dataKey === "workingHours" || fullWidthBody ? "" : "w-75"
        }`}
        style={isEditable === false ? { pointerEvents: "none" } : {}}
      >
        {values[dataKey]?.map((item, i) => {
          return (
            !item._destroy && (
              <AvailabilityInfoInput
                {...props}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                infoInputFrom={cardFrom}
                activeColumnsPermittedAttributes={activeColumnsPermittedAttributes}
                key={item.id || item.key}
                title={`${title} ${i + 1}`}
                data={item}
                valueIndex={i}
                errorMsg={errors?.[dataKey]?.[i] && touched?.[dataKey]?.[i] && errors?.[dataKey]?.[i]}
              />
            )
          );
        })}
        <div className={`${horizontal ? "row" : ""}`}>
          {horizontal && <div className={`${horizontal ? "col-sm-3" : ""}`}></div>}
          <div className={`${horizontal ? "col-sm-9 p-l-0" : ""}`}>
            <button
              disabled={isEditable === false}
              onClick={() => {
                if (!values?.[dataKey]) return;
                setFieldValue(dataKey, [...values[dataKey], { ...initialValue, key: generateUUID() }], false);
              }}
              className="btn btn-link p-l-0 address-color"
              type="button"
            >
              + {addText}
            </button>
            {typeof errorMsg === "string" && <p className="invalid-error ">{errorMsg}</p>}
          </div>
        </div>
        {EmptyState && EmptyState}
      </div>
    </div>
  );
};

export default AvailabilityInfoCard;
