import { Skeleton } from "../common";
import React, { Fragment, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import cx from "clsx";
function CustomTabs(props) {
  const {
    tabsData,
    loading,
    defaultIndex,
    renderDefaultBtn,
    customStyles,
    selectedTabIndex = null,
    setSelectedTabIndex = index => {},
    setSelectedTab = null,
    tabTitleContainerClassName,
  } = props;

  const [tabIndex, setTabIndex] = useState(typeof defaultIndex === "number" ? defaultIndex : 0);
  const renderHeaderSkeleton = () => (
    <div className="d-flex flex-row justify-content-between m-2 mt-4 w-100 overflow-hidden">
      <div className="d-flex flex-row">
        {tabsData.map(tab => (
          <Fragment key={tab?.id}>
            <Skeleton height={40} width={80} className="m-2" />
          </Fragment>
        ))}
      </div>
      {tabsData[tabIndex].renderTabBtn && <Skeleton height={40} width={100} style={{ borderRadius: 25 }} />}
    </div>
  );

  const renderActionButtons = renderTabBtn => {
    const actionBtns = [];
    if (renderTabBtn) {
      actionBtns.push(
        <div key={"tab-action-btn"} className="ml-auto p-2">
          {renderTabBtn()}
        </div>
      );
    }
    if (renderDefaultBtn) {
      actionBtns.push(
        <div key={"default-action-btn"} className="ml-auto p-2">
          {renderDefaultBtn(tabIndex)}
        </div>
      );
    }
    return actionBtns;
  };

  return (
    <div className={`theme-tab justify-content-between align-items-center ${props.className ? props.className : ""}`}>
      <>
        {loading && renderHeaderSkeleton()}
        <Tabs
          selectedIndex={selectedTabIndex ?? tabIndex}
          onSelect={selectedIndex => {
            if (selectedTabIndex) {
              setSelectedTabIndex(previousIndex => (selectedIndex < previousIndex ? selectedIndex : previousIndex));
            }
            if (setSelectedTab) {
              setSelectedTab(selectedIndex);
            }
            !selectedTabIndex && setTabIndex(selectedIndex);
          }}
        >
          {!loading && (
            <div className="d-flex flex-row">
              <TabList
                className={cx("tabs tab-title m-b-0 scroll-x", tabTitleContainerClassName)}
                style={{ "--scroll-height": "6px" }}
              >
                {tabsData?.map(item => {
                  return (
                    <Tab key={`city-${item.id}`}>
                      <>{item.name}</>
                    </Tab>
                  );
                })}
              </TabList>
              <div className="ml-auto">{renderActionButtons(tabsData[selectedTabIndex ?? tabIndex]?.renderTabBtn)}</div>
            </div>
          )}
          <div
            className={`tab-content-cls mt-${
              typeof customStyles?.topMargin === "number" ? customStyles?.topMargin : 4
            }`}
          >
            {tabsData?.map(item => {
              return (
                <TabPanel key={`job-table-${item.id}`}>
                  {" "}
                  {item.renderComponent && item.renderComponent(item.componentProps)}{" "}
                </TabPanel>
              );
            })}
          </div>
        </Tabs>
      </>
    </div>
  );
}

export default CustomTabs;
