import { useState, useEffect, useReducer } from "react";
import * as yup from "yup";
import { clone, getErrorString, isFieldRestricted } from "../../../helpers/Util";
import {
  createQCReport,
  fetchQCReportQuestionsWithAnswers,
  fetchQCTemplatesQuestions,
  updateQCQuestionsWithAnswers,
} from "../../../helpers/ApiHelper";
import { Toast } from "../../../components/common";
import { QC_INPUT_FIELD_TYPES } from "constant/appConstants";
import { useHistory } from "react-router-dom";
import { createQCReportPermission, getQCSummaryData, updateQCReportPermission } from "./QCHelpers";
import { INITIAL_STATE, actionFactory, reducer } from "../../../reducers/local/qcFormReducer";
import {
  CRM_CALL_VIEW_EDIT_QC_PATH,
  CRM_PATH,
  INSPECTION_PATH,
  INS_CALL_VIEW_EDIT_QC_PATH,
} from "../../../constant/appPaths";

const useQCForm = ({
  viewOrEditQC = false,
  callId = "",
  formikRef,
  templateId = "",
  report = null,
  setQCSummaryData = () => {},
  setQcCallData = () => {},
}) => {
  const history = useHistory();

  const [questionnaire, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { setLoading, setQuestionnaire } = actionFactory(dispatch);
  const [isFormEditable, setEdittingForm] = useState(false);

  const toggleFormEdit = () => setEdittingForm(prev => !prev);
  const disableFields = (viewOrEditQC && !isFormEditable) ?? false;

  // Permissions
  const hasEditQCPermission = updateQCReportPermission();
  const hasCreateQCReportPermission = createQCReportPermission();

  // as our validation schema is based on "value" key, we must create a value object with answered question for prefilling the input fields
  const formatQuestionsWithAnswers = (questionsWithAnswers = []) => {
    if (isFieldRestricted(questionsWithAnswers)) return [];
    const questionsWithAnswersSnapShot = clone(questionsWithAnswers);

    return (
      questionsWithAnswersSnapShot.map(questionGroup => {
        const { questions, ...rest } = questionGroup;

        const formattedQuestionsWithAnswers = questions.reduce((acc, curr) => {
          const { question_options = [], statement_value, input_type } = curr;

          const asnwerFormatLookUp = {
            [QC_INPUT_FIELD_TYPES.DROP_DOWN]: () => {
              const answer = question_options.find(option => option.name === statement_value);

              if (!!answer) {
                return answer;
              }
            },
          };

          curr["value"] = asnwerFormatLookUp[input_type]() ?? null;

          acc.push(curr);
          return acc;
        }, []);

        return {
          ...rest,
          questions: formattedQuestionsWithAnswers,
        };
      }) ?? []
    );
  };

  const routeToRecoringListing = () => {
    const currentPath = history.location.pathname;
    if (currentPath.includes(INSPECTION_PATH)) return history.push(INS_CALL_VIEW_EDIT_QC_PATH.replace(":id", callId));
    else if (currentPath.includes(CRM_PATH)) return history.push(CRM_CALL_VIEW_EDIT_QC_PATH.replace(":id", callId));
  };

  useEffect(() => {
    try {
      if (viewOrEditQC) {
        // EDITTING  QC
        const getQCQuestionsWithAnswers = async () => {
          setLoading(true);
          const response = await fetchQCReportQuestionsWithAnswers(report.id);
          const {
            template: { question_groups = [] },
          } = response;
          const formattedQuestionsWithAnswers = formatQuestionsWithAnswers(question_groups);
          setQuestionnaire({
            loading: false,
            questionnaireWithSections: formattedQuestionsWithAnswers,
          });
        };
        if (report.id) {
          getQCQuestionsWithAnswers();
        }
      } else if (!!templateId) {
        // Creating QC
        const getQCTemplatesQuestions = async () => {
          setLoading(true);
          const templateQuestions = await fetchQCTemplatesQuestions(templateId);
          setQuestionnaire({
            loading: false,
            questionnaireWithSections:
              (templateQuestions?.templates?.question_groups &&
              !isFieldRestricted(templateQuestions?.templates?.question_groups)
                ? templateQuestions.templates.question_groups
                : []) ?? [],
          });
        };

        getQCTemplatesQuestions();
      }
    } catch (error) {
      setLoading(false);
      Toast.error(getErrorString(error));
    }
  }, [templateId, viewOrEditQC]);

  const getQuestionnaireSchema = question => {
    const { input_type = "" } = question;

    const schemaObj = {
      [QC_INPUT_FIELD_TYPES.DROP_DOWN]: () => {
        return {
          value: yup.object().when("is_required", {
            is: true,
            then: yup.object().nullable().required("Please select an option"),
            otherwise: yup.object().nullable(),
          }),
        };
      },
    };

    return yup.object().shape(schemaObj[input_type]()) ?? yup.object();
  };

  const getFormikKey = ({ questionnaireSectionId, questionId }) => `formik_${questionnaireSectionId}_${questionId}`;

  const getDefaultValue = currentQuestion => {
    if (viewOrEditQC) {
      return {};
    }
    const { question_options = [], input_type } = currentQuestion;
    const getDefaultAnswer = {
      [QC_INPUT_FIELD_TYPES.DROP_DOWN]: () => {
        return question_options?.find(option => !isFieldRestricted(option?.pre_selected) && option?.pre_selected);
      },
    };
    return {
      value: getDefaultAnswer[input_type]() ?? [],
    };
  };

  const getInitialValues = (questionnaireWithSections = []) => {
    const initialValues =
      questionnaireWithSections?.reduce((acc, questionnaireSection) => {
        acc =
          questionnaireSection?.questions?.reduce((questions, currQuestion) => {
            let formikKey = getFormikKey({
              questionnaireSectionId: questionnaireSection.id,
              questionId: currQuestion.id,
            });
            questions[formikKey] = {
              section: { ...questionnaireSection },
              ...clone(currQuestion),
              formikKey: formikKey,
              ...getDefaultValue(currQuestion),
            };
            return questions;
          }, acc) ?? {};

        return acc;
      }, {}) ?? {};

    console.log("getInitialValues ===<> questionnaireWithSections ==> ", initialValues);
    return initialValues;
  };

  const getValidationSchema = (questionnaireWithSections = []) => {
    return (
      yup.object().shape({
        ...(questionnaireWithSections?.reduce((acc, questionnaireSection) => {
          acc =
            questionnaireSection?.questions?.reduce((questions, currQuestion) => {
              let formikKey = getFormikKey({
                questionnaireSectionId: questionnaireSection.id,
                questionId: currQuestion.id,
              });
              questions[formikKey] = getQuestionnaireSchema(currQuestion);
              return questions;
            }, acc) ?? {};

          return acc;
        }, {}) ?? {}),
      }) ?? {}
    );
  };

  const prepareFormattedQCPayload = ({ payload }) => {
    const formatQcResponseObjectByInputType = question => {
      const formatObj = {
        [QC_INPUT_FIELD_TYPES.DROP_DOWN]: () => {
          return question?.value?.id
            ? {
                question_option_ids: [question?.value?.id],
                value: "",
              }
            : null;
        },
      };

      const formattedPayload = {
        question_group_id: question?.section?.id,
        question_id: question?.id,
        ...(formatObj[question.input_type]() ?? {}),
      };
      return formattedPayload;
    };

    let evaluation = {
      recording_id: callId,
      template_version_id: "",
      questions: [],
    };

    evaluation.questions = Object.entries(payload)
      .reduce((acc, [key, question]) => {
        evaluation.template_version_id = question.section.template_version_id;
        acc.push(formatQcResponseObjectByInputType(question));
        return acc;
      }, [])
      .filter(Boolean);

    return evaluation;
  };

  const createQC = async (values, setSubmitting) => {
    try {
      /** 
       const previousSnapShot = clone(getInitialValues(questionnaire.questionnaireWithSections));

      // diffing between original formik values and current formik values inorder to decide which input fields the user has filled
       const diffedValues = Object.entries(previousSnapShot).reduce((acc, [key, question]) => {
      //   if (key in values) {
      //     // user has certainly answered this input field
      //     if (!!values[key]?.value) {
      //       acc = {
      //         ...acc,
      //         [key]: clone(values[key]),
      //       };
      //     }
      //   }
      //   return acc;
      // }, {});

      */

      const evaluation = prepareFormattedQCPayload({
        payload: values,
      });
      const response = await createQCReport({
        evaluation,
      });
      !!response?.message && Toast.success(response?.message);

      const {
        evaluation_report: { answer_groups, recording },
      } = response;

      const updatedQuestionnaireWithAnswers = updateQuestionnaireWithUpdatedAnswers({
        newAnswersGroups: answer_groups,
        currentQuestionnairSectionsWithAnswers: questionnaire.questionnaireWithSections,
      });

      setQuestionnaire({
        questionnaireWithSections: updatedQuestionnaireWithAnswers,
      });

      setQcCallData(prev => ({
        ...prev,
        qcCallDetail: recording,
      }));
      updateQCSummaryData({ evaluationReportData: response.evaluation_report });
    } catch (error) {
      Toast.error(getErrorString(error));
    }
  };

  const updateQCSummaryData = ({ evaluationReportData }) => {
    const { score, sectionWiseRating } = getQCSummaryData(evaluationReportData);
    setQCSummaryData(prev => ({
      ...prev,
      qcOverallRatingScore: score,
      qcSectionWiseRating: sectionWiseRating,
    }));
  };

  const getUpdatedAnswerOfCurrentQuestion = ({ questionWithOldAnswer, questionWithUpdatedAnswer }) => {
    const changedAnswersLookUp = {
      [QC_INPUT_FIELD_TYPES.DROP_DOWN]: () => {
        // user has certainly updated the answer of this question
        if (questionWithUpdatedAnswer?.value?.name !== questionWithOldAnswer?.value?.name) {
          return questionWithUpdatedAnswer;
        }
      },
    };

    return changedAnswersLookUp[questionWithUpdatedAnswer.input_type]() ?? null;
  };

  const editQC = async (values, setSubmitting, updateQCSummaryData = () => {}) => {
    try {
      /** 
      const previousSnapShot = clone(getInitialValues(questionnaire.questionnaireWithSections));

      // diffing between original formik values and current formik values inorder to decide which input fields' answers are now changed
      const diffedValues = Object.entries(previousSnapShot).reduce((acc, [key, question]) => {
        if (key in values) {
          const updatedAnswerOfTheQuestion = getUpdatedAnswerOfCurrentQuestion({
            questionWithOldAnswer: question,
            questionWithUpdatedAnswer: values[key],
          });

          if (!!updatedAnswerOfTheQuestion) {
            acc = {
              ...acc,
              [key]: clone(updatedAnswerOfTheQuestion),
            };
          }
        }
        return acc;
      }, {});

      // if there are no changes, do not make the edit API call
       if (isEmptyObject(diffedValues)) return;
      */

      const evaluation = prepareFormattedQCPayload({
        payload: values,
      });

      const response = await updateQCQuestionsWithAnswers({
        params: report.id,
        payload: { evaluation },
      });

      const {
        evaluation_report: { answer_groups },
      } = response;

      const updatedQuestionnaireWithAnswers = updateQuestionnaireWithUpdatedAnswers({
        newAnswersGroups: answer_groups,
        currentQuestionnairSectionsWithAnswers: questionnaire.questionnaireWithSections,
      });

      // console.log("updatedQuestionnaireWithAnswers ====<> ", updatedQuestionnaireWithAnswers);

      setQuestionnaire({
        questionnaireWithSections: updatedQuestionnaireWithAnswers,
      });
      setEdittingForm(false);

      updateQCSummaryData({ evaluationReportData: response.evaluation_report });
    } catch (error) {
      console.log("error ===<> ", error);
      Toast.error(getErrorString(error));
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    isFormEditable ? await editQC(values, setSubmitting, updateQCSummaryData) : await createQC(values, setSubmitting);
  };

  const handleUpdatedAnswerAssociatedWithQuestion = ({ currentQuestionWithOldAnswer, updatedAnswer }) => {
    const currentQuestionWithOldAnswerSnapShot = clone(currentQuestionWithOldAnswer);

    const { input_type, question_options = [] } = currentQuestionWithOldAnswerSnapShot;

    const asnwerFormatLookUp = {
      [QC_INPUT_FIELD_TYPES.DROP_DOWN]: () => {
        if (!!updatedAnswer) {
          const { question_option_ids = [] } = updatedAnswer;

          currentQuestionWithOldAnswerSnapShot["statement_value"] = updatedAnswer.statement_value;

          question_option_ids.forEach(questionOptionId => {
            const updatedQuestionOption = question_options.find(groupOption => groupOption.id === questionOptionId);
            if (!!updatedQuestionOption) {
              currentQuestionWithOldAnswerSnapShot["value"] = updatedQuestionOption;
            }
          });
        }

        return currentQuestionWithOldAnswerSnapShot;
      },
    };

    return asnwerFormatLookUp[input_type]() ?? null;
  };

  // updates the current questionnaire state with the answers recieved from the backend (FOR CONSISTENCY)
  const updateQuestionnaireWithUpdatedAnswers = ({
    newAnswersGroups = [],
    currentQuestionnairSectionsWithAnswers = [],
  }) => {
    // console.log("answersGroups ===<> ", newAnswersGroups);
    // console.log("currentQuestionnairSectionsWithAnswers ===<> ", currentQuestionnairSectionsWithAnswers);

    const currentQuestionnairSectionsWithAnswersSnapshot = clone(currentQuestionnairSectionsWithAnswers);

    return currentQuestionnairSectionsWithAnswersSnapshot.map(questionGroup => {
      const { questions = [], slug } = questionGroup;

      // console.log("questionGroup ===<> ", questionGroup);
      const answerGroup = newAnswersGroups.find(answerGroup => answerGroup.slug === slug);

      let updatedQuestionsWithAnswers = [];
      if (!!answerGroup) {
        // console.log("answerGroup ====<> ", answerGroup);

        const { answers = [] } = answerGroup;
        // console.log("answers ====<> ", answers);

        updatedQuestionsWithAnswers = questions.reduce((acc, question) => {
          const updatedAnswerOfQuestion = answers.find(answer => answer.question_id === question.id);
          if (!!updatedAnswerOfQuestion) {
            question = handleUpdatedAnswerAssociatedWithQuestion({
              currentQuestionWithOldAnswer: question,
              updatedAnswer: updatedAnswerOfQuestion,
            });
            // console.log("question with updated answer  ===<> ", question);
          }

          acc.push(question);

          return acc;
        }, []);
        // console.log("questions ====<> ", updatedQuestionsWithAnswers);
      }

      return {
        ...questionGroup,
        questions: updatedQuestionsWithAnswers?.filter(Boolean) ?? [],
      };
    });
  };

  return {
    getInitialValues,
    getValidationSchema,
    getFormikKey,
    questionnaire,
    handleSubmit,
    toggleFormEdit,
    isFormEditable,
    disableFields,
    hasEditQCPermission,
    hasCreateQCReportPermission,
  };
};

export default useQCForm;
