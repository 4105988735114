import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Formik } from "formik";
import { getRemoteConfigLoginWithEmail } from "helpers/FirebaseHelpers";
import React, { useEffect, useRef, useState } from "react";
import { ArrowLeft } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import loginIllustrationUrl from "../../assets/illustrations/login-illustration.svg";
import { Button, ButtonLoading, CustomAlert, FormInput } from "../../components/common";
import Logo from "../../components/common/Logo/Logo";
import { IoEyeOffOutline, IoEyeOutline, LoginGoogleIcon } from "../../components/svg-icon/svg-icons";
import { FETCH_LOGGED_IN_USER_INFO } from "../../constant/actionTypes";
import { strings } from "../../constant/strings";
import ConfigDB from "../../data/config";
import { loginApi, loginWithSSOApi } from "../../helpers/ApiHelper";
import { getCookie, setCookie, setCookieObject } from "../../helpers/CookieHelper";
import { cookieObjForUserInfo, getAppPath, getErrorString, removeAppCookies } from "../../helpers/Util";
import useMountEffect from "../../hooks/useMountEffect";
import app from "../../services/firebase";
import { persistor } from "../../store";

const authUser = token => {
  const res = loginWithSSOApi({}, token).then(response => {
    return response;
  });
  return res;
};

const SignIn = ({ history, handleLogin, state, setUser, ...rest }) => {
  // to set theme colors
  const configDB = useSelector(content => content.Customizer.customizer);
  const color = localStorage.getItem("color");
  const primary_color = localStorage.getItem("primary_color");
  const secondary_color = localStorage.getItem("secondary_color");
  const layout_version = localStorage.getItem("layout_version");
  const mix_layout = configDB.color.mix_layout;
  const [inputType, setInputType] = useState("password");
  const dispatch = useDispatch();
  const formikRef = useRef();
  const showPasswordRef = useRef();
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [isEmailLoginEnabled, setIsEmailLoginEnabled] = useState(true);

  const location = useLocation();

  useMountEffect(() => {
    persistor.purge();
    removeAppCookies();
    getRemoteConfigLoginWithEmail().then(enabled => setIsEmailLoginEnabled(enabled));
  });

  useEffect(() => {
    dispatch({
      type: "ADD_COLOR",
      payload: {
        color,
        primary_color,
        secondary_color,
        layout_version,
      },
    });

    document.body.className = mix_layout;
    document.documentElement.className = color;

    let link = document.querySelector('link[rel="shortcut icon"]');

    if (!link) {
      link = document.createElement("link");
      link.id = "favicon";
      link.rel = "shortcut icon";
      document.head.appendChild(link);
    }

    link.href = ConfigDB.data.favicon;
  }, []);

  const signInWithProvider = cb => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    formikRef.current.setSubmitting(true);
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const res = authUser(token);
        res.then(result => {
          // The signed-in user info.
          cb({
            success: true,
            ...result,
          });
        });
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        cb({
          success: false,
          error,
        });
        // ...
      });
  };

  const callBackForSignIn = async response => {
    if (response?.success ?? response?.data?.success) {
      if (response?.data?.user && response?.headers?.["authorization"]) {
        const redirectTo = new URLSearchParams(location.search).get("redirect_to");
        formikRef.current.setSubmitting(false);
        if (response.data.user.roles?.length === 1 && response.data?.user?.roles[0]?.name === "Inspector") {
          formikRef.current.setStatus({ message: "User not allowed to login!" });
        } else if (!response.data.user.platforms.length) {
          formikRef.current.setStatus({ message: "No Platform Assigned! Cannot login" });
        } else {
          const currentApp = getCookie("currentApp") || response.data.user.platforms[0].slug;
          const { platforms, userObjForCookie } = cookieObjForUserInfo(response?.data?.user);
          setCookieObject("userInfo", userObjForCookie);
          dispatch({ type: FETCH_LOGGED_IN_USER_INFO, payload: response.data });
          setCookie("client", response.headers["client"]);
          setCookie("refresh-token", response.headers["refresh-token"]);
          setCookie("authorization", response.headers["authorization"]);
          setCookie("uid", response.headers["uid"]);
          setCookieObject("platforms", platforms);
          setCookie("currentApp", currentApp);
          setUser(true);
          if (redirectTo) {
            history.push(redirectTo);
          } else {
            history.push({
              pathname: getAppPath(currentApp),
            });
          }
          await handleLogin(response.data.user);
        }
      }
    } else {
      formikRef.current.setSubmitting(false);
      formikRef.current.setStatus({ message: getErrorString(response) });
    }
  };

  const loginAuth = async ({ email, password }, setSubmitting, setStatus) => {
    setSubmitting(true);
    var obj = {
      email: email,
      password: password,
    };
    try {
      const res = await loginApi(obj);
      callBackForSignIn(res);
    } catch (error) {
      setStatus({ message: getErrorString(error) });
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = () =>
    yup.object().shape({
      email: yup.string().required(strings.type_email).email(strings.type_valid_email),
      password: yup.string().required(strings.type_password),
      // .matches(
      //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      //   "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      // ),
    });
  const EyeView = () =>
    inputType === "text" ? (
      <IoEyeOutline onClick={() => setInputType("password")} />
    ) : (
      <IoEyeOffOutline onClick={() => setInputType("text")} />
    );

  return (
    <div className="login-page">
      <div className="login-page-header">
        <Logo className="d-block" />
      </div>
      <div className="login-page-body">
        <div className="login-illustration-col">
          <div className="login-illustration">
            {/* <Lottie options={defaultOptions} /> */}
            <img src={loginIllustrationUrl} width={"550"} alt="" />
          </div>
        </div>
        <div className="login-card-wrap">
          <div className="login-card">
            {showEmailForm && (
              <button
                className="btn slide-btn btn-prev bg-white pl-0 login-back-btn"
                type="button"
                onClick={() => {
                  if (formikRef?.current?.setStatus) {
                    formikRef.current.setStatus({
                      message: "",
                    });
                  }
                  setShowEmailForm(false);
                }}
              >
                {<ArrowLeft color="#0e53e6" />}
              </button>
            )}
            <h4 className="primary-color-text">{strings.login}</h4>
            <p className="mb-4">Hi &#128075; Welcome to CarForce!</p>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema()}
              innerRef={formikRef}
              onSubmit={(values, { setSubmitting, setStatus }) => {
                setStatus({
                  message: "",
                });
                loginAuth(values, setSubmitting, setStatus);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                status,
                setStatus,
              }) => (
                <form className="theme-form" onSubmit={handleSubmit}>
                  {showEmailForm ? (
                    <div>
                      <FormInput
                        label={strings.your_email}
                        placeholder={strings.email_ph}
                        type="email"
                        name="email"
                        onChange={e => {
                          setStatus({ message: "" });
                          setFieldValue("email", e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                        errorMsg={errors.email && touched.email && errors.email}
                      />
                      <FormInput
                        inputRef={showPasswordRef}
                        label={strings.your_password}
                        placeholder={strings.password_ph}
                        type={inputType}
                        name="password"
                        onChange={e => {
                          setStatus({ message: "" });
                          setFieldValue("password", e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.password}
                        errorMsg={errors.password && touched.password && errors.password}
                        right={EyeView}
                      />
                      {status && status.message && <CustomAlert message={status.message} />}
                      <ButtonLoading
                        onClick={handleSubmit}
                        color="login"
                        className="justify-content-center"
                        block
                        // color={
                        //   config.data.color.primary_color || undefined
                        // }
                        type="submit"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        {strings.login}
                      </ButtonLoading>
                    </div>
                  ) : (
                    <div>
                      <ButtonLoading
                        className="loginGoogleBtn d-flex justify-content-center align-items-center py-3"
                        style={{ gap: 11 }}
                        block
                        color=""
                        variant=""
                        onClick={e => {
                          e.preventDefault();
                          signInWithProvider(callBackForSignIn);
                        }}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        icon={<LoginGoogleIcon className="googleIcon" />}
                      >
                        {"Login With Google"}
                      </ButtonLoading>
                      {isEmailLoginEnabled && (
                        <Button
                          btnText={"Login with Email"}
                          onClick={() => {
                            if (formikRef?.current?.setStatus) {
                              formikRef.current.setStatus({
                                message: "",
                              });
                            }
                            setShowEmailForm(true);
                          }}
                          color=""
                          className="primary-color-text bg-transparent w-100 d-flex justify-content-center align-items-center pt-3 text-decoration-none login-email-btn"
                        />
                      )}
                      {status && status?.message && <CustomAlert message={status.message} />}
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
          <p className="power-by mb-0">
            Powered By{" "}
            <a href="https://www.empglabs.com/" target="_blank" rel="noreferrer">
              dubizzlelabs
            </a>
          </p>
          <div className="sr-only">
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignIn);
