import React from "react";
import {
  AUC_CARS_DETAIL_PATH,
  CRM_CARS_PATH,
  CRM_LEADS_PATH,
  CRM_PAYMENTS_PATH,
  CRM_TASKS_PATH_DETAIL,
  INS_INSPECTIONS_PATH,
  INS_LEADS_PATH,
} from "../../constant/appPaths";
import { CHATTER_ACTIONS } from "constant/appConstants";
import { updateNotificationAsReadApi } from "../../helpers/ApiHelper";
import {
  getPermission,
  getTimeDateString,
  isObjRestricted,
  getDateTimeDiff,
  isFieldRestricted,
} from "../../helpers/Util";
import {
  UnreadDot,
  TaskNotification,
  TransactionNotificatioIcon,
  InventoryNotificationIcon,
  FileNotificationIcon,
  FallbackNotificationIcon,
  LeadNotificationIcon,
} from "../svg-icon/svg-icons";

const PLATFORM = {
  inspection: {
    slug: "inspection",
    models: {
      lead: {
        slug: "lead",
        permission: "Lead",
        redirectUrl: id => `${INS_LEADS_PATH}/${id}`,
        getIcon: () => <LeadNotificationIcon />,
      },
      report: {
        slug: "report",
        permission: "Inspection",
        redirectUrl: id => `${INS_INSPECTIONS_PATH}`,
        getIcon: () => <FileNotificationIcon />,
      },
    },
  },
  crm: {
    slug: "crm",
    models: {
      lead: {
        slug: "lead",
        permission: "CrmLead",
        redirectUrl: id => `${CRM_LEADS_PATH}/${id}/detail`,
        getIcon: () => <LeadNotificationIcon />,
      },
      task: {
        slug: "task",
        permission: "Task",
        permissionType: "index",
        redirectUrl: () => `${CRM_TASKS_PATH_DETAIL}`,
        getIcon: () => <TaskNotification />,
      },
      car: {
        slug: "inventory",
        permission: "Car",
        redirectUrl: id => `${CRM_CARS_PATH}/${id}/detail`,
        getIcon: () => <InventoryNotificationIcon />,
      },
      approval: {
        slug: "approval",
        permission: "Approval",
        redirectUrl: id => `${CRM_LEADS_PATH}/${id}/detail`,
        getIcon: () => <TransactionNotificatioIcon />,
      },
      payment: {
        slug: "payment",
        permission: "CrmPayment",
        permissionType: "index",
        redirectUrl: () => `${CRM_PAYMENTS_PATH}`,
        getIcon: () => <TransactionNotificatioIcon />,
      },
    },
  },
  auction: {
    slug: "auction",
    models: {
      car: {
        slug: "inventory",
        permission: "Car",
        redirectUrl: id => `${AUC_CARS_DETAIL_PATH}`,
        getIcon: () => <InventoryNotificationIcon />,
      },
      report: {
        slug: "report",
        permission: "Car",
        redirectUrl: id => `${AUC_CARS_DETAIL_PATH}/${id}`,
        getIcon: () => <FileNotificationIcon />,
      },
    },
  },
};

const getNotificationPathName = (id, notificationMeta) => {
  let pathName = id ? notificationMeta.redirectUrl(id).replace(":id", id) : notificationMeta.redirectUrl();
  return pathName;
};

const getNotificationMeta = (item, platform) => {
  let notificationSource = item?.source_type?.toLowerCase();
  let id = item?.source_id;
  let notificationMetaObj = PLATFORM[platform].models;

  let notificationMeta = Object.keys(notificationMetaObj).filter(function (modelKey) {
    return notificationSource?.includes(modelKey);
  });
  notificationMeta = notificationMetaObj[notificationMeta.at(0)];
  return { id, notificationMeta };
};

const handleNotificationClick = props => {
  const { item, platform, setSidebarOpen, history, onNotificationOpen, setnotificationCount, setData, data } = props;
  let { id = null, notificationMeta } = getNotificationMeta(item, platform);
  if (!notificationMeta) return;
  getPermission("Notification", "mark_as_read") &&
    !item.is_read &&
    onNotificationOpen({ notificationId: item.id, setnotificationCount, setData, data });
  const pathName = getNotificationPathName(id, notificationMeta);
  if (getPermission(notificationMeta.permission, notificationMeta.permissionType ?? "read")) {
    setSidebarOpen(false);
    const notificationType = item?.payload.data?.notification_type;
    history.push({
      pathname: pathName,
    });
  }
};

const onNotificationOpen = async props => {
  const { setnotificationCount, notificationId, setData, data } = props;
  let response = await updateNotificationAsReadApi(notificationId);
  if (response.success) {
    let updatedNotifications = data.map(item => {
      return response.notification.id === item.id ? Object.assign(item, response.notification) : item;
    });
    setnotificationCount(count => count - 1);
    setData(updatedNotifications);
  }
};

export default function NotificationCard({
  item,
  data,
  setData,
  history,
  setSidebarOpen,
  platform,
  setnotificationCount,
}) {
  return (
    <>
      <div
        onClick={() => {
          handleNotificationClick({
            item,
            platform,
            setSidebarOpen,
            history,
            onNotificationOpen,
            setnotificationCount,
            setData,
            data,
          });
        }}
        className={`d-flex flex-row py-3 align-items-center justify-content-center  ${
          item.is_read ? "notification-read" : "notification-unread"
        }  notification-center-card`}
        key={item.id}
      >
        <div className="mr-3 ml-3">
          {getNotificationMeta(item, platform)?.notificationMeta?.getIcon() ?? <FallbackNotificationIcon />}
        </div>
        <div className="d-flex flex-column ">
          {!isFieldRestricted(item?.title) && (
            <div style={{ fontWeight: "600", fontSize: "0.9rem" }}> {item?.title}</div>
          )}

          <div className="d-flex flex-row ">
            {!isFieldRestricted(item?.id) && <div className="mr-3 text-muted">{item?.description}</div>}
          </div>
          <div className="text-muted">
            {!isFieldRestricted(item?.created_at) &&
              (getDateTimeDiff(item.created_at, new Date()).split(" ")[0] < 24 ? (
                <>{getDateTimeDiff(item.created_at, new Date())} ago</>
              ) : (
                getTimeDateString(item.created_at)
              ))}
          </div>
        </div>

        <div className="ml-auto p-2 pr-3">{item?.is_read ? null : <UnreadDot></UnreadDot>}</div>
      </div>
    </>
  );
}
