import {
  AUCTION_APPLY_LEAVE_NEW_PATH,
  AUCTION_PATH,
  AUC_CARS_EDIT_PATH,
  AUC_CARS_EDIT_SOLD_CAR_PATH,
  AUC_DASHBOARD_PATH,
  AUC_EDIT_USER_SETTINGS_PATH,
  CRM_APPLY_LEAVE_NEW_PATH,
  CRM_CARS_EDIT_PATH,
  CRM_CARS_EDIT_SOLD_CAR_PATH,
  CRM_DASHBOARD_PATH,
  CRM_EDIT_USER_SETTINGS_PATH,
  CRM_PATH,
  INSPECTION_PATH,
  INS_APPLY_LEAVE_NEW_PATH,
  INS_DASHBOARD_PATH,
  INS_EDIT_USER_SETTINGS_PATH,
} from "constant/appPaths";

import { paramCase } from "change-case";
import Anchor from "components/common/Anchor";
import Text from "components/common/Text";
import Toast from "components/common/Toast";
import { FLUSH_DYNAMIC_FILTERS_DATA } from "constant/actionTypes";
import {
  DUBIZZLE,
  DYNAMIC_FIELD_TYPES,
  FILTER_TYPES,
  PAYMENT_STATUSES,
  PRICE_CATEGORIES,
  TASKS_STATUSES_SLUGS,
  TASK_MEETING_TYPES,
} from "constant/appConstants";
import { dynamicFilterTypeBooleanList } from "constant/lists";
import { strings } from "constant/strings";
import { getCookie, removeCookie, setCookie } from "helpers/CookieHelper";
import moment from "moment";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { store } from "store/index";
import stc from "string-to-color";

// Tables
const getTableCellStyleClass = attributeName => {
  switch (attributeName) {
    case "id":
      return "cell-id";
    case "ref":
      return "cell-ref";
    case "name":
      return "cell-name";
    case "assignee":
      return "cell-assignee";
    case "groups":
      return "cell-groups";
    case "phone":
      return "cell-phone";
    case "description":
      return "cell-description";
    default:
      return "";
  }
};

export const colorBrewerExportMatcher = /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/;
export const rgbStringToTuple = s => colorBrewerExportMatcher.exec(s).slice(1);
export const colorBrewerCategoricalStark9 = [
  "rgb(228,26,28)",
  "rgb(55,126,184)",
  "rgb(77,175,74)",
  "rgb(152,78,163)",
  "rgb(255,127,0)",
  "rgb(255,255,51)",
  "rgb(166,86,40)",
  "rgb(247,129,191)",
  "rgb(153,153,153)",
].map(rgbStringToTuple);

export const onTypeaheadOptionsChange = (key, cb) => selected => {
  const value = isEmptyArray(selected) ? null : selected[0];
  cb(key, value);
};

export const discreteColor =
  (categoricalColors, opacity = 1) =>
  i =>
    `rgba(${categoricalColors[i % categoricalColors.length].concat([opacity.toString()]).join(",")})`;

export const removingIndexes = (restrictedColumns, tableColumns) => {
  // Filtering Indexes
  const filteredIndexes = restrictedColumns?.map(item => {
    return tableColumns?.queryNames && tableColumns?.queryNames?.findIndex(child => item === child);
  });

  // Removing Indexes
  const prettyNames =
    tableColumns?.prettyNames &&
    tableColumns?.prettyNames?.filter((item, index) => {
      return filteredIndexes?.indexOf(index) !== -1;
    });

  const queryNames =
    tableColumns?.queryNames &&
    tableColumns?.queryNames?.filter((item, index) => {
      return filteredIndexes?.indexOf(index) !== -1;
    });

  return [prettyNames, queryNames];
};

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export const filteringTableColumns = (columnFilter = [], data) => {
  const columns = Object.keys(data[0]).filter(e => {
    if (e === "ID" || columnFilter.indexOf(e) === -1) {
      return false;
    } else {
      return true;
    }
  });
  return columns;
};

export function openContractLink(uuid) {
  const url = new URL(window.location.href);
  window.open(`${url.origin}/contracts/${uuid}`, "_blank");
}

export const getTableColumns = (
  data,
  permittedColumns = [],
  tableColumns = {},
  showActions = true,
  anchorProps = null
) => {
  let columns = [];
  if (data && data.length > 0) {
    let [prettyNames, queryNames] = removingIndexes(permittedColumns, tableColumns);
    showActions && prettyNames.push(strings.actions);
    let filteredColumns = filteringTableColumns(prettyNames, data);
    columns = filteredColumns.map((item, index) => {
      const cellTag = paramCase(item);
      return {
        id: cellTag,
        // key: queryNames[index],
        showActions: true,
        name: item,
        selector: item,
        key: `sort[${queryNames[index]}]`,
        allowOverflow: true,
        sortable: tableColumns?.sortNames?.findIndex(e => e === queryNames?.[index]) !== -1,
        cell: row =>
          anchorProps?.isReassignement ? (
            <div className={getTableCellStyleClass(cellTag)} data-tag="allowRowEvents">
              {row[item] ? row[item] : "-"}
            </div>
          ) : (
            <TableCell row={row} anchorProps={anchorProps} item={item} cellTag={cellTag} />
          ),
        style: {
          fontSize: 13,
        },
      };
    });

    return columns;
  }
};

export const isCtrlClicked = e => e.ctrlKey || e.metaKey || e.control;

const TableCell = props => {
  const { row, anchorProps, item, cellTag, showActions } = props;
  const isRowItemString = row[item] && typeof row[item] === "string";
  let hasPermission = !!anchorProps?.hasPermission;
  let anchorLink = null;
  let conditionals = anchorProps?.conditionals ? anchorProps.conditionals(row) : true;
  let shouldWrapAnchor = conditionals && hasPermission && !showActions && isRowItemString;
  let tooltipColumns = anchorProps?.tooltipColumns ?? [];
  let shouldWrapTooltip = isRowItemString && !showActions && tooltipColumns.includes(item);
  if (anchorProps) {
    if (anchorProps?.getCustomAnchorLink || anchorProps?.getAnchorLink) {
      anchorLink = anchorProps?.getCustomAnchorLink
        ? anchorProps.getCustomAnchorLink(row)
        : anchorProps.getAnchorLink(row.ID);
    }
  }
  let isActionCell = false;
  if (item === "Actions") {
    isActionCell = true;
    //const actionProps = row?.Actions?.props?.actionsList;
    //shouldWrapAnchor = actionProps.map(actionItem => actionItem.anchor);
    //anchorLink = actionProps.map(actionItem => actionItem.anchorLink ?? null);
    shouldWrapAnchor = false;
  }
  const rowItem = row[item] ? row[item] : "-";
  return (
    <WrappedCell cellTag={props.cellTag} shouldWrap>
      <WrappedAnchor
        shouldWrapAnchor={shouldWrapAnchor}
        link={anchorLink}
        data-tag="allowRowEvents"
        isActionCell={isActionCell}
      >
        <WrappedTooltip row={row} rowItem={rowItem} shouldWrapTooltip={shouldWrapTooltip} />
      </WrappedAnchor>
    </WrappedCell>
  );
};

const WrappedCell = props => {
  if (props.shouldWrap) return <>{props.children}</>;
  return (
    <div className={getTableCellStyleClass(props.cellTag)} data-tag="allowRowEvents">
      {props.children}
    </div>
  );
};

export const WrappedAnchor = props => {
  const history = useHistory();
  if (!props.shouldWrapAnchor) return <>{props.children}</>;
  return (
    <Anchor
      {...props}
      onClick={e => {
        if (isCtrlClicked(e)) {
          e.preventDefault();
        } else {
          history.push(`${props.link}`);
        }
      }}
    />
  );
};

const WrappedTooltip = props => {
  if (!props.shouldWrapTooltip) return <>{props.rowItem}</>;
  let text = props.rowItem;
  let textId = props.rowItem.replace(/\s/g, "");
  return <Text text={text} dataTipId={`${props.row.ID}-${textId}`} />;
};

export const defaultFn = () => {};

// Query String
export const appendParamsToQueryString = (paramKey, paramValue, queryString) => {
  const queryParam = `${queryString ? "&" : "?"}${createQueryParam(paramKey, paramValue)}`;
  const newQueryString = queryString + queryParam;
  return newQueryString;
};
export const createQueryParam = (paramKey, paramValue) => `${paramKey}=${paramValue || ""}`;

// Filters

export const convertQueryObjToStringNew = obj => {
  let keys = Object.keys(obj);
  let queryString = keys.map((key, index) => {
    if (obj[key] === undefined) return "";
    let queryKey =
      typeof obj[key] !== "number" && obj[key]?.indexOf(",") !== -1
        ? obj[key]
            .split(",")
            .map(e => key + "=" + e)
            .join("&")
        : obj[key]
        ? key + "=" + obj[key]
        : "";
    return queryKey;
  });
  return queryString.join("&");
};

export const convertQueryObjToString = obj => {
  const keys = Object.keys(obj);
  const queryParts = keys
    .map(key =>
      typeof obj[key] !== "number" && obj[key]?.indexOf(",") !== -1
        ? obj[key]
            ?.split(",")
            .map(e => key + "=" + e)
            .join("&")
        : obj[key]
        ? key + "=" + obj[key]
        : null
    )
    .filter(part => part !== null);
  return queryParts.join("&");
};

export const getQueryString = filters => {
  let queryString = "";
  if (filters) {
    queryString = "?" + convertQueryObjToString(filters);
  }
  return queryString;
};

export const getQueryStringFromArray = (queryKey = "", arr = []) => {
  return arr?.reduce(function (previousValue, currentValue, currentIndex, array) {
    let result = previousValue + `${queryKey}=` + currentValue + `${currentIndex === array.length - 1 ? "" : "&"}`;
    return result;
  }, "");
};

export const getDefaultCalendarView = (queryObj = {}, fromInventory) => {
  if (queryObj.view) return queryObj.view;
  if (fromInventory) return "month";
  return "day";
};

export const mapQueryStringToFilterObject = (search, cb = () => {}) => {
  const queryObj = {},
    queryString = search.split("?")[1];

  if (queryString) {
    const items = queryString.split("&");
    for (let item of items) {
      const key = item.split("=")[0],
        value = item.split("=")[1];
      cb(key, value);
      if (key && value) {
        if (queryObj[key]) {
          queryObj[key] += "," + decodeURIComponent(value);
        } else {
          queryObj[key] = decodeURIComponent(value);
        }
      }
    }
  }

  return { queryObj };
};

export function convertQueryStringToArray(queryString) {
  if (!queryString) {
    return [];
  }
  const cleanQueryString = queryString.startsWith("?") ? queryString.slice(1) : queryString;
  const params = new URLSearchParams(cleanQueryString);
  const resultArray = [];

  for (const [key, value] of params.entries()) {
    resultArray.push({ key, value: decodeURIComponent(value) });
  }

  return resultArray;
}

export const addQueryFilterArray = (filters, searchKey, value, name, cb) => {
  const searchKeyValues = searchKey.split(".");
  const containsDynamicAndDealerEvaluation =
    searchKeyValues.includes("f[dynamic_attributes") && searchKeyValues.includes("dealer_evaluation]");
  const prevVal = filters[searchKey];
  // const prevValArr = prevVal ? prevVal.split(",") : [];
  const prevValArr = prevVal ? (Array.isArray(prevVal) ? prevVal : prevVal.split(",")) : [];
  if (!prevValArr.includes(value)) {
    const filtersObj = { ...filters };
    filtersObj[searchKey] = [...prevValArr, containsDynamicAndDealerEvaluation ? name : value].join(",");
    filtersObj.page && delete filtersObj.page;
    cb(filtersObj);
  }
};

export const appendFilterDataToStatuses = (statuses, query) => {
  let fetchedStatuses = statuses ?? [];
  let savedStatuses = store.getState().CRMLeadsData.statuses;
  const searchParams = window.location.search;
  const parsedQueryString = query.parse(searchParams);
  const statusParentKey = "f[category_type.id][]";
  let statusParentIds = parsedQueryString[statusParentKey];
  let parentIds;
  if (statusParentIds) {
    parentIds = typeof statusParentIds === "string" ? [statusParentIds] : [...statusParentIds];
  }
  let newStatuses = fetchedStatuses.map((status, index) =>
    savedStatuses[index]?.ancestry ? { ...savedStatuses[index] } : { ...status, ancestry: [...parentIds] }
  );
  let uniqueStatuses = [];
  newStatuses.forEach((status, index) => {
    let isDuplicateStatus = uniqueStatuses.find(currentStatus => currentStatus.slug === status.slug);
    if (isDuplicateStatus) {
      let index = uniqueStatuses.findIndex(currentSavedStatus => currentSavedStatus.slug === status.slug);
      let ancestry = [...parentIds];
      uniqueStatuses[index] = {
        ...uniqueStatuses[index],
        duplicateStatusId: status.id,
        ancestry,
      };
    } else {
      uniqueStatuses.push({ ...status });
    }
  });

  return uniqueStatuses;
};

const filterChildIds = (obj, childFilterKey, childIds, parent, baseList, unmutatedObj) => {
  if (!obj[childFilterKey]) return;

  obj[childFilterKey] = obj[childFilterKey].split(",");
  unmutatedObj[childFilterKey] = unmutatedObj[childFilterKey].split(",");
  let removedChildIds = [];
  childIds.forEach(childId => {
    let index = obj[childFilterKey].indexOf(childId);
    if (index !== -1) {
      removedChildIds.push(obj[childFilterKey][index]);
      obj[childFilterKey].splice(index, 1);
    }
  });

  obj[childFilterKey] = Array.isArray(obj[childFilterKey]) ? obj[childFilterKey].join(",") : obj[childFilterKey];
  unmutatedObj[childFilterKey] = Array.isArray(unmutatedObj[childFilterKey])
    ? unmutatedObj[childFilterKey].join(",")
    : unmutatedObj[childFilterKey];

  if (!obj[childFilterKey]) delete obj[childFilterKey];

  parent?.child?.length &&
    removedChildIds.forEach(childId => {
      removeChildFilterCallback(baseList, parent.child[0], obj, childId, unmutatedObj);
    });
};

export const filterFalsyValues = arr => arr.filter(Boolean);
export const stringifyValues = arr => arr.map(value => String(value));

const removeDispositionFiltersFromSearch = (childFilterKey, baseList, parent, obj, searchValue, unmutatedObj) => {
  const isDispositionListInStatuses = parent?.list?.[0]?.dispositions;
  if (parent.key === "f[status.slug][]" || (parent.key === "f[status.id][]" && !isDispositionListInStatuses)) {
    let filteredChildrenList = baseList.filter(list => list.key === childFilterKey);
    filteredChildrenList =
      filteredChildrenList?.[0]?.list.filter(currentChild => String(currentChild.ancestry) === String(searchValue)) ||
      [];
    filteredChildrenList = filteredChildrenList.map(entry => String(entry.slug));
    filterChildIds(obj, childFilterKey, filteredChildrenList, parent, baseList, unmutatedObj);
  } else if (parent.key === "f[status.id][]" && isDispositionListInStatuses) {
    let parentFilter = parent.list.find(parent => Number(parent.id) === Number(searchValue));
    let filteredChildrenList = parentFilter?.dispositions.map(disposition => String(disposition.id)) || [];
    filterChildIds(obj, childFilterKey, filteredChildrenList, parent, baseList, unmutatedObj);
  }
};

const removeStatusFiltersFromSearch = (parent, obj, childFilterKey, baseList, unmutatedObj, searchValue) => {
  if (parent.key === "f[category_type.id][]") {
    if (obj["f[disposition.id][]"]) delete obj["f[disposition.id][]"];
  }
  const filteredChildrenListCallback = currentChild => {
    let ancestry = currentChild.ancestry;
    if (!Array.isArray(ancestry)) {
      ancestry = [ancestry];
    }
    ancestry = filterFalsyValues(ancestry);
    ancestry = stringifyValues(ancestry);
    return ancestry.includes(searchValue);
  };
  const childKey = parent.child[0];
  const childList = baseList.filter(list => list.key === childKey);
  let filteredChildrenList = childList?.[0]?.list.filter(filteredChildrenListCallback) || [];
  filteredChildrenList = filteredChildrenList.map(entry => String(entry.slug));
  filterChildIds(obj, childFilterKey, filteredChildrenList, parent, baseList, unmutatedObj);
};

const removeFiltersFromSearch = (parent, obj, baseList, childFilterKey, searchValue) => {
  const childKey = parent.child[0];
  const childList = baseList.filter(list => list.key === childKey);
  const filteredChildrenList =
    childList?.[0]?.list.filter(currentChild => String(currentChild.ancestry) === String(searchValue)) || [];
  const childIds = filteredChildrenList.map(entry => String(entry.id));
  filterChildIds(obj, childFilterKey, childIds, parent, baseList, clone(obj));
};

export const removeChildFilterCallback = (baseList, searchKey, obj, searchValue, unmutatedObj) => {
  const index = baseList?.findIndex(e => e.key === searchKey);
  const parent = baseList[index];
  const childFilterKey = baseList[index]?.child?.[0];
  if (!childFilterKey) return;
  switch (childFilterKey) {
    case "f[disposition.id][]": {
      removeDispositionFiltersFromSearch(childFilterKey, baseList, parent, obj, searchValue, unmutatedObj);
      break;
    }
    case "f[status.slug][]": {
      removeStatusFiltersFromSearch(parent, obj, childFilterKey, baseList, unmutatedObj, searchValue);
      break;
    }
    default: {
      removeFiltersFromSearch(parent, obj, baseList, childFilterKey, searchValue);
    }
  }
};

export const removeChildFilter = (baseList, searchKey, filterObj, searchValue) => {
  removeChildFilterCallback(baseList, searchKey, filterObj, searchValue, clone(filterObj));
};

export const addQueryFilterArrayPreSelected = (filters, searchKey, value, name, baseList, cb) => {
  const obj = { ...filters };
  const searchKeyValues = searchKey.split(".");
  const containsDynamicAndDealerEvaluation =
    searchKeyValues.includes("f[dynamic_attributes") && searchKeyValues.includes("dealer_evaluation]");
  // removeChildFilter(baseList, searchKey, obj);
  if (obj[searchKey]) {
    const array = obj[searchKey].split(",");
    const index = array.indexOf(containsDynamicAndDealerEvaluation ? name : value.toString());
    if (array.length > 1) {
      array.splice(index, 1);
      obj[searchKey] = array.join(",");
    } else {
      delete obj[searchKey];
      const index = baseList?.findIndex(e => e.key === searchKey);
      const childFilterKeys = baseList[index]?.child ?? [];
      if (!!childFilterKeys.length) {
        childFilterKeys.forEach(element => {
          delete obj[element];
        });
      }
    }
  }
  obj.page && delete obj.page;
  cb(obj);
};

export const checkValueForKeyInMultiSelectFilter = (filtersObject, key, valueToCheck) => {
  if (filtersObject[key]) {
    const values = filtersObject[key].split(",");
    return values.includes(valueToCheck);
  }
  return false;
};

export const getPlatformPath = (platform, action) => {
  const data = {
    auction: {
      editSettings: AUC_EDIT_USER_SETTINGS_PATH,
      applyLeave: AUCTION_APPLY_LEAVE_NEW_PATH,
    },
    crm: {
      editSettings: CRM_EDIT_USER_SETTINGS_PATH,
      applyLeave: CRM_APPLY_LEAVE_NEW_PATH,
    },
    inspection: {
      editSettings: INS_EDIT_USER_SETTINGS_PATH,
      applyLeave: INS_APPLY_LEAVE_NEW_PATH,
    },
  };
  return data[platform][action];
};

// Permissions

export const getPermission = (
  categories,
  actions = "",
  permissionObject = store.getState().PermissionsData.permissions || {}
) => {
  const getCategoryActionPermission = (perm, category, action) => {
    if (typeof action === "string") {
      return perm?.[category] && (perm?.[category]?.manage || perm?.[category]?.[action]);
    } else {
      let actionAll = true;
      action.forEach(e => {
        if (!perm?.[category]?.[e]) {
          actionAll = false;
        }
      });
      return perm?.[category] && (perm?.[category]?.manage || actionAll);
    }
  };

  if (permissionObject?.all) return true;

  if (typeof categories === "string") {
    return !!getCategoryActionPermission(permissionObject, categories, actions);
  } else {
    let categoriesAll = false;
    categories.forEach(e => {
      categoriesAll = !!getCategoryActionPermission(permissionObject, e, actions);
    });
    return categoriesAll;
  }
};

export const creatingSlotsForSkeleton = (
  interval,
  date = moment(),
  start,
  end,
  appendObj = {
    status: {
      bg_color: "#E0E0E0",
      color: "#E0E0E0",
    },
  }
) => {
  let startTime = start ? moment(start, "HH:mm") : moment(date, "HH:mm").startOf(interval);
  let endTime = end ? moment(end, "HH:mm") : moment(date, "HH:mm").endOf(interval);
  let slots = [];
  let slotIntervals = interval === "day" ? [30, 60] : interval === "week" ? [30, 40] : [200, 150];
  while (startTime < endTime) {
    let time = { ...appendObj };
    time.start =
      interval === "day" || interval === "week"
        ? new Date(startTime)
        : new Date(new Date(startTime).getTime() + 7 * 60 * 60 * 1000);
    startTime.add(slotIntervals[Math.floor(Math.random() * slotIntervals.length)], "minutes");
    time.end = new Date(startTime);
    time.id = generateUUID();
    slots.push(time);
  }
  return slots
    .slice(0, -1)
    .filter((value, index) =>
      interval === "day" ? index % 5 === 0 : interval === "week" ? index % 9 === 0 : index % 16 === 0
    );
};
// Errors

export const getErrorString = response => {
  const defaultError = strings.default_error;
  const getError = errors => {
    if (typeof errors === "string") {
      return errors.length < 250 ? errors : defaultError;
    } else if (!Array.isArray(errors)) {
      let errorString = "";
      Object.keys(errors || {}).forEach(e => {
        errorString = errorString + capitalize(e) + ": " + (errors[e] || strings.not_found) + "\n";
      });
      return errorString.length < 250 ? errorString : defaultError;
    } else {
      let errorString = errors.join("\n");
      return errorString.length < 250 ? errorString : defaultError;
    }
  };
  if (response?.errors) {
    return getError(response.errors);
  } else if (response?.message) {
    return getError(response.message);
  } else {
    return getError(response);
  }
};

// Time

export const getTimeDateString = (
  dateStr,
  timeZone,
  local,
  noTime,
  fullMonth,
  showSeconds = false,
  customFormat = false
) => {
  if (!!dateStr) {
    const format = `${noTime ? "" : `hh:mm${showSeconds ? ":ss" : ""} A, `}MMM${fullMonth ? "M" : ""} DD, YYYY`;
    if (timeZone) {
      dateStr = moment(dateStr).tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
      // dateStr = setTimeZone(timeZone, dateStr);
    }

    let date = new Date(
      typeof dateStr === "string" && dateStr.includes("Z") && !local ? dateStr.replace("Z", "") : dateStr
    );
    var datetime = moment(date).format(customFormat ? customFormat : format);
    return datetime;
  }
  return "";
};

export const getSeconds = date => date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();

export const setTimeZone = (timezone, date = null, nullForNoDate) => {
  let dateLocal = date ? new Date(date) : new Date();
  let momentObj = moment(dateLocal).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
  if (nullForNoDate && !date) {
    return null;
  }
  return new Date(momentObj);
};

export const stateUpdateCallback = (prev = [], data = []) => {
  const previousIds = extractIds(prev);
  return [...prev, ...data.filter(item => !previousIds.includes(item.id))];
};

export const getUTCOffset = timezone => {
  return moment().utcOffset(moment().tz(timezone).utcOffset()).format("Z");
};

export const appendTimeZone = (time, timeZone) => {
  return `${moment(time).format("YYYY-MM-DDTHH:mm:ss")}${getUTCOffset(timeZone)}`;
};
export const appendTimeZoneToLeaves = (time, timeZone) => {
  const formattedDate = moment.tz(time, timeZone).format("YYYY-MM-DDTHH:mm:ss");
  return `${formattedDate}${getUTCOffset(timeZone)}`;
};

export const validateFormikFields = (valuesArray, setError) => {
  let error = false;
  valuesArray.forEach(([key, value, condition = true, regex = ""]) => {
    if (!value && condition) {
      if (!!regex && !new RegExp(value).test(regex)) {
        setError(key, strings.field_not_valid_message);
      } else {
        setError(key, strings.field_required_message);
      }
      error = true;
    }
  });
  return error;
};

export const getCanScheduleDate = (inspections, inspection_id, timezone) => {
  const todayDate = setTimeZone(timezone);
  const inspection = inspections.find(e => e.id === inspection_id);
  const inspectionStartTime = setTimeZone(timezone, inspection?.service_start_time);
  const inspectionEndTime = setTimeZone(timezone, inspection?.service_end_time);

  // const getSeconds = (date) =>
  //   date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
  if (
    getSeconds(todayDate) > getSeconds(inspectionStartTime) &&
    getSeconds(todayDate) < getSeconds(inspectionEndTime) - inspection.duration * 60
  ) {
    return todayDate;
  } else if (getSeconds(todayDate) < getSeconds(inspectionStartTime)) {
    return new Date(
      todayDate.setHours(
        inspectionStartTime.getHours(),
        inspectionStartTime.getMinutes(),
        inspectionStartTime.getSeconds(),
        0
      )
    );
  } else {
    const d = new Date(todayDate.setDate(todayDate.getDate()));

    return new Date(
      d.setHours(inspectionStartTime.getHours(), inspectionStartTime.getMinutes(), inspectionStartTime.getSeconds(), 0)
    );
  }
};

export const getCurrentDayIntervals = (timeInterval, date, timeZone) => {
  const getStartDate = () => {
    let startDate = timeZone ? setTimeZone(timeZone) : new Date();
    startDate.setHours(0, 0, 0, 0);
    return startDate;
  };
  const getEndDate = () => {
    let endDate = timeZone ? setTimeZone(timeZone) : new Date();
    endDate.setHours(23, 59, 59, 0);
    return endDate;
  };

  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  let startDate = getStartDate();
  const endDate = getEndDate();
  let dateIncrement = 0;
  let dateIntervals = [];
  let interval = addMinutes(startDate, dateIncrement);
  while (interval.getTime() < endDate.getTime()) {
    dateIntervals.push(interval);
    dateIncrement = dateIncrement + timeInterval;
    interval = addMinutes(startDate, dateIncrement);
  }
  return dateIntervals;
};

const filterTimeIntervalsBeforeCurrentDate = currentDate => dateInterval => {
  if (!dateInterval) return false;
  return currentDate.getTime() > dateInterval.getTime();
};

export const excludeTodayTimes = (timeInterval, date, timeZone) => {
  if (!date) return [];
  const compareDate = timeZone ? setTimeZone(timeZone) : new Date();
  const isCurrentDay = moment(date).isSame(moment(compareDate), "day");
  if (!isCurrentDay) return [];
  const todayDate = timeZone ? setTimeZone(timeZone) : new Date();
  let dateIntervals = getCurrentDayIntervals(timeInterval, date, timeZone);
  let excludedTimes;
  excludedTimes = dateIntervals.filter(filterTimeIntervalsBeforeCurrentDate(todayDate));
  return excludedTimes;
};

export const toFixedWithoutRounding = (num, fixed) => {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
};

export const getReportScore = score => {
  let newScore = Number(score) / 10;
  if (Number.isInteger(newScore)) return newScore;
  return toFixedWithoutRounding(Math.floor(score) / 10, 1);
};

export const convertSecondsToReadableTime = value => (value ? moment.unix(value).utc().format("m [minutes]") : "");

/**
 * @params (Array<Object>) Each entry must have an id.
 * @return (Array<number>)
 */
export const extractIds = arr => arr.map(obj => obj.id);

// Lists

export const makeTeamsSelectData = cities => {
  return cities.map(city => {
    return {
      ...city,
      label: city.name,
      slug: city.id,
    };
  });
};
export const getRequiredEmiratesData = emirateData => {
  const data = makeEmiratesOptions(emirateData);
  return data;
};
export const makeEmiratesOptions = list => {
  return list.map(e => {
    return {
      ...e,
      label: e?.name_en,
      slug: e.slug,
    };
  });
};
export const getAdPostSelectionPrefilledData = ({ data, key, formikKey, makeList, property }) => {
  let requiredData = makeList(data);
  let value = requiredData.find(item => item[property] === key);
  return { formikKey, value };
};

export const getAdPostCheckboxesPrefilledData = (data, value, key, setFieldValue, formikKey) => {
  if (data && value.length === 0) {
    let value = data.filter(obj => key.includes(obj.value));
    return { formikKey, value };
  }
};
export const getAdPostInputPrefilledData = (value, key, setFieldValue, formikKey) => {
  return { formikKey, value: key };
};

const addPayloadIfFilterIsSelected = ({ str = null, setSlugAsKey = null, e = null, verifySlugOnly = false }) => {
  if (!str || !e) return {};
  let key = setSlugAsKey ? e.slug : e.id;
  const keyExists = str.split(",").includes(String(key));
  return keyExists ? { selected: true } : {};
};

export const makeListToSelectData = (list = [], str, setSlugAsKey, verifySlugOnly, key) => {
  let formattedList = list.map(e => {
    return {
      ...e,
      label: e?.name || e?.select_value || e?.label,
      slug: setSlugAsKey ? e.slug : e.id,
      ...addPayloadIfFilterIsSelected({ str: str, setSlugAsKey: setSlugAsKey, e: e, verifySlugOnly: verifySlugOnly }),
    };
  });
  return formattedList;
};

export const formatOptionsForReactSelect = (list, filterValues) => {
  let parsedFilterValues = filterValues && filterValues.split(",").map(filterValue => Number(filterValue));
  let formatListReducer = option => {
    let id = option.id;
    let label = option?.name || option?.select_value;
    let slug = option.slug ?? option.id;
    let selected = false;
    if (parsedFilterValues) {
      selected = parsedFilterValues.includes(Number(option.id));
    }
    return {
      ...option,
      id,
      label,
      slug,
      selected,
    };
  };
  return list.map(formatListReducer);
};

export const getLeadTypeCurrencyLabel = (e, country) =>
  e?.name + (getCurrency(country, e?.price) ? ` (${getCurrency(country, e?.price)})` : "");

export const makeLeadTypeListToSelectData = (list, str, country) => {
  let newList = [];

  const inspections = list
    .map(item => item.inspection.name)
    .filter((value, index, self) => self.indexOf(value) === index);

  inspections.forEach(inspection => {
    newList.push({
      id: inspection,
      name: inspection,
      label: inspection,
      slug: inspection,
      header: true,
    });
    newList = [
      ...newList,
      ...list
        .filter(e => e.inspection.name === inspection)
        .map(e => ({
          ...e,
          id: e.id,
          name: e.name,
          label: getLeadTypeCurrencyLabel(e, country),
          slug: e.name,
          ...(str && (str == e.id || str.includes(e.id)) ? { selected: true } : {}),
        })),
    ];
  });

  return newList;
};

export const makeAresSelectionData = areas => {
  return areas.map(e => {
    return {
      ...e,
      label: e.name,
      slug: e.id,
    };
  });
};

export const makePossibleStatusesSelectData = (model, statuses) => {
  const selectStatuses = [];
  statuses.forEach(status => {
    if (status.action && getPermission(model, status.action)) {
      selectStatuses.push({
        id: status.id,
        name: status.name,
        label: status.name,
        slug: status.slug,
        header: true,
      });
      status.dispositions.forEach(disposition => {
        selectStatuses.push({
          ...disposition,
          status_id: disposition?.status_id,
          label: disposition.name,
          slug: disposition.slug,
          action: status.action,
        });
      });
    }
  });
  return selectStatuses;
};

export const makePossibleStatusesContractSelectData = (model, statuses) => {
  const selectStatuses = [];
  statuses.forEach(status => {
    selectStatuses.push({
      id: status.id,
      name: status.name,
      label: status.name,
      slug: status.slug,
      header: true,
    });
    status.dispositions.forEach(disposition => {
      selectStatuses.push({
        ...disposition,
        status_id: disposition?.status_id,
        label: disposition.name,
        slug: disposition.slug,
        action: status.action,
      });
    });
  });
  return selectStatuses;
};

export const makeDispositionSelectData = (dispositions, statuses = [], shouldAncestryBeId = false) => {
  const getCurrentDispositionStatusSlug = item => {
    let currentDispositionStatus = statuses.find(status =>
      [status.id, status.duplicateStatusId].includes(item.status_id)
    );
    return currentDispositionStatus?.slug;
  };
  const disp = [];

  Object.keys(dispositions).forEach(e => {
    disp.push({
      id: e,
      name: e,
      label: e,
      slug: e,
      header: true,
    });
    dispositions[e].forEach(item => {
      let ancestry =
        shouldAncestryBeId || isEmptyArray(statuses) ? item.status_id : getCurrentDispositionStatusSlug(item);
      disp.push({
        ...item,
        label: item.name,
        slug: item.id,
        status_id: item.status_id,
        ancestry,
      });
    });
  });
  return disp;
};

// App paths

export const setAppOnRoute = (dispatch, ACTION, platformAvailable) => {
  const url = new URL(window.location.href);
  const app = url.pathname.split("/")?.[2];
  if (platformAvailable && app !== getCookie("currentApp")) {
    dispatch({
      type: ACTION,
      payload: {
        currentApp: app,
      },
    });
    setCookie("currentApp", app);
  }
};
export const getCurrentApp = (key = getCookie("currentApp")) => {
  const url = new URL(window.location.href);
  const app = url.pathname.split("/")?.[2];
  switch (key) {
    case "auction":
      return "auction";
    case "crm":
      return "crm";
    case "inspection":
      return "inspection";
    default:
      return app;
  }
};

export const getAppPath = (key = getCookie("currentApp")) => {
  switch (key) {
    case "auction":
      return AUC_DASHBOARD_PATH;
    case "crm":
      return CRM_DASHBOARD_PATH;
    default:
      return INS_DASHBOARD_PATH;
  }
};

export const getAppToggleLabel = (key = getCookie("currentApp")) => {
  switch (key) {
    case "auction":
      return "Auction";
    case "crm":
      return "CRM";
    default:
      return "Inspection";
  }
};

export const getFullAppTogglePath = key => {
  if (key === "auction") {
    return AUC_DASHBOARD_PATH;
  } else if (key === "inspection") {
    return INS_DASHBOARD_PATH;
  }
};
export const updateAppKey = key => {
  setCookie("currentApp", key);
};

// Extras
export const slugToPlainText = str => {
  return capitalize(str?.replace(/[_-]/g, " "));
};

export const capitalize = str => {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const formattingStringWithDashes = value => {
  return capitalize(value).replace("-", " ");
};

export const formatStringToAbbrevation = str => {
  if (typeof str !== "string") return "";
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase())
    .join(".");
};

export const formatPascalCaseProperty = str => {
  return str
    .split("_")
    .map(word => capitalize(word))
    .join(" ");
};

export const formattingStringWithUnderScores = value => {
  return value
    .split("_")
    .map(item => capitalize(item))
    .join(" ");
};
export const getUniqueObjects = array => [...new Set(array.map(item => JSON.stringify(item)))].map(e => JSON.parse(e));

export const getSimilarItemsFormArray = (arr1, arr2) => {
  const array1 = arr1.map(e => JSON.stringify(e));
  const array2 = arr2.map(e => JSON.stringify(e));

  return [
    ...new Set([...array1.filter(value => array2.includes(value)), ...array2.filter(value => array1.includes(value))]),
  ].map(e => JSON.parse(e));
};

export const FiletoBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const generateUUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const getCurrency = (country, number) => {
  const { country_code, currency_code } = country;
  if ((country_code && currency_code) || number) {
    return `${currency_code}  ${new Intl.NumberFormat(`en-${country_code}`, {
      // style: "currency",
      // currency: currency_code,
      minimumFractionDigits: 0,
    }).format(number)}`;
  }
  return null;
};

export const formateNumberWithCommas = number => {
  return number.toLocaleString("en-US");
};

export const getCurrencyWithoutSpace = (country, number) => {
  const { country_code, currency_code } = country;
  if ((country_code && currency_code) || number) {
    return `${currency_code}${new Intl.NumberFormat(`en-${country_code}`, {
      // style: "currency",
      // currency: currency_code,
      minimumFractionDigits: 0,
    }).format(number)}`;
  }
  return null;
};

export const removeAppCookies = () => {
  removeCookie("client");
  removeCookie("uid");
  removeCookie("refresh-token");
  removeCookie("authorization");
  removeCookie("userInfo");
  // removeCookie("currentApp");
};

export const getAppRootPath = () => {
  const app = getCookie("currentApp");
  if (app === "crm") {
    return CRM_PATH;
  } else if (app === "auction") {
    return AUCTION_PATH;
  } else {
    return INSPECTION_PATH;
  }
};

export const getPath = (path, id = undefined, root = undefined, type = undefined) => {
  const appPath = type ? "/" + type : getAppRootPath();
  return root ? `${appPath}/${path}` : id ? `${appPath}/${path}/:id/edit`.replace(":id", id) : `${appPath}/${path}/new`;
};

export const getCarTitle = (car, ref) => {
  return (
    (ref
      ? !isFieldRestricted(car?.car?.reference_number) && car?.car?.reference_number
        ? `${car?.car?.reference_number} - `
        : !isFieldRestricted(car?.reference_number)
        ? `${car?.reference_number} - `
        : ""
      : "") +
    (car?.title
      ? car.title
      : (!isObjRestricted(car?.make, ["select_value"]) && car?.make?.select_value ? car?.make?.select_value : "") +
        " " +
        (!isObjRestricted(car?.model, ["select_value"]) && car?.model?.select_value ? car?.model?.select_value : "") +
        " " +
        (!isObjRestricted(car?.variant, ["select_value"]) && car?.variant?.select_value
          ? car?.variant?.select_value
          : "") +
        " " +
        (!isObjRestricted(car?.year, ["select_value"]) && car?.year?.select_value ? car?.year?.select_value : ""))
  );
};

export const getTasksByStatuses = (taskStatuses = [], tasks = []) => {
  const taskObject = {};
  const sortedTasks = tasks.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  taskStatuses.forEach(e => {
    const list = sortedTasks.filter(item => item.status.slug === e.slug);
    taskObject[e.name] = [...list];
  });
  return taskObject;
};

export const getTasksByCurrentAndPreviousStatuses = (taskStatuses = [], tasks = [], previousCategories = []) => {
  const taskObject = {};

  let taskPreviousCategoryTypes = [];
  if (previousCategories?.length) {
    taskPreviousCategoryTypes = previousCategories?.map(cateogryType => {
      return {
        ...cateogryType,
        name: `Previous - ${cateogryType.name}`,
      };
    });
  }
  const updatedTaskStatuses = [...taskStatuses, ...taskPreviousCategoryTypes];

  updatedTaskStatuses.forEach(e => {
    const currentName = e.name;
    const currentStatus = e.slug;
    const currentStatusId = e.id;
    const list = tasks.filter(item => {
      if (item.status.slug === TASKS_STATUSES_SLUGS.COMPLETED) {
        if (item?.category_type_id === currentStatusId) {
          return true;
        } else if (
          item.status.slug === currentStatus &&
          !updatedTaskStatuses.some(status => status.id === item.category_type_id)
        ) {
          return true;
        }
      } else {
        return item.status.slug === currentStatus;
      }
    });
    taskObject[currentName] = [...list];
  });

  return taskObject;
};

export const getYears = (back = 50, start = 3) => {
  const year = parseInt(moment().add(start, "years").format("YYYY"));
  // new Date().getFullYear();
  return Array.from({ length: back }, (v, i) => year - back + i + 1 + "").reverse();
};

export const getAuctionCyclesList = MAX => {
  let list = [];
  for (let i = 0; i <= MAX; i++) {
    list.push({
      id: i,
      name: `${i}`,
      filterChipLabel: `${i} Auction Cycle${i > 1 ? "s" : ""}`,
    });
  }
  return list;
};

export const platformsCheck = (existing, newObj) => {
  if (existing.length !== newObj.length) {
    return true;
  } else {
    const values = existing.filter((item, index) => {
      return item.id !== newObj[index]["id"];
    });
    return values.length ? true : false;
  }
};

export const appendFromData = (formData, values, name) => {
  if (!name || Array.isArray(name)) {
    (name || Object.keys(values)).forEach(e => appendFromData(formData, values[e], e));
    return formData;
  }
  if (!values && name) formData.append(name, "");
  else {
    if (typeof values === "object") {
      const isArray = Array.isArray(values);
      Object.keys(values).forEach(key => {
        if (typeof values[key] === "object") {
          values[key] instanceof Date || values[key] instanceof File
            ? formData.append(`${name}${isArray ? "[]" : `[${key}]`}`, values[key])
            : appendFromData(formData, values[key], `${name}${isArray ? "[]" : `[${key}]`}`);
        } else
          (!!values[key] || values[key] === 0 || values[key] === false) &&
            formData.append(`${name}${isArray ? "[]" : `[${key}]`}`, values[key]);
      });
    } else (!!values || values === 0 || values === false) && formData.append(name, values);
  }
  return formData;
};

export const openUrlNewTab = id => {
  const url = new URL(window.location.href);
  window.open(`${url.origin}/inspections/${id}/compliance_report`, "_blank");
};

export const openBankEvaluationReport = id => {
  openUrlLinkInNewTab(`/inspections/${id}/bank_evaluation`);
};

export const openComplianceReport = id => {
  openUrlLinkInNewTab(`/inspections/${id}/compliance_report`);
};

export const openUrlLinkInNewTab = url => {
  if (!url || typeof url !== "string") {
    Toast.error("Invalid URL:", url);
    return;
  }
  const baseUrl = new URL(window.location.href);
  window.open(baseUrl.origin + url, "_blank").focus();
};

export const openBulkImportUrl = url => {
  window.open(url, "_blank");
};

export const validateURL = string => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const downloadPDF = (url, name) => {
  fetch(url)
    .then(response => {
      if (response.ok) {
        return response.blob();
      }
      throw new Error("Tax Invoices download failed");
    })
    .then(blob => {
      const downloadLink = document.createElement("a");
      const fileName = `${name}.pdf`;
      const blobUrl = URL.createObjectURL(blob);

      downloadLink.href = blobUrl;
      downloadLink.download = fileName;
      downloadLink.click();
      URL.revokeObjectURL(blobUrl);
    })
    .catch(error => {
      console.error("Error downloading PDF:", error);
      Toast.error(getErrorString(error));
    });
};

export const routerCleanUp = () => {
  store.dispatch({
    type: FLUSH_DYNAMIC_FILTERS_DATA,
    payload: {},
  });
};
export const isValidDate = date => moment(date, "YYYY-MM-DD").isValid();

export const getDynamicFieldsData = (item = {}, permittedDynamicColumns = []) => {
  const setValue = (data, value) => ({
    ...data,
    ...value,
  });

  const isNotObjectAndNull = data => typeof data !== "object" && data !== null;

  let dynamicFieldData = {};
  permittedDynamicColumns.forEach(e => {
    const keys = e.key.split(".");
    const attributeData = keys.reduce((acc, value) => acc?.[value], item);
    if (keys.length === 3 && attributeData) {
      if (attributeData?.type === DYNAMIC_FIELD_TYPES.USER_LIST) {
        dynamicFieldData = setValue(dynamicFieldData, { [e.name]: attributeData?.users?.at(0)?.name });
      } else if (attributeData?.type === DYNAMIC_FIELD_TYPES.ITEM_LIST) {
        dynamicFieldData = setValue(dynamicFieldData, {
          [e.name]: formatValueForDealerEvaluation(attributeData),
        });
      } else if (Array.isArray(attributeData)) {
        dynamicFieldData = setValue(dynamicFieldData, { [e.name]: attributeData.join(", ") });
      } else if (typeof attributeData === "boolean") {
        dynamicFieldData = setValue(dynamicFieldData, { [e.name]: attributeData === true ? strings.yes : strings.no });
      } else {
        if (isNotObjectAndNull(attributeData)) {
          dynamicFieldData = setValue(dynamicFieldData, { [e.name]: attributeData });
        }
      }
    } else {
      dynamicFieldData = setValue(dynamicFieldData, { [e.name]: "" });
    }
  });
  return dynamicFieldData;
};

export const getDynamicFieldChipValue = (dynamicFilters, key, value) => {
  const dynamicField = dynamicFilters?.find(e => e?.key === key?.split("[")?.[1]?.split("]")?.[0]);
  if (dynamicField?.options?.length) {
    if (dynamicField?.type === "single-select" || dynamicField?.type === "multi-select") {
      return {
        value: dynamicField?.options?.find(e => e.field_label === value)?.field_label,
        filterTitle: dynamicField?.name,
      };
    }
  } else if (dynamicField?.type === "boolean") {
    return {
      value: dynamicFilterTypeBooleanList?.find(e => String(e.slug) === String(value))?.name,
      filterTitle: dynamicField?.name,
    };
  } else {
    return {
      value: store.getState().DynamicFilterData.filterData[key]?.find(e => e.id == value)?.name,
      filterTitle: dynamicField?.name,
    };
  }
};

export const checkUndefinedApiParams = (id, name = "") => {
  if (id && id !== "undefined") return true;
  Toast.error(strings.something_went_wrong);
  return false;
};

export const isFieldRestricted = field => {
  if (field) {
    return field === strings.Restricted;
  } else {
    return false;
  }
};

export const isObjRestricted = (data = {}, keys = ["name"]) => {
  if (!data) return;
  if (isFieldRestricted(data)) return true;
  return keys.every(e => isFieldRestricted(data[e]));
};
export const fileIsUploading = files => files.filter(file => file.inError || !file.uploaded || file.uploading)?.length;

export const groupByOnKeys = (data, matchKey) => {
  const dataObj = {};
  let uniqueKeys = [
    ...new Set(data?.map(item => (item?.[matchKey]?.name ? item?.[matchKey]?.name : item?.[matchKey]))),
  ];
  uniqueKeys.forEach(e => {
    const filteredDataForASpecificKey = data.filter(item =>
      item?.[matchKey]?.name ? item?.[matchKey]?.name === e : item?.[matchKey] === e
    );
    filteredDataForASpecificKey.sort((a, b) => a.display_order - b.display_order);
    dataObj[e] = filteredDataForASpecificKey;
  });
  return dataObj;
};

export const parseDate = date => {
  const parsedDate = date.split("T");
  return parsedDate[0];
};

export const ifTypeObject = value => typeof value === "object" && !Array.isArray(value) && value !== null;

export const getUniqueColor = text => stc(text);

export const isProductionEnvironment = () => {
  return process.env.REACT_APP_ENVIRONMENT === "PRODUCTION";
};

export const getAppointmentEvents = (appointments, calenderDate, timeZone, setTimeZone, dateView) => {
  const events = [];
  const currentDay = new Date(calenderDate).getDay();
  appointments.forEach(it => {
    let d = setTimeZone(timeZone, it.due_date);
    d.setHours(d.getHours() - 1);
    const scheduleDate = new Date(it?.start_time).getDay();
    if (dateView === "day" && currentDay !== scheduleDate) {
      return;
    }
    events.push({
      ...it,
      // id: it?.task.id,
      color: it?.status?.color || it?.lead?.status?.color,
      bg_color: it?.status?.bg_color || it?.lead?.status?.bg_color,
      start: setTimeZone(timeZone, it?.start_time ? it?.start_time : d.toISOString()),
      end: setTimeZone(timeZone, it?.end_time),
    });
  });
  return events;
};

export const routingCarEdit = car => {
  const replacePath = path => path.replace(":id", car.id);
  const ifPlatformIsAuction = window.location.href.includes("auction");
  const ifCarIsSold = car.inventory?.status?.slug === "sold";
  if (ifPlatformIsAuction) {
    return ifCarIsSold ? replacePath(AUC_CARS_EDIT_SOLD_CAR_PATH) : replacePath(AUC_CARS_EDIT_PATH);
  } else {
    return ifCarIsSold ? replacePath(CRM_CARS_EDIT_SOLD_CAR_PATH) : replacePath(CRM_CARS_EDIT_PATH);
  }
};

const checkAdminPermittedAttributePermissions = () => store.getState().PermissionsData?.activeColumns?.all === true;

export const isFieldViewable = activeColumnPayload =>
  checkAdminPermittedAttributePermissions() || activeColumnPayload?.is_readable === true;

export const isFieldEditable = activeColumnPayload =>
  checkAdminPermittedAttributePermissions() || activeColumnPayload?.is_editable === true;

export const addPermittedProperty = (payload, propertyObj, addPropertyIfFieldEditable = false) => {
  const handleEditablePayloadPropertyConditional = addPropertyIfFieldEditable ? isFieldEditable(payload) : true;
  if (checkAdminPermittedAttributePermissions()) return { ...propertyObj };
  if (isFieldViewable(payload) && handleEditablePayloadPropertyConditional) {
    return { ...propertyObj };
  }
  return {};
};

export const handleEmptyFormPayload = (obj, modal) => {
  if (!isEmptyObject(obj)) return true;
  Toast.error(strings.something_went_wrong);
  console.log(`cannot post empty ${modal} object`);
  return false;
};

export const canEditPayment = (item = {}) => item.status?.slug !== PAYMENT_STATUSES.VERIFIED;

export const isEmptyObject = obj =>
  obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export const isEmptyArray = arr => arr && Array.isArray(arr) && arr?.length === 0;

export const mapLinksPayloadToTypes = links => {
  const linkPayload = {};
  links.forEach(link => {
    if (!linkPayload.hasOwnProperty(link.link_type)) {
      linkPayload[link.link_type] = [];
      linkPayload[link.link_type].push(link);
    } else {
      linkPayload[link.link_type].push(link);
    }
  });
  return linkPayload;
};

export function clone(obj) {
  if (typeof obj == "function") {
    return obj;
  }
  var result = Array.isArray(obj) ? [] : {};
  for (var key in obj) {
    // include prototype properties
    var value = obj[key];
    if (value && (Object.getPrototypeOf(value) === Object.prototype || Array.isArray(value))) {
      result[key] = clone(value);
    } else {
      result[key] = value;
    }
  }
  return result;
}

export const shortenString = (originalString, maxLength = 15, newLength = 12, optionalAppendedString = "") => {
  return originalString?.length > maxLength
    ? `${originalString.slice(0, newLength)}${optionalAppendedString}`
    : originalString;
};
export const parseLocationType = meeting => {
  const [areaKey, sellerAddressKey, locationKey] = [meeting.area, meeting.time_slot, meeting.location];
  if (locationTypeExists(areaKey)) return TASK_MEETING_TYPES.AREA;
  else if (locationTypeExists(sellerAddressKey)) return TASK_MEETING_TYPES.SELLER_ADDRESS;
  else if (locationTypeExists(locationKey)) {
    switch (locationKey.location_type) {
      case "branch":
        return TASK_MEETING_TYPES.BRANCHES;
      case "warehouse":
        return TASK_MEETING_TYPES.WAREHOUSE;
      default:
    }
  }
};

const locationTypeExists = meetingKey => meetingKey && Object.keys(meetingKey)?.length;
export const setTabForRescheduledMeeting = (rescheduleMeeting, setTabIndex) => {
  if (rescheduleMeeting.time_slot) {
    setTabIndex(0);
  }
  if (rescheduleMeeting.area) {
    setTabIndex(1);
  }
};

export const ifLeadSourceExists = (leadSource = {}) => leadSource?.source && leadSource?.medium && leadSource?.campaign;

export const isValidNumber = (value = "") => !!String(value);

export const formatNumberString = (num, compact = true) => {
  return new Intl.NumberFormat("en", {
    ...(compact ? { notation: "compact" } : {}),
    // compactDisplay: "long",
    unitDisplay: "narrow",
    // minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
};

export const formatPhoneNumberWithPlus = ({ phoneNumber = "" }) => {
  if (phoneNumber.startsWith("00")) {
    return phoneNumber.replace(/^00/, "+");
  }
  return phoneNumber;
};

export const subtractSeconds = (date, seconds = 1) => moment(date).subtract(seconds, "seconds").format();

export const getDateTimeDiff = (startDataTime, endDateTime) => {
  if (!startDataTime || !endDateTime) return "-";
  let startDate = new Date(startDataTime);
  let endDate = new Date(endDateTime);
  startDate = moment(startDate, "DD-MM-YYYY hh:mm:ss");
  endDate = moment(endDate, "DD-MM-YYYY hh:mm:ss");
  let hours = endDate.diff(startDate, "hours");
  let minutes = endDate.diff(startDate, "minutes");
  let timeDiff = "";
  if (!isNaN(hours) && hours !== 0) {
    timeDiff = `${hours} hr `;
  }
  if (!isNaN(minutes)) {
    let min = !isNaN(hours) && minutes - hours * 60;
    timeDiff = timeDiff.concat(`${min} min`);
  }
  return timeDiff;
};

export const ConditionalLink = ({ children, to, condition }) =>
  !!condition && to ? <Link to={to}>{children}</Link> : <>{children}</>;

export const sortArrayAlphabetically = (data = [], key = "") => data.sort((a, b) => a[key].localeCompare(b[key]));

export const ifFuelTypeIsElectric = (value = {}) => value?.name === "Electric";

export const CustomMenuItem = props => {
  const { option } = props;
  return (
    <div id="custom-menu-item">
      <div>{option.name ?? "(no name)"}</div>
      <div>{option.email}</div>
    </div>
  );
};
export const showExtraFields = (value = {}) =>
  value?.enable_car_information === true && !isFieldRestricted(value?.enable_car_information);

export const canUpdateInventoryData = (data = {}) => {
  const canUpdateSoldInventory =
    data.inventory?.status?.slug === "sold" && getPermission("Car", "update_sold") && getPermission("Car", "edit");
  const canUpdateInventory =
    data.inventory?.can_be_edited === true && getPermission("Car", "update") && getPermission("Car", "edit");
  return canUpdateSoldInventory || canUpdateInventory;
};

export const getFormattedTimeStringForMeetingCard = ({ timezone, startTime, endTime }) => {
  const timeString = (format = "", time) =>
    moment(timezone ? setTimeZone(timezone, time) : time)
      .format(format)
      .toString();

  return `${timeString("MMM DD", startTime)}, ${timeString("h:mm A", startTime)} - ${timeString("h:mm A", endTime)} `;
};

export const makeAvailabilityHoursData = (timeslots = []) => {
  const timezone = store.getState()?.Configs?.tenant?.country.timezone;
  const utcOffset = getUTCOffset(timezone);
  return timeslots.map(item => ({
    ...item,
    start_time: formatTimeWithToday(item.start_time, utcOffset),
    end_time: formatTimeWithToday(item.end_time, utcOffset),
  }));
};
export const formatTimeWithToday = (dateTime = null, utcOffset) => {
  if (typeof dateTime !== "string" || !dateTime) return dateTime;
  const timeString = dateTime?.split("T")?.[1];
  const time = timeString.split("+")?.[0];
  const formattedTime = `${moment().format("YYYY-MM-DD")}T${time}${utcOffset}`;
  return formattedTime;
};

export const dropDownListToShow = (list = [], showItemKey = "showListItem") =>
  list?.filter(item => item[showItemKey] === true);

export const objectToQuery = (obj = {}) => {
  const arr = [];
  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value) && value?.length) {
      arr.push(getQueryStringFromArray(key, value));
    } else if (String(value)) {
      arr.push(`${key}=${value}`);
    }
  }
  return `?${arr.join("&")}`;
};

export const appendObjectToQuery = (obj = {}) => {
  const arr = [];
  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value) && value?.length) {
      arr.push(getQueryStringFromArray(key, value));
    } else if (String(value)) {
      arr.push(`${key}=${value}`);
    }
  }
  return `${arr.join("&")}`;
};

export const formatValuesForReactSelect = (obj = {}, key = "name") => ({
  id: -1,
  value: obj?.[key],
  ...obj,
});

export const getTenantAuctionCycles = () => {
  const auctionsCycleCount = store.getState()?.Configs?.tenant?.configuration?.auction_cycle_count;
  return auctionsCycleCount;
};

export const selectAllGroupField = (groups = {}) => {
  return groups.find(group => group.slug === "select_all")
    ? { all_groups: true }
    : { group_ids: groups.map(e => e.id) };
};

export const removeUnderscores = str => {
  if (str.includes("_")) {
    return str.replace(/_/g, " ");
  } else {
    return str;
  }
};
export const formatValuesForAsyncSelect = (values, property) => {
  return values[property] ? [{ ...values[property], label: values[property]?.name }] : [];
};

export const isValueObject = value => typeof value === "object" && value !== null;

export const makeCallOutcomeObject = (obj = {}) => {
  if (isEmptyObject(obj) || obj === null) return;
  obj["label"] = obj?.name;
  return obj;
};

export const makeCallOutcomeOptions = (outcomes = null) => {
  if (outcomes && outcomes.length === 0) return;
  return outcomes?.map(obj => {
    return {
      ...obj,
      label: obj?.name,
    };
  });
};

export const capitalizeFirstLetters = name => {
  if (!name || typeof name !== "string") return "";
  const formattedName = name
    .split(" ")
    .map(word => capitalize(word))
    .join(" ");
  return formattedName;
};

export const checkDaysPresent = (startTime = "") => {
  if (!startTime) return false;

  const startMoment = moment(startTime);
  if (!startMoment.isValid()) return false;

  const now = moment();
  const daysDifference = now.diff(startMoment, "days");

  return daysDifference > 0;
};

export const daysBetween = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays.toString();
};

export const getDaysBetweenDates = (startDate, endDate) => {
  const date1 = moment(startDate).format("YYYY-MM-DDTHH:mm:ss");
  const date2 = moment(endDate).format("YYYY-MM-DDTHH:mm:ss");
  const start = moment(date1);
  const end = moment(date2);
  let numDays = end.diff(start, "days");
  const days = [];
  for (let i = 0; i <= numDays; i++) {
    const date = moment(start).add(i, "days");
    const formattedDate = date.format("YYYY-MM-DD");
    days.push(formattedDate);
  }
  return days;
};

export const formatDateWithMonthAndDayName = dateString => {
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const date = new Date(dateString);
  const dayOfMonth = date.getDate();
  const monthName = monthsOfYear[date.getMonth()];
  const dayOfWeek = daysOfWeek[date.getDay()];
  return `${dayOfMonth}${
    dayOfMonth === 1 ? "st" : dayOfMonth === 2 ? "nd" : dayOfMonth === 3 ? "rd" : "th"
  } ${monthName}, ${dayOfWeek}`;
};

export const formatArrayWithStringsForReactSelect = (list = []) => {
  return list.map((val, index) => ({ value: val, id: index + 1 }));
};

export function getContractHelper(contractType, contractTypeMetaData, modelName = "") {
  function filterDynamicFields({ dynamicFieldsData }) {
    const dynamicKeys = contractTypeMetaData?.[contractType?.slug]?.dynamicAttributeKeys ?? {};
    const dynamicFields = dynamicFieldsData?.dynamic_fields ?? [];
    const newDynamicFieldsData = {
      ...dynamicFieldsData,
      dynamic_fields: dynamicFields.filter(dynamicField => !!dynamicKeys?.[dynamicField.field_name]),
    };

    return newDynamicFieldsData;
  }

  function filterAllFormValues(formValues) {
    const staticFormValues = filterStaticFormValues(formValues);
    const dynamicFormValues = filterDynamicFormValues(formValues);
    const newFormValues = { ...staticFormValues, ...dynamicFormValues };
    return newFormValues;
    // let allKeys = contractTypeMetaData?.[contractType?.slug] ?? {};
    // allKeys = { ...allKeys.dynamicAttributeKeys, ...allKeys.staticAttributeKeys };
    // let newFormValues = filterFormValues(formValues, allKeys);
    // return newFormValues;
  }

  function filterFormValues(formValues, properties) {
    let newFormValues = Object.entries(formValues).reduce((prev, [property, value]) => {
      if (!properties[property]) return { ...prev };
      return {
        ...prev,
        [property]: value,
      };
    }, {});
    return newFormValues;
  }

  function filterStaticFormValues(formValues) {
    const staticKeys = contractTypeMetaData?.[contractType.slug]?.staticAttributeKeys ?? {};
    let newFormValues = filterFormValues(formValues, staticKeys);
    return newFormValues;
  }

  function filterDynamicFormValues(formValues) {
    let dynamicKeys = contractTypeMetaData?.[contractType.slug]?.dynamicAttributeKeys ?? {};
    if (dynamicKeys) {
      dynamicKeys = Object.keys(dynamicKeys).reduce((prev, dynamicFieldKey) => {
        return { ...prev, [`${modelName}_dynamic_${dynamicFieldKey}`]: true };
      }, {});
    }
    let newFormValues = filterFormValues(formValues, dynamicKeys);
    return newFormValues;
  }

  function hasStaticField(fieldKey) {
    const staticKeys = contractTypeMetaData?.[contractType.slug]?.staticAttributeKeys ?? {};
    return !!staticKeys?.[fieldKey];
  }

  return {
    filterDynamicFields,
    hasStaticField,
    filterAllFormValues,
    filterStaticFormValues,
    filterDynamicFormValues,
  };
}

export const convertSnakeCaseToTitleCase = s =>
  s.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()).replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

export const convertSnakeCaseToCamelCase = obj => {
  const camelCasedObj = {};
  obj &&
    Object.keys(obj).forEach(key => {
      const camelCasedKey = key.replace(/[-_]+(.)?/g, (_, chr) => (chr ? chr.toUpperCase() : ""));
      camelCasedObj[camelCasedKey] = obj[key];
    });
  return camelCasedObj;
};

export const addSeconds = (date, seconds = 1) => moment(date).add(seconds, "seconds").format();

export const calculateHours = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(addSeconds(endDate));
  const duration = moment.duration(end.diff(start));
  const hours = duration.asHours();
  return Math.floor(hours);
};

/**
 * Represents a time slot.
 * @typedef {Object} timeSlot
 * @property {string} start_time - The start time of the time slot in ISO 8601 format.
 * @property {string} end_time - The end time of the time slot in ISO 8601 format.
 */

/**
 * Example function that operates on a time slot.
 * @param {timeSlot[]} timeslots - array of available timeslots.
 */
export const mergeContiguousTimeSlots = timeslots => {
  const isTimeSlotContiguous = (currentTimeSlot, nextTimeSlot) => {
    return currentTimeSlot.totalEndTimeMin + 1 === nextTimeSlot.totalStartTimeMin;
  };

  /**
   * Represents a time slot.
   * @typedef {Object} formattedTimeSlots
   * @property {number} totalStartTimeMin - total minutes for a single day till start time.
   * @property {number} totalEndTimeMin - total minutes for a single day till end time.
   * @property {string} start_time - The start time of the time slot in ISO 8601 format.
   * @property {string} end_time - The end time of the time slot in ISO 8601 format.
   */
  /**
   * Example function that operates on a time slot.
   * @param {formattedTimeSlots[]} formattedTimeSlots - array of available timeslots.
   */
  const formattedTimeSlots = timeslots.map(timeslot => ({
    start_time: timeslot.start_time,
    end_time: timeslot.end_time,
    totalStartTimeMin: new Date(timeslot.start_time).getHours() * 60 + new Date(timeslot.start_time).getMinutes(),
    totalEndTimeMin: new Date(timeslot.end_time).getHours() * 60 + new Date(timeslot.end_time).getMinutes(),
  }));
  let mergedTimeSlots = [];
  for (let i = 0; i < formattedTimeSlots.length; i++) {
    if (i === formattedTimeSlots.length - 1) {
      mergedTimeSlots.push({
        start_time: formattedTimeSlots[i].start_time,
        end_time: formattedTimeSlots[i].end_time,
      });
      break;
    }
    let j = i;
    while (
      j < formattedTimeSlots.length - 1 &&
      isTimeSlotContiguous(formattedTimeSlots[j], formattedTimeSlots[j + 1])
    ) {
      j++;
    }

    mergedTimeSlots.push({
      start_time: formattedTimeSlots[i].start_time,
      end_time: formattedTimeSlots[j].end_time,
    });
    i = j;
  }
  return mergedTimeSlots;
};

export const isParentOptionSelected = ({ e, values, modelName, dynamicFieldsData }) => {
  if (!e.is_dependent) return true;
  let flag = false;
  dynamicFieldsData?.length &&
    dynamicFieldsData.forEach(entry => {
      if (entry.is_dependent || !["single-select", "multi-select"].includes(entry.field_data_type)) {
      } else {
        entry.dynamic_field_options.forEach(option => {
          if (option?.dependent_fields.length > 0) {
            const dependentFields = option?.dependent_fields ?? [];
            if (dependentFields.length > 0) {
              !isFieldRestricted(dependentFields) &&
                dependentFields.forEach(dependentField => {
                  if (dependentField.id === e.id) {
                    const parentValue = values[`${modelName}_dynamic_${entry.field_name}`];
                    let isDependent = {};
                    if (parentValue?.length > 0) {
                      for (let i = 0; i < parentValue?.length; i++) {
                        isDependent =
                          {
                            ...isDependent,
                            ...parentValue?.[i]?.dependentFields?.find(children => children.id === e.id),
                          } ?? {};
                      }
                    }
                    if (!!isDependent && Object.keys(isDependent)?.length) {
                      flag = true;
                    }
                  }
                });
            }
          }
        });
      }
    });
  return flag;
};

export const isParentOptionSelectedForRequiredChild = ({ dynamicFields }) => {
  let dependentFieldsData = {};
  const groupedDynamicFields = groupByOnKeys(dynamicFields, "dynamic_field_section") || {};
  const dynamicFieldsData = Object.keys(groupedDynamicFields).map((item, i) => {
    return groupedDynamicFields[item];
  });
  dynamicFieldsData.forEach(entry => {
    entry.forEach(option => {
      if (["single-select", "multi-select"].includes(option.field_data_type)) {
        const dependentFields = option.dynamic_field_options
          .filter(field => field?.dependent_fields?.length > 0)
          .map(field => field.dependent_fields);
        if (dependentFields.length > 0) {
          dependentFieldsData = {
            ...dependentFieldsData,
            ...{ [option.field_name]: dependentFields },
          };
        }
      }
    });
  });
  return dependentFieldsData;
};

export const formatValueForDealerEvaluation = value => {
  if (typeof value === "string") return value;
  else if (typeof value === "object" && value !== null) return value?.value?.label || value?.value?.at(0)?.label;
};

export const cookieObjForUserInfo = (obj = {}) => {
  if (isEmptyObject(obj) || obj === null) return;
  const { roles, platforms, ...userObjForCookie } = obj;
  return {
    roles,
    platforms,
    userObjForCookie,
  };
};

export const checkifTenantIsDubizzle = ({ tenant = {} }) => tenant?.slug?.includes(DUBIZZLE) ?? false;

export const replaceKeyWithId = (str = "", key, id) => str.replace(key, id);

export const formatAmountToAED = value => {
  return value && !isFieldRestricted(value) ? `AED ${value.toLocaleString()}` : "-";
};

export function flattenObject({ obj = {}, parentKey = "", separator = ".", flattened = {} }) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let newKey = parentKey ? `${parentKey}${separator}${key}` : key;

      if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively flatten nested objects
        Object.assign(flattened, flattenObject({ obj: obj[key], parentKey: newKey, separator }));
      } else {
        flattened[newKey] = obj[key];
      }
    }
  }

  return flattened;
}

export function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  let remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds %= 60;

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""}, ${minutes} minute${
      minutes > 1 ? "s" : ""
    }, ${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""}, ${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""}`;
  }
}

export const formatRestrictedKeys = ({ obj = {} }) =>
  Object.entries(obj).reduce((acc, [key, val]) => {
    acc[key] = (!!val && !isFieldRestricted(val) ? val : "-") ?? "-";
    return acc;
  }, {});

export function mergeArrays(...arrays) {
  return [].concat(...arrays);
}

// refactor these util functions
export function convertArrayToObject(array = []) {
  let resultArray = [];
  for (let i = 0; i < array?.length; i++) {
    let obj = {
      id: i + 1,
      name: array[i],
      slug: array[i],
    };
    resultArray.push(obj);
  }
  return resultArray;
}

export function extractValueFromQueryString(queryString, key) {
  const regex = new RegExp(`\\[${key}\\]=([^&]+)`);
  const match = queryString.match(regex);
  return match ? decodeURIComponent(match[1]) : null;
}

export function transformArray(inputArray) {
  return inputArray.map(obj => ({
    id: obj.external_id,
    name: obj.external_name,
  }));
}

export function findClosestMatch(slug, formikObj) {
  const keys = Object.keys(formikObj);

  function levenshteinDistance(a, b) {
    const dp = Array.from({ length: a.length + 1 }, (_, i) => Array.from({ length: b.length + 1 }, (_, j) => i + j));
    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        dp[i][j] = Math.min(dp[i - 1][j] + 1, dp[i][j - 1] + 1, dp[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1));
      }
    }
    return dp[a.length][b.length];
  }

  let minDistance = Infinity;
  let closestKey = null;
  keys.forEach(key => {
    if (!key.toLowerCase().includes("data")) {
      const distance = levenshteinDistance(slug, key.toLowerCase());
      if (distance < minDistance) {
        minDistance = distance;
        closestKey = key;
      }
    }
  });
  return closestKey;
}

export function generateSlugsArray(inputObject) {
  const slugsArray = [];

  for (const key in inputObject) {
    if (typeof inputObject[key] === "object" && inputObject[key] !== null) {
      slugsArray.push({
        slug: key,
        value: inputObject[key].value,
        name: inputObject[key].name !== null ? inputObject[key].name.toString() : "",
        label: inputObject[key].name !== null ? inputObject[key].name.toString() : "",
      });
    } else {
      slugsArray.push({
        slug: key,
        value: inputObject[key],
        name: inputObject[key] !== null ? inputObject[key].toString() : null,
      });
    }
  }

  return slugsArray;
}

export function makeDataKeyForAdPost(str) {
  return str + "Data";
}

export const getAdPostPayloadData = ({ data, key, formikKey, makeList, property }) => {
  let requiredData = makeList(data);
  let value = requiredData.find(item =>
    typeof item[property] === "string" ? item[property].toLowerCase() == key : item[property] == key
  );
  return { formikKey, value };
};

export function getShapedRangeSettings(values) {
  let { previousChargesSnapshot, ...rest } = values;

  let slabsEntries = Object.entries(rest);
  let marginSlabsAttributes = slabsEntries.map(([_, payload]) => {
    const { isNewService, formikKey, ...rest } = payload;
    return { ...rest };
  });

  let newMarginSlabsAttributes = marginSlabsAttributes.filter(marginSlabs => !marginSlabs.id);
  let savedMarginSlabsAttributes = marginSlabsAttributes
    .filter(marginSlabs => !!marginSlabs.id)
    .sort((a, b) => a.id - b.id);
  let previousMarginSlabsAttributes = [...previousChargesSnapshot].sort((a, b) => a.id - b.id);
  let updatedMarginSlabsAttributes = [];
  for (let i = 0; i < savedMarginSlabsAttributes.length; i++) {
    let [savedMarginSlabs, previousMarginSlabs] = [savedMarginSlabsAttributes[i], previousMarginSlabsAttributes[i]];

    let serviceProperties = Object.keys(savedMarginSlabs);
    if (savedMarginSlabs._destroy === true) {
      let { ...trimmedMarginSlabsAttributes } = savedMarginSlabs;
      updatedMarginSlabsAttributes.push(trimmedMarginSlabsAttributes);
    } else if (!serviceProperties.every(property => previousMarginSlabs[property] === savedMarginSlabs[property])) {
      let { ...trimmedMarginSlabsAttributes } = savedMarginSlabs;
      updatedMarginSlabsAttributes.push(trimmedMarginSlabsAttributes);
    }
  }

  let differentiatedAttributes = [...newMarginSlabsAttributes, ...updatedMarginSlabsAttributes];
  return differentiatedAttributes;
}
export const isKeyPresentInPermittedColumns = ({ permittedColumns = [], key = "" }) => {
  const index = permittedColumns.findIndex(column => column === key);
  return index > -1;
};

export const getResultantPaymentBreakdown = dataArray => {
  return dataArray.reduce((acc, { amount, comment, payment_type }) => {
    acc[payment_type] = { amount: parseInt(amount), comment };
    return acc;
  }, {});
};

export const getPaymentBreakdownArray = object => {
  return Object.entries(object).map(([key, value]) => {
    return {
      amount: value.amount,
      comment: value.comment,
      payment_type: key,
      slug: key,
      id: key,
    };
  });
};

export const camelCaseToTitle = camelCaseStr => {
  let words = camelCaseStr.replace(/([a-z])([A-Z])/g, "$1 $2").split(/[\s_]+/);
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  let titleCaseStr = words.join(" ");
  return titleCaseStr;
};
export function getValueByPath(obj, path) {
  if (isEmptyObject(obj) || !path) return;
  return path.split(".").reduce((acc, key) => {
    if (acc) {
      if (acc && key.includes("[") && key.includes("]")) {
        const arrayKey = key.slice(0, key.indexOf("["));
        const index = parseInt(key.match(/\d+/)[0]);
        return acc[arrayKey] && acc[arrayKey][index];
      }
      return acc[key];
    }
  }, obj);
}

export const isDateValid = dateStr => {
  return !isNaN(new Date(dateStr));
};

export const formatOnlyDate = date => {
  const d = new Date(date);
  if (!isDateValid(d)) {
    return date;
  }
  d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
  return d.toISOString().split("T")[0];
};

export const formatKittSectionData = (obj = {}) => {
  const greatData = {};
  const fairData = {};
  const riskyData = {};

  for (const key in obj) {
    if (key.startsWith(PRICE_CATEGORIES.GREAT)) {
      greatData[key] = obj[key];
    } else if (key.startsWith(PRICE_CATEGORIES.FAIR)) {
      fairData[key] = obj[key];
    } else if (key.startsWith(PRICE_CATEGORIES.RISKY)) {
      riskyData[key] = obj[key];
    }
  }
  return [greatData, fairData, riskyData];
};

export function findKeyInNestedArray(arr, searchKey) {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    if (`${item.key}` === `${searchKey}`) {
      return item;
    }
    if (item.children && item.children.length > 0) {
      const found = findKeyInNestedArray(item.children, searchKey);
      if (found) {
        return found;
      }
    }
  }

  return null;
}

export const generateChips = async (array, key, value, setAsyncFiltersSelected) => {
  let filterChip =
    array.find(
      item =>
        key === String(item.id) ||
        key === String(item.key) ||
        key === String(item.startDateKey) ||
        key === String(item.endDateKey)
    ) || array.find(item => key.includes(String(item.id)) || key.includes(String(item.key)));
  if (!filterChip) {
    return { value: "", filterTitle: "" };
  }

  let filterChipTitleObject = {};

  switch (filterChip.type) {
    case FILTER_TYPES.DROPDOWN:
    case FILTER_TYPES.DROPDOWN_V2:
      filterChipTitleObject = filterChip?.list
        ? filterChip?.list?.find(item => `${item.id}` === `${value}` || `${item.slug}` === `${value}`)
        : filterChip?.options?.find(item => `${item.id}` === `${value}` || `${item.slug}` === `${value}`);
      return {
        value: filterChipTitleObject?.name || filterChipTitleObject?.label || "",
        filterTitle: filterChip.label,
        filterChild: filterChip?.child ?? null,
      };

    case FILTER_TYPES.TREE_DROPDOWN:
      filterChipTitleObject = findKeyInNestedArray(filterChip?.list, value);
      return {
        value: filterChipTitleObject?.title || "",
        filterTitle: filterChip.label,
        filterChild: filterChip?.child ?? null,
      };

    case FILTER_TYPES.NESTED_CHECKBOX_DROPDOWN:
    case FILTER_TYPES.SCROLLABLE_DROPDOWN:
      let response = null;
      const keysWithFId = [
        "dynamic_attributes",
        "dre",
        "user",
        "location",
        "lead_source",
        "assignee",
        "created_by",
        "task_type",
        "inspectors",
        "initiated_by",
        "lead_type",
        "offers",
        "highest_bidder_id",
        "negotiation.purchaser.id",
        "report.evaluated_by.id",
        "evaluation.approved_by.id",
      ];

      const queryParams = keysWithFId.some(k => key.includes(k))
        ? { "f[id]": value }
        : { ...filterChip?.extraParams, "q[id_eq]": value };

      response = await filterChip?.listApi(objectToQuery(queryParams));

      const keysToCheck = [
        "item_lists",
        "users",
        "locations",
        "lead_sources",
        "inspections",
        "groups",
        "task_types",
        "initiated_by",
        "lead_types",
      ];
      let selectedKey = keysToCheck
        .map(key => response[key]?.[0] || (key === "lead_sources" ? response[key] : null))
        .find(value => value);
      if (key.includes("lead_source")) {
        const parentKey = Object.keys(selectedKey)[0];
        selectedKey = selectedKey[parentKey]?.[0] || null;
      }
      if (selectedKey) {
        setAsyncFiltersSelected(prevState => {
          const existingItems = prevState[key] || [];
          const itemExists = existingItems?.some(item => item.id === selectedKey?.id);

          if (!itemExists) {
            const result = {
              ...prevState,
              [key]: [...existingItems, selectedKey],
              filterChild: filterChip?.child ?? null,
            };
            return result;
          }
          return prevState;
        });
      }

      return {
        value:
          selectedKey?.name ||
          selectedKey?.label ||
          selectedKey?.address ||
          selectedKey?.source ||
          selectedKey?.email ||
          "",
        filterTitle: filterChip.label,
        filterChild: filterChip?.child ?? null,
      };

    case FILTER_TYPES.DATE:
      return { value: formatOnlyDate(value), filterTitle: filterChip.label };
    case FILTER_TYPES.MONTH_DATE_RANGE_FILTER:
      return { value: formatOnlyDate(value), filterTitle: filterChip.label };
    case FILTER_TYPES.INPUT:
    case FILTER_TYPES.RANGE:
      return { value, filterTitle: filterChip.label };

    default:
      return { value: "", filterTitle: "" };
  }
};

export const copyContent = async (val, message = "") => {
  try {
    await navigator.clipboard.writeText(val);
  } catch (err) {
    Toast.error(message);
  }
};

export const checkIfValueEqualsOther = val => {
  if (typeof val !== "string") return false;
  return val.trim().toLowerCase() === "other" || val.trim().toLowerCase() === "others";
};

export const mapTasksCount = (leadsData, showSellerLeads, leadTasksMap) => {
  const defaultSellerKeys = Object.keys(leadTasksMap.seller).reduce((acc, key) => {
    acc[key] = 0;
    return acc;
  }, {});

  const defaultBuyerKeys = Object.keys(leadTasksMap.buyer).reduce((acc, key) => {
    acc[key] = 0;
    return acc;
  }, {});

  const defaultVasKeys = Object.keys(leadTasksMap.vas).reduce((acc, key) => {
    acc[key] = 0;
    return acc;
  }, {});

  const tasks = {
    ...(showSellerLeads && {
      seller: {
        ...defaultSellerKeys,
        ...leadsData?.seller,
      },
    }),
    buyer: {
      ...defaultBuyerKeys,
      ...leadsData?.buyer,
    },
    vas: {
      ...defaultVasKeys,
      ...leadsData?.vas,
    },
  };

  return tasks;
};

export const formatPhoneNumber = number => {
  if (typeof number !== "string") return;
  let formattedNumber = number.trim();
  if (formattedNumber?.startsWith("+")) {
    formattedNumber = formattedNumber.substring(1);
  }

  if (formattedNumber?.startsWith("9")) {
    formattedNumber = "00" + formattedNumber;
  }
  return formattedNumber;
};

export const truncateText = (text = "", limit = 50, suffix = "...") => {
  if (!text) return "";
  return text.length > limit ? `${text.substring(0, limit)}${suffix}` : text;
};

export const hasExpiryReached = expiryDate => {
  if (!expiryDate || isNaN(new Date(expiryDate))) return false;
  return new Date(expiryDate) < new Date();
};

export const waitForEvent = ({
  addEventListener,
  removeEventListener,
  eventName,
  conditionCallback = () => true,
  timeoutDuration = 9000,
} = {}) => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      cleanup();
      reject(new Error(`${eventName} event timed out after ${timeoutDuration}ms`));
    }, timeoutDuration);

    const eventHandler = (...args) => {
      try {
        if (conditionCallback(...args)) {
          clearTimeout(timeout);
          cleanup();
          resolve(...args);
        }
      } catch (error) {
        cleanup();
        reject(error);
      }
    };

    const cleanup = () => {
      clearTimeout(timeout);
      if (removeEventListener) {
        try {
          removeEventListener(eventName, eventHandler);
        } catch (e) {
          Toast.error(`Failed to remove event listener for ${eventName}:`, e);
        }
      }
    };

    addEventListener(eventName, eventHandler);
  });
};
