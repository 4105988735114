export const COMMUNICATIONS_TYPES_SLUGS = {
  ALL: { slug: "all", name: "All" },
  WHATSAPP: { slug: "whatsapp", name: "Whatsapp" },
  EMAIL: { slug: "email", name: "Email" },
};

export const COMMUNICATION_STATUSES = Object.freeze({
  SUCCESS: {
    name: "Success",
    color: "#16915A",
    slug: "success",
    bg_color: "#C2F8E0",
  },

  FAILURE: {
    name: "Failure",
    color: "#BB2025",
    slug: "failure",
    bg_color: "#F8E9E980",
  },
});

export const UNIFONIC_COMPONENT_TYPES = {
  HEADER: "HEADER",
  BUTTONS: "BUTTONS",
  BODY: "BODY",
};

export const RESTRICTED_UNIFONIC_TEMPLATES = {
  NAMES_SLUGS: [],
  COMPONENTS_SLUGS: ["BUTTONS", "CAROUSEL"],
};

export const SENDER_LEAD_TYPES = {
  DEFAULT: {
    name: "Default",
    slug: "default",
  },
  SELLER: {
    name: "Seller",
    slug: "seller",
  },
  BUYER: {
    name: "Buyer",
    slug: "buyer",
  },
  VAS: {
    name: "Vas",
    slug: "vas",
  },
};
