import React, { useEffect, useState } from "react";
import cx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styles from "./ChevronUsersSidebar.module.scss";
import ChevronUserSection from "./users-section/ChevronUserSection";
import { ChevronSidebarLoader } from "../chevron-loaders";
import { strings } from "../../../../constant/strings";
import { fetchChevronUsersData } from "../../../../actions/crm";
import { selectChevronUsersData } from "../../../../reducers/crm/selectors";
import { checkAllFiltersApplied, getChevronUsersQueryString } from "../../../../helpers/CrmLeadsChevronUtils";
import { mapQueryStringToFilterObject } from "helpers/Util";

const ChevronUsersSidebar = () => {
  const history = useHistory();
  const [previousUserKey, setPreviousUserKey] = useState("");
  const chevronUsersDispatch = useDispatch();
  const chevronUsers = useSelector(selectChevronUsersData);
  const { chevronUsers: chevronUsersData } = chevronUsers;

  const fetchChevronUsers = () => {
    const usersQuery = getChevronUsersQueryString(history);
    if (previousUserKey && previousUserKey !== usersQuery) {
      fetchChevronUsersData(usersQuery)(chevronUsersDispatch);
      setPreviousUserKey(usersQuery);
    } else if (!previousUserKey) {
      fetchChevronUsersData(usersQuery)(chevronUsersDispatch);
      setPreviousUserKey(usersQuery);
    }
  };

  useEffect(() => {
    const filtersObj = mapQueryStringToFilterObject(history.location.search);
    if (checkAllFiltersApplied(filtersObj?.queryObj)) {
      fetchChevronUsers();
    }
  }, [history.location.search]);

  if (chevronUsers?.usersLoading) {
    return <ChevronSidebarLoader />;
  }

  return (
    <div className={cx(styles.usersSidebar, "d-flex flex-column p-3 p-absolute shadow")}>
      <div className="f-w-700 text-muted">{strings.chevron_sidebar_title}</div>
      <div
        className={cx(styles.sidebarItems, "scroll-y scrollbar-hidden")}
        style={{ "--box-height": "calc(100vh - 80px)" }}
      >
        <ChevronUserSection chevronUsersData={chevronUsersData} />
      </div>
    </div>
  );
};

export default ChevronUsersSidebar;
