export const NON_RESCHEDULABLE_STATUSES = Object.freeze([
  "done",
  "cancelled",
  "cancelled-at-branch",
  "cancelled-by-deposit-taken",
]);

export const APPOINTMENT_STATUSES_SLUGS = Object.freeze({
  RESCHEDULED: "rescheduled",
});
