import {
  ADD_CRM_LEAD,
  ADD_CRM_LEAD_FILTERS,
  CLEAR_CRM_LEADS,
  FETCH_CRM_LEADS,
  UPDATE_CRM_LEAD,
  SET_CRM_LEADS_LOADING,
} from "../../constant/actionTypes";
import { fetchCRMLeadsApi } from "../../helpers/ApiHelper";
import { appendFilterDataToStatuses, getErrorString } from "../../helpers/Util";
import query from "query-string";

export const clearCRMLeads = () => dispatch => {
  dispatch({
    type: CLEAR_CRM_LEADS,
  });
};

export const addCRMLead = item => dispatch => {
  dispatch({
    type: ADD_CRM_LEAD,
    payload: {
      item: { ...item },
    },
  });
};

export const updateCRMLead = item => dispatch => {
  dispatch({
    type: UPDATE_CRM_LEAD,
    payload: {
      item: { ...item },
    },
  });
};

export const fetchCRMLeads = queryString => async dispatch => {
  dispatch({ type: SET_CRM_LEADS_LOADING, payload: { loading: true } });
  const res = await fetchCRMLeadsApi(queryString);
  if (res) {
    const { crm_leads, call_outcomes, restrictions, category_types, ...rest } = res;
    let categoryTypes = category_types?.map(category_type => {
      let ancestry = res.categories.find(category => category_type.category_id === category.id).slug;
      return {
        ...category_type,
        ancestry,
      };
    });
    let newStatuses = appendFilterDataToStatuses(res.statuses, query);

    if (crm_leads) {
      dispatch({
        type: FETCH_CRM_LEADS,
        payload: {
          ...rest,
          statuses: newStatuses,
          list: crm_leads,
          loading: false,
          error: "",
          category_types: categoryTypes,
          call_outcomes: call_outcomes,
          restrictions: restrictions,
        },
      });
    } else {
      dispatch({
        type: FETCH_CRM_LEADS,
        payload: {
          list: [],
          statuses: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  }
};

export const addFilters = item => dispatch => {
  dispatch({
    type: ADD_CRM_LEAD_FILTERS,
    payload: {
      item: { ...item },
    },
  });
};
