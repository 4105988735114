export const LOCATION_FIELD_INPUTS = {
  CAPACITY_IN_HOURS: {
    label: "Capacity",
    MIN: 0,
    STEP: 1,
  },
  SHOW_UP_RATE: {
    label: "Show up rate",
    STEP: 0.1,
    MIN: 0,
    MAX: 1,
  },
};
