import React, { FC, useState } from "react";
import DropdownSelect from "../DropdownSelect";
import { SIRefresh } from "../../svg-icon/svg-icons";
import { strings } from "../../../constant/strings";
import { refreshOptions as defaultRefreshOptions } from "../../../constant/lists";
import useInterval from "../../../hooks/useInterval";

interface RefreshOption {
  id: number;
  name?: string;
  label?: string;
  value: number | boolean;
  slug?: string;
}

interface RefreshOptionsDropDownProps {
  refetchCallback: () => {};
  refreshContainerClassName?: string;
  optionalRefreshOptions?: RefreshOption[];
}

const RefreshOptionsDropDown: FC<RefreshOptionsDropDownProps> = ({
  refreshContainerClassName = "",
  refetchCallback,
  optionalRefreshOptions = [],
}) => {
  const refreshOptions = optionalRefreshOptions?.length ? optionalRefreshOptions : defaultRefreshOptions;
  const [selectedRefreshOption, setSelectedRefreshOption] = useState(refreshOptions[0]);

  const refreshOptionDelay: number = (selectedRefreshOption?.value as number) || 0;

  useInterval({
    callback: refetchCallback,
    delay: refreshOptionDelay * 60 * 1000,
    conditionals: !!refreshOptionDelay,
    deps: [refreshOptionDelay],
  });

  return (
    <div className={refreshContainerClassName}>
      <DropdownSelect
        labelAsValue={false}
        options={refreshOptions.filter(option => option.slug !== selectedRefreshOption.slug)}
        selected={selectedRefreshOption?.label ?? "Select Value"}
        onChange={e => {
          setSelectedRefreshOption(e);
        }}
        caret={false}
        icon={<SIRefresh />}
        label={`${strings.refresh}${!!selectedRefreshOption?.label ? ` (${selectedRefreshOption.label}) ` : ""}`}
        className={"auction-inventory-refresh"}
        optionClassName={"auction-inventory-refresh-option"}
      />
    </div>
  );
};

export default RefreshOptionsDropDown;
