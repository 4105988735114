import React from "react";
import cx from "clsx";
import { TabsRoundIcon } from "../../svg-icon/svg-icons";
import styles from "./SpecificationDetail.module.scss";

const SpecificationDetail = ({ label, description, currency, expense, style, key }) => {
  return (
    <div
      key={key}
      className={cx("d-flex justify-content-between pb-0", styles.expenseSpecification)}
      style={{ ...style }}
    >
      <div className={cx("d-flex align-items-center justify-content-center")} style={{ gap: 12 }}>
        <TabsRoundIcon color="currentColor" opacity="0.4" style={{ minWidth: "7px" }} />
        <dl className="mb-0">
          <dt>{label}</dt>
          <dd>{description}</dd>
        </dl>
      </div>

      {expense && <div className={cx(styles.cost, "mt-1")}>{expense}</div>}
    </div>
  );
};

export default SpecificationDetail;
