export const extractPayloadFromObj = ({ item, ...rest }) => {
  if (!item || !item.client || !item.client.uuid || !item.id) return {};
  let payload = {};
  payload.uuid = item.client.uuid;
  payload.associatedId = item.id;
  payload.value = item?.client?.value || null;

  return { ...payload, ...rest };
};

export const extractPayloadFromClientObj = ({ item, key = "phone", ...rest }) => {
  if (!item || !item.uuid) return {};

  let payload = {};
  payload.uuid = item.uuid;
  payload.value = item.value || item[key] || null;

  //   if (associatedId) payload.associatedId = associatedId;
  //   if (associatedType) payload.associatedType = associatedType;
  //   if (component) payload.component = component;
  return { ...payload, ...rest };
};
export const extractPayloadFromUserObj = ({ item, ...rest }) => {
  if (!item || !item.uuid) return {};

  let payload = {};
  payload.uuid = item.uuid;
  payload.value = item.value || item.phone || null;

  //   if (associatedId) payload.associatedId = associatedId;
  //   if (associatedType) payload.associatedType = associatedType;
  //   if (component) payload.component = component;
  return { ...payload, ...rest };
};
