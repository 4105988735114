import React, { useEffect } from "react";

const useOnScroll = ({ handleScroll = () => {}, onScrollElement }) => {
  useEffect(() => {
    // Attach a scroll event listener to the document
    document.addEventListener("scroll", handleScroll);
    if (onScrollElement) onScrollElement.addEventListener("scroll", handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("scroll", handleScroll);
      if (onScrollElement) onScrollElement.removeEventListener("scroll", handleScroll);
    };
  }, [onScrollElement]);
};
export default useOnScroll;
