import React, { useRef, useState, useEffect } from "react";
import { Modal } from "..";
import { strings } from "../../../constant/strings";
import AddTaskReminderForm from "../../forms/AddTaskReminderForm";
import { addPermittedProperty, appendTimeZone } from "../../../helpers/Util";
import { useSelector } from "react-redux";
import { selectActiveColumns, selectTimeZone } from "../../../reducers/general/selectors";

function AddTaskReminderModal({
  reminderId = "",
  task,
  reminderModal,
  setReminderModal,
  isEditTaskForm,
  setFieldValue,
  toggleTaskModal,
  reminder: newReminder,
}) {
  const reminderFormformikRef = useRef(null);
  const [reminder, setReminder] = useState(null);
  const [loading, setLoading] = useState(false);
  const timeZone = useSelector(selectTimeZone);

  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const taskReminderPermittedAttributes = activeColumnPermittedAttributes.task;

  useEffect(() => {}, [task, reminderId, newReminder, isEditTaskForm]);

  const getReminder = () => {
    const checkActiveTaskExist =
      task && task.reminders && task.reminders.length && task.reminders?.at(0)?.status?.slug === "pending";
    if (reminderId !== "") {
      return task.reminders.find(item => item.id === reminderId);
    } else if (checkActiveTaskExist) {
      return task.reminders.at(0);
    } else if (!isEditTaskForm) {
      return newReminder;
    }
  };

  return (
    <div>
      <Modal
        toggle={() => {
          setReminderModal(!reminderModal);
        }}
        title={"Reminder"}
        open={reminderModal}
        className="save_model modal-lg"
        body={() => (
          <AddTaskReminderForm
            task={task}
            ref={reminderFormformikRef}
            setReminderModal={setReminderModal}
            reminderId={reminderId}
            reminder={getReminder()}
            loading={loading}
            setLoading={setLoading}
            isEditTaskForm={isEditTaskForm}
            setTaskFieldValue={setFieldValue}
            toggleTaskModal={toggleTaskModal}
          />
        )}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setReminderModal(false);
            },
            color: "secondary",
          },
          {
            label: getReminder() ? strings.update_reminder : strings.save_reminder,
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: () => {
              reminderFormformikRef.current.handleSubmit();
            },

            loading: loading,
            disabled: getReminder() && getReminder()?.status?.slug !== "pending",
          },
        ]}
      />
    </div>
  );
}

export default AddTaskReminderModal;
