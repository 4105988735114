import { getErrorString } from "helpers/Util";
import React, { useState } from "react";
import { strings } from "../../constant/strings";
import { extendTimeNegotiationStatus } from "../../helpers/ApiHelper";
import { Modal } from "../common";
import Toast from "../common/Toast";

const ExtendTimeNegotiationModal = ({ negotiationId, statuses, showModal, setShowModal }) => {
  const [loading, setLoading] = useState(null);
  const onAccept = async () => {
    setLoading(true);
    const res = await extendTimeNegotiationStatus(negotiationId)
      .then(res => {
        if (!res.success) {
          setLoading(false);
          Toast.error(getErrorString(res));
        }
        setShowModal(false);
        return res;
      })
      .catch(error => {
        setLoading(false);
        Toast.error(getErrorString(error));
      });
  };

  return (
    <div>
      <Modal
        toggle={() => setShowModal(null)}
        title={<div>{strings.extend_negotiation}</div>}
        open={!!showModal}
        // scrollable={true}
        body={() => <div>{strings.extend_negotiation_prompt}</div>}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setShowModal(false);
            },
            color: "secondary",
            className: "modal__cancel-btn",
          },
          {
            label: strings.yes,
            onClick: () => {
              onAccept();
            },
            color: "primary",
            className: "modal__cancel-btn",
            loading: loading,
          },
        ]}
      />
    </div>
  );
};

export default ExtendTimeNegotiationModal;
