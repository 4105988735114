import "./index.scss";
import React, { useEffect } from "react";

import { ConfigProvider } from "react-avatar";
import { I18nextProvider } from "react-i18next";
import LanguageHelper from "./helpers/languageHelper";
import { ConfigProvider as AntDConfigProvider } from "antd";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import Routes from "./routes";
import configDB, { ConfigDB } from "./data/config";
import { persistor, store } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { themeConfig } from "./antd.theme.config";

const componentStyle = { baseStyle: { _focus: { boxShadow: "none", outline: "none" } } };
const PRIMARY_COLOR = ConfigDB.data.color.primary_color;
const theme = extendTheme({
  colors: {
    primary: {
      50: PRIMARY_COLOR,
      100: PRIMARY_COLOR,
      200: PRIMARY_COLOR,
      300: PRIMARY_COLOR,
      400: PRIMARY_COLOR,
      500: PRIMARY_COLOR,
      600: PRIMARY_COLOR,
      700: PRIMARY_COLOR,
      800: PRIMARY_COLOR,
      900: PRIMARY_COLOR,
    },
    colors: {
      gray: {
        50: "#919699",
      },
    },
  },
  shadows: { outline: "none" },
  components: {
    Divider: {
      baseStyle: {
        borderWidth: "1px",
        borderColor: "#919699",
      },
    },
    Switch: { ...componentStyle },
    Button: { ...componentStyle },
  },
});

export const useReadyStateEffect = (effect, deps = [], onState = "complete") => {
  useEffect(() => {
    const destructors = [() => document.removeEventListener("readystatechange", listener)];
    const listener = () => {
      if (!isReadyStateMatch(onState)) {
        return;
      }
      const destructor = effect();
      if (destructor) {
        destructors.push(destructor);
      }
    };
    listener();
    document.addEventListener("readystatechange", listener);
    return () => destructors.forEach(d => d());
  }, deps);
};

export const isReadyStateMatch = expected => {
  if (!expected) {
    return true;
  }
  if ("string" === typeof expected && document.readyState === expected) {
    return true;
  }
  return expected.indexOf(document.readyState) !== -1;
};

function Root() {
  const abortController = new AbortController();

  useEffect(() => {
    const layout = localStorage.getItem("layout_version") || configDB.data.color.layout_version;
    document.body.classList.add(layout);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
  }, [abortController]);

  useReadyStateEffect(
    /* callback */ () => {
      if (document.readyState === "interactive" || document.readyState === "complete") {
        const html = document.querySelector("html");
        html.style.opacity = 1;
        html.style.visibility = "visible";
      }
    },
    /* dependencies */ [],
    /* expected ready state to execute callback */ "complete"
  );

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </div>
  );
}

// Disabling Console.Log in Production
if (process.env.NODE_ENV !== "development") console.log = () => {};

ReactDOM.render(
  <I18nextProvider i18n={LanguageHelper.geti18Instance()}>
    <AntDConfigProvider theme={themeConfig}>
      <ConfigProvider
        colors={["#A2CDF8", "#FF8181", "#29AA6D", "#7496F2", "#F29B74", "#A074F2", "#F2D074", "#E56964", "#D288F7"]}
      >
        <ChakraProvider theme={theme} resetCSS={false}>
          <Root />
        </ChakraProvider>
      </ConfigProvider>
    </AntDConfigProvider>
  </I18nextProvider>,
  document.getElementById("root")
);
