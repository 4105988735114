import { FETCH_EMAIL_TEMPLATES, UPDATE_EMAIL_TEMPLATE, SET_EMAILS_TEMPLATES_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  emailTemplates: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_EMAIL_TEMPLATES:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_EMAIL_TEMPLATE:
      const emailTemplates = state.emailTemplates;
      const index = emailTemplates.findIndex(e => e.id === action.payload.emailTemplate.id);
      emailTemplates[index] = action.payload.emailTemplate;
      return {
        ...state,
        ...emailTemplates,
      };
    case SET_EMAILS_TEMPLATES_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    default:
      return state;
  }
};
