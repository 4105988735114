export const TASK_MEETING_TYPES = Object.freeze({
  WAREHOUSE: "Warehouse",
  BRANCHES: "Branches",
  AREA: "Area",
  SELLER_ADDRESS: "Seller Address",
});

export const CRM_TASKS_TABS_DATA_SLUGS = {
  TASKS: "tasks",
  FOLLOW_UP: "tasks_followup",
};

export const TASKS_STATUSES_SLUGS = Object.freeze({
  COMPLETED: "completed",
  DUE: "due",
});
