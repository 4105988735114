import dayjs from "dayjs";
import { DATE_FORMAT, TIME_DATE_FORMAT, TIME_FORMAT } from "pf-constants";
import { t } from "i18next";

export const convertTo12HourFormat = timeString => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const hours12 = hours % 12 || 12;
  const formattedTime = `${hours12}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")} `;
  return formattedTime;
};

export function getDayFromDate(date) {
  let daysHash = {
    0: t("Sunday"),
    1: t("Monday"),
    2: t("Tuesday"),
    3: t("Wednesday"),
    4: t("Thursday"),
    5: t("Friday"),
    6: t("Saturday"),
  };
  const dayNumber = dayjs(date).day();
  return daysHash[dayNumber];
}

export function getMonthFromString(mon) {
  const month = new Date(Date.parse(`${mon} 1, 2012`)).getMonth() + 1;
  return month.toString().length === 1 ? `0${month}` : month;
}

export function getStartValue(start) {
  return `${start[3]}-${getMonthFromString(start[1])}-${start[2]}`;
}

export function getEndValue(end) {
  return `${end[3]}-${getMonthFromString(end[1])}-${end[2]}`;
}

export function getStartEndValue(start, end) {
  const startDate = getTimeDateString(getStartValue(start), false, true);
  const endDate = getTimeDateString(getStartValue(end), false, true);
  return `${startDate} - ${endDate}`;
}

export const getTimeDateString = (dateString, local, noTime, timeOnly) => {
  //   const timezone = configs.settings.timezone;
  const format = noTime ? DATE_FORMAT : TIME_DATE_FORMAT;
  if (!!dateString) {
    // if (timezone) {
    //   dateString = dayjs(dateString)
    //     // .tz(timeZone)
    //     .format(DATE_TIME_FORMAT);
    // }

    let date = new Date(
      typeof dateString === "string" && dateString.includes("Z") && !local
        ? dateString.replace("Z", "")
        : typeof dateString === "string"
        ? dateString //.replace(" ", "T")
        : dateString
    );
    const datetime = dayjs(date).format(format);
    const time = dayjs(date).format("hh:mm a");
    return timeOnly ? time : datetime;
  }
};

export const formatTimeString = timeString => {
  if (!!timeString) {
    const time = dayjs(timeString, TIME_FORMAT);
    const formattedTime = time.format(TIME_FORMAT);
    return formattedTime;
  }
  return "";
};

export const checkExpiryInHours = date => {
  const now = dayjs();
  const difference = now.diff(date);
  return difference > 24 ? true : false;
};

export const dateTimeFunction = ({ date, format, timeZone, Default, isUnix, apiRequest }) => {
  return isUnix
    ? convertUnixToDate(date, "YYYY-MM-DD")
    : format
    ? dayjs(date).format(format)
    : timeZone
    ? dayjs(date).format("YYYY-MM-DD")
    : Default
    ? dayjs(date)
    : dayjs(date).format(DATE_FORMAT);
};

export const convertUnixToDate = (date, format) => {
  const dt = +new Date(date);
  return dayjs.unix(dt / 1000).format(format);
};

export const getElapsedTime = dateString => {
  const currentDate = new Date();
  const inputDate = new Date(dateString);
  const timeDifference = currentDate - inputDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    const remainingMonths = months % 12;
    if (remainingMonths > 0) {
      return `${years} ${years > 1 ? t("years") : t("year")} ${remainingMonths} ${
        remainingMonths > 1 ? t("months") : t("month")
      } ${t("ago")}`;
    } else {
      return `${years} ${years > 1 ? t("years") : t("year")} ${t("ago")}`;
    }
  } else if (months > 0) {
    const remainingDays = days % 30;
    if (remainingDays > 0) {
      return `${months} ${months > 1 ? t("months") : t("month")} ${remainingDays} ${
        remainingDays > 1 ? t("days") : t("day")
      } ${t("ago")}`;
    } else {
      return `${months} ${months > 1 ? t("months") : t("month")} ${t("ago")}`;
    }
  } else if (days > 0) {
    return `${days} ${days > 1 ? t("days") : t("day")} ${t("ago")}`;
  } else if (hours > 0) {
    return `${hours} ${hours > 1 ? t("hours") : t("hour")} ${t("ago")}`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? t("minutes") : t("minute")} ${t("ago")}`;
  } else {
    return t("few seconds ago");
  }
};

export const getAgeFromDate = date => {
  const dateOfBirth = new Date(date);
  const birthYear = dateOfBirth.getFullYear();
  const currentYear = new Date().getFullYear();
  return currentYear - birthYear;
};

export const customDateFormat = date => {
  const diff = dayjs().diff(date, "milliseconds");
  const duration = dayjs.duration(diff);
  let dateString = [];
  if (duration.years() != 0) {
    dateString.push(`${duration.years()}y`);
  }
  if (duration.months() != 0) {
    dateString.push(`${duration.months()}m`);
  }
  if (duration.days() != 0) {
    dateString.push(`${duration.days()}d`);
  } else if (duration.years() == 0 && duration.months() == 0) {
    dateString.push(`${duration.days()}d`);
  }

  return dateString.join(" ");
};

export const checkNewHire = date => {
  const diff = dayjs().diff(date, "milliseconds");
  const duration = dayjs.duration(diff);
  const year = duration.years();
  const months = duration.months();

  return year <= 0 && months < 6;
};

export const workingDaysCalculator = startDate => {
  startDate = dayjs(startDate).endOf("days");
  const daysDifference = dayjs().diff(startDate, "days");
  let workingDays = 0;
  for (let i = 0; i < daysDifference; i++) {
    const currentDate = dayjs(startDate).add(i, "days");
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      workingDays++;
    }
  }
  return workingDays;
};
