import { FETCH_CRM_CICOS, SET_CRM_CICO_LOADING } from "../../constant/actionTypes";
import { fetchCrmCicosApi } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

export const fetchCRMCicos = queryString => async dispatch => {
  dispatch({ type: SET_CRM_CICO_LOADING, payload: { loading: true } });
  const res = await fetchCrmCicosApi(queryString);
  if (res) {
    const { cicos, cico_reasons, cico_types, pagination, permitted_meta, locations, inventory_types, ...rest } = res;
    if (cicos) {
      dispatch({
        type: FETCH_CRM_CICOS,
        payload: {
          cicoList: cicos,
          cicoReasons: cico_reasons,
          cicoTypes: cico_types,
          pagination,
          permittedMeta: permitted_meta,
          cicoLocations: locations,
          inventoryTypes: inventory_types,
          loading: false,
          error: "",
        },
      });
    } else {
      dispatch({
        type: FETCH_CRM_CICOS,
        payload: {
          cicoList: [],
          cicoReasons: [],
          cicoTypes: [],
          inventoryTypes: [],
          pagination: {},
          permittedMeta: {},
          cicoLocations: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  }
};
