import { FETCH_OFFERS } from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  restrictedColumns: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_OFFERS:
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
        list: action.payload.mergeList ? [...state.list].concat([...action.payload.list]) : [...action.payload.list],
        pagination: action.payload?.pagination ? { ...action.payload.pagination } : { ...state.pagination },
        restrictedColumns: action.payload?.restrictedColumns,
      };

    default:
      return state;
  }
};
