import { FETCH_SMS_TEMPLATES, UPDATE_SMS_TEMPLATE } from "../../constant/actionTypes";

const INITIAL_STATE = {
  smsTemplates: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SMS_TEMPLATES:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_SMS_TEMPLATE:
      const smsTemplates = state.smsTemplates;
      const index = smsTemplates.findIndex(e => e.id === action.payload.smsTemplate.id);
      smsTemplates[index] = action.payload.smsTemplate;
      return {
        ...state,
        ...smsTemplates,
      };

    default:
      return state;
  }
};
