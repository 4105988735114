import React, { useRef, useState } from "react";
import { Modal } from "components/common";
import { strings } from "constant/strings";
import MarkWinnerAsLostForm from "./MarkWinnerAsLostForm";
import styles from "./MarkWinnerAsLost.module.scss";

const MarkWinnerAsLostModal = ({
  offersObject = {},
  showMarkWinnerLostModal = false,
  setShowMarkWinnerLostModal = () => {},
  onSuccess = () => {},
  offerId = "",
}) => {
  const [loading, setLoading] = useState(null);
  const formRef = useRef();

  return (
    <div>
      <Modal
        toggle={() => setShowMarkWinnerLostModal(false)}
        title={<div>{strings.remove_winner_confirmation}</div>}
        open={!!showMarkWinnerLostModal}
        scrollable={true}
        body={() => (
          <MarkWinnerAsLostForm
            ref={formRef}
            dispositionsList={offersObject?.dispositions ?? []}
            offerId={offerId}
            onSuccess={onSuccess}
            setLoading={setLoading}
          />
        )}
        className={styles.winnerLostModalContainer}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setShowMarkWinnerLostModal(false);
            },
            color: "secondary",
            className: "modal__cancel-btn",
          },
          {
            label: strings.confirm,
            onClick: () => {
              formRef.current && formRef.current.submitForm();
            },
            color: "primary",
            className: "modal__cancel-btn",
            loading: loading,
          },
        ]}
      />
    </div>
  );
};

export default MarkWinnerAsLostModal;
