import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AudioPlayer from "../../../components/crm/Audio/AudioPlayer";
import {
  AiOutlineCalendar,
  IconDeal,
  IconDuration,
  IconRefrence,
  CallIcon,
} from "../../../components/svg-icon/svg-icons";
import { CRM_LEAD_DETAIL_PATH, CRM_TASKS_PATH_DETAIL, INS_LEADS_DETAIL_PATH } from "../../../constant/appPaths";
import { MODELS_FOR_VIEW_PHONE_NUMBER_API, PHONE_NUMBER_CATEGORIZATION_PER_PAGE } from "constant/appConstants";
import { PERMISSIONS } from "../../../constant/permissions";
import { strings } from "../../../constant/strings";
import {
  flattenObject,
  formatRestrictedKeys,
  formatTime,
  getTimeDateString,
  isFieldRestricted,
} from "../../../helpers/Util";
import DetailCard from "../../crm/DetailCard";

const callInfoData = {
  reference_number: {
    Icon: IconRefrence,
    title: strings.reference_no,
    valueFormatter: value => value,
    displayOrder: 1,
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
  },
  "report.evaluated_at": {
    Icon: IconDeal,
    title: strings.completed_at,
    valueFormatter: value => getTimeDateString(value),
    displayOrder: 2,
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
  },
  call_type: {
    Icon: CallIcon,
    title: strings.call_type,
    valueFormatter: value => value ?? "-",
    displayOrder: 3,
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
  },
  duration: {
    Icon: IconDuration,
    title: strings.duration,
    valueFormatter: value => formatTime(value),
    displayOrder: 4,
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
  },
  "crm_lead.reference_number": {
    Icon: AiOutlineCalendar,
    title: strings.lead_ref,
    displayOrder: 5,
    valueFormatter: value => value ?? "-",
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
    handleRoute: ({ crm_lead }) =>
      crm_lead?.id || !isFieldRestricted(crm_lead.id) ? CRM_LEAD_DETAIL_PATH.replace(":id", crm_lead.id) : null,
    permissionCategory: PERMISSIONS.CRMLEAD.NAME,
    permissionAction: PERMISSIONS.CRMLEAD.ACTIONS.READ,
    style: "infoItemHover",
  },
  "task.reference_number": {
    Icon: AiOutlineCalendar,
    title: strings.Task_Ref,
    displayOrder: 6,
    valueFormatter: value => value ?? "-",
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
    handleRoute: ({ task }) =>
      task?.id || !isFieldRestricted(task.id) ? CRM_TASKS_PATH_DETAIL.replace(":id", task.id) : null,
    permissionCategory: PERMISSIONS.TASK.NAME,
    permissionAction: PERMISSIONS.TASK.ACTIONS.INDEX,
    style: "infoItemHover",
  },
  "assignee.name": {
    Icon: AiOutlineCalendar,
    title: strings.inspector,
    displayOrder: 7,
    valueFormatter: value => value,
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
  },
  "crm_lead.category_type.name": {
    Icon: AiOutlineCalendar,
    title: strings.lead_type,
    displayOrder: 8,
    valueFormatter: value => value,
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
  },
  "task.task_type.name": {
    Icon: AiOutlineCalendar,
    title: strings.task_type,
    displayOrder: 9,
    valueFormatter: value => value,
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
  },
  "lead.lead_type.name": {
    Icon: AiOutlineCalendar,
    title: strings.lead_type,
    displayOrder: 10,
    valueFormatter: value => value,
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
  },
  "lead.reference_number": {
    Icon: AiOutlineCalendar,
    title: strings.lead_ref,
    displayOrder: 11,
    valueFormatter: value => value ?? "-",
    iconProps: { color: "hsl(var(--primary-color)/ .5)" },
    handleRoute: ({ lead }) =>
      lead?.id || !isFieldRestricted(lead.id) ? INS_LEADS_DETAIL_PATH.replace(":id", lead.id) : null,
    permissionCategory: PERMISSIONS.LEAD.NAME,
    permissionAction: PERMISSIONS.LEAD.ACTIONS.READ,
    style: "infoItemHover",
  },
};

const pruneCallDetailsByAssociatedType = ({ associatedType = "", callDetails }) => {
  const { crm_lead, task, lead, ...rest } = callDetails;

  const prunedCallDetailLookUp = {
    Task: {
      ...rest,
      task,
    },
    CrmLead: {
      ...rest,
      crm_lead,
    },
    Lead: {
      ...rest,
      lead,
    },
  };

  return prunedCallDetailLookUp[associatedType] ?? callDetails;
};
const QCHeaderSection = props => {
  const { id: callId = "" } = useParams();

  const { callRecordingDetails } = props;

  const [isAudioPlayerPlaying, setAudioPlaying] = useState(false);

  const { user, status, associated_id, associated_type, source_url, id } = callRecordingDetails;

  const prunedCallRecordingDetails = pruneCallDetailsByAssociatedType({
    associatedType: callRecordingDetails?.associated_type,
    callDetails: callRecordingDetails,
  });

  const flattenedCallRecording =
    flattenObject({
      obj: prunedCallRecordingDetails,
    }) ?? {};

  const callInformation =
    Object.entries(flattenedCallRecording)
      .reduce((acc, [key, value]) => {
        if (callInfoData[key]) {
          const { valueFormatter, handleRoute = () => null } = callInfoData[key];

          const data = {
            value: valueFormatter(value),
            ...callInfoData[key],
          };

          const formattedData = formatRestrictedKeys({ obj: data });

          acc.push({
            route: handleRoute(callRecordingDetails),
            ...formattedData,
          });
        }
        return acc;
      }, [])
      .sort((a, b) => a.displayOrder - b.displayOrder ?? 0) ?? [];

  const cardData = {
    name: (!isFieldRestricted(user) && user ? user?.name : "-") ?? "-",
  };

  const qcStatus = !!status && !isFieldRestricted(status) ? status : {};

  return (
    <section>
      <DetailCard
        cardData={cardData}
        IconsList={callInformation}
        status={qcStatus}
        hidePhoneNumberProps={{
          associatedId: callId,
          associatedType: MODELS_FOR_VIEW_PHONE_NUMBER_API.RECORDINGS,
          component: PHONE_NUMBER_CATEGORIZATION_PER_PAGE.QC.DETAIL,
          isUserTypeNotClient: true,
        }}
        client={user}
      >
        <AudioPlayer
          item={callRecordingDetails}
          mp3url={source_url}
          index={id}
          audioPlayingId={!!isAudioPlayerPlaying}
          setAudioPlayingId={(item, isAudioPlaying) => setAudioPlaying(!!isAudioPlaying)}
          canSpeedUp={true}
        />
      </DetailCard>
    </section>
  );
};

export default QCHeaderSection;
