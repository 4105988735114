import { FETCH_CRM_CLIENTS, SET_CRM_CLIENTS_LOADING } from "../../constant/actionTypes";
import { fetchCrmClientsApi } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

export const fetchCRMClients = queryString => async dispatch => {
  dispatch({ type: SET_CRM_CLIENTS_LOADING, payload: { loading: true } });
  const res = await fetchCrmClientsApi(queryString);
  if (res && res.clients) {
    dispatch({
      type: FETCH_CRM_CLIENTS,
      payload: {
        ...res,
        list: [...res.clients],
        loading: false,
        error: "",
        restrictions: res.restrictions,
      },
    });
  } else {
    dispatch({
      type: FETCH_CRM_CLIENTS,
      payload: {
        list: [],
        loading: false,
        error: getErrorString(res),
      },
    });
  }
};
