import React, { Fragment } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { getAppPath } from "../../helpers/Util";

const Breadcrumb = props => {
  const { title, parent, rightView, diffTitle, BreadcrumbIcon } = props;

  return (
    <Fragment>
      <div className="page-header">
        <div className="row">
          <div className="col">
            <div className="page-header-left">
              <h3>{diffTitle ? diffTitle : title}</h3>
              <ol className="breadcrumb pull-right">
                <li className="breadcrumb-item">
                  <Link to={getAppPath()}>
                    <Home />
                  </Link>
                </li>
                {!!parent && <li className="breadcrumb-item">{parent}</li>}
                {!!title && <li className="breadcrumb-item active">{title}</li>}
              </ol>
              {BreadcrumbIcon && <BreadcrumbIcon />}
            </div>
          </div>
          {rightView && <div className="col d-flex align-items-center justify-content-end">{rightView()}</div>}
        </div>
      </div>
    </Fragment>
  );
};

export default Breadcrumb;
