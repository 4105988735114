import { AsyncPaginate } from "react-select-async-paginate";
import React, { Fragment, useEffect } from "react";
import { makeListToSelectData, objectToQuery } from "../../helpers/Util";
import Skeleton from "./Skeleton";
import FormRow from "./FormRow";
import ConfigDB from "../../data/config";
import { Check } from "react-feather";

const AsyncSelect = props => {
  const {
    filterKey = "",
    filtersObject = {},
    setSlugAsKey,
    verifySlugOnly,
    loading = false,
    isFitler = false,
    getDataInParent = data => {},
    prependOptions = [],
    queryKey = "s[name]",
    extraParams,
    horizontal = true,
    formatListCallback,
    columnRightSection,
    columnLeftSection,
    isEditable = true,
    showRequiredAsterisk,
    isMultiOption = false,
    id = "",
    placeholder,
  } = props;

  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  async function loadOptions(search, prevOptions = [], { page }) {
    await sleep(300);
    //props?.listQueryBased is an array containing IDs that we pass into the listApi function to fetch API results based on those IDs.
    let queryObj = {};
    if (Array.isArray(queryKey)) {
      queryKey.forEach(key => {
        queryObj[key] = search;
      });
    } else {
      queryObj[queryKey] = search;
    }
    const response = await props.listApi(objectToQuery({ page, ...queryObj, ...extraParams }), props?.listQueryBased);
    if (response.success) {
      let list = response?.[props.listProperty] ?? [];
      const hasNextPage = !!response?.pagination?.next_page;
      if (formatListCallback) {
        list = formatListCallback(list, page, search, [], filterKey);
      } else if (prependOptions) {
        list = [...prependOptions, ...list];
      }
      isFitler && getDataInParent(list);
      return {
        options: isFitler
          ? makeListToSelectData(list, filtersObject[filterKey], setSlugAsKey, verifySlugOnly, filterKey)
          : makeListToSelectData(list, "", false),
        hasMore: hasNextPage,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  const selectedOptions = filtersObject?.arrayType
    ? filtersObject?.[filterKey].map(value => Number(value))
    : filtersObject?.[filterKey]?.split(",")?.map(value => Number(value)) ?? [];

  const CustomOption = ({ innerProps, label, data }) => {
    const isSelected = selectedOptions?.includes(data?.id);
    return (
      <div {...innerProps} className="custom-async-multiSelect d-flex align-items-center justify-content-between">
        <span>{label}</span>
        {isSelected && <Check size={14} />}
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton height={30} />
      ) : (
        <Fragment>
          <FormRow
            columnLeftSection={columnLeftSection}
            columnRightSection={columnRightSection}
            horizontal={horizontal}
            label={props?.label}
            textRight={true}
            shouldWrap={!!props.label}
            showRequiredAsterisk={showRequiredAsterisk}
            id={id}
          >
            <AsyncPaginate
              value={props.selected}
              additional={{
                page: 1,
              }}
              debounceTimeout={300}
              loadOptionsOnMenuOpen={true}
              loadOptions={loadOptions}
              placeholder={!!placeholder ? placeholder : "Search..."}
              classNamePrefix="select"
              isClearable={!props.disabled}
              defaultSelected={props.defaultSelected}
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                //props?.disableCustomOption is used to prevent the display of CustomOptions.
                ...(!props?.disableCustomOption ? isMultiOption && { Option: CustomOption } : {}),
              }}
              getOptionValue={option => option.id}
              styles={customStyles({
                filterSelected: props.filterSelected,
              })}
              closeMenuOnSelect={!isMultiOption}
              isDisabled={!isEditable}
              {...props}
            />
            {!!props.errorMsg && <span className="invalid-error">{props.errorMsg}</span>}
          </FormRow>
        </Fragment>
      )}
    </>
  );
};

const customStyles = props => {
  // container: provided => ({
  //   ...provided,
  // }),
  return {
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    menu: provided => ({
      ...provided,
      zIndex: 999,
    }),
    control: provided => ({
      ...provided,
      boxShadow: "none",
      borderColor: "#efefef",
      minHeight: 42,
      borderRadius: "0.25rem",

      "&:hover, &:focus": {
        borderColor: "#efefef",
      },
    }),
    placeholder: provided => ({
      ...provided,
      textTransform: "capitalize",
      ...(props.filterSelected && {
        color: ConfigDB?.data?.color?.primary_color,
      }),
    }),
    multiValue: provided => {
      return {
        ...provided,
        fontSize: "18px",
      };
    },
    multiValueRemove: provided => ({
      ...provided,
      ":hover": {
        backgroundColor: "none",
      },
    }),
  };
};

export default AsyncSelect;
