import { IF, Modal } from "../";
import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "../../svg-icon/svg-icons";
import { strings } from "../../../constant/strings";
import DynamicList from "./DynamicListSingleSelect";
import { fetchCityZones } from "../../../helpers/ApiHelper";
import { useSelector } from "react-redux";

const SelectLocationModal = props => {
  const cities = useSelector(content => content.Configs.tenant.cities);
  const initialState = {
    city: null,
    zone: null,
  };

  const [selected, setSelected] = useState(initialState);
  useEffect(() => {
    if (props.city) {
      setSelected(prev => ({ ...prev, city: props.city }));
    }
  }, [props.city, props.visible]);

  const onDone = obj => {
    props.onSelect(obj);
    props.setVisible(false);
    setSelected(initialState);
  };

  // const isDisabled = () => {
  //   if (props.child === "area") {
  //     return !selected?.city?.id;
  //   } else {
  //     if (selected?.city?.id && listLength > 0 && !selected?.zone?.id) {
  //       return true;
  //     }
  //   }
  // };
  const toggleModal = () => {
    props.setVisible(!props.visible);
  };

  const onCancel = () => {
    props.setVisible(false);
    setSelected({});
  };

  const renderCitiesList = () => {
    return (
      <div>
        {cities?.map(e => (
          <div
            className={`modal-list${
              props.city ? "-disabled" : ""
            } d-flex flex-row align-items-center justify-content-between ${
              e.id === selected?.city?.id ? "selected" : ""
            }`}
            onClick={() => {
              if (props.city) return;
              setSelected(state => ({
                ...state,
                city: e,
                zone: null,
              }));
            }}
            key={e.id}
          >
            <span className="d-flex">{e.name}</span>
            <IoIosArrowForward className="d-flex" size="15" />
          </div>
        ))}
      </div>
    );
  };

  const renderZonesList = () => {
    return (
      <>
        <DynamicList
          title={strings.zones}
          params={selected?.city?.id ? `?q[city_id_eq]=${selected?.city?.id}` : {}}
          selected={selected}
          onSelect={e => setSelected({ ...selected, ...e })}
          apiCall={fetchCityZones}
          apiKey="zones"
          keyString="zone"
        />
      </>
    );
  };

  const modalBody = () => {
    return (
      <div className="modal-wrapper d-flex flex-row">
        <div className="modal-inner-wrapper">
          <h6>{strings.cities}</h6>
          <div className="filter__row--section mb-2 px-3">
            {/*<SearchInput onChange={onChange} placeholder="Search..." className="theme-form" />*/}
          </div>
          <div className="modal-list-wrapper d-flex flex-column">{renderCitiesList()}</div>
        </div>

        <IF condition={!!selected?.city?.id}>{renderZonesList()}</IF>
      </div>
    );
  };

  return (
    <Modal
      toggle={toggleModal}
      open={props.visible}
      className="modal-lg deposit_model crm-modal two-box"
      style={{ "--modal-width": "650px", "--inner-wrapper-width": "50%" }}
      body={modalBody}
      actions={[
        {
          label: strings.cancel,
          onClick: onCancel,
          color: "outline-dark",
          className: "modal__cancel-btn btn-pill",
        },
        {
          label: strings.done,
          color: "primary",
          className: "modal__cr-btn btn-pill",
          onClick: () => onDone(selected),
          disabled: false,
        },
      ]}
    />
  );
};

export default SelectLocationModal;
