import EmptyState from "../common/EmptyState/EmptyState";
import { Button, FormInput, SelectField } from "../common";
import { strings } from "../../constant/strings";
import { MdClose } from "../svg-icon/svg-icons";
import React, { Fragment } from "react";
import { generateUUID, isEmptyArray, isEmptyObject } from "../../helpers/Util";
import { selectTenant } from "../../reducers/general/selectors";
import { useSelector } from "react-redux";

const getServices = (values, serviceType) => {
  if (isEmptyObject(values)) return [];
  let services = [];
  let { previousSnapshot, ...rest } = values;
  let serviceEntries = Object.entries(rest);
  services = serviceEntries
    .map(([formikKey, payload]) => ({ ...payload, formikKey }))
    .filter(entry => entry.service_type === serviceType)
    .filter(entry => !entry._destroy);
  return services;
};

const ServiceTypeCharges = props => {
  const { metaData, formikProps, appendSchema, removeSchema } = props;
  const { errors, touched, handleBlur, setFieldValue } = formikProps;
  const { header, serviceType, hasCityFrom, hasCityTo, hasTitle, Icon } = metaData;

  const initialValue = {
    ...(hasCityTo && {
      city_to: null,
      city_to_id: null,
    }),
    ...(hasCityFrom && {
      city_from: null,
      city_from_id: null,
    }),
    name: "",
    price: "",
    service_type: serviceType,
    is_active: true,
  };

  const services = getServices(formikProps.values, serviceType);
  const showEmptyServicesCard = isEmptyArray(services);
  const tenant = useSelector(selectTenant);
  const cities = tenant?.cities;
  const selectedCityFrom = services?.map(service => service?.city_from?.id)?.filter(Boolean) ?? [];
  //filter cities in case of pick up delivery (price can be set at mark as winner)
  const filteredCityFrom = cities?.filter(city => !selectedCityFrom.includes(city?.id)) ?? [];

  const onNewService = () => {
    let formikKey = `${serviceType}-${generateUUID()}`;
    setFieldValue(formikKey, { ...initialValue, isNewService: true });
    appendSchema(formikKey, serviceType);
  };

  return (
    <div className={"card"}>
      <div className="card-header">
        <h5>{header}</h5>
      </div>
      <div className={"card-body"}>
        {showEmptyServicesCard && (
          <EmptyState
            title={`No ${header} Added Yet`}
            message={`You can add ${header} with a simple click!`}
            image={<Icon />}
            button={
              <>
                <Button color="skeleton" btnText={`+ Add ${header}`} variant="link" onClick={onNewService} />
              </>
            }
          />
        )}

        {!showEmptyServicesCard &&
          services?.map((item, i) => {
            const formikKey = item?.formikKey;
            return (
              <Fragment key={formikKey}>
                <div className="mb-3">
                  <div className="row">
                    {hasTitle && (
                      <FormInput
                        placeholder={strings.enter_name_message}
                        value={item?.name}
                        onChange={e => {
                          formikProps.setFieldValue(formikKey, { ...item, name: e.target.value });
                        }}
                        className="col-3 pr-1 mb-0"
                        onBlur={e => {
                          formikProps.setFieldTouched(`${formikKey}-name`);
                          return formikProps.handleBlur(formikKey);
                        }}
                        errorMsg={touched?.[formikKey]?.name && errors?.[formikKey]?.name}
                        errorClassName={"ml-2"}
                      />
                    )}

                    {hasCityFrom && (
                      <SelectField
                        placeholder={strings.pickup_location}
                        options={hasCityTo ? cities : filteredCityFrom}
                        onChange={value => {
                          let name = value.name;
                          if (hasCityTo) {
                            //has both city from and city to.
                            name = {
                              cityFrom: value?.name,
                              cityTo: item?.name?.cityTo,
                            };
                          }
                          setFieldValue(formikKey, { ...item, city_from_id: value.id, city_from: { ...value }, name });
                        }}
                        value={item.city_from}
                        isSearchable
                        textRight
                        containerClassName="col-4 pr-1 mb-0"
                        onBlur={e => {
                          formikProps.setFieldTouched(`${formikKey}-cityfrom`);
                          return formikProps.handleBlur(formikKey);
                        }}
                        errorMsg={touched?.[formikKey]?.city_from && errors?.[formikKey]?.city_from}
                        errorClassName={"ml-2"}
                      />
                    )}

                    {hasCityTo && (
                      <SelectField
                        placeholder={strings.dropoff_location}
                        options={cities}
                        onChange={value => {
                          let name = { cityFrom: item?.name?.cityFrom, cityTo: value?.name };
                          setFieldValue(formikKey, { ...item, city_to_id: value?.id, city_to: { ...value }, name });
                        }}
                        value={item.city_to}
                        isSearchable
                        textRight
                        containerClassName="col-3 pr-1 mb-0"
                        // onBlur={formikProps.handleBlur(formikKey)}
                        onBlur={e => {
                          formikProps.setFieldTouched(`${formikKey}-cityto`);
                          return formikProps.handleBlur(formikKey);
                        }}
                        errorMsg={touched?.[formikKey]?.city_to && errors?.[formikKey]?.city_to}
                        errorClassName={"ml-2"}
                      />
                    )}

                    <FormInput
                      placeholder={strings.enter_price_message}
                      value={item?.price}
                      onChange={e => {
                        setFieldValue(formikKey, { ...item, price: e.target.value });
                      }}
                      className="col-3 pr-1 mb-0"
                      onBlur={e => {
                        formikProps.setFieldTouched(`${formikKey}-price`);
                        return formikProps.handleBlur(formikKey);
                      }}
                      errorMsg={touched?.[formikKey]?.price && errors[formikKey]?.price}
                      errorClassName={"ml-2"}
                    />
                    <Button
                      onClick={() => {
                        setFieldValue(formikKey, !item?.isNewService ? { ...item, _destroy: true } : undefined);
                        removeSchema(formikKey);
                      }}
                      size="sm"
                      color="skeleton"
                      icon={<MdClose size={12} />}
                      btnText=""
                      className={`p-0 d-flex align-items-center justify-content-center mt-2 ml-3 expense-close-btn`}
                    />
                  </div>
                </div>
              </Fragment>
            );
          })}

        {!showEmptyServicesCard && (
          <div>
            <Button
              onClick={onNewService}
              color="skeleton"
              btnText={`+ Add ${header}`}
              variant="link"
              className={"mt-3"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ServiceTypeCharges;
