import { Button } from "reactstrap";
import React from "react";
import EmptyState from "./EmptyState/EmptyState";
import { ImageErrorView } from "./EmptyState/EmptyStateSvgs";

const ErrorView = ({ buttons = [], title = "" }) => {
  return (
    <div className="errorWrap">
      <div className="container">
        {/* <img className="img-100" src={sad} alt="" /> */}
        {/* <div className="error-heading">
          <h2 className="headline font-primary">Oops!</h2>
        </div> */}
        <EmptyState
          image={<ImageErrorView />}
          title={title ? title : "Oops!"}
          message={"Error something went wrong please try refreshing this web page."}
        />
        {/* <div className="col-md-8 offset-md-2">
          <p className="sub-content">{title ? title : "Let's not lose hope!"}</p>
        </div> */}
        <div className="row justify-content-center">
          {buttons?.map(({ onClick, label, setPrimaryColor, ...rest }, i) => (
            <Button
              color={setPrimaryColor ? "primary" : "dark"}
              outline={!setPrimaryColor ? true : false}
              onClick={onClick}
              className="btn-pill mx-2"
              size="lg"
              key={label}
              {...rest}
            >
              {label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ErrorView;
