import {
  ADD_ITEM_LIST_DATA,
  FETCH_CRM_ITEM_LISTS,
  UPDATE_ITEM_LIST_DATA,
  SET_ITEM_LIST_LOADING,
} from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: false,
  error: "",
  pagination: {},
  payloadData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CRM_ITEM_LISTS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_ITEM_LIST_DATA: {
      const list = [...state.list];
      const index = list.findIndex(item => item.id === action?.data?.id);
      list.splice(index, 1, { ...(action.data || {}) });
      return {
        ...state,
        list,
      };
    }
    case ADD_ITEM_LIST_DATA: {
      return {
        ...state,
        list: [action.data, ...state.list],
      };
    }
    case SET_ITEM_LIST_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};
