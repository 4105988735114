import React, { Fragment } from "react";

const PageTitle = props => {
  const { title, rightView } = props;

  return (
    <Fragment>
      <div className="page-header">
        <div className="row">
          <div className="col">
            <div className="page-header-left">
              <h3>{title}</h3>
            </div>
          </div>
          {rightView && <div className="col">{rightView()}</div>}
        </div>
      </div>
    </Fragment>
  );
};

export default PageTitle;
