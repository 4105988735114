import React from "react";

const DropZone = props => {
  const { children, onDropCallback, className, style } = props;

  const dragOver = e => {
    e.preventDefault();
  };

  const dragEnter = e => {
    e.preventDefault();
  };

  const dragLeave = e => {
    e.preventDefault();
  };

  const fileDrop = e => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    onDropCallback(files);
  };
  return (
    <div
      className={className}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

export default DropZone;
