import React from "react";
import ImagesUpload from "./ImagesUpload";
import FileInput from "./FileInput";
import { BtnDefaultState } from "../..";

const UploadFileDynamicInput = props => {
  const { pictures, setPictures, textRight, onFilesAdded, label, id, title, isEditable, horizontal = true } = props;
  const handleOnChangeFileInput = e => {
    onFilesAdded(e.target.files);
    e.target.value = null;
  };

  return (
    <div className={`form-group ${horizontal ? "row" : ""} align-items-center`}>
      <label className={`${horizontal ? "col-sm-3" : ""} col-form-label ${textRight ? "text-right" : ""}`}>
        {label}
      </label>
      <div className={`${horizontal ? "col-sm-9" : ""}`}>
        {pictures?.length > 0 ? (
          <ImagesUpload
            disabled={isEditable === false}
            pictures={pictures}
            setPictures={setPictures}
            label={label}
            title={title}
            url={props.url}
            isEditable={isEditable}
          />
        ) : (
          <>
            <FileInput disabled={isEditable === false} id={id} handleOnChange={handleOnChangeFileInput} />
            <BtnDefaultState
              disabled={isEditable === false}
              onClick={() => document.getElementById(id).click()}
              className="custom-field-state b-r-6"
            />
          </>
        )}
        {!!props.errorMsg && props.errorType !== "icon" && (
          <p className={"invalid-error " + props.errorClassName}>{props.errorMsg}</p>
        )}
      </div>
    </div>
  );
};
export default UploadFileDynamicInput;
