import {
  ADD_TASK,
  ADD_TASK_FILTERS,
  CLEAR_TASK,
  FETCH_TASKS,
  UPDATE_TASK,
  UPDATE_TASK_MEETING,
  UPDATE_TASKS_REMINDERS,
  SET_TASKS_LOADING,
} from "../../constant/actionTypes";
import { clone } from "../../helpers/Util";

export const INITIAL_STATE = {
  list: [],
  filters: {
    status_id: "",
    id: "",
    client_phone: "",
    client_name: "",
  },
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  restrictions: {},
  statuses: [],
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TASK:
      return {
        ...state,
        list: [action.payload.item].concat([...state.list]),
      };
    case UPDATE_TASK:
      const taskList = state.list;
      let index = taskList.findIndex(e => e.id === action.payload.item.id);
      taskList.splice(index, 1, { ...action.payload.item });
      return {
        ...state,
        list: [...taskList],
      };
    case UPDATE_TASK_MEETING:
      const taskId = action.payload.taskId;
      const updatedAppointments = action.payload.appointments;
      const newState = clone(state);
      const task = newState.list.find(task => task.id === taskId);
      if (task) {
        task.appointments = updatedAppointments;
      }
      return { ...newState };
    case FETCH_TASKS:
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_TASKS_REMINDERS: {
      const taskId = action.payload.taskId;
      const updatedReminders = action.payload.reminders;
      const newState = clone(state);
      const task = newState.list.find(task => task.id === taskId);
      if (task) {
        task.reminders = updatedReminders;
      }
      return { ...newState };
    }

    case ADD_TASK_FILTERS:
      return {
        ...state,
        list: [],
        pagination: {
          current_page: 1,
          next_page: null,
          prev_page: null,
        },
        loading: true,
        error: "",
        filters: { ...action.payload.item },
      };
    case CLEAR_TASK:
      return {
        ...state,
        INITIAL_STATE,
      };
    case SET_TASKS_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };
    default:
      return state;
  }
};
