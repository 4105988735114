import React, { Fragment } from "react";
import cx from "clsx";
import styles from "./ChevronSidebarLoader.module.scss";
import Skeleton from "../../../Skeleton";
import { Avatar } from "../../../Avatar";
import { strings } from "../../../../../constant/strings";

const ChevronSidebarLoader = () => {
  return (
    <div className={cx(styles.usersSidebar, "d-flex flex-column p-3 p-absolute")}>
      <div className="f-w-700 text-muted">{strings.chevron_sidebar_title}</div>
      {[1, 2, 3].map(item => (
        <div key={item} className="mt-3">
          <Skeleton height={10} width={"100%"} />
          {[11, 12, 13].map(item => (
            <div key={item} className="mt-2">
              <Avatar loading avatarOnly />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ChevronSidebarLoader;
