import {
  ADD_TASK_TYPE,
  DELETE_TASK_TYPE,
  FETCH_TASK_TYPES,
  UPDATE_TASK_TYPE,
  SET_TASK_TYPES_LOADING,
} from "../../constant/actionTypes";
import { clone } from "../../helpers/Util";
export const INITIAL_STATE = {
  list: [],
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  initialRequestCalled: false,
  loading: true,
  error: "",
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TASK_TYPES:
      const newState = clone({
        ...state,
        loading: action.payload.loading,
        list: action.payload.list,
        error: action.payload.error,
        initialRequestCalled: true,
        permittedMeta: action.payload.permitted_meta,
        pagination: action.payload.pagination,
      });
      return newState;
    case ADD_TASK_TYPE:
      const list = state.list;
      list.unshift(action.payload.taskType);

      return {
        ...state,
        list,
      };

    case UPDATE_TASK_TYPE: {
      const list = state.list;
      let index = list.findIndex(e => e.id === action.payload.taskType.id);
      list[index] = action.payload.taskType;
      return {
        ...state,
        list,
      };
    }

    case DELETE_TASK_TYPE: {
      const list = state.list;
      let index = list.findIndex(e => e.id === action.payload.taskTypeId);
      list.splice(index, 1);
      return {
        ...state,
        list,
      };
    }

    case SET_TASK_TYPES_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    default:
      return state;
  }
};
