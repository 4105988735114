// Calls smooth scroll using ref of particular component
export const smoothScrollError = (errors, refObject, isSubmitting) => {
  const errorKey = Object.keys(errors)?.[0];
  if (
    Object.keys(errors).length > 0 &&
    isSubmitting &&
    errorKey &&
    window.scrollY !== 0 &&
    refObject[errorKey] &&
    refObject[errorKey].current
  ) {
    refObject[errorKey].current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};
