import { APPROVAL_STATUSES } from "constant/appConstants";
import React, { useState } from "react";
import { strings } from "../../constant/strings";
import { acceptRejectNegotiation } from "../../helpers/ApiHelper";

import { IF, Modal } from "../common";
import Toast from "../common/Toast";
import { getMostRecentBuyingOffer } from "./OfferTrials/OfferTrialsHelpers";

const AcceptOfferModal = ({ data, statuses, showModal, setShowModal, setShowCounterModal, auctionUser }) => {
  const [loading, setLoading] = useState(null);

  const clientInfo = data?.started_by;

  const isAuctionDre = !!auctionUser?.DRE;
  const isPurchaser = !!auctionUser?.Purchaser;
  const isAuctionHOD = !!auctionUser?.HOD;

  const getNegotiatedOffer = () => {
    const negotiatedOffer = data?.negotiation_offers?.find(offer => offer?.id === showModal);

    // when purchaser is logged in and his negotiated offer has been adjusted, he will have the option to accept the Dealers latest offer
    if (isPurchaser && "adjusted_negotiated_offer" in negotiatedOffer)
      return getMostRecentBuyingOffer(data?.negotiation_offers);

    return negotiatedOffer?.negotiated_offer;
  };

  const negotiatedOffer = getNegotiatedOffer();

  console.log({ data });
  const onAccept = async () => {
    const payload = {
      status_id: statuses?.find(status => status?.slug === APPROVAL_STATUSES.ACCEPTED)?.id,
    };

    setLoading(true);
    const res = await acceptRejectNegotiation(data?.id, payload)
      .then(res => {
        if (!res.success) {
          setLoading(false);
          throw new Error("Bad Request");
        }
        setShowModal(false);
        return res;
      })
      .then(data => {
        setLoading(false);
        setShowModal(false);
      })
      .catch(error => {
        setLoading(false);
        Toast.error(error.message);
      });
  };

  return (
    <div>
      <Modal
        toggle={() => setShowModal(null)}
        title={
          <div>
            {strings.accept_offer}
            {/* <Status {...data?.status} /> */}
          </div>
        }
        open={!!showModal}
        body={() => (
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <IF condition={isAuctionDre}>
              <span>Do you want to accept the offer of AED {negotiatedOffer} made by the Purchaser</span>
            </IF>
            <IF condition={isPurchaser || isAuctionHOD}>
              <span>Do you want to accept the offer of AED {negotiatedOffer} made by the Bidder</span>
            </IF>
          </div>
        )}
        actions={[
          {
            label: strings.accept,
            onClick: () => onAccept(),
            color: "primary",
            className: "modal__cancel-btn",
            loading: loading,
          },
        ]}
      />
    </div>
  );
};

export default AcceptOfferModal;
