import * as dragula from "react-dragula";

import React, { useEffect, useState } from "react";
import { addQuickLink, deleteQuickLink, fetchQuickLinks } from "../../actions/general/quickLink.action";
import {
  deleteQuickLinksApi,
  saveQuickLinksApi,
  sortQuickLinkApi,
  uploadPaymentSheetApi,
} from "../../helpers/ApiHelper";
import { checkUndefinedApiParams, dropDownListToShow, getErrorString, getPermission } from "../../helpers/Util";

import { CustomAlert } from "./CustomAlert";
import FormInput from "./FormInput";
import Modal from "./ModalBase";
import { RowAction } from "./RowAction";
import { Toast } from ".";
import { X } from "react-feather";
import { connect, useSelector } from "react-redux";
import { strings } from "../../constant/strings";
import ImportLeadsModal from "./ImportLeadsModal";
import { selectTenant } from "../../reducers/general/selectors";
import { RESTRICTED_MODULES } from "constant/appConstants";

const ManageQuickSearch = props => {
  const {
    history,
    type,
    addQuickLink,
    quickLinkRef,
    deleteQuickLink,
    linksPayload,
    fetchQuickLinks,
    queryParam,
    extraRowActions = null,
  } = props;
  const tenant = useSelector(selectTenant);

  const [createModal, setCreateModal] = useState(false);
  const [manageModal, setManageModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [showImportLeadModal, setShowImportLeadModal] = useState(false);
  const restrictedModules = tenant?.configuration?.restricted_modules;
  const isBulkImportCrmLeads = !!restrictedModules?.includes(RESTRICTED_MODULES.BULK_IMPORT_CRM_LEADS);

  let ids = [];
  let links = linksPayload?.[type];

  useEffect(() => {
    ids = links?.map(e => e.id);
  }, [links]);

  const dragulaDecorator = componentBackingInstance => {
    if (componentBackingInstance) {
      let options = {};
      const drake = dragula([componentBackingInstance], options);
      drake.on("drop", (el, target, source, sibling) => {
        const targetIds = [];
        let a = target?.childNodes?.forEach(e => {
          targetIds.push(Number(e.id));
        });
        ids = targetIds;
      });
    }
  };

  const onSave = async () => {
    const link = `${history.location.pathname}${history.location.search}`;
    if (searchName) {
      setLoading(true);
      const response = await saveQuickLinksApi({
        quick_link: {
          name: searchName,
          link,
          link_type: type,
        },
      });
      if (response) {
        setLoading(false);
        if (response.quick_link) {
          setSearchName("");
          setCreateModal(false);
          setStatus("");
          addQuickLink(response.quick_link, type);
          quickLinkRef.current.setQuickLinks();
        } else {
          setStatus(getErrorString(response));
        }
      }
    } else {
      setLoading(false);
      setStatus(strings.add_search_name);
    }
  };

  const onDelete = async item => {
    if (!checkUndefinedApiParams(item?.id, "item")) return;
    const response = await deleteQuickLinksApi(item?.id);
    if (response && response.success) {
      deleteQuickLink(item, type);
    } else {
      Toast.error(strings.quick_link_delete_error);
    }
  };

  const onSort = async ids => {
    if (ids.length && JSON.stringify(ids) !== JSON.stringify(links.map(e => e.id))) {
      const obj = { quick_link_ids: ids };
      const response = await sortQuickLinkApi(obj);
      if (response) {
        if (response.success) {
          fetchQuickLinks("");
          setManageModal(false);
        } else {
          Toast.error(strings.quick_link_sort_error);
        }
      }
    } else {
      setManageModal(false);
    }
  };

  let rowActionsList = [
    {
      showListItem: getPermission("CrmLead", "bulk_import") && props.fetchLeads && !isBulkImportCrmLeads,
      title: strings.import_leads,
      onPress: () => {
        setShowImportLeadModal(true);
      },
    },
    {
      showListItem: getPermission("QuickLink", ["sort", "destroy"]),
      title: strings.manage_quick_link,
      onPress: () => {
        setManageModal(true);
      },
    },
  ];
  if (extraRowActions) {
    rowActionsList = [...extraRowActions, ...rowActionsList];
  }
  return (
    <>
      <Modal
        toggle={() => {
          setStatus("");
          setCreateModal(!createModal);
        }}
        title={strings.create_quick_link}
        open={createModal}
        className="save_model"
        body={() => (
          <>
            <FormInput
              className="save_model_row"
              placeholder={strings.name_your_search}
              onChange={e => {
                setSearchName(e.target.value);
                if (/^(?!\s+$).*/.test(e.target.value)) {
                  if (searchName.length < 25) {
                    setStatus("");
                  } else {
                    setStatus(strings.character_validation);
                  }
                } else {
                  setStatus(strings.quick_link_space_validation);
                }
              }}
              value={searchName}
            />
            {status && <CustomAlert message={status} />}
          </>
        )}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setSearchName("");
              setCreateModal(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
          {
            label: strings.done,
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: onSave,
            disabled: loading || !!status,
          },
        ]}
      />
      <Modal
        toggle={() => {
          setManageModal(!manageModal);
        }}
        title={strings.manage_quick_link}
        open={manageModal}
        className="save_model"
        body={() =>
          links?.length ? (
            <div className="ui-sortable small-drag manage__links-list" id="draggableMultiple">
              <div
                className="container"
                ref={getPermission("QuickLink", "sort") ? dragulaDecorator : undefined}
                id="quickLink"
              >
                {links.map((e, i) => (
                  <div id={e.id} index={i} key={i}>
                    <p>{e.name}</p>
                    {getPermission("QuickLink", "destroy") && (
                      <X size={18} onClick={() => onDelete(e)} className="hover-pointer" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p>{strings.no_link_available}</p>
          )
        }
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setManageModal(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
          {
            label: strings.done,
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: () => onSort(ids),
          },
        ]}
      />

      <ImportLeadsModal
        title={strings.bulk_import_leads}
        apiCall={uploadPaymentSheetApi}
        heading={strings.payment_sheet_modal_heading}
        setShowModal={setShowImportLeadModal}
        onToggle={() => setShowImportLeadModal(!showImportLeadModal)}
        showModal={showImportLeadModal}
        fetchLeads={props.fetchLeads}
      />
      <RowAction dropDownList={dropDownListToShow(rowActionsList)} />
    </>
  );
};

const mapStateToProps = ({ QuickLinksData }) => {
  return {
    linksLoading: QuickLinksData.loading,
    linksError: QuickLinksData.error,
    linksPayload: QuickLinksData.links,
  };
};

export default connect(mapStateToProps, {
  addQuickLink,
  deleteQuickLink,
  fetchQuickLinks,
})(ManageQuickSearch);
