import * as yup from "yup";
import { AsyncSelect, CheckboxComponent, FormInput, IF, Toast } from "../common";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { addTaskType, updateTaskType } from "../../actions/crm/taskTypes.action";
import { addTaskTypeApi, fetchUserRolesApi, updateTaskTypeApi } from "../../helpers/ApiHelper";
import { getErrorString, makeListToSelectData } from "../../helpers/Util";
import { Formik } from "formik";
import { connect } from "react-redux";
import { strings } from "../../constant/strings";
import { selectTenant } from "../../reducers/general/selectors";
import { useSelector } from "react-redux";
import { RESTRICTED_MODULES } from "constant/appConstants";
import FormRow from "../common/FormRow";

const getPayload = (obj = {}) => {
  const taskTypeObj = {
    task_type: {
      name: obj?.values.taskTypeName,
      ...(obj?.values.appointmentRequired === true
        ? { role_ids: obj?.values?.roles.map(el => el.id) }
        : { role_ids: [] }),
      appointment_required: obj?.values.appointmentRequired,
      inventory_required: obj?.values.inventoryRequired,
      payment_required: obj?.values.paymentRequired,
      ...(!obj?.isFollowUpRestricted && {
        follow_up_required: obj?.values.followUpRequired,
      }),
      start_time_required: obj?.values?.startTimeRequired,
    },
  };
  return taskTypeObj;
};

const AddTaskTypeForm = props => {
  const { history, taskTypeData } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const tenant = useSelector(selectTenant);

  const restrictedModules = tenant?.configuration?.restricted_modules;
  const isFollowUpRestricted = restrictedModules?.includes(RESTRICTED_MODULES.FOLLOW_UP);

  const formikRef = useRef();
  useImperativeHandle(props.fRef, () => ({
    submitForm() {
      return formikRef.current.submitForm();
    },
  }));

  // Initial Values
  const getInitialValues = props => ({
    taskTypeName: props.name ?? "",
    roles: props.roles ?? [],
    appointmentRequired: !!props?.appointment_required,
    inventoryRequired: !!props.inventory_required,
    paymentRequired: !!props.payment_required,
    ...(!isFollowUpRestricted && {
      followUpRequired: !!props.follow_up_required,
    }),
    startTimeRequired: !!props?.start_time_required,
  });

  const validationSchema = () =>
    yup.object().shape({
      taskTypeName: yup.string().required(strings.enter_task_type_name),
      roles: yup.array().when("appointmentRequired", {
        is: true,
        then: yup.array().min(1, strings.select_role_message),
        otherwise: yup.array(),
      }),
    });

  const onSubmit = async values => {
    const payload = getPayload({ values, isFollowUpRestricted });

    setSaveLoading(true);
    let response;
    if (taskTypeData) {
      response = await updateTaskTypeApi(taskTypeData.id, payload);
    } else {
      response = await addTaskTypeApi(payload);
    }
    if (response) {
      setSaveLoading(false);
      if (response.success && response.task_type) {
        taskTypeData ? props.updateTaskType(response.task_type) : props.addTaskType(response.task_type);
        props.closeModal();
      } else {
        Toast.error(getErrorString(response));
      }
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues({ ...taskTypeData })}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, status, setFieldValue }) => {
        return (
          <form className="form theme-form" onSubmit={handleSubmit}>
            <FormInput
              label={strings.name}
              placeholder={strings.task_type_name}
              onChange={handleChange("taskTypeName")}
              onBlur={handleBlur("taskTypeName")}
              value={values.taskTypeName || ""}
              horizontal
              errorMsg={errors.taskTypeName && touched.taskTypeName && errors.taskTypeName}
              required
            />
            <IF condition={values.appointmentRequired === true}>
              <FormRow horizontal={true} textRight={true} label={strings.select_role}>
                <AsyncSelect
                  className=""
                  isClearable={false}
                  selected={makeListToSelectData(values.roles) || []}
                  listApi={fetchUserRolesApi}
                  listProperty={"roles"}
                  isMulti={true}
                  queryKey={"s[name]"}
                  errorMsg={errors.roles && touched.roles && errors.roles}
                  onChange={selected => {
                    setFieldValue("roles", selected);
                  }}
                  formatOptionLabel={props.formatOptionLabel}
                  onBlur={handleBlur("roles")}
                  placeholder={strings.role}
                />
              </FormRow>
            </IF>
            <div className="form-group row">
              <div className="col-sm-9 offset-sm-3">
                <CheckboxComponent
                  label={strings.appointment_required}
                  checked={values.appointmentRequired || false}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue("appointmentRequired", true);
                      setFieldValue("inventoryRequired", true);
                    } else {
                      setFieldValue("appointmentRequired", false);
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-sm-9 offset-sm-3">
                <CheckboxComponent
                  label={strings.inventory_required}
                  checked={values.inventoryRequired || false}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue("inventoryRequired", true);
                    } else {
                      setFieldValue("inventoryRequired", false);
                      setFieldValue("paymentRequired", false);
                      setFieldValue("appointmentRequired", false);
                    }
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-9 offset-sm-3">
                <CheckboxComponent
                  label={strings.payment_required}
                  checked={values.paymentRequired}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue("inventoryRequired", true);
                      setFieldValue("paymentRequired", true);
                    } else {
                      setFieldValue("paymentRequired", false);
                    }
                  }}
                />
              </div>
            </div>
            {!isFollowUpRestricted && (
              <div className="form-group row">
                <div className="col-sm-9 offset-sm-3">
                  <CheckboxComponent
                    label={strings.follow_up_required}
                    checked={values.followUpRequired || false}
                    onChange={e => {
                      if (e.target.checked) {
                        setFieldValue("followUpRequired", true);
                      } else {
                        setFieldValue("followUpRequired", false);
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div className="form-group row">
              <div className="col-sm-9 offset-sm-3">
                <CheckboxComponent
                  label={strings.start_time_required}
                  checked={values.startTimeRequired}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue("startTimeRequired", true);
                    } else {
                      setFieldValue("startTimeRequired", false);
                    }
                  }}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

const ConnectedComponent = connect(() => ({}), {
  updateTaskType,
  addTaskType,
})(AddTaskTypeForm);

export default forwardRef((props, ref) => <ConnectedComponent {...props} fRef={ref} />);
