import { DYNAMIC_MODELS, MILEAGE, PERMITTED_ATTRIBUTE_MODELS } from "constant/appConstants";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import * as yup from "yup";
import { getDynamicFieldInitialValues } from "../components/common/DyamicFields/DynamicFieldHelpers";
import { getDynamicFieldsValidationSchema } from "../components/common/DyamicFields/DynamicFieldSchemaHelpler";
import { classificationList, inspectionLeadClassificationList, testDriveCicoList } from "../constant/lists";
import { strings } from "../constant/strings";
import { store } from "../store/index";
import {
  addPermittedProperty,
  isEmptyObject,
  isFieldViewable,
  isObjRestricted,
  makeAvailabilityHoursData,
  showExtraFields,
} from "./Util";
import { nameValidation, phoneValidation } from "./validations";

export const validationSchemaHelper = (
  schema,
  permittedAttributes,
  tenant,
  dynamicFields,
  setFormikValidationSchema,
  dynamicModel,
  staticSchema
) => {
  const getDynamicFieldsSchema = (fields = dynamicFields, model = dynamicModel) => {
    const dynamicSchema = {
      ...(getDynamicFieldsValidationSchema({
        dynamicFields: fields,
        activeColumnsModel: model,
        activeColumnsPermittedAttributes: permittedAttributes,
        tenant,
      }) || {}),
    };
    return dynamicSchema;
  };
  const getFlattenedSchema = schema => {
    let flattenedSchema = Object.values(schema);
    flattenedSchema = flattenedSchema.reduce((prev, current) => ({ ...prev, ...current }), {});
    return yup.object({ ...flattenedSchema });
  };

  const getValidationSchema = () => {
    {
      /* by zulqarnain: previously there was getFlattenedSchema(schema); needed to change it
  getFlattenedSchema(updatedSchema) because any runtime time change e.g. cta click in static schema was not
  reflecting to the edit form formik validations
  */
    }

    let updatedSchema = { ...schema };
    if (staticSchema && Object.keys(staticSchema).length > 0) {
      updatedSchema = { ...updatedSchema, staticSchema: { ...staticSchema } };
    }
    if (isEmptyObject(updatedSchema)) return yup.object({});
    return getFlattenedSchema(updatedSchema);
  };
  const setValidationSchema = () => {
    return setFormikValidationSchema({
      staticSchema: { ...staticSchema },
      dynamicFieldsSchema: { ...getDynamicFieldsSchema() },
    });
  };
  const appendDynamicFieldTypesSchema = (value, key) => {
    const dynamicFields = value?.dynamic_fields;
    const fieldTypeSchema = {
      [key]: getDynamicFieldsSchema(dynamicFields, key),
    };
    const newSchema = {
      ...schema,
      ...fieldTypeSchema,
    };
    setFormikValidationSchema(newSchema);
  };
  const appendAdditionalDynamicFieldSchema = (additionalDynamicFields = [], model, oldAdditionalDynamicFields) => {
    const deleteOldDependentFields = (oldSchema, deletionFields) => {
      let filteredObj = { ...oldSchema };
      for (let i = 0; i < deletionFields?.length; i++) {
        delete filteredObj[`${model}_dynamic_${deletionFields[i]?.field_name}`];
      }
      return filteredObj;
    };
    let updatedDynamicSchema = !!oldAdditionalDynamicFields.length
      ? deleteOldDependentFields(schema.dynamicFieldsSchema, oldAdditionalDynamicFields)
      : { ...schema.dynamicFieldsSchema };

    const newSchema = {
      staticSchema: { ...schema.staticSchema },
      dynamicFieldsSchema: { ...updatedDynamicSchema, ...getDynamicFieldsSchema(additionalDynamicFields, model) },
    };
    setFormikValidationSchema(newSchema);
  };
  const appendDependentDynamicFieldsSchema = (
    dynamicFields,
    oldDynamicFields,
    setFieldError,
    setFieldTouched,
    typeOfFields
  ) => {
    const deleteOldDependentFields = (oldSchema, deletionFields) => {
      let filteredObj = { ...oldSchema };
      for (let i = 0; i < deletionFields?.length; i++) {
        setFieldError(`${typeOfFields}${deletionFields[i]?.field_name}`, "");
        setFieldTouched && setFieldTouched(`${typeOfFields}${deletionFields[i]?.field_name}`, false);
        delete filteredObj[`${typeOfFields}${deletionFields[i]?.field_name}`];
      }
      return filteredObj;
    };
    let updatedDynamicSchema = !!oldDynamicFields.length
      ? deleteOldDependentFields(schema.dynamicFieldsSchema, oldDynamicFields)
      : { ...schema.dynamicFieldsSchema };
    const newSchema = {
      staticSchema: { ...schema.staticSchema },
      dynamicFieldsSchema: { ...updatedDynamicSchema, ...getDynamicFieldsSchema(dynamicFields) },
    };
    setFormikValidationSchema(newSchema);
  };

  return {
    getSchema: getValidationSchema,
    setSchema: setValidationSchema,
    appendDynamicFieldTypesSchema: appendDynamicFieldTypesSchema,
    appendDependentDynamicFieldsSchema: appendDependentDynamicFieldsSchema,
    appendAdditionalDynamicFieldSchema: appendAdditionalDynamicFieldSchema,
  };
};

const getModelPermittedAttributes = type => store.getState().PermissionsData?.activeColumns?.[type];
const inspectionLeadHelper = (type, dynamicFields, extraProps) => {
  const tenant = store.getState().Configs.tenant;

  const { isPrimaryPhoneEditable = false, addPhoneNumbersInAPIPayload = false, isEditLeadPage } = extraProps || {};
  const permittedAttributes = getModelPermittedAttributes(type);

  const shouldAddPhoneNumbersInValidationSchema =
    !isEditLeadPage || (isEditLeadPage && isPrimaryPhoneEditable && addPhoneNumbersInAPIPayload);
  const vehicleInformation = [
    {
      key: "make",
      message: "Please select make",
      viewable: isFieldViewable(permittedAttributes?.["make.name"]),
    },
    {
      key: "model",
      message: "Please select model",
      viewable: isFieldViewable(permittedAttributes?.["model.name"]),
    },
    {
      key: "variant",
      message: "Please select variant",
      viewable: isFieldViewable(permittedAttributes?.["variant.name"]),
    },
    {
      key: "exteriorColor",
      message: "Please select exterior color",
      viewable: isFieldViewable(permittedAttributes?.["exterior_color.name"]),
    },
    {
      key: "modelYear",
      message: "Please select model year",
      viewable: isFieldViewable(permittedAttributes?.["model_year"]),
    },
    {
      key: "bodyType",
      message: "Please select body type",
      viewable: isFieldViewable(permittedAttributes?.["body_type.name"]),
    },
  ];

  const vehicleInformationValidationSchema = vehicleInformation.reduce((acc, item) => {
    // @ts-ignore
    const type = item.type;
    if (item.viewable) {
      acc[item.key] = yup
        .mixed()
        .nullable()
        .notRequired()
        .when(["lead_type"], {
          is: lead_type => showExtraFields(lead_type),
          then: yup.mixed().nullable(),
        });
    }
    return acc;
  }, {});

  const staticSchema = {
    ...addPermittedProperty(permittedAttributes?.["client.name"], {
      name: nameValidation(strings.lead_name, 30, /^[a-zA-Z0-9 ]*$/),
    }),
    ...addPermittedProperty(permittedAttributes?.["client.email"], {
      email: yup.string().email(strings.type_valid_email).nullable(),
    }),
    ...addPermittedProperty(permittedAttributes?.["lead_type.name"], {
      lead_type: yup.object().required(strings.select_lead_type).nullable(),
    }),
    ...(!!shouldAddPhoneNumbersInValidationSchema &&
      addPermittedProperty(permittedAttributes?.["client.phone"], {
        phone: phoneValidation(tenant.configuration?.phone_regex),
      })),
    ...addPermittedProperty(permittedAttributes?.["city.name"], {
      city: yup.string().required(strings.select_city),
    }),
    ...addPermittedProperty(permittedAttributes?.["classification"], {
      classification: yup.string(),
    }),
    // ...addPermittedProperty(permittedAttributes?.["lead_source.name"], {
    //   leadSource: yup.string().nullable().required(strings.select_lead_source),
    // }),
    ...vehicleInformationValidationSchema,
  };
  const staticInitialValues = {
    ...addPermittedProperty(permittedAttributes?.["client.name"], { name: "" }),
    ...addPermittedProperty(permittedAttributes?.["client.email"], { email: "" }),
    ...addPermittedProperty(permittedAttributes?.["lead_type.name"], { lead_type: null }),
    ...addPermittedProperty(permittedAttributes?.["client.phone"], { phone: "" }),
    ...addPermittedProperty(permittedAttributes?.["city.name"], { city: "" }),
    ...addPermittedProperty(permittedAttributes?.["classification"], {
      classification: inspectionLeadClassificationList[1].id,
    }),
    ...addPermittedProperty(permittedAttributes?.["make.name"], {
      make: null,
    }),
    ...addPermittedProperty(permittedAttributes?.["model.name"], {
      model: null,
    }),
    ...addPermittedProperty(permittedAttributes?.["variant.name"], {
      variant: null,
    }),
    ...addPermittedProperty(permittedAttributes?.["exterior_color.name"], {
      exteriorColor: null,
    }),
    ...addPermittedProperty(permittedAttributes?.["body_type.name"], {
      bodyType: null,
    }),
    ...addPermittedProperty(permittedAttributes?.["model_year"], {
      modelYear: "",
    }),
  };
  const getStaticFieldsInitialValues = () => ({ ...staticInitialValues });
  const getInitialValues = () => {
    const data = {
      ...getStaticFieldsInitialValues(),
    };
    return Object.assign({}, data, ...getDynamicFieldInitialValues(dynamicFields, permittedAttributes, "lead"));
  };
  return {
    getStaticInitialValues: getStaticFieldsInitialValues,
    getStaticSchema: () => ({ ...staticSchema }),
    getInitialValues: getInitialValues,
  };
};

/* TODO */
const crmTaskHelper = () => {};

const crmCicoHelper = (type = "", dynamicFields, extraProps) => {
  try {
    const permittedAttributes = getModelPermittedAttributes(type);
    const {
      formikRef = null,
      cicoId,
      cico,
      getInventoryFromHistory = () => {},
      getFormatedImageObj = () => {},
      getLocationsFromInventory = () => {},
      getCicoReasonFromType = () => {},
      getCicoTypeFromInventory = () => {},
      CICO_PICTURE = [],
      inventory,
      user,
      showTestDriveFields,
      latestCico,
    } = extraProps;
    let updatedDynamicFields = dynamicFields;
    const staticSchema = {
      ...addPermittedProperty(permittedAttributes?.["inventory.reference_number"], {
        inventory: yup.object().nullable().required(strings.inventory_required),
      }),
      ...addPermittedProperty(permittedAttributes?.["cico_reason.name"], {
        reason: yup.object().nullable().required("Select Reason"),
      }),
      ...addPermittedProperty(permittedAttributes?.["location.address"], {
        location: yup.object().nullable().required("Select Location"),
      }),
      ...addPermittedProperty(permittedAttributes?.["requested_by.full_name"], {
        assigned_by: yup.object().nullable().required("Select Assignee"),
      }),
      ...addPermittedProperty(permittedAttributes?.["cico_type.name"], {
        type: yup.string().required("Select Type"),
      }),
      ...addPermittedProperty(permittedAttributes?.["fsr.id"], {
        fsr_id: yup.string().when(["showTestDriveFields"], {
          is: () => showTestDriveFields,
          then: yup.string().nullable().required("Select FSR"),
          otherwise: yup.string().nullable(),
        }),
      }),
      agreement: yup.string().when([], {
        is: () => showTestDriveFields,
        then: yup
          .string()
          .oneOf(["true"], "Please accept the terms to continue")
          .required("Please accept the terms to continue"),
      }),
      ...addPermittedProperty(permittedAttributes?.["crm_lead.id"], {
        crm_lead_id: yup.string().when(["showTestDriveFields"], {
          is: () => showTestDriveFields,
          then: yup.string().nullable().required("Select Lead"),
          otherwise: yup.string().nullable(),
        }),
      }),
      ...Object.assign(
        {},
        ...CICO_PICTURE.map(side => {
          return addPermittedProperty(permittedAttributes?.[`${side}.file_name`], {
            [side]: yup.lazy(() => {
              if (formikRef.current && formikRef.current.values.imageValidation) {
                return yup
                  .array()
                  .of(
                    yup.object().shape({
                      id: yup.string().required(strings.image_required),
                    })
                  )
                  .required(strings.image_required)
                  .nullable();
              }
              return yup.mixed().notRequired();
            }),
          });
        })
      ),
    };

    const getInitialValues = permittedAttributes => {
      const data = {
        imageValidation: false,
        ...addPermittedProperty(permittedAttributes?.["inventory.reference_number"], {
          inventory: cicoId ? cico.inventory : getInventoryFromHistory(),
        }),
        ...addPermittedProperty(permittedAttributes?.["cico_type.name"], {
          type: cicoId ? cico?.cico_type?.id.toString() : getCicoTypeFromInventory(inventory, latestCico),
        }),
        ...addPermittedProperty(permittedAttributes?.["cico_reason.name"], {
          reason: getCicoReasonFromType(inventory, latestCico),
        }),
        ...addPermittedProperty(permittedAttributes?.["location.address"], {
          location: cicoId ? cico?.location : getLocationsFromInventory(inventory),
        }),
        ...addPermittedProperty(permittedAttributes?.["requested_by.full_name"], {
          assigned_by: cicoId ? cico.requested_by : user,
        }),
        ...addPermittedProperty(permittedAttributes?.["fsr.id"], {
          fsr_id: null,
        }),
        ...addPermittedProperty(permittedAttributes?.["crm_lead.id"], {
          crm_lead_id: null,
        }),
        agreement: false,
        ...Object.assign(
          {},
          ...CICO_PICTURE.map(side => {
            return addPermittedProperty(permittedAttributes?.[`${side}.file_name`], {
              [side]: cicoId ? getFormatedImageObj(side, cico) : null,
            });
          })
        ),
        ...addPermittedProperty(permittedAttributes?.["comments.comment"], {
          comment: cicoId ? cico.comments && cico?.comments[0]?.comment : null,
        }),
      };

      if (!showTestDriveFields) {
        updatedDynamicFields = dynamicFields?.filter(field => !testDriveCicoList.includes(field.field_name));
      }
      const initialValues = Object.assign(
        {},
        data,
        ...getDynamicFieldInitialValues(updatedDynamicFields, permittedAttributes, DYNAMIC_MODELS.CICO)
      );
      return initialValues;
    };

    return {
      getStaticSchema: () => ({ ...staticSchema }),
      getInitialValues: () => getInitialValues(permittedAttributes),
    };
  } catch (error) {
    console.log("error:", error);
  }
};
const crmLeadHelper = (type, dynamicFields, extraProps) => {
  const permittedAttributes = getModelPermittedAttributes(type);
  const {
    formikRef,
    lead,
    fromContract = false,
    addPhoneNumbersInAPIPayload = false,
    isPrimaryPhoneEditable = false,
    isSecondaryPhoneEditable = false,
    showPhoneNumberCTA = false,
    categoryDynamicFields = {},
    categoryTypeDynamicFields = {},
  } = extraProps;

  // const isAddNewLeadPage = !isEditLeadPage;
  const tenant = store.getState().Configs.tenant;

  const staticSchema = {
    ...addPermittedProperty(permittedAttributes?.["category.name"], {
      lead_category: yup.object().nullable().required(strings.select_lead_category),
    }),
    ...addPermittedProperty(permittedAttributes?.["category_type.name"], {
      category_type: yup.string().nullable().required(strings.select_category_type),
    }),
    ...addPermittedProperty(permittedAttributes?.["lead_source.name"], {
      lead_source: yup.string().nullable().required(strings.select_lead_source),
    }),
    ...addPermittedProperty(permittedAttributes?.["city.name"], {
      city: yup.string().nullable().required(strings.select_city),
    }),
    ...addPermittedProperty(permittedAttributes?.["client.name"], {
      name: yup
        .string()
        .nullable()
        .required(strings.enter_name_message)
        .matches(new RegExp(/^[A-Za-z0-9 ]*$/), strings.add_lead_name_message),
    }),
    // ...addPermittedProperty(permittedAttributes?.["inventory.title"], {
    //   preferred_buyer_lead: yup
    //     .object()
    //     .nullable()
    //     .notRequired()
    //     .when(["lead_category"], {
    //       is: leadCategory => leadCategory?.slug === "vas",
    //       then: yup.object().nullable().required("Please select Lead"),
    //     }),
    // }),

    ...(fromContract &&
      isFieldViewable(permittedAttributes?.["client.address"]) && {
        address: yup.string().nullable().required(strings.select_address),
      }),

    ...((!showPhoneNumberCTA || (showPhoneNumberCTA && isPrimaryPhoneEditable && addPhoneNumbersInAPIPayload)) && {
      ...addPermittedProperty(permittedAttributes?.["client.phone"], {
        phone: phoneValidation(tenant.configuration.phone_regex),
      }),
    }),
    ...((!showPhoneNumberCTA || (showPhoneNumberCTA && isSecondaryPhoneEditable && addPhoneNumbersInAPIPayload)) && {
      ...addPermittedProperty(permittedAttributes?.["client.secondary_phone"], {
        secondary_phone: phoneValidation(tenant.configuration.phone_regex, false /*required*/, true /*nullable*/),
      }),
    }),

    ...addPermittedProperty(permittedAttributes?.["client.email"], {
      email: fromContract
        ? yup.string().email(strings.type_valid_email).required(strings.enter_email).nullable()
        : yup.string().email(strings.type_valid_email).nullable(),
    }),
  };
  let inventorySet = lead?.inventory ?? lead?.preferred_inventory;
  let inventorySetPemittedPropertyCheck = lead?.inventory !== null ? "inventory" : "preferred_inventory";
  const getInitialValues = crmLeadPermittedAttributes => {
    let data = {
      ...formikRef?.current?.values,
      ...addPermittedProperty(crmLeadPermittedAttributes?.["category.name"], {
        lead_category: lead ? lead.category : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["category_type.name"], {
        category_type: lead ? lead.category_type : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["lead_source.name"], {
        lead_source: lead ? lead.lead_source : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["assignee.full_name"], {
        lead_assignee: lead ? lead.assignee : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["classification"], {
        classification:
          lead?.classification && lead?.classification_id
            ? { id: lead.classification_id, name: lead.classification }
            : classificationList[2],
      }),

      ...addPermittedProperty(crmLeadPermittedAttributes?.["city.name"], {
        city: lead ? lead.city : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["client.name"], {
        name: lead ? lead.client?.name : "",
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["client.phone"], {
        phone: lead ? lead.client?.phone : "",
      }),

      ...addPermittedProperty(crmLeadPermittedAttributes?.["client.email"], {
        email: lead ? lead.client?.email : "",
      }),

      ...addPermittedProperty(crmLeadPermittedAttributes?.["client.secondary_phone"], {
        secondary_phone: lead ? lead.client?.secondary_phone : "",
      }),

      ...addPermittedProperty(crmLeadPermittedAttributes?.["client.address"], {
        address: lead ? lead.client?.address : "",
      }),

      ...addPermittedProperty(crmLeadPermittedAttributes?.["buyer_lead.client.name"], {
        preferred_buyer_lead: lead ? lead?.buyer_lead : null,
      }),
      // Radio Button
      ...addPermittedProperty(
        crmLeadPermittedAttributes?.["preference.car_preferences.name"],
        lead &&
          lead?.preference && {
            buyer_preference: "make-modal",
          }
      ),
      ...addPermittedProperty(
        crmLeadPermittedAttributes?.["preferred_inventory.title"],
        lead && lead?.preferred_inventory && { buyer_preference: "inventory" }
      ),

      // If inventory Id

      ...addPermittedProperty(crmLeadPermittedAttributes?.[`${inventorySetPemittedPropertyCheck}.title`], {
        inventory:
          lead && inventorySet && !isObjRestricted(inventorySet, ["id", "title", "reference_number"])
            ? {
                id: inventorySet?.id,
                title: inventorySet?.title,
                reference_number: inventorySet?.reference_number,
              }
            : null,
      }),

      // If Car Preference

      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.min_price"], {
        min_price: lead ? lead.preference?.min_price : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.max_price"], {
        max_price: lead ? lead.preference?.max_price : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.mileage"], {
        mileage: lead ? lead.preference?.mileage : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.engine_capacity"], {
        engine_capacity: lead ? lead.preference?.engine_capacity : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.assembly_id"], {
        assembly: lead ? lead.preference?.assembly : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.inspection_score"], {
        inspection_score: lead ? lead.preference?.inspection_score : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.body_types.name"], {
        body_type: lead ? lead.preference?.body_types : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.colors.name"], {
        exterior_color: lead ? lead.preference?.colors : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.registration_cities.name"], {
        registration_city: lead ? lead.preference?.registration_cities : null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.car_preferences.name"], {
        car_preferences_attributes: lead ? lead.preference?.car_preferences : null,
      }),
      assigneesData: [],
    };
    let initialValues = Object.assign(
      {},
      data,
      ...getDynamicFieldInitialValues(dynamicFields, permittedAttributes, "crm_lead"),
      ...getDynamicFieldInitialValues(categoryDynamicFields?.fields, permittedAttributes, categoryDynamicFields?.model),
      ...getDynamicFieldInitialValues(
        categoryTypeDynamicFields?.fields,
        permittedAttributes,
        categoryTypeDynamicFields?.model
      )
    );
    if (formikRef?.current?.values) {
      Object.keys(formikRef?.current?.values).forEach(key => {
        if (initialValues.hasOwnProperty(key)) {
          initialValues[key] = formikRef?.current?.values[key];
        }
      });
    }
    return initialValues;
  };
  return {
    getStaticSchema: () => ({ ...staticSchema }),
    getInitialValues: () => getInitialValues(permittedAttributes),
  };
};
const crmInventoryHelper = (type, dynamicFields, extraProps) => {
  const { crmLead, car, uploaded, fromContract = false } = extraProps;
  const permittedAttributes = getModelPermittedAttributes(type);
  const staticSchema = {
    ...((isFieldViewable(permittedAttributes?.["inventory.city.name"]) ||
      isFieldViewable(permittedAttributes?.["inventory.crm_lead.id"])) && {
      city: yup
        .object()
        .nullable()
        .required(strings.select_city)
        .test("city", strings.select_city, value => !!value?.name),
    }),
    ...addPermittedProperty(permittedAttributes?.["make.name"], {
      make: yup
        .object()
        .nullable()
        .required(strings.select_make)
        .test("make", strings.select_make, value => !!value?.select_value),
    }),
    ...addPermittedProperty(permittedAttributes?.["model.name"], {
      model: yup
        .object()
        .nullable()
        .required(strings.select_model)
        .test("model", strings.select_model, value => !!value?.select_value),
    }),

    // ...addPermittedProperty(permittedAttributes?.["variant.name"], {
    //   variant: yup
    //     .object()
    //     .nullable()
    //     .required(strings.select_variant)
    //     .test("variant", strings.select_variant, value => !!value?.select_value),
    // }),
    ...addPermittedProperty(permittedAttributes?.["model_year"], {
      year: yup
        .string()
        .nullable()
        .required(strings.select_year)
        .test("year", strings.select_year, value => value),
    }),
    ...addPermittedProperty(permittedAttributes?.["mileage"], {
      mileage: yup
        .string()
        .required(strings.enter_millage_message)
        .test("mileage", strings.enter_mileage_message, value => !isNaN(value) && value <= MILEAGE.MAX),
    }),
    ...addPermittedProperty(permittedAttributes?.["inventory.inventory_type.name"], {
      inventoryType: yup
        .object()
        .nullable()
        .required(strings.select_inventory_type)
        .test("city", strings.select_inventory_type, value => !!value?.name),
    }),

    ...addPermittedProperty(permittedAttributes?.["inventory.time_slots.address"], {
      addresses: yup.array().of(
        yup.object().shape({
          address_link: yup.string().nullable().url(strings.enter_link_validation),
          area: yup.string().nullable().required(strings.select_area_message),
        })
      ),
    }),
    ...addPermittedProperty(
      permittedAttributes?.["inventory.ask_price"],
      !fromContract && {
        demandPrice: yup.number().test("min-than-2000", "Price should be either greater or equal to 2000", val => {
          if (Number(val) < 2000) {
            return false;
          }
          return true;
        }),
      }
    ),
    ...(isFieldViewable(permittedAttributes?.["inventory.ask_price"]) &&
      fromContract && {
        demandPrice: yup
          .string(strings.invalid_amount)
          .nullable()
          .required(strings.enter_amount)
          .test("min-than-2000", "Price should be either greater or equal to 2000", val => {
            if (Number(val) < 2000) {
              return false;
            }
            return true;
          }),
      }),
    ...(isFieldViewable(permittedAttributes?.["engine_capacity"]) &&
      fromContract && {
        engineCapacity: yup
          .string(strings.enter_engine_capacity_message)
          .nullable()
          .required(strings.enter_engine_capacity_message)
          .test("engineCapacity", strings.enter_engine_capacity_message, value => Number(value) >= 0),
      }),
    // ...(isFieldViewable(permittedAttributes?.["registration_no"]) &&
    //   fromContract && {
    //     registrationNumber: yup.string().nullable().required(strings.select_reg_no),
    //   }),
  };
  const getInitialValues = inventoryPermittedAttributes => {
    const data = {
      makeAvailable: true,
      ...((isFieldViewable(inventoryPermittedAttributes?.["inventory.city.name"]) ||
        isFieldViewable(inventoryPermittedAttributes?.["inventory.crm_lead.id"])) && {
        city: car?.inventory?.city || crmLead?.city || null,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["make.name"], {
        make: car?.make || null,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["model.name"], {
        model: car?.model || null,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["variant.name"], {
        variant: car?.variant || null,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["model_year"], {
        year: car?.model_year || null,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["registration_city.name"], {
        registrationCity: car?.registration_city || null,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["mileage"], {
        mileage: car?.mileage || "",
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["registration_no"], {
        registrationNumber: car?.registration_no || "",
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["exterior_color.name"], {
        exteriorColor: car?.exterior_color || null,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["description"], {
        description: car?.description || "",
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["inventory.ask_price"], {
        demandPrice: car?.inventory?.ask_price || "",
      }),

      ...addPermittedProperty(inventoryPermittedAttributes?.["inventory.pictures.file_name"], {
        pictures:
          car?.inventory?.pictures?.map(pic => {
            return { ...pic, uploaded: true };
          }) || [],
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["body_type.name"], {
        bodyType: car?.body_type || null,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["engine"], {
        engineType: car?.engine || null,
      }),

      ...addPermittedProperty(inventoryPermittedAttributes?.["engine_capacity"], {
        engineCapacity: car?.engine_capacity || "",
      }),

      ...addPermittedProperty(inventoryPermittedAttributes?.["transmission"], {
        transmission: car?.transmission || null,
      }),

      ...addPermittedProperty(inventoryPermittedAttributes?.["assembly_id"], {
        assembly: car?.assembly || null,
      }),

      ...addPermittedProperty(inventoryPermittedAttributes?.["inventory.inventory_type.name"], {
        inventoryType: car?.inventory?.inventory_type || null,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["registration_no"], {
        registrationNumber: car?.registration_no || "",
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["car_features.name"], {
        carFeatures: Array.isArray(car?.car_features) ? car?.car_features?.map(e => e.id) : [],
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["auction_sheet_image_path"], {
        auctionSheetImage: car?.auction_sheet_image_path
          ? [
              {
                gallerythumb: car?.auction_sheet_image_path,
                ...uploaded,
              },
            ]
          : [],
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["car_features.name"], {
        carFeatures: Array.isArray(car?.car_features) ? car?.car_features?.map(e => e.id) : [],
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["inventory.location.name"], {
        location: car?.inventory?.location || null,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["inventory.crm_lead.id"], {
        crmLead: crmLead,
      }),
      ...addPermittedProperty(inventoryPermittedAttributes?.["inventory.time_slots.address"], {
        addresses: makeAvailabilityHoursData(car?.inventory?.time_slots) || [],
      }),
    };
    return Object.assign({}, data, ...getDynamicFieldInitialValues(dynamicFields, permittedAttributes, "car"));
  };
  return {
    getStaticSchema: () => ({ ...staticSchema }),
    getInitialValues: () => getInitialValues(permittedAttributes),
  };
};

const defaultHelper = () => ({
  getInitialValues: () => ({}),
  getStaticSchema: () => ({}),
  getStaticInitialValues: () => ({}),
});

/**
 * @param {String} type of form model, which is used to select the desired helper method.
 * @param {Array<TYPE>} dynamicFields
 * @param {Object} extraProps Custom dependencies needed for each formik model.
 * @return {Object} return methods for formik
 */
export const formikPropsHelper = (type, dynamicFields, extraProps) => {
  const HELPERS = {
    [PERMITTED_ATTRIBUTE_MODELS.CRM_LEAD]: crmLeadHelper,
    [PERMITTED_ATTRIBUTE_MODELS.CAR]: crmInventoryHelper,
    [PERMITTED_ATTRIBUTE_MODELS.INSPECTION_LEAD]: inspectionLeadHelper,
    [PERMITTED_ATTRIBUTE_MODELS.TASK]: crmTaskHelper,
    [PERMITTED_ATTRIBUTE_MODELS.CICO]: crmCicoHelper,
  };
  const helperMethod = HELPERS[type](type, dynamicFields, extraProps) || defaultHelper();
  return {
    getInitialValues: helperMethod.getInitialValues,
    getStaticSchema: helperMethod.getStaticSchema,
    getStaticInitialValues: helperMethod.getStaticInitialValues,
  };
};

export const ScrollToFieldError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();
  const dynamicModelsPrefixesFromObj = Object.values(DYNAMIC_MODELS);
  // Formatting a List for DYNAMIC_MODELS to check dynamic value errors
  const dynamicModelsPrefixesList = dynamicModelsPrefixesFromObj.reduce((acc, curr) => {
    acc.push(curr + "_dynamic_");
    return acc;
  }, []);
  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let errorKeys = Object.keys(errors);
      if (errorKeys.length > 0) {
        let firstErrorKey = errorKeys[0]; // this will get the first error in the list, to scroll to
        if (firstErrorKey?.includes("dynamic")) {
          for (const prefix of dynamicModelsPrefixesList) {
            // replacing dynamic keys prefixes from error keys
            if (firstErrorKey?.includes(prefix)) {
              firstErrorKey = firstErrorKey.replace(prefix, "");
            }
          }
        }
        const errorElement = document.getElementById(firstErrorKey);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          errorElement.focus({ preventScroll: true });
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);
  return null;
};
