import cookie from "react-cookies";

export const setCookie = (name, value, options = { path: "/" }) => {
  cookie.save(name, value, options);
};

export const setCookieObject = (name, obj, options = { path: "/" }) => {
  cookie.save(name, JSON.stringify(obj), options);
};

export const removeCookie = (name, options = { path: "/" }) => {
  cookie.remove(name, options);
  cookie.remove(name, { ...options, path: "/admin" });
  cookie.remove(name, { ...options, path: "/admin/inspection" });
};

export const getCookieObject = (name, type) => {
  const val = cookie.load(name);
  return val ? val : type === "array" ? [] : {};
};

export const getCookie = name => {
  const val = cookie.load(name);
  return val || "";
};

export const signOutUser = () => {
  removeCookie("accessToken");
};
