import { FormSelect } from ".";
import React, { useState } from "react";
import { priceList } from "../../constant/lists";
import { strings } from "../../constant/strings";
import { isFieldEditable, isFieldViewable, isFieldRestricted } from "../../helpers/Util";

const PriceRangeInput = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  crmLeadPermittedAttributes,
  isEditable = null,
}) => {
  const [maxEdit, setMaxEdit] = useState(false);
  const formattedPriceList = priceList.filter(item => Number(values?.min_price) <= item?.slug);
  return (
    <div className="form-group row d-flex flex-row align-items-center">
      <label className="col-sm-3 col-form-label text-right">{strings.price}</label>
      <div className="col-sm-9">
        <div className="d-flex flex-row align-items-center">
          {!isFieldRestricted(values.min_price) &&
            isFieldViewable(crmLeadPermittedAttributes?.["preference.min_price"]) && (
              <FormSelect
                options={priceList}
                placeholder={"Minimum"}
                onBlur={handleBlur("min_price")}
                value={values.min_price}
                errorMsg={errors.min_price && touched.min_price && errors.min_price}
                onChange={e => {
                  handleChange("min_price")(e);
                  setMaxEdit(true);
                }}
                setSlugAsValue
                className="m-b-0"
                isEditable={isEditable && isFieldEditable(crmLeadPermittedAttributes?.["preference.min_price"])}
              />
            )}
          <p className="mx-4 m-b-0">{strings.to}</p>
          {!isFieldRestricted(values.min_price) &&
            isFieldViewable(crmLeadPermittedAttributes?.["preference.min_price"]) && (
              <FormSelect
                options={formattedPriceList}
                placeholder={"Maximum"}
                onBlur={handleBlur("max_price")}
                value={values.max_price}
                errorMsg={errors.max_price && touched.max_price && errors.max_price}
                onChange={e => {
                  handleChange("max_price")(e);
                }}
                setSlugAsValue
                className="m-b-0"
                isEditable={
                  maxEdit === true &&
                  isEditable === true &&
                  isFieldEditable(crmLeadPermittedAttributes?.["preference.min_price"])
                }
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default PriceRangeInput;
