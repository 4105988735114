import React, { forwardRef, Fragment, useImperativeHandle, useRef } from "react";
import { RadioButton, Toast } from "components/common";
import Group from "components/common/Group";
import { Formik } from "formik";
import * as yup from "yup";
import { strings } from "constant/strings";
import { getErrorString } from "helpers/Util";
import { markWinnerAsLostApi } from "helpers/ApiHelper";
import FormRow from "components/common/FormRow";

const MarkWinnerAsLostForm = forwardRef(
  ({ dispositionsList = [], offerId = "", setLoading = () => {}, onSuccess = () => {} }, ref) => {
    const formikRef = useRef();

    useImperativeHandle(ref, () => ({
      submitForm() {
        formikRef.current.submitForm();
      },
    }));

    const initialValues = () => {
      return {
        updated_disposition: "",
      };
    };

    const validationSchema = () => {
      return yup.object().shape({
        updated_disposition: yup.string().required(strings.remove_winner_error),
      });
    };

    const onSubmitForm = async (values, actions) => {
      setLoading(true);
      try {
        const payload = {
          offer: {
            disposition_id: values?.updated_disposition ?? "",
            won: false,
          },
        };
        const response = await markWinnerAsLostApi(offerId, payload);
        console.log("Payload:- ", payload);
        if (response && response?.success) {
          console.log("Response:- ", response);
          onSuccess();
          Toast.success(strings.remove_winner_success);
        } else {
          Toast.error(getErrorString(response));
        }
      } catch (error) {
        Toast.error(getErrorString(error));
      } finally {
        setLoading(false);
      }
    };

    return (
      <Formik
        innerRef={formikRef}
        initialValues={initialValues()}
        validationSchema={validationSchema()}
        onSubmit={onSubmitForm}
      >
        {formikProps => {
          const { handleChange, errors, touched } = formikProps;
          return (
            <Fragment>
              <div className="mb-4">{strings.remove_winner_subtitle}</div>
              <FormRow showRequiredAsterisk={true} label={"Select Reason"}>
                <Group template={"1fr"} gap={"12px"}>
                  {dispositionsList?.map(option => (
                    <RadioButton
                      key={option.id}
                      id={option.id}
                      label={option.name}
                      name="updated_disposition"
                      value={option.id}
                      onChange={handleChange}
                      style={{ color: "#1D2939", width: 16, height: 16 }}
                    />
                  ))}
                  {errors.updated_disposition && touched.updated_disposition && errors.updated_disposition && (
                    <span className={"invalid-error"}>{errors.updated_disposition}</span>
                  )}
                </Group>
              </FormRow>
            </Fragment>
          );
        }}
      </Formik>
    );
  }
);

export default MarkWinnerAsLostForm;
