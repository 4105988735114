import React from "react";
import cx from "clsx";
import styles from "./ChevronLeadColumn.module.scss";
import { FiChevronLeft, FilterDescending } from "../../../svg-icon/svg-icons";
import Status from "components/common/Status";
import Button from "components/common/Button/Button";

const ChevronLeadColumnHeader = ({
  dispositionTitle = "",
  totalDispositionsCount = 0,
  setColumnOpen = () => {},
  isLatestTimestamp = true,
  setIsLatestTimestamp = () => {},
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between m-b-5">
      <div className="d-flex align-items-center" style={{ gap: "12px" }}>
        <div className={cx(" f-16 f-w-600 text-truncate")}>{dispositionTitle}</div>
        <Status
          className={cx(styles.totalCount, "f-w-700 f-16")}
          color={"#dc6803"}
          bg_color={"#fef0c7"}
          name={`${totalDispositionsCount}`}
        />
      </div>
      <div className={cx(styles.actionBtn, "d-flex align-items-center")}>
        <Button
          onClick={() => setColumnOpen(columnOpen => !columnOpen)}
          className={cx(styles.arrowBtn, "p-0")}
          icon={<FiChevronLeft size={18} />}
          btnText=""
          color="link"
        />
        <div className={styles.sepratorLine} />
        <Button
          onClick={() => setIsLatestTimestamp(prevIsLatestTimeStamp => !prevIsLatestTimeStamp)}
          className={cx({ [styles.filterBtn]: true, [styles.filterBtnActive]: !isLatestTimestamp })}
          icon={<FilterDescending size={18} />}
          btnText=""
          color="link"
        />
      </div>
    </div>
  );
};

export default ChevronLeadColumnHeader;
