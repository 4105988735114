import cx from "clsx";
import moment from "moment";
import React, { Fragment, useState } from "react";
import LazyLoad from "react-lazyload";
import { connect, useSelector } from "react-redux";
import { Progress } from "reactstrap";
import { updateInventoryStatus } from "../../actions/general/carDetail.action";
import { INVENTORY_ACTIONS } from "constant/appConstants";
import { strings } from "../../constant/strings";
import {
  fetchBuyerConnectsApi,
  fetchLeadsDuplicationApi,
  markInventoryApi,
  markInventoryAsReadyForAuction,
} from "../../helpers/ApiHelper";
import {
  canUpdateInventoryData,
  dropDownListToShow,
  formattingStringWithUnderScores,
  getCarTitle,
  getCurrency,
  getErrorString,
  getPermission,
  getReportScore,
  isFieldRestricted,
  isObjRestricted,
  isFieldViewable,
  makePossibleStatusesSelectData,
  routingCarEdit,
  setTimeZone,
  appendParamsToQueryString,
} from "../../helpers/Util";
import {
  Button,
  Carousel,
  CustomAlert,
  DropdownSelect,
  IF,
  Loader,
  Modal,
  RowAction,
  Status,
  Toast,
  Tooltip,
} from "../common";
import { SelectConnectModal } from "../crm";
import {
  InventoryPriceIcon,
  IoIosWarning,
  IoMdArrowRoundBack,
  IoMdArrowRoundForward,
  UserInventoryIcon,
  ViewInspectionReportIcon,
} from "../svg-icon/svg-icons";
import styles from "./CarDetail.module.scss";
import CarTags from "./CarTags";
import { Link } from "react-router-dom";
import { CRM_CARS_DETAIL_PATH } from "constant/appPaths";
import Flex from "components/common/Flex";
import ExistingCarDetailCard from "../crm/duplicationSearch/ExistingCarDetailCard";
import { selectActiveColumns } from "reducers/general/selectors";
import Group from "components/common/Group";

const INVENTORY = "Inventory";

const CarDetails = ({ car, history, country, updateInventoryStatus, fetchExternalAd }) => {
  const [showModal, setShowModal] = useState(null);
  const [connectsModal, setConnectsModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedDisposition, setSelectedDisposition] = useState(null);
  const [buyerConnects, setBuyerConnects] = useState([]);
  const [carList, setCarList] = useState([]);
  const [isDuplicatesModalOpen, setIsDuplicatesModalOpen] = useState(false);
  const [duplicatesLoading, setDuplicatesLoading] = useState(false);
  const timezone = useSelector(content => content.Configs.tenant.country.timezone);
  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const carPermittedAttributes = activeColumnPermittedAttributes.Car;

  const priceHistory =
    !isObjRestricted(car?.inventory) && !isObjRestricted(car?.inventory?.price_logs)
      ? car?.inventory?.price_logs?.sort((a, b) => b?.id - a?.id ?? 0)
      : [];

  const possibleStatusesOptions = car?.possibleStatuses
    ? makePossibleStatusesSelectData("Inventory", car.possibleStatuses)
    : [];

  const updateInventoryCallback = (response = {}) => {
    const updatedInventoryPayload = {
      status: response.inventory?.status,
      disposition: response.inventory?.disposition,
      possibleStatuses: response.possible_statuses,
      canAddInAuction: response.inventory?.can_add_in_auction,
      canBeEdited: response.inventory?.can_be_edited,
      canBeRemoved: response.inventory?.can_be_removed,
    };
    updateInventoryStatus(updatedInventoryPayload, response.possible_statuses);
  };

  const changeStatusHandler = async () => {
    setLoading(true);
    const Inventory = {
      disposition_id: showModal.dispositionId,
    };
    const response = await showModal?.changeStatusApi(car?.inventory?.id, showModal.action, Inventory);
    if (response) {
      setLoading(false);
      if (response.success) {
        setSelectedDisposition(showModal.disposition);
        setShowModal(null);
        updateInventoryCallback(response);
        fetchExternalAd && fetchExternalAd();
      } else {
        setError(getErrorString(response));
      }
    }
  };

  const handleViewDuplicates = async () => {
    try {
      setDuplicatesLoading(true);
      setIsDuplicatesModalOpen(true);
      let params = appendParamsToQueryString(
        "vin_number",
        car?.dynamic_attributes?.car?.vehicle_identification_number,
        ""
      );
      params = appendParamsToQueryString("inventory_id", car?.inventory?.id, params);
      const response = await fetchLeadsDuplicationApi(params);
      if (response?.success) {
        setCarList(response?.cars);
      } else {
        Toast.error(getErrorString(response));
      }
    } catch (error) {
      Toast.error(getErrorString(error));
    } finally {
      setDuplicatesLoading(false);
    }
  };

  const Reports = () => {
    if (!car?.inventory?.reports) return null;
    const reports = car?.inventory?.reports;
    if (reports.length === 1)
      return (
        <Report
          list={reports?.[0]?.report_sections}
          path={reports?.[0]?.inspection_report_path}
          score={reports?.[0]?.score}
        />
      );
    return (
      <div className="inspection__report-box">
        <div
          className="report_progess-sec"
          style={{
            scrollBehavior: "smooth",
            overFlow: "hidden",
          }}
        >
          <div className={`crm-task-meeting-slider report-slider ${reports?.length > 1 ? "" : ""}`}>
            <Carousel
              disableButtonsControls
              leftIcon={<IoMdArrowRoundBack />}
              rightIcon={<IoMdArrowRoundForward />}
              showButtons={reports?.length > 1}
            >
              {reports?.map(report => (
                <Fragment key={report.id}>
                  <Report list={report.report_sections} path={report?.inspection_report_path} score={report?.score} />
                </Fragment>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    );
  };

  const Report = ({ list, path, score }) => {
    const nonEmptyPercentageList = list.filter(e => !!e.score_in_percentage).slice(0, 6);
    return (
      <>
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <h6 className="mb-0">{strings.inspection_report}</h6>
            {!isFieldRestricted(score) && score && (
              <span className="mx-2 status-primary badge-status car__score-badge">{`${getReportScore(score)}/10`}</span>
            )}
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-end">
            <a
              href="#gallery"
              className="view_link"
              onClick={() => {
                const url = new URL(window.location.href);
                window.open(url.origin + path, "_blank");
              }}
            >
              {strings.view_report}
            </a>
          </div>
        </div>
        {nonEmptyPercentageList.map((e, i) => (
          <div className="inspection-progress-wrap d-flex align-items-end" key={i}>
            <div className="flex-1">
              <span className="inspection_title">{e.name}</span>
              <Progress
                className="sm-progress-bar mt-2"
                color="primary"
                value={e.score_in_percentage.toString()}
                title={e.name}
              />
            </div>
            <div
              className="ml-2 pl-1 f-w-700"
              style={{
                minWidth: "50px",
              }}
            >
              <span className="progess_percentage">{e.score_in_percentage.toString()}%</span>
            </div>
          </div>
        ))}
      </>
    );
  };

  const changeStatusModal = () => {
    return (
      <Modal
        toggle={() => {
          setShowModal(null);
        }}
        title={showModal?.statusTitle}
        open={showModal}
        body={() => (
          <>
            <p>{showModal?.statusDesc}</p>
            {error && <CustomAlert message={error} />}
          </>
        )}
        actions={[
          {
            label: strings.no,
            onClick: () => {
              setSelectedDisposition(null);
              setShowModal(null);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
          {
            color: "primary",
            className: "modal__cr-btn btn-pill",
            label: strings.yes,
            onClick: () => changeStatusHandler(),
            disabled: loading,
            loading: loading,
          },
        ]}
      />
    );
  };

  const getDispositionModalDescription = name =>
    `Are you sure you want to mark as ${formattingStringWithUnderScores(name)}?`;
  const setDispositionModal = disposition => {
    setShowModal({
      statusTitle: strings.change_status,
      statusDesc: getDispositionModalDescription(disposition.label),
      action: disposition.action,
      dispositionId: disposition?.id,
      changeStatusApi: markInventoryApi,
      disposition: disposition,
    });
  };
  const fetchBuyerConnects = async () => {
    const response = await fetchBuyerConnectsApi(car?.id);
    setBuyerConnects(response?.buyer_leads);
  };

  const dispositionClickHandler = disposition => {
    if (!disposition.action) return;
    const action = disposition.action;
    switch (action) {
      case INVENTORY_ACTIONS.MARK_AS_SOLD: {
        setConnectsModal({ show: true, dispositionId: disposition?.id });
        fetchBuyerConnects();
        break;
      }
      default:
        setDispositionModal(disposition);
    }
  };

  const dropDownList = [
    {
      showListItem: canUpdateInventoryData(car),
      title: strings.edit_inventory,
      onPress: () => {
        history.push({
          pathname: routingCarEdit(car),
          state: {
            crmLead: car?.inventory?.crm_lead,
            platform: window.location.href.includes("auction") ? "auction" : "crm",
          },
        });
      },
    },
    {
      showListItem:
        getPermission(INVENTORY, "mark_ready_for_auction") &&
        car?.inventory?.ready_for_auction === false &&
        car?.inventory?.can_add_in_auction === true,
      title: strings.mark_as_ready_for_auction,
      onPress: () =>
        setShowModal({
          statusTitle: strings.ready_for_auction,
          statusDesc: strings.mark_inventory_as_ready_for_auction,
          changeStatusApi: markInventoryAsReadyForAuction,
        }),
    },
  ];
  const showStatus =
    !isObjRestricted(car?.inventory?.status, ["name"]) && !isObjRestricted(car?.inventory?.disposition, ["name"]);

  const getInventoryLocation = car => {
    return (
      !isObjRestricted(car?.inventory?.location) &&
      `${car?.inventory?.location?.address ? car?.inventory?.location?.address : ""} ` +
        (!isObjRestricted(car?.inventory?.location?.area)
          ? `${car?.inventory?.location?.area?.name ? car?.inventory?.location?.area?.name : ""}`
          : " ")
    );
  };

  return (
    <div className="inspection_content-main">
      {changeStatusModal()}

      <div className="">
        <Group template={"auto auto"} gap={"20px"} style={{ alignItems: "start" }}>
          <Flex wrap={false} align="start" gap={"8px"}>
            <h4>{getCarTitle(car, car?.reference_number)}</h4>
            {showStatus && (
              <Status
                {...car?.inventory?.status}
                disposition={car?.inventory?.disposition?.name}
                style={{ flexShrink: 0 }}
              />
            )}
          </Flex>
          <div className="inventory_action d-flex align-items-center justify-content-end">
            {isFieldViewable(carPermittedAttributes?.["inventory.risks"]) && car?.inventory?.risks?.length > 0 && (
              <>
                <Status
                  data-tip
                  data-for="risks"
                  bg_color="#BB2025"
                  color="#fff"
                  name={strings.risks}
                  icon={<IoIosWarning color="currentColor" />}
                  className="f-w-600 f-16"
                />

                <Tooltip id={"risks"} place="down" type="dark" effect="solid" padding="10px">
                  <>
                    {car?.inventory?.risks?.map((risk, index) => (
                      <Group template={"repeat(2, 1fr)"} key={index} gap="48px">
                        <div>{risk.title}</div>
                        <ul className="f-w-500 text-align-left" style={{ listStyle: "disc" }}>
                          {risk.issues.map((issue, i) => (
                            <>
                              <li key={i}>{issue}</li>
                            </>
                          ))}
                        </ul>
                      </Group>
                    ))}
                  </>
                </Tooltip>
              </>
            )}
            {!isFieldRestricted(car?.possibleStatuses) &&
              car?.possibleStatuses?.length > 0 &&
              possibleStatusesOptions?.length > 0 && (
                <DropdownSelect
                  label={strings.update_status}
                  options={possibleStatusesOptions}
                  selected={selectedDisposition}
                  selectOnConfirmationModal
                  onChange={dispositionClickHandler}
                  className="ml-2 userActionDropdown"
                  menuStyles="action_dropDown_filter"
                  color="monochrome"
                  alignment={true}
                />
              )}
            <RowAction iconColor="#A29E9E" dropDownList={dropDownListToShow(dropDownList)} className="ml-2" />
          </div>
        </Group>
        <div className={"d-flex m-2 align-items-center"}>
          <p className={cx("mb-0", styles.inventoryDescriptionData)}>{car.inventory.inventory_type.name}</p>
          {car?.inventory?.location && !isObjRestricted(car?.inventory) && <div className={"separator-dot"} />}
          <p className={cx("mb-0", styles.inventoryDescriptionData)}>
            {!isObjRestricted(car?.inventory) && getInventoryLocation(car)}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          {!isObjRestricted(car?.tags) && (
            <div className="mb-3 mt-4">
              <CarTags car={car} tags={car?.tags?.reverse()} carId={car?.inventory?.id} />
            </div>
          )}
          <div className={cx("demand_price text-right mt-3", styles.carDetailCard)}>
            {priceHistory?.length > 0 && (
              <div className={cx("onhover-dropdown", styles.carDetailData)}>
                <div className={cx("media align-items-center mt-1", styles.InventoryPriceIcon)}>
                  <InventoryPriceIcon />
                </div>
                <div
                  className={cx(
                    "profile-dropdown onhover-show-div p-40 profile-dropdown-hover scrollbar-hidden ",
                    styles.carDetailDataItem
                  )}
                  style={dropDownStyle}
                >
                  <p className={styles.carDetailDataItemHeading}>{strings.listing_price_history}</p>
                  {priceHistory?.map((item, index) => {
                    return <HistoryOfPrice key={index} priceLog={item} timeZone={timezone} country={country} />;
                  })}
                </div>
              </div>
            )}
            <div>
              {!isFieldRestricted(car?.inventory?.ask_price) && (
                <div>
                  <h4>{getCurrency(country, car?.inventory?.ask_price)}</h4>
                  <p>{strings.listing_price}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <IF condition={car?.duplicate_count && !isFieldRestricted(car?.duplicate_count) && car.duplicate_count > 0}>
          <Fragment>
            <Flex align="center" gap="4px" className={styles.totalInventory}>
              <span>This inventory has {car?.duplicate_count} duplicate(s). </span>
              <Button
                color="link"
                icon={<ViewInspectionReportIcon />}
                iconPlacement="right"
                btnText="View here"
                onClick={handleViewDuplicates}
                style={{ fontSize: "14px" }}
                className={cx("p-0", styles.btnLink)}
                disabled={duplicatesLoading}
              />
            </Flex>

            {isDuplicatesModalOpen && (
              <Modal
                title={"Duplicate Inventory Details"}
                open={isDuplicatesModalOpen}
                // size="lg"
                className={styles.modalFrame}
                toggle={() => setIsDuplicatesModalOpen(!isDuplicatesModalOpen)}
                body={() => {
                  return duplicatesLoading ? (
                    <Loader />
                  ) : (
                    <div>
                      {carList?.map(car => (
                        <div key={car.id} className="mb-3">
                          <Link to={CRM_CARS_DETAIL_PATH.replace(":id", car.id)}>
                            <ExistingCarDetailCard
                              car={car}
                              carPermittedAttributes={carPermittedAttributes}
                              tooltipPlacement="left"
                            />
                          </Link>
                        </div>
                      ))}
                    </div>
                  );
                }}
              />
            )}
          </Fragment>
        </IF>
      </div>
      <LazyLoad height={100} offset={100}>
        <SelectConnectModal
          dispositionId={connectsModal?.dispositionId}
          visible={connectsModal?.show}
          setVisible={setConnectsModal}
          dataFromProps={buyerConnects}
          fetchConnects={fetchBuyerConnects}
          inventoryId={car?.inventory?.id}
          updateInventoryCallback={updateInventoryCallback}
        />
      </LazyLoad>
    </div>
  );
};

const HistoryOfPrice = props => {
  const { priceLog = {}, timeZone, country } = props;
  const renderTime = (time, timeZone) => {
    const displayTime = setTimeZone(timeZone, time);
    return moment(displayTime).format("D MMM, YYYY - hh:mm A");
  };
  return (
    <div className={"priceHistoryItem mb-3"}>
      {!isFieldRestricted(priceLog?.value) && (
        <p className={"priceHistorydata"}>{getCurrency(country, priceLog?.value) ?? ""}</p>
      )}
      {!isFieldRestricted(priceLog?.created_at) && <p>{renderTime(priceLog?.created_at, timeZone) ?? "-"}</p>}
      {!isFieldRestricted(priceLog?.user?.name) && <UserDetail userName={priceLog?.user?.name ?? "-"} />}
    </div>
  );
};

const dropDownStyle = {
  margin: "10px",
  padding: "10px 20px",
  width: "218px",
  maxHeight: "280px",
  overflowY: "auto",
  textAlign: "justify",
};

const UserDetail = ({ userName }) => {
  return (
    <div className="d-flex align-items-baseline" style={{ gap: 3 }}>
      <small style={{ display: "block" }}>
        <UserInventoryIcon />
      </small>
      <div style={{ color: "#919191", fontSize: "0.8rem" }}>{userName}</div>
    </div>
  );
};

const mapStateToProps = ({ Configs, CarDetailData }) => {
  return {
    country: Configs.tenant.country,
  };
};

export default connect(mapStateToProps, {
  updateInventoryStatus: updateInventoryStatus,
})(CarDetails);
