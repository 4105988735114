export const AUCTION_USERS = {
  DRE: "dre",
  PURCHASER: "purchaser",
};

export const AUCTION_NEGOTIATION_OFFER_STATUSES = {
  BUYING: "Buying",
  SELLING: "Selling",
};

export const ROLES_SLUGS = {
  AUCTION_DRE: "auction-dre",
  PURCHASING: "purchasing",
};
