import { FETCH_DEPOSITS, UPDATE_DEPOSIT, SET_DEPOSITS_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  statuses: [],
  paymentLinkStatuses: [],
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DEPOSITS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_DEPOSIT:
      const depositList = state.list;
      let index = depositList.findIndex(e => e.id === action.payload.item.id);
      depositList.splice(index, 1, { ...action.payload.item });
      return {
        ...state,
        list: [...depositList],
      };

    case SET_DEPOSITS_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };

    default:
      return state;
  }
};
