import { FormInput, IF, PriceRangeInput, RadioButton, SelectionListTypeHead } from "../../common";
import React, { useEffect } from "react";
import { inspectionScoreList } from "../../../constant/lists";

import { CarInfoSelect } from ".";
import { SelectListModal } from "../../crm";
import { connect } from "react-redux";
import { fetchCarFeaturesLists } from "../../../actions/general/carFeaturesLists.action";
import { fetchCities } from "../../../actions/general/cities.action";
import { strings } from "../../../constant/strings";
import { isFieldEditable, isFieldViewable, addPermittedProperty } from "../../../helpers/Util";

const BuyerPreferences = props => {
  const {
    fetchCarFeaturesLists,
    fetchCities,
    cities,
    citiesLoading,
    citiesError,
    carFeatures,
    carFeaturesLoading,
    carFeaturesError,
    inventory,
    crmLeadPermittedAttributes = null,
    ...rest
  } = props;
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setValues } = rest;

  useEffect(() => {
    !inventory && fetchCarFeaturesLists();
  }, []);

  const setCarFromInventoryNull = () => {
    setFieldValue("inventory", null);
  };

  const setCarPreferencesNull = () => {
    if (isFieldViewable(crmLeadPermittedAttributes?.["preference.car_preferences.name"])) return;
    setValues({
      ...values,
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.min_price"], { min_price: null }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.max_price"], { max_price: null }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.mileage"], { mileage: null }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.engine_capacity"], { engine_capacity: null }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.assembly_id"], { assembly: null }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.inspection_score"], { inspection_score: null }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.body_types.name"], { body_type: null }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.colors.name"], { exterior_color: null }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.registration_cities.name"], {
        registration_city: null,
      }),
      ...addPermittedProperty(crmLeadPermittedAttributes?.["preference.car_preferences.name"], {
        car_preferences_attributes: null,
      }),
    });
  };

  return (
    <>
      <div className="border-bottom my-4">
        <h6>{strings.buyer_preference}</h6>
      </div>

      <IF condition={values?.lead_category?.slug === "buyer"}>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label text-right">{strings.car_information}</label>
          <div className="col-sm-9 d-flex flex-row align-items-center mb-0 p-20 pt-0 pb-0">
            {isFieldViewable(crmLeadPermittedAttributes?.["preference.car_preferences.name"]) && (
              <RadioButton
                label={strings.make_model_variant}
                onChange={e => {
                  if (e.target.checked) {
                    setCarFromInventoryNull();
                  }
                  handleChange("buyer_preference")(e);
                }}
                name="buyer_preference"
                value="make-modal"
                id="radio-button-make-model"
                checked={values?.buyer_preference === "make-modal"}
                isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preference.car_preferences.name"])}
              />
            )}
            {isFieldViewable(crmLeadPermittedAttributes?.["preferred_inventory.title"]) && (
              <RadioButton
                label={strings.car_from_inventory}
                onChange={e => {
                  if (e.target.checked) {
                    setCarPreferencesNull();
                  }
                  handleChange("buyer_preference")(e);
                }}
                name="buyer_preference"
                value="inventory"
                id="radio-button-inventory"
                checked={values?.buyer_preference === "inventory"}
                isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preferred_inventory.title"])}
              />
            )}
          </div>
        </div>

        {isFieldViewable(crmLeadPermittedAttributes?.["preferred_inventory.title"]) && (
          <IF condition={values?.buyer_preference === "inventory"}>
            <SelectListModal
              title={strings.select_inventory}
              taskFrom={"lead"}
              currentValue={values?.inventory}
              onSelect={item => {
                setFieldValue("inventory", item.inventory);
              }}
              errorMsg={errors.inventory}
              onDeselect={() => {
                setFieldValue("inventory", null);
              }}
              textRight
              isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preferred_inventory.title"])}
              filterRemovedAndSold
            />
          </IF>
        )}
      </IF>

      <IF condition={values?.buyer_preference === "make-modal"}>
        {isFieldViewable(crmLeadPermittedAttributes?.["preference.car_preferences.name"]) && (
          <CarInfoSelect
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preference.car_preferences.name"])}
            {...rest}
          />
        )}

        {isFieldViewable(crmLeadPermittedAttributes?.["preference.car_preferences.name"]) && (
          <PriceRangeInput
            crmLeadPermittedAttributes={crmLeadPermittedAttributes}
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preference.car_preferences.name"])}
            {...rest}
          />
        )}

        {isFieldViewable(crmLeadPermittedAttributes?.["preference.registration_cities.name"]) && (
          <SelectionListTypeHead
            label={strings.registration_city}
            placeholder={strings.city_ph}
            horizontal
            textRight
            options={cities}
            isLoading={citiesLoading}
            isError={citiesError}
            fetchList={fetchCities}
            selected={values.registration_city ? [...values.registration_city] : []}
            onBlur={handleBlur("registration_city")}
            errorMsg={errors.registration_city && touched.registration_city && errors.registration_city}
            onChange={selected => {
              setFieldValue("registration_city", selected);
            }}
            labelKey="name"
            multiple
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preference.registration_cities.name"])}
            // formSelectRef={refObject["registration_city"]}
          />
        )}

        {isFieldViewable(crmLeadPermittedAttributes?.["preference.mileage"]) && (
          <FormInput
            label={strings.mileage}
            placeholder={strings.mileage_ph}
            horizontal
            value={values.mileage || ""}
            onBlur={handleBlur("mileage")}
            errorMsg={errors.mileage && touched.mileage && errors.mileage}
            onChange={handleChange("mileage")}
            textRight
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preference.mileage"])}
            // formRef={refObject["mileage"]}
          />
        )}
        {isFieldViewable(crmLeadPermittedAttributes?.["preference.colors.name"]) && (
          <SelectionListTypeHead
            label={strings.exterior_color}
            placeholder={strings.color_ph}
            horizontal
            textRight
            options={carFeatures.color}
            isLoading={carFeaturesLoading}
            isError={carFeaturesError}
            selected={values.exterior_color ? [...values.exterior_color] : []}
            onBlur={handleBlur("exterior_color")}
            errorMsg={errors.exterior_color && touched.exterior_color && errors.exterior_color}
            onChange={selected => {
              setFieldValue("exterior_color", selected);
            }}
            labelKey="select_value"
            multiple
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preference.colors.name"])}
            // formSelectRef={refObject["exterior_color"]}
          />
        )}

        {isFieldViewable(crmLeadPermittedAttributes?.["preference.engine_capacity"]) && (
          <FormInput
            label={strings.engine_capacity}
            placeholder={strings.engine_capacity_ph}
            horizontal
            value={values.engine_capacity || ""}
            onBlur={handleBlur("engine_capacity")}
            errorMsg={errors.engine_capacity && touched.engine_capacity && errors.engine_capacity}
            onChange={handleChange("engine_capacity")}
            textRight
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preference.engine_capacity"])}
            // formRef={refObject["engine_capacity"]}
          />
        )}
        {isFieldViewable(crmLeadPermittedAttributes?.["preference.body_types.name"]) && (
          <SelectionListTypeHead
            label={strings.body_type}
            placeholder={strings.body_type_ph}
            horizontal
            textRight
            options={carFeatures.bodyType}
            isLoading={carFeaturesLoading}
            isError={carFeaturesError}
            selected={values.body_type ? [...values.body_type] : []}
            onBlur={handleBlur("body_type")}
            errorMsg={errors.body_type && touched.body_type && errors.body_type}
            onChange={selected => {
              setFieldValue("body_type", selected);
            }}
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preference.body_types.name"])}
            labelKey="select_value"
            multiple
            // formSelectRef={refObject["body_type"]}
          />
        )}
        {isFieldViewable(crmLeadPermittedAttributes?.["preference.assembly_id"]) && (
          <SelectionListTypeHead
            label={strings.assembly}
            placeholder={strings.assembly_ph}
            horizontal
            textRight
            options={props.leadData.assemblies}
            selected={values.assembly ? [values.assembly] : []}
            onBlur={handleBlur("assembly")}
            errorMsg={errors.assembly && touched.assembly && errors.assembly}
            onChange={selected => {
              setFieldValue("assembly", selected[0]);
            }}
            labelKey="name"
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preference.assembly_id"])}
            // formSelectRef={refObject["assembly"]}
          />
        )}
        {isFieldViewable(crmLeadPermittedAttributes?.["preference.inspection_score"]) && (
          <SelectionListTypeHead
            label={strings.inspection}
            placeholder={strings.score}
            horizontal
            textRight
            options={inspectionScoreList}
            selected={values.inspection_score ? [...values.inspection_score] : []}
            onBlur={handleBlur("inspection_score")}
            errorMsg={errors.inspection_score && touched.inspection_score && errors.inspection_score}
            onChange={selected => {
              setFieldValue("inspection_score", selected);
            }}
            labelKey="label"
            multiple
            isEditable={isFieldEditable(crmLeadPermittedAttributes?.["preference.inspection_score"])}
            // formSelectRef={refObject["assembly"]}
          />
        )}
      </IF>
    </>
  );
};

export default connect(
  ({ CitiesData, CarFeaturesLists }) => ({
    cities: CitiesData.list,
    citiesLoading: CitiesData.loading,
    citiesError: CitiesData.error,
    carFeatures: CarFeaturesLists.lists,
    carFeaturesLoading: CarFeaturesLists.loading,
    carFeaturesError: CarFeaturesLists.error,
  }),
  {
    fetchCities,
    fetchCarFeaturesLists,
  }
)(BuyerPreferences);
