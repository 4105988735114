import * as firebase from "firebase/app";
import { getDatabase } from "firebase/database";
import { getRemoteConfig, getValue as getRemoteConfigValue, fetchAndActivate } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
const app = !firebase.getApps().length ? firebase.initializeApp(firebaseConfig) : firebase.getApp();
const database = getDatabase(app);
const initializeApp = firebase.initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings = {
  minimumFetchIntervalMillis: 100,
};

remoteConfig.defaultConfig = {
  login_with_email: false,
};

export default initializeApp;
export { database, firebaseConfig, app, remoteConfig, fetchAndActivate, getRemoteConfigValue };
