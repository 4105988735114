import React from "react";
import { RiFolderDownloadFill } from "../svg-icon/svg-icons";

const FileTilePreview = props => {
  const { item } = props;
  return (
    <div className="hover-pointer img-upload-wrapper d-flex flex-row align-items-center justify-content-between spbwx8 hover-bg">
      <div className="d-flex flex-row align-items-center w-100">
        <div size={30} className="download-icon">
          <RiFolderDownloadFill />
        </div>

        <div
          onClick={() => {
            window.open(item.url, "_blank");
          }}
          className="file-label"
        >
          {item?.file_name}
        </div>
      </div>
    </div>
  );
};

export default FileTilePreview;
