import React from "react";
import { Children } from "react";

const Close = ({ icon = "", isTransparent = "", btnWidth = "", btnHeight = "", className = "", ...props }) => {
  return (
    <>
      <button
        className={`btn-close ${className} ${isTransparent ? "btn-transparent" : ""} ${
          props.btnRightTop ? "btnCloseRT" : ""
        }`.trim()}
        style={{ width: btnWidth, height: btnHeight }}
        {...props}
      >
        {icon ? (
          icon
        ) : (
          <>
            <svg viewBox="-8 -8 16 16" aria-hidden="true">
              <path d="M3 3-3-3M3-3-3 3" strokeLinecap="round" stroke="currentcolor" />
            </svg>
            <span className="sr-only">"close"</span>
          </>
        )}
      </button>
    </>
  );
};

export default Close;
