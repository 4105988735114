// import { IoMdArrowDropdown } from "react-icons/io";
import React from "react";
import Select from "react-select";
import cx from "clsx";
import { IoMdArrowDropdown, MdInfoOutline } from "../svg-icon/svg-icons";
import Tooltip from "./Tooltip";

const SelectComponent = props => {
  const {
    containerClassName,
    label,
    horizontal,
    className,
    columnsLeftSection = 3,
    columnsRightSection = 6,
    textRight = true,
    isViewable = true,
    inputRef,
    formSelectRef,
    getOptionLabel = op => op.name || op.label,
    getOptionValue = op => op.id || op.key || op.value || op.slug,
    portal = false,
    isEditable = true,
    showRequiredAsterisk,
    labelClassName = "",
    id = "",
    hideDropDownIndicator = false,
    transparentBorder = false,
    removeFormGroup = false,
    showTooltip = false,
    tooltipMessage = "",
    labelContainerClasses = "",
  } = props;

  if (!isViewable) return null;

  const customStyles = {
    indicatorSeparator: provided => ({
      ...provided,
      display: "none",
    }),
    control: (provided, { isFocused, isMenuOpen }) => ({
      ...provided,
      boxShadow: "none",
      borderColor: transparentBorder ? "transparent" : isFocused ? "" : "#efefef",
      minHeight: 42,
      "&:hover": {
        borderColor: isMenuOpen ? "" : "",
      },
    }),
    indicatorsContainer: provided => ({
      ...provided,
      width: "2.125rem",
      justifyContent: "center",
      fontSize: "1.5rem",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "2px 5px",
    }),
    menu: provided => ({
      ...provided,
      width: "100%",
      zIndex: 2,
    }),
    // option: (provided, { isSelected, isFocused }) => {
    //   const color = chroma(primaryColor);
    //   return {
    //     ...provided,
    //     backgroundColor: isSelected ? primaryColor : isFocused ? color.alpha(0.1).css() : undefined,
    //   };
    // },
  };

  const DropdownIndicator = () => {
    return props.isDisabled || hideDropDownIndicator ? null : <IoMdArrowDropdown />;
  };

  return (
    <div
      className={cx(`${removeFormGroup ? "" : "form-group"}`, containerClassName, horizontal ? "row form-group-y" : "")}
      ref={formSelectRef}
      id={id}
    >
      {label && (
        <label
          className={
            labelClassName
              ? labelClassName
              : horizontal
              ? cx("col-form-label d-flex", `col-sm-${columnsLeftSection}`, textRight ? "justify-content-end" : "")
              : ""
          }
        >
          {label} {showRequiredAsterisk && <sup className={"invalid-error mb-0"}>*</sup>}
          {showTooltip && (
            <>
              <MdInfoOutline
                size={"1.25rem"}
                className="text-primary"
                data-tip
                data-for={`lead-allocation-tooltip-${label}`}
                style={{ position: "relative", top: 4 }}
              />
              <Tooltip
                className="pointer-events-unset"
                id={`lead-allocation-tooltip-${label}`}
                backgroundColor="white"
                place="right"
                textColor="black"
                isCapture
                // eventOff="scroll blur"
              >
                <div
                  className="taskTooltipWrap p-1 m-0"
                  style={{ width: "250px", fontWeight: "normal", fontSize: "11px", lineHeight: "170%" }}
                >
                  <div>{tooltipMessage}</div>
                </div>
              </Tooltip>
            </>
          )}
        </label>
      )}

      <div className={cx(horizontal ? `col-sm-${columnsRightSection}` : "")}>
        <Select
          ref={inputRef}
          className={className}
          classNamePrefix="select"
          styles={customStyles}
          components={{ DropdownIndicator }}
          menuPlacement="auto"
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          menuPortalTarget={portal ? document.body : undefined}
          isDisabled={isEditable === false}
          {...props}
        />
        {!!props.errorMsg && props.errorType !== "icon" && (
          <span className={"invalid-error " + props.errorClassName}>{props.errorMsg}</span>
        )}
      </div>
    </div>
  );
};

export default SelectComponent;
