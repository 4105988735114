export const BASE_PATH = "/admin";
export const RESET_PASSWORD_PATH = "/reset-password";
export const LOGIN_PATH = "/login";
export const IMPERSONATED_LOGIN_PATH = "/impersonated-login";
export const PRIVACY_POLICY_PATH = "/privacy-policy";
export const TERMS_PATH = "/terms";

// Apps
export const INSPECTION_PATH = "/inspection";
export const AUCTION_PATH = "/auction";
export const CRM_PATH = "/crm";

// Inspections

export const INS_TENANT_PATH = `${INSPECTION_PATH}/tenant`;
export const INS_DASHBOARD_PATH = `${INSPECTION_PATH}/dashboard`;
export const INS_LEADS_PATH = `${INSPECTION_PATH}/leads`;
export const INS_LEADS_DETAIL_PATH = `${INSPECTION_PATH}/leads/:id`;
export const INS_SCHEDULES_PATH = `${INSPECTION_PATH}/schedules`;
export const INS_INSPECTIONS_PATH = `${INSPECTION_PATH}/inspections`;
export const INS_USERS_PATH = `${INSPECTION_PATH}/users`;
export const INS_TEAMS_PATH = `${INSPECTION_PATH}/teams`;
export const INS_PAYMENTS_PATH = `${INSPECTION_PATH}/payments`;
export const INS_LEAD_TYPES_PATH = `${INSPECTION_PATH}/lead_types`;
export const INS_EDIT_LEAD_TYPE_PATH = `${INSPECTION_PATH}/lead_types/:id/edit`;
export const INS_NEW_LEAD_TYPE_PATH = `${INSPECTION_PATH}/lead_types/new`;
export const INS_DEPOSITS_PATH = `${INSPECTION_PATH}/deposits`;
export const INS_ROLES_PATH = `${INSPECTION_PATH}/roles`;
export const INS_NEW_ROLE_PATH = `${INSPECTION_PATH}/roles/new`;
export const INS_EDIT_ROLE_PATH = `${INSPECTION_PATH}/roles/:id/edit`;
export const INS_AUTHENTICATION_TOKEN_PATH = `${INSPECTION_PATH}/authentication_tokens`;
export const INS_NEW_AUTHENTICATION_TOKEN_PATH = `${INSPECTION_PATH}/authentication_tokens/new`;
export const INS_EDIT_AUTHENTICATION_TOKEN_PATH = `${INSPECTION_PATH}/authentication_tokens/:id/edit`;
export const INS_SMS_TEMPLATES_PATH = `${INSPECTION_PATH}/sms_templates`;
export const INS_EDIT_SMS_TEMPLATES_PATH = `${INSPECTION_PATH}/sms_templates/:id/edit`;
export const INS_USER_ASSIGNMENT_PATH = `${INSPECTION_PATH}/user_assignment/:id/edit`;
export const INS_LEAD_SOURCE_PATH = `${INSPECTION_PATH}/lead_source`;
export const INS_NEW_LEAD_SOURCE_PATH = `${INSPECTION_PATH}/lead_source/new`;
export const INS_EDIT_LEAD_SOURCE_PATH = `${INSPECTION_PATH}/lead_source/:id/edit`;
export const INS_INSPECTION_PROGRAM_PATH = `${INSPECTION_PATH}/inspection_program`;
export const INS_NEW_INSPECTION_PROGRAM_PATH = `${INSPECTION_PATH}/inspection_program/new`;
export const INS_EDIT_INSPECTION_PROGRAM_PATH = `${INSPECTION_PATH}/inspection_program/:id/edit`;
export const INS_EMAIL_TEMPLATES_PATH = `${INSPECTION_PATH}/email_templates`;
export const INS_EDIT_EMAIL_TEMPLATES_PATH = `${INSPECTION_PATH}/email_templates/:id/edit`;
export const INS_DYNAMIC_MODELS_PATH = `${INSPECTION_PATH}/dynamic_models`;
export const INS_DYNAMIC_FIELDS_PATH = `${INSPECTION_PATH}/dynamic_models/:id/dynamic_fields`;
export const INS_LEAD_NEW_PATH = `${INSPECTION_PATH}/lead/new`;
export const INS_LEAD_EDIT_PATH = `${INSPECTION_PATH}/lead/:id/edit`;
export const INS_EDIT_USER_SETTINGS_PATH = `${INSPECTION_PATH}/:id/users`;
export const INS_APPLY_LEAVE_NEW_PATH = `${INSPECTION_PATH}/leaves/new`;
export const INS_APPLY_LEAVE_EDIT_PATH = `${INSPECTION_PATH}/leaves/:id/edit`;
export const INS_CALLS_PATH = `${INSPECTION_PATH}/calls`;
export const INS_CALL_VIEW_EDIT_QC_PATH = `${INSPECTION_PATH}/call/:id/qc`;
export const INS_CALL_QC_PATH = `${INSPECTION_PATH}/call/:id/qc/:templateId`;
export const RECEIPT_PATH = `/inspection_invoices/:id`;

// Auction

export const AUC_TENANT_PATH = `${AUCTION_PATH}/tenant`;
export const AUC_DASHBOARD_PATH = `${AUCTION_PATH}/dashboard`;
export const AUC_ROLES_PATH = `${AUCTION_PATH}/roles`;
export const AUC_NEW_ROLE_PATH = `${AUCTION_PATH}/roles/new`;
export const AUC_EDIT_ROLE_PATH = `${AUCTION_PATH}/roles/:id/edit`;
export const AUC_CARS_PATH = `${AUCTION_PATH}/cars`;
export const AUC_CARS_DETAIL_PATH = `${AUCTION_PATH}/cars/:id`;
export const AUC_CARS_EDIT_PATH = `${AUCTION_PATH}/cars/:id/edit`;
export const AUC_CARS_EDIT_SOLD_CAR_PATH = `${AUCTION_PATH}/cars/:id/update_sold`;

export const AUC_USERS_PATH = `${AUCTION_PATH}/users`;
export const AUC_USER_ASSIGNMENT_PATH = `${AUCTION_PATH}/user_assignment/:id/edit`;
export const AUC_GROUPS_PATH = `${AUCTION_PATH}/groups`;
export const AUC_AUCTION_TYPES_PATH = `${AUCTION_PATH}/auction_types`;
export const AUC_AUCTIONS_PATH = `${AUCTION_PATH}/auctions`;
export const AUC_OFFERS_PATH = `${AUCTION_PATH}/offers`;
export const AUC_AUCTION_USERS_PATH = `${AUCTION_PATH}/auction-users`;
export const AUC_AUCTION_BOOKINGS = `${AUCTION_PATH}/auction-bookings`;
export const AUC_AUTHENTICATION_TOKEN_PATH = `${AUCTION_PATH}/authentication_tokens`;
export const AUC_NEW_AUTHENTICATION_TOKEN_PATH = `${AUCTION_PATH}/authentication_tokens/new`;
export const AUC_EDIT_AUTHENTICATION_TOKEN_PATH = `${AUCTION_PATH}/authentication_tokens/:id/edit`;
export const AUC_DYNAMIC_MODELS_PATH = `${AUCTION_PATH}/dynamic_models`;
export const AUC_DYNAMIC_FIELDS_PATH = `${AUCTION_PATH}/dynamic_models/:id/dynamic_fields`;
export const AUC_LEAD_SOURCE_PATH = `${AUCTION_PATH}/lead_source`;
export const AUC_NEW_LEAD_SOURCE_PATH = `${AUCTION_PATH}/lead_source/new`;
export const AUC_EDIT_LEAD_SOURCE_PATH = `${AUCTION_PATH}/lead_source/:id/edit`;
export const AUC_EDIT_USER_SETTINGS_PATH = `${AUCTION_PATH}/:id/users`;
export const AUC_AUCTION_USER_DETAIL_PATH = `${AUCTION_PATH}/auction-users/:id/detail`;
export const AUCTION_BOOKING_EXPENSES = `${AUCTION_PATH}/booking_expenses`;
export const AUCTION_BOOKING_EXPENSES_DETAIL = `${AUCTION_PATH}/booking_expenses/:id`;
export const AUCTION_APPLY_LEAVE_NEW_PATH = `${AUCTION_PATH}/leaves/new`;
export const AUCTION_APPLY_LEAVE_EDIT_PATH = `${AUCTION_PATH}/leaves/:id/edit`;
export const AUCTION_MARGIN_SLABS = `${AUCTION_PATH}/margin_slabs`;

// CRM

export const CRM_DASHBOARD_PATH = `${CRM_PATH}/dashboard`;
export const CRM_TENANT_PATH = `${CRM_PATH}/tenant`;
export const CRM_LEADS_PATH = `${CRM_PATH}/crm_leads`;
export const CRM_EDIT_USER_SETTINGS_PATH = `${CRM_PATH}/:id/users`;
export const CRM_LEADS_SELLER_PATH = `${CRM_PATH}/crm_leads?f[category.slug][]=seller`;
export const CRM_LEADS_BUYER_PATH = `${CRM_PATH}/crm_leads?f[category.slug][]=buyer`;
export const CRM_LEADS_VAS_PATH = `${CRM_PATH}/crm_leads?f[category.slug][]=vas`;
export const CRM_LEADS_CATEGORY_PATH = `${CRM_PATH}/crm_leads?f[category.slug][]=:categorySlug`;
export const CRM_LEAD_NEW_PATH = `${CRM_PATH}/crm_leads/new`;
export const CRM_LEAD_EDIT_PATH = `${CRM_PATH}/crm_leads/:id/edit`;
export const CRM_LEAD_DETAIL_PATH = `${CRM_PATH}/crm_leads/:id/detail`;
export const CRM_TASKS_PATH = `${CRM_PATH}/tasks/`;
export const CRM_TASKS_PATH_DETAIL = `${CRM_PATH}/tasks/:id`;
export const CRM_CARS_PATH = `${CRM_PATH}/cars`;
export const CRM_CARS_STOCK_PATH = `${CRM_PATH}/cars/stock`;
export const CRM_CARS_DETAIL_PATH = `${CRM_PATH}/cars/:id/detail`;
export const CRM_CARS_AD_POST = `${CRM_PATH}/cars/ad_post/:carId/new`;
export const CRM_CARS_ADD_QUOTATION = `${CRM_PATH}/cars/quotation`;
export const CRM_CARS_AD_POST_EDIT = `${CRM_PATH}/cars/ad_post/:carId/external_ad/:externalAdId/edit`;
export const CRM_CARS_AD_POST_DETAIL = `${CRM_PATH}/cars/external_ad/:carId`;
export const CRM_CARS_EDIT_PATH = `${CRM_PATH}/cars/:id/edit`;
export const CRM_CARS_EDIT_SOLD_CAR_PATH = `${CRM_PATH}/cars/:id/update_sold`;
export const CRM_CARS_NEW_PATH = `${CRM_PATH}/cars/new`;
export const CRM_CLIENTS_PATH = `${CRM_PATH}/clients`;
export const EVENT_CALENDAR_PATH = `${CRM_PATH}/calender`;
export const CRM_USERS_PATH = `${CRM_PATH}/users`;
export const CRM_AUTOMATE_BRANCH_CAPACITY_PATH = `${CRM_PATH}/automate_branch_capacity`;
export const CRM_CICO_PATH = `${CRM_PATH}/cico`;
export const CRM_CALLS_PATH = `${CRM_PATH}/calls`;
export const CRM_CICO_NEW_PATH = `${CRM_PATH}/cico/new`;
export const CRM_CICO_DETAIL_PATH = `${CRM_PATH}/cico/:id/detail`;
export const CRM_CICO_EDIT_PATH = `${CRM_PATH}/cico/:id/edit`;
export const CRM_STOCK_COUNT_PATH = `${CRM_PATH}/stock_count`;
export const CRM_STOCK_COUNT_DETAIL_PATH = `${CRM_PATH}/stock_count/:id/detail`;
export const CRM_REMINDER_PATH = `${CRM_PATH}/reminder`;
export const CRM_ROTA_PLAN_PATH = `${CRM_PATH}/rota_plan`;

export const CRM_USER_ASSIGNMENT_PATH = `${CRM_PATH}/user_assignment/:id/edit`;
export const CRM_PERMISSIONS_PATH = `${CRM_PATH}/permissions`;
export const CRM_ROLES_PATH = `${CRM_PATH}/roles`;
export const CRM_NEW_ROLE_PATH = `${CRM_PATH}/roles/new`;
export const CRM_EDIT_ROLE_PATH = `${CRM_PATH}/roles/:id/edit`;
export const CRM_AUTHENTICATION_TOKEN_PATH = `${CRM_PATH}/authentication_tokens`;
export const CRM_NEW_AUTHENTICATION_TOKEN_PATH = `${CRM_PATH}/authentication_tokens/new`;
export const CRM_EDIT_AUTHENTICATION_TOKEN_PATH = `${CRM_PATH}/authentication_tokens/:id/edit`;
export const CRM_DYNAMIC_MODELS_PATH = `${CRM_PATH}/dynamic_models`;
export const CRM_DYNAMIC_FIELDS_PATH = `${CRM_PATH}/dynamic_models/:id/dynamic_fields`;
export const CRM_LEAD_SOURCE_PATH = `${CRM_PATH}/lead_source`;
export const CRM_NEW_LEAD_SOURCE_PATH = `${CRM_PATH}/lead_source/new`;
export const CRM_EDIT_LEAD_SOURCE_PATH = `${CRM_PATH}/lead_source/:id/edit`;
export const CRM_TASK_TYPES_PATH = `${CRM_PATH}/task_types`;
export const CRM_LOCATIONS_PATH = `${CRM_PATH}/locations`;
export const CRM_LOCATION_NEW_PATH = `${CRM_PATH}/locations/new`;
export const CRM_LOCATION_EDIT_PATH = `${CRM_PATH}/locations/:id/edit`;
export const CRM_PAYMENTS_PATH = `${CRM_PATH}/payments`;
export const CRM_PAYMENTS_NEW_PATH = `${CRM_PATH}/payments/new`;
export const CRM_PAYMENTS_EDIT_PATH = `${CRM_PATH}/payments/:id/edit`;
export const CRM_INVOICES_PATH = `${CRM_PATH}/invoices`;
export const CRM_DASHBOARD_REPORTS_PATH = `${CRM_PATH}/visualizations`;
export const CRM_DASHBOARD_REPORTS_WIDGETS_PATH = `${CRM_PATH}/widgets/:id`;
export const CRM_APPROVAL_PATH = `${CRM_PATH}/approvals`;
export const CRM_ADD_APPROVAL = `${CRM_PATH}/approvals/new`;
export const CRM_APPROVAL_DETAIL_PATH = `${CRM_PATH}/approvals/:id/detail`;
export const CRM_CLIENT_EDIT_PATH = `${CRM_PATH}/clients/:id/edit`;
export const CRM_CLIENT_VIEW_PATH = `${CRM_PATH}/clients/:id/detail`;
export const BULK_QR_CODE = `${CRM_PATH}/download-qr-codes`;
export const CRM_ITEM_LISTS_PATH = `${CRM_PATH}/item_lists`;
// export const CRM_ITEM_LISTS_NEW = `${CRM_PATH}/item_lists/new`;
// export const CRM_ITEM_LISTS_EDIT = `${CRM_PATH}/item_lists/:id/edit`;
export const CRM_APPLY_LEAVE_NEW_PATH = `${CRM_PATH}/leaves/new`;
export const CRM_APPLY_LEAVE_EDIT_PATH = `${CRM_PATH}/leaves/:id/edit`;
export const CRM_ADD_SCHEDULE = `${CRM_PATH}/schedule/new`;
export const CRM_EDIT_SCHEDULE = `${CRM_PATH}/schedule/:id/edit`;
export const CRM_CONTRACTS_PATH = `${CRM_PATH}/contracts`;
export const CRM_CONTRACT_NEW_PATH = `${CRM_PATH}/contract/new`;
export const CRM_CONTRACT_EDIT_PATH = `${CRM_PATH}/contracts/:id/edit`;
export const CRM_CONTRACT_DETAIL_PATH = `${CRM_PATH}/contracts/:id/detail`;
export const CRM_CALL_VIEW_EDIT_QC_PATH = `${CRM_PATH}/call/:id/qc`;
export const CRM_CALL_QC_PATH = `${CRM_PATH}/call/:id/qc/:templateId`;
export const CRM_FIELD_MAPPING_PATH = `${CRM_PATH}/field_mapping`;
export const CRM_APPROVALS_ADMIN = `${CRM_PATH}/approval-manager`;
export const CRM_RETAIL_PRICE_REDUCTION = `${CRM_PATH}/price_reduction`;
export const CRM_WHATSAPP_TRIGGER_CONTROLLER = `${CRM_PATH}/whatsapp-trigger-controller`;
export const CRM_DUPLICATION_SEARCH = `${CRM_PATH}/search`;

//Reports
export const CRM_REPORT_CALL_CENTER_SELLERS = `${CRM_PATH}/reports/call-center-sellers`;
export const CRM_REPORT_CALL_CENTER_BUYERS = `${CRM_PATH}/reports/call-center-buyers`;
