import * as yup from "yup";

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { addTeamApi, fetchUsersByTypeApi, updateTeamApi } from "../../helpers/ApiHelper";
import { capitalize, getErrorString, getUniqueObjects, makeListToSelectData } from "../../helpers/Util";
import { descriptionValidation, nameValidation } from "../../helpers/validations";
import { CheckboxComponent, CustomAlert, FormInput, FormSelect, Loader, SelectionListTypeHead } from "../common";

import { Formik } from "formik";
import { strings } from "../../constant/strings";

const AddTeamForm = forwardRef((props, ref) => {
  const { inspections, cities, addTeam, updateTeam, closeDialog, asUpdate, updateData, toggleAddTeamLoading } = props;

  const [inspectorsList, setInspectorsList] = useState([]);
  const [userInspectors, setUserInspectors] = useState([]);
  const [userInspectorsLoading, setUserInspectorsLoading] = useState(false);
  const [userInspectorsError, setUserInspectorsError] = useState("");

  let formikRef = useRef();

  useImperativeHandle(ref, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  useEffect(() => {
    if (asUpdate) {
      inspections.forEach(it => {
        if (Number(it.id) === Number(updateData.inspection_id)) {
          setInspectorsList(it.inspector_types);
        }
      });
      fetchUsers();
    }
  }, [asUpdate, updateData]);

  const validationSchema = () =>
    yup.object().shape({
      name: nameValidation(strings.team_name, 30),
      city: yup.string().required(strings.select_city),
      description: descriptionValidation,
      inspectionType: yup.string().required(strings.select_inspection_message),
    });

  const createTeamMembersObject = (type, users) => {
    const initialInspectorTypes = {};
    updateData.team_members &&
      updateData.team_members.forEach(e => {
        const inspection = inspections.find(it => it.id === updateData.inspection_id);
        const currentInpsectorType = inspection.inspector_types.find(it => it.id === e.inspector_type_id);
        if (currentInpsectorType && e.user_id) {
          initialInspectorTypes[currentInpsectorType.name] =
            type === "schema" ? yup.string().required(`Please add ${currentInpsectorType.name}`) : e.user_id;
        }
      });
    return initialInspectorTypes;
  };

  const setFromInitialValues = initialInspectorTypes => {
    if (updateData && formikRef && formikRef.current) {
      const { name, city_id, description, inspection_id, team_lead_id, ext_auto_assign } = updateData;
      formikRef.current.setValues({
        name: formikRef.current.values.name || name,
        city: formikRef.current.values.city || city_id,
        description: formikRef.current.values.description || description,
        inspectionType: formikRef.current.values.inspectionType || inspection_id,
        teamLead: formikRef.current.values.teamLead || team_lead_id,
        extAutoAssign: formikRef.current.values.extAutoAssign || ext_auto_assign,
        ...initialInspectorTypes,
      });
    }
  };

  const fetchUsers = async () => {
    setUserInspectorsLoading(true);
    const response = await fetchUsersByTypeApi("inspector", 1000);
    if (response && response.success) {
      setUserInspectors(response.users);
      setUserInspectorsLoading(false);
      setFromInitialValues(createTeamMembersObject("initial-value", response.users));
    } else if (response.errors) {
      setUserInspectorsError(strings.fetching_users_error);
    }
  };

  const getInitialValues = () => ({
    name: "",
    city: "",
    description: "",
    inspectionType: "",
    extAutoAssign: false,
  });

  const onSubmit = async (values, { setStatus, setErrors }) => {
    const { name, city, description, inspectionType, teamLead, extAutoAssign, ...rest } = values;

    setStatus({ message: "" });
    let errorObj = {};
    inspectorsList.map(e => {
      if (!values[e.name]) {
        errorObj[e.name] = strings.select_inspector_message;
      }
    });
    if (!teamLead) {
      errorObj.teamLead = strings.select_team_lead_message;
    }
    if (Object.keys(errorObj).length > 0) {
      setErrors(errorObj);
      return;
    }
    const teamMemberAttributes = [];

    Object.keys(rest).forEach(e => {
      const inspType = inspectorsList.find(i => i.name === e) || {};

      // for update
      const remainingAttributes =
        updateData && inspectionType === updateData.inspection_id
          ? updateData.team_members.find(i => Number(i.inspector_type_id) === Number(inspType.id))
          : {};
      if (inspType && inspType.id) {
        teamMemberAttributes.push({
          ...remainingAttributes,
          user_id: rest[e],
          inspector_type_id: inspType.id,
        });
      }
    });
    const teamData = {
      team: {
        inspection_id: inspectionType,
        name: name,
        city_id: city,
        is_active: 1,
        description: description,
        team_members_attributes: [...teamMemberAttributes],
        team_lead_id: Number(teamLead),
        ext_auto_assign: extAutoAssign,
      },
    };

    toggleAddTeamLoading(true);
    if (asUpdate) {
      const response = await updateTeamApi(updateData.id, teamData);
      if (response) {
        toggleAddTeamLoading(false);
        if (response.team) {
          updateTeam({ ...response.team });
          closeDialog();
        } else {
          setStatus({
            message: getErrorString(response),
          });
        }
      }
    } else {
      const response = await addTeamApi(teamData);
      if (response) {
        toggleAddTeamLoading(false);
        if (response.team) {
          addTeam({ ...response.team });
          closeDialog();
        } else {
          setStatus({ message: getErrorString(response) });
        }
      }
    }
  };

  const teamLeadOp = values => {
    const teamLeadOptions = [];
    inspectorsList.forEach(inspector => {
      values[inspector.name] &&
        userInspectors.forEach(it => {
          Number(values[inspector.name]) === Number(it.id) &&
            teamLeadOptions.push({
              // ...userInspectors,
              label: it.first_name + " " + it.last_name,
              slug: it.id,
            });
        });
    });
    return getUniqueObjects(teamLeadOptions);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, status, setFieldValue }) => {
        const teamLeadOptions = teamLeadOp(values);

        return (
          <form className="form theme-form">
            <FormInput
              label={strings.name}
              placeholder={strings.name_ph}
              onChange={handleChange("name")}
              onBlur={handleBlur("name")}
              value={values.name}
              errorMsg={errors.name && touched.name && errors.name}
              required
            />

            <FormSelect
              label={strings.city}
              placeholder={strings.city_ph}
              onBlur={handleBlur("city")}
              value={values.city}
              errorMsg={errors.city && touched.city && errors.city}
              options={makeListToSelectData(cities)}
              onChange={e => {
                handleChange("city")(e);
              }}
              setSlugAsValue
              required
            />

            <FormInput
              label={strings.description}
              placeholder={strings.description_ph}
              linesCount="4"
              onChange={handleChange("description")}
              onBlur={handleBlur("description")}
              value={values.description}
              errorMsg={errors.description && touched.description && errors.description}
            />
            <SelectionListTypeHead
              className="form-group"
              label={strings.inspection_type}
              placeholder={strings.inspection_type_ph}
              onBlur={handleBlur("inspectionType")}
              errorMsg={errors.inspectionType && touched.inspectionType && errors.inspectionType}
              options={makeListToSelectData(inspections)}
              onChange={event => {
                const inspectionTypeId = event[0]?.id;
                setFieldValue("inspectionType", inspectionTypeId);
                inspections.forEach(item => {
                  if (Number(item.id) === Number(inspectionTypeId)) {
                    setInspectorsList(item.inspector_types);
                    fetchUsers();
                    item.inspector_types.forEach(el => {
                      setFieldValue(el.name, "");
                    });
                  }
                });
              }}
              selected={inspections?.filter(e => Number(e.id) === Number(values["inspectionType"])) || []}
              labelKey="name"
            />

            {!userInspectorsLoading && !userInspectorsError ? (
              <>
                <CheckboxComponent
                  mainStyles={{ marginBottom: "20px" }}
                  labelStyles={{ fontWeight: "400" }}
                  label={`${strings.auto_assign_external_evaluation}`}
                  checked={values.extAutoAssign}
                  onChange={e => {
                    setFieldValue("extAutoAssign", e.target.checked);
                  }}
                />

                {inspectorsList?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <SelectionListTypeHead
                        className="form-group"
                        label={`${strings.select} ${capitalize(e.name)}`}
                        placeholder={`${strings.select_ph} ${capitalize(e.name)}`}
                        onBlur={handleBlur(e.name)}
                        errorMsg={errors[e.name]}
                        options={userInspectors.map(it => {
                          return {
                            // ...userInspectors,
                            label: it.first_name + " " + it.last_name,
                            slug: it.id,
                          };
                        })}
                        onChange={event => {
                          setFieldValue(e.name, event[0]?.slug);
                          setFieldValue("teamLead", "");
                        }}
                        selected={
                          makeListToSelectData(
                            userInspectors?.filter(item => Number(item.id) === Number(values[e.name]))
                          ) || []
                        }
                        labelKey="label"
                      />
                      {i === inspectorsList.length - 1 ? (
                        <SelectionListTypeHead
                          className="form-group"
                          label={strings.team_lead}
                          placeholder={strings.team_lead_ph}
                          onBlur={e => handleBlur("teamLead")}
                          options={teamLeadOptions}
                          onChange={event => {
                            setFieldValue("teamLead", event[0]?.slug);
                          }}
                          selected={teamLeadOptions.filter(item => item.slug == Number(values["teamLead"])) || []}
                          errorMsg={errors.teamLead}
                          labelKey="label"
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <Loader />
            )}
            {status && status.message && <CustomAlert message={status.message} />}
          </form>
        );
      }}
    </Formik>
  );
});

export default AddTeamForm;
