import { CreditCard } from "react-feather";
import {
  AiOutlineProfile,
  CallIcon,
  IconSidebarAdmin,
  IconSidebarApprove,
  IconSidebarAuction,
  IconSidebarBidders,
  IconSidebarCalendar,
  IconSidebarCheckIn,
  IconSidebarClient,
  IconSidebarContracts,
  IconSidebarCore,
  IconSidebarDashboard,
  IconSidebarInventory,
  IconSidebarLeads,
  IconSidebarPayments,
  IconSidebarReminder,
  IconSidebarReports,
  IconSidebarRotaPlan,
  IconSidebarStockCount,
  IconSidebarTasks,
} from "../components/svg-icon/svg-icons";
import { getPath } from "../helpers/Util";
import { store } from "../store/index";
import { RESTRICTED_MODULES } from "./appConstants";
import {
  AUCTION_BOOKING_EXPENSES,
  AUCTION_MARGIN_SLABS,
  AUC_AUCTIONS_PATH,
  AUC_AUCTION_BOOKINGS,
  AUC_AUCTION_TYPES_PATH,
  AUC_AUCTION_USERS_PATH,
  AUC_CARS_PATH,
  AUC_DASHBOARD_PATH,
  AUC_GROUPS_PATH,
  CRM_APPROVALS_ADMIN,
  CRM_APPROVAL_PATH,
  CRM_CALLS_PATH,
  CRM_CARS_PATH,
  CRM_CARS_STOCK_PATH,
  CRM_CICO_PATH,
  CRM_CLIENTS_PATH,
  CRM_CONTRACTS_PATH,
  CRM_DASHBOARD_PATH,
  CRM_DASHBOARD_REPORTS_PATH,
  CRM_FIELD_MAPPING_PATH,
  CRM_ITEM_LISTS_PATH,
  CRM_LEADS_BUYER_PATH,
  CRM_LEADS_PATH,
  CRM_LEADS_SELLER_PATH,
  CRM_LEADS_VAS_PATH,
  CRM_LOCATIONS_PATH,
  CRM_PAYMENTS_PATH,
  CRM_REMINDER_PATH,
  CRM_RETAIL_PRICE_REDUCTION,
  CRM_ROTA_PLAN_PATH,
  CRM_STOCK_COUNT_PATH,
  CRM_TASKS_PATH,
  CRM_TASK_TYPES_PATH,
  CRM_WHATSAPP_TRIGGER_CONTROLLER,
  EVENT_CALENDAR_PATH,
  INS_CALLS_PATH,
  INS_DASHBOARD_PATH,
  INS_DEPOSITS_PATH,
  INS_EMAIL_TEMPLATES_PATH,
  INS_INSPECTIONS_PATH,
  INS_LEADS_PATH,
  INS_LEAD_TYPES_PATH,
  INS_PAYMENTS_PATH,
  INS_SCHEDULES_PATH,
  INS_TEAMS_PATH,
} from "./appPaths";
import { strings } from "./strings";

const coreItems = type => {
  return {
    title: strings.core,
    key: "Admin",
    icon: IconSidebarCore,
    type: "sub",
    active: false,
    iconProps: { size: "1rem" },
    children: [
      {
        path: getPath("users", undefined, true, type),
        title: strings.users,
        type: "link",
        key: "User",
      },
      {
        path: getPath("roles", undefined, true, type),
        title: strings.roles,
        type: "link",
        key: "Role",
      },
      {
        path: getPath("authentication_tokens", undefined, true, type),
        title: strings.tokens,
        type: "link",
        key: "AuthenticationToken",
      },
      {
        path: getPath("lead_source", undefined, true, type),
        title: strings.lead_source,
        type: "link",
        key: "LeadSource",
      },

      {
        path: getPath("tenant", undefined, true, type),
        title: strings.tenant_settings,
        type: "link",
        key: "Tenant",
      },
      {
        path: getPath("dynamic_models", undefined, true, type),
        title: strings.dynamic_models,
        type: "link",
        key: "DynamicModel",
      },
    ],
  };
};

const auctionMenuItems = [
  {
    title: strings.dashboard,
    key: "Dashboard",
    icon: IconSidebarDashboard,
    type: "link",
    path: AUC_DASHBOARD_PATH,
    permit: true,
    active: false,
    iconProps: { style: { marginRight: "12px" } },
  },
  {
    title: strings.inventory,
    key: "Car",
    icon: IconSidebarInventory,
    type: "link",
    path: AUC_CARS_PATH,
    active: false,
    iconProps: { style: { marginRight: "11px", width: "1.1em", height: "1.1em" } },
  },
  {
    title: strings.auctions,
    key: "Auction",
    icon: IconSidebarAuction,
    type: "link",
    path: AUC_AUCTIONS_PATH,
    active: false,
    iconProps: { size: "1rem" },
  },
  {
    title: strings.bidders,
    key: "AuctionUser",
    icon: IconSidebarBidders,
    type: "link",
    path: AUC_AUCTION_USERS_PATH,
    active: false,
    iconProps: { style: { marginRight: "12px", width: "1.09em", height: "1.09em" } },
  },
  {
    title: strings.bookings,
    key: "Booking",
    icon: IconSidebarCalendar,
    type: "link",
    path: AUC_AUCTION_BOOKINGS,
    active: false,
    iconProps: { style: { marginRight: "12px", width: "1.09em", height: "1.09em" } },
    restrictedModuleKey: RESTRICTED_MODULES.BOOKING,
  },
  {
    title: strings.admin,
    key: "Admin",
    icon: IconSidebarAdmin,
    type: "sub",
    active: false,
    iconProps: { size: "1rem" },
    children: [
      {
        path: AUC_GROUPS_PATH,
        title: strings.groups,
        type: "link",
        key: "Group",
      },
      {
        path: AUC_AUCTION_TYPES_PATH,
        title: strings.auction_types,
        type: "link",
        key: "AuctionType",
      },
      {
        path: AUCTION_BOOKING_EXPENSES,
        restrictedModuleKey: RESTRICTED_MODULES.BOOKING,
        title: strings.expenses,
        type: "link",
        key: "BookingService",
      },
      {
        path: AUCTION_MARGIN_SLABS,
        title: strings.auction_margin,
        type: "link",
        key: "AuctionMarginSlabs",
        restrictedModuleKey: RESTRICTED_MODULES.AUCTION_MARGIN_SLABS,
      },
    ],
  },
  {
    ...coreItems("auction"),
  },
];

const inspectionMenuItems = [
  {
    title: strings.dashboard,
    key: "Dashboard",
    icon: IconSidebarDashboard,
    type: "link",
    path: INS_DASHBOARD_PATH,
    permit: true,
    active: false,
    iconProps: { style: { marginRight: "12px" } },
  },
  {
    title: strings.leads,
    key: "Lead",
    icon: IconSidebarLeads,
    type: "link",
    path: INS_LEADS_PATH,
    active: false,
  },
  {
    title: strings.schedules,
    key: "Schedule",
    icon: IconSidebarCalendar,
    type: "link",
    path: INS_SCHEDULES_PATH,
    active: false,
  },
  {
    title: strings.reports,
    key: "Report",
    icon: IconSidebarReports,
    type: "link",
    path: INS_INSPECTIONS_PATH,
    active: false,
  },
  {
    title: strings.call_recording,
    key: "Recording",
    icon: CallIcon,
    type: "link",
    path: INS_CALLS_PATH,
    active: false,
    restrictedModuleKey: RESTRICTED_MODULES.RECORDING,
  },
  {
    title: strings.finance,
    key: ["Payment", "Deposit"],
    icon: IconSidebarPayments,
    type: "sub",
    active: false,
    children: [
      {
        title: strings.payments,
        key: "Payment",
        icon: CreditCard,
        type: "link",
        path: INS_PAYMENTS_PATH,
        active: false,
      },
      {
        title: strings.deposits,
        key: "Deposit",
        icon: CreditCard,
        type: "link",
        path: INS_DEPOSITS_PATH,
        active: false,
      },
    ],
  },

  {
    title: strings.admin,
    key: "Admin",
    icon: IconSidebarAdmin,
    type: "sub",
    active: false,
    iconProps: { style: { marginRight: "15px", width: "0.929rem", height: "0.929rem" } },
    children: [
      {
        path: INS_TEAMS_PATH,
        title: strings.teams,
        type: "link",
        key: "Team",
      },
      {
        path: INS_LEAD_TYPES_PATH,
        title: strings.lead_types,
        type: "link",
        key: "LeadType",
      },
      // {
      //   path: INS_SMS_TEMPLATES_PATH,
      //   title: strings.sms_settings,
      //   type: "link",
      //   key: "SmsTemplate",
      // },
      {
        path: INS_EMAIL_TEMPLATES_PATH,
        title: strings.email_settings,
        type: "link",
        key: "EmailTemplate",
      },
    ],
  },
  {
    ...coreItems("inspection"),
  },
];
const handleRestrictedMenuItems = (menuItemPayload, type) => {
  let restrictedModules = store.getState().Configs?.tenant?.configuration?.restricted_modules;
  if (restrictedModules && restrictedModules.includes(type)) return null;
  return { ...menuItemPayload };
};

export const crmMenuItems = [
  {
    title: strings.dashboard,
    key: "Dashboard",
    icon: IconSidebarDashboard,
    permit: true,
    type: "link",
    path: CRM_DASHBOARD_PATH,
    active: false,
    iconProps: { style: { marginRight: "12px" } },
  },

  // {
  //   title: 'Appointment Report',
  //   key: "Dashboard",
  //   icon: IconSidebarReports,
  //   permit: true,
  //   type: "link",
  //   path: `${CRM_APPOINTMENTS_REPORT}`,
  //   active: false,
  //   iconProps: { style: { marginRight: "12px" } },
  // },

  {
    title: strings.leads,
    key: ["CrmLead", "CrmLead", "CrmLead"],
    icon: IconSidebarLeads,
    type: "sub",
    active: false,
    children: [
      {
        title: strings.all,
        key: "CrmLead",
        type: "link",
        path: CRM_LEADS_PATH,
        active: false,
      },
      {
        title: strings.seller,
        key: "CrmLead",
        type: "link",
        path: CRM_LEADS_SELLER_PATH,
        active: false,
      },
      {
        title: strings.buyer,
        key: "CrmLead",
        type: "link",
        path: CRM_LEADS_BUYER_PATH,
        active: false,
      },
      {
        title: strings.vas,
        key: "CrmLead",
        type: "link",
        path: CRM_LEADS_VAS_PATH,
        active: false,
        restrictedModuleKey: RESTRICTED_MODULES.VAS,
      },
    ],
  },
  {
    title: strings.tasks,
    key: "Task",
    icon: IconSidebarTasks,
    type: "link",
    path: CRM_TASKS_PATH,
    active: false,
  },
  {
    title: strings.inventory,
    key: ["Car", "Car"],
    icon: IconSidebarInventory,
    type: "sub",
    active: false,
    children: [
      {
        title: strings.listing,
        key: "Car",
        type: "link",
        path: CRM_CARS_PATH,
        active: false,
      },
      {
        title: strings.stock_list,
        key: "Car",
        type: "link",
        path: CRM_CARS_STOCK_PATH,
        active: false,
      },
    ],
  },
  {
    title: strings.stock_count,
    key: "StockCounter",
    icon: IconSidebarStockCount,
    type: "link",
    path: CRM_STOCK_COUNT_PATH,
    restrictedModuleKey: RESTRICTED_MODULES.STOCK_COUNTERS,
  },
  {
    title: strings.cico,
    key: "Cico",
    icon: IconSidebarCheckIn,
    type: "link",
    path: CRM_CICO_PATH,
    active: false,
    restrictedModuleKey: RESTRICTED_MODULES.CICOS,
  },
  {
    title: strings.call_recording,
    key: "Recording",
    icon: CallIcon,
    type: "link",
    path: CRM_CALLS_PATH,
    active: false,
    restrictedModuleKey: RESTRICTED_MODULES.RECORDING,
  },
  {
    title: strings.reports,
    key: "Visualisation",
    icon: IconSidebarReports,
    permit: true,
    type: "link",
    path: CRM_DASHBOARD_REPORTS_PATH,
    active: false,
    iconProps: { style: { marginRight: "12px", size: "1.3rem" } },
  },
  {
    title: strings.clients,
    key: "Client",
    icon: IconSidebarClient,
    type: "link",
    path: CRM_CLIENTS_PATH,
    active: false,
  },
  {
    title: strings.payments,
    key: "CrmPayment",
    icon: IconSidebarPayments,
    type: "link",
    path: CRM_PAYMENTS_PATH,
    active: false,
  },

  {
    title: strings.contracts,
    key: "Contract",
    icon: IconSidebarContracts,
    type: "link",
    path: CRM_CONTRACTS_PATH,
    active: false,
    restrictedModuleKey: RESTRICTED_MODULES.CONTRACT,
  },
  {
    title: strings.approvals,
    key: "Approval",
    icon: IconSidebarApprove,
    type: "link",
    path: CRM_APPROVAL_PATH,
    active: false,
    restrictedModuleKey: RESTRICTED_MODULES.APPROVALS,
  },
  {
    title: strings.calender,
    key: "Appointment",
    icon: IconSidebarCalendar,
    type: "link",
    path: EVENT_CALENDAR_PATH,
    active: false,
    action: "calendar",
  },
  {
    title: strings.reminders,
    key: "Reminder",
    icon: IconSidebarReminder,
    type: "link",
    path: CRM_REMINDER_PATH,
    active: false,
  },
  {
    title: strings.rota_plan,
    key: "Shift",
    icon: IconSidebarRotaPlan,
    type: "link",
    path: CRM_ROTA_PLAN_PATH,
    active: false,
    restrictedModuleKey: RESTRICTED_MODULES.ROTA,
  },
  {
    title: strings.admin,
    key: "Admin",
    icon: IconSidebarAdmin,
    type: "sub",
    active: false,
    iconProps: { size: "1rem" },
    children: [
      {
        path: CRM_TASK_TYPES_PATH,
        title: strings.task_types,
        type: "link",
        key: "TaskType",
      },
      // {
      //   path: CRM_PAYMENTS_PATH,
      //   title: strings.add_payment,
      //   type: "link",
      //   key: "addPayment",
      // },
      {
        path: CRM_LOCATIONS_PATH,
        title: strings.locations,
        type: "link",
        key: "Location",
      },
      {
        path: CRM_ITEM_LISTS_PATH,
        title: strings.mmvs,
        type: "link",
        key: "ItemList",
      },
      {
        path: CRM_RETAIL_PRICE_REDUCTION,
        title: strings.retail_price_reduction,
        type: "link",
        key: "RetailPriceReduction",
        restrictedModuleKey: RESTRICTED_MODULES.RETAIL_PRICE_REDUCTION,
      },
      {
        title: strings.field_mapping,
        key: "Field Mapping",
        icon: IconSidebarRotaPlan,
        type: "link",
        path: CRM_FIELD_MAPPING_PATH,
        restrictedModuleKey: RESTRICTED_MODULES.FIELD_MAPPING,
      },
      {
        title: strings.approvals_settings,
        key: "Approvals Settings",
        icon: IconSidebarRotaPlan,
        type: "link",
        path: CRM_APPROVALS_ADMIN,
        restrictedModuleKey: RESTRICTED_MODULES.APPROVALS,
      },
      {
        title: strings.whatsapp_trigger_controller,
        key: "whatsapp_trigger_controller",
        icon: IconSidebarRotaPlan,
        type: "link",
        path: CRM_WHATSAPP_TRIGGER_CONTROLLER,
        // active: false,
        restrictedModuleKey: RESTRICTED_MODULES.WHATSAPP_TRIGGER_CONTROLLER,
      },
    ],
  },
  {
    ...coreItems("crm"),
  },
];

const getCrmMenuItems = () => {
  const approvalMenuItem = handleRestrictedMenuItems(
    {
      title: strings.approvals,
      key: "Approval",
      icon: IconSidebarApprove,
      type: "link",
      path: CRM_APPROVAL_PATH,
      active: false,
    },
    "approvals"
  );
  const crmMenuItems = [
    {
      title: strings.dashboard,
      key: "Dashboard",
      icon: IconSidebarDashboard,
      permit: true,
      type: "link",
      path: CRM_DASHBOARD_PATH,
      active: false,
      iconProps: { style: { marginRight: "12px" } },
    },

    {
      title: strings.leads,
      key: ["CrmLead", "CrmLead", "CrmLead"],
      icon: AiOutlineProfile,
      type: "sub",
      active: false,
      children: [
        {
          title: strings.all,
          key: "CrmLead",
          type: "link",
          path: CRM_LEADS_PATH,
          active: false,
        },
        {
          title: strings.seller,
          key: "CrmLead",
          type: "link",
          path: CRM_LEADS_SELLER_PATH,
          active: false,
        },
        {
          title: strings.buyer,
          key: "CrmLead",
          type: "link",
          path: CRM_LEADS_BUYER_PATH,
          active: false,
        },
      ],
    },
    {
      title: strings.tasks,
      key: "Task",
      icon: IconSidebarTasks,
      type: "link",
      path: CRM_TASKS_PATH,
      active: false,
    },
    {
      title: strings.inventory,
      key: "Car",
      icon: IconSidebarInventory,
      type: "link",
      path: CRM_CARS_PATH,
      active: false,
      iconProps: { size: "1.12rem", style: { marginRight: "13px" } },
    },
    {
      title: strings.reports,
      key: "Visualisation",
      icon: IconSidebarReports,
      permit: true,
      type: "link",
      path: CRM_DASHBOARD_REPORTS_PATH,
      active: false,
      iconProps: { style: { marginRight: "12px", size: "1.3rem" } },
    },
    {
      title: strings.clients,
      key: "Client",
      icon: IconSidebarClient,
      type: "link",
      path: CRM_CLIENTS_PATH,
      active: false,
    },
    {
      title: strings.payments,
      key: "CrmPayment",
      icon: IconSidebarPayments,
      type: "link",
      path: CRM_PAYMENTS_PATH,
      active: false,
    },

    {
      title: strings.contracts,
      key: "Contract",
      icon: IconSidebarPayments,
      type: "link",
      path: CRM_CONTRACTS_PATH,
      active: false,
    },
    approvalMenuItem,
    // {
    //   title: strings.approvals,
    //   key: "Approval",
    //   icon: ApprovalLogo,
    //   type: "link",
    //   path: CRM_APPROVAL_PATH,
    //   active: false,
    // },
    {
      title: strings.calender,
      key: "Appointment",
      icon: IconSidebarCalendar,
      type: "link",
      path: EVENT_CALENDAR_PATH,
      active: false,
      action: "calendar",
    },
    {
      title: strings.admin,
      key: "Admin",
      icon: IconSidebarAdmin,
      type: "sub",
      active: false,
      iconProps: { size: "1rem" },
      // children: [
      // {
      //   path: CRM_TASK_TYPES_PATH,
      //   title: strings.task_types,
      //   type: "link",
      //   key: "TaskType",
      // },
      // {
      //   path: CRM_LOCATIONS_PATH,
      //   title: strings.locations,
      //   type: "link",
      //   key: "Location",
      // },
      // {
      //   path: CRM_ITEM_LISTS_PATH,
      //   title: strings.make_modal_variant,
      //   type: "link",
      //   key: "Location",
      // },
      // ],
    },
    {
      ...coreItems("crm"),
    },
  ];
  return crmMenuItems.filter(Boolean);
};

export const MENUITEMS = () => {
  let restrictedModules = store.getState().Configs?.tenant?.configuration?.restricted_modules;
  const checkRestriction = value => !(restrictedModules && restrictedModules.includes(value));
  const filterRestrictedModules = (menuItems = []) => {
    return menuItems.filter(item => {
      if (item.children?.length) {
        const filteredChildren = item.children.filter(child => checkRestriction(child.restrictedModuleKey));
        return (item["children"] = filteredChildren);
      }
      return checkRestriction(item.restrictedModuleKey);
    });
  };

  if (window.location.href.indexOf("/inspection") !== -1) {
    return filterRestrictedModules(inspectionMenuItems);
  } else if (window.location.href.indexOf("/auction") !== -1) {
    return filterRestrictedModules(auctionMenuItems);
  } else if (window.location.href.indexOf("/crm") !== -1) {
    return filterRestrictedModules(crmMenuItems);
  }
  return [];
};
