import React from "react";
import "./index.module.scss";
import Logo from "../../components/common/Logo/Logo";

const Terms = () => {
  return (
    <div className="page-wrapper">
      <div className="page-body-wrapper">
        <div className="page-main-header open">
          <div className="main-header-right flex-row justify-content-center">
            <div className="compactLogo">
              <a href="/admin/dashboard">
                <Logo className="d-block" />
              </a>
            </div>
          </div>
        </div>
        <div className="page-body staticPage">
          <div className={"container pt-5 pb-1"}>
            <h2>Terms and Conditions</h2>
            <p>
              These terms and conditions outline the rules and regulations for the use of Carforce Suite owned and
              operated by EMPG LABS (SMC - Private) Ltd. By accessing these apps, we assume you accept these terms and
              conditions. Do not continue to use the Carforce Suite if you do not agree to take all of the terms and
              conditions stated on this page. The following terminology applies to these Terms and Conditions, Privacy
              Statement and Disclaimer Notice and all Agreements: “Client”, “EMPG LABS (SMC - Private) Ltd” and the
              person logged on this application and compliant to the EMPG Labs (Private Limited)'s terms and conditions.
              EMPG LABS (SMC - Private) Ltd refers to our EMPG LABS (SMC - Private) Ltd. “Party”, “Parties”, or “Us”,
              refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of
              payment necessary to undertake the process of our assistance to the Client in the most appropriate manner
              for the express purpose of meeting the Client's needs in respect of provision of the EMPG LABS (SMC -
              Private) Ltd's stated services, in accordance with and subject to, prevailing law of Pakistan. Any use of
              the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are
              taken as interchangeable and therefore as referring to the same.
            </p>
            <h4>Cookies</h4>
            <p>
              We employ the use of cookies. By accessing Carforce Suite, you agreed to use cookies in agreement with
              EMPG LABS (SMC - Private) Ltd Privacy Policy. Most interactive apps use cookies to let us retrieve the
              user's details for each visit. Cookies are used by our apps to enable the functionality of certain areas
              to make it easier for people visiting our apps. Some of our affiliate/advertising partners may also use
              cookies.
            </p>

            <h4>License</h4>
            <p>
              Unless otherwise stated, EMPG LABS (SMC - Private) Ltd and/or its licensors own the intellectual property
              rights for all material on Carforce. All intellectual property rights are reserved. You may access this
              from Carforce for your own personal use subjected to restrictions set in these terms and conditions. You
              must not:
              <ul>
                <li>Republish material from Carforce</li>
                <li>Sell, rent or sub-license material from Carforce</li>
                <li>Reproduce, duplicate or copy material from Carforce</li>
                <li>Redistribute content from Carforce</li>
              </ul>
            </p>
            <p>
              This Agreement shall begin on the date hereof. Parts of these apps offer an opportunity for users to post
              and exchange opinions and information in certain areas of the apps. EMPG LABS (SMC - Private) Ltd does not
              <ul>
                <li>
                  You are entitled to post the Comments on our apps and have all necessary licenses and consents to do
                  so;
                </li>
                <li>
                  The Comments do not invade any intellectual property right, including without limitation copyright,
                  patent or trademark of any third party;
                </li>
                <li>
                  The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful
                  material which is an invasion of privacy
                </li>
                <li>
                  The Comments will not be used to solicit or promote business or custom or present commercial
                  activities or unlawful activity.
                </li>
              </ul>
            </p>
            <p>
              You hereby grant EMPG LABS (SMC - Private) Ltd a non-exclusive license to use, reproduce, edit and
              authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
            </p>
            <h4>Hyperlinking to our Content</h4>
            <p>
              The following organizations may link to our apps without prior written approval:
              <ul>
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>
                  Online directory distributors may link to our apps in the same manner as they hyperlink to the
                  websites of other listed businesses; and
                </li>
                <li>
                  System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls,
                  and charity fundraising groups which may not hyperlink to our applications.
                </li>
              </ul>
            </p>

            <p>
              These organizations may link to our home page, to publications or to other apps information so long as the
              link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of
              the linking party and its products and/or services; and (c) fits within the context of the linking party's
              site. We may consider and approve other link requests from the following types of organizations:
              <ul>
                <li>commonly-known consumer and/or business information sources;</li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law and consulting firms; and</li>
                <li>educational institutions and trade associations.</li>
              </ul>
            </p>
            <p>
              We will approve link requests from these organizations if we decide that: (a) the link would not make us
              look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any
              negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the
              absence of EMPG LABS (SMC - Private) Ltd; and (d) the link is in the context of general resource
              information. These organizations may link to our home page so long as the link: (a) is not in any way
              deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its
              products or services; and (c) fits within the context of the linking party's site. If you are one of the
              organizations listed in paragraph 2 above and are interested in linking to our applications, you must
              inform us by sending an email to support@empgautos.com. Please include your name, your organization name,
              contact information as well as the URL of your site, a list of any URLs from which you intend to link to
              our apps, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a
              response. Approved organizations may hyperlink to our apps as follows:
              <ul>
                <li>By use of our corporate name; or</li>
                <li>By use of the uniform resource locator being linked to; or</li>
                <li>
                  By use of any other description of our apps being linked to that makes sense within the context and
                  format of content on the linking party's site.
                </li>
              </ul>
            </p>
            <p>
              No use of EMPG LABS (SMC - Private) Ltd's logo or other artwork will be allowed for linking absent a
              trademark license agreement.
            </p>

            <h4>iFrames</h4>
            <p>
              Without prior approval and written permission, you may not create frames around our Web Pages that alter
              in any way the visual presentation or appearance of our apps.
            </p>
            <h4>Content Liability</h4>
            <p>
              We shall not be held responsible for any content that appears on your apps. You agree to protect and
              defend us against all claims that are rising on your apps. No link(s) should appear on any apps that may
              be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the
              infringement or other violation of, any third party rights.
            </p>
            <h4> Reservation of Rights</h4>
            <p>
              We reserve the right to request that you remove all links or any particular link to our apps. You approve
              to immediately remove all links to our apps upon request. We also reserve the right to amend these terms
              and conditions and its linking policy at any time. By continuously linking to our apps, you agree to be
              bound to and follow these linking terms and conditions.
            </p>

            <h4> Removal of links from our apps</h4>
            <p>
              If you find any link on our apps that is offensive for any reason, you are free to contact and inform us
              any moment. We will consider requests to remove links but we are not obligated to or so or to respond to
              you directly. We do not ensure that the information on these apps is correct, we do not warrant its
              completeness or accuracy; nor do we promise to ensure that the apps remain available or that the material
              on the apps is kept up to date.
            </p>

            <h4>Disclaimer</h4>
            <p>
              To the maximum extent permitted by applicable law, we exclude all representations, warranties and
              conditions relating to our apps and the use of these apps. Nothing in this disclaimer will:
              <ul>
                <li> limit or exclude our or your liability for death or personal injury;</li>
                <li> limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                <li> limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                <li> exclude any of our or your liabilities that may not be excluded under applicable law.</li>
              </ul>
            </p>
            <p>
              The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a)
              are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer,
              including liabilities arising in contract, in tort and for breach of statutory duty. As long as the apps
              and the information and services on the apps are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
