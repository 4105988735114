import { FETCH_CHEVRON_USERS_DATA, SET_CHEVRON_USERS_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  chevronUsers: {},
  usersLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CHEVRON_USERS_LOADING:
      return {
        ...state,
        usersLoading: action.payload.usersLoading,
      };
    case FETCH_CHEVRON_USERS_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
