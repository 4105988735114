import React, { Fragment } from "react";
import cx from "clsx";
import Card from "../../../Card";
import Skeleton from "../../../Skeleton";
import styles from "./ChevronLeadColumnLoader.module.scss";

const ChevronLeadCardLoader = () => {
  return (
    <Card
      cardBg="#fff"
      className={cx("d-flex flex-column mt-4 p-3", styles.cardContainer)}
      cardBorderRadius={"6px"}
      style={{ border: "1px solid #D0D5DD" }}
    >
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Skeleton width={180} height={12} />
      </div>
      <div className={cx(styles.cardTitle, "mt-2 f-w-600 f-16")}>
        <Skeleton width={200} height={12} />
      </div>
      <div className="d-flex flex-row mt-1">
        {[1, 2].map(taskItem => (
          <div key={taskItem} className="m-r-10">
            <Skeleton width={90} height={10} />
          </div>
        ))}
      </div>
      <div className="d-flex flex-row align-items-center justify-content-between mt-1">
        <div className="d-flex align-items-center mt-3">
          <Skeleton width={80} height={10} />
        </div>
        <div className={"d-flex align-items-center"}>
          {/* Later Make this component seprate into CountDownLoader */}
          <ul className={styles.timerList}>
            {[1, 2, 3, 4].map(item => (
              <li key={item}>
                <Skeleton width={20} height={20} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Card>
  );
};

const ChevronLeadColumnLoader = ({ index }) => {
  return (
    <Card cardBg={"#F9FAFB"} cardPadding={"18px 16px"} cardBorderRadius={"6px"}>
      <div className="d-flex align-items-center justify-content-between m-b-5">
        <div className="d-flex align-items-center" style={{ gap: "12px" }}>
          <Skeleton height={20} width={150} />
          <Skeleton height={30} width={30} circle />
        </div>
      </div>
      {[1, 2, 3].map(item => (
        <ChevronLeadCardLoader key={index + item} />
      ))}
    </Card>
  );
};

export default ChevronLeadColumnLoader;
