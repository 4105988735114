import { createStore, applyMiddleware, compose } from "redux";

// middlewares
import thunkMiddleware from "redux-thunk";
// import logger from 'redux-logger'

// Import custom components
import reducers from "../reducers/index";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    return undefined;
  }
}

/**
 * Create a Redux store that holds the app state.
 */

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["LoggedInUserData", "Configs", "PermissionsData", "QuickLinksData"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(thunkMiddleware),
    // For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    //   window.devToolsExtension
    //     ? window.devToolsExtension()
    //     : function (f) {
    //         return f;
    //       }
  )
);

const persistor = persistStore(store);
// eslint-disable-next-line
const unsubscribe = store.subscribe(() => {
  const state = store.getState();
  saveToLocalStorage(state);
});

export { store, persistor };
