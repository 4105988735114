import { FETCH_LEAD_SOURCES, UPDATE_LEAD_SOURCE, SET_LEAD_SOURCES_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  leadSources: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  initialRequestCalled: false,
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LEAD_SOURCES:
      return {
        ...state,
        ...action.payload,
        error: action.payload.error,
      };

    case UPDATE_LEAD_SOURCE:
      const leadSources = state.leadSources;
      let index = leadSources.findIndex(e => e.id === action.payload.item.id);
      leadSources.splice(index, 1, { ...action.payload.item });
      return {
        ...state,
        leadSources,
      };

    case SET_LEAD_SOURCES_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    default:
      return state;
  }
};
