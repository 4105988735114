import {
  appendTimeZone,
  creatingSlotsForSkeleton,
  generateUUID,
  getErrorString,
  getPermission,
  isEmptyArray,
  mergeContiguousTimeSlots,
  setTimeZone,
  subtractSeconds,
} from "../../../helpers/Util";

import CalenderModalHeader from "../../crm/CalenderModalHeader";
import { DayEvent } from "../../crm";
import { Modal } from "../../common";
import { Toast } from "..";
import { fetchTimeSlots, getAutoAssignedUser } from "../../../helpers/ApiHelper";
import moment from "moment";
import { strings } from "../../../constant/strings";
import { connect, useSelector } from "react-redux";
import IF from "../IF";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import React, { Children, useEffect, useState } from "react";
import { TASK_MEETING_TYPES } from "constant/appConstants";

const CalenderAssignEvent = props => {
  const { onDoneMeeting, getValues, date, disabled, addressItem, addressType, configuration, taskId = null } = props;
  const [scheduledCount, setScheduledCount] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [slotsTimeRange, setSlotsTimeRange] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [legendsHeight, setLegendsHeight] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [calenderDate, setCalenderDate] = useState(null);
  const [dateView, setDateView] = useState("day");
  const [error, setError] = useState("");

  const tenant = useSelector(content => content.Configs.tenant);
  const timeZone = tenant.country.timezone;
  const newAppointments = useSelector(content => content.TaskDetail.newAppointments);

  useEffect(() => {
    if (date) {
      setCalenderDate(moment(date));
    }
  }, [date]);

  useEffect(() => {
    const height = document.getElementById("legendItemWrapper")?.clientHeight;
    if (height) setLegendsHeight(height);
  }, [statuses?.length]);

  useEffect(() => {
    if (!calenderDate) return;
    fetchAppointments(getQueryStringForAppointments());
  }, [calenderDate]);

  const getQueryStringForAppointments = () => {
    const { assignee, newMeeting, task_type, inventory } = getValues();
    const assigneeId = assignee?.id || null;
    const autoAssign = newMeeting?.autoAssign || false;
    const outOfZone = newMeeting?.outOfZone || false;
    const taskTypeId = task_type?.id ?? null;
    const queryStringForAddressType = getQueryStringForSelectedAddressType({ addressItem, addressType });
    const inventoryId = inventory?.id;

    const params = `${queryStringForAddressType}&task_type_id=${taskTypeId}${
      autoAssign ? "" : `&assignee_id=${assigneeId}`
    }&inventory_id=${inventoryId}&auto_assign=${autoAssign}&date=${calenderDate}${
      outOfZone ? "&out_of_zone=true" : ""
    }`;

    return params;
  };

  const fetchAppointments = async (params = "") => {
    try {
      setAppointmentsLoading(true);
      setError("");
      const res = await fetchTimeSlots(params);
      if (res) {
        // addressType === TASK_MEETING_TYPES.BRANCHES && setScheduledCount(res.scheduled_count);
        setAppointmentsLoading(false);
        if (res.success && res.appointments) {
          const st = res?.appointment_statuses?.find(e => e?.slug === "scheduled");
          setAppointments([
            ...newAppointments.map(e => ({
              ...e,
              start: setTimeZone(timeZone, e.start_time),
              end: setTimeZone(timeZone, e.end_time),
              status: st,
              id: generateUUID(),
              title: "Event",
              new: true,
            })),
            ...res.appointments.map(e => ({
              ...e,
              title: e?.area?.name,
              start: setTimeZone(timeZone, e.start_time),
              end: setTimeZone(timeZone, e.end_time),
            })),
          ]);
          setStatuses(res.appointment_statuses);
          const fetchedSlots = mergeContiguousTimeSlots(res.available_slots);
          setDayAvailableSlots(fetchedSlots);
        } else {
          setError(getErrorString(res));
          Toast.error(getErrorString(res), { autoClose: 3000 });
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const setDayAvailableSlots = (slots = []) => {
    handleDefaultAvailableSlots(slots, calenderDate, setAvailableSlots);
  };

  const onSelectSlot = async event => {
    // const eventInAppointments = filteringEvents(event, appointments);
    const eventInAppointments = false;
    const eventInAvailableTime = filteringAvailableTime(event, availableSlots);
    if ((event.action == "select" || event.action == "click") && !eventInAppointments && eventInAvailableTime) {
      setSelectedEvent(event);
      pushAppointment(event);
      const { task_type } = getValues();
      const queryString = `${getQueryStringForSelectedAddressType({
        addressType,
        addressItem,
      })}&task_type_id=${task_type?.id}&start_time=${appendTimeZone(event.start, timeZone)}&end_time=${appendTimeZone(
        subtractSeconds(event.end),
        timeZone
      )}${getValues().newMeeting.id ? `&appointment_id=${getValues().newMeeting.id}` : ""}${
        getValues()?.language ? `&language_preference=${getValues()?.language}` : ""
      }`;

      const appointedUser =
        getPermission("TimeSlot", "available_user") && getValues()?.newMeeting?.autoAssign === true
          ? await getAutoAssignedUser(queryString)
          : {};

      if (appointedUser?.user || getValues().assignee?.id) {
        setShowModal({
          data: appointedUser?.user,
          bodyText: props.isMeetingRescheduled
            ? `${strings.add_reschedule_event_confirmation}
            ${moment(event?.slots?.[0]).format("MMM DD, hh:mm A")} -
            ${moment(event?.slots?.[event?.slots?.length - 1]).format("MMM DD, hh:mm A")}?`
            : `${strings.add_event_confirmation}
            ${moment(event?.slots?.[0]).format("MMM DD, hh:mm A")} -
            ${moment(event?.slots?.[event?.slots?.length - 1]).format("MMM DD, hh:mm A")}?`,
          title: props.isMeetingRescheduled ? strings.reschedule_meeting_confirmation : strings.meeting_confirmation,
        });
      } else {
        setShowModal({
          data: null,
          bodyText: "Cannot auto-assign, user is not available, please assign the meeting manually",
          title: "Auto-Assignment Error",
        });
      }
    } else {
      Toast.error(!eventInAvailableTime ? strings.slot_not_available_msg : strings.calender_error);
    }
  };

  const pushAppointment = event => {
    appointments.push({
      start: event.start,
      end: event.end,
      id: generateUUID(),
      title: "Event",
      new: true,
      status: { color: "#E65F41", bg_color: "#FCEDD5" },
    });
    setAppointments(appointments);
  };

  const popAppointment = () => {
    appointments.pop();
    setAppointments(appointments);
  };

  const onConfirm = () => {
    const { newMeeting, assignee } = getValues();
    const assigneeData = showModal?.data && getValues()?.newMeeting?.autoAssign === true ? showModal?.data : assignee;
    const userNotAvailable = !showModal?.data && newMeeting.autoAssign === true;
    const meetingData = {
      ...newMeeting,
      start_time: selectedEvent.start,
      end_time: selectedEvent.end,
      ...(userNotAvailable
        ? {
            start_time: "",
            end_time: "",
          }
        : {}),
      ...(!showModal?.data ? { autoAssign: false } : {}),
    };

    onDoneMeeting(meetingData, assigneeData);
  };

  const filteringAvailableTime = (event, list) => {
    const start = appendTimeZone(event.start, timeZone);
    let end = moment(event.end).subtract(1, "seconds");
    end = appendTimeZone(end, timeZone);
    const isValidTime = availableSlot => {
      const { start_time, end_time } = availableSlot;
      const isValidStartTime = moment(start_time).isSame(start) || moment(start).isBetween(start_time, end_time);
      const isValidEndTime = moment(end).isBetween(start_time, end_time) || moment(end).isSame(end_time);
      return isValidStartTime && isValidEndTime;
    };
    return list.some(availableSlot => {
      return isValidTime(availableSlot);
    });
  };

  return (
    <>
      <IF
        condition={[addressType === TASK_MEETING_TYPES.BRANCHES, scheduledCount, addressItem.capacity].every(
          value => Boolean(value) === true
        )}
      >
        <span>{`Available Slots: ${scheduledCount}/${addressItem.capacity}`}</span>
      </IF>
      <div className="custom-event-class">
        <Calendar
          selectable
          events={appointmentsLoading ? creatingSlotsForSkeleton("day", date) : appointments}
          style={{ "--calendar-height": `calc(100vh - ${legendsHeight}px - 247px)` }}
          slotGroupPropGetter={() => ({
            style: { minHeight: 80 },
          })}
          scrollToTime={setTimeZone(timeZone, slotsTimeRange[0]?.start_time)}
          onNavigate={(date, view) => setCalenderDate(moment(date))}
          onView={view => setDateView(view)}
          onSelectSlot={!appointmentsLoading ? onSelectSlot : () => {}}
          localizer={momentLocalizer(moment)}
          resizable
          timeslots={4}
          step={15}
          defaultView={Views.DAY}
          defaultDate={date ? new Date(date) : new Date()}
          views={["day"]}
          components={{
            toolbar: props => <CalenderModalHeader {...props} statuses={statuses} loading={appointmentsLoading} />,
            event: p => {
              return (
                <DayEvent
                  fromCalendarAssignEvent
                  calendarAssignEvent
                  event={p.event}
                  view={dateView}
                  showPopup
                  loading={appointmentsLoading}
                  associatedId={taskId}
                />
              );
            },
            timeGutterHeader,
            ...{
              timeSlotWrapper: timeSlotWrapper({ availableSlots, timeZone }),
            },
          }}
          eventPropGetter={event => {
            return {
              ...event,
              style: {
                ...event.style,
                backgroundColor: event.status?.bg_color,
                borderColor: event.status?.color,
                color: event.status?.color,
              },
            };
          }}
        />
      </div>
      <Modal
        toggle={() => {
          if (showModal) popAppointment();
          setShowModal(!showModal);
        }}
        title={showModal?.title}
        open={showModal}
        body={() => <p>{showModal?.bodyText}</p>}
        actions={[
          ...(showModal?.data || getValues().assignee?.id
            ? [
                {
                  label: strings.cancel,
                  onClick: () => {
                    popAppointment();
                    setShowModal(false);
                  },
                  color: "secondary",
                  className: "modal__cancel-btn btn-pill",
                },
              ]
            : []),
          {
            label: showModal?.data || getValues().assignee?.id ? strings.confirm : strings.done,
            color: showModal?.data || getValues().assignee?.id ? "primary" : "secondary",
            className: "modal__cr-btn btn-pill",
            onClick: () => onConfirm(),
          },
        ]}
      />
    </>
  );
};

const mapStateToProps = ({ Configs }) => {
  return {
    configuration: Configs.tenant.configuration,
  };
};

export default connect(mapStateToProps)(CalenderAssignEvent);

const timeSlotWrapper =
  props =>
  ({ children, value }) => {
    const { availableSlots, timeZone } = props;
    if (isEmptyArray(availableSlots)) {
      return React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
          ...styles.disabledSlot,
        },
      });
    }
    const isAvailable = filterAvailableTimes({
      date: value,
      availableSlots,
      timeZone,
    });
    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        ...(!isAvailable && styles.disabledSlot),
      },
    });
  };

const timeGutterHeader = () => (
  <div className="d-flex justify-content-center align-items-center" style={{}}>
    <strong>{strings.all_day}</strong>
  </div>
);

const getQueryStringForSelectedAddressType = ({ addressItem, addressType }) => {
  const queryString = {
    [TASK_MEETING_TYPES.SELLER_ADDRESS]: `?seller_address_id=${addressItem.seller_address_id}`,
    [TASK_MEETING_TYPES.AREA]: `?area_id=${addressItem.id}`,
  };

  return queryString[addressType] || "";
};

const filterDefaultAvailableTimes = (newDate, availableSlots) => {
  if (!availableSlots.length) return false;
  return availableSlots.some(
    item =>
      moment(newDate).isSame(item.start_time) ||
      (moment(newDate).isBefore(item.end_time) && moment(newDate).isAfter(item.start_time))
  );
};
const filterAvailableTimes = props => {
  const { date, availableSlots, timeZone } = props;
  const newDate = appendTimeZone(date, timeZone);
  return filterDefaultAvailableTimes(newDate, availableSlots);
};
const handleDefaultAvailableSlots = (slots, calenderDate, setAvailableSlots) => {
  const formattedDate = calenderDate.format("YYYY-MM-DD");
  slots &&
    setAvailableSlots(
      slots.map(
        item =>
          item && {
            ...item,
            start_time: formattedDate + item.start_time.substring(item.start_time.indexOf("T")),
            end_time: formattedDate + item.end_time.substring(item.end_time.indexOf("T")),
          }
      )
    );
};
const styles = {
  disabledSlot: {
    backgroundColor: "lightgray",
    cursor: "not-allowed",
    borderTop: 0,
    opacity: 0.55,
  },
};
