import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";
import React from "react";

const DynamicDateSelect = props => {
  const { defaultText, label, errorMsg, id, textRight = true, isEditable = null, horizontal = true, ...rest } = props;
  return (
    <div className={`form-group ${horizontal ? "row" : ""}`} id={id ? id : ""}>
      <label className={`${horizontal ? "col-sm-3 col-form-label" : ""} ${textRight ? "text-right" : ""}`}>
        {label}
      </label>
      <div className={`${horizontal ? "col-sm-6" : ""}`}>
        <DatePicker
          readOnly={isEditable === false}
          disabled={isEditable === false}
          className="form-control digits"
          id="exampleFormControlSelect3"
          autoComplete="off"
          shouldCloseOnSelect
          {...rest}
        />
        {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
      </div>
    </div>
  );
};

export default DynamicDateSelect;
