import React, { Fragment, useEffect, useState } from "react";
import { components } from "react-select";
import { getErrorString, makeListToSelectData } from "../../helpers/Util";
import { FiSearch, MdPerson } from "../svg-icon/svg-icons";
import { strings } from "../../constant/strings";
import { Avatar } from "./Avatar";
import cx from "clsx";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import UserOption from "./UserOption";
import FormRow from "./FormRow";
import Toast from "./Toast";
import { useThrottle } from "../../hooks/useThrottle";

export const PaginatedSearch = props => {
  const {
    className,
    defaultSelected,
    noSelectValue,
    direction = "down",
    infoView = true,
    showLabel = true,
    avatarSize = "28",
    selectHeading = false,
    labelUser,
    textRight,
    horizontal,
    rowClass,
    filterCurrentUser,
  } = props;
  const [selectedValue, setSelectedValue] = useState(defaultSelected);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setSelectedValue(defaultSelected);
  }, [defaultSelected]);

  const Menu = menuProps => {
    return (
      <Fragment>
        <components.Menu {...menuProps}>
          <div>{menuProps.children}</div>
        </components.Menu>
      </Fragment>
    );
  };

  const Option = props => {
    return (
      <Fragment>
        <components.Option {...props}>{props.children}</components.Option>
      </Fragment>
    );
  };

  const NoOptionsMessage = props => {
    return (
      <Fragment>
        <components.NoOptionsMessage {...props}>
          <p> {strings.no_result_found} </p>
        </components.NoOptionsMessage>
      </Fragment>
    );
  };
  const DropdownIndicator = () => <FiSearch />;

  const handleSelectChange = (e, action) => {
    if (props.updateDataApi && props.id) {
      postData(e);
      return;
    }
    setSelectedValue(e);
    e && props.onChange && props.onChange(e);
    setDropdownOpen(false);
  };

  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const toggle = () => setDropdownOpen(prevState => !prevState);
  const value = selectedValue;

  const handleScroll = () => {
    setDropdownOpen(false);
  };

  const throttledScrollFn = useThrottle({
    cb: handleScroll,
    delay: 1000,
  });

  async function loadOptions(search, prevOptions = [], { page }) {
    if (hasError) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    await sleep(300);

    try {
      const res = await props.fetchOptions(search, page);
      if (res?.success) {
        const { pagination } = res;
        const hasNextPage = !!pagination?.next_page;
        let usersList = res?.available_users ? [...res?.available_users] : [...res?.users];

        if (filterCurrentUser) {
          usersList = usersList.filter(user => user.id !== filterCurrentUser);
        }

        return {
          options: makeListToSelectData(usersList, "", true),
          hasMore: hasNextPage,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 0,
          },
        };
      }
    } catch (error) {
      console.error("Error loading options:", error);
      setHasError(true);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
  }

  const postData = async e => {
    let obj;
    if (props.modelKey) {
      obj = {
        [props.modelKey]: {
          [props.payloadKey]: e.id,
        },
      };
    } else {
      obj = {
        [props.payloadKey]: e.id,
      };
    }
    setDropdownOpen(false);
    const response = await props.updateDataApi({ id: props.id, data: obj });
    if (response) {
      if (!props?.modelKey) {
        if (response.success) {
          if (props.onSuccess) {
            props.onSuccess(e);
            Toast.success(response.message);
          }
        } else {
          Toast.error(getErrorString(response));
        }
      }

      if (response[props.modelKey]) {
        props.onSuccess && props.onSuccess(e, response[props.modelKey]);
        setSelectedValue(e);
      } else {
        if (props?.modelKey) {
          Toast.error(getErrorString(response));
        }
      }
    }
  };

  useEffect(() => {
    // Attach a scroll event listener to the document
    document.addEventListener("scroll", throttledScrollFn);
    return () => {
      document.removeEventListener("scroll", throttledScrollFn);
    };
  }, []);

  return (
    <>
      <FormRow horizontal={horizontal} label={props.label} textRight={textRight} containerClassName={rowClass}>
        <div className="d-inline-flex">
          <Dropdown
            className={cx(
              `search-assignee dropdown-status ${
                props.disabled ? "" : "custom-field-state"
              } d-flex align-items-center b-r-6`,
              horizontal ? "mt-1" : ""
            )}
            isOpen={dropdownOpen}
            toggle={toggle}
            direction={direction}
            disabled={props.disabled}
          >
            <DropdownToggle
              className="d-flex align-items-center py-0 pl-0"
              caret={props.disabled ? false : true}
              color="skeleton"
              // onClick={() => fetchOptions(1)}
              style={{ cursor: props.disabled ? "default" : "pointer" }}
            >
              <div className="d-flex align-items-center">
                {!value && !infoView ? (
                  <>
                    <div className="media-icon simple bordered">
                      <MdPerson />
                    </div>
                    {selectHeading || "Select Assignee"}
                  </>
                ) : !value && infoView ? (
                  <div
                    className={cx(
                      "d-flex spbwx8 info-block",
                      showLabel ? "align-items-end" : "mb-0 align-items-center"
                    )}
                  >
                    <div className="media-icon simple bordered">
                      <MdPerson />
                    </div>
                    <div className="d-flex flex-column align-items-start">
                      {showLabel && <div className="info-block-title mb-1">{labelUser || strings.assigned_to}</div>}
                      <div> {selectHeading || "Select Assignee"}</div>
                    </div>
                  </div>
                ) : value && infoView ? (
                  <div
                    className={cx(
                      "d-flex spbwx8 info-block",
                      showLabel ? "align-items-end" : "mb-0 align-items-center"
                    )}
                  >
                    <Avatar
                      avatarOnly={true}
                      name={value?.name || value?.first_name || value?.full_name}
                      imgSrc={value?.picture?.url}
                      size={avatarSize}
                    />
                    <div className="d-flex flex-column align-items-start">
                      {showLabel && <div className="info-block-title mb-1">{labelUser || strings.assigned_to}</div>}
                      <div> {value?.name || value?.first_name || value?.full_name}</div>
                    </div>
                  </div>
                ) : (
                  <Avatar name={value?.name || value?.first_name} size={avatarSize} imgSrc={value?.picture?.url} />
                )}
              </div>
            </DropdownToggle>
            <DropdownMenu container="body" className="selectDropdown">
              <AsyncPaginate
                value={props.selected}
                additional={{
                  page: 1,
                }}
                debounceTimeout={300}
                loadOptionsOnMenuOpen={false}
                loadOptions={loadOptions}
                onChange={handleSelectChange}
                menuIsOpen={dropdownOpen}
                placeholder="Search..."
                classNamePrefix="select"
                styles={selectStyles}
                isClearable={!props.disabled}
                onInputChange={props?.onInputChange}
                label={props.label}
                defaultSelected={props.defaultSelected}
                className={className}
                backspaceRemovesValue={false}
                components={{ Menu, Option, NoOptionsMessage, DropdownIndicator }}
                isMulti={!props.isSingleSelect}
                controlShouldRenderValue={!noSelectValue}
                formatOptionLabel={option => (
                  <Fragment key={option.id}>
                    <UserOption value={option} />
                  </Fragment>
                )}
              />
            </DropdownMenu>
          </Dropdown>
        </div>
        {!!props.errorMsg && <p className="invalid-error">{props.errorMsg}</p>}
      </FormRow>
    </>
  );
};

const selectStyles = {
  valueContainer: provided => ({
    ...provided,
    minWidth: "220px",
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    borderColor: "#efefef",
    margin: "0.5rem",
  }),
  indicatorsContainer: provided => ({
    ...provided,
    width: "2rem",
    justifyContent: "center",
    fontSize: "1.125rem",
    color: "#919191",
  }),
  menu: provided => ({
    ...provided,
    width: "100%",
    position: "static",
    marginBottom: "0",
    borderRadius: "0 0 4px 4px",
    border: "1px solid #efefef",
    boxShadow: "none",
    overflow: "hidden",
  }),
  menuList: provided => ({
    ...provided,
  }),
};
