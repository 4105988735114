import { ADD_USER, FETCH_USERS, UPDATE_USER, UPDATE_USER_ROLE, SET_USERS_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_USER:
      return {
        ...state,
        list: [action.payload.item].concat([...state.list]),
      };
    case UPDATE_USER:
      const userList = state.list;
      let index = userList.findIndex(e => e.id === action.payload.item.id);
      userList.splice(index, 1, { ...action.payload.item });
      return {
        ...state,
        list: [...userList],
      };

    case UPDATE_USER_ROLE:
      const usersList = state.list;
      let user = usersList.find(e => e.id === action.payload.item.id);
      let userIndex = usersList.findIndex(e => e.id === action.payload.item.id);
      usersList[userIndex] = { ...user, roles: [...action.payload.item.roles] };
      return {
        ...state,
        list: [...usersList],
      };

    case FETCH_USERS:
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
        list: action.payload.mergeList ? [...state.list].concat([...action.payload.list]) : [...action.payload.list],
        pagination: action.payload?.pagination ? { ...action.payload.pagination } : { ...state.pagination },
        restrictions: action.payload.restrictions,
        permittedMeta: action.payload.permittedMeta,
      };

    case SET_USERS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
