import { DUPLICATE_SEARCH_CRITERIA_KEYS } from "constant/appConstants";
import { strings } from "./strings";

export const carEngineType = ["petrol", "cng", "hybrid", "diesel", "lpg", "ev"];

export const carTransmission = ["automatic", "manual"];
export const carAssembly = ["local", "imported"];

export const inspectionLeadClassificationList = [
  {
    id: 1,
    slug: "hot",
    name: "Hot",
  },
  {
    id: 2,
    slug: "moderate",
    name: "Moderate",
  },
  {
    id: 3,
    slug: "cold",
    name: "Cold",
  },
];
export const inspectionScheduleTypes = [
  { id: 1, slug: "area", label: "Area", name: "Area" },
  { id: 2, slug: "branch", label: "Branch", name: "Branch" },
  { id: 3, slug: "warehouse", label: "Warehouse", name: "Warehouse" },
];
export const reportPresentList = [
  { id: 1, slug: "notnull", label: "Yes", name: "Yes", filterChipLabel: "Report Present" },
  { id: 2, slug: "null", label: "No", name: "No", filterChipLabel: "Report Not Present" },
];

export const autoCalculateCapacityList = [
  { id: 1, slug: "true", label: "Yes", name: "Yes", filterChipLabel: "Auto Calculated Capacity" },
  { id: 2, slug: "false", label: "No", name: "No", filterChipLabel: "Manually Calculated Capacity" },
];

export const autoCalculateShowUpRateList = [
  { id: 1, slug: "true", label: "Yes", name: "Yes", filterChipLabel: "Auto Calculated Show up Rate" },
  { id: 2, slug: "false", label: "No", name: "No", filterChipLabel: "Manually Calculated Show up Rate" },
];

export const offerExtensionThreshold = [
  {
    slug: 60,
    label: "Less than 1 minute",
  },
  {
    slug: 180,
    label: "Less than 3 minute",
  },
  {
    slug: 300,
    label: "Less than 5 minute",
  },
  {
    slug: 600,
    label: "Less than 10 minute",
  },
];

export const offerExtensionPeriod = [
  {
    slug: 30,
    label: "0.5 minute",
  },
  {
    slug: 60,
    label: "1 minute",
  },
  {
    slug: 120,
    label: "2 minutes",
  },
  {
    slug: 300,
    label: "5 minutes",
  },
  {
    slug: 600,
    label: "10 minutes",
  },
  {
    slug: 900,
    label: "15 minutes",
  },
  {
    slug: 1800,
    label: "Half hour",
  },
  {
    slug: 3600,
    label: "1 hour",
  },
];

export const activeList = [
  { id: true, name: "Active" },
  { id: false, name: "Inactive" },
];

export const verifiedList = [
  { id: true, name: "Verified" },
  { id: false, name: "Not Verified" },
];

export const extensionCountList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 50, 100].map(e => ({
  slug: e,
  label: e,
}));

export const tokenExpiryList = [
  {
    slug: 2629746,
    label: "1 month",
  },
  {
    slug: 7889238,
    label: "3 month",
  },
  {
    slug: 15778476,
    label: "6 month",
  },
  {
    slug: 31556952,
    label: "1 Year",
  },
];

export const durationList = [
  { slug: 1800, label: "30 minutes" },
  {
    slug: 2700,
    label: "45 minutes",
  },
  { slug: 3600, label: "60 minutes" },
  {
    slug: 5400,
    label: "90 minutes",
  },
  {
    slug: 7200,
    label: "120 minutes",
  },
];
export const classificationList = [
  { id: 1, name: "Very Hot" },
  { id: 2, name: "Hot" },
  { id: 3, name: "Moderate" },
  { id: 4, name: "Cold" },
];

export const priceList = [
  { slug: 0, label: "0 K" },
  { slug: 20000, label: "20 K" },
  { slug: 40000, label: "40 K" },
  { slug: 60000, label: "60 K" },
  { slug: 80000, label: "80 K" },
  { slug: 100000, label: "100 K" },
  { slug: 150000, label: "150 K" },
  { slug: 200000, label: "200 K" },
  { slug: 250000, label: "250 K" },
  { slug: 300000, label: "300 K" },
  { slug: 350000, label: "350 K" },
  { slug: 400000, label: "400 K" },
  { slug: 450000, label: "450 K" },
  { slug: 500000, label: "500 K" },
  { slug: 1000000, label: "1,000 K" },
  { slug: 1500000, label: "1,500 K" },
  { slug: 2000000, label: "2,000 K" },
  { slug: 2500000, label: "2,500 K" },
  { slug: 3000000, label: "3,000 K" },
  { slug: 3500000, label: "3,500 K" },
];

export const inspectionScoreList = [
  { slug: 1, label: "1" },
  { slug: 2, label: "2" },
  { slug: 3, label: "3" },
  { slug: 4, label: "4" },
  { slug: 5, label: "5" },
  { slug: 6, label: "6" },
  { slug: 7, label: "7" },
  { slug: 8, label: "8" },
  { slug: 9, label: "9" },
  { slug: 10, label: "10" },
];

export const paymentMethodList = [
  { id: 1, slug: "Bank Transfer", name: "Bank Transfer" },
  { id: 2, slug: "Cash", name: "Cash" },
  { id: 3, slug: "Adyen Payment Link", name: "Adyen Payment Link" },
  { id: 4, slug: "Adyen Payment Manual", name: "Adyen Payment Manual" },
];

export const weekdaysList = [
  {
    label: strings.monday,
    slug: "mon",
  },
  {
    label: strings.tuesday,
    slug: "tue",
  },
  {
    label: strings.wednesday,
    slug: "wed",
  },
  {
    label: strings.thursday,
    slug: "thu",
  },
  {
    label: strings.friday,
    slug: "fri",
  },
  {
    label: strings.saturday,
    slug: "sat",
  },
  {
    label: strings.sunday,
    slug: "sun",
  },
];

export const crmLeadCategory = [
  { id: "all", name: strings.all },
  { id: "seller", name: strings.seller },
  { id: "buyer", name: strings.buyer },
];

export const locationTypes = [
  { id: 0, slug: "branch", name: "Branch" },
  { id: 2, slug: "warehouse", name: "Warehouse" },
];

export const contentMapping = [
  "application/pdf",
  "text/csv",
  "text/plain",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "image/jpeg",
  "image/png",
  "application/json",
  "image/svg+xml",
  "application/xml",
  ".heic",
];

export const contentMappingForImages = ["image/jpeg", "image/png"];

export const dynamicFilterTypeBooleanList = [
  {
    id: 0,
    name: strings.yes,
    slug: true,
  },
  {
    id: 1,
    name: strings.no,
    slug: false,
  },
];

export const isAssignedFilterList = [
  { id: 1, name: strings.assigned, slug: "notnull" },
  { id: 2, name: strings.not_assigned, slug: "null" },
];
export const isExternalFilterList = [
  { id: 1, name: strings.yes, slug: "true" },
  { id: 2, name: strings.no, slug: "false" },
];

export const isExternalIdPresentList = [
  { id: 1, name: strings.yes, slug: "true" },
  { id: 2, name: strings.no, slug: "false" },
];

export const timeKeys24Hour = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
export const timeKeys = [
  "00:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM",
];

export const RotatimeKeys = [
  "00:00 AM",
  "00:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];
export const crmInventoryAdOptions = [
  {
    fieldKey: "makeModalData",
    fieldKeyOptionsString: "data.category.choices",
  },
  {
    fieldKey: "motorsTrimData",
    fieldKeyOptionsString: "data.motors_trim.choices",
  },
  {
    fieldKey: "regionalSpecData",
    fieldKeyOptionsString: "data.regional_specs.choices",
  },
  {
    fieldKey: "yearData",
    fieldKeyOptionsString: "data.year.choices",
  },
  {
    fieldKey: "fuelData",
    fieldKeyOptionsString: "data.fuel_type.choices",
  },
  {
    fieldKey: "bodyConditionData",
    fieldKeyOptionsString: "data.body_condition.choices",
  },
  {
    fieldKey: "mechanicalConditionData",
    fieldKeyOptionsString: "data.mechanical_condition.choices",
  },
  {
    fieldKey: "colorData",
    fieldKeyOptionsString: "data.color.choices",
  },
  {
    fieldKey: "warrantyData",
    fieldKeyOptionsString: "data.warranty.choices",
  },
  {
    fieldKey: "doorsData",
    fieldKeyOptionsString: "data.doors.choices",
  },
  {
    fieldKey: "cylindersData",
    fieldKeyOptionsString: ["data", "no._of_cylinders", "choices"],
  },
  {
    fieldKey: "transmissionData",
    fieldKeyOptionsString: "data.transmission_type.choices",
  },
  {
    fieldKey: "bodyTypeData",
    fieldKeyOptionsString: "data.body_type.choices",
  },
  {
    fieldKey: "hpData",
    fieldKeyOptionsString: "data.horsepower.choices",
  },
  {
    fieldKey: "steeringSideData",
    fieldKeyOptionsString: "data.steering_side.choices",
  },
];

export const optionsKeyData = [
  {
    fieldKey: "engineCapacityData",
    fieldKeyOptionsString: "engine_capacity_(cc).choices",
  },
  {
    fieldKey: "seatingCapacityData",
    fieldKeyOptionsString: "seating_capacity.choices",
  },
  {
    fieldKey: "targetMarketData",
    fieldKeyOptionsString: "target_market.choices",
  },
  {
    fieldKey: "emirateData",
    fieldKeyOptionsString: "emirates",
  },
  {
    fieldKey: "neighbourhoodData",
    fieldKeyOptionsString: "neighbourhood",
  },
  {
    fieldKey: "categoryData",
    fieldKeyOptionsString: "category.choices",
  },
  {
    fieldKey: "motorsTrimData",
    fieldKeyOptionsString: "motors_trim.choices",
  },
  {
    fieldKey: "regionalSpecData",
    fieldKeyOptionsString: "regional_specs.choices",
  },
  {
    fieldKey: "yearData",
    fieldKeyOptionsString: "year.choices",
  },
  {
    fieldKey: "fuelData",
    fieldKeyOptionsString: "fuel_type.choices",
  },
  // {
  //   fieldKey: "bodyConditionData",
  //   fieldKeyOptionsString: "body_condition.choices",
  // },
  // {
  //   fieldKey: "mechanicalConditionData",
  //   fieldKeyOptionsString: "mechanical_condition.choices",
  // },
  {
    fieldKey: "exteriorColorData",
    fieldKeyOptionsString: "exterior_color.choices",
  },

  {
    fieldKey: "interiorColorData",
    fieldKeyOptionsString: "interior_color.choices",
  },

  {
    fieldKey: "warrantyData",
    fieldKeyOptionsString: "warranty.choices",
  },
  {
    fieldKey: "doorsData",
    fieldKeyOptionsString: "doors.choices",
  },
  {
    fieldKey: "cylindersData",
    fieldKeyOptionsString: ["no._of_cylinders", "choices"],
  },
  {
    fieldKey: "transmissionData",
    fieldKeyOptionsString: "transmission_type.choices",
  },
  {
    fieldKey: "bodyTypeData",
    fieldKeyOptionsString: "body_type.choices",
  },
  {
    fieldKey: "hpData",
    fieldKeyOptionsString: "horsepower.choices",
  },
  {
    fieldKey: "steeringSideData",
    fieldKeyOptionsString: "steering_side.choices",
  },
  {
    fieldKey: "autoOptionsData",
    fieldKeyOptionsString: "auto_options.choices",
  },
  {
    fieldKey: "autoOptionsRecommendedData",
    fieldKeyOptionsString: "auto_options_recommended.choices",
  },
  {
    fieldKey: "autopilotData",
    fieldKeyOptionsString: "auto_pilot.choices",
  },
  {
    fieldKey: "autopilotTypeData",
    fieldKeyOptionsString: "autopilot_type.choices",
  },
  {
    fieldKey: "batteryCapacityData",
    fieldKeyOptionsString: "battery_capacity_(kwh).choices",
  },
  {
    fieldKey: "rangeData",
    fieldKeyOptionsString: "range.choices",
  },
  {
    fieldKey: "chargingTimeData",
    fieldKeyOptionsString: "charging_time.choices",
  },
];

export const ExternalIdPresentList = [
  { id: 1, slug: "false", label: "Yes", name: "Yes" },
  { id: 2, slug: "true", label: "No", name: "No" },
];

export const typeList = [
  { id: 1, slug: "make", label: "Make", name: "Make" },
  { id: 2, slug: "model", label: "Model", name: "Model" },
  { id: 3, slug: "variant", label: "Variant", name: "Variant" },
];

export const followUpTypeList = [
  { id: 1, slug: "Whatsapp", label: "Whatsapp", name: "Whatsapp" },
  { id: 2, slug: "Call", label: "Call", name: "Call" },
  { id: 3, slug: "Email", label: "Email", name: "Email" },
  { id: 4, slug: "In Person", label: "In Person", name: "In Person" },
];

export const outComeStatusList = [
  { id: 1, name: "Unset", slug: "null" },
  { id: 2, name: "Set", slug: "notnull" },
];

export const refreshOptions = [
  {
    id: 0,
    name: "Refresh Off",
    label: "Refresh Off",
    value: false,
    slug: "refresh-off",
  },
  {
    id: 1,
    name: "1 min",
    label: "1 min",
    value: 1,
    slug: "1min",
  },
  {
    id: 2,
    name: "3 min",
    label: "3 min",
    value: 3,
    slug: "3min",
  },
  {
    id: 3,
    name: "5 min",
    label: "5 min",
    value: 5,
    slug: "5min",
  },
];

export const pageLimitOptions = [20, 30, 40, 50];

export const binaryOptionsList = [
  { id: 1, name: strings.yes, slug: "yes" },
  { id: 2, name: strings.no, slug: "no" },
];

export const sortByOptions = [
  {
    id: 1,
    name: "Newest to Oldest",
    slug: "FIFO",
  },
  {
    id: 2,
    name: "Oldest to Newest",
    slug: "LIFO",
  },
];

export const availableLeadStatuses = ["in-progress", "converted", "new"];

export const availableInventoryStatuses = ["available", "on-hold", "pending"];

export const vatApplicableList = [
  { id: 1, name: strings.full_vat_applicable, slug: "no" },
  { id: 2, name: strings.profit_margin_scheme, slug: "yes" },
];

export const QC_STATUS_LIST = [
  { id: 1, name: strings.evaluation_done, slug: "evaluation_done" },
  { id: 2, name: strings.unevaluated_call, slug: "unevaluated_call" },
];

export const adPostKeys = [
  // { externalKey: "emirate", localKey: "inventory.city.name", formikKey: "city.name" },
  // { externalKey: "regional_specs", localKey: "assembly.name", formikKey: "assembly.name" },
  // { externalKey: "motors_trim", localKey: "variant.name", formikKey: "variant.name" },
  // { externalKey: "fuel_type", localKey: "engine", formikKey: "engineType" },
  // { externalKey: "transmission_type", localKey: "transmission", formikKey: "transmission" },
  // { externalKey: "body_type", localKey: "body_type.name", formikKey: "bodyType.name" },
  // { externalKey: "kilometers", localKey: "mileage", formikKey: "mileage" },
  // { externalKey: "year", localKey: "model_year", formikKey: "year" },
  // { externalKey: "exterior_color", localKey: "exterior_color.name", formikKey: "exteriorColor.name" },
  // {
  //   externalKey: "interior_color",
  //   localKey: "dynamic_attributes.car.interior_colour",
  //   formikKey: "car_dynamic_interior_colour.value",
  // },
  // {
  //   externalKey: "warranty",
  //   localKey: "dynamic_attributes.car.warranty[0]",
  //   formikKey: "car_dynamic_warranty[0].name",
  // },
  // {
  //   externalKey: "seating_capacity",
  //   localKey: "dynamic_attributes.car.number_of_seats[0]",
  //   formikKey: "car_dynamic_number_of_seats[0].name",
  // },
  // {
  //   externalKey: "no_of_cylinders",
  //   localKey: "dynamic_attributes.car.car_cylinders[0]",
  //   formikKey: "car_dynamic_car_cylinders[0].name",
  // },
  { externalKey: "price", localKey: "inventory.ask_price", formikKey: "demandPrice" },
];
export const qcCallTypeList = [
  { id: 1, name: strings.call_type_inbound, slug: "inbound" },
  { id: 2, name: strings.call_type_outbound, slug: "outbound" },
];

export const testDriveCicoList = [
  "terms_agreement",
  "driving_license_no_cico",
  "driving_license_expiry_dates_cico",
  "mileage_cico",
  "customer_signature_cico",
];

export const CICO_PICTURES_LIST = [
  "front_picture",
  "rear_picture",
  "left_picture",
  "right_picture",
  "vin_plate_picture",
  "keys_picture",
  "odometer_picture",
];

export const chevronRefreshOptions = [
  {
    id: 0,
    name: "Refresh Off",
    label: "Refresh Off",
    value: false,
    slug: "refresh-off",
  },
  {
    id: 1,
    name: "30 sec",
    label: "30 sec",
    value: 0.5,
    slug: "30sec",
  },
  {
    id: 2,
    name: "1 min",
    label: "1 min",
    value: 1,
    slug: "1min",
  },
  {
    id: 3,
    name: "3 min",
    label: "3 min",
    value: 3,
    slug: "3min",
  },
  {
    id: 4,
    name: "5 min",
    label: "5 min",
    value: 5,
    slug: "5min",
  },
];

export const kittRecommendationFetchedList = [
  { id: 1, slug: "notnull", label: "Yes", name: "Yes", filterChipLabel: "KITT Fetched" },
  { id: 2, slug: "null", label: "No", name: "No", filterChipLabel: "KITT not Fetched" },
];

export const isAutoApprovedList = [
  { id: 1, slug: "true", label: "Yes", name: "Yes", filterChipLabel: "Auto Approved" },
  { id: 2, slug: "false", label: "No", name: "No", filterChipLabel: "Not Auto Approved" },
];

export const SEARCH_OPTIONS = [
  {
    id: 1,
    label: "Phone Number",
    name: "Phone Number",
    key: DUPLICATE_SEARCH_CRITERIA_KEYS.PHONE_NUMBER,
  },
  {
    id: 2,
    label: "VIN",
    name: "VIN",
    key: DUPLICATE_SEARCH_CRITERIA_KEYS.VIN,
  },
];

export const isExternalAppointmentUpdateList = [
  { id: 1, slug: "notnull", label: "Yes", name: "Yes", filterChipLabel: "Appointments Updated Automatically" },
  { id: 2, slug: "null", label: "No", name: "No", filterChipLabel: "Appointments Updated Manually" },
];

export const PaymentLinkStatuses = [
  { id: 1, slug: "active", label: "Active", name: "Active" },
  { id: 2, slug: "expired", label: "Expired", name: "Expired" },
];
