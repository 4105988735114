import * as yup from "yup";

import { CustomAlert, FormInput, FormSelect, Toast } from "components/common";
import {
  addPermittedProperty,
  getErrorString,
  isFieldEditable,
  isFieldViewable,
  makeListToSelectData,
} from "helpers/Util";
import React, { forwardRef, useImperativeHandle, useRef } from "react";

import { updateLeadPayment } from "actions/inspection/leadDetail.action";
import ImageUpload from "components/image-upload/ImageUpload";
import AmountCalculation from "components/inspection-payment/AmountCalculation";
import { ADYEN_PAYMENT_LINK } from "constant/appConstants";
import { paymentMethodList } from "constant/lists";
import { strings } from "constant/strings";
import { Formik } from "formik";
import { addPaymentApi } from "helpers/ApiHelper";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveColumns } from "reducers/general/selectors";

const AddPaymentForm = forwardRef((props, ref) => {
  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const dispatch = useDispatch();
  const addPaymentPermittedAttributes = activeColumnPermittedAttributes.Payment;

  const { lead, toggleAddPaymentLoading, setShowPaymentModal, setAdyenData } = props;

  let formikRef = useRef();

  useImperativeHandle(ref, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  const getInitialValues = () => {
    return {
      ...addPermittedProperty(addPaymentPermittedAttributes?.["payment_method"], {
        paymentMethod: "",
      }),

      ...(isFieldViewable(addPaymentPermittedAttributes?.["lead_type.price"]) &&
        isFieldViewable(addPaymentPermittedAttributes?.["discount"]) && {
          payment: lead?.lead_type?.price - (lead?.discount || 0),
        }),

      ...addPermittedProperty(addPaymentPermittedAttributes?.["comment"], {
        comments: "",
      }),

      ...addPermittedProperty(addPaymentPermittedAttributes?.["slip_url"], {
        depositSlip: [],
      }),
    };
  };

  const onSubmit = async (values, { setStatus }) => {
    try {
      if (values?.depositSlip?.[0]?.uploaded === false) return;
      setStatus({ message: "" });
      const paymentData = {
        ...addPermittedProperty(
          addPaymentPermittedAttributes?.["lead.id"],
          {
            lead_id: lead?.id,
          },
          true
        ),

        ...addPermittedProperty(
          addPaymentPermittedAttributes?.["payment_method"],
          {
            payment_method: values?.paymentMethod?.toString(),
          },
          true
        ),

        is_prepaid: true,

        ...addPermittedProperty(
          addPaymentPermittedAttributes?.["comment"],
          {
            comment: values?.comment,
          },
          true
        ),

        ...addPermittedProperty(
          addPaymentPermittedAttributes?.["discount"],
          {
            discount: lead?.discount,
          },
          true
        ),

        ...addPermittedProperty(
          addPaymentPermittedAttributes?.["slip_url"],
          {
            deposit_attributes: {
              slip_ids: [values?.depositSlip?.[0]?.id],
            },
          },
          true
        ),
      };

      toggleAddPaymentLoading(true);
      const response = await addPaymentApi(paymentData);
      if (response) {
        toggleAddPaymentLoading(false);
        if (values?.paymentMethod === ADYEN_PAYMENT_LINK.name) {
          const paymentLink = response?.payment?.payment_link;
          if (paymentLink?.url) {
            setAdyenData(prevData => ({
              ...prevData,
              adyenPaymentModal: true,
              response: response,
              id: response?.payment?.transaction_id,
              url: paymentLink?.url,
              status: paymentLink?.status,
            }));
          } else {
            Toast.error(strings.payment_link_not_generated_msg);
          }
        }
        if (response.payment) {
          dispatch(updateLeadPayment(response?.payment));
          setShowPaymentModal(false);
        } else {
          setStatus({
            message: getErrorString(response),
          });
        }
      }
    } catch (error) {
      error?.errors && error?.errors?.[0] && Toast.error(error?.errors?.[0]);
    }
  };

  const validationSchema = () => {
    return yup.object().shape({
      ...addPermittedProperty(addPaymentPermittedAttributes?.["payment_method"], {
        paymentMethod: yup.string().nullable().required(strings.select_payment_method),
      }),

      ...addPermittedProperty(addPaymentPermittedAttributes?.["slip_url"], {
        depositSlip: yup
          .array()
          .of(
            yup.object().shape({
              id: yup.string().required(strings.image_required),
            })
          )
          .when("paymentMethod", {
            is: paymentMethod => paymentMethod !== ADYEN_PAYMENT_LINK.name,
            then: yup.array().min(1, strings.image_required).required(strings.image_required),
            otherwise: yup.array().nullable(),
          }),
      }),
    });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, status, setFieldValue }) => (
        <form className="form theme-form db__form-main">
          {isFieldViewable(addPaymentPermittedAttributes?.["lead_type.price"]) &&
            isFieldViewable(addPaymentPermittedAttributes?.["discount"]) && (
              <FormInput
                label={strings.payment_to_be_received}
                placeholder={strings.enter_payment_received}
                onChange={handleChange("payment")}
                onBlur={handleBlur("payment")}
                value={values.payment}
                errorMsg={errors.payment && touched.payment && errors.payment}
                labelClassName="p-t-0"
                disabled={true}
              />
            )}

          {isFieldViewable(addPaymentPermittedAttributes?.["payment_method"]) && (
            <FormSelect
              label={strings.payment_method}
              placeholder={strings.select_payment_method}
              onChange={e => {
                handleChange("paymentMethod")(e);
              }}
              onBlur={handleBlur("paymentMethod")}
              value={values.paymentMethod}
              options={makeListToSelectData(paymentMethodList)}
              errorMsg={errors.paymentMethod && touched.paymentMethod && errors.paymentMethod}
              isEditable={isFieldEditable(addPaymentPermittedAttributes?.["payment_method"])}
            />
          )}

          {isFieldViewable(addPaymentPermittedAttributes?.["comment"]) && (
            <FormInput
              label={strings.comments}
              placeholder={strings.enter_your_comments}
              onChange={handleChange("comment")}
              onBlur={handleBlur("comment")}
              value={values.comment}
              errorMsg={errors.comment && touched.comment && errors.comment}
              linesCount="4"
              labelClassName="p-t-0"
              isEditable={isFieldEditable(addPaymentPermittedAttributes?.["comment"])}
            />
          )}

          {isFieldViewable(addPaymentPermittedAttributes?.["slip_url"]) &&
            values.paymentMethod !== ADYEN_PAYMENT_LINK.name && (
              <ImageUpload
                values={values.depositSlip}
                setFieldValue={value => setFieldValue("depositSlip", value)}
                errorMsg={errors.depositSlip && touched.depositSlip && errors.depositSlip}
                multiSelect={false}
                associationKey={"slip"}
                showButton
                imagesOnly
                isEditable={isFieldEditable(addPaymentPermittedAttributes?.["slip_url"])}
              />
            )}

          <AmountCalculation leadTypePrice={lead?.lead_type?.price} price={0} discount={lead?.discount} />
          {status && status.message && <CustomAlert message={status.message} />}
        </form>
      )}
    </Formik>
  );
});

export default AddPaymentForm;
