import React from "react";
import cx from "clsx";
import { useSelector } from "react-redux";
import { selectLeadsChevronOptions } from "../../../../reducers/crm/selectors";
import styles from "./ChevronLeads.module.scss";
import ChevronLeadColumn from "../chevron-lead-column/ChevronLeadColumn";
import { ChevronLeadColumnLoader } from "../chevron-loaders";

const ChevronLeads = ({ dispositionsDataLoading, leadsContainerStyle = {}, leadsContainerClassName = "" }) => {
  const chevronOptions = useSelector(selectLeadsChevronOptions);
  const { chevronLeadDispositions } = chevronOptions;

  if (dispositionsDataLoading) {
    return (
      <div
        className={cx(styles.leadsContainer, "container-fluid d-flex p-16 scroll-x", leadsContainerClassName)}
        style={{ gap: "10px", scrollbarWidth: "none" }}
      >
        {[10, 20, 30].map(item => (
          <ChevronLeadColumnLoader key={item} index={item} />
        ))}
      </div>
    );
  }

  return (
    <div
      className={cx(styles.leadsContainer, "container-fluid d-flex p-16 scroll-x")}
      style={{ gap: "10px", scrollbarWidth: "none", ...leadsContainerStyle }}
    >
      {chevronLeadDispositions?.map(leadDisposition => (
        <ChevronLeadColumn
          key={leadDisposition?.slug}
          dispositionSlug={leadDisposition?.slug}
          dispositionTitle={leadDisposition?.name}
        />
      ))}
    </div>
  );
};

export default ChevronLeads;
