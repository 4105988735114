import React, { useEffect } from "react";
import { Loader } from "../../../common/Loader";
import { Chip } from "../../../common";

const Image = ({ item, removeImage, uploadImage, title, url, isEditable }) => {
  useEffect(() => {
    item.uploaded === false && uploadImage(item);
  }, []);
  const handleRemoveImage = e => {
    e.preventDefault();
    removeImage(item);
  };

  if (item.uploaded === false) return <span className="fa fa-refresh fa-spin text-primary"></span>;

  return (
    <div className={`${url ? "hover-pointer" : ""}`}>
      <Chip
        disabled={isEditable === false}
        onClick={e => window.open(url, "_blank")}
        title={title}
        canClose={isEditable === true}
        onClose={handleRemoveImage}
        simpleView
      />
    </div>
  );
};
export default Image;
