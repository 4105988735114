import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "react-feather";
import React, { useEffect, useState } from "react";
import cx from "clsx";
import { convertQueryObjToString, isEmptyObject, mapQueryStringToFilterObject } from "../../helpers/Util";
import InfiniteScroll from "react-infinite-scroll-component";
import DataTable from "react-data-table-component";
import { RowSkeletons, TableLoader } from "./RowSkeleton";
import { useSelector, useDispatch } from "react-redux";
import { selectTenant } from "../../reducers/general/selectors";
import { UPDATE_LIMIT_PER_PAGE } from "../../constant/actionTypes";
import { pageLimitOptions } from "../../constant/lists";
import { useHistory } from "react-router-dom";

const getHasMore = page => {
  if (!page) return true;
  return !(page?.next_page === null);
};

const DataTableComponent = props => {
  const {
    tableData,
    columns,
    headerText,
    handlePagination,
    paginationObject,
    tableRootClass = "",
    fetch,
    queryParam,
    noUrlPush,
    loading,
    getDataTableList,
    scrollable,
    fetchItems,
    rootFiltersObj,
    borderBottomWidth = "0px",
    className = "",
    ...rest
  } = props;
  const [scrollList, setScrollList] = useState([]);
  const [page, setPage] = useState(null);
  const {
    next_page: next,
    current_page: current,
    prev_page: previous,
    total_count,
    total_pages,
  } = paginationObject || {};
  let dataTableList = [];
  if (scrollable) {
    dataTableList = getDataTableList(scrollList, scrollable);
  }
  const dispatch = useDispatch();
  const tenant = useSelector(selectTenant);
  const history = useHistory();
  const initialRender = loading && !current;

  let limitPerPage =
    mapQueryStringToFilterObject(history.location.search)?.queryObj?.limit ??
    (tenant?.listingRecordsPerPage && tenant?.listingRecordsPerPage?.[history?.location?.pathname]) ??
    tenant?.configuration?.records_per_page;

  const handlePerRowsChange = async (newPerPage, page) => {
    const { queryObj } = mapQueryStringToFilterObject(history.location.search);
    let searchParam = convertQueryObjToString({ ...queryObj, limit: newPerPage });

    dispatch({
      type: UPDATE_LIMIT_PER_PAGE,
      payload: {
        tenant: {
          ...tenant,
          listingRecordsPerPage: {
            ...tenant.listingRecordsPerPage,
            [history.location.pathname]: newPerPage,
          },
        },
      },
    });
    history.push({
      pathname: history.location.pathname,
      search: searchParam,
    });
  };

  const pagePush = (page, sorts = "") => {
    if (noUrlPush) {
      fetch(`${queryParam}page=${page}`);
    } else {
      let searchParam = history.location.search;
      const { queryObj } = mapQueryStringToFilterObject(history.location.search);
      queryObj.page = page;
      if (!!sorts) {
        Object.keys(queryObj).forEach(item => {
          if (item.includes("sort")) {
            delete queryObj[item];
          }
          queryObj[sorts.split(" ")[0]] = sorts.split(" ")[1];
        });
      }
      searchParam = convertQueryObjToString({ ...queryObj });
      history.push({
        pathname: history.location.pathname,
        search: searchParam,
      });
      window.scrollTo(0, 0);
    }
  };

  const getTableProps = () =>
    handlePagination
      ? {
          onChangeRowsPerPage: handlePerRowsChange,
          pagination: true,
          paginationServer: true,
          paginationPerPage: limitPerPage,
          paginationRowsPerPageOptions: pageLimitOptions,
          sortServer: true,
          onSort: (column, sortDirection, event) => {
            column.key && pagePush(1, `${column.key} ${sortDirection}`);
          },
          paginationIconFirstPage: (
            <ChevronsLeft
              size={24}
              onClick={() => {
                pagePush(1);
              }}
            />
          ),
          paginationIconLastPage: (
            <ChevronsRight
              size={24}
              onClick={() => {
                pagePush(total_pages);
              }}
            />
          ),
          ...(current && { paginationDefaultPage: current }),
          paginationTotalRows: total_count,
          paginationIconNext: (
            <ChevronRight
              size={24}
              onClick={() => {
                next && pagePush(next);
              }}
            />
          ),
          paginationIconPrevious: (
            <ChevronLeft
              size={24}
              onClick={() => {
                previous && pagePush(previous);
              }}
            />
          ),
          paginationComponentOptions: {
            //noRowsPerPage: true,
          },
        }
      : {};

  return (
    <div className="card">
      {!!headerText && (
        <div className="card-header">
          <h5>{typeof headerText === "string" ? headerText : "Select Multiple and Delete Single Data"}</h5>
        </div>
      )}
      <div className={`datatable-react ${tableRootClass}`.trim()} id="scrollableDataTable">
        <ScrollableWrapper
          scrollable={scrollable}
          scrollList={scrollList}
          setScrollList={setScrollList}
          fetchItems={fetchItems}
          page={page}
          setPage={setPage}
          rootFiltersObj={rootFiltersObj}
          avatarSkeletonColumnIndex={props.avatarSkeletonColumnIndex}
        >
          <DataTable
            columns={columns}
            data={scrollable ? dataTableList : tableData}
            striped={true}
            center={true}
            persistTableHead
            responsive
            fixedHeader
            className={cx(className, { ["pe-none opacity-50"]: loading })}
            {...getTableProps()}
            style={{
              borderLeftWidth: "1px",
              borderRightWidth: "1px",
              borderTopWidth: "1px",
              borderBottomWidth: borderBottomWidth,
              borderTopColor: "#DCDCDC",
              borderLeftColor: "#DCDCDC",
              borderRightColor: "#DCDCDC",
              borderBottomColor: borderBottomWidth ? "#DCDCDC" : "",
              borderStyle: "solid",
            }}
            pointerOnHover
            highlightOnHover
            progressPending={initialRender}
            //paginationComponent={() => <div>Pagination Component</div>}
            progressComponent={
              <TableLoader
                columnSkeletons={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                avatarSkeletonColumnIndex={props.avatarSkeletonColumnIndex}
              />
            }
            {...rest}
            limitPerPage={limitPerPage}
          />
        </ScrollableWrapper>
      </div>
    </div>
  );
};

const ScrollableWrapper = props => {
  const { children, scrollable, scrollList, setScrollList, fetchItems, page, setPage, rootFiltersObj } = props;
  const hasMore = getHasMore(page);
  const fetchMoreData = async () => {
    const { list, pagination } = await fetchItems(page?.next_page ?? 1);
    setScrollList([...scrollList, ...list]);
    setPage(pagination);
  };

  // useEffect(() => {
  //   const fetchInitialData = async () => {
  //     const { list, pagination } = await fetchItems(1);
  //     if (list && pagination) {
  //       setScrollList([...list]);
  //       setPage(pagination);
  //     }
  //   };
  //   scrollable && fetchInitialData();
  // }, [scrollable]);

  useEffect(() => {
    const fetchDataOnFilterChange = async () => {
      const { list, pagination } = await fetchItems(1);
      if (list && pagination) {
        setScrollList([...list]);
        setPage(pagination);
      }
    };
    if (!isEmptyObject(rootFiltersObj) && fetchItems) {
      fetchDataOnFilterChange();
    }
  }, [rootFiltersObj]);

  const rowSkeletonsOnInitialFetch = 6;
  const defaultRowSkeletons = 2;
  const numberOfRowSkeletons = page ? defaultRowSkeletons : rowSkeletonsOnInitialFetch;

  if (!scrollable) return <>{props.children}</>;
  return (
    <InfiniteScroll
      dataLength={scrollList.length}
      next={fetchMoreData}
      hasMore={hasMore}
      scrollableTarget="scrollableDataTable"
      scrollThreshold={0.9}
      height={"100%"}
      style={{ maxHeight: "650px" }}
      loader={
        <>
          <RowSkeletons number={numberOfRowSkeletons} avatarSkeletonColumnIndex={props.avatarSkeletonColumnIndex} />
        </>
      }
    >
      {children}
    </InfiniteScroll>
  );
};

export default DataTableComponent;
