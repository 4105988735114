import React from "react";
import { isFieldRestricted } from "../../helpers/Util";
import { Skeleton } from "../common";
import Group from "../common/Group";
import {
  IconAdminFee,
  IconCurrentMargin,
  IconHighestBid,
  IconNegotiation,
  IconRequiredBid,
  IconSeller,
} from "../svg-icon/svg-icons";
import styles from "./OfferTrials/OfferTrails.module.scss";
import { sortNegotiationsByUpdatedTime } from "./OfferTrials/OfferTrialsHelpers";

const formatRestrictedAmountToAED = value => {
  if (isFieldRestricted(value)) return value;
  return value ? `AED ${value.toLocaleString()}` : "-";
};

const getOfferInformationFromNegotiationOffers = ({ negotiationOffers = [] }) => {
  if (!negotiationOffers.length) return {};
  const latestOffer = sortNegotiationsByUpdatedTime(negotiationOffers)[0];
  const { required_bid: requiredBid, margin: currentMargin, negotiated_offer: currentNegotiation } = latestOffer || {};

  return {
    requiredBid,
    currentMargin,
    currentNegotiation,
  };
};

const PostAuctionCards = props => {
  const { data, negotiationsPermittedColumns = [] } = props;

  const { seller_expectation: sellerExpectations, amount: highestBid, admin_fee: adminFee } = data || {};

  const {
    requiredBid = "",
    currentMargin = "",
    currentNegotiation = "",
  } = getOfferInformationFromNegotiationOffers({
    negotiationOffers: data?.negotiation_offers ?? [],
  });

  const sortedNegotiations = sortNegotiationsByUpdatedTime(data?.negotiation_offers);

  const cardsData = [
    {
      key: "seller_expectation",
      label: "Seller Exp.",
      value: formatRestrictedAmountToAED(sellerExpectations),
      icon: IconSeller,
      iconColor: "#6B99FF",
      iconBgColor: "#EDF4FA",
    },
    {
      key: "negotiation_offers.required_bid",
      label: "Required Bid",
      value: formatRestrictedAmountToAED(requiredBid),
      icon: IconRequiredBid,
      iconColor: "#CC8225",
      iconBgColor: "#cc82251a",
    },

    {
      key: "negotiation_offers.amount",
      label: "Highest Bid",
      value: formatRestrictedAmountToAED(highestBid),
      icon: IconHighestBid,
      iconColor: "#0BB233",
      iconBgColor: "#E7F7EB",
    },
    {
      key: "negotiation_offers.negotiated_offer",
      label: "Curr. Negotiation",
      value: formatRestrictedAmountToAED(currentNegotiation),
      icon: IconNegotiation,
      iconColor: "#FFA900",
      iconBgColor: "#FBEFD999",
    },
    {
      key: "negotiation_offers.margin",
      label: "Curr. Margin",
      value: formatRestrictedAmountToAED(
        sortedNegotiations[0]?.adjusted_margin ? sortedNegotiations[0]?.adjusted_margin : currentMargin
      ),
      icon: IconCurrentMargin,
      iconColor: "#6B99FF",
      iconBgColor: "#EDF4FA",
    },
    {
      key: "admin_fee",
      label: "Admin Fee",
      value: formatRestrictedAmountToAED(adminFee),
      icon: IconAdminFee,
      iconColor: "#CC8225",
      iconBgColor: "#CC82251A",
    },
  ];

  const auctionCardsData = cardsData.reduce((acc, card) => {
    const { key } = card;
    const index = negotiationsPermittedColumns.findIndex(permittedColumn => permittedColumn === key);
    if (index > -1) {
      acc.push(card);
    }

    return acc;
  }, []);

  const item = (title, desc, Icon, iconColor, iconBgColor) => {
    return (
      <div className={styles.infoBlock} key={title}>
        <div className={styles.infoBlockIcon} style={{ "--info-icon-bg": iconBgColor }}>
          <Icon size={18} color={iconColor} />
        </div>
        <div>
          <div className={styles.infoBlockTitle}>{title}</div>
          <div className={styles.infoBlockDesc}>{desc}</div>
        </div>
      </div>
    );
  };

  const loadingItem = e => (
    <div className="info-block info-block-bordered d-flex spbwx16" key={e}>
      <Skeleton height={30} width={30} />
      <div className="d-flex flex-column">
        <Skeleton width={100} />
        <Skeleton width={30} />
      </div>
    </div>
  );

  return (
    <Group template={"repeat(auto-fill,minmax(min(168px, 100%), 1fr))"} gap={"8px"} style={{ marginBlockEnd: "24px" }}>
      {props.loading ? (
        [1, 2, 3, 4, 5, 6].map(e => loadingItem(e))
      ) : (
        <>
          {auctionCardsData
            ?.filter(cardData => !isFieldRestricted(cardData?.value))
            ?.map(cardItem =>
              item(cardItem.label, cardItem.value, cardItem.icon, cardItem.iconColor, cardItem.iconBgColor)
            )}
        </>
      )}
    </Group>
  );
};

export default PostAuctionCards;
