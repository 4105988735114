import React, { useCallback, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Gallery from "react-photo-gallery";
import { strings } from "../../constant/strings";
import { useSelector } from "react-redux";
import { selectTenant } from "../../reducers/general/selectors";
import { isFieldRestricted } from "../../helpers/Util";

const GalleryView = props => {
  const {
    photos,
    thumbnailCount,
    formattedPhotos,
    openLightbox,
    setCurrentImage,
    setViewerIsOpen,
    viewerIsOpen,
    setCurrentGalleryImage,
    currentGalleryImage,
    closeLightbox,
    daysInStock,
    currentImage,
    isDaysInStockRestricted,
  } = props;

  return (
    <div className="photo__gallery-primary">
      <div className="thumbnails__left-col">
        <Gallery
          margin={4}
          photos={photos.length > thumbnailCount ? formattedPhotos.slice(0, thumbnailCount) : formattedPhotos}
          onClick={openLightbox}
          direction="column"
          renderImage={({ photo, onClick }) => {
            return (
              <figure className="thumb_img_slide" key={photo.id}>
                <img
                  src={photo.gallerythumb}
                  onClick={() => setCurrentImage(photos.findIndex(e => e.full === photo.full))}
                  alt={strings.preview_not_available}
                />
              </figure>
            );
          }}
        />
        {photos.length > 4 && (
          <span
            className="view_gallery_link"
            onClick={() => {
              setViewerIsOpen(true);
            }}
          >
            More
          </span>
        )}
      </div>
      <div itemProp="associatedMedia" className="main__slide-img">
        <div className="" id="aniimated-thumbnials13" style={{ position: "relative" }}>
          <figure className="gallery_main_img">
            <img
              src={photos[currentImage]?.full}
              itemProp="thumbnail"
              alt="description"
              //width={width * 0.68}
              onClick={() => {
                setViewerIsOpen(true);
                setCurrentGalleryImage(currentImage);
              }}
            />
          </figure>
          {!!daysInStock && !isFieldRestricted(daysInStock) && !isDaysInStockRestricted && (
            <div className="days-in-stock p-1">{`${daysInStock ?? 0} Days On Dubizzle`}</div>
          )}
        </div>
      </div>

      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentGalleryImage].full}
          nextSrc={photos[(currentGalleryImage + 1) % photos.length].full}
          prevSrc={photos[(currentGalleryImage + photos.length - 1) % photos.length].full}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setCurrentGalleryImage((currentGalleryImage + photos.length - 1) % photos.length)}
          onMoveNextRequest={() => setCurrentGalleryImage((currentGalleryImage + 1) % photos.length)}
        />
      )}
    </div>
  );
};
const CardView = props => {
  const {
    photos,
    thumbnailCount,
    formattedPhotos,
    openLightbox,
    setCurrentImage,
    setViewerIsOpen,
    viewerIsOpen,
    setCurrentGalleryImage,
    currentGalleryImage,
    closeLightbox,
    isDaysInStockRestricted,
    daysInStock,
    currentImage,
  } = props;
  return (
    <>
      <figure itemProp="associatedMedia" className="galleryThumb">
        <img
          src={photos[currentImage]?.full}
          itemProp="thumbnail"
          alt="description"
          //width={width * 0.68}
          onClick={() => {
            setViewerIsOpen(true);
            setCurrentGalleryImage(currentImage);
          }}
        />

        {photos.length > 1 && (
          <span
            className="view_gallery_link"
            onClick={() => {
              setViewerIsOpen(true);
            }}
          >
            All Photos ({photos.length})
          </span>
        )}

        {!!daysInStock && !isFieldRestricted(daysInStock) && !isDaysInStockRestricted && (
          <div className="days-in-stock">{`${daysInStock ?? 0} Days ago`}</div>
        )}
      </figure>

      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentGalleryImage].full}
          nextSrc={photos[(currentGalleryImage + 1) % photos.length].full}
          prevSrc={photos[(currentGalleryImage + photos.length - 1) % photos.length].full}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setCurrentGalleryImage((currentGalleryImage + photos.length - 1) % photos.length)}
          onMoveNextRequest={() => setCurrentGalleryImage((currentGalleryImage + 1) % photos.length)}
        />
      )}
    </>
  );
};
const PhotoGallery = ({ photos, thumbnailCount, daysInStock, galleryView = true }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [currentGalleryImage, setCurrentGalleryImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const tenant = useSelector(selectTenant);
  const restrictedModules = tenant.configuration.restricted_modules;
  const isDaysInStockRestricted = restrictedModules.includes("stock_counters");

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const mainImageWidth = document.getElementById(`inventory-detail-main-image`)?.clientWidth;

    setWidth(mainImageWidth);
  }, [document.getElementById(`inventory-detail-main-image`)?.clientWidth, currentImage]);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentGalleryImage(index);
    // setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    // setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const formattedPhotos = [...photos].map(photo => ({ ...photo, src: photo.gallerythumb, width: 100, height: 100 }));
  const galleryProps = {
    photos,
    thumbnailCount,
    formattedPhotos,
    openLightbox,
    setCurrentImage,
    setViewerIsOpen,
    viewerIsOpen,
    setCurrentGalleryImage,
    currentGalleryImage,
    closeLightbox,
    isDaysInStockRestricted,
    daysInStock,
    currentImage,
  };

  const hasPhotos = photos?.length > 0;
  if (!hasPhotos) return null;
  return galleryView ? <GalleryView {...galleryProps} /> : <CardView {...galleryProps} />;
};

export default PhotoGallery;
