import * as yup from "yup";

import { CustomAlert, FormInput } from "../common";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { addGroupApi, updateGroupApi } from "../../helpers/ApiHelper";
import { descriptionValidation, nameValidation } from "../../helpers/validations";

import { Formik } from "formik";
import { checkUndefinedApiParams, getErrorString } from "../../helpers/Util";
import { strings } from "../../constant/strings";

const AddGroupForm = forwardRef((props, ref) => {
  const { addGroup, updateGroup, closeDialog, asUpdate, updateData, fetchGroups, toggleAddGroupLoading } = props;

  let formikRef = useRef();

  useImperativeHandle(ref, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  useEffect(() => {
    if (asUpdate) {
      setFromInitialValues();
    }
  }, [asUpdate, updateData]);

  const validationSchema = () =>
    yup.object().shape({
      name: nameValidation(strings.group_name_message, 30),
      description: descriptionValidation,
    });

  const setFromInitialValues = () => {
    if (updateData && formikRef && formikRef.current) {
      const { name, description } = updateData;
      formikRef.current.setValues({
        name: formikRef.current.values.name || name,
        description: formikRef.current.values.description || description,
      });
    }
  };
  const getInitialValues = () => ({
    name: "",
    description: "",
  });

  const onSubmit = async ({ name, description, ...rest }, { setStatus }) => {
    setStatus({ message: "" });
    const groupData = {
      group: {
        name: name,
        description: description,
      },
    };
    toggleAddGroupLoading(true);
    if (asUpdate) {
      const response = await updateGroupApi(updateData.id, groupData);
      if (response) {
        toggleAddGroupLoading(false);
        if (response.group) {
          updateGroup(response.group);
          // fetchGroups();
          closeDialog();
        } else {
          setStatus({
            message: getErrorString(response),
          });
        }
      }
    } else {
      const response = await addGroupApi(groupData);
      if (response) {
        toggleAddGroupLoading(false);
        if (response.group) {
          addGroup(response.group);
          // fetchGroups();
          closeDialog();
        } else {
          setStatus({ message: getErrorString(response) });
        }
      }
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, status }) => {
        return (
          <form className="form theme-form">
            <FormInput
              label={strings.name}
              placeholder={strings.name_ph}
              onChange={handleChange("name")}
              onBlur={handleBlur("name")}
              value={values.name}
              errorMsg={errors.name && touched.name && errors.name}
              // required
            />
            <FormInput
              label={strings.description}
              placeholder={strings.description_ph}
              linesCount="4"
              onChange={handleChange("description")}
              onBlur={handleBlur("description")}
              value={values.description}
              errorMsg={errors.description && touched.description && errors.description}
            />
            {status && status.message && <CustomAlert message={status.message} />}
          </form>
        );
      }}
    </Formik>
  );
});

export default AddGroupForm;
