import { FETCH_STOCK_COUNT, SET_STOCK_COUNT_LOADING } from "../../constant/actionTypes";
import { fetchCrmStockCountsApi } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

export const fetchCRMStockCounts = queryString => async dispatch => {
  dispatch({ type: SET_STOCK_COUNT_LOADING, payload: { loading: true } });
  const res = await fetchCrmStockCountsApi(queryString);
  if (res) {
    const { stock_counters, pagination, permitted_meta, ...rest } = res;
    if (stock_counters) {
      dispatch({
        type: FETCH_STOCK_COUNT,
        payload: {
          stockCounts: stock_counters,
          pagination,
          permittedMeta: permitted_meta,
          loading: false,
          error: "",
        },
      });
    } else {
      dispatch({
        type: FETCH_STOCK_COUNT,
        payload: {
          stockCounts: [],
          pagination: {},
          permittedMeta: {},
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  }
};
