import React, { useEffect, useState } from "react";
import CustomTabs from "../common/CustomTabs";
import { strings } from "../../constant/strings";
import { Avatar, IF, Loader } from "../common";
import { selectTenant } from "../../reducers/general/selectors";
import { useSelector } from "react-redux";
import { getCurrency, getTimeDateString, isEmptyArray, isFieldRestricted } from "../../helpers/Util";
import { fetchReconditioningExpensesApi } from "../../helpers/ApiHelper";
import styles from "./internal-info.module.scss";
import cx from "clsx";
import Group from "../common/Group";
import Card from "../common/Card";
import SpecificationDetail from "../common/InfoCard/SpecificationDetail";
import toast from "../common/Toast";
import EmptyState from "../common/EmptyState/EmptyState";
import { NoProjectedExpenseIcon, NoInternalCommentsIcon } from "../svg-icon/svg-icons";

const InternalInfo = ({ internalComments, reportId, inspector }) => {
  const tenant = useSelector(selectTenant);
  const [expenses, setExpenses] = useState(null);

  const [expensesLoading, setExpensesLoading] = useState(false);

  const { country } = tenant;
  return (
    <div className="">
      <CustomTabs
        loading={false}
        tabsData={[
          {
            id: 1,
            name: strings.comments,
            renderComponent: () => (
              <InternalComments internalComments={internalComments} country={country} inspector={inspector} />
            ),
          },
          {
            id: 2,
            name: strings.projected_expense,
            renderComponent: () => (
              <ProjectedExpense
                reportId={reportId}
                expenses={expenses}
                setExpenses={setExpenses}
                expensesLoading={expensesLoading}
                setExpensesLoading={setExpensesLoading}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default InternalInfo;

const InternalComments = ({ internalComments, country, inspector }) => {
  if (isEmptyArray(internalComments) || isFieldRestricted(internalComments))
    return (
      <EmptyState
        titleStyle={{ width: 200 }}
        title={"No Internal Comments"}
        image={<NoInternalCommentsIcon size={40} />}
        style={{ width: "200px", color: "#a1a1a1" }}
      />
    );
  return (
    <div className={cx("scroll-y mt-4")} style={{ "--box-height": "355px" }}>
      {internalComments
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        ?.map((info, index) => (
          <Card cardBg="#F7F7F7" cardPadding={"24px"} key={index} className={styles.userInfo}>
            <div className="d-flex justify-content-between align-items-center">
              {!isFieldRestricted(inspector?.full_name) && (
                <Avatar name={inspector?.full_name} style={{ fontWeight: "500" }} />
              )}
              <p className={styles.timeZone}>
                {!isFieldRestricted(info?.created_at)
                  ? getTimeDateString(info?.created_at, country.timezone, true)
                  : "-"}
              </p>
            </div>
            <div className={styles.userComment}>{!isFieldRestricted(info?.comment) ? info?.comment : "-"}</div>
          </Card>
        ))}
    </div>
  );
};

const ProjectedExpense = ({
  reportId,
  expenses = null,
  setExpenses,
  expensesLoading = true,
  setExpensesLoading = () => {},
}) => {
  const getReconditioningExpenses = async reportId => {
    try {
      setExpensesLoading(true);
      const response = await fetchReconditioningExpensesApi(reportId);
      if (response.success) {
        setExpenses(response);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setExpensesLoading(false);
    }
  };
  const tenant = useSelector(selectTenant);
  const { country } = tenant;
  useEffect(() => {
    getReconditioningExpenses(reportId);
    return () => {
      setExpensesLoading(true);
    };
  }, [reportId]);

  const groupedData =
    expenses?.expense_breakdown?.length > 0
      ? expenses?.expense_breakdown?.reduce((result, item) => {
          const sectionName = item.section_name;
          if (!result[sectionName]) {
            result[sectionName] = [];
          }
          result[sectionName].push(item);
          return result;
        }, {})
      : isEmptyArray(expenses?.expense_breakdown)
      ? expenses?.expense_breakdown
      : null;

  if (!!expensesLoading) {
    return (
      <div className={"d-flex justify-content-center align-items-center"} style={{ minHeight: "355px" }}>
        <Loader />
      </div>
    );
  }

  if (groupedData && !Object.keys(groupedData)?.length) {
    return (
      <>
        <EmptyState
          titleStyle={{ width: 200 }}
          title={strings.no_projected_expenses}
          image={<NoProjectedExpenseIcon size={30} />}
          style={{ width: "200px", color: "#a1a1a1" }}
          message={strings.no_expenses_message}
        />
      </>
    );
  }

  return (
    <>
      <IF condition={!!expenses && expenses?.total_expense}>
        <div className={cx(styles.totalExpense, " d-flex justify-content-between align-items-center")}>
          <div className={styles.expenseLabel}>
            <p className={cx("mb-2")}>{strings.total_expense}</p>
            <span className="mb-0 font-weight-normal">{strings.total_expense_per_report}</span>
          </div>
          <p className={cx("text-right font-weight-bold", styles.expenseTotalCost)}>
            {getCurrency(country, expenses?.total_expense)}
          </p>
        </div>
      </IF>

      <IF condition={!!groupedData && !!Object.keys(groupedData)?.length}>
        <div className={cx("scroll-y")} style={{ "--box-height": "240px", paddingBottom: 20 }}>
          {!!groupedData &&
            Object.keys(groupedData).map((sectionName, index) => (
              <>
                <div className={styles.projectExpenseContainer} key={index}>
                  <h5 className={styles.expenseTitle}>{sectionName}</h5>
                  {
                    <Group template={"repeat(2,1fr)"} gap="18px 96px" className={cx("", styles.projectExpenseDetail)}>
                      {groupedData[sectionName].map((item, itemIndex) => (
                        <SpecificationDetail
                          key={itemIndex}
                          item={item}
                          label={item.question}
                          description={item.answer}
                          expense={getCurrency(country, item.expense)}
                        />
                      ))}
                    </Group>
                  }
                </div>
              </>
            ))}
        </div>
      </IF>
    </>
  );
};
