import Countup from "react-countup";
import { FilterSelect } from "./FilterSelect";
import React from "react";
import { formatNumberString } from "../../helpers/Util";
import Anchor from "./Anchor";

const InventoryStatusCard = ({
  value,
  convertedCount,
  onClick,
  bottomLabel,
  timeFilters,
  onFilterChange,
  statusId,
  path,
  link,
}) => {
  return (
    <div className="row justify-content-between">
      <div className="hover-pointer col-md-6" onClick={onClick}>
        {path ? (
          <Anchor link={link}>
            <div>
              <h4 className="font-primary counter insight_count">{convertedCount}</h4>
              <h6 className="mb-0 insight_status">{bottomLabel}</h6>
            </div>
          </Anchor>
        ) : (
          <div>
            <h4 className="font-primary counter insight_count">{convertedCount}</h4>
            <h6 className="mb-0 insight_status">{bottomLabel}</h6>
          </div>
        )}
      </div>
      {timeFilters && timeFilters.length > 0 && (
        <div className="col-md-6">
          <FilterSelect
            value={value}
            onChange={e => {
              onFilterChange(e, statusId);
            }}
            options={timeFilters?.map(it => {
              return {
                ...it,
                slug: it.days_before,
                id: it.days_before,
              };
            })}
          />
        </div>
      )}
    </div>
  );
};

export const StatusCard = props => {
  const { onClick, time_filters, top_label, bottom_label, count, status_id, slug, timeFilters, onFilterChange, type } =
    props;
  let value = (timeFilters?.[status_id] && timeFilters?.[status_id].days_before) || 0;

  const convertedCount = formatNumberString(count, true);

  return type === "inventory" ? (
    <div className="card flex-1">
      <div className="card-body">
        <InventoryStatusCard
          convertedCount={convertedCount}
          bottomLabel={bottom_label}
          timeFilters={time_filters}
          onFilterChange={onFilterChange}
          statusId={status_id}
          value={value}
          onClick={onClick}
          link={props.link}
          path={props.path}
        />
      </div>
    </div>
  ) : (
    <div className="card flex-1">
      <div className="card-body">
        <div className="media">
          {time_filters && time_filters.length > 0 ? (
            <FilterSelect
              value={value}
              onChange={e => {
                onFilterChange(e, String(status_id) || slug);
              }}
              options={time_filters.map(it => {
                return {
                  ...it,
                  slug: it.days_before,
                  id: it.days_before,
                };
              })}
            />
          ) : (
            <h5 className="mb-0">{top_label}</h5>
          )}
        </div>

        {props.path ? (
          <Anchor link={props.link}>
            <div className="project-widgets text-center hover-pointer" onClick={onClick}>
              <h1 className="font-primary counter">{convertedCount}</h1>
              <h6 className="mb-0">{bottom_label}</h6>
            </div>
          </Anchor>
        ) : (
          <div className="project-widgets text-center hover-pointer" onClick={onClick}>
            <h1 className="font-primary counter">{convertedCount}</h1>
            <h6 className="mb-0">{bottom_label}</h6>
          </div>
        )}
      </div>
    </div>
  );
};
