import {
  FETCH_AUCTION_NEGOTIATIONS,
  FETCH_CRM_LEAD_FOLLOW_UPS,
  UPDATE_AUCTION_NEGOTIATIONS,
  UPDATE_CRM_LEAD_FOLLOW_UPS,
  UPDATE_LEAD_FOLLOW_UP_STATUS,
} from "../../constant/actionTypes";

const INITIAL_STATE = {
  negotiations: [],
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
  statuses: [],
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_AUCTION_NEGOTIATIONS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_AUCTION_NEGOTIATIONS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
