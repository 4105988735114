import * as yup from "yup";

import { AsyncSelect, CheckboxComponent, CustomAlert, FormInput, FormSelect, IF } from "../common";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { addDynamicAttributeApi, fetchUserRolesApi, updateDynamicAttributeApi } from "../../helpers/ApiHelper";

import DropDownFields from "./AddDropDownFields";
import { Formik } from "formik";
import { checkUndefinedApiParams, getErrorString, makeListToSelectData } from "../../helpers/Util";
import { regex } from "../../constant/regex";
import { strings } from "../../constant/strings";
import { useSelector } from "react-redux";
import { DYNAMIC_FIELD_TYPES } from "constant/appConstants";
import FormRow from "../common/FormRow";

const isPlaceholderSupported = dynamicFieldType => {
  let unSupportedFields = ["datetime", "attachment", "link", "boolean"];
  return !unSupportedFields.includes(dynamicFieldType);
};
const isRegexSupported = dynamicFieldType => {
  let supportedFields = ["price", "integer", "string", "text", "formula"];
  return !!supportedFields.includes(dynamicFieldType);
};
const getDynamicFieldSectionId = props => {
  let { supportedSectionsList, isSourceCar, section } = props;
  if (!isSourceCar) return supportedSectionsList.find(e => e.name === "Default")?.id;
  return supportedSectionsList.find(e => e.name === section)?.id;
};
const isFieldSupported = props => {
  const { type, value } = props;
  if (!value) return false;
  let configObj = {
    placeholder: isPlaceholderSupported,
    regex: isRegexSupported,
  };
  return configObj?.[type]?.(value) ?? false;
};

const getInitialValues = () => ({
  fieldLabel: "",
  fieldName: "",
  fieldDataType: "",
  fieldRoles: [],
  fieldPlaceholder: "",
  fieldRegex: "",
  required: false,
  filterable: false,
  listable: false,
  options: [],
  section: "",
  displayOrder: 0,
  optionSection: [],
});

const makeDataForDynamicFieldsForm = data =>
  data.map((e, i) => ({
    id: i,
    slug: e,
    label: e,
  })) || [];

const makeDataForDynamicSection = data =>
  data.map((e, i) => ({
    ...e,
    label: e.name,
    slug: e.id,
  })) || [];

const creatingRegex = props => {
  const { dynamicAttributes, modelId, dynamicAttribute } = props;
  let existingFields = [];
  dynamicAttributes?.length && dynamicAttributes.forEach(e => existingFields.push(e.field_name));
  if (modelId) {
    existingFields = existingFields.filter(e => e !== props.dynamicAttribute.field_name);
  }
  return existingFields.length && `^(${existingFields.join("|")})$`;
};

const validationSchema = (props, isSourceCar) =>
  yup.object().shape({
    fieldLabel: yup.string().required(strings.enter_field_label_message),
    fieldName: yup
      .string()
      .required(strings.enter_field_name)
      .test("field_name", strings.field_name_taken_message, value => !new RegExp(creatingRegex(props)).test(value))
      .matches(new RegExp(regex.fieldName), strings.valid_field_name_message),
    fieldDataType: yup.string().required(strings.select_data_type),
    ...(isSourceCar && { section: yup.string().required(strings.select_section) }),
    fieldPlaceholder: yup.string(strings.select_city).nullable(),
    fieldRegex: yup.string(strings.select_city).nullable(),
    options: yup
      .array()
      .of(
        yup.object().shape({
          field_label: yup.string().nullable().required(strings.add_option_message),
        })
      )
      .test("options", strings.add_options_message, function (value, ctx) {
        if (this.parent.fieldDataType === "single-select" || this.parent.fieldDataType === "multi-select") {
          if (this.parent.fieldDataType === "multi-select") {
            return value?.filter(item => !item?._destroy)?.length > 1;
          } else {
            return value?.filter(item => !item?._destroy)?.length >= 1;
          }
        } else {
          return true;
        }
      }),
    displayOrder: yup.number().typeError("Position must be a number").min(0, "Position cannot be less than 0"),
  });

const onFilterAbleChange = (e, setFieldValue) => {
  setFieldValue("filterable", e.target.checked);
  setFieldValue("listable", e.target.checked);
};

const DynamicAttributesForm = forwardRef((props, ref) => {
  const dynamicAttributeData = Object.keys(props.dynamicAttribute || {})?.length > 0;

  const dynamicFieldsData = useSelector(state => state.DynamicFieldsData);
  const sourceName = dynamicFieldsData?.dynamicModel?.source_name ?? null;
  const isSourceCar = sourceName === "Car";
  const supportedFieldTypes = makeDataForDynamicFieldsForm(dynamicFieldsData.supportedFieldTypes);
  const supportedSectionsList = makeDataForDynamicSection(dynamicFieldsData.supportedSectionsList);

  let formikRef = useRef();

  useEffect(() => {
    if (props.dynamicAttribute && formikRef.current) {
      const {
        field_label,
        field_name,
        field_data_type,
        filtered_roles,
        field_place_holder,
        field_regex,
        is_required,
        is_filterable,
        is_listable,
        dynamic_field_options,
        dynamic_field_section,
        display_order,
      } = props.dynamicAttribute;
      formikRef.current.setValues({
        fieldLabel: field_label,
        fieldName: field_name,
        fieldDataType: field_data_type,
        fieldRoles: filtered_roles,
        fieldPlaceholder: field_place_holder,
        fieldRegex: field_regex,
        required: is_required,
        filterable: is_filterable,
        listable: is_listable,
        options: [...(dynamic_field_options || [])],
        section: dynamic_field_section?.name,
        displayOrder: display_order,
        optionSection: [],
      });
    }
  }, [props.dynamicAttribute?.field_name]);

  useImperativeHandle(ref, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
    resetForm() {
      formikRef.current.resetForm();
    },
  }));

  useEffect(() => {
    return () => {
      formikRef.current.resetForm({ values: { ...formikRef.current.initialValues } });
      props.setDynamicFieldId && props.setDynamicFieldId(null);
    };
  }, []);

  const onSubmit = async (values, actions) => {
    try {
      if (!checkUndefinedApiParams(props?.modelId, "model")) return;
      actions.setStatus({ message: "" });
      const {
        fieldLabel,
        fieldName,
        fieldDataType,
        fieldRoles,
        fieldPlaceholder,
        fieldRegex,
        required,
        options,
        listable,
        filterable,
        section,
        displayOrder,
      } = values;
      props.toggleLoading(true);
      const dynamic_field = {
        field_label: fieldLabel,
        field_name: fieldName,
        field_data_type: fieldDataType,
        field_place_holder: isFieldSupported({ type: "placeholder", value: values?.fieldDataType })
          ? fieldPlaceholder
          : null,
        field_regex: isFieldSupported({ value: values?.fieldDataType, type: "regex" }) ? fieldRegex : "",
        ...(fieldDataType === DYNAMIC_FIELD_TYPES.USER_LIST && fieldRoles
          ? { filtered_role_ids: fieldRoles.map(el => el.id) }
          : {}),
        ...(options?.length
          ? {
              dynamic_field_options_attributes: options.map(i => {
                return {
                  ...i,
                  field_label: i.field_label,
                  field_name: i.field_label,
                  dependent_field_ids: i.dependent_fields?.map(field => field.id),
                };
              }),
            }
          : {}),
        is_required: required,
        is_listable: listable,
        is_filterable: filterable,
        dynamic_field_section_id: getDynamicFieldSectionId({ supportedSectionsList, isSourceCar, section }),
        display_order: displayOrder,
      };
      let response;
      if (!dynamicAttributeData) {
        response = await addDynamicAttributeApi(props.modelId, { dynamic_field });
      } else {
        response = await updateDynamicAttributeApi(props.modelId, props.dynamicAttribute?.id, { dynamic_field });
      }

      if (response) {
        props.toggleLoading(false);
        if (response.success) {
          formikRef.current.resetForm({ values: { ...formikRef.current.initialValues } });

          props.onSuccess(response?.dynamic_field);
        } else {
          actions.setStatus({ message: getErrorString(response) });
        }
      }
    } catch (error) {
      props.toggleLoading(false);
      actions.setStatus({ message: getErrorString(error) });
    }
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={validationSchema(props, isSourceCar)}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        status,
        setFieldValue,
        submitCount,
        setFieldError,
        setErrors,
      }) => (
        <form className="form theme-form db__form-main">
          <FormInput
            label={strings.field_label}
            placeholder={strings.field_label_ph}
            onChange={handleChange("fieldLabel")}
            onBlur={handleBlur("fieldLabel")}
            value={values.fieldLabel || ""}
            errorMsg={errors.fieldLabel && touched.fieldLabel && errors.fieldLabel}
            required
            showRequiredAsterisk
            errorClassName="d-block"
          />
          <FormInput
            label={strings.field_name}
            placeholder={strings.field_name_ph}
            onChange={handleChange("fieldName")}
            onBlur={handleBlur("fieldName")}
            value={values.fieldName || ""}
            errorMsg={errors.fieldName && touched.fieldName && errors.fieldName}
            required
            showRequiredAsterisk
            disabled={dynamicAttributeData}
            errorClassName="d-block"
          />
          <FormSelect
            placeholder={strings.field_datatype_ph}
            label={strings.field_datatype}
            onBlur={handleBlur("fieldDataType")}
            value={values.fieldDataType || ""}
            errorMsg={errors.fieldDataType && touched.fieldDataType && errors.fieldDataType}
            options={supportedFieldTypes}
            onChange={e => {
              handleChange("fieldDataType")(e);
            }}
            showRequiredAsterisk
            disabled={dynamicAttributeData}
          />

          <IF condition={values.fieldDataType === "single-select" || values.fieldDataType === "multi-select"}>
            <>
              <DropDownFields
                dynamicAttribute={props.dynamicAttribute}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                handleBlur={handleBlur}
                submitCount={submitCount}
                setFieldError={setFieldError}
                dynamicAttributes={props.dynamicAttributes}
                supportedSectionsList={supportedSectionsList}
                modelId={props.modelId}
                outerClassName="dataTypeOuter"
              />
            </>
          </IF>

          <IF condition={values.fieldDataType === DYNAMIC_FIELD_TYPES.USER_LIST}>
            <FormRow label={strings.select_role}>
              <AsyncSelect
                className=""
                isClearable={false}
                label={strings.select_role}
                selected={makeListToSelectData(values.fieldRoles) || []}
                listApi={fetchUserRolesApi}
                listProperty={"roles"}
                isMulti={true}
                queryKey={"s[name]"}
                errorMsg={errors.fieldRoles && touched.fieldRoles && errors.fieldRoles}
                onChange={selected => {
                  setFieldValue("fieldRoles", selected);
                }}
                formatOptionLabel={props.formatOptionLabel}
                onBlur={handleBlur("fieldRoles")}
                placeholder={strings.role}
              />
            </FormRow>
          </IF>
          {isFieldSupported({ value: values?.fieldDataType, type: "placeholder" }) && (
            <FormInput
              label={strings.field_placeholder}
              placeholder={strings.field_placeholder_ph}
              onChange={handleChange("fieldPlaceholder")}
              onBlur={handleBlur("fieldPlaceholder")}
              value={values.fieldPlaceholder || ""}
              errorMsg={errors.fieldPlaceholder && touched.fieldPlaceholder && errors.fieldPlaceholder}
              required
            />
          )}
          {isSourceCar && (
            <FormSelect
              placeholder={strings.select_section}
              label={strings.section}
              onBlur={handleBlur("section")}
              value={values.section || ""}
              errorMsg={errors.section && touched.section && errors.section}
              options={makeDataForDynamicSection(supportedSectionsList)}
              onChange={e => {
                handleChange("section")(e);
              }}
              showRequiredAsterisk
            />
          )}
          <FormInput
            label={strings.position}
            placeholder={strings.position_ph}
            value={values.displayOrder}
            onBlur={handleBlur("displayOrder")}
            errorMsg={errors.displayOrder && touched.displayOrder && errors.displayOrder}
            onChange={handleChange("displayOrder")}
            type={"number"}
          />
          {isFieldSupported({ value: values?.fieldDataType, type: "regex" }) && (
            <FormInput
              label={strings.field_regex}
              placeholder={strings.field_regex_ph}
              onChange={handleChange("fieldRegex")}
              onBlur={handleBlur("fieldRegex")}
              value={values.fieldRegex || ""}
              errorMsg={errors.fieldRegex && touched.fieldRegex && errors.fieldRegex}
              required
            />
          )}
          <div className={"spbwx16 mb-3"}>
            {values?.fieldDataType !== "link" && values?.fieldDataType !== "attachment" && (
              <>
                <CheckboxComponent
                  label={strings.filterable}
                  checked={values.filterable}
                  onChange={e => onFilterAbleChange(e, setFieldValue)}
                />
                <CheckboxComponent
                  label={strings.listable}
                  checked={values.listable}
                  onChange={e => setFieldValue("listable", e.target.checked)}
                  disabled={values.filterable === true}
                />
              </>
            )}

            <CheckboxComponent
              label={strings.required}
              checked={values.required || ""}
              onChange={e => setFieldValue("required", e.target.checked)}
            />
          </div>
          {status && status.message && <CustomAlert message={status.message} />}
        </form>
      )}
    </Formik>
  );
});

export default DynamicAttributesForm;
