import { AsyncSelect, ButtonLoading, CustomAlert, Permissions, Toast } from "../common";
import React, { useEffect, useState } from "react";
import FormInput from "../common/FormInput";
import {
  addCRMCarCommentsApi,
  addCRMLeadCommentsApi,
  addCRMTaskCommentsApi,
  fetchUsersApi,
  fetchUsersListApi,
} from "../../helpers/ApiHelper";
import { checkUndefinedApiParams, defaultFn, extractIds, getErrorString } from "../../helpers/Util";
import { strings } from "../../constant/strings";
import MentionInputField from "../common/MentionInputField";
import { useDispatch } from "react-redux";
import { MODULE_TYPE } from "constant/appConstants";

const commentsHelper = type => {
  const addPost = {
    [MODULE_TYPE.CRM_TASK]: addCRMTaskCommentsApi,
    [MODULE_TYPE.CRM_LEAD]: addCRMLeadCommentsApi,
    [MODULE_TYPE.CAR]: addCRMCarCommentsApi,
  };

  return {
    addCommentsApi: addPost[type],
  };
};
const ChatterInput = ({
  detailId,
  setComments,
  type,
  showButton = true,
  useTextAreaButton = false,
  height = "100px",
  className = "",
  postSubmit = defaultFn,
  addCommentPermission = () => false,
}) => {
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mentions, setMentions] = useState([]);
  const dispatch = useDispatch();

  const helper = commentsHelper(type);

  const onPost = async () => {
    const taggedUserIds = extractIds(mentions).map(mentionIds => Number(mentionIds));
    if (!checkUndefinedApiParams(detailId, type)) return;
    if (!!comment) {
      setError("");
      setLoading(true);
      const response = await helper.addCommentsApi(detailId, {
        comment_attributes: {
          comment,
          tagged_user_ids: taggedUserIds,
        },
      });
      if (response) {
        setLoading(false);
        if (response.success) {
          setComments(response.comments[response.comments.length - 1]);
          setComment("");
          setMentions([]);
          postSubmit(response);
        } else {
          setError(getErrorString(response));
        }
      }
    }
  };

  const inputProps = {
    required: true,
    submitOnEnter: true,
    onSubmit: mentionProps => {
      onPost(mentionProps);
    },
    onFocus: () => {
      setError("");
    },
    onBlur: () => {
      setError("");
    },
    placeholder: strings.comments_ph,
    value: comment,
    setValue: setComment,
  };

  return (
    addCommentPermission() && (
      <>
        <MentionInputField
          inputProps={inputProps}
          setMentions={setMentions}
          height={height}
          className={className}
          textAreaButton={() => {
            if (!useTextAreaButton) return <></>;
            return (
              <>
                <CustomAlert type="icon" message={error} tooltipKey="comments-post-error" />
                <ButtonLoading
                  className="send-btn bg-transparent f-w-600"
                  disabled={!comment || loading}
                  onClick={onPost}
                  loading={loading}
                >
                  {strings.comment}
                </ButtonLoading>
              </>
            );
          }}
        />

        <div className="d-flex flex-row justify-content-end">
          <CustomAlert type="icon" message={error} tooltipKey="comments-post-error" />
          {showButton && (
            <div>
              <ButtonLoading size="sm" disabled={!comment.length} onClick={onPost} loading={loading}>
                {strings.post}
              </ButtonLoading>
            </div>
          )}
        </div>
      </>
    )
  );
};

export default ChatterInput;
