import React from "react";
import Progress from "../../../components/common/Progress";
import { strings } from "../../../constant/strings";
import { QCQualityRatingSkeleton } from "./QCSkeletons";
import styles from "./index.module.scss";

export const CallProgressBar = props => {
  const { sectionsRating = [], qcStatsLoading } = props;

  if (qcStatsLoading) return <QCQualityRatingSkeleton />;

  return (
    <div className="card">
      <div className={"card-body"}>
        <h5 className="f-w-600 mb-3">{strings.inspector_performance_feedback}</h5>
        <div
          className={"grid"}
          style={{ "--template": "repeat(auto-fill, minmax(min(320px, 100%), 1fr))", gap: "10px 64px" }}
        >
          {sectionsRating?.map(section => {
            return (
              <div key={section.id}>
                <div className={"d-flex align-items-baseline justify-content-between"}>
                  <span className={styles.progressBarLabel}>{section.name}</span>
                  <span className="f-18 f-w-600">{`${section.score}/${section.total}`}</span>
                </div>
                <Progress
                  className="w-100"
                  id={section.id}
                  value={parseFloat(section?.score)}
                  max={section?.total}
                  showLabel={false}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
