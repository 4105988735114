import cx from "clsx";
import { Copy, Skeleton, Toast } from "components/common";
import ShowPhoneButton from "components/common/Button/ShowPhoneButton";
import { viewPhoneNumber } from "helpers/ApiHelper";
import { getPermission } from "helpers/Util";
import useOnScroll from "hooks/useOnScroll/useOnScroll";
import useHasPermission from "hooks/useViewPhonePermission";
import React, { useRef, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import styles from "./RenderPrivateField.module.scss";

const FIELD_TYPES = {
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
};

const MESSAGES = {
  [FIELD_TYPES.PHONE_NUMBER]: {
    showMessage: "Show Phone Number",
    fieldNameLower: "phone number",
    fieldNameSentence: "Phone number",
    copiedSuccessfully: "Phone number copied successfully",
    failedToCopy: "Failed to copy the phone number",
  },
  [FIELD_TYPES.EMAIL]: {
    showMessage: "Show Email",
    fieldNameLower: "email",
    fieldNameSentence: "Email",
    copiedSuccessfully: "Email copied successfully",
    failedToCopy: "Failed to copy the email",
  },
};
const RenderPrivateField = props => {
  const {
    data = [],
    hasViewPermission = false,

    associatedType = null,
    associatedId = null,
    uuid,
    isUserTypeNotClient = false,
    component = "",

    hasCopyPermission = true,
    id,
    value,
    optionalClassName = "",
    textStyle = {},
    fieldType = FIELD_TYPES.PHONE_NUMBER,
    showOnlyPrimaryField = false,
    showOnlySecondaryField = false,
    ctaText = null,
    dropDownContainer = "body",
    addDropdownToContainer = true,
    onScrollElement = null,
    dropDownContainerStyle,
    ...rest
  } = props || {};
  const [fieldData, setFieldData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { hasPermission: hasViewPhonePermission } = useHasPermission("Client", "view_phone");
  const hasViewPhonePermissionn = getPermission("Client", "view_phone");
  useOnScroll({ handleScroll: () => setDropdownOpen(false), onScrollElement: onScrollElement });

  const toggle = () => setDropdownOpen(prevState => !prevState);
  const messages = MESSAGES[fieldType];
  const dropdownRef = useRef(null);

  const hasPermissionToView = isUserTypeNotClient
    ? getPermission("Client", "view_phone")
    : getPermission("Client", "view_phone");

  const renderViewIcon = (color = "#4366F2") => {
    // if (!isFieldRestricted) return null;
    return (
      <>
        <div className={styles.menuDropDown} style={{ display: "inline-block" }}>
          <Dropdown isOpen={dropdownOpen} toggle={toggle} key={associatedId}>
            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
              <ShowPhoneButton ctaText={ctaText ?? messages.showMessage} onBtnClick={viewField} />
            </DropdownToggle>
            <DropdownMenu
              style={dropDownContainerStyle}
              className={styles.dropdownItemOpen}
              container={addDropdownToContainer ? dropDownContainer : ""}
            >
              {/* <DropdownMenu className={styles.dropdownItemOpen}> */}
              {loading ? (
                <>
                  {[1].map((entry, index) => {
                    return showOnlyPrimaryField && index > 0 ? null : (
                      <DropdownItem text className={cx(styles.contactCard, "d-flex flex-column")} key={index}>
                        {index == 0 && <div className={styles.clientsNumber}>Client Phone Number(s)</div>}
                        <div className="d-flex align-items-center justify-content-between">
                          <Skeleton height={15} width={100} />
                          <Skeleton height={15} width={20} />
                        </div>
                      </DropdownItem>
                    );
                  })}
                </>
              ) : (
                <>
                  {!fieldData || fieldData?.length === 0 ? (
                    <DropdownItem text>
                      <div style={{ display: "flex" }}>
                        <span>{"No Result Found"}</span>
                      </div>
                    </DropdownItem>
                  ) : (
                    fieldData.map((entry, index) => {
                      return (
                        <DropdownItem
                          text
                          onClick={() => {
                            setDropdownOpen(true);
                          }}
                          className={cx(styles.contactCard, "d-flex flex-column")}
                          key={index}
                        >
                          {index == 0 && <div className={styles.clientsNumber}>Client Phone Number(s)</div>}
                          <div className="d-flex align-items-center justify-content-between">
                            <span className={styles.numbers}>{entry}</span>
                            <span> {hasCopyPermission ? <Copy text={entry} /> : null}</span>
                          </div>
                        </DropdownItem>
                      );
                    })
                  )}
                </>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </>
    );
  };

  const viewField = async e => {
    if (dropdownOpen) return;
    // if (!uuid || !associatedType || !associatedId) {
    if (!uuid) {
      Toast.error("Incomplete Arguments to view phone number! ");
      // setDropdownOpen(false);
      toggle();
      setFieldData(null);
      return;
    }

    setLoading(true);
    try {
      //API
      let data = await viewPhoneNumber({
        uuid: uuid,
        associatedId: associatedId,
        associatedType: associatedType,
        component: component ? component : "",
        isUserTypeNotClient: isUserTypeNotClient,
      });

      if (data && data?.success) {
        if (fieldType == FIELD_TYPES.PHONE_NUMBER) {
          if (!data?.phone && !data?.secondary_phone) setFieldData(null);
          if (showOnlyPrimaryField && !data?.phone) setFieldData(null);

          if (showOnlyPrimaryField) setFieldData([data?.phone]);
          else if (showOnlySecondaryField) {
            setFieldData([data?.secondary_phone]);
          } else {
            if (data?.phone) setFieldData([data?.phone]);
            if (data?.phone && data?.secondary_phone) setFieldData([data?.phone, data?.secondary_phone]);
          }
        }
      } else {
        toggle();
        setFieldData(null);
        Toast.error("API Error in viewing phone number");
      }
    } catch (error) {
      toggle();
      setFieldData(null);
      Toast.error(error);
    }
    setLoading(false);
    // setDropdownOpen(true);
    // let data = await getClientPhoneNumber(5018);
    // console.log("data", data);
  };

  // if (!hasPermissionToView) return <></>;
  // if (!hasViewPhonePermission) return value && value.includes("*") ? value : `***-***-***-*xx`;

  if (!hasViewPhonePermission)
    return value && value.includes("*") ? (
      value
    ) : (
      <>
        <span>{"***-********"}</span>
      </>
    );

  return (
    <>
      <div className={optionalClassName}>
        {/* {hasCopyPermission ? renderCopyIcon(fieldData) : null} */}
        {/* {hasViewPermission ? renderViewIcon() : null} */}
        <span className={styles.viewIcon}> {renderViewIcon()}</span>
      </div>
    </>
  );
};

export default RenderPrivateField;
