import "react-datepicker/dist/react-datepicker.css";

import React, { forwardRef, useEffect, useRef, useState } from "react";

import { Calendar } from "react-feather";
import DatePicker from "react-datepicker";
import { Skeleton } from "../";
import moment from "moment-timezone";
import { setTimeZone } from "../../../helpers/Util";

const DateFilter = props => {
  const {
    onSelect,
    queryStartDate,
    queryEndDate,
    onClear,
    label,
    noFilter,
    country,
    timezone,
    dateFormat,
    className,
    loading,
    showIcon = true,
    inputClassName = "",
    isSingleSelect = false,
    ...rest
  } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let calenderRef = useRef();

  useEffect(() => {
    if (queryStartDate || startDate != null) {
      const queryParamToDateStart = setTimeZone(timezone, queryStartDate);
      setStartDate(queryStartDate && moment(queryStartDate).isValid() ? queryParamToDateStart : null);
    }
    if (queryEndDate || endDate != null) {
      const queryParamToDateEnd = setTimeZone(timezone, queryEndDate);
      setEndDate(queryEndDate && moment(queryEndDate).isValid() ? queryParamToDateEnd : null);
    }
  }, [queryStartDate, queryEndDate]);

  useEffect(() => {
    if (calenderRef && calenderRef.current && endDate != null) {
      calenderRef.current.setOpen(false);
    }
  }, [endDate?.getDate()]);

  const onChange = dates => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      if (end != null) {
        if (start === end) {
          onSelect(
            moment(start).format("YYYY-MM-DDTHH:mm:ss"),
            moment(start).endOf("day").format("YYYY-MM-DDTHH:mm:ss")
          );
        } else {
          onSelect(
            moment(start).format("YYYY-MM-DDTHH:mm:ss"),
            moment(end).isValid() ? moment(end).endOf("day").format("YYYY-MM-DDTHH:mm:ss") : ""
          );
        }
      }
    } else if (isSingleSelect && dates) {
      setStartDate(dates);
      setEndDate(dates);
      onSelect(moment(dates).format("YYYY-MM-DDTHH:mm:ss"), moment(dates).endOf("day").format("YYYY-MM-DDTHH:mm:ss"));
    } else {
      if (!dates) {
        setStartDate(null);
        setEndDate(null);
        onClear();
      }
    }
  };

  const formattedStartDate = moment(startDate).isValid()
    ? dateFormat
      ? moment(startDate).format(dateFormat)
      : moment(startDate).format("L")
    : "";
  const formattedEndDate = moment(endDate).isValid()
    ? dateFormat
      ? moment(endDate).format(dateFormat)
      : moment(endDate).format("L")
    : "";

  const dateRangeSelected =
    isSingleSelect && formattedStartDate
      ? formattedStartDate
      : formattedStartDate && formattedEndDate
      ? `${formattedStartDate} - ${formattedEndDate}`
      : "";

  const Input = forwardRef(({ value, ...rest }, ref) => (
    <div className="faq-form">
      <div className="d-flex justify-content-center align-items-center">
        {showIcon && (
          <Calendar className={`search-icon ${props.filterSelected ? "selected-filter-svg" : ""}`} size={16} />
        )}
      </div>
      <input className={`form-control ${inputClassName}`} type="text" value={dateRangeSelected} {...rest} ref={ref} />
    </div>
  ));

  return loading ? (
    <Skeleton height={30} />
  ) : (
    <div className={className}>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        onChange={onChange}
        onKeyDown={e => e.preventDefault()}
        customInput={<Input />}
        isClearable
        shouldCloseOnSelect={isSingleSelect ? true : false}
        placeholderText={label}
        autoComplete="off"
        className={`form-control digits ${inputClassName}`}
        // id="exampleFormControlSelect3"
        clearButtonTitle="Clear"
        popperPlacement="bottom"
        popperModifiers={{
          flip: {
            behavior: ["bottom"], // don't allow it to flip to be above
          },
          preventOverflow: {
            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
          },
          hide: {
            enabled: false, // turn off since needs preventOverflow to be enabled
          },
        }}
        ref={calenderRef}
        filterDate={
          !noFilter
            ? date => {
                return setTimeZone(timezone) > date;
              }
            : undefined
        }
        {...rest}
      />
    </div>
  );
};

export default DateFilter;
