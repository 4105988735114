import {
  FETCH_CRM_TASK_FOLLOW_UPS,
  UPDATE_CRM_TASK_FOLLOW_UPS,
  UPDATE_TASK_FOLLOW_UP_STATUS,
  SET_CRM_TASKS_FOLLOW_UPS_LOADING,
} from "../../constant/actionTypes";
import { clone } from "../../helpers/Util";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CRM_TASK_FOLLOW_UPS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CRM_TASK_FOLLOW_UPS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_TASK_FOLLOW_UP_STATUS:
      const followUpList = state.list;
      const currentFollowUp = followUpList.find(item => item.id === action.payload?.followUp?.id);
      currentFollowUp.status = action.payload?.followUp?.status;
      return {
        ...state,
        currentFollowUp,
      };

    case SET_CRM_TASKS_FOLLOW_UPS_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };
    default:
      return state;
  }
};
