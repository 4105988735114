import React, { useEffect } from "react";

import { FaSyncAlt, MdFolderOpen } from "../../svg-icon/svg-icons";
import { Close } from "../../common";
import { strings } from "../../../constant/strings";
import { uploadFile } from "../UploadAttachmentsHelpers";

const FileUploadItem = props => {
  const { uploaded, uploading, inError } = props.item;
  useEffect(() => {
    if (uploaded === false) {
      uploadFile(
        props.item,
        "documents",
        props.associationKey || "files",
        props.files,
        props.setFiles,
        false,
        false,
        props.updateDocuments
      );
    }
  }, []);

  return (
    <div className="img-upload-wrapper d-flex hover-pointer flex-row align-items-center justify-content-between spbwx8">
      <div className="d-flex flex-row align-items-center">
        {uploading ? (
          <FaSyncAlt className="download-icon fa-spin" />
        ) : (
          <MdFolderOpen size={30} className="download-icon" />
        )}

        <div
          onClick={() => {
            props.item.url && window.open(props.item.url, "_blank");
          }}
        >
          {props.item?.file?.name || props.item?.file_name}
        </div>
        {inError && (
          <button type="button" className="btn btn-link" onClick={e => props.onRetry(e)}>
            {strings.retry}
          </button>
        )}
        {props.canRemoveFile && (
          <Close
            className="btn-close-right"
            onClick={e => {
              e.preventDefault();
              props.removeFile(props.item);
            }}
            disabled={uploading === true}
            type={"button"}
          />
        )}
      </div>
    </div>
  );
};

export default FileUploadItem;
