import { useCallback } from "react";
import { getPermission } from "../helpers/Util";

export const useHasPermission = (category, action) => {
  const hasPermission = useCallback(() => {
    return getPermission(category, action);
  }, [])();

  return { hasPermission };
};

export default useHasPermission;
