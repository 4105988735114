import { Skeleton } from "./index";
import React from "react";

const CustomSkeletonFields = ({ label, field }) => {
  return (
    <div>
      <Skeleton style={label.style} height={label.height} width={label.width} />
      <Skeleton style={field.style} height={field.height} width={field.width} />
    </div>
  );
};

export default CustomSkeletonFields;
