import { MILEAGE } from "constant/CRM/Inventory";

export const DYNAMIC_FILTER_TYPES = {
  STRING: "string",
  SINGLE_SELECT: "single-select",
  MULTI_SELECT: "multi-select",
  INTEGER: "integer",
  DATE_TIME: "datetime",
  BOOLEAN: "boolean",
  PRICE: "price",
  DATE: "date",
  TEXT: "text",
  USER_LIST: "user_list",
  ITEM_LIST: "item_list",
};

export const FILTER_TYPES = {
  INPUT: "input",
  DROPDOWN: "dropdown",
  DROPDOWN_V2: "dropdown-v2",
  DATE: "date",
  TYPEAHEAD: "typeahead",
  CHECKBOX_DROPDOWN: "checkbox-dropdown",
  RANGE: "range",
  SCROLLABLE_DROPDOWN: "scrollable-dropdown",
  MODAL: "city-modal-select",
  TREE_DROPDOWN: "treeSelect",
  MONTH_DATE_RANGE_FILTER: "MonthDateRangeFilter",
  NESTED_CHECKBOX_DROPDOWN: "nested-checkbox-dropdown",
};

export const CRM_CHEVRON_FILTER_KEYS = {
  CATEGORY_TYPE_KEY: "f[category_type.id][]",
  APPOINTMENT_TIME_START_KEY: {
    CHEVRON_DISPOSITION_KEY: "f[chevron_appointment.start_time][gte]",
    CHEVRON_USERS_KEY: "start_time",
  },
  APPOINTMENT_TIME_END_KEY: {
    CHEVRON_DISPOSITION_KEY: "f[chevron_appointment.start_time][lte]",
    CHEVRON_USERS_KEY: "end_time",
  },
  APPOINTMENT_AREA_KEY: {
    CHEVRON_DISPOSITION_KEY: "f[chevron_appointment.area.id][]",
    CHEVRON_USERS_KEY: "area_ids[]",
  },
  APPOINTMENT_CITY_KEY: "chevron_appointment.city.id", //not used for api , only to cache city.id
  APPOINTMENT_CITY_ALL_KEY: {
    CHEVRON_DISPOSITION_KEY: "f[chevron_appointment.area.city.id][]",
    CHEVRON_USERS_KEY: "city_id",
  },
};

export const RANGE_FILTER_KEYS = ({ MAX_LIMIT, MIN_LIMIT } = {}) => ({
  MILEAGE: {
    GREATER_THAN: "f[mileage][gte]",
    LESS_THAN: "f[mileage][lte]",
    MAX_LIMIT: MILEAGE.MAX,
    MIN_LIMIT: MILEAGE.MIN,
    STEP: 5000,
  },
  MODEL_YEAR: {
    GREATER_THAN: "f[model_year][gte]",
    LESS_THAN: "f[model_year][lte]",
    MAX_LIMIT: 2026,
    MIN_LIMIT: 1974,
    STEP: 1,
  },

  SHOW_UP_RATE: {
    GREATER_THAN: "f[show_up_rate][gte]",
    LESS_THAN: "f[show_up_rate][lte]",
    MAX_LIMIT: 1,
    MIN_LIMIT: 0,
    STEP: 0.1,
  },

  CAPACITY_IN_HOURS: {
    GREATER_THAN: "f[capacity_in_hours][gte]",
    LESS_THAN: "f[capacity_in_hours][lte]",
    MAX_LIMIT: 100,
    MIN_LIMIT: 0,
    STEP: 1,
  },
  AUCTION_CYCLE: {
    GREATER_THAN: "f[inventory.auction_cycle][gte]",
    LESS_THAN: "f[inventory.auction_cycle][lte]",
    MAX_LIMIT,
    MIN_LIMIT,
    STEP: 1,
  },
  DAYS_IN_STOCK: {
    GREATER_THAN: "f[inventory.days_in_stock][gte]",
    LESS_THAN: "f[inventory.days_in_stock][lte]",
    MAX_LIMIT: 1000,
    MIN_LIMIT: 0,
    STEP: 1,
  },
  INSPECTION_RATING: {
    GREATER_THAN: "f[inventory.report.score][gte]",
    LESS_THAN: "f[inventory.report.score][lte]",
    MAX_LIMIT: 100,
    MIN_LIMIT: 0,
    STEP: 0.1,
  },
  CALL_DURATION: {
    GREATER_THAN: "f[duration][gte]",
    LESS_THAN: "f[duration][lte]",
    MAX_LIMIT: 150,
    MIN_LIMIT: 0,
    STEP: 1,
  },
  QC_SCORE: {
    GREATER_THAN: "f[report.score][gte]",
    LESS_THAN: "f[report.score][lte]",
    MAX_LIMIT: 100,
    MIN_LIMIT: 0,
    STEP: 1,
  },
  SELLER_EXPECTATIONS: {
    GREATER_THAN: "f[inventory.inventoriable.seller_expectations][gte]",
    LESS_THAN: "f[inventory.inventoriable.seller_expectations][lte]",
    MAX_LIMIT: 500000,
    MIN_LIMIT: 0,
    STEP: 1,
  },
  ENGINE_CAPACITY: {
    GREATER_THAN: "f[engine_capacity][gte]",
    LESS_THAN: "f[engine_capacity][lte]",
    MAX_LIMIT: 8000,
    MIN_LIMIT: 600,
    STEP: 1,
  },
  LISTING_PRICE: {
    GREATER_THAN: "f[inventory.ask_price][gte]",
    LESS_THAN: "f[inventory.ask_price[lte]",
    MAX_LIMIT: 1500000,
    MIN_LIMIT: 0,
    STEP: 1,
  },
  KITT_FAIR_PRICE: {
    GREATER_THAN: "f[kitt_recommendation.valuation.dubizzle_ad_listing_price][gte]",
    LESS_THAN: "f[kitt_recommendation.valuation.dubizzle_ad_listing_price][lte]",
    MAX_LIMIT: 10000000,
    MIN_LIMIT: 0,
    STEP: 100,
  },
});

export const QUERIES = Object.freeze({
  RANSACK: {
    CITY_MULTISELECT: `q[city_id_in][]`,
    ZONE_MULTISELECT: `q[zone_id_in][]`,
    AREA_MULTISELECT: `q[area_id_in][]`,
    SEARCH_NAME: `q[name_start]`,
  },
});

export const SEARCH_TYPES = {
  LAZY: `s`,
  EXACT: `f`,
};
