import { FaCaretDown, FaCaretRight } from "../../svg-icon/svg-icons";
import React, { useEffect, useState } from "react";
import cx from "clsx";
import styles from "./Collapse.module.scss";

import { Collapse as CollapseComponent } from "reactstrap";

const Collapse = props => {
  const {
    children,
    label,
    header,
    containerClassName,
    btnClassName,
    contentClassName,
    showButtons = true,
    iconPlacement,
    openIcon,
    closeIcon,
    isOpen = true,
    ...rest
  } = props;

  const [open, setOpen] = useState(isOpen);

  return (
    <div className={cx(containerClassName, styles.collapse)}>
      {open ? (
        <button
          className={cx(styles.collapseHeader, iconPlacement == "right" && styles.iconRight, btnClassName)}
          onClick={() => (showButtons ? setOpen(false) : props.setOpen(false))}
        >
          {label && label}
          {openIcon ? openIcon : <FaCaretDown />}
        </button>
      ) : (
        <button
          className={cx(styles.collapseHeader, iconPlacement == "right" && styles.iconRight, btnClassName)}
          onClick={() => (showButtons ? setOpen(true) : props.setOpen(false))}
        >
          {label && label}
          {closeIcon ? closeIcon : <FaCaretRight />}
        </button>
      )}

      <CollapseComponent className={contentClassName ? contentClassName : ""} ref={props.ref} isOpen={open}>
        {children}
      </CollapseComponent>
    </div>
  );
};

export default Collapse;
