import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoute from "../auth/ProtectedRoute";
import App from "../components/app";
import { IF, NotAvailable } from "../components/common";
import { FETCH_TENANT } from "../constant/actionTypes";
import {
  AUC_AUCTION_BOOKINGS,
  AUC_AUCTION_TYPES_PATH,
  AUC_AUCTION_USER_DETAIL_PATH,
  AUC_AUCTION_USERS_PATH,
  AUC_AUCTIONS_PATH,
  AUC_AUTHENTICATION_TOKEN_PATH,
  AUC_CARS_DETAIL_PATH,
  AUC_CARS_EDIT_PATH,
  AUC_CARS_EDIT_SOLD_CAR_PATH,
  AUC_CARS_PATH,
  AUC_DASHBOARD_PATH,
  AUC_DYNAMIC_FIELDS_PATH,
  AUC_DYNAMIC_MODELS_PATH,
  AUC_EDIT_AUTHENTICATION_TOKEN_PATH,
  AUC_EDIT_LEAD_SOURCE_PATH,
  AUC_EDIT_ROLE_PATH,
  AUC_EDIT_USER_SETTINGS_PATH,
  AUC_GROUPS_PATH,
  AUC_LEAD_SOURCE_PATH,
  AUC_NEW_AUTHENTICATION_TOKEN_PATH,
  AUC_NEW_LEAD_SOURCE_PATH,
  AUC_NEW_ROLE_PATH,
  AUC_OFFERS_PATH,
  AUC_ROLES_PATH,
  AUC_TENANT_PATH,
  AUC_USER_ASSIGNMENT_PATH,
  AUC_USERS_PATH,
  AUCTION_APPLY_LEAVE_EDIT_PATH,
  AUCTION_APPLY_LEAVE_NEW_PATH,
  AUCTION_BOOKING_EXPENSES,
  AUCTION_BOOKING_EXPENSES_DETAIL,
  AUCTION_MARGIN_SLABS,
} from "../constant/appPaths";
import { RESTRICTED_MODULES } from "constant/appConstants";
import { setAppOnRoute } from "../helpers/Util";
import {
  AuctionBookings,
  AuctionDashboard,
  AuctionMarginSlabs,
  Auctions,
  AuctionTypes,
  AuctionUsers,
  AuctionUsersDetail,
  AuthenticationTokens,
  CrmApplyLeave,
  DynamicFields,
  DynamicModels,
  Groups,
  Inventories,
  InventoryDetail,
  LeadSources,
  Offers,
  Roles,
  SaveAuthenticationToken,
  SaveInventory,
  SaveLeadSource,
  SaveRole,
  SaveTenant,
  SaveUserConfiguration,
  Users,
  UserSettingsPage,
} from "../pages";
import AuctionBookingExpenses from "../pages/auction/AuctionBookingExpenses";
import AuctionBookingExpensesDetail from "../pages/auction/AuctionBookingExpensesDetail";
import { selectTenant } from "../reducers/general/selectors";

const AuctionRoutes = props => {
  const { isAdmin, handleLogout, user, userInfo, platformAvailable } = props;
  const dispatch = useDispatch();
  const tenant = useSelector(selectTenant);
  const restrictedModules = tenant?.configuration?.restricted_modules;

  useEffect(() => {
    setAppOnRoute(dispatch, FETCH_TENANT, platformAvailable);
  }, []);

  return (
    <App handleLogout={handleLogout} userInfo={userInfo} user={user} platformAvailable={platformAvailable}>
      {platformAvailable ? (
        <>
          <Suspense fallback={<> </>}>
            <ProtectedRoute path={AUC_DASHBOARD_PATH} component={AuctionDashboard} exact user={user} />
            <ProtectedRoute
              path={AUC_CARS_PATH}
              component={Inventories}
              exact
              user={user}
              catagories="Car"
              actions="index"
            />
            {/* Uncomment for "Edit User Profile" feature */}
            <ProtectedRoute
              path={AUC_EDIT_USER_SETTINGS_PATH}
              component={UserSettingsPage}
              user={user}
              exact
              catagories="AuctionUser"
              actions="index"
            />
            <ProtectedRoute
              path={AUC_CARS_DETAIL_PATH}
              component={InventoryDetail}
              exact
              user={user}
              catagories="Car"
              actions="read"
            />
            <ProtectedRoute
              path={AUC_CARS_EDIT_PATH}
              component={SaveInventory}
              exact
              user={user}
              catagories="Car"
              actions="update"
            />
            <ProtectedRoute
              path={AUC_CARS_EDIT_SOLD_CAR_PATH}
              component={SaveInventory}
              exact
              user={user}
              catagories="Car"
              actions="update_sold"
            />
            <ProtectedRoute
              path={AUC_AUCTIONS_PATH}
              component={Auctions}
              exact
              user={user}
              catagories="Auction"
              actions="index"
            />
            <ProtectedRoute
              path={AUC_OFFERS_PATH}
              component={Offers}
              exact
              user={user}
              catagories="Offer"
              actions="index"
            />
            <ProtectedRoute
              path={AUC_AUCTION_USERS_PATH}
              component={AuctionUsers}
              exact
              user={user}
              catagories="AuctionUser"
              actions="index"
            />
            {!!restrictedModules && !restrictedModules.includes(RESTRICTED_MODULES.BOOKING) && (
              <ProtectedRoute
                path={AUC_AUCTION_BOOKINGS}
                component={AuctionBookings}
                exact
                user={user}
                catagories="Booking"
                actions="index"
              />
            )}
            <ProtectedRoute
              path={AUC_AUCTION_USER_DETAIL_PATH}
              exact
              component={AuctionUsersDetail}
              user={user}
              catagories="AuctionUser"
              actions="read"
            />
            <ProtectedRoute
              path={AUCTION_BOOKING_EXPENSES_DETAIL}
              component={AuctionBookingExpensesDetail}
              exact
              user={user}
              catagories="Booking"
              actions="read"
            />

            <ProtectedRoute
              path={AUCTION_APPLY_LEAVE_NEW_PATH}
              component={CrmApplyLeave}
              exact
              user={user}
              catagories="Leave"
              actions="create"
            />

            <ProtectedRoute
              path={AUCTION_APPLY_LEAVE_EDIT_PATH}
              component={CrmApplyLeave}
              exact
              user={user}
              catagories="Leave"
              actions="update"
            />

            <IF condition={isAdmin}>
              <ProtectedRoute path={AUC_USERS_PATH} component={Users} user={user} categories="User" actions="index" />
              <ProtectedRoute
                path={AUC_USER_ASSIGNMENT_PATH}
                exact
                component={SaveUserConfiguration}
                user={user}
                categories="User"
                actions="update"
              />

              <ProtectedRoute
                path={`${AUC_GROUPS_PATH}`}
                component={Groups}
                user={user}
                catagories="Group"
                actions="index"
              />

              <ProtectedRoute
                exact
                path={AUC_ROLES_PATH}
                component={Roles}
                user={user}
                categories="Role"
                actions="index"
              />
              <ProtectedRoute
                exact
                path={AUC_NEW_ROLE_PATH}
                component={SaveRole}
                user={user}
                categories="Role"
                actions="create"
              />

              <ProtectedRoute
                path={AUC_EDIT_ROLE_PATH}
                exact
                component={SaveRole}
                user={user}
                categories="Role"
                actions="update"
              />
              <ProtectedRoute path={AUC_TENANT_PATH} component={SaveTenant} user={user} categories="Tenant" />

              <ProtectedRoute
                path={AUC_AUCTION_TYPES_PATH}
                component={AuctionTypes}
                user={user}
                catagories="AuctionType"
                actions="index"
              />

              <ProtectedRoute
                path={AUC_AUTHENTICATION_TOKEN_PATH}
                exact
                component={AuthenticationTokens}
                user={user}
                categories="AuthenticationToken"
                actions="index"
              />
              <ProtectedRoute
                path={AUC_NEW_AUTHENTICATION_TOKEN_PATH}
                exact
                component={SaveAuthenticationToken}
                user={user}
                categories="AuthenticationToken"
                actions="create"
              />
              <ProtectedRoute
                path={AUC_EDIT_AUTHENTICATION_TOKEN_PATH}
                exact
                component={SaveAuthenticationToken}
                user={user}
                categories="AuthenticationToken"
                actions="update"
              />

              <ProtectedRoute
                path={AUC_DYNAMIC_MODELS_PATH}
                exact
                component={DynamicModels}
                user={user}
                categories="DynamicModel"
                actions="index"
              />

              <ProtectedRoute
                path={AUC_DYNAMIC_FIELDS_PATH}
                exact
                component={DynamicFields}
                user={user}
                categories="DynamicField"
                actions="index"
              />

              <ProtectedRoute
                path={AUC_LEAD_SOURCE_PATH}
                exact
                component={LeadSources}
                user={user}
                catagories="LeadSource"
                actions="index"
              />
              <ProtectedRoute
                path={AUC_NEW_LEAD_SOURCE_PATH}
                exact
                component={SaveLeadSource}
                user={user}
                catagories="LeadSource"
                actions="create"
              />
              <ProtectedRoute
                path={AUC_EDIT_LEAD_SOURCE_PATH}
                exact
                component={SaveLeadSource}
                user={user}
                catagories="LeadSource"
                actions="update"
              />
              {!!restrictedModules && !restrictedModules.includes(RESTRICTED_MODULES.BOOKING) && (
                <ProtectedRoute
                  path={AUCTION_BOOKING_EXPENSES}
                  component={AuctionBookingExpenses}
                  exact
                  user={user}
                  catagories="BookingExpenses"
                  actions="index"
                />
              )}
              <ProtectedRoute
                path={AUCTION_MARGIN_SLABS}
                component={AuctionMarginSlabs}
                exact
                user={user}
                catagories="AuctionMarginSlabs"
                actions="index"
              />
            </IF>
            {/* <Redirect to={AUC_DASHBOARD_PATH} /> */}
          </Suspense>
        </>
      ) : (
        <NotAvailable />
      )}
    </App>
  );
};

export default AuctionRoutes;
