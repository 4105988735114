import "react-bootstrap-typeahead/css/Typeahead.css";

import React, { useEffect, useRef } from "react";

import { Search } from "react-feather";
import { Skeleton } from ".";
import { Typeahead } from "react-bootstrap-typeahead";
import cx from "clsx";

const SelectionListTypeHead = props => {
  const {
    className,
    horizontal,
    textRight,
    label,
    errorMsg,
    formSelectRef,
    inputClassName,
    loading,
    inputRef,
    showSearchIcon,
    caret,
    isEditable = null,
    canClose,

    ...rest
  } = props;

  const inputFieldReference = useRef();
  const inputFieldRef = inputRef ? inputRef : inputFieldReference;
  useEffect(() => {
    const { options, isLoading, isError, fetchList } = props;
    if (options?.length === 0 && !isLoading && fetchList) {
      fetchList();
    }
  }, []);

  return (
    <div className={`${horizontal ? "form-group row" : ""}${className ? ` ${className}` : ""}`} ref={formSelectRef}>
      {label && (
        <label
          className={
            horizontal
              ? `col-sm-3 col-form-label d-flex ${loading ? "pt-0" : ""} ${textRight ? "justify-content-end" : ""}`
              : ""
          }
        >
          {loading ? <Skeleton height={24} width={210} /> : label}
        </label>
      )}
      <div className={inputClassName ? inputClassName : horizontal ? "col-sm-9" : "dropdown-caret p-relative"}>
        {loading ? (
          <Skeleton height={30} />
        ) : (
          <div className={cx("position-relative", showSearchIcon ? "faq-form" : "")}>
            {showSearchIcon ? <Search size={18} className="search-icon" /> : null}
            <Typeahead
              id="multiple-typeahead"
              clearButton
              isInvalid
              flip
              isValid
              ref={inputFieldRef}
              inputProps={{
                style: {
                  ...(isEditable === false && { backgroundColor: "#e9ecef" }),
                },
              }}
              disabled={props.disabled || isEditable === false}
              onInputChange={(input, e) => {
                // clear input on backspace
                if (
                  inputFieldRef.current &&
                  inputFieldRef.current.props.selected?.length &&
                  e.nativeEvent.inputType === "deleteContentBackward" &&
                  !inputFieldRef.current.props.multiple
                ) {
                  inputFieldRef.current && inputFieldRef.current.clear();
                  inputFieldRef.current && inputFieldRef.current._showMenu();
                }
              }}
              {...rest}
            />
            {caret ? (
              <span className="task-icon p-absolute">
                <i className="fa fa-caret-down"></i>
              </span>
            ) : null}
          </div>
        )}
        {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
      </div>
    </div>
  );
};

export default SelectionListTypeHead;
