import { Avatar, Modal, Permissions, Skeleton } from "../common";
import React, { Fragment, useEffect, useState } from "react";
import { MdAdd } from "../svg-icon/svg-icons";
import WatcherInput from "./WatcherInput";
import { connect } from "react-redux";
import { fetchCRMWatcherUsers } from "../../actions/crm/watcherUsers.action";
import { getErrorString } from "../../helpers/Util";
import { strings } from "../../constant/strings";

const Watchers = props => {
  const {
    task,
    id,
    watchers: currentWatchers,
    fetchCRMWatcherUsers,
    users,
    loadingSkeleton,
    className = "",
    horizontal,
    type,
    saveWatcherApi,
    onSuccess = () => {},
  } = props;
  const [showInput, setShowInput] = useState(false);
  const [visible, setVisible] = useState(false);
  const [addWatcherError, setAddWatcherError] = useState("");
  const [watchers, setWatchers] = useState(currentWatchers);

  useEffect(() => {
    if (currentWatchers) {
      setWatchers(currentWatchers);
      // !users && !users?.length && fetchCRMWatcherUsers();
    }
  }, [currentWatchers]);

  useEffect(() => {
    if (!task) {
      setWatchers(null);
    }
  }, [task]);

  const saveWatcher = async (apiCall, id_, list, route) => {
    if (id_) {
      let payload = { watcher_type: "User", watcher_id: id_ };

      const response = await apiCall(id, payload, route);
      if (response) {
        if (response.success) {
          setWatchers(list);
          onSuccess(list);
        } else {
          setAddWatcherError(getErrorString(response));
        }
      }
    }
  };

  const getItemChanged = list => {
    let currentWatchers = watchers ?? [];
    if (currentWatchers?.length < list.length) {
      let changedItem = list?.filter(e => !currentWatchers?.find(x => x.id == e.id));
      saveWatcher(saveWatcherApi, changedItem[0]?.id, list, "add_watcher");
    }
    if (currentWatchers?.length > list.length) {
      let changedItem = currentWatchers?.filter(e => !list.find(x => x.id == e.id));
      saveWatcher(saveWatcherApi, changedItem[0]?.id, list, "remove_watcher");
    }
  };

  const renderSkeleton = () => [1, 2, 3, 4].map(e => <Skeleton circle height={30} width={30} key={e} />);

  return (
    <>
      <div
        className={
          horizontal
            ? "d-flex flex-row align-items-center flex-grow-1"
            : `${"d-flex flex-column justify-content-end"} ${className}`
        }
      >
        <div className={horizontal ? "m-r-10 m-l-40" : "m-b-5"}>{strings.watchers}</div>
        {showInput ? (
          <WatcherInput
            {...props}
            getItemChanged={getItemChanged}
            watchers={watchers}
            addWatcherError={addWatcherError}
            onHideInput={() => setShowInput(false)}
            type={type}
          />
        ) : (
          <>
            <div className={`${"d-flex spbwx4 align-items-center"} ${horizontal ? "flex-wrap" : ""}`}>
              {watchers?.length > 0 && (
                <div className={`${"d-flex task-watchers-list m-r-10"} ${horizontal ? "flex-wrap" : ""}`}>
                  {loadingSkeleton
                    ? renderSkeleton()
                    : watchers?.slice(0, 3).map(e => (
                        <Fragment key={e.id}>
                          <Avatar name={e?.name} imgSrc={e.picture?.url} avatarOnly size={27} round className="m-r-5" />
                          {/* <Close size={8} className="deleteImage" onClick={onClose} /> */}
                        </Fragment>
                      ))}
                </div>
              )}
              {watchers?.length > 3 ? (
                <button
                  className="more-watchers btn d-flex align-items-center justify-content-center"
                  onClick={() => setVisible(true)}
                >
                  +{watchers?.length - 3}
                </button>
              ) : (
                ""
              )}

              <Permissions catagories={type} actions="add_watcher">
                <button
                  className={`add-watcher-icon d-flex align-items-center justify-content-center btn-transparent cursor-pointer ${
                    horizontal ? "m-0" : ""
                  }`}
                  style={{ "--media-icon-container-size": "24px" }}
                  onClick={() => {
                    !users && !users?.length && fetchCRMWatcherUsers();
                    setShowInput(true);
                  }}
                >
                  <MdAdd size={16} />
                </button>
              </Permissions>
            </div>
          </>
        )}
      </div>

      <Modal
        toggle={() => {
          setVisible(!visible);
        }}
        open={visible}
        title={strings.watchers}
        body={() => (
          <div>
            {watchers?.map(e => (
              <Avatar name={e?.name} round className="m-r-5" containerClass="m-b-10" key={e.id} />
            ))}
          </div>
        )}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setVisible(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
        ]}
      />
    </>
  );
};

const mapStateToProps = ({ CrmWatcherUsers }) => {
  return {
    ...CrmWatcherUsers,
  };
};

export default connect(mapStateToProps, {
  fetchCRMWatcherUsers,
})(Watchers);
