import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { useEffect, useState } from "react";
import "./RowAction.module.scss";

import { MoreVertical } from "react-feather";

export const RowAction = ({ IconProp = null, dropDownList, iconColor = "", ...rest }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {}, []);

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} {...rest}>
        <DropdownToggle
          outline
          aria-haspopup
          style={{
            borderWidth: 0,
            padding: 0,
            margin: 0,
          }}
          size="sm"
          className="btn btn-link"
          color="transparent"
          caret={false}
        >
          {IconProp ? (
            <div className="table__action-icon">
              <IconProp size={18} />
            </div>
          ) : (
            <MoreVertical size={18} color={iconColor ? iconColor : "currentColor"} />
          )}
        </DropdownToggle>

        <DropdownMenu right container="body">
          {dropDownList &&
            dropDownList.map((e, i) => (
              <DropdownItem disabled={e?.disabled || false} key={i} onClick={e.onPress} style={{ zIndex: 2 }}>
                <div className={"d-flex align-items-center"}>
                  {e.icon && e.icon}
                  <span className={e.icon && "ml-2"}>{e.title}</span>
                </div>
              </DropdownItem>
            ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
