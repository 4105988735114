import React from "react";
import { Skeleton, Avatar } from "../../../../components/common";

const QCQualityAnalysisSkeleton = ({ qcStatsLoading = true }) => {
  return (
    <>
      <div className="card ">
        <div className={"card-body"}>
          <div className={"d-flex  justify-content-between"} style={{ flexDirection: "row", gap: 120 }}>
            <div>
              <div className={"d-flex "} style={{ flexDirection: "column", gap: 10 }}>
                <Skeleton width={220} height={30} />
                {/* <Skeleton width={280} height={10} /> */}
              </div>
              <div className={"d-flex "} style={{ flexDirection: "column", marginTop: 20, gap: 10 }}>
                {[1, 2, 3].map(e => (
                  <div key={e} className={"d-flex"} style={{ flexDirection: "row", gap: 5 }}>
                    <Avatar loading={qcStatsLoading} size={15} />
                    <Skeleton width={200} height={15} />
                  </div>
                ))}
              </div>
            </div>
            <div className={"d-flex"} style={{ alignItems: "center", justifyContent: "center" }}>
              <Avatar loading size={180} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QCQualityAnalysisSkeleton;
