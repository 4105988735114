import React, { Suspense, useEffect } from "react";
import { IF, NotAvailable } from "../components/common";
import {
  CRM_ADD_APPROVAL,
  CRM_ADD_SCHEDULE,
  CRM_APPLY_LEAVE_EDIT_PATH,
  CRM_APPLY_LEAVE_NEW_PATH,
  CRM_REPORT_CALL_CENTER_SELLERS,
  CRM_APPROVAL_PATH,
  CRM_AUTHENTICATION_TOKEN_PATH,
  CRM_AUTOMATE_BRANCH_CAPACITY_PATH,
  CRM_CALL_QC_PATH,
  CRM_CALL_VIEW_EDIT_QC_PATH,
  CRM_CALLS_PATH,
  CRM_CARS_AD_POST,
  CRM_CARS_AD_POST_DETAIL,
  CRM_CARS_AD_POST_EDIT,
  CRM_CARS_ADD_QUOTATION,
  CRM_CARS_DETAIL_PATH,
  CRM_CARS_EDIT_PATH,
  CRM_CARS_EDIT_SOLD_CAR_PATH,
  CRM_CARS_NEW_PATH,
  CRM_CARS_PATH,
  CRM_CARS_STOCK_PATH,
  CRM_CICO_DETAIL_PATH,
  CRM_CICO_EDIT_PATH,
  CRM_CICO_NEW_PATH,
  CRM_CICO_PATH,
  CRM_CLIENT_EDIT_PATH,
  CRM_CLIENT_VIEW_PATH,
  CRM_CLIENTS_PATH,
  CRM_CONTRACT_DETAIL_PATH,
  CRM_CONTRACT_NEW_PATH,
  CRM_CONTRACTS_PATH,
  CRM_DASHBOARD_PATH,
  CRM_DASHBOARD_REPORTS_PATH,
  CRM_DASHBOARD_REPORTS_WIDGETS_PATH,
  CRM_DYNAMIC_FIELDS_PATH,
  CRM_DYNAMIC_MODELS_PATH,
  CRM_EDIT_AUTHENTICATION_TOKEN_PATH,
  CRM_EDIT_LEAD_SOURCE_PATH,
  CRM_EDIT_ROLE_PATH,
  CRM_EDIT_SCHEDULE,
  CRM_EDIT_USER_SETTINGS_PATH,
  CRM_FIELD_MAPPING_PATH,
  CRM_APPROVALS_ADMIN,
  CRM_ITEM_LISTS_PATH,
  CRM_LEAD_DETAIL_PATH,
  CRM_LEAD_EDIT_PATH,
  CRM_LEAD_NEW_PATH,
  CRM_LEAD_SOURCE_PATH,
  CRM_LEADS_BUYER_PATH,
  CRM_LEADS_PATH,
  CRM_LEADS_SELLER_PATH,
  CRM_LEADS_VAS_PATH,
  CRM_LOCATION_EDIT_PATH,
  CRM_LOCATION_NEW_PATH,
  CRM_LOCATIONS_PATH,
  CRM_NEW_AUTHENTICATION_TOKEN_PATH,
  CRM_NEW_LEAD_SOURCE_PATH,
  CRM_NEW_ROLE_PATH,
  CRM_PAYMENTS_EDIT_PATH,
  CRM_PAYMENTS_NEW_PATH,
  CRM_PAYMENTS_PATH,
  CRM_REMINDER_PATH,
  CRM_RETAIL_PRICE_REDUCTION,
  CRM_ROLES_PATH,
  CRM_ROTA_PLAN_PATH,
  CRM_STOCK_COUNT_DETAIL_PATH,
  CRM_STOCK_COUNT_PATH,
  CRM_TASK_TYPES_PATH,
  CRM_TASKS_PATH,
  CRM_TASKS_PATH_DETAIL,
  CRM_TENANT_PATH,
  CRM_USER_ASSIGNMENT_PATH,
  CRM_USERS_PATH,
  CRM_WHATSAPP_TRIGGER_CONTROLLER,
  EVENT_CALENDAR_PATH,
  CRM_APPROVAL_DETAIL_PATH,
  CRM_DUPLICATION_SEARCH,
  CRM_REPORT_CALL_CENTER_BUYERS,
} from "../constant/appPaths";

import { useDispatch, useSelector } from "react-redux";
import ProtectedRoute from "../auth/ProtectedRoute";
import App from "../components/app";
import { FETCH_TENANT } from "../constant/actionTypes";
import { setAppOnRoute } from "../helpers/Util";

import { RESTRICTED_MODULES } from "constant/appConstants";
import { PERMISSIONS } from "../constant/permissions";
import {
  AuthenticationTokens,
  CrmAddApproval,
  CrmApplyLeave,
  CrmApprovals,
  CRMAutomateBranchCapacity,
  CrmCalendar,
  CrmCalls,
  CrmCiCo,
  CrmCicoDetail,
  CrmClientDetail,
  CrmClientEditDetail,
  CrmClients,
  CrmContractDetail,
  CrmContracts,
  CrmDashboard,
  CrmDashboardReports,
  CrmDashboardReportWidgets,
  CrmFieldMapper,
  CrmInventoryAddPost,
  CrmInventoryAdPostDetail,
  CrmItemList,
  CrmLeadDetail,
  CrmLeads,
  CrmLocations,
  CrmPayments,
  CrmReminders,
  CRMRetailPriceReduction,
  CrmRotaPlan,
  CrmSaveCico,
  CrmSaveContract,
  CrmSaveLead,
  CrmSaveLocation,
  CrmSavePayment,
  CrmSaveQuotation,
  CrmStockCount,
  CrmStockCountDetail,
  CrmTasks,
  CrmTaskTypes,
  CRMWhatsappTriggerController,
  CrmWhatsApp,
  DynamicFields,
  DynamicModels,
  Inventories,
  SaleStockInventories,
  InventoryDetail,
  LeadSources,
  Roles,
  SaveAuthenticationToken,
  SaveInventory,
  SaveLeadSource,
  SaveRole,
  SaveTenant,
  SaveUserConfiguration,
  Users,
  UserSettingsPage,
  CrmApprovalAdmin,
  CrmApprovalDetailPage,
  AppointmentReports,
  CrmDuplicationSearch,
  CallCenterSellerReport,
  CallCenterBuyerReport,
} from "../pages";
import { CallQC } from "../pages/common/CallQC";
import CrmAddSchedule from "../pages/crm/CrmAddSchedule";
import { selectTenant } from "../reducers/general/selectors";

const CrmRoutes = props => {
  const { isAdmin, handleLogout, user, userInfo, platformAvailable } = props;
  const dispatch = useDispatch();
  const tenant = useSelector(selectTenant);
  const restrictedModules = tenant?.configuration?.restricted_modules;

  useEffect(() => {
    setAppOnRoute(dispatch, FETCH_TENANT, platformAvailable);
  }, []);

  return (
    <App handleLogout={handleLogout} userInfo={userInfo} user={user} platformAvailable={platformAvailable}>
      <IF condition={platformAvailable}>
        <Suspense fallback={<></>}>
          <ProtectedRoute path={CRM_DASHBOARD_PATH} component={CrmDashboard} exact user={user} />

          <ProtectedRoute
            path={CRM_DASHBOARD_REPORTS_PATH}
            component={CrmDashboardReports}
            catagories="Visualization"
            actions="index"
            exact
            user={user}
          />

          <ProtectedRoute
            path={CRM_REPORT_CALL_CENTER_SELLERS}
            component={CallCenterSellerReport}
            catagories="Visualization"
            actions="index"
            exact
            user={user}
          />
          <ProtectedRoute
            path={CRM_REPORT_CALL_CENTER_BUYERS}
            component={CallCenterBuyerReport}
            catagories="Visualization"
            actions="index"
            exact
            user={user}
          />

          <ProtectedRoute
            path={CRM_DASHBOARD_REPORTS_WIDGETS_PATH}
            component={CrmDashboardReportWidgets}
            catagories="Visualization"
            actions="read"
            exact
            user={user}
          />

          <ProtectedRoute
            path={CRM_LEADS_PATH}
            component={CrmLeads}
            exact
            user={user}
            catagories="CrmLead"
            actions="index"
          />

          <ProtectedRoute
            path={CRM_LEADS_SELLER_PATH}
            component={CrmLeads}
            exact
            user={user}
            catagories="CrmLead"
            actions="index"
          />

          <ProtectedRoute
            path={CRM_LEADS_BUYER_PATH}
            component={CrmLeads}
            exact
            user={user}
            catagories="CrmLead"
            actions="index"
          />
          <ProtectedRoute
            path={CRM_LEADS_VAS_PATH}
            component={CrmLeads}
            exact
            user={user}
            catagories="CrmLead"
            actions="index"
          />
          <ProtectedRoute
            path={CRM_LEAD_NEW_PATH}
            component={CrmSaveLead}
            user={user}
            catagories="CrmLead"
            actions="create"
          />

          {!!restrictedModules && !restrictedModules.includes(RESTRICTED_MODULES.QUOTATIONS) && (
            <ProtectedRoute
              path={CRM_CARS_ADD_QUOTATION}
              component={CrmSaveQuotation}
              user={user}
              catagories="CrmLead"
              actions="create"
            />
          )}

          <ProtectedRoute
            path={CRM_LEAD_EDIT_PATH}
            component={CrmSaveLead}
            user={user}
            exact
            catagories="CrmLead"
            actions="update"
          />
          <ProtectedRoute
            path={CRM_DUPLICATION_SEARCH}
            component={CrmDuplicationSearch}
            exact
            user={user}
            actions="index"
          />
          {/* Uncomment for "Edit User Profile" feature */}
          <ProtectedRoute
            path={CRM_EDIT_USER_SETTINGS_PATH}
            component={UserSettingsPage}
            user={user}
            exact
            catagories="CrmLead"
            actions="update"
          />

          <ProtectedRoute
            path={CRM_LEAD_DETAIL_PATH}
            component={CrmLeadDetail}
            user={user}
            catagories="CrmLead"
            actions="read"
          />

          <ProtectedRoute
            path={CRM_TASKS_PATH}
            component={CrmTasks}
            exact
            user={user}
            catagories="Task"
            actions="index"
          />
          <ProtectedRoute
            path={CRM_TASKS_PATH_DETAIL}
            component={CrmTasks}
            exact
            user={user}
            catagories="Task"
            actions="index"
          />
          <ProtectedRoute
            path={CRM_CICO_PATH}
            component={CrmCiCo}
            exact
            user={user}
            catagories="Cico"
            actions="index"
          />
          <ProtectedRoute
            path={CRM_CALLS_PATH}
            component={CrmCalls}
            exact
            user={user}
            catagories="Recording"
            actions="index"
          />
          <ProtectedRoute
            path={CRM_CALL_QC_PATH}
            component={CallQC}
            exact
            user={user}
            catagories={PERMISSIONS.EvaluationReport.NAME}
            actions={PERMISSIONS.EvaluationReport.ACTIONS.CREATE}
          />

          <ProtectedRoute
            path={CRM_CALL_VIEW_EDIT_QC_PATH}
            component={CallQC}
            exact
            user={user}
            catagories={PERMISSIONS.EvaluationReport.NAME}
            actions={PERMISSIONS.EvaluationReport.ACTIONS.READ}
          />

          <ProtectedRoute
            path={CRM_CICO_NEW_PATH}
            component={CrmSaveCico}
            exact
            user={user}
            catagories="Cico"
            actions="create"
          />
          <ProtectedRoute
            path={CRM_CICO_DETAIL_PATH}
            component={CrmCicoDetail}
            exact
            user={user}
            catagories="Cico"
            actions="read"
          />
          <ProtectedRoute
            path={CRM_CICO_EDIT_PATH}
            component={CrmSaveCico}
            exact
            user={user}
            catagories="Cico"
            actions="update"
          />

          <ProtectedRoute
            path={CRM_LOCATIONS_PATH}
            component={CrmLocations}
            exact
            user={user}
            catagories="Location"
            actions="index"
          />
          <ProtectedRoute
            path={CRM_STOCK_COUNT_PATH}
            component={CrmStockCount}
            exact
            user={user}
            catagories="StockCounter"
            actions="index"
          />
          <ProtectedRoute
            path={CRM_STOCK_COUNT_DETAIL_PATH}
            component={CrmStockCountDetail}
            exact
            user={user}
            catagories="StockCounter"
            actions="read"
          />

          {/* /crm/payments Route */}
          <ProtectedRoute
            path={CRM_PAYMENTS_PATH}
            component={CrmPayments}
            exact
            user={user}
            catagories="CrmPayment"
            actions="index"
          />

          {!!restrictedModules && !restrictedModules.includes(RESTRICTED_MODULES.APPROVALS) && (
            <>
              <ProtectedRoute
                path={CRM_APPROVAL_PATH}
                component={CrmApprovals}
                exact
                user={user}
                catagories="Approval"
                actions="index"
              />

              <ProtectedRoute
                path={CRM_ADD_APPROVAL}
                component={CrmAddApproval}
                exact
                user={user}
                catagories="Approval"
                actions="create"
              />
              <ProtectedRoute
                path={CRM_APPROVAL_DETAIL_PATH}
                component={CrmApprovalDetailPage}
                exact
                user={user}
                catagories="Approval"
                actions="read"
              />
            </>
          )}

          <ProtectedRoute
            path={CRM_PAYMENTS_NEW_PATH}
            component={CrmSavePayment}
            exact
            user={user}
            catagories="CrmPayment"
            actions="create"
          />

          <ProtectedRoute
            path={CRM_PAYMENTS_EDIT_PATH}
            component={CrmSavePayment}
            exact
            user={user}
            catagories="CrmPayment"
            actions="update"
          />
          <ProtectedRoute
            path={CRM_CLIENT_EDIT_PATH}
            component={CrmClientEditDetail}
            exact
            user={user}
            catagories="Client"
            actions="update"
          />

          <ProtectedRoute
            path={CRM_CLIENT_VIEW_PATH}
            component={CrmClientDetail}
            exact
            user={user}
            catagories="Client"
            actions="read"
          />
          <ProtectedRoute
            path={CRM_CARS_PATH}
            component={Inventories}
            exact
            user={user}
            catagories="Car"
            actions="index"
          />

          <ProtectedRoute
            path={CRM_CARS_STOCK_PATH}
            component={SaleStockInventories}
            exact
            user={user}
            catagories="Car"
            actions="index"
          />

          <ProtectedRoute
            path={CRM_CARS_DETAIL_PATH}
            component={InventoryDetail}
            exact
            user={user}
            catagories="Car"
            actions="read"
          />

          {!!restrictedModules && !restrictedModules.includes(RESTRICTED_MODULES.EXTERNAL_AD) && (
            <>
              <ProtectedRoute
                path={CRM_CARS_AD_POST}
                component={CrmInventoryAddPost}
                exact
                user={user}
                catagories="Car"
                actions="read"
              />
              <ProtectedRoute
                path={CRM_CARS_AD_POST_EDIT}
                component={CrmInventoryAddPost}
                exact
                user={user}
                catagories="Car"
                actions="edit"
              />
              <ProtectedRoute
                path={CRM_CARS_AD_POST_DETAIL}
                component={CrmInventoryAdPostDetail}
                exact
                user={user}
                catagories="Car"
                actions="read"
              />
            </>
          )}

          <ProtectedRoute
            path={CRM_CARS_EDIT_PATH}
            component={SaveInventory}
            exact
            user={user}
            catagories="Car"
            actions="update"
          />

          <ProtectedRoute
            path={CRM_CARS_EDIT_SOLD_CAR_PATH}
            component={SaveInventory}
            exact
            user={user}
            catagories="Car"
            actions="update_sold"
          />

          <ProtectedRoute
            path={CRM_CARS_NEW_PATH}
            component={SaveInventory}
            exact
            user={user}
            catagories="Car"
            actions="create"
          />
          {/* /crm/clients Route */}
          <ProtectedRoute
            path={CRM_CLIENTS_PATH}
            component={CrmClients}
            exact
            user={user}
            catagories="Client"
            actions="index"
          />

          <ProtectedRoute
            path={EVENT_CALENDAR_PATH}
            component={CrmCalendar}
            exact
            user={user}
            catagories="Appointment"
            actions="index"
          />
          <ProtectedRoute
            path={CRM_REMINDER_PATH}
            component={CrmReminders}
            exact
            user={user}
            catagories="Reminder"
            actions="index"
          />

          {!!restrictedModules && !restrictedModules.includes(RESTRICTED_MODULES.ROTA) && (
            <>
              <ProtectedRoute
                path={CRM_ROTA_PLAN_PATH}
                component={CrmRotaPlan}
                exact
                user={user}
                catagories="Shift"
                actions="index"
              />
              <ProtectedRoute
                path={CRM_ADD_SCHEDULE}
                component={CrmAddSchedule}
                exact
                user={user}
                catagories="Shift"
                actions="create_shifts"
              />
              <ProtectedRoute
                path={CRM_EDIT_SCHEDULE}
                component={CrmAddSchedule}
                exact
                user={user}
                catagories="Shift"
                actions="update"
              />
            </>
          )}

          {!!restrictedModules && !restrictedModules.includes(RESTRICTED_MODULES.LEAVE) && (
            <>
              <ProtectedRoute
                path={CRM_APPLY_LEAVE_NEW_PATH}
                component={CrmApplyLeave}
                exact
                user={user}
                catagories="Leave"
                actions="create"
              />
              <ProtectedRoute
                path={CRM_APPLY_LEAVE_EDIT_PATH}
                component={CrmApplyLeave}
                exact
                user={user}
                catagories="Leave"
                actions="update"
              />
            </>
          )}

          {!!restrictedModules && !restrictedModules.includes(RESTRICTED_MODULES.CONTRACT) && (
            <>
              <ProtectedRoute
                path={CRM_CONTRACT_NEW_PATH}
                component={CrmSaveContract}
                exact
                user={user}
                catagories="Contract"
                actions="create"
              />
              {/* /crm/contracts Route */}
              <ProtectedRoute
                path={CRM_CONTRACTS_PATH}
                component={CrmContracts}
                exact
                user={user}
                catagories="Contract"
                actions="index"
              />

              {/* /crm/contracts/id/detail */}
              <ProtectedRoute
                path={CRM_CONTRACT_DETAIL_PATH}
                component={CrmContractDetail}
                exact
                user={user}
                catagories="Contract"
                actions="read"
              />
            </>
          )}

          <IF condition={isAdmin}>
            <ProtectedRoute path={CRM_USERS_PATH} component={Users} user={user} categories="User" actions="index" />

            <ProtectedRoute
              path={CRM_AUTOMATE_BRANCH_CAPACITY_PATH}
              component={CRMAutomateBranchCapacity}
              exact
              user={user}
              categories="User"
              actions="index"
            />

            <ProtectedRoute
              path={CRM_USER_ASSIGNMENT_PATH}
              exact
              component={SaveUserConfiguration}
              user={user}
              categories="User"
              actions="update"
            />

            <ProtectedRoute
              exact
              path={CRM_ROLES_PATH}
              component={Roles}
              user={user}
              categories="Role"
              actions="index"
            />

            <ProtectedRoute
              exact
              path={CRM_NEW_ROLE_PATH}
              component={SaveRole}
              user={user}
              categories="Role"
              actions="create"
            />
            <ProtectedRoute
              path={CRM_EDIT_ROLE_PATH}
              exact
              component={SaveRole}
              user={user}
              categories="Role"
              actions="update"
            />

            <ProtectedRoute
              path={CRM_AUTHENTICATION_TOKEN_PATH}
              exact
              component={AuthenticationTokens}
              user={user}
              categories="AuthenticationToken"
              actions="index"
            />
            <ProtectedRoute
              path={CRM_NEW_AUTHENTICATION_TOKEN_PATH}
              exact
              component={SaveAuthenticationToken}
              user={user}
              categories="AuthenticationToken"
              actions="create"
            />
            <ProtectedRoute
              path={CRM_EDIT_AUTHENTICATION_TOKEN_PATH}
              exact
              component={SaveAuthenticationToken}
              user={user}
              categories="AuthenticationToken"
              actions="update"
            />
            <ProtectedRoute path={CRM_TENANT_PATH} component={SaveTenant} user={user} categories="Tenant" />

            <ProtectedRoute
              path={CRM_DYNAMIC_MODELS_PATH}
              exact
              component={DynamicModels}
              user={user}
              categories="DynamicModel"
              actions="index"
            />

            <ProtectedRoute
              path={CRM_DYNAMIC_FIELDS_PATH}
              exact
              component={DynamicFields}
              user={user}
              categories="DynamicField"
              actions="index"
            />

            <ProtectedRoute
              path={CRM_LEAD_SOURCE_PATH}
              exact
              component={LeadSources}
              user={user}
              catagories="LeadSource"
              actions="index"
            />
            <ProtectedRoute
              path={CRM_NEW_LEAD_SOURCE_PATH}
              exact
              component={SaveLeadSource}
              user={user}
              catagories="LeadSource"
              actions="create"
            />
            <ProtectedRoute
              path={CRM_EDIT_LEAD_SOURCE_PATH}
              exact
              component={SaveLeadSource}
              user={user}
              catagories="LeadSource"
              actions="update"
            />
            <ProtectedRoute
              path={CRM_TASK_TYPES_PATH}
              exact
              component={CrmTaskTypes}
              user={user}
              catagories="TaskType"
              actions="index"
            />
            <ProtectedRoute
              path={CRM_LOCATION_NEW_PATH}
              exact
              component={CrmSaveLocation}
              user={user}
              catagories="Location"
              actions="create"
            />
            <ProtectedRoute
              path={CRM_LOCATION_EDIT_PATH}
              exact
              component={CrmSaveLocation}
              user={user}
              catagories="Location"
              actions="update"
            />

            <ProtectedRoute
              path={CRM_ITEM_LISTS_PATH}
              exact
              component={CrmItemList}
              user={user}
              catagories="ItemList"
              actions="index"
            />
            <ProtectedRoute path={CRM_APPROVALS_ADMIN} exact component={CrmApprovalAdmin} user={user} />
            <ProtectedRoute path={CRM_FIELD_MAPPING_PATH} exact component={CrmFieldMapper} user={user} />
            <ProtectedRoute
              path={CRM_RETAIL_PRICE_REDUCTION}
              component={CRMRetailPriceReduction}
              exact
              user={user}
              catagories="RetailPriceReduction"
              actions="index"
            />
            <ProtectedRoute
              path={CRM_WHATSAPP_TRIGGER_CONTROLLER}
              component={CRMWhatsappTriggerController}
              exact
              user={user}
              catagories="Whatsapp"
              actions="create"
            />
          </IF>
        </Suspense>
      </IF>
      <IF condition={!platformAvailable}>
        <NotAvailable />
      </IF>
    </App>
  );
};

export default CrmRoutes;
