export const APPROVAL_STATUSES = {
  PENDING: "pending",
  CANCELLED: "cancelled",
  APPROVED: "approved",
  REJECTED: "rejected",
  ACCEPTED: "accepted",
  TIMEOUT: "timeout",
  REMOVED: "removed",
};
export const APPROVER_STATUSES = {
  APPROVED: "approved",
  NOT_APPROVED: "not-approved",
  PENDING: "pending",
  MORE_INFO_REQUESTED: "more-info-requested",
};

export const APPROVAL_DISPOSITIONS = {
  FINAL_INFO: "final-info-requested",
  FIRST_INFO: "first-info-requested",
  AWAITING_FINAL_APPROVAL: "awaiting-final-approval",
  AWAITING_FIRST_APPROVAL: "awaiting-first-approval",
};
export const APPROVER_TYPE = {
  FINAL: "final-approver",
  INITIAL: "initial-approver",
};

export const APPROVAL_TYPE = {
  FLEET: "fleet",
  FLEET_NAME: "Fleet",
};
