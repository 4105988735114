import React from "react";
import { Checkbox as AntdCheckbox } from "antd";

const Checkbox = props => {
  if (props.group) {
    return <AntdCheckbox.Group {...props}></AntdCheckbox.Group>;
  } else {
    return <AntdCheckbox {...props}></AntdCheckbox>;
  }
};

export default Checkbox;
