import * as Yup from "yup";

import { checkifTenantIsDubizzle, isFieldEditable } from "../../../helpers/Util";
import { DYNAMIC_FIELD_NAMES } from "constant/appConstants";
import { regex } from "../../../constant/regex";
import { strings } from "../../../constant/strings";

export const INTEGER_TYPE = "integer";
export const BOOLEAN_TYPE = "boolean";
export const STRING_TYPE = "string";
export const NUMBER_TYPE = "number";
export const OBJECT_TYPE = "object";
export const ITEM_LIST_TYPE = "item_list";
export const DATE_TIME_TYPE = "datetime";
export const DATE_TYPE = "date";
export const TEXT_AREA_TYPE = "text";
export const GENERIC_LIST_TYPE = "generic_list";
export const MULTI_SELECT = "multi-select";
export const SINGLE_SELECT = "single-select";
export const LINK_TYPE = "link";
export const ATTACHMENT_TYPE = "attachment";
export const AMOUNT_TYPE = "price";
export const USER_LIST = "user_list";

const getDynamicFieldsNameValidationSchema = ({
  item = {},
  dynamicFieldName = "",
  activeColumnsModel = "",
  dynamicFieldNameSchema = {},
  tenant = {},
  extraProp = {},
}) => {
  const isTenantDubizzle = checkifTenantIsDubizzle({ tenant });

  const fieldKey = getFieldKey(item, activeColumnsModel);

  const fieldNameValidationSchemaLookUp = {
    [DYNAMIC_FIELD_NAMES.VIN]: () => {
      const vinValidation = Yup.object().test(fieldKey, strings.no_spaces_vin, val => {
        return new RegExp(regex.noSpaces).test(val?.value);
      });

      if (isTenantDubizzle) {
        return vinValidation
          .test(fieldKey, strings.no_special_characters_allowed, val => {
            return new RegExp(regex.noSpecialCharacters).test(val?.value);
          })
          .test(fieldKey, strings.vin_plate, val => {
            // Allow empty strings
            if (!val?.value) {
              return true;
            }
            return new RegExp(regex.vinNumber).test(val?.value);
          })
          .nullable();
      }

      return vinValidation;
    },
    [DYNAMIC_FIELD_NAMES.PURCHASE_PRICE]: () => {
      const purchasePriceValidation = Yup.object().test(fieldKey, "Purchase price must be a positive number", val => {
        if (val?.value) {
          return !isNaN(val.value) && parseFloat(val.value) > 0;
        }
        return true;
      });
      return purchasePriceValidation;
    },
    [DYNAMIC_FIELD_NAMES.VEHICLE_REQUIREMENTS]: () => {
      const regEx = item?.field_regex ?? regex.noSpecialCharacters;
      const vehicleRequirements = Yup.object()
        .test(fieldKey, strings.vehicle_requirements_validation, val => {
          if (val?.value) {
            return new RegExp(regEx).test(val.value);
          }
          return true;
        })
        .nullable();
      return vehicleRequirements;
    },
    [DYNAMIC_FIELD_NAMES.CUSTOMER_SIGNATURE]: () => {
      const regEx = /^[A-Za-z\s]+$/;
      const signatureValidation = Yup.object()
        .test(fieldKey, strings.signature_validation, val => {
          if (val?.value) {
            return new RegExp(regEx).test(val.value);
          }
          return true;
        })
        .nullable();
      return signatureValidation;
    },
  };

  if (dynamicFieldName in fieldNameValidationSchemaLookUp) {
    dynamicFieldNameSchema[fieldKey] = fieldNameValidationSchemaLookUp[dynamicFieldName]() ?? {};
  }

  return dynamicFieldNameSchema ?? {};
};

const getDynamicFieldsInvalidMessage = (dynamicFieldName = "") => {
  switch (dynamicFieldName) {
    case DYNAMIC_FIELD_NAMES.VIN:
      return strings.vin_plate;
    case DYNAMIC_FIELD_NAMES.EMIRATES_ID_NUMBER:
      return strings.emirates_id_validation;
    case DYNAMIC_FIELD_NAMES.VEHICLE_REQUIREMENTS:
      return strings.vehicle_requirements_validation;
    case DYNAMIC_FIELD_NAMES.CUSTOMER_TRN:
      return strings.customer_trn_validation;
    default:
      return "Not a valid value";
  }
};

const getFieldKey = (item, activeColumnsModel = "car") => `${activeColumnsModel}_dynamic_${item.field_name}`;
const message = "This field is required";
let invalidValue;
const maxIntegerMessage = "This number is too large";
export const MAXIMUM_ALLOWED_INTEGER_VALUE = 9223372036854775807;
const validField = val => !!val?.value || String(val?.value) === "0";
export const maxValidation = val => Number(val) <= MAXIMUM_ALLOWED_INTEGER_VALUE;

export const getDynamicFieldsValidationSchema = ({
  dynamicFields = [],
  activeColumnsModel,
  activeColumnsPermittedAttributes = {},
  tenant = {},
  extraProp = {},
}) => {
  let regEx;
  let schema = {};
  let dynamicFieldNameSchema = {};
  dynamicFields.map(item => {
    const activeColumnKey = `dynamic_attributes.${activeColumnsModel}.${item.field_name}`;
    const activeColumnsPermittedAttributePayload = activeColumnsPermittedAttributes?.[activeColumnKey];
    invalidValue = getDynamicFieldsInvalidMessage(item.field_name);
    if (!isFieldEditable(activeColumnsPermittedAttributePayload)) return null;
    switch (item.field_data_type) {
      case BOOLEAN_TYPE:
        if (item.is_required) {
          schema[getFieldKey(item, activeColumnsModel)] = Yup.string().required(message).nullable();
        }
        break;
      case ITEM_LIST_TYPE:
        if (item.is_required) {
          schema[getFieldKey(item, activeColumnsModel)] = Yup.string()
            .nullable()
            .required(message)
            .test(getFieldKey(item, activeColumnsModel), "Not a valid field", function (value) {
              return !!value;
            });
        }
        break;
      case INTEGER_TYPE:
        let intFieldRegex = item.field_regex && item.field_regex?.length ? item.field_regex : "[^]*";
        const intRegexPattern = new RegExp(intFieldRegex);

        schema[getFieldKey(item, activeColumnsModel)] = Yup.object({
          value: Yup.number().max(MAXIMUM_ALLOWED_INTEGER_VALUE, maxIntegerMessage).nullable(),
        })
          .test(getFieldKey(item, activeColumnsModel), message, val => {
            if (item.is_required === true) {
              return validField(val);
            }
            return true;
          })
          .test(getFieldKey(item, activeColumnsModel), invalidValue, val => {
            if (val?.value) {
              return intRegexPattern.test(val.value);
            }
            return true;
          })
          .nullable();
        break;
      case STRING_TYPE:
        regEx = item.field_regex ? item.field_regex : "[^]*";
        const stringRegexPattern = new RegExp(regEx);
        schema[getFieldKey(item, activeColumnsModel)] = Yup.object()
          .test(getFieldKey(item, activeColumnsModel), message, val => {
            if (item.is_required === true) {
              return validField(val);
            }
            return true;
          })
          .test(getFieldKey(item, activeColumnsModel), invalidValue, val => {
            if (val?.value) {
              return stringRegexPattern.test(val.value);
            }
            return true;
          })
          .nullable();
        break;
      case AMOUNT_TYPE:
        regEx = item.field_regex ? item.field_regex : "[^]*";
        const amountRegexPattern = new RegExp(regEx);
        schema[getFieldKey(item, activeColumnsModel)] = Yup.object()
          .test(getFieldKey(item, activeColumnsModel), message, val => {
            if (item.is_required === true) {
              return validField(val);
            }
            return true;
          })
          .test(getFieldKey(item, activeColumnsModel), invalidValue, val => {
            if (val?.value) {
              return amountRegexPattern.test(val.value);
            }
            return true;
          })
          .nullable();
        break;
      case TEXT_AREA_TYPE:
        regEx = item.field_regex ? item.field_regex : "[^]*";
        const textRegexPattern = new RegExp(regEx);

        schema[getFieldKey(item, activeColumnsModel)] = Yup.object()
          .test(getFieldKey(item, activeColumnsModel), message, val => {
            if (item.is_required === true) {
              return validField(val);
            }
            return true;
          })
          .test(getFieldKey(item, activeColumnsModel), invalidValue, val => {
            if (val?.value) {
              return textRegexPattern.test(val.value);
            }
            return true;
          })
          .nullable();

        break;
      case DATE_TIME_TYPE:
        schema[getFieldKey(item, activeColumnsModel)] = Yup.object()
          .test(getFieldKey(item, activeColumnsModel), message, val => {
            if (item.is_required === true) {
              return !!val?.value;
            } else {
              return true;
            }
          })
          .nullable();

        break;
      case DATE_TYPE:
        schema[getFieldKey(item, activeColumnsModel)] = Yup.object()
          .test(getFieldKey(item, activeColumnsModel), message, val => {
            if (item.is_required === true) {
              return !!val?.value;
            } else {
              return true;
            }
          })
          .nullable();

        break;
      case MULTI_SELECT:
        regEx = item.field_regex ? item.field_regex : "[^]*";
        if (item.is_required) {
          schema[getFieldKey(item, activeColumnsModel)] = Yup.array()
            .min(1, message)
            .required(message)
            .test("test-array", "Not a valid field", function (value) {
              return !!value?.length;
            });
        }
        break;
      case SINGLE_SELECT:
        regEx = item.field_regex ? item.field_regex : "[^]*";
        if (item.is_required) {
          schema[getFieldKey(item, activeColumnsModel)] = Yup.array()
            .min(1, message)
            .required(message)
            .test("test-array", "Not a valid field", function (value) {
              return !!value?.length;
            });
        }
        break;
      case LINK_TYPE:
        if (item.is_required) {
          schema[getFieldKey(item, activeColumnsModel)] = Yup.object()
            .nullable()
            .required(message)
            .test(getFieldKey(item), "Not a valid field", function (value) {
              return !!value?.link;
            });
        }
        break;
      case ATTACHMENT_TYPE:
        if (item.is_required) {
          schema[getFieldKey(item, activeColumnsModel)] = Yup.object()
            .nullable()
            .required(message)
            .test(getFieldKey(item, activeColumnsModel), "Not a valid field", function (value) {
              return !!value?.type;
            });
        }
        break;
      case USER_LIST:
        if (item.is_required) {
          schema[getFieldKey(item, activeColumnsModel)] = Yup.object()
            .nullable()
            .required(message)
            .test(getFieldKey(item, activeColumnsModel), "Not a valid field", function (value) {
              return !!value?.type;
            });
        }
        break;
      default:
        break;
    }

    // creating validations for dynamic fields depending upon their field names
    dynamicFieldNameSchema = {
      ...dynamicFieldNameSchema,
      ...getDynamicFieldsNameValidationSchema({
        item,
        dynamicFieldName: item.field_name,
        activeColumnsModel,
        dynamicFieldNameSchema,
        tenant,
        extraProp,
      }),
    };
  });

  // merging field type validation schema with field name validation schema
  for (const key in dynamicFieldNameSchema) {
    if (key in schema) {
      schema[key] = schema[key].concat(dynamicFieldNameSchema[key]);
    }
  }

  return schema;
};
