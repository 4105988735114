import { FETCH_LEAD_DETAILS, UPDATE_LEAD_DETAILS, UPDATE_LEAD_PAYMENT } from "../../constant/actionTypes";

export const INITIAL_STATE = {
  lead: {},
  statuses: {},
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LEAD_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_LEAD_DETAILS:
      return {
        ...state,
        lead: { ...action.payload.item },
      };
    case UPDATE_LEAD_PAYMENT:
      return {
        ...state,
        lead: { ...state.lead, ...action.payload },
      };

    default:
      return state;
  }
};
