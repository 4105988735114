import { FETCH_PAYMENTS, SET_PAYMENTS_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  statuses: [],
  paymentLinkStatuses: [],
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PAYMENTS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_PAYMENTS_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    default:
      return state;
  }
};
