import {
  FETCH_RUNNING_LATE_DASHBOARD,
  FETCH_STATUSES_INFO_DASHBOARD,
  SET_BY_CITIES_FILTERS,
  SET_BY_DISPOSITIONS_FILTERS,
  SET_BY_LEAD_SOURCE_FILTERS,
  SET_BY_LEAD_STATUSES_FILTERS,
  SET_BY_TYPES_FILTERS,
  SET_STATUS_FILTERS,
  UPDATE_STATUSES_DATA_FOR_DOGHNUT,
} from "../../constant/actionTypes";

export const INITIAL_STATE = {
  leads_statistics: [],
  loading_leads_statistics: true,
  error_leads_statistics: "",
  leads_statistics_filters: {
    cityId: [],
    inspectionId: [],
    timeFilters: {},
  },
  leads_by_types_filters: {
    statusId: 0,
    byTime: "today_on",
    timeType: "created_at",
  },
  leads_by_lead_source_filters: {
    statusId: 0,
    byTime: "today_on",
    timeType: "created_at",
  },
  leads_by_cities_filters: {
    statusId: 0,
    byTime: "today_on",
    timeType: "created_at",
  },
  leads_by_dispositions_filters: {
    byTime: "today_on",
    timeType: "created_at",
  },
  leads_by_statuses_filters: {
    byTime: "today_on",
    timeType: "created_at",
  },
  statuses: [
    {
      slug: -1,
      lead_filterable_column: "created_at",
      label: "All",
    },
  ],
  timeTypes: [
    { slug: "today_on", label: "Today" },
    { slug: "yesterday_on", label: "Yesterday" },
    { slug: "weekly_on", label: "Weekly" },
    { slug: "monthly_on", label: "Monthly" },
  ],
  running_late_leads: [],
  loading_running_late_leads: true,
  error_running_late_leads: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_STATUSES_INFO_DASHBOARD:
    case FETCH_RUNNING_LATE_DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };
    case SET_STATUS_FILTERS:
      return {
        ...state,
        ...{
          leads_statistics_filters: {
            ...state.leads_statistics_filters,
            ...action.payload.filters,
          },
        },
      };
    case SET_BY_TYPES_FILTERS:
      return {
        ...state,
        ...{
          leads_by_types_filters: {
            ...state.leads_by_types_filters,
            ...action.payload.filters,
          },
        },
      };
    case SET_BY_LEAD_SOURCE_FILTERS:
      return {
        ...state,
        ...{
          leads_by_lead_source_filters: {
            ...state.leads_by_lead_source_filters,
            ...action.payload.filters,
          },
        },
      };
    case SET_BY_CITIES_FILTERS:
      return {
        ...state,
        ...{
          leads_by_cities_filters: {
            ...state.leads_by_cities_filters,
            ...action.payload.filters,
          },
        },
      };

    case SET_BY_DISPOSITIONS_FILTERS:
      return {
        ...state,
        ...{
          leads_by_dispositions_filters: {
            ...state.leads_by_dispositions_filters,
            ...action.payload.filters,
          },
        },
      };

    case SET_BY_LEAD_STATUSES_FILTERS:
      return {
        ...state,
        ...{
          leads_by_statuses_filters: {
            ...state.leads_by_statuses_filters,
            ...action.payload.filters,
          },
        },
      };
    case UPDATE_STATUSES_DATA_FOR_DOGHNUT:
      return {
        ...state,
        ...{
          statuses: [
            {
              slug: -1,
              lead_filterable_column: "created_at",
              label: "All",
            },
            ...action.payload.statuses,
          ],
        },
      };
    default:
      return state;
  }
};
