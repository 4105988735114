import { CustomAlert } from "./CustomAlert";
import { Info } from "react-feather";
import Tooltip from "./Tooltip";
import React from "react";
import { Skeleton } from ".";
import styles from "./FormInput.module.scss";
import cx from "clsx";

const FormInput = props => {
  const {
    label,
    linesCount,
    className = "",
    errorMsg,
    horizontal,
    formRef,
    labelClassName,
    containerClassName,
    loading,
    inputClassName,
    inputOnly,
    textAreaButton,
    textAreaPadding = "",
    columnLeftSection = "3",
    columnRightSection = "9",
    textRight = true,
    inputContainerClass = "",
    isViewable = true,
    modalInput = "",
    left,
    right,
    errorType,
    errorClassName,
    id,
    showRequiredAsterisk,
    isEditable = null,
    inputRef = null,
    style,
    showTooltip = false,
    tooltipLabel = "",
    ...rest
  } = props;

  if (!isViewable) return null;

  const renderLabel = () =>
    label ? (
      <label
        className={
          labelClassName
            ? labelClassName
            : `${horizontal ? `col-sm-${columnLeftSection} col-form-label` : ""} ${textRight ? "text-right" : ""}  ${
                loading ? "pt-0" : ""
              }`.trim()
        }
      >
        {loading ? (
          <Skeleton height={24} />
        ) : (
          <>
            {label}
            {showTooltip && (
              <>
                <Info className={cx(styles.hoverableSvg, "ml-2")} size={15} data-tip data-for={label} color="#898989" />
                <Tooltip id={label} place="top" type="dark" effect="solid" padding="10px">
                  {tooltipLabel}
                </Tooltip>
              </>
            )}
            {showRequiredAsterisk && <sup className={"invalid-error mb-0"}>*</sup>}
          </>
        )}
      </label>
    ) : null;

  const renderInput = () =>
    loading ? (
      <Skeleton height={30} />
    ) : (
      <input
        disabled={isEditable === false || props.disabled === true}
        readOnly={isEditable === false}
        className={inputClassName ? inputClassName : `form-control${errorMsg ? " is-invalid" : ""}`}
        ref={inputRef}
        {...rest}
        style={
          isEditable === false || !!props.disabled
            ? { backgroundColor: "#f2f2f2 !important", color: "#898989 !important" }
            : {}
        }
      />
    );

  const renderTextArea = () =>
    loading ? (
      <Skeleton height={90} />
    ) : (
      <>
        <textarea
          style={
            isEditable === false ? { backgroundColor: "#f2f2f2", color: "#898989", resize: "none" } : { resize: "none" }
          }
          disabled={isEditable === false || props.disabled === true}
          readOnly={isEditable === false}
          className={`${"form-control"} ${textAreaPadding}`}
          rows={linesCount}
          {...rest}
        />
        {textAreaButton && textAreaButton()}
      </>
    );

  return (
    <>
      <div
        className={
          containerClassName != undefined
            ? containerClassName
            : `form-group ${horizontal ? "row form-group-y" : ""} ${className}
            `.trim()
        }
        ref={formRef}
        id={id ? id : ""}
        style={{ ...style }}
      >
        {renderLabel()}
        <div
          className={`${inputContainerClass} ${horizontal ? `col-sm-${columnRightSection}` : ""} ${
            left || right ? `has-icon ${left ? "left-icon" : "right-icon"} ` : ""
          }`}
        >
          {left && left()}
          {linesCount && linesCount > 1 ? renderTextArea() : renderInput()}
          {right && <div className="input-icon">{right()}</div>}

          {!!errorMsg && errorType === "icon" && (
            <CustomAlert message={errorMsg} type="icon" containerClassName={errorClassName} />
          )}
          {!!errorMsg && errorType !== "icon" && <span className={"invalid-error " + errorClassName}>{errorMsg}</span>}
        </div>
      </div>
    </>
  );
};

export default FormInput;
