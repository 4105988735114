import cx from "clsx";
import { Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { fetchCarFeaturesLists } from "../../actions/general/carFeaturesLists.action";
import { AUC_CARS_PATH, CRM_CARS_PATH } from "../../constant/appPaths";
import { DYNAMIC_MODELS, PERMITTED_ATTRIBUTE_MODELS } from "constant/appConstants";
import { strings } from "../../constant/strings";
import { addCarApi, fetchCarAdPost, updateCarApi } from "../../helpers/ApiHelper";
import { formikPropsHelper, ScrollToFieldError } from "../../helpers/FormikHelpers";
import {
  addPermittedProperty,
  appendTimeZone,
  getErrorString,
  getValueByPath,
  handleEmptyFormPayload,
  isEmptyArray,
  isEmptyObject,
  isFieldEditable,
  isFieldRestricted,
  isFieldViewable,
  isParentOptionSelectedForRequiredChild,
  setTimeZone,
} from "../../helpers/Util";
import useValidation from "../../hooks/useValidation";
import { AdditionalInfoInput, AvailabilityInfoCard, CarInfoInput, DemandPriceInput, LocationCard } from "../cars";
import { ButtonLoading, FileUploadDropzone, ImageUploadWithPreview, Modal, Toast } from "../common";
import {
  getDynamicAttachmentIds,
  makeValuesObject,
  setInitialValuesForDynamicFields,
} from "../common/DyamicFields/DynamicFieldHelpers";
import EmptyState from "../common/EmptyState/EmptyState";
import { AddressEmptyState } from "../common/EmptyState/EmptyStateSvgs";
import { UploadIcon, UploadIconWithBg } from "../svg-icon/svg-icons";
import { adPostKeys } from "../../constant/lists";
import WarningMessage from "../cars/WarningDialogue";

const FormikHelper = ({
  formikValidationSchemaHelper,
  dynamicFields,
  setOldDependentField,
  oldDependentField,
  car,
  setChangedValues,
}) => {
  const typeOfFields = "car_dynamic_";
  const { values, setFieldError, setFieldTouched } = useFormikContext();
  const [dependentFieldsToUpdate, setDependentFieldsToUpdate] = useState([]);

  const parentFields = Object.keys(isParentOptionSelectedForRequiredChild({ dynamicFields })) ?? [];

  useEffect(() => {
    if (car && car.id) {
      checkChangedValues();
    }
  }, [values, car]);

  let changedValuesTemp = {};

  function checkChangedValues() {
    for (let i = 0; i < adPostKeys?.length; i++) {
      const currentValue = getValueByPath(values, adPostKeys[i]?.formikKey);
      const prevValue = getValueByPath(car, adPostKeys[i]?.localKey);
      if (currentValue !== prevValue && currentValue) {
        changedValuesTemp[adPostKeys[i]?.formikKey?.split(".")[0]] = { currentValue, prevValue };
      }
    }
    setChangedValues({
      ...changedValuesTemp,
    });
  }
  useEffect(() => {
    parentFields.forEach(parentField => {
      const fieldsForSchemaUpdate = values[`car_dynamic_${parentField}`] ?? [];
      if (!isEmptyArray(fieldsForSchemaUpdate)) {
        const updatedDependentFields = fieldsForSchemaUpdate.flatMap(item => {
          const dependentFields = item?.dependentFields ?? [];
          return !isFieldRestricted(dependentFields) && dependentFields.map(field => ({ ...field, parentField }));
        });

        formikValidationSchemaHelper.appendDependentDynamicFieldsSchema(
          updatedDependentFields,
          oldDependentField,
          setFieldError,
          setFieldTouched,
          typeOfFields
        );

        setOldDependentField(updatedDependentFields);
        setDependentFieldsToUpdate(prevDependentFields => [...prevDependentFields, ...updatedDependentFields]);
      }
    });
  }, [dynamicFields, values]);

  useEffect(() => {
    if (dependentFieldsToUpdate.length > 0) {
      formikValidationSchemaHelper.appendDependentDynamicFieldsSchema(
        dependentFieldsToUpdate,
        oldDependentField,
        setFieldError,
        setFieldTouched,
        typeOfFields
      );
      setOldDependentField(dependentFieldsToUpdate);
      setDependentFieldsToUpdate([]);
    }
  }, [formikValidationSchemaHelper]);

  return null;
};

const CarDetailForm = props => {
  const {
    car,
    history,
    carId,
    crmLead,
    carFeatures,
    inventoryTypes,
    fetchCarFeaturesLists,
    assemblies,
    containerRef,
    className,
  } = props;
  const inventoryPermittedAttributes = props.permissions?.activeColumns?.Car || {};
  const timezone = useSelector(content => content.Configs.tenant.country.timezone);
  const tenant = useSelector(state => state?.Configs.tenant);
  const uploaded = { uploaded: true, uploading: false, inError: false };
  let formikRef = useRef();
  let dynamicFieldsRef = useRef();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [oldDependentField, setOldDependentField] = useState([]);
  const [openWarningDialogue, setOpenWarningDialogue] = useState({
    show: false,
    onConfirm: () => {},
  });
  const [changedValues, setChangedValues] = useState({});
  const { country } = tenant;

  const formikPropHelper = formikPropsHelper(PERMITTED_ATTRIBUTE_MODELS.CAR, props?.dynamicFieldsData?.dynamic_fields, {
    uploaded,
    car,
    crmLead,
  });
  const screenHeight = window.screen.height;
  const { formikValidationSchemaHelper } = useValidation(
    inventoryPermittedAttributes,
    props.dynamicFieldsData?.dynamic_fields.filter(dynamicField => !dynamicField?.is_dependent),
    formikPropHelper.getStaticSchema(),
    DYNAMIC_MODELS.CAR
  );

  useEffect(() => {
    fetchCarFeaturesLists();
  }, [fetchCarFeaturesLists]);

  useEffect(() => {
    setInitialValuesForDynamicFields(
      props.dynamicFieldsData,
      car,
      DYNAMIC_MODELS.CAR,
      formikRef.current && formikRef.current.setFieldValue,
      "",
      timezone
    );
  }, [props.dynamicFieldsData]);

  let retryImages = null;
  let picturesRef = useRef();
  const saveData = async (values, setStatus, cb = () => {}) => {
    try {
      let dynamicAttributes = makeValuesObject(values, DYNAMIC_MODELS.CAR, timezone, inventoryPermittedAttributes);
      const carObj = {
        car: {
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["model.name"],
            {
              model_id: values.model?.id,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["make.name"],
            {
              make_id: values.make?.id,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["variant.name"],
            {
              variant_id: values.variant?.id ?? null,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["model_year"],
            {
              model_year: values.year,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["exterior_color.name"],
            {
              exterior_color_id: values.exteriorColor?.id ?? null,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["registration_city.name"],
            {
              registration_city_id: values.registrationCity?.id ?? null,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["mileage"],
            {
              mileage: values?.mileage,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["registration_no"],
            {
              registration_no: values?.registrationNumber,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["description"],
            {
              description: values?.description,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["body_type.name"],
            {
              body_type_id: values.bodyType?.id,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["engine"],
            {
              engine: values.engineType,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["engine_capacity"],
            {
              engine_capacity: values.engineCapacity,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["transmission"],
            {
              transmission: values.transmission,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["assembly_id"],
            {
              assembly_id: values.assembly?.id || null,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["car_features.name"],
            {
              car_feature_ids: values.carFeatures,
            },
            true
          ),
          ...addPermittedProperty(
            inventoryPermittedAttributes?.["auction_sheet_image_path"],
            {
              auction_sheet_image_id: values.auctionSheetImage?.[0]?.id,
            },
            true
          ),
          ...(isFieldEditable(inventoryPermittedAttributes?.["inventory.additional_attributes"]) && {
            inventory_attributes: {
              ...addPermittedProperty(
                inventoryPermittedAttributes?.["inventory.id"],
                {
                  id: car?.inventory.id,
                },
                true
              ),
              ...addPermittedProperty(
                inventoryPermittedAttributes?.["inventory.inventory_type.name"],
                {
                  inventory_type_id: values?.inventoryType?.id || null,
                },
                true
              ),
              ...addPermittedProperty(
                inventoryPermittedAttributes?.["inventory.ask_price"],
                {
                  ask_price: values?.demandPrice,
                },
                true
              ),

              ...((isFieldEditable(inventoryPermittedAttributes?.["inventory.city.name"]) ||
                isFieldEditable(inventoryPermittedAttributes?.["inventory.crm_lead.id"])) && {
                city_id: values.city?.id,
              }),
              ...addPermittedProperty(
                inventoryPermittedAttributes?.["inventory.location.name"],
                {
                  location_id: values.location?.id || null,
                },
                true
              ),
              ...addPermittedProperty(
                inventoryPermittedAttributes?.["inventory.pictures.file_name"],
                {
                  picture_ids: values.pictures.map(pic => pic.id),
                },
                true
              ),
              ...addPermittedProperty(
                inventoryPermittedAttributes?.["inventory.time_slots.address"],
                {
                  time_slots_attributes: values.addresses.map(
                    ({ city, start_time, end_time, area, building_address, address_link, key, ...rest }) => {
                      return {
                        ...rest,
                        ...addPermittedProperty(
                          inventoryPermittedAttributes?.["inventory.time_slots.building_address"],
                          { building_address: building_address },
                          true
                        ),
                        ...addPermittedProperty(
                          inventoryPermittedAttributes?.["inventory.time_slots.address_link"],
                          { address_link: address_link },
                          true
                        ),
                        city_id: city.id,
                        area_id: area.id,
                        start_time: appendTimeZone(setTimeZone(timezone, start_time), timezone),
                        end_time: appendTimeZone(setTimeZone(timezone, end_time), timezone),
                      };
                    }
                  ),
                },
                true
              ),
            },
          }),
          ...(!isEmptyObject(dynamicAttributes) && { dynamic_attributes: { car: dynamicAttributes } }),
        },
        ...addPermittedProperty(
          inventoryPermittedAttributes?.["inventory.crm_lead.id"],
          {
            crm_lead_id: values.crmLead?.id || null,
          },
          true
        ),
      };

      if (!handleEmptyFormPayload(carObj.car, "car")) return;

      const dynamic_attachment_ids = getDynamicAttachmentIds(carObj.car?.dynamic_attributes?.car);
      if (!isEmptyArray(dynamic_attachment_ids)) {
        Object.assign(carObj.car, { dynamic_attachment_ids: dynamic_attachment_ids });
      }
      if (!!car) {
        const response =
          car?.inventory?.status?.slug === "sold"
            ? await updateCarApi(car.id, carObj, true)
            : await updateCarApi(car.id, carObj);
        if (response) {
          cb();
          if (response.car) {
            history.push({
              pathname:
                history.location.state?.platform === "auction"
                  ? `${AUC_CARS_PATH}/${car.id}`
                  : `${CRM_CARS_PATH}/${car.id}/detail`,
            });
          } else {
            Toast.error(getErrorString(response.errors[0] ?? response));
          }
        }
      } else {
        const response = await addCarApi(carObj);
        if (response) {
          cb();
          if (response.car) {
            history.goBack();
          } else {
            Toast.error(getErrorString(response.errors[0] ?? response));
          }
        }
      }
    } catch (error) {
      Toast.error(getErrorString(error));
    } finally {
      setSubmitLoading(false);
      setConfirmationLoading(false);
    }
  };

  const fetchExternalAd = async (thisCarId = carId) => {
    if (thisCarId) {
      const response = await fetchCarAdPost(thisCarId);

      if (response?.success) {
        return response.external_ad;
      }
    }

    return null;
  };

  const onSubmit = async (values, { setStatus }) => {
    try {
      if (carId && !car) return;

      let picsStillUnDone = values.pictures?.filter(it => {
        return it.inError || !it.uploaded || it.uploading;
      });

      if (picsStillUnDone?.length > 0) {
        picsStillUnDone?.length > 0 && retryImages();
        picturesRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }

      setSubmitLoading(true);
      setStatus({ message: "" });

      const externalAd = await fetchExternalAd();

      if (!isEmptyObject(changedValues) && !!externalAd?.status?.slug) {
        setOpenWarningDialogue(prevState => ({
          ...prevState,
          show: true,
          onConfirm: () => {
            setConfirmationLoading(true);
            saveData(values, setStatus, () => {});
          },
        }));
      } else {
        saveData(values, setStatus, () => {});
      }
    } catch (error) {
      Toast.error(getErrorString(error));
      setStatus({ message: "An error occurred. Please try again later." });
    }
  };

  const inventoryInitialValues = formikPropHelper.getInitialValues();
  const inventoryValidationSchema = formikValidationSchemaHelper.getSchema();
  const containerStyles = {
    minHeight: "100vh",
    paddingBottom: "12vh",
  };
  const invisibleHeightStyles = {
    height: "12vh",
    marginBottom: "-12vh",
  };
  return (
    props.dynamicFieldsData && (
      <Formik
        innerRef={formikRef}
        initialValues={inventoryInitialValues}
        validationSchema={inventoryValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {renderProps => {
          const { handleSubmit, values, status, setFieldValue } = renderProps;
          return (
            <form className={cx("form theme-form db__form-main auction_form", className)}>
              <Modal
                toggle={() =>
                  setOpenWarningDialogue(prevState => ({
                    ...prevState,
                    show: !openWarningDialogue.show,
                  }))
                }
                title={strings.ad_update_confirmation_title}
                open={openWarningDialogue.show}
                body={() => <WarningMessage changedValues={changedValues} country={country} />}
                actions={[
                  {
                    label: strings.cancel,
                    onClick: () => {
                      setOpenWarningDialogue(prevState => ({
                        ...prevState,
                        show: false,
                      }));
                    },
                    color: "secondary",
                  },
                  {
                    label: strings.confirm,
                    color: "primary",
                    onClick: () => openWarningDialogue.onConfirm(),
                    loading: confirmationLoading,
                  },
                ]}
              />

              <FormikHelper
                formikValidationSchemaHelper={formikValidationSchemaHelper}
                dynamicFields={props.dynamicFieldsData?.dynamic_fields}
                setOldDependentField={setOldDependentField}
                oldDependentField={oldDependentField}
                car={car}
                setChangedValues={setChangedValues}
              />
              <div className={"row"} style={screenHeight >= 968 ? containerStyles : {}}>
                <div className="col-8">
                  <ScrollToFieldError />
                  <CarInfoInput {...renderProps} inventoryPermittedAttributes={inventoryPermittedAttributes} />
                  <DemandPriceInput
                    {...renderProps}
                    inventoryPermittedAttributes={inventoryPermittedAttributes}
                    horizontal={false}
                  />
                  <AdditionalInfoInput
                    {...renderProps}
                    inventoryPermittedAttributes={inventoryPermittedAttributes}
                    features={carFeatures}
                    dynamicFieldsRef={dynamicFieldsRef}
                    assemblies={assemblies}
                    car={car}
                    dynamicFieldsData={props.dynamicFieldsData}
                    containerRef={containerRef}
                    oldDependentField={oldDependentField}
                  />
                  {isFieldViewable(inventoryPermittedAttributes?.["inventory.location.name"]) && (
                    <LocationCard
                      {...renderProps}
                      crmLead={crmLead}
                      carId={carId}
                      inventoryTypes={inventoryTypes}
                      inventoryPermittedAttributes={inventoryPermittedAttributes}
                      isEditable={isFieldEditable(inventoryPermittedAttributes?.["inventory.location.name"])}
                      horizontal={false}
                    />
                  )}
                  {isFieldViewable(inventoryPermittedAttributes?.["inventory.time_slots.address"]) && (
                    <div id={strings.address_info}>
                      <AvailabilityInfoCard
                        {...renderProps}
                        cardFrom="inventoryForm"
                        inventoryPermittedAttributes={inventoryPermittedAttributes}
                        titleHeading={strings.address_info}
                        addText={strings.add_new_address}
                        dataKey="addresses"
                        title={strings.address}
                        removeText={strings.remove_address}
                        isRedirectedFromInventoryDetailPageEditAddressButton={
                          !!history?.location?.state?.isRedirectedFromInventoryDetailPageEditAddressButton
                        }
                        isEditable={isFieldEditable(inventoryPermittedAttributes?.["inventory.time_slots.address"])}
                        horizontal={false}
                        EmptyState={
                          !values["addresses"]?.length > 0 && (
                            <EmptyState
                              image={<AddressEmptyState size={186} title="No Address Found" />}
                              style={{ borderRadius: "10px", border: "1px dashed #98A2B3", marginBlockStart: "16px" }}
                            />
                          )
                        }
                        fullWidthBody={true}
                      />
                    </div>
                  )}
                </div>
                <div className="col-4">
                  {isFieldViewable(inventoryPermittedAttributes?.["inventory.pictures.file_name"]) && (
                    <FileUploadDropzone
                      title={strings.upload_photos}
                      label={strings.drop_photos}
                      pictures={values.pictures}
                      field="pictures"
                      setFieldValue={setFieldValue}
                      getRetry={retry => (retryImages = retry)}
                      picturesRef={picturesRef}
                      isEditable={isFieldEditable(inventoryPermittedAttributes?.["inventory.pictures.file_name"])}
                      imageKey={"url"}
                      description={false}
                      image={<UploadIconWithBg size={40} className={"mb-2"} />}
                      btnLink
                      uploadBtnText={
                        <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                          {values.pictures.length > 0 && <UploadIcon size={20} />} Upload Images
                        </div>
                      }
                      dropZoneStyles={{
                        "--dzu-dropdown-border": values.pictures.length > 0 ? 0 : "1px solid #EAECF0",
                        borderRadius: values.pictures.length > 0 ? "0" : "12px",
                        padding: values.pictures.length > 0 && "0",
                      }}
                      imgUploadStyles={{ "--dzu-gallery-thumb-size": "100px" }}
                      headerUploadBtn={values.pictures.length > 0 && true}
                      uploadBtn={!values.pictures.length > 0 && true}
                      shouldResize={false}
                    />
                  )}

                  {isFieldViewable(inventoryPermittedAttributes?.["auction_sheet_image_path"]) && (
                    <ImageUploadWithPreview
                      files={values.auctionSheetImage}
                      setFiles={e => setFieldValue("auctionSheetImage", e)}
                      onRemove={e => setFieldValue("auctionSheetImage", [])}
                      associationKey={"auction_sheet_image"}
                      horizontal={false}
                      cardView
                      containerClassName="text-center"
                      labelClassName="f-w-500"
                      cardTitle="Auction Sheet"
                      isEditable={isFieldEditable(inventoryPermittedAttributes?.["auction_sheet_image_path"])}
                      imageKey={"url"}
                      fileUploaderClass={"inventory-form-imgUpload"}
                      image={
                        !values["auctionSheetImage"]?.length > 0 && <UploadIconWithBg size={40} className={"mb-2"} />
                      }
                      btnLink
                    />
                  )}
                </div>
                <div style={screenHeight >= 968 ? invisibleHeightStyles : {}} />
              </div>
              <div className="text-right position-sticky sticky-bottom sticky-buttons-container mb-3">
                <ButtonLoading
                  type="submit"
                  onClick={handleSubmit}
                  variant=""
                  size="lg"
                  disabled={submitLoading}
                  loading={submitLoading}
                >
                  {strings.submit}
                </ButtonLoading>
              </div>
            </form>
          );
        }}
      </Formik>
    )
  );
};

const mapStateToProps = ({ Configs, PermissionsData }) => ({
  tenant: Configs.tenant,
  permissions: PermissionsData,
});

export default connect(mapStateToProps, {
  fetchCarFeaturesLists,
})(CarDetailForm);
