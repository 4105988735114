import { Select, Skeleton, Typography } from "antd";
import React, { useCallback, useState } from "react";

import PropTypes from "prop-types";
import { filterCriteria } from "utils";
import HoverText from "../hoverable-text/hoverable-text";
import Icon from "../icon/icon";
import Spinner from "../spinner/spinner";
import Switch from "../switch/switch";

function SelectInput(props) {
  const {
    label,
    labelProps,
    labelClass,
    placeholder,
    labelIcon,
    skeletonLoading,
    options,
    getOptionLabel,
    getOptionValue,
    suffixIcon,
    prefixIcon,
    openIcon,
    showSearch,
    serverProps,
    horizontal,
    className,
    onChange,
    errorMsg,
    valueAsObj,
    inputLoading,
    selectGrow,
    toggle,
    zIndex,
    getPopupContainer = true,
    filterOption = (inputValue, option) => filterCriteria(inputValue, option, getOptionLabel),
    ...rest
  } = props;
  const [suffix, setSuffix] = useState(suffixIcon);
  const [prefix, setPrefix] = useState(prefixIcon);
  const [isToggle, setIsToggle] = useState(false);

  const getOptions = useCallback(() => {
    let option = !!toggle && isToggle ? options.filter(e => e[toggle?.key] == true) : options;
    return option && !!option.length
      ? option?.map((item, index) => ({
          ...item,
          value: getOptionValue(item),
          label: getOptionLabel(item),
        }))
      : [];
  }, [options, toggle, isToggle]);

  const onToggleClick = value => {
    setIsToggle(value);
  };

  const renderSelect = () => {
    return (
      <>
        <Select
          style={{ width: "100%" }}
          className={className}
          showSearch={showSearch}
          maxTagCount="responsive"
          maxTagPlaceholder={items => (
            <HoverText
              message={`+${items.length}`}
              data={items.map(getOptionLabel)}
              tooltipContentStyles={{ maxHeight: "200px", overflowY: "auto" }}
            />
          )}
          suffixIcon={
            inputLoading ? (
              <Spinner type="plain" />
            ) : (
              !!suffix && <Icon icon={"AiFillCaretDown"} className="ant-select-suffix" color="#9D9D9D" size={"16px"} />
            )
          }
          placeholder={
            <>
              {prefixIcon && <Icon icon={prefixIcon} />}
              {placeholder && placeholder}
            </>
          }
          onDropdownVisibleChange={() => {
            setSuffix(suffix == suffixIcon ? openIcon : suffixIcon);
            setPrefix(prefix == prefixIcon ? openIcon : suffixIcon);
          }}
          options={getOptions()}
          onChange={onChange}
          filterOption={filterOption}
          status={!!errorMsg ? "error" : undefined}
          dropdownStyle={{ "--select-dropdown-accent-color": rest.accentColor, zIndex: zIndex }}
          {...(getPopupContainer ? { getPopupContainer: triggerNode => triggerNode.parentElement } : {})}
          dropdownMatchSelectWidth={false}
          showArrow
          dropdownRender={menu =>
            toggle ? (
              <>
                <div className="pi-12">
                  <Switch size="small" onChange={onToggleClick} label={toggle?.label} className="selectSearch" />
                </div>
                {menu}
              </>
            ) : (
              menu
            )
          }
          {...rest}
        />
        {!!errorMsg && (
          <Typography.Text type="danger">
            <small>{errorMsg}</small>
          </Typography.Text>
        )}
      </>
    );
  };

  const renderLabel = () => {
    return skeletonLoading ? (
      <Skeleton.Button active size="small" style={{ height: 22 }} />
    ) : (
      label && (
        <label className={labelClass} style={{ display: "block" }}>
          {label}
        </label>
      )
    );
  };

  return (
    <>
      {skeletonLoading ? (
        <Skeleton.Input active style={{ borderRadius: 6, width: "100%" }} />
      ) : (
        <div>
          {renderLabel()}
          {renderSelect()}
        </div>
      )}
    </>
  );
}

SelectInput.propTypes = {
  label: PropTypes.string,
  labelProps: PropTypes.object,
  placeholder: PropTypes.string,
  labelIcon: PropTypes.string,
  name: PropTypes.string,
  skeletonLoading: PropTypes.bool,
  loading: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  suffixIcon: PropTypes.string,
  prefixIcon: PropTypes.string,
  openIcon: PropTypes.string,
  showSearch: PropTypes.bool,
  serverProps: PropTypes.object,
  horizontal: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  errorMsg: PropTypes.string,
};

SelectInput.defaultProps = {
  labelIcon: null,
  skeletonLoading: false,
  options: [],
  getOptionLabel: item => item.label || item.name || item.title || "",
  getOptionValue: item => item.value || item.id,
  suffixIcon: "MdKeyboardArrowDown",
  openIcon: "MdKeyboardArrowUp",
  showSearch: true,
  serverProps: null,
  horizontal: false,
};

export default SelectInput;
