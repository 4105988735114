import React from "react";
import cx from "clsx";
import styles from "./ListItems.module.scss";
import Group from "../Group";
import Card from "../Card";

const ListItems = ({
  className = "",
  icon,
  iconBgColor = "",
  itemHoverColor = "",
  iconContainerSize,
  contentGap,
  template,
  gap,
  style,
  title,
  description,
  descriptionNoWrap
}) => {
  return (
    <Card cardPadding="14px" cardBorderRadius="8px" style={{ border: "1px solid #F0F0F0" }}>
      <Group
        template={template ? template : "30px 1fr"}
        gap={gap ? gap : "15px"}
        className={cx(className, "align-items-center", styles.cardContainer)}
        style={{ ...style }}
      >
        <div
          className={styles.iconContainer}
          style={{
            "--iconBgColor": iconBgColor,
            "--iconContainer": iconContainerSize,
          }}
        >
          {icon}
        </div>
        <div className="flex flexColumn" style={{ gap: contentGap || "8px", overflow: descriptionNoWrap && "hidden" }}>
          <div className={styles.title}>{title}</div>
          <div className={cx(styles.description, descriptionNoWrap && styles.ellipsis)}>{description ?? "–"}</div>
        </div>
      </Group>
    </Card>
  );
};

export default ListItems;
