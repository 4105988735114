import { ADD_UNSCHEDULED_LEADS, FETCH_UNSCHEDULED_LEADS } from "../../constant/actionTypes";

export const INITIAL_STATE = {
  leads: [],
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_UNSCHEDULED_LEADS:
      return {
        ...state,
        leads: [...action.payload.item],
      };
    case FETCH_UNSCHEDULED_LEADS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
