import React, { useState } from "react";
import { Search } from "react-feather";
import { Box } from "@chakra-ui/react";
import AreaSelectModal from "../../AreaSelectModal";
import styles from "./ModalCityFilter.module.scss";
import Skeleton from "../../Skeleton";

const ModalFilter = ({
  areaKey = "",
  cityKey = "",
  filtersObject,
  loading,
  filterSelected,
  label,
  inputClassName,
  levels,
  type = "generic_list",
  SelectModal = AreaSelectModal,
  onChange,
  isMultiOption = false,
}) => {
  const [selected, setSelected] = useState({});
  const [modalShow, setModalShow] = useState(false);

  const onSelect = item => {
    setSelected({ ...item });
    onChange(item);
  };

  const selectedOptions = {
    cityIds:
      (filtersObject?.[cityKey.defaultCityKey] &&
        filtersObject?.[cityKey.defaultCityKey]?.split(",")?.map(value => Number(value))) ??
      (filtersObject?.[cityKey.allCityKey] &&
        filtersObject?.[cityKey.allCityKey]?.split(",")?.map(value => Number(value))) ??
      [],
    areaIds: (filtersObject?.[areaKey] && filtersObject?.[areaKey]?.split(",")?.map(value => Number(value))) ?? [],
    selectAllAreas: !!filtersObject?.[cityKey.allCityKey] ?? false,
  };
  const hasSelected = () => !!Object.keys(selected).length;

  if (loading) {
    return <Skeleton height={30} />;
  }

  return (
    <div className="faq-form">
      <SelectModal
        visible={modalShow}
        setVisible={setModalShow}
        onSelect={onSelect}
        levels={levels}
        type={type}
        variant="crm-area-modal"
        isMultiSelect={isMultiOption}
        selectedOptions={selectedOptions}
      />

      <Search size={18} className={`search-icon ${filterSelected ? "selected-filter-svg" : ""}`} />
      <Box
        cursor={"pointer"}
        onClick={() => {
          setModalShow(show => !show);
        }}
      >
        <input
          className={`form-control ${inputClassName} ${filterSelected ? styles.selectedFilter : ""}`}
          type={"text"}
          readOnly
          style={{ pointerEvents: "none", background: "#ffffff" }}
          placeholder={label}
        />
      </Box>
    </div>
  );
};

export default ModalFilter;
