import cx from "clsx";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AssignUserLocationModal from "../../components/admin/AssignUserLocationModal";
import { BtnDefaultState, Button, Chip, FormSelect } from "../../components/common";
import FormRow from "../../components/common/FormRow";
import { BiCopy, MdClose } from "../../components/svg-icon/svg-icons";
import { RotatimeKeys } from "../../constant/lists";
import { strings } from "../../constant/strings";
import {
  appendTimeZone,
  formatDateWithMonthAndDayName,
  generateUUID,
  getDaysBetweenDates,
  isEmptyArray,
} from "../../helpers/Util";
import { selectTimeZone } from "../../reducers/general/selectors";
import styles from "./CrmAddSchedule.module.scss";
import { getZoneAreaTitle } from "../../helpers/Rota";

const PreviousDates = ({ currentDate, startTime, show, onClick, previousDaysAllowed }) => {
  const startDate = moment(startTime).format("YYYY-MM-DD");
  let previousDays = getDaysBetweenDates(startDate, currentDate);
  if (previousDays.length > 0) {
    previousDays.pop();
  }
  const previousDaysToRender = previousDaysAllowed.reduce((acc, curr) => {
    if (previousDays.includes(curr)) {
      acc.push(curr);
    }
    return acc;
  }, []);
  return (
    <div className={cx(styles.previousDatesMenu, !show && styles.hidden)}>
      {previousDaysToRender.map(day => {
        const formattedDay = formatDateWithMonthAndDayName(day);
        return (
          <div key={day} onClick={onClick({ current: currentDate, previous: day })}>
            {formattedDay}
          </div>
        );
      })}
    </div>
  );
};

const AddShiftContainer = ({
  dateKey,
  values,
  shift,
  previousShift,
  formikProps,
  formRef,
  userId,
  date,
  removeSchema,
  appendSchema,
  dayShiftEntries,
}) => {
  let timeSlots = RotatimeKeys.map(timeKey => ({
    slug: timeKey,
    label: `${timeKey}`,
  }));

  const [levels, setLevels] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [showPreviousDates, setShowPreviousDates] = useState(false);
  const timezone = useSelector(selectTimeZone);
  const previousDaysAllowed = dayShiftEntries.map(a => a[0]);

  const onSelect = item => {
    formikProps.setFieldValue(shift.formikKey, {
      ...shift,
      ...(item.areas && {
        areas: [...formikProps.values[shift.formikKey]?.areas, ...item?.areas],
      }),
      ...(item.zones && {
        zones: [...formikProps.values[shift.formikKey]?.zones, ...item?.zones],
      }),
    });
  };

  const renderChip = (list, key) =>
    list?.map(e => (
      <Chip
        key={`${e.id}-${generateUUID()}`}
        title={getZoneAreaTitle(e) ?? ""}
        titleContainerClassName="text-nowrap"
        containerClassName="mb-2"
        onClose={() => {
          const modelsList = [...list];
          let index = modelsList.findIndex(item => item.id === e.id);
          modelsList.splice(index, 1);
          list[key] = [...modelsList];
          formRef &&
            formRef.current.setFieldValue(shift.formikKey, {
              ...shift,
              [key]: modelsList,
            });
        }}
        chipBorderRadius="4px"
        chipBgColor="#F0EFEF"
        icon={<MdClose color="#3E3E3E" />}
      />
    ));

  const renderField = (title, levels, value, key, btnClassName, errorMsg, touched) => (
    <div>
      {!isEmptyArray(value) && (
        <div className={cx("d-flex align-items-center justify-content-between", styles.renderTitleBtn)}>
          <div className={cx(styles.renderFieldTitle)}>{title}</div>
          <Button
            onClick={e => {
              e.preventDefault();
              setModalShow(true);
              setLevels(levels);
            }}
            className={cx("p-0", styles.renderFieldButton)}
            btnText={`+ Add ${title}`}
            color="link"
          />
        </div>
      )}
      <div className={!isEmptyArray(value) ? cx(styles.chipsContainer) : null}>{renderChip(value, key)}</div>
      {isEmptyArray(value) && (
        <div>
          <BtnDefaultState
            onClick={() => {
              setModalShow(true);
              setLevels(levels);
            }}
            className={cx("custom-field-state", btnClassName)}
            btnText={`Select ${title}`}
            iconStyle={{ "--media-icon-container-size": "35px" }}
          />
          {!!errorMsg && <p className="invalid-error">{errorMsg && touched && errorMsg}</p>}
        </div>
      )}
    </div>
  );

  const onClickPreviousDay =
    ({ current, previous }) =>
    e => {
      const currentDayShifts = Object.values(formikProps.values).filter(shift => {
        return shift.date === current;
      });
      const previousDayShifts = Object.values(formikProps.values).filter(shift => {
        return shift.date === previous;
      });
      currentDayShifts.forEach(shift => {
        formikProps.setFieldValue(shift.formikKey, { ...shift, _destroy: true });
        removeSchema(shift.formikKey);
      });
      previousDayShifts.forEach((shift, index) => {
        if (!shift?._destroy) {
          const originalDateForFormikKey = moment(dateKey);
          const convertedDateForFormikKey = originalDateForFormikKey.format("YYYYMMDD");
          const formikKey = `shift-${convertedDateForFormikKey}-${currentDayShifts.length + index + 1}`;
          formikProps.setFieldValue(formikKey, {
            isNewEntity: true,
            date: current,
            title: formatDateWithMonthAndDayName(current),
            formikKey,
            min_time: shift.min_time,
            max_time: shift.max_time,
            zones: shift.zones,
            areas: shift.areas,
          });
          appendSchema(formikKey);
        }
      });
    };

  return (
    <>
      {previousShift && (
        <div onMouseEnter={() => setShowPreviousDates(true)} onMouseLeave={() => setShowPreviousDates(false)}>
          <Button
            onClick={e => {
              e.preventDefault();
            }}
            className={cx("p-0", styles.copyPreviousBtn)}
            btnText="Copy Previous Date"
            icon={<BiCopy size={20} />}
            color="link"
          />
          <PreviousDates
            startTime={formikProps.values.start_time}
            currentDate={shift.date}
            show={showPreviousDates}
            onClick={onClickPreviousDay}
            previousDaysAllowed={previousDaysAllowed}
          />
        </div>
      )}

      <div className="form theme-form">
        <FormRow horizontal={false} label={strings.date}>
          <div className="grid" style={{ "--template": "repeat(2, 1fr) auto", "--gap": "16px" }}>
            <FormSelect
              options={timeSlots}
              placeholder={"From"}
              onBlur={() => {
                formikProps.setFieldTouched(`${shift.formikKey}-minTime`);
                return formikProps.handleBlur(shift.formikKey);
              }}
              value={shift.min_time}
              errorMsg={
                formikProps?.errors?.[shift?.formikKey]?.min_time &&
                formikProps?.touched?.[`${shift?.formikKey}-minTime`] &&
                formikProps?.errors?.[shift?.formikKey]?.min_time
              }
              onChange={e => {
                formikProps.setFieldValue(shift.formikKey, {
                  ...shift,
                  min_time: e.target.value,
                  start_time: appendTimeZone(new Date(`${shift.date} ${e.target.value}`), timezone),
                });
              }}
              setSlugAsValue
              className="m-b-0"
            />
            <FormSelect
              options={timeSlots}
              placeholder={"To"}
              onBlur={() => {
                formikProps.setFieldTouched(`${shift.formikKey}-maxTime`);
                return formikProps.handleBlur(shift.formikKey);
              }}
              value={shift.max_time}
              errorMsg={
                formikProps?.errors?.[shift?.formikKey]?.max_time &&
                formikProps?.touched?.[`${shift?.formikKey}-maxTime`] &&
                formikProps?.errors?.[shift?.formikKey]?.max_time
              }
              onChange={e => {
                formikProps.setFieldValue(shift.formikKey, {
                  ...shift,
                  max_time: e.target.value,
                  end_time: appendTimeZone(new Date(`${shift.date} ${e.target.value}`), timezone),
                });
              }}
              setSlugAsValue
              className="m-b-0"
            />

            <Button
              onClick={e => {
                e.preventDefault();
                formikProps.setFieldValue(
                  shift.formikKey,
                  !shift?.isNewEntity ? { ...shift, _destroy: true } : undefined
                );
                removeSchema(shift.formikKey);
              }}
              className={cx("p-0", styles.btnRemove)}
              btnText={"- Remove"}
              color="link"
            />
          </div>
        </FormRow>
        <div className={styles.addFieldsContainer}>
          {renderField(
            strings.zones,
            ["cities", "zones"],
            shift.zones,
            "zones",
            "",
            formikProps?.errors?.[shift?.formikKey]?.zones,
            formikProps?.touched?.[`${shift?.formikKey}-zones`]
          )}
          {renderField(strings.areas, ["cities", "zones", "areas"], shift.areas, "areas")}
        </div>
        <AssignUserLocationModal
          visible={modalShow}
          setVisible={setModalShow}
          onSelect={onSelect}
          levels={levels}
          values={values}
        />
      </div>
    </>
  );
};
export default AddShiftContainer;
