import { IF, Skeleton } from ".";
import React, { useEffect, useState } from "react";
import { fetchGenericListsApi, fetchItemListsApi, fetchItemListsWithoutPaginationApi } from "../../helpers/ApiHelper";

import { CustomAlert } from "./CustomAlert";
import { getErrorString } from "../../helpers/Util";

const DynamicList = ({ title, params, onSelect, apiCall, type = "item_list", initialList = null }) => {
  const [list, setList] = useState(initialList);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    params && fetchData();
  }, [params]);

  const fetchData = async () => {
    setLoading(true);
    const response = await (apiCall
      ? apiCall(params)
      : type === "generic_list"
      ? fetchGenericListsApi(params)
      : fetchItemListsWithoutPaginationApi(params));
    if (response) {
      setLoading(false);
      if (response.success) {
        setList(response[type === "generic_list" ? "generic_lists" : "item_lists"]);
      } else {
        setError(getErrorString(response));
      }
    }
  };
  return (
    <div className="modal-inner-wrapper">
      <h6>{title}</h6>
      <div className="modal-list-wrapper d-flex flex-column">
        <IF condition={loading}>
          {[1, 2, 3, 4, 5, 6, 7].map(e => (
            <Skeleton
              key={e}
              height={45}
              themeProps={{ color: "#DCDCDC", highlightColor: "#F0F0F0" }}
              className="my-2"
            />
          ))}
        </IF>
        <IF condition={list}>
          {list?.map(e => (
            <div
              className={`modal-list d-flex flex-row align-items-center ${selected[0] == e.id ? "selected" : ""}`}
              onClick={() => {
                setSelected([e.id]);
                onSelect(e);
              }}
              key={e.id}
            >
              <span>{e[type === "generic_list" ? "name" : "select_value"]}</span>
            </div>
          ))}
        </IF>
        <CustomAlert message={error} />
      </div>
    </div>
  );
};

export default DynamicList;
