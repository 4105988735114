import {
  ADD_CRM_LEAD,
  ADD_CRM_LEAD_FILTERS,
  CLEAR_CRM_LEADS,
  FETCH_CRM_LEADS,
  UPDATE_CRM_LEAD,
  SET_CRM_LEADS_LOADING,
} from "../../constant/actionTypes";

export const INITIAL_STATE = {
  list: [],
  filters: {
    status_id: "",
    id: "",
    client_phone: "",
    client_name: "",
  },
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  call_outcomes: [],
  statuses: [],
  loading: true,
  error: "",
  restrictions: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CRM_LEAD:
      return {
        ...state,
        list: [action.payload.item].concat([...state.list]),
      };
    case UPDATE_CRM_LEAD:
      const leadList = state.list;
      let index = leadList.findIndex(e => e.id === action.payload.item.id);
      leadList.splice(index, 1, { ...action.payload.item });
      return {
        ...state,
        list: [...leadList],
      };
    case FETCH_CRM_LEADS:
      return {
        ...state,
        ...action.payload,
      };

    case ADD_CRM_LEAD_FILTERS:
      return {
        ...state,
        list: [],
        pagination: {
          current_page: 1,
          next_page: null,
          prev_page: null,
        },
        loading: true,
        error: "",
        filters: { ...action.payload.item },
      };
    case CLEAR_CRM_LEADS:
      return {
        ...state,
        INITIAL_STATE,
      };

    case SET_CRM_LEADS_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };

    default:
      return state;
  }
};
