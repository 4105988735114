import en from "../assets/i18n/en.json";
import { getCookie } from "./CookieHelper";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ur from "../assets/i18n/ur.json";

class LanguageHelper {
  static #i18Ins;
  static geti18Instance() {
    if (!this.#i18Ins) {
      i18n.use(initReactI18next).init({
        resources: {
          en: {
            translation: en,
          },
          ur: {
            translation: ur,
          },
        },
        lng: getCookie("language") || "en",
        fallbackLng: "en",
        interpolation: {
          escapeValue: false,
        },
      });
      this.#i18Ins = i18n;
    }
    return this.#i18Ins;
  }
}

export default LanguageHelper;
