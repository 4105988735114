import { FLUSH_DYNAMIC_FILTERS_DATA, SET_DYNAMIC_FILTERS_DATA } from "../../constant/actionTypes";

const INITIAL_STATE = {
  filterData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DYNAMIC_FILTERS_DATA:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          ...action.payload,
        },
      };
    case FLUSH_DYNAMIC_FILTERS_DATA:
      return {
        ...state,
        filterData: action.payload,
      };

    default:
      return state;
  }
};
