import { CustomAlert } from "./CustomAlert";
import React from "react";
import { Skeleton } from ".";

const FormInputGroup = props => {
  const {
    label,
    linesCount,
    className = "",
    errorMsg,
    horizontal,
    formRef,
    labelClassName,
    containerClassName,
    loading,
    inputClassName,
    inputOnly,
    textAreaButton,
    textAreaPadding = "",
    textRight = "",
    inputContainerClass = "",
    modalInput = "",
    left,
    right,
    errorType,
    errorClassName,
    id,
    ...rest
  } = props;

  const renderLabel = () =>
    label ? (
      <label
        className={
          labelClassName
            ? labelClassName
            : `${horizontal ? "col-sm-3 col-form-label" : ""} ${textRight ? "text-right" : ""}  ${
                loading ? "pt-0" : ""
              }`.trim()
        }
      >
        {loading ? <Skeleton height={24} /> : label}
      </label>
    ) : null;

  const renderInput = () =>
    loading ? (
      <Skeleton height={30} />
    ) : (
      <input className={inputClassName ? inputClassName : `form-control${errorMsg ? " is-invalid" : ""}`} {...rest} />
    );

  const renderTextArea = () =>
    loading ? (
      <Skeleton height={90} />
    ) : (
      <>
        <textarea className={`${"form-control"} ${textAreaPadding}`} rows={linesCount} {...rest} />
        {textAreaButton && textAreaButton()}
      </>
    );

  return (
    <>
      <div
        className={
          containerClassName != undefined
            ? containerClassName
            : `form-group ${horizontal ? "row form-group-y" : ""} ${className}`.trim()
        }
        ref={formRef}
        id={id ? id : ""}
      >
        {renderLabel()}
        <div className={`${inputContainerClass} ${horizontal ? "col-sm-9" : ""}`}>
          {left && left()}
          {linesCount && linesCount > 1 ? renderTextArea() : renderInput()}
          {right && right()}

          {!!errorMsg && errorType === "icon" ? (
            <CustomAlert message={errorMsg} type="icon" containerClassName={errorClassName} />
          ) : (
            <p className={"invalid-error " + errorClassName}>{errorMsg}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default FormInputGroup;
