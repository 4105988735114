import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { ButtonLoading, CustomAlert, FormInput } from "../../components/common";
import { IoEyeOffOutline, IoEyeOutline, LoginGoogleIcon } from "../../components/svg-icon/svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { cookieObjForUserInfo, getAppPath, getErrorString, removeAppCookies } from "../../helpers/Util";
import { setCookie, setCookieObject } from "../../helpers/CookieHelper";
import { useDispatch, useSelector } from "react-redux";
import ConfigDB from "../../data/config";
import { Formik } from "formik";
import { ToastContainer } from "react-toastify";
import { loginApi, loginWithSSOApi, generateOtpForImpersonation } from "../../helpers/ApiHelper";
import { strings } from "../../constant/strings";
import { withRouter } from "react-router";
import { FETCH_LOGGED_IN_USER_INFO } from "../../constant/actionTypes";
import Logo from "../../components/common/Logo/Logo";
import loginIllustrationUrl from "../../assets/illustrations/login-illustration.svg";
import useMountEffect from "../../hooks/useMountEffect";
import { persistor } from "../../store";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import app from "../../services/firebase";
import OneSignal from "react-onesignal";

const authUser = token => {
  const res = loginWithSSOApi({}, token).then(response => {
    return response;
  });
  return res;
};

const ImpersonatedSignin = ({ history, handleLogin, state, isImpersonatedPath = false, setUser, ...rest }) => {
  // to set theme colors
  const configDB = useSelector(content => content.Customizer.customizer);
  const color = localStorage.getItem("color");
  const primary_color = localStorage.getItem("primary_color");
  const secondary_color = localStorage.getItem("secondary_color");
  const layout_version = localStorage.getItem("layout_version");
  const mix_layout = configDB.color.mix_layout;
  const [inputType, setInputType] = useState("password");
  const dispatch = useDispatch();
  const formikRef = useRef();
  const showPasswordRef = useRef();
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [actorEmail, setActorEmail] = useState("");

  useMountEffect(() => {
    persistor.purge();
    removeAppCookies();
  });

  useEffect(() => {
    dispatch({
      type: "ADD_COLOR",
      payload: {
        color,
        primary_color,
        secondary_color,
        layout_version,
      },
    });

    document.body.className = mix_layout;
    document.documentElement.className = color;

    let link = document.querySelector('link[rel="shortcut icon"]');

    if (!link) {
      link = document.createElement("link");
      link.id = "favicon";
      link.rel = "shortcut icon";
      document.head.appendChild(link);
    }

    link.href = ConfigDB.data.favicon;
  }, []);

  const generateOtp = (token, email) =>
    generateOtpForImpersonation({ access_token: token })
      .then(() => {
        setActorEmail(email || "");
        setShowEmailForm(true);
        formikRef.current.setSubmitting(false);
      })
      .catch(error => {
        formikRef.current.setSubmitting(false);
        formikRef.current.setStatus({ message: error?.error });
      });

  const signInWithProvider = cb => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    formikRef.current.setSubmitting(true);
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        if (isImpersonatedPath) {
          generateOtp(token, result?.user?.email);
        } else {
          const res = authUser(token);
          res.then(result => {
            // The signed-in user info.
            cb({
              success: true,
              ...result,
            });
          });
        }
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error?.code;
        const errorMessage = error?.message;
        // The email of the user's account used.
        const email = error?.customData?.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        cb({
          success: false,
          error,
        });
        // ...
      });
  };

  const callBackForSignIn = response => {
    if (response?.success) {
      if (response?.data?.user && response?.headers?.["authorization"]) {
        formikRef.current.setSubmitting(false);
        if (response.data.user.roles?.length === 1 && response.data?.user?.roles[0]?.name === "Inspector") {
          formikRef.current.setStatus({ message: "User not allowed to login!" });
        } else if (!response.data.user.platforms.length) {
          formikRef.current.setStatus({ message: "No Platform Assigned! Cannot login" });
        } else {
          const currentApp = response.data.user.platforms[0].slug;
          const { platforms, userObjForCookie } = cookieObjForUserInfo(response?.data?.user);
          setCookieObject("userInfo", userObjForCookie);
          dispatch({ type: FETCH_LOGGED_IN_USER_INFO, payload: response.data });
          setCookie("client", response.headers["client"]);
          setCookie("refresh-token", response.headers["refresh-token"]);
          setCookie("authorization", response.headers["authorization"]);
          setCookie("uid", response.headers["uid"]);
          setCookieObject("platforms", platforms);
          setCookie("currentApp", currentApp);
          setUser(true);
          handleLogin();
          history.push({
            pathname: getAppPath(currentApp),
          });
        }
      }
    } else {
      formikRef.current.setSubmitting(false);
      formikRef.current.setStatus({ message: getErrorString(response) });
    }
  };

  const loginAuth = async ({ email, password }, setSubmitting, setStatus) => {
    const oneSignalSubscriptionId = OneSignal?.User?.PushSubscription?.id;
    setSubmitting(true);
    var obj = {
      ...(oneSignalSubscriptionId ? { player_id: oneSignalSubscriptionId } : {}),
      email: email,
      password: password,
      ...(isImpersonatedPath ? { actor_email: actorEmail } : {}),
    };
    try {
      const res = await loginApi(obj);
      if (res?.data?.user && res?.headers?.["authorization"]) {
        setSubmitting(false);
        if (res.data.user.roles?.length === 1 && res.data?.user?.roles[0]?.name === "Inspector") {
          setStatus({ message: "User not allowed to login!" });
        } else if (!res.data.user.platforms.length) {
          setStatus({ message: "No Platform Assigned! Cannot login" });
        } else {
          const currentApp = res.data.user.platforms[0].slug;
          const { platforms, userObjForCookie } = cookieObjForUserInfo(res?.data?.user);
          setCookieObject("userInfo", userObjForCookie);
          dispatch({ type: FETCH_LOGGED_IN_USER_INFO, payload: res.data });
          setCookie("refresh-token", res.headers["refresh-token"]);
          setCookie("authorization", res.headers["authorization"]);
          setCookie("client", res.headers["client"]);
          setCookie("uid", res.headers["uid"]);
          setCookieObject("platforms", platforms);
          setCookie("currentApp", currentApp);
          setUser(true);
          handleLogin();
          history.push({
            pathname: getAppPath(currentApp),
          });
        }
      } else {
        setStatus({ message: getErrorString(res) });
      }
    } catch (error) {
      setStatus({ message: getErrorString(error) });
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = () =>
    yup.object().shape({
      email: yup.string().required(strings.type_email).email(strings.type_valid_email),
      password: yup.string().required(strings.type_password),
      // .matches(
      //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      //   "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      // ),
    });
  const EyeView = () =>
    inputType === "text" ? (
      <IoEyeOutline onClick={() => setInputType("password")} />
    ) : (
      <IoEyeOffOutline onClick={() => setInputType("text")} />
    );

  return (
    <div className="login-page">
      <div className="login-page-header">
        <Logo className="d-block" />
      </div>
      <div className="login-page-body">
        <div className="login-illustration-col">
          <div className="login-illustration">
            {/* <Lottie options={defaultOptions} /> */}
            <img src={loginIllustrationUrl} width={"550"} alt="" />
          </div>
        </div>
        <div className="login-card-wrap">
          <div className="login-card">
            <h4 className="primary-color-text">{strings.login}</h4>
            <p className="mb-4">Hi &#128075; Welcome to CarForce!</p>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema()}
              innerRef={formikRef}
              onSubmit={(values, { setSubmitting, setStatus }) => {
                setStatus({
                  message: "",
                });
                loginAuth(values, setSubmitting, setStatus);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                status,
                setStatus,
              }) => (
                <form className="theme-form" onSubmit={handleSubmit}>
                  {showEmailForm ? (
                    <div>
                      <FormInput
                        label={strings.impersonated_user_email}
                        placeholder={strings.email_ph}
                        type="email"
                        name="email"
                        onChange={e => {
                          setStatus({ message: "" });
                          setFieldValue("email", e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                        errorMsg={errors.email && touched.email && errors.email}
                      />
                      <FormInput
                        inputRef={showPasswordRef}
                        label={strings.your_otp}
                        placeholder={strings.otp_ph}
                        type={inputType}
                        name="password"
                        onChange={e => {
                          setStatus({ message: "" });
                          setFieldValue("password", e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.password}
                        errorMsg={errors.password && touched.password && errors.password}
                        right={EyeView}
                        autoComplete={"new-password"}
                      />
                      {status && status.message && <CustomAlert message={status.message} />}
                      <ButtonLoading
                        onClick={handleSubmit}
                        color="login"
                        className="justify-content-center"
                        block
                        // color={
                        //   config.data.color.primary_color || undefined
                        // }
                        type="submit"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        {strings.login}
                      </ButtonLoading>
                    </div>
                  ) : (
                    <div>
                      <ButtonLoading
                        className="loginGoogleBtn d-flex justify-content-center align-items-center py-3"
                        style={{ gap: 11 }}
                        block
                        color=""
                        variant=""
                        onClick={e => {
                          e.preventDefault();
                          signInWithProvider(callBackForSignIn);
                        }}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        icon={<LoginGoogleIcon className="googleIcon" />}
                      >
                        {"Login With Google"}
                      </ButtonLoading>

                      {status && status?.message && <CustomAlert message={status.message} />}
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
          <p className="power-by mb-0">
            Powered By{" "}
            <a href="https://www.empglabs.com/" target="_blank" rel="noreferrer">
              dubizzlelabs
            </a>
          </p>
          <div className="sr-only">
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ImpersonatedSignin);
