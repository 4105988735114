import React, { Fragment } from "react";
import { selectLoggedInUser } from "../../../reducers/login/selectors";
import { useSelector } from "react-redux";
import { Avatar } from "../Avatar";
import { Tooltip } from "@chakra-ui/react";

const getRolesNames = (roles, key) => {
  let role_names = [];
  for (let i = 2; i < roles.length; i++) {
    role_names.push(roles[i][key]);
  }
  return role_names;
};

const NameTag = ({ namesForToolTip }) => {
  const labelForToolTip = Array.isArray(namesForToolTip) ? namesForToolTip.join(", ") : "";
  return (
    <div className="">
      <Tooltip
        padding={"10px"}
        borderRadius={"4px"}
        fontSize={"12px"}
        fontWeigth={"400"}
        color={"#393535DE"}
        maxWidth={"150px"}
        marginInline={"auto"}
        backgroundColor={"white"}
        label={labelForToolTip}
      >
        <div className="pl-1">{` +${namesForToolTip.length} more`}</div>
      </Tooltip>
    </div>
  );
};

const UserPanel = () => {
  const info = useSelector(selectLoggedInUser);
  const namesForToolTip = info?.roles.length > 2 ? getRolesNames(info?.roles, "name") : [];
  return (
    <Fragment>
      <div className="user-menu-header d-flex hover-pointer">
        <Avatar avatarOnly name={info?.first_name?.split(" ")[0]} size="40" imgSrc={info.picture?.url} />
        <div>
          <div className="ml-2">
            <h6>{`${info?.first_name} ${info?.last_name}`}</h6>
          </div>
          <div className="ml-2">
            <small>{`${info?.email}`}</small>
          </div>
          <div className="ml-2 d-flex">
            <small>{`${info?.roles
              .slice(0, 2)
              ?.map(e => e.name)
              .join(", ")}`}</small>
            <small>{namesForToolTip.length > 0 && <NameTag namesForToolTip={namesForToolTip} />}</small>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserPanel;
