import { AUCTION_NEGOTIATION_OFFER_STATUSES } from "constant/appConstants";
import { isKeyPresentInPermittedColumns } from "../../../helpers/Util";

const AUCTION_USERS_SLUGS = {
  AUCTION_HOD: "auction-hod",
};

export const isNegotiationStatusBuying = negotiationStatus =>
  negotiationStatus.toLowerCase() === AUCTION_NEGOTIATION_OFFER_STATUSES.BUYING.toLowerCase();

export const isNegotiationStatusSelling = negotiationStatus =>
  negotiationStatus.toLowerCase() === AUCTION_NEGOTIATION_OFFER_STATUSES.SELLING.toLowerCase();

export const isPurchaserRevisingOffer = ({ AuctionUser = null, negotiationStatus = null }) =>
  AuctionUser?.Purchaser && isNegotiationStatusSelling(negotiationStatus);

export const isDreRevisingOffer = ({ AuctionUser = null, negotiationStatus = null }) =>
  AuctionUser?.DRE && isNegotiationStatusBuying(negotiationStatus);

// BOTH PURCHASER AND HOD CAN REVISE OFFER
export const isAuctionUserRevisingOffer = ({ AuctionUser = null, negotiationStatus = null }) =>
  isPurchaserRevisingOffer({
    AuctionUser,
    negotiationStatus,
  }) ||
  isHODRevisingOffer({
    AuctionUser,
    negotiationStatus,
  });

export const getMostRecentBuyingOffer = negotiations => {
  // Filter negotiations for Buying offers
  const buyingOffers = negotiations.filter(
    negotiation => negotiation.negotiation_offer_type === AUCTION_NEGOTIATION_OFFER_STATUSES.BUYING
  );

  if (buyingOffers.length === 0) {
    return null; // Return null if no Buying offers are found
  }

  // Find the offer with the latest updated_at
  const highestOffer = buyingOffers.reduce((max, current) =>
    new Date(max.updated_at) > new Date(current.updated_at) ? max : current
  );

  // Extract the amount of the highest offer
  const amountOfHighestOffer = highestOffer?.negotiated_offer;

  return amountOfHighestOffer;
};

export const getMostRecentSellingOffer = negotiations => {
  // Filter negotiations for Selling offers
  const sellingOffers = negotiations.filter(
    negotiation => negotiation.negotiation_offer_type === AUCTION_NEGOTIATION_OFFER_STATUSES.SELLING
  );

  if (sellingOffers.length === 0) {
    return null; // Return null if no Selling offers are found
  }

  // Find the offer with the latest updated_at
  const highestOffer = sellingOffers.reduce((max, current) =>
    new Date(max.updated_at) > new Date(current.updated_at) ? max : current
  );

  // Extract the amount of the highest offer
  const amountOfHighestOffer = highestOffer.amount;

  return amountOfHighestOffer;
};

export const isDreCounteringOffer = ({ AuctionUser = null, negotiationStatus = null }) =>
  AuctionUser?.DRE && isNegotiationStatusSelling(negotiationStatus);

export const isPurchaserCounteringOffer = ({ AuctionUser = null, negotiationStatus = null }) =>
  AuctionUser?.Purchaser && isNegotiationStatusBuying(negotiationStatus);

export function sortNegotiationsByUpdatedTime(negotiations = []) {
  return negotiations.sort((a, b) =>
    b?.updated_at && a?.updated_at ? new Date(b.updated_at) - new Date(a.updated_at) : 0
  );
}

export const doesAuctionUserHasHODRole = ({ roles = [] }) =>
  roles.some(role => role.slug === AUCTION_USERS_SLUGS.AUCTION_HOD);

export const isHODRevisingOffer = ({ AuctionUser = null, negotiationStatus = null }) =>
  AuctionUser?.HOD && isNegotiationStatusSelling(negotiationStatus);

export const isHODCounteringOffer = ({ AuctionUser = null, negotiationStatus = null }) =>
  AuctionUser?.HOD && isNegotiationStatusBuying(negotiationStatus);

export const getClientInfo = ({
  negotiationOfferStatus,
  dealer = {},
  purchaser = {},
  negotiationsPermittedColumns = [],
}) => {
  const getPermittedValue = ({ clientMeta }) =>
    Object.entries(clientMeta).reduce(
      (acc, [key, val]) => {
        if (
          isKeyPresentInPermittedColumns({
            key,
            permittedColumns: negotiationsPermittedColumns,
          })
        ) {
          return {
            ...acc,
            ...val,
          };
        }
      },
      {
        name: "",
        email: "",
      }
    ) ?? {
      name: "",
      email: "",
    };
  const purchaserMeta = {
    "purchaser.name": {
      name: purchaser?.name,
    },
    "purchaser.email": {
      email: purchaser?.email,
    },
  };

  const dealerMeta = {
    "started_by.name": {
      name: dealer.name,
    },
    "started_by.email": {
      email: dealer.email,
    },
  };

  if (isNegotiationStatusSelling(negotiationOfferStatus)) {
    return getPermittedValue({
      clientMeta: purchaserMeta,
    });
  } else if (isNegotiationStatusBuying(negotiationOfferStatus)) {
    return getPermittedValue({
      clientMeta: dealerMeta,
    });
  }

  return {
    name: "",
    email: "",
  };
};

export const errorMessages = ({ currentOffer = 0, maximumOffer = Number.MAX_SAFE_INTEGER, minimumOffer = 0 }) => {
  // console.log({
  //   currentOffer,
  //   maximumOffer,
  //   minimumOffer,
  // });

  return {
    purchaserCounterOfferToDre: {
      MAXIMUM_OFFER: currentOffer > maximumOffer ? "Offer cannot be bigger than seller's expectations" : "",
      MINIMUM_OFFER: currentOffer <= minimumOffer ? "Offer cannot be equal to or less than Bidder's Latest Offer" : "",
    },
    purchaserRevisingOffer: {
      MAXIMUM_OFFER: currentOffer > maximumOffer ? "Offer cannot be bigger than seller's expectations" : "",
      MINIMUM_OFFER: currentOffer <= minimumOffer ? "Offer cannot be equal to or less than Bidder's Latest Offer" : "",
    },
    dreCounterOfferToPurchaser: {
      MAXIMUM_OFFER:
        currentOffer >= maximumOffer ? "Offer cannot be bigger than or equal to Purchaser's Lastest Offer" : "",
      MINIMUM_OFFER: currentOffer <= minimumOffer ? "Offer cannot be equal to or less than DRE's Lastest Offer" : "",
    },
    dreRevisingOffer: {
      MAXIMUM_OFFER: currentOffer > maximumOffer ? "Offer cannot be bigger than seller's Latest Offer" : "",
      MINIMUM_OFFER: currentOffer <= minimumOffer ? "Offer cannot be equal to or less than DRE's Lastest Offer" : "",
    },
  };
};
