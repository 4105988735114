import * as yup from "yup";

import { regex } from "../constant/regex";
import { strings } from "../constant/strings";

// ^[a-zA-Z0-9_.-]*$

export const nameValidation = (nameText, length = 20, nameRegex) =>
  yup
    .string()
    .required(`Please enter ${nameText ? nameText : "name"}`)
    .test("length", strings.valid_name, value => {
      return value && value.length > 1;
    })
    .test("length", `Name must have less than ${length} character`, value => {
      return value && value.length <= length;
    })
    .test("alphabets", `${nameText ? nameText : "name"} must only contain alphabets`, value => {
      return new RegExp(nameRegex ? nameRegex : regex.name).test(value);
    });

export const phoneValidation = (phoneRegExp, isRequired = true, isNullable = false) => {
  const regex = new RegExp(phoneRegExp);

  if (!isRequired && isNullable) return yup.string().nullable().matches(regex, strings.valid_phone);

  return isRequired
    ? yup.string().required(strings.enter_phone).matches(regex, strings.valid_phone)
    : yup.string().matches(regex, strings.valid_phone);
};

export const phoneValidation1 = (phoneRegExp, isRequired = true, addPhoneNumbersInAPIPayload = false, isEditable) => {
  const regex = new RegExp(phoneRegExp);

  if (!isEditable || (isEditable && !addPhoneNumbersInAPIPayload)) return yup.string().nullable();

  return isRequired
    ? yup.string().required(strings.enter_phone).matches(regex, strings.valid_phone)
    : yup.string().matches(regex, strings.valid_phone);
};

export const descriptionValidation = yup
  .string()
  .nullable()
  // .test(
  //   "len",
  //   "Description must greater than 5 charactors",
  //   (val) => val === "" || (val && val.length >= 5)
  // )
  // .test(
  //   "len",
  //   "Description must less than 200 charactors",
  //   (val) => val === "" || (val && val.length >= 5)
  // )
  .matches(regex.text, strings.valid_description);

export const passwordValidation = yup.string().matches(regex.password, strings.valid_password);

export const commentsValidation = yup.string(strings.add_comment).matches(regex.text, strings.valid_comment);

export const numberValidation = (inputValue, onSuccess, onError, regExp = regex.numbersOnly) => {
  const validChar = new RegExp(regExp).test(inputValue);
  if (validChar) {
    onSuccess(inputValue);
  } else {
    onError();
  }
};
