import React from "react";
import { Skeleton } from "../../components/common";
import { getPermission, isFieldRestricted } from "../../helpers/Util";
import { useHistory } from "react-router-dom";

function UserInfoItems(props) {
  const history = useHistory();
  const { UserDropdownComponent } = props;
  const RenderItem = props => {
    const { Icon, title, value, permissionCategory, permissionAction, route, id, iconProps = {} } = props;

    let iconOptions = { color: "", iconClass: "", size: "" };
    let truncated = false;

    const handleClick = () => {
      if (getPermission(permissionCategory, permissionAction) && route && value) {
        history.push(route.replace(":id", id));
      }
    };

    return (
      <div
        onClick={props.onClick ?? handleClick}
        className={`${route ? "cursor-pointer" : ""} info-block d-flex spbwx12 ${
          truncated ? "info-block-truncated" : ""
        }`.trim()}
      >
        {Icon && value && !isFieldRestricted(value) && (
          <>
            <Icon
              className={`icon-svg ${iconOptions.iconClass ? iconOptions.iconClass : ""}`.trim()}
              style={{
                "--icon-size": iconOptions.size ? iconOptions.size : "",
                "--icon-color": iconOptions.color ? iconOptions.color : "",
              }}
              {...iconProps}
            />
            <div>
              <div className="info-block-title"> {title}</div>
              <div className={`basicSelect ${props.style ? props.style : ""}`}>{value}</div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={"d-flex flex-wrap align-items-center"}>
        {props.loading ? (
          Array(props.detailWithIcons?.length)
            .fill(0)
            .map((e, i) => (
              <div key={i} className={"info-block"}>
                <Skeleton height={35} width={95} />
              </div>
            ))
        ) : (
          <>
            {props.detailWithIcons?.map((e, i) => {
              return <RenderItem Icon={e.Icon} title={e.title} value={e.value} key={i} {...e} />;
            })}
            {UserDropdownComponent && <UserDropdownComponent />}
          </>
        )}
      </div>
    </>
  );
}

export default UserInfoItems;
