import { FETCH_CRM_WATCHER_USERS } from "../../constant/actionTypes";

export const INITIAL_STATE = {
  users: null,
  loading: true,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CRM_WATCHER_USERS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
