import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { addQuickLink, fetchQuickLinks } from "../../actions/general/quickLink.action";

import cx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectQuickLinksData } from "reducers/general/selectors";
import { strings } from "../../constant/strings";
import { saveQuickLinksApi } from "../../helpers/ApiHelper";
import { getErrorString, getPermission, isEmptyArray } from "../../helpers/Util";
import { Button, CustomAlert } from "../common";
import { AiFillLock, GoPlus } from "../svg-icon/svg-icons";
import FormInput from "./FormInput";
import Modal from "./ModalBase";
import Permissions from "./Permissions";
import Skeleton from "./Skeleton";

const QuickLinkComponent = props => {
  const { fRef, prependLinks = [], initialRender, listLoading, defaultSearchQuery = "" } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { links: linksPayload, loading: linksLoading } = useSelector(selectQuickLinksData);
  const [selectedLink, setSelectedLink] = useState(0);
  const [createModal, setCreateModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [noSearchParamsError, setNoSearchParamsError] = useState("");
  const hasPermissionForIndex = getPermission("QuickLink", "index");
  let links = linksPayload?.[props.type] || [];
  links = [...prependLinks, ...links];

  useImperativeHandle(fRef, () => ({
    setQuickLinks() {
      setQuickLinks(links);
    },
  }));

  useEffect(() => {
    hasPermissionForIndex &&
      (isEmptyArray(linksPayload) || !links) &&
      dispatch(fetchQuickLinks("", links => setQuickLinks([...prependLinks, ...links]), props.type));
  }, []);

  useEffect(() => {
    if (!location.search.length) {
      handleDefaultQuickLinks();
    } else {
      hasPermissionForIndex && setQuickLinks(links);
    }
  }, [location.search, links, prependLinks]);

  const setQuickLinks = links => {
    const search = location.search?.replace("?", "");
    let hasFoundSelectedQuickLink = false;
    links?.length &&
      links.forEach(e => {
        if (e.link?.split("?")[1] === search) {
          handleQuickLinksSelection({ id: e?.id, shouldPushToURL: false });
          hasFoundSelectedQuickLink = true;
        }
      });

    if (!hasFoundSelectedQuickLink) handleDefaultQuickLinks();
  };

  const onSave = async () => {
    if (!!location.search.length) {
      const link = `${location.pathname}${location.search}`;
      if (searchName) {
        setLoading(true);
        const response = await saveQuickLinksApi({
          quick_link: {
            name: searchName,
            link,
            link_type: props.type,
          },
        });
        if (response) {
          setLoading(false);
          if (response.quick_link) {
            setSearchName("");
            setCreateModal(false);
            setStatus("");
            dispatch(addQuickLink(response.quick_link, props.type));
          } else {
            let errorMsg = getErrorString(response);
            let formattedErrorMsg = errorMsg.replace(/:/g, "");
            if (formattedErrorMsg.trim("") === "Name has already been taken") {
              setStatus(formattedErrorMsg);
            } else if (formattedErrorMsg.trim("") === "Link has already been taken") {
              setStatus("Filters have already been selected");
            } else {
              setStatus("Name has already been taken | Filters have already been selected");
            }
          }
        }
      } else {
        setLoading(false);
        setStatus(strings.add_search_name);
      }
    } else {
      setLoading(false);
      setNoSearchParamsError(strings.no_search_params_msg);
    }
  };

  const renderModalBody = () => (
    <div className="form theme-form">
      <FormInput
        className="save_model_row mb-0"
        placeholder={strings.name_your_search}
        onChange={e => {
          setSearchName(e.target.value);
          if (/^(?!\s+$).*/.test(e.target.value)) {
            if (searchName.length < 25) {
              setStatus("");
            } else {
              setStatus(strings.character_validation);
            }
          } else {
            setStatus(strings.quick_link_space_validation);
          }
        }}
        value={searchName}
      />
      {status && <CustomAlert message={status} />}
      {noSearchParamsError && <CustomAlert message={noSearchParamsError} />}
    </div>
  );
  const renderModal = () => {
    return (
      <Modal
        toggle={() => {
          setStatus("");
          setCreateModal(!createModal);
        }}
        title={strings.create_quick_link}
        open={createModal}
        className="save_model"
        body={() => renderModalBody()}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setSearchName("");
              setCreateModal(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
          {
            label: strings.done,
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: onSave,
            disabled: loading || !!status,
            loading,
          },
        ]}
      />
    );
  };

  const isThisQuickLinkSelected = id => {
    if (!id) return false;

    return selectedLink === id;
  };

  const handleDefaultQuickLinks = ({ shouldPushToURL = false } = {}) => {
    setSelectedLink(0);

    if (shouldPushToURL) {
      history.push({
        pathname: location.pathname,
        ...(defaultSearchQuery ? { search: defaultSearchQuery } : {}),
      });
    }
  };

  const handleQuickLinksSelection = ({ id = null, search = "", shouldPushToURL = true }) => {
    if (!id || (id && shouldPushToURL && !search)) {
      handleDefaultQuickLinks({ shouldPushToURL: true });
      return;
    }

    setSelectedLink(id);

    if (shouldPushToURL) {
      history.push({
        pathname: location.pathname,
        search: search,
      });
    }
  };

  const quickLinksOnClick = e => {
    const link = e.link.split("?");
    const search = "?" + link[1];
    return !isThisQuickLinkSelected(e?.id)
      ? handleQuickLinksSelection({ id: e?.id, search: search })
      : handleDefaultQuickLinks({ shouldPushToURL: true });
  };

  return hasPermissionForIndex && linksLoading ? (
    <div className="quick-links">
      <div className="d-flex flex-row">
        {[1, 2, 3].map(item => (
          <Skeleton height={40} width={110} key={item} className="mr-4" style={{ borderRadius: 55 }} />
        ))}
      </div>
    </div>
  ) : (
    <>
      {renderModal()}
      <div className={cx("quick-links", { "pe-none opacity-50": initialRender || listLoading })}>
        {links?.length > 0 &&
          links?.map(e => {
            if (e.link) {
              return (
                <div
                  key={e.id}
                  onClick={() => {
                    quickLinksOnClick(e);
                  }}
                  className={`quick-link-chip ${isThisQuickLinkSelected(e?.id) ? "active" : ""}`}
                >
                  {e.isPrepended && <AiFillLock size={16} />}
                  {e.name}
                </div>
              );
            }
          })}
        {!prependLinks.find(prependLink => prependLink.id === selectedLink) && (
          <Permissions catagories="QuickLink" actions="create">
            <Button
              btnText="Create Quick Link"
              color="outline-primary"
              icon={<GoPlus />}
              size="default"
              className="quick-link-btn"
              onClick={e => {
                setStatus("");
                setCreateModal(true);
                setNoSearchParamsError("");
                setSearchName("");
              }}
            />
          </Permissions>
        )}
      </div>
    </>
  );
};

export default forwardRef((props, ref) => <QuickLinkComponent {...props} fRef={ref} />);
