import { IF } from "../index";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useFetch } from "../../../hooks";
import { makeValuesObject, setInitialValuesForDynamicFields, validateFieldsData } from "./DynamicFieldHelpers";
import { renderDynamicFields } from "./DynamicFieldComponents";
import { Edit2 } from "react-feather";
import { isFieldRestricted } from "../../../helpers/Util";
import { useSelector } from "react-redux";
import SelectField from "../SelectField";

const DynamicFormField = forwardRef((props, ref) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleChange,
    handleBlur,
    setValues,
    horizontal,
    fieldKey,
    fieldErrorMsg,
    onChange,
    options,
    isLoading,
    isError,
    server,
    serverProps = {},
    current,
    children,
    textRight,
    selectedSlug,
    dynamicFieldsRef,
    setTaskDynamicAttributes,
    setTaskTypeDynamicAttributes,
    disabled = false,
    postValues,
    FieldsParent,
    isEditable = null,
    activeColumnsPermittedAttributes,
    validateIsTouchedError = null,
    ...rest
  } = props;

  const { controller, fetchCall, params } = serverProps;
  const { data, loading, error } = useFetch(
    server && (!disabled || selectedSlug) ? fetchCall : undefined,
    [`?limit=1000${params ? "&" + params : ""}`],
    [disabled]
  );
  const timezone = useSelector(content => content.Configs.tenant.country.timezone);

  const isRestricted = () => {
    if (current) {
      return isFieldRestricted(current[fieldKey]);
    } else {
      return values[fieldKey] && isFieldRestricted(values[fieldKey]);
    }
  };

  useEffect(() => {
    if (!isRestricted()) {
      if (!disabled || selectedSlug) {
        data?.[controller]?.length && setFormInitialValues(data?.[controller]);
      } else {
        current && setFormInitialValues();
      }
    }
  }, [data?.[controller], current, selectedSlug]);

  useImperativeHandle(ref, () => ({
    validateFields,
    setInitialValues() {
      if ((disabled || data?.[controller]?.length) && (current || selectedSlug) && !isRestricted()) {
        setFormInitialValues(data?.[controller]);
      }
    },
    clearField() {
      inputRef.current && inputRef.current.clear();
    },
  }));

  let inputRef = useRef();

  const setFormInitialValues = list => {
    const currentValue =
      list?.find(e => (selectedSlug ? e.slug === selectedSlug : Number(e.id) === Number(current?.[fieldKey]?.id))) ||
      current?.[fieldKey];
    setInitialValuesForDynamicFields(
      currentValue,
      current,
      currentValue?.dynamic_model?.source,
      setFieldValue,
      fieldKey,
      timezone
    );
  };

  const validateFields = cb => {
    const valuesObj = {};
    const currentValue =
      data?.[controller].find(e => Number(e.id) === Number(values[fieldKey]?.id)) || current?.[fieldKey];

    let error;
    if (currentValue) {
      const { hasError, errorData } = validateFieldsData(
        values,
        currentValue,
        currentValue.dynamic_model.source,
        setFieldError,
        activeColumnsPermittedAttributes
      );
      error = hasError;
      if (!error) {
        valuesObj[values[fieldKey].dynamic_model.source] = makeValuesObject(
          values,
          values[fieldKey].dynamic_model.source,
          timezone,
          activeColumnsPermittedAttributes
        );
      }
    } else {
      error = true;
      setFieldError(fieldKey, fieldErrorMsg);
    }
    cb(valuesObj, error);
  };

  const renderFields =
    values?.[fieldKey] &&
    !isFieldRestricted(values?.[fieldKey]?.dynamic_fields) &&
    renderDynamicFields(
      values[fieldKey]?.dynamic_fields,
      props,
      values?.[fieldKey]?.dynamic_model?.source,
      validateIsTouchedError,
      activeColumnsPermittedAttributes
    );

  const onTaskTypeChange = async selected => {
    const value = selected;
    if (value) {
      await setFieldValue(fieldKey, value);
      onChange && onChange(value);
      value &&
        value.dynamic_fields &&
        !isFieldRestricted(value.dynamic_fields) &&
        value.dynamic_fields.forEach(item => {
          const modelName = value.dynamic_model.source;
          if (item.field_data_type === "boolean") {
            setFieldValue(`${modelName}_dynamic_${item.field_name}`, false);
          } else if (item.field_data_type === "single-select" || item.field_data_type === "multi-select") {
            setFieldValue(`${modelName}_dynamic_${item.field_name}`, []);
          } else {
            setFieldValue(`${modelName}_dynamic_${item.field_name}`, null);
          }
        });
    } else {
      setFieldValue(fieldKey, null);
    }
  };

  const onUpdateClick = () => {
    validateFields((valuesObj, error) => setTaskTypeDynamicAttributes({ valuesObj, error }));
    dynamicFieldsRef &&
      dynamicFieldsRef.current &&
      dynamicFieldsRef.current.validateFields((valuesObj, error) => setTaskDynamicAttributes({ valuesObj, error }));
  };

  const taskFormAdditionalFields = () => {
    return (
      <div className="additional-field-list b-r-6 p-25 m-b-45 position-relative">
        {current && !props?.editDynamicFields && (
          <button className="circle__site-icon icon-right icon-primary">
            <Edit2 onClick={() => props.setEditDynamicFields(true)} />
          </button>
        )}
        <h5 className="heading m-b-15 ">Additional Fields</h5>

        <div className={`${current && !props?.editDynamicFields ? "pointer-events-none" : ""}`}>
          {renderFields}
          {children?.length && children?.filter(e => e?.props?.id === "dynamicFields")[0]}
        </div>
        <IF condition={(values[fieldKey]?.dynamic_fields?.length || !!values?.dynamicAttributes?.length) && current}>
          <div className="d-flex justify-content-end">
            {props?.editDynamicFields && (
              <button className="btn btn-pill btn-outline-primary" type="button" onClick={onUpdateClick}>
                Update
              </button>
            )}
          </div>
        </IF>
      </div>
    );
  };
  return (
    <>
      {!isRestricted() && (
        <SelectField
          columnsRightSection={9}
          className="flex-grow-1 m-0"
          onChange={onTaskTypeChange}
          value={values[fieldKey] ? [{ ...values[fieldKey] }] : null}
          errorMsg={errors[fieldKey] && touched[fieldKey] && errors[fieldKey]}
          formatOptionLabel={option => option.name}
          required
          isSearchable
          horizontal={horizontal}
          isEditable={isEditable}
          options={server ? data?.[controller] || [] : options}
          isMulti={false}
          isDisabled={isEditable === false || disabled}
          {...rest}
        />
      )}
      {children?.length && children?.filter(e => e?.props?.id !== "dynamicFields")}
      <IF
        condition={
          (values[fieldKey] && values[fieldKey]?.dynamic_fields?.length) || !!values?.dynamicAttributes?.length
        }
      >
        <IF condition={FieldsParent} ELSE={() => renderFields}>
          {props.taskForm ? taskFormAdditionalFields() : <> {renderFields} </>}
          {/*<div className="additional-field-list b-r-6 p-25 m-b-45 position-relative">*/}
          {/*  {current && !props?.editDynamicFields && (*/}
          {/*    <button className="circle__site-icon icon-right icon-primary">*/}
          {/*      <Edit2 onClick={() => props.setEditDynamicFields(true)} />*/}
          {/*    </button>*/}
          {/*  )}*/}
          {/*  <h5 className="heading m-b-15 ">Additional Fields</h5>*/}

          {/*  <div className={`${current && !props?.editDynamicFields ? "pointer-events-none" : ""}`}>*/}
          {/*    {renderFields}*/}
          {/*    {children?.length && children?.filter(e => e?.props?.id === "dynamicFields")[0]}*/}
          {/*  </div>*/}
          {/*  <IF*/}
          {/*    condition={(values[fieldKey]?.dynamic_fields?.length || !!values?.dynamicAttributes?.length) && current}*/}
          {/*  >*/}
          {/*    <div className="d-flex justify-content-end">*/}
          {/*      {props?.editDynamicFields && (*/}
          {/*        <button className="btn btn-pill btn-outline-primary" type="button" onClick={onUpdateClick}>*/}
          {/*          Update*/}
          {/*        </button>*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  </IF>*/}
          {/*</div>*/}
        </IF>
      </IF>
    </>
  );
});

export default DynamicFormField;
