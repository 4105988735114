import { ButtonLoading, FormInput, SelectionListTypeHead, Toast } from "../common";
import React, { useEffect, useState } from "react";
import { addRoleApi, fetchRoleDetailApi, updateRoleApi } from "../../helpers/ApiHelper";
import {
  checkUndefinedApiParams,
  getErrorString,
  getPath,
  makeListToSelectData,
  platformsCheck,
} from "../../helpers/Util";

import { strings } from "../../constant/strings";
import { useSelector } from "react-redux";

const AddRoleForm = props => {
  const { roleId, setRoleData, onSuccess, tabIndex } = props;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [rolePlatforms, setRolePlatforms] = useState([]);

  const [role, setRole] = useState({
    name: "",
    description: "",
    platforms: [],
  });
  const platforms = useSelector(content => content.Configs.tenant.platforms);
  useEffect(() => {
    roleId && fetchRoleDetail();
  }, []);
  const fetchRoleDetail = async () => {
    if (!checkUndefinedApiParams(roleId, "role")) return;
    setLoading(true);
    const response = await fetchRoleDetailApi(roleId);
    if (response && response.role) {
      setLoading(false);
      setRolePlatforms(response.role.platforms);
      setRoleData(response.role);
      setRole(response.role);
    } else {
      setError(getErrorString(response));
    }
  };
  const saveRoleDetail = async () => {
    if (!role.name) {
      setError(strings.enter_role_name);
      return;
    }
    setError("");
    const roleObject = {
      role: {
        name: role.name,
        description: role.description,
        platform_ids: role.platforms.map(e => e.id),
      },
    };
    setSaveError("");
    setSaveLoading(true);
    let response;
    if (roleId) {
      response = await updateRoleApi(roleId, roleObject);
    } else {
      response = await addRoleApi(roleObject);
    }
    if (response) {
      // props.fetchBaseDetails(userInfo.id);
      setSaveLoading(false);
      if (response.success && response.role) {
        if (!props.roleId) {
          props.history.push(getPath("roles", response.role?.id));
          return;
        }
        setRolePlatforms(response.role.platforms);
        const platformValidate = platformsCheck(rolePlatforms, role.platforms);
        platformValidate && onSuccess(tabIndex === 0 ? "permissions" : "restrictions");
        setRoleData(response.role);
        Toast.success(response.message);
      } else {
        setSaveError(strings.error);
        Toast.error(getErrorString(response));
      }
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5>{strings.role_name_and_description}</h5>
      </div>
      <div className="card-body w-70 form theme-form">
        <FormInput
          label={strings.name}
          placeholder={strings.name_ph}
          onChange={e => setRole({ ...role, name: e.target.value })}
          onFocus={e => setError("")}
          onBlur={e => !e.target.value && setError(strings.enter_role_name)}
          value={role.name}
          required
          horizontal
          errorMsg={error}
          loading={loading}
        />
        <FormInput
          label={strings.description}
          horizontal
          placeholder={strings.description_ph}
          linesCount="4"
          onChange={e => setRole({ ...role, description: e.target.value })}
          value={role.description || ""}
          loading={loading}
        />
        <SelectionListTypeHead
          label={strings.platform}
          placeholder={strings.platform_ph}
          options={makeListToSelectData(platforms.filter(it => !role.platforms.find(el => el.id == it.id)) || [])}
          // isLoading={roles.loading}
          // isError={roles.error}
          selected={role.platforms ? [...role.platforms] : []}
          onChange={selected => {
            setRole({ ...role, platforms: selected });
          }}
          labelKey="name"
          // errorMsg={!!errors.roles && !!touched.roles && errors.roles}
          renderMenuItemChildren={option => <div>{option.name}</div>}
          horizontal
          multiple
          textRight
          loading={loading}
        />
        <div className="text-right p-b-10">
          <ButtonLoading loading={saveLoading} disabled={saveLoading} onClick={saveRoleDetail} error={!!saveError}>
            {strings.save}
          </ButtonLoading>
        </div>
      </div>
    </div>
  );
};
export default AddRoleForm;
