import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "reducers/login/selectors";
import { selectTenant } from "reducers/general/selectors";
import ConfigDB from "data/config";
import { getCookie } from "helpers/CookieHelper";
import QRCodeStyling from "qr-code-styling";
// import { getQRDesign } from "helpers/QRUtils";

const GoDialerQRCode = () => {
  const [qrCodeBlob, setQrCodeBlob] = useState("");
  const currentUser = useSelector(selectLoggedInUser);
  const tenant = useSelector(selectTenant);
  const primary = ConfigDB.data.color.primary_color;

  useEffect(() => {
    getQRDataUrl();
  }, []);

  const encryptionKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_PROPGO_ENCRYPTION_KEY);
  const vector = CryptoJS.enc.Hex.parse(process.env.REACT_APP_PROPGO_ENCRYPTION_HEX);
  const cipherText = CryptoJS.AES.encrypt(getCookie("authorization"), encryptionKey, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: vector,
  }).toString();

  const QRCodeJsonString = {
    applicationPlatform: "CarForce",
    cell: currentUser?.phone,
    userId: currentUser?.id,
    email: currentUser?.email,
    userName: currentUser?.name,
    designationTitle: currentUser?.designation ?? {},
    teams: currentUser?.teams ?? [],
    tenant: tenant?.name,
    token: cipherText,
  };

  const getQRDesign = data => ({
    width: 250,
    height: 250,
    data: data,
    margin: 0,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "L" },
    dotsOptions: { type: "dots" },
    backgroundOptions: { color: "#FFFFFF" },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "rgb(68, 102, 242)",
        color2: "rgb(68, 102, 242)",
        rotation: "0",
      },
    },
  });

  const initializeQR = url => new QRCodeStyling(getQRDesign(url));

  const getQRDataUrl = async () => {
    const qrCode = initializeQR(JSON.stringify(QRCodeJsonString));
    let blob = await qrCode.getRawData();
    let dataUrl = await new Promise(resolve => {
      let a = new FileReader();
      a.onload = function (e) {
        resolve(e.target.result);
      };
      a.readAsDataURL(blob);
    });
    setQrCodeBlob(dataUrl);
  };

  return (
    <div className={"d-flex justify-content-center mb-5"}>
      {qrCodeBlob && <img className={"qr-code-image"} src={qrCodeBlob} />}
    </div>
  );
};

export default GoDialerQRCode;
