import { Modal, Toast } from "components/common";
import { CRM_PATH } from "constant/appPaths";
import { strings } from "constant/strings";
import { regenerateAdyenPaymentLinkApi } from "helpers/ApiHelper";
import { getErrorString } from "helpers/Util";
import React, { useState } from "react";

const RegenerateAdyenLinkModal = ({ open, toggle, id, setAdyenData = () => {}, route }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    const response = await regenerateAdyenPaymentLinkApi(id, route);
    try {
      if (response?.success) {
        const { url, adyen_id, status } = route.includes(CRM_PATH)
          ? response?.crm_payment?.payment_link
          : response?.payment?.payment_link;
        setAdyenData(prevData => ({
          ...prevData,
          response,
          url,
          id: adyen_id,
          status,
          adyenPaymentModal: true,
          expiresAt: response?.crm_payment?.payment_link?.expires_at,
        }));
        Toast.success(strings.payment_link_regenerated);
        toggle();
      } else {
        Toast.error(getErrorString(response));
        toggle();
      }
    } catch (error) {
      Toast.error(getErrorString(error));
      toggle();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      toggle={toggle}
      title={strings.regenerate_link_confirmation}
      body={() => (
        <>
          <div className="mb-4">
            {strings.regenerate_link_confirmation}
            <br />
            {strings.previous_link_discarded}
          </div>
        </>
      )}
      actions={[
        {
          label: strings.cancel,
          onClick: () => {
            toggle();
          },
          color: "secondary",
          key: "cancel",
          className: "modal__cancel-btn btn-pill",
        },
        {
          label: strings.confirm,
          onClick: handleConfirm,
          color: "primary",
          key: "confirm",
          className: "modal__cr-btn btn-pill",
          loading: isLoading,
        },
      ]}
    />
  );
};

export default RegenerateAdyenLinkModal;
