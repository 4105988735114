import cx from "clsx";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { fetchAuctionNegotiations } from "../../../actions/auction/auctionNegotiations.actions";
import { columnsMap } from "../../../constant/columnsMapStrings";
import {
  MODELS_FOR_VIEW_PHONE_NUMBER_API,
  PHONE_NUMBER_CATEGORIZATION_PER_PAGE,
  RESTRICTED_MODULES,
} from "constant/appConstants";
import { strings } from "../../../constant/strings";
import { fetchOffersApi, markAuctionOfferAsWinner, updateOfferApi } from "../../../helpers/ApiHelper";
import { getCookie } from "../../../helpers/CookieHelper";
import { subscribe, unsubscribeFirebase } from "../../../helpers/FirebaseHelpers";
import {
  checkUndefinedApiParams,
  getErrorString,
  getPermission,
  getTableColumns,
  getTimeDateString,
  isFieldRestricted,
} from "../../../helpers/Util";
import MarkAsWinnerForm from "../../../pages/auction/MarkAsWinnerForm";
import { selectLoggedInUser } from "../../../reducers/login/selectors";
import {
  Actions,
  Avatar,
  ConfirmationDialog,
  CustomAlert,
  DataTableComponent,
  FormInput,
  FormSelect,
  Loader,
  Modal,
} from "../../common";
import Group from "../../common/Group";
import RenderPrivateField from "../../common/RenderPrivateField/RenderPrivateField";
import AuctionNegotiationTable from "../AuctionNegotiationTable";
import MarkAsNegotiatorModal from "../MarkAsNegotiatorModal";
import styles from "./OfferTrails.module.scss";
import PostAuctionCards from "../PostAuctionCards";
import { sortNegotiationsByUpdatedTime } from "./OfferTrialsHelpers";
import EmptyState from "../../common/EmptyState/EmptyState";

const OfferTrails = ({
  latestAuctionId,
  tenant,
  auctionCycles,
  history,
  actionCableData,
  fetchCar,
  inventoryId = null,
  auctionNegotiations,
  sellerExpectations,
}) => {
  const [offersObject, setOffersObject] = useState({});
  const [error, setError] = useState(null);
  const [auctionCycle, setAuctionCycle] = useState(latestAuctionId);
  const [offerId, setOfferId] = useState(null);
  const [showMarkWinnerDialog, setShowMarkWinnerDialog] = useState(false);
  const [showMarkArchiveDialog, setShowMarkArchiveDialog] = useState(false);
  const [archiveReason, setArchiveReason] = useState("");
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [loadingWinner, setLoadingWinner] = useState(false);
  const [errorArchive, setErrorArchive] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [realTimeNegotiations, setRealTimeNegotiations] = useState(null);
  const [auctionData, setAuctionData] = useState(null);
  const [showMarkAsNegotiator, setShowMarkAsNegotiator] = useState(null);
  const restrictedModules = tenant?.configuration?.restricted_modules;
  const isBookingServicesRestricted = !!restrictedModules?.includes(RESTRICTED_MODULES.BOOKING);
  const { country } = tenant;
  const { auction_OfferTrails } = columnsMap;
  const { statuses, loading: postAuctionNegotiationLoading } = auctionNegotiations || [];
  const user = useSelector(selectLoggedInUser);
  const dispatch = useDispatch();
  const markAsWinnerRef = useRef(null);
  const auctionRef = useRef(null);
  const negotiationRef = useRef(null);

  const tabsList = [
    { id: 0, name: "Bids" },
    getPermission("Negotiation", "index") && { id: 1, name: "Post Auction Negotiations" },
  ];

  useEffect(() => {
    if (latestAuctionId) {
      latestAuctionId && dispatch(fetchAuctionNegotiations(`?f[auction_id]=${auctionCycle}`));
      subscribe({ id: auctionCycle, setter: setAuctionData, subscribeRef: auctionRef });
    }
  }, [auctionCycle]);

  useEffect(() => {
    if (auctionData && auctionData.hasOwnProperty("negotiation_id")) {
      subscribe({
        id: auctionData.negotiation_id,
        setter: setRealTimeNegotiations,
        path: "negotiations/",
        subscribeRef: negotiationRef,
      });
    } else {
      setRealTimeNegotiations(null);
    }
  }, [auctionData]);

  useEffect(() => {
    if (auctionRef.current && negotiationRef.current)
      return () => {
        unsubscribeFirebase({ subscribeRef: negotiationRef });
        unsubscribeFirebase({ subscribeRef: auctionRef });
      };
  }, []);

  useEffect(() => {
    fetchOffers(`?q[auction_id_eq]=${auctionCycle || latestAuctionId}`);
  }, [auctionData?.total_offers]);

  const currentApp = getCookie("currentApp");

  const updateOffers = response => {
    const index = offersObject.offers.findIndex(e => e.id === offerId);
    offersObject.offers.splice(index, 1, { ...response.offer });
    setOffersObject({ ...offersObject, offers: offersObject.offers });
  };

  const fetchOffers = async params => {
    if (latestAuctionId) {
      const response = await fetchOffersApi(params);
      if (response) {
        if (response.success) {
          setOffersObject(response);
          return response;
        } else {
          setError(getErrorString(response));
        }
      }
    }
  };

  const onArchive = async () => {
    if (!checkUndefinedApiParams(offerId, "offer")) return;
    const userId = user?.id;
    const response = await updateOfferApi(offerId, {
      offer: {
        archived: true,
        archived_by_id: userId,
        archived_reason: archiveReason,
      },
    });
    setLoadingArchive(true);
    if (response) {
      setLoadingArchive(false);
      if (response.success) {
        updateOffers(response);
        setShowMarkArchiveDialog(false);
        setArchiveReason("");
        setErrorArchive("");
      } else {
        setErrorArchive(getErrorString(response));
      }
    }
  };

  const getAuctionList = () => {
    let auctionList = [];
    if (offersObject?.offers && offersObject?.offers.length > 0) {
      auctionList = offersObject?.offers.map(item => ({
        ID: item.id,
        [strings.bidder_ref_no]: item?.bidder?.reference_number,
        [strings.auction_]: item.auction?.reference_number,
        [strings.buyer_name]: item?.bidder?.first_name,
        [strings.contact_numbers]: (
          <RenderPrivateField
            associatedId={inventoryId}
            uuid={item?.bidder?.uuid}
            value={item?.bidder?.phone}
            associatedType={MODELS_FOR_VIEW_PHONE_NUMBER_API.INVENTORY_PHONE_KEY}
            component={PHONE_NUMBER_CATEGORIZATION_PER_PAGE.INVENTORY.DETAIL.OFFER_TRAIL}
            isUserTypeNotClient={true}
            showOnlyPrimaryField={true}
          />
        ),
        [strings.assigned_dre]: item?.bidder?.assignee ? (
          <Avatar name={item?.bidder?.assignee?.name} size="28" round />
        ) : (
          ""
        ),
        [strings.date_and_time]: getTimeDateString(item.created_at, country.timezone, true, false, false, true),
        [strings.offer]: item?.bid_amount,
        [strings.archived]: item?.archived ? "Yes" : "",
        [strings.won]: item?.won ? "Yes" : "",
        [strings.actions]: (
          <Actions
            id={item.id}
            actionsList={[
              !item?.archived &&
                auctionData?.status?.slug === "live" && {
                  type: "button",
                  iconType: "archive",
                  tooltipLabel: strings.archive,
                  onClick: () => {
                    setOfferId(item.id);
                    setShowMarkArchiveDialog(true);
                  },
                  color: "#0094f1",
                  id: item.id,
                },
              !item.archived &&
                auctionData?.status?.slug === "completed" &&
                getPermission("Negotiation", "create") && {
                  type: "button",
                  iconType: "negotiation",
                  tooltipLabel: strings.mark_as_negotiator,
                  onClick: () => {
                    setShowMarkAsNegotiator(item);
                  },
                  id: item.id,
                },
            ]}
          />
        ),
      }));
      return auctionList;
    }
  };

  let updatedList = getAuctionList();
  let columnsToRender = getTableColumns(updatedList, offersObject?.permitted_meta?.static_columns, auction_OfferTrails);
  const negotiationsPermittedColumns = auctionNegotiations?.permittedMeta?.static_columns;
  // console.log({ "auctionNegotiations?.negotiations": auctionNegotiations?.negotiations });

  console.log({ realTimeNegotiations });

  const latestNegotiation = sortNegotiationsByUpdatedTime(auctionNegotiations?.negotiations)[0];

  const { dre = {}, purchaser = {} } = latestNegotiation || {};

  const BiddingComponent = () => {
    return !isFieldRestricted(auctionCycles) ? (
      <>
        {isBookingServicesRestricted && (
          <ConfirmationDialog
            visible={showMarkWinnerDialog}
            setVisible={setShowMarkWinnerDialog}
            title={currentApp === "crm" ? strings.generate_crm_lead : strings.mark_as_winner}
            body={currentApp === "crm" ? strings.generate_crm_lead_message : strings.mark_as_winner_message}
            apiPromise={markAuctionOfferAsWinner}
            payloadData={{
              id: latestAuctionId,
              data: {
                offer_id: offerId,
              },
            }}
            onSuccess={response => {
              fetchCar();
            }}
            fetchOffers={fetchOffers}
          />
        )}

        {!isBookingServicesRestricted && (
          <Modal
            toggle={() => {
              setShowMarkWinnerDialog(prev => !prev);
            }}
            title={strings.mark_as_winner}
            open={showMarkWinnerDialog}
            className="offer-trails-modal"
            body={() => (
              <>
                <MarkAsWinnerForm
                  offer={offersObject?.offers?.find(offer => offer?.id === offerId) ?? null}
                  offerId={offerId}
                  ref={markAsWinnerRef}
                  setOpenModal={setShowMarkWinnerDialog}
                  openModal={showMarkWinnerDialog}
                  fetchOffers={fetchOffers}
                  auctionCycle={latestAuctionId}
                  setLoading={setLoadingWinner}
                />
              </>
            )}
            actions={[
              {
                label: strings.cancel,
                onClick: () => {
                  setShowMarkWinnerDialog(false);
                },
                color: "secondary",
                className: "modal__cancel-btn btn-pill",
              },
              {
                label: strings.done,
                color: "primary",
                className: "modal__cr-btn btn-pill",
                onClick: () => {
                  markAsWinnerRef.current && markAsWinnerRef.current.submitForm();
                },
                disabled: loadingWinner,
                loading: loadingWinner,
              },
            ]}
          />
        )}

        <>
          <div className="auction__noborder--table">
            <DataTableComponent
              className="sticky-action-cells offer-trail-cells"
              tableData={updatedList}
              columns={columnsToRender}
              noHeader
              handlePagination
              paginationObject={offersObject?.pagination}
              limitPerPage={tenant?.configuration?.records_per_page}
              fetch={fetchOffers}
              history={history}
              noUrlPush
              queryParam={`?q[auction_id_eq]=${auctionCycle || latestAuctionId}&`}
            />
          </div>
          {showMarkAsNegotiator && (
            <MarkAsNegotiatorModal setShowModal={setShowMarkAsNegotiator} showModal={showMarkAsNegotiator} />
          )}
        </>
      </>
    ) : null;
  };

  const AuctionsComponent = () => {
    const negotiators = [
      ...(dre?.name && !isFieldRestricted(dre?.name)
        ? [
            {
              label: "DRE",
              ...dre,
            },
          ]
        : []),
      ...(purchaser?.name && !isFieldRestricted(purchaser?.name)
        ? [
            {
              label: "Purchaser",
              ...purchaser,
            },
          ]
        : []),
    ];

    const filteredNegotiations = auctionNegotiations?.negotiations
      ?.filter(obj => obj.id !== realTimeNegotiations?.id)
      ?.map(oldNegotiations => ({
        ...oldNegotiations,
        isCurrentNegotiation: false,
      }));

    console.log({ filteredNegotiations });

    const negotiationsWithRealTime = !!Object.keys(realTimeNegotiations || {})?.length
      ? [{ ...realTimeNegotiations, isCurrentNegotiation: true }, ...filteredNegotiations]
      : filteredNegotiations;
    if (postAuctionNegotiationLoading) return <Loader style={{ minHeight: "540px" }} />;
    if (!negotiationsWithRealTime?.length) return <EmptyState title={"No negotiations Found !"} minHeight={540} />;
    return (
      <div>
        <div className={cx("d-flex align-items-center justify-content-end", styles.negotiators)}>
          {negotiators?.map((item, idx) => {
            return (
              <div key={idx} className="d-flex align-items-center">
                <div className={styles.userType}>{item?.label}:</div>
                <Avatar name={item?.name} size={20} />
              </div>
            );
          })}
        </div>
        <PostAuctionCards
          data={realTimeNegotiations ?? latestNegotiation}
          negotiationsPermittedColumns={negotiationsPermittedColumns}
        />
        <Group template={"initial"} gap={"24px"}>
          {negotiationsWithRealTime?.map((negEntry, idx) => (
            <>
              <AuctionNegotiationTable
                key={idx}
                index={idx}
                title={negEntry?.started_by?.full_name || negEntry?.started_by?.name}
                data={negEntry}
                containsRealTime={!!negotiationsWithRealTime}
                statuses={statuses}
                offersObject={offersObject}
                markAsWinnerRef={markAsWinnerRef}
                fetchOffers={fetchOffers}
                latestAuctionId={latestAuctionId}
                setLoadingWinner={setLoadingWinner}
                loadingWinner={loadingWinner}
                offerId={offerId}
                // sellerExpectations={sellerExpectations}
                user={user}
                negotiationsPermittedColumns={negotiationsPermittedColumns}
                auctionDetail={auctionData}
              />
            </>
          ))}
        </Group>
      </div>
    );
  };

  return (
    <div className="position-relative">
      <div className={cx("theme-form", styles.auctionSelect)}>
        {!isFieldRestricted(auctionCycles) && auctionCycles?.length > 0 && (
          <FormSelect
            options={
              auctionCycles
                ?.sort((a, b) => b.cycle - a.cycle)
                ?.map(e => ({
                  slug: e.auction_id,
                  label: e.cycle,
                })) || []
            }
            label={strings.auction_cycle}
            value={auctionCycle}
            defaultValue={auctionCycle}
            onChange={e => {
              setAuctionCycle(e.target.value);
              fetchOffers(`?q[auction_id_eq]=${e.target.value}`);
            }}
            setSlugAsValue
            horizontal
            labelClass="col-sm-6 text-right align-self-center"
            selectContainerClass="col-sm-6"
          />
        )}
      </div>
      <Tabs
        className="col-sm-12"
        onSelect={index => {
          setTabIndex(index);
        }}
      >
        <TabList className="tabs tab-title">
          {tabsList?.map((item, i) => {
            return (
              <Fragment key={i}>
                <Tab key={i}>{item.name}</Tab>
              </Fragment>
            );
          })}
        </TabList>
        <div className="tab-content-cls">
          <TabPanel key={"bids"}>
            <div>
              <BiddingComponent />
            </div>
          </TabPanel>
          {getPermission("Negotiation", "index") && (
            <TabPanel key={"post-auctions"}>
              <div>
                <AuctionsComponent />
              </div>
            </TabPanel>
          )}
        </div>
      </Tabs>
      <Modal
        toggle={() => {
          setShowMarkArchiveDialog(!showMarkArchiveDialog);
          if (showMarkArchiveDialog) {
            setArchiveReason("");
            setErrorArchive("");
          }
        }}
        title={strings.archive_offer}
        open={showMarkArchiveDialog}
        body={() => (
          <>
            <FormInput
              className="save_model_row"
              placeholder="Enter Reason"
              onChange={e => setArchiveReason(e.target.value)}
              value={archiveReason}
            />
            {errorArchive && <CustomAlert message={errorArchive} />}
          </>
        )}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setArchiveReason("");
              setErrorArchive("");
              setShowMarkArchiveDialog(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
          {
            label: strings.done,
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: onArchive,
            disabled: loadingArchive,
            loading: loadingArchive,
          },
        ]}
      />
    </div>
  );
};

const mapStateToProps = ({ Configs, PermissionsData, AuctionNegotiationsData }) => {
  return {
    auctionNegotiations: AuctionNegotiationsData,
    tenant: Configs.tenant,
    permissions: PermissionsData.permissions,
  };
};

export default connect(mapStateToProps, {})(OfferTrails);
