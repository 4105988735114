import React, { useState } from "react";
import { getErrorString } from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { CustomAlert } from "./CustomAlert";
import Modal from "./ModalBase";

const ConfirmationDialog = props => {
  const { visible, setVisible, title = "Confirmation", body, apiPromise, onSuccess, fetchOffers } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const offerId = props.payloadData?.data?.offer_id;
  const auctionCycle = props.payloadData?.id;

  const onDone = async () => {
    const payload = {
      offer: {
        won: true,
      },
    };

    const response = await apiPromise(offerId, payload);
    setLoading(true);
    if (response) {
      if (response.success) {
        onSuccess(response);
        fetchOffers(`?q[auction_id_eq]=${auctionCycle}`);
        setVisible(false);
      } else {
        setError(getErrorString(response));
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      toggle={() => {
        setVisible(!visible);
        if (visible) setError("");
      }}
      title={title}
      open={visible}
      body={() => (
        <>
          {typeof body === "string" ? <p>{body}</p> : body}
          {error && <CustomAlert message={error} />}
        </>
      )}
      actions={[
        {
          label: strings.no,
          onClick: () => {
            setVisible(false);
            if (visible) setError("");
          },
          color: "secondary",
          className: "modal__cancel-btn btn-pill",
        },
        {
          label: strings.yes,
          color: "primary",
          className: "modal__cr-btn btn-pill",
          onClick: () => onDone(),
          disabled: loading,
          loading: loading,
        },
      ]}
    />
  );
};

export default ConfirmationDialog;
