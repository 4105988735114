import {
  checkUndefinedApiParams,
  getErrorString,
  getFormattedTimeStringForMeetingCard,
  getPermission,
  isFieldEditable,
  isFieldViewable,
  isObjRestricted,
  parseLocationType as fetchLocationType,
  setTimeZone,
  objectToQuery,
  appendObjectToQuery,
} from "../../../helpers/Util";
import { crmLeadUpdateTask } from "../../../actions/crm/crm-detail.action";
import { updateTask, updateTaskMeetings } from "../../../actions/crm";
import { Button, IF, SearchAssignee, Toast } from "../../common";
import React, { useState } from "react";
import { MdEditCalendar, RiTimeFill } from "../../svg-icon/svg-icons";
import { TaskStatusInput } from "../../crm";
import moment from "moment";
import { strings } from "../../../constant/strings";
import { fetchLocationAssigneesApi, updateTaskApi } from "../../../helpers/ApiHelper";
import { connect } from "react-redux";
import { PaginatedSearch } from "components/common/PaginatedSearch";
import { NON_RESCHEDULABLE_STATUSES, TASK_MEETING_TYPES } from "constant/CRM";

const fetchResponseKey = "available_users";

const meetingItemColors = {
  scheduled: {
    "--meeting-wrapper-bg": "#FFFCF8",
    "--meeting-wrapper-border": "#E65F41",
  },
  cancelled: {
    "--meeting-wrapper-bg": "#F9F9F9",
    "--meeting-wrapper-border": "#696969",
  },
  done: {
    "--meeting-wrapper-bg": "#FAFFFB",
    "--meeting-wrapper-border": "#0BB233",
  },
  confirmed: {
    "--meeting-wrapper-bg": "#e1f2f2",
    "--meeting-wrapper-border": "#52E2DD",
  },
  "no-show": {
    "--meeting-wrapper-bg": "#F9F9F9",
    "--meeting-wrapper-border": "#696969",
  },
  "awaiting-confirmation": {
    "--meeting-wrapper-bg": "#FFFCF8",
    "--meeting-wrapper-border": "#E65F41",
  },
  rescheduled: {
    "--meeting-wrapper-bg": "rgb(0 144 254 / 18%)",
    "--meeting-wrapper-border": "#E2F9FD",
  },
  [`cancelled-at-branch`]: {
    "--meeting-wrapper-bg": "#F9F9F9",
    "--meeting-wrapper-border": "#696969",
  },
  [`cancelled-by-deposit-taken`]: {
    "--meeting-wrapper-bg": "#F9F9F9",
    "--meeting-wrapper-border": "#696969",
  },
  [`cancelled-to-be-rescheduled`]: {
    "--meeting-wrapper-bg": "#F9F9F9",
    "--meeting-wrapper-border": "#696969",
  },
};

//    "--meeting-wrapper-bg": "#E65F41",
//  "--meeting-wrapper-border": "#e65f4100",

const MeetingCard = ({
  taskOptions,
  disabled,
  meetings,
  timeZone,
  postMeetings,
  meeting,
  showMeetingCalender,
  crmLeadUpdateTask,
  TaskDetail,
  task,
  updateTaskMeetings,
  updateTask,
  taskFrom,
  onAppointmentUpdate,
  crmTaskPermittedAttributes,
  onAppointmentAssigneeUpdate,
  rescheduleMeeting,
  values,
}) => {
  const { start_time, status, end_time, description, area, id, time_slot, location } = meeting;
  const [disableAppointment, setDisableAppointment] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState(meeting.user);
  const filteredTaskOptions = taskOptions.filter(
    taskOption => taskOption.slug !== "rescheduled" && taskOption.slug !== "cancelled-by-deposit-taken"
  );

  const getAssigneePropsOnType = (type, meeting) => {
    const timeRangeParams = `&start_time=${meeting.start_time}&end_time=${meeting.end_time}`;
    switch (type) {
      case TASK_MEETING_TYPES.BRANCHES:
        return {
          assigneeQueryParams: `?branch_id=${meeting?.location?.id || ""}${timeRangeParams}`,
          assigneeDisabled: disabled,
          addressString: `${strings.branch}: ${meeting?.location?.address}`,
        };
      case TASK_MEETING_TYPES.WAREHOUSE:
        return {
          assigneeQueryParams: `?warehouse_id=${meeting?.location?.id || ""}${timeRangeParams}`,
          assigneeDisabled: disabled,
          addressString: `${strings.warehouse}: ${meeting?.location?.address}`,
        };
      case TASK_MEETING_TYPES.AREA:
        return {
          assigneeQueryParams: `?area_id=${meeting?.area?.id || ""}${timeRangeParams}&task_type_id=${
            values?.task_type?.id
          }${values?.language ? `&language_preference=${values?.language}` : ``}`,
          assigneeDisabled: disabled,
          addressString: `${strings.area}: ${meeting?.area?.name}, ${meeting?.area?.city?.name}`,
        };
      case TASK_MEETING_TYPES.SELLER_ADDRESS:
        return {
          assigneeQueryParams: `?seller_address_id=${meeting?.time_slot?.id}&task_type_id=${
            values?.task_type?.id
          }${timeRangeParams}${values?.language ? `&language_preference=${values?.language}` : ""}`,

          assigneeDisabled: disabled,
          addressString: `${strings.address}: ${meeting?.time_slot?.address}`,
        };
      default:
        return {
          assigneeQueryParams: "",
          assigneeDisabled: true,
          addressString: "",
        };
    }
  };

  const locationType = fetchLocationType(meeting);
  const { assigneeQueryParams, assigneeDisabled, addressString } = getAssigneePropsOnType(locationType, meeting);

  const updateTaskAssignee = (taskFrom, newUpdatedTask) => {
    switch (taskFrom) {
      case "lead":
        crmLeadUpdateTask(newUpdatedTask);
        break;
      case "calendar":
        onAppointmentUpdate(newUpdatedTask);
        break;
      default:
        updateTaskMeetings(meeting?.task?.id, newUpdatedTask?.appointments);
        updateTask(newUpdatedTask);
    }
  };

  const handleMeetingAssigneeChange = async assignee => {
    setSelectedAssignee(assignee);
    const taskId = meeting.task.id;
    const appointmentId = meeting.id;
    const assigneeId = assignee.id;
    const newTask = {
      task: {
        appointments_attributes: [
          {
            id: appointmentId,
            user_id: assigneeId,
          },
        ],
      },
    };
    if (!checkUndefinedApiParams(taskId, "task")) return;
    try {
      setDisableAppointment(true);
      const response = await updateTaskApi(taskId, newTask);
      if (response.success) {
        updateTaskAssignee(taskFrom, response.task);
        onAppointmentAssigneeUpdate(response?.task?.appointments);
      } else {
        Toast.error(getErrorString(response));
      }
    } catch (error) {
      Toast.error(getErrorString(error));
    } finally {
      setDisableAppointment(false);
    }
  };

  return (
    <div
      className="d-flex flex-column media sidebar-meeting-wrapper position-relative b-r-6"
      style={status?.slug ? meetingItemColors[status.slug] : meetingItemColors["scheduled"]}
      key={start_time + end_time}
    >
      <div className="d-flex flex-row align-items-start w-100">
        <RiTimeFill size="18" className="time-icon m-r-5" />
        <div className="d-flex flex-column m-l-5 media-body">
          <h6 className="m-b-5">
            {moment(start_time).format("MMM DD").toString()}, {moment(start_time).format("h:mm A").toString()} -{" "}
            {moment(end_time).format("h:mm A").toString()}
          </h6>
          <span className="text-muted f-12">{addressString}</span>
          <IF condition={description}>
            <div className="m-t-20">
              <span className="text-muted d-block f-12 m-b-6">{strings.description}</span>
              <p className="f-14 text-break">{description}</p>
            </div>
          </IF>
          <IF condition={id}>
            <div className="m-t-20">
              <span className="text-muted d-block f-12 m-b-10">{"Assignee"}</span>
              {getPermission("Task", "update") && isFieldViewable(crmTaskPermittedAttributes?.["assignee.name"]) && (
                <>
                  <PaginatedSearch
                    rowClass="mb-0"
                    infoView
                    showLabel={false}
                    toggleClassName="form-control text-truncate"
                    isSingleSelect
                    defaultSelected={selectedAssignee}
                    selectHeading={strings.assignee}
                    id={meeting.id}
                    showSearchIcon={false}
                    loading={false}
                    fetchOptions={(input, page) => {
                      let query = appendObjectToQuery({ "s[name]": input, page });
                      return fetchLocationAssigneesApi(assigneeQueryParams + query);
                    }}
                    onChange={handleMeetingAssigneeChange}
                    disabled={!isFieldEditable(crmTaskPermittedAttributes?.["assignee.name"]) || assigneeDisabled}
                  />
                </>
              )}
              <br></br>
              <span className="text-muted d-block f-12 m-b-10">{strings.status}</span>
              <span className="d-flex flex-row">
                {!isObjRestricted(status, ["name"]) && isFieldViewable(crmTaskPermittedAttributes?.["status.name"]) && (
                  <TaskStatusInput
                    status={filteredTaskOptions}
                    selectedTask={status}
                    onChange={status => {
                      meeting.status = status;
                      postMeetings(meeting, true);
                    }}
                    canUpdate={
                      getPermission("Task", "update") &&
                      isFieldEditable(crmTaskPermittedAttributes?.["status.name"]) &&
                      !disabled &&
                      !disableAppointment
                    }
                  />
                )}
              </span>
            </div>
          </IF>
        </div>
      </div>

      {!NON_RESCHEDULABLE_STATUSES.includes(status?.slug) && (
        <div className="ml-auto mt-2">
          <Button
            btnText="Reschedule"
            color="link"
            size="sm"
            variant="text-danger"
            icon={<MdEditCalendar />}
            className="p-0"
            onClick={() => rescheduleMeeting()}
            disabled={disableAppointment}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ TaskDetail }) => {
  return {
    ...TaskDetail,
  };
};

export default connect(mapStateToProps, {
  crmLeadUpdateTask,
  updateTask,
  updateTaskMeetings,
})(MeetingCard);
