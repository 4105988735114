import React, { useEffect, useReducer } from "react";
import { Button, FormInput, Skeleton, Modal } from ".";
import { strings } from "../../constant/strings";
import { fetchAreaListApi, fetchCityListApi } from "../../helpers/ApiHelper";
import { actionFactory, INITIAL_STATE, reducer } from "../../reducers/local/areaSelectReducer";
import FlatList from "./FlatList";
import { defaultFn, isEmptyArray, isEmptyObject } from "../../helpers/Util";
import useIsMounted from "../../hooks/useIsMounted";
import { IoIosArrowForward, TickIcon } from "../svg-icon/svg-icons";

const SelectItem = props => {
  const {
    onSelect,
    selected,
    optionProperty = "name",
    loading,
    isMultiSelect = false,
    isSelectAll = false,
    showTickIcon = false,
  } = props;
  const entry = props.item;
  const getIsSelected = () => {
    if (isMultiSelect) {
      return (Array.isArray(selected) && selected?.map(selected => selected.id).includes(entry.id)) || isSelectAll;
    } else {
      return selected?.id === entry.id;
    }
  };
  return (
    <div
      key={entry.id}
      className={`modal-list d-flex align-items-center justify-content-between ${getIsSelected() ? "selected" : ""}`}
      onClick={onSelect(entry)}
    >
      <span>{entry[optionProperty]}</span>
      {getIsSelected() && showTickIcon ? (
        <TickIcon style={{ minWidth: "1em" }} color={"hsl(var(--primary-color))"} />
      ) : (
        <IoIosArrowForward style={{ minWidth: "1em" }} />
      )}
    </div>
  );
};
const ListSkeleton = props => {
  const { loading } = props;
  if (!loading) return null;
  return [1, 2, 3, 4, 5, 6, 7].map(e => (
    <Skeleton key={e} height={45} themeProps={{ color: "#DCDCDC", highlightColor: "#F0F0F0" }} className="my-2" />
  ));
};

const AreaSelectModal = ({
  visible,
  setVisible,
  onSelect,
  variant = "",
  showAreaOnly = false,
  showLocationOnly = false,
  isMultiSelect = false,
  selectedOptions = {},
}) => {
  const isMounted = useIsMounted();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const actions = actionFactory(dispatch);
  const initialAreaType = showLocationOnly ? "location" : "area";

  const fetchInitialCityData = async (search = null) => {
    actions.setSingleList("city", []);
    actions.setLoading("city", true);
    const params = `?page=1${search ? `&q[name_start]=${search}` : ""}`;
    const response = await fetchCityListApi(params);
    if (!isMounted) return;
    if (response.success) {
      actions.setSingleList("city", [...response.cities], response.pagination);
      handlePrefillOptions({ cities: [...response.cities] });
    }
    actions.setLoading("city", false);
  };

  const fetchInitialAreaData = async ({ areaType, city, cityId, search = null }) => {
    actions.setSingleList("area", []);
    actions.setLoading("area", true);
    const locationParam = `q[location_id_null]=${areaType !== "location"}`;
    const cityParam = `q[city_id_eq]=${cityId}`;
    const searchParam = search ? `&q[name_start]=${search}` : "";
    const params = `?${cityParam}&${locationParam}${searchParam}`;
    const response = await fetchAreaListApi(params);
    if (response.success) {
      actions.setSingleList("area", [...response.areas], response.pagination);
      return [...response.areas];
    }
    actions.setLoading("area", false);
  };

  const fetchMoreAreaData = async () => {
    const nextPage = state?.pagination?.area?.next_page;
    if (!nextPage) return;
    const paginationParam = `page=${state?.pagination?.area.next_page}`;
    const locationParam = `q[location_id_null]=${state.areaType !== "location"}`;
    const cityParam = `q[city_id_eq]=${state?.selected?.city?.id}`;
    const searchParam = state?.search?.area ? `&q[name_start]=${state.search.area}` : ``;
    const params = `?${paginationParam}&${cityParam}&${locationParam}${searchParam}`;
    const response = await fetchAreaListApi(params);
    if (response.success) {
      actions.setSingleList("area", [...state.lists.area, ...response.areas], response.pagination);
      handlePrefillOptions({ areas: [...state.lists.area, ...response.areas] });
    }
  };

  const fetchMoreCityData = async () => {
    const nextPage = state?.pagination?.city?.next_page;
    if (!nextPage) return;
    const paginationParam = `?page=${state?.pagination?.city.next_page}`;
    const searchParam = state?.search?.area ? `&q[name_start]=${state.search.city}` : ``;
    const params = `${paginationParam}${searchParam}`;
    const response = await fetchAreaListApi(params);
    if (response.success) {
      actions.setSingleList("city", [...state.lists.city, ...response.cities], response.pagination);
      handlePrefillOptions({ cities: [...response.cities] });
    }
  };

  const onDone = obj => {
    onSelect(obj);
    setVisible(false);
    actions.setSelected({});
  };
  useEffect(() => {
    if (visible) {
      fetchInitialCityData();
    }
  }, [visible]);

  const handleAreaTypeChange = areaType => async e => {
    const areas = await fetchInitialAreaData({
      areaType,
      cityId: state?.selected?.city?.id,
      search: state?.search?.area,
    });
    handlePrefillAreaTypeChange({ areas: areas });
    actions.setAreaType(areaType);
  };

  const handlePrefillOptions = async ({ cities = [], areas = [], currentCity = null }) => {
    if (!isEmptyObject(selectedOptions) && isMultiSelect) {
      if (!isEmptyArray(cities)) {
        const preSelectedCities = cities.filter(city => selectedOptions?.cityIds?.includes(city?.id));
        const areas = await fetchInitialAreaData({
          areaType: state?.areaType,
          cityId: preSelectedCities?.[0]?.id,
          search: state?.search?.["area"],
        });
        const preSelectedAreas = areas.filter(area => selectedOptions?.areaIds?.includes(area?.id));
        actions.setSelected({
          city: preSelectedCities?.[0],
          area: preSelectedAreas,
          selectAllAreas: selectedOptions?.selectAllAreas ?? false,
        });
      }
      if (!isEmptyArray(areas)) {
        const preSelectedAreas = areas.filter(area => selectedOptions?.areaIds?.includes(area?.id));
        actions.setSelected({
          city: currentCity ?? state?.selected?.city,
          area: currentCity ? preSelectedAreas : [...(state?.selected?.area ?? []), ...preSelectedAreas],
          selectAllAreas: currentCity ? false : state?.selected?.selectAllAreas,
        });
      }
    }
  };

  const handlePrefillAreaTypeChange = async ({ areas = [] }) => {
    if (!isEmptyObject(selectedOptions) && isMultiSelect && !isEmptyArray(areas)) {
      const preSelectedAreas = areas.filter(area => selectedOptions?.areaIds?.includes(area?.id));
      const updatedArea = preSelectedAreas?.length ? [...(state?.selected?.area ?? []), ...preSelectedAreas] : null;
      actions.setSelected({
        city: state?.selected?.city,
        area: updatedArea,
      });
    }
  };

  return (
    <Modal
      toggle={() => {
        setVisible(!visible);
      }}
      open={visible}
      className={`${"modal-lg deposit_model crm-modal background-white"} ${variant}`}
      footerClass="background-white"
      headerClass="background-white"
      body={() => {
        return (
          <div className="modal-wrapper d-flex flex-row background-white">
            <div className="modal-inner-wrapper">
              <h6>{strings.city}</h6>

              <div className="modal-list-wrapper d-flex flex-column">
                <div className="form theme-form db__form-main">
                  <FormInput
                    className="save_model_row"
                    placeholder={strings.city}
                    onChange={e => {
                      actions.setSingleSearch({ property: "city", value: e.target.value });
                      fetchInitialCityData(e.target.value);
                    }}
                    value={state.search.city}
                  />
                </div>
                <FlatList
                  ListComponent={SelectItem}
                  className={""}
                  styleProps={{ height: "480px" }}
                  // fetchItems={fetchCityData}

                  parentList={{
                    fetchInitialData: defaultFn,
                    fetchMoreData: fetchMoreCityData,
                    list: state?.lists?.city ?? [],
                    hasMore: !!state?.pagination?.city,
                  }}
                  onSelect={city => async e => {
                    actions.setSelected({ city, area: null });
                    actions.setAreaType(initialAreaType);
                    const areas = await fetchInitialAreaData({
                      areaType: initialAreaType,
                      cityId: city.id,
                      search: state?.search?.["area"],
                    });
                    handlePrefillOptions({ areas: areas, currentCity: city });
                  }}
                  ScrollLoader={ListSkeleton}
                  disableLoader={!state.lists?.city?.length > 0}
                  optionProperty={"name"}
                  selected={state.selected.city}
                  isSelectAll={state?.selected?.selectAllAreas}
                  showTickIcon={state?.selected?.selectAllAreas}
                />
              </div>
            </div>

            <div className="modal-inner-wrapper">
              <h6>{strings.area}</h6>
              {!!state.selected?.city && (
                <div className="modal-list-wrapper d-flex flex-column">
                  <div className="form theme-form db__form-main">
                    <FormInput
                      className="save_model_row"
                      placeholder={state.areaType === "area" ? strings.area : strings.location}
                      onChange={async e => {
                        actions.setSingleSearch({ property: "area", value: e.target.value });
                        const areas = await fetchInitialAreaData({
                          areaType: state.areaType,
                          cityId: state.selected.city.id,
                          search: e.target.value,
                        });
                        handlePrefillOptions({ areas: areas });
                      }}
                      value={state.search.area}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                    <div className="d-flex space-between" style={{ gap: 5 }}>
                      {!showLocationOnly && (
                        <Button
                          size="sm"
                          color={state.areaType === "area" ? "primary" : "outline-primary"}
                          btnText={strings.area}
                          onClick={handleAreaTypeChange("area")}
                          className="py-1 px-3"
                        />
                      )}

                      {!showAreaOnly && (
                        <Button
                          size="sm"
                          color={state.areaType === "location" ? "primary" : "outline-primary"}
                          btnText={strings.location}
                          onClick={handleAreaTypeChange("location")}
                          className="py-1 px-3"
                        />
                      )}
                    </div>
                    {isMultiSelect && state?.areaType === "area" && (
                      <div
                        className="btn btn-link"
                        onClick={() => {
                          actions.setSelected({
                            city: state?.selected?.city,
                            area: null,
                            selectAllAreas: state?.selected?.selectAllAreas ? !state?.selected?.selectAllAreas : true,
                          });
                        }}
                      >
                        {state?.selected?.selectAllAreas ? strings.deselect_all : strings.select_all}
                      </div>
                    )}
                  </div>

                  <FlatList
                    ListComponent={SelectItem}
                    className={""}
                    parentList={{
                      fetchInitialData: defaultFn,
                      fetchMoreData: fetchMoreAreaData,
                      list: state?.lists?.area ?? [],
                      hasMore: !!state?.pagination?.area,
                    }}
                    onSelect={area => async e => {
                      if (isMultiSelect) {
                        let newAreas = [...(state.selected?.area ?? [])];
                        if (newAreas.find(selectedArea => selectedArea.id === area.id)) {
                          newAreas = newAreas.filter(selectedArea => selectedArea.id !== area.id);
                        } else {
                          newAreas.push({ ...area });
                        }
                        actions.setSelected({ city: state.selected.city, area: newAreas });
                      } else {
                        actions.setSelected({ city: state.selected.city, area });
                        let newObj = {
                          city: state.selected.city,
                          area,
                        };
                        onDone({ ...newObj });
                      }
                    }}
                    styleProps={{ height: "470px" }}
                    selected={state.selected.area}
                    ScrollLoader={ListSkeleton}
                    disableLoader={!state.loading.area}
                    optionProperty={"name"}
                    isMultiSelect={isMultiSelect}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }}
      actions={[
        {
          label: strings.cancel,
          onClick: () => {
            setVisible(false);
          },
          color: "secondary",
          className: "modal__cancel-btn btn-pill",
        },
        isMultiSelect && {
          label: strings.done,
          color: "primary",
          className: "modal__cr-btn btn-pill",
          onClick: () => {
            onSelect({
              city: state.selected?.city,
              area: state.selected?.area ?? [],
              selectAllAreas: state?.selected?.selectAllAreas || false,
            });
            setVisible(false);
          },
        },
      ].filter(Boolean)}
    />
  );
};

export default AreaSelectModal;
