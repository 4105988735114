import { numberValidation } from "../../../helpers/validations";
import { strings } from "../../../constant/strings";
import { AsyncSelect, CheckboxComponent, DynamicDateSelect, DynamicListSelect, FormInput } from "../index";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  ifValueForInput,
  ifValueForTypeahead,
  ifValueTypeNumber,
  makeDynamicSelectFieldOptionsData,
  makeOptionsData,
} from "./DynamicFieldHelpers";
import IF from "../IF";
import AttachmentUpload from "./AttachmentUpload/AttachmentUpload";
import { BtnDefaultState, Chip, Modal } from "../../common";
import AddLinkTypeDynamicField from "../../forms/AddLinkTypeDynamicField";
import {
  formatOnlyDate,
  formatValueForDealerEvaluation,
  isFieldEditable,
  isFieldRestricted,
  isFieldViewable,
  isParentOptionSelected,
  openBulkImportUrl,
} from "../../../helpers/Util";
import { regex } from "../../../constant/regex";
import { contractTypeDynamicAttributesDisableMetaData, DYNAMIC_FIELD_TYPES } from "constant/appConstants";
import moment from "moment";
import { fetchItemListsApi, fetchUsersListApi } from "../../../helpers/ApiHelper";
import SelectField from "../SelectField";

const getErrorMsg = (validateIsTouchedError, errors, modelName, touched, value) => {
  const fieldKey = `${modelName}_dynamic_${value.field_name}`;
  if (validateIsTouchedError) {
    return errors[fieldKey] && touched[fieldKey] && errors[fieldKey];
  } else {
    return errors[fieldKey];
  }
};

export const FormInputField = props => {
  const {
    value,
    modelName,
    values,
    errors,
    setFieldValue,
    setFieldError,
    handleBlur,
    touched,
    isEditable,
    columnRightSection,
    columnLeftSection,
    horizontal = true,
  } = props;
  const fieldKey = `${modelName}_dynamic_${value.field_name}`;
  const onInputChange = (event, value) => {
    const data = { value: event.target.value, type: value.field_data_type };
    setFieldValue(fieldKey, data);
  };

  const successCallback = () => {};
  const errorCallback = () => setFieldError(fieldKey, strings.enter_number_message);

  const checkValidation = inputValue => {
    if (value.field_data_type === DYNAMIC_FIELD_TYPES.INTEGER) {
      numberValidation(inputValue, successCallback, errorCallback, regex.numbersOnly);
    } else if (value.field_data_type === DYNAMIC_FIELD_TYPES.PRICE) {
      numberValidation(inputValue, successCallback, errorCallback, regex.decimalNumbers);
    }
  };

  return (
    <FormInput
      label={value.field_label}
      placeholder={value.field_place_holder}
      name={fieldKey}
      onChange={e => onInputChange(e, value)}
      onBlur={e => {
        handleBlur(e);
        checkValidation(values[fieldKey]?.value || "");
      }}
      value={values[fieldKey]?.value ?? ""}
      linesCount={value.field_data_type === DYNAMIC_FIELD_TYPES.TEXT ? "3" : undefined}
      errorMsg={
        getErrorMsg(props.validateIsTouchedError, errors, modelName, props.touched, value)?.value ||
        getErrorMsg(props.validateIsTouchedError, errors, modelName, props.touched, value)
      }
      required
      key={value.field_name}
      horizontal={horizontal}
      isEditable={isEditable}
      textRight={true}
      id={value.field_name}
      columnRightSection={columnRightSection}
      columnLeftSection={columnLeftSection}
      {...(ifValueTypeNumber(value.field_data_type) ? { type: "number" } : undefined)}
    />
  );
};

export const FormLinkField = props => {
  const [showModal, setShowModal] = useState(false);
  const [isLinkSelected, setIsLinkSelected] = useState(false);
  const { value, modelName, values, errors, setFieldValue, setFieldError, handleBlur, isEditable } = props;
  const submitRef = useRef();
  const fieldKey = `${modelName}_dynamic_${value.field_name}`;

  const currentValue = values[fieldKey];

  useEffect(() => {
    currentValue && setIsLinkSelected(true);
  }, [currentValue]);

  const handleOnClickLabel = () => {
    setShowModal(true);
  };

  const getValuesObj = values => {
    const newLinkFieldValue = { type: DYNAMIC_FIELD_TYPES.LINK, title: values.label, link: values.url };
    setShowModal(false);
    setIsLinkSelected(true);
    setFieldValue(fieldKey, newLinkFieldValue);
  };

  const unSelectLabel = () => {
    setIsLinkSelected(false);
    setFieldValue(fieldKey, null);
  };

  const ShowLinkButton = () => {
    if (isLinkSelected) return null;
    return (
      <BtnDefaultState
        disabled={isEditable === false}
        errorMsg={getErrorMsg(props.validateIsTouchedError, errors, modelName, props.touched, value)}
        btnText={"Link"}
        onClick={props.isEditable === false ? () => {} : handleOnClickLabel}
      />
    );
  };

  const ShowChipAfterSelectingLink = () => {
    if (!isLinkSelected) return null;
    return (
      <div className={`${values[fieldKey]?.link ? "hover-pointer" : ""}`}>
        <Chip
          disabled={isEditable === false}
          title={values[fieldKey] && values[fieldKey].title}
          canClose={isEditable === true}
          onClose={unSelectLabel}
          onClick={props.isEditable === false ? () => {} : e => openBulkImportUrl(values[fieldKey]?.link)}
        />
      </div>
    );
  };

  return (
    <>
      <ShowLinkButton />
      <ShowChipAfterSelectingLink />

      <Modal
        toggle={() => {
          setShowModal(!showModal);
        }}
        title={"Add Link"}
        open={showModal}
        body={() => <AddLinkTypeDynamicField saveCallback={getValuesObj} ref={submitRef} />}
        actions={[
          {
            label: "Cancel",
            onClick: () => {
              setShowModal(false);
            },
            color: "secondary",
            className: "modal__cancel-btn btn-pill",
          },
          {
            label: "Add Link",
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: () => {
              submitRef.current && submitRef.current.submitForm();
            },
          },
        ]}
      />
    </>
  );
};

export const ImageInputField = props => {
  const {
    value,
    modelName,
    values,
    errors,
    setFieldValue,
    setFieldError,
    handleBlur,
    isEditable,
    horizontal = true,
  } = props;

  const createAttachmentsObject = attachmentIds =>
    attachmentIds && attachmentIds.length > 0
      ? {
          type: DYNAMIC_FIELD_TYPES.ATTACHMENT,
          attachment_ids: attachmentIds,
        }
      : "";

  const getAttachments = fieldKey => {
    return values[fieldKey] ? values[fieldKey].attachment_ids : [];
  };

  const onAttachmentsChange = (field, pictures) => {
    const attachments = createAttachmentsObject(pictures);
    setFieldValue(field, attachments);
  };

  const getUrl = () => values?.[fieldKey]?.url;
  const fieldKey = `${modelName}_dynamic_${value.field_name}`;
  const pictures = getAttachments(fieldKey);

  return (
    <AttachmentUpload
      label={value.field_label}
      title={value.field_name}
      pictures={pictures}
      field={fieldKey}
      setFieldValue={onAttachmentsChange}
      horizontal={horizontal}
      textRight={true}
      errorMsg={getErrorMsg(props.validateIsTouchedError, errors, modelName, props.touched, value)}
      url={getUrl()}
      isEditable={isEditable}
    />
  );
};

export const CheckboxComponentField = props => {
  const {
    value,
    modelName,
    values,
    errors,
    setFieldValue,
    setFieldError,
    handleBlur,
    horizontal = true,
    mainStyles,
    labelStyles,
    isLabelLeft = false,
  } = props;
  const fieldKey = `${modelName}_dynamic_${value.field_name}`;
  return (
    <CheckboxComponent
      label={value.field_label}
      checked={values[fieldKey] || ""}
      onChange={event => {
        setFieldValue(fieldKey, event.target.checked);
      }}
      onBlur={event => {
        handleBlur(fieldKey)(event);
      }}
      isEditable={props.isEditable}
      horizontal={horizontal}
      errorMsg={getErrorMsg(props.validateIsTouchedError, errors, modelName, props.touched, value)}
      mainStyles={mainStyles}
      labelStyles={labelStyles}
      isLabelLeft={isLabelLeft}
    />
  );
};

export const DynamicListSelectField = props => {
  const { value, modelName, values, errors, setFieldValue, touched } = props;
  const fieldKey = `${modelName}_dynamic_${value.field_name}`;
  return (
    <DynamicListSelect
      values={values}
      label={value.field_label}
      answer_key={value.answer_key}
      levels={value.levels}
      type={value.field_data_type}
      setFieldValue={setFieldValue}
      formFieldKey={fieldKey}
      errorMsg={errors[fieldKey] && touched[fieldKey] && errors[fieldKey]}
      textRight={true}
    />
  );
};

export const DynamicDateSelectField = props => {
  const {
    value,
    modelName,
    values,
    errors,
    setFieldValue,
    handleBlur,
    horizontal = true,
    dateFormat,
    showTimeSelect = true,
  } = props;
  const fieldKey = `${modelName}_dynamic_${value.field_name}`;
  const isValid = moment(values[fieldKey]?.value)?.isValid();

  const onDateChange = date => {
    if (date) {
      setFieldValue(fieldKey, { value: dateFormat ? formatOnlyDate(date) : date, type: value.field_data_type });
    } else {
      setFieldValue(fieldKey, null);
    }
  };

  return (
    <DynamicDateSelect
      label={value.field_label}
      placeholderText={strings.select_date}
      isEditable={props.isEditable}
      fieldName={value.field_name}
      errorMsg={
        getErrorMsg(props.validateIsTouchedError, errors, modelName, props.touched, value)?.value ||
        getErrorMsg(props.validateIsTouchedError, errors, modelName, props.touched, value)
      }
      onChange={date => onDateChange(date)}
      onBlur={event => {
        handleBlur(fieldKey)(event);
      }}
      selected={isValid ? (values[fieldKey]?.value ? new Date(values[fieldKey]?.value) : null) : null}
      dateFormat={dateFormat ? dateFormat : "HH:mm, d MMMM, yy"}
      textRight={true}
      onKeyDown={e => {
        e.preventDefault();
      }}
      isClearable={props.isEditable}
      horizontal={horizontal}
      showTimeSelect={showTimeSelect}
    />
  );
};

export const SelectListField = props => {
  const {
    value,
    modelName,
    values,
    errors,
    setFieldValue,
    horizontal = true,
    columnRightSection = "9",
    columnLeftSection = "",
    oldDependentField = [],
  } = props;
  const fieldKey = `${modelName}_dynamic_${value.field_name}`;
  const errorMsg = getErrorMsg(props.validateIsTouchedError, errors, modelName, props.touched, value);
  const setInitialValues = type => {
    switch (type) {
      case "single-select":
        return [];
      case "multi-select":
        return [];
      case "attachment":
        return { type, attachment_ids: [] };
      case "price" || "integer" || "string" || "text" || "datetime" || "user_list":
        return { type, value: "" };
      default:
        return null;
    }
  };
  return (
    <SelectField
      className="flex-grow-1 m-0"
      label={value.field_label}
      placeholder={value.field_place_holder}
      onChange={selected => {
        for (let i = 0; i < oldDependentField?.length; i++) {
          if (value.field_name === oldDependentField[i].parentField) {
            setFieldValue(
              `${modelName}_dynamic_${oldDependentField[i].field_name}`,
              setInitialValues(oldDependentField[i]?.field_data_type)
            );
          }
        }
        if (value.field_data_type === DYNAMIC_FIELD_TYPES.MULTI_SELECT) {
          setFieldValue(fieldKey, [...selected]);
        } else {
          setFieldValue(fieldKey, [selected]);
        }
      }}
      value={values[fieldKey]}
      errorMsg={errorMsg}
      formatOptionLabel={option => option.name}
      required
      isSearchable
      horizontal={horizontal}
      isEditable={props.isEditable}
      options={makeDynamicSelectFieldOptionsData(value, values, modelName)}
      isMulti={value.field_data_type === DYNAMIC_FIELD_TYPES.MULTI_SELECT}
      // containerClassName={"w-100"}
      columnsRightSection={columnRightSection}
    />
  );
};

export const AsyncSelectField = props => {
  const {
    value,
    modelName,
    values,
    errors,
    setFieldValue,
    horizontal,
    listApi,
    listProperty,
    extraParams = {},
    dynamicFieldType,
    queryKey = "",
  } = props;
  const fieldKey = `${modelName}_dynamic_${value.field_name}`;
  const errorMsg = getErrorMsg(props.validateIsTouchedError, errors, modelName, props.touched, value);
  return (
    <>
      <AsyncSelect
        isClearable={true}
        listApi={listApi}
        listProperty={listProperty}
        extraParams={extraParams}
        isMulti={false}
        horizontal={horizontal}
        label={value.field_label}
        isEditable={props.isEditable}
        placeholder={value.field_place_holder}
        options={makeOptionsData(value, values, modelName)}
        errorMsg={errorMsg}
        selected={
          dynamicFieldType === DYNAMIC_FIELD_TYPES.ITEM_LIST && values[fieldKey]
            ? [
                {
                  ...values[fieldKey],
                  label: formatValueForDealerEvaluation(values[fieldKey]),
                },
              ]
            : props.isEditable
            ? values[fieldKey]?.value
            : null
        }
        textRight
        onChange={selected => {
          if (selected) {
            if (dynamicFieldType === DYNAMIC_FIELD_TYPES.ITEM_LIST) {
              setFieldValue(fieldKey, selected?.select_value);
            } else {
              setFieldValue(fieldKey, {
                type: dynamicFieldType,
                value: selected,
              });
            }
          } else {
            setFieldValue(fieldKey, null);
          }
        }}
        queryKey={queryKey}
      />
    </>
  );
};

export const renderDynamicFields = (
  data = [],
  props,
  modelName = "",
  validateIsTouchedError,
  activeColumnsPermittedAttributes = null,
  horizontal = true,
  checkboxStyles = null,
  checkboxLabelStyles = null,
  dynamicFieldsData = [],
  oldDependentField = []
) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    columnLeftSection,
    columnRightSection,
    disableAll = false,
    isCheckBoxLabelLeft = false,
  } = props;
  return data.map((e, i) => {
    let isEditable = !disableAll && true;
    if (activeColumnsPermittedAttributes) {
      const activeColumnKey = `dynamic_attributes.${modelName}.${e.field_name}`;
      const activeColumnsPermittedAttributePayload = activeColumnsPermittedAttributes?.[activeColumnKey];
      if (!isFieldViewable(activeColumnsPermittedAttributePayload)) return null;
      isEditable =
        !disableAll &&
        isFieldEditable(activeColumnsPermittedAttributes?.[activeColumnKey]) &&
        isParentOptionSelected({ e, values, modelName, dynamicFieldsData });
    }
    const contractMeta = contractTypeDynamicAttributesDisableMetaData?.[values?.contractType?.slug];
    if (contractMeta && contractMeta[e?.field_name]) {
      isEditable = !contractMeta[e.field_name];
    }
    return (
      <Fragment key={e.id}>
        <IF condition={ifValueForInput(e.field_data_type)}>
          <div id={e.field_name}>
            <FormInputField
              value={e}
              modelName={modelName}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              handleBlur={handleBlur}
              validateIsTouchedError={validateIsTouchedError}
              isEditable={isEditable}
              horizontal={horizontal}
              columnLeftSection={columnLeftSection}
              columnRightSection={columnRightSection}
            />
          </div>
        </IF>

        <IF condition={e.field_data_type === DYNAMIC_FIELD_TYPES.LINK}>
          <div className={`${horizontal ? "form-group row align-items-center" : ""}`} id={e.field_name}>
            <label className={`${horizontal ? "col-sm-3 col-form-label text-right" : ""}`}>{e.field_label}</label>
            <div className={`${horizontal ? "col-sm-9" : ""}`}>
              <FormLinkField
                value={e}
                modelName={modelName}
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                handleBlur={handleBlur}
                validateIsTouchedError={validateIsTouchedError}
                isEditable={isEditable}
              />
            </div>
          </div>
        </IF>

        <IF condition={e.field_data_type === DYNAMIC_FIELD_TYPES.BOOLEAN}>
          <div className="form-group" id={e.field_name}>
            <CheckboxComponentField
              value={e}
              modelName={modelName}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              handleBlur={handleBlur}
              validateIsTouchedError={validateIsTouchedError}
              isEditable={isEditable}
              horizontal={horizontal}
              mainStyles={checkboxStyles}
              labelStyles={checkboxLabelStyles}
              isLabelLeft={isCheckBoxLabelLeft}
            />
          </div>
        </IF>
        <IF condition={e.field_data_type === DYNAMIC_FIELD_TYPES.ATTACHMENT}>
          <div id={e.field_name}>
            <ImageInputField
              value={e}
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              modelName={modelName}
              errors={errors}
              validateIsTouchedError={validateIsTouchedError}
              isEditable={isEditable}
              horizontal={horizontal}
            />
          </div>
        </IF>

        <IF condition={e.field_data_type === DYNAMIC_FIELD_TYPES.DATE_TIME}>
          <div id={e.field_name}>
            <DynamicDateSelectField
              value={e}
              modelName={modelName}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              handleBlur={handleBlur}
              validateIsTouchedError={validateIsTouchedError}
              isEditable={isEditable}
              horizontal={horizontal}
            />
          </div>
        </IF>

        <IF condition={e.field_data_type === DYNAMIC_FIELD_TYPES.DATE}>
          <div id={e.field_name}>
            <DynamicDateSelectField
              value={e}
              modelName={modelName}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              handleBlur={handleBlur}
              validateIsTouchedError={validateIsTouchedError}
              isEditable={isEditable}
              horizontal={horizontal}
              dateFormat={"d MMMM, yy"}
              showTimeSelect={false}
            />
          </div>
        </IF>

        {ifValueForTypeahead(e.field_data_type) && (
          <div id={e.field_name}>
            <SelectListField
              value={e}
              modelName={modelName}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              handleBlur={handleBlur}
              validateIsTouchedError={validateIsTouchedError}
              isEditable={isEditable}
              horizontal={horizontal}
              columnRightSection={columnRightSection}
              columnLeftSection={columnLeftSection}
              oldDependentField={oldDependentField}
            />
          </div>
        )}

        <IF condition={e.field_data_type === DYNAMIC_FIELD_TYPES.USER_LIST}>
          <div id={e.field_name}>
            <AsyncSelectField
              value={e}
              modelName={modelName}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              handleBlur={handleBlur}
              validateIsTouchedError={validateIsTouchedError}
              isEditable={isEditable}
              horizontal={horizontal}
              listApi={fetchUsersListApi}
              listProperty={"users"}
              extraParams={{
                "f[roles.id][]": isFieldRestricted(e?.filtered_roles) ? [] : e?.filtered_roles?.map(role => role.id),
              }}
              queryKey="s[name]"
              dynamicFieldType={DYNAMIC_FIELD_TYPES.USER_LIST}
            />
          </div>
        </IF>

        <IF condition={e.field_data_type === DYNAMIC_FIELD_TYPES.ITEM_LIST}>
          <div id={e.field_name}>
            <AsyncSelectField
              value={e}
              modelName={modelName}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              handleBlur={handleBlur}
              validateIsTouchedError={validateIsTouchedError}
              isEditable={isEditable}
              horizontal={horizontal}
              listApi={fetchItemListsApi}
              listProperty={"item_lists"}
              extraParams={{ locale: "en", "q[select_key_eq]": e?.field_name || "", "q[ancestry_eq]": "" }}
              queryKey="q[select_value_cont]"
              dynamicFieldType={DYNAMIC_FIELD_TYPES.ITEM_LIST}
            />
          </div>
        </IF>
      </Fragment>
    );
  });
};
