import React from "react";
import { getCurrency, isFieldRestricted, isObjRestricted, removeUnderscores } from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { MdContentCopy } from "../../components/svg-icon/svg-icons";
import { useSelector } from "react-redux";
import Toast from "../../components/common/Toast";
import { Skeleton } from "../../components/common";

const BookingDetailInfo = ({ bookingInfo, servicesItems, carDetailItems, loading }) => {
  const country = useSelector(content => content.Configs.tenant.country);
  const { longitude, latitude, booking_expenses } = bookingInfo;
  const isLocationAccessible = longitude && latitude && !isFieldRestricted(longitude) && !isFieldRestricted(latitude);
  if (isObjRestricted(bookingInfo)) return null;

  return (
    <>
      {bookingInfo && <h5 className="m-b-20">{strings.car_detail.toUpperCase()}</h5>}
      <div className="clientDetails">
        <RenderItems bookingInfo={bookingInfo} listItems={carDetailItems} loading={loading} />
        {isLocationAccessible && <PinLocation bookingInfo={bookingInfo} />}
      </div>
      {bookingInfo && <h5 className="m-b-20 m-t-20">{strings.services.toUpperCase()}</h5>}
      <div className="clientDetails">
        <RenderItems bookingInfo={bookingInfo} listItems={servicesItems} country={country} loading={loading} />
        {booking_expenses &&
          !isFieldRestricted(booking_expenses) &&
          booking_expenses.map(item => {
            if (isFieldRestricted(item.name) || isFieldRestricted(item.price)) return null;
            return (
              <BookingItem
                key={item.id}
                title={item.name}
                text={getCurrency(country, item.price)}
                type={item.service_type}
              />
            );
          })}
      </div>
    </>
  );
};

const PinLocation = ({ bookingInfo }) => {
  const latitude = bookingInfo.latitude >= 0 ? `${bookingInfo.latitude} N` : `${Math.abs(bookingInfo.latitude)} S`;
  const longitude = bookingInfo.longitude >= 0 ? `${bookingInfo.longitude} E` : `${Math.abs(bookingInfo.longitude)} W`;

  const handleCopyLocation = () => {
    const location = `${latitude}, ${longitude}`;
    navigator.clipboard.writeText(location);
    Toast.success("Location copied!");
  };

  return (
    <div className={"d-flex justify-content-start align-items-center"}>
      <BookingItem title={strings.receiver_coordinates} text={`${latitude}, ${longitude}`} />
      <div className={"cursor-pointer ml-2"} onClick={handleCopyLocation}>
        <MdContentCopy />
      </div>
    </div>
  );
};

const RenderItems = ({ bookingInfo, listItems, country, loading }) => {
  return listItems(bookingInfo, country).map((item, index) => {
    if (isFieldRestricted(item.text)) return null;
    return <BookingItem title={item.title} text={item.text} key={index} loading={loading} />;
  });
};

const BookingItem = ({ title, text, loading, type = "" }) => {
  if (loading) {
    return <Skeleton height={35} width={100} className="my-2" />;
  } else if (isFieldRestricted(text)) return null;
  return (
    <>
      {text && (
        <div className="clientDetailsItem ">
          <div>
            <div className={"d-flex align-items-center"}>
              <div className={"detail-pointer"}></div>
              <h6 className="info-title d-block">
                {title} {type && `(${removeUnderscores(type)})`}
              </h6>
            </div>
            <h6 className={"info-text"}>{text}</h6>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingDetailInfo;
