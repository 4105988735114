import { ref, update, onValue, off } from "firebase/database";
import { database, fetchAndActivate, remoteConfig, getRemoteConfigValue } from "../services/firebase";

export const subscribe = ({ id, setter = () => {}, path = "auctions/", onlyOnce = false, subscribeRef }) => {
  const databaseRef = ref(database, path + `${id}`);
  const onValueCallback = snapshot => {
    const value = snapshot.val();
    setter(value);
  };
  subscribeRef.current = {
    databaseRef,
    onValueCallback,
  };
  onValue(databaseRef, onValueCallback, onlyOnce && { onlyOnce: true });
};

export const updateAuctionData = (auctionId, payload) => {
  const auctionData = {
    [`auctions/${auctionId}`]: {
      ...payload,
    },
  };
  update(ref(database), auctionData);
};

export const unsubscribeFirebase = ({ subscribeRef }) => {
  const { databaseRef, onValueCallback } = subscribeRef.current;
  if (databaseRef && onValueCallback) {
    off(databaseRef, "value", onValueCallback);
  }
};

export const getRemoteConfigLoginWithEmail = () => {
  return new Promise((resolve, reject) => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const loginWithEmail = getRemoteConfigValue(remoteConfig, "login_with_email").asBoolean();
        resolve(loginWithEmail);
      })
      .catch(error => {
        reject(error);
      });
  });
};
