// import React from "react";
import { ConfigDB } from "./data/config";
// import chroma from "chroma-js";

const colorPrimary = ConfigDB.data.color.primary_color;

export const themeConfig = {
  cssVar: { key: "cf" },
  token: {
    colorPrimary: colorPrimary,
    colorGray50: "#919699",
    boxShadow: "none",
    controlHeight: 42,
    controlHeightLG: 42,
    colorBorder: "#efefef",
    colorTextPlaceholder: "#828282",
    fontFamily: "Figtree",
  },
  components: {
    Divider: {
      colorSplit: "#919699",
    },
    Switch: {
      boxShadow: "none",
      outline: "none",
    },
    Button: {
      boxShadow: "none",
      outline: "none",
      defaultShadow: "none",
      dangerShadow: "none",
      primaryShadow: "none",
      marginXS: 4,
    },
    DatePicker: {
      borderRadius: "0.25rem",
      minHeight: 42,
    },
    Input: {
      hoverBorderColor: "none",
    },
    TreeSelect: {
      borderRadius: "0.25rem",
      width: "100%",
    },
    Select: {
      hoverBorderColor: "#efefef",
      activeBorderColor: "#efefef",
      activeOutlineColor: "none",
      colorTextQuaternary: "#000",
      fontSizeIcon: 16,
      optionPadding: "6px 12px",
      optionHeight: 34,
    },
  },
};
