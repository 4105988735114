import {
  ADD_APPOINTMENT,
  SET_APPOINTMENTS_DATA,
  SET_SCHEDULES_DATA,
  SET_TASK_APPOINTMENTS,
  UPDATE_APPOINTMENT_DATA,
  UPDATE_APPOINTMENTS_DATA,
} from "../../constant/actionTypes";

export const setAppointmentsData = data => dispatch => {
  dispatch({
    type: SET_APPOINTMENTS_DATA,
    payload: {
      ...data,
    },
  });
};

export const setSchedulesData = data => dispatch => {
  dispatch({
    type: SET_SCHEDULES_DATA,
    payload: data,
  });
};

export const updateAppointmentData = item => dispatch => {
  dispatch({
    type: UPDATE_APPOINTMENT_DATA,
    payload: {
      ...item,
    },
  });
};

export const updateAppointmentsData = item => dispatch => {
  dispatch({
    type: UPDATE_APPOINTMENTS_DATA,
    payload: {
      ...item,
    },
  });
};

export const addAppointment = item => dispatch => {
  dispatch({
    type: ADD_APPOINTMENT,
    payload: {
      item,
    },
  });
};

export const setTaskAppointments = item => dispatch => {
  dispatch({
    type: SET_TASK_APPOINTMENTS,
    payload: {
      item,
    },
  });
};
