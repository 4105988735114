import {
  SET_CRM_REPORT_TYPE_WIDGETS,
  SET_CRM_STATISTICS,
  SET_CRM_INVENTORY_STATISTICS,
  SET_CRM_LEAD_STATISTICS_FILTERS,
  SET_CRM_LEAD_STATISTICS_DATA,
  SET_CRM_STATUS_FILTER,
} from "../../constant/actionTypes";

export const INITIAL_STATE = {
  crmReport: {},
  crmLeadsStatisticsFilters: {
    selectedValue: null,
    tabIndex: 0,
    tabsList: null,
    categoryType: null,
  },
  crmLeadsStatisticsData: [],
  crmTasksStatistics: [],
  filters: [],

  crmLeadsFilter: {
    dateRange: {},
  },
  crmLeadsStatusesData: [],
  loadingCrmLeadStatistics: true,
  errorCrmLeadStatistics: "",
  crmInventoryCounts: {
    inventoryCounts: [],
    loading: true,
    error: "",
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CRM_REPORT_TYPE_WIDGETS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CRM_STATISTICS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_CRM_INVENTORY_STATISTICS: {
      return {
        ...state,
        ...{
          crmInventoryCounts: {
            ...state.crmInventoryCounts,
            ...action.payload,
          },
        },
        ...action.payload,
      };
    }

    case SET_CRM_LEAD_STATISTICS_FILTERS:
      return {
        ...state,
        ...{
          crmLeadsStatisticsFilters: {
            ...state.crmLeadsStatisticsFilters,
            ...action.payload.filters,
          },
        },
      };

    case SET_CRM_LEAD_STATISTICS_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case SET_CRM_STATUS_FILTER:
      return {
        ...state,
        ...{
          crmLeadsFilter: {
            ...state.crmLeadsFilter,
            ...action.payload.filters,
          },
        },
      };

    default:
      return state;
  }
};
