import { Maximize, MoreHorizontal, Bell, Search } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import React, { Fragment, useEffect, useRef, useState } from "react";
import configDB, { ConfigDB } from "../../../data/config";

import UserMenu from "./userMenu";
import { getAppPath, getPermission, getCurrentApp, sortArrayAlphabetically } from "../../../helpers/Util";
import { getCookie } from "../../../helpers/CookieHelper";
import SidebarModal from "../SidebarModal";
import NotificationCenter from "../NotificationCenter";
import { useSelector } from "react-redux";
import useOutsideAlterter from "../../../hooks/useOutsideAlterter";
import { TrainingManualIcon } from "../../svg-icon/svg-icons";
import { CRM_DUPLICATION_SEARCH } from "constant/appPaths";

const Header = props => {
  const { handleLogout, tenant, open, setOpen } = props;
  const { platforms } = tenant;
  const [sidebar, setSidebar] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [fetchOnOpen, setFetchOnOpen] = useState(false);

  //const { currentApp } = useSelector(state => state.Configs);
  const [platform, setPlatform] = useState(getCurrentApp());
  const [notificationCount, setnotificationCount] = useState(0);
  const ref = useRef();

  const isSidebarMounted = !!ref?.current;

  // const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);
  const logo = configDB.data.logo;
  let history = useHistory();

  const openCloseSidebar = () => {
    setOpen(prev => !prev);
  };

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
        document.webkitCancelFullScreen();
      }
    }
  }

  const toggleAppProps = e => {
    const currentApp = getCookie("currentApp");
    if (e.slug === currentApp) {
      return {
        className: "font-primary",
      };
    } else {
      return {
        onClick: () => history.push(getAppPath(e.slug)),
      };
    }
  };

  const toggleNotificationModal = () => {
    setSidebarOpen(!sidebarOpen);
    setFetchOnOpen(sidebarOpen ? false : true);
  };

  const redirectToTrainingDocument = () => {
    window.open("https://docs.google.com/document/d/1Lr7bD5FSqgpzHm1OvaLg4BXDcrChk0iopWqkH9ruJJM/edit", "_blank");
  };

  return (
    <Fragment>
      {!!isSidebarMounted && (
        <SidebarModal
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          styles={customStyles.sidebar}
          content={
            <NotificationCenter
              platform={platform}
              setnotificationCount={setnotificationCount}
              history={history}
              setSidebarOpen={setSidebarOpen}
              sidebarOpen={sidebarOpen}
              setFetchOnOpen={setFetchOnOpen}
              fetchOnOpen={fetchOnOpen}
            />
          }
        />
      )}
      <div className="page-main-header" ref={ref}>
        <div className="main-header-right row">
          <div className="d-flex align-items-center">
            <div className="main-header-left d-lg-none">
              <div className="logo-wrapper">
                <Link to={getAppPath()}>
                  <img className="img-fluid" src={tenant.logo_path || logo} alt="" />
                </Link>
              </div>
            </div>
            <div className="main-header-left d-none d-lg-block">
              <div className="logo-wrapper compactLogo">
                <Link to={getAppPath()}>
                  <img className="blur-up lazyloaded" src={props.tenant.logo_path || logo} alt="" />
                  <img className="blur-up lazyloaded" src={props.tenant.logo_path || logo} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="nav-right col p-0 " style={{ zIndex: 1030 }}>
            <ul
              className={
                ConfigDB.data.settings.layout_type === "ltr"
                  ? `nav-menus ${headerbar ? "" : "open"}`
                  : `nav-menus align-header-menu-left ${headerbar ? "" : "open"}`
              }
            >
              {/* add permission for show duplication */}
              {getPermission("CrmLead", "show_duplication") && (
                <li>
                  <Link className="d-inline-flex" to={`${CRM_DUPLICATION_SEARCH}`}>
                    <Search size={18} />
                  </Link>
                </li>
              )}
              <li className="onhover-dropdown">
                <a className="d-inline-flex align-items-center" href="#javascript">
                  <span className="ml-2">{platforms.find(e => e.slug === getCookie("currentApp"))?.name}</span>
                </a>
                {platforms?.length > 1 && (
                  <nav className={"language-dropdown onhover-show-div"}>
                    {sortArrayAlphabetically(platforms, "name").map(e => (
                      <a
                        href={`#${e.name}`}
                        key={e.id}
                        {...toggleAppProps(e)}
                        className="d-flex flex-row align-items-center"
                      >
                        <span className="ml-2">{e?.name}</span>
                      </a>
                    ))}
                  </nav>
                )}
              </li>

              <li>
                <a onClick={goFull} className="text-dark" href="#!">
                  <Maximize />
                </a>
              </li>

              <li className="training-manual-link">
                <a onClick={redirectToTrainingDocument} className="text-dark" href="#!">
                  <TrainingManualIcon />
                </a>
              </li>

              {getPermission("Notification", "my_notifications") && (
                <li className="mr-2">
                  <a onClick={() => toggleNotificationModal()} className="text-dark" href="#!">
                    <div style={{ position: "relative" }}>
                      <Bell />
                      {notificationCount !== 0 && (
                        <div className="d-flex align-items-center justify-content-center notification-bell-badge">
                          <span>{notificationCount > 99 ? "99+" : notificationCount}</span>
                        </div>
                      )}
                    </div>
                  </a>
                </li>
              )}
              <UserMenu handleLogout={handleLogout} />
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}>
              <MoreHorizontal />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const customStyles = {
  sidebar: {
    root: {},
    sidebar: {
      position: "fixed",
      zIndex: 1020,
      top: 80,
      backgroundColor: "white",
      width: 550,
    },
    content: {},
    overlay: {
      top: 80,
      zIndex: 1010,
    },
    dragHandle: {
      zIndex: 1020,
    },
  },
};

export default Header;
