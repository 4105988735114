export const selectTaskTypes = state => state.TaskTypesData.list;
export const selectLeadActivities = state => state.CrmLeadActivitiesData;
export const selectTaskTypesError = state => state.TaskTypesData.error;
export const selectLocations = state => state.LocationsData.list;
export const selectLocationTypes = state => state.LocationsData.locationTypes;
export const selectLocationsError = state => state.LocationsData.error;
export const selectLocationsInitialRequestCalled = state => state.LocationsData.initialRequestCalled;
export const selectTaskTypesInitialRequestCalled = state => state.TaskTypesData.initialRequestCalled;
export const selectCurrencyCode = state => state.Configs.tenant.country.currency_code;
export const selectCrmReport = state => state.CrmDashboardData;
export const selectLeadsChevronOptions = state => state.CRMLeadsChevronOptionsData;
export const selectLeadsChevronDispositionsData = state => state.CRMLeadsChevronDispositionsData;
export const selectChevronUsersData = state => state.CRMLeadsChevronUsersData;

// Payments
export const selectPaymentsLoading = (state) => state.CRMPaymentsData.loading;
export const selectPaymentsError = (state) => state.CRMPaymentsData.error;
export const selectPayments = (state) => state.CRMPaymentsData.list;
export const selectStatuses = (state) => state.CRMPaymentsData.statuses;
export const selectLinkStatuses = (state) => state.CRMPaymentsData.paymentLinkStatuses;
export const selectPaymentMethods = (state) => state.CRMPaymentsData.paymentMethods;
export const selectPaymentTypes = (state) => state.CRMPaymentsData.paymentTypes;
export const selectPaymentsPaginationObject = (state) => state.CRMPaymentsData.pagination;
export const selectPaymentsPermittedMeta = (state) => state.CRMPaymentsData.permittedMeta;

