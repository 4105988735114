import { FETCH_LEAD_DETAILS, UPDATE_LEAD_DETAILS, UPDATE_LEAD_PAYMENT } from "../../constant/actionTypes";

import { fetchLeadDetailsApi } from "../../helpers/ApiHelper";
import { checkUndefinedApiParams, getErrorString, isFieldRestricted } from "../../helpers/Util";
import { Toast } from "../../components/common";
import { strings } from "../../constant/strings";

export const fetchLeadDetails = id => async dispatch => {
  if (!checkUndefinedApiParams(id)) return;
  const res = await fetchLeadDetailsApi(id);
  if (res) {
    if (res.lead) {
      dispatch({
        type: FETCH_LEAD_DETAILS,
        payload: {
          lead: res.lead,
          statuses: res.statuses,
          loading: false,
        },
      });
    } else {
      dispatch({
        type: FETCH_LEAD_DETAILS,
        payload: {
          statuses: {},
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  }
};

export const updateLeadDetails = item => dispatch => {
  dispatch({
    type: UPDATE_LEAD_DETAILS,
    payload: {
      item: { ...item },
    },
  });
};

export const updateLeadPayment = item => dispatch => {
  dispatch({
    type: UPDATE_LEAD_PAYMENT,
    payload: {
      payment: { ...item },
    },
  });
};
