import React, { useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";
import { getQRDesign } from "helpers/QRUtils";

const QrCode = ({ url, className, img, width, height }) => {
  const design = getQRDesign(width, height, url, img);
  const qrCode = new QRCodeStyling(design);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, []);

  return <div ref={ref} className={className} />;
};

export default QrCode;
