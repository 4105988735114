import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "./Loader";

const DefaultWrapper = props => {
  return props.children;
};

export const FlatList = forwardRef((props, ref) => {
  const {
    fetchItems,
    ListComponent,
    className = "timeline-small timeline-activity activity-scroll timeline-activity-height mt-0",
    styleProps = null,
    WrapperComponent = DefaultWrapper,
    customClassName = null,
    ScrollLoader = null,
    disableLoader = false,
    parentList = null,
    id = "flat_list",
    ...listComponentProps
  } = props;
  const [scrollList, setScrollList] = useState([]);
  const [showWrappedComponent, setShowWrappedComponent] = useState(false);
  const [page, setPage] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  let length = parentList?.list?.length ?? scrollList.length;
  let listClassName = `${className} ${styleProps?.className ? styleProps.className : ""}`;
  if (customClassName) {
    listClassName = customClassName;
  }

  useImperativeHandle(ref, () => ({
    getScrollData() {
      return parentList?.list ?? scrollList;
    },
  }));

  const fetchMoreData = async () => {
    if (parentList) {
      parentList.fetchMoreData();
      return;
    }
    let { list, pagination } = await fetchItems(page.current_page);
    if (pagination?.next_page === null) {
      setHasMore(false);
    }
    setScrollList([...scrollList, ...list]);
    setPage(pagination);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      if (parentList) {
        parentList.fetchInitialData();
        return;
      }

      const { list, pagination } = await fetchItems(0);
      if (pagination?.next_page === null) {
        setHasMore(false);
      }
      if (list && list.length > 0) {
        setShowWrappedComponent(true);
      }

      if (list && pagination) {
        setScrollList([...scrollList, ...list]);
        setPage(pagination);
      }
    };
    fetchInitialData();
  }, []);
  const flatListStyle = {
    ...(styleProps && { ...styleProps }),
    ...{ maxHeight: `${styleProps?.height ? styleProps.height : "410px"}`, width: "100%", overflowY: "scroll" },
  };
  const listToRender = parentList?.list ?? scrollList;

  return (
    <div id="scrollableDiv">
      <WrapperComponent show={showWrappedComponent}>
        <InfiniteScroll
          dataLength={length}
          next={fetchMoreData}
          hasMore={parentList ? parentList.hasMore : hasMore}
          scrollThreshold={0.9}
          scrollableTarget="scrollableDiv"
          className={listClassName}
          height={"100%"}
          style={flatListStyle}
          {...(!disableLoader && {
            loader: ScrollLoader ? <ScrollLoader /> : <Loader />,
          })}
        >
          {listToRender.map((item, index) => (
            <ListComponent
              item={item}
              key={item?.id || `item-${index}`}
              scrollList={scrollList}
              setScrollList={setScrollList}
              {...listComponentProps}
            />
          ))}
        </InfiniteScroll>
      </WrapperComponent>
    </div>
  );
});

export default FlatList;
