import { Avatar, Button, Permissions, Skeleton } from "../common";

import React from "react";
import { CRM_CARS_PATH } from "../../constant/appPaths";
import { DYNAMIC_MODELS } from "constant/appConstants";
import { strings } from "../../constant/strings";
import { getTimeDateString, isFieldRestricted } from "../../helpers/Util";
import { IconId, IoCarOutline, calendar, car, checkIn, fuel } from "../svg-icon/svg-icons";

const CicoDetailCard = props => {
  const { cico, loading, history } = props;
  const dynamicAttributes = cico?.dynamic_attributes ?? {};
  const cicoDynamicAttributes = dynamicAttributes[DYNAMIC_MODELS.CICO];

  const item = (
    Icon,
    title,
    desc,
    iconOptions = { color: "", iconClass: "", size: "" },
    truncated = false,
    style = {}
  ) => {
    return desc && !isFieldRestricted(desc) ? (
      <div className={`info-block d-flex spbwx8 ${truncated ? "info-block-truncated" : ""}`.trim()}>
        <Icon
          letter={desc.charAt(0)}
          className={`icon-svg ${iconOptions.iconClass ? iconOptions.iconClass : ""}`.trim()}
          style={{
            "--icon-size": iconOptions.size ? iconOptions.size : "",
            "--icon-color": iconOptions.color ? iconOptions.color : "",
          }}
        />
        <div style={style.titleContainer}>
          <div className="info-block-title">{title}</div>
          <div className="info-block-desc" title={truncated === true ? desc : ""} style={style.infoDesc}>
            {desc}
          </div>
        </div>
      </div>
    ) : null;
  };

  const loadingItem = key => (
    <div className={`info-block d-flex spbwx8`.trim()} key={key}>
      <Skeleton height={40} width={40} />
      <div className="d-flex flex-column">
        <Skeleton width={100} />
        <Skeleton width={150} />
      </div>
    </div>
  );

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex flex-column m-b-25 justify-content-between align-items-left">
            <div className={`d-flex flex-wrap align-items-center ${props.className}`.trim()}>
              {loading ? (
                [1, 2].map(e => loadingItem(e))
              ) : (
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div>
                    {item(
                      car,
                      cico?.inventory?.car?.model?.select_value + " " + cico?.inventory?.car?.model_year,
                      cico?.inventory?.car?.make?.select_value,
                      {
                        color: "#ffa564",
                      },
                      false,
                      {
                        titleContainer: {
                          display: "flex",
                          flexDirection: "column-reverse",
                        },
                        infoDesc: {
                          fontSize: "22px",
                          fontWeight: 600,
                        },
                      }
                    )}
                  </div>
                  {
                    <div>
                      <Permissions catagories="Car" actions="read">
                        <Button
                          btnText={strings.view_inventory}
                          className={"btn btn-light"}
                          size="sm"
                          variant=""
                          icon={<IoCarOutline />}
                          onClick={() => {
                            history.push({
                              pathname: `${CRM_CARS_PATH}/${cico?.inventory?.car?.id}/detail`,
                            });
                          }}
                        />
                      </Permissions>
                    </div>
                  }
                </div>
              )}
            </div>
            <div className={`d-flex flex-wrap align-items-center ${props.className}`.trim()}>
              {loading ? (
                [1, 2, 3, 4, 5, 6].map(e => loadingItem(e))
              ) : (
                <>
                  {item(checkIn, strings.type, cico?.cico_type?.name, {
                    color: "#ffa564",
                  })}
                  {item(fuel, strings.reason, cico?.cico_reason?.name, {
                    color: "#ffa564",
                  })}
                  {item(calendar, strings.date_and_time, getTimeDateString(cico?.created_at), {
                    color: "#ffa564",
                  })}
                  {item(IconId, strings.location, cico?.location?.address, {
                    color: "#ffa564",
                  })}
                  {/* {item(IconKeysNew, strings.no_of_keys, data?.dynamic_attributes?.cico?.no_of_keys?.toString(), {
                    color: "#ffa564",
                  })} */}
                  <div className={`info-block d-flex spbwx8 ${"info-block-truncated"}`.trim()}>
                    <Avatar name={cico?.requested_by?.full_name} imgSrc={cico?.requested_by?.picture?.url}></Avatar>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CicoDetailCard;
