import { ButtonLoading, CustomAlert, FormInput } from "../common";
import React, { useEffect, useRef, useState } from "react";

import { Formik } from "formik";
import { INS_EMAIL_TEMPLATES_PATH } from "../../constant/appPaths";
import { checkUndefinedApiParams, getErrorString } from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { updateEmailTemplateApi } from "../../helpers/ApiHelper";
import { useSelector } from "react-redux";

const EmailTemplateForm = props => {
  const { loading, emailTemplate } = props;

  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState("");

  const formikRef = useRef();
  useEffect(() => {
    if (formikRef && formikRef.current) {
      const { title, title_tld, message, message_tld } = emailTemplate;
      formikRef.current.setValues({
        title: title || "",
        titleTranslation: title_tld || "",
        message: message || "",
        messageTranslation: message_tld || "",
      });
    }
  }, [emailTemplate]);
  const tenant = useSelector(content => content.Configs.tenant);

  const { default_locale_direction, secondary_locale_direction } = tenant.configuration;

  const onSubmit = async values => {
    if (!checkUndefinedApiParams(emailTemplate?.id, "email template")) return;
    const obj = {
      email_template: {
        title: values.title,
        title_tld: values.titleTranslation,
        message: values.message,
        message_tld: values.messageTranslation,
      },
    };
    setSaveLoading(true);
    const response = await updateEmailTemplateApi(emailTemplate.id, obj);
    if (response) {
      setSaveLoading(false);
      if (response.email_template) {
        props.history.push({
          pathname: INS_EMAIL_TEMPLATES_PATH,
        });
      } else {
        setSaveError(getErrorString(response));
      }
    }
  };
  const getInitialValues = () => ({
    title: "",
    titleTranslation: "",
    message: "",
    messageTranslation: "",
  });

  return (
    <Formik innerRef={formikRef} initialValues={getInitialValues()} onSubmit={onSubmit}>
      {props => {
        const { values, handleChange, handleBlur, handleSubmit } = props;
        return (
          <form className="form theme-form">
            <FormInput
              label={strings.title}
              placeholder={strings.title}
              onChange={handleChange("title")}
              onBlur={handleBlur("title")}
              value={values.title}
              horizontal
              linesCount="4"
              dir={default_locale_direction}
            />
            <FormInput
              label={strings.title_translation}
              placeholder={strings.title_translation}
              onChange={handleChange("titleTranslation")}
              onBlur={handleBlur("titleTranslation")}
              value={values.titleTranslation}
              horizontal
              linesCount="4"
              dir={secondary_locale_direction}
            />
            <FormInput
              label={strings.message}
              placeholder={strings.message}
              onChange={handleChange("message")}
              onBlur={handleBlur("message")}
              value={values.message}
              horizontal
              linesCount="4"
              dir={default_locale_direction}
            />
            <FormInput
              label={strings.message_translation}
              placeholder={strings.message_translation}
              onChange={handleChange("messageTranslation")}
              onBlur={handleBlur("messageTranslation")}
              value={values.messageTranslation}
              horizontal
              linesCount="4"
              dir={secondary_locale_direction}
            />
            <ButtonLoading
              loading={saveLoading}
              disabled={saveLoading}
              onClick={handleSubmit}
              className="pull-right"
              error={!!saveError}
              loadingSkeleton={loading}
            >
              {strings.update}
            </ButtonLoading>
            {saveError && saveError.message && <CustomAlert message={saveError.message} />}
          </form>
        );
      }}
    </Formik>
  );
};

export default EmailTemplateForm;
