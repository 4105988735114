import { getAuth, signOut } from "firebase/auth";
import React, { Fragment, useState } from "react";
import { Lock, LogOut, Settings } from "react-feather";
import OneSignal from "react-onesignal";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logOutAction } from "../../../actions/admin/user.action";
import { LOGIN_PATH, RESET_PASSWORD_PATH } from "../../../constant/appPaths";
import { strings } from "../../../constant/strings";
import { logoutApi } from "../../../helpers/ApiHelper";
import { getErrorString, getPlatformPath, removeAppCookies } from "../../../helpers/Util";
import { selectTenant } from "../../../reducers/general/selectors";
import { selectLoggedInUser } from "../../../reducers/login/selectors";
import { app } from "../../../services/firebase";
import { persistor } from "../../../store";
import { Avatar } from "../Avatar";
import GoDialerModal from "../GoDialerModal/GoDialerModal";
import UserPanel from "../sidebar-component/userPanel";

const UserMenu = ({ history, handleLogout, logOutAction }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const user = useSelector(selectLoggedInUser);

  const logOut = async e => {
    const oneSignalSubscriptionId = OneSignal?.User?.PushSubscription?.id;
    setLoading(true);
    const response = await logoutApi(oneSignalSubscriptionId ? { player_id: oneSignalSubscriptionId } : {});
    if (response) {
      setLoading(false);
      if (response.data?.success) {
        const auth = getAuth(app);
        signOut(auth)
          .then(() => {
            console.log("Signed out successfully");
          })
          .catch(error => {});
        logOutAction();
        persistor.purge();
        removeAppCookies();
        handleLogout(e);
        history.push(LOGIN_PATH);
      } else {
        setError(getErrorString(response));
      }
    }
  };

  // Uncomment for "Edit User Profile" feature
  const location = history.location;
  const editUserSettingsPath = getPlatformPath(location?.pathname.split("/")[1], "editSettings");
  const tenant = useSelector(selectTenant);

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <Avatar avatarOnly name={user?.first_name?.split(" ")[0]} size={50} imgSrc={user.picture?.url} />
        </div>
        <div className="user-menu-dropdown onhover-show-div p-20">
          <UserPanel />
          <div className="user-menu-body">
            {/* <small>{strings.admin_settings}</small> */}
            <ul>
              <li>
                <GoDialerModal />
              </li>
              <li>
                <Link to={editUserSettingsPath.replace(":id", user?.id)}>
                  <Settings size={"1em"} />
                  {strings.edit_user_settings}
                </Link>
              </li>
              <li>
                <Link to={`${RESET_PASSWORD_PATH}`}>
                  <Lock size={"1em"} />
                  {strings.change_password}
                </Link>
              </li>
              <li onClick={logOut}>
                <a>
                  {loading ? (
                    <i className="fa fa-refresh fa-spin" style={{ marginRight: "8px" }} />
                  ) : (
                    <LogOut size={"1em"} />
                  )}
                  {strings.logout}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </Fragment>
  );
};

export default connect(() => ({}), { logOutAction })(withRouter(UserMenu));
