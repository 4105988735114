import React from "react";
import { Link } from "react-router-dom";
const Anchor = props => {
  const { shouldWrapAnchor, isActionCell, ...rest } = props;
  return (
    <Link {...rest} style={{ all: "unset" }} to={props.link} target="_blank" rel="noopener noreferrer">
      {props.children}
    </Link>
  );
};
export default Anchor;
