import React from "react";
import { isFieldRestricted } from "../../helpers/Util";
import Group from "../common/Group";
import CarDetails from "./CarDetails";
import styles from "./CardInfoCard.module.scss";
import Skeleton from "../common/Skeleton";
import ImagePlaceholder from "../../assets/images/ic_car_placeholder.png";
import PhotoGalleryWithLoader from "../common/PhotoGalleryWithLoader";
import { fetchAttachments } from "../../helpers/ApiHelper";
import { ATTACHMENT_TYPES } from "constant/appConstants";

const CarInfoCardSkeleton = () => {
  return (
    <Group template={"136px 1fr"}>
      <Skeleton height={136} width={136} />

      <div>
        <div className="d-flex justify-content-between">
          <div>
            <Skeleton height={26.5} width={250} style={{ marginRight: "5px" }} />
            <Skeleton height={27} width={100} />
          </div>
          <div>
            <Skeleton height={33} width={125} />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex" style={{ gap: "5px" }}>
            <Skeleton height={31.5} width={75} />
            <Skeleton height={31.5} width={75} />
            <Skeleton height={31.5} width={75} />
          </div>
          <div className="d-flex align-items-center">
            <Skeleton height={25} width={25} style={{ borderRadius: "50%", marginRight: "10px" }} />
            <Skeleton height={33.6} width={175} />
          </div>
        </div>
      </div>
    </Group>
  );
};

const CarInfoCard = ({ car, dynamicFields, history, fetchCar, loading, paymentsData, fetchExternalAd }) => {
  if (loading) return <CarInfoCardSkeleton />;

  const fetchInventoryPhotos = async () => {
    try {
      const { attachments = [] } = await fetchAttachments({
        attachedType: ATTACHMENT_TYPES.INVENTORY,
        attachedId: car?.inventory?.id,
      });
      return attachments ?? [];
    } catch (error) {
      throw error;
    }
  };

  return (
    <Group template={"136px auto"}>
      <div className={styles.carThumb}>
        {car?.inventory?.main_picture && !isFieldRestricted(car?.inventory?.main_picture) ? (
          <PhotoGalleryWithLoader
            photo={car?.inventory?.main_picture}
            // totalPhotosCount={3}
            fetchAttachments={fetchInventoryPhotos}
            daysInStock={car?.inventory?.days_in_stock}
          />
        ) : (
          <figure className="no__preview--image">
            <img src={ImagePlaceholder} />
          </figure>
        )}
      </div>
      <div>
        <CarDetails car={car} history={history} fetchCar={fetchCar} fetchExternalAd={fetchExternalAd} />
      </div>
    </Group>
  );
};

export default CarInfoCard;
