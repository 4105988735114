import React from "react";
import cx from "clsx";
import { Card as AntdCard } from "antd";
import styles from "./index.module.less";

const Card = props => {
  const { className, children, variant, ...rest } = props;

  return (
    <AntdCard
      className={cx(className, styles.card, variant == "gray" && styles.grayed, variant == "fill" && styles.filled)}
      {...rest}
    >
      {children}
    </AntdCard>
  );
};

export default Card;
