import { performGetRequest, performPostRequest } from "../../services/NetworkServices";
import { convertQueryObjToString } from "../Util";
import {
  deleteCallback,
  endpoint,
  endpointApi,
  getApiMethod,
  getCallback,
  getError,
  LEAD_TYPES_CONTROLLER,
  postApiMethod,
  postCallback,
  putApiMethod,
  putCallback,
  ROLES_CONTROLLER,
  TOKENS_CONTROLLER,
} from "./coreApiHelper.js";
export * from "./AdFieldMapping/index.js";
export * from "./WhatsappController/index.js";

export const fetchBaseDetailsApi = async (userId, successCallback, failureCallback) => {
  const tenantDetail = `${endpoint}/current/details`;
  const permissions = `${endpointApi}/users/${userId}/permissions`;
  const promiseArray = [performGetRequest(tenantDetail), performGetRequest(permissions)];
  Promise.all(promiseArray)
    .then(values => {
      successCallback(values[0].data, values[1].data);
    })
    .catch(error => {
      failureCallback(error);
    });
};

export const fetchLoginPermissions = userId => {
  const path = `${endpointApi}/users/${userId}/permissions`;
  return getCallback(path);
};

export const loginApi = object => {
  const path = endpoint + "/auth/sign_in";
  return postCallback(path, object, true);
};

export const logoutApi = object => {
  const path = `${endpoint}/auth/sign_out`;
  return deleteCallback(path, object, true);
};

export const addUserApi = object => {
  const path = endpoint + "/auth";
  return postCallback(path, object);
};

export const updateUserApi = object => {
  const path = `${endpoint}/auth`;
  return putCallback(path, object);
};

export const fetchUsersApi = (queryParams = "") => {
  const path = endpointApi + "/users" + queryParams;
  return getCallback(path);
};

export const fetchUserAvailableSlots = () => {
  const path = endpointApi + "/crm/time_slots/user_available_slots";
  return getCallback(path);
};

export const fetchConnectsApi = (queryParams = "") => {
  const path = endpointApi + "/crm/connects" + queryParams;
  return getCallback(path);
};

export const fetchUsersByTypeApi = (type, limit) => {
  const path = `${endpointApi}/users?s[roles.name]=${type}&f[is_active]=true&limit=${limit}`;
  return getCallback(path);
};
export const addTeamApi = object => {
  const path = endpointApi + "/teams";
  return postCallback(path, object);
};

export const updateTeamApi = (id, object) => {
  const path = `${endpointApi}/teams/${id}`;
  return putCallback(path, object);
};

export const fetchTeamsApi = (queryParams = "") => {
  const path = endpointApi + "/teams" + queryParams;
  return getCallback(path);
};

export const fetchTeamsApiList = (queryParams = "") => {
  const path = endpointApi + "/inspection/teams/list" + queryParams;
  return getCallback(path);
};

export const addLeadApi = object => {
  const path = endpointApi + "/leads";
  return postCallback(path, object);
};

export const updateLeadApi = (id, object) => {
  const path = `${endpointApi}/leads/${id}`;
  return putCallback(path, object);
};

export const createAndScheduleQC = (id, object) => {
  const path = `${endpointApi}/inspection/leads/${id}/schedule_qc_lead`;
  return postCallback(path, object);
};

export const fetchLeadDetailsApi = id => {
  const path = `${endpointApi}/leads/${id}`;
  return getCallback(path);
};

export const fetchCrmQuotationsApi = (query = "") => {
  const path = `${endpointApi}/crm/quotations${query}`;
  return getCallback(path);
};
export const fetchCrmQuotationNewApi = () => {
  const path = `${endpointApi}/crm/quotations/new`;
  return getCallback(path);
};
export const postCrmQuotationApi = payload => {
  const path = `${endpointApi}/crm/quotations`;
  return postCallback(path, payload);
};

export const resendEmailCrmQuotationApi = (id, payload) => {
  const path = `${endpointApi}/crm/quotations/${id}/resend_email`;
  return putCallback(path, payload);
};

export const resendEmailCrmPaymentApi = (id, payload) => {
  const path = `${endpointApi}/crm/crm_payments/${id}/resend_email`;
  return putCallback(path, payload);
};

export const sendEmailBankEvaluationApi = (id, payload) => {
  const path = `${endpointApi}/reports/${id}/send_email`;
  return putCallback(path, payload);
};

export const fetchLeadsApi = params => {
  const path = `${endpointApi}/leads${params ? params : ""}`;
  return getCallback(path);
};

export const fetchCityAreas = (params = "") => {
  const path = `${endpointApi}/areas${params}`;
  return getCallback(path);
};

export const bulkReassignCrmLeadApi = (object, errorKey) => {
  const path = `${endpointApi}/crm/crm_leads/bulk_reassign_crm_leads`;
  return putCallback(path, object, errorKey);
};
export const bulkReassignInspectionLead = (object, errorKey) => {
  const path = `${endpointApi}/inspection/leads/bulk_reassign_leads`;
  return putCallback(path, object, errorKey);
};
export const fetchCallingLeads = () => {
  const path = `${endpointApi}/leads?q[status_id_eq]=1`;
  return getCallback(path);
};

export const fetchQuikLinks = queryParam => {
  const path = `${endpointApi}/quick_links?${queryParam}`;
  return getCallback(path);
};

export const saveQuickLinksApi = object => {
  const path = `${endpointApi}/quick_links`;
  return postCallback(path, object);
};

export const deleteQuickLinksApi = id => {
  const path = `${endpointApi}/quick_links/${id}`;
  return deleteCallback(path);
};

export const fetchAvailableTeams = (
  dateTime,
  cityId,
  inspectionId,
  qcLeadId = null,
  parentTeamId = null,
  queryParams = ""
) => {
  const path = `${endpointApi}/schedules/available_teams?start_time=${dateTime}&city_id=${cityId}&inspection_id=${inspectionId}${queryParams}
  ${qcLeadId ? `&qc_lead_id=${qcLeadId}` : ""}
  ${parentTeamId ? `&parent_lead_team_id=${parentTeamId}` : ""}`;
  return getCallback(path);
};

export const fetchAllDispositions = () => {
  const path = `${endpointApi}/statuses/all_dispositions`;
  return getCallback(path);
};

export const fetchSchedules = (startDate, endDate, cityId, inspectionId) => {
  const path = `${endpointApi}/schedules?q[start_time_gteq]=${startDate}&q[end_time_lt]=${endDate}&q[city_id_eq]=${cityId}${
    inspectionId ? `&q[inspection_id_eq]=${inspectionId}` : ""
  }`;
  return getCallback(path);
};

export const fetchDashboardApi = () => {
  const path = `${endpointApi}/dashboard`;
  return getCallback(path);
};

export const addBankApprovalApi = (id, object) => {
  const path = `${endpointApi}/reports/${id}/add_evaluation`;
  return postCallback(path, object);
};

export const updateBankApprovalApi = (id, object) => {
  const path = `${endpointApi}/reports/${id}/update_evaluation`;
  return putCallback(path, object);
};

export const fetchReportsApi = (queryParams = "") => {
  const path = `${endpointApi}/inspection/reports${queryParams}`;
  return getCallback(path);
};

export const fetchTenantApi = async () => {
  const path = `${endpoint}/current/details`;
  return getCallback(path);
};

export const updateReportApi = (id, object) => {
  const path = `${endpointApi}/inspection/reports/${id}/update_status`;
  return putCallback(path, object);
};

export const fetchJobStatusesApi = (params = "", object) => {
  const path = endpointApi + "/statuses_and_their_leads?" + params;
  return postCallback(path, object);
};

export const fetchPaymentsApi = (queryParams = "") => {
  const path = endpointApi + "/payments" + queryParams;
  return getCallback(path);
};

export const fetchRunningLateJobsApi = () => {
  const path = `${endpointApi}/leads/running_late`;
  return getCallback(path);
};

export const fetchCounts = (params = {}) => {
  let queryString = convertQueryObjToString(params);
  const path = `${endpointApi}/leads/counts_by_group?${queryString}`;
  return getCallback(path);
};

export const sortQuickLinkApi = object => {
  const path = `${endpointApi}/quick_links/sort`;
  return putCallback(path, object);
};

export const changePasswordApi = object => {
  const path = `${endpoint}/auth/password`;
  return putCallback(path, object);
};

export const changePaymentStatusApi = (id, data) => {
  const path = `${endpointApi}/crm/crm_payments/${id}/change_status`;
  return putCallback(path, data);
};

export const countriesListApi = () => {
  const path = `${endpointApi}/countries`;
  return getCallback(path);
};

export const updateTenantDetails = (id, object) => {
  const path = `${endpointApi}/tenants/${id}`;
  return putCallback(path, object);
};

export const fetchRolesApi = (queryParams = "") => {
  const path = `${endpointApi}${ROLES_CONTROLLER}${queryParams}`;
  return getCallback(path);
};

export const fetchFolderPermissionsApi = (roleId = "") => {
  const path = `${endpointApi}${ROLES_CONTROLLER}/${roleId}/access_rights?accessible_type=label&klass_names[]=Inventory&klass_names[]=CrmLead`;
  return getCallback(path);
};

export const updateFolderAccessRightsApi = (roleId, payload = {}) => {
  const path = `${endpointApi}${ROLES_CONTROLLER}/${roleId}`;
  return putCallback(path, payload);
};

export const addRoleApi = object => {
  const path = `${endpointApi}${ROLES_CONTROLLER}`;
  return postCallback(path, object);
};

export const updateRoleApi = (id, object) => {
  const path = `${endpointApi}${ROLES_CONTROLLER}/${id}`;
  return putCallback(path, object);
};
export const deleteRoleApi = id => {
  const path = `${endpointApi}${ROLES_CONTROLLER}/${id}`;
  return deleteCallback(path);
};

export const fetchNewRoleApi = () => {
  const path = `${endpointApi}${ROLES_CONTROLLER}/new`;
  return getCallback(path);
};

export const fetchRoleDetailApi = id => {
  const path = `${endpointApi}${ROLES_CONTROLLER + "/:id".replace(":id", id)}`;
  return getCallback(path);
};

export const fetchPermissionsApi = (sourceClass, id) => {
  // /api/permissions?source_type=roles&source_id=17
  const path = `${endpointApi}/permissions?source_class=${sourceClass}&source_id=${id}`;

  return getCallback(path);
};

export const fetchPermittedAttributesApi = (userId, model) => {
  const path = `${endpointApi}/users/${userId}/permissions?subject_class=${model}`;
  return getCallback(path);
};

export const fetchPermittedModelsApi = (sourceClass, sourceId) => {
  const path = `${endpointApi}/active_columns?source_class=${sourceClass}&source_id=${sourceId}`;
  return getCallback(path);
};

export const fetchGenericListsApi = (queryParams = "") => {
  const path = `${endpointApi}/generic_list${queryParams}`;
  return getCallback(path);
};

export const fetchCitiesApi = (queryParams = "") => {
  const path = endpointApi + "/cities" + queryParams;
  return getCallback(path);
};

// Cars

export const addCarApi = object => {
  const path = `${endpointApi}/crm/cars`;
  return postCallback(path, object);
};

export const updateCarApi = (id, object, markAsSold = false) => {
  const path = `${endpointApi}/crm/cars/${id}${markAsSold ? "/update_sold" : ""}`;
  return putCallback(path, object);
};

export const fetchCarDetailsApi = id => {
  const path = `${endpointApi}/crm/cars/${id}`;
  return getCallback(path);
};

export const fetchCarDetailDynamicAttributes = id => {
  const path = `${endpointApi}/dynamic_models/car/dynamic_fields?limit=1000`;
  return getCallback(path);
};

export const fetchCarAdPost = id => {
  const path = `${endpointApi}/crm/cars/${id}/external_ad`;
  return getCallback(path);
};

export const fetchCarEditApi = id => {
  const path = `${endpointApi}/crm/cars/${id}/edit`;
  return getCallback(path);
};

export const fetchCarsNewApi = params => {
  const path = `${endpointApi}/crm/cars/new${params ? params : ""}`;
  return getCallback(path);
};

export const fetchCarsApi = params => {
  const path = `${endpointApi}/crm/cars${params ? params : ""}`;
  return getCallback(path);
};

export const fetchSalesStockCarsApi = params => {
  const path = `${endpointApi}/crm/cars/stock_list${params ? params : ""}`;
  return getCallback(path);
};

export const fetchSalesStockDownloadApi = params => {
  const path = `${endpointApi}/crm/cars/stock_list/download${params ? params : ""}`;
  return getCallback(path);
};

export const uploadPaymentSheetApi = data => {
  const path = `${endpointApi}/crm/crm_leads/bulk_import`;
  return putCallback(path, data);
};

// Inventory

export const updateInventoryApi = (id, object) => {
  const path = `${endpointApi}/crm/inventories/${id}`;
  return putCallback(path, object);
};

export const fetchInventoryDetailsApi = id => {
  const path = `${endpointApi}/auction/inventories/${id}`;
  return getCallback(path);
};

export const fetchInventoryApi = params => {
  const path = `${endpointApi}/auction/inventories${params ? params : ""}`;
  return getCallback(path);
};

export const removeInventoryApi = id => {
  const path = `${endpointApi}/crm/inventories/${id}/mark_as_remove`;
  return putCallback(path);
};

export const soldInventoryApi = (id, obj) => {
  const path = `${endpointApi}/crm/inventories/${id}/mark_as_sold`;
  return putCallback(path, obj);
};

// Images
export function getS3PresignedUrl(attachmentType, key, fileName, labelId) {
  return new Promise((resolve, reject) => {
    let url = `${endpointApi}/${attachmentType}/presigned_url?key=${key}&file_name=${fileName}${
      labelId ? `&label_id=${labelId}` : ``
    }`;
    performGetRequest(url, "")
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getUsersAvatarURL(userIDs) {
  const queryParam = "?q[attached_type_eq]=User&q[key_eq]=picture&q[attached_ids]=";
  const path = `${endpointApi}/attachments${queryParam}${JSON.stringify(userIDs)}`;
  return getCallback(path);
}

// Groups

export const addGroupApi = object => {
  const path = endpointApi + "/auction/groups";
  return postCallback(path, object);
};

export const updateGroupApi = (id, object) => {
  const path = `${endpointApi}/auction/groups/${id}`;
  return putCallback(path, object);
};

export const fetchGroupsApi = (queryParams = "") => {
  const path = endpointApi + "/auction/groups" + queryParams;
  return getCallback(path);
};

export const deleteGroupApi = id => {
  const path = `${endpointApi}/auction/groups/${id}`;
  return deleteCallback(path);
};

// Auction Types

export const addAuctionTypeApi = object => {
  const path = endpointApi + "/auction/auction_types";
  return postCallback(path, object);
};

export const updateAuctionTypeApi = (id, object) => {
  const path = `${endpointApi}/auction/auction_types/${id}`;
  return putCallback(path, object);
};

export const fetchAuctionTypesApi = (queryParams = "") => {
  const path = endpointApi + "/auction/auction_types" + queryParams;
  return getCallback(path);
};

export const deleteAuctionTypeApi = id => {
  const path = `${endpointApi}/auction/auction_types/${id}`;
  return deleteCallback(path);
};

export const updateCrmApprovals = (id, obj) => {
  const path = `${endpointApi}/crm/approvals/${id}`;
  return putCallback(path, obj);
};
export const fetchNewCrmApprovalApi = () => {
  const path = `${endpointApi}/crm/approvals/new`;
  return getCallback(path);
};
export const fetchCrmApprovalApi = id => {
  const path = `${endpointApi}/crm/approvals/${id}`;
  return getCallback(path);
};

export const fetchCrmApprovals = (queryParams = "") => {
  const path = `${endpointApi}/crm/approvals${queryParams}`;
  return getCallback(path);
};

// Auction Users
export const updateAuctionUserApi = (id, object) => {
  const path = `${endpointApi}/auction/auction_users/${id}`;
  return putCallback(path, object);
};

export const fetchAuctionUserDetailApi = id => {
  const path = `${endpointApi}/auction/auction_users/${id}`;
  return getCallback(path);
};

export const changeAuctionUserProfileStatusApi = (id, obj) => {
  const path = `${endpointApi}/auction/auction_users/${id}/change_profile_status`;
  return putCallback(path, obj);
};

export const changeAuctionUserPaymentStatusApi = (id, obj) => {
  const path = `${endpointApi}/auction/auction_users/${id}/change_payment_status`;
  return putCallback(path, obj);
};

export const removeDocumentWithAuctionUserDetailApi = (id, obj) => {
  const path = `${endpointApi}/auction/auction_users/${id}/remove_file`;
  return deleteCallback(path, obj);
};

export const attachDocumentWithAuctionUserDetailApi = (id, data) => {
  const path = `${endpointApi}/auction/auction_users/${id}/attach_file`;
  return putCallback(path, data);
};

export const fetchAuctionUsersApi = (queryParams = "") => {
  const path = endpointApi + "/auction/auction_users" + queryParams;
  return getCallback(path);
};
export const fetchAuctionUsersListApi = (queryParams = "") => {
  const path = endpointApi + "/auction/auction_users/list" + queryParams;
  return getCallback(path);
};
// Auctions

export const fetchPurchaserApi = (queryParams = "") => {
  let newQueryParams = queryParams ? `${queryParams}&f[roles.slug][]=purchasing` : "?f[roles.slug][]=purchasing";
  const path = endpointApi + "/users" + newQueryParams;
  return getCallback(path);
};

export const fetchDreApi = (queryParams = "") => {
  let newQueryParams = queryParams ? `${queryParams}&f[roles.slug][]=auction-dre` : "?f[roles.slug][]=auction-dre";
  const path = endpointApi + "/users" + newQueryParams;
  return getCallback(path);
};

export const addAuctionApi = object => {
  const path = endpointApi + "/auction/auctions";
  return postCallback(path, object);
};

export const fetchAuctionsApi = (queryParams = "") => {
  const path = endpointApi + "/auction/auctions" + queryParams;
  return getCallback(path);
};

export const cancelAuctionApi = (id, object) => {
  const path = `${endpointApi}/auction/auctions/${id}/cancel`;
  return putCallback(path, object);
};

export const extendAuctionApi = (id, object) => {
  const path = `${endpointApi}/auction/auctions/${id}/extend`;
  return putCallback(path, object);
};

// Offers

export const fetchOffersApi = (queryParams = "") => {
  const path = endpointApi + "/auction/offers" + queryParams;
  return getCallback(path);
};

export const updateOfferApi = (id, object) => {
  const path = `${endpointApi}/auction/offers/${id}`;
  return putCallback(path, object);
};

export const extendTimeNegotiationStatus = id => {
  const path = `${endpointApi}/auction/negotiations/${id}/extend_time`;
  return putCallback(path);
};

// Assignees

export const fetchAuctionAssigneesApi = (queryParams = "") => {
  const path = endpointApi + "/crm/inventories/assignees" + queryParams;
  return getCallback(path);
};

// Lead types apis

export const fetchLeadTypesApi = (queryParams = "") => {
  const path = `${endpointApi}${LEAD_TYPES_CONTROLLER}${queryParams}`;
  return getCallback(path);
};

export const fetchLeadTypeApi = id => {
  const path = `${endpointApi}${LEAD_TYPES_CONTROLLER}/${id}`;
  return getCallback(path);
};
export const addLeadTypeApi = object => {
  const path = `${endpointApi}${LEAD_TYPES_CONTROLLER}`;
  return postCallback(path, object);
};

export const updateLeadTypeApi = (id, object) => {
  const path = `${endpointApi}${LEAD_TYPES_CONTROLLER}/${id}`;
  return putCallback(path, object);
};

export const deleteLeadTypeApi = id => {
  const path = `${endpointApi}${LEAD_TYPES_CONTROLLER}/${id}`;
  return deleteCallback(path);
};

export const leadDiscountApi = (id, object) => {
  const path = `${endpointApi}/leads/${id}/add_discount`;
  return putCallback(path, object);
};
export const addPaymentApi = object => {
  const path = `${endpointApi}/payments`;
  return postCallback(path, object);
};

// Dynamic attributes
export const addDynamicAttributeApi = (modelId, object) => {
  const path = `${endpointApi}/dynamic_models/${modelId}/dynamic_fields`;
  return postCallback(path, object);
};

export const deleteDynamicAttributeApi = (modelId, id) => {
  const path = `${endpointApi}/dynamic_models/${modelId}/dynamic_fields/${id}`;
  return deleteCallback(path);
};

export const fetchDynamicFieldsApi = (modelId, queryParams = "", object) => {
  const path = `${endpointApi}/dynamic_models/${modelId}/dynamic_fields${queryParams}`;
  return getCallback(path, object);
};

export const updateDynamicAttributeApi = (modelId, id, object) => {
  const path = `${endpointApi}/dynamic_models/${modelId}/dynamic_fields/${id}`;
  return putCallback(path, object);
};

export const fetchDynamicAttributeApi = (modelId, id) => {
  const path = `${endpointApi}/dynamic_models/${modelId}/dynamic_fields/${id}`;
  return getCallback(path);
};

// Auction Dashboard

export const fetchAuctionStatusesApi = (params = "", object) => {
  const path = endpointApi + "/auction/statuses_and_their_auctions?" + params;
  return postCallback(path, object);
};

export const fetchInventoriesStatusesApi = (params = "", object) => {
  const path = endpointApi + "/auction/statuses_and_their_inventories?" + params;
  return postCallback(path, object);
};

export const fetchAuctionStatusCounts = (params = {}) => {
  let queryString = convertQueryObjToString(params);
  const path = `${endpointApi}/auction/counts_by_group?${queryString}`;
  return getCallback(path);
};

export const fetchAuctionTopClientsApi = (queryParams = "") => {
  const path = endpointApi + "/auction/top_clients" + queryParams;
  return getCallback(path);
};

export const fetchEndingSoonAuctionsApi = (queryParams = "") => {
  const path = endpointApi + "/auction/auctions/ending_soon" + queryParams;
  return getCallback(path);
};

// Deposits

export const fetchDepositsApi = (queryParams = "") => {
  const path = endpointApi + "/deposits" + queryParams;
  return getCallback(path);
};

export const updateDepositsApi = (id, object) => {
  const path = `${endpointApi}/deposits/${id}`;
  return putCallback(path, object);
};

// Assigned Relations

export const fetchUserAssignedRelations = id => {
  const path = `${endpointApi}/users/${id}/assigned_relations`;
  return getCallback(path);
};

export const updateUserAssignedRelations = (id, object) => {
  const path = `${endpointApi}/users/${id}/assign_relations`;
  return putCallback(path, object);
};

// Auction matrix

export const fetchAuctionMatrixData = () => {
  const path = `${endpointApi}/auction/bar_chart`;
  return getCallback(path);
};

// Authentication Tokens

export const fetchAuthenticationTokensApi = (queryParams = "") => {
  const path = `${endpointApi}${TOKENS_CONTROLLER}${queryParams}`;
  return getCallback(path);
};

export const addAuthenticationTokenApi = object => {
  const path = `${endpointApi}${TOKENS_CONTROLLER}`;
  return postCallback(path, object);
};

export const updateAuthenticationTokenApi = (id, object) => {
  const path = `${endpointApi}${TOKENS_CONTROLLER}/${id}`;
  return putCallback(path, object);
};

export const expireAuthenticationTokenApi = id => {
  const path = `${endpointApi}${TOKENS_CONTROLLER}/${id}`;
  return deleteCallback(path);
};

export const fetchNewAuthenticationTokenApi = () => {
  const path = `${endpointApi}${TOKENS_CONTROLLER}/new`;
  return getCallback(path);
};

export const fetchAuthenticationTokenDetailApi = id => {
  const path = `${endpointApi}${TOKENS_CONTROLLER + "/:id/edit".replace(":id", id)}`;
  return getCallback(path);
};

// Inspection Program
export const fetchInspectionProgramsApi = async (queryParams = "") => {
  const path = `${endpointApi}/inspections${queryParams}`;
  return getCallback(path);
};
// Crm Leads
export const addCRMLeadApi = object => {
  const path = endpointApi + "/crm/crm_leads";
  return postCallback(path, object);
};

export const updateCRMLeadApi = (id, object) => {
  const path = `${endpointApi}/crm/crm_leads/${id}`;
  return putCallback(path, object);
};

export const updateCRMLeadAttributesApi = (id, object, endPoint = "change_status") => {
  const path = `${endpointApi}/crm/crm_leads/${id}/${endPoint}`;
  return putCallback(path, object);
};

export const fetchCRMLeadDetailApi = id => {
  const path = `${endpointApi}/crm/crm_leads/${id}`;
  return getCallback(path);
};

export const fetchCRMLeadNewApi = () => {
  const path = `${endpointApi}/crm/crm_leads/new`;
  return getCallback(path);
};

export const fetchInspectorsApi = params => {
  const path = `${endpointApi}/users/inspectors${params}`;
  return getCallback(path);
};

export const fetchCrmBuyerLeadConnectsApi = id => {
  const path = `${endpointApi}/crm/inventories/${id}/buyer_leads`;
  return getCallback(path);
};

export const fetchCRMLeadEditApi = id => {
  const path = `${endpointApi}/crm/crm_leads/${id}/edit`;
  return getCallback(path);
};

export const fetchCRMLeadsApi = params => {
  const path = `${endpointApi}/crm/crm_leads${params ? params : ""}`;
  return getCallback(path);
};

export const addCRMLeadCommentsApi = (id, object) => {
  const path = `${endpointApi}/crm/crm_leads/${id}/add_comment`;
  return putCallback(path, object);
};

export const createCRMLeadFromOfferApi = object => {
  const path = endpointApi + "/crm/crm_leads/create_from_offer";
  return postCallback(path, object);
};

// Categpry Type

export const fetchCategoryTypesApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/category_types${queryParams}`;
  return getCallback(path);
};

// Task Type

export const fetchTaskTypesApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/task_types${queryParams}`;
  return getCallback(path);
};

export const fetchTaskTypesListApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/task_types/list${queryParams}`;
  return getCallback(path);
};

// Crm Tasks

export const addTaskApi = object => {
  const path = endpointApi + "/crm/tasks";
  return postCallback(path, object);
};

export const updateTaskApi = (id, object) => {
  const path = `${endpointApi}/crm/tasks/${id}`;
  return putCallback(path, object);
};

export const fetchTaskDetailApi = id => {
  const path = `${endpointApi}/crm/tasks/${id}`;
  return getCallback(path);
};

export const fetchCrmLeadTasksApi = (id, params) => {
  const path = `${endpointApi}/crm/crm_leads/${id}/tasks${params ? params : ""}`;
  return getCallback(path);
};

export const fetchTasksApi = params => {
  const path = `${endpointApi}/crm/tasks${params ? params : ""}`;
  return getCallback(path);
};

export const addCRMTaskCommentsApi = (id, object) => {
  const path = `${endpointApi}/crm/tasks/${id}/add_comment`;
  return putCallback(path, object);
};

export const addCRMCarCommentsApi = (id, object) => {
  const path = `${endpointApi}/crm/cars/${id}/add_comment`;
  return putCallback(path, object);
};

export const updateCRMTaskAttributesApi = (id, object, endPoint = "change_status") => {
  const path = `${endpointApi}/crm/tasks/${id}/${endPoint}`;
  return putCallback(path, object);
};

// Crm Payments

export const addCrmPaymentApi = object => {
  const path = endpointApi + "/crm/crm_payments";
  return postCallback(path, object);
};

export const updateCrmPaymentApi = (id, object) => {
  const path = `${endpointApi}/crm/crm_payments/${id}`;
  return putCallback(path, object);
};

export const regenerateAdyenPaymentLinkApi = (id, route) => {
  const path = `${endpointApi}/${route}/${id}/regenerate_adyen_link`;
  return getCallback(path);
};

export const fetchPaymentOptionsNewApi = inventory_id => {
  let endPoint = "/crm/crm_payments/new";
  if (inventory_id) {
    endPoint = endPoint + `?inventory_id=${inventory_id}`;
  }
  const path = endpointApi + endPoint;
  return getCallback(path);
};

export const editPaymentApi = id => {
  const path = `${endpointApi}/crm/crm_payments/${id}/edit`;
  return getCallback(path);
};

export const fetchPaymentApi = id => {
  const path = `${endpointApi}/crm/crm_payments/${id}`;
  return getCallback(path);
};

export const fetchCRMPaymentsApi = params => {
  const path = `${endpointApi}/crm/crm_payments${params ? params : ""}`;
  return getCallback(path);
};

// Assigness

export const fetchTaskAssigneesApi = params => {
  const path = `${endpointApi}/crm/tasks/assignees${params ? params : ""}`;
  return getCallback(path);
};

export const fetchLeadAssigneesApi = params => {
  const path = `${endpointApi}/crm/crm_leads/assignees${params ? params : ""}`;
  return getCallback(path);
};

// Watchers

export const crmLeadWatchersApi = (id, object, endRoute) => {
  const path = `${endpointApi}/crm/crm_leads/${id}/${endRoute}`;
  return putCallback(path, object);
};
export const crmTaskWatchersApi = (id, object, endRoute) => {
  const path = `${endpointApi}/crm/tasks/${id}/${endRoute}`;
  return putCallback(path, object);
};

export const fetchCalenderTasksApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/appointments/calendar${queryParams}`;
  return getCallback(path);
};
export const fetchSchedulesApi = (queryParams = "") => {
  const path = `${endpointApi}/schedules/calendar${queryParams}`;
  return getCallback(path);
};
// Task Add File

export const taskAttachFileApi = (id, object) => {
  const path = `${endpointApi}/crm/tasks/${id}/attach_file`;
  return putCallback(path, object);
};

export const taskDeleteFileApi = (id, params) => {
  const path = `${endpointApi}/crm/tasks/${id}/remove_file${params ? params : ""}`;
  return deleteCallback(path);
};

// Lead Add File

export const crmLeadAttachFileApi = (id, object) => {
  const path = `${endpointApi}/crm/crm_leads/${id}/attach_file`;
  return putCallback(path, object);
};

export const crmLeadDeleteFileApi = (id, params) => {
  const path = `${endpointApi}/crm/crm_leads/${id}/remove_file${params ? params : ""}`;
  return deleteCallback(path);
};

export const fetchCrmClientsApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/clients${queryParams}`;
  return getCallback(path);
};
export const fetchCrmClientEditApi = (id = "") => {
  const path = `${endpointApi}/crm/clients/${id}/edit`;
  return getCallback(path);
};
export const updateCrmClientApi = async (id, object) => {
  const path = `${endpointApi}/crm/clients/${id}`;
  return putCallback(path, object);
};
export const fetchCrmClientApi = (id = "") => {
  const path = `${endpointApi}/crm/clients/${id}`;
  return getCallback(path);
};
export const attachDocumentWithCrmClientLead = (id, data) => {
  const path = `${endpointApi}/crm/clients/${id}/attach_file`;
  return putCallback(path, data);
};
export const deleteCrmClientFileApi = (id, data) => {
  const path = `${endpointApi}/crm/clients/${id}/remove_file`;
  return deleteCallback(path, data);
};

export const fetchCrmClientTaxInvoices = (queryParams = "") => {
  const path = `${endpointApi}/crm/clients/tax_invoices${queryParams}`;
  return getCallback(path);
};

// Locations
export const fetchLocationsApi = (queryParams = "") => {
  const path = `${endpointApi}/locations${queryParams}`;
  return getCallback(path);
};
export const fetchLocationsListApi = (queryParams = "") => {
  const path = `${endpointApi}/locations/list${queryParams}`;
  return getCallback(path);
};

export const bulkUpdateCapacityApi = object => {
  const path = `${endpointApi}/capacities/bulk_update_capacity`;
  return putCallback(path, object);
};

export const fetchLocationApi = locationId => {
  const path = `${endpointApi}/locations/${locationId}`;
  return getCallback(path);
};

export const fetchAsyncLocationApi = (queryParams = "") => {
  const path = `${endpointApi}/locations${queryParams}`;
  return getCallback(path);
};

export const fetchTaskStatusesApi = (params = {}, object) => {
  let queryString = convertQueryObjToString(params);
  const path = `${endpointApi}/crm/tasks_statistics?${queryString}`;
  return postCallback(path, object);
};

export const fetchCityZones = (queryParams = "") => {
  const path = `${endpointApi}/zones${queryParams}`;
  return getCallback(path);
};
export const fetchZoneAreas = (queryParams = "") => {
  const path = `${endpointApi}/areas${queryParams}`;
  return getCallback(path);
};
// // Appointments

export const fetchAppointmentsApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/appointments${queryParams}`;
  return getCallback(path);
};
export const fetchLeadSourcesApi = (queryParams = "") => {
  const path = `${endpointApi}/lead_sources${queryParams}`;
  return getCallback(path);
};

export const fetchLeadTimeLineApi = (type, id, page = 1) => {
  const path = `${endpointApi}/timelines?q[trackable_type_eq]=${type}&q[trackable_id_eq]=${id}&page=${page}`;
  return getCallback(path);
};

export const fetchInspectionProgramDetailApi = async id => {
  const path = `${endpointApi}/inspections/${id}`;
  return getCallback(path);
};

export const addInspectionProgramApi = async object => {
  const path = `${endpointApi}/inspections`;
  return postCallback(path, object);
};

export const updateInspectionProgramApi = async (id, object) => {
  const path = `${endpointApi}/inspections/${id}`;
  return putCallback(path, object);
};

export const excelFileUpload = async (id, obj) => {
  const path = `${endpointApi}/inspections/${id}/upload_checkpoints`;
  try {
    const res = await performPostRequest(path, obj);
    return res.data;
  } catch (e) {
    return getError(e);
  }
};
export const fetchLeadsByStatusesApi = (params, obj) => {
  const path = `${endpointApi}/inspection/leads_stats_by_date${params}`;
  return postCallback(path, obj);
};

export const fetchSmsTemplatesApi = (queryParams = "") => {
  const path = `${endpointApi}/sms_templates${queryParams}`;
  return getCallback(path);
};

export const fetchSmsTemplateDetailsApi = id => {
  const path = `${endpointApi}/sms_templates/${id}`;
  return getCallback(path);
};

export const updateSmsTemplateApi = (id, object) => {
  const path = `${endpointApi}/sms_templates/${id}`;
  return putCallback(path, object);
};
export const fetchEmailTemplatesApi = (queryParams = "") => {
  const path = `${endpointApi}/email_templates${queryParams}`;
  return getCallback(path);
};
export const fetchEmailTemplateDetailsApi = id => {
  const path = `${endpointApi}/email_templates/${id}`;
  return getCallback(path);
};

export const updateEmailTemplateApi = (id, object) => {
  const path = `${endpointApi}/email_templates/${id}`;
  return putCallback(path, object);
};
export const fetchDynamicModelsApi = (queryParams = "") => {
  const path = `${endpointApi}/dynamic_models${queryParams}`;
  return getCallback(path);
};

export const updatePriceRecommendationApi = (inventoryId, object) => {
  const path = `${endpointApi}/crm/inventories/${inventoryId}/update_price_recommendation`;
  return putCallback(path, object);
};

export const fetchLeadSourceDetailApi = id => {
  const path = `${endpointApi}/lead_sources/${id}`;
  return getCallback(path);
};

export const addLeadSourceApi = obj => {
  const path = `${endpointApi}/lead_sources`;
  return postCallback(path, obj);
};

export const updateLeadSourceApi = (id, object) => {
  const path = `${endpointApi}/lead_sources/${id}`;
  return putCallback(path, object);
};

export const fetchColaborators = id => {
  const path = `${endpointApi}/crm/crm_leads/${id}/collaborators`;
  return getCallback(path);
};
export const fetchTimeSlotsApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/time_slots${queryParams}`;
  return getCallback(path);
};

export const fetchInventoryBusySlots = (queryParams = "") => {
  const path = `${endpointApi}/crm/time_slots/user_available_slots${queryParams}`;
  return getCallback(path);
};

export const fetchTimeSlots = (queryParams = "") => {
  const path = `${endpointApi}/crm/time_slots/available_slots${queryParams}`;
  return getCallback(path);
};

// Crm Dashboard

export const fetchCrmStatisticsApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/all_statistics${queryParams}`;
  return getCallback(path);
};

export const fetchCrmInventoryStatisticsApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/cars_statistics${queryParams}`;
  return getCallback(path);
};

export const fetchInspectionLeadAssignessApi = id => {
  const path = `${endpointApi}/leads/assignees?lead_id=${id}`;
  return getCallback(path);
};

export const updateInspectionLeadAssignessApi = (id, obj) => {
  const path = `${endpointApi}/leads/${id}/change_assignee`;
  return putCallback(path, obj);
};

export const revertTaskStatusApi = (id, obj = {}) => {
  const path = `${endpointApi}/crm/tasks/${id}/reopen`;
  return putCallback(path, obj);
};

export const markInventoryApi = (inventoryId, action, obj) => {
  const path = `${endpointApi}/crm/inventories/${inventoryId}/${action}`;
  return putCallback(path, obj);
};

export const addTaskTypeApi = obj => {
  const path = `${endpointApi}/crm/task_types`;
  return postCallback(path, obj);
};
export const markInventoryAsReadyForAuction = id => {
  const path = `${endpointApi}/crm/inventories/${id}/mark_ready_for_auction`;
  return putCallback(path);
};

export const updateTaskTypeApi = (id, obj) => {
  const path = `${endpointApi}/crm/task_types/${id}`;
  return putCallback(path, obj);
};

export const deleteTaskTypeApi = id => {
  const path = `${endpointApi}/crm/task_types/${id}`;
  return deleteCallback(path);
};

export const fetchAvailableCarsApi = queryParams => {
  const path = `${endpointApi}/crm/cars/available${queryParams}`;
  return getCallback(path);
};

export const uploadDocumentsApi = data => {
  const path = `${endpointApi}/crm/documents/create_bulk`;
  return putCallback(path, data);
};

export const removeDocumentApi = id => {
  const path = `${endpointApi}/crm/documents/${id}`;
  return deleteCallback(path);
};

export const uploadLabelApi = (id, data) => {
  const path = `${endpointApi}/crm/documents/${id}`;
  return putCallback(path, data);
};

export const fetchCRMTimeLineApi = (type, id, page = 1) => {
  const path = `${endpointApi}/activities?or[0][trackable_type]=${type}&or[0][trackable_id]=${id}&or[1][associated_type]=${type}&or[1][associated_id]=${id}&page=${page}`;
  return getCallback(path);
};

export const fetchCRMInventoryTimelineApi = (inventoryId, carId, page = 1) => {
  const path = `${endpointApi}/activities?or[0][trackable_type]=Inventory&or[0][trackable_id]=${inventoryId}&or[1][trackable_type]=Car&or[1][trackable_id]=${carId}&page=${page}`;
  return getCallback(path);
};

export const fetchBranchesLocationApi = (params = "") => {
  const path = `${endpointApi}/locations?f[location_type]=branch`;
  return getCallback(path);
};

export const fetchInspectionsApi = (queryParams = "") => {
  const path = `${endpointApi}/inspections${queryParams}`;
  return getCallback(path);
};

export const fetchLocationAssigneesApi = queryParams => {
  const path = `${endpointApi}/crm/time_slots/available_users${queryParams}`;
  return getCallback(path);
};

export const addLocationApi = async object => {
  const path = `${endpointApi}/locations`;
  return postCallback(path, object);
};

export const deleteLocationApi = async object => {
  const path = `${endpointApi}/locations`;
  return deleteCallback(path, object);
};

export const editLocationApi = async (id, object) => {
  const path = `${endpointApi}/locations/${id}`;
  return putCallback(path, object);
};
export const attachDocumentWithCrmLead = (id, data, params = "") => {
  const path = `${endpointApi}/crm/crm_leads/${id}/attach_file${params}`;
  return putCallback(path, data);
};

export const deleteDocumentWithCrmLead = (id, data) => {
  const path = `${endpointApi}/crm/crm_leads/${id}/remove_file`;
  return deleteCallback(path, data);
};
export const attachDocumentWithTask = (id, data) => {
  const path = `${endpointApi}/crm/tasks/${id}/attach_file`;
  return putCallback(path, data);
};

export const deleteDocumentWithTask = (id, data) => {
  const path = `${endpointApi}/crm/tasks/${id}/remove_file`;
  return deleteCallback(path, data);
};

export const getTargetPricePoliciesApi = (params = "") => {
  const path = `${endpointApi}/auction/target_price_policies${params}`;
  return getCallback(path);
};

export const getCrmDashboardWidgetsDataApi = (params = "") => {
  const path = `${endpointApi}/crm/widgets${params}`;
  return getCallback(path);
};

export const getAuctionStatsByDate = (params = "") => {
  const path = `${endpointApi}/auction/stats_by_date${params}`;
  return getCallback(path);
};

export const changeBiddersPaymentStatusApi = (id, data) => {
  const path = `${endpointApi}/auction/auction_users/${id}/change_payment_status`;
  return putCallback(path, data);
};

export const fetchCrmReportTypesApi = (id = "") => {
  const path = `${endpointApi}/crm/visualizations${id ? `/${id}` : ""}`;
  return getCallback(path);
};

export const fetchCrmDashboardWidgetDataApi = (id, queryParams) => {
  const path = `${endpointApi}/crm/widgets/${id}${queryParams ? `?${queryParams}` : ""}`;
  return getCallback(path);
};

export const fetchAdInsights = queryParams => {
  const path = `${endpointApi}/crm/widgets/fetch_widgets_data${queryParams ? `?${queryParams}` : ""}`;
  return getCallback(path);
};

export const updateTaskInventory = (taskId, data) => {
  const path = `${endpointApi}/crm/tasks/${taskId}/change_inventory`;
  return putCallback(path, data);
};
//Stock Count
export const fetchCrmStockCountsApi = (queryParam = "") => {
  const path = `${endpointApi}/crm/stock_counters${queryParam}`;
  return getCallback(path);
};
export const fetchCrmStockCountDetailApi = id => {
  const path = `${endpointApi}/crm/stock_counters/${id}`;
  return getCallback(path);
};
//CRM CICOS
export const fetchCrmCicosApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/cicos${queryParams}`;
  return getCallback(path);
};
export const fetchCrmCicoApi = id => {
  const path = `${endpointApi}/crm/cicos/${id}`;
  return getCallback(path);
};
export const fetchCrmCicoNewApi = () => {
  const path = `${endpointApi}/crm/cicos/new`;
  return getCallback(path);
};

export const addCrmCicoApi = obj => {
  const path = `${endpointApi}/crm/cicos`;
  return postCallback(path, obj);
};
export const updateCrmCicoApi = (id, obj) => {
  const path = `${endpointApi}/crm/cicos/${id}`;
  return putCallback(path, obj);
};

export const fetchUsersListApi = (queryParams = "", lisQuery = [], reload = false) => {
  let newQueryString = queryParams;
  if (lisQuery?.length && !reload) {
    lisQuery.forEach(element => {
      newQueryString = newQueryString + `&f[roles.id][]=${element.id}`;
    });
  }
  const path = `${endpointApi}/users/list${newQueryString}`;
  return getCallback(path);
};

export const fetchUsersRolesApi = (queryParams = "") => {
  const path = `${endpointApi}/roles/list${queryParams}`;
  return getCallback(path);
};

export const fetchUserRolesApi = (queryParam = "") => {
  const path = `${endpointApi}/roles/list${queryParam}`;
  return getCallback(path);
};

export const fetchInventoryRecommendations = carId => {
  const path = `${endpointApi}/crm/cars/${carId}/recommendations`;
  return getCallback(path);
};

export const fetchCrmLeadRecommendations = leadId => {
  const path = `${endpointApi}/crm/crm_leads/${leadId}/car_recommendations`;
  return getCallback(path);
};
export const deleteInventoryTagApi = (id, obj) => {
  const path = `${endpointApi}/crm/inventories/${id}/remove_tags`;
  return deleteCallback(path, obj);
};

export const addInventorytagApi = (id, obj) => {
  const path = `${endpointApi}/crm/inventories/${id}/add_tags`;
  return putCallback(path, obj);
};
export const fetchNotificationApi = (queryParam = "") => {
  const path = `${endpointApi}/notifications/my_notifications${queryParam}`;
  return getCallback(path);
};
export const updateNotificationAsReadApi = (notificationId = "") => {
  const path = `${endpointApi}/notifications/${notificationId}/mark_as_read`;
  return putCallback(path);
};
export const updateNotificationaAsAllReadApi = obj => {
  const path = `${endpointApi}/notifications/mark_all_as_read`;
  return putCallback(path, obj);
};

export const getAutoAssignedUser = (queryParams = "") => {
  const path = `${endpointApi}/crm/time_slots/available_user${queryParams}`;
  return getCallback(path);
};
export const fetchAdPostLookupData = () => {
  const path = `${endpointApi}/crm/external_ads/lookup`;
  return getCallback(path);
};
export const fetchExternalAdEditApi = id => {
  const path = `${endpointApi}/crm/external_ads/${id}`;
  return getCallback(path);
};

export const getPostedAd = async id => {
  const path = `${endpointApi}/crm/external_ads/38`;
  return getCallback(path);
};

export const createAdPost = async object => {
  const path = `${endpointApi}/crm/external_ads`;
  return postCallback(path, object);
};
export const updateAdPost = async (externalAdId, object) => {
  const path = `${endpointApi}/crm/external_ads/${externalAdId}`;
  return putCallback(path, object);
};
export const deleteAdPost = async externalAdId => {
  const path = `${endpointApi}/crm/external_ads/${externalAdId}`;
  return deleteCallback(path);
};

export const deactivateAd = externalAdId => {
  const path = `${endpointApi}/crm/external_ads/${externalAdId}/pause`;
  return getCallback(path);
};

export const activateAd = externalAdId => {
  const path = `${endpointApi}/crm/external_ads/${externalAdId}/resume`;
  return getCallback(path);
};
export const reserveAdApi = externalAdId => {
  const path = `${endpointApi}/crm/external_ads/${externalAdId}/reserve`;
  return getCallback(path);
};
export const unreserveAdApi = externalAdId => {
  const path = `${endpointApi}/crm/external_ads/${externalAdId}/unreserve`;
  return getCallback(path);
};

export const fetchCrmItemListsApi = (queryParams = "") => {
  const path = `${endpointApi}/item_lists/list${queryParams}`;
  return getCallback(path);
};

export const fetchItemListsApi = (queryParams = "") => {
  const path = `${endpointApi}/item_lists/list${queryParams}`;
  return getCallback(path);
};
export const fetchItemListsWithoutPaginationApi = (queryParams = "") => {
  const path = `${endpointApi}/item_lists${queryParams}`;
  return getCallback(path);
};

export const fetchItemListApi = (queryParams = "") => {
  const path = `${endpointApi}/crm/item_lists${queryParams}`;
  return getCallback(path);
};

export const addCrmItemListApi = data => {
  const path = `${endpointApi}/crm/item_lists`;
  return postCallback(path, data);
};

export const fetchCrmItemListApi = (id = "") => {
  const path = `${endpointApi}/crm/item_lists/${id}/edit`;
  return getCallback(path);
};

export const updateCrmItemListApi = (id = "", data) => {
  const path = `${endpointApi}/crm/item_lists/${id}`;
  return putCallback(path, data);
};

export const addTaskReminderApi = (id, obj) => {
  const path = `${endpointApi}/crm/tasks/${id}/add_reminder`;
  return putCallback(path, obj);
};
export const deleteTaskReminderApi = (id, obj) => {
  const path = `${endpointApi}/crm/tasks/${id}/remove_reminder`;
  return deleteCallback(path, obj);
};
export const updateTaskReminderApi = (id, obj) => {
  const path = `${endpointApi}/crm/tasks/${id}/update_reminder`;
  return putCallback(path, obj);
};

export const fetchCrmTaskRemindersList = (params = "") => {
  const path = `${endpointApi}/crm/reminders${params}`;
  return getCallback(path);
};
export const updateUserSettings = async (id, object) => {
  const path = `${endpoint}/api/users/${id}`;
  return putCallback(path, object);
};
export const getCurrentUser = async id => {
  const path = `${endpoint}/api/users/${id}`;
  return getCallback(path);
};

export const fetchBuyerLeadRecommendations = carId => {
  const path = `${endpointApi}/crm/cars/${carId}/buyer_lead_recommendations`;
  return getCallback(path);
};

export const fetchBookingExpenses = id => {
  const path = `${endpointApi}/tenants/${id}/booking_services`;
  return getCallback(path);
};

export const updateBookingExpenses = (id, obj) => {
  const path = `${endpointApi}/tenants/${id}/update_booking_services`;
  return putCallback(path, obj);
};

export const fetchRangeSettings = associatedType => {
  const path = `${endpointApi}/crm/range_settings?q[associated_type_eq]=${associatedType}`;
  return getCallback(path);
};

export const updateRangeSettings = (id, obj) => {
  const path = `${endpointApi}/tenants/${id}/update_range_setting`;
  return putCallback(path, obj);
};

export const fetchAuctionOfferMarkAsOfferDetail = offerId => {
  const path = `${endpointApi}/auction/offers/${offerId}`;
  return getCallback(path);
};

export const fetchTenantBookingServices = tenantId => {
  const path = `${endpointApi}/tenants/${tenantId}/booking_services`;
  return getCallback(path);
};

export const markAuctionOfferAsWinner = (id, object) => {
  const path = `${endpoint}/api/auction/offers/${id}/mark_winner`;
  return putCallback(path, object);
};

export const markWinnerAsLostApi = (id, object) => {
  const path = `${endpoint}/api/auction/offers/${id}/mark_as_lost`;
  return putCallback(path, object);
};

export const fetchAuctionBookingsApi = (query = "") => {
  const path = `${endpointApi}/auction/bookings${query}`;
  return getCallback(path);
};

export const loginWithSSOApi = (object = {}, query = "") => {
  const path = endpoint + `/auth/google_login?access_token=${query}`;
  return postCallback(path, object, true);
};

export const generateOtpForImpersonation = (object = {}) => {
  const path = endpoint + `/auth/master_otp`;
  return postCallback(path, object, true);
};

export const fetchAuctionBookingDetailApi = id => {
  const path = `${endpointApi}/auction/bookings/${id}`;
  return getCallback(path);
};

export const updateAllocationRules = (id, payload) => {
  const path = `${endpointApi}/users/${id}`;
  return putCallback(path, payload);
};

export const fetchAllocatedLead = () => {
  const path = `${endpointApi}/crm/crm_leads/fetch_lead`;
  return putCallback(path, {});
};

export const removeAllocationRule = (id, object) => {
  const path = `${endpointApi}/users/${id}/remove_allocation_rule`;
  return deleteCallback(path, object);
};

export const fetchLocationCapacities = (params = "") => {
  const path = `${endpointApi}/capacities${params}`;
  return getCallback(path);
};

export const updateCRMLeadCallOutcomeApi = (id, object) => {
  const path = `${endpointApi}/crm/crm_leads/${id}/update_call_outcome`;
  return putCallback(path, object);
};

export const fetchCityListApi = (params = "") => {
  const path = `${endpointApi}/cities/list${params}`;
  return getCallback(path);
};
export const fetchAreaListApi = params => {
  //q[location_id_null]=true
  const path = `${endpointApi}/areas/list${params}`;
  return getCallback(path);
};

export const fetchStockCountCountedVehicles = (id, params = "") => {
  const path = `${endpointApi}/crm/stock_counters/${id}/counted_inventories${params}`;
  return getCallback(path);
};

export const fetchStockCountMissedVehicles = (id, params = "") => {
  const path = `${endpointApi}/crm/stock_counters/${id}/missed_inventories${params}`;
  return getCallback(path);
};

export const fetchUserConfigration = id => {
  const path = `${endpointApi}/users/${id}`;
  return getCallback(path);
};

export const fetchUserLeavesApi = ({ params = "", cancelToken = null }) => {
  const path = `${endpointApi}/leaves${params}`;
  return getCallback(path, null, cancelToken);
};

export const createUserLeaveApi = (object = {}) => {
  const path = `${endpointApi}/leaves`;
  return postCallback(path, object);
};

export const updateUserLeaveApi = (id, object = {}) => {
  const path = `${endpointApi}/leaves/${id}`;
  return putCallback(path, object);
};

export const fetchShifts = ({ params = "", cancelToken = null }) => {
  const path = `${endpointApi}/shifts${params}&limit=1000`;
  return getCallback(path, null, cancelToken);
};

export const createTaskFollowUp = (id, object) => {
  const path = `${endpointApi}/crm/tasks/${id}/add_follow_up`;
  return putCallback(path, object);
};

export const changeFollowUpStatus = (followUpId, object) => {
  const path = `${endpointApi}/crm/follow_ups/${followUpId}/change_status`;
  return putCallback(path, object);
};

export const changeFollowUpAssignee = (followUpId, object) => {
  const path = `${endpointApi}/crm/follow_ups/${followUpId}/change_assignee`;
  return putCallback(path, object);
};

export const fetchFollowUps = (params = "") => {
  const path = `${endpointApi}/crm/follow_ups${params}`;
  return getCallback(path);
};

export const fetchCrmLeadFollowUpsApi = (params = "") => {
  const path = `${endpointApi}/crm/follow_ups?[followed_type]=CrmLead${params}`;
  return getCallback(path);
};

export const createLeadFollowUp = (id, object) => {
  const path = `${endpointApi}/crm/crm_leads/${id}/add_follow_up`;
  return putCallback(path, object);
};

export const createShiftsApi = object => {
  const path = `${endpointApi}/shifts/create_shifts`;
  return postCallback(path, object);
};

export const editShiftApi = (object, id) => {
  const path = `${endpointApi}/shifts/${id}`;
  return putCallback(path, object);
};

export const overagedEmailApi = (id, object) => {
  const path = `${endpointApi}/crm/cars/${id}/send_email`;
  return putCallback(path, object);
};

export const deleteLeave = id => {
  const path = `${endpointApi}/leaves/${id}`;
  return deleteCallback(path);
};

export const deleteShifts = id => {
  const path = `${endpointApi}/shifts/${id}`;
  return deleteCallback(path);
};

export const updateShiftsApi = (id, object) => {
  const path = `${endpointApi}/shifts/${id}`;
  return putCallback(path, object);
};

export const getInsightsApi = params => {
  const path = `${endpointApi}/crm/appointments/insights?date=${params}`;
  return getCallback(path);
};
export const fetchCrmContractsApi = params => {
  const path = `${endpointApi}/crm/contracts${params}`;
  return getCallback(path);
};
export const resendEmailContractsApi = (uuid, payload) => {
  const path = `${endpointApi}/crm/contracts/${uuid}/resend_email`;
  return putCallback(path, payload);
};
export const fetchCrmContractsNewApi = () => {
  const path = `${endpointApi}/crm/contracts/new`;
  return getCallback(path);
};
export const createCrmContractApi = payload => {
  const path = `${endpointApi}/crm/contracts`;
  return postCallback(path, payload);
};
export const updateCrmContractApi = (id, payload) => {
  const path = `${endpointApi}/crm/contracts/${id}/edit`;
  return putCallback(path, payload);
};
export const fetchCrmContractEditApi = id => {
  const path = `${endpointApi}/crm/contracts/${id}/edit`;
  return getCallback(path);
};

export const fetchCrmContractDetailApi = id => {
  const path = `${endpointApi}/crm/contracts/${id}`;
  return getCallback(path);
};
export const changeContractStatus = (id, payload) => {
  const path = `${endpointApi}/crm/contracts/${id}/change_status`;
  return putCallback(path, payload);
};
export const editLeave = id => {
  const path = `${endpointApi}/leaves/${id}`;
  return getCallback(path);
};

export const updateAuctionDreUserApi = (id, object, endPoint = "add_assignee") => {
  const path = `${endpointApi}/auction/auction_users/${id}/${endPoint}`;
  return putCallback(path, object);
};

export const getKittReportsApi = params => {
  const path = `${endpointApi}/crm/kitt_recommendations${params}`;
  return getCallback(path);
};

export const getKittSearchVariantsApi = id => {
  const path = `${endpointApi}/crm/kitt_recommendations/variant_search?variant_ids[]=${id}`;
  return getCallback(path);
};

export const getKittOptionsApi = () => {
  const path = `${endpointApi}/crm/kitt_recommendations/options`;
  return getCallback(path);
};

export const generateKittRecommendationsApi = object => {
  const path = `${endpointApi}/crm/kitt_recommendations/kitt_recommendation`;
  return postCallback(path, object);
};

export const updateKittMarginsApi = (id, object) => {
  const path = `${endpointApi}/tenants/${id}/update_kitt_margins`;
  return putCallback(path, object);
};

export const fetchReconditioningExpensesApi = id => {
  const path = `${endpointApi}/reports/${id}/reconditioning_expense`;
  return getCallback(path);
};

export const viewPhoneNumber = ({
  uuid,
  associatedId,
  associatedType,
  component = "",
  isUserTypeNotClient = false, //Needed because on backend Clients and Users are different models
}) => {
  let path = isUserTypeNotClient
    ? `${endpointApi}/users/${uuid}/view_phone`
    : `${endpointApi}/crm/clients/${uuid}/view_phone`;

  let payload = {
    associated_type: associatedType,
    associated_id: associatedId,
    component: component,
    platform: "web",
  };

  return postCallback(path, payload);
};

export const getAuctionNegotiations = (params = "") => {
  const path = `${endpointApi}/auction/negotiations${params}`;
  return getCallback(path);
};

export const getAuctionNegotiationOffers = () => {
  const path = `${endpointApi}/auction/negotiation_offers`;
  return getCallback(path);
};

export const createAuctionNegotiation = payload => {
  const path = `${endpointApi}/auction/negotiation_offers`;
  return postCallback(path, payload);
};

export const markAsNegotiator = payload => {
  const path = `${endpointApi}/auction/negotiations`;
  return postCallback(path, payload);
};

export const acceptRejectNegotiation = (id, payload) => {
  const path = `${endpointApi}/auction/negotiations/${id}`;
  return putCallback(path, payload);
};
export const viewAttachmentApi = ({ attachmentId, associatedType, associatedId, component, platform = "web" }) => {
  const payload = {
    associated_type: associatedType,
    associated_id: associatedId,
    component: component,
    platform: platform,
  };
  const path = `${endpointApi}/attachments/${attachmentId}/view_attachment`;

  return postCallback(path, payload);
};

// QC
export const fetchCallRecordings = params => {
  const path = `${endpointApi}/crm/recordings${params ? params : ""}`;
  return getCallback(path);
};

export const fetchQCTemplates = () => {
  const path = `${endpointApi}/crm/templates`;
  return getCallback(path);
};

export const fetchQCTemplatesQuestions = params => {
  const path = `${endpointApi}/crm/templates/${params ? params : ""}/questions`;
  return getApiMethod(path);
};

export const createQCReport = object => {
  const path = `${endpointApi}/crm/evaluation_reports`;
  return postApiMethod(path, object);
};
export const updateCallStatusApi = (id, object) => {
  const path = `${endpointApi}/crm/recordings/${id}`;
  return putCallback(path, object);
};

export const callDialerApi = ({ agentId, phoneNumber, crmLeadId, inventoryId, taskId, insLeadId }) =>
  `https://proxy-czentrix.empgautos.com/apps/customize_apps/click_to_call.php?mobile=${phoneNumber}&agent_id=${agentId}&crm_lead_id=${
    crmLeadId ?? null
  }&inventory_id=${inventoryId ?? null}&task_id=${taskId ?? null}&insp_lead_id=${insLeadId ?? null}&resFormat=0`;

export const initiatePropGoCallApi = object => {
  const path = `${endpointApi}/crm/recordings/initiate_prop_go_call`;
  return postCallback(path, object);
};

export const fetchQCReportQuestionsWithAnswers = params => {
  const path = `${endpointApi}/crm/evaluation_reports/${params ? params : ""}/edit`;
  return getApiMethod(path);
};

export const updateQCQuestionsWithAnswers = ({ params = "", payload = {} }) => {
  const path = `${endpointApi}/crm/evaluation_reports/${params ? params : ""}`;
  return putApiMethod(path, payload);
};

export const fetchQCReportAnswers = params => {
  const path = `${endpointApi}/crm/evaluation_reports/${params ? params : ""}`;
  return getApiMethod(path);
};

export const fetchAttachments = ({ attachedType, attachedId }) => {
  const path = `${endpointApi}/attachments/?q[attached_type_eq]=${attachedType}&q[attached_id_eq]=${attachedId}`;
  return getApiMethod(path);
};
export const fetchDocumentsApi = params => {
  const path = `${endpointApi}/crm/documents${params ? params : ""}`;
  return getApiMethod(path);
};

export const fetchBuyerConnectsApi = id => {
  const path = `${endpointApi}/crm/cars/${id}/buyer_connects`;
  return getApiMethod(path);
};

export const fetchComments = ({ commentableType, commentableId, params }) => {
  const path = `${endpointApi}/crm/comments?q[commentable_type_eq]=${commentableType}&q[commentable_id_eq]=${commentableId}${
    params ? params : ""
  }`;
  return getApiMethod(path);
};

export const fetchInspectionReports = id => {
  const path = `${endpointApi}/crm/cars/${id}/reports`;
  return getApiMethod(path);
};

export const updateOfferMargin = ({ params = "", payload = {} }) => {
  const path = `${endpointApi}/auction/negotiation_offers/${params ? params : ""}/adjust_margin`;
  return putApiMethod(path, payload);
};

export const fetchAuctionCriteriaState = ({ params = "" }) => {
  const path = `${endpointApi}/auction/auctions/new${params ? params : ""}`;
  return getCallback(path);
};

export const getPaymentMetaApi = id => {
  const path = `${endpointApi}/crm/cars/${id}/payment_card`;
  return getCallback(path);
};

export const fetchLeadsOfPaymentTypes = (id, inventoryId, params) => {
  let path = `${endpointApi}/crm/crm_payment_types/${id}/crm_leads`;
  if (params) {
    path += params;
  }
  if (inventoryId && params) {
    path += `&f[inventory.id][]=${inventoryId}`;
  } else if (inventoryId && !params) {
    path += `?f[inventory.id][]=${inventoryId}`;
  }
  return getCallback(path);
};

export const fetchUnifonicTemplates = (params = "") => {
  const path = `${endpointApi}/crm/unifonic_messages${params ? params : ""}`;
  return getCallback(path);
};

export const sendUnifonicWhatsAppMessage = ({ payload }) => {
  const path = `${endpointApi}/crm/unifonic_messages`;
  return postCallback(path, payload);
};

export const fetchLeadsChevronOptions = () => {
  const path = `${endpointApi}/crm/crm_leads/chevron_settings`;
  return getCallback(path);
};

export const fetchChevronUsers = params => {
  const path = `${endpointApi}/crm/crm_leads/chevron_users_list${params ? params : ""}`;
  return getApiMethod(path);
};

export const fetchExternalAdActivities = (params, page) => {
  const path = `${endpointApi}/activities?inventory_id=${params ? params : ""}&page=${page}`;
  return getApiMethod(path);
};
export const fetchCrmReportsApi = ({ params = "", key }) => {
  let path = `${endpointApi}/crm/widgets/${key}`;
  if (params) {
    path += params;
  }
  return getCallback(path);
};

export const fetchHierarchyApi = id => {
  const path = `${endpointApi}/users/hierarchy?widget_id=${id}`;
  return getCallback(path);
};

export const fetchVisualizationApi = (params = "") => {
  const updatedParams = params ? `?${params}` : "";
  const path = `${endpointApi}/crm/visualizations${updatedParams}`;
  return getCallback(path);
};

export const fetchPopoverApi = params => {
  const path = `${endpointApi}/crm/${params}`;
  return getCallback(path);
};

export const fetchLeadsDuplicationApi = params => {
  const path = `${endpointApi}/crm/crm_leads/show_duplication${params}`;
  return getCallback(path);
};

export const inspectionReportsApi = id => {
  const path = `${endpointApi}/inspection_invoices/${id}`;
  return getCallback(path);
};
