import { useEffect, useState } from "react";
import { getErrorString } from "../helpers/Util";

export const useFetch = (fetchCall, params = [], dependencyArray = [], errorHandlerCallback) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchCall && fetchData();
  }, dependencyArray);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchCall(...params);
      if (response) {
        setLoading(false);
        if (response.success) {
          setData(response);
        } else {
          setError(getErrorString(response));
        }
      }
    } catch (error) {
      errorHandlerCallback && errorHandlerCallback(error);
    }
  };
  return { data, error, loading, fetchData };
};
