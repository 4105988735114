import React from "react";
import { Avatar, Button } from "../../components/common";
import { strings } from "../../constant/strings";
import { CgSoftwareUpload, IconCamera } from "../svg-icon/svg-icons";
import cx from "clsx";
import { onFilesAdded, uploadFile } from "../crm-leads/UploadAttachmentsHelpers";
import ImageUploadItem from "./ImageUploadItem";
import { isEmptyArray } from "../../helpers/Util";

const ImageUploadWithPreview = props => {
  const {
    isEditable = null,
    imgUploadPreviewClass = "",
    fileType = "documents",
    imageKey = "gallerythumb",
    isSvg = true,
    makeBlob = false,
    userImgView,
    thumbnailSize,
    imgContain = true,
    fileUploaderClass = "",
    label,
    image,
    btnLink = false,
  } = props;

  const retryCallback = imageItem => {
    if (imageItem.inError && !imageItem.uploading && !imageItem.uploaded) {
      uploadFile(imageItem, fileType, props.associationKey, props.files, props.setFiles, false, true, undefined);
    }
  };

  const renderImages = () => {
    if (fileType === "avatars" && isEmptyArray(props.files)) {
      return (
        <div className={"imgUploadPreviewWrap"}>
          <Avatar name={props.userName} avatarOnly size={145} />
        </div>
      );
    }
    return (
      <>
        {props.files.map((e, i) => {
          return getImageItem(e, i);
        })}
      </>
    );
  };

  const getImageItem = (item, index) => {
    return (
      <ImageUploadItem
        key={index}
        onRetry={() => retryCallback(item)}
        item={item}
        files={props.files}
        setFiles={props.setFiles}
        removeImage={props.onRemove}
        isSvg={isSvg}
        makeBlob={makeBlob}
        attachmentType={fileType}
        associationKey={props.associationKey}
        imgContain={imgContain}
        imgUploadPreviewClass={imgUploadPreviewClass}
        userImgView={userImgView}
        size={thumbnailSize}
        imageKey={imageKey}
      />
    );
  };

  const onInputChange = event => {
    let file = event.target.files;
    onFilesAdded(userImgView ? [] : props.files, props.setFiles, file);
    event.target.value = null;
  };

  const imageUpload = () => {
    return (
      <div
        className={cx(
          props.horizontal ? "row form-group" : "",
          props.containerClassName ? props.containerClassName : ""
        )}
      >
        {label && (
          <label
            className={cx(
              props.horizontal
                ? `col-form-label col-sm-${props.columnsLeftSection ? props.columnsLeftSection : "3"}`
                : "",
              props.labelLeft ? "" : "text-right",
              props.labelClassName ? props.labelClassName : ""
            )}
          >
            {props.label}
          </label>
        )}

        {image && image}

        <div
          className={cx(
            props.horizontal ? `col-sm-${props.columnsRightSection ? props.columnsRightSection : "9"}` : ""
          )}
        >
          <div className={props.imageContainerClass ? props.imageContainerClass : ""}>
            {userImgView ? renderUserImage() : renderImageAndButton()}
          </div>
          {!!props.errorMsg && <p className={"invalid-error"}>{props.errorMsg}</p>}
        </div>
      </div>
    );
  };

  const renderImageAndButton = () => {
    return !props.files?.length ? (
      <>
        <input
          disabled={isEditable === false}
          type="file"
          accept={`image/*`}
          style={{ display: "none" }}
          id={`${props.formikKey}-${props.slug}`}
          onChange={e => {
            onInputChange(e, props.formikKey, props.associationKey, props.isArray, props.formikProps, props.slug);
          }}
        />

        {/* {userImgView ? (
          <></>
        ) : ( */}
        {btnLink ? (
          <button
            className="btn btn-link"
            disabled={isEditable === false}
            onClick={event => {
              event.preventDefault();
              document.getElementById(`${props.formikKey}-${props.slug}`).click();
            }}
            style={{ fontSize: "1.125rem", fontWeight: "500" }}
          >
            Click to upload
          </button>
        ) : (
          <Button
            disabled={isEditable === false}
            btnText={strings.upload}
            color="outline-primary"
            icon={<CgSoftwareUpload size="1.25em" />}
            variant="normal"
            className="d-inline-flex"
            onClick={() => {
              document.getElementById(`${props.formikKey}-${props.slug}`).click();
            }}
          />
        )}
      </>
    ) : (
      <>{renderImages()}</>
    );
  };

  const renderUserImage = () => {
    return (
      <div className="profileImgOuter mx-auto">
        <input
          disabled={isEditable === false}
          type="file"
          accept={`image/*`}
          style={{ display: "none" }}
          id={`${props.formikKey}-${props.slug}`}
          onChange={e => {
            onInputChange(e, props.formikKey, props.associationKey, props.isArray, props.formikProps, props.slug);
          }}
        />
        <Button
          disabled={isEditable === false}
          btnText={null}
          icon={<IconCamera size="1.25em" />}
          className="p-2 userImgBtn"
          onClick={() => {
            document.getElementById(`${props.formikKey}-${props.slug}`).click();
          }}
        />
        {renderImages()}
      </div>
    );
  };

  const renderCardView = () => {
    return (
      <div className="card">
        <div className="card-header">
          <h5>{props.cardTitle ? props.cardTitle : "Card Title"}</h5>
        </div>
        <div className="card-body auction__sheet--fileuploader">
          <div className={`fileUploader ${fileUploaderClass}`}>{imageUpload()}</div>
        </div>
      </div>
    );
  };

  return <>{props.cardView ? renderCardView() : imageUpload()}</>;
};

export default ImageUploadWithPreview;
