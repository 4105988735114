import React from "react";
import { Tooltip, notify } from "components/ant-common";
import { t } from "i18next";
import { Fragment } from "react";
import theme from "./themeVars";
import moment from "moment";
import { configs } from "configs";

export const getAppLanguage = () => {
  const pathDirectories = window.location.pathname.split("/");
  if (pathDirectories.length > 1) {
    const pathLocale = pathDirectories[1];
    return configs.languages.find(e => e.key == pathLocale) || {};
  }
};

export const getErrorString = errorObj => {
  const errors = errorObj?.errors || errorObj?.error || errorObj?.message;
  if (errors) {
    if (Array.isArray(errors)) {
      const messageArray = errors.map(error => error.message);
      return messageArray.join(" ");
    }
    if (typeof errors === "string") return errors;
    if (typeof errors === "object")
      return Object.keys(errors)
        .map(key => errors[key])
        .join(", ");
  }
};

export const convertQueryObjToString = obj => {
  if (!obj) return "";
  const keys = Object.keys(obj);
  return keys
    .map(key => (obj[key] === null || obj[key] === undefined ? "" : `${key}=${obj[key]}`))
    .filter(e => !!e)
    .join("&");
};

export const filterCriteria = (inputValue, option, getOptionLabel) => {
  const normalizedInput = inputValue?.toLowerCase().trim();
  const label = getOptionLabel(option)?.toString()?.toLowerCase().trim();

  if (label?.includes(normalizedInput)) {
    return true;
  }
  const wordsInLabel = [];

  for (let i = 0; i < label?.length; i++) {
    if (label[i] === " ") {
      wordsInLabel.push(label.substring(i + 1, label.length));
    }
  }

  let found = false;
  wordsInLabel.forEach(word => {
    if (word?.includes(normalizedInput)) {
      found = true;
    }
  });

  return found;
};

export const filterParamsObject = (params, key, value) => {
  let paramsObj = { ...params };
  if (paramsObj.hasOwnProperty(key) && paramsObj?.staffType === value) delete paramsObj[key];
  return paramsObj;
};
export const removeEmptyKeysFromObject = obj => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v));
};

export const capitalizeFirstLetter = (str = "") => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};
export const capitalizetillNthLetter = (str = "", position) => {
  let tempStr = "";

  for (let x = 0; x < position; ++x) {
    tempStr = tempStr + str?.charAt(x).toLocaleUpperCase();
  }
  return tempStr + str?.slice(position);
};

export const convertToReadableString = (str = "", allCaps) => {
  const splitted = str?.split("_");
  return splitted?.map((e, i) => (allCaps || i == 0 ? capitalizeFirstLetter(e) : e))?.join(" ");
};

export const convertCamelCaseToReadable = inputString => {
  const camelCaseRegex = /[a-z]+|[A-Z][a-z]*/g;
  const words = inputString?.match(camelCaseRegex) || [];
  const result = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

  return result;
};
export const localisedValue = ({ object, key }) => {
  const language = getAppLanguage();
  const localizedKey = `${key}${language?.locale?.toUpperCase()}`;
  return object?.[localizedKey] ? object[localizedKey] : object?.[key];
};

export const mapQueryStringToFilterObject = (search, cb = () => {}) => {
  const queryObj = {};
  const queryString = search?.split("?")[1];
  if (queryString) {
    const items = queryString.split("&");
    for (const item of items) {
      const key = item?.split("=")[0];
      const value = item?.split("=")[1];
      cb(key, value);
      if (key && value) {
        if (queryObj[key]) {
          queryObj[key] += `,${value}`;
        } else {
          queryObj[key] = value;
        }
      }
    }
  }
  return { queryObj };
};

export const addQueryFilterArray = (filters, searchKey, value, splitString, cb) => {
  const prevVal = filters[searchKey];
  const prevValArr = prevVal ? prevVal.toString().split(splitString) : [];
  if (!value) {
    const filtersObj = { ...filters };
    filtersObj[searchKey] = undefined;
    cb(filtersObj);
  } else if (!prevValArr.includes(value.toString())) {
    const filtersObj = { ...filters };
    filtersObj[searchKey] = [...prevValArr, value].join(splitString);
    cb(filtersObj);
  }
};

export const addQueryFilterArrayPreSelected = (filters, searchKey, value, splitString, cb) => {
  const obj = { ...filters };
  if (obj[searchKey]) {
    const array = obj[searchKey].split(splitString);
    const index = array.indexOf(value.toString());
    if (array.length > 1) {
      array.splice(index, 1);
      obj[searchKey] = array.join(splitString);
    } else {
      delete obj[searchKey];
    }
  }
  obj.page && delete obj.page;
  cb(obj);
};

export const getUniqueArrayByObjKey = (array, key) => {
  return [
    ...new Map(array.map(item => [Array.isArray(key) ? key.map(e => item[e]).join("-") : item[key], item])).values(),
  ];
};

export const fileToBase64 = file => {
  return new Promise(resolve => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};
export const arrayBufferToBase64 = buffer => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const openMailTo = (email, subject = "", body = "", children) => {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;
  const mailTo = Array.isArray(email) ? email.join(";") : email;
  return window.open(`mailto:${mailTo}${params}`, "_blank");
};

export const getOBJValueByKey = (object, path, defval = null) => {
  if (!path) return defval;
  if (typeof path === "string") path = path?.split(".");
  const res = path.reduce(
    (obj, key) =>
      obj && key.includes("[") ? obj[key.substring(1, key.length - 1)] : obj && obj[key] ? obj[key] : defval,
    object
  );
  return res;
};

export const withTryCatch = async promise => {
  try {
    const response = await promise();
    return { response };
  } catch (error) {
    return { error: { message: getErrorString(error) } };
  }
};

const getCurrency = val => {
  switch (val) {
    default:
      return "Rs ";
  }
};

export const formatCompactedNumber = (num, digit) => {
  let cNumber;
  let absNum = Math.abs(num);
  switch (true) {
    case absNum >= 0 && absNum < 1000:
      cNumber = (absNum / 1).toFixed(absNum % 1 ? digit : 0);
      break;
    case absNum >= 1000 && absNum < 100000:
      cNumber = (absNum / 1000).toFixed((absNum / 1000) % 1 ? digit : 0) + t("Thousand");
      break;
    case absNum >= 100000 && absNum < 10000000:
      cNumber = (absNum / 100000).toFixed((absNum / 100000) % 1 ? digit : 0) + t("Lakh");
      break;
    case absNum >= 10000000 && absNum < 1000000000:
      cNumber = (absNum / 10000000).toFixed((absNum / 10000000) % 1 ? digit : 0) + t("Crore");
      break;
    case absNum >= 1000000000 && absNum < 100000000000:
      cNumber = (absNum / 1000000000).toFixed((absNum / 1000000000) % 1 ? digit : 0) + t("Arab");
      break;
    case absNum >= 100000000000:
      cNumber = (absNum / 100000000000).toFixed((absNum / 100000000000) % 1 ? digit : 0) + t("Kharab");
      break;
  }
  return num < 0 ? "-" + cNumber : cNumber;
};

export const numberFormat = (value, format, options) => {
  return new Intl.NumberFormat(format, options).format(value);
};

export const internationalFormatCompactedNumber = (value, digit) => {
  let cNumber = true;
  let absValue = Math.abs(value);
  switch (true) {
    case absValue > 0 && absValue < 1000:
      cNumber = parseFloat(absValue.toFixed(absValue % 1 ? digit : 0));
      break;
    case absValue >= 1000 && absValue < 1000000:
      cNumber = parseFloat((absValue / 1000).toFixed((absValue / 1000) % 1 ? digit : 0)) + " K";
      break;
    case absValue >= 1000000 && absValue < 1000000000:
      cNumber = parseFloat((absValue / 1000000).toFixed((absValue / 1000000) % 1 ? digit : 0)) + " M";
      break;
    case absValue >= 1000000000 && absValue < 1000000000000:
      cNumber = parseFloat((absValue / 1000000000).toFixed((absValue / 1000000000) % 1 ? digit : 0)) + " B";
      break;
    case absValue >= 1000000000000:
      cNumber = parseFloat((absValue / 1000000000000).toFixed((absValue / 1000000000000) % 1 ? digit : 0)) + " T";
      break;
    default:
      cNumber = absValue;
  }
  return value < 0 ? "-" + cNumber : cNumber;
};

export const formatPriceValue = (
  priceValue,
  currencyOptions,
  fractionDigits,
  settings = {
    locale: "en-PK",
    country_code: "PK",
    currency: "PKR",
    timezone: "Asia/Karachi",
  }
) => {
  if (!isNaN(Number(priceValue))) {
    if (currencyOptions?.notation == "compact")
      return getCurrency(settings.currency) + formatCompactedNumber(Number(priceValue), currencyOptions?.dashForNone);
    const fracDigits = !!fractionDigits
      ? Number(priceValue) % 1
        ? fractionDigits
        : 0
      : Number(priceValue) % 1
      ? 2
      : 0;
    let formattedPrice = new Intl.NumberFormat(settings.locale, {
      currency: settings.currency,
      style: "currency",
      currencyDisplay: "symbol",
      ...(fracDigits <= 2 && { minimumFractionDigits: 0 }),
      maximumFractionDigits: fracDigits,
      ...currencyOptions,
    }).format(priceValue);

    return formattedPrice;
  }
  return "";
};

export const joinBytItems = (array, separator) => {
  const filtered = array.filter(e => !!e);
  return filtered.map((e, i) => {
    let formattedElement = e;
    if (typeof e === "object" && e.label && e.value) {
      formattedElement = (
        <Fragment key={i + ""}>
          <span>{e.label}: </span>
          {e.value}
        </Fragment>
      );
    }
    if (typeof separator === "string") {
      return i + 1 < filtered.length ? formattedElement + separator : formattedElement;
    } else {
      return i + 1 < filtered.length ? (
        <Fragment key={i + ""}>
          {formattedElement}
          {separator}
        </Fragment>
      ) : (
        formattedElement
      );
    }
  });
};

export const getCountryList = (countries, international = false) => {
  if (countries) {
    const list = [...countries];
    const frequentList = [
      ...list.splice(
        list.findIndex(e => e.locCode === "PK"),
        1
      ),
      ...list.splice(
        list.findIndex(e => e.locCode === "AE"),
        1
      ),
      ...list.splice(
        list.findIndex(e => e.locCode === "MA"),
        1
      ),
    ];
    return [
      {
        label: t("Frequently Used"),
        options: international
          ? frequentList.concat({ label: t("International"), value: "international" })
          : frequentList,
      },
      { label: t("All"), options: list },
    ];
  }
  return [];
};

export const numberConvertor = (value, compact, digit) => {
  if (compact === "local") {
    return formatCompactedNumber(Number(value), digit);
  } else if (compact === "intl") {
    return internationalFormatCompactedNumber(Number(value), digit);
  } else {
    return Number(value);
  }
};

export const roundValues = (value, decimalPlaces) => Math.round(value, decimalPlaces);

export const convertToHMSString = value => {
  if (isNaN(Number(value))) {
    return value;
  }
  if (!value) {
    return "-";
  }
  let v = Number(value);
  const d = Math.floor(v / 86400);
  v -= d * 86400;
  const h = Math.floor(v / 3600);
  const m = Math.floor((v % 3600) / 60);
  const s = Math.floor((v % 3600) % 60);
  var daysDisplay = d > 0 ? d + (h == 0 ? "d " : "d, ") : "";
  var hDisplay = h > 0 ? h + (m == 0 ? "h " : "h, ") : "";
  var mDisplay = m > 0 ? m + (s == 0 ? "m " : "m ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
  // return daysDisplay + hDisplay + mDisplay + sDisplay
  return daysDisplay + hDisplay + mDisplay;
};

export const base64ToBlob = (base64String, contentType) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export function downloadPDF(pdf, name) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = `${name}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export const downloadFileFromLink = (url, key, src = "link") => {
  const link = document.createElement("a");
  if (src === "link") {
    link.href = url?.[key];
    link.download = url?.filename || url?.fileName;
  } else {
    link.href = `data:application/pdf;base64,${url?.data}`;
    link.download = url?.fileName;
  }
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isValidNICFormat = nic => {
  const regex = /^[0-9]{5}-[0-9]{7}-[0-9]$/;
  return regex.test(nic);
};
export const getSortedKeys = obj => {
  if (!obj || typeof obj != "object") return obj;
  const sortedObj = {};
  Object.keys(obj)
    .sort()
    .forEach(key => {
      sortedObj[key] = obj[key];
    });
  return sortedObj;
};

export const convertNumbersToWords = n => {
  if (isNaN(n) || n === "") {
    return "-";
  }

  if (n == 0) {
    return "Zero";
  }

  let isNegative = false;
  if (n < 0) {
    isNegative = true;
    n = Math.abs(n); // Convert negative number to positive
  }

  let word = "";
  const single_digit = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const double_digit = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const below_hundred = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

  function translate(n) {
    if (n < 10) {
      word = single_digit[n] + " ";
    } else if (n < 20) {
      word = double_digit[n - 10] + " ";
    } else if (n < 100) {
      const rem = translate(n % 10);
      word = below_hundred[Math.floor(n / 10)] + " " + rem;
    } else if (n < 1000) {
      word = single_digit[Math.trunc(n / 100)] + " Hundred " + translate(n % 100);
    } else if (n < 1000000) {
      word = translate(parseInt(n / 1000)).trim() + " Thousand " + translate(n % 1000);
    } else if (n < 1000000000) {
      word = translate(parseInt(n / 1000000)).trim() + " Million " + translate(n % 1000000);
    } else {
      word = translate(parseInt(n / 1000000000)).trim() + " Billion " + translate(n % 1000000000);
    }
    return word;
  }

  let result = translate(n);

  if (isNegative) {
    result = "Minus " + result; // Append "Minus" for negative numbers
  }

  return result.trim() + ".";
};

export const getFileType = filename => {
  return filename?.split(".").pop();
};

export const openGmail = selectedClients => {
  return window.open(
    `https://mail.google.com/mail/?view=cm&fs=1&su=SUBJECT&body=BODY&bcc=${selectedClients
      .map(client => client?.email)
      .toString()}`
  );
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const onS3Upload = async ({ file, getS3, updateS3, path }) => {
  let object = {};
  let fileObject = dataURLtoFile(file?.url, file?.name);
  const fileName = file.name;
  const s3Response = await getS3({ path: path({ fileName }) }).unwrap();
  if (s3Response) {
    await updateS3({ url: s3Response.url, file: fileObject })
      .unwrap()
      .then(() => {
        object = { key: s3Response.key, file: file, url: s3Response.url, fileObject: fileObject };
      })
      .catch(error => notify.error(error));
  }
  return object;
};

export const deleteKeysWithSubstring = (obj, substring) => {
  const newObj = {};
  for (const key in obj) {
    if (!key.includes(substring)) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

//TODO: NEEDS TO BE CHANGED FROM BE
export const includeChildren = (obj, keys) => {
  const result = [];
  const keySet = new Set(keys);

  const findKeysRecursively = node => {
    result.push(node.key);
    if (node.children) {
      node.children.forEach(child => findKeysRecursively(child));
    }
  };

  const checkChildren = children => {
    children.forEach(child => {
      if (keySet.has(child.key)) {
        findKeysRecursively(child);
      }
      if (child.children) {
        checkChildren(child.children);
      }
    });
  };

  obj.forEach(item => {
    if (keySet.has(item.key)) {
      findKeysRecursively(item);
    } else if (item.children) {
      checkChildren(item.children);
    }
  });

  return result;
};

export const openBase64 = filePreview => {
  var byteCharacters = atob(filePreview?.split(",")[1]);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var file = new Blob([byteArray], { type: filePreview?.split(",")[0].split(":")[1] });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

export const isNotImageCheck = file =>
  !["image/png", "image/jpeg", "png", "jpeg"].includes(
    getFileType(file.type || file.name || file.previewTitle || file.previewImage)
  );

export const isPDF = url => {
  return url?.endsWith(".pdf");
};

export const getAreaUnit = agency => {
  if (agency === "bayut_ksa") return "SqMt";
  return "SqFt";
};

export const convertToSpecificAreaUnit = (value, desiredUnit) => {
  const conversionTable = {
    sq_m: 0.092903,
    kanal: 0.000222222,
    sq_yd: 0.111111,
    sq_f: 1,
    marla: 0.00444444,
    sq_wah: 0.0232558,
    rai: 0.00005807,
    katha: 0.00138889,
  };

  if (!conversionTable.hasOwnProperty(desiredUnit)) {
    throw new Error(`Desired unit '${desiredUnit}' is not a valid unit.`);
  }

  const conversionFactor = conversionTable[desiredUnit];
  const convertedValue = value * conversionFactor;

  return convertedValue;
};
//Accepts an array of objects, each containing a 'name' attribute
export const getCompactCompositeString = (items = [], showCount) => {
  if (items.length > showCount) {
    let stringifiedUnits = items
      .slice(0, showCount)
      .map(e => localisedValue({ object: e, key: "name" }))
      .join(", ");

    let additionalUnits = items
      .slice(showCount)
      .map(e => localisedValue({ object: e, key: "name" }))
      .join(", ");

    let finalString = (
      <div>
        {stringifiedUnits}
        <span
          style={{
            color: theme["primary-color"],
            bg: theme["primary-light"],
            cursor: "pointer",
          }}
        >
          {" "}
          <Tooltip placement="top" title={additionalUnits}>
            +{items.length - showCount} {t("More")}
          </Tooltip>
        </span>
      </div>
    );
    return finalString;
  }
  return items.map(e => localisedValue({ object: e, key: "name" })).join(", ") || "-";
};

export const isWithinLast24Hours = date => {
  const now = moment().startOf("minute");
  const inputDate = moment(date).startOf("minute");
  const diffInMinutes = now.diff(inputDate, "minutes");
  const twentyFourHoursInMinutes = 24 * 60;
  return diffInMinutes <= twentyFourHoursInMinutes;
};
