import { ADD_AUCTION, FETCH_AUCTIONS, SET_AUCTIONS_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  statuses: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_AUCTION:
      return {
        ...state,
        list: [action.payload.item].concat([...state.list]),
      };

    // case UPDATE_AUCTION_USER:
    //   const userList = state.list;
    //   let index = userList.findIndex((e) => e.id === action.payload.item.id);
    //   userList.splice(index, 1, { ...action.payload.item });
    //   return {
    //     ...state,
    //     list: [...userList],
    //   };

    // case ARCHIVE_AUCTION_USER:
    //   const userListToDelete = state.list;
    //   let deleteIndex = userListToDelete.findIndex(
    //     (e) => e.id === action.payload.id
    //   );
    //   deleteIndex > -1 && userListToDelete.splice(deleteIndex, 1);
    //   return {
    //     ...state,
    //     list: [...userListToDelete],
    //   };
    case FETCH_AUCTIONS:
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
        list: action.payload.mergeList ? [...state.list].concat([...action.payload.list]) : [...action.payload.list],
        pagination: action.payload?.pagination ? { ...action.payload.pagination } : { ...state.pagination },
        statuses: action.payload.statuses,
        negotiation_statuses: action.payload.negotiation_statuses,
        permittedMeta: action.payload.permittedMeta,
      };

    case SET_AUCTIONS_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};
