import React from "react";
import cx from "clsx";
import { PropTypes } from "prop-types";
import styles from "./index.module.scss";

const Flex = props => {
  const { children, className, asChild, align, justify, style, ...rest } = props;

  const flexStyles = {
    "--flex-display": rest.inline && "inline-flex",
    "--flex-align": align,
    "--flex-justify": justify,
    "--flex-gap": rest.gap,
    "--flex": rest.flex,
    "--flex-wrap": rest.wrap === false && "nowrap",
    "--flex-direction": rest.vertical ? "column" : "row",
  };

  return (
    <div className={cx(styles.flex, className)} style={{ ...flexStyles, ...style }} {...rest}>
      {children}
    </div>
  );
};
export default Flex;

Flex.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  align: PropTypes.oneOf(["normal", "start", "center", "end", "stretch", "baseline", "initial"]),
  justify: PropTypes.oneOf([
    "normal",
    "start",
    "end",
    "center",
    "space-around",
    "space-between",
    "space-evenly",
    "stretch",
    "initial",
  ]),
  gap: PropTypes.string,
  style: PropTypes.object,
  flex: PropTypes.string,
  vertical: PropTypes.bool,
  wrap: PropTypes.bool,
};
