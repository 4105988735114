import { clone } from "../../helpers/Util";
import { FETCH_CHEVRON_OPTIONS, SET_CHEVRON_OPTIONS_LOADING } from "../../constant/actionTypes";

const INITIAL_STATE = {
  chevronLeadDispositions: [],
  chevronLeadCategoryTypes: [],
  optionsLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CHEVRON_OPTIONS:
      return clone({
        ...state,
        ...action.payload,
      });
    case SET_CHEVRON_OPTIONS_LOADING:
      return clone({
        ...state,
        optionsLoading: action.payload.optionsLoading,
      });
    default:
      return state;
  }
};
