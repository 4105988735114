import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactHowler from "react-howler";
import { PlayButton, Progress, Timer } from "react-soundplayer/components";
import "./AudioPlayer.scss";

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      currentTime: 0,
      speedup: false,
      loadErr: false,
      isSeeking: false,
      duration: null,
      audioReady: false, // New state variable
    };
    this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this);
    this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this);
    this.handleSeekingChange = this.handleSeekingChange.bind(this);
  }

  seek(secs, play) {
    if (secs && secs.seek != null) secs = secs.seek();
    this.player.seek(secs);
    let toSet = { currentTime: secs };
    if (play === true) toSet.audioPlayingId = true;
    toSet.pause = false;
    this.setState(toSet);
  }

  handleMouseDownSeek() {
    this.setState({
      isSeeking: true,
    });
  }

  handleMouseUpSeek(e) {
    this.setState({
      isSeeking: false,
    });

    this.player.seek(e.target.value);
  }

  handleSeekingChange(e) {
    this.setState({
      seek: parseFloat(e.target.value),
    });
  }

  toggleRate() {
    let { speedup } = this.state;
    speedup = !speedup;
    this.setState({ speedup });
    this.player._howler.rate(speedup ? 1.5 : 1.0);
  }

  getSeek() {
    if (this.playerInterval) clearInterval(this.playerInterval);
    this.playerInterval = setInterval(() => {
      if (this.player) {
        let currentTime = this.player.seek();
        const duration = this.player.duration();
        const toSet = { currentTime };
        if (!this.state.duration && duration != null) {
          toSet.duration = duration;
        }
        if (duration != null) toSet.loadErr = false;
        this.setState(toSet);
      }
    }, 250);
  }

  componentWillUnmount() {
    if (this.playerInterval) clearInterval(this.playerInterval);
    this.player = null;
    if (this?.props?.setAudioPlayingId && this?.props?.index) this.props.setAudioPlayingId(this.props.index, false);
  }

  componentDidMount() {
    if (this?.props?.setAudioPlayingId && this?.props?.index) this.props.setAudioPlayingId(this.props.index, false);

    this.setState({
      playing: false,
      currentTime: 0,
      speedup: false,
      loadErr: false,
      isSeeking: false,
      duration: null,
      audioReady: false,
    });
    this.getSeek();
  }

  isObject(obj) {
    return obj instanceof Object || (typeof obj === "object" && obj !== null);
  }

  render() {
    const { mp3url, index, audioPlayingId, setAudioPlayingId, item = null } = this.props || {};
    let { playing, currentTime, duration, speedup, loadErr, pause, audioReady } = this.state;
    if (this.isObject(currentTime)) currentTime = 0;

    return (
      <div className="ff-audio">
        <div>
          {this.props.canSpeedUp && (
            <button
              className={"playback-speed"}
              onClick={event => {
                event.preventDefault();
                this.toggleRate();
              }}
            >
              {speedup ? "1.5x" : "1x"}
            </button>
          )}
          <PlayButton
            playing={audioPlayingId}
            onTogglePlay={() => {
              if (!audioPlayingId) {
                setAudioPlayingId(index, true);
              } else {
                setAudioPlayingId(index, false);
              }
            }}
            className="flex-none button button-transparent button-grow rounded"
          />
        </div>
        <div className={"d-flex align-items-center"}>
          <ReactHowler
            src={mp3url}
            preload={false}
            playing={audioPlayingId}
            loop={false}
            onLoadError={(id, err) => {
              this.setState({ loadErr: (err && err.message) || "Startup error" });
            }}
            onEnd={() => setAudioPlayingId(false)}
            onLoad={() => {
              this.setState({ audioReady: true });
              this.getSeek();
            }}
            ref={ref => (this.player = ref)}
          />
          <Progress
            className={`flex-1 rounded`}
            innerClassName="progress"
            value={((currentTime || 0) / (duration || 1)) * 100 || 0}
            onSeekTrack={ts => {
              if (this.player) {
                this.player.stop();
                this.seek(ts * duration, true);
                // this.player.play();
              }
            }}
          />
          <Timer className={"timer"} duration={item?.duration} currentTime={currentTime != null ? currentTime : 0} />
        </div>
      </div>
    );
  }
}

AudioPlayer.propTypes = {
  mp3url: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  audioPlayingId: PropTypes.bool.isRequired,
  setAudioPlayingId: PropTypes.func.isRequired,
  canSpeedUp: PropTypes.bool,
  item: PropTypes.object,
};

export default AudioPlayer;
