import { MdAdd } from "../svg-icon/svg-icons";
import React from "react";

export const BtnDefaultState = ({
  className = "",
  Icon = MdAdd,
  btnText = "Select",
  errorMsg,
  iconStyle,
  ...props
}) => {
  return (
    <>
      <button className={`${className} ${"btn btn-skeleton media align-items-center"}`.trim()} type="button" {...props}>
        <div
          className="media-icon simple bordered"
          style={{ pointerEvents: props.disabled ? "none" : "", ...iconStyle }}
        >
          <Icon />
        </div>
        <div className="text">{btnText}</div>
      </button>
      {errorMsg && <p className={"invalid-error "}>{errorMsg}</p>}
    </>
  );
};
