import {
  AUCTION_MATRIX_FILTER,
  FETCH_AUCTION_STATUSES_INFO_DASHBOARD,
  FETCH_ENDING_SOON_AUCTIONS,
  FETCH_INVENTORIES_STATUSES_INFO_DASHBOARD,
  SET_STATUS_AUCTION_FILTERS,
  SET_STATUS_INVENTORIES_FILTERS,
  SET_TOP_CITIES_AUCTION_FILTERS,
  UPDATE_STATUSES_DATA_FOR_AUCTION_DOGHNUT,
} from "../../constant/actionTypes";
import moment from "moment";

export const INITIAL_STATE = {
  auctions_statistics: [],
  loading_auctions_statistics: true,
  error_auctions_statistics: "",
  auctions_statistics_filters: {
    cityId: [],
    // auctionId: "-1",
    timeFilters: {},
  },

  inventories_statistics: [],
  loading_inventories_statistics: true,
  error_inventories_statistics: "",
  inventories_statistics_filters: {
    cityId: [],
    // auctionId: "-1",
    timeFilters: {},
  },

  auctions_top_cities_filters: {
    statusId: 0,
    byTime: "today_on",
    timeType: "created_at",
  },
  statuses: [
    {
      slug: -1,
      auction_filterable_column: "created_at",
      label: "All",
    },
  ],

  auctionMatrixFilter: {
    "q[created_at_gteq]": moment().subtract(15, "day").format(),
    "q[created_at_lt]": moment().format(),
  },
  timeTypes: [
    { slug: "today_on", label: "Today" },
    { slug: "yesterday_on", label: "Yesterday" },
    { slug: "weekly_on", label: "Weekly" },
    { slug: "monthly_on", label: "Monthly" },
  ],
  ending_soon_auctions: [],
  loading_ending_soon_auctions: true,
  error_ending_soon_auctions: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_AUCTION_STATUSES_INFO_DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_INVENTORIES_STATUSES_INFO_DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_ENDING_SOON_AUCTIONS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_STATUS_AUCTION_FILTERS:
      return {
        ...state,
        ...{
          auctions_statistics_filters: {
            ...state.auctions_statistics_filters,
            ...action.payload.filters,
          },
        },
      };
    case SET_STATUS_INVENTORIES_FILTERS:
      return {
        ...state,
        ...{
          inventories_statistics_filters: {
            ...state.inventories_statistics_filters,
            ...action.payload.filters,
          },
        },
      };
    case SET_TOP_CITIES_AUCTION_FILTERS:
      return {
        ...state,
        ...{
          auctions_top_cities_filters: {
            ...state.auctions_top_cities_filters,
            ...action.payload.filters,
          },
        },
      };
    case UPDATE_STATUSES_DATA_FOR_AUCTION_DOGHNUT:
      return {
        ...state,
        ...{
          statuses: [
            {
              slug: -1,
              auction_filterable_column: "created_at",
              label: "All",
            },
            ...action.payload.statuses,
          ],
        },
      };
    case AUCTION_MATRIX_FILTER: {
      return {
        ...state,
        ...{
          auctionMatrixFilter: {
            ...state.auctionMatrixFilter,
            ...action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
};
