import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Skeleton, Status, Toast } from "../common";

import { checkUndefinedApiParams, getErrorString } from "../../helpers/Util";
import { strings } from "../../constant/strings";
import { updateTaskApi } from "../../helpers/ApiHelper";
import { useSelector } from "react-redux";

const AssignStatusInput = ({ status, style, onChange, taskId, onSuccess, loading, label }) => {
  const options = useSelector(content => content.CRMLeadDetail.task_statuses);
  const [open, setOpen] = useState(false);
  const [selectedStatus, setStatus] = useState(status);

  useEffect(() => {
    if (status) {
      setStatus(options.find(e => e.id === status.id) || status);
    }
  }, [options, status]);

  const postData = async e => {
    if (!checkUndefinedApiParams(taskId, "task")) return;
    let obj = {
      task: {
        status_id: e.id,
      },
    };
    const response = await updateTaskApi(taskId, obj);
    if (response) {
      if (response.task) {
        onSuccess && onSuccess(e, response.task);
        setStatus(e);
      } else {
        Toast.error(getErrorString(response));
      }
    }
  };

  const renderComponent = () =>
    loading ? (
      <Skeleton height={40} />
    ) : (
      <Dropdown
        className="dropdown-status"
        isOpen={open}
        toggle={() => setOpen(prevState => !prevState)}
        direction="down"
      >
        <DropdownToggle
          className="btn btn-pill badge-status btn-sm"
          caret={!!options?.length}
          style={{
            "--bg": selectedStatus?.bg_color,
            "--color": selectedStatus?.color,
          }}
        >
          {selectedStatus ? selectedStatus.name : strings.unassigned}
        </DropdownToggle>

        <DropdownMenu container={onChange ? undefined : "body"}>
          {options &&
            options
              .filter(e => e.id !== selectedStatus?.id)
              .map((e, i) => (
                <DropdownItem
                  key={i}
                  onClick={() => {
                    if (onChange) {
                      setStatus(e);
                      onChange(e);
                    } else {
                      postData(e);
                    }
                  }}
                >
                  <Status {...e} />
                </DropdownItem>
              ))}
        </DropdownMenu>
      </Dropdown>
    );

  return label ? (
    <div className="form-group row">
      <label className="col-sm-3 col-form-label">{loading ? <Skeleton height={20} /> : label}</label>
      <div className="col-sm-9">{renderComponent()}</div>
    </div>
  ) : (
    renderComponent()
  );
};

export default AssignStatusInput;
