import React from "react";
import { strings } from "../../../constant/strings";
import { IconSquareRounded } from "../../../components/svg-icon/svg-icons";
import { CircularProgressbarWithChildren as CircularProgressbar } from "react-circular-progressbar";
import styles from "./index.module.scss";
import cx from "clsx";
import { QCQualityAnalysisSkeleton } from "./QCSkeletons";

const listQcAnalysis = [
  {
    key: "good",
    text: "Good (8-10)",
    color: "#51CC81",
    stroke: "#51CC81",
    fill: "#51CC81",
  },
  {
    key: "average",
    text: "Average (4-7)",
    color: "#f0b122",
    stroke: "#f0b122",
    fill: "#f0b122",
  },
  {
    key: "poor",
    text: "Poor (0-3)",
    color: "#d95454",
    fill: "#d95454",
  },
];

const getFillColorBasedOnScore = score => {
  if (score >= 8) {
    return "#51CC81";
  } else if (score >= 4) {
    return "#f0b122";
  } else {
    return "#d95454";
  }
};

const CallOverallQualityProgress = props => {
  const { callQualityScore, qcStatsLoading } = props;

  if (qcStatsLoading) return <QCQualityAnalysisSkeleton qcStatsLoading={qcStatsLoading} />;

  const qualityScore = (callQualityScore / 10).toLocaleString("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <div className="card">
      <div className={"card-body"}>
        <h5 className="f-w-600 mb-3">{strings.overall_quality_analysis}</h5>
        <div className="d-flex justify-content-between align-items-center" style={{ gap: 32 }}>
          <ul>
            {listQcAnalysis.map(e => (
              <li className="m-b-10 f-16 d-flex align-items-baseline" style={{ gap: 8 }} key={e.key}>
                <IconSquareRounded style={{ "--icon-size": ".8em" }} {...e} />
                <span className={styles.progressBarLabel}>{e.text}</span>
              </li>
            ))}
          </ul>

          <div style={{ width: 180, height: 180 }}>
            <CircularProgressbar
              value={qualityScore}
              text={`${qualityScore}/10`}
              strokeWidth="6"
              maxValue={10}
              minValue={0}
              styles={{
                path: {
                  stroke: getFillColorBasedOnScore(qualityScore), // Dynamic stroke color
                },
                trail: {
                  stroke: "#f0f0f0",
                },
                text: {
                  fill: "currentColor",
                  fontSize: 16,
                  fontWeight: 600,
                  letterSpacing: -0.5,
                  textAnchor: "middle",
                },
              }}
            >
              <div className={cx("fs-12 f-w-500 ", styles.progressBarLabel)} style={{ marginTop: 32 }}>
                {strings.overall_quality}
              </div>
            </CircularProgressbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallOverallQualityProgress;
