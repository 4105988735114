import { Carousel, Close, IF, Toast } from "../../common";
import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  checkUndefinedApiParams,
  getErrorString,
  isEmptyArray,
  isFieldViewable,
  setTabForRescheduledMeeting,
} from "../../../helpers/Util";
import { connect, useSelector } from "react-redux";
import { AddMeetingForm } from "..";
import { IoMdAdd } from "../../svg-icon/svg-icons";
import MeetingCard from "./MeetingCard";
import { strings } from "../../../constant/strings";
import { updateTaskApi } from "../../../helpers/ApiHelper";
import { NON_RESCHEDULABLE_STATUSES, TASK_MEETING_TYPES } from "constant/appConstants";
import { updateTaskMeetings } from "../../../actions/crm";

const MeetingFormFields = props => {
  const {
    showMeetingCalender,
    values,
    errors,
    setFieldValue,
    timeZone,
    taskId,
    disabled,
    tabIndex,
    setTabIndex,
    setFieldError,
    validateOnNewMeeting,
    onAppointmentUpdate,
    addAppointment,
    taskFrom,
    crmTaskPermittedAttributes,
    updateTaskMeetings,
    addressesTab,
    setAddressesTab,
  } = props;
  const { meetings, newMeeting } = values;
  const [showForm, setShowForm] = useState(false);
  const [saveMeetingLoading, setSaveMeetingLoading] = useState(false);
  const [rescheduleMeeting, setRescheduleMeeting] = useState(false);
  const [selectInventoryError, setSelectInventoryError] = useState("");
  const hideMeetingButton =
    props.taskId && values.task_type?.restricted_inventory_statuses.includes(values?.inventory?.status?.slug);

  const meetingsTabs = [
    {
      id: 0,
      name:
        isFieldViewable(crmTaskPermittedAttributes?.["appointments.time_slot.address"]) &&
        TASK_MEETING_TYPES.SELLER_ADDRESS,
    },
    { id: 1, name: isFieldViewable(crmTaskPermittedAttributes?.["appointments.area.id"]) && TASK_MEETING_TYPES.AREA },
  ];

  const options = useSelector(content => content.TaskDetail.appointment_statuses);

  useEffect(() => {
    newMeeting && !(typeof newMeeting?.id === "number") && setShowForm(true);
    if (!newMeeting) {
      setShowForm(false);
      setAddressesTab(false);
    }
  }, [newMeeting, newMeeting?.id]);

  useEffect(() => {
    // Meeting start datetime as default due_date
    !taskId && setFieldValue("due_date", values.newMeeting?.start_time);
  }, [values.newMeeting?.start_time]);

  useEffect(() => {
    if (!taskId && values.newMeeting) {
      setFieldValue("newMeeting", null);
    }
  }, [values.inventory]);

  const postMeetings = async (meeting, statusUpdate = false) => {
    if (!checkUndefinedApiParams(taskId, "task")) return;
    const obj = {
      task: {
        appointments_attributes: props.makeAppointmentData(meeting, statusUpdate, values),
      },
    };
    setSaveMeetingLoading(true);
    const response = await updateTaskApi(taskId, obj);
    if (response) {
      setSaveMeetingLoading(false);
      if (response.task) {
        showForm && setShowForm(false);
        !statusUpdate && setFieldValue("newMeeting", null);
        statusUpdate && onAppointmentUpdate && onAppointmentUpdate(response.task);
        !statusUpdate && addAppointment && addAppointment(response.task.appointments[0]);
        onMeetingSave(response.task.appointments);
        updateTaskMeetings(taskId, response.task?.appointments);
        setRescheduleMeeting(false);
      } else {
        meetings?.length && meetings.shift();
        setFieldValue("meetings", meetings);
        Toast.error(getErrorString(response));
      }
    }
  };

  const onAppointmentAssigneeUpdate = (updatedMeetings = []) => {
    if (!isEmptyArray(updatedMeetings)) {
      onMeetingSave(updatedMeetings);
    }
  };

  const onMeetingSave = appointments => {
    setFieldValue(
      "meetings",
      appointments.map(meeting => ({
        ...meeting,
        meeting_area: meeting.area,
        comments: meeting.description,
      }))
    );
  };

  const onCloseMeetingForm = () => {
    setFieldValue("newMeeting", null);
    setShowForm(false);
    setAddressesTab(false);
    setRescheduleMeeting(false);
    setTabIndex(0);
  };

  const onChangeTabIndex = index => {
    values.newMeeting && setFieldValue("newMeeting", {});
    setTabIndex(index);
    setAddressesTab(true);
    setShowForm(true);
  };

  const setErrorValues = (assigneeError, inventoryError) => {
    setSelectInventoryError(inventoryError);
  };

  const onClickAddMeeting = (isRescheduledMeeting = false) => {
    if (!values.inventory) {
      setErrorValues("", strings.inventory_select_msg);
      return;
    } else {
      setErrorValues("", "");
      setAddressesTab(true);
      if (!isRescheduledMeeting) {
        setRescheduleMeeting(false);
        setTabIndex(0);
      }
    }
  };

  const onDoneMeeting = meeting => {
    const rescheduleStatus = options.find(option => option.slug === "rescheduled");
    if (rescheduleMeeting) {
      meeting.status = rescheduleStatus;
    }
    const meetingsArray = values.meetings || [];
    const meetingError = validateOnNewMeeting(!!newMeeting, meeting, setFieldError, "newMeeting");
    if (meetingError) return;
    meetingsArray.unshift(meeting);
    postMeetings(meeting);
  };

  const renderButton = ({ disabled }) => {
    return hideMeetingButton ? (
      <></>
    ) : (
      <>
        <button
          className="btn btn-pill btn-pill-icon-left btn-primary d-flex spbwx4 align-items-center"
          onClick={onClickAddMeeting}
          type="button"
          disabled={disabled}
        >
          <IoMdAdd size="1.4em" />
          <span>{strings.add_meeting}</span>
        </button>
        <IF condition={!values.inventory && selectInventoryError}>
          <p className="invalid-error">{selectInventoryError}</p>
        </IF>
      </>
    );
  };

  const renderForm = () => {
    const type = meetingsTabs[tabIndex]?.name;
    if (!type) return null;
    return (
      <div className="d-flex flex-column position-relative">
        <AddMeetingForm
          rescheduleMeeting={rescheduleMeeting}
          setRescheduleMeeting={setRescheduleMeeting}
          crmTaskPermittedAttributes={crmTaskPermittedAttributes}
          onDone={onDoneMeeting}
          values={values}
          timeZone={timeZone}
          newMeeting={values.newMeeting || {}}
          assignee={values.assignee}
          setFieldValue={setFieldValue}
          saveMeetingLoading={saveMeetingLoading}
          taskId={taskId}
          showMeetingCalender={showMeetingCalender}
          type={type}
          inventory={values.inventory}
          setTabIndex={setTabIndex}
          errors={
            errors?.meetings ? JSON.parse(errors?.meetings) : errors?.newMeeting ? JSON.parse(errors?.newMeeting) : ""
          }
        />
      </div>
    );
  };
  return (
    <IF condition={disabled === false || meetings?.length}>
      <div className="form-group row d-flex flex-row">
        <div className="col-sm-3 py-1">{strings.meetings}</div>
        <div className={"col-sm-9 mb-2"}>
          <div className={`${addressesTab ? "sidebar-add-meeting" : ""}`}>
            <IF condition={addressesTab}>
              <Close btnWidth="1.5em" btnHeight="1.5em" onClick={onCloseMeetingForm} />
              <div className="row theme-tab add-task-tab-list">
                <Tabs className="col-sm-12" selectedIndex={tabIndex} onSelect={onChangeTabIndex}>
                  <TabList className="tabs tab-title d-inline-flex mb-3">
                    {meetingsTabs?.map(item => (
                      <Tab key={item.id}>{item.name}</Tab>
                    ))}
                  </TabList>
                  <div className="tab-content-cls">
                    {meetingsTabs?.map(item => (
                      <TabPanel key={item.id}></TabPanel>
                    ))}
                  </div>
                </Tabs>
              </div>
            </IF>
            {addressesTab || showForm ? renderForm() : renderButton({ disabled })}
          </div>
        </div>
        <IF condition={!!meetings}>
          <div className="col-sm-9 offset-sm-3 m-t-20">
            <div className={`crm-task-meeting-slider ${meetings?.length > 1 ? "have-btns" : ""}`}>
              <Carousel disableButtonsControls disableDotsControls showButtons={meetings?.length > 1}>
                {meetings
                  ?.filter(item => item?.id !== rescheduleMeeting?.id)
                  ?.map(item => (
                    <MeetingCard
                      key={item?.start_time?.toString()}
                      meeting={item}
                      values={values}
                      taskOptions={options}
                      meetings={meetings}
                      disabled={disabled || NON_RESCHEDULABLE_STATUSES.includes(item?.status?.slug)}
                      timeZone={timeZone}
                      postMeetings={postMeetings}
                      showMeetingCalender={showMeetingCalender}
                      taskFrom={taskFrom}
                      onAppointmentUpdate={onAppointmentUpdate}
                      onAppointmentAssigneeUpdate={onAppointmentAssigneeUpdate}
                      crmTaskPermittedAttributes={crmTaskPermittedAttributes}
                      rescheduleMeeting={() => {
                        setRescheduleMeeting(item);
                        setTabForRescheduledMeeting(item, setTabIndex);
                        onClickAddMeeting(true);
                      }}
                    />
                  ))}
              </Carousel>
            </div>
          </div>
        </IF>
      </div>
    </IF>
  );
};

const mapStateToProps = ({ TaskDetail, Configs }) => {
  return {
    appointments: TaskDetail?.task?.appointments,
    timeZone: Configs.tenant.country.timezone,
  };
};

export default connect(mapStateToProps, { updateTaskMeetings: updateTaskMeetings })(MeetingFormFields);
