export const CONTRACT_TYPE_SLUGS = Object.freeze({
  VEHICLE_PURCHASE_AGREEMENT_RETAIL: "vehicle-purchase-agreement-retail",
  VEHICLE_SALE_AGREEMENT_AUCTION: "vehicle-sale-agreement-auction",
  VEHICLE_SALE_AGREEMENT_RETAIL: "vehicle-sale-agreement-retail",
  SELLER_ASSIST_AGREEMENT_MANAGED: "seller-assist-agreement-managed",
  BUYER_ASSIST_AGREEMENT_MANAGED: "buyer-assist-agreement-managed",
  CONCIERGE_SERVICE_AGREEMENT_BUYER: "concierge-service-agreement-buyer",
  SELLER_PURCHASE_INVOICE_RETAIL: "seller-purchase-invoice-retail",
  VAS_C2C_AGREEMENT: "vas-c2c-agreement",
  VEHICLE_RELEASE_AGREEMENT: "vehicle-release-agreement",
  SELLER_TRANSFER_AGREEMENT_MANAGED: "seller-transfer-agreement-managed",
  WAIVER_OF_LIABILITY_MANAGED: "waiver-of-liability-managed",
  SELLER_DECLARATION_AGREEMENT_MANAGED: "seller-declaration-agreement-managed",
});

//later add isLeadDeatailRequired and isContractDetailRequired
export const contractTypeDetailsMetaData = {
  [CONTRACT_TYPE_SLUGS.SELLER_DECLARATION_AGREEMENT_MANAGED]: {
    contract: { required: false },
    client: { required: true },
    inventory: {
      required: true,
      editable: true,
      disabled_static_fields: [],
      disabled_dynamic_fields: [],
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_PURCHASE_AGREEMENT_RETAIL]: {
    contract: { required: false },
    client: { required: true },
    inventory: {
      required: true,
      editable: true,
      disabled_static_fields: [],
      disabled_dynamic_fields: [],
    },
  },
  [CONTRACT_TYPE_SLUGS.WAIVER_OF_LIABILITY_MANAGED]: {
    contract: { required: false },
    client: { required: true },
    inventory: {
      required: true,
      editable: true,
      disabled_static_fields: [],
      disabled_dynamic_fields: [],
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_SALE_AGREEMENT_AUCTION]: {
    contract: { required: false },
    client: { required: true },
    inventory: { required: true, editable: true, disabled_static_fields: [], disabled_dynamic_fields: [] },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_SALE_AGREEMENT_RETAIL]: {
    contract: { required: false },
    client: { required: true },
    inventory: {
      required: true,
      editable: true,
      inventory: { required: true, editable: true, disabled_static_fields: [], disabled_dynamic_fields: [] },
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_ASSIST_AGREEMENT_MANAGED]: {
    contract: { required: false },
    client: { required: true },
    inventory: { required: true, editable: true, disabled_static_fields: [], disabled_dynamic_fields: [] },
  },
  [CONTRACT_TYPE_SLUGS.BUYER_ASSIST_AGREEMENT_MANAGED]: {
    contract: { required: false },
    client: { required: true },
    inventory: { required: true, editable: true, disabled_static_fields: [], disabled_dynamic_fields: [] },
  },
  [CONTRACT_TYPE_SLUGS.CONCIERGE_SERVICE_AGREEMENT_BUYER]: {
    contract: { required: true },
    client: { required: true },
    inventory: { required: false, editable: false, disabled_static_fields: [], disabled_dynamic_fields: [] },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_PURCHASE_INVOICE_RETAIL]: {
    contract: { required: false },
    client: { required: true },
    inventory: { required: true, editable: true, disabled_static_fields: [], disabled_dynamic_fields: [] },
  },
  [CONTRACT_TYPE_SLUGS.VAS_C2C_AGREEMENT]: {
    contract: { required: false },
    client: { required: true },
    inventory: { required: true, editable: true, disabled_static_fields: [], disabled_dynamic_fields: [] },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_RELEASE_AGREEMENT]: {
    contract: { required: false },
    client: { required: true },
    inventory: { required: true, editable: true, disabled_static_fields: [], disabled_dynamic_fields: [] },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_TRANSFER_AGREEMENT_MANAGED]: {
    contract: { required: true },
    client: { required: true },
    inventory: {
      required: true,
      editable: true,
      disabled_static_fields: [],
      disabled_dynamic_fields: ["success_fee_due", "seller_deductions", "seller_net_amount", "loan_clearance_fees"],
    },
  },
};

export const contractMetaData = {
  [CONTRACT_TYPE_SLUGS.CONCIERGE_SERVICE_AGREEMENT_BUYER]: {
    dynamicAttributeKeys: {
      emirates_id_number: true,
      vehicle_requirements: true,
      concierge_service: true,
      concierge_service_charges: true,
      auto_financing_buyer_concierge: true,
      car_insurance_buyer_concierge: true,
      bank_evaluation_report_buyer_concierge: true,
      car_inspection_buyer_concierge: true,
      extended_warranty_buyer_concierge: true,
      service_contract_buyer_concierge: true,
      value_added_service_fee: true,
    },
    staticAttributeKeys: {},
  },
  [CONTRACT_TYPE_SLUGS.SELLER_TRANSFER_AGREEMENT_MANAGED]: {
    dynamicAttributeKeys: {
      others_adjustment: true,
      buyer_seller_cash_exchange: true,
      balance_payment: true,
      success_fee_inclusive_vat: true,
    },
    staticAttributeKeys: {},
  },
};

export const contractTypeMetaDataPartyDetails = {
  [CONTRACT_TYPE_SLUGS.SELLER_DECLARATION_AGREEMENT_MANAGED]: {
    dynamicAttributeKeys: {},
    staticAttributeKeys: { name: true, address: true },
  },
  [CONTRACT_TYPE_SLUGS.WAIVER_OF_LIABILITY_MANAGED]: {
    dynamicAttributeKeys: {},
    staticAttributeKeys: { name: true, email: true },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_PURCHASE_AGREEMENT_RETAIL]: {
    dynamicAttributeKeys: {
      company_registered_in: true,
      license_no: true,
      traffic_file_code: true,
      company_number: true,
      tax_reg_no: true,
      designation: true,
      authorised_representative: true,
    },
    staticAttributeKeys: {
      name: true,
      address: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_SALE_AGREEMENT_AUCTION]: {
    dynamicAttributeKeys: {
      company_number: true,
      company_registered_in: true,
      license_no: true,
      traffic_file_code: true,
      tax_reg_no: true,
      designation: true,
      authorised_representative: true,
    },
    staticAttributeKeys: {
      name: true,
      address: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_SALE_AGREEMENT_RETAIL]: {
    dynamicAttributeKeys: {
      company_registered_in: true,
      license_no: true,
      traffic_file_code: true,
      tax_reg_no: true,
      company_number: true,
      designation: true,
      authorised_representative: true,
    },
    staticAttributeKeys: {
      name: true,
      address: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_ASSIST_AGREEMENT_MANAGED]: {
    dynamicAttributeKeys: {
      id_number: true,
      company_number: true,
      designation: true,
      authorised_representative: true,
      company_registered_in: true,
    },
    staticAttributeKeys: {
      name: true,
      address: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.BUYER_ASSIST_AGREEMENT_MANAGED]: {
    dynamicAttributeKeys: {
      company_registered_in: true,
      company_number: true,
      id_number: true,
      designation: true,
      authorised_representative: true,
    },
    staticAttributeKeys: {
      name: true,
      address: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.CONCIERGE_SERVICE_AGREEMENT_BUYER]: {
    dynamicAttributeKeys: {},
    staticAttributeKeys: {
      name: true,
      address: true,
      email: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_PURCHASE_INVOICE_RETAIL]: {
    dynamicAttributeKeys: {
      customer_trn: true,
    },
    staticAttributeKeys: {
      name: true,
      address: true,
      email: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VAS_C2C_AGREEMENT]: {
    staticAttributeKeys: {
      name: true,
      email: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_RELEASE_AGREEMENT]: {
    staticAttributeKeys: {
      name: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_TRANSFER_AGREEMENT_MANAGED]: {
    staticAttributeKeys: {
      name: true,
      address: true,
      email: true,
    },
  },
};

export const contractTypeMetaDataPartyDetailsForStaticFields = {
  [CONTRACT_TYPE_SLUGS.SELLER_DECLARATION_AGREEMENT_MANAGED]: {
    staticAttributeKeys: {
      "client.name": true,
      "client.address": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.WAIVER_OF_LIABILITY_MANAGED]: {
    staticAttributeKeys: {
      "client.name": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_PURCHASE_AGREEMENT_RETAIL]: {
    staticAttributeKeys: {
      "client.name": true,
      "client.address": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_SALE_AGREEMENT_AUCTION]: {
    staticAttributeKeys: {
      "client.name": true,
      "client.address": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_SALE_AGREEMENT_RETAIL]: {
    staticAttributeKeys: {
      "client.name": true,
      "client.address": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_ASSIST_AGREEMENT_MANAGED]: {
    staticAttributeKeys: {
      "client.name": true,
      "client.address": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.BUYER_ASSIST_AGREEMENT_MANAGED]: {
    staticAttributeKeys: {
      "client.name": true,
      "client.address": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.CONCIERGE_SERVICE_AGREEMENT_BUYER]: {
    staticAttributeKeys: {
      "client.name": true,
      "client.address": true,
      "client.email": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_PURCHASE_INVOICE_RETAIL]: {
    staticAttributeKeys: {
      "client.name": true,
      "client.address": true,
      "client.email": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VAS_C2C_AGREEMENT]: {
    staticAttributeKeys: {
      "client.name": true,
      "client.email": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_RELEASE_AGREEMENT]: {
    staticAttributeKeys: {
      "client.name": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_TRANSFER_AGREEMENT_MANAGED]: {
    staticAttributeKeys: {
      "client.name": true,
      "client.address": true,
      "client.email": true,
    },
  },
};

export const contractTypeMetaDataVehicleInfo = {
  [CONTRACT_TYPE_SLUGS.SELLER_DECLARATION_AGREEMENT_MANAGED]: {
    dynamicAttributeKeys: {
      vehicle_identification_number: true,
      seller_deposit_amount: true,
    },
    staticAttributeKeys: { make: true, model: true, variant: true, year: true, mileage: true },
  },
  [CONTRACT_TYPE_SLUGS.WAIVER_OF_LIABILITY_MANAGED]: {
    dynamicAttributeKeys: {
      vehicle_identification_number: true,
    },
    staticAttributeKeys: { make: true, model: true, variant: true },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_PURCHASE_AGREEMENT_RETAIL]: {
    dynamicAttributeKeys: {
      mortgage_notes: true,
      vehicle_identification_number: true,
      car_cylinders: true,
      purchase_price: true,
    },
    staticAttributeKeys: {
      registrationNumber: true,
      make: true,
      model: true,
      variant: true,
      year: true,
      engineCapacity: true,
      mileage: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_SALE_AGREEMENT_AUCTION]: {
    dynamicAttributeKeys: {
      mortgage_notes: true,
      car_cylinders: true,
      vehicle_identification_number: true,
      purchase_price: true,
      // success_fee_exclusive_vat: true,
    },
    staticAttributeKeys: {
      registrationNumber: true,
      make: true,
      model: true,
      variant: true,
      mileage: true,
      year: true,
      engineCapacity: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_SALE_AGREEMENT_RETAIL]: {
    dynamicAttributeKeys: {
      seller_net_amount: true,
      deposit_amount_aed: true,
      no_of_keys: true,
      vehicle_identification_number: true,
      // success_fee_exclusive_vat: true,
    },
    staticAttributeKeys: {
      make: true,
      model: true,
      variant: true,
      year: true,
      mileage: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_ASSIST_AGREEMENT_MANAGED]: {
    dynamicAttributeKeys: {
      vehicle_identification_number: true,
      car_last_price: true,
      contracts_success_fee: true,
      sign_up_fees: true,
      initial_ask_price: true,
    },
    staticAttributeKeys: {
      make: true,
      model: true,
      variant: true,
      year: true,
      mileage: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_PURCHASE_INVOICE_RETAIL]: {
    dynamicAttributeKeys: {
      purchase_price: true,
      purchase_date: true,
      vat_applicable: true,
      vehicle_identification_number: true,
    },
    staticAttributeKeys: {
      make: true,
      model: true,
      variant: true,
      year: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.BUYER_ASSIST_AGREEMENT_MANAGED]: {
    dynamicAttributeKeys: {
      no_of_keys: true,
      seller_net_amount: true,
      deposit_amount_aed: true,
      vehicle_identification_number: true,
      managed_service_fee: true,
      extended_warranty: true,
      car_service_contract: true,
      third_party_service_fee: true,
    },
    staticAttributeKeys: {
      make: true,
      model: true,
      variant: true,
      year: true,
      mileage: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VAS_C2C_AGREEMENT]: {
    staticAttributeKeys: {
      make: true,
      model: true,
      variant: true,
      year: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_RELEASE_AGREEMENT]: {
    dynamicAttributeKeys: {
      no_of_keys: true,
      vehicle_identification_number: true,
    },
    staticAttributeKeys: {
      make: true,
      model: true,
      variant: true,
      year: true,
      mileage: true,
      exteriorColor: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_TRANSFER_AGREEMENT_MANAGED]: {
    dynamicAttributeKeys: {
      success_fee_due: true,
      seller_deductions: true,
      seller_net_amount: true,
      loan_clearance_fees: true,
    },
    staticAttributeKeys: {
      make: true,
      model: true,
      variant: true,
      year: true,
      registrationNumber: true,
    },
  },
};

export const contractTypeMetaDataVehicleInfoForStaticFields = {
  [CONTRACT_TYPE_SLUGS.SELLER_DECLARATION_AGREEMENT_MANAGED]: {
    staticAttributeKeys: {
      "make.name": true,
      "model.name": true,
      "variant.name": true,
      model_year: true,
      mileage: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.WAIVER_OF_LIABILITY_MANAGED]: {
    staticAttributeKeys: {
      "make.name": true,
      "model.name": true,
      "variant.name": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_PURCHASE_AGREEMENT_RETAIL]: {
    staticAttributeKeys: {
      registration_no: true,
      "make.name": true,
      "model.name": true,
      "variant.name": true,
      model_year: true,
      engine_capacity: true,
      mileage: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_SALE_AGREEMENT_AUCTION]: {
    staticAttributeKeys: {
      registration_no: true,
      "make.name": true,
      "model.name": true,
      "variant.name": true,
      model_year: true,
      engine_capacity: true,
      mileage: true,
      "inventory.ask_price": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_SALE_AGREEMENT_RETAIL]: {
    staticAttributeKeys: {
      "make.name": true,
      "model.name": true,
      "variant.name": true,
      model_year: true,
      mileage: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_ASSIST_AGREEMENT_MANAGED]: {
    staticAttributeKeys: {
      "make.name": true,
      "model.name": true,
      "variant.name": true,
      model_year: true,
      mileage: true,
      "inventory.ask_price": true,
    },
  },
  [CONTRACT_TYPE_SLUGS.BUYER_ASSIST_AGREEMENT_MANAGED]: {
    staticAttributeKeys: {
      "make.name": true,
      "model.name": true,
      "variant.name": true,
      model_year: true,
      mileage: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VAS_C2C_AGREEMENT]: {
    staticAttributeKeys: {
      "make.name": true,
      "model.name": true,
      "variant.name": true,
      model_year: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.VEHICLE_RELEASE_AGREEMENT]: {
    staticAttributeKeys: {
      "make.name": true,
      "model.name": true,
      "variant.name": true,
      "exterior_color.name": true,
      model_year: true,
      mileage: true,
    },
  },
  [CONTRACT_TYPE_SLUGS.SELLER_TRANSFER_AGREEMENT_MANAGED]: {
    staticAttributeKeys: {
      "make.name": true,
      "model.name": true,
      "variant.name": true,
      model_year: true,
    },
  },
};

export const nonMandatoryContractFields = {
  party: {
    id_number: true,
    company_registered_in: true,
    company_number: true,
    tax_reg_no: true,
    authorised_representative: true,
    designation: true,
    customer_trn: true,
  },
  vehicle: {
    car_last_price: true,
    extended_warranty: true,
    car_service_contract: true,
    vat_applicable: true,
    loan_clearance_fees: true,
    seller_deductions: true,
  },
  contract: {
    emirates_id_number: true,
    vehicle_requirements: true,
    auto_financing_buyer_concierge: true,
    car_insurance_buyer_concierge: true,
    bank_evaluation_report_buyer_concierge: true,
    car_inspection_buyer_concierge: true,
    extended_warranty_buyer_concierge: true,
    service_contract_buyer_concierge: true,
    value_added_service_fee: true,
    others_adjustment: true,
    buyer_seller_cash_exchange: true,
  },
};

export const contractTypeDynamicAttributesDisableMetaData = {
  [CONTRACT_TYPE_SLUGS.SELLER_TRANSFER_AGREEMENT_MANAGED]: {
    balance_payment: true,
    success_fee_inclusive_vat: true,
  },
};
