import {
  CLEAR_CRM_CONTRACT,
  FETCH_CRM_CONTRACTS,
  UPDATE_CRM_CONTRACT,
  UPDATE_CRM_CONTRACT_EMAIL,
  SET_CRM_CONTRACTS_LOADING,
} from "../../constant/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: "",
  pagination: {
    current_page: null,
    next_page: null,
    prev_page: null,
    total_pages: null,
    total_count: null,
  },
  statuses: [],
  permittedMeta: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CRM_CONTRACTS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CRM_CONTRACT:
      const index = state.list.findIndex(item => item.id === action.payload.item.id);
      const updatedList = [...state.list];
      updatedList.splice(index, 1, action.payload.item);
      return {
        ...state,
        list: updatedList,
      };
    case UPDATE_CRM_CONTRACT_EMAIL:
      const updatedfindItemList = [...state.list];
      const findItem = updatedfindItemList.findIndex(item => item.uuid === action.payload.uuid);
      if (findItem > -1) {
        updatedfindItemList[findItem].client_email = action.payload.email;
      }
      return {
        ...state,
        list: updatedfindItemList,
      };
    case CLEAR_CRM_CONTRACT:
      return {
        ...state,
        list: [],
      };
    case SET_CRM_CONTRACTS_LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };
    default:
      return state;
  }
};
