import React from "react";
import { RowAction } from "../../components/common";
import UserInfoItems from "./CrmClientInfoItems";
import UserAction from "./UserAction";
import cx from "clsx";
import { ContactInfo } from "./ContactInfo";

function DetailCard(props) {
  const {
    IconsList,
    actionsList,
    cardData = null,
    renderProp: RenderComponent,
    title,
    statusAction = null,
    status,
    disposition,
    userDropdownProp: UserDropdownComponent,
    hidePhoneNumberProps = {
      associatedId: null,
      component: null,
      associatedType: null,
    },
    client = null,
    children,
    onPressPreference = () => {},
    isBlacklisted = false,
    clientBlackListReason = "",
    className,
  } = props;

  return (
    <div className={cx("card", className)}>
      <div className="card-body">
        <div className="d-flex m-b-25 p-b-15 justify-content-between align-items-center border-bottom">
          {title ? (
            <h5>Booking Detail</h5>
          ) : (
            <ContactInfo
              loading={props.loading}
              client={client || cardData}
              // cardData={cardData}
              isBlacklisted={isBlacklisted}
              blacklistReason={clientBlackListReason || client?.reason}
              status={status}
              disposition={disposition}
              onPressPreference={onPressPreference}
              hidePhoneNumberProps={hidePhoneNumberProps}
            />
          )}
          <div className={"d-flex align-items-center"}>
            {actionsList && <UserAction loading={props.loading} actionList={actionsList} />}
            {statusAction && statusAction}
            {RenderComponent && <RenderComponent />}
            {children}
            {props.rowActionDropdownList && props.rowActionDropdownList.length > 0 && (
              <RowAction dropDownList={props.rowActionDropdownList} />
            )}
          </div>
        </div>
        <UserInfoItems
          className="m-b-10"
          detailWithIcons={IconsList}
          loading={props.loading}
          UserDropdownComponent={UserDropdownComponent}
        />
      </div>
    </div>
  );
}

export default DetailCard;
