import React from "react";
import { Skeleton } from ".";

export default function FormRow(props) {
  const {
    children,
    label,
    horizontal,
    containerClassName,
    loading,
    columnLeftSection = "3",
    columnRightSection = "9",
    textRight,
    isViewable = true,
    rightSectionClass = "",
    leftSectionClass = "",
    shouldWrap = true,
    isEditable = true,
    showRequiredAsterisk,
    id = "",
    ...rest
  } = props;
  if (!shouldWrap) return <>{props.children}</>;
  return isViewable ? (
    <div
      className={`form-group ${horizontal ? "row form-group-y" : ""} ${
        containerClassName ? containerClassName : ""
      }`.trim()}
      id={id}
    >
      <RenderLabel
        label={label}
        loading={loading}
        horizontal={horizontal}
        leftSectionClass={leftSectionClass}
        columnLeftSection={columnLeftSection}
        textRight={textRight}
        showRequiredAsterisk={showRequiredAsterisk}
        {...rest}
      />
      <div className={`${rightSectionClass} ${horizontal ? `col-sm-${columnRightSection}` : ""}`}>{children}</div>
    </div>
  ) : null;
}

const RenderLabel = ({
  label,
  loading,
  horizontal,
  leftSectionClass,
  columnLeftSection,
  textRight,
  labelClassName,
  showRequiredAsterisk,
}) =>
  label ? (
    <label
      className={`${labelClassName} ${leftSectionClass} ${
        horizontal ? `col-sm-${columnLeftSection} col-form-label` : ""
      } ${textRight ? "text-right" : ""}  ${loading ? "pt-0" : ""}`.trim()}
    >
      {loading ? (
        <Skeleton height={24} />
      ) : (
        <>
          {label} {showRequiredAsterisk && <sup className={"invalid-error mb-0"}>*</sup>}
        </>
      )}
    </label>
  ) : null;
