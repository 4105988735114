import { FETCH_PERMISSIONS, FETCH_TENANT } from "../../constant/actionTypes";
import {
  checkUndefinedApiParams,
  getErrorString,
  getSimilarItemsFormArray,
  isFieldRestricted,
} from "../../helpers/Util";

import { fetchBaseDetailsApi, fetchLoginPermissions, fetchTenantApi } from "../../helpers/ApiHelper";
import { store } from "../../store";

export const updateTenant = () => async dispatch => {
  const platforms = store.getState().LoggedInUserData?.user?.platforms;
  const response = await fetchTenantApi();
  if (response?.success) {
    dispatch({
      type: FETCH_TENANT,
      payload: {
        tenant: {
          ...response.tenant,
          platforms: getSimilarItemsFormArray(response.tenant.platforms, platforms),
        },
        tenantLastSavedAt: new Date(),
        loading: false,
        error: "",
      },
    });
  } else {
    dispatch({
      type: FETCH_TENANT,
      payload: {
        tenant: {},
        loading: false,
        tenantLastSavedAt: null,
        error: getErrorString(response?.errors),
      },
    });
  }
};

export const updatePermissions = userId => async dispatch => {
  if (!checkUndefinedApiParams(userId, "user")) return;
  const permissionsResponse = await fetchLoginPermissions(userId);
  if (permissionsResponse.success) {
    dispatch({
      type: FETCH_PERMISSIONS,
      payload: {
        permissions: { ...permissionsResponse.permissions },
        activeColumns: { ...permissionsResponse.active_columns },
        widgets: permissionsResponse.widgets,
        isAdmin: permissionsResponse.is_admin,
        // oneTimeFetchPermission: true,
        loading: false,
        error: "",
      },
    });
  }
};

export const fetchBaseDetails = (userId, load) => dispatch => {
  const platforms = store.getState().LoggedInUserData?.user?.platforms;
  if (load) {
    dispatch({
      type: FETCH_TENANT,
      payload: {
        tenant: {},
        loading: true,
        tenantLastSavedAt: null,
        error: "",
      },
    });
    dispatch({
      type: FETCH_PERMISSIONS,
      payload: {
        permissions: {},
        widgets: [],
        loading: true,
        error: "",
      },
    });
  }

  const failureCallback = error => {
    dispatch({
      type: FETCH_TENANT,
      payload: {
        tenant: {},
        loading: false,
        tenantLastSavedAt: null,
        error,
      },
    });
    dispatch({
      type: FETCH_PERMISSIONS,
      payload: {
        permissions: {},
        loading: false,
        error,
      },
    });
  };

  if (!userId && isFieldRestricted(userId)) {
    const error = "Invalid user id";
    failureCallback(error);
    return;
  }

  fetchBaseDetailsApi(
    userId,
    (tenantResponse, permissionsResponse) => {
      dispatch({
        type: FETCH_TENANT,
        payload: {
          tenant: {
            ...tenantResponse.tenant,
            platforms: getSimilarItemsFormArray(tenantResponse.tenant.platforms, platforms),
            adInsightWidgetIds: tenantResponse?.car_detail_widgets,
          },
          tenantLastSavedAt: new Date(),
          loading: false,
          error: "",
        },
      });
      permissionsResponse &&
        dispatch({
          type: FETCH_PERMISSIONS,
          payload: {
            permissions: { ...permissionsResponse.permissions },
            activeColumns: { ...permissionsResponse.active_columns },
            widgets: permissionsResponse.widgets,
            isAdmin: permissionsResponse.is_admin,
            loading: false,
            error: "",
          },
        });
    },
    errors => {
      const error = getErrorString(errors);
      failureCallback(error);
    }
  );
};
