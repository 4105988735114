// eslint-disable-next-line no-unused-vars
import React, { Dispatch, useState } from "react";
import { useSelector } from "react-redux";
import { strings } from "../../constant/strings";
import { createAuctionNegotiation } from "../../helpers/ApiHelper";
import { selectLoggedInUser } from "../../reducers/login/selectors";
import { Avatar, ButtonLoading, FormInput, IF, Modal, Status } from "../common";
import Toast from "../common/Toast";
import {
  getMostRecentBuyingOffer,
  getMostRecentSellingOffer,
  isAuctionUserRevisingOffer,
  isDreCounteringOffer,
  isDreRevisingOffer as isDreRevisingOfferFn,
  isHODRevisingOffer as isHODRevisingOfferFn,
  isPurchaserCounteringOffer,
  isPurchaserRevisingOffer as isPurchaserRevisingOfferFn,
  isHODCounteringOffer as isHODCounteringOfferFn,
  errorMessages,
} from "./OfferTrials/OfferTrialsHelpers";
import { AUCTION_NEGOTIATION_OFFER_STATUSES } from "constant/appConstants";

import styles from "./CounterOfferModal.module.scss";
import { IconMinus, IconPlus } from "../svg-icon/svg-icons";
// eslint-disable-next-line no-unused-vars
import { counterOfferModalType, counterIncreasedOrDecreaseOfferType } from "./OfferTrials/OfferTrials.types";

type user = {
  id: string;
};
type client = {
  name?: string;
  email?: string;
};

const IncreaseOrDecreaseOffer = React.memo(
  ({
    disablePlusBtnFn = () => false,
    disableMinusBtnFn = () => false,
    setShowAcceptModal = () => {},
    onSubmit,
    getErrorMessages = () => Object,
    getOfferLabel,
    offer = null,
    jumpFactor = 0,
    minimumOffer = 0,
    maximumOffer = Number.MAX_SAFE_INTEGER,
    errorMessagesNature,
    isDreCounteringPurchaserOffer,
    mostRecentSellingOffer,
    isDreRevisingOffer,
    mostRecentBuyingOffer,
    isPurchaserRevisingOffer,
    isHODRevisingOffer,
    isHODCounteringOffer,
    isPurchaserCounteringBuyingOffer,
    negotiationOffer,
  }: counterIncreasedOrDecreaseOfferType) => {
    const [offerAmount, setOfferAmount] = useState<number>(offer);
    const [loading, setLoading] = useState(null);

    const increaseOffer = () => {
      setOfferAmount(prev => prev + jumpFactor);
    };

    const decreaseOffer = () => {
      setOfferAmount(prev => prev - jumpFactor);
    };

    const handleKeyDown = (event: any) => {
      if (event.key === "ArrowUp") {
        event.preventDefault();
        setOfferAmount(prev => prev + jumpFactor);
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        setOfferAmount(prev => prev - jumpFactor);
      }
    };

    const errorMessages =
      getErrorMessages({
        currentOffer: offerAmount,
        minimumOffer: minimumOffer,
        maximumOffer: maximumOffer,
      })?.[`${errorMessagesNature}`] ?? null;

    const handleClick = () => onSubmit({ counterOffer: offerAmount, setLoading });
    const handleAcceptCounterBtn = () => {
      setShowAcceptModal(negotiationOffer?.id);
      //TODO: bad code, need to refactor
    };

    const hasDreReachedPurchaserOfferDuringCountering =
      isDreCounteringPurchaserOffer && offerAmount === mostRecentSellingOffer;

    const hasDreReachedPurchaserLatestOfferDuringRevision =
      isDreRevisingOffer && offerAmount === mostRecentSellingOffer;

    const hasPurchaserReachedLatestBuyingOfferDuringCountering =
      isPurchaserCounteringBuyingOffer && offerAmount <= mostRecentBuyingOffer;

    const hasPurchaserReachedLatestBuyingOfferDuringRevising =
      isPurchaserRevisingOffer && offerAmount === mostRecentBuyingOffer;

    const hasPurchaserReachedTheMostRecentSellingOfferDuringRevising =
      isPurchaserRevisingOffer && offerAmount === mostRecentSellingOffer;

    const hasHODReachedLatestBuyingOfferDuringCountering = isHODCounteringOffer && offerAmount <= mostRecentBuyingOffer;

    const hasHODReachedTheMostRecentSellingOfferDuringRevising =
      isHODRevisingOffer && offerAmount === mostRecentSellingOffer;

    const maximumOfferErrorMsg: string = errorMessages?.MAXIMUM_OFFER ?? "";
    const minimumOfferErrorMsg: string = errorMessages?.MINIMUM_OFFER ?? "";

    // FUN FACT: infoErrorMsg does not disable the plus or minus buttons, if you want to add rules that disable plus and minus buttons then use the errorMessages hashmap !
    const infoErrorMsg: string =
      hasPurchaserReachedTheMostRecentSellingOfferDuringRevising || hasHODReachedTheMostRecentSellingOfferDuringRevising
        ? "This offer has already been placed"
        : "";

    const isError = maximumOfferErrorMsg || minimumOfferErrorMsg || infoErrorMsg;

    const disableCounterOfferBtn =
      isError ||
      hasPurchaserReachedLatestBuyingOfferDuringCountering ||
      hasDreReachedPurchaserLatestOfferDuringRevision ||
      hasDreReachedPurchaserOfferDuringCountering ||
      hasHODReachedLatestBuyingOfferDuringCountering ||
      hasHODReachedTheMostRecentSellingOfferDuringRevising;

    const showAccpetOfferBtn =
      hasPurchaserReachedLatestBuyingOfferDuringCountering ||
      hasDreReachedPurchaserLatestOfferDuringRevision ||
      hasPurchaserReachedLatestBuyingOfferDuringRevising ||
      hasDreReachedPurchaserOfferDuringCountering ||
      hasHODReachedLatestBuyingOfferDuringCountering;

    const disablePlusBtn = disablePlusBtnFn({ currentOffer: offerAmount }) || !!maximumOfferErrorMsg;

    const disableMinusBtn = disableMinusBtnFn({ currentOffer: offerAmount }) || !!minimumOfferErrorMsg;

    return (
      <>
        <label> {getOfferLabel()} Offer</label>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center" style={{ gap: "12px" }}>
            <IconMinus
              onClick={decreaseOffer}
              style={
                disableMinusBtn
                  ? {
                      pointerEvents: "none",
                    }
                  : {}
              }
              size={"1.2em"}
              color={disableMinusBtn ? "#707070" : "hsl(var(--primary-color))"}
              className={disableMinusBtn ? "" : "cursor-pointer"}
            />

            {/* {isPurchaserCounteringBuyingOffer && offerAmount < mostRecentBuyingOffer ? (
              mostRecentBuyingOffer
            ) : ( */}
            <FormInput
              className="f-16 mb-0 theme-form"
              value={offerAmount}
              // type="text"
              onChange={(e: any) => {
                setOfferAmount(parseInt(e.target.value || "0"));
              }}
              onKeyDown={handleKeyDown}
            />
            {/* )} */}
            {/* <IF
              condition={isPurchaserCounteringBuyingOffer && offerAmount < mostRecentBuyingOffer}
              ELSE={() => (
              )}
            >
              {mostRecentBuyingOffer}
            </IF> */}

            <IconPlus
              onClick={increaseOffer}
              style={
                disablePlusBtn
                  ? {
                      pointerEvents: "none",
                    }
                  : {}
              }
              size={"1.2em"}
              color={disablePlusBtn ? "#707070" : "hsl(var(--primary-color))"}
              className={disablePlusBtn ? "" : "cursor-pointer"}
            />
          </div>
          {!showAccpetOfferBtn ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ButtonLoading loading={loading} disabled={disableCounterOfferBtn} onClick={handleClick}>
                {getOfferLabel()}
              </ButtonLoading>
            </div>
          ) : (
            <ButtonLoading
              onClick={handleAcceptCounterBtn}
              disabled={isPurchaserCounteringBuyingOffer && offerAmount < mostRecentBuyingOffer}
            >
              {strings.accept}
            </ButtonLoading>
          )}
        </div>
        <div className={`mt-3 ${showAccpetOfferBtn ? "d-flex justify-content-end" : ""}`}>
          {showAccpetOfferBtn ? (
            <></>
          ) : (
            <div style={{ color: "#f21717" }}>
              {maximumOfferErrorMsg}
              {minimumOfferErrorMsg}
              {infoErrorMsg}
            </div>
          )}
        </div>
      </>
    );
  }
);
const CounterOfferModal = ({
  data,
  sellerExpectations,
  showModal,
  setShowModal = () => {},
  auctionUser,
  setShowAcceptModal = () => {},
  inventorySellerExpectation,
}: counterOfferModalType) => {
  const negotiationOffer = data?.negotiation_offers?.find(offer => offer?.id === showModal);
  const dreClient = data?.started_by;
  const purchaserClient = data?.purchaser;

  const negotiationOfferStatus = negotiationOffer?.negotiation_offer_type;

  const user: user = useSelector(selectLoggedInUser);

  const negotiationJump = data?.negotiation_jump;
  const isAuctionDre = !!auctionUser?.DRE;
  const isPurchaser = !!auctionUser?.Purchaser;
  const isAuctionHOD = !!auctionUser?.HOD;
  const client: client = (isAuctionDre ? dreClient : isPurchaser || isAuctionHOD ? purchaserClient : "") ?? "";
  const mostRecentBuyingOffer: number = getMostRecentBuyingOffer(data?.negotiation_offers ?? []);

  const mostRecentSellingOffer: number = getMostRecentSellingOffer(data?.negotiation_offers ?? []);

  const isPurchaserCounteringBuyingOffer: boolean = isPurchaserCounteringOffer({
    AuctionUser: auctionUser,
    negotiationStatus: negotiationOfferStatus,
  });

  const isDreCounteringPurchaserOffer: boolean = isDreCounteringOffer({
    AuctionUser: auctionUser,
    negotiationStatus: negotiationOfferStatus,
  });

  const isDreRevisingOffer: boolean = isDreRevisingOfferFn({
    AuctionUser: auctionUser,
    negotiationStatus: negotiationOfferStatus,
  });
  const isPurchaserRevisingOffer: boolean = isPurchaserRevisingOfferFn({
    AuctionUser: auctionUser,
    negotiationStatus: negotiationOfferStatus,
  });

  const isHODRevisingOffer: boolean = isHODRevisingOfferFn({
    AuctionUser: auctionUser,
    negotiationStatus: negotiationOfferStatus,
  });

  const isHODCounteringOffer: boolean = isHODCounteringOfferFn({
    AuctionUser: auctionUser,
    negotiationStatus: negotiationOfferStatus,
  });

  const getModalName = () => {
    if (isPurchaserRevisingOffer || isHODRevisingOffer) return strings.revise_offer;

    return strings.counter_offer;
  };

  const disablePlusBtnWhenPurchaserIsCounteringOffer = ({ currentOffer }) => {
    if (!isPurchaserCounteringBuyingOffer) return false;

    if (mostRecentSellingOffer) return currentOffer + negotiationJump >= mostRecentSellingOffer;

    if (sellerExpectations) return currentOffer >= sellerExpectations;
  };

  const disablePlusBtnWhenPurchaserIsRevising = ({ currentOffer }) => {
    return (
      isPurchaserRevisingOffer && currentOffer + negotiationJump >= sellerExpectations
      // currentOffer + negotiationJump >= (mostRecentSellingOffer ?? sellerExpectations)
    );
  };

  const disableMinusBtnWhenDreIsCounteringPurchaser = ({ currentOffer }) => {
    return isDreCounteringPurchaserOffer && currentOffer - negotiationJump <= mostRecentBuyingOffer;
  };

  const onSubmit = async ({
    counterOffer = 0,
    setLoading = () => {},
  }: {
    counterOffer: number;
    setLoading: Dispatch<boolean>;
  }) => {
    const payload = {
      negotiation_offer: {
        negotiation_id: data?.id,
        amount: counterOffer,
        negotiation_offer_type: isAuctionDre
          ? AUCTION_NEGOTIATION_OFFER_STATUSES.BUYING
          : isPurchaser || isAuctionHOD
          ? AUCTION_NEGOTIATION_OFFER_STATUSES.SELLING
          : "",
        user_id: user?.id,
      },
    };

    try {
      setLoading(true);
      const res = await createAuctionNegotiation(payload);
      if (res && res.success) {
        setShowModal(false);
      } else {
      }
    } catch (e) {
      Toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getOfferLabel = () =>
    isAuctionUserRevisingOffer({ AuctionUser: auctionUser, negotiationStatus: negotiationOfferStatus })
      ? strings.revise
      : strings.counter;

  const getOfferPriceFieldPlaceHolder = () =>
    isAuctionUserRevisingOffer({ AuctionUser: auctionUser, negotiationStatus: negotiationOfferStatus })
      ? strings.enter_revise_offer
      : strings.enter_counter_offer;

  const increaseOrDecreaseOfferProps = {
    isDreCounteringPurchaserOffer,
    mostRecentSellingOffer,
    isDreRevisingOffer,
    mostRecentBuyingOffer,
    isPurchaserRevisingOffer,
    isHODRevisingOffer,
    isHODCounteringOffer,
    setShowAcceptModal,
    isPurchaserCounteringBuyingOffer,
    onSubmit,
    getOfferLabel,
    negotiationOffer,
  };

  const FormItems = () => {
    return (
      <>
        <div className="" style={{ width: "100%" }}>
          <IF condition={isAuctionHOD}>
            <FormInput
              label={strings.bidder_latest_offer}
              placeholder={strings.bidder_latest_offer}
              value={mostRecentBuyingOffer}
              isEditable={false}
              className="theme-form"
            />
            <IF condition={isHODCounteringOffer}>
              <IncreaseOrDecreaseOffer
                offer={mostRecentSellingOffer ? mostRecentSellingOffer - negotiationJump : sellerExpectations}
                jumpFactor={negotiationJump}
                minimumOffer={mostRecentBuyingOffer}
                maximumOffer={inventorySellerExpectation}
                errorMessagesNature={"purchaserCounterOfferToDre"}
                getErrorMessages={errorMessages}
                disablePlusBtnFn={disablePlusBtnWhenPurchaserIsCounteringOffer}
                {...increaseOrDecreaseOfferProps}
              />
            </IF>
            <IF condition={isHODRevisingOffer}>
              <>
                <IncreaseOrDecreaseOffer
                  offer={mostRecentSellingOffer - negotiationJump ?? sellerExpectations - negotiationJump}
                  jumpFactor={negotiationJump}
                  minimumOffer={mostRecentBuyingOffer}
                  maximumOffer={inventorySellerExpectation}
                  getErrorMessages={errorMessages}
                  errorMessagesNature={"purchaserRevisingOffer"}
                  disablePlusBtnFn={disablePlusBtnWhenPurchaserIsRevising}
                  {...increaseOrDecreaseOfferProps}
                />
              </>
            </IF>
          </IF>

          <IF condition={isPurchaser}>
            <FormInput
              label={strings.bidder_latest_offer}
              placeholder={strings.bidder_latest_offer}
              value={mostRecentBuyingOffer}
              isEditable={false}
              className="theme-form"
            />
            <IF condition={isPurchaserCounteringBuyingOffer}>
              <>
                <IncreaseOrDecreaseOffer
                  offer={mostRecentSellingOffer ? mostRecentSellingOffer - negotiationJump : sellerExpectations}
                  jumpFactor={negotiationJump}
                  minimumOffer={mostRecentBuyingOffer}
                  maximumOffer={inventorySellerExpectation}
                  errorMessagesNature={"purchaserCounterOfferToDre"}
                  getErrorMessages={errorMessages}
                  disablePlusBtnFn={disablePlusBtnWhenPurchaserIsCounteringOffer}
                  {...increaseOrDecreaseOfferProps}
                />
              </>
            </IF>
            <IF condition={isPurchaserRevisingOffer}>
              <>
                <IncreaseOrDecreaseOffer
                  offer={mostRecentSellingOffer ? mostRecentSellingOffer - negotiationJump : sellerExpectations}
                  jumpFactor={negotiationJump}
                  minimumOffer={mostRecentBuyingOffer}
                  maximumOffer={inventorySellerExpectation}
                  errorMessagesNature={"purchaserCounterOfferToDre"}
                  getErrorMessages={errorMessages}
                  disablePlusBtnFn={disablePlusBtnWhenPurchaserIsCounteringOffer}
                  {...increaseOrDecreaseOfferProps}
                />
              </>
            </IF>
          </IF>
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        toggle={() => setShowModal(null)}
        title={<div className="d-flex justify-content-between">{getModalName()}</div>}
        open={!!showModal}
        body={() => (
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <div
              className={styles.userDetailContainer}
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar name={client?.name ?? ""} avatarOnly />
                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <span style={{ fontWeight: "bolder", fontSize: 16 }}>{client?.name ?? ""}</span>
                  <span>{client?.email ?? ""}</span>
                </div>
              </div>
              <div>
                <Status name={negotiationOfferStatus} />
              </div>
            </div>
            <div className={styles.formField}>
              <FormItems />
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CounterOfferModal;
