import React from "react";
import { Avatar } from "../../components/common/Avatar";
import { LANGUAGE_ABBREVIATIONS } from "constant/appConstants";

const UserOption = props => {
  const { value } = props;
  return (
    <>
      <div className={"mention-suggestion d-flex p-1"}>
        <Avatar name={value.name} imgSrc={value?.picture?.url} avatarOnly />
        <div className={"d-flex flex-column ml-2 flex-grow-1"}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "30px" }}>
            <div style={{ fontSize: "medium", color: "#2F2929" }}>{value.name}</div>
            <div style={{ fontSize: "12px", color: "#ADADAD" }}>
              {LANGUAGE_ABBREVIATIONS[value?.language_preference] ?? "-"}
            </div>
          </div>
          <div style={{ fontSize: "small", color: "#ADADAD", textOverflow: "ellipsis", maxWidth: "19ch" }}>
            {value.email}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserOption;
