import { Chip, Close, CustomAlert, IF, Modal, ScrollableTable, Skeleton, Status, Toast } from "../common";
import React, { useCallback, useEffect, useState } from "react";
import { fetchAvailableCarsApi, fetchCarsApi, fetchCitiesApi, fetchItemListsApi } from "../../helpers/ApiHelper";
import {
  clone,
  CustomMenuItem,
  getCarTitle,
  getErrorString,
  getQueryString,
  getQueryStringFromArray,
  isFieldRestricted,
  stateUpdateCallback,
} from "../../helpers/Util";

import { BtnDefaultState } from "../common/BtnDefaultState";
import EmptyState from "../common/EmptyState/EmptyState";
import FiltersWithoutQueryParams from "../common/filters/FiltersWithoutQueryParams";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { strings } from "../../constant/strings";
import { useSelector } from "react-redux";
import {
  MODELS_FOR_VIEW_PHONE_NUMBER_API,
  PHONE_NUMBER_CATEGORIZATION_PER_PAGE,
  RESTRICTED,
  SCROLLABLE_ELEMENTS_KEYS,
} from "constant/appConstants";
import { useMakeModel } from "../../hooks/useMakeModel";
import RenderPrivateField from "../common/RenderPrivateField/RenderPrivateField";
import { extractPayloadFromClientObj } from "../../helpers/ViewPhoneNumber";

const SelectInventoryModal = props => {
  const { canRemove = true, isViewable = true, showDetail = true, associatedId = null } = props;
  const cities = useSelector(content => content.Configs.tenant.cities);
  const [inventoryModelOpen, setInventoryModelOpen] = useState(false);

  const [filtersObject, setFiltersObject] = useState({ limit: 10 });
  const [filterStates, setFilterStates] = useState({ bodyTypes: [], cities: [] });
  const [selected, setSelected] = useState(null);
  const [showInventoryAddModel, setShowInventoryAddModel] = useState({ check: false, item: null });
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showIllustration, setShowIllustration] = useState(false);
  const setFilters = () => {
    const queryObj = { ...filtersObject };
    return { queryObj };
  };
  const [makeList, modelList, variantList, getMakeModelVariantFilters, filterStateHelper] = useMakeModel(setFilters, [
    filtersObject,
  ]);
  useEffect(() => {
    if (!filtersObject?.["f[make.id][]"]) {
      delete filtersObject["f[model.id][]"];
      delete filtersObject["f[variant.id][]"];
      filterStateHelper.reset();
    }
    if (!filtersObject?.["f[model.id][]"]) {
      delete filtersObject["f[variant.id][]"];
    }
    const query = getQueryString(filtersObject);
    visible && fetchAvailableCars(props?.extraParams ? query + props.extraParams : query);
  }, [filtersObject, visible]);

  const fetchAvailableCars = async params => {
    setList([]);
    setShowIllustration(false);
    setError(null);
    setLoading(true);
    if (props.filterRemovedAndSold && props?.availableInventoryStatuses?.length) {
      params = `${params}&${getQueryStringFromArray("f[status.slug][]", props?.availableInventoryStatuses)}`;
    }

    const response = props.fetchAllInventories ? await fetchCarsApi(params) : await fetchAvailableCarsApi(params);
    if (response) {
      setLoading(false);
      if (response.cars) {
        if (!response.cars.length) {
          setShowIllustration(true);
        } else {
          setList(response.cars);
        }
      } else {
        setError(getErrorString(response));
      }
    }
  };

  useEffect(() => {
    if (!props.currentValue) {
      setSelected(null);
    }
  }, [props.currentValue]);

  const getOnScrollElement = useCallback(() => {
    return document.getElementById(SCROLLABLE_ELEMENTS_KEYS.MODAL_TABLE_KEY);
  }, [list]);

  if (!isViewable) return null;

  const renderHeader = () => (
    <tr>
      <th></th>
      <th>{strings.ref}</th>
      <th>{strings.make_model}</th>
      <th>{strings.mileage}</th>
      <th>{strings.status}</th>
      <th>{strings.model_year}</th>
      <th>{strings.city}</th>
      <th>{strings.seller_info}</th>
      <th>{strings.seller_phone}</th>
      <th>{strings.body_type}</th>
    </tr>
  );

  const renderBody = item => (
    <tr
      key={item.id}
      id={`tbody-${item.id}`}
      onClick={() => {
        const checkRestrictedStatus =
          props.validateInventoryStatus &&
          props.values?.task_type?.restricted_inventory_statuses?.includes(item.status.slug);
        if (checkRestrictedStatus) {
          Toast.error(`You are not allowed to attach inventory with status: ${item.status?.name}`);
          return;
        }
        const showPromptForTaskEdit = props.showPrompt && props.fromEdit;
        showPromptForTaskEdit && setShowInventoryAddModel({ item: item, check: true });
        if (!props.fromEdit) {
          setSelected(item);
          props.onSelect && props.onSelect(item);
          setVisible(false);
        }
      }}
    >
      <td className="">
        <label styles={{ top: "-8px", left: "10px" }}></label>
      </td>
      <td scope="row">{item?.reference_number}</td>
      <td>{item?.make?.select_value + " " + item?.model?.select_value}</td>
      <td>{item?.mileage}</td>
      <td>
        <Status {...item.status} />
      </td>
      <td> {item?.model_year}</td>
      <td>{item?.inventory?.city?.name}</td>

      <td>{`${item?.client?.name || "-"}`}</td>
      <td>
        <div
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <RenderPrivateField
            {...extractPayloadFromClientObj({ item: item?.client })}
            associatedType={MODELS_FOR_VIEW_PHONE_NUMBER_API.INVENTORY_PHONE_KEY}
            component={PHONE_NUMBER_CATEGORIZATION_PER_PAGE.TASK.INVENTORY_MODAL}
            associatedId={item?.id}
            dropDownContainer="tbody"
            onScrollElement={getOnScrollElement()}
          />
        </div>
      </td>
      <td>{item?.body_type?.select_value ? item?.body_type?.select_value : " -"}</td>
    </tr>
  );

  const renderSkeleton = item => (
    <tr key={item}>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
    </tr>
  );
  const getTitle = () => {
    const car = clone(props.currentValue);

    if (!selected && props.currentValue && props.currentValue?.inventoriable) {
      if (
        isFieldRestricted(props.currentValue?.inventoriable) ||
        isFieldRestricted(props.currentValue?.inventoriable?.reference_number) ||
        isFieldRestricted(props.currentValue?.inventoriable)
      ) {
        car.reference_number = RESTRICTED;
      } else {
        car.reference_number = props.currentValue?.inventoriable?.reference_number;
      }
    }
    const name = getCarTitle(!!selected ? selected : car, true);
    return name;
  };

  const renderFilters = () => {
    return (
      <FiltersWithoutQueryParams
        list={[
          {
            sizeratio: 1,
            label: strings.ref,
            type: "input",
            key: "f[reference_number]",
            isRefNumber: true,
          },
          ...getMakeModelVariantFilters(),
          {
            id: "inventory.location.address",
            type: "scrollable-dropdown",
            listApi: fetchCitiesApi,
            listProperty: "cities",
            getDataInParent: data =>
              setFilterStates(prev => ({ ...prev, cities: stateUpdateCallback(prev["cities"], data) })),
            queryKey: "s[address]",
            formatOptions: option => <CustomMenuItem option={option} />,
            customClass: "col-sm-6 col-md-4 col-xl-2 m-b-10 async-select-filter",
            sizeratio: 1,
            label: strings.city,
            key: "f[city.id][]",
            isSingleSelect: true,
          },
          {
            type: "scrollable-dropdown",
            listApi: fetchItemListsApi,
            listProperty: "item_lists",
            extraParams: {
              "q[select_key_eq]": "body_type",
            },
            getDataInParent: data =>
              setFilterStates(prev => ({ ...prev, bodyTypes: stateUpdateCallback(prev["bodyTypes"], data) })),
            queryKey: "q[select_value_cont]",
            formatOptions: option => <CustomMenuItem option={option} />,
            customClass: "col-sm-6 col-md-4 col-xl-2 m-b-10 async-select-filter",
            sizeratio: 1,
            label: strings.body_type,
            key: "f[body_type.id]",
            isSingleSelect: true,
          },
          {
            sizeratio: 1,
            label: strings.model_year,
            type: "input",
            key: "f[model_year]",
          },
          {
            sizeratio: 2,
            label: strings.mileage,
            type: "input",
            key: "f[mileage]",
          },
          {
            sizeratio: 2,
            label: strings.seller_name,
            type: "input",
            key: "s[client.name]",
          },
          {
            sizeratio: 2,
            label: strings.seller_phone,
            type: "input",
            key: "f[client.phone]",
          },
        ]}
        filtersObject={filtersObject}
        setFiltersObject={setFiltersObject}
        containerClass="m-t-20"
      />
    );
  };

  const renderChipAndButton = () => {
    return (
      <>
        <IF condition={selected || props.currentValue}>
          <div className={`d-flex align-items-center ${props.editable && "hover-pointer"}`}>
            <Chip
              disabled={props.isEditable === false}
              containerClassName="pr-0"
              onClick={() => props.editable && setVisible(true)}
              title={getTitle()}
              onClose={() => {
                props.onDeselect();
                setSelected(null);
              }}
              canClose={canRemove && !props.fromEdit && !props.taskId}
              // key={selected?.id}
            />
            {props.fromEdit && props.currentValue && showDetail && (
              <Link className="m-t-10 txt-info hover-pointer" to={`/crm/${props.detailsRoute}`}>
                {strings.view_details}
              </Link>
            )}
          </div>
        </IF>
        <IF condition={!selected && !props.currentValue}>
          <BtnDefaultState
            disabled={props.isEditable === false}
            onClick={() => {
              props.path === "addCico" ? setInventoryModelOpen(true) : setVisible(true);
            }}
            className={`custom-field-state b-r-6`}
          />
        </IF>
      </>
    );
  };

  const PromptMessage = () => {
    if (props.fromEdit) {
      return <p>{strings.attached_meetings_prompt}</p>;
    }
    return <p>{`Are you sure you want to add ${showInventoryAddModel?.item?.title}?`}</p>;
  };

  const promptTitle = () => {
    if (props.fromEdit) {
      return strings.edit_inventory;
    }
    return strings.add_inventory;
  };

  const renderModal = () => {
    return (
      <>
        {props.path !== "addCico" ? (
          <Modal
            toggle={() => {
              setVisible(prevState => !prevState);
            }}
            open={visible}
            className="modal-lg deposit_model inventory_model"
            body={() => {
              return (
                <>
                  <div className="d-flex w-100 align-items-start justify-content-between">
                    <h4>{props.title}</h4>
                    <Close
                      style={{ right: "30px" }}
                      onClick={() => {
                        setVisible(false);
                      }}
                    />
                  </div>
                  {renderFilters()}
                  <div
                    id={SCROLLABLE_ELEMENTS_KEYS.MODAL_TABLE_KEY}
                    className={`${SCROLLABLE_ELEMENTS_KEYS.MODAL_TABLE_KEY} inventory-modal-table`}
                  >
                    {error ? (
                      <CustomAlert message={error} className="mx-3" />
                    ) : (
                      <>
                        {!list?.length && showIllustration && <EmptyState />}
                        {list?.length > 0 && (
                          <Table>
                            {list?.length > 0 && !showIllustration && <thead>{renderHeader()}</thead>}
                            <tbody>
                              {props.showPrompt && (
                                <Modal
                                  toggle={() => {
                                    setShowInventoryAddModel(prevState => ({ check: !prevState.check, item: null }));
                                  }}
                                  title={promptTitle()}
                                  open={showInventoryAddModel.check}
                                  body={() => (
                                    <>
                                      <PromptMessage />
                                      {error && <CustomAlert message={error} />}
                                    </>
                                  )}
                                  actions={[
                                    {
                                      label: strings.no,
                                      onClick: () => {
                                        setShowInventoryAddModel({ item: null, check: false });
                                        setSelected(false);
                                      },
                                      color: "secondary",
                                      className: "modal__cancel-btn btn-pill",
                                    },

                                    {
                                      color: "primary",
                                      className: "modal__cr-btn btn-pill",
                                      label: strings.yes,

                                      onClick: () => {
                                        setShowInventoryAddModel({ item: showInventoryAddModel.item, check: false });
                                        props.onSelect && props.onSelect(showInventoryAddModel.item);
                                        setSelected(showInventoryAddModel.item);
                                        setVisible(false);
                                      },
                                    },
                                  ]}
                                />
                              )}

                              {loading && !showIllustration
                                ? [1, 2, 3, 4, 5, 6, 7].map(e => renderSkeleton(e))
                                : list?.map(item => renderBody(item))}
                            </tbody>
                          </Table>
                        )}
                      </>
                    )}
                  </div>
                </>
              );
            }}
            actions={[
              {
                label: strings.cancel,
                onClick: () => {
                  setVisible(false);
                },
                color: "secondary",
                className: "modal__cancel-btn btn-pill",
              },
            ]}
            onClosed={e => {
              setFiltersObject({ limit: 10 });
              setShowIllustration(false);
            }}
          />
        ) : (
          <Modal
            open={inventoryModelOpen}
            className="modal-lg deposit_model inventory_model"
            body={() => {
              return (
                <ScrollableTable
                  closeModel={setInventoryModelOpen}
                  setInventoryModelOpen={setInventoryModelOpen}
                  history={props.history}
                  path={"inventoryModel"}
                  setSelected={setSelected}
                  filterRemovedAndSold
                />
              );
            }}
          />
        )}
      </>
    );
  };

  return (
    <div className="form-group row d-flex flex-row align-items-center">
      <label className={`col-sm-3 col-form-label ${props.textRight ? "text-right" : ""}`}>
        {props.loadingSkeleton ? <Skeleton height={20} width={210} /> : props.title}
      </label>
      <div className="col-sm-9 d-flex flex-column align-items-start">
        {props.loadingSkeleton ? (
          <label className={`col-sm-3 col-form-label ${props.textRight ? "text-right" : ""}`}>
            <Skeleton height={30} />
          </label>
        ) : (
          renderChipAndButton()
        )}

        {!!props.errorMsg && <p className="invalid-error">{props.errorMsg}</p>}
      </div>
      {renderModal()}
    </div>
  );
};

export default SelectInventoryModal;
