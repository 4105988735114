import React from "react";
import { strings } from "../../constant/strings";

const Footer = props => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row justify-content-center">
          {/* <div className="col-md-6 footer-copyright">
                    <p className="mb-0">Copyright 2021 © Endless All rights reserved.</p>
                </div> */}
          <div className="footer-copyright">
            <p className=" mb-0">
              {strings.handcrafted_made}
              <i className="fa fa-heart"></i>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
