import React from "react";
import { Skeleton } from "../../../../components/common";

const QCFormSkeleton = () => {
  const mapArray = [1, 2, 3, 4, 5, 6, 7];
  return (
    <div className="card container-fluid py-4 m-b-40">
      <div className={"d-flex"} style={{ gap: 20, marginTop: 30 }}>
        {mapArray.map(e => (
          <Skeleton key={e} width={180} height={30} />
        ))}
      </div>
      <div className={"d-flex"} style={{ flexDirection: "row", marginTop: 30, gap: 50, flexWrap: "wrap" }}>
        {[1, 2, 3, 4].map(e => (
          <div key={e} className={"d-flex"} style={{ flexDirection: "column", gap: 10 }}>
            <Skeleton width={80} height={20} />
            <Skeleton width={550} height={50} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QCFormSkeleton;
