import React, { useState } from "react";
import { useSelector } from "react-redux";
import cx from "clsx";

import styles from "./FormCurrencyInput.module.scss";
import { Info } from "react-feather";
import Tooltip from "./Tooltip";

const FormCurrencyInput = ({
  value,
  onChange,
  className,
  errorClassName,
  errorMsg,
  onBlur,
  onToggleChange,
  togglerValueType,
  label,
  showTooltip = false,
  tooltipLabel = "",
}) => {
  const [isPercentage, setIsPercentage] = useState(togglerValueType === "percentage" ? true : false);

  const currency_code = useSelector(state => state.Configs.tenant.country?.currency_code);

  const handleToggle = () => {
    setIsPercentage(prev => {
      onToggleChange(prev !== true ? "percentage" : "amount");
      return !prev;
    });
  };

  return (
    <>
      <div className={cx(className, "position-relative")}>
        {/* {label &&<div st>{label}</div>} */}
        {label && (
          <label>
            {label}
            {showTooltip && (
              <>
                <Info className={cx(styles.hoverableSvg, "ml-2")} size={15} data-tip data-for={label} color="#898989" />
                <Tooltip id={label} place="top" type="dark" effect="solid" padding="10px">
                  {tooltipLabel}
                </Tooltip>
              </>
            )}
          </label>
        )}
        <input
          type="text"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className="currency-input form-control"
          placeholder={isPercentage ? "%" : currency_code}
        />
        <div>
          <label className={cx(styles.languageSwitcher)} style={label ? { "--top": "30px" } : {}}>
            <input type="checkbox" checked={isPercentage} onClick={handleToggle} />
            <span className={cx(styles.slider, styles.round)}></span>
            <span className={styles.selectFr}>{currency_code}</span>
            <span className={styles.selectEr}>%</span>
          </label>
          {!!errorMsg && <div className={"invalid-error " + errorClassName}>{errorMsg}</div>}
        </div>
      </div>
    </>
  );
};

export default FormCurrencyInput;
