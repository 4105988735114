import React from "react";
import Resizer from "react-image-file-resizer";
import { getS3PresignedUrl } from "../helpers/ApiHelper";

export default class FileUploadHelper {
  async getUUIDForLocalFile(attachmentType = "", associationKey = "", fileName = "", labelId) {
    let count = 0;
    let data;
    let latestError;

    return new Promise(async (resolve, reject) => {
      while (count < 3) {
        try {
          const res = await getS3PresignedUrl(attachmentType, associationKey, fileName, labelId);
          if (res) {
            if (res.data) {
              data = res.data;
            }
          }
          break;
        } catch (e) {
          latestError = e;
          count += 1;
        }
      }
      if (data) {
        resolve(data);
      } else {
        reject(latestError);
      }
    });
  }

  async uploadFileOnS3(file, s3Data, isImageFile) {
    return new Promise(async function (resolve, reject) {
      try {
        if (s3Data) {
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", s3Data.url);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                resolve(xhr);
                console.log("Image successfully uploaded to S3");
                return xhr.status;
              } else {
                console.log("Error while sending the image to S3. Status:", xhr.status);
              }
            }
          };
          xhr.onerror = e => {
            reject(e);
            console.log("Image uploading to s3 failure", e);
          };

          const type = isImageFile ? file.split(";")[0].split(":")[1] : file.type;

          xhr.setRequestHeader("Content-Type", type);

          if (isImageFile) {
            xhr.setRequestHeader("Content-Encoding", "base64");
          }

          let buffer = file;

          if (isImageFile) {
            buffer = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""), "base64");
          }
          xhr.send(buffer);
        } else {
          console.log("Cannot upload image on S3, data is not correct.");
        }
      } catch (error) {
        console.log("catch (error)", error);
      }
    });
  }

  async resize(image) {
    return new Promise(resolve => {
      Resizer.imageFileResizer(
        image,
        2000, // maxWidth: number,
        2000, // maxHeight: number,
        "JPEG",
        100,
        0,
        uri => {
          resolve(uri);
        },
        "base64"
      );
    });
  }
}
