import { strings } from "../../../../constant/strings";

export const getValueDatePlaceholder = ({ valueDate = null, disabled = false }) => {
  if (!valueDate && disabled) {
    return "Not Available";
  }

  return strings.value_date;
};

export const getLastMinRangeOfDays = value => {
  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setDate(currentDate.getDate() - parseInt(value));
  return minDate;
};

export const futureDate = days => {
  const today = new Date();
  const futureDate = new Date();
  futureDate.setDate(today.getDate() + days);
  return futureDate;
};
