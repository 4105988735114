import {
  ADD_USER,
  FETCH_USERS,
  UPDATE_USER,
  UPDATE_USER_ROLE,
  USER_LOGGED_OUT,
  SET_USERS_LOADING,
} from "../../constant/actionTypes";

import { fetchUsersApi } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

export const addUser = item => dispatch => {
  dispatch({
    type: ADD_USER,
    payload: {
      item: { ...item },
    },
  });
};

export const updateUser = item => dispatch => {
  dispatch({
    type: UPDATE_USER,
    payload: {
      item: { ...item },
    },
  });
};

export const updateUserRoles = item => dispatch => {
  dispatch({
    type: UPDATE_USER_ROLE,
    payload: {
      item: { ...item },
    },
  });
};

export const fetchUsers =
  (queryParams = "") =>
  async dispatch => {
    dispatch({ type: SET_USERS_LOADING, payload: { loading: true } });
    const res = await fetchUsersApi(queryParams);
    if (res && res.success) {
      dispatch({
        type: FETCH_USERS,
        payload: {
          list: res.users,
          loading: false,
          error: "",
          pagination: { ...res.pagination },
          permittedMeta: res.permitted_meta,
        },
      });
    } else {
      dispatch({
        type: FETCH_USERS,
        payload: {
          list: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  };
export const logOutAction = () => dispatch => {
  dispatch({
    type: USER_LOGGED_OUT,
  });
};
