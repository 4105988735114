import "react-toastify/dist/ReactToastify.css";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { trim } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { connect, useDispatch } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { fetchBaseDetails, updatePermissions, updateTenant } from "../actions/general/configs.action";
import { FETCH_TENANT } from "../constant/actionTypes";
import { LOGIN_PATH } from "../constant/appPaths";
import { strings } from "../constant/strings";
import ConfigDB from "../data/config";
import { removeAppCookies, setAppOnRoute } from "../helpers/Util";
import useOutsideAlterter from "../hooks/useOutsideAlterter";
import { ErrorView, Footer, Header, IF, LoaderMain, Sidebar } from "./common";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    release: trim(process.env.REACT_APP_RELEASE, '"'),
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

const AppLayout = props => {
  const {
    children,
    loadingConfig,
    fetchBaseDetails,
    errorLoadingConfig,
    handleLogout,
    tenant,
    user,
    userInfo,
    toggleApp,
    history,
    platformAvailable,
    tenantLastSavedAt,
    updateTenant,
    updatePermissions,
    permissionsData,
  } = props;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const updateTenantIf24HoursPassedSinceLastFetching = () => {
    const dateWhenTenantPreviouslyFetched = new Date(tenantLastSavedAt);
    const presentDate = new Date();
    const msBetweenDates = Math.abs(dateWhenTenantPreviouslyFetched.getTime() - presentDate.getTime());
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
    const have24HoursPassedSinceGettingTenants = hoursBetweenDates >= 24;
    if (have24HoursPassedSinceGettingTenants) {
      updateTenant();
    }
  };

  // useEffect(() => {
  //   if (user && !permissionsData?.oneTimeFetchPermission) {
  //     updatePermissions(userInfo.id);
  //   }
  // }, []);

  useEffect(() => {
    user && Object.keys(tenant || {})?.length === 0 && fetchBaseDetails(userInfo.id);
  }, []);

  useEffect(() => {
    if (user && tenantLastSavedAt) {
      updateTenantIf24HoursPassedSinceLastFetching();
    }
  }, []);

  const getTenant = () => {
    if (Object.values(tenant).length === 0) {
      return {
        error: true,
        errorMessage: "No Tenant available",
      };
    } else {
      // Set favicon
      let link = document.querySelector('link[rel="shortcut icon"]');
      if (!link) {
        link = document.createElement("link");
        link.id = "favicon";
        link.rel = "shortcut icon";
        document.head.appendChild(link);
      }
      link.href = tenant?.favicon_path || ConfigDB.data.favicon;

      // Set route path
      setAppOnRoute(dispatch, FETCH_TENANT, platformAvailable);

      return {
        error: false,
      };
    }
  };

  const errorButtons = [
    {
      label: strings.try_again,
      onClick: () => {
        fetchBaseDetails(userInfo.id, true);
      },
    },
    {
      label: strings.login,
      onClick: () => {
        removeAppCookies();
        handleLogout();
        history.push(LOGIN_PATH);
      },
      setPrimaryColor: true,
    },
  ];

  return (
    <IF
      condition={!!user}
      ELSE={() => (
        <Redirect
          to={{
            pathname: LOGIN_PATH,
            search: history?.location?.pathname ? `?redirect_to=${history.location.pathname}` : "",
          }}
        />
      )}
    >
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          {loadingConfig ? (
            <LoaderMain show={loadingConfig} />
          ) : errorLoadingConfig ? (
            <ErrorView buttons={errorButtons} title={errorLoadingConfig} />
          ) : getTenant().error ? (
            <ErrorView
              buttons={errorButtons}
              retryCallback={() => {
                fetchBaseDetails(userInfo.id);
              }}
              errorBtnLabel={strings.try_again}
              title={getTenant().errorMessage}
            />
          ) : (
            <Sentry.ErrorBoundary fallback={<ErrorView />}>
              <NavBars
                handleLogout={handleLogout}
                tenant={tenant}
                open={open}
                setOpen={setOpen}
                user={user}
                platformAvailable={platformAvailable}
              />
              <div className="page-body mt-0">{children}</div>
              <Footer />
            </Sentry.ErrorBoundary>
          )}
          <Toaster />
        </div>
      </div>
    </IF>
  );
};

const NavBars = props => {
  const navRef = useRef();
  const { handleLogout, tenant, open, setOpen, user, platformAvailable } = props;
  useOutsideAlterter(navRef, () => {
    setOpen(prev => !prev);
  });

  const headerProps = {
    handleLogout,
    tenant,
    open,
    setOpen,
  };
  const sidebarProps = {
    user,
    tenant,
    platformAvailable,
    open,
    setOpen,
  };

  return (
    <>
      <Header {...headerProps} />
      <Sidebar {...sidebarProps} />
    </>
  );
};

const mapStateToProps = ({ Configs, PermissionsData }) => {
  return {
    loadingConfig: Configs.loading || PermissionsData.loading,
    errorLoadingConfig: Configs.error || PermissionsData.error,
    tenant: Configs.tenant,
    permissions: PermissionsData.permissions,
    tenantLastSavedAt: Configs.tenantLastSavedAt,
    permissionsData: PermissionsData,
  };
};

export default connect(mapStateToProps, { fetchBaseDetails, updateTenant, updatePermissions })(withRouter(AppLayout));
