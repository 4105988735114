import React from "react";
import { Button as AntdButton } from "antd";
import Icon from "../icon/icon";
import { Tooltip } from "components/ant-common";
import chroma from "chroma-js";
import cx from "clsx";
import styles from "./index.module.less";
import theme from "utils/themeVars";
import { useState } from "react";

const Button = props => {
  const {
    className,
    children,
    text,
    icon,
    iconProps,
    color,
    size,
    shape,
    title,
    content,
    type,
    bordered,
    style,
    onClick,
    onMouseEnter,
    onMouseLeave,
    tooltipPlacement = "top",
    suffixIcon = false,
    ...rest
  } = props;

  const [visible, setVisible] = useState(false);

  const btnTextColor = e => {
    if (e === undefined || type === "default") return;
    if (type === "light") return e;
    if (chroma(e)?.luminance() < 0.4) {
      return "#fff";
    }
    return "#000";
  };

  const renderButton = () => {
    return (
      <AntdButton
        shape={shape}
        size={size}
        type={type}
        icon={typeof icon === "string" ? <Icon icon={icon} {...iconProps} /> : icon}
        className={cx(className, styles.btn, bordered && "ant-btn-bordered")}
        style={{
          "--btn-color": btnTextColor(color),
          "--btn-bg":
            type === "light" ? chroma.mix(color || theme["primary-color"], "#fff", 0.85).saturate(0.24) : color,
          "--btn-hover-bg": type === "light" && chroma.mix(color || theme["primary-color"], "#fff", 0.8).saturate(0.24),

          ...style,
          "--icon-suffix": suffixIcon && "row-reverse",
        }}
        onClick={(...rest) => {
          onClick && onClick(...rest);
          content && setTimeout(() => setVisible(false), 100);
        }}
        onMouseLeave={(...rest) => {
          onMouseLeave && onMouseLeave(...rest);
          content && setTimeout(() => setVisible(false), 50);
        }}
        onMouseEnter={(...rest) => {
          onMouseEnter && onMouseEnter(...rest);
          content && setTimeout(() => setVisible(true), 50);
        }}
        {...rest}
      >
        {children || text}
      </AntdButton>
    );
  };

  return content ? (
    <Tooltip placement={tooltipPlacement} title={content} open={visible}>
      <div>{renderButton()}</div>
    </Tooltip>
  ) : (
    renderButton()
  );
};

Button.defaultProps = {
  type: "default",
};
export default Button;
