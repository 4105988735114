import React, { useEffect } from "react";

import DropZone from "./DropZone";
import ImageUploadItem from "./ImageUploadItem";
import imageUploadIllustration from "../../assets/illustrations/image-upload-illustration.svg";
import { strings } from "../../constant/strings";
import { onFilesAdded, uploadFile } from "../crm-leads/UploadAttachmentsHelpers";
import { contentMappingForImages } from "../../constant/lists";
import { clone } from "../../helpers/Util";

const FileUploadDropzone = props => {
  const {
    title = "Upload",
    accept = "image/*",
    imageKey = "",
    setFieldValue,
    pictures,
    getRetry,
    picturesRef,
    isEditable = null,
    image,
    description = true,
    btnLink = false,
    uploadBtnText,
    dropZoneStyles,
    imgUploadStyles,
    imagesGridStyles = "",
    headerUploadBtn = false,
    uploadBtn = true,
    shouldResize = true,
  } = props;

  useEffect(() => {
    getRetry(retryCallback);
  });

  const renderImages = () => {
    return (
      <div className="images-grid" style={{ ...imagesGridStyles }}>
        {pictures.map((pic, i) => {
          return getImageItem(pic, i);
        })}
      </div>
    );
  };

  const removeImage = imageItem => {
    const picturesSnapShot = clone(pictures);
    let index = picturesSnapShot.findIndex(pic => {
      return pic.id === imageItem.id;
    });
    picturesSnapShot.splice(index, 1);
    setFieldValue("pictures", picturesSnapShot);
  };

  const setFiles = files => setFieldValue("pictures", files);

  const retryCallback = () => {
    pictures.map(pic => {
      if (pic.inError && !pic.uploading && !pic.uploaded) {
        uploadFile(pic, "generic", "nil", pictures, setFiles, true, false, undefined, null, shouldResize);
      }
    });
  };

  const getImageItem = (item, index) => {
    return (
      <ImageUploadItem
        key={item.id}
        onRetry={retryCallback}
        removeImage={removeImage}
        item={item}
        files={pictures}
        setFiles={setFiles}
        isSvg={false}
        makeBlob={true}
        attachmentType={"generic"}
        associationKey={null}
        imageKey={imageKey}
        style={imgUploadStyles}
        shouldResize={shouldResize}
      />
    );
  };

  const UploadButton = () => {
    return (
      <label className={`btn ${btnLink ? "btn-link" : "btn-pill btn-primary"}`}>
        {uploadBtnText || (
          <>
            <svg width="24" style={{ marginRight: 4, verticalAlign: "middle" }} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"
              />
            </svg>

            {strings.add_photos}
          </>
        )}
        <input
          style={{ display: "none" }}
          type="file"
          accept={contentMappingForImages.join(",")}
          multiple
          onChange={e => {
            let newFiles = e.target.files;
            onFilesAdded(pictures, pics => setFieldValue("pictures", pics), newFiles);
            e.target.value = null;
          }}
        />
      </label>
    );
  };

  return (
    <div className="card" ref={picturesRef} style={isEditable === false ? { cursor: "not-allowed" } : {}}>
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5>{title}</h5>
        {headerUploadBtn && <UploadButton />}
      </div>
      <div className="card-body" style={isEditable === false ? { pointerEvents: "none" } : {}}>
        <div className="dz-message needsclick">
          <DropZone
            onDropCallback={newFiles => onFilesAdded(pictures, pics => setFieldValue("pictures", pics), newFiles)}
            className="dzu-dropzone"
            style={{ ...dropZoneStyles }}
          >
            <div className="upload_auction_photos">
              {pictures?.length > 0
                ? renderImages()
                : image || (
                    <figure>
                      <img src={imageUploadIllustration} />
                    </figure>
                  )}
              <div className="text-center">
                {uploadBtn && <UploadButton />}
                {description && <p>{strings.max_limit_per_image}</p>}
              </div>
            </div>
          </DropZone>
        </div>
      </div>
    </div>
  );
};

export default FileUploadDropzone;
