import React from "react";
import cx from "clsx";

const Button = props => {
  const {
    className,
    color = "primary",
    size = "lg",
    variant = "pill",
    icon = null,
    iconPlacement = "left",
    btnText = "Select",
    ...rest
  } = props;
  return (
    <>
      {!!props.errorMsg && props.errorType !== "icon" && (
        <p className={"invalid-error " + props.errorClassName}>{props.errorMsg}</p>
      )}
      <button
        className={cx("btn", `btn-${color}`, `btn-${size}`, `btn-${variant}`, className).trim()}
        type="button"
        {...rest}
      >
        {iconPlacement == "left" && icon}
        {btnText && <div className="text">{btnText}</div>}
        {iconPlacement == "right" && icon}
      </button>
    </>
  );
};

export default Button;
