import React from "react";
import illustration from "../../assets/illustrations/coming-soon-illustration.svg";
import { strings } from "../../constant/strings";

const NotAvailable = () => {
  return (
    <div className="container-fluid">
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ height: window.innerHeight }}
      >
        <img src={illustration} alt="Illustration" width={300} />
        <h4>{strings.coming_soon}</h4>
      </div>
    </div>
  );
};

export default NotAvailable;
