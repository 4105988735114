import React, { Fragment } from "react";
import cx from "clsx";
import { useDispatch } from "react-redux";
import styles from "./ChevronLeadTasks.module.scss";
import { strings } from "constant/strings";
import { Avatar } from "../../../../Avatar";
import { TASKS_STATUSES_SLUGS } from "../../../../../../constant/appConstants";
import { TickIcon, CrossIcon } from "../../../../../svg-icon/svg-icons";
import IF from "../../../../IF";
import { useTaskModalContext } from "contexts/task-modal/task-modal.context";
import { fetchTaskDetail } from "actions/crm";

const ChevronLeadTasks = ({ leadTasks, leadRefNumber }) => {
  const { setTaskModalOpen } = useTaskModalContext();
  const taskDetailDispatch = useDispatch();

  return (
    <div className={cx(styles.leadTasksContainer, "d-flex flex-column mt-2")} style={{ gap: 0 }}>
      <h5 className="mb-0">{strings.tasks}</h5>
      <IF condition={!!leadTasks?.length}>
        {leadTasks?.map(taskItem => (
          <div
            key={taskItem?.id}
            className={cx(styles.taskRow)}
            onClick={() => {
              fetchTaskDetail(taskItem?.id)(taskDetailDispatch);
              setTaskModalOpen(true, taskItem?.id);
            }}
          >
            <div className="d-flex flex-row align-items-center">
              {taskItem?.status?.slug === TASKS_STATUSES_SLUGS.COMPLETED ? (
                <TickIcon size="12px" />
              ) : (
                <CrossIcon size="12px" color="#BB2025" />
              )}
              <div className="ml-2">{taskItem?.name}</div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between" style={{ gap: 6 }}>
              <div className="d-flex align-items-center" style={{ gap: 6 }}>
                <IF condition={taskItem?.assignee}>
                  <Avatar
                    avatarOnly
                    name={taskItem?.assignee?.name}
                    imgSrc={taskItem?.assignee?.picture?.url}
                    size={24}
                    round
                  />
                  <div className="text-truncate" style={{ maxWidth: "16ch" }}>
                    {taskItem?.assignee?.name}
                  </div>
                </IF>
              </div>
              <div className={styles.detailLink}>
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          </div>
        ))}
      </IF>
    </div>
  );
};

export default ChevronLeadTasks;
