import { RESET_CUSTOM_STATE, SET_VISUALIZATION } from "constant/actionTypes";

const INITIAL_STATE = {
  payload: {
    sellerConfigs: null,
    sellerStaffList: [],
    buyerConfigs: null,
    buyerStaffList: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_VISUALIZATION:
      return {
        payload: action.payload,
      };
    case RESET_CUSTOM_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
