import { Close, Permissions } from "../../common";
import React, { useEffect } from "react";
import { strings } from "../../../constant/strings";
import { uploadFile } from "../UploadAttachmentsHelpers";
import { FaSyncAlt, MdFolderOpen, MdEdit } from "../../svg-icon/svg-icons";

const FileUploadItemWithLabel = props => {
  const { canEditLabel = true, associationKey = "file", onRetry = () => {} } = props;
  useEffect(() => {
    if (props.item.uploaded === false) {
      uploadFile(
        props.item,
        "documents",
        associationKey,
        props.files,
        props.setFiles,
        false,
        false,
        undefined,
        props.label ?? null
      );
    }
  }, []);

  const onFileClick = () => {
    if (props.item?.url) {
      window.open(props.item.url, "_blank");
      return;
    }
    props.item?.file?.url && window.open(props.item.file.url, "_blank");
  };
  const onLabelClick = () => {
    props.setUploadAttachmentModal(true);
    props.setDocumentData(props.item);
  };

  return (
    <div
      className={`img-upload-wrapper hover-pointer d-flex flex-row align-items-center justify-content-between spbwx8 ${
        props?.visible ? "" : "with-label"
      }`}
    >
      <div className="d-flex flex-row align-items-center w-100">
        <div className="upload-icon">
          {props.item.uploading ? (
            <FaSyncAlt className="download-icon fa-spin" />
          ) : (
            <MdFolderOpen size={24} className="download-icon" />
          )}
        </div>
        <div className="file-name" onClick={onFileClick}>
          {props.item?.file?.name || props.item?.file?.file_name || props.item?.file_name}
          {!props.documentData ? (
            props.item?.label?.name && <div className="file-label">{props.item?.label?.name}</div>
          ) : (
            <></>
          )}
        </div>
        {props.item.inError && (
          <button className="btn btn-link" onClick={e => onRetry(e)}>
            {strings.retry}
          </button>
        )}
        {!props.documentData && props.documentData !== null && canEditLabel ? (
          <MdEdit className="btn-edit ml-auto" onClick={onLabelClick} />
        ) : (
          <></>
        )}
      </div>
      {props.canRemoveFile && !props.documentData && (
        <Close
          onClick={e => {
            e.preventDefault();
            props.removeFile(props.item);
          }}
        />
      )}
    </div>
  );
};

export default FileUploadItemWithLabel;
