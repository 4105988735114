import theme from "./theme";
import "moment/locale/ar";
import "moment/locale/en-gb";
import { AGENCY } from "./agency";

const inDevelopMode = true;
const apiBaseURL = () => (inDevelopMode ? "https://api-qa.propforce.com/api" : window.location.origin + "/api");

const agenciesConfig = {
  zameen: {
    agency: "zameen",
    apiBaseURL,
    odysseyApiURL: process.env.REACT_APP_ODYSSEY_BASE_URL + "/api",
    countryCode: "PK",
    currency: "PKR",
    countryId: 155,
    timezone: "Asia/Karachi",
    languages: [
      {
        id: 1,
        key: "en",
        label: "EN",
        jsFormat: "en-US",
        rtl: false,
        darkMode: false,
        topMenu: false,
        font: "https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600&display=swap",
      },
    ],
    locationRequired: false,
  },
  sandbox: {
    agency: "zameen",
    apiBaseURL,
    odysseyApiURL: process.env.REACT_APP_ODYSSEY_BASE_URL + "/api",
    countryCode: "PK",
    currency: "PKR",
    countryId: 155,
    timezone: "Asia/Karachi",
    languages: [
      {
        id: 1,
        key: "en",
        label: "EN",
        jsFormat: "en-US",
        rtl: false,
        darkMode: false,
        topMenu: false,
        font: "https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600&display=swap",
      },
      {
        id: 2,
        key: "ar",
        label: "AR",
        jsFormat: "en-US",
        rtl: true,
        darkMode: false,
        topMenu: false,
        locale: "ar",
        font: "https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;700&display=swap",
      },
    ],
    locationRequired: false,
  },
  bayut: {
    agency: "bayut",
    apiBaseURL,
    odysseyApiURL: process.env.REACT_APP_ODYSSEY_BASE_URL + "/api",
    countryCode: "SA",
    countryId: 177,
    currency: "SAR",
    timezone: "Asia/Riyadh",
    languages: [
      {
        id: 2,
        key: "ar",
        label: "AR",
        jsFormat: "en-US", //"ar-EG",
        rtl: true,
        darkMode: false,
        topMenu: false,
        locale: "ar",
        font: "https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;700&display=swap",
        // momentLocale: momentAr,
      },
      {
        id: 1,
        key: "en",
        label: "EN",
        jsFormat: "en-US",
        rtl: false,
        darkMode: false,
        topMenu: false,
        locale: "en",
        font: "https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600&display=swap",
        // momentLocale: momentEn,
      },
    ],
    locationRequired: false,
  },
};

const configs = agenciesConfig[AGENCY];

export { theme, configs };
