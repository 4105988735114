import React from "react";
import { isFieldEditable, isFieldViewable } from "../../helpers/Util";
import SelectLeadModal from "../crm/SelectLeadModal";
import { strings } from "../../constant/strings";
import SelectInventoryModal from "../crm/SelectInventoryModal";

const VasPreferredBuyerLead = props => {
  const {
    crmLeadPermittedAttributes = null,
    setShowPhoneNumberCTA = null,
    setAddPhoneNumbersInAPIPayload = null,
    ...rest
  } = props;
  const { values, errors, touched, handleBlur, setFieldValue, setFieldTouched } = rest;
  return (
    <div>
      <div className="border-bottom my-4">
        <h6>{strings.buyer_lead}</h6>
      </div>
      {isFieldViewable(crmLeadPermittedAttributes?.["buyer_lead.reference_number"]) && (
        <SelectLeadModal
          textRight
          type={"buyer"}
          title={strings.select_lead}
          currentValue={values.preferred_buyer_lead}
          onSelect={item => {
            setFieldValue("preferred_buyer_lead", item);
            setFieldValue("name", item?.client?.name);
            setFieldValue("phone", item?.client?.phone);
            item?.client?.secondary_phone && setFieldValue("secondary_phone", item?.client?.secondary_phone);
          }}
          onBlur={handleBlur("preferred_buyer_lead")}
          onDeselect={() => {
            setFieldValue("preferred_buyer_lead", null);
            setFieldValue("name", "");
            setFieldValue("phone", "");
            setFieldValue("secondary_phone", "");
            setFieldTouched("preferred_buyer_lead", true);
            if (props?.setShowPhoneNumberCTA) setShowPhoneNumberCTA(false);
            if (props?.setAddPhoneNumbersInAPIPayload) setAddPhoneNumbersInAPIPayload(false);
          }}
          detailsRoute={`crm_leads/${values?.crm_lead?.id}/detail`}
          errorMsg={touched?.preferred_buyer_lead && errors?.preferred_buyer_lead}
          isEditable={isFieldEditable(crmLeadPermittedAttributes?.["buyer_lead.reference_number"])}
          removeLostLead
        />
      )}
      {isFieldViewable(crmLeadPermittedAttributes?.["inventory.title"]) && (
        <SelectInventoryModal
          title={strings.select_inventory}
          canRemove={true}
          onSelect={item => {
            setFieldValue("inventory", item?.inventory);
          }}
          currentValue={values?.inventory}
          onDeselect={() => {
            setFieldValue("inventory", null);
          }}
          textRight
          isEditable={isFieldEditable(crmLeadPermittedAttributes?.["inventory.title"])}
          filterRemovedAndSold
        />
      )}
    </div>
  );
};

export default VasPreferredBuyerLead;
