import { Formik } from "formik";
import React, { useEffect, useRef } from "react";
import * as yup from "yup";
import { strings } from "../../constant/strings";
import { updateOfferMargin } from "../../helpers/ApiHelper";
import { ButtonLoading, FormInput, Modal } from "../common";

const ModalBody = ({ showModal = false, liveNegotiationOffer }) => {
  const { margin, id: negotiationOfferId, negotiated_bid } = liveNegotiationOffer;
  const marginFieldRef = useRef(null);
  useEffect(() => {
    if (showModal && marginFieldRef?.current) {
      const getFocus = marginFieldRef?.current?.focus();
    }
  }, [showModal]);

  const initialValues = {
    adjustedMargin: margin,
  };

  const adjustedMarginValidationSchema = () =>
    yup.object().shape({
      adjustedMargin: yup
        .number(strings.invalid_input)
        .positive("Margin must be a positive number")
        .required(strings.enter_value_ph)
        .test("adjusted-margin-less-than-margin", `Margin cannot be greater than or equal to ${margin}`, value => {
          if (value == margin || value > margin) return false;
          return true;
        }),
    });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        adjusted_margin: values.adjustedMargin,
      };
      const response = await updateOfferMargin({
        params: negotiationOfferId,
        payload,
      });
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={adjustedMarginValidationSchema} onSubmit={onSubmit}>
        {({ handleBlur, handleChange, values, errors, touched, isSubmitting, handleSubmit }) => {
          const errorMsg = errors.adjustedMargin && touched.adjustedMargin && errors.adjustedMargin;
          return (
            <form className="form theme-form">
              <FormInput
                name={"adjustedMargin"}
                label={"Auction Profit Margin"}
                value={values.adjustedMargin}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMsg={errorMsg}
                inputRef={marginFieldRef}
              />
              <FormInput label={"Negotiated Offer"} value={negotiated_bid - values.adjustedMargin} isEditable={false} />
              <ButtonLoading
                onClick={handleSubmit}
                disabled={isSubmitting || values.adjustedMargin == margin || errorMsg}
              >
                Adjust Margin
              </ButtonLoading>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
export const AdjustMarginModal = props => {
  const { showModal, setShowModal, auctionUser, liveNegotiationOffer } = props;

  return (
    <div>
      <Modal
        title={<div>Adjust Margin</div>}
        toggle={() => setShowModal(prev => !prev)}
        open={!!showModal}
        body={() => <ModalBody showModal={showModal} liveNegotiationOffer={liveNegotiationOffer} />}
      />
    </div>
  );
};
