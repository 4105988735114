import { strings } from "../constant/strings";

export const columnsMap = {
  // Inspection
  inspection_Lead: (dynamicFieldNames = [], dynamicFieldKeys = [], sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.name,
      strings.phone,
      strings.inspection_type,
      strings.lead_type,
      strings.city,
      strings.area,
      strings.inventory_ref,
      strings.classification,
      strings.lead_source,
      strings.assigned_to,
      strings.status,
      strings.disposition,
      strings.created_at,
      strings.created_by,
      strings.completed_at,
      strings.scheduled_at,
      strings.make,
      strings.model,
      strings.variant,
      strings.model_year,
      strings.body_type,
      strings.exterior_color,
      strings.vehicle_identification_number,
      strings.is_external,
      strings.email,
      strings.payment_link_status,
      ...dynamicFieldNames,
    ],
    queryNames: [
      "reference_number",
      "client.name",
      "client.phone",
      "inspection.name",
      "lead_type.name",
      "city.name",
      "area.name",
      "inventory.car.reference_number",
      "classification",
      "lead_source.name",
      "team.name",
      "status.name",
      "disposition.name",
      "created_at",
      "created_by.name",
      "report.completed_at",
      "schedule.start_time",
      "make.select_value",
      "model.select_value",
      "variant.select_value",
      "model_year",
      "body_type.select_value",
      "exterior_color.select_value",
      "vehicle_identification_number",
      "is_external",
      "client.email",
      "payment.payment_link.status.name",
      ...dynamicFieldKeys,
    ],
    sortNames: sortKeys,
  }),

  inspection_Report: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.lead_ref,
      strings.inventory_ref,
      strings.overall_score,
      strings.status,
      strings.disposition,
      strings.created_at,
      strings.completed_at,
      strings.payment_status,
      strings.inspection_type,
      strings.lead_types,
      strings.make,
      strings.model,
      strings.variant,
      strings.body_type,
      strings.exterior_color,
      strings.vehicle_identification_number,
      strings.requested_for,
      strings.evaluation_price,
      strings.scheduled_at,
      strings.approved_at,
      strings.approved_by,
      strings.inspector,
      strings.inspection,
      strings.lead_types,
      strings.approval_status,
    ],
    queryNames: [
      "reference_number",
      "lead.reference_number",
      "inventory.car.reference_number",
      "score",
      "status.name",
      "disposition.name",
      "created_at",
      "completed_at",
      "payment_status.name",
      "inspection.name",
      "inspection.lead_types.name",
      "make.select_value",
      "model.select_value",
      "variant.select_value",
      "body_type.select_value",
      "exterior_color.select_value",
      "vehicle_identification_number",
      "evaluation.requested_for",
      "evaluation.price",
      "lead.scheduled_at",
      "evaluation.evaluated_at",
      "evaluation.approved_by.name",
      "evaluation.evaluated_by.id",
      "inspection.name",
      "inspection.lead_types.name",
      "evaluation.status.name",
    ],

    sortNames: sortKeys,
  }),

  inspection_Payment: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.payment_type,
      strings.payment_method,
      strings.amount,
      strings.discount,
      strings.collected_at,
      strings.deposit,
      strings.deposited_at,
      strings.report,
      strings.status,
      strings.payment_link_status,
    ],
    queryNames: [
      "reference_number",
      "payment_type",
      "payment_method",
      "amount",
      "discount",
      "collected_at",
      "deposit.reference_number",
      "deposited_at",
      "report.reference_number",
      "status.name",
      "payment_link.status.name",
    ],
    sortNames: sortKeys,
  }),

  inspection_Deposit: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.amount,
      strings.created_at,
      strings.verified,
      strings.verified_at,
      strings.inspector,
      strings.payment_link_status,
    ],
    queryNames: [
      "reference_number",
      "amount",
      "created_at",
      "is_verified",
      "verified_at",
      "inspector.full_name",
      "payment_link.status.name",
    ],
    sortNames: sortKeys,
  }),

  // Insepction Admin
  inspection_Team: {
    prettyNames: [strings.ref, strings.name, strings.team_members, strings.city, strings.status, strings.inspection],
    queryNames: ["reference_number", "name", "team_members.name", "city.name", "is_active", "inspection.name"],
    sortNames: ["reference_number", "inspection.name"],
  },

  inspection_LeadType: {
    prettyNames: [strings.ref, strings.name, strings.description, strings.price],
    queryNames: ["reference_number", "name", "description", "price"],
    sortNames: ["reference_number"],
  },

  inspection_LeadType_DynamicAttributes: {
    prettyNames: [
      strings.ref,
      strings.label,
      strings.name,
      strings.data_type,
      strings.placeholder,
      strings.regex,
      strings.selected_key,
      strings.required,
    ],
    queryNames: [
      "id",
      "field_label",
      "field_name",
      "field_data_type",
      "field_placeholder",
      "field_regex",
      "select_key",
      "is_required",
    ],
    sortNames: [],
  },

  inspection_EmailTemplate: {
    prettyNames: [
      strings.ref,
      strings.event,
      strings.title,
      strings.title_translation,
      strings.message,
      strings.message_translation,
      strings.status,
    ],

    queryNames: ["reference_number", "action", "title", "title_tld", "message", "message_tld", "is_active"],
    sortNames: ["reference_number"],
  },

  inspection_InspectionProgram: {
    prettyNames: [
      strings.ref,
      strings.name,
      strings.description,
      strings.duration,
      strings.start_time,
      strings.end_time,
      strings.status,
    ],
    queryNames: [
      "reference_number",
      "name",
      "description",
      "duration",
      "service_start_time",
      "service_end_time",
      "is_active",
    ],
    sortNames: ["reference_number"],
  },
  inspection_SmsTemplate: {
    prettyNames: [strings.ref, strings.event, strings.message, strings.message_translation, strings.status],
    queryNames: ["reference_number", "action", "message", "message_tld", "is_active"],
    sortNames: ["reference_number"],
  },

  // Core
  core_User: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.name,
      strings.email,
      strings.phone,
      strings.team_assigned,
      strings.roles,
      strings.inspection,
      strings.status,
    ],
    queryNames: [
      "reference_number",
      "name",
      "email",
      "phone",
      "teams.id",
      "roles.name",
      "inspections.name",
      "is_active",
    ],
    sortNames: sortKeys,
  }),

  core_Role: (sortKeys = []) => ({
    prettyNames: [strings.ref, strings.name, strings.description, strings.status],
    queryNames: ["reference_number", "name", "description", "is_active"],
    sortNames: sortKeys,
  }),
  core_AuthenticationToken: (sortKeys = []) => ({
    prettyNames: [strings.ref, strings.name, strings.description, strings.expiry],
    queryNames: ["reference_number", "name", "description", "expires_in"],
    sortNames: sortKeys,
  }),

  core_LeadSource: (sortKeys = []) => ({
    prettyNames: [strings.ref, strings.name, strings.source, strings.medium, strings.campaign, strings.leads_count],
    queryNames: ["reference_number", "name", "source", "medium", "campaign", "leads_count"],
    sortNames: sortKeys,
  }),

  // Auction

  auction_Auction: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.inventory_,
      strings.auction_status,
      strings.start_time,
      strings.end_time,
      strings.make,
      strings.model,
      strings.variant,
      strings.model_year,
      strings.mileage,
      strings.target_price,
      strings.total_offers,
      strings.highest_offer,
      strings.highest_bidder,
      strings.groups,
      strings.negotiation_status,
      strings.negotiation_start_time,
      strings.assign_dre,
      strings.purchaser,
    ],
    queryNames: [
      "reference_number",
      "inventory.inventoriable.reference_number",
      "status.name",
      "start_time",
      "end_time",
      "inventory.inventoriable.make.select_value",
      "inventory.inventoriable.model.select_value",
      "inventory.inventoriable.variant.select_value",
      "inventory.inventoriable.model_year",
      "inventory.inventoriable.mileage",
      "target_price",
      "total_offers",
      "highest_offer.bid_amount",
      "highest_offer.bidder.name",
      "groups.name",
      "negotiation.status.name",
      "negotiation.created_at",
      "negotiation.dre.name",
      "negotiation.purchaser.name",
    ],
    sortNames: sortKeys,
  }),

  auction_Bidders: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.name,
      strings.phone,
      strings.email,
      strings.city,
      strings.groups,
      strings.user_verified,
      strings.payment_verified,
      strings.deposit_amount,
      strings.security_deposit_paid,
      strings.active,
      strings.score,
      strings.assign_dre,
    ],
    queryNames: [
      "reference_number",
      "first_name",
      "phone",
      "email",
      "auction_user_profile_city_name",
      "groups_name",
      "auction_user_profile_is_verified",
      "auction_user_profile_payment_verified",
      "auction_deposit_amount",
      "auction_security_deposit_paid",
      "is_active",
      "auction_user_profile_score",
      "assignee",
    ],
    sortNames: sortKeys,
  }),

  auction_Offers: {
    prettyNames: [
      strings.ref,
      strings.amount,
      strings.bidder_name,
      strings.bidder_phone,
      strings.archived,
      strings.created_at,
      strings.won,
      strings.auction_,
      strings.auction_status,
    ],
    queryNames: [
      "reference_number",
      "bid_amount",
      "bidder_first_name",
      "bidder_phone",
      "archived",
      "created_at",
      "won",
      "auction_reference_number",
      "auction_status_name",
    ],
    sortNames: ["reference_number", "created_at", "auction_reference_number"],
  },
  auction_Bidder_offers: {
    prettyNames: [
      strings.ref,
      strings.current_offer,
      strings.inventory_ref,
      strings.make,
      strings.model,
      strings.year,
      strings.city,
      strings.mileage,
      strings.inspection_rating,
      strings.creation_date,
    ],
    queryNames: [
      "auction.reference_number",
      "bid_amount",
      "inventory.id",
      "inventory.car.make.select_value",
      "inventory.car.model.select_value",
      "inventory.car.model_year",
      "inventory.city.name",
      "inventory.car.mileage",
      "inventory.report_score",
      "created_at",
    ],
    sortNames: [
      "reference_number",
      "current_offer",
      "inventory_id",
      "make",
      "model",
      "year",
      "city",
      "mileage",
      "inspection_rating",
      "creation_date",
    ],
  },

  auction_OfferTrails: {
    prettyNames: [
      strings.bidder_ref_no,
      strings.auction_,
      strings.buyer_name,
      strings.contact_numbers,
      strings.assigned_dre,
      strings.date_and_time,
      strings.offer,
      strings.archived,
      strings.won,
    ],
    queryNames: [
      "bidder.reference_number",
      "auction.reference_number",
      "bidder.first_name",
      "bidder.phone",
      "bidder.assignee.name",
      "created_at",
      "bid_amount",
      "archived",
      "won",
    ],
    sortNames: [],
  },
  auction_TopClients: {
    prettyNames: [strings.ref, strings.name, strings.score, strings.auction_won, strings.total_offers],
    queryNames: ["reference_number", "full_name", "score", "auctions_won", "total_offers"],
    sortNames: ["reference_number"],
  },
  // Auction Admin
  auction_Group: (sortKeys = []) => ({
    prettyNames: [strings.ref, strings.name, strings.description],
    queryNames: ["reference_number", "name", "description"],
    sortNames: sortKeys,
  }),

  auction_AuctionType: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.name,
      strings.extendable,
      strings.extension_threshold,
      strings.extension_period,
      strings.extension_count,
    ],
    queryNames: [
      "reference_number",
      "name",
      "is_extendable",
      "extension_threshold",
      "extension_period",
      "extension_count",
    ],
    sortNames: sortKeys,
  }),

  // Crm
  crm_Leads: (dynamicFieldNames = [], dynamicFieldKeys = [], sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.name,
      strings.phone,
      strings.email,
      strings.city,
      strings.assignee,
      strings.lead_category,
      strings.category_type,
      strings.lead_source,
      strings.classification,
      strings.status,
      strings.created_by,
      strings.created_at,
      strings.disposition,
      strings.call_outcome,
      ...dynamicFieldNames,
    ],
    queryNames: [
      "reference_number",
      "client.name",
      "client.phone",
      "client.email",
      "city.name",
      "assignee.name",
      "category.name",
      "category_type.name",
      "lead_source.source",
      "classification",
      "status.name",
      "created_by.name",
      "created_at",
      "disposition.name",
      "call_outcome.name",
      ...dynamicFieldKeys,
    ],
    sortNames: sortKeys,
  }),

  crm_Stock_count: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.stock_count,
      strings.location,
      strings.start_time,
      strings.end_time,
      strings.assigne,
    ],
    queryNames: ["reference_number", "count", "start_time", "end_time", "location.address", "user.name"],
    sortNames: sortKeys,
  }),
  crm_Cico: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.inventory_ref,
      strings.make,
      strings.model,
      strings.model_year,
      strings.variant,
      strings.vehicle_identification_number,
      strings.type,
      strings.reason,
      strings.location,
      strings.kilometers,
      strings.date_and_time,
    ],
    queryNames: [
      "reference_number",
      "inventory.reference_number",
      "inventory.car.make.select_value",
      "inventory.car.model.select_value",
      "inventory.car.model_year",
      "inventory.car.variant.select_value",
      "inventory.car.vehicle_identification_number",
      "cico_type.name",
      "cico_reason.name",
      "location.address",
      "inventory.car.mileage",
      "created_at",
    ],
    sortNames: sortKeys,
  }),

  crm_Task_FollowUps: {
    prettyNames: [
      strings.ref,
      "Task Ref#",
      strings.follow_up,
      strings.assignee,
      strings.status,
      strings.task_type,
      strings.task_name,
      strings.due_date,
    ],
    queryNames: [
      "reference_number",
      "task.reference_number",
      "follow_up_type",
      "assignee.id",
      "status.id",
      "task.task_type.id",
      "task.name",
      "due_date",
    ],
  },

  crm_Lead_FollowUps: {
    prettyNames: [
      strings.ref,
      strings.lead_ref,
      strings.lead_name,
      strings.lead_phone,
      strings.type,
      strings.assignee,
      strings.due_date,
      strings.status,
    ],
    queryNames: [
      "reference_number",
      "crm_lead.reference_number",
      "crm_lead.client.name",
      "crm_lead.client.phone",
      "follow_up_type",
      "assignee.id",
      "due_date",
      "status.id",
    ],
  },

  crm_Tasks: (dynamicFieldNames = [], dynamicFieldKeys = [], sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.name,
      strings.task_type,
      strings.status,
      strings.meetings,
      strings.assignee,
      strings.is_external,
      strings.created_at,
      strings.created_by,
      strings.due_date,
      ...dynamicFieldNames,
    ],
    queryNames: [
      "reference_number",
      "name",
      "task_type.name",
      "status.name",
      "appointments.status.name",
      "assignee.name",
      "is_external",
      "created_at",
      "created_by.id",
      "due_date",
      ...dynamicFieldKeys,
    ],
    sortNames: sortKeys,
  }),
  crm_Reminders: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.title,
      strings.task_ref,
      strings.task_name,
      strings.priority,
      strings.status,
      strings.date,
    ],
    queryNames: ["reference_number", "title", "task.reference_number", "task.name", "priority", "status.name", "date"],
    sortNames: sortKeys,
  }),

  crm_Locations: {
    prettyNames: [
      strings.ref,
      strings.address,
      strings.location_type,
      strings.city,
      strings.zone,
      strings.capacity,
      strings.auto_calculate_capacity_label,
      strings.capacity_updated_at,
      strings.show_up_rate,
      strings.auto_calculate_show_up_rate_label,
      strings.show_up_rate_updated_at,
    ],
    queryNames: [
      "reference_number",
      "address",
      "location_type",
      "city.name",
      "zone.name",
      "capacity_in_hours",
      "show_up_rate",
      "auto_calculate_capacity",
      "auto_calculate_show_up_rate",
      "capacity_updated_at",
      "show_up_rate_updated_at",
    ],
    sortNames: ["reference_number"],
  },

  crm_Inventory: (dynamicFieldNames = [], dynamicFieldKeys = [], sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.registration,
      strings.make,
      strings.model,
      strings.variant,
      strings.days_in_stock,
      strings.client_name,
      strings.client_phone,
      strings.status,
      strings.location,
      strings.disposition,
      strings.report_completed_at,
      strings.inspection_rating,
      strings.inventory_type,
      strings.auction_cycles,
      strings.report_present,
      strings.assembly,
      strings.mileage,
      strings.model_year,
      strings.body_type,
      strings.engine,
      strings.price,
      strings.city,
      strings.buyer_name,
      strings.buyer_phone,
      strings.negotiation_status,
      strings.negotiation_start_time,
      strings.assign_dre,
      strings.purchaser,
      strings.ad_status,
      ...dynamicFieldNames,
    ],
    queryNames: [
      "reference_number",
      "registration_no",
      "make.select_value",
      "model.select_value",
      "variant.select_value",
      "inventory.days_in_stock",
      "client.name",
      "client.phone",
      "status.name",
      "inventory.location.address",
      "disposition.name",
      "inventory.report.completed_at",
      "inventory.report.score",
      "inventory.inventory_type.name",
      "inventory.auction_cycle",
      "inventory.report_id",
      "assembly.name",
      "mileage",
      "model_year",
      "body_type.select_value",
      "engine",
      "inventory.ask_price",
      "city.name",
      "inventory.buyer_lead.client.name",
      "inventory.buyer_lead.client.phone",
      "inventory.external_ad.status.name",
      "negotiation.status.name",
      "negotiation.created_at",
      "negotiation.dre.name",
      "negotiation.purchaser.name",
      ...dynamicFieldKeys,
    ],
    sortNames: sortKeys,
  }),

  crm_Task_Type: {
    prettyNames: [
      strings.ref,
      strings.name,
      strings.appointment_required,
      strings.inventory_required,
      strings.payment_required,
      strings.follow_up_required,
      strings.start_time_required,
    ],
    queryNames: [
      "reference_number",
      "name",
      "appointment_required",
      "inventory_required",
      "payment_required",
      "follow_up_required",
      "start_time_required",
    ],
    sortNames: ["reference_number"],
  },
  crm_Clients: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.name,
      strings.phone,
      strings.email,
      strings.secondary_phone,
      strings.address,
      strings.blacklisted,
    ],
    queryNames: ["reference_number", "name", "phone", "email", "secondary_phone", "address", "is_blacklisted"],
    sortNames: sortKeys,
  }),
  crm_Client_Leads: {
    prettyNames: [
      strings.ref,
      strings.assignee,
      strings.lead_category,
      strings.category_type,
      strings.status,
      strings.created_at,
    ],
    queryNames: [
      "reference_number",
      "assignee.name",
      "category.name",
      "category_type.name",
      "status.name",
      "created_at",
    ],
    sortNames: [],
  },

  core_DynamicAttributes: {
    prettyNames: [
      strings.ref,
      strings.position,
      strings.label,
      strings.name,
      strings.data_type,
      strings.placeholder,
      strings.regex,
      strings.required,
      strings.filterable,
      strings.listable,
      strings.section,
    ],
    queryNames: [
      "reference_number",
      "display_order",
      "field_label",
      "field_name",
      "field_data_type",
      "field_placeholder",
      "field_regex",
      "is_required",
      "is_filterable",
      "is_listable",
      "section",
    ],
    sortNames: ["reference_number"],
  },

  Crm_Quotation: {
    prettyNames: [
      strings.ref,
      strings.amount,
      strings.date,
      strings.quotation_type,
      strings.buyer_name,
      strings.buyer_contact,
      strings.seller_name,
      strings.seller_contact,
      strings.generated_by,
      strings.generated_at,
    ],
    queryNames: [
      "reference_number",
      "amount",
      "date",
      "quotation_type.slug",
      "buyer_lead.client.name",
      "buyer_lead.client.phone",
      "seller_lead.client.name",
      "seller_lead.client.phone",
      "created_by.name",
      "created_at",
    ],
  },

  crm_Contract: {
    prettyNames: [
      strings.ref,
      strings.car_ref,
      strings.lead_ref,
      strings.type,
      strings.status,
      strings.disposition,
      strings.created_by,
      strings.created_at,
      strings.signed_at,
      strings.make,
      strings.model,
      strings.variant,
      strings.model_year,
      strings.mileage,
      strings.regional_spec,
      strings.client_name,
      strings.client_phone,
    ],
    queryNames: [
      "reference_number",
      "inventory.car.reference_number",
      "crm_lead.reference_number",
      "contract_type.name",
      "status.name",
      "disposition.name",
      "created_by.name",
      "created_at",
      "signed_at",
      "inventory.car.make.select_value",
      "inventory.car.model.select_value",
      "inventory.car.variant.select_value",
      "inventory.car.model_year",
      "inventory.car.mileage",
      "assembly",
      "crm_lead.client.name",
      "crm_lead.client.phone",
    ],
  },
  crm_Payment: {
    prettyNames: [
      strings.ref,
      strings.car_ref,
      strings.client,
      strings.client_phone,
      strings.make,
      strings.model,
      strings.amount,
      strings.payment_status,
      strings.verification_date,
      strings.verified_by,
      strings.payment_type,
      strings.payment_method,
      strings.deposit_time,
      strings.payment_link_status,
    ],

    queryNames: [
      "reference_number",
      "inventory.inventoriable.reference_number",
      "crm_lead.client.name",
      "crm_lead.client.phone",
      "inventory.inventoriable.make.select_value",
      "inventory.inventoriable.model.select_value",
      "amount",
      "status.name",
      "verified_at",
      "verified_by.full_name",
      "crm_payment_type.name",
      "crm_payment_method.name",
      "transaction_date",
      "payment_link.status.name",
    ],
    sortNames: ["reference_number"],
  },
  crm_Approvals: (sortKeys = []) => ({
    prettyNames: [
      strings.car_detail,
      strings.approval_type,
      strings.requested_by,
      strings.requested_at,
      strings.approved_by,
      strings.approved_at,
      strings.disposition_status,
    ],
    queryNames: [
      "reference_number",
      "approval_type.name",
      "initiated_by.name",
      "created_at",
      "approved_by",
      "approved_at",
      "status.name",
    ],
    sortNames: sortKeys,
  }),
  crm_StockCount_vehicle: {
    prettyNames: [
      strings.ref,
      strings.image,
      strings.make,
      strings.model,
      strings.variant,
      strings.vin,
      strings.model_year,
    ],
    queryNames: [
      "inventory.car.reference_number",
      "inventory.car.image",
      "inventory.car.make.name",
      "inventory.car.model.name",
      "inventory.car.variant.name",
      "inventory.car.vehicle_identification_number",
      "inventory.car.model_year",
    ],
    sortNames: [],
  },

  crm_Client_Payment: {
    prettyNames: [
      strings.ref,
      strings.payment_type,
      strings.payment_method,
      strings.client_phone,
      strings.amount,
      strings.payment_status,
    ],
    queryNames: [
      "reference_number",
      "inventory.inventoriable.reference_number",
      "inventory.inventoriable.make.select_value",
      "inventory.inventoriable.model.select_value",
      "crm_lead.client.phone",
      "amount",
      "status.name",
    ],
    sortNames: [],
  },

  client_Tax_Invoices: {
    prettyNames: [strings.ref, "Tax Invoice No", "Attachment Id", strings.make, strings.model, strings.variant],
    queryNames: [
      "attached_id",
      "tax_invoice_number",
      "inventory.inventoriable.reference_number",
      "attachment.id",
      "inventory.inventoriable.make.select_value",
      "inventory.inventoriable.model.select_value",
      "inventory.inventoriable.variant.select_value",
    ],
  },

  core_DynamicModels: (sortKeys = []) => ({
    prettyNames: [strings.ref, strings.source, strings.destination, strings.source_name],
    queryNames: ["reference_number", "source_name", "destination_name", "source_record.name"],
    sortNames: sortKeys,
  }),

  crm_DashboardReports: {
    prettyNames: [strings.ref, strings.name, strings.widgets_count],
    queryNames: ["reference_number", "name", "widgets"],
    sortNames: [],
  },

  crm_ItemLists: {
    prettyNames: [
      strings.ref,
      strings.parent_ref,
      strings.name,
      strings.type,
      strings.parent_type,
      strings.parent_name,
      strings.external_id,
    ],
    queryNames: [
      "reference_number",
      "select_key",
      "select_value",
      "parent.id",
      "parent.select_key",
      "parent.select_value",
      "external_id",
    ],
    sortNames: ["reference_number"],
  },
  auction_bookings: (sortKeys = []) => ({
    prettyNames: [
      strings.ref,
      strings.receiver_name,
      strings.receiver_phone,
      strings.amount,
      strings.created_at,
      strings.bidder_name,
      strings.car_reference_number,
      strings.make,
      strings.model,
      strings.variant,
      strings.vehicle_identification_number,
      strings.lead_reference_number,
      strings.offer_amount,
    ],
    queryNames: [
      "reference_number",
      "receiver_name",
      "receiver_phone",
      "amount",
      "created_at",
      "bidder.name",
      "inventory.car.reference_number",
      "inventory.car.make.name",
      "inventory.car.model.name",
      "inventory.car.variant.name",
      "inventory.car.vehicle_identification_number",
      "crm_lead.reference_number",
      "offer.amount",
    ],
    sortNames: sortKeys,
  }),
  user_leaves: {
    prettyNames: [
      strings.ref,
      strings.start_date,
      strings.end_date,
      strings.reason,
      strings.user,
      strings.created_by,
      strings.created_at,
    ],
    queryNames: ["reference_number", "start_time", "end_time", "reason", "user.name", "created_by.name", "created_at"],
    // sortNames: ["reference_number"],
  },
  auction_negotiations: {
    prettyNames: [
      strings.user_info,
      strings.type,
      strings.negotiated_offer,
      strings.negotiated_bid,
      strings.margin,
      strings.created_at,
      strings.updated_at,
    ],
    queryNames: [
      "negotiation_offers.owner.full_name",
      "negotiation_offers.negotiation_offer_type",
      "negotiation_offers.negotiated_offer",
      "negotiation_offers.negotiated_bid",
      "negotiation_offers.margin",
      "negotiation_offers.created_at",
      "negotiation_offers.updated_at",
    ],
    sortNames: [],
  },

  crm_calls: {
    prettyNames: [
      strings.ref,
      strings.call_src,
      strings.association_info,
      strings.client_info,
      strings.call_type,
      strings.status,
      strings.initiated_by,
      strings.created_at,
      strings.evaluated_by,
      strings.evaluated_date,
      strings.recording_qc_status,
      strings.qc_score,
      strings.call_recording,
    ],

    queryNames: [
      "reference_number",
      "",
      "crm_lead.reference_number",
      "user.name",
      "call_type",
      "status.name",
      "assignee.name",
      "created_at",
      "source_url",
      "report.evaluated_by.name",
      "report.evaluated_at",
      "report.score",
      "recording_qc_status",
    ],
    sortNames: ["reference_number"],
  },
  crm_ad_fields_mapper: {
    prettyNames: [strings.field_id, strings.external_id, strings.name, strings.external_name, strings.source_name],
    queryNames: ["field_id", "external_id", "name", "external_name", "source_name"],
  },
  crm_stock_list: ({ dynamicFieldKeys = [], sortKeys = [], extraPrettyNames = [] }) => ({
    prettyNames: [
      strings.car_detail,
      strings.inv_type,
      strings.last_price,
      strings.service_package,
      strings.warranty_package,
      strings.location,
      strings.disposition_status,
      strings.stats,
      ...extraPrettyNames,
    ],
    queryNames: [
      "reference_number",
      "inventory.inventory_type.name",
      "inventory.ask_price",
      ...dynamicFieldKeys,
      "location",
      "status.name",
      "stats",
    ],
    sortNames: [
      "reference_number",
      "inventory.inventory_type.name",
      "inventory.ask_price",
      ...sortKeys,
      "location",
      "status.name",
      "stats",
    ],
  }),

  crm_duplication_leads: {
    prettyNames: [strings.ref, strings.car_details, strings.category, strings.status_disp, strings.risks],
    queryNames: ["reference_number", "car_details", "category", "status", "disposition", "risks"],
  },
};
