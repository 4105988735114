export const PAYMENT_FLOW_KEYS = {
  "buyer-full-payment": {
    seller: false,
    buyer: true,
    vas: false,
  },
  "buyer-deposit-refund": {
    seller: false,
    buyer: true,
    vas: false,
  },
  "buyer-full-payment-refund": {
    seller: false,
    buyer: true,
    vas: false,
  },
  "seller-sign-up-fee": {
    seller: true,
    buyer: false,
    vas: false,
  },
  "advanced-inspection": {
    seller: true,
    buyer: true,
    vas: true,
  },
  "buyer-deposit-cash": {
    seller: false,
    buyer: true,
    vas: false,
  },
  "buyer-deposit-seller-loan-clearance": {
    seller: false,
    buyer: true,
    vas: false,
  },
  "buyer-deposit-buyer-finance": {
    seller: false,
    buyer: true,
    vas: false,
  },
  "buyer-deposit-seller-buyer-finance": {
    seller: false,
    buyer: true,
    vas: false,
  },
  "auction-car-booking": {
    seller: false,
    buyer: true,
    vas: false,
  },
  "registration-test": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "export-test": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "retest-fee": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "rta-comprehensive-test": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "export-and-registration-test": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "fleet-charge": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "double-fleet-charge": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "transfer-of-ownership-charges": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "hayaza-fee-possession-certificate": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "export-certificate": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "traffic-fines": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "plates-saving-fee": {
    seller: true,
    buyer: true,
    vas: false,
  },
  "managed-service-fee": {
    seller: false,
    buyer: true,
    vas: false,
  },
  "buyer-concierge-silver-payment": {
    seller: false,
    buyer: true,
    vas: false,
    inventoryRequired: false,
  },
  "buyer-concierge-silver-refund": {
    seller: false,
    buyer: true,
    vas: false,
    inventoryRequired: false,
  },
  "buyer-concierge-gold-payment": {
    seller: false,
    buyer: true,
    vas: false,
    inventoryRequired: false,
  },
  "buyer-concierge-gold-refund": {
    seller: false,
    buyer: true,
    vas: false,
    inventoryRequired: false,
  },
};

export const PAYMENT_DISPOSITIONS = {
  DEPOSITED: "deposited",
  VERIFIED: "verified",
  PENDING: "pending",
  LINK_CREATED: "link-created",
};

export const PAYMENT_STATUSES = {
  ISSUE: "issue",
  VERIFIED: "verified",
  TO_BE_COLLECTED: "to-be-collected",
  DEPOSITED: "deposited",
};

export const PAYMENT_TYPE = {
  MISC: "miscellaneous",
};

export const ADYEN_PAYMENT_LINK = { name: "Adyen Payment Link", slug: "adyen-payment-link" };

export const ROUTE_TO_PERMISSION_MAP = {
  PAYMENT: "Payment",
  CRMPAYMENT: "CrmPayment",
  DEPOSIT: "Deposit",
};
