import { PERMISSIONS } from "../../../constant/permissions";
import { getPermission } from "../../../helpers/Util";

const getQCSummaryData = QCReportData => {
  const { score, answer_groups = [] } = QCReportData;
  return {
    score,
    sectionWiseRating: answer_groups
      .reduce((acc, curr) => {
        const { name, id, score = 0, weightage: total = 0 } = curr;
        acc.push({
          name,
          total,
          id,
          score,
        });
        return acc;
      }, [])
      .filter(Boolean),
  };
};

const createQCReportPermission = () =>
  getPermission(PERMISSIONS.EvaluationReport.NAME, PERMISSIONS.EvaluationReport.ACTIONS.CREATE);

const updateQCReportPermission = () =>
  getPermission(PERMISSIONS.EvaluationReport.NAME, PERMISSIONS.EvaluationReport.ACTIONS.UPDATE);

const viewQCDetailPermission = () =>
  getPermission(PERMISSIONS.EvaluationReport.NAME, PERMISSIONS.EvaluationReport.ACTIONS.READ);

export { getQCSummaryData, createQCReportPermission, updateQCReportPermission, viewQCDetailPermission };
