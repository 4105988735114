import { fetchChevronDispositionData, fetchChevronUsersData } from "actions/crm";
import { useSelector, useDispatch } from "react-redux";
import { selectLeadsChevronDispositionsData, selectLeadsChevronOptions } from "reducers/crm/selectors";
import { useHistory } from "react-router";
import { mapQueryStringToFilterObject } from "helpers/Util";
import {
  checkAllFiltersApplied,
  getChevronDispositionQueryString,
  getChevronUsersQueryString,
} from "helpers/CrmLeadsChevronUtils";

const useRefreshChevron = () => {
  const history = useHistory();
  const chevronUsersDispatch = useDispatch();
  const leadDispositionDispatch = useDispatch();
  const chevronOptions = useSelector(selectLeadsChevronOptions);
  const data = useSelector(selectLeadsChevronDispositionsData);
  const { chevronDispositions } = data;
  const { chevronLeadDispositions = [] } = chevronOptions;

  const getCurrentLimit = (dispositionSlug = "") => {
    const dispositionColumnData = chevronDispositions?.[dispositionSlug?.slug];
    if (dispositionColumnData?.list?.length > dispositionColumnData?.limit) return dispositionColumnData?.list?.length;
    return dispositionColumnData?.limit;
  };

  const getExtraFiltersConfig = (dispositionSlug = "", currentLimit, queryObj = {}) => {
    const extraFiltersConfig = [
      { keySlug: "f[disposition.slug][]", value: dispositionSlug?.slug },
      !queryObj["sort[created_at]"] ? { keySlug: "sort[created_at]", value: "asc" } : {},
      { keySlug: "limit", value: currentLimit },
    ];
    return extraFiltersConfig;
  };

  const refreshChevronDataCallback = () => {
    const { queryObj } = mapQueryStringToFilterObject(history.location.search);
    if (checkAllFiltersApplied(queryObj) && chevronLeadDispositions?.length) {
      const usersQuery = getChevronUsersQueryString(history);
      fetchChevronUsersData(usersQuery, false)(chevronUsersDispatch);
      chevronLeadDispositions.map(dispositionSlug => {
        const currentLimit = getCurrentLimit(dispositionSlug);
        const searchQuery = getChevronDispositionQueryString(
          history,
          getExtraFiltersConfig(dispositionSlug, currentLimit, queryObj)
        );
        fetchChevronDispositionData(
          dispositionSlug?.slug,
          searchQuery,
          false,
          false,
          currentLimit
        )(leadDispositionDispatch);
      });
    }
  };

  return {
    refreshChevronDataCallback,
  };
};

export default useRefreshChevron;
