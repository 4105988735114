export * from "constant/Common/Filters";
export * from "constant/Common/KITT";
export * from "constant/Common/PrivateField";

export const CHATTER_ACTIONS = {
  new_chatter_crm_lead: { tabIndex: 1 },
  new_chatter_task: { tabIndex: 1 },
  new_chatter_car: { tabIndex: 1 },
  tagged_user: {
    tabIndex: 4,
  },
};

export const DASHBOARD_WIDGET_TYPES = {
  BAR_CHART: "bar-chart",
  LINE_CHART: "line-chart",
  PIE_CHART: "pie-chart",
  TABLE: "table",
  SCORE_CARD: "score-card",
};

export const DYNAMIC_FIELD_TYPES = Object.freeze({
  STRING: "string",
  TEXT: "text",
  INTEGER: "integer",
  PRICE: "price",
  SINGLE_SELECT: "single-select",
  MULTI_SELECT: "multi-select",
  DATE_TIME: "datetime",
  DATE: "date",
  BOOLEAN: "boolean",
  ATTACHMENT: "attachment",
  LINK: "link",
  USER_LIST: "user_list",
  ITEM_LIST: "item_list",
});

export const DYNAMIC_FIELD_NAMES = Object.freeze({
  VIN: "vehicle_identification_number",
  PURCHASE_PRICE: "purchase_price",
  EMIRATES_ID_NUMBER: "emirates_id_number",
  VEHICLE_REQUIREMENTS: "vehicle_requirements",
  CUSTOMER_TRN: "customer_trn",
  CUSTOMER_SIGNATURE: "customer_signature_cico",
});

export const CALENDAR_QUERY_KEYS = Object.freeze({
  SELECTOR_USER_KEY: "f[user_id][]",
  START_TIME_KEY: "f[start_time][gte]",
  END_TIME_KEY: "f[end_time][lte]",
  TASK_TYPE_KEY: "f[task.task_type_id]",
  INSPECTOR_USER_KEY: "f[inspectors.id][]",
});

export const ASSIGNEE_KEYS = Object.freeze({
  CRM_LEAD: "CRM_LEAD",
  CRM_TASK: "CRM_TASK",
  INSPECTION_REPORTS: "INSPECTION_REPORT",
});

export const PERMITTED_ATTRIBUTE_MODELS = Object.freeze({
  CAR: "Car",
  TASK: "Task",
  CRM_LEAD: "CrmLead",
  INSPECTION_LEAD: "Lead",
  QUOTATION: "Quotation",
  CICO: "Cico",
  CONTRACT: "Contract",
  RECORDING: "Recording",
});

export const RESTRICTED_MODULES = Object.freeze({
  APPROVALS: "approvals",
  CICOS: "cicos",
  CHEVRON: "chevron_view",
  STOCK_COUNTERS: "stock_counters",
  BULK_REASSIGN_CRM_LEADS: "bulk_reassign_crm_leads",
  BULK_REASSIGN_LEADS: "bulk_reassign_leads",
  BULK_IMPORT_CRM_LEADS: "bulk_import_crm_leads",
  EXTERNAL_AD: "external_ad",
  QUOTATIONS: "quotations",
  BOOKING: "booking",
  SALES_STOCK: "sales_stock",
  ALLOCATION: "allocation_rule",
  FOLLOW_UP: "follow_up",
  CONTRACT: "contract",
  ROTA: "rota",
  LEAVE: "leaves",
  KITT: "kitt",
  VAS: "vas",
  RECORDING: "recording",
  AUCTION_MARGIN_SLABS: "auction_margin_slabs",
  RETAIL_PRICE_REDUCTION: "retail_price_reduction",
  WHATSAPP_TRIGGER_CONTROLLER: "whatsapp_trigger_controller",
  FIELD_MAPPING: "field_mapping",
});
export const DYNAMIC_MODELS = Object.freeze({
  CAR: "car",
  CRM_LEAD: "crm_lead",
  INSPECTION_LEAD: "lead",
  INSPECTION_LEAD_TYPE: "lead_type",
  CATEGORY: "category",
  CATEGORY_TYPE: "category_type",
  CICO: "cico",
  CONTRACT: "Contract",
  CONTRACT_TYPE: "contract_type",
});

export const MODULE_TYPE = Object.freeze({
  CAR: "car",
  CRM_LEAD: "crm_lead",
  INSPECTION_LEAD: "lead",
  INSPECTION_LEAD_TYPE: "lead_type",
  CRM_TASK: "crm_task",
});

export const WIDGET_TYPES = Object.freeze({
  BAR_CHART: "bar-chart",
  PIE_CHART: "pie-chart",
  SCORE_CARD: "score-card",
  TABLE: "table",
});

export const RESTRICTED = "Restricted";

export const DUBIZZLE = "dubizzle";

export const LANGUAGE_ABBREVIATIONS = {
  English: "en",
  Arabic: "ar",
  Urdu: "ur",
  Hindi: "hi",
  Tagalog: "tl",
};

export const ATTACHMENT_TYPES = {
  INVENTORY: "Inventory",
};

export const COMMENTABLE_TYPES = {
  CAR: PERMITTED_ATTRIBUTE_MODELS.CAR,
  CRM_LEAD: PERMITTED_ATTRIBUTE_MODELS.CRM_LEAD,
  TASK: PERMITTED_ATTRIBUTE_MODELS.TASK,
};

export const LINK_STATUS = {
  ACTIVE: "active",
  EXPIRED: "expired",
};
export const SCROLLABLE_ELEMENTS_KEYS = {
  MODAL_TABLE_KEY: "modal-table",
  TASK_FORM_KEY: "task-form-body",
};
