import React from "react";
import cx from "clsx";
import IconWithBg from "components/common/IconWithBackground/icon-with-bg";
import { ClockChevron } from "components/svg-icon/svg-icons";
import styles from "../ChevronLeadCard.module.scss";
import Tooltip from "components/common/Tooltip";

const ChevronCardLocation = ({ categoryType, chevronAppointment, locationTooltipId = "" }) => {
  return (
    <div className={cx("mt-2 d-flex flex-row align-items-center", styles.cardText)}>
      <IconWithBg icon={<ClockChevron />} borderRadius="50%" />
      <div className="d-flex flex-row align-items-center item-separator-dot" style={{ "--separator-color": "#D0D5DD" }}>
        <div>{categoryType?.name ?? ""}</div>
        <div data-tip data-for={locationTooltipId}>
          <div>{chevronAppointment?.area?.city?.name} </div>
          <div className="m-l-5 text-truncate" style={{ maxWidth: "13ch" }}>
            {chevronAppointment?.area?.name}
          </div>
          <Tooltip id={locationTooltipId}>
            <div className="d-flex align-items-center">
              {chevronAppointment?.area?.city?.name}
              <div className="separator-dot" />
              {chevronAppointment?.area?.name}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ChevronCardLocation;
