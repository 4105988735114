import React from "react";
import styles from "./index.module.scss";
import cx from "clsx";

const IconWithBg = props => {
  const { className, icon, bgColor, containerSize, borderColor, borderRadius, style } = props;

  return (
    <div
      className={cx(styles.iconContainer, className)}
      style={{
        "--bg-color": bgColor,
        "--container-size": containerSize,
        "--border": borderColor,
        "--border-radius": borderRadius,
        ...style,
      }}
    >
      {icon}
    </div>
  );
};

export default IconWithBg;
