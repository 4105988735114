import React, { useState } from "react";
import { Flex, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { fetchPopoverApi } from "helpers/ApiHelper";
import { convertToHMSString } from "utils";
import { Skeleton } from "components/common";

export default function ReportPopover({ value, path, elementId, userId }) {
  const history = useHistory();
  const [popoverData, setPopoverData] = useState({
    max_interaction_time: {
      value: "",
    },
    min_interaction_time: {
      value: "",
    },
  });

  async function fetchPopoverData() {
    const urlParams = new URLSearchParams(history.location.search);
    const startTimeFromUrl = urlParams.get("start_time");
    const endTimeFromUrl = urlParams.get("end_time");

    // If not present in the URL, use default values (1st of current month and today)
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const startDate = startTimeFromUrl || firstDayOfMonth.toISOString().split("T")[0];
    const endDate = endTimeFromUrl || today.toISOString().split("T")[0];
    const res = await fetchPopoverApi(
      `${path}?drill_down_element_id=${elementId}&parent_key=${userId}&start_time=${startDate}&end_time=${endDate}`
    );
    setPopoverData(res.data);
    setLoading(false);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const content = (
    <>
      <Flex vertical gap={4}>
        <Flex gap={8} style={{ color: "#313131" }}>
          <div>
            <strong>{"Max Interaction Time: "}</strong>{" "}
            {loading ? (
              <Skeleton width={60} height={15} />
            ) : (
              convertToHMSString(popoverData?.max_interaction_time.value)
            )}
          </div>
        </Flex>
        <Flex gap={8} style={{ color: "#313131" }}>
          <div>
            <strong>{`Min Interaction Time: `}</strong>{" "}
            {loading ? (
              <Skeleton width={60} height={15} />
            ) : popoverData.min_interaction_time.value < 60 ? (
              `${popoverData.min_interaction_time.value}s`
            ) : (
              convertToHMSString(popoverData.min_interaction_time.value)
            )}
          </div>
        </Flex>
      </Flex>
    </>
  );

  return (
    <Popover
      placement={"bottomRight"}
      content={content}
      trigger="click"
      open={isOpen}
      onClick={e => {
        setLoading(true);
        fetchPopoverData();
        e.stopPropagation();
      }}
      onOpenChange={() => setIsOpen(!isOpen)}
      overlayStyle={{ "--ant-popover-z-index-popup": "1060" }}
    >
      <div className="cursor-pointer" styles={{ flexShrink: 0 }}>
        {value}
      </div>
    </Popover>
  );
}
