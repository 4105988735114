import React, { useState } from "react";

import { DynamicList } from ".";
import { Modal } from ".";
import { capitalCase } from "change-case";
import { convertQueryObjToString } from "../../helpers/Util";
import { strings } from "../../constant/strings";

const DynamicListSelectModal = ({ visible, setVisible, onSelect, levels, type, variant = "" }) => {
  const [selected, setSelected] = useState({});

  const onDone = obj => {
    onSelect(obj);
    setVisible(false);
    setSelected({});
  };

  const getParams = (currentValue, parent) =>
    type === "generic_list"
      ? convertQueryObjToString({
          type: currentValue,
          ...(!!parent ? { parent_id: parent + "" } : {}),
        })
      : convertQueryObjToString({
          "q[select_key_in][]": currentValue,
          ...(!!parent ? { "q[ancestry_eq]": parent + "" } : {}),
        });

  const getQueryParams = (e, i) => {
    if (!i) {
      return `?${getParams(e)}`;
    } else if (selected[levels[i - 1]]) {
      return `?${getParams(e, selected[levels[i - 1]]?.id)}`;
    } else {
      return "";
    }
  };

  return (
    <Modal
      toggle={() => {
        setVisible(!visible);
      }}
      open={visible}
      className={`${"modal-lg deposit_model crm-modal"} ${variant}`}
      body={() => {
        return (
          <div className="modal-wrapper d-flex flex-row">
            {levels.map((e, i) => (
              <DynamicList
                title={capitalCase(e)}
                params={getQueryParams(e, i)}
                onSelect={item => {
                  selected[e] = item;
                  setSelected({ ...selected });
                  if (i === levels.length - 1) onDone(selected);
                }}
                searchKey={e}
                type={type}
                key={e}
              />
            ))}
          </div>
        );
      }}
      actions={[
        {
          label: strings.cancel,
          onClick: () => {
            setVisible(false);
          },
          color: "secondary",
          className: "modal__cancel-btn btn-pill",
        },
      ]}
    />
  );
};

export default DynamicListSelectModal;
