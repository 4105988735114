import React from "react";
import cx from "clsx";
import styles from "./card.module.scss";
import propTypes from "prop-types";

const Card = ({ className, children, cardBg, cardPadding, cardBorderRadius, style, viewable = true, ...rest }) => {
  const cardStyles = (React.CSSProperties = {
    "--card-bg": cardBg,
    "--card-padding": cardPadding,
    "--card-border-radius": cardBorderRadius,
  });
  if (!viewable) return null;

  return (
    <div className={cx(className, styles.card)} style={{ ...cardStyles, ...style }} {...rest}>
      {children}
    </div>
  );
};

Card.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
  cardBg: propTypes.string,
  cardPadding: propTypes.string,
  cardBorderRadius: propTypes.string,
  style: propTypes.object,
};

export default Card;
