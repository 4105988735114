import React, { useEffect, useRef, useState } from "react";
import {
  getCurrency,
  getPermission,
  getTimeDateString,
  isFieldRestricted,
  isFieldViewable,
  routingCarEdit,
} from "../../helpers/Util";
import { AddToAuctionForm } from "../forms";
import AuctionDetailCardItem from "./AuctionDetailCardItem";
import { GoPlus } from "../svg-icon/svg-icons";
import Modal from "../common/ModalBase";
import addToAuctionIllustration from "../../assets/images/addToAuctionCardImg.svg";
import { connect, useSelector } from "react-redux";
import { strings } from "../../constant/strings";
import { Button, ButtonLoading } from "../common";
import { selectActiveColumns } from "../../reducers/general/selectors";
import styles from "./AuctionDetailCard.module.scss";
import cx from "clsx";
import Card from "../common/Card";
import Group from "../common/Group";
import Countdown from "react-countdown";
import { AuctionActions } from "./index";
import { fetchAuctionCriteriaState } from "../../helpers/ApiHelper";

const AuctionDetailCard = ({ car, fetchCar, auction, country, history }) => {
  const [showAddToAuction, setShowAddToAuction] = useState(false);
  const [showAddToAuctionCriteriaProps, setShowAddToAuctionCriteriaProps] = useState({
    isOpen: false,
    data: { errors: [] },
    loading: false,
  });
  const [addToAuctionLoading, setAddToAuctionLoading] = useState(false);
  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const auctionPermittedAttributes = activeColumnPermittedAttributes.Auction;
  const {
    isOpen,
    data: { errors = [] },
    loading,
  } = showAddToAuctionCriteriaProps;
  // auction = MOCK.completed;
  // auction = MOCK.live;
  // auction = MOCK.upcoming;
  // auction = MOCK.cancelled;
  useEffect(() => {}, [auction]);

  const inventoryId = car?.inventory?.id;
  let formRef = useRef();
  const isLive = auction?.status?.name === "Live";
  const hasWinner = auction?.status?.name === "Completed" && !!auction?.winner;
  const isAvailable = e => !isFieldRestricted(e) && auction[e] !== undefined && auction[e] !== null;

  const canInventoryBeAddedToAuction = () => auction?.status?.slug !== "live" && auction?.status?.slug !== "upcoming";

  const noAuctionCard = !auction && !(getPermission("Auction", "create") && canInventoryBeAddedToAuction());
  if (noAuctionCard) return null;

  return (
    <>
      <Card cardPadding="20px">
        {!!auction && (
          <>
            <div className={"d-flex align-items-center justify-content-between"}>
              <div className={cx(styles.headingStatusWrapper, "mb-3 d-flex align-items-center")}>
                <span className={styles.heading}>Auction Details</span>
                <span className={styles.dotSeparator}></span>
                <span
                  className={styles.status}
                  style={{
                    "--status-color": getStatusColor(auction),
                  }}
                >
                  {auction?.status?.name}
                </span>
                {hasWinner && (
                  <>
                    <span className={styles.dotSeparator}></span>
                    <span
                      className={styles.status}
                      style={{
                        "--status-color": "#F79009",
                      }}
                    >
                      Auction Won
                    </span>
                  </>
                )}
              </div>
              {auction?.status?.name !== "Cancelled" && <AuctionActions auction={auction} />}
            </div>
            <Group template={"repeat(3, 1fr)"} gap={isLive ? ".5rem" : "1rem .75rem"}>
              {isAvailable("opening_offer") && isFieldViewable(auctionPermittedAttributes?.["opening_offer"]) && (
                <AuctionDetailCardItem
                  title={strings.starting_price}
                  subTitle={getCurrency(country, auction["opening_offer"])}
                  key="opening_offer"
                />
              )}
              {isAvailable("highest_offer") &&
                isFieldViewable(auctionPermittedAttributes?.["highest_offer.bidder.first_name"]) && (
                  <AuctionDetailCardItem
                    title={strings.bidder_name}
                    subTitle={[
                      auction["highest_offer"]?.bidder?.first_name,
                      auction["highest_offer"]?.bidder?.last_name,
                    ].join(" ")}
                    key="bidder_name"
                  />
                )}
              {isAvailable("start_time") && isFieldViewable(auctionPermittedAttributes?.["start_time"]) && (
                <AuctionDetailCardItem
                  title={strings.start_time}
                  subTitle={getTimeDateString(auction["start_time"], country.timezone, true)}
                  key="start_time"
                />
              )}
              {isAvailable("highest_offer") &&
                isFieldViewable(auctionPermittedAttributes?.["highest_offer.bidder.phone"]) && (
                  <AuctionDetailCardItem
                    title={strings.bidder_ph}
                    subTitle={auction["highest_offer"]?.bidder?.phone}
                    key="bidder_name"
                  />
                )}
              {isAvailable("highest_offer") &&
                !isFieldRestricted(auction?.highest_offer?.bid_amount) &&
                isFieldViewable(auctionPermittedAttributes?.["highest_offer.bid_amount"]) && (
                  <AuctionDetailCardItem
                    title={strings.highest_offer}
                    subTitle={getCurrency(country, auction["highest_offer"]?.bid_amount)}
                    key="bid_amount"
                  />
                )}
              {isAvailable("end_time") && isFieldViewable(auctionPermittedAttributes?.["end_time"]) && (
                <AuctionDetailCardItem
                  title={strings.end_time}
                  subTitle={getTimeDateString(auction["end_time"], country.timezone, true)}
                  key="end_time"
                  style={{ "--sub-title-color": "#D92D20" }}
                />
              )}
              {isAvailable("total_offers") && isFieldViewable(auctionPermittedAttributes?.["total_offers"]) && (
                <AuctionDetailCardItem title={"No.of Offers"} subTitle={auction["total_offers"]} key="total_offers" />
              )}
            </Group>
            {isLive && (
              <>
                <Countdown
                  date={new Date(auction?.end_time)}
                  renderer={({ total, days, hours, minutes, seconds }) => {
                    if (total) {
                      return timer(days, hours, minutes, seconds);
                    } else {
                      return timer(0, 0, 0, 0);
                    }
                  }}
                />
              </>
            )}
          </>
        )}

        {/*{getPermission("Auction", "create") ? (*/}
        {/*  <AddToAuctionButton*/}
        {/*    again={auction?.status?.slug === "completed" || auction?.status?.slug === "cancelled"}*/}
        {/*    setShowAddToAuction={setShowAddToAuction}*/}
        {/*  />*/}
        {/*) : null}*/}

        {getPermission("Auction", "create") && canInventoryBeAddedToAuction() ? (
          //  && car?.inventory?.can_add_in_auction === true
          <AddToAuctionButton
            again={auction?.status?.slug === "completed" || auction?.status?.slug === "cancelled"}
            setShowAddToAuction={setShowAddToAuction}
            setShowAddToAuctionCriteriaProps={setShowAddToAuctionCriteriaProps}
            inventoryId={inventoryId}
            loading={loading}
          />
        ) : null}

        <Modal
          toggle={() => {
            setShowAddToAuction(!showAddToAuction);
          }}
          title={strings.add_to_auction}
          open={showAddToAuction}
          body={() => {
            return (
              <AddToAuctionForm
                ref={formRef}
                showAddToAuction={showAddToAuction}
                closeDialog={() => setShowAddToAuction(!showAddToAuction)}
                toggleAddToAuctionLoading={setAddToAuctionLoading}
                inventoryId={car?.inventory?.id}
                auctionTypeId={null}
                fetchCar={fetchCar}
                openingOffer={car.inventory?.ask_price || ""}
                timeZone={country.timezone}
              />
            );
          }}
          actions={[
            {
              label: strings.cancel,
              onClick: () => {
                setShowAddToAuction(false);
              },
              color: "secondary",
              className: "modal__cancel-btn btn-pill",
            },
            {
              label: strings.create,
              color: "primary",
              className: "modal__cr-btn btn-pill",
              onClick: () => {
                if (formRef && formRef.current) formRef.current.submitForm();
              },
              disabled: addToAuctionLoading,
              loading: addToAuctionLoading,
            },
          ]}
        />
      </Card>

      <Modal
        toggle={() => {
          setShowAddToAuctionCriteriaProps(prev => ({
            ...prev,
            isOpen: !prev.isOpen,
          }));
        }}
        title={strings.auction_creation_criteria}
        open={isOpen}
        body={() => {
          return (
            <>
              <h5 className={cx("f-w-600", styles.auctionCriteriaHeader)}>{strings.auction_error_criteria_info}</h5>
              <ul
                className={cx("grid scroll-y")}
                style={{
                  "--template": "repeat(2,1fr)",
                  "--gap": "15px",
                  paddingInlineStart: "24px",
                  "--box-height": "350px",
                }}
              >
                {errors?.map((error, key) => (
                  <>
                    <li className={styles.errorList} key={key}>
                      {error}
                    </li>
                  </>
                ))}
              </ul>
            </>
          );
        }}
        actions={[
          {
            label: strings.edit_inventory,
            color: "primary",
            className: "modal__cr-btn btn-pill",
            onClick: () => {
              history.push({
                pathname: routingCarEdit(car),
                state: {
                  crmLead: car?.inventory?.crm_lead,
                  platform: window.location.href.includes("auction") ? "auction" : "crm",
                },
              });
            },
          },
        ]}
      />
    </>
  );
};

const TimeElement = ({ label, value, showColons }) => (
  <li>
    <span>{value < 10 ? "0" + value : value}</span>
    <p>{label}</p>
  </li>
);

const timer = (days, hours, minutes, seconds) => (
  <ul className={"timer_list"}>
    <TimeElement label={"Days"} value={days} showColons />
    <TimeElement label={"Hrs"} value={hours} showColons />
    <TimeElement label={"Mins"} value={minutes} showColons />
    <TimeElement label={"Sec"} value={seconds} />
  </ul>
);

const getStatusColor = (auction = {}) => {
  switch (auction?.status?.name) {
    case "Upcoming":
      return "#3a52b2";
    case "Live":
      return "#f2494e";
    case "Completed":
      return "#19a229";
    case "Cancelled":
      return "#f2494e";
    default:
      return;
  }
};

const AddToAuctionButton = ({ again, setShowAddToAuction, setShowAddToAuctionCriteriaProps, inventoryId, loading }) => {
  const getAuctionCriteria = async () => {
    setShowAddToAuctionCriteriaProps(prev => ({
      ...prev,
      loading: true,
    }));
    const response = await fetchAuctionCriteriaState({
      params: `?inventory_id=${inventoryId}`,
    });

    setShowAddToAuctionCriteriaProps(prev => ({
      ...prev,
      data: response,
      loading: false,
    }));

    return response;
  };

  const handleAddToAuctionBtnClick = async () => {
    const { success = false } = await getAuctionCriteria();
    if (success) {
      setShowAddToAuction(true);
    } else {
      setShowAddToAuctionCriteriaProps(prev => ({
        ...prev,
        isOpen: !prev.isOpen,
      }));
    }
  };
  if (!again)
    return (
      <div className={cx("d-flex align-items-center justify-content-between", styles.defaultCard)}>
        <div>
          <div className="mb-4">
            <h6 className={cx(styles.heading, "mb-2")}>{strings.inventory_ready_for_desc}</h6>
            <p className={styles.desc}>{strings.add_to_auction_desc}</p>
          </div>

          <ButtonLoading
            loading={loading}
            icon={<GoPlus />}
            color="monochrome"
            size="sm"
            variant=""
            className="border-0"
            onClick={handleAddToAuctionBtnClick}
          >
            {strings.add_to_auction}
          </ButtonLoading>
        </div>
        <img src={addToAuctionIllustration} alt={strings.preview_not_available} className={styles.img} />
      </div>
    );
  return (
    <>
      <ButtonLoading
        loading={loading}
        icon={<GoPlus />}
        color="monochrome"
        size="sm"
        variant=""
        className="border-0 mt-3"
        onClick={handleAddToAuctionBtnClick}
      >
        {strings.add_to_auction_again}
      </ButtonLoading>
    </>
  );
};

const mapStateToProps = ({ Configs }) => {
  return {
    country: Configs.tenant.country,
  };
};

export default connect(mapStateToProps, {})(AuctionDetailCard);
