import LegendItem from "./LegendItem";
import React from "react";
import { strings } from "../../constant/strings";

export const CalenderModalHeader = ({
  date,
  view,
  views,
  label,
  DateLocalizer,
  onNavigate,
  onView,
  children,
  statuses,
  loading,
}) => {
  return (
    <>
      <div className="lead-calendar-modal-header d-flex align-items-center justify-content-between px-24 py-16 mb-4">
        <div className="fc-button-group btn-group btn-group-sm">
          <button
            type="button"
            className="fc-today-button fc-button btn btn-outline-primary"
            onClick={() => onNavigate("TODAY")}
            disabled={loading}
            // disabled={props.today.getTime() === props.selectedDate.getTime()}
          >
            {strings.today}
          </button>
          <button
            type="button"
            className="fc-prev-button fc-button btn btn-outline-primary"
            aria-label="prev"
            onClick={() => onNavigate("PREV")}
            disabled={loading}
          >
            <i className="icofont icofont-arrow-left"></i>
          </button>
          <button
            type="button"
            className="fc-next-button fc-button btn btn-outline-primary"
            aria-label="next"
            onClick={() => onNavigate("NEXT")}
            disabled={loading}
          >
            {/* {strings.next} */}
            <i className="icofont icofont-arrow-right"></i>
          </button>
        </div>
        <div className="fc-center">
          <h4 className="mb-0">{label}</h4>
        </div>
        <div className="fc-button-group btn-group btn-group-sm">
          <button
            type="button"
            className="fc-next-button fc-button btn btn-outline-primary"
            aria-label="prev"
            onClick={() => onView("month")}
            disabled={loading}
          >
            {strings.month}
          </button>
          {/* <button
            type="button"
            className="fc-next-button fc-button btn-outline-primary"
            aria-label="next"
            onClick={() =>  toolbar.onView('week')}>
            {strings.week}
          </button> */}
          <button
            type="button"
            className="fc-next-button fc-button btn btn-outline-primary"
            aria-label="next"
            onClick={() => onView("day")}
            disabled={loading}
          >
            {strings.day}
          </button>
        </div>
      </div>
      <div className="d-flex flex-wrap mb-4 spbwx16" id="legendItemWrapper">
        {[...statuses, { id: 0, name: strings.not_available, bg_color: "lightgray", color: "gray" }].map(item => (
          <LegendItem key={item.id} children={item.name} size="1.2em" color={item.bg_color} strokeColor={item.color} />
        ))}
      </div>
    </>
  );
};

export default CalenderModalHeader;
