import {
  ADD_TASK,
  ADD_TASK_FILTERS,
  CLEAR_TASK,
  FETCH_TASKS,
  UPDATE_TASK,
  UPDATE_TASK_MEETING,
  UPDATE_TASKS_REMINDERS,
  UPDATE_FOLLOW_UPS,
  SET_TASKS_LOADING,
} from "../../constant/actionTypes";

import { fetchTasksApi } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

export const clearTasks = () => dispatch => {
  dispatch({
    type: CLEAR_TASK,
  });
};

export const addTask = item => dispatch => {
  dispatch({
    type: ADD_TASK,
    payload: {
      item: { ...item },
    },
  });
};

export const updateFollowUps = followUp => dispatch => {
  dispatch({
    type: UPDATE_FOLLOW_UPS,
  });
};

export const updateTask = item => dispatch => {
  dispatch({
    type: UPDATE_TASK,
    payload: {
      item: { ...item },
    },
  });
};

export const updateTaskMeetings = (taskId, appointments) => dispatch => {
  dispatch({
    type: UPDATE_TASK_MEETING,
    payload: {
      taskId,
      appointments,
    },
  });
};
export const updateTasksReminders = (taskId, reminders) => dispatch => {
  dispatch({
    type: UPDATE_TASKS_REMINDERS,
    payload: {
      taskId,
      reminders,
    },
  });
};

export const fetchTasks = queryString => async dispatch => {
  dispatch({ type: SET_TASKS_LOADING, payload: { loading: true } });
  const res = await fetchTasksApi(queryString);
  if (res) {
    const { tasks, restrictions, ...rest } = res;
    if (tasks) {
      dispatch({
        type: FETCH_TASKS,
        payload: {
          ...rest,
          list: tasks,
          loading: false,
          error: "",
          restrictions: restrictions,
        },
      });
    } else {
      dispatch({
        type: FETCH_TASKS,
        payload: {
          list: [],
          statuses: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  }
};

export const addTaskFilters = item => dispatch => {
  dispatch({
    type: ADD_TASK_FILTERS,
    payload: {
      item: { ...item },
    },
  });
};
