import cx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import { strings } from "constant/strings";
import { getCurrency } from "helpers/Util";
import { Status } from "components/common";

const AmountCalculation = ({
  leadTypePrice,
  price,
  discount,
  calculatedValue = true,
  setValue = () => {},
  ...rest
}) => {
  const country = useSelector(content => content.Configs.tenant.country);
  setValue(calculatedValue ? getCurrency(country, leadTypePrice - price - discount) : getCurrency(country, price));
  return (
    <>
      <div className={cx("d-flex flex-1 justify-content-between align-items-center p-b-15")}>
        <div>{strings.method_of_payment}</div>
        <div className="font-weight-bold value">{rest?.paymentMethod} </div>
      </div>
      <div className="d-flex flex-1 justify-content-between align-items-center p-b-15">
        <div>{strings.payment_status}</div>
        <div>
          <Status {...rest?.paymentStatus} />
        </div>
      </div>
      <div className="d-flex flex-1 align-items-center justify-content-between p-b-15">
        <div>{strings.gross_total} </div>
        <div className="font-weight-bold value">{leadTypePrice}</div>
      </div>
      <div className="d-flex flex-1 justify-content-between align-items-center p-b-15">
        <div>{strings.discount} </div>
        <div className="font-weight-bold value">{discount ? discount : 0}</div>
      </div>
    </>
  );
};

export default AmountCalculation;
