import cx from "clsx";
import { MODELS_FOR_VIEW_PHONE_NUMBER_API, PHONE_NUMBER_CATEGORIZATION_PER_PAGE } from "constant/appConstants";
import moment from "moment";
import React from "react";
import { Portal } from "react-portal";
import { useSelector } from "react-redux";
import { strings } from "../../constant/strings";
import { isObjRestricted, setTimeZone, shortenString } from "../../helpers/Util";
import { extractPayloadFromClientObj } from "../../helpers/ViewPhoneNumber";
import { Skeleton, Status, Tooltip } from "../common";
import { Avatar } from "../common/Avatar";
import RenderPrivateField from "../common/RenderPrivateField/RenderPrivateField";
import styles from "./DayEvent.module.scss";

const DayEvent = ({
  event,
  view,
  onClick = () => {},
  loading,
  height,
  inShowMoreTooltip = false,
  hourlyFormattedDate,
  calendarAssignEvent,
  users,
  fromCalendarAssignEvent,
  associatedId = null,
}) => {
  const { id, reference_number, status = {}, task = {} } = event;

  const timezone = useSelector(content => content.Configs.tenant.country.timezone);
  let startTime = setTimeZone(timezone, event.start_time);
  startTime = moment(startTime).format("hh:mm A, MMM D, YYYY");

  let endTime = setTimeZone(timezone, event.end_time);
  endTime = moment(endTime).format("hh:mm A, MMM D, YYYY");
  const selectedEventUser = fromCalendarAssignEvent ? event?.user : users?.filter(e => e?.id === event?.user?.id)?.[0];

  const renderZones = () => {
    return (
      <li>
        <small className="d-block">{strings.assignee_zones}</small>
        {selectedEventUser?.zones?.map(e => e.name).join(", ")}
      </li>
    );
  };

  return loading ? (
    <div key={event.start.toString()} style={{ backgroundColor: "#E0E0E0", zIndex: -1 }}>
      <Skeleton themeProps={{ color: "#E0E0E0", highlightColor: "#FAFAFA" }} height={height} duration={2} />
    </div>
  ) : (
    <>
      <div
        key={`${id}-${reference_number}`}
        className="d-flex flex-column align-items-start"
        id={`pop-${id}-${reference_number}`}
        onClick={() => {
          onClick(id, task.id);
        }}
        data-tip
        data-for={`pop-${id}-${reference_number}`}
      >
        {inShowMoreTooltip ? (
          <ShowMore
            taskName={event.task?.name}
            hourlyFormattedDate={hourlyFormattedDate}
            color={event.color}
            bgColor={event.bg_color}
          />
        ) : view === "day" && !calendarAssignEvent ? (
          <span
            onClick={onClick}
            key={event.id}
            className="badge-status cursor-pointer"
            style={{ "--badge-bg": event.bg_color, "--badge-color": event.color }}
          >
            {shortenString(event.task?.name, 17, 14, "...")}
          </span>
        ) : (
          <span className="text-truncate w-100 d-block">{shortenString(event.task?.name, 17, 14, "...")}</span>
        )}
      </div>

      <Portal>
        <Tooltip
          eventOff="mouseleave"
          clickable={true}
          delayHide={50}
          className={cx("meeting-tooltip", styles.toolTipCustomClass)}
          id={`pop-${id}-${reference_number}`}
          backgroundColor="white"
          textColor="black"
        >
          <>
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="mb-0 meeting-title" style={{ "--meeting-bg-color": event.bg_color }}>
                {event.task?.task_type?.name}
              </h6>{" "}
              {status && <Status className="mr-0 border-0" {...status} />}
            </div>
            <div className="meeting-info">
              <ul>
                <li>
                  <small className="d-block">Task Name</small>
                  {event.task?.name}
                </li>
                <li>
                  <small className="d-block">Lead Ref</small>
                  {event.task?.crm_lead?.reference_number}
                </li>

                {renderZones()}
                <li>
                  <small className="d-block">{strings.car_ref}</small>
                  {event.inventory?.car?.reference_number}
                </li>
                <li>
                  <small className="d-block">{strings.car_detail}</small>
                  {event.inventory?.title}
                </li>
              </ul>

              <BuyerSellerDetail
                event={event}
                hidePhoneNumberProps={{
                  associatedType: MODELS_FOR_VIEW_PHONE_NUMBER_API.TASK_PHONE_KEY,
                  associatedId: event?.task_id || associatedId,
                  addDropdownToContainer: false,
                }}
              />
              <MeetingTime startTime={startTime} endTime={endTime} />
            </div>
            <UserDetail selectedEventUser={selectedEventUser} />
          </>
        </Tooltip>
      </Portal>
    </>
  );
};

const UserDetail = ({ selectedEventUser }) => {
  return (
    <div className="d-flex align-items-center">
      <div>
        <Avatar className="calender-avatar" name={selectedEventUser?.name} size={24} round="12px" avatarOnly />
      </div>
      <div>
        {selectedEventUser?.name} <small className="d-flex">{getUserRole(selectedEventUser)}</small>
      </div>
    </div>
  );
};

const getUserRole = selectedEventUser => {
  const roles = selectedEventUser?.roles;
  if (!roles || roles?.length <= 0) return "";
  return roles.reduce((acc, curr) => `${acc}${acc !== "" ? "," : ""} ${curr.name}`, "");
};

const ShowMore = ({ taskName, bgColor, color, hourlyFormattedDate }) => {
  return (
    <div className="badge-status cursor-pointer mr-0" style={{ "--badge-bg": bgColor, "--badge-color": color }}>
      {taskName}
      <span className="d-block">{hourlyFormattedDate}</span>
    </div>
  );
};
const BuyerSellerDetail = ({ event, hidePhoneNumberProps = null }) => {
  return (
    <>
      <small className="d-block mt-3">{strings.buyer_seller_detail}</small>
      {event.buyer_client?.name && (
        <div className="d-flex align-items-center my-3">
          <div>
            <Avatar className="calender-avatar" name={event.buyer_client?.name} size={24} round="12px" avatarOnly />
          </div>
          <div>
            {event.buyer_client?.name} <small> ({strings.buyer})</small>
            <small className="d-flex">
              {!isObjRestricted(event.buyer_client, ["phone"]) ? (
                !!hidePhoneNumberProps ? (
                  <RenderPrivateField
                    component={PHONE_NUMBER_CATEGORIZATION_PER_PAGE.TASK.CALENDAR.BUYER_DETAIL}
                    uuid={event.buyer_client.uuid}
                    {...extractPayloadFromClientObj({ item: event.buyer_client })}
                    {...hidePhoneNumberProps}
                  />
                ) : (
                  event.buyer_client?.phone
                )
              ) : null}
            </small>
          </div>
        </div>
      )}

      {event.seller_client?.name && (
        <div className="d-flex align-items-center my-3">
          <div>
            <Avatar className="calender-avatar" name={event.seller_client?.name} size={24} round="12px" avatarOnly />
          </div>
          <div>
            {event.seller_client?.name} <small> ({strings.seller})</small>
            <small className="d-flex">
              {!isObjRestricted(event.seller_client, ["phone"]) ? (
                !!hidePhoneNumberProps ? (
                  <RenderPrivateField
                    component={PHONE_NUMBER_CATEGORIZATION_PER_PAGE.TASK.CALENDAR.BUYER_DETAIL}
                    uuid={event?.seller_client?.uuid}
                    {...extractPayloadFromClientObj({ item: event?.seller_client })}
                    {...hidePhoneNumberProps}
                  />
                ) : (
                  event.seller_client?.phone
                )
              ) : null}
            </small>
          </div>
        </div>
      )}
    </>
  );
};
const MeetingTime = ({ startTime, endTime }) => {
  return <small>{`${startTime}-${endTime}`} </small>;
};
export default DayEvent;
