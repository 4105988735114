import * as yup from "yup";

import { AsyncSelect, CustomAlert, DateSelect, FormInput, FormSelect, SelectionListTypeHead, Toast } from "../common";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  addAuctionApi,
  fetchAuctionTypesApi,
  fetchGroupsApi,
  getTargetPricePoliciesApi,
} from "../../helpers/ApiHelper";
import {
  addPermittedProperty,
  appendTimeZone,
  getErrorString,
  isFieldEditable,
  isFieldViewable,
  makeListToSelectData,
  selectAllGroupField,
  setTimeZone,
} from "../../helpers/Util";

import { Formik } from "formik";
import moment from "moment";
import { strings } from "../../constant/strings";
import { selectActiveColumns, selectTimeZone } from "../../reducers/general/selectors";
import { useSelector } from "react-redux";
import FormRow from "../common/FormRow";

const AddToAuctionForm = forwardRef((props, ref) => {
  const { closeDialog, showAddToAuction, toggleAddToAuctionLoading, inventoryId, fetchCar, openingOffer } = props;

  const timeZone = useSelector(selectTimeZone);
  const [groupOptions, setGroupOptions] = useState([]);

  const [groups, setGroups] = useState([]);
  const [groupsFetchLoading, setGroupsFetchLoading] = useState(false);
  const [groupsFetchError, setGroupsFetchError] = useState("");

  const [auctionTypes, setAuctionTypes] = useState([]);
  const [auctionTypesFetchLoading, setAuctionTypesFetchLoading] = useState(false);
  const [auctionTypesFetchError, setAuctionTypesFetchError] = useState("");
  const [targetPricePolicies, setTargetPricePolicies] = useState([]);
  const [targetPricePoliciesLoading, setTargetPricePoliciesLoading] = useState(false);

  const [targetPricePoliciesError, setTargetPricePoliciesError] = useState("");
  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const auctionPermittedAttributes = activeColumnPermittedAttributes?.Auction;

  let formikRef = useRef();

  useImperativeHandle(ref, () => ({
    submitForm() {
      formikRef.current.submitForm();
    },
  }));

  useEffect(() => {
    if (showAddToAuction) {
      fetchUserGroups();
      fetchAuctionTypes();
      fetchTargetPricePolicies();
    }
  }, [showAddToAuction]);

  const fetchTargetPricePolicies = async () => {
    setTargetPricePoliciesLoading(true);
    const response = await getTargetPricePoliciesApi();
    if (response) {
      setTargetPricePoliciesLoading(false);
      if (response.success) {
        setTargetPricePolicies(response.target_price_policies);
      } else {
        setTargetPricePoliciesError(getErrorString(response));
      }
    }
  };

  const fetchUserGroups = async () => {
    setGroupsFetchLoading(true);
    const response = await fetchGroupsApi();
    if (response) {
      setGroupsFetchLoading(false);
      if (response.success) {
        setGroups(response.groups);
      } else {
        setGroupsFetchError(getErrorString(response));
      }
    }
  };

  const fetchAuctionTypes = async () => {
    setAuctionTypesFetchLoading(true);
    const response = await fetchAuctionTypesApi();
    if (response) {
      setAuctionTypesFetchLoading(false);
      if (response.success) {
        setAuctionTypes(response.auction_types);
      } else {
        setAuctionTypesFetchError(getErrorString(response));
      }
    }
  };

  const validationSchema = () =>
    yup.object().shape({
      ...addPermittedProperty(auctionPermittedAttributes?.["target_price"], {
        targetPrice: yup
          .string(strings.enter_number_message)
          .required(strings.target_price_message)
          .test("targetPrice", strings.enter_number_message, value => !isNaN(value)),
      }),
      ...addPermittedProperty(auctionPermittedAttributes?.["offer_jump"], {
        offerIncrement: yup
          .number(strings.enter_number_message)
          .required(strings.offer_jump_message)
          .test("offer-jump-no-negative-values", strings.no_negative_values_are_allowed, value => value > 0),
      }),
      ...addPermittedProperty(auctionPermittedAttributes?.["opening_offer"], {
        startOffer: yup
          .number(strings.enter_number_message)
          .required(strings.start_offer_msg)
          .test("opening-offer-no-negative-values", strings.no_negative_values_are_allowed, value => value > 0),
      }),
      ...addPermittedProperty(auctionPermittedAttributes?.["start_time"], {
        startTime: yup.string().required(strings.start_date_message),
      }),
      ...addPermittedProperty(auctionPermittedAttributes?.["end_time"], {
        endTime: yup.string().required(strings.end_date_message),
      }),
      ...addPermittedProperty(auctionPermittedAttributes?.["auction_type_id"], {
        auctionType: yup.string().required(strings.select_auction_type),
      }),
      ...addPermittedProperty(auctionPermittedAttributes?.["groups.name"], {
        groups: yup
          .array()
          .of(
            yup
              .object()
              .required(strings.select_group_message)
              .test("group", strings.select_valid_group_message, value => value && value.name)
          )
          .required(strings.select_group_message),
      }),
    });

  const getInitialValues = () => ({
    ...addPermittedProperty(auctionPermittedAttributes?.["target_price"], {
      targetPrice: "",
    }),
    ...addPermittedProperty(auctionPermittedAttributes?.["opening_offer"], {
      startOffer: "",
    }),
    ...addPermittedProperty(auctionPermittedAttributes?.["offer_jump"], {
      offerIncrement: "",
    }),
    ...addPermittedProperty(auctionPermittedAttributes?.["start_time"], {
      startTime: "",
    }),
    ...addPermittedProperty(auctionPermittedAttributes?.["end_time"], {
      endTime: "",
    }),
    ...addPermittedProperty(auctionPermittedAttributes?.["auction_type_id"], {
      auctionType: "",
    }),
    ...addPermittedProperty(auctionPermittedAttributes?.["groups.name"], {
      groups: "",
    }),
  });

  const onSubmit = async (values, { setStatus }) => {
    setStatus({ message: "" });
    const { groups, auctionType, startTime, endTime, startOffer, offerIncrement, targetPrice } = values;
    const auctionData = {
      auction: {
        ...addPermittedProperty(
          auctionPermittedAttributes?.["target_price"],
          {
            target_price: targetPrice,
          },
          true
        ),
        ...addPermittedProperty(
          auctionPermittedAttributes?.["opening_offer"],
          {
            opening_offer: startOffer,
          },
          true
        ),
        ...addPermittedProperty(
          auctionPermittedAttributes?.["offer_jump"],
          {
            offer_jump: offerIncrement,
          },
          true
        ),
        ...addPermittedProperty(
          auctionPermittedAttributes?.["groups.id"],
          {
            ...selectAllGroupField(groups),
          },
          true
        ),
        ...addPermittedProperty(
          auctionPermittedAttributes?.["inventory_id"],
          {
            inventory_id: inventoryId,
          },
          true
        ),
        ...addPermittedProperty(
          auctionPermittedAttributes?.["auction_type_id"],
          {
            auction_type_id: auctionType,
          },
          true
        ),
        ...addPermittedProperty(
          auctionPermittedAttributes?.["start_time"],
          {
            start_time: appendTimeZone(startTime, timeZone),
          },
          true
        ),
        ...addPermittedProperty(
          auctionPermittedAttributes?.["end_time"],
          {
            end_time: appendTimeZone(endTime, timeZone),
          },
          true
        ),
      },
    };

    toggleAddToAuctionLoading(true);
    try {
      const response = await addAuctionApi(auctionData);
      if (response) {
        toggleAddToAuctionLoading(false);
        if (response.auction) {
          fetchCar && fetchCar();
          props.onSuccess && props.onSuccess();
          Toast.success(`New Auction Added Successfully `);
          closeDialog();
        } else {
          setStatus({ message: getErrorString(response) });
          Toast.error(getErrorString(response));
        }
      }
    } catch (error) {
      toggleAddToAuctionLoading(false);
      Toast.error(getErrorString(error));
      closeDialog();
    }
  };

  const isNumberBetweenRange = (start, end, input) => {
    if (!start) {
      return input <= end;
    } else if (!end) {
      return input >= start;
    } else {
      return input >= start && input <= end;
    }
  };
  const percentageOfTotalValue = (value, totalValue) => ((value / 100) * totalValue).toFixed(2);

  const handleStartingOfferChange = (input, formikProps) => {
    const inputValue = parseInt(input.target.value) || "";

    const index = targetPricePolicies?.findIndex(e =>
      isNumberBetweenRange(parseInt(e.starting_price), parseInt(e.ending_price), inputValue)
    );
    const targetPolicy = targetPricePolicies[index];
    const percentageValue = percentageOfTotalValue(targetPolicy?.starting_percentage, inputValue);
    const data = {
      targetPrice: inputValue,
      ...(index !== -1 && { startOffer: percentageValue }),
      ...(index !== -1 && { offerIncrement: targetPolicy?.offer_increment }),
    };
    Object.keys(data).forEach(e => formikProps.setFieldValue(e, data[e]));
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {formikProps => {
        const { values, errors, touched, handleChange, handleBlur, status, setFieldValue } = formikProps;
        return (
          <form className="form theme-form">
            {isFieldViewable(auctionPermittedAttributes?.["target_price"]) && (
              <FormInput
                label={strings.target_price}
                placeholder={strings.target_price_msg}
                onChange={e => handleStartingOfferChange(e, formikProps)}
                value={values.targetPrice}
                errorMsg={errors.targetPrice && touched.targetPrice && errors.targetPrice}
                isEditable={isFieldEditable(auctionPermittedAttributes?.["target_price"])}
              />
            )}
            {isFieldViewable(auctionPermittedAttributes?.["opening_offer"]) && (
              <FormInput
                label={strings.start_offer}
                placeholder={strings.start_offer}
                value={values.startOffer}
                type={"number"}
                onChange={e => setFieldValue("startOffer", e.target.value)}
                errorMsg={errors.startOffer && touched.startOffer && errors.startOffer}
                isEditable={isFieldEditable(auctionPermittedAttributes?.["opening_offer"])}
              />
            )}
            {isFieldViewable(auctionPermittedAttributes?.["offer_jump"]) && (
              <FormInput
                label={strings.offer_increment}
                placeholder={strings.offer_increment}
                value={values.offerIncrement}
                type={"number"}
                onChange={e => setFieldValue("offerIncrement", e.target.value)}
                errorMsg={errors.offerIncrement && touched.offerIncrement && errors.offerIncrement}
                isEditable={isFieldEditable(auctionPermittedAttributes?.["offer_jump"])}
              />
            )}
            {isFieldViewable(auctionPermittedAttributes?.["start_time"]) && (
              <DateSelect
                onChange={e => {
                  setFieldValue("startTime", e);
                }}
                selected={values.startTime}
                label={strings.start_date_and_time}
                dateFormat="MMMM d, yyyy h:mm aa"
                filterDate={date => {
                  return moment() < date;
                }}
                filterTime={date => {
                  return setTimeZone(timeZone) < date;
                }}
                minDate={null}
                minTime={null}
                maxTime={null}
                placeholderText={strings.select_date}
                timeIntervals={5}
                timeZone={timeZone}
                errorMsg={errors.startTime && touched.startTime && errors.startTime}
                isEditable={isFieldEditable(auctionPermittedAttributes?.["start_time"])}
              />
            )}
            {isFieldViewable(auctionPermittedAttributes?.["end_time"]) && (
              <DateSelect
                onChange={e => {
                  setFieldValue("endTime", e);
                }}
                selected={values.endTime}
                label={strings.end_date_and_time}
                dateFormat="MMMM d, yyyy h:mm aa"
                filterDate={date => {
                  return moment() < date;
                }}
                filterTime={date => {
                  return setTimeZone(timeZone, values.startTime) < setTimeZone(timeZone, date);
                }}
                minDate={null}
                minTime={null}
                maxTime={null}
                placeholderText={strings.select_date}
                timeIntervals={5}
                timeZone={timeZone}
                errorMsg={errors.endTime && touched.endTime && errors.endTime}
                isEditable={isFieldEditable(auctionPermittedAttributes?.["end_time"])}
              />
            )}

            {isFieldViewable(auctionPermittedAttributes?.["auction_type_id"]) && (
              <FormSelect
                placeholder={strings.auction_type_ph}
                label={strings.auction_type}
                onBlur={handleBlur("auctionType")}
                value={values.auctionType}
                errorMsg={errors.auctionType && touched.auctionType && errors.auctionType}
                options={makeListToSelectData(auctionTypes)}
                onChange={e => {
                  handleChange("auctionType")(e);
                }}
                setSlugAsValue
                isEditable={isFieldEditable(auctionPermittedAttributes?.["auction_type_id"])}
              />
            )}
            {isFieldViewable(auctionPermittedAttributes?.["groups.name"]) && (
              <FormRow textRight label={strings.groups} columnLeftSection={3} columnRightSection={9}>
                <AsyncSelect
                  isClearable={true}
                  listApi={fetchGroupsApi}
                  listProperty={"groups"}
                  isFitler
                  isMulti={true}
                  placeholder={strings.groups}
                  getDataInParent={data => {
                    setGroupOptions(data);
                  }}
                  prependOptions={[
                    {
                      name: "Select all Groups",
                      id: "select_all",
                      slug: "select_all",
                    },
                  ]}
                  errorMsg={!!errors.groups && !!touched.groups && errors.groups}
                  selected={values.groups ? makeListToSelectData([...values.groups]) : []}
                  onChange={selected => {
                    setFieldValue("groups", selected);
                  }}
                  isDisabled={!isFieldEditable(auctionPermittedAttributes?.["groups.name"])}
                />
              </FormRow>
            )}

            {status && status.message && <CustomAlert message={status.message} />}
          </form>
        );
      }}
    </Formik>
  );
});

export default AddToAuctionForm;
