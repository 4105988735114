import { generateUUID } from "../../helpers/Util";
import FileUploadHelper from "../../services/FileUploadHelper";

export const onFilesAdded = (files, setFiles, newFiles) => {
  const filesData = files?.concat(
    Array.from(newFiles).map(item => {
      return {
        id: generateUUID(),
        file: item,
        uploaded: false,
        uploading: true,
        inError: false,
      };
    })
  );
  if (filesData?.length > 1) {
    filesData.sort((a, b) => {
      return a.id < b.id ? 1 : -1;
    });
  }
  setFiles(filesData);
};

export const updateImage = (files, setFiles, file, updated) => {
  const filesData = [...files];
  for (let item of filesData) {
    if (file.id == item.id) {
      item = Object.assign(item, updated);
    }
  }
  setFiles([...filesData]);
};

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const uploadFile = async (
  item,
  attachmentType,
  associationKey,
  files,
  setFiles,
  makeBlob = false,
  isSvg = false,
  updateDocument = null,
  labelId = null,
  shouldResize = true
) => {
  const { file, uploaded } = item;
  if (uploaded === true) return;
  const fileUploader = new FileUploadHelper();
  let isImageFile = isSvg ? false : file.type.includes("image");

  let fileData = file;
  if (isImageFile && !isSvg) {
    fileData = shouldResize ? await fileUploader.resize(file) : await toBase64(file);
  }
  let presignedUrlResponse;
  try {
    presignedUrlResponse = await fileUploader.getUUIDForLocalFile(attachmentType, associationKey, file.name, labelId);
  } catch (e) {
    const data = { id: item.id, uploaded: false, uploading: false, inError: true };
    updateImage(files, setFiles, item, data);
  }
  try {
    const res = await fileUploader.uploadFileOnS3(fileData, presignedUrlResponse, isImageFile);
    if (res) {
      if (updateDocument) updateDocument(item, presignedUrlResponse.id);
      else {
        if (makeBlob) {
          const b64 = await fetch(fileData);
          const b64Blob = await b64.blob();
          const data = {
            id: presignedUrlResponse.id,
            uploaded: true,
            uploading: false,
            inError: false,
            localPreview: URL.createObjectURL(b64Blob),
          };
          updateImage(files, setFiles, item, data);
        } else if (isSvg) {
          let reader = new FileReader();
          reader.onload = function (ev) {
            const data = {
              id: presignedUrlResponse.id,
              uploaded: true,
              uploading: false,
              inError: false,
              localPreview: ev.target.result,
            };
            updateImage(files, setFiles, item, data);
          };
          reader.readAsDataURL(fileData);
        } else {
          const data = {
            id: presignedUrlResponse.id,
            uploaded: true,
            uploading: false,
            inError: false,
          };
          updateImage(files, setFiles, item, data);
        }
      }
    }
  } catch (e) {
    const data = {
      uploaded: false,
      uploading: false,
      inError: true,
    };
    updateImage(files, setFiles, item, data);
  }
};
