import React, { useEffect, useState } from "react";

import LocationSelectModal from "./LocationSelectModal";
import { Chip, Skeleton, BtnDefaultState } from "../";
import { strings } from "../../../constant/strings";
import { ImLocation } from "../../svg-icon/svg-icons";

const LocationSelect = props => {
  const { onlyBtn } = props;
  const [modalShow, setModalShow] = useState(false);
  const [chipValue, setChipValue] = useState();

  useEffect(() => {
    setChipValue(props.value);
  }, [props.value]);

  useEffect(() => {
    return () => {
      setChipValue(null);
    };
  }, []);
  const getChipValue = value => {
    return `${value?.city?.name}${value?.zone?.name ? ", " + value?.zone?.name : ""}${
      value?.area?.name ? ", " + value?.area?.name : ""
    }`;
  };
  const renderChip = value => (
    <div className="chip__filter-row mb-0">
      <Chip
        title={getChipValue(value)}
        titleContainerClassName="text-nowrap"
        onClose={() => {
          props.onDeselect();
        }}
      />
    </div>
  );
  const renderButton = (title, value) => {
    return (
      <>
        {props.loading ? (
          <Skeleton height={30} />
        ) : (
          <>
            {chipValue ? (
              renderChip(chipValue)
            ) : (
              <BtnDefaultState
                className={`custom-field-state b-r-6 area-select${props.className}`}
                btnText={`Select ${title}`}
                Icon={ImLocation}
                onClick={() => {
                  setModalShow(true);
                }}
              />
            )}
          </>
        )}
      </>
    );
  };
  const renderField = (title, levels, value, key) => (
    <>
      {onlyBtn ? (
        renderButton(title)
      ) : (
        <div className="form-group row align-items-center">
          <label className={`col-sm-3 col-form-label text-right`}>
            {props.loading ? <Skeleton height={20} /> : title}
          </label>
          <div className="col-sm-9 d-flex align-items-center">{renderButton(title)}</div>
          {!!props.errorMsg && props.errorType !== "icon" && (
            <div className="col-sm-9 offset-sm-3">
              <p className={"invalid-error " + props.errorClassName}>{props.errorMsg}</p>
            </div>
          )}
        </div>
      )}
    </>
  );

  const onSelect = e => {
    setChipValue(e);
    props.onChange(e);
  };

  const renderLocationModal = () => {
    return (
      <LocationSelectModal
        child={props.child}
        visible={modalShow}
        setVisible={setModalShow}
        onSelect={onSelect}
        city={props.city}
      />
    );
  };
  return (
    <>
      {renderLocationModal()}
      {props.loading ? <Skeleton height={20} /> : renderField(props.title ? props.title : strings.location)}
    </>
  );
};

export default LocationSelect;
