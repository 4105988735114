import React from "react";

export const Loader = ({ type, style }) => {
  switch (type) {
    case "line":
      return (
        <div className="d-flex justify-content-center">
          <div className="loader">
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
          </div>
        </div>
      );

    default:
      return (
        <div className="d-flex justify-content-center" style={{ ...style }}>
          <span className="rotate dotted"></span>
        </div>
      );
  }
};
