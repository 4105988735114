import React from "react";
import CustomVisibilitySensor from "react-visibility-sensor";

function VisibilitySensor(props) {
  return props.data?.length ? (
    props.data.map((item, index) => {
      const { Component } = item;
      return (
        <CustomVisibilitySensor key={index}>
          {({ isVisible }) => {
            if (!Component) return null;
            return <Component isVisible={isVisible} {...item} />;
          }}
        </CustomVisibilitySensor>
      );
    })
  ) : (
    <></>
  );
}

export default VisibilitySensor;
