import cx from "clsx";
import { Formik } from "formik";
import { fetchUserOptions } from "helpers/userHelper";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import { Button, ButtonLoading, CustomAlert, DateFilter, PageTitle, Toast } from "../../components/common";
import FormRow from "../../components/common/FormRow";
import { PaginatedSearch } from "../../components/common/PaginatedSearch";
import { BiCopy, IoMdAdd } from "../../components/svg-icon/svg-icons";
import { CRM_ROTA_PLAN_PATH } from "../../constant/appPaths";
import { strings } from "../../constant/strings";
import { createShiftsApi, fetchShifts, getCurrentUser } from "../../helpers/ApiHelper";
import { ScrollToFieldError } from "../../helpers/FormikHelpers";
import {
  addSeconds,
  appendTimeZone,
  clone,
  formatDateWithMonthAndDayName,
  getDaysBetweenDates,
  getErrorString,
  getUTCOffset,
  isEmptyArray,
  setTimeZone,
  subtractSeconds,
} from "../../helpers/Util";
import AddShiftContainer from "./AddShiftContainer";
import styles from "./CrmAddSchedule.module.scss";

const getShiftEntitySchema = () => {
  let schemaObj = {
    min_time: yup.string().nullable().required("Please select starting time"),
    max_time: yup
      .string()
      .nullable()
      .required("Please select ending time")
      .test("min-time-less-than-max-time", "End time should be greater than start time", function (value) {
        const { min_time } = this.parent || {};
        if (min_time && value) {
          return moment(min_time, "h:mm A").isBefore(moment(value, "h:mm A"));
        }
        return true;
      })
      .test("max-time-less-than-min-time", "Start time should be less than end time", function (value) {
        const { min_time } = this.parent || {};
        if (min_time && value) {
          return moment(value, "h:mm A").isAfter(moment(min_time, "h:mm A"));
        }
        return true;
      })
      .test("min-time-plus-30-mins", "End time should be at least 1 hour greater than start time", function (value) {
        const { min_time } = this.parent || {};
        if (min_time && value) {
          const startTime = moment(min_time, "h:mm A");
          const endTime = moment(value, "h:mm A");
          const duration = moment.duration(endTime.diff(startTime));
          const minutesDifference = duration.asMinutes();
          return minutesDifference > 30;
        }
        return true;
      }),
    zones: yup
      .array()
      .required("Please select zones for the shift")
      .of(yup.object().shape({ name: yup.string(strings.select_zone) })),
  };
  return yup.object().shape({ ...schemaObj });
};

const CrmAddSchedule = props => {
  const tenant = useSelector(content => content.Configs.tenant);
  const timezone = tenant.country.timezone;
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [scheduleData, setScheduleData] = useState([]);
  const paramsDate = new URLSearchParams(location?.search);
  const initialStartTime = appendTimeZone(paramsDate.get("q[start_time_gteq]"), timezone);
  const initialEndTime = appendTimeZone(paramsDate.get("q[start_time_lteq]"), timezone);
  const userIdFromSearch = paramsDate.get("q[user_id_in][]");
  const selectedUsers = location?.state?.selectedUsers;
  const selectedRoles = location?.state?.selectedRoles;
  const roleParams = selectedRoles?.length > 0 && selectedRoles?.map(role => `q[role_id_in][]=${role.id}`).join("&");
  const userParams = selectedUsers?.length > 0 && selectedUsers?.map(user => `q[user_id_in][]=${user.id}`).join("&");
  const [editLoading, setEditLoading] = useState(true);
  const [schema, setSchema] = useState({});
  const params = useParams();
  const userIdFromParams = userIdFromSearch ? userIdFromSearch : params?.id;
  const showScheduleForm = userIdFromParams ? !editLoading : true;
  const [defaultUser, setDefaultUser] = useState({});

  const fetchUsers = async userIdFromParams => {
    const response = await getCurrentUser(userIdFromParams);
    if (response.success) {
      setEditLoading(false);
      setDefaultUser(response?.user);
    }
  };

  useEffect(() => {
    fetchUsers(userIdFromParams);
  }, []);

  const getShiftData = async () => {
    const queryParams = `?q[start_time_gteq]=${initialStartTime}&q[start_time_lteq]=${initialEndTime}&q[user_id_in][]=${userIdFromParams}`;
    const response = await fetchShifts({ params: queryParams });
    if (response.success) {
      setEditLoading(false);
      setScheduleData(response?.shifts);
    }
  };

  useEffect(() => {
    params?.id && getShiftData();
  }, [params?.id]);

  const appendSchema = formikKey => {
    setSchema(prev => ({ ...prev, [formikKey]: getShiftEntitySchema() }));
  };

  const removeSchema = formikKey => {
    setSchema(prev => {
      let newSchema = clone({ ...prev });
      delete newSchema[formikKey];
      return { ...newSchema };
    });
  };

  const formikRef = useRef();

  const getInitialValues = ({ formikRef, initialStartTime, initialEndTime, scheduleData }) => {
    if (formikRef.current && initialStartTime && initialEndTime && isEmptyArray(scheduleData)) {
      const shifts = getShiftElements({ startTime: initialStartTime, endTime: initialEndTime });
      return {
        ...shifts,
        start_time: initialStartTime,
        end_time: initialEndTime,
      };
    }
    return {
      user: null,
      start_time: null,
      end_time: null,
      copyUser: null,
    };
  };

  useEffect(() => {
    if (isEmptyArray(scheduleData) && initialStartTime && initialEndTime) {
      setInitialSchemas({ startTime: initialStartTime, endTime: initialEndTime });
    }
  }, [initialStartTime, initialEndTime]);

  useEffect(() => {
    if (!isEmptyArray(scheduleData) && initialStartTime && initialEndTime && formikRef.current) {
      const start_time = initialStartTime;
      const end_time = initialEndTime;
      let schedules = scheduleData.reduce((prev, savedSchedule) => {
        const date = moment(savedSchedule?.start_time).format("YYYY-MM-DD");
        const title = formatDateWithMonthAndDayName(date);
        const { start_time, end_time } = savedSchedule;
        const min_time = moment.utc(start_time).utcOffset(getUTCOffset(timezone)).format("hh:mm A");
        const max_time = moment.utc(addSeconds(end_time)).utcOffset(getUTCOffset(timezone)).format("hh:mm A");
        return {
          ...prev,
          [date]: prev[date]
            ? [...prev[date], { ...savedSchedule, date, title, min_time, max_time }]
            : [{ ...savedSchedule, date, title, min_time, max_time }],
        };
      }, {});
      let savedShifts = Object.entries(schedules).reduce((prev, [date, entries]) => {
        const newEntries = entries.reduce((prev, current, idx) => {
          const formikKey = `shift-${date.split("-").join("")}-${idx + 1}`;
          return {
            ...prev,
            [formikKey]: {
              ...current,
              formikKey,
            },
          };
        }, {});
        return { ...prev, ...newEntries };
      }, {});

      let savedShiftSchema = Object.entries(schedules).reduce((prev, [date, entries]) => {
        const newEntries = entries.reduce((prev, current, idx) => {
          const formikKey = `shift-${date.split("-").join("")}-${idx + 1}`;
          return {
            ...prev,
            [formikKey]: getShiftEntitySchema(),
          };
        }, {});
        return { ...prev, ...newEntries };
      }, {});
      setSchema(prev => ({ ...savedShiftSchema }));

      formikRef.current &&
        formikRef.current.setValues(values => {
          return {
            user: defaultUser,
            start_time,
            end_time,
            ...savedShifts,
          };
        });
    }
  }, [scheduleData]);

  const onSubmit = async (values, { setFieldError }) => {
    setSaveLoading(true);
    const { user, start_time, end_time, copyUser, ...shifts } = values;
    try {
      const shifts_attributes = Object.entries(shifts).map(([formikKey, shift]) => {
        let startTime = moment(`${shift.date} ${shift.min_time}`).format("YYYY-MM-DDTHH:mm:ss");
        let endTime = moment(`${shift.date} ${shift.max_time}`).format("YYYY-MM-DDTHH:mm:ss");
        endTime = subtractSeconds(endTime);
        startTime = appendTimeZone(startTime, timezone);
        endTime = appendTimeZone(endTime, timezone);
        return {
          ...(!shift?.isNewEntity && {
            id: shift.id,
          }),
          ...(!shift?._destroy && {
            start_time: startTime,
            end_time: endTime,
            area_ids: shift?.areas?.map(area => area?.id) ?? [],
            zone_ids: shift?.zones?.map(zone => zone?.id) ?? [],
          }),
          ...(!!shift?._destroy && {
            _destroy: true,
          }),
        };
      });
      const payload = {
        user: {
          id: defaultUser?.id,
          shifts_attributes,
        },
      };
      const response = await createShiftsApi({ ...payload });
      if (response.success) {
        history.push({
          pathname: CRM_ROTA_PLAN_PATH,
          search: `?q[start_time_gteq]=${start_time}&${roleParams}&${userParams}`,
        });
        Toast.success("Schedule Added Successfully");
        setSaveLoading(false);
      } else {
        Toast.error(getErrorString(response));
        setSaveLoading(false);
      }
    } catch (err) {
      debugger;
      console.log("err", err);
    }
  };

  function getShiftElements({ startTime, endTime }) {
    let formattedDates = getDaysBetweenDates(startTime, endTime);
    formattedDates = formattedDates.reduce((prev, dateStr, count) => {
      const formikKey = `shift-${dateStr.split("-").join("")}-1`;
      return {
        ...prev,
        [formikKey]: {
          formikKey,
          date: dateStr,
          title: formatDateWithMonthAndDayName(dateStr),
          min_time: null,
          max_time: null,
          zones: [],
          areas: [],
          isNewEntity: true,
        },
      };
    }, {});
    return formattedDates;
  }

  function setInitialSchemas({ startTime, endTime }) {
    let formattedDates = getDaysBetweenDates(startTime, endTime);
    let newSchemaObj = {};
    formattedDates.forEach(dateStr => {
      const formikKey = `shift-${dateStr.split("-").join("")}-1`;
      newSchemaObj[formikKey] = getShiftEntitySchema();
    });
    setSchema(prev => ({ ...newSchemaObj }));
  }

  const getShifts = async (params, initialStartTime, initialEndTime, currentShifts) => {
    const response = await fetchShifts({ params: params });
    const start_time = initialStartTime;
    const end_time = initialEndTime;
    const currentShiftsLength = currentShifts.length ?? 0;
    if (response.success) {
      const copyUserShifts = response?.shifts;
      let schedules = copyUserShifts.reduce((prev, savedSchedule) => {
        const { id, ...rest } = savedSchedule;
        const date = moment(rest?.start_time).format("YYYY-MM-DD");
        const title = formatDateWithMonthAndDayName(date);
        const { start_time, end_time } = rest;
        const min_time = moment.utc(start_time).utcOffset(getUTCOffset(timezone)).format("hh:mm A");
        const max_time = moment.utc(addSeconds(end_time)).utcOffset(getUTCOffset(timezone)).format("hh:mm A");
        return {
          ...prev,
          [date]: prev[date]
            ? [...prev[date], { ...rest, date, title, min_time, max_time, isNewEntity: true }]
            : [{ ...rest, date, title, min_time, max_time, isNewEntity: true }],
        };
      }, {});
      let savedShifts = Object.entries(schedules).reduce((prev, [date, entries]) => {
        const newEntries = entries.reduce((prev, current, idx) => {
          const formikKey = `shift-${date.split("-").join("")}-${currentShiftsLength + idx + 1}`;
          return {
            ...prev,
            [formikKey]: {
              ...current,
              formikKey,
            },
          };
        }, {});
        return { ...prev, ...newEntries };
      }, {});

      let savedShiftSchema = Object.entries(schedules).reduce((prev, [date, entries]) => {
        const newEntries = entries.reduce((prev, current, idx) => {
          const formikKey = `shift-${date.split("-").join("")}-${currentShiftsLength + idx + 1}`;
          return {
            ...prev,
            [formikKey]: getShiftEntitySchema(),
          };
        }, {});
        return { ...prev, ...newEntries };
      }, {});
      setSchema(prev => ({ ...savedShiftSchema }));

      formikRef.current &&
        formikRef.current.setValues(values => {
          return {
            user: defaultUser,
            start_time,
            end_time,
            ...values,
            ...savedShifts,
          };
        });
    }
  };

  const getShiftsFromUser = (selectedUser, initialStartTime, initialEndTime, formikProps) => {
    const formattedDate1 = appendTimeZone(initialStartTime, timezone);
    const formattedDate2 = appendTimeZone(initialEndTime, timezone);
    const params = `?q[start_time_gteq]=${initialStartTime}&q[start_time_lteq]=${initialEndTime}&q[user_id_in][]=${selectedUser.id}`;
    const { user, start_time, end_time, copyUser, ...shifts } = formikProps?.values;
    const currentShifts = Object.values(shifts);
    currentShifts.forEach(shift => {
      formikProps.setFieldValue(shift.formikKey, { ...shift, _destroy: true });
      removeSchema(shift.formikKey);
    });
    getShifts(params, formattedDate1, formattedDate2, currentShifts);
  };

  return (
    showScheduleForm && (
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues({
          formikRef,
          initialEndTime,
          initialStartTime,
          scheduleData,
        })}
        validationSchema={yup.object().shape({ ...schema })}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {formikProps => {
          const { handleSubmit, setFieldValue, values, setValues } = formikProps;
          const dayShifts = Object.entries(values).reduce((prev, [formikKey, value]) => {
            if (["user", "copyUser", "start_time", "end_time"].includes(formikKey)) return { ...prev };
            return {
              ...prev,
              [value.date]: !!prev?.[value.date] ? [...prev?.[value.date], { ...value }] : [{ ...value }],
            };
          }, {});
          const dayShiftEntries = Object.entries(dayShifts);
          dayShiftEntries.sort((a, b) => {
            const dateA = new Date(a[0]);
            const dateB = new Date(b[0]);
            return dateA - dateB;
          });

          return (
            <div className="container-fluid">
              <form className="form theme-form">
                <ScrollToFieldError />
                <PageTitle title={""} />
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>{params?.id ? strings.edit_schedule : strings.add_schedule}</h5>
                      <div className={styles.cardHeader}>
                        <PaginatedSearch
                          infoView
                          showLabel={false}
                          toggleClassName="form-control text-truncate"
                          onChange={selectedUser => {
                            setFieldValue("copyUser", selectedUser);
                            getShiftsFromUser(
                              selectedUser,
                              values?.start_time ?? initialStartTime,
                              values?.end_time ?? initialEndTime,
                              formikProps
                            );
                          }}
                          isSingleSelect
                          defaultSelected={values?.copyUser}
                          selectHeading={strings.add_user}
                          showSearchIcon={false}
                          horizontal={false}
                          loading={loading}
                          fetchOptions={(input, page) => fetchUserOptions({ "s[name]": input, page })}
                          onClear={() => setFieldValue("copyUser", null)}
                          rowClass={cx(styles.addUserBtn, "mb-0")}
                        />
                        <Button
                          className={cx("p-0 align-items-center", styles.copyUserBtn)}
                          btnText="Copy from a user"
                          icon={<BiCopy size={20} />}
                          color="link"
                        />
                      </div>
                    </div>
                  </div>

                  <div className={cx("card-body", styles.contentContainer)}>
                    <h3 className={styles.bodyHeading}>Schedule Options</h3>
                    <PaginatedSearch
                      label={strings.user}
                      infoView
                      showLabel={false}
                      isSingleSelect
                      defaultSelected={defaultUser}
                      selectHeading={strings.assignee}
                      showSearchIcon={false}
                      horizontal
                      disabled={true}
                    />
                    <FormRow horizontal label={strings.date} columnLeftSection={3} columnRightSection={9}>
                      <DateFilter
                        label={strings.date}
                        onSelect={(startTime, endTime) => {
                          const shifts = getShiftElements({ startTime, endTime });
                          setValues(values => ({
                            ...shifts,
                            start_time: startTime,
                            end_time: endTime,
                          }));
                          setInitialSchemas({ startTime, endTime });
                        }}
                        showIcon={false}
                        onClear={() => {
                          setValues(values => ({
                            start_time: null,
                            end_time: null,
                          }));
                        }}
                        queryStartDate={initialStartTime}
                        queryEndDate={initialEndTime}
                        timezone={timezone}
                        noFilter={true}
                        minDate={setTimeZone(timezone)}
                      />
                    </FormRow>
                    <h3 className={styles.bodyHeading}>Days</h3>
                    {dayShiftEntries
                      .map(([formikKey, values], idx) => {
                        const date = formatDateWithMonthAndDayName(formikKey);
                        const areAllShiftsDeleted = !!values?.every(value => value._destroy);
                        if (areAllShiftsDeleted) return null;
                        return (
                          <div key={formikKey}>
                            <div className={cx(styles.shiftContainer)}>
                              <div className={cx(styles.date)}>{date && date}</div>
                              <div className={styles.addScheduleFieldsContainer}>
                                {values
                                  .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
                                  .map((shift, i) => {
                                    const { _destroy: isDeleted } = shift;
                                    if (isDeleted) return null;
                                    return (
                                      <div
                                        key={shift.formikKey}
                                        className={styles.addScheduleFields}
                                        id={shift.formikKey}
                                      >
                                        <AddShiftContainer
                                          previousShift={idx !== 0}
                                          shift={shift}
                                          values={values}
                                          formikProps={formikProps}
                                          formRef={formikRef}
                                          userId={defaultUser?.id}
                                          date={date}
                                          dateKey={formikKey}
                                          removeSchema={removeSchema}
                                          appendSchema={appendSchema}
                                          dayShiftEntries={dayShiftEntries}
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  onClick={() => {
                                    let newFormikKey = `shift-${formikKey.split("-").join("")}-${values?.length + 1}`;
                                    formikProps.setFieldValue(newFormikKey, {
                                      formikKey: newFormikKey,
                                      date: formikKey,
                                      title: date,
                                      min_time: null,
                                      max_time: null,
                                      zones: [],
                                      areas: [],
                                      isNewEntity: true,
                                    });
                                    appendSchema(newFormikKey);
                                  }}
                                  color="monochrome"
                                  btnText={`Add New Shift`}
                                  variant="square"
                                  className={cx(styles.addNewButton)}
                                  style={{ "--btn-border-color": "transparent" }}
                                  icon={<IoMdAdd size={16} />}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .filter(Boolean)}
                  </div>
                </div>

                <div className="text-right p-b-10 p-r-10 position-sticky sticky-bottom">
                  <ButtonLoading
                    type={"submit"}
                    onClick={e => {
                      e.preventDefault();
                      handleSubmit();
                      let touchedKeys = dayShiftEntries.reduce((previous, current) => {
                        const touchedObj = current[1].reduce((prev, obj) => {
                          return {
                            ...prev,
                            [`${obj.formikKey}-minTime`]: true,
                            [`${obj.formikKey}-maxTime`]: true,
                            [`${obj.formikKey}-zones`]: true,
                          };
                        }, {});
                        return {
                          ...previous,
                          ...touchedObj,
                        };
                      }, {});
                      formikProps.setTouched({ ...formikProps.touched, ...touchedKeys });
                    }}
                    loadingSkeleton={props.loading}
                    disabled={saveLoading}
                    loading={saveLoading}
                  >
                    {strings.save}
                  </ButtonLoading>
                </div>
                {saveError && <CustomAlert message={saveError} />}
              </form>
            </div>
          );
        }}
      </Formik>
    )
  );
};

export default CrmAddSchedule;
