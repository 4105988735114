import {
  ADD_TASK_TYPE,
  DELETE_TASK_TYPE,
  FETCH_TASK_TYPES,
  UPDATE_TASK_TYPE,
  SET_TASK_TYPES_LOADING,
} from "../../constant/actionTypes";

import { fetchTaskTypesApi } from "../../helpers/ApiHelper";
import { getErrorString } from "../../helpers/Util";

export const fetchTaskTypes =
  (queryString = "") =>
  async dispatch => {
    dispatch({ type: SET_TASK_TYPES_LOADING, payload: { loading: true } });
    const res = await fetchTaskTypesApi(queryString);
    if (res) {
      const { task_types, pagination, ...rest } = res;
      if (task_types) {
        dispatch({
          type: FETCH_TASK_TYPES,
          payload: {
            ...rest,
            pagination,
            list: task_types,
            loading: false,
            initialRequestCalled: true,
            error: "",
          },
        });
      } else {
        dispatch({
          type: FETCH_TASK_TYPES,
          payload: {
            list: [],
            statuses: [],
            loading: false,
            initialRequestCalled: true,
            error: getErrorString(res),
          },
        });
      }
    }
  };

export const addTaskType = data => async dispatch => {
  dispatch({
    type: ADD_TASK_TYPE,
    payload: {
      taskType: data,
    },
  });
};

export const updateTaskType = data => async dispatch => {
  dispatch({
    type: UPDATE_TASK_TYPE,
    payload: {
      taskType: data,
    },
  });
};

export const deleteTaskType = id => async dispatch => {
  dispatch({
    type: DELETE_TASK_TYPE,
    payload: {
      taskTypeId: id,
    },
  });
};
