import React from "react";
import { message as msg } from "antd";

const notify = {
  error: obj => {
    if (typeof obj === "string") {
      msg.error({ content: obj, className: "errorMessage" });
    } else {
      const { message, title } = obj;
      msg.error({ content: message, className: "errorMessage" });
    }
  },
  success: obj => {
    if (typeof obj === "string") {
      msg.success({ content: obj });
    } else {
      const { message, title } = obj;
      msg.success({ content: message });
    }
  },
};

export default notify;
