import { MdContentCopy } from "components/svg-icon/svg-icons";
import { strings } from "constant/strings";
import React, { FC, useState } from "react";
import { Check } from "react-feather";
import Button from "../Button/Button";
import Toast from "../Toast";

interface CopyToClipboardProps {
  value: string;
  errorText?: string;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({ value = "", errorText = strings.text_copy_error }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(value);
    } catch (err) {
      Toast.error(errorText);
    }
  };
  const copyField = () => {
    setTimeout(() => {
      copyContent();
      setIsCopied(true);
    }, 200);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  if (isCopied) {
    return <Button icon={<Check size={15} />} btnText="" color="link" className={"p-0"} />;
  }

  return <Button onClick={copyField} icon={<MdContentCopy size={15} />} btnText="" color="link" className={"p-0"} />;
};

export default CopyToClipboard;
