import {
  FETCH_CRM_LEAD_FOLLOW_UPS,
  UPDATE_CRM_LEAD_FOLLOW_UPS,
  UPDATE_LEAD_FOLLOW_UP_STATUS,
  SET_CRM_LEADS_FOLLOW_UPS_LOADING,
} from "../../constant/actionTypes";
import { getErrorString } from "../../helpers/Util";
import { fetchFollowUps } from "../../helpers/ApiHelper";
import { store } from "../../store";
import { QUERY_KEYS } from "../../constant/queryKeys";

export const fetchCrmLeadFollowUps =
  (queryParams = "") =>
  async dispatch => {
    let query = queryParams;
    if (!query) {
      query += `?${QUERY_KEYS.EXACT.followed_type}=CrmLead`;
    } else {
      query = `?${QUERY_KEYS.EXACT.followed_type}=CrmLead&` + queryParams?.slice(1, queryParams.length);
    }
    dispatch({ type: SET_CRM_LEADS_FOLLOW_UPS_LOADING, payload: { loading: true } });
    const res = await fetchFollowUps(query);
    if (res && res.success) {
      dispatch({
        type: FETCH_CRM_LEAD_FOLLOW_UPS,
        payload: {
          list: [...res.FollowUps],
          loading: false,
          error: "",
          pagination: { ...res.pagination },
          statuses: [...res.statuses],
          permittedMeta: res.permitted_meta,
        },
      });
    } else {
      dispatch({
        type: FETCH_CRM_LEAD_FOLLOW_UPS,
        payload: {
          list: [],
          loading: false,
          error: getErrorString(res.message),
        },
      });
    }
  };

export const updateCrmLeadFollowUps = (assignee, followUp) => async dispatch => {
  let followUpsList = store.getState().CRMLeadFollowUpsData.list;
  const index = followUpsList.findIndex(item => item.id === followUp.id);
  if (index !== -1) {
    followUpsList[index]["assignee"] = assignee;
    dispatch({
      type: UPDATE_CRM_LEAD_FOLLOW_UPS,
      payload: followUpsList,
    });
  }
};

export const updateLeadFollowUpStatus = params => async dispatch => {
  dispatch({
    type: UPDATE_LEAD_FOLLOW_UP_STATUS,
    payload: {
      followUp: params,
    },
  });
};
