import { fetchUsersApi, fetchUsersListApi } from "./ApiHelper";
import { objectToQuery } from "./Util";

export const fetchUserOptions = async (obj = {}) => {
  let query = objectToQuery(obj);
  const data = await fetchUsersListApi(query);
  if (data?.users?.length > 0) {
    return { users: data.users, pagination: data.pagination, success: true };
  } else {
    return { users: [], pagination: { next_page: null, success: false } };
  }
};

export const fetchUsers = async (obj = {}, extraParams = "") => {
  let query = objectToQuery(obj);
  if (extraParams !== "") {
    query += extraParams;
  }
  const data = await fetchUsersApi(query);
  if (data.users) {
    return { users: data.users, pagination: data.pagination, success: true };
  } else {
    return { users: [], pagination: { next_page: null }, success: false };
  }
};
