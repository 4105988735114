import React, { Fragment, useEffect, useState } from "react";
import { CgSoftwareUpload } from "../svg-icon/svg-icons";
import { strings } from "../../constant/strings";
import { onFilesAdded, uploadFile } from "../crm-leads/UploadAttachmentsHelpers";
import ImageItem from "./ImageItem";
import FileUploadItem from "../crm-leads/UploadAttachments/FileUploadItem";
import { contentMapping, contentMappingForImages } from "../../constant/lists";

const ImageUpload = props => {
  const {
    setFieldValue,
    errorMsg,
    multiSelect,
    associationKey,
    horizontal,
    boxedBtn,
    isEditable = null,
    label = strings.proof_of_payment,
    textRight,
    paddingSmall,
  } = props;
  const [files, setFiles] = useState([]);

  const isMultipleFiles = (multiSelect === true && files?.length > 0) || files?.length === 0;

  const removeFile = item => {
    const filesData = [...files];
    const index = filesData.findIndex(e => e.id === item.id);
    filesData.splice(index, 1);
    setFieldValue(filesData);
  };

  useEffect(() => {
    props.values &&
      setFiles(
        props.values
          .map(e => ({
            ...e,
            uploaded: e.hasOwnProperty("uploaded") ? e.uploaded : true,
            uploading: e.hasOwnProperty("uploading") ? e.uploading : false,
            inError: e.hasOwnProperty("inError") ? e.inError : false,
          }))
          .sort((a, b) => {
            return a.id < b.id ? 1 : -1;
          })
      );
  }, [props.values]);

  const retryCallback = imageItem => {
    if (imageItem.inError && !imageItem.uploading && !imageItem.uploaded) {
      uploadFile(imageItem, "documents", associationKey, files, props.setFieldValue, false, false, undefined);
    }
  };

  return (
    <Fragment>
      <div
        className={`payment-info-img-upload ${
          horizontal ? "row form-group" : "d-flex flex-1 align-items-center justify-content-between p-b-15 p-relative"
        }`}
        style={isEditable === false ? { cursor: "not-allowed" } : {}}
      >
        <div className={`${horizontal ? "col-sm-3 col-form-label" : ""}  ${textRight ? "text-right" : ""}`}>
          {label}
        </div>
        <div
          className={`${horizontal ? "col-sm-9" : ""}`}
          style={isEditable === false ? { pointerEvents: "none" } : {}}
        >
          {files.length > 0 &&
            files.map(item =>
              isMultipleFiles ? (
                <FileUploadItem
                  onRetry={() => {
                    retryCallback(item);
                  }}
                  item={item}
                  files={files}
                  setFiles={props.setFieldValue}
                  removeFile={removeFile}
                  key={item.id}
                  isSvg={false}
                  makeBlob={false}
                  attachmentType={"documents"}
                  associationKey={associationKey}
                  canRemoveFile={!props.disabled}
                />
              ) : (
                <React.Fragment key={item.id}>
                  <ImageItem
                    onRetry={() => {
                      retryCallback(item);
                    }}
                    item={item}
                    files={files}
                    setFiles={props.setFieldValue}
                    removeImage={removeFile}
                    isSvg={false}
                    makeBlob={false}
                    attachmentType={"documents"}
                    associationKey={associationKey}
                  />
                </React.Fragment>
              )
            )}

          {isMultipleFiles && props.showButton && (
            <div className={`${boxedBtn ? "fileUploader" : ""} ${paddingSmall ? "padding-small" : ""}`}>
              <button
                type="button"
                className={`${
                  boxedBtn
                    ? "btn btn-outline-primary position-relative"
                    : "d-flex flex-row align-items-center justify-content-between upload-btn p-relative cursor-pointer"
                }`}
              >
                <input
                  className="file-upload-input cursor-pointer"
                  disabled={isEditable === false}
                  type="file"
                  accept={props.imagesOnly ? contentMappingForImages.join(",") : contentMapping.join(",")}
                  multiple={multiSelect}
                  onChange={e => {
                    let file = e.target.files;
                    onFilesAdded(files, setFieldValue, file);
                    e.target.value = null;
                  }}
                />
                <CgSoftwareUpload className="icon" size="16" />
                <span>{strings.upload}</span>
              </button>
            </div>
          )}
          {!!errorMsg && <p className="invalid-error">{errorMsg}</p>}
        </div>
      </div>
    </Fragment>
  );
};

export default ImageUpload;
