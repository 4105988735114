import {
  APPEND_INSPECTION_LEAD,
  CLEAR_CRM_LEAD_DETAIL,
  CRM_LEAD_ADD_TASK,
  CRM_LEAD_CHANGE_STATUS,
  CRM_LEAD_CONNECTS_UPDATE,
  CRM_LEAD_TASK_STATUS_UPDATE,
  CRM_LEAD_UPDATE,
  CRM_LEAD_UPDATE_TASK,
  FETCH_CRM_LEAD_DETAIL,
  FETCH_CRM_LEAD_TASKS,
  SET_CRM_LEAD_DATA,
  UPDATE_CRM_LEAD_COMMENTS,
  UPDATE_CRM_LEAD_WATCHERS,
  UPDATE_CRM_LEAD_DETAIL,
} from "../../constant/actionTypes";
import { fetchCRMLeadDetailApi, fetchCrmLeadTasksApi, fetchLeadAssigneesApi } from "../../helpers/ApiHelper";

import { checkUndefinedApiParams, getErrorString, isFieldRestricted, isIdAvailable } from "../../helpers/Util";
import { Toast } from "../../components/common";
import { strings } from "../../constant/strings";

export const fetchCRMLeadDetail = id => async dispatch => {
  if (!checkUndefinedApiParams(id, "id")) return;
  dispatch({
    type: FETCH_CRM_LEAD_DETAIL,
    payload: {
      lead: null,
      loading: true,
    },
  });
  const res = await fetchCRMLeadDetailApi(id);
  if (res) {
    const { crm_lead, ...rest } = res;
    if (crm_lead) {
      dispatch({
        type: FETCH_CRM_LEAD_DETAIL,
        payload: {
          lead: { ...crm_lead, connects: [] },
          ...rest,
          loading: false,
          error: "",
        },
      });
    } else {
      dispatch({
        type: FETCH_CRM_LEAD_DETAIL,
        payload: {
          statuses: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  }
};

export const updateCrmLeadDetail = crmLead => async dispatch => {
  dispatch({
    type: UPDATE_CRM_LEAD_DETAIL,
    payload: {
      crmLead: crmLead,
    },
  });
};

export const fetchCrmLeadTasks = id => async dispatch => {
  const res = await fetchCrmLeadTasksApi(id, `?limit=1000&sorts=created_at asc`);
  if (res) {
    if (res.tasks) {
      dispatch({
        type: FETCH_CRM_LEAD_TASKS,
        payload: {
          tasks: res.tasks,
        },
      });
    } else {
      dispatch({
        type: FETCH_CRM_LEAD_TASKS,
        payload: {
          statuses: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  }
};

export const clearCRMLeadDetails = () => dispatch => {
  dispatch({
    type: CLEAR_CRM_LEAD_DETAIL,
  });
};

export const crmLeadUpdate = item => async dispatch => {
  const res = await fetchCrmLeadTasksApi(item?.task?.crm_lead?.id, `?limit=1000&sorts=created_at asc`);
  if (res) {
    if (res.tasks) {
      dispatch({
        type: CRM_LEAD_UPDATE,
        payload: {
          tasks: res.tasks,
          status: item.task.crm_lead.status,
          disposition: item.task.crm_lead.disposition,
          dispositions: item.lead_dispositions,
          connects: item.task.crm_lead.connects,
        },
      });
    } else {
      dispatch({
        type: CRM_LEAD_UPDATE,
        payload: {
          statuses: [],
          loading: false,
          error: getErrorString(res),
        },
      });
    }
  }
};

export const crmLeadTaskStatusUpdate = item => async dispatch => {
  dispatch({
    type: CRM_LEAD_TASK_STATUS_UPDATE,
    payload: {
      ...item,
    },
  });
};

export const crmLeadAddTask = item => dispatch => {
  dispatch({
    type: CRM_LEAD_ADD_TASK,
    payload: {
      task: { ...item },
    },
  });
};

export const crmLeadUpdateTask = item => async dispatch => {
  dispatch({
    type: CRM_LEAD_UPDATE_TASK,
    payload: {
      task: item,
    },
  });
};

export const crmLeadUpdateComments = item => async dispatch => {
  dispatch({
    type: UPDATE_CRM_LEAD_COMMENTS,
    payload: {
      comment: item,
    },
  });
};

export const crmLeadUpdateWatchers = item => async dispatch => {
  dispatch({
    type: UPDATE_CRM_LEAD_WATCHERS,
    payload: {
      watchers: item,
    },
  });
};

export const crmLeadUpdateStatus = item => async dispatch => {
  dispatch({
    type: CRM_LEAD_CHANGE_STATUS,
    payload: {
      status: item.status,
    },
  });
};

export const crmLeadConnectsUpdate = item => async dispatch => {
  dispatch({
    type: CRM_LEAD_CONNECTS_UPDATE,
    payload: {
      connects: item,
    },
  });
};

export const appendInspectionLead = item => async dispatch => {
  dispatch({
    type: APPEND_INSPECTION_LEAD,
    payload: {
      newLead: item,
    },
  });
};
