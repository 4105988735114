import React, { useCallback, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useSelector } from "react-redux";
import { selectTenant } from "../../reducers/general/selectors";
import { clone, getErrorString, isFieldRestricted } from "../../helpers/Util";
import IF from "./IF";
import Toast from "./Toast";
import cx from "clsx";
import { Loader } from "./Loader";
import { RESTRICTED_MODULES } from "constant/appConstants";
import { strings } from "../../constant/strings";

const CardView = props => {
  const { photos = [], viewerIsOpen, setCurrentGalleryImage, currentGalleryImage, closeLightbox = () => {} } = props;

  return (
    <>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentGalleryImage]?.full ?? {}}
          nextSrc={photos[(currentGalleryImage + 1) % photos.length]?.full ?? {}}
          prevSrc={photos[(currentGalleryImage + photos.length - 1) % photos.length]?.full ?? {}}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setCurrentGalleryImage((currentGalleryImage + photos.length - 1) % photos.length)}
          onMoveNextRequest={() => setCurrentGalleryImage((currentGalleryImage + 1) % photos.length)}
        />
      )}
    </>
  );
};
const PhotoGallery = ({
  photos,
  thumbnailCount,
  daysInStock,
  setViewerIsOpen,
  viewerIsOpen,
  setCurrentGalleryImage,
  currentGalleryImage,
  isDaysInStockRestricted,
}) => {
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentGalleryImage(index);
  }, []);

  const closeLightbox = () => {
    setViewerIsOpen(false);
  };

  const formattedPhotos = [...photos].map(photo => ({ ...photo, src: photo.gallerythumb, width: 100, height: 100 }));

  console.log("formattedPhotos :>> ", formattedPhotos);

  const galleryProps = {
    photos,
    thumbnailCount,
    formattedPhotos,
    openLightbox,
    setViewerIsOpen,
    viewerIsOpen,
    setCurrentGalleryImage,
    currentGalleryImage,
    closeLightbox,
    isDaysInStockRestricted,
    daysInStock,
  };

  const hasPhotos = photos?.length > 0;
  if (!hasPhotos) return null;
  return <CardView {...galleryProps} />;
};

const PhotoGalleryWithLoader = ({ photo, totalPhotosCount, daysInStock, fetchAttachments = () => {} }) => {
  const [currentGalleryImage, setCurrentGalleryImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const tenant = useSelector(selectTenant);
  const restrictedModules = tenant.configuration.restricted_modules;
  const isDaysInStockRestricted = restrictedModules.includes(RESTRICTED_MODULES.STOCK_COUNTERS);

  const [photos, setPhotos] = useState({
    loading: false,
    data: [],
  });

  const { data, loading } = photos;

  const fetchPhotos = async () => {
    try {
      setPhotos(prev => ({
        ...prev,
        loading: true,
      }));

      const attachments = await fetchAttachments();

      const newArray = attachments.sort((a, b) => {
        if (b?.updated_at && a?.updated_at) {
          return new Date(b.updated_at) - new Date(a.updated_at);
        }

        return 0;
      });

      setPhotos(prev => ({
        ...prev,
        data: newArray,
      }));

      return newArray;
    } catch (error) {
      Toast.error(getErrorString(error));
    } finally {
      setPhotos(prev => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handlePreviewClick = async () => {
    let attachments = clone(data);
    if (!data.length) {
      attachments = await fetchPhotos();
    }
    selectCurrentGallaryImage(attachments);
  };

  const selectCurrentGallaryImage = photos => {
    if (!!photos?.length) {
      const currentPhotoIndexForGallery = photos.findIndex(attachment => attachment.id === photo.id);

      if (currentPhotoIndexForGallery > -1) {
        setCurrentGalleryImage(currentPhotoIndexForGallery);
        setViewerIsOpen(true);
      }
    }
  };

  return (
    <>
      <figure
        itemProp="associatedMedia"
        className={`galleryThumb position-relative  ${loading ? "loader-overlay" : ""}`}
      >
        <img src={photo?.full} itemProp="thumbnail" alt="description" />
        <IF condition={loading}>
          <div className={cx("position-absolute photo-gallery-loader")}>
            <Loader style={{ "--border-color": "#fff" }} />
          </div>
        </IF>

        <IF condition={!loading}>
          <span className={"view_gallery_link"} onClick={handlePreviewClick}>
            {strings.all_photos}
          </span>
        </IF>

        <IF condition={daysInStock && !isFieldRestricted(daysInStock) && !isDaysInStockRestricted}>
          <div className="days-in-stock">{`${daysInStock ?? 0} ${strings.days_ago}`}</div>
        </IF>
      </figure>

      <IF condition={!!data?.length}>
        <PhotoGallery
          photos={photos.data}
          setViewerIsOpen={setViewerIsOpen}
          viewerIsOpen={viewerIsOpen}
          setCurrentGalleryImage={setCurrentGalleryImage}
          currentGalleryImage={currentGalleryImage}
          isDaysInStockRestricted={isDaysInStockRestricted}
        />
      </IF>
    </>
  );
};

export default PhotoGalleryWithLoader;
