import { Skeleton } from "components/common";
import Card from "components/common/Card";
import Flex from "components/common/Flex";
import Group from "components/common/Group";
import React from "react";

const ExistingCarDetailSkeleton = () => {
  return [1, 2, 3].map(_ => (
    <Card key={_} className="mb-2" cardBg="#f9fafb" cardBorderRadius="6px">
      <Group className="align-items-center" template={"80px auto"} gap="10px">
        <Skeleton height={80} width={80} />
        <Flex justify="space-between" className="w-100" align="start" style={{ height: "100%" }}>
          <div>
            <div>
              <Skeleton height={12} width={40} />
            </div>
            <div>
              <Skeleton height={12} width={80} />
            </div>
            <div>
              <Skeleton height={16} width={200} />
            </div>

            <div className={"text-dark-gray"}>
              <Skeleton height={12} width={100} style={{ marginTop: "10px", marginRight: "10px" }} />
              <Skeleton height={12} width={100} style={{ marginTop: "10px" }} />
            </div>
          </div>
          <Flex vertical align="end" justify="space-between" style={{ height: "94%" }}>
            <div>
              <Skeleton height={24} width={55} style={{ borderRadius: "5px" }} />
              <Skeleton height={24} width={55} style={{ marginTop: "10px", marginLeft: "10px" }} />
              <Skeleton height={24} width={55} style={{ marginTop: "10px", marginLeft: "10px" }} />
            </div>
            <Skeleton height={12} width={140} style={{ marginTop: "10px" }} />
          </Flex>
        </Flex>
      </Group>
    </Card>
  ));
};

export default ExistingCarDetailSkeleton;
