import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { APPROVAL_STATUSES } from "constant/appConstants";
import { strings } from "../../constant/strings";
import { acceptRejectNegotiation } from "../../helpers/ApiHelper";
import { Modal } from "../common";
import Toast from "../common/Toast";
import { updateAuctionNegotiations } from "actions/auction/auctionNegotiations.actions";

const RejectNegotiationModal = ({ negotiationId, statuses, showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);

  const auctionNegotiations = useSelector(state => state.AuctionNegotiationsData.negotiations);
  const onReject = async () => {
    const payload = {
      status_id: statuses?.find(status => status?.slug === APPROVAL_STATUSES.REJECTED)?.id,
    };

    setLoading(true);
    const res = await acceptRejectNegotiation(negotiationId, payload)
      .then(res => {
        if (!res.success) {
          setLoading(false);
          throw new Error("Bad Request");
        }
        setShowModal(false);
        const updatedNegotiations = auctionNegotiations.map(obj =>
          obj.id === res?.negotiation?.id ? { ...obj, ...res?.negotiation } : obj
        );
        dispatch(updateAuctionNegotiations({ negotiations: updatedNegotiations }));
        return res;
      })
      .then(data => {
        setLoading(false);
        setShowModal(false);
      })
      .catch(error => {
        setLoading(false);
        Toast.error(error.message);
      });
  };

  return (
    <div>
      <Modal
        toggle={() => setShowModal(null)}
        title={<div>{strings.reject_negotiation}</div>}
        open={!!showModal}
        // scrollable={true}
        body={() => <div>{strings.reject_negotiation_prompt}</div>}
        actions={[
          {
            label: strings.cancel,
            onClick: () => {
              setShowModal(false);
            },
            color: "secondary",
            className: "modal__cancel-btn",
          },
          {
            label: strings.yes,
            onClick: () => {
              onReject();
            },
            color: "primary",
            className: "modal__cancel-btn",
            loading: loading,
          },
        ]}
      />
    </div>
  );
};

export default RejectNegotiationModal;
