import * as yup from "yup";

import { ButtonLoading, CustomAlert, DateSelect, FormInput, Toast } from "../common";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import {
  addPermittedProperty,
  checkUndefinedApiParams,
  excludeTodayTimes,
  getErrorString,
  getUTCOffset,
  isFieldEditable,
  isFieldViewable,
} from "../../helpers/Util";

import { Formik } from "formik";
import { commentsValidation } from "../../helpers/validations";
import moment from "moment-timezone";
import { strings } from "../../constant/strings";
import { updateLeadApi } from "../../helpers/ApiHelper";
import { useSelector } from "react-redux";
import { selectActiveColumns } from "../../reducers/general/selectors";

const AddFollowUpForm = forwardRef((props, ref) => {
  const activeColumnPermittedAttributes = useSelector(selectActiveColumns);
  const followUpFormPermittedAttributes = activeColumnPermittedAttributes.Lead;

  const today = new Date();
  const timezone = useSelector(content => content.Configs.tenant.country.timezone);

  const { lead, onDone, dispositionId, timeZone } = props;
  const [loading, setLoading] = useState(false);
  let formikRef = useRef();
  let scheduleDate = moment(today).tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
  scheduleDate = new Date(scheduleDate);

  useImperativeHandle(ref, () => ({
    clearValues() {
      formikRef.current.setValues(
        {
          date: scheduleDate,
          comments: "",
        },
        true
      );
    },
  }));

  const getInitialValues = () => ({
    ...(isFieldViewable(followUpFormPermittedAttributes?.["schedule.start_time"]) &&
      isFieldViewable(followUpFormPermittedAttributes?.["schedule.end_time"]) && {
        date: scheduleDate,
      }),

    ...addPermittedProperty(followUpFormPermittedAttributes?.["timelines.comment"], {
      comments: "",
    }),
  });

  const validationSchema = () => {
    return yup.object().shape({
      ...(isFieldViewable(followUpFormPermittedAttributes?.["schedule.start_time"]) &&
        isFieldViewable(followUpFormPermittedAttributes?.["schedule.end_time"]) && {
          date: yup.string().required(strings.select_date),
        }),

      ...addPermittedProperty(followUpFormPermittedAttributes?.["timelines.comment"], {
        comments: commentsValidation,
      }),
    });
  };

  const onSubmit = async (values, { setStatus }) => {
    if (!checkUndefinedApiParams(lead?.id, "lead")) return;
    setStatus({ message: "" });
    // const dateString = moment
    //   .tz(values.date, timeZone)
    //   .format("YYYY-MM-DDTHH:mm:ssZ");
    const dateString = `${moment(values.date).format("YYYY-MM-DDTHH:mm:ss")}${getUTCOffset(timezone)}`;
    setLoading(true);
    const obj = {
      lead: {
        disposition_id: dispositionId,
        followup_at: dateString,
        ...(isFieldEditable(followUpFormPermittedAttributes?.["timelines.comment"]) &&
          isFieldEditable(followUpFormPermittedAttributes?.["timelines.disposition_id"]) && {
            timelines_attributes: [
              {
                disposition_id: dispositionId,
                comment: values.comments,
              },
            ],
          }),
      },
    };
    const res = await updateLeadApi(lead.id, obj);
    if (res) {
      setLoading(false);
      if (res.lead) {
        onDone(res.lead);
        Toast.success(strings.lead_added_as_followup_message);
      } else {
        setStatus({
          message: getErrorString(res),
        });
      }
    }
  };

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors, touched, handleChange, handleBlur, handleSubmit, status }) => {
          return (
            <form className="form theme-form page__inner-form">
              {isFieldViewable(followUpFormPermittedAttributes?.["schedule.start_time"]) &&
                isFieldViewable(followUpFormPermittedAttributes?.["schedule.end_time"]) && (
                  <DateSelect
                    onChange={e => {
                      setFieldValue("date", e);
                    }}
                    selected={values.date}
                    label={strings.date_and_time}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    inspection_id={lead?.inspection_id}
                    excludeTimes={excludeTodayTimes(15, values.date, timeZone)}
                    required
                    excludeDates={today.getDay() !== scheduleDate.getDay() ? [today] : []}
                    timeZone={timeZone}
                    isEditable={
                      isFieldEditable(followUpFormPermittedAttributes?.["schedule.start_time"]) &&
                      isFieldEditable(followUpFormPermittedAttributes?.["schedule.end_time"])
                    }
                  />
                )}

              {isFieldViewable(followUpFormPermittedAttributes?.["timelines.comment"]) && (
                <FormInput
                  label={strings.comments}
                  placeholder={strings.comments_ph}
                  linesCount="3"
                  onBlur={handleBlur("comments")}
                  value={values.comments}
                  errorMsg={errors.comments && touched.comments && errors.comments}
                  onChange={e => {
                    handleChange("comments")(e);
                  }}
                  isEditable={isFieldEditable(followUpFormPermittedAttributes?.["timelines.comment"])}
                />
              )}

              {status && status.message && <CustomAlert message={status.message} />}
              <ButtonLoading
                className="theme__primary-btn pull-left"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
                loading={loading}
              >
                {strings.confirm}
              </ButtonLoading>
            </form>
          );
        }}
      </Formik>
    </div>
  );
});

export default AddFollowUpForm;
