import React from "react";
import Tooltip from "../tooltip/tooltip";
import { capitalizeFirstLetter } from "../../../utils";
import { Space } from "antd";
import cx from "clsx";
import { Number } from "components/ant-common";

const UserReportHover = ({ data, message, hoverKeys }) => {
  const content = (
    <Space direction="vertical" size={12}>
      {Object.keys(hoverKeys)?.map((item, index) => {
        return (
          <Space className={cx("w-100")} align="center" style={{ justifyContent: "space-between" }} size={24}>
            <span key={index}>{hoverKeys[item]}: </span>
            {typeof data[item] === "number" ? (
              <span className="bold">
                <Number value={data[item]} />
              </span>
            ) : (
              <span className="bold">{data[item]}</span>
            )}
          </Space>
        );
      })}
    </Space>
  );
  return (
    <Tooltip overlayInnerStyle={{ padding: "16px" }} title={content}>
      {message}
    </Tooltip>
  );
};

export default UserReportHover;
