import {
  CAR_CONNECTS_UPADTE,
  CLEAR_CAR_DETAILS,
  REMOVE_CAR_DOCUMENT,
  SET_CAR_DETAIL,
  UPDATE_CAR_DOCUMENTS,
  UPDATE_CAR_DOCUMENT_LABEL,
  UPDATE_RECOMMENDED_PRICE,
  UPDATE_INVENTORY_STATUS,
  UPDATE_CAR_TAGS,
  UPDATE_CAR_DETAIL,
  SET_CAR_DETAIL_COMMENTS,
  SET_CAR_DETAIL_DYNAMIC_ATTRIBUTES,
} from "../../constant/actionTypes";

const INITIAL_STATE = {
  car: {},
  documentLabels: [],
  buyerLeads: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CAR_DETAIL:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CAR_DETAIL_DYNAMIC_ATTRIBUTES:
      return {
        ...state,
        dynamicFields: action.payload.dynamicFields,
      };
    case UPDATE_CAR_DETAIL:
      return {
        ...state,
        car: { ...action.payload.car },
      };

    case SET_CAR_DETAIL_COMMENTS:
      return {
        ...state,
        car: {
          ...state.car,
          comments: [...state.car.comments, action.payload.comments],
        },
      };

    case UPDATE_INVENTORY_STATUS:
      return action.payload;
    case UPDATE_RECOMMENDED_PRICE:
      const car = state.car;
      car.inventory.price_recommendation = { ...action.payload.price };
      return {
        ...state,
        car,
      };
    case CLEAR_CAR_DETAILS:
      return {
        ...INITIAL_STATE,
      };
    case CAR_CONNECTS_UPADTE: {
      const car = state.car;
      car.inventory.connects = [...(car.inventory.connects || []), ...action.payload.connects];
      return {
        ...state,
        ...car,
      };
    }

    case UPDATE_CAR_DOCUMENTS: {
      const car = state.car;
      car.inventory.documents = [...(car.inventory.documents || []), ...action.payload.documents];
      return {
        ...state,
        ...car,
      };
    }

    case REMOVE_CAR_DOCUMENT: {
      const documents = [...state.car.inventory.documents];
      const index = documents?.findIndex(f => f.id === action.payload.document.id);
      documents.splice(index, 1);
      return {
        ...state,
        car: {
          ...state.car,
          inventory: {
            ...state.car.inventory,
            documents,
          },
        },
      };
    }

    case UPDATE_CAR_DOCUMENT_LABEL: {
      const documents = [...state.car.inventory.documents];
      const index = documents?.findIndex(f => f.id === action.payload.id);
      documents.splice(index, 1, { ...documents[index], label: action.payload.label });
      return {
        ...state,
        car: {
          ...state.car,
          inventory: {
            ...state.car.inventory,
            documents,
          },
        },
      };
    }
    case UPDATE_CAR_TAGS: {
      return {
        ...state,
        car: {
          ...state.car,
          tags: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
