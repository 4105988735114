import React, { useEffect } from "react";
import { uploadFile } from "../crm-leads/UploadAttachmentsHelpers";
import Close from "../common/Close";
import Button from "./Button";
import { TrashIcon } from "../svg-icon/svg-icons";

const ImageUploadItem = props => {
  const {
    uploadImage,
    imageKey,
    item,
    removeImage,
    imgUploadPreviewClass = "",
    userImgView,
    style,
    updateDocument = null,
    labelId = null,
    shouldResize = true,
  } = props;
  const { uploaded, uploading, inError } = item;
  useEffect(() => {
    uploadFile(
      props.item,
      props.attachmentType,
      props.associationKey,
      props.files,
      props.setFiles,
      props.makeBlob,
      props.isSvg,
      updateDocument,
      labelId,
      shouldResize
    );
  }, [item.id]);

  return (
    <div
      className={`imgUploadPreviewWrap ${imgUploadPreviewClass}`}
      style={{ "--thumbnail-width": props.size, ...style }}
    >
      <div
        className={`${userImgView ? "userImgView" : "gallery-thumb"} ${props.imgContain ? "imgContain" : ""} ${
          inError || uploading ? "thumb-faded" : ""
        }`.trim()}
      >
        {!!uploading && <div className="loader"></div>}
        <img
          src={item[imageKey] ? item[imageKey] : item.localPreview}
          alt=""
          className={inError || uploading ? "invisible" : ""}
        />

        {inError && (
          <button type="button" className="btn-thumb-retry" onClick={props.onRetry}>
            Retry
          </button>
        )}

        {!uploading &&
          (userImgView ? (
            <Button
              btnText="Remove"
              color="transparent"
              icon={<TrashIcon />}
              className={`btn-remove ${inError ? "d-none" : ""}`}
              onClick={e => {
                e.preventDefault();
                removeImage(item);
              }}
            />
          ) : (
            <Close
              className="btnCloseRT"
              btnWidth="1.5rem"
              btnHeight="1.5rem"
              onClick={e => {
                e.preventDefault();
                removeImage(item);
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default ImageUploadItem;
