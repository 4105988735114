import * as yup from "yup";

import { ButtonLoading, CheckboxComponent, CustomAlert, FormInput, FormSelect } from "../common";
import React, { useEffect, useRef, useState } from "react";
import { addLeadTypeApi, fetchInspectionsApi, updateLeadTypeApi } from "../../helpers/ApiHelper";
import { getErrorString, makeListToSelectData } from "../../helpers/Util";

import { Formik } from "formik";
import { nameValidation } from "../../helpers/validations";
import { regex } from "../../constant/regex";
import { strings } from "../../constant/strings";
import { INS_LEAD_TYPES_PATH } from "../../constant/appPaths";

const LeadTypeForm = ({ leadType, onSuccess, history }) => {
  const [loading, setLoading] = useState(false);
  const [inspections, setInspections] = useState([]);

  let formRef = useRef();

  useEffect(() => {
    async function fetchInspections() {
      const response = await fetchInspectionsApi();
      if (response.success) {
        setInspections(response.inspections);
      }
    }
    fetchInspections();
  }, []);

  useEffect(() => {
    if (leadType) {
      formRef.current.setValues(
        {
          name: leadType.name,
          description: leadType.description,
          inspectionCategory: leadType.inspection?.id,
          price: leadType.price,
          sendEmail: leadType.enable_report_completion_email,
          auto_approval: leadType.auto_approval,
        },
        true
      );
    }
  }, [leadType]);

  const getInitialValues = () => ({
    name: "",
    description: "",
    inspectionCategory: "",
    price: "",
    enable_report_completion_email: false,
    auto_approval: false,
  });

  const validationSchema = () =>
    yup.object().shape({
      inspectionCategory: yup.string().required(strings.select_inspection_category),
      name: nameValidation("lead type name", 60),
      description: yup.string(strings.enter_description_message).nullable(),
      price: yup
        .number()
        .required(strings.enter_price_message)
        .typeError(strings.specify_a_number_message)
        .min(0, strings.min_value_message),
    });

  const onSubmit = async ({ sendEmail, inspectionCategory, ...values }, { setStatus }) => {
    setStatus({ message: "" });
    setLoading(true);
    const leadTypeObj = {
      lead_type: { ...values, inspection_id: inspectionCategory, enable_report_completion_email: sendEmail },
    };
    const response = leadType?.id
      ? await updateLeadTypeApi(leadType?.id, leadTypeObj)
      : await addLeadTypeApi(leadTypeObj);
    if (response) {
      setLoading(false);
      if (response) {
        onSuccess(response?.lead_type);
        history.push(INS_LEAD_TYPES_PATH);
      } else {
        setStatus({ message: getErrorString(response) });
      }
    }
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, status, setFieldValue }) => (
        <form className="form theme-form db__form-main">
          <FormInput
            label={strings.name}
            placeholder={strings.name_ph}
            onChange={handleChange("name")}
            onBlur={handleBlur("name")}
            value={values.name}
            errorMsg={errors.name && touched.name && errors.name}
            required
            horizontal
          />
          <FormInput
            label={strings.description}
            placeholder={strings.description_ph}
            linesCount="4"
            onChange={handleChange("description")}
            onBlur={handleBlur("description")}
            value={values.description}
            horizontal
            errorMsg={errors.description && touched.description && errors.description}
            required
          />
          <FormSelect
            placeholder={strings.inspection_category_ph}
            label={strings.inspection_category}
            onBlur={handleBlur("inspectionCategory")}
            value={values.inspectionCategory}
            horizontal
            errorMsg={errors.inspectionCategory && touched.inspectionCategory && errors.inspectionCategory}
            options={makeListToSelectData(inspections)}
            onChange={e => {
              handleChange("inspectionCategory")(e);
            }}
            required
            setSlugAsValue
          />

          <FormInput
            label={strings.price}
            horizontal
            placeholder={strings.price_ph}
            onChange={e => {
              const validChar = new RegExp(regex.priceWithDecimal).test(e.target.value);
              if (validChar) handleChange("price")(e);
            }}
            onBlur={handleBlur("price")}
            value={values.price}
            errorMsg={errors.price && touched.price && errors.price}
            required
          />

          <div className="form-group row">
            <label className="col-sm-3 col-form-label"></label>
            <div className="col-sm-9 d-flex" style={{ gap: "10px" }}>
              <CheckboxComponent
                label={strings.send_email}
                checked={values.sendEmail}
                onChange={event => {
                  setFieldValue("sendEmail", event.target.checked);
                }}
                horizontal
              />
              <CheckboxComponent
                label={"Auto Approval"}
                checked={values?.auto_approval}
                onChange={e => {
                  setFieldValue("auto_approval", e.target.checked);
                }}
              />
            </div>
          </div>

          {status && status.message && <CustomAlert message={status.message} />}
          <ButtonLoading
            className="pull-right"
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
            loading={loading}
          >
            {strings.save}
          </ButtonLoading>
        </form>
      )}
    </Formik>
  );
};

export default LeadTypeForm;
