import React, { useEffect, useState } from "react";

import { Chart } from "react-google-charts";
import ConfigDB from "../../data/config";
import { Select } from "./Select";
import Skeleton from "./Skeleton";
import { makeListToSelectData } from "../../helpers/Util";
import { strings } from "../../constant/strings";

export const DoughnutContainer = ({
  title,
  type,
  currentFilter,
  setFilters,
  setDoughnutStatuses,
  statuses,
  fetchCounts,
  platform,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const { byTime, statusId, timeType } = currentFilter;

  useEffect(() => {
    fetchData();
  }, [byTime, statusId]);

  const fetchData = async () => {
    setLoading(true);
    var obj = {
      group_by: type,
    };
    if (statusId && statusId != -1) {
      obj["q[status_id_eq]"] = statusId.toString();
    }
    if (byTime && timeType) {
      obj[`q[${byTime}]`] = timeType;
    }
    const response = await fetchCounts(obj);

    if (response && response.success) {
      setLoading(false);
      const counts = [["Types", "Hours per Day"]];
      Object.keys(response?.counts).forEach(e => {
        counts.push([e, response?.counts[e]]);
      });
      setData(counts);

      const statusList = makeListToSelectData(response.statuses || []);
      setDoughnutStatuses(statusList);
    }
  };

  return !data && loading ? (
    <div className="col-xl-6 d-flex flex-column doughnut-chart-height">
      <div className="card flex-grow-1"></div>
    </div>
  ) : (
    <div className="col-xl-6 d-flex flex-column">
      <div className="card flex-grow-1">
        <div className="card-header project-header">
          <div className="row">
            <div className={platform !== "auction" ? "col-sm-6" : "col-sm-9"}>
              <h5>{title}</h5>
            </div>
            {platform !== "auction" && (
              <div className="col-sm-3">
                <Select
                  options={statuses}
                  value={statusId}
                  onChange={e => {
                    let status = statuses.filter(it => {
                      if (it && it.slug == e.target.value) {
                        return true;
                      }
                      return false;
                    })[0];
                    let state = { statusId: Number(e.target.value) };
                    if (status && status.lead_filterable_column) {
                      state["timeType"] = status.lead_filterable_column;
                    }
                    setFilters({ ...state });
                  }}
                />
              </div>
            )}
            <div className="col-sm-3">
              <Select
                options={[
                  { slug: "today_on", label: strings.today },
                  { slug: "yesterday_on", label: strings.yesterday },
                  { slug: "weekly_on", label: strings.weekly },
                  { slug: "monthly_on", label: strings.monthly },
                ]}
                value={byTime}
                onChange={e => {
                  setFilters({ byTime: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-body chart-block chart-vertical-center project-charts p-0">
          {data && (
            <Chart
              width={"100%"}
              height={"400px"}
              chartType="PieChart"
              loader={<div>{strings.loading_chart}</div>}
              data={data}
              options={{
                // is3D: true,
                pieHole: 0.5,
                legend: {
                  alignment: "center",
                },
                slices: {
                  0: { color: ConfigDB.data.color.primary_color },
                  1: { color: ConfigDB.data.color.secondary_color },
                },
              }}
              rootProps={{ "data-testid": "3" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
