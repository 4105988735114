import React, { Fragment } from "react";
import {
  Archive,
  Bell,
  Check,
  Clock,
  Edit,
  Edit2,
  Eye,
  FileMinus,
  Lock,
  Trash2,
  Unlock,
  User,
  UserCheck,
  UserX,
  X,
  XCircle,
} from "react-feather";
import { RowAction } from ".";
import { Tooltip } from "components/ant-common";
import { WrappedAnchor } from "../../helpers/Util";
import {
  CgFileDocument,
  CounterOfferIcon,
  CreateQCIcon,
  GoPlus,
  IconInvoice,
  IconShare,
  IoReceiptOutline,
  MdCancel,
  NegotiationTrophy,
  RiFolderDownloadFill,
  ViewQCIcon,
} from "../svg-icon/svg-icons";
import HorizontalPopover from "./HorizontalPopover";

export const Actions = ({ actionsList, id }) => {
  const getIcon = (iconType, color) => {
    switch (iconType) {
      case "status":
        return <UserX color={color} className="hover-pointer" />;
      case "edit":
        return <Edit2 color={color || "#42ae7c"} className="hover-pointer" />;
      case "file":
        return <FileMinus color={color || "#42ae7c"} className="hover-pointer" />;
      case "view":
        return <Eye color={color || "#0094f1"} className="hover-pointer" />;
      case "delete":
        return <Trash2 color={color || "red"} className="hover-pointer" />;
      case "assign":
        return <User color={color || "#0094f1"} className="hover-pointer" />;
      case "archive":
        return <Archive color={color} className="hover-pointer" />;
      case "check":
        return <Check color={color} className="hover-pointer" />;
      case "user-check":
        return <UserCheck color={color} className="hover-pointer" />;
      case "lock":
        return <Lock color={color || "#ED6771"} className="hover-pointer" />;
      case "expire":
        return <XCircle color={color} className="hover-pointer" />;
      case "unlock":
        return <Unlock color={color || "#0094f1"} className="hover-pointer" />;

      case "clock":
        return <Clock color={color} className="hover-pointer" />;
      case "create-task":
        return <Edit color={color || "#42ae7c"} className="hover-pointer" />;
      case "cg-file":
        return <CgFileDocument color={color || "#808080"} className="hover-pointer" />;
      case "receipt":
        return <IoReceiptOutline color={color || "#808080"} className="hover-pointer" />;
      case "add":
        return <GoPlus color={color || "#808080"} className="hover-pointer" />;
      case "notification":
        return <Bell className={`hover-pointer ${color}`} />;
      case "invoice":
        return <IconInvoice color={color || "#808080"} className="hover-pointer" size={24} />;
      case "share":
        return <IconShare color={color || "#808080"} className="hover-pointer" size={24} />;
      case "counter-auction":
        return <CounterOfferIcon color={color || "#808080"} className="hover-pointer" size={19} />;
      case "reject":
        return <MdCancel color={color || "#808080"} className="hover-pointer" />;
      case "negotiation":
        return <NegotiationTrophy color={color || "#808080"} className="hover-pointer" />;
      case "createQC":
        return <CreateQCIcon color={color || "#808080"} className="hover-pointer" size={24} />;
      case "viewQC":
        return <ViewQCIcon color={color || "#808080"} className="hover-pointer" size={24} />;
      case "cross":
        return <X color={color} className="hover-pointer" />;
      case "download":
        return <RiFolderDownloadFill color={color || "#808080"} className="hover-pointer" size={24} />;
      default:
        return;
    }
  };

  const renderAction = (action, index) => {
    const { type, iconType, tooltipLabel, list, onClick, color, show, id, anchor, anchorLink, IconProp } = action;
    const uniqueKey = `${type}-${iconType}-${id}-${index}`;
    if (show === false) return null;
    switch (type) {
      case "button":
        return (
          <Fragment key={uniqueKey}>
            <WrappedAnchor shouldWrapAnchor={anchor} link={anchorLink}>
              <Tooltip
                title={tooltipLabel}
                placement="top"
                overlayStyle={{ padding: "0" }}
                overlayInnerStyle={{
                  color: "white",
                }}
                color={"black"}
              >
                <button className="table__action-icon" data-tip data-for={uniqueKey} onClick={onClick}>
                  {getIcon(iconType, color)}
                </button>
              </Tooltip>
            </WrappedAnchor>
          </Fragment>
        );
      case "dropdown":
        return (
          <RowAction
            key={uniqueKey}
            dropDownList={list}
            className={IconProp ? "" : "table__icon_opt"}
            IconProp={IconProp}
          />
        );
      default:
        return;
    }
  };
  const list = actionsList.map((action, index) => renderAction(action, index));
  const filteredArray = list.filter(item => item !== null);
  return (
    <>
      <div key={id} className={actionsList.length > 1 ? "d-flex justify-content-left ml-1 mr-1 align-item-center" : ""}>
        {filteredArray?.length <= 3 ? filteredArray : filteredArray?.slice(0, 2)}
      </div>
      {filteredArray?.length > 3 && (
        <HorizontalPopover items={filteredArray} placement="left" trigger="hover" id={id} />
      )}
    </>
  );
};
